import React from "react";
import { Ad2Card } from "../adCard";
import { Col, Row } from "react-bootstrap";
import DoughnutChart from "../charts/doughnutChart";
import { cloneDeep, merge } from "lodash";
import { Utils } from "../utilities";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";



export function Ad2EnglishProficiency({ data,selectedFilter }) {
    const { t, i18n } = useTranslation();
    const chartData = data && data.data;
    const gridData = data && data.gridData;
    const status = data && data.status;
    const total = data && data.total;
    const totalEnglishProficiencyLevel = chartData && chartData.General;

    const grammar = chartData && chartData.Grammar;
    const comprehension = chartData && chartData.Comprehension;
    const removeObjectNullValues = (obj) => {
        const newObj = {};
      
        for (const key in obj) {
          if (obj.hasOwnProperty(key) && obj[key] !== null) {
            if(obj.hasOwnProperty(key) && Array.isArray(obj[key]) && obj[key].length === 0){
            }else{
              newObj[key] = Array.isArray(obj[key]) ? obj[key]?.join(",") : obj[key];
            }
          }
        }
      
        return newObj;
      };

      var filteredSelected = removeObjectNullValues(selectedFilter);
    const columns = [
        {
            name: '',
            selectorKey: 'type',
            selector: row => row.type,
            sortable: false,
        },
        {
            name: t('Level 1'),
            selectorKey: 'level1',
            selector: row => row.level1,
            sortable: false,
        },
        {
            name: t('Level 2'),
            selectorKey: 'level2',
            selector: row => row.level2,
            sortable: false,
        },
        {
            name: t('Level 3'),
            selectorKey: 'level3',
            selector: row => row.level3,
            sortable: false,
        },
        {
            name: t('Level 4'),
            selectorKey: 'level4',
            selector: row => row.level4,
            sortable: false,
        },
        {
            name: t('Level 5'),
            selectorKey: 'level5',
            selector: row => row.level5,
            sortable: false,
        },
    ];
    const commonProperties = {
        cutout: '40%',
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        elements: {
            arc: {
                borderWidth: 1
            }
        },
        onHover: (event, chartElement) => {
            event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
        },
        plugins: {
            customLabels: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            title: {
                color: '#000000',
                align: 'center',
                position: 'bottom',
                text: 'asdf',
                display: true,
                padding: 10,
                font: {
                    family: 'Inter',
                    size: '20px',
                    style: 'normal',
                    weight: 700,
                    lineHeight: '24px',
                }
            },
            tooltip: {
                enabled: true,
                yAlign: 'bottom',
                xAlign: 'center',
                displayColors: false,
                padding: 10,
                callbacks: {
                    label: (context) => {
                        return context.formattedValue;
                    },
                    title: (context) => {
                        return '';
                    }
                }
            },
            legend: {
                display: true,
                labels: {
                    padding: 10,
                    usePointStyle: true,
                    boxWidth: 15
                }
            },
        },
    }
    const navigate = useNavigate();
    const handleNavigate = Utils.handleNavigate.bind(this, navigate);


    const onClick = (data, type) => {
        handleNavigate(type, { label: data.label,...filteredSelected })
    }
    const labelsAll = ["Level 1","Level 2","Level 3","Level 4","Level 5",]

    const getLabels = (arr) => { 
        // console.log("arrrr",arr)
        // return Utils.getLabels(arr).map(value => {
        //     console.log("ddddd",value);
        //     console.log("mmmmmm", value.substring(1, 5) + " " + value.substring(5))
        //     return t(value.charAt(0).toUpperCase() + value.substring(1, 5) + " " + value.substring(5));
        // })
        return arr.map(value =>{
            return t(value);
        })
    }
    const percentageArray = (arr) => {
        const sum = arr.reduce((acc, curr) => acc + curr);
        const percentages = arr.map((num) => Math.round(((num / sum) * 100) * 10) / 10);
        return percentages;
    }
    return <>
        <Ad2Card title={t("English Proficiency")} data={gridData} columns={columns} exportFileName="english_proficiency" sum={total} status={status}>
            <Row>
                {/* <Col md="4">
                    <DoughnutChart
                        onClick={(e) => {
                            onClick(e, "english-proficiency-drilldown")
                        }}
                        data={{
                            labels: getLabels(totalEnglishProficiencyLevel),
                            datasets: [{
                                data: percentageArray([
                                    totalEnglishProficiencyLevel?.level1,
                                    totalEnglishProficiencyLevel?.level2,
                                    totalEnglishProficiencyLevel?.level3,
                                    totalEnglishProficiencyLevel?.level4,
                                    totalEnglishProficiencyLevel?.level5,
                                ]),
                                backgroundColor: [
                                    '#058AA5',
                                    '#00B4D8',
                                    '#62DBF1',
                                    '#90E0EF',
                                    '#CAF0F8',
                                ]
                            }],
                        }} optionsOverride={merge(cloneDeep(commonProperties), {
                            plugins: {
                                title: {
                                    text: t('Total English Proficiency Level')
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            return context.formattedValue + '%';
                                        }
                                    }
                                }
                            }
                        })} />
                </Col> */}
                <Col md="4">
                    <DoughnutChart
showlabel={false}
onClick={(e) => {
                            onClick(e, "english-proficiency-drilldown")
                        }}
                        data={{
                            labels: getLabels(labelsAll),
                            datasets: [{
                                data: percentageArray([
                                    totalEnglishProficiencyLevel?.level1,
                                    totalEnglishProficiencyLevel?.level2,
                                    totalEnglishProficiencyLevel?.level3,
                                    totalEnglishProficiencyLevel?.level4,
                                    totalEnglishProficiencyLevel?.level5,
                                ]),
                                backgroundColor: [
                                    '#b685f3',
                                    '#66bafa',
                                    '#9ff5c3',
                                    '#ffafdf',
                                    '#fff0c4',
                                ]
                            }],
                        }} optionsOverride={merge(cloneDeep(commonProperties), {
                            plugins: {
                                title: {
                                    text: t('Total English Proficiency Level')
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            return context.formattedValue + '%';
                                        }
                                    }
                                }
                            }
                        })} />
                </Col>               <Col md="4">
                    <DoughnutChart
showlabel={true}

                        onClick={(e) => {
                            onClick(e, "grammar")
                        }}
                        data={{
                            labels: getLabels(labelsAll),
                            datasets: [{
                                data: percentageArray([
                                    grammar?.level1,
                                    grammar?.level2,
                                    grammar?.level3,
                                    grammar?.level4,
                                    grammar?.level5,
                                ]),
                                backgroundColor: [
                                    '#b685f3',
                                    '#66bafa',
                                    '#9ff5c3',
                                    '#ffafdf',
                                    '#fff0c4',
                                ]
                            }],
                        }} optionsOverride={merge(cloneDeep(commonProperties), {
                            plugins: {
                                title: {
                                    text: t('Grammar')
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            return context.formattedValue + '%';
                                        }
                                    }
                                }
                            }
                        })} />
                </Col>
                <Col md="4">
                    <DoughnutChart
showlabel={false}
                        onClick={(e) => {
                            onClick(e, "comprehension")
                        }}
                        data={{
                            labels: getLabels(labelsAll),
                            datasets: [{
                                data: percentageArray([
                                    comprehension?.level1,
                                    comprehension?.level2,
                                    comprehension?.level3,
                                    comprehension?.level4,
                                    comprehension?.level5,
                                ]),
                                backgroundColor: [
                                    '#b685f3',
                                    '#66bafa',
                                    '#9ff5c3',
                                    '#ffafdf',
                                    '#fff0c4',
                                ]
                            }],
                        }} optionsOverride={merge(cloneDeep(commonProperties), {
                            plugins: {
                                title: {
                                    text: t('Comprehension')
                                },
                                tooltip: {
                                    callbacks: {
                                        label: function (context) {
                                            return context.formattedValue + '%';
                                         }
                                    }
                                }
                            }
                        })}
                        showLable={false}
                        />
                </Col>
            </Row>
        </Ad2Card>
    </>;
}