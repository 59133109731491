import React from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { useState } from "react";
import {
  getAppliedJobDocuments,
  getjobdocument,
} from "../../services/apiCalls";
import { useEffect } from "react";
import { jobProxy } from "./jobproxy";
import { Col, Row } from "react-bootstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MainWrapper = styled.div`
  background: #ffffff;
  border-radius: 55px;
  padding: 24px;
`;
const MainDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: space-evenly;
  margin: 50px;
`;
const Content = styled.div`
  color: black;
  font-weight: 700;
  text-transform: capitalize;
`;
const ContentLink = styled.a`
  color: #d04d8b;
  text-decoration: none;
`;
const Doc = styled.div`
  position: relative;
  justify-content: center;
  display: flex;
`;
const ViewDocument = () => {
  const routeData = useLocation()
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [retrivedJobDocs, setRetrivedJobDocs] = useState([]);
  const [JobDocs, setJobDocs] = useState([]);

  const getDocsIfAlreadyUploaded = async () => {
    const { data } = await getAppliedJobDocuments({
      job_id: jobProxy.jobid,
      user_id: jobProxy.userid,
    });
    if (data && data.status === 1) {
      setRetrivedJobDocs(data.data);
    }
  };
  const getJobDocsDetails = async () => {
    const { data } = await getjobdocument(jobProxy.jobid);
    if (data && data.status === 1) {
      setJobDocs(data.data);
    }
  };
  useEffect(() => {
    getDocsIfAlreadyUploaded();
    getJobDocsDetails();
  }, []);
  const newArrayObj = JobDocs.reduce((acc, current, index) => {
    return acc.concat({ ...current, value: retrivedJobDocs[index] });
  }, []);

  return (
    <MainLayout>
      {routeData && routeData?.state?.source === "track" ?
        <div className="breadcrumb">
          <p onClick={() => navigate("/track-applications")} style={{ cursor: "pointer" }}>
            {t("Track Applications")}
          </p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "-10px" }}
          />
          <p style={{ color: "#D04C8D" }}>{t("Supporting Document")}</p>
        </div>
        :
        null
      }
      <MainWrapper>
        <Doc>{t("The Document Uploaded")}</Doc>
        {newArrayObj.map((x) => {
          return (
            <MainDiv>
              <Row>
                <Col lg={3}>
                  <>
                    <Content>{x?.document_type}</Content>
                  </>
                </Col>
                <Col lg={9}>
                  <>
                    <ContentLink href={x?.value?.file_path} target="_blank">
                      {x?.value?.file_name}
                    </ContentLink>
                  </>
                </Col>
              </Row>
            </MainDiv>
          );
        })}
      </MainWrapper>
    </MainLayout>
  );
};

export default ViewDocument;
