import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { customLabelsPlugin } from "../../utilities/commonFunctions";
import { cloneDeep } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
  cutout: "75%",
  borderRadius: [30, 30],
  aspectRatio: 1,
  rotation: 180,
  layout: {
    padding: {
      left: 10,
      right: 10,
      top: 10,
      bottom: 10,
    },
    margin: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: true,
      position: "top",
      width: 40,
    },
    datalabels: {
      display: false,
    },
    tooltip: {
      enabled: false,
    },
  },
  title: {
    display: false,
  },
};

const getData = (end, currentValue, labels) => ({
  labels: labels || [],
  datasets: [
    {
      data: [currentValue, end - currentValue],
      backgroundColor: ["rgba(54, 162, 235, 1)", "#DDE3EE"],
      borderColor: ["rgba(54, 162, 235, 1)", "#DDE3EE"],
      borderWidth: [0.5, 0],
      hoverBackgroundColor: ["rgba(54, 162, 235, 1)", "#DDE3EE"],
    },
  ],
});

const getPlugins = (
  start,
  end,
  gradientStart,
  gradientEnd,
  currentValue,
  customTitle,
  fontSize,
  skipTitle
) => ({
  id: "curves",
  beforeDraw(chart) {
    const {
      ctx,
      chartArea: { left, right, height },
    } = chart;
    ctx.save();
    const arc = chart.getDatasetMeta(0).data[0];

    const gradient = ctx.createLinearGradient(
      chart.width / 2,
      0,
      chart.width / 2,
      chart.height
    );
    gradient.addColorStop(0.307, gradientStart);
    gradient.addColorStop(0.7521, gradientEnd);
    ctx.strokeStyle = "#DDE3EE";
    ctx.lineWidth = arc.outerRadius - arc.innerRadius;
    ctx.lineCap = "round";
    const radiusDifference = arc.outerRadius - arc.innerRadius;
    const unfillRadius = arc.innerRadius + radiusDifference / 2;
    const offsetAngle = Math.asin(ctx.lineWidth / 2 / unfillRadius);
    ctx.beginPath();
    ctx.arc(
      arc.x,
      arc.y,
      arc.innerRadius + ctx.lineWidth / 2,
      0 - offsetAngle,
      Math.PI * 2,
      false
    ); // outer (filled)
    ctx.stroke();
    if (!skipTitle) {
      ctx.font = `600 ${fontSize}em General Sans`;
      ctx.fillStyle = gradient;
      ctx.textAlign = "center";
      ctx.verticalAlign = "center";
      ctx.fillText(customTitle || currentValue, arc.x, arc.y);
    }
  },
});

export default function FullGaugeChart({
  gradient: [gradientStart, gradientEnd],
  currentValue,
  optionsOverride,
  customTitle = null,
  labels = null,
  fontSize = null,
  showArrowLabel = false,
  showLegend = false,
  skipTitle = false,
}) {
  const [start, end] = [0, 100];
  const data = getData(end, currentValue, labels);
  let finalOptions = cloneDeep(defaultOptions);
  if (optionsOverride) {
    finalOptions = { ...finalOptions, ...optionsOverride };
  }
  finalOptions.plugins.legend.display = showLegend;
  const curves = getPlugins(
    start,
    end,
    gradientStart,
    gradientEnd,
    currentValue,
    customTitle,
    fontSize,
    skipTitle
  );
  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState(data);

  React.useEffect(() => {
    const chart = chartRef.current;
    const ctx = chart && chart.ctx;

    if (ctx) {
      /*** Gradient ***/
      const gradient = ctx.createLinearGradient(
        chart.width / 2,
        0,
        chart.width / 2,
        chart.height
      );
      gradient.addColorStop(0, gradientStart);
      gradient.addColorStop(1, gradientEnd);
      setChartData({
        ...data,
        datasets: data.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: [gradient, dataset.backgroundColor[1]],
          hoverBackgroundColor: [gradient, dataset.hoverBackgroundColor[1]],
        })),
      });
    }
  }, []);

  const plugins = [curves];

  if (showArrowLabel) {
    finalOptions.plugins.customLabels = {
      labelColor: "#525252",
      arrowColor: "#525252",
      font: "14px 'General Sans-bold'",
    };
    plugins.push(customLabelsPlugin);
  }

  return (
    <Doughnut
      data={chartData}
      ref={chartRef}
      options={finalOptions}
      plugins={plugins}
    />
  );
}
