import styled from "styled-components";


const IntroSectionWrapper = styled.div`
  margin-top: -80px;
`;
const IntroSectionHeader = styled.h2`
  font-weight: 500;
  font-size: 30px;
  margin-bottom: 0px;
  margin-top: 8%;
`;
const IntroSectionSubHeader = styled.div`
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #707070;
  margin: 0px;
`;
export default function IntroSection({ name, description }) {
  return (
    <IntroSectionWrapper>
      <IntroSectionHeader>{name}</IntroSectionHeader>
      <IntroSectionSubHeader
        dangerouslySetInnerHTML={{ __html: description }}
      ></IntroSectionSubHeader>
    </IntroSectionWrapper>
  );
}
