import React,{useState} from 'react'
import styled from 'styled-components';
import Profile from '../../components/talentProfile/profile'
import MainLayout from '../../layouts/mainLayout/mainLayout'
import rightarrow from "../../assets/icons/arrow_right.png";
import Card from '../../components/talentProfile/card';
import EmployementDetails from '../../components/talentProfile/employementDetails';
import InternshipDetails from '../../components/talentProfile/internshipDetails';
import EducationDetails from '../../components/talentProfile/educationDetails';
import EventsProjects from '../../components/talentProfile/eventProjects';
import Skills from '../../components/talentProfile/skills';
import OceanReport from '../../components/applicantsComparision/oceanReportForProfile';
import { SectionHeading } from '../../components/applicantsComparision/common';
import EmployabilityCharts from '../../components/talentProfile/employabilityCharts';
import FutureOfWork from '../../components/talentProfile/futureOfWork';
import EnglishProficiency from '../../components/talentProfile/englishProficiency';
import ProfilePopup from '../../components/talentProfile/popup';
import { useTranslation } from "react-i18next";

const MainText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 38px;
color: #000000;
margin-bottom:32px;
`;

const ShortButton = styled.button`
  width: 158px;
  height: 36px;
  margin-left:12px;
  background: #FFD1E8;
  border: 1px solid #FFA9D3;
  border-radius: 64px;
`;
const MainWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 55px;
  padding:24px;
`;

export default function TalentProfile() {
    const { t, i18n } = useTranslation();
    const [popup, setPopup] = useState(false)
    const setOpen = () => {
        setPopup(false)
    }
    const open = ()=>{
        setPopup(true)
    }
    return (
        <>
            {popup ? <ProfilePopup isOpen={popup} setOpen={setOpen}  /> : ""}
            <MainLayout>
                <div className="breadcrumb">
                    <p>{t("Talent Search")}</p>
                    <img src={rightarrow} alt="img" style={{ height: "14px", marginTop: "-10px" }} />
                    <p>{t("Graduate Talent Finder")}</p>
                    <img src={rightarrow} alt="img" style={{ height: "14px", marginTop: "-10px" }} />
                    <p style={{ color: "#D04C8D" }}>{t("Profile")}</p>
                </div>
                <div style={{ display: "flex" }}>
                    <MainText>{"Ali muthu ahok"}</MainText>
                    <ShortButton>{t("Shorlisted")}</ShortButton>
                </div>
                <p style={{textAlign:"center",fontWeight:"bold",fontSize:"30px"}}>This is a static temporary page. It is dependent on university R1.</p>

                <MainWrapper>
                    <Profile open={open}></Profile>
                    <Card></Card>
                    <EmployementDetails></EmployementDetails>
                    <InternshipDetails></InternshipDetails>
                    <EducationDetails></EducationDetails>
                    <EventsProjects></EventsProjects>
                    <Skills></Skills>
                    <br />
                    <hr />
                    <SectionHeading style={{ width: "220px" }}> {"Assessments Report"}</SectionHeading>
                    <EmployabilityCharts></EmployabilityCharts>
                    <FutureOfWork></FutureOfWork>
                    <EnglishProficiency></EnglishProficiency>
                    <OceanReport />
                </MainWrapper>
            </MainLayout>
        </>
    )
}
