import { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateLoader } from "../../pages/myCompany/reducer";
import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schemaObj } from "./staticContent";
import ErrorField from "../errorField/errorField";
import {
  onUpdateCompanyDatas,
  getCompanyData,
  getOrganisationTypesMasterList,
  getSectorMasterList,
  onCompanyLogoUpdate,
} from "../../services/apiCalls";
import Logo from "../../assets/companylogosettings.png";

import { updateCurrentlySelectedCompanyData } from "../../pages/myCompany/reducer";
import Loader from "../loader/loader";
import Dropdown from "../dropdown/dropdown";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ModalWrapper = styled.div`
  padding: 10px;
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 30px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;

const Uploader = styled.div`
  position: relative;
  width: "100%";
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  > LinkText {
    cursor: pointer !important;
  }
`;
const ImageWrapper = styled.div`
  text-align: center;
`;
const Image = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 50%;
  margin-bottom: 20px;
`;
const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  text-transform: capitalize;
  cursor: pointer !important;
`;
// const statuses = ["Primary", "Secondary"];

export default function UpdateCompanyIntroSection(props) {
  const companyOrgs = useSelector((state) => state.company.companyData);
  const company = props.companyDetails;
  const org = useSelector((state) => state.org.orgData);
  const onClose = props.onClose;
  const { t } = useTranslation();
  const company_status = company.status; //companyOrgs[props.companyIdx].user_details.status;

  const defaultVal = {
    name: company && company.name ? company.name : "",
    ssm_number: company && company.ssm_number ? company.ssm_number : "",
    sector: company && company.sector ? company.sector : "",
    type_of_org: company && company.type_of_org ? company.type_of_org : "",
    sector_abbr: company && company.sector_abbr ? company.sector_abbr : "",
    type_of_org_abbr:
      company && company.type_of_org_abbr ? company.type_of_org_abbr : "",
    moto: company && company.moto ? company.moto : "",
    org_number: company && company.org_number ? company.org_number : "",
  };

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const editLoading = useSelector((state) => state.company.loading);
  // const [options1, setOptions1] = useState({});

  const [organisationTypesList, setOrganisationTypes] = useState([]);
  const [selectedOrganisation, setSelectedOrganisation] = useState([]);

  const [sectorList, setSectorList] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);

  const [imageUrl,setImageUrl] = useState(company.logo_path)

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schemaObj),
  });

  const uploadFile = async (event) => {
    const formdata = new FormData();
    let maxFileSize = 1024 * 1024 * 5; //5mb
    if (event.target.files[0].size > maxFileSize) {
      toast.error(t("File size exceeds maximum limit."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      formdata.append("logo_path", event.target.files[0]);

    const { data, status } = await onCompanyLogoUpdate({
      code: companyOrgs[props.companyIdx].org_code,//org?.additional_info?.org_code,
      data: formdata,
    });
    setImageUrl(data?.data?.logo_path)
    if (status === 200) getData();
  }
  };

  const onUploaded = async (uploadData) => {
    const { status } = uploadData;
    if (status === 1) {
      getData();
    }
  };

  const formVal = watch();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    debugger
    setLoading(true);
    const { data } = await onUpdateCompanyDatas({
      code: companyOrgs[props.companyIdx].org_code, //org?.additional_info?.org_code,
      data: { ...formVal },
    });
    if (data && data.status === 1) {
      toast.success(t(`Details updated successfully.`), {
        position: toast.POSITION.TOP_RIGHT,
      });
      getData();
      dispatch(updateLoader(!editLoading));
      props.onClose();
      setFormErrors("");
    } else {
      toast.error(`${t(data.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const getData = async () => {
    const { data } = await getCompanyData({
      code: companyOrgs[props.companyIdx].org_code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      //dispatch(updateCurrentlySelectedCompanyData({ ...data.data }))
    } else {
      setLoading(false);
    }
  };

  const dropdownChange = (name, val) => {
    setValue(name, val.label);
    switch (name) {
      case "type_of_org":
        // dispatch(
        //   updateCompanyData({
        //     ...formVal,
        //     ...defaultVal,
        //     [name]: val.label,
        //   })
        // )
        setSelectedOrganisation(val);
        break;
      case "sector":
        // dispatch(
        //   updateCompanyData({
        //     ...formVal,
        //     ...defaultVal,
        //     [name]: val.label,
        //   })
        // )
        setSelectedSector(val);
        break;
      default:
        break;
    }
  };

  const getOrganisationTypes = async () => {
    const { data, status } = await getOrganisationTypesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const typeOfOrglist = data.data;
        const Organisation = typeOfOrglist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setOrganisationTypes(Organisation);
        if (formVal.type_of_org) {
          if (isNaN(formVal.type_of_org) === false) {
            var selTypeOfOrglist = Organisation.filter(
              (x) => x.label === formVal.type_of_org_abbr
            );
          } else {
            selTypeOfOrglist = Organisation.filter(
              (x) => x.label === formVal.type_of_org
            );
          }

          if (selTypeOfOrglist.length > 0) {
            selTypeOfOrglist = selTypeOfOrglist[0];
            setValue("type_of_org", selTypeOfOrglist.label);
          }
          setSelectedOrganisation(selTypeOfOrglist);
          // if (formVal.moto) {
          //   let selTypeOfOrglist = Organisation.filter(
          //     (x) => x.label === formVal.type_of_org
          //   );
          //   if (selTypeOfOrglist.length > 0) {
          //     selTypeOfOrglist = selTypeOfOrglist[0];
          //     setValue("type_of_org", selTypeOfOrglist.label);
          //   }
          //   setSelectedOrganisation(selTypeOfOrglist);
          // } else {
          //   let selTypeOfOrglist = Organisation.filter(
          //     (x) => x.value === formVal.type_of_org
          //   );
          //   if (selTypeOfOrglist.length > 0) {
          //     selTypeOfOrglist = selTypeOfOrglist[0];
          //     setValue("type_of_org", selTypeOfOrglist.value);
          //   }
          //   setSelectedOrganisation(selTypeOfOrglist);
          // }
        }
      }
    }
  };
  const getSector = async () => {
    const { data, status } = await getSectorMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Sectorlist = data.data;
        const sector = Sectorlist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setSectorList(sector);
        if (formVal.sector) {
          if (isNaN(formVal.sector) === false) {
            var selSector = sector.filter(
              (x) => x.label === formVal.sector_abbr
            );
          } else {
            selSector = sector.filter((x) => x.label === formVal.sector);
          }
          if (selSector.length > 0) {
            selSector = selSector[0];
            setValue("sector", selSector.label);
          }
          setSelectedSector(selSector);
          // if (formVal.moto) {
          //   let selSector = sector.filter((x) => x.label === formVal.sector);
          //   if (selSector.length > 0) {
          //     selSector = selSector[0];
          //     setValue("sector", selSector.label);
          //   }
          //   setSelectedSector(selSector);
          // } else {
          //   let selSector = sector.filter((x) => x.value === formVal.sector);
          //   if (selSector.length > 0) {
          //     selSector = selSector[0];
          //     setValue("sector", selSector.value);
          //   }
          //   setSelectedSector(selSector);
          // }
        }
      }
    }
  };
  useEffect(() => {
    getOrganisationTypes();
    getSector();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <CustomModal
      open={props.open}
      onCloseModal={props.onClose}
      styles={{ modal: { overflowY: "unset" } }}
    >
      <ModalWrapper>
        <Grid container>
          <Grid item lg={3}>
            <ImageWrapper>
              <Image
                src={imageUrl}
                alt="company logo"
              />
              <Uploader>
                <LinkText>{t("Change Picture")}</LinkText>
                <input
                  onChange={uploadFile}
                  accept="image/x-png,image/jpeg"
                  type="file"
                />
              </Uploader>
              <p style={{ fontSize: "12px", color: "gray" }}>
                {t("The maximum image size limit is 5 MB")}
              </p>
            </ImageWrapper>
          </Grid>
          <Grid item lg={9}>
            <h3 style={{ width: "auto" }}>{company && company.name}</h3>
            <hr />
            <FormWrapper>
              {company_status != "accepted" ? (
                <>
                  <SingleField
                    style={{ display: "flex", alignItems: "space-between" }}
                  >
                    <Grid container alignItems="center">
                      <Grid item lg={3}>
                        <FormLabel>Organisation Name</FormLabel>
                      </Grid>
                      <Grid item lg={9}>
                        <Controller
                          style={{}}
                          name="name"
                          control={control}
                          render={({ field }) => (
                            <Input {...field} placeholder={company.name} />
                          )}
                        />
                        {errors.name && (
                          <ErrorField err={errors.name.message} />
                        )}
                      </Grid>
                    </Grid>
                  </SingleField>

                  <SingleField
                    style={{ display: "flex", alignItems: "space-between" }}
                  >
                    <Grid container alignItems="center">
                      <Grid item lg={3}>
                        <FormLabel>SSM Number</FormLabel>
                      </Grid>
                      <Grid item lg={9}>
                        <Controller
                          style={{}}
                          name="ssm_number"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={company.ssm_number}
                            />
                          )}
                        />
                        {errors.ssm_number && (
                          <ErrorField err={errors.ssm_number.message} />
                        )}
                      </Grid>
                    </Grid>
                  </SingleField>
                </>
              ) : (
                <></>
              )}

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Motto")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      style={{}}
                      name="moto"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={company.moto} />
                      )}
                    />
                    {errors.moto && <ErrorField err={errors.moto.message} />}
                  </Grid>
                </Grid>
              </SingleField>

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Type of Organisation")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="type_of_org"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          search={true}
                          options={organisationTypesList}
                          placeholder={t("Select Organisation")}
                          val={selectedOrganisation?.value}
                          label={selectedOrganisation?.label}
                          change={(val) => dropdownChange("type_of_org", val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.type_of_org && (
                      <ErrorField err={t(errors.type_of_org.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Sector")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      name="sector"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          bgGrey
                          search={true}
                          placeholder="sector"
                          change={(val) => dropdownChange("sector", val)}
                          options={sectorList}
                          val={selectedSector?.value}
                          label={selectedSector?.label}
                          {...field}
                        />
                      )}
                    />
                    {errors.sector && (
                      <ErrorField err={t(errors.sector.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>

              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                  marginRight: "1%",
                }}
              >
                {formErrors && <ErrorField err={formErrors} />}
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    name={t("Confirm")}
                    onClick={onSubmit}
                  />
                )}
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    marginLeft: "10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
