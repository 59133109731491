import * as yup from "yup";

export const schema = yup.object().shape({
  //overview: yup.string().required(),
  website: yup.string().url("Enter correct url!"),
  //founded: yup.string().required(),
  bsn_nature: yup.string().required('Nature of Business is required.'),
  bsn_address: yup.string().required('Address is required.'),
  bsn_country_code: yup.string().required('Postcode is required.'),
  bsn_country: yup.string().required('Country is required.'),
  bsn_state: yup.string().required('State is required.'),
  bsn_city: yup.string().required('City is required.'),
  //org_size: yup.string().required(),
});

export const schemaObj = yup.object().shape({
  moto: yup.string().required("Motto is a required field"),
  sector: yup.string().required(),
  type_of_org: yup.string().required(),
  description:yup.string().required("Description is required"),
  // org_email: yup.string().email("Email is required").required(),
  article_url: yup.string()
        .matches(
            /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
            'Enter correct url!'
        )
        .required('Please enter correct website'),
  // org_number: yup.string().required(),
});
