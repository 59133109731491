import { useEffect, useState } from "react";
import DataTable, { createTheme } from "react-data-table-component";
import { useLocation, useNavigate } from "react-router-dom";
import { Colors } from "../../utilities/colors";
import styled from "styled-components";
import {
  DataTableWrapper,
  Heading,
  LoaderBackdrop,
  LoaderWrapper,
} from "../employeeManagement/commonStyles";
import { ReactComponent as GreenCircle } from "../../assets/svg/greenCircle.svg";
import { ReactComponent as RedCircle } from "../../assets/svg/redCircle.svg";
import { ReactComponent as RightIcon } from "../../assets/svg/check_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross_icon.svg";
import { ReactComponent as ReminderIcon } from "../../assets/svg/reminder.svg";
import { ReactComponent as ViewIcon } from "../../assets/svg/view.svg";
import { ReactComponent as GreenViewIcon } from "../../assets/svg/greenview.svg";

import { ReactComponent as DeleteIcon } from "../../assets/svg/trash.svg"
import { ReactComponent as EditIcon } from "../../assets/svg/pencil.svg";

import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../reducers/employeeModalReducer";
import { updateModal as updateStudentModal } from "../../reducers/studentModalReducer";

import { ReactComponent as Loader } from "../../assets/svg/loader.svg";
import EventBus from "../../utilities/eventBus";
import userManagementService from "../../services/employeeManagement.service";
import {
  resetAll,
  setPage,
  setPerPage,
  setSearch,
  setSortDirection,
  setSortField,
  setRegistration,
  setAssessment,
} from "../../reducers/employeeManagementReducer";
import { getUsersByUniversityManagment } from "../../actions/employeeManagement";
import { Col, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { ConvertDate } from "./utils/date-converter";
import moment from "moment";
import { useTranslation } from "react-i18next";
import AlertModal from "../../components/alertModal/alertModal";
import Button from "../../components/button/button";
import { Grid } from "@material-ui/core";

const CustomHeading = styled(Heading)`
  color: #acacac;
`;

const TableButtonsNew = styled.div`
  display: flex;
`;

const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const columnStyling = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "92px",
  color: "#202020",
  margin: " 0.5rem 1.5rem 0.5rem 0",
  justifyContent: "flex-start",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};

const columnStylingLast = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "92px",

  color: "#202020",
  margin: " 0.5rem 1.5rem 0.5rem 0",
  justifyContent: "flex-end",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-end",
    padding: "0.5rem 0 0 0.5rem",
  },
};
const columnStylingBigger = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "200px",
  color: "#202020",
  margin: " 1.5rem 0",
  textAlign: "left",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};

const Icon = styled.img`
  margin: auto;
  cursor: pointer;
  display: block;
`;

const customStyles = {
  style: {
    fontSize: "16px!important",
    width: "1px",
    overflow: "scroll",
  },
};

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;

function createUserTableTheme(backgroundColor) {
  createTheme("solarized", {
    divider: {
      default: backgroundColor || "#FFFFFF",
    },
  });
}

const dataTableLoader = (
  <DataTableLoaderWrapper>
    <LoaderBackdrop />
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  </DataTableLoaderWrapper>
);

function EmployeeDataTable({
  setModalMode,
  fromUserManagement = false,
  backgroundColor = "#FFFFFF",
  id,
  t,
}) {
  const dispatch = useDispatch();
  const location = useLocation();

  const totalRows = useSelector(
    (state) => state.employeeManagementReducer.totalRows
  );
  const perPage = useSelector(
    (state) => state.employeeManagementReducer.perPage
  );
  const data = useSelector((state) => state.employeeManagementReducer.data);
  const page = useSelector((state) => state.employeeManagementReducer.page);

  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [orgCode, setOrgCode] = useState(undefined);
  const [companyid, setCompanyid] = useState("");

  useEffect(() => {
    getOrgCode();
  });

  console.log("data---------------------", data);

  useEffect(() => {}, [orgCode]);

  useEffect(() => {
    if (!companyid || companyid.length === 0) {
      if (company && company.id) {
        setCompanyid(company.id);
      }
    } else {
      if (companyid !== company.id) {
        getOrgCode();
        setCompanyid(company.id);
      }
    }
  }, [company]);

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }

  const [loading, setLoading] = useState(false);
  const [gridLoading, setGridLoading] = useState(true);
  const [countRef, setCountRef] = useState(Math.random());

  const [show, setShow] = useState(false);
  const [fullName, setFullName] = useState();

  useEffect(() => {
    createUserTableTheme();
  });

  const getUsersAction = async (codeorg) => {
    setLoading(true);
    dispatch(getUsersByUniversityManagment(codeorg))
      .unwrap()
      .then(() => {
        setLoading(false);
        setGridLoading(false);
      });
  };

  useEffect(() => {
    getOrgCode();
    if (orgCode) {
      EventBus.on(
        "userSearchEvent",
        ({ search, registration, is_assessment_done, codeorg }) => {
          dispatch(setSearch(search));
          dispatch(setRegistration(registration));
          dispatch(setAssessment(is_assessment_done));

          getUsersAction(codeorg);
        }
      ); // resetForm(initialState);
      // setLoading(false);
      // setModal(true);

      EventBus.on(
        "userActivateDeactivateEvent",
        ({ userId, activate, codeorg }) => {
          var status = activate ? "active" : "inactive";
          userManagementService
            .updateUniversityStudentById(userId, { change_status_to: status })
            .then(() => {
              getUsersAction(codeorg);
            });
        }
      );
      // resetForm(initialState);
      // setLoading(false);
      // setModal(true);
      EventBus.on("userActivateDeactivateCancelEvent", ({ codeorg }) => {
        setCountRef(Math.random());
        getUsersAction(codeorg);
      });

      EventBus.on("userAddEvent", ({ codeorg }) => {
        setCountRef(Math.random());
        getUsersAction(codeorg);
      });

      return () => {
        setCountRef(Math.random());
        setLoading(null);
        setGridLoading(null);
        EventBus.remove("userSearchEvent");
        EventBus.remove("userActivateDeactivateCancelEvent");
        EventBus.remove("userActivateDeactivateEvent");
        EventBus.remove("userAddEvent");
      };
    }
  }, [orgCode]);

  useEffect(() => {
    dispatch(resetAll());
    getUsersAction(orgCode);
  }, [location.pathname, orgCode, companyid]);

  const handlePageChange = (page) => {
    dispatch(setPage(page));
    getUsersAction(orgCode);
  };

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(setPage(page));
    dispatch(setPerPage(newPerPage));
    getUsersAction(orgCode);
  };

  const handleSort = (column, sortDirection) => {
    const sortMap = {
      Name_asc: "full_name",
      Name_desc: "full_name",
      Email_asc: "email",
      Email_desc: "email",
    };
    dispatch(setSortField(sortMap[`${column.name}_${sortDirection}`]));
    dispatch(setSortDirection(sortDirection === "desc" ? "-" : ""));
    getUsersAction(orgCode);
  };

  const onChange = (value, row) => {
    setModalMode(value.value === "active" ? "activate" : "deactivate");
    dispatch(updateModal({ modalIsOpen: true, cache: row.id }));
  };

  const ondeletemployee = (value, row) => {
    dispatch(
      updateStudentModal({
        delmodal: true,
        cache: row,
        full_name: row.full_name,
      })
    );
  };

  const onar = (row) => {
    dispatch(updateStudentModal({ armodal: true, cache: row }));
  };

  const onChangeStudentEdit = (value, row, index) => {
    dispatch(
      updateStudentModal({
        modalIsOpen: true,
        cache: row,
        mode: "normal",
        id: row.id,
      })
    );
  };

  const deleteAction = (id, user_id) => {
    setModalMode("delete");
    dispatch(updateModal({ modalIsOpen: true, cache: { id, user_id } }));
  };

  function checkIsValidDate(date) {
    if (date === undefined || date === "-") {
      return false;
    }
    return true;
  }

  function createDateFromString(dateStr) {
    if (!(dateStr === undefined || dateStr === "-")) {
      let parts = dateStr.split("/");
      let d = parts[2] + "-" + parts[1] + "-" + parts[0];
      let date = new Date(d);
      date.setDate(date.getDate() + 30);
      let dateString = moment(date).format("YYYY-MM-DD");
      return dateString;
    }
  }

  const sendReminder = async (email, joinedDate, assessmentName) => {
    let joined_date = checkIsValidDate(joinedDate)
      ? createDateFromString(joinedDate)
      : undefined;
    let assessment = assessmentName === "-" ? undefined : assessmentName;
    const res = await userManagementService.sendSingleReminder(orgCode, {
      email: email,
      type_of_reminder: "assessment",
      date_joined: joined_date,
      name_of_assessment: assessment,
    });
    if (res.status === 200) {
      EventBus.dispatch("userAddEvent", { codeorg: orgCode });
      toast.success(t(res.data.message));
    }
  };
  const navigateRow = (id) => {
    navigate(`/employeeManagement/student/${id}`);
  };
  const navigate = useNavigate();
  const onRowClicked = (row) => {
    if (!row.user) {
      setShow(true);
      setFullName(row.full_name);
    } else {
      navigateRow(row.user);
    }
  };
  const ClosePopup = () => {
    setShow(false);
  };
  const content = (name) => {
    return (
      <>
        <Grid style={{ paddingLeft: "7%", paddingRight: "7%" }}>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Message>
              {t(`Employee ${name} not registered in Mynext Talent Portal`)}
            </Message>
            <Message></Message>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              style={{ width: "25%" }}
              name={t("Ok")}
              onClick={ClosePopup}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const columns = [
    {
      name: t("Employee Name"),
      selector: (row) => row.full_name + row.email,
      sortable: true,
      style: columnStylingBigger,
      center: true,
      width: "250px",
      cell: (row, index) => {
        return (
          <>
            <Row
              style={{ position: "absolute", left: "0" }}
              onClick={() => onRowClicked(row)}
            >
              <Col lg="">
                <span
                  style={{ textTransform: "capitalize", marginLeft: "0px" }}
                >
                  {row.full_name}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{row.email}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: t("Department"),
      selector: (row) => row.department,
      sortable: true,
      style: columnStyling,
      center: true,
    },
    {
      name: t("Position"),
      selector: (row) => row.position,
      sortable: true,
      style: columnStyling,
      center: true,
    },
    {
      name: t("Date of Joining"),
      selector: (row) =>
        ["-", "", null].includes(row.date_joined)
          ? "-"
          : ConvertDate(row.date_joined, "dd/mm/yyyy"),
      sortable: true,
      style: columnStyling,
      center: true,
      width: "150px",
    },
    {
      name: t("Assessments"),
      selector: (row) => row.assessment,
      sortable: true,
      style: columnStyling,
      center: true,
    },
    {
      name: t("Reminder Last Sent"),
      selector: (row) =>
        row.reminder === "-"
          ? row.reminder
          : ConvertDate(row.reminder, "dd/mm/yyyy"),
      sortable: true,
      width: "150px",
      style: columnStyling,
      center: true,
    },
    {
      name: t("Actions"),
      selector: (row) => row.status,
      sortable: false,
      style: columnStyling,
      center: true,
      // width: 100,
      cell: (row, index) => {
        if (!fromUserManagement) {
          return (
            <div>
              <span style={{ marginRight: "10px" }}>
                {row.status === "active" ? <GreenCircle /> : <RedCircle />}
              </span>
              <span>{row.status === "active" ? "Active" : "Deactivated"}</span>
            </div>
          );
        }
        const CustomOption = (props) =>
          !props.isDisabled ? (
            <div
              {...props?.innerProps}
              style={{
                padding: 10,
                ...(props?.selectProps?.value?.value === props?.value
                  ? {
                      pointerEvents: "none",
                      cursor: "none",
                      backgroundColor: "lightgray",
                      opacity: 0.8,
                    }
                  : {}),
              }}
            >
              <span style={{ marginRight: 10 }}>
                {props.value === "active" ? <GreenCircle /> : <RedCircle />}
              </span>
              <span>{props.label}</span>
            </div>
          ) : null;
        const CustomInput = (props) => (
          <div
            style={{
              color: "inherit",
              gridArea: "1/1/2/3",
              maxWidth: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
              boxSizing: "border-box",
              fontSize: "medium",
            }}
          >
            <span>{props.selectProps.value.label}</span>
          </div>
        );

        return (
          <TableButtonsNew>
            <PencilButton>
              {row.is_assessment_done === 1 ? <GreenViewIcon /> : <ViewIcon />}
            </PencilButton>
            {/* <TrashButton >
              <EditIcon 
                // onClick={(value) => ondeletemployee(value, row)} // replace with onEditEmployee
              />
            </TrashButton> */}
            <TrashButton>
              <ReminderIcon
                color={Colors.greyText}
                onClick={() =>
                  sendReminder(row.email, row.joined_date, row.assessment)
                }
              />
            </TrashButton>
            <PencilButton>
              <EditIcon
                color={Colors.greyText}
                onClick={(value) => onChangeStudentEdit(value, row, index)}
              />
            </PencilButton>
            <TrashButton >
              <DeleteIcon
                color={Colors.greyText}
                onClick={(value) => ondeletemployee(value, row)}
              />
            </TrashButton>
          </TableButtonsNew>
        );
      },
    },
    {
      name: t("mynext Registration"),
      selector: (row) => row.is_registered,
      sortable: false,
      width: "150px",
      style: columnStyling,
      center: true,
      cell: (row, index) => {
        return (
          <>
            {row.status === "pending" ? (
              <div onClick={() => onar(row)}>
                <>
                  <>
                    <CrossIcon height={25} />
                  </>
                  &nbsp;
                  <>
                    <RightIcon height={25} />{" "}
                  </>
                </>
              </div>
            ) : (
              <>
                {row.is_registered === 1 ? (
                  <RightIcon height={25} />
                ) : (
                  <CrossIcon height={25} />
                )}
              </>
            )}
          </>
        );
      },
    },
  ];
  const paginationOptions = {
    rowsPerPageText: t("Rows per page:"),
  };
  return (
    <>
      <DataTableWrapper className="users-management">
        <DataTable
          onRowClicked={onRowClicked}
          columns={columns}
          data={data}
          theme="solarized"
          sortServer
          onSort={handleSort}
          progressPending={gridLoading}
          customStyles={customStyles}
          progressComponent={
            <div
              style={{
                minHeight: "586px",
                backgroundColor: `${backgroundColor} !important`,
              }}
            >
              <DataTableLoaderWrapper>
                <LoaderWrapper>
                  <Loader />
                </LoaderWrapper>
              </DataTableLoaderWrapper>
            </div>
          }
          noDataComponent={
            <div
              style={{
                minHeight: "586px",
                backgroundColor: `${backgroundColor} !important`,
              }}
            >
              <DataTableLoaderWrapper>
                <LoaderWrapper>
                  <CustomHeading>{t("No Results Found")}</CustomHeading>
                </LoaderWrapper>
              </DataTableLoaderWrapper>
            </div>
          }
          pagination={fromUserManagement}
          paginationServer={fromUserManagement}
          paginationTotalRows={totalRows}
          onChangeRowsPerPage={handlePerRowsChange}
          onChangePage={handlePageChange}
          paginationComponentOptions={paginationOptions}
        />
        {loading && typeof data !== "undefined" && dataTableLoader}
      </DataTableWrapper>
      <AlertModal show={show} content={content(fullName)} />
    </>
  );
}

export default EmployeeDataTable;
