import React from "react";
import styled from "styled-components";
import Input from "../input/input";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "./interview.css";
import { useState } from "react";
import { useSnapshot } from "valtio";
import { mycalendarProxy } from "../../pages/myCalendar/mycalendarproxy";
import Button from "../button/button";
import { useEffect } from "react";
import { changApplications } from "../../services/apiCalls";
import { toast } from "react-toastify";

import AlertInterviewModal from "../alertModal/alertInterviewModal";

import { t } from "i18next";
import AlertChangeModal from "../alertModal/alertChangeModal";
import AlertSaveModal from "../alertModal/alertSaveModal";
import { useTranslation } from "react-i18next";
const CardsWrapper = styled.div`
  position: absolute;
  width: 867px;
  height: max-content;
  // left: 30%;
  top: 20%;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;
  padding-bottom: 35px;
  z-index: 9998;
  @media (max-width: 500px) {
    width: 386px;
    position: relative;
    padding-top: 10px;
    // left: 3%;
    height: max-content;
    padding-bottom: 65px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 86px;
  margin: 10px;
  display: flex;
`;
const Icon = styled.div`
  position: relative;
  top: 5px;
  left: 590px;
  @media (max-width: 500px) {
    right: 120px;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 20px 50px;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 50px 0px;
  margin: -10px 0 0 -135px;
`;
const FormLabel = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  margin: 20px 0 0 10px;
`;
const Wrapper = styled.div`
  position: relative;
  width: 85%;
  // height: 128px;
  left: 77px;
  //bottom: 20px;
  Input {
    height: 40px;
  }
  @media (max-width: 500px) {
    left: auto;
    margin-left: 10px;
    Input {
      height: 40px;
      width: 70%;
      margin: 10px 0 0px -10px;
    }
  }
`;

const Wrapper2 = styled.div`
  position: relative;
  width: 501px;
  // height: 128px;
  left: 77px;
  top: 10px;
  magin: 10px 0 0 0;
  @media (max-width: 500px) {
    position: relative;
    /* width: 501px; */
    // height: 128px;
    left: 19px;
    // top: 23%;
    Input {
      height: 40px;
      width: 70%;
    }
  }
`;
const ButtonWrapper = styled.div`
  button {
    width: 100px;
    height: 39px;
    background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
    border-radius: 128px;
    border: none;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    top: 14px;
  }
  button:hover {
    background: linear-gradient(90deg, #504f8c 0%, #fa4aa1 100%);
  }
  @media (max-width: 500px) {
    position: relative;
    right: 9%;
    button {
      width: 100px;
      height: 39px;
      background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
      border-radius: 128px;
      border: none;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      position: relative;
      top: 14px;
    }
  }
`;
const Span = styled.span`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;
const ButtonWrapper3 = styled.div`
  // background: #f8f8f8;
  // border-radius: 83px;
  // width:700px;
  // height: 44px;
  // position: relative;
  // right: 10px;
  // bottom: 10px;
  button {
    width: 130px;
    height: 39px;
    background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
    border-radius: 128px;
    border: none;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    position: relative;
    // top: 8px;
    // left: 530px;
  }
  button:hover {
    background: linear-gradient(90deg, #504f8c 0%, #fa4aa1 100%);
  }
  @media (max-width: 500px) {
    // background: #f8f8f8;
    border-radius: 83px;
    width: 33%;
    height: 44px;
    position: relative;
    left: -8%;
    // top: 180px;
    bottom: 10px;
    button {
      width: 100px;
      height: 28px;
      background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
      border-radius: 128px;
      border: none;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 19px;
      color: #ffffff;
      position: relative;
      top: 8px;
      right: 10px;
    }
  }
`;
const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  // top: 20px;
  left: 0px;
  // bottom:50px;
  button {
    // background: #FFE6F2;
    border-radius: 128px;
    border: 1px solid #d04c8d;
    width: 200px;
    height: 50px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: white;
  }

  button:nth-child(2):hover {
    background: #d04c8d;
    color: white;
  }
  button:nth-child(3):hover {
    background: #d04c8d;
    color: white;
  }
  button:nth-child(2) {
    background: #ffe6f2;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    position: relative;
    left: 200px;
  }
  button:nth-child(3) {
    background: white;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    position: relative;
    left: 220px;
  }
  @media (max-width: 500px) {
    position: relative;
    display: grid;
    left: -50%;
    padding: 2px 1px 2px 9px;
    margin: 1px 6p -1px;
    gap: 10px;
    // top: 55%;
    button {
      position: relative;
      left: 8px;
    }
    button:nth-child(2) {
      left: 0px;
      bottom: 10px;
    }
    button:nth-child(3) {
      left: 0;
    }
  }
`;

const ButtonWrappers = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  gap: 10px;
`;


const InterviewModal = ({ open, onClose, id }) => {
  useSnapshot(mycalendarProxy);
  const [ch, setCh] = useState(0);
  const [title, setTitle] = useState("");
  const [addemailsvalue, setAddemailsvalue] = useState("");
  const [attendeesName, setAttendeesName] = useState("");
  const [addemails, setAddemails] = useState("");
  const [stdate, setSttdate] = useState(null);
  const [sttime, setSttime] = useState(null);
  const [endate, setEntdate] = useState(null);
  const [entime, setEntime] = useState(null);
  const [change, setChange] = useState(0);
  const [doc, setDoc] = useState("");
  const [dis, setDis] = useState(true);
  const [errormsg, setErrormsg] = useState("");
  //const [resetValue, setResetValue] = useState(false);
  const [docvalue, setDocvalue] = useState("");
  const [error, setError] = useState("");
  const { t, i18n } = useTranslation();
  const [doclist, setDoclist] = useState([
    "Resume",
    "Cover Letter",
    "Transcripts",
    "Writing Sample",
  ]);
 const resetValues = () =>{
  //setCh(0);
  setTitle("");
  setAttendeesName("");
  setAddemails("")
  setDoc("");
  setSttdate(null);
  setSttime(null);
  setEntdate(null);
  setEntime(null);
  setAddemailsvalue("");
  setDocvalue("");
  setErrormsg("");
 }
  useEffect(async () => {
    checkdis();
  }, [title, addemails, stdate, sttime, endate, entime, doc, attendeesName]);


  useEffect(() => {
    const currentEvent = mycalendarProxy.applist.find(item => item.id === id);
  
    if (currentEvent) {
      setTitle(currentEvent.interview_title);
      setAttendeesName(currentEvent.name);
      setAddemails(currentEvent.attendees_email);
      setDoc(currentEvent.attendees_documents);
      setDoclist(currentEvent.attendees_documents.split(","));
      setSttdate(currentEvent.interview_startdate);
      setEntdate(currentEvent.interview_enddate);
      setSttime(currentEvent.interview_starttime);
      setEntime(currentEvent.interview_endtime);
    }
  }, [id, mycalendarProxy.applist]);

  useEffect(async () => {

    
    for (let i = 0; i < mycalendarProxy.applist.length; i++) {
      if (mycalendarProxy.applist[i]["id"] == id) {
        let l = mycalendarProxy.applist[i];
        if (ch === 0 && title != l["interview_title"]) {
          setTitle(l["interview_title"]);
          //setResetValue(true);
        }
        if (ch === 0 && attendeesName != l["name"]) {
          setAttendeesName(l["name"]);
          //setResetValue(true);
        }
        if (ch === 0 && addemails != l["attendees_email"]) {
          setAddemails(l["attendees_email"]);
         //setResetValue(true);
        }
        if (ch === 0 && doc != l["attendees_documents"]) {
          setDoc(l["attendees_documents"]);
          setDoclist(l["attendees_documents"].split(","));
         // setResetValue(true);
        }
        if (ch === 0 && stdate != l["interview_startdate"]) {
          setSttdate(l["interview_startdate"]);
          //setResetValue(true);
        }
        if (ch === 0 && endate != l["interview_enddate"]) {
          setEntdate(l["interview_enddate"]);
          //setResetValue(true);
        }
        if (ch === 0 && sttime != l["interview_starttime"]) {
          setSttime(l["interview_starttime"]);
          //setResetValue(true);
        }
        if (ch === 0 && endate != l["interview_endtime"]) {
          setEntime(l["interview_endtime"]);
          //setResetValue(true);
        }

        setCh(1);
      }
    }
  }, [id]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submit = async () => {
    let l = {
      interview_startdatetime:
        stdate.replaceAll("-", "") + sttime.replaceAll(":", ""),
      interview_enddatetime:
        endate.replaceAll("-", "") + entime.replaceAll(":", ""),
      interview_title: title,
      interview_startdate: stdate,
      interview_enddate: endate,
      interview_starttime: sttime,
      interview_endtime: entime,
      attendees_email: addemails,
      attendees_documents: doc,
    };
    if (stdate > endate) {
      toast.error(t("Start Date is greater than End Date"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError(t("Start Date is greater than End Date"));
      return;
    } else if (sttime > entime) {
      toast.error(t("Start Time is greater than end time"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError(t("Start Date is greater than End Date"));
      return;
    }

    const { data } = await changApplications("interview", id, l);
    if (data && data["status"] == 0) {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      // togglePopupModal();
      // togglePopupSaveModal();
      close();
    }
    handleCloseModal();
    toast.success(t("Attendee Information Updated"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const checkdis = () => {
    if (title && addemails && stdate && sttime && endate && entime && doc) {
      if (
        title.length > 0 &&
        addemails.length > 0 &&
        stdate.length > 0 &&
        sttime.length > 0 &&
        endate.length > 0 &&
        entime.length > 0 &&
        doc.length > 0 &&
        errormsg.length === 0
      ) {
        setDis(false);
      } else {
        setDis(true);
      }
    }
  };
  const [opens, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(async () => {
    if (stdate && sttime && endate && entime && addemails.length > 0) {
      let interview_startdatetime =
        stdate.replaceAll("-", "") + sttime.replaceAll(":", "");
      let interview_enddatetime =
        endate.replaceAll("-", "") + entime.replaceAll(":", "");

      let em = addemails.split(",");

      if (
        interview_startdatetime.length == 12 &&
        interview_enddatetime.length == 12
      ) {
        console.clear();
        for (let j = 0; j < em.length; j++) {
          for (let i = 0; i < mycalendarProxy.applist.length; i++) {
            if (
              mycalendarProxy.applist[i]["interview_startdatetime"] &&
              mycalendarProxy.applist[i]["interview_enddatetime"] &&
              mycalendarProxy.applist[i]["attendees_email"]
            ) {
              let a = Number(
                mycalendarProxy.applist[i]["interview_startdatetime"]
              );
              let b = Number(
                mycalendarProxy.applist[i]["interview_enddatetime"]
              );
              let c = Number(interview_startdatetime);
              let d = Number(interview_enddatetime);
              if (
                ((a <= c && b >= c) || (a <= d && b >= d)) &&
                mycalendarProxy.applist[i]["attendees_email"].indexOf(em[j]) !=
                  -1 &&
                mycalendarProxy.applist[i]["id"] != id
              ) {
                setErrormsg(
                  em[j] +
                    ` alredy schedule interview ${mycalendarProxy.applist[i]["interview_startdate"]} ${mycalendarProxy.applist[i]["interview_starttime"]} - ${mycalendarProxy.applist[i]["interview_enddate"]} ${mycalendarProxy.applist[i]["interview_enddatetime"]}`
                );
                i = mycalendarProxy.applist.length + 1;
                j = em.length + 1;
              } else {
                setErrormsg("");
              }
            }
          }
        }
      }
    }
  }, [addemails, stdate, sttime, entime]);

  const close = () => {
    if (mycalendarProxy.setcheck === 0) {
      mycalendarProxy.setcheck = 1;
    } else {
      mycalendarProxy.setcheck = 0;
    }
    onClose();
    handleCloseModal();
    resetValues();
    // togglePopupModal();
    // togglePopupSaveModal();
  };
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const deletefu = async () => {
    //new parameters shortlist_jobposition & shortlist_tag added
    const { data } = await changApplications("shortlist", id, {
      "shortlist_jobposition": id,
      "shortlist_tag": "shortlist_tag"
    });
    close();
  };

  const adddoc = (d) => {
    if (doc.indexOf(d) === -1) {
      if (doc.length === 0) {
        setDoc(d);
      } else {
        setDoc(doc + "," + d);
      }
    } else {
      if (doc.indexOf(d + ",") !== -1) {
        setDoc(doc.replace(d + ",", ""));
      } else {
        if (doc.indexOf("," + d) !== -1) {
          setDoc(doc.replace("," + d, ""));
        } else {
          if (doc.indexOf(d) !== -1) {
            setDoc(doc.replace(d, ""));
          }
        }
      }
    }
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };

  const [isButtonWrapper2Clicked, setButtonWrapper2Clicked] = useState(false);
  
  const handleCloseModal = () => {
    setButtonWrapper2Clicked(false); 
    setIsOpen(false);
    setIsOpenModal(false);
  };


  const [isOpen, setIsOpen] = useState(false);

  const togglePopup = () => {
    setIsOpen(true);
    setButtonWrapper2Clicked(true);
  };
  const [isOpenModal, setIsOpenModal] = useState(false);

  const togglePopupModal = () => {
    setIsOpenModal(true);
    setButtonWrapper2Clicked(true);
  };

  const setdocarray = () => {
    if (docvalue.trim().length > 0) {
      let a = doclist;
      a.push(docvalue.trim());
      adddoc(docvalue.trim());
      setDocvalue("");
    }
  };

  const addemailslist = (e) => {
    if (addemails.indexOf(e) === -1) {
      if (addemails.length === 0) {
        setAddemails(e);
      } else {
        setAddemails(addemails + "," + e);
      }
    }
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
    setAddemailsvalue("");
  };

  const removeaddemail = (e) => {
    if (addemails.indexOf(e + ",") !== -1) {
      setAddemails(addemails.replace(e + ",", ""));
    } else {
      if (addemails.indexOf("," + e) !== -1) {
        setAddemails(addemails.replace("," + e, ""));
      } else {
        if (addemails.indexOf(e) !== -1) {
          setAddemails(addemails.replace(e, ""));
        }
      }
    }

    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <div className="overlay-popup">
        <CardsWrapper className="modalContent">
          <CardWrapper id="headercard">
            <Title>{t("INTERVIEW")}</Title>
            <Content>{t("Schedule an interview")} </Content>
            <Icon onClick={close} id="headericon" style={{ cursor: "pointer" }}>
              X
            </Icon>
          </CardWrapper>

          <Wrapper>
            <FormLabel>{t("Interview Title")}</FormLabel>
            <Input
              name="Engage"
              search={true}
              placeholder={t("Type your interview heading or agenda.")}
              minWidth="auto"
              width="710px"
              className="interviewInput"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                if (change === 0) {
                  setChange(1);
                } else {
                  setChange(0);
                }
              }}
            />
            <Row style={{ margin: "0px" }}>
              <Col lg="6">
                <FormLabel className="interviewStart">{t("Start time")}</FormLabel>
                <Row style={{ marginLeft: "-15px" }}>
                  <Col lg="6">
                    <Input
                      id="stdate"
                      name="stdate"
                      type="date"
                      width="160px"
                      value={stdate}
                      dateFormat="dd/mm/yy"
                      onChange={(e) => {
                        setSttdate(e.target.value);
                        if (change === 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                  <Col lg="6">
                    <Input
                      id="sttime"
                      name="sttime"
                      type="time"
                      value={sttime}
                      width="150px"
                      onChange={(e) => {
                        setSttime(e.target.value);
                        if (change === 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Col>
              <Col lg="6" className="dateColumn">
                <FormLabel className="interviewEnd">{t("End time")}</FormLabel>
                <Row style={{ margin: "0px" }}>
                  <Col lg="6">
                    <Input
                      id="endate"
                      name="endate"
                      type="date"
                      width="160px"
                      value={endate}
                      onChange={(e) => {
                        setEntdate(e.target.value);
                        if (change === 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                  <Col lg="6">
                    <Input
                      id="entime"
                      name="entime"
                      type="time"
                      width="150px"
                      value={entime}
                      onChange={(e) => {
                        setEntime(e.target.value);
                        if (change === 0) {
                          setChange(1);
                        } else {
                          setChange(0);
                        }
                      }}
                    ></Input>
                  </Col>
                </Row>
              </Col>
            </Row>

            <div>{error ? <span className="Error">{error}</span> : ""}</div>
            {console.log(attendeesName, "This is the atendees name:")}
            <FormLabel>{t("Attendees Name")}</FormLabel>
            <Input
                name="Engage"
                search={false}
                placeholder={t("Type your interview heading or agenda.")}
                disabled={true}
                minWidth="auto"
                width="587px"
                style={{ margin: "10px 0 0 10px" }}
                value={attendeesName}
                onChange={(e) => {
                  setAddemailsvalue(e.target.value);
                }}
              />
            <FormLabel>{t("Attendees Email")}</FormLabel>
            <div className="interviewEmail">
              <Input
                name="Engage"
                search={true}
                placeholder={t("Type your interview heading or agenda.")}
                minWidth="auto"
                width="587px"
                style={{ margin: "10px 0 0 10px" }}
                value={addemailsvalue}
                onChange={(e) => {
                  setAddemailsvalue(e.target.value);
                }}
              />
              <ButtonWrapper
                onClick={() => {
                  if (validateEmail(addemailsvalue)) {
                    if (
                      addemails
                        .toLowerCase()
                        .indexOf(addemailsvalue.toLowerCase()) === -1
                    ) {
                      addemailslist(addemailsvalue);
                      setErrormsg("");
                    } else {
                      setErrormsg(t("Email Already Exists"));
                    }
                  } else {
                    setErrormsg(t("Invalid Email"));
                  }
                }}
              >
                <button>{t("Add Email")}</button>
              </ButtonWrapper>
            </div>
            {errormsg.length ? (
              <>
                <span style={{ position: "intial" }} className="ErrorPopup">
                  {errormsg}
                </span>
              </>
            ) : (
              ""
            )}
            {errormsg.length ? <br /> : <></>}
            {addemails.split(",").map((e) => {
              return e.length > 0 ? (
                <span className="addemails">
                  {e} <span onClick={() => removeaddemail(e)}>x</span>
                </span>
              ) : (
                ""
              );
            })}
            {/* </div> */}
          </Wrapper>
          <Wrapper2>
            <FormLabel>{t("Attachment In Document")}</FormLabel>
            <div style={{ margin: "10px 0 20px 20px" }}>
              {doclist.map((e, i) => {
                return (
                  <div style={{ margin: "20px 0 0px 0" }}>
                    {i === 0 ? (
                      <>
                        <div className="interviewAttach">
                          <Input
                            minWidth="auto"
                            border="none"
                            height="45px"
                            width="557px"
                            background="#f8f8f8"
                            value={docvalue}
                            onChange={(e) => {
                              setDocvalue(e.target.value);
                            }}
                          />

                          <ButtonWrapper3>
                            {" "}
                            <button onClick={setdocarray}>
                              {t("+ Add document")}
                            </button>
                          </ButtonWrapper3>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div
                      style={{
                        display: "flex",
                        gap: "10px",
                        marginTop: "10px",
                      }}
                    >
                      <Form.Check
                        checked={doc.indexOf(e) !== -1}
                        onClick={() => {
                          adddoc(e);
                        }}
                      ></Form.Check>
                      <Span
                        onClick={() => {
                          adddoc(e);
                        }}
                      >
                        {e}
                      </Span>
                    </div>
                  </div>
                );
              })}
            </div>

            <ButtonWrapper2 >
              <Button
                onClick={togglePopupModal}
                cursor="pointer"
                name={t("Save")}
                disabled={dis}
                className="interview"
              ></Button>
              <button onClick={togglePopup}>{t("Delete")}</button>
              <button onClick={close}>{t("Cancel")}</button>
            </ButtonWrapper2>
          </Wrapper2>
        </CardsWrapper>
      </div>

      {isOpen ===true && isButtonWrapper2Clicked ===true && (
        <AlertInterviewModal
          content={
            <>
              <p className="alertInterview">
                {t(`Are you sure you wants to delete this event from the calendar`)}{" "}
                <b>{`${title}`}</b>.{""}
                <br />
                {t(`Participant will recieve a notification on the changes that have been made?`)}{" "}
              </p>
              <ButtonWrappers>
                <Button secondary onClick={handleCloseModal} name={t("No")} />
                <Button onClick={deletefu} name={t("Yes")} />
              </ButtonWrappers>
            </>
          }
          handleClose={handleCloseModal}
          title={t("Delete an Event")}
        />
      )}
      {isOpenModal ===true && isButtonWrapper2Clicked ===true  && (
        <AlertChangeModal
          content={
            <>
              <p className="alertInterview">
                {t(`Are you sure you wants to make changes to the existings event?`)}
                <br />
                {t(`Participant will recieve a notification on the changes that have been made?`)}{" "}
              </p>
              <ButtonWrappers>
                <Button secondary onClick={handleCloseModal} name={t("Cancel")} />
                <Button onClick={submit} name={t("Save")} />
              </ButtonWrappers>
            </>
          }
          handleClose={handleCloseModal}
          title={t("Change an Event")}
        />
      )}
      
    </>
  );
};

export default InterviewModal;
