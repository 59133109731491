import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Graduate from "../../assets/pool.png";
import Universities from "../../assets/university.png";
import Study from "../../assets/study.png";
import Scope from "../../assets/scope.png";
import "./Subscriptions.css";
import Button from "../../components/button/button";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { getCounts } from "../../services/apiCalls";
import W1 from "../../assets/svg/w1.svg";
import W2 from "../../assets/svg/w2.svg";
import W3 from "../../assets/svg/w3.svg";
import W4 from "../../assets/svg/w4.svg";
import { t } from "i18next";

const HeaderText = styled.h2`
  margin-top: 2%;
  margin-bottom: 1%;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

const CardsWrapper = styled.div`
  background: ${Colors.light};
  margin-top: 28px !important;
  border-radius: 32px;
  padding: 1%;
`;
const Header = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #d04c8d;
  padding: 4% 0px 0px 5%;
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  transform: rotate(90deg);
  width: 278px;
  position: relative;
  margin-top: 22%;
  margin-right: 5%;
`;
const SmallText = styled.p`
  font-size: 16px;
  line-height: 21.6px;
  font-weight: 400;
  text-align: center;
  margin: 0px 0 28px 0;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 500px) {
    align-items: start;
  }
`;
const ListText = styled.p`
  color: ${(props) => props?.color};
  font-family: "Oxanium";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
  color: #6373ff;
  background: white;
  border-radius: 5px;
`;

const WidgetWrapper = styled.div`
  display: inline-flex;
  height: 400px;
  padding: 10px;
  grid-gap: 20px;
  width: 100%;
  @media (max-width: 500px) {
    display: table-cell;
  }
`;

const Widget = styled.div`
  flex: 1;
  display: block;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  @media (max-width: 500px) {
    position: absolute;
    top:18%;
    height: 160px;
    width: 160px;
  }
`;
const Widget2 = styled.div`
  flex: 1;
  display: block;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  @media (max-width: 500px) {
    position: absolute;
    left: 52%;
    top: 18%;
    height: 160px;
    width:160px;
  }
`;
const Widget3 = styled.div`
  flex: 1;
  display: block;
  border-radius: 10px;
  padding: 10px;
  position: relative;

  @media (max-width: 500px) {
    position: absolute;
    top: 37%;
    width: fit-content;
    height: 160px;
    width: 160px;
  }
`;
const Widget4 = styled.div`
  flex: 1;
  display: block;
  border-radius: 10px;
  padding: 10px;
  position: relative;
  @media (max-width: 500px) {
    position: absolute;
    width: fit-content;
    left: 52%;
    top: 37%;
    width: 160px;
    height: 160px;
  }
`;
const BottomText = styled.div`
  color: ${(props) => props?.color};
  font-family: "Oxanium";
  font-style: normal;
  font-weight: 900;
  font-size: 24px;
  text-align: center;
  color: #6373ff;
  background: white;
  border-radius: 5px;
  @media (max-width: 500px) {
    color: ${(props) => props?.color};
    font-family: "Oxanium";
    font-style: normal;
    font-weight: 900;
    font-size: 15px;
    text-align: center;
    color: #6373ff;
    background: white;
    border-radius: 5px;
    margin: 6px -6px;
  }
  @media (min-width:768px) and (max-width:1200px){
    color: ${(props) => props?.color};
    font-family: "Oxanium";
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    text-align: center;
    color: #6373ff;
    background: white;
    border-radius: 5px;
  }
`;
const Subscriptions2 = () => {
  const navigate = useNavigate();
  const orgDetails = useSelector((state) => state.company.currentOrgData);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscribedData = useSelector(
    (state) => state.subscription.subscriptionData
  );
  const [subscriptionExpired, setSubscriptionExpired] = useState(null);
  const [countDetails, setCountDetails] = useState(undefined);

  const onNavigate = () => {
    navigate("/subscriptions/subscription-plan");
  };

  useEffect(() => {
    if (countDetails === undefined) getAllCounts();
  }, []);

  useEffect(() => {
    if (
      subscribedData &&
      subscribedData[org_code] &&
      subscriptionExpired != null &&
      !subscriptionExpired
    ) {
      navigate("/my-company");
    }
  }, [orgDetails.status != "accepted"]);

  useEffect(() => {
    if (org_code && subscribedData && subscribedData[org_code]) {
      checkIsSubscriptionExpired(subscribedData[org_code]);
    }
  });

  function checkIsSubscriptionExpired(data) {
    let today = moment(new Date()).format("MM-DD-YYYY");
    today = new Date(today);
    let renew_date = data.renew_date.split("-");
    renew_date = new Date(
      renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2]
    );
    setSubscriptionExpired(today > renew_date);
    return today > renew_date;
  }

  const getAllCounts = async () => {
    const { data } = await getCounts({ code: "123" });
    if (data && data.status === 1) {
      setCountDetails(data.data);
    }
  };

  return (
    <CardsWrapper>
      {/* <Wrapper>
        <div className="subWrapper mt-3">
          <div className=" subtwo  ">
            <ListText color='#42C1A6'>{countDetails?.students_count}</ListText>
            <img src={Graduate} alt="img" style={{}} />
          </div>
          <div className="subtwo univ ">
            <ListText color='#6373FF'>{countDetails?.universities_count}</ListText>
            <img src={Universities} alt="img" style={{}} />
          </div>
        </div>
        <div className="subWrapper wrapperTwo">
          <div className=" subtwo  ">
            <ListText color='#63BDFF'>{countDetails?.study_program_count}</ListText>
            <img src={Study} alt="img" />
          </div>
          <div className="subtwo univ ">
            <ListText color='#FE63C0'>{countDetails?.scope_of_study_count}</ListText>
            <img src={Scope} alt="img" />
          </div>
        </div>
      </Wrapper>
      <SmallText>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore <br />et dolore magna aliqua. Ut enim ad minim veniam,
        quis nostrud exercitation ullamco laboris nisi ut <br /> aliquip ex ea commodo
        consequat.
      </SmallText> */}
      <WidgetWrapper>
        <Widget style={{ backgroundColor: Colors.green }}>
          <ListText color="#42C1A6">{countDetails?.students_count}</ListText>
          <img src={W1} className="svg-center" />
          <BottomText className="label-bottom">{t("Graduate Talent Pool")}</BottomText>
        </Widget>
        <Widget2 style={{ backgroundColor: Colors.inkBlue }}>
          <ListText color="#6373FF">
            {countDetails?.universities_count}
          </ListText>
          <img src={W2} className="svg-center" />
          <BottomText className="label-bottom">{t("Universities")}</BottomText>
        </Widget2>
        <Widget3 style={{ backgroundColor: Colors.blue }}>
          <ListText color="#63BDFF">
            {countDetails?.study_program_count}
          </ListText>
          <img src={W3} className="svg-center" />
          <BottomText className="label-bottom">{t("Study Programmes")}</BottomText>
        </Widget3>
        <Widget4 style={{ backgroundColor: Colors.pink1 }}>
          <ListText color="#FE63C0">
            {countDetails?.scope_of_study_count}
          </ListText>
          <img src={W4} className="svg-center" />
          <BottomText className="label-bottom">{t("Scope of Study")}</BottomText>
        </Widget4>
      </WidgetWrapper>
      <SmallText>
      {t("You will be given access to this premium features when you subscribe with us.")}
      </SmallText>
    </CardsWrapper>
  );
};

export default Subscriptions2;
