import { useEffect, useState } from "react";
import styled from "styled-components";
import InternLogo from "../../assets/internLogo.png";
import { toast } from "react-toastify";


import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Colors } from "../../utilities/colors";
import { Row, Col } from "react-bootstrap";
import Button from "../button/button";
import JobCardTitle from "./jobCardTitle";

import AlertModal from "../alertModal/alertModal";
import { useTranslation } from "react-i18next";

import moment from "moment";
import {
  onDeletingInternship,
  jobActionReview
} from "../../services/apiCalls";
const FlexWrapper = styled.div`
  margin-bottom: 1.825rem;
`;
const Message = styled.div`
  margin-bottom: 20px;
  // padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const ImgContainer = styled.div`
  width: 150px;
  height: 150px;
  border-radius: 50%;
`;

const JobTitle = styled.div`
  // display: flex;
  // font-family: General Sans;
  // font-weight: 500;
  // font-size: 22px;

  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: ${Colors.labelText};
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  padding-left: 40px;

`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
`;

const TextContainer = styled.div`
  margin-top: 1rem;
`;

const FlexDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CompanyName = styled.div`
  // font-family: General Sans;
  // font-weight: 500;
  // font-size: 22px;
  // margin-bottom: 7px;

  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 32px;
  //color: ${Colors.primaryText};
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  padding-left: 40px;
`;

const CompanyAddress = styled.div`
  font-family: General Sans;
  // font-weight: 500;
  // font-size: 18px;
  max-width: 500px;
  color: #202020;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24.3px;
  margin: 0;
  margin-bottom: 5px;
  text-transform: capitalize;
  padding-left: 40px;
`;

const EditWrapper = styled.div`
  color: #D04C8D;
  font-size: 14px;
  @media (max-width: 767px) {
    margin-left: 350px;
  }
`;

export default function InternIntroSection({ data }) {
  const org = useSelector((state) => state.org.orgData);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [showDeleteIcon, setShowDeleteIcon] = useState(true);
  const [show, setShow] = useState(false);
  const orgDetails = useSelector((state) => state.company.currentOrgData);
  const isUserPermittedToEdit = orgDetails?.user_details?.internship_modify === "Y" ? true : false;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const onNavigate = () => {
    navigate({ pathname: "/internships/new-intern", search: `?id=${data.id}` });
  };
  const onDelete = (e) => {
    e.stopPropagation();
    setShow(true);
  };
  const closeModal = () => {
    setShow(false);
  };
  const { is_activate, org_details, position_end_date, id, status, update_time } = data;
 
  const code = useSelector((state) => state.company.selectedGlobalOrgCode);

  const onConfirmDeletion = async () => {
    const { data } = await onDeletingInternship({
      code: code,
      id: id,
    });
    if (data && data.status === 1) {
      toast.success(t("Internship deleted successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/internships");
    } else {
      toast.error(t("Internship has been deleted"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const showToast = () => {
    toast.success(t("Internship status updated successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const makeActivateOrDeactivate = ($cks) => {
    var mainStatus = "";
    if ($cks === false) {
      mainStatus = "deactivate";
    } else {
      mainStatus = "activate";
    }
    const body = {
      id: id,
      status: mainStatus,
      update_time: update_time,
    };
    const result = jobActionReview(body);
    showToast();
    if (result.status === 1) {

      toast.success(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (result.data.status === 1) {
      }
    }
  };

  const content = () => {
    return (
      <>
        <Message>{t('Are you sure want to delete the Internship?')}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="20px 0px 30px 0px"
              name={t('Yes')}
              onClick={onConfirmDeletion}
            />
            <Button
              secondary
              color="#FFFFFF"
              name={t('No')}
              width="100px"
              margin="20px 0px 30px 20px"
              onClick={closeModal}
            />
          </Col>
        </Row>
      </>
    );
  };
  useEffect(() => {
    if (data.intern_type === "General Internship") {
      if (data.status === "pending") {
        setShowEditIcon(true)
        setShowDeleteIcon(true)
      } else if (data.status === "pending_payment") {
        setShowEditIcon(true)
        setShowDeleteIcon(true)
      } else if (data.status === "accepted") {
        setShowDeleteIcon(false)
        setShowEditIcon(false)
      } else if (data.status === "expired") {
        setShowEditIcon(false)
        setShowDeleteIcon(false)

      } else if (data.status === "review") {
        setShowDeleteIcon(true);
        setShowEditIcon(true);
      }
    }
    else if (data.intern_type === "sip") {
      if (data.status === "pending") {
        setShowEditIcon(true)
        setShowDeleteIcon(true)
      } else if (data.status === "pending_payment") {
        setShowEditIcon(true)
        setShowDeleteIcon(true)
      } else if (data.status === "accepted") {
        setShowEditIcon(false)
        setShowDeleteIcon(false)
      } else if (data.status === "expired") {
        setShowEditIcon(false)
        setShowDeleteIcon(false)

      } else if (data.status === "review") {
        setShowDeleteIcon(true);
        setShowEditIcon(true);
      }
    }
    if (moment.utc(data.position_end_date).format("YYYY-MM-DD") < moment.utc().format("YYYY-MM-DD")) {
      setShowDeleteIcon(false)
      setShowEditIcon(false);
    }
  }, [data.intern_type, data.position_end_date, data.status])

  return (
    <FlexWrapper>
      <Row>
        <Col xl="2" lg="12">
          <ImgContainer>
            <StyledImage
              src={
                org_details?.logo_path
                  ? org_details?.logo_path
                  : InternLogo
              }
            />
          </ImgContainer>
        </Col>
        <Col xl="10">
          <TextContainer>
            <FlexDiv>
              <JobTitle>{data && data.title}</JobTitle>
              {(data.status === "expired" || moment.utc(position_end_date).format("YYYY-MM-DD") < moment.utc().format("YYYY-MM-DD")) ? (
                t("Expired")
              ) : data.status === "review" ? (
                t("Sent back for Review")
              ) : ""}
              {data.status === "accepted" ? (
                <JobCardTitle
                  navigation={onNavigate}
                  onToggle={makeActivateOrDeactivate}
                  /*pending payment is an assumption for now*/
                  isActivate={is_activate}
                  status={status}
                  position_end_date={position_end_date}
                />
              ) : (
                ""
              )}

              {/* <Line>Edit</Line> */}
            </FlexDiv>
            <FlexDiv className='w-100 d-xs-block'>
              <CompanyName >{org_details?.name}</CompanyName>
              <Row>
                <Col sm={4} xs={2} md={4} style={{ marginLeft: '40px' }}>
                  {showEditIcon && isUserPermittedToEdit ?
                    <div className="edit-line" onClick={onNavigate} style={{ cursor: 'pointer' }}>
                      {t('Edit')}
                    </div>
                    : null}
                </Col>
                <Col sm={4} xs={2} md={4}>
                  {showDeleteIcon && isUserPermittedToEdit?
                    <div className="line" onClick={onDelete} style={{ cursor: 'pointer' }}>
                      {t('Delete')}
                    </div>
                    : null}
                </Col>
              </Row>

            </FlexDiv>
            <CompanyAddress>
              📍
              {org_details?.country_abbr}
            </CompanyAddress>
         
          </TextContainer>
          <AlertModal show={show} close={closeModal} content={content()} />
        </Col>
      </Row>
    </FlexWrapper>
  );
}
