import React, { useState, useEffect } from "react";
import styled from "styled-components";
import "./swiper.css";
import InternIcon from "../../assets/icons/intern.png";
import Salery from "../../assets/icons/salary.png";
import { getInternLists, getOrgData } from "../../services/apiCalls";
import { staticState } from "../../pages/dashboard/staticContent";
import { getToday } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "./internshipSwiper.css"

const FlexWrapper = styled.div`
  display: flex;
`;
const FlexWrappers = styled.div`
  display: flex;
  gap: 10px;
  margin: 15px 0 0 0;
`;
const ImgText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #525252;
  margin: 5px 0 0 0;
`;

const Card1 = styled.div`
  background: #fdfdfd;
  border: 1px solid #d04c8d;
  border-radius: 16px;
  position: relative;
  width: 95%;
`;
const CardTextWrapper = styled.div`
  background: #d04c8d;
  border-radius: 0px 0px 16px 16px;
  padding: 1px;
`;
const CardText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 4;
  color: #202020;
  text-align: center;
  // margin: 20px;
  position: relative;
  word-wrap: break-word;
  height:100px;
  .backco:nth-child(2){
    background:blue;
  }
`;







const Slider = (props) => {

  const [loading, setLoading] = useState(true);
  const [dataList, setDataList] = useState([]);
  const [searchval, setSearchFilter] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { dashboardStatus } = useParams();
  const [status, setStatus] = useState(
    dashboardStatus ? dashboardStatus : "all"
  );

  const [fromDateError, setFromDateError] = useState(false);

  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

  let navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    getDatas("", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (org_code) getDatas("", 1);
  }, [org_code]);

  useEffect(() => {
    setFromDateError(false);
  }, [status, fromDate, toDate, searchval]);

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  const getDatas = async (name, pageNumber, updatedStatus) => {
    var result1 = await getOrgData();
    if (result1.status === "401") {
      navigate("/login");
    }
    if (org_code) {
      let req = {};
      let filter = {};
      req["start_date"] = fromDate || "";
      req["end_date"] = toDate || "";
      if (req["start_date"] || req["end_date"]) {
        if (!req["start_date"]) {
          setFromDateError(true);
          return false;
        }
        if (!req["end_date"]) {
          req["end_date"] = getToday();
        }
        filter = { period: { ...req } };
      }

      const body = {
        ...staticState,
        search: name ? name : searchval,
        filters: {
          ...filter,
          status: updatedStatus ? updatedStatus : status ? status : "all",
        },
        page_number: pageNumber || 1,
        org_code: org_code,
      };

      const { data } = await getInternLists({
        ...body,
      });

      if (data && data.status === 1) {
        
        if (data?.pagination?.current_page > 1) {
          setDataList(
            dataList.length ? [...dataList, ...data.data] : [...data.data]
          );
        } else {
          setDataList(data?.data?.length ? [...data.data] : []);
        }
        setLoading(false);
        setTotal(data?.pagination?.total_pages);
        setCurrentPage(data?.pagination?.current_page);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setDataList([]);
  }, [status]);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1201 },
      items: 3,
      slidesToSlide: 3,
    },
    Largedesktop: {
      breakpoint: { max: 1200, min: 1025 },
      items: 2,
      slidesToSlide: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel
        showDots={true}
        responsive={responsive}
        arrows={false}
      >
        {dataList?.map((list, index) => (
          <div className="swiper1" >
            <Card1>
              <FlexWrapper>
                <img
                  src={company?.logo_path}
                  alt="img"
                  className="imagecarousel"
                />
                <div>
                  <FlexWrappers>
                    <img
                      src={InternIcon}
                      alt="view"
                      className="swiperImageOne"
                    ></img>
                    <ImgText>{list?.intern_type}</ImgText>
                  </FlexWrappers>
                  <FlexWrappers>
                    <img
                      src={Salery}
                      alt="view"
                      className="salaryImage1"
                    ></img>
                    {list?.payment_status === "unpaid" ? (
                      <ImgText>0 MYR</ImgText>
                    ) : (
                      <ImgText>{`${list?.min_compensation} MYR - ${list?.max_compensation} MYR`}</ImgText>
                    )}
                  </FlexWrappers>
                </div>
              </FlexWrapper>
              <CardTextWrapper>
               
                  <CardText>{list?.title}</CardText>
           
              </CardTextWrapper>
            </Card1>
          </div>
        ))}
      </Carousel>
    </>
  );
};
export default Slider;
