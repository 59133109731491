import React from "react";
import { useSearchParams } from "react-router-dom";
// import { getEnglishProficiency } from "../../../../../services/CommonApi";
// import { Utils } from "../../../../../utilities/utils";
import AnalyticalCardVarientFour from "../../../pages/applicantAssessments/analyticalCardVarientFour/analyticalCardVarientFour";
import { Ad2EnglishProficiency } from "./adEnglishProficiency";
// import AnalyticalCardVarientFour from "../../../ad2-shared/AnalyticalCardVarientFour/AnalyticalCardVarientFour";
// import { Ad2EnglishProficiency } from "../../ad2EnglishProficiency/ad2EnglishProficiency";

export default function Ad2EnglishProficiencyDrilldown() {
    let controller = new AbortController();
    let [searchParams] = useSearchParams();
    const [englishProficiency, setEnglishProficiency] = React.useState({ data: null, status: 'loading' });

    React.useEffect(() => {
        // fetchEnglishProficiency();

        return () => {
            controller.abort();
        };
    }, []);

    // const fetchEnglishProficiency = async () => {
    //     const labelMap = {
    //         'Grammar': 'Grammar',
    //         'Comprehension': 'Comprehension',
    //         'General': 'Total English Proficiency Level'
    //     }
    //     setEnglishProficiency({ data: null, status: 'loading' });
    //     const filterParams = Utils.getParams(searchParams);
    //     const { data, status } = await getEnglishProficiency({ filters: filterParams, chartsLoadingController: controller });
    //     let total = data && data.data && data.data.results && data.data.results.totalTalents;
    //     const { proceed, stateObj } = Utils.commonStateUpdateLogic(data, total, status, 'englishProficiency');
    //     if (stateObj) {
    //         setEnglishProficiency(stateObj);
    //     }
    //     if (proceed) {
    //         let results = data && data.data && data.data.results;
    //         const gridData = [];
    //         const chartDataMap = {};
    //         Object.keys(results)
    //             .filter(x => x !== 'totalTalents')
    //             .forEach((key) => {
    //                 const level1 = Utils.falsyNumberCaseHandle(results[key] && results[key].results && results[key].results.level1),
    //                     level2 = Utils.falsyNumberCaseHandle(results[key] && results[key].results && results[key].results.level2),
    //                     level3 = Utils.falsyNumberCaseHandle(results[key] && results[key].results && results[key].results.level3),
    //                     level4 = Utils.falsyNumberCaseHandle(results[key] && results[key].results && results[key].results.level4),
    //                     level5 = Utils.falsyNumberCaseHandle(results[key] && results[key].results && results[key].results.level5);
    //                 gridData.push({
    //                     type: labelMap[key],
    //                     level1,
    //                     level2,
    //                     level3,
    //                     level4,
    //                     level5,
    //                 });
    //                 chartDataMap[key] = {
    //                     level1,
    //                     level2,
    //                     level3,
    //                     level4,
    //                     level5,
    //                 }
    //             })
    //         setEnglishProficiency({
    //             gridData,
    //             data: chartDataMap,
    //             status,
    //             total
    //         });
    //     }
    // }
    return (
        <AnalyticalCardVarientFour>
            <Ad2EnglishProficiency
                data={englishProficiency}
            />
        </AnalyticalCardVarientFour>
    );
};
