import React from "react";
import { Col, Row } from "react-bootstrap";
import { Ad2CardVariantTwoBoxDescription, Ad2CardVariantTwoBoxTitle } from "./adCardStyles";
import ProgressChart from "./charts/progressChart";

export function Ad2CardVariantTwo({ value, title, description, onClick = () => { } }) {
    return <>
        <Row>
            <Col xs="12">
                <Ad2CardVariantTwoBoxTitle>
                    <span>{title}</span>
                </Ad2CardVariantTwoBoxTitle>
            </Col>
            <Col xs="12" className="cursor-pointer" onClick={onClick}>
                <ProgressChart
                    currentValue={value}
                    gradient={['#0B6EBA', '#F156A3']}
                />
            </Col>
            <Col xs="12">
                <Ad2CardVariantTwoBoxDescription>{description}</Ad2CardVariantTwoBoxDescription>
            </Col>
        </Row>
    </>;
}