import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";

//for completionOfInternshipOverview page dummy data
export const completionOfInternshipOverviewObj ={
  attendanceRecord:"attendance_record.pdf",
  internshipTitle:"Marketing",
  allowance:"Rm 600",
  additionalAllowance: "Allowance 1, Allowance 2",
  expendituresRecords:"expenditures_records.pdf",
  externalTrainings:"Yes", 
  externalTrainingsYes:"Yes",
  externalTrainingsEvidence:"external_training.pdf",
  typeOfTraining:"Online",
  courseTitle:"FB Ads",
  traningProvider:"XYZ Sdn Bhd",
  duration:"2 weeks",


 
}

export const completionOfInternshipObj ={
  heading: "Add a New MySIP Direct Hiring Internship",
  completionForm:"Completion Form",
  datesHeading:"Internship start and end dates* (Please specify the exact start and end dates for this intern)",
  editHeading: "Edit Internship",
  description:
    "Upload the details of your hired interns. Evaluation form to both companies and interns will be send upon receiving details of intern. Posting will be reviewed by Talent Corp before issuance of LOE.",

  minimumAllowance:"Based on current practice by your organisation, what is the actual allowance per intern per month?",
  allowance:"How much total allowance did the company pay per intern for the whole duration of the programme?",
  allowanceNotes:"(Not including meals, travel expense, accommodation,and other additional allowance)",
  additionalAllowance: "Additional allowance",
  additionalAllowanceNotes:"(Besides the intern's basic allowance, do you provide any additional allowance for your interns?)",
  expenditures:"Expenditures records*",
  expendituresNotes:"(Please attach the evidence of your expenditures [PDF format])",
  externalTrainings:"External tranings*",
  internalTraning:"Did you provide any internal training for your intern?",
  internalTraningNotes:"(e.g.: Short course by your internal personnel)",
  externalTrainingsQuestions: "Did you provide any external trainings during the internship period?",
  externalTrainingsQuestionsNotes:"(e.g Sending intern for a short course or training by a recognized training provider)",

  learningOutcomes: "What were the learning outcome(s) of this internship programme? ", 
  learningOutcomesNotes:"(Please select at least one.)",
  learningOutcomesList: [
        "Ability to demonstarte skills in problem solving and decision making through hands-on practice.",
        "Ability to develop skills and techniques directly applicable to their careers.",
        "Exposed to real work environment and gain knowledge in writing report in technical works/projects",
        "Interns able to utilise their strength, teamwork spirit, and self-confidence.",
        "Interns able to enhance creativity and idea sharing skills.",
        "Build good communicatio skills with colleagues and learn proper behaviour in a corporate setting.",
        "Others (please specify)"
    ],

  hrdCertified:"Were the external trainings provider certified by the Human Resources Development Corporation (HRD Corp)?",
  hrdCertifiedUpload:"Please attach the evidence of training",
  internStartDate: "Internship Start Date*",
  internEndDate: "Internship End Date*",
  internPositions: "No. of positions*",
  uploadTitle: "Attendance records*",
  uploadFileText: "Choose File",
  uploadNotes:"(Please attach along the attendance record of the intern. [PDF format])",
  uploadProof: "(Please attach along the evidence of training. [PDF format])",
  academicTitle: "Academic Requirements",
  applicantDocumentTitle: "Applicant Documents",
  scopeOfStudy: "Scope of Study*",
  requiredDocs: [
    "Online",
    "Classroom",
    "Others(Please specify)"
  ],
  hoursTitle: "Hours & Allowance",
  workingHours: "Working Hours/Day*",
  padiText: "Paid/ Unpaid*",
  providerDetails: "Please provide the following information:",
  courseTitle:"Course Title",
  trainingProvider:"Training Provider",
  durationOfTraning:"Duration (no. of Days)",
}
export const newInternStaticObj = {
  heading: "Add a New MySIP Direct Hiring Internship",
  heading2:"Internship Type Detail",
  subHeading1:"Type Of Internship Offered",
  editHeading: "Edit Internship",
  description:
    "Complete your endorsement for National Structured Internship Programme(MySIP), by providing the types of internship including the details of job descriptions you are offering to interns.",
  description2:
  "Please provide job description (if any) and fill in below for internship type placement information:",
  formTitle: "Internship Title*",
  allowance:"Monthly allowance offered",
  educationLevel: "Minimum education level required",
  scopeOfStudy:"Scope of study",
  minimumYearOfStudy: "Preferred minimum Year Of Study",
  formDescription: "Internship Job Description*",
  internTypes: {
    sip: "Yes",
    "No": "No", 
  },
  others:"Others (please specify)",
  formInternType: "Internship Type*",
  studyDiffer: "Would you accept students whose area of study differs from the scope of work required as interns?",
  internEmployment:"Do you offer interns employment opportunity upon internship completion ?",
  flexibleWorking:"Flexible Working Arrangement (FWA) has become the new norm for many organizations.Do you provide this flexibility to your interns?",
  internStartDate: "Internship Start Date*",
  internEndDate: "Internship End Date*",
  internPositions: "No. of positions*",
  uploadTitle: "Please upload a supporting documentation for internship job description (if any).",
  uploadFileText: "Choose File",
  uploadNotes:
    "e.g., Further job descriptions, company profile, etc. The job viewer can download this",
  academicTitle: "Academic Requirements",
  applicantDocumentTitle: "Applicant Documents",
  scopeOfStudy: "Scope of Study*",
  gpaText: "Minimum Required CGPA*",
  degreeText: "Minimum Education Level Required*",
  gradeText: "Grade Level(s)  you can select multiple",
  degrees: [
    "SKM 1",
    "SKM 2",
    "SKM 3",
    "DKM",
    "DLKM",
    "Diploma",
    "Bachelor's Degree",
    "Master's Degree",
  ],
  grades: ["Year 1", "Year 2", "Year 3", "Year 4", "Others"],
  requiredDocs: [
    "Analytical/Problem Solving",
    "Project Management",
    "Communication",
    "Logistics",
    "Administration",
    "Presentation",
    "Special project",
    "Non-essential functions"
  ],
  collectionTypes: [
    "Receive applications when submitted",
    "Receive appplications in a single package on a given date",
  ],
  addSkillText: "Please provide three (3) most important technical skills an intern should possess and add extra detail if necessary",
  threeSkills: "Please provide three (3) most important soft skills an intern should possess",
  hoursTitle: "Hours & Allowance",
  workingHours: "Working Hours/Day*",
  padiText: "Paid/ Unpaid*",
  min_compensation: "Minimum Compensation (in RM)",
  max_compensation: "Maximum Compensation (in RM)",
  location: "Location(s)",
  country: "Country*",
  city: "City*",
  state: "State*",
  anotherLocation: "+ Add another location",
  advertiseMentDate: "Advertisement dates",
  advertiseStartDate: "Advertisement Start Date*",
  advertiseExpDate: "Advertisement Expiration Date*",
  contactTitle: "Contact Person Information",
  contactPerson: "Select Contact Information*",
  fullName: "Full Name",
  email: "E-mail Address",
  mobileNumber: "Mobile Number",
  displayContact: "Display contact information to students",
};

export const internFields = {
  internship_module_id: "",
  title: "",
  description: "",
  intern_type: "General Internship",
  exp_start_date: "",
  exp_end_date: "",
  no_positions: "1",
  req_gpa: "",
  min_degree: "",
  working_hours: "",
  max_compensation: "",
  min_compensation: "",
  country: "",
  state: "",
  city: "",
  grade: "",
  file_paths: [],
  skills: [],
  soft_skills:[],
  scopes: [],
  documents: [],
  payment_status: "",
  locations: [],
  position_start_date: null,
  position_end_date: null,
  contact_name: "",
  contact_email: "",
  contact_person: "",
  direct_hiring_type: "",
  contact_mob_code: "",
  contact_mob: "",
  visibility_status: 0,
  collection_type: null,
  collection_radio_type: "",
  collection_date: "",
  re_email_address: "",
  optional_documents: "",
  application_instruction: "",
  external_application_link: "",
  internship_id: "",
  file_paths: [],
  attendance_records: [],
  actual_allowance_per_intern: null,
  is_external_training_given: null,
  is_internal_training_given: null,
  total_allowance:"",
  expenditure_records:[],
  is_acknowledged:1,
  documents: [],
  additionalAllowance:[],
  learningOutcome:[],
  typeOfTrainings:[],
  // external_training: [
  //   {
      external_training_course_title: '',
      external_training_provider: '',
      external_training_duration: null,
      is_external_training_provider_certified_by_the_hrdcorp: null,
  //   },
  // ],
  // internal_training: [
  //   {
      internal_training_course_title: '',
      internal_training_provider: '',
      internal_training_duration: null,
  //   },
  // ],
  proof_of_training:[]
};

export const schemaCompletion = yup.object().shape({
  uploadTitle: yup.string().required("Upload title is required"),
  uploadNotes: yup.string().required("Upload notes are required"),
  actual_allowance_per_intern: yup.string().required("Actual allowance per intern is required"),
  is_internal_training_given: yup.string().required("Internal training information is required"),
  is_external_training_given: yup.string().required("External training information is required"),
  internal_training_course_title: yup.string().when('is_internal_training_given', {
    is: 'Yes',
    then: yup.string().required("Internal training course title is required"),
    otherwise: yup.string(),
  }),
  internal_training_provider: yup.string().when('is_internal_training_given', {
    is: 'Yes',
    then: yup.string().required("Internal training provider is required"),
    otherwise: yup.string(),
  }),
  internal_training_duration: yup.number()
  .integer('Duration must be an integer')
  .typeError('Duration must be a number')
  .required('Duration is required').when('is_internal_training_given', {
    is: 'Yes',
    then: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
    //otherwise: yup.string(),
  }),
  external_training_course_title: yup.string().when('is_external_training_given', {
    is: 'Yes',
    then: yup.string().required("External training course title is required"),
    otherwise: yup.string(),
  }),
  external_training_provider: yup.string().when('is_external_training_given', {
    is: 'Yes',
    then: yup.string().required("External training provider is required"),
    otherwise: yup.string(),
  }),
  external_training_duration: yup.number()
  .integer('Duration must be an integer')
  .typeError('Duration must be a number')
  .required('Duration is required').when('is_external_training_given', {
    is: 'Yes',
    then: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
    //otherwise: yup.string(),
  }),
  proof_of_training: yup.array().of(
    yup.object().shape({
      file_path: yup.string().required("Proof of training file path is required"),
    })
  ),
  learningOutcome: yup.array().of(
    yup.object().shape({
      id: yup.string().required("Learning outcome ID is required"),
      name: yup.string().required("Learning outcome name is required"),
    })
  ),
  total_allowance: yup.string().required("Total allowance is required"),
  additionalAllowance: yup.array().of(
    yup.object().shape({
      id: yup.string().required("Additional allowance ID is required"),
      name: yup.string().required("Additional allowance name is required"),
    })
  ),
  expenditures: yup.string().required("Expenditures information is required"),
  expendituresNotes: yup.string().required("Expenditures notes are required"),
});


export const schema = yup.object().shape({
  title: yup.string().required("Internship title is required"),
  total_allowance:yup.string().required("Total allowance is required"),
  allowance: yup.string().required("Internship allowance is required"),
  min_allowance_per_intern: yup.string().required("Internship allowance is required"),
  educationLevel: yup.string().required("Minimum education level required"),
  scopeOfStudy: yup.string().required("Scope of study is required"),
  minimumYearOfStudy: yup.string().required("Minimum year of study required"),
  description: yup.string().required("Internship description is required"),
  intern_type: yup.string().required(),

  exp_start_date: yup.string().required("Internship start date is required"),
  exp_end_date: yup.string().test('is-valid-duration', function(value, context) {
    const { exp_start_date } = context.parent;

    // If either date is missing, let the required validation handle it
    if (!value || !exp_start_date) {
      return true;
    }
    
    const startDate = new Date(exp_start_date);
    const endDate = new Date(value);
    
    // Calculate difference in milliseconds
    const differenceInTime = endDate.getTime() - startDate.getTime();
    
    // Convert difference to weeks (assuming 7 days per week)
    const differenceInWeeks = differenceInTime / (1000 * 3600 * 24 * 7);

    console.log("Difference in weeks:", differenceInWeeks);

    // Check if duration is less than 10 weeks
    if (differenceInWeeks < 10) {
      console.log("Duration is less than 10 weeks");
      // Return the error message if duration is less than 10 weeks
      return this.createError({
        path: context.path,
        message: 'Internship duration must be at least 10 weeks',
      });
    }
    
    // Validation passes if duration is at least 10 weeks
    return true;
  }).required("Internship end date is required"),
  no_positions: yup
    .string()
    .required("No. of positions available is required")
    .test(
      "no_positions",
      "Minimum position should be greater than 0",
      (val) => val === undefined || val === null || val > 0
    )
    .test(
      "no_positions",
      "No. of positions cannot exceed 10000",
      (val) => val < 10000
    ),
  req_gpa: yup.string().required("Minimum CGPA is required"),
  min_degree: yup.string().required("Minimum education level is required"),
  working_hours: yup.number()
  .integer('Working Hours must be an integer')
  .typeError('Working Hours must be a number')
  .required('Working Hours is required'),
  contact_person: yup.string().required("Contact is required."),

  // min_compensation: yup
  //   .string()
  //   .max(7, "Minimum Commission should not be more than 7 digits")
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree === "Bachelor's Degree" ||
  //         min_degree === "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Bachelor's Degree and above is RM600",
  //         (val) => val >= 600
  //       ),
  //   })
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree !== "Bachelor's Degree" ||
  //         min_degree !== "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Diploma and below is RM500",
  //         (val) => val >= 500
  //       ),
  //   })
  //   .when("payment_status", {
  //     is: "paid",
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "Minimum Compensation cannot be 0",
  //         (val) => val >= 1
  //       ),
  //   }),
  min_compensation: yup
  .string()
  .required('Minimum compensation is required')
  .when('min_degree', {
    is: (minDegree) => ['SKM 1', 'SKM 2', 'SKM 3', 'DKM', 'Diploma'].includes(minDegree),
    then: yup.string().test('is-greater-than-500', 'Min compensation should be more than 500', (value) => {
      return Number(value) >= 500;
    }),
    otherwise: yup.string().test('is-greater-than-600', 'Min compensation should be more than 600', (value) => {
      return Number(value) >= 600;
    }),
  })
  // .test({
  //   name: "min_compensation",
  //   message: "Min compensation should be higher than monthly allowance provided in eligibility check",
  //   test: function (value) {
  //     const { monthly_allowance_1, monthly_allowance_2 } = this.options.context;

  //     // Check if min_compensation is lower than monthly_allowance_1 or monthly_allowance_2
  //     if (
  //       Number(value) < monthly_allowance_1 ||
  //       Number(value) < monthly_allowance_2
  //     ) {
  //       return false;
  //     }

  //     return true;
  //   },
  // })
  ,
  max_compensation: yup
    .string()
    .required("Maximum allowance is required")
    .max(7, "Maximum Commission should not be more than 7 digits")
    .when("min_compensation", (min_compensation, schema) => {
      return schema.test({
        test: (max_compensation) =>
          Number(max_compensation) > Number(min_compensation),
        message: "Maximum Compensation should be greater than the minimum allowance",
      });
    })
    .test({
      test: (max_compensation) => {
          const maxCompensationNumber = Number(max_compensation);
          return !(maxCompensationNumber < 500);
      },
      message: "Maximum Compensation should be at least 500",
  }),
    // .when("payment_status", {
    //   is: "paid",
    //   then: yup.string().required("Maximum allowance is required"),
    // }),
  position_start_date: yup.string().required("Post start date is required"),
  position_end_date: yup.string().required("Post expiration date is required"),
  re_email_address: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup
      .string()
      .required("E-mail is required.")
      .matches(/^([\w+-._]+@[\w-.]+\.[A-Za-z0-9]{2,10},?)+$/, "Invalid Email"),
    // .email("Invalid email")
  }),
  application_instruction: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("Application instruction is required."),
  }),
  external_application_link: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("External application link is required."),
  }),

  collection_radio_type: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup.string().required(),
  }),
  collection_date: yup.string().when("collection_radio_type", {
    is: "Receive appplications in a single package on a given date",
    then: yup.string().required("Date is Required"),
  }),
 
  grade: yup.string().required("Year of Study is required"),

  documents: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select at least one required document",
      test: (arr) => arr.length > 0,
    }),
  proof_of_training: yup
    .array()
    .of(
      yup.object({
        file_path: yup.string(),
      })
    )
    .min(1, "Please upload at least 1 proof of training"),
    // additionalAllowance: yup
    // .array(
    //   yup.object({
    //     allowance_type: yup.string().required(),
    //   })
    // )
    // .test({
    //   message: "Please select at least one additional allowance",
    //   test: (arr) => arr.length > 0,
    // }),

    learningOutcome: yup
    .array(
      yup.object({
        allowance_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select at least one learning outcome",
      test: (arr) => arr.length > 0,
    }),

    typeOfTrainings: yup
    .array(
      yup.object({
        allowance_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select at least one type of training",
      test: (arr) => arr.length > 0,
    }),

  skills: yup
    .array(
      yup.object({
        skill: yup.string(),
      })
    )
    .when("intern_type", (intern_type, schema) => {
      return schema.test({
        test: (arr) => {
          if (intern_type === "General Internship") {
            return true;
          } else if (intern_type === "sip" && arr.length === 0) {
            return false;
          } else {
            return true;
          }
        },
        message: `For MySIP add at least one skill`,
      });
    })
    .test({
      message: "This is a required field",
      test: (arr) => arr.length > 0,
    })
  .test({
      message: "Please select exactly 3 skills",
      test: (arr) => arr.length === 3,
    }),
    soft_skills: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select 3 soft skills",
      test: (arr) => arr.length > 0,
    }),
  file_paths: yup.array(
    yup.object({
      file_path: yup.string(),
    })
  ),
  
  attendance_records:  yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please upload at least 1 attendance record"),

  expenditure_records:  yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please uplaod at least 1 expenditure record"),

  scopes: yup
    .array(
      yup.object({
        scope: yup.string().required(),
      })
    )
    .test({
      message: "Scope of study is required",
      test: (arr) => arr.length > 0,
    }),
  locations: yup
    .array(
      yup.object({
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
      })
    )
    .test({
      message: "Please add any one location.",
      test: (arr) => arr.length > 0,
    }),

  //internal_training_course_title: yup.string().required('Course title is required'),
  //internal_training_provider: yup.string().required('Training provider is required'),
  internal_training_duration: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
  //external_training_course_title: yup.string().required('Course title is required'),
  //external_training_provider: yup.string().required('Training provider is required'),
  external_training_duration: yup.number()
  .integer('Duration must be an integer')
  .typeError('Duration must be a number')
  .required('Duration is required'),
  
  actual_allowance_per_intern: yup.string().required("Actual allowance is a required field")
});

export const QuestField = {
  question1: "Minimum 10 weeks internship duration",
  question2: "Reached minimum monthly allowance threshold of RM500 (for SKM1-5 & Diploma)",
  question3: "Reached minimum monthly allowance threshold of RM500 (for professional certificates, Bachelor's Degree and Master) ",
  question4: "Reached minimum monthly allowance threshold of RM600 (for SKM5, professional certificate, Bachelor Degree and Master Degree)",
  subQuestion: "Actual monthly allowance paid for this tier:",
  remark: "Remarks",
  is_eligible: "You are eligible to participate in National Structured Internship Programme (MySIP) 2023. Please pay a minimun of RM 500 per month or more for SKM1-SKM5 or Diploma or a minimum of RM 600 per month or more for Bachelor degree, master degree or Professional Certificate.",
  not_eligible: "You are not eligible.",
  eligibile: "You are eligible",
  obj_student: "The best three objectives for students:",
  obj_company: "The best three objectives for company:",
  act_offered: "Activities Offered During Internship:-",
  expected_outcome: "The best three expected learning outcomes for interns:",
  additional_doc: "Internship additional information Document:-",
  other_allowance: "Additional Allowance",
  declare_head: "PERSONAL DATA PROTECTION ACT 2010",
  declaration: "I hearby certify that the information contained herein is true and accurate to the best of my knowledge. I hereby consent for Talent Corporation Malaysia Berhad (TalentCorp) to collect, store, process amd use my personal data contained herein accordance with the Personal Data Protection Act 2010 for the purpose it was collected, which includes but is not limited for administrative purposes in connection with TalentCorp's #National Internship Programme (MySIP) programme."
}
