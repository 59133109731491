import React from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { useTranslation } from "react-i18next";

const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: -10px 0 10px 0;
  @media (min-width: 768px) and (max-width: 1100px) {
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: ${Colors.dark};
    margin: 0px;
  }
  @media (max-width:500px){
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    color: ${Colors.dark};
    margin: 0px;p
    
  }
`;
const IntroSectionSubHeader = styled.p`
  font-weight: ${(props) => (props.fw ? props.fw : "normal")};
  font-size: 16px;
  line-height: ${(props) => (props.lh ? props.lh : "25px")};
  color: ${(props) => (props.color ? props.color : "#707070")};
  margin: 0px;
  // width: 70%;
  @media (max-width: 767px) {
    width: 100%;
  }
`;

export default function Header() {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <PageTitle>{t("Employee Management")}</PageTitle>
      <IntroSectionSubHeader>
        {t("Employees added here will be able to link their talent account to your organisation. You will be able to see their assessment data on your organization's psychometric analytics.")}
      </IntroSectionSubHeader>
    </div>
  );
}
