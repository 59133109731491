// import { getSelfLeadershipDrilldown } from "../../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";


export const getSelfLeadershipDrilldown = () => {
    const getSelf = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Self-Awareness": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 23,
                        "moderate": 486,
                        "high": 178
                    }
                },
                "Self-Management": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 20,
                        "moderate": 466,
                        "high": 201
                    }
                },
                "Entrepreneurship": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 18,
                        "moderate": 476,
                        "high": 193
                    }
                },
                "Goal Achievement": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 13,
                        "moderate": 463,
                        "high": 211
                    }
                }
            }
        }
    }

    return getSelf
}

export default Ad2DrilldownHOC(getSelfLeadershipDrilldown, [
    {
        title: "Self-Awareness",
        dataKey: "Self-Awareness",
        url: 'self-awareness',
        info:"High self-awareness skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to understand your emotions, how they impact you and knowing how to calm yourself down when you are feeling stressed is important for your health, wellbeing, and your performance at work."
    },
    {
        title: "Self-Management",
        dataKey: "Self-Management",
        url: 'self-management',
        info:"High self-management skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to look after your wellbeing, motivate yourself and make progress towards your goals is important for your ability to secure employment and progress in your career."
    },
    {
        title: "Entrepreneurship",
        dataKey: "Entrepreneurship",
        url: 'entrepreneurship',
        info:"High entrepreneurship skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards your work is important for your ability to secure employment, progress in your career and stay ahead of the competition."
    },
    {
        title: "Goal Achievement",
        dataKey: "Goal Achievement",
        url: 'goal-achievement',
        info:"High goal achievement skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to take responsibility for setting and working towards your goals, persevering, especially during periods of uncertainty and committing to your professional development is important for your ability to secure employment and progress in your career."
    },
]);
