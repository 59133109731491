import React, { useEffect, useState } from "react";
import QuickChart from "quickchart-js/build/quickchart.cjs";
import { Text, Image, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const chartData = data;
  const cognitive = chartData && chartData?.domains?.["Cognitive"];
  const digital = chartData && chartData?.domains?.["Digital"];
  const selfLeadership = chartData && chartData?.domains?.["Self Leadership"];
  const interpersonal = chartData && chartData?.domains?.["Interpersonal"];

  const [cognitiveChartUrl, setCognitiveChartUrl] = useState(null);
  const [digitalChartUrl, setDigitalChartUrl] = useState(null);
  const [selfLeadershipChartUrl, setSelfLeadershipChartUrl] = useState(null);
  const [interpersonalChartUrl, setInterpersonalChartUrl] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (cognitive !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(cognitive?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#FF75B9", "#D24C8E", "#9B3668"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#542F41",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setCognitiveChartUrl(await myChart.getUrl());
      })();
    }

    if (digital !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(digital?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#FF75B9", "#D24C8E", "#9B3668"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#542F41",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setDigitalChartUrl(await myChart.getUrl());
      })();
    }

    if (selfLeadership !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(selfLeadership?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#FF75B9", "#D24C8E", "#9B3668"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#542F41",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setSelfLeadershipChartUrl(await myChart.getUrl());
      })();
    }

    if (interpersonal !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(interpersonal?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#FF75B9", "#D24C8E", "#9B3668"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#542F41",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setInterpersonalChartUrl(await myChart.getUrl());
      })();
    }
  }, [cognitive, digital, selfLeadership, interpersonal]);

  return (
    <View style={{}}>
      <View style={{}}>
        <Text
          style={{
            fontFamily: "General Sans",
            fontSize: 24,
            fontWeight: 500,
            textTransform: "uppercase",
            borderBottom: "2px solid black",
            paddingBottom: 5,
          }}
        >
          {"5."} {t("The Future Of Work")}
        </Text>

        {cognitive && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.1"} {t("Cognitive")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.1"} {t("Communication")} -{" "}
                {t(cognitive["values"]["Communication"]["score"])}
              </Text>
              {cognitive["values"]["Communication"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that the talent has all the information he/she needs when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire."
                    )}
                  </Text>
                </>
              ) : cognitive["values"]["Communication"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that the talent has all the information he/she needs when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire."
                    )}
                  </Text>
                </>
              ) : cognitive["values"]["Communication"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that the talent has all the information he/she needs when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.2"} {t("Critical Thinking")} -{" "}
                {t(cognitive["values"]["Critical Thinking"]["score"])}
              </Text>
              {cognitive["values"]["Critical Thinking"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions talents well to access exciting opportunities in the world of work for the future. Being able tosolve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill."
                    )}
                  </Text>
                </>
              ) : cognitive["values"]["Critical Thinking"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to solve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill."
                    )}
                  </Text>
                </>
              ) : cognitive["values"]["Critical Thinking"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to solve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.3"} {t("Mental Flexibility")} -{" "}
                {t(cognitive["values"]["Mental Flexibility"]["score"])}
              </Text>
              {cognitive["values"]["Mental Flexibility"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change."
                    )}
                  </Text>
                </>
              ) : cognitive["values"]["Mental Flexibility"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change."
                    )}
                  </Text>
                </>
              ) : cognitive["values"]["Mental Flexibility"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.4"} {t("Planning and Ways of Working")} -{" "}
                {t(
                  cognitive["values"]["Planning and Ways of Working"]["score"]
                )}
              </Text>
              {
                cognitive["values"]["Planning and Ways of Working"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to plan and prioritise one’s work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that one stays on track, yet being flexible to switch tasks if required is also an important skill."
                    )}
                  </Text>
                </>
              ) : 
                  cognitive["values"]["Planning and Ways of Working"]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to plan and prioritise one’s work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that one stays on track, yet being flexible to switch tasks if required is also an important skill."
                    )}
                  </Text>
                </>
              ) : 
                  cognitive["values"]["Planning and Ways of Working"]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to plan and prioritise your work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that you stay on track, yet being flexible to switch tasks if required is also an important skill. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}

        {digital && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.2"} {t("Digital")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.1"} {t("Digital Fluency")} -{" "}
                {t(digital["values"]["Digital Fluency"]["score"])}
              </Text>
              {digital["values"]["Digital Fluency"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work."
                    )}
                  </Text>
                </>
              ) : digital["values"]["Digital Fluency"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work."
                    )}
                  </Text>
                </>
              ) : digital["values"]["Digital Fluency"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.2"} {t("Software Development")} -{" "}
                {t(digital["values"]["Software Development"]["score"])}
              </Text>
              {digital["values"]["Software Development"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
                    )}
                  </Text>
                </>
              ) : digital["values"]["Software Development"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
                    )}
                  </Text>
                </>
              ) : digital["values"]["Software Development"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If the talent thinks this could be significant for his/her career, he/she must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.3"} {t("Software Use")} -{" "}
                {t(digital["values"]["Software Use"]["score"])}
              </Text>
              {digital["values"]["Software Use"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to learn about, adapt to and integrate new software into one’s work, are important skills to some extent, in all careers in the future of work. If you think this could be significant for your career."
                    )}
                  </Text>
                </>
              ) : digital["values"]["Software Use"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to learn about, adapt to and integrate new software into one’s work, are important skills to some extent, in all careers in the future of work. If you think this could be significant for your career."
                    )}
                  </Text>
                </>
              ) : digital["values"]["Software Use"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to learn about, adapt to and integrate new software into one’s work, are important skills to some extent, in all careers in the future of work. If the talent thinks this could be significant for his/her career, he/she must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.4"} {t("Understanding Digital Systems")} -{" "}
                {t(digital["values"]["Understanding Digital Systems"]["score"])}
              </Text>
              {
                digital["values"]["Understanding Digital Systems"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
                    )}
                  </Text>
                </>
              ) : 
                  digital["values"]["Understanding Digital Systems"]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If you think this could be significant for your career."
                    )}
                  </Text>
                </>
              ) : 
                  digital["values"]["Understanding Digital Systems"]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if the talent sees digital skills as a core element of his/her chosen career. If the talent thinks this could be significant for his/her career, he/she must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}

        {selfLeadership && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.3"} {t("Self Leadership")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.1"} {t("Entrepreneurship")} -{" "}
                {t(selfLeadership["values"]["Entrepreneurship"]["score"])}
              </Text>
              {selfLeadership["values"]["Entrepreneurship"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards one’s work is important for one’s ability to secure employment, progress in his/her career and stay ahead of the competition."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Entrepreneurship"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards one’s work is important for one’s ability to secure employment, progress in his/her career and stay ahead of the competition."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Entrepreneurship"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to take appropriate risks, innovate, adapt to change and apply positive energy towards one’s work is important for one’s ability to secure employment, progress in his/her career and stay ahead of the competition. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.2"} {t("Goal Achievement")} -{" "}
                {t(selfLeadership["values"]["Goal Achievement"]["score"])}
              </Text>
              {selfLeadership["values"]["Goal Achievement"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to take responsibility for setting and working towards one’s goals, persevering, especially during periods of uncertainty and committing to one’s professional development is important for his/her ability to secure employment and progress in his/her career."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Goal Achievement"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to take responsibility for setting and working towards one’s goals, persevering, especially during periods of uncertainty and committing to one’s professional development is important for his/her ability to secure employment and progress in his/her career."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Goal Achievement"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to take responsibility for setting and working towards one’s goals, persevering, especially during periods of uncertainty and committing to one’s professional development is important for his/her ability to secure employment and progress in his/her career. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.3"} {t("Self-Awareness")} -{" "}
                {t(selfLeadership["values"]["Self-Awareness"]["score"])}
              </Text>
              {selfLeadership["values"]["Self-Awareness"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to understand one’s emotions, how they impact one and knowing how to calm oneself down when one is feeling stressed is important for his/her health, wellbeing, and his/her performance at work."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Self-Awareness"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to understand one’s emotions, how they impact one and knowing how to calm oneself down when one is feeling stressed is important for his/her health, wellbeing, and his/her performance at work."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Self-Awareness"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to understand one’s emotions, how they impact one and knowing how to calm oneself down when one is feeling stressed is important for his/her health, wellbeing, and his/her performance at work. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.4"} {t("Self-Management")} -{" "}
                {t(selfLeadership["values"]["Self-Management"]["score"])}
              </Text>
              {selfLeadership["values"]["Self-Management"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to look after one’s wellbeing, motivate oneself and make progress towards one’s goals is important for his/her ability to secure employment and progress in his/her career."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Self-Management"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to look after one’s wellbeing, motivate oneself and make progress towards one’s goals is important for his/her ability to secure employment and progress in his/her career."
                    )}
                  </Text>
                </>
              ) : selfLeadership["values"]["Self-Management"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to look after one’s wellbeing, motivate oneself and make progress towards one’s goals is important for his/her ability to secure employment and progress in his/her career. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}

        {interpersonal && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.4"} {t("Interpersonal")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.4.1"} {t("Coaching Others")} -{" "}
                {t(interpersonal["values"]["Coaching Others"]["score"])}
              </Text>
              {interpersonal["values"]["Coaching Others"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today."
                    )}
                  </Text>
                </>
              ) : interpersonal["values"]["Coaching Others"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today."
                    )}
                  </Text>
                </>
              ) : interpersonal["values"]["Coaching Others"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.4.2"} {t("Developing Relationships")} -{" "}
                {t(
                  interpersonal["values"]["Developing Relationships"]["score"]
                )}
              </Text>
              {
                interpersonal["values"]["Developing Relationships"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to have empathy for others by putting oneself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in one’s interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients."
                    )}
                  </Text>
                </>
              ) : 
                  interpersonal["values"]["Developing Relationships"]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to have empathy for others by putting oneself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in one’s interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients."
                    )}
                  </Text>
                </>
              ) : 
                  interpersonal["values"]["Developing Relationships"]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to have empathy for others by putting oneself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in one’s interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.4.3"} {t("Effective Teamwork")} -{" "}
                {t(interpersonal["values"]["Effective Teamwork"]["score"])}
              </Text>
              {interpersonal["values"]["Effective Teamwork"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace."
                    )}
                  </Text>
                </>
              ) : interpersonal["values"]["Effective Teamwork"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace."
                    )}
                  </Text>
                </>
              ) : interpersonal["values"]["Effective Teamwork"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.4.4"} {t("Mobilising Systems")} -{" "}
                {t(interpersonal["values"]["Mobilising Systems"]["score"])}
              </Text>
              {interpersonal["values"]["Mobilising Systems"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This skill positions the talent well to access exciting opportunities in the world of work for the future. Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of the talent is also significant."
                    )}
                  </Text>
                </>
              ) : interpersonal["values"]["Mobilising Systems"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "Developing this skill will position the talent well to access opportunities in the world of work for the future. Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of the talent is also significant."
                    )}
                  </Text>
                </>
              ) : interpersonal["values"]["Mobilising Systems"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(
                      "This is an area that requires some attention for the talent. Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of the talent is also significant. The talent must take every opportunity to develop these skills to enhance his/her prospects for the world of work in the future."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          width: "100%",
          marginTop: 14,
        }}
      >
        {cognitiveChartUrl !== null && (
          <View
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={cognitiveChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Cognitive")}
            </Text>
          </View>
        )}

        {digitalChartUrl !== null && (
          <View
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={digitalChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Digital")}
            </Text>
          </View>
        )}

        {selfLeadershipChartUrl !== null && (
          <View
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={selfLeadershipChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Self Leadership")}
            </Text>
          </View>
        )}

        {interpersonalChartUrl !== null && (
          <View
            style={{
              width: "50%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={interpersonalChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Interpersonal")}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
