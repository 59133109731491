import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import rightarrow from "../../assets/icons/arrow_right.png";
import {
  Image,
  NormalText,
  SpanText,
  SubHeading,
  ContentText,
  MainText,
  BoxWrapper,
} from "../../components/talentProfile/common";
import Skills1 from "../../assets/Saly-32skills1.png";
import { Col, Row, Container } from "react-bootstrap";
import WorkCompetencyFactorChart from "../studentOceanCharts/workCompetencyFactorChart";
import PersonalityAspectsChart from "../studentOceanCharts/personalityAspectsChart";
import ThirtyFacetsChart from "../studentOceanCharts/thirtyFacetsChart";
import OrganizationalFitFactor from "../studentOceanCharts/organizationalFitFactorChart";
import PredictiveReliabilityFactorChart from "../studentOceanCharts/predictiveReliabilityFactorChart";
import { Ad2StudentPersonalityProfile } from "../studentOceanCharts/studentPersonalityChart";
import { Ad2StudentWorkInterest } from "../studentOceanCharts/studentWorkInterestChart";
import { Ad2EmployabilityFactors } from "../studentOceanCharts/studentEmployabilityChart";
import { Ad2FutureOfWork } from "../studentOceanCharts/studentFutureOfWorkChart";
import { Ad2EnglishProficiency } from "../studentOceanCharts/studentEnglishChart";
import {
  getDataWorkEmployability,
  getDataWorkEnglishTest,
  getDataWorkFutureWorks,
  getDataWorkInterest,
  getDataWorkPersonality,
  getDataWorkValues,
  getMyCyOverall,
  getOverViewProfile,
  getTalentOceanReport,
  updateViewprofile,
} from "../../services/apiCalls";
import { useSnapshot } from "valtio";
import { jobProxy } from "./jobproxy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton } from "@material-ui/core";
import FiveFactorChartSlider from "../studentOceanCharts/fiveFactorSlider";
import { useTranslation } from "react-i18next";
import { Ad2StudentWorkValues } from "../studentOceanCharts/studentWorkValuesChart";
import { updateModal } from "../../reducers/detailModalReducer";
import StudentModal from "../../components/studentModal/studentModal";
import InterestProfileModal from "./modals/interestProfileModal/interestProfileModal";
import PreferencesModal from "./modals/preferencesModal/preferencesModal";
import EmployabilityFactorModal from "./modals/employabilityFactorModal/employabilityFactorModal";
import ValueProfileModal from "./modals/valueProfileModal/valueProfileModal";
import FutureLearningModal from "./modals/futureLearningModal/futureLearningModal";
import FutureWorkFactorModal from "./modals/futureWorkFactorModal/futureWorkFactorModal";
import EnglishProficiencyModal from "./modals/englishProficiencyModal/englishProficiencyModal";
import MotivationLevelModal from "./modals/motivationLevelModal/motivationLevelModal";
import DetailModal from "../../components/detailModal/detailModal"
import { useDispatch } from "react-redux";

const ModalContainer = styled(Container)`
  @media (min-width: 480px) {
    width: 80vw;
  }

  @media (min-width: 768px) {
    width: 100vw;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    width: 892px;
    // padding: 25px 70px;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: 892px;
    // padding: 25px 70px;
  }
`;
const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;


export const HRLine = styled.hr`
  border: 0.4px solid #202020;
  width: 1100px;
  height: 0px;
  @media (max-width: 500px) {
    width: 290px;
  }
  @media (min-width: 501px) and(max-width:768px) {
    width: 550px;
  }
  @media (min-width: 769px) and (max-width: 920px) {
    width: 600px;
  }
  @media (min-width: 921px) and (max-width: 1024px) {
    width: 450px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: 650px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 42px 0px 42px;
  gap: 24px;
  @media (max-width: 1200px) {
    padding: 16px;
    flex-direction: column;
  }
`;

const MainWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
`;
const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const HrLine = styled.div`
  width: 100%;

  height: 10px;
  border-bottom: 0.6px solid #c4c4c4;
`;
const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentCard = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  @media (max-width: 500px) {
    display: block;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 2fr 3fr;
  }
`;

const Card1 = styled.div`
  width: 260px;
  height: 280px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media (min-width: 481px) and (max-width: 768px) {
    width: auto;
    height: auto;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: auto;
    height: auto;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: auto;
    height: auto;
  }
`;

const Image1 = styled.img`
  width: 92px;
  height: 92px;
  margin: 0 auto;
`;

const CardHeader = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 115.3%;
  /* or 21px */
  text-align: center;
  letter-spacing: 0.01em;

  color: #434343;
  margin-bottom: 12px;
`;



const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 20px 0 20px;
  flex-flow: wrap;
  justify-content: space-around;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: grid;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;
export default function ViewProfileJob() {
  useSnapshot(jobProxy);
  const routeData = useLocation()
  const dispatch = useDispatch();
  const [modalMode, setModalMode] = useState("");

  const [dataUser, setUserdata] = useState({});
  const [workValues, setWorkValues] = useState([]);
  const [workValuesView, setWorkValuesView] = useState([]);
  const [workValuesLabels, setWorkValuesLabels] = useState([]);
  const [workValuesColors, setWorkValuesColors] = useState([]);
  const [workValuesData, setWorkValuesData] = useState([]);

  const [workInterest, setWorkInterest] = useState([]);
  const [workInterestView, setWorkInterestView] = useState([]);
  const [workInterestLabels, setWorkInterestLabels] = useState([]);
  const [workInterestColors, setWorkInterestColors] = useState([]);
  const [workInterestData, setWorkInterestData] = useState([]);

  const [workPersonality, setWorkPersonality] = useState([]);
  const [workPersonalityData, setWorkPersonalityData] = useState([]);
  const [workPersonalityView, setWorkPersonalityView] = useState([]);
  const [workPersonalityLabels, setWorkPersonalityLabels] = useState([]);
  const [workPersonalityColors, setWorkPersonalityColors] = useState([]);

  const [workEmployability, setWorkEmployability] = useState([]);
  const [workEmployabilityData, setWorkEmployabilityData] = useState([]);
  const [workEmployabilityView, setWorkEmployabilityView] = useState([]);
  const [workEmployabilityDetails, setWorkEmployabilityDetails] = useState([]);

  const [workFutureWorks, setWorkFutureWorks] = useState([]);
  const [workFutureWorksView, setWorkFutureWorksView] = useState([]);
  const [workFutureWorksData, setWorkFutureWorksData] = useState([]);

  const [workEnglishTest, setWorkEnglishTest] = useState([]);
  const [workEnglishTestView, setWorkEnglishTestView] = useState([]);

  const [userData, setUserData] = useState([]);
  const [talentData, setTalentData] = useState("");
  const [openAssessmentReport, setOpenAssessmentReport] = useState(true);
  const [openOceanReport, setOpenOceanReport] = useState(true);
  const { t, i18n } = useTranslation();
  const [openStudentHistory, setOpenStudentHistory] = useState(true);
  const [studentHistoryData, setStudentHistoryData] = useState([]);
  const [facetData, setFacetData] = useState([]);
  const [orgFitData, setOrgFitData] = useState([]);
  const [personalityAspectData, setPersonalityAspectData] = useState([]);
  const [reliabilityData, setReliabilityData] = useState([]);
  const [competencyData, setCompetencyData] = useState([]);
  const [profileDetails, setProfileDetails] = useState({});
  const navigate = useNavigate();
  const getDataValue = async (id) => {
    const { data } = await getDataWorkValues(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
      var datasValues = [];
      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        datasValues.push(data?.data[e]["values"]);

        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });
      setWorkValuesLabels(datasLabel);
      setWorkValues(datasValue);
      setWorkValuesColors(datasColor);
      setWorkValuesView(datasValues);
      setWorkValuesData(data?.data);
    }
  };

  console.log(routeData, "routeData")
  const getDataIntrest = async (id) => {
    const { data } = await getDataWorkInterest(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];

      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });
      setWorkInterestLabels(datasLabel);
      setWorkInterest(datasValue);
      setWorkInterestColors(datasColor);
      setWorkInterestView(data?.data);
      setWorkInterestData(data?.data);
    }
  };
  const getDataPersonality = async (id) => {
    const { data } = await getDataWorkPersonality(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];

      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });

      setWorkPersonalityData(data?.data);
      setWorkPersonalityLabels(datasLabel);
      setWorkPersonality(datasValue);
      setWorkPersonalityColors(datasColor);
      setWorkPersonalityView(data?.data);
    }
  };
  const getDataEmployability = async (id) => {
    const { data } = await getDataWorkEmployability(id);
    if (data?.status === 1) {
      console.clear();
      setWorkEmployabilityDetails(data);
      setWorkEmployabilityView(data?.data?.domains);
      setWorkEmployability(data?.data?.totalScore?.points);
      setWorkEmployabilityData(data?.data);
    }
  };

  const getDataFutureWorks = async (id) => {
    const { data } = await getDataWorkFutureWorks(id);
    if (data?.status === 1 && data?.data?.domains) {
      setWorkFutureWorksView(data?.data.domains);
      setWorkFutureWorks(data?.data.totalScore?.points);
      setWorkFutureWorksData(data?.data);
    }
  };
  const getDataEnglishTest = async (id) => {
    const { data } = await getDataWorkEnglishTest(id);
    if (data?.status === 1) {
      setWorkEnglishTestView(data?.data);
      setWorkEnglishTest(
        data?.data?.General && data?.data?.General.points !== undefined
          ? data?.data?.General.points
          : "0"
      );
    }
  };
  const getOceanReport = async (id) => {
    const { data } = await getTalentOceanReport(id);
    if (data?.status === 1) {
      const OCEANData = data?.data?.data[0];
      setFacetData(OCEANData["30 - Facets"]);
      setOrgFitData(OCEANData["Organizational Fit Factor"]);
      setPersonalityAspectData(OCEANData["Personality Aspects"]);
      setReliabilityData(OCEANData["Predictive Reliability Factor"]);
      setCompetencyData(OCEANData["Work Competency Factor"]);
    }
  };
  const updateviewprofile = async (id) => {
    const { data } = await updateViewprofile({ user_id: id });
  };
  const getUser = async (id) => {
    await getMyCv(id);
    await getProfileDetails(id);
    await getDataValue(id);
    await getDataIntrest(id);
    await getDataPersonality(id);
    await getDataEmployability(id);
    await getDataFutureWorks(id);
    await getDataEnglishTest(id);
    await getOceanReport(id); //this is static id for refren university
    await updateviewprofile(id);
  };
  const getMyCv = async (id) => {
    const { data } = await getMyCyOverall(id);
    if (data?.status === 1) {
      setUserdata(data.data);
    }
  };
  const getProfileDetails = async (id) => {
    const { data } = await getOverViewProfile(id);
    if (data?.status === 1) {
      setProfileDetails(data.data);
    }
  };
  useEffect(async () => {
    await getUser(jobProxy.userid);
  }, [jobProxy.userid]);
  const handleOpenOceanReport = () => {
    setOpenOceanReport(!openOceanReport);
  };
  const handleOpenAssessmentReport = () => {
    setOpenAssessmentReport(!openAssessmentReport);
  };
  const getModalContent = () => {
    switch (modalMode) {
      case "interest":
        return (
          <InterestProfileModal
            data={workValuesView}
            labels={workValuesLabels}
          />
        );
      case "preferences":
        return (
          <PreferencesModal
            result={workPersonalityData}
            data={workPersonality}
            labels={workPersonalityLabels}
            colors={workPersonalityColors}
          />
        );
      case "employability":
        return <EmployabilityFactorModal data={workEmployabilityDetails} />;
      case "valueProfile":
        return <ValueProfileModal data={workEmployabilityView} />;
      case "futureLearning":
        return <FutureWorkFactorModal data={workFutureWorksView} />;
      case "motivationLevel":
        return <MotivationLevelModal data={workInterestView} />;
      case "englishProficiency":
        return <EnglishProficiencyModal data={workEnglishTestView} />;
      default:
        break;
    }
  };
  return (
    <>
      <MainLayout>
        {routeData && routeData?.state?.source === "track" ?
          <div className="breadcrumb">
            <p onClick={() => navigate("/track-applications")} style={{ cursor: "pointer" }}>
              {t("Track Applications")}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>View Profile</p>
          </div>
          :
          <div className="breadcrumb">
            <p onClick={() => navigate("/jobs")} style={{ cursor: "pointer" }}>
              {t("Jobs")}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p
              onClick={() => navigate("/jobs/viewjobs")}
              style={{ cursor: "pointer" }}
            >
              {t("View Job")}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p
              onClick={() => navigate("/jobs/Filterjobs")}
              style={{ cursor: "pointer" }}
            >
              {t("View Applicant")}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>{t("View Profile")}</p>
          </div>
        }
        <div style={{ display: "flex" }}></div>

        <MainWrapper>
          <Wrapper>
            <Image src={dataUser?.users?.profile_pic} />
            <ProfileWrapper>
              <MainText style={{ marginBottom: "0px" }}>
                {dataUser?.users?.full_name}
              </MainText>
              <HRLine />
              <ContentCard>
                <SubHeading>{t("Scope of Study")}:</SubHeading>
                <ContentText>{dataUser?.users?.scope_abbr}</ContentText>
                <SubHeading>{t("Education Institution")}:</SubHeading>
                <ContentText>{profileDetails?.insti_name_abbr}</ContentText>
                <SubHeading>{t("Address")}:</SubHeading>
                <ContentText>
                  {dataUser?.users?.city_abbr}, {dataUser?.users?.state_abbr},{" "}
                  {dataUser?.users?.curr_country_abbr}{" "}
                  {dataUser?.users?.postalcode}
                </ContentText>
                <SubHeading>{t("Primary e-mail id")}:</SubHeading>
                <ContentText>{profileDetails?.email}</ContentText>
                <SubHeading>{t("Primary mobile no")}:</SubHeading>
                <ContentText>{dataUser?.users?.mob_number}</ContentText>
              </ContentCard>
            </ProfileWrapper>
          </Wrapper>
          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>{t("Summary")}</SubHeading>
            <hr />

            <ContentText>{dataUser?.summary?.summary}</ContentText>
          </BoxWrapper>

          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Employment Details")}
            </SubHeading>
            <hr />
            {dataUser?.employement_details?.map((i, index) => {
              return (
                <Section>
                  <div>
                    <NormalText>{i?.role}</NormalText>
                    <SubHeading
                      style={{ fontStyle: "italic", marginBottom: "0" }}
                    >
                      {i?.organization}
                    </SubHeading>
                    <SpanText>{i?.location}</SpanText>
                  </div>
                  <SpanText>{i?.duration_months} {t("Months")}</SpanText>
                </Section>
              );
            })}
          </BoxWrapper>
          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Internship Details")}
            </SubHeading>
            <hr />
            {dataUser?.internship_details?.map((list, index) => {
              return (
                <Section>
                  <div>
                    <NormalText>{list?.job_title}</NormalText>
                    <SubHeading
                      style={{ fontStyle: "italic", marginBottom: "0" }}
                    >
                      {list?.organization}
                    </SubHeading>
                    <SpanText>{list?.location}</SpanText>
                  </div>
                  <SpanText>{list?.duration_months} {t("Months")}</SpanText>
                </Section>
              );
            })}
          </BoxWrapper>
          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Educational Details")}
            </SubHeading>
            <hr />
            {dataUser?.education?.map((data, index) => {
              return (
                <div style={{ marginBottom: "36px" }}>
                  <NormalText>{data?.degree}</NormalText>
                  <SubHeading style={{ marginBottom: "0" }}>
                    {data?.institution}
                  </SubHeading>
                  <SpanText
                    style={{
                      fontStyle: "italic",
                      fontSize: "16px",
                      color: "#525252",
                      marginBottom: "12px",
                    }}
                  >
                    {t("Completion year")} {data?.completion_year}
                  </SpanText>
                  <ContentText>{data?.description}</ContentText>
                </div>
              );
            })}
          </BoxWrapper>

          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Events & Projects")}
            </SubHeading>
            <hr />
            {dataUser?.event_details?.map((link, index) => {
              return (
                <Section>
                  <div style={{ maxWidth: "70%" }}>
                    <NormalText>{link?.event_title}</NormalText>
                    <ContentText>{link?.description}</ContentText>
                  </div>
                </Section>
              );
            })}
          </BoxWrapper>

          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>{t("Skills")}</SubHeading>
            <hr />
            <CardsWrapper style={{ marginTop: "24px" }}>
              {dataUser?.skills?.map((card, idx) => (
                <Card1 key={idx} style={{ marginTop: "12px" }}>
                  <Image1 src={Skills1} />
                  <CardHeader>{card?.title}</CardHeader>
                </Card1>
              ))}
            </CardsWrapper>
          </BoxWrapper>
          <br />

          <SeparatorDiv onClick={handleOpenAssessmentReport}>
            <HrLine />
            <Separator>{t("Assessment Report")}</Separator>
            <HrLine />

            {openAssessmentReport ? (
              <IconButton>
                <UpIcon />
              </IconButton>
            ) : (
              <IconButton>
                <DownIcon />
              </IconButton>
            )}
          </SeparatorDiv>

          {openAssessmentReport ? (
            <Row>
              <Col lg="12" style={{ marginTop: "20px" }}>
                <Ad2StudentPersonalityProfile 
                  onViewMoreClick={() => {
                    setModalMode("preferences");
                    dispatch(updateModal(true));
                    console.log("Clicked personality details");
                  }}
                  data={workPersonalityData}
                />
              </Col>
              <Col lg="6" style={{ marginTop: "20px" }}>
                <Ad2StudentWorkValues
                    onViewMoreClick={() => {
                      setModalMode("interest");
                      dispatch(updateModal(true));
                      console.log("Clicked workvalue");
                    }}
                    data={workValuesData}
                  />
              </Col>
              <Col lg="6" style={{ marginTop: "20px" }}>
                <Ad2StudentWorkInterest 
                  onViewMoreClick={() => {
                    setModalMode("motivationLevel");
                    dispatch(updateModal(true));
                    console.log("Clicked motivation");
                  }}
                  data={workInterestData}
                />
              </Col>

              <Col lg="12" style={{ marginTop: "20px" }}>
                <Ad2EmployabilityFactors
                  data={workEmployabilityData}
                  onViewMoreClick={() => {
                    setModalMode("employability");
                    dispatch(updateModal(true));
                    console.log("Clicked employ");
                  }}
                ></Ad2EmployabilityFactors>
              </Col>
              <Col lg="12" style={{ marginTop: "20px" }}>
                <Ad2FutureOfWork 
                  onViewMoreClick={() => {
                    setModalMode("futureLearning");
                    dispatch(updateModal(true));
                    console.log("Clicked future");
                  }}
                  data={workFutureWorksData}>
                </Ad2FutureOfWork>
              </Col>
              <Col lg="12" style={{ marginTop: "20px", marginBottom: "20px" }}>
                <Ad2EnglishProficiency
                  onViewMoreClick={() => {
                    setModalMode("englishProficiency");
                    dispatch(updateModal(true));
                    console.log("Clicked english");
                  }}
                  data={workEnglishTestView}
                ></Ad2EnglishProficiency>
              </Col>
            </Row>
          ) : (
            ""
          )}
          <br />

          <SeparatorDiv onClick={handleOpenOceanReport}>
            <HrLine />
            <Separator>{t("OCEAN Report")}</Separator>
            <HrLine />

            {openOceanReport ? (
              <IconButton>
                <UpIcon />
              </IconButton>
            ) : (
              <IconButton>
                <DownIcon />
              </IconButton>
            )}
          </SeparatorDiv>

          {openOceanReport ? (
            <>
              <Row lg="12">
                <Col lg="6" style={{ marginTop: "20px" }}>
                  <WorkCompetencyFactorChart data={competencyData}>
                    {" "}
                  </WorkCompetencyFactorChart>
                </Col>
                <Col lg="6" style={{ marginTop: "20px" }}>
                  <PersonalityAspectsChart
                    data={personalityAspectData}
                  ></PersonalityAspectsChart>
                </Col>
              </Row>
              <Row lg="12">
                {/* <FiveFactorChartSlider data={workPersonalityData}/> */}
                <ThirtyFacetsChart data={facetData}> </ThirtyFacetsChart>
              </Row>
              <Row lg="12">
                {/* <Col lg="6" style={{ marginTop: "20px" }}>
                  <OrganizationalFitFactor
                    data={orgFitData}
                  ></OrganizationalFitFactor>
                </Col> */}
                <Col lg="6" style={{ marginTop: "20px" }}>
                  <PredictiveReliabilityFactorChart
                    data={reliabilityData}
                  ></PredictiveReliabilityFactorChart>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </MainWrapper>
      </MainLayout>
      <DetailModal>
        <ModalContainer>
          <Row>
            <Col>{getModalContent()}</Col>
          </Row>
        </ModalContainer>
      </DetailModal>
    </>
  );
}
