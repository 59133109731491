import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
  getUniversitiesMasterList,
  getCountriesMasterList,
  getScopeOfStudyMasterList,
  getSatesMasterList,
  getCitiesMasterListByCountry,
} from "../services/apiCalls";

export const fetchAllUniverisities = createAsyncThunk(
  "masterData/fetchAllUniverisities",
  async (_, thunkAPI) => {
    try {
      const response = await getUniversitiesMasterList();
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchAllCountries = createAsyncThunk(
  "masterData/fetchAllCountries",
  async (_, thunkAPI) => {
    try {
      const response = await getCountriesMasterList();
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchAllStatesByCountry = createAsyncThunk(
  "masterData/fetchAllStatesByCountry",
  async (_, thunkAPI) => {
    try {
      const response = await getSatesMasterList();
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchAllCitiesByCountry = createAsyncThunk(
  "masterData/fetchAllCitiesByCountry",
  async (_, thunkAPI) => {
    try {
      const response = await getCitiesMasterListByCountry(132);
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

export const fetchAllScopeOfStudies = createAsyncThunk(
  "masterData/fetchAllScopeOfStudies",
  async (_, thunkAPI) => {
    try {
      const response = await getScopeOfStudyMasterList();
      return response.data.data;
    } catch (e) {
      return thunkAPI.rejectWithValue(e.message);
    }
  }
);

const initialState = {
  universities: [],
  countries: [],
  states: [],
  cities: [],
  scopeOfStudies: [],
};

const masterDataSlice = createSlice({
  name: "master_data",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllUniverisities.pending, () => {})
      .addCase(fetchAllUniverisities.fulfilled, (state, action) => {
        state.universities = action.payload;
      })
      .addCase(fetchAllUniverisities.rejected, () => {})
      .addCase(fetchAllCountries.pending, () => {})
      .addCase(fetchAllCountries.fulfilled, (state, action) => {
        state.countries = action.payload;
      })
      .addCase(fetchAllCountries.rejected, () => {})
      .addCase(fetchAllStatesByCountry.pending, () => {})
      .addCase(fetchAllStatesByCountry.fulfilled, (state, action) => {
        state.states = action.payload;
      })
      .addCase(fetchAllStatesByCountry.rejected, () => {})
      .addCase(fetchAllCitiesByCountry.pending, () => {})
      .addCase(fetchAllCitiesByCountry.fulfilled, (state, action) => {
        state.cities = action.payload;
      })
      .addCase(fetchAllCitiesByCountry.rejected, () => {})
      .addCase(fetchAllScopeOfStudies.pending, () => {})
      .addCase(fetchAllScopeOfStudies.fulfilled, (state, action) => {
        state.scopeOfStudies = action.payload;
      })
      .addCase(fetchAllScopeOfStudies.rejected, () => {});
  },
});

export default masterDataSlice.reducer;
