import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import About from "../../components/companyBranding/about";
import Profile from "../../components/companyBranding/profile";
import CompanyPhotos from "../../components/companyBranding/companyPhotos";
import Articles from "../../components/companyBranding/articles";
import Button from "../../components/button/button";
import Page from "../../components/companyBranding/pageTheme";
import BrandingPopup from "../../components/companyBranding/popup";
import { toast } from "react-toastify";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getCompanyBranding,
  updateCompanyBranding,
  createCompanyBranding,
} from "../../services/apiCalls";
import { t } from "i18next";
import ErrorField from "../../components/errorField/errorField";

const HeaderText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  padding: 18px 0px;
`;
const MainWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  border-radius: 10px;
  padding: 24px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: space-around;
  max-width: 480px;
  margin-left: auto;
  @media (max-width: 480px) {
    flex-direction: column;
    align-items: center;
  }
`;
const ErrorDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  top: -19px;
`;
export default function CompanyBrandingEdit() {
  const [popup, setPopup] = useState(false);
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [about, setAbout] = useState("");
  const [profileDes, setProfileDesc] = useState("");
  const [id, setId] = useState(null);
  const [brandingDetails, setBrandingDetails] = useState({});
  let company = useSelector((state) => state.company.currentOrgData);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const setOpen = () => {
    setPopup(false);
  };
  const proceed = () => {
    setPopup(false);
  };
  const submit = () => {
    setPopup(false);
    applyChanges();
   
  };
  const setPrimaryColor = (e) => {
    if (e.hex === undefined) {
      setPrimary(e.currentTarget.value);
    } else {
      setPrimary(e.hex);
    }
  };
  const setSecondaryColor = (e) => {
    if (e.hex === undefined) {
      setSecondary(e.currentTarget.value);
    } else {
      setSecondary(e.hex);
    }
  };
  const onVideoUrl = (e) => {
    setVideoUrl(e.currentTarget.value);
    const { value } = e.currentTarget;
    const regex =
      /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    if (!regex.test(value)) {
      setError({
        message: t("please enter the correct url eg:https://www.google.com"),
      });
    } else {
      setError(null);
    }
  };
  const getCompanyBrandingDetails = async (org_code) => {
    const details = await getCompanyBranding({ code: org_code });
    if (details?.status == 200 && details?.data && !isEmpty(details?.data)) {
      setPrimary(details?.data?.primary_color);
      setSecondary(details?.data?.secondary_color);
      setVideoUrl(details?.data?.video_url);
      setAbout(details?.data?.about);
      setProfileDesc(details?.data?.video_description);
      setId(details?.data?.id);
    } else if (
      details?.status == 200 &&
      details?.data?.status == 1 &&
      !details?.data?.data?.id
    ) {
      let payload = {
        primary_color: "",
        secondary_color: "",
        video_url: "",
        code: company?.org_code,
      };

      let data = await createCompanyBranding(payload);
      if (data?.status == 200) {
        //getCompanyBrandingDetails(company?.org_code)
        const orgBrandingDetails = await getCompanyBranding({ code: org_code });
        if (orgBrandingDetails?.status == 200 && orgBrandingDetails?.data) {
          setId(orgBrandingDetails?.data?.id);
        }
      }
    }
  };

  const applyChanges = async () => {
    let payload = {
      primary_color: primary || "",
      secondary_color: secondary || "",
      video_url: videoUrl || "",
      about: about || "",
      video_description: profileDes || "",
      code: company?.org_code,
    };
    if (id) payload["id"] = id;
    let addData;
    if (id) {
      addData = await updateCompanyBranding(payload);
    } else {
      addData = await createCompanyBranding(payload);
    }

    if (addData?.status == 200) {
      getCompanyBrandingDetails(company?.org_code);
      navigate("/companybranding");
      return toast.success(t("Changes Applied Succesfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return toast.error(t("Failed While Applying Changes"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  useEffect(() => {
    if (company?.org_code) {
      getCompanyBrandingDetails(company?.org_code);
    }
  }, [company]);

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  function handleCancel() {
    navigate("/companybranding");
  }

  const confirmAbout = (e) => {
    setAbout(e.currentTarget.value);
  };
  const confirmVideo = (e) => {
    setProfileDesc(e.currentTarget.value);
  };
  return (
    <>
      {popup ? (
        <BrandingPopup
          isOpen={popup}
          setOpen={setOpen}
          proceed={proceed}
          submitForm={submit}
        />
      ) : (
        ""
      )}
      <MainLayout>
        <HeaderText>{t("Company Branding")}</HeaderText>
        <MainWrapper>
          <Page
            primaryColor={primary}
            setPrimary={setPrimaryColor}
            secondaryColor={secondary}
            setSecondary={setSecondaryColor}
          />
          <About aboutName={about} setAboutDetail={confirmAbout} />
          <Profile
            onVideoUrl={onVideoUrl}
            url={videoUrl}
            dataVideo={profileDes}
            setVideoDetail={confirmVideo}
          />
          <ErrorDiv>{error && <ErrorField err={t(error.message)} />}</ErrorDiv>
          <CompanyPhotos />
          <Articles />
          <ButtonWrapper>
            <Button onClick={() => setPopup(true)} name={t("Apply-companybranding")} />
            <Button onClick={() => handleCancel()} secondary name={t("Cancel")} />
          </ButtonWrapper>
        </MainWrapper>
      </MainLayout>
    </>
  );
}
