export const messagesData = {
    "talent": {
        "messages": [{
            key: "what is mynext company",
            value: "Mynext Company supports the national workforce by integrating talent solutions and analytics onto one platform, with a focus on bridging the gap between Malaysia’s talent supply to future jobs via TalentCorp-endorsed internship programmes.",
        }, {
            key: "apa itu mynext company",
            value: "Mynext Company menyokong tenaga kerja negara dengan menyepadukan penyelesaian bakat dan analitik pada satu platform, dengan tumpuan untuk merapatkan jurang antara pembekalan bakat Malaysia kepada pekerjaan masa depan melalui program latihan industri yang disokong oleh TalentCorp.",
        }, {
            key: "how much internship posting",
            value: "To get the latest pricing of our platform, please contact our customer service at company@mynext.my",
        }, {
            key: "harga menggunakan mynext",
            value: "Untuk mendapatkan harga terkini platform kami, sila hubungi perkhidmatan pelanggan kami di company@mynext.my",
        }, {
            key: "price pricing",
            value: "To get the latest pricing of our platform, please contact our customer service at company@mynext.my",
        }, {
            key: "how long expiry",
            value: "Upon successful payment, your job posting will be advertised via the mynext platform based on your selected Start and End date, with a duration based on your subscription plan.",
        }, {
            key: "tempoh pengiklanan",
            value: "Setelah pembayaran berjaya, iklan kerja anda akan diiklankan melalui platform mynext berdasarkan tarikh Mula dan Tamat yang anda pilih, dengan tempoh berdasarkan pelan langganan anda.",
        }, {
            key: "tax deduction outside mynext",
            value: "To qualify for the double tax deduction through the National Structured Internship Programme (MySIP), you may proceed to create a job posting on mynext Company platform and request your offered interns to apply for the job posting on mynext Talent.",
        }, {
            key: "potongan cukai berganda",
            value: "Untuk melayakkan anda mendapat potongan cukai berganda melalui Program Latihan Industri Berstruktur Nasional (MySIP), anda boleh teruskan membuat siaran pekerjaan di platform Mynext Company dan meminta pelatih yang ditawarkan untuk memohon jawatan di Mynext Talent.",
        }, {
            key: "edit update company info",
            value: "Details such as company registration number can be changed from our end upon you providing us with the relevant SSM documentation in order for us to validate and verify your company before proceeding with the update, you may email to company@mynext.my to make the request.",
        }, {
            key: "kemaskini info syarikat",
            value: "Pihak kami boleh mengemaskini butiran seperti nombor pendaftaran syarikat selepas anda mengemukakan dokumentasi SSM yang berkaitan untuk pengesahan. Anda boleh menghantar e-mel kepada company@mynext.my untuk membuat permohonan ini.",
        }, {
            key: "evaluation link letter of endorsement LOE",
            value: "For us to assist you better, kindly email us at company@mynext.my with the intern details and also the internship posting title that they had completed so we can further check on the matter. Please take note that the evaluation links will only be provided to your company and intern 10 days prior to your internship posting end date.",
        }, {
            key: "pautan penilaian surat sokongan",
            value: "Untuk kami membantu anda dengan lebih baik, sila e-mel kami di company@mynext.my dengan butiran pelatih dan juga tajuk siaran latihan yang telah mereka selesaikan supaya kami boleh menyemak lebih lanjut mengenai perkara itu. Sila ambil perhatian bahawa pautan penilaian hanya akan diberikan kepada syarikat dan pelatih anda 10 hari sebelum tarikh tamat pengeposan latihan amali anda.",
        }, {
            key: "not listed",
            value: "If your intern is not able to register to mynext Talent because their institution is not listed, kindly contact us at company@mynext.my and provide the following details: Institution name, campus, faculty, study programme. We may then proceed to process your request accordingly.",

        }, {
            key: "tidak disenaraikan",
            value: "Jika pelatih anda tidak dapat mendaftar ke mynext Talent kerana institusi mereka tidak disenaraikan, sila hubungi kami di company@mynext.my dan berikan butiran berikut: Nama institusi, kampus, fakulti, program pengajian. Kami kemudiannya boleh meneruskan proses permintaan anda dengan sewajarnya.",
        }, {
            key: "page not working",
            value: "Apologies for the difficulties you are facing on the platform. To fix this issue, please try the following suggestions. 1. Refresh the page and try again. 2. Close the tab and reopen the platform in a new tab. If the issue persists, do reach out to us at company@mynext.my for further assistance.",
        }, {
            key: "tidak berfungsi",
            value: "Maaf atas kesulitan yang anda hadapi pada platform. Untuk menyelesaikan isu ini, sila cuba cadangan berikut. 1. Muat semula halaman dan cuba lagi. 2. Tutup tab dan buka semula platform dalam tab baharu. Jika isu itu berterusan , hubungi kami di company@mynext.my untuk bantuan lanjut.",
        }, {
            key: "change update company name",
            value: "In order to update your company's name, kindly email us at company@mynext.my with the following details and we will get back to you with an update at the soonest. 1. Old company name 2. New company name 3. SSM registration number & ROC 4. SSM registration date",
        }, {
            key: "kemas kini nama syarikat",
            value: "Untuk mengemas kini nama syarikat anda, sila e-mel kami di company@mynext.my dengan butiran berikut dan kami akan menghubungi anda semula dengan kemas kini secepat mungkin. 1. Nama syarikat lama 2. Nama syarikat baharu 3. Pendaftaran SSM nombor & ROC 4. Tarikh pendaftaran SSM",
        }, {
            key: "remove contact person",
            value: "You may provide us with the details of the user that you wish to remove and we will assist to remove it for you accordingly. Kindly note that once we deleted the contact, all information related to it will be deleted as well.",
        }, {
            key: "padam pengguna",
            value: "Anda boleh memberikan kami butiran pengguna yang ingin anda alih keluar dan kami akan membantu mengalih keluarnya untuk anda sewajarnya. Harap maklum bahawa sebaik sahaja kami memadamkan kenalan itu, semua maklumat yang berkaitan dengannya akan dipadamkan juga.",
        }, {
            key: "how register",
            value: "You may refer to our mynext Company User Guide for the step-by-step guide on how to register for mynext at the link here https://www.mynext.my/assets/pdf/mynext-30-Company-User-Guide.pdf",
        }, {
            key: "cara untuk daftar",
            value: "Anda boleh merujuk kepada Panduan Pengguna mynext Company kami untuk proses langkah demi langkah tentang cara mendaftar mynext di sini: https://www.mynext.my/assets/pdf/mynext-30-Company-User-Guide.pdf",
        }, {
            key: "issue enquiry enquiries",
            value: "You may contact the mynext Team by dropping us a message at www.mynext.my/contact and we will gladly assist you with any issues or inquiries. You may also call our hotline at 03-7839 7111.",
        }, {
            key: "isu pertanyaan",
            value: "Anda boleh menghubungi mynext Team dengan menghantar mesej kepada kami di www.mynext.my/contact dan kami akan membantu anda dengan sebarang isu atau pertanyaan. Anda juga boleh menghubungi talian hotline kami di 03-7839 7111.",
        },
        ]
    },
    "company": {
        "messages": []
    },
    "university": {
        "messages": []
    }

}