export const AD2_CONSTANTS = {
    MASTERY_DESCRIPTION: 'The Mastery domain contains the values Intellectual stimulation, Recognition, Achievement, Independence. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
    QUALITY_OF_LIFE_DESCRIPTION: 'The Quality of Life domain contains the values Variety, Security, Way of Life, Surroundings, and Economic Return. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
    FELLOWSHIP_DESCRIPTION: 'The Fellowship domain contains the values Altruism, Supervisory Relationship, Associates, Belonging, and Family. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
    CREATIVE_VALUES_DESCRIPTION: 'The Creating Value domain contains the values Aesthetic, Creativity, Leadership, and Protecting the Planet. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
    IDEAS_AND_OPPORTUNITIES_DESCRIPTION: 'The Ideas and Opportunities domain contains the factors Spotting Opportunities, Creativity, Valuing ideas, Vision, and Ethical and Sustainable Thinking',
    RESOURCES_DESCRIPTION: 'The Resources domain contains the factors Self-efficacy, Self-awareness, Financial and economic literacy, Mobilising others, and Mobilising Resources.',
    INTO_ACTION_DESCRIPTION: 'The Into Action domain contains the factors Motivation and perseverance, Working with others, Planning and management, Learning through Experience, Coping with uncertainty, Taking the initiative.',
}

export const TITLE_MAP = {
    "analytical-dashboard": "Analytical Dashboard",
    "personality-and-motivation": "Personality & Motivation",
    "work-interest": "Work Interest",
    "work-values": "Work Values",
    // student population routes
    'employee-registered': 'Student Population (Onboarded)',
    'employee-not-registered': 'Student Population (Not Registered)',
    // internship pyramid
    'completed-internships': 'Internships (Completed)',
    'pending-internships': 'Internships (Pending)',
    'secured-internships': 'Internships (Secured)',
    'available-internships': 'Internships (Available)',
    // internship industry/sector
    'internship-industry-sector': 'Internship Industry/Sector $sector',
    // personality and motivation
    'emotional-stability': 'Emotional Stability $label',
    'extraversion': 'Extraversion $label',
    'openness-to-experience': 'Openness to Experience $label',
    'agreeableness': 'Agreeableness $label',
    'conscientiousness': 'Conscientiousness $label',
    // work values
    'mastery': 'Mastery $label',
    'mastery-drilldown': 'Mastery',
    'quality-of-life': 'Quality of Life $label',
    'quality-of-life-drilldown': 'Quality of Life',
    'fellowship': 'Fellowship $label',
    'fellowship-drilldown': 'Fellowship',
    'creating-value': 'Creating Value $label',
    'creating-value-drilldown': 'Creating Value',
    "intellectual-stimulation": "Intellectual Stimulation $label",
    "recognition": "Recognition $label",
    "achievement": "Achievement $label",
    "independence": "Independence $label",
    "variety": "Variety $label",
    "security": "Security $label",
    "way-of-life": "Way of Life $label",
    "surroundings": "Surroundings $label",
    "economic-return": "Economic Return $label",
    "altruism": "Altruism $label",
    "supervisory-relationship": "Supervisory Relationship $label",
    "associates": "Associates $label",
    "belonging": "Belonging $label",
    "family": "Family $label",
    "aesthetic": "Aesthetic $label",
    "creativity": "Creativity $label",
    "leadership": "Leadership $label",
    "protecting-the-planet": "Protecting the Planet $label",
    // employability
    'employability': 'Employability',
    'ideas-and-opportunities': "Ideas and Opportunities $label",
    'ideas-and-opportunities-drilldown': "Ideas and Opportunities",
    "spotting-opportunities": "Spotting Opportunities $label",
    "valuing-ideas": "Valuing Ideas $label",
    "vision": "Vision $label",
    "ethical-and-sustainable-thinking": "Ethical and Sustainable Thinking $label",
    'resources': "Resources $label",
    'resources-drilldown': "Resources",
    "self-efficacy": "Self-Efficacy $label",
    "financial-and-economic-literacy": "Financial and Economic Literacy $label",
    "mobilising-others": "Mobilising Others $label",
    "mobilising-resources": "Mobilising Resources $label",
    'into-action': "Into Action $label",
    'into-action-drilldown': "Into Action",
    "motivation-and-perseverance": "Motivation and Perseverance $label",
    "working-with-others": "Working with Others $label",
    "planning-and-management": "Planning and Management $label",
    "learning-through-experience": "Learning Through Experience $label",
    "coping-with-uncertainty-ambiguity-and-risk": "Coping with Uncertainty Ambiguity and Risk $label",
    "taking-the-initiative": "Taking the Initiative $label",
    "future-of-work": "Future Of Work",
    "cognitive": "Cognitive $label",
    "cognitive-drilldown": "Cognitive",
    "critical-thinking": "Critical Thinking $label",
    "planning-and-ways-of-working": "Planning and Ways of Working $label",
    "communication": "Communication $label",
    "mental-flexibility": "Mental Flexibility $label",
    "interpersonal": "Interpersonal $label",
    "interpersonal-drilldown": "Interpersonal",
    "mobilising-systems": "Mobilising Systems $label",
    "developing-relationships": "Developing Relationships $label",
    "effective-teamwork": "Effective Teamwork $label",
    "coaching-others": "Coaching Others $label",
    "self-leadership-action": "Self Leadership $label",
    "self-leadership": "Self Leadership $label",
    "self-leadership-drilldown": "Self Leadership",
    "self-awareness": "Self-Awareness $label",
    "self-management": "Self-Management $label",
    "entrepreneurship": "Entrepreneurship $label",
    "goal-achievement": "Goal Achievement $label",
    "digital": "Digital $label",
    "digital-drilldown": "Digital",
    "digital-fluency": "Digital Fluency $label",
    "software-use": "Software Use $label",
    "software-development": "Software Development $label",
    "understanding-digital-systems": "Understanding Digital Systems $label",
    "english-proficiency": "English Proficiency",
    "english-proficiency-drilldown": "English Proficiency $label",
    "comprehension": "Comprehension $label",
    "grammar": "Grammar $label",
    "student/{id}": "$name",

};

const INTERNSHIP_URLS = {
    genderRatio: 'dashboard/university/analytical/demographic/student-gender-ratio-by-internship-status/',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/',
};

const PERSONALITY_AND_MOTIVATION_URLS = {
    genderRatio: 'api/dashboard/company/analytical/gender-ratio/?type_of_assessment=personality_motivation',
table: 'api/dashboard/company/analytical/results/assessment-get-by-type/?assessment-type=personality-and-motivation',
};

const MASTERY_URLS = {
    genderRatio: 'dashboard/analytical/work-values/gender-ratio/?type_of_sub_assessment=mastery',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
};

const QUALITY_OF_LIFE_URLS = {
    genderRatio: 'dashboard/analytical/work-values/gender-ratio/?type_of_sub_assessment=quality_of_life',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
};

const FELLOWSHIP_URLS = {
    genderRatio: 'dashboard/analytical/work-values/gender-ratio/?type_of_sub_assessment=fellowship',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
};

const CREATING_VALUE_URLS = {
    genderRatio: 'dashboard/analytical/work-values/gender-ratio/?type_of_sub_assessment=creating_value',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
};

const IDEAS_AND_OPPORTUNITIES_URLS = {
    genderRatio: 'dashboard/analytical/employability/gender-ratio/?type_of_sub_assessment=ideas_and_opportunities',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=employability',
};

const RESOURCES_URLS = {
    genderRatio: 'dashboard/analytical/employability/gender-ratio/?type_of_sub_assessment=resources',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=employability',
};

const INTO_ACTION_URLS = {
    genderRatio: 'dashboard/analytical/employability/gender-ratio/?type_of_sub_assessment=into_action',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=employability',
};

const COGNITIVE_URLS = {
    genderRatio: 'dashboard/analytical/future-of-work/gender-ratio/?type_of_assessment=future_of_work&type_of_sub_assessment=cognitive',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=future-of-work',
};

const INTERPERSONAL_URLS = {
    genderRatio: 'dashboard/analytical/future-of-work/gender-ratio/?type_of_assessment=future_of_work&type_of_sub_assessment=interpersonal',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=future-of-work',
};

const SELF_LEADERSHIP_URLS = {
    genderRatio: 'dashboard/analytical/future-of-work/gender-ratio/?type_of_assessment=future_of_work&type_of_sub_assessment=self_leadership',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=future-of-work',
};

const DIGITAL_URLS = {
    genderRatio: 'dashboard/analytical/future-of-work/gender-ratio/?type_of_assessment=future_of_work&type_of_sub_assessment=digital',
    table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=future-of-work',
};

export const DRILLDOWN_API_MAP = {
    'employee-registered': {
        genderRatio: 'api/dashboard/company/analytical/demographic/total-employees-by-registration-date/',
        table: 'api/dashboard/company/analytical/demographic/registered-employees-list/',
    },
    'employee-not-registered': {
        genderRatio: 'dashboard/university/analytical/demographic/total-un-registered-students-by-registration-date/',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/',
    },
    'completed-internships': INTERNSHIP_URLS,
    'pending-internships': INTERNSHIP_URLS,
    'secured-internships': INTERNSHIP_URLS,
    'available-internships': INTERNSHIP_URLS,
    'internship-industry-sector': {
        genderRatio: 'dashboard/university/analytical/demographic/get-students-gender-ratio-by-internship-sector/',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/',
    },
    'personality-and-motivation': PERSONALITY_AND_MOTIVATION_URLS,
    'emotional-stability': PERSONALITY_AND_MOTIVATION_URLS,
    'extraversion': PERSONALITY_AND_MOTIVATION_URLS,
    'openness-to-experience': PERSONALITY_AND_MOTIVATION_URLS,
    'agreeableness': PERSONALITY_AND_MOTIVATION_URLS,
    'conscientiousness': PERSONALITY_AND_MOTIVATION_URLS,
    'work-interest': {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_interests',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-interest',
    },
    'work-values': {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'mastery-drilldown': MASTERY_URLS,
    'mastery': MASTERY_URLS,
    'intellectual-stimulation': {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'recognition': {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'achievement': {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'independence': {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'quality-of-life': QUALITY_OF_LIFE_URLS,
    'quality-of-life-drilldown': QUALITY_OF_LIFE_URLS,
    "variety": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "security": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "way-of-life": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "surroundings": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "economic-return": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'fellowship': FELLOWSHIP_URLS,
    'fellowship-drilldown': FELLOWSHIP_URLS,
    "altruism": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "supervisory-relationship": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "associates": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "belonging": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "family": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    'creating-value': CREATING_VALUE_URLS,
    'creating-value-drilldown': CREATING_VALUE_URLS,
    "aesthetic": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "creativity": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "leadership": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },
    "protecting-the-planet": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=work_values',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=work-values',
    },

    "employability": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=employability',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=employability',
    },
    "ideas-and-opportunities": IDEAS_AND_OPPORTUNITIES_URLS,
    'ideas-and-opportunities-drilldown': IDEAS_AND_OPPORTUNITIES_URLS,
    "spotting-opportunities": {
        genderRatio: IDEAS_AND_OPPORTUNITIES_URLS.genderRatio,
        table: IDEAS_AND_OPPORTUNITIES_URLS.table,
    },
    "creativity": {
        genderRatio: IDEAS_AND_OPPORTUNITIES_URLS.genderRatio,
        table: IDEAS_AND_OPPORTUNITIES_URLS.table,
    },
    "valuing-ideas": {
        genderRatio: IDEAS_AND_OPPORTUNITIES_URLS.genderRatio,
        table: IDEAS_AND_OPPORTUNITIES_URLS.table,
    },
    "vision": {
        genderRatio: IDEAS_AND_OPPORTUNITIES_URLS.genderRatio,
        table: IDEAS_AND_OPPORTUNITIES_URLS.table,
    },
    "ethical-and-sustainable-thinking": {
        genderRatio: IDEAS_AND_OPPORTUNITIES_URLS.genderRatio,
        table: IDEAS_AND_OPPORTUNITIES_URLS.table,
    },
    "resources": RESOURCES_URLS,
    'resources-drilldown': RESOURCES_URLS,
    "self-awareness": {
        genderRatio: RESOURCES_URLS.genderRatio,
        table: RESOURCES_URLS.table,
    },
    "self-efficacy": {
        genderRatio: RESOURCES_URLS.genderRatio,
        table: RESOURCES_URLS.table,
    },
    "financial-and-economic-literacy": {
        genderRatio: RESOURCES_URLS.genderRatio,
        table: RESOURCES_URLS.table,
    },
    "mobilising-others": {
        genderRatio: RESOURCES_URLS.genderRatio,
        table: RESOURCES_URLS.table,
    },
    "mobilising-resources": {
        genderRatio: RESOURCES_URLS.genderRatio,
        table: RESOURCES_URLS.table,
    },
    "into-action": INTO_ACTION_URLS,
    'into-action-drilldown': INTO_ACTION_URLS,
    "motivation-and-perseverance": {
        genderRatio: INTO_ACTION_URLS.genderRatio,
        table: INTO_ACTION_URLS.table,
    },
    "working-with-others": {
        genderRatio: INTO_ACTION_URLS.genderRatio,
        table: INTO_ACTION_URLS.table,
    },
    "planning-and-management": {
        genderRatio: INTO_ACTION_URLS.genderRatio,
        table: INTO_ACTION_URLS.table,
    },
    "learning-through-experience": {
        genderRatio: INTO_ACTION_URLS.genderRatio,
        table: INTO_ACTION_URLS.table,
    },
    "coping-with-uncertainty-ambiguity-and-risk": {
        genderRatio: INTO_ACTION_URLS.genderRatio,
        table: INTO_ACTION_URLS.table,
    },
    "taking-the-initiative": {
        genderRatio: INTO_ACTION_URLS.genderRatio,
        table: INTO_ACTION_URLS.table,
    },
    "future-of-work": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=future_of_work',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=future-of-work',
    },
    "cognitive": COGNITIVE_URLS,
    "cognitive-drilldown": COGNITIVE_URLS,
    "critical-thinking": {
        genderRatio: COGNITIVE_URLS.genderRatio, // missing
        table: COGNITIVE_URLS.table,
    },
    "planning-and-ways-of-working": {
        genderRatio: COGNITIVE_URLS.genderRatio, // missing
        table: COGNITIVE_URLS.table,
    },
    "communication": {
        genderRatio: COGNITIVE_URLS.genderRatio, // missing
        table: COGNITIVE_URLS.table,
    },
    "mental-flexibility": {
        genderRatio: COGNITIVE_URLS.genderRatio, // missing
        table: COGNITIVE_URLS.table,
    },
    "interpersonal": INTERPERSONAL_URLS,
    "interpersonal-drilldown": INTERPERSONAL_URLS,
    "mobilising-systems": {
        genderRatio: INTERPERSONAL_URLS.genderRatio, // missing
        table: INTERPERSONAL_URLS.table,
    },
    "developing-relationships": {
        genderRatio: INTERPERSONAL_URLS.genderRatio, // missing
        table: INTERPERSONAL_URLS.table,
    },
    "effective-teamwork": {
        genderRatio: INTERPERSONAL_URLS.genderRatio, // missing
        table: INTERPERSONAL_URLS.table,
    },
    "coaching-others": {
        genderRatio: INTERPERSONAL_URLS.genderRatio, // missing
        table: INTERPERSONAL_URLS.table,
    },
    "self-leadership": SELF_LEADERSHIP_URLS,
    "self-leadership-drilldown": SELF_LEADERSHIP_URLS,
    "self-awareness": {
        genderRatio: SELF_LEADERSHIP_URLS.genderRatio, // missing
        table: SELF_LEADERSHIP_URLS.table,
    },
    "self-management": {
        genderRatio: SELF_LEADERSHIP_URLS.genderRatio, // missing
        table: SELF_LEADERSHIP_URLS.table,
    },
    "entrepreneurship": {
        genderRatio: SELF_LEADERSHIP_URLS.genderRatio, // missing
        table: SELF_LEADERSHIP_URLS.table,
    },
    "goal-achievement": {
        genderRatio: SELF_LEADERSHIP_URLS.genderRatio, // missing
        table: SELF_LEADERSHIP_URLS.table,
    },
    "digital": DIGITAL_URLS,
    "digital-drilldown": DIGITAL_URLS,
    "digital-fluency": {
        genderRatio: DIGITAL_URLS.genderRatio, // missing
        table: DIGITAL_URLS.table,
    },
    "software-use": {
        genderRatio: DIGITAL_URLS.genderRatio, // missing
        table: DIGITAL_URLS.table,
    },
    "software-development": {
        genderRatio: DIGITAL_URLS.genderRatio, // missing
        table: DIGITAL_URLS.table,
    },
    "understanding-digital-systems": {
        genderRatio: DIGITAL_URLS.genderRatio, // missing
        table: DIGITAL_URLS.table,
    },
    "english-proficiency": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=english_proficiency',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=english-test',
    },
    "english-proficiency-drilldown": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=english_proficiency',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=english-test',
    },
    "grammar": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=english_proficiency',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=english-test',
    },
    "comprehension": {
        genderRatio: 'dashboard/analytical/gender-ratio/?type_of_assessment=english_proficiency',
        table: 'dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=english-test',
    },
}