import { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import Button from "../button/button";
import FormErrors from "../formErrors/formErrors";
import Input from "../input/input";
import { SelectorWrapper } from "../button/commonStyles";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../loader/loader";
import { isEmpty } from "../../utilities/commonFunctions";
import { onChangePassword } from "../../services/apiCalls"
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";


const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 0.4px solid #202020;
  .changeText {
    font-family: General Sans;
    font-weight: 500;
    font-size: 14px;
    color: #d04c8d;
    cursor: pointer;
  }
`;

const SubText = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #202020;
`;
const SubHeadingText = styled(SubText)`
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;

const LabelHeading = styled(SubHeadingText)`
  font-size: 16px;
  color: #000000;
  margin-bottom: 12px;
`;

// const ButtonGroup = styled.div`
// display:flex;
// justify-content:space-between;
// flex-wrap:wrap;
// `;

const ContentText = styled(SubText)`
  font-size: 14px;
  line-height: 17px;
  color: #878787;
`;

export default function SettingsChangePassword() {
  // const message = useSelector((state) => state.message?.message);
  // const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const formSchema = Yup.object().shape({
    currentPassword: Yup.string().required("Current password is required"),
    newPassword: Yup.string()
      .required("New password is required")
      .matches(
        /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
      ),
    repeatNewPassword: Yup.string()
      .required("Repeat new password is required")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match."),
  });
  const {t,} = useTranslation();
  const {
    // register,
    formState: { errors },
    control,
    handleSubmit,
    // formState: { touchedFields },
    reset,
  } = useForm({
    criteriaMode: "all",
    resolver: yupResolver(formSchema),
  });


  const getPasswordDetails = async ({ currentPassword, newPassword }) => {
    const { data, status } = await onChangePassword(
      { curr_pswd: currentPassword, new_pswd: newPassword }
    );

    if (status === 200) {
      if (data.status === 0) {
        toast.error(t(data.message));
      } else if (data.status === 1) {
        toast.success(t("Your Password has been changed succesfully."));
        setIsEditable(false);
        reset({
          currentPassword: "",
          newPassword: "",
          repeatNewPassword: "",
        })
      }
    }
  };

  const onSubmit = async (data) => {
    if (!isEmpty(errors)) {
      return;
    }
    getPasswordDetails(data)
  };

  const onCancel = () => {
    reset({
      currentPassword: "",
      newPassword: "",
      repeatNewPassword: "",
    });
    setIsEditable(false);
    setLoading(false);
  };
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col>
            <Container className="container-br p-sm-4 p-0">
              <SubHeading>
                <SubHeadingText>{t('Change Password')}</SubHeadingText>
                {!isEditable && (
                  <div
                    className="changeText"
                    onClick={() => setIsEditable(true)}
                  >
                    {t('Edit')}
                  </div>
                )}
              </SubHeading>
              <div className="">
                <div className="mb-4 mt-4">
                  <ContentText>
                    {t('Choose a unique password to protect your account')}
                  </ContentText>
                </div>
                <Row>
                  <Col lg="6">
                    <div className="mb-4 mt-4">
                      <LabelHeading>{t('Type your current password*')}</LabelHeading>
                      <SelectorWrapper>
                        <Controller
                          control={control}
                          name="currentPassword"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              placeholder={t('Current password')}
                              onChange={onChange}
                              disabled={!isEditable}
                              onBlur={onBlur}
                              type="password"
                              value={value}
                              className={error ? "invalid" : ""}
                            />
                          )}
                        />
                        <FormErrors
                          errors={errors}
                          formControlName="currentPassword"
                        />
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-4 mt-4">
                      <LabelHeading>{t('Type your new password*')}</LabelHeading>
                      <SelectorWrapper>
                        <Controller
                          control={control}
                          name="newPassword"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              placeholder={t('New password')}
                              onChange={onChange}
                              disabled={!isEditable}
                              onBlur={onBlur}
                              type="password"
                              value={value}
                              className={error ? "invalid" : ""}
                            />
                          )}
                        />
                        <FormErrors
                          errors={errors}
                          formControlName="newPassword"
                        />
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-4 mt-4">
                      <LabelHeading>{t('Retype your new password*')}</LabelHeading>
                      <SelectorWrapper>
                        <Controller
                          control={control}
                          name="repeatNewPassword"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <Input
                              placeholder={t('Repeat new password')}
                              onChange={onChange}
                              disabled={!isEditable}
                              onBlur={onBlur}
                              type="password"
                              value={value}
                              className={error ? "invalid" : ""}
                            />
                          )}
                        />
                        <FormErrors
                          errors={errors}
                          formControlName="repeatNewPassword"
                        />
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
              </div>

              {isEditable && (
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button name={t('Save')} type="submit" />
                  )}
                  <Button
                    secondary
                    color="#FFFFFF"
                    name={t('Cancel')}
                    style={{
                      marginLeft: "10px",
                    }}
                    type="button"
                    className="cancelBtn"
                    onClick={onCancel}
                  >
                  </Button>
                </Grid>
              )}
            </Container>
          </Col>
        </Row>
      </form>
    </>
  );
}
