import jsPDF from "jspdf";
import { createSearchParams } from "react-router-dom";
import { TITLE_MAP } from "../pages/constants/ad2Constants";

export const Utils = {
  intToString(value) {

    var suffixes = ["", "K", "M", "B", "T"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum !== 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 !== 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  },
  replaceStringWithValues(str, ...params) {
    if (params && params.length) {
      params.forEach((item, i) => {
        const dRegex = new RegExp("\\{" + i + "\\}", "gm");
        str = str.replace(dRegex, item);
      });
    }
    return str;
  },
  getOrCreateLegendList(chart, legendContainer, justifyContent = "center") {
    let listContainer = legendContainer.querySelector("ul");
    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "row";
      listContainer.style.justifyContent = justifyContent;
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }
    return listContainer;
  },
  convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr);
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join("\n");
  },
  appendAlpha(str, alpha) {
    var replace = "\\)";
    var re = new RegExp(replace, "g");
    return str.replace(re, `, ${alpha})`);
  },
  exportPDF(
    tableData = [],
    title = "Custom Report",
    fileName = "custom_report",
    customTitles = []
  ) {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers =
      tableData && tableData.length ? Object.keys(tableData[0]) : [];

    const data =
      tableData && tableData.length
        ? tableData.map((item) => {
            return headers.map((x) => item[x]);
          })
        : [];
    let content = {
      startY: 50,
      head: [customTitles && customTitles.length ? customTitles : headers],
      body: data,
      styles: {
        halign: "center",
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${fileName}.pdf`);
  },
  titleCase(string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    document.write(sentence.join(" "));
    return sentence;
  },
  falsyNumberCaseHandle(value) {
    return +(value || 0);
  },
  convertAndRoundToPercentage(value) {
    const nanCase = value || 0;
    return +((nanCase && nanCase) * 100).toFixed(1);
  },
  getObjectValuesSum(arr) {
    return Object.values(arr).reduce(function (a, b) {
      return a + b;
    }, 0);
  },
  getLabels(obj) {
    return (obj && Object.keys(obj).filter((key) => obj[key])) || [];
  },
  commonStateUpdateLogic(data, totalSum, status, key, skipSumLogic = false) {
    if (skipSumLogic || ((totalSum || 0) > 0 && data !== "api error")) {
      if (status === 200) {
        return {
          proceed: true,
          stateObj: null,
        };
      }
      return {
        proceed: false,
        stateObj: key ? { [key]: { data: [], status } } : { data: [], status },
      };
    } else {
      return {
        proceed: false,
        stateObj: key
          ? { [key]: { data: [], status: "noDataFound" } }
          : { data: [], status: "noDataFound" },
      };
    }
  },
  getUrlWithParams() {
    let urlParams = "";
    if (typeof window !== "undefined") {
      urlParams = window.location.search;
    }
    return urlParams;
  },
  handleNavigate(navigate, path, extraParams = {}, state = {}) {
    const queryParams = Utils.getUrlWithParams().replace("?", "");

    const params = queryParams.split("&").reduce(function (prev, curr, i, arr) {
      var p = curr.split("=");
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
    for (var key in extraParams) {
      params[key] = extraParams[key];
    }
    const pathFinal = {
      pathname: path,
      search: createSearchParams(params).toString(),
    };
    navigate(pathFinal);
  },
  getParams(searchParams, extraParams = []) {
    const mandatoryParams = [
      { propertyKey: "selectedCampus", urlKey: "campus" },
      { propertyKey: "selectedFaculty", urlKey: "faculty" },
      { propertyKey: "selectedStudyProgram", urlKey: "study_program" },
      { propertyKey: "selectedYearOfStudy", urlKey: "year_of_study" },
      { propertyKey: "selectedUniversity", urlKey: "insti_name" },
      { propertyKey: "selectedScopeOfStudy", urlKey: "scope_of_study" },
    ];
    const params = {};
    [...mandatoryParams, ...extraParams].forEach((param) => {
      params[param.urlKey] = searchParams.get(param.propertyKey);
    });
    return params;
  },
  getPathName(path, searchParams) {
    let pathName = TITLE_MAP[path];
    const regexp = /\$([a-zA-Z0-9_]+)/g;
    const matches = pathName ? [...pathName.matchAll(regexp)] : [];
    if (matches && matches.length) {
      for (const match of matches) {
        const found = match && match[1];
        if (found) {
          const re = new RegExp(`\\$${found}`, "g");
          const foundSearchParam = searchParams.get(found);
          if (foundSearchParam && found) {
            pathName =
              found === "name"
                ? pathName.replace(re, `${foundSearchParam}`)
                : pathName.replace(re, `(${foundSearchParam}) `);
          } else {
            pathName = pathName.replace(re, "");
          }
        }
      }
    }
    return pathName;
  },
  getSearchParams(paramsArray, searchParams) {
    const outputParams = {};
    paramsArray.forEach((item) => {
      const value = searchParams[item];
      if (value) {
        outputParams[item] = value;
      }
    });
    return outputParams;
  },
  getArrayFromString(str) {
    const strSplit = str && str.split(",");
    return strSplit && strSplit.length
      ? strSplit.map((x) => (typeof x === "string" ? +x : x))
      : [];
  },
};
