import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import "./SubscriptionsRenew.css";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";
import {
  companySubscription,
  getCompanySubscription,
} from "../../services/apiCalls";
import { updateSubscriptionData } from "./reducer";
import { useSelector, useDispatch } from "react-redux";
import { paymentGateWay } from "../../services/apiCalls";
import { SubscriptionData } from "../../assets/data/subscriptionData";
import { subsctipationsProxy } from "./subscripationsProxy";

const HeaderText = styled.h2`
  margin-top: 20px 0 20px 0;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

const CardsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 32px;
  padding-bottom: 1%;
  padding-bottom: 100px;
`;
const Header = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #d04c8d;
  padding: 4% 0px 0px 5%;
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #202020;
  margin: 2% 0 0 5%;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-bottom: 2%;
  width: 27%;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 50%;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    width: auto;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: auto;
  }
`;
const ButtonWrapper = styled.div`
  @media (max-width: 500px) {
    position: relative;
    right: 80px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: absolute;
    left: 63%;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: absolute;
    left: 63%;
  }
`;
const Subscriptions = () => {
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [details, setDetails] = useState(undefined);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const orgDetails = useSelector((state) => state.company.currentOrgData);

  useEffect(() => {
    setDetails({
      address: localStorage.getItem("address"),
      name: localStorage.getItem("name"),
      department: localStorage.getItem("department"),
      position: localStorage.getItem("position"),
      plan: localStorage.getItem("plan"),
      price: localStorage.getItem("price"),
      country: localStorage.getItem("country"),
      state: localStorage.getItem("state"),
      city: localStorage.getItem("city"),
      country_code: localStorage.getItem("price"),
      renew_date: localStorage.getItem("renew_date"),
      paymentstatus: localStorage.getItem("paymentstatus"),
    });
  }, []);

  function handleSubmit() {
    subscribe();
  }

  const subscribe = async () => {
    let d = details;
    if (details.plan) {
      let pl = {};
      if (SubscriptionData[d.plan.toLowerCase()]) {
        pl = SubscriptionData[d.plan.toLowerCase()];
      } else {
        pl = SubscriptionData["basic"];
      }
      console.log("deDetails",details);
      d.job_quota = pl.job_quota;
      d.job_duration_day = pl.job_duration_day;
      d.internship_quota = pl.internship_quota;
      d.internship_duration_month = pl.internship_duration_month;
      d.employe_equota = pl.employe_equota;
      d.graduate_talent_search =parseInt(SubscriptionData.talent_acquisition.features[0][d.plan.toLowerCase()]);
    }
    const { data } = await companySubscription(org_code, d);
    if (data && data.status === 1) {
      subsctipationsProxy.setcount(org_code);
      const { data } = await getCompanySubscription(org_code);
      if (data && data.status === 1) {
        let localObj = {};
        localObj[org_code] = data.data;
        dispatch(updateSubscriptionData(localObj));
      }
      const body = {
        client_reference_item: [{ id: details?.plan, type: "Subscription" }],
        customer: {
          name: details?.name,
          phone: "0123456789",
          email: "email@test.com",
          designation: details?.position,
          department: details?.department,
          company_name: company?.name,
          company_ssm_num: company?.ssm_number,
          address: [
            {
              address: "Address",
              city: details?.city,
              postcode: details?.country_code,
              state: details?.state,
              country: details?.country,
            },
          ],
        },
        items: [
          {
            price_id: "99",
            quantity: "1",
          },
        ],
        callback_url: `${process.env.REACT_APP_FRONT_END_URL}subscriptions/subscriptions-renew`,
      };
      await paymentGateWay(body).then((res) => {
        window.location.replace(res?.data?.payment_link_url);
      });
    }
  };

  const onNavigate = () => {
    navigate("/subscriptions/subscriptions-renew");
  };
  const getCurrencyFormat = (amount) => {
    const dt = +amount;
    return dt.toLocaleString("en-US");
  };
  return (
    <div>
      <MainLayout>
        <HeaderText>{"Subscriptions"}</HeaderText>

        <CardsWrapper>
          <Header>Subscribe to {localStorage.getItem("plan")}</Header>
          <Title>
            Billing Address
            <Content>{localStorage.getItem("address")}</Content>
            Name
            <Content>{localStorage.getItem("name")}</Content>
            Department
            <Content>{localStorage.getItem("department")}</Content>
            Position
            <Content>{localStorage.getItem("position")}</Content>
            Plan
            <Content>{localStorage.getItem("plan")}</Content>
            Price(RM)
            <Content>
              {getCurrencyFormat(localStorage.getItem("price"))}
            </Content>
          </Title>
          <ButtonWrapper>
            {" "}
            <Button
              pointer="cursor"
              name="Buy Now"
              onClick={handleSubmit}
              style={{ position: "absolute", right: "7%", marginTop: "10px" }}
            ></Button>
          </ButtonWrapper>

        </CardsWrapper>
      </MainLayout>
    </div>
  );
};

export default Subscriptions;
