import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Logo from "../../assets/addNewIntern.png";
import PlusIcon from "../../assets/plus.png";
import Export from "../../assets/ExportIcon.png";
import Exports from "../../assets/ExportIcon2.png";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import "./adduser.css";

const AddNeCompanyWrapper = styled.div`
  background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  padding: 15px 15px 15px 15px;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    > img {
      display: block;
      margin-left: auto;
      width: 60%;
      height: 100%;
      top: 64px;
    }
    
  }
`;
const StaticImg = styled.img`
  position: absolute;
  left: -45px;
  /* top: 22%; */
  width: 228px;
  height: 250px;
  bottom: -37%;
  cursor:default!important;
  @media (max-width:500px){
    top:
    display: inline-block;
    bottom: -25%;
    left: -35px;
   
  }
`;
const ContentWrapper = styled.div`
  margin-left: 115px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0 0 10px 70px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 0 70px;
    width: 290px;
    color: #ffffff;
  }
  @media (max-width: 600px) {
    padding: 5px;
    margin-left: -40px;
  }
  @media (min-width: 768px) {
    margin-left: 40px;
  }
  @media (min-width: 1024px) and (max-width: 1100px) {
    margin-left: 47px;
    > h3 {
      font-style: normal;
      font-weight: 500;
      font-size: 17px;
      line-height: 27px;
      color: #ffffff;
      margin: 0 0 10px 70px;
    }
    > p {
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      margin: 0 0 0 70px;
      width: 235px;
      color: #ffffff;
    }
  }
`;
const Wrapper = styled.div`
@media (max-width:600px){
  margin:-20px 0 20px 200px;
} 
@media (max-width:400px){
  margin:-20px 0 20px 130px;
} 
`
const ButtonWrapper = styled.div`
  button {
    border: 1px solid #ffffff;
    border-radius: 8px;
    width: 101px;
    height: 37px;
    border: 1px solid #ffffff;
    background: transparent;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    padding-right: 12px;
  }
  img {
    margin: -3px 15px 0 5px;
  }
  img:nth-child(2) {
    display: none;
  }
  &:hover {
    button {
      background: white;
      color: #d04c8d;
    }
    img:nth-child(1) {
      display: none;
    }
    img:nth-child(2) {
      display: inline;
    }
  }

   @media (max-width: 600px) {
    margin: 10px 23px 3px 270px;
    
   }
   @media (max-width: 400px) {
    margin: 10px 23px 3px 200px;
    
   }
`;
export default function AddNewUser(props) {
  const navigate = useNavigate();
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const userRole = useSelector((state) => state.company.currentOrgData);
  const [manage, setManage] = useState(false);
  const onNavigate = () => {
    if (userRole?.user_details.user_management_modify !== "Y") {
      return props.setUnAuth(true);
    }
    if (company.status == "accepted") {
      navigate("/my-company/add-user");
    } else {
      props.setUnAuth(true);
    }
  };


  return (
    <AddNeCompanyWrapper>
      <StaticImg src={Logo} alt="new company" />
      <ContentWrapper>
        <h3>Add New User</h3>
        <p>
          Create New user for your Organisation
          <br />
        </p>
      </ContentWrapper>
      <div className="row align-items-center">
        <div className="col-md-6">
          <ButtonWrapper onClick={props.handleExcelExport}>
            <button >
              <img src={Export} alt="imgexport" />
              <img src={Exports} alt="imgexport" />
              Export
            </button>

            {/* <img src={Export} alt="imgexport" flag="0"/>
        <img src={Exports} alt="imgexport" flag="0"/> */}
          </ButtonWrapper>
        </div>
        <div className="col-md-6">
          <Wrapper>
          <img
            onClick={onNavigate}
            src={PlusIcon}
            alt="new company add"
            className="add-user-class"
          />
          </Wrapper>
        </div>

      </div>
    </AddNeCompanyWrapper>
  );
}
