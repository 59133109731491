import React from 'react'
import { BoxWrapper,ChartsWrapper,ChartHeaderText } from './common'
import ChartsView from './charts/colorCharts1';
import ChartsView2 from './charts/colorCharts2';
import ChartsView3 from './charts/colorCharts3';

export default function EnglishProficiency() {
    return (
        <BoxWrapper style={{background:"#FFFFFF"}}>
           <ChartHeaderText>English Proficiency</ChartHeaderText>
            <hr/>
            <ChartsWrapper style={{marginTop:"42px"}}>
                <ChartsView  text={"Total English Proficiency Level"} colors={"blue"} />
                <ChartsView2 text={"Grammer"} colors={"pink"} />
                <ChartsView3 text={"Comprehension"} colors={"green"} />
            </ChartsWrapper>
        </BoxWrapper>
    )
}
