import { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Input from "../../components/input/input";
import { Colors } from "../../utilities/colors";
import ErrorField from "../../components/errorField/errorField";
import { resetPassword } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { errorMessages } from "../../utilities/commonMessages";
import Tooltip from "../../components/reactTooltip/tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

// styling starts
const Label = styled.label`
  font-weight: 500;
  display: block;
`;

const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const FormWrapper = styled.form`
  margin: 10px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;

const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GroupWrapper = styled.div`
  margin-bottom: 40px;
`;
// styling ends
const PasswordInput = styled.div`
  position: relative;
`;
const Visibility = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
  top: 9px;
  cursor: pointer;
  svg {
    fill: gray;
  }
`;
const schema = yup
  .object({
    code: yup.string().required(""),
    password: yup
      .string()
      .required(errorMessages.password)
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirm_password: yup
      .string()
      .required(errorMessages.password)
      .test("passwords-match", errorMessages.passwords, function (value) {
        return this.parent.password === value;
      }),
  })
  .required();

export default function Confirmpass() {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      code: "",
      password: "",
      confirm_password: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [formErrors,] = useState("");
  const [loading, setLoader] = useState(false);
  const [newPasswordShow, setNewPasswordShow] = useState(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
  const { t, } = useTranslation();
  const formValues = watch();
  const navigate = useNavigate();
  const onNavigate = (path) => {
    navigate(path);
  };
  const location = useLocation();

  useEffect(() => {
    if (location.state.name) {
      setValue("code", location.state.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const onSubmit = async () => {
    setLoader(true);
    const { data } = await resetPassword({ ...formValues });
    if (data && data.status === 1) {
      toast.success(
        t("Password reset successfully. Please login with your new credentials"),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
      setLoader(false);
      navigate("/login");
    } else {
      setLoader(false);
      toast.error(data && data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const togglePasswordVisiblity = (index) => {
    if (index === 0) {
      setNewPasswordShow(newPasswordShow ? false : true);
    } else {
      setConfirmPasswordShow(confirmPasswordShow ? false : true);
    }
  };
  return (
    <UserCreationLayout>
      <HeaderWrapper onClick={() => onNavigate("/forget-password")}>
        <IoIosArrowBack color={Colors.primary} size={20} />
        <SignUpBtn>{t('Back')}</SignUpBtn>
      </HeaderWrapper>
      <IntroSection name="change_password" />
      <Divider />
      <FormWrapper onSubmit={handleSubmit(onSubmit)}>
        <GroupWrapper>
          <FormLabel>{t('New Password*')}</FormLabel>
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <PasswordInput>
              <Input
                placeholder={t('Enter New Password*')}
                type={newPasswordShow ? "text" : "password"}
                dataId="password"
                {...field}
              />
              <Visibility onClick = { () =>  togglePasswordVisiblity(0)}>
                  {newPasswordShow ? <FaEyeSlash /> : <FaEye />}
                </Visibility>
              </PasswordInput>
            )}
          />
          {errors.password && <ErrorField err={t(errors.password.message)} />}
        </GroupWrapper>
        <GroupWrapper>
          <FormLabel>{t('Confirm New Password*')}</FormLabel>
          <Controller
            name="confirm_password"
            control={control}
            render={({ field }) => (
              <PasswordInput>
                <Input
                  placeholder={t("Confirm Password")}
                  type={confirmPasswordShow ? "text" : "password"}
                  {...field}
                />
                <Visibility onClick = { () =>  togglePasswordVisiblity(1)}>
                  {confirmPasswordShow ? <FaEyeSlash /> : <FaEye />}
                </Visibility>
              </PasswordInput>
            )}
          />
          {errors.confirm_password && (
            <ErrorField err={t(errors.confirm_password.message)} />
          )}
        </GroupWrapper>
        {formErrors && <ErrorField err={t(formErrors)} />}
        {loading ? (
          <Loader />
        ) : (
          <input
            type="submit"
            className="custom-submit-btn w-100"
            value={t('Create New Password')}
          />
        )}
        <Tooltip id="password" content={t('Enter new password')}/>
        <Tooltip id="confirmPassword" content={t('Confirm new password')} />
      </FormWrapper>
    </UserCreationLayout>
  );
}
