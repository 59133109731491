import React from 'react'
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { completionOfInternshipObj } from "./staticContent";
import { schema, schema2 } from './staticContentCompletion';
import Input from "../../components/input/input";
import TextArea from "../../components/description/description";
import RadioInput from "../../components/radioInput/radioInput";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import SkillCard from "../../components/skillCard/skillCard";
import Button from "../../components/button/button";
import ErrorField from "../../components/errorField/errorField";
import Loader from "../../components/loader/loader";
import Dropdown from "../../components/dropdown/dropdown";
import PaginationDivider from "./paginationDividerSip";
import { AsyncTypeahead } from "react-bootstrap-typeahead";

import {
  createNewIntern,
  getInternScrollList,
  uploadObject, // getCountries,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
  getScopeOfStudyMasterList,
  getSkillMasterList,
  ongettingInternInfo,
  onUpdatingInternInfo,
  getOrganizationInternshipCoinDetail,
  postCompletionform,
  getCompletionAdditionalAllowance,
  getCompletionLearningOutcomes,
  getCompletionTraningProviderType,
  addNewIntern,
  internBulkUpload,
  getQuota,
  CheckToken
} from "../../services/apiCalls";
import LocationTable from "../../components/locationTable/locationTable";
import { formatDate } from "../../utilities/commonFunctions";
import { updateValues } from "./reducer";
import Tooltip from "../../components/reactTooltip/tooltip";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { internFields as staticInternFields } from "./staticContent";
import { completionFields } from './staticContentCompletion';
import RichTextEditorComponent from "../../utilities/rich-text-editor";
import { Link } from "react-router-dom";
import moment from "moment";
import PaymentPopup from "../../components/paymentPopup/paymentPopup";
import { useTranslation } from "react-i18next";
import AlertModal from "../../components/newInternModal/alertModal";
import NewInternDropDown from "../../components/newInterndropdown/newInterndropdown";
import "./addNewInternship.css";
import { differenceInWeeks, differenceInDays } from 'date-fns';
import CreateInternshipModuleModal from "../../components/createInternshipModule/createInternshipModal";






const materialTheme = createTheme({
    palette: {
      primary: {
        main: "#D44B9C",
      },
    },
    overrides: {
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: "#D44B9C",
        },
      },
    },
  });
  // styling starts
  const NewInternPageWrapper = styled.div`
    background: ${Colors.light};
    border-radius: 10px;
    padding: 2rem;
    margin: 2rem 0;
    @media (min-width: 767px) and (max-width: 1023px) {
      margin: 0 -10px 0 -20px;
    }
  `;
  const SectionWrapper = styled.section``;
  const HeaderText = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    margin: 0;
    color: ${Colors.dark};
  `;
  
  const HeaderText2 = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    margin: 0;
    color: ${Colors.dark};
  `;
  const Description = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 5px 0;
    color: #858585;
  `;
  const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
  `;
  const FileWrapper = styled(FlexWrapper)`
    margin: 10px 0;
    flex-wrap: wrap;
  `;
  const GridWrapper = styled.div`
    display: grid;
    margin-bottom: 30px;
  `;
  // const LogoWrapper = styled(FlexWrapper)`
  //   margin: 20px 0;
  //   > img {
  //     margin-right: 20px;
  //   }
  //   @media (max-width: 650px) {
  //     flex-direction: column;
  //   }
  // `;
  // const CompanyHeader = styled.h4`
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 27px;
  //   margin: 0;
  // `;
  const FormWrapper = styled.div`
    margin-top: 20px;
  `;
  const FormGroup = styled.div`
    min-width: 240px;
    width: 100%;
    label {
      width: unset !important;
    }
  `;
  const SingleField = styled.div`
    margin-bottom: 30px;
  `;
  const FormLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: block;
    color: ${Colors.labelText};
    visibility: ${(props) => (props.visible ? "hidden" : "default")};
    margin-bottom: 5px;
    > span {
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
    }
  `;
  const InternTypeWrapper = styled(FlexWrapper)``;
  // const InternDateWrapper = styled(GridWrapper)`
  //   grid-template-columns: 1fr 1fr 0.5fr;
  //   grid-column-gap: 20px;
  //   @media (max-width: 900px) {
  //     grid-template-columns: repeat(1, 1fr);
  //   }
  //   label {
  //     position: relative;
  //     width: -webkit-fill-available;
  //   }
  // `;
  const UploadText = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    margin-left: 5px;
    color: ${Colors.labelText};
  `;
  const UploadNotes = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #a5a0a0;
  `;
  const UploadFileText = styled.label`
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: ${Colors.primaryText};
  `;
  const UploadFileWrapper = styled.label`
    position: relative;
    cursor: pointer;
    > input {
      position: absolute;
      opacity: 0;
      width: 100%;
    }
  `;
  const DividerLine = styled.hr`
    border: 0.5px solid #acacac;
    margin: 30px 0;
  `;
  const FormHeaderText = styled.h3`
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    color: ${Colors.dark};
  `;
  const InfoHeaderText = styled.h3`
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 25px;
    color: ${Colors.dark};
  `;
  const AcademicGridWrapper = styled(GridWrapper)`
    grid-template-columns: ${(props) =>
      props.template ? props.template : "3fr 1fr"};
    grid-column-gap: 20px;
    @media (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
    label {
      position: relative;
      //width: -webkit-fill-available;
    }
  `;
  
  const ButtonWrapper = styled(FlexWrapper)`
    justify-content: space-between;
    margin-top: 40px;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      justify-content: space-between;
    }
  `;
  const LabelText = styled.label`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  `;
  const SaveExitBtn = styled(LabelText)`
    color: #504f8c;
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 800px) {
      width:100%;
      text-align:center;
    }
  `;
  
  const AddButton = styled.p`
    // font-weight: 600;
    // font-size: 14px;
    // line-height: 19px;
    cursor: pointer;
    // color: #d04c8d;
    background: linear-gradient(90deg, #504f8c 0%, #fa4aa1 100%);
    border-radius: 128px;
    outline: none;
    border: 0px;
    // font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    padding: 13px 28px;
    padding-top: 13px;
    padding-right: 28px;
    padding-bottom: 13px;
    padding-left: 28px;
    // margin: 15px 0;
    width: 192px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
  
  const CalenderIconWrapper = styled.div`
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
  `;
  
  const ErrorLabel = styled.label`
    color: red;
    font-size: 12px;
    margin: 0;
    display: block;
    font-weight: 500;
  `;
  
  //
  
  // const ManualWrapper = styled.div``;
  const ModalHeaderText = styled.div`
    position: absolute;
    left: 51px;
    top: 44px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #d04c8d;
  `;
  
  const ModalHeaderTextTwo = styled.div`
    position: absolute;
    left: 51px;
    top: 72px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
  `;
  
  const ModalHeaderTextThree = styled.div`
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 31px;
    text-align: center;
  
    color: #202020;
    margin-top: 29px;
  `;
  const ModalHeaderTextFour = styled.div`
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
  
    color: #202020;
    margin-top: 3px;
  `;
  
  const ModalHeader = styled.div`
    height: 136px;
    background: rgba(83, 80, 141, 0.1);
    border-radius: 24px 24px 0px 0px;
  `;
  
  const SubHeading = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #D04C8D;
    margin-top: 30px;
  `;
  
  const Description3 = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    margin-top: 30px;
  `;
  
  const BreakSection = styled.div`
  > hr{
      opacity: 0.1;
      max-width: 100%;
      margin-top: 10px
  }
  `;
  
  const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  gap: 200px;
  `;
  
  const RadioButtonGroup = styled.div`
    margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    cursor: pointer;
    minWidth:300px;
    marginRight:100px;
    margin-left:10px;
    display: flex;
    flex-direction: row;
  `;
  const RadioLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
    text-transform: capitalize;
    // white-space: nowrap;
    text-overflow: ellipsis;
  `;
  const ComponentFile = styled.div`
  display: flex;
`;

//sip Model

const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
`;

const ModalContent = styled.div`
  background: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  text-align: center;
`;

const CenterButton = styled.div`
display: flex;
justify-content: center;
`;

const ModalLabel = styled.label`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
`;

const ModalLabel2 = styled.label`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  max-width: 500px;
  text-align: center;
`;

const ModalSection = styled.div`
margin-top: 20px;
margin-bottom: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

const SIPModelContent = styled.div`
margin-top: 20px;
margin-bottom: 20px
`;

const SIPItemContent = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-right: 20px;
padding-left: 20px;
`;

const SIPModelLabel = styled.h3`
  font-family: "General Sans";
  font-style: normal;
  font-size: 18px;
  line-height: 35px;
`;
export default function CompletionOfInternshipform() {

  
  const [getAllowanceData,setGetAllowanceData] = useState();
  const getadditionalAllowanceData = async()=>{
    const allowanceData= await getCompletionAdditionalAllowance();
    
    setGetAllowanceData(allowanceData.data);
  }

  const [getLearningOutcomeData,setLearningOutcomeData] = useState();
  const getlearningOutcomeData= async()=>{
    const learningOutcomeData = await getCompletionLearningOutcomes();
    setLearningOutcomeData(learningOutcomeData.data);
  }

  const [getTraningProvideTypeData, setTraningProviderTypeData] = useState();
  const gettraningProviderTypeData = async()=>{
    const trainingProviderTypeData = await getCompletionTraningProviderType();
    setTraningProviderTypeData(trainingProviderTypeData.data);
  }

  useEffect(()=>{
    getadditionalAllowanceData();
    getlearningOutcomeData();
    gettraningProviderTypeData();
  },[])

    const { t } = useTranslation();
  const [coins, setCoins] = useState(0);

  //handle radio buttons
  const [externalTrainingsRadio, setExternalTrainingsRadio] = useState('');
  const [internalTraningRadio,setInternalTraningRadio] = useState("");
  const [employmentOpp, setEmploymentOpp] = useState('');
  const [fWa, setFwa] = useState('Yes');

  var data1 = {
    "empty-package": {
      subDesc: "Your internship wallet is empty",
      desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
      submit: "Submit",
    },
    "empty-coin": { 
      subDesc: "Looks like your wallet is empty.",
      desc: "To proceed with internship posting via mynext, kindly renew your internship plan subscription. Should you choose to not renew, you will be charged on a pay-per-intern basis for your internship posting.",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Thank you for purchasing our internship plan package.",
      desc: `${t("You are about to redeem ")}1${
        t(" out of your total ") +
        coins +
        t(" coins for this internship posting")
      }.`,
      submit: "Proceed",
    },
  };
  const selectedRef = useRef(null);

  //const internFields = useSelector((state) => state.intern.value);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const companyData = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const eligibleData = localStorage.getItem("eligibilityData");
  const AdvertiseEligible = JSON.parse(eligibleData);
  console.log(AdvertiseEligible, "FROM DH")

  console.log(companyData,"CompanyData")

  


  // const monthly_allowance_1 = AdvertiseEligible.monthly_allowance_1
  // const monthly_allowance_2 = AdvertiseEligible.monthly_allowance_2
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    // status,
  } = useForm({
    defaultValues: { ...completionFields },
    resolver: yupResolver(schema2),
    // context: {
    //   monthly_allowance_1,
    //   monthly_allowance_2,
    // },
    mode: "onBlur",
  });
  const formVal = watch();
  console.log("DOC>>>"+JSON.stringify(formVal.documents));
  var data1 = {
    "empty-package": {
      subDesc: "Your internship wallet is empty",
      desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
      submit: "Submit",
    },
    "empty-coin": {
      subDesc: "Looks like your wallet is empty.",
      desc: "To proceed with internship posting via mynext, kindly renew your internship plan subscription. Should you choose to not renew, you will be charged on a pay-per-intern basis for your internship posting.",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Thank you for purchasing our internship plan package.",
      desc: `${t("You are about to redeem ")}${formVal.no_positions}${
        t(" out of your total ") +
        coins +
        t(" coins for this internship posting")
      }.`,
      submit: "Proceed",
    },
    "position-coins": {
      subDesc: `${t(
        "Unfortunately, you are unable to complete this transaction due to insufficient token balance."
      )}`,
      desc: `1. ${t("Reduce application to")} ${coins} ${t("positions")}`,
      desc2: `2. ${t("Create new internship with ")} ${
        parseInt(formVal.no_positions) - coins
      } ${t("position(s) using ala carte payment mode")}.`,
      submit: `${t("ok")}`,
      "tokens-available": `${t("Available token(s)")}: `,
      "required-tokens": `${t("Required token(s)")}: `,
    },
  };


  const location = useLocation();
  const typeOrigin = location.state?.typeOrigin;
  const internship_id = location.state?.internshipID


  // const requestData = {
  //   internship: internship_id,
  //   internship_start_date: formVal.exp_start_date,
  //   internship_end_date:formVal.exp_end_date,
  //   attendance_records:formVal.attendance_records,
  //   min_allowance_per_intern:formVal.min_allowance_per_intern,
  //   is_external_training_given:formVal.is_external_training_given,
  //   is_internal_training_given:formVal.is_internal_training_given,
  //   total_allowance:formVal.total_allowance,
  //   expenditure_records: formVal.expenditure_records,
  //   is_acknowledged: formVal.is_acknowledged,
  //   additional_allowance: formVal.additionalAllowance,
  //   type_of_training_provided: formVal.type_of_training_provided,
  //   learning_outcomes: formVal.learning_outcomes,
  //   external_training: formVal.external_training,
  //   internal_training: formVal.internal_training,
  // };
  

   console.log(internship_id,"internDetailUpload");

  // const submitCompletionForm =async()=>{
  //   const { data } = await postCompletionform(internship_id,requestData);
  //     if (data.status===1) {
  //       toast.success(t("Completion form submitted"), {
  //         position: toast.POSITION.TOP_RIGHT,
          
  //       });
  //   };
  //   console.log(data,"FormVal to Submit")
  // }


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = () => {
    return (
      <>
        <ModalHeader></ModalHeader>
        <ModalHeaderText>{t("Internship Creation")}</ModalHeaderText>
        <ModalHeaderTextTwo>
          {modalMode === "position-coins"
            ? t("Insufficient token balance")
            : t("Internship Plan")}
        </ModalHeaderTextTwo>

        <ModalHeaderTextThree>
          {t(data1[modalMode]["subDesc"])}
        </ModalHeaderTextThree>
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour>
            {data1[modalMode]["tokens-available"]}
            <b>{coins}</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour>
            {data1[modalMode]["required-tokens"]}
            <b>{parseInt(formVal.no_positions) - coins}</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}

        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            <b>{t("To proceed, you may try the following")}:</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            {t(data1[modalMode]["desc"])}
          </ModalHeaderTextFour>
        ) : (
          <ModalHeaderTextFour>
            {t(data1[modalMode]["desc"])}
          </ModalHeaderTextFour>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            {t(data1[modalMode]["desc2"])}
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}

        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            <b>
              {t(
                "Should you require further assistance, please reach out to us at company@mynext.my"
              )}
            </b>
          </ModalHeaderTextFour>
        ) : (
          ""
        )}

        {modalMode === "empty-coin" ? (
          <ModalHeaderTextFour>
            <b>
              {t(
                "Note: Kindly email us at company@mynext.my for subscription renewal."
              )}
            </b>
          </ModalHeaderTextFour>
        ) : (
          ""
        )}

        <ComponentFile></ComponentFile>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            {modalMode !== "position-coins" ? (
              <>
                <Button
                  style={{ width: "213px" }}
                  margin="40px 0px 30px 0px"
                  name={t(data1[modalMode]["submit"])}
                  onClick={onSipModalSubmit}
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </>
            ) : (
              <Button
                style={{ width: "213px" }}
                margin="40px 0px 30px 0px"
                name={t(data1[modalMode]["submit"])}
                onClick={onClose}
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  const [loading, setLoading] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [userArr, setUsersArr] = useState([]);
  const [newSkill, setSkill] = useState(null);

  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const [scope, setScope] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);

  const [enableDate, setEnableDate] = useState(false);
  const [duplicateLoc, setDuplicateLocErr] = useState("");
  const [hideLocation, setHideLocation] = useState(false);
  const collectionValueList = [
    { label: t("Manually download applications") },
    { label: t("Receive applications by E-mail") },
    { label: t("Others") },
  ];
  const [collectionValues, setCollectionValues] = useState(collectionValueList);
  const [resetorg, setResetOrg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMode, setModalMode] = useState("empty-coin");

  //to check if file has been uploaded 
  const [isUpload,setIsUpload] = useState(false);

  // const formVal = watch();
  const org = useSelector((state) => state.org.orgData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const isFromInternshipPage = new URLSearchParams(search).get(
    "fromInternshipPage"
  );
  // const formVal = watch();
  if (resetorg === 0) {
    reset({
      description: "",
    });
    setResetOrg(1);
  }

  const [isOpen, setOpen] = useState(false);
  const bufferDate = 5;

  function onClose() {
    setModal(false);
    setLoading(false);
  }

  function onSipModalSubmit() {
    setModal(false);
    onNewIntern(true);
  }

  const [addPopup, setAddPopup] = useState(false);
  useEffect(() => {
    if (id && org_code) {
      getInternData();
      

    }
    if (isFromInternshipPage) {
      setUpdate(true);
    }
    if (formVal && !formVal["payment_status"]) {
      setValue("payment_status", "paid");
    }
    //  getOptions("all/loc/country", "country");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, org]);

  useEffect(() => {
    const sip = "National Structured Internship Programme (MySIP)";
    if (formVal.intern_type === "sip" || formVal.intern_type === sip) {
      collectionValues.splice(
        collectionValues.findIndex((f) => f.label === sip),
        1
      );
      setValue("collection_type", "");
      setValue("re_email_address", "");
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      setCollectionValues(collectionValues);
      setValue("payment_status", "paid");
    } else {
      setCollectionValues([...collectionValueList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.intern_type]);

  useEffect(() => {
    if (formVal.contact_person === "" || formVal.contact_person === null) {
      // alert('hii')
      formVal.contact_person = formVal.contact_name;
      setValue("contact_person", formVal.contact_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.contact_person]);

  const getInternData = async () => {
    setPageLoading(true);
    const { data } = await ongettingInternInfo({ id: id, code: org_code });
    if (data && data.status === 1) {
      //document updation
      if (data.data.documents && data.data.documents.length >= 4) {
        data.data.documents.push({ doc_type: "All" });
      }
      dispatch(
        updateValues({
          ...data.data,
        })
      );
      setUpdate(true);
      let arr = Object.keys(data.data);
      for (let i = 0; i <= arr.length; i++) {
        let key = arr[i];
        setValue(key, data.data[key] ? data.data[key] : "");
      }
      const scopeArr = data.data.scopes.map((x) => {
        return { ...x, label: x.scope_abbr, value: x.scope };
      });
      setValue("scopes", scopeArr);
      setSelectedScope(scopeArr);
      setValue("update_time", data.data.update_time);
      setPageLoading(false);
    } else {
      setUpdate(false);
      setPageLoading(false);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paidOptions = [
    { value: "paid", label: t("Paid") },
    { value: "unpaid", label: t("Unpaid") },
  ];

  useEffect(() => {
    getDropDownData();
    getcountryList();
    getScopeOfStudy();
    reset({ ...completionFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDropDownData = async () => {
    const { data } = await getInternScrollList();
    if (data && data.status === 0) {
      onDataConstruct(data.data);
    }
  };

  const dropdownChange = (name, val) => {
    switch (name) {
      case "country":
        setValue(name, val.label);
        dispatch(
          updateValues({
            ...formVal,
            ...completionFields,
            [name]: val.label,
            state: [],
            city: [],
          })
        );
        setSelectedCountry(val);
        getState(val.value);
        break;
      case "state":
        setValue(name, val.label);
        dispatch(
          // ,
          updateValues({
            ...formVal,
            ...completionFields,
            [name]: val.label,
          })
          //
        );
        setSelectedState(val);
        getCity(selectedCountry.value, val.value);
        break;
      case "city":
        setValue(name, val.label);
        dispatch(
          updateValues({
            ...formVal,
            ...completionFields,
            [name]: val.label,
          })
        );
        setSelectedCity(val);
        break;
      default:
        setValue(name, val.value);
    }
  };

  const getcountryList = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Countrylist = data.data;
        const country = Countrylist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCountry(country);
        if (formVal.country) {
          let selCountry = country.filter((x) => x.value === formVal.country);
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          setSelectedCountry(selCountry);
          getState(selCountry.value);
        }
      }
    }
  };

  const getState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const Stateslist = data.data;
        const state = Stateslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setStates(state);
        if (formVal.state) {
          let selStates = state.filter((x) => x.value === formVal.state);
          if (selStates.length > 0) {
            selStates = selStates[0];
            setValue("state", selStates.value);
          }
          setSelectedState(selStates);
          // fetchCampus(selStates.value);
        }
      }
    }
  };
  const getCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCities(cities);
        if (formVal.city) {
          let selcities = cities.filter((x) => x.value === formVal.city);
          if (selcities.length > 0) {
            selcities = selcities[0];
            setValue("city", selcities.value);
          }
          setSelectedCity(selcities);
          // fetchCampus(selStates.value);
        }
      }
    }
  };

  const getScopeOfStudy = async (CountryId, StateId) => {
    const { data, status } = await getScopeOfStudyMasterList(
      CountryId,
      StateId
    );
    if (status === 200) {
      if (data.status === 1) {
        const Scopelist = data.data;
        const scope = Scopelist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setScope(scope);
        if (formVal.scopes) {
          let selcities = scope.filter((x) => x.value === formVal.scopes);
          setSelectedScope(selcities);
          // if (selcities.length > 0) {
          //   selcities = selcities[0];
          //   setValue("scopes", selcities.value);
          // }
          // setSelectedCity(selcities);
        }
      }
    }
  };

  const onDataConstruct = (data) => {
    // const skillsArr = data.skills.map((x) => {
    //   return { label: x, skill: x, value: x };
    // });
    let contactPersonArr = data.users.map((x) => {
      if (x.status === "accepted")
        return { ...x, label: x.first_name + " " + x.last_name, value: x.id };
      else {
        return null;
      }
    });
    contactPersonArr = contactPersonArr.filter(function (e) {
      return e;
    });
    // setSkillsArr(skillsArr);
    // setValue("skills", skillsArr);
    setUsersArr(contactPersonArr);
    if (formVal.contact_person === "" || formVal.contact_person === null) {
      // alert('hii')
      formVal.contact_person = formVal.contact_name;
      setValue("contact_person", formVal.contact_name);
    }
  };

  const fetchAllSkills = async (searchKey) => {
    const { data, status } = await getSkillMasterList(searchKey);
    if (status === 200 && data.status === 1) {
      const options = data.data.map((x) => {
        return { ...x, label: x.Example, value: x.Id };
      });
      setOptions(options);
      setIsLoading(false);
    }
  };

  const typeaheadRef = useRef(null);

  const filterBy = () => true;
  const handleSearch = (query) => {
    setIsLoading(true);
    fetchAllSkills(query);
  };
  const dropdownSkillChange = async (val) => {
    // setSelectedSkill(val[0])
    setSkill(val[0]?.label);
  };

  const addSkill = () => {
    const arr = [...formVal.skills];
    if (arr.length >= 30) {
      toast.error(t("The skills must be exactly 30."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const isNewSkill =
        arr.length > 0 ? arr.some((skill) => skill.skill !== newSkill) : true;
      var added_skills = [];

      arr.map((skl) => added_skills.push(skl.skill));

      if (added_skills.includes(newSkill)) {
        toast.error(t("Skill already added"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (isNewSkill) {
          if (newSkill === "" || newSkill === null) {
            toast.error(t("Please add at least one skill!"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            arr.push({ skill: newSkill });
            setValue("skills", arr);
            setSkill("");
            typeaheadRef.current.clear();
            errors.skills = "";
          }
        }
      }
    }
  };

  const deleteSkill = (index) => {
    const arr = [...formVal.skills];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("skills", newArr);
    setSkill(null);
    typeaheadRef.current.clear();
  };

  const onCancle = async () => {
    navigate("/internships/upload-intern-details");

  }
  
  const [internshipAddId, setInternshipAddId] = useState();
  const internDataDH = localStorage.getItem("DH_Data");
  const parsedData = JSON.parse(internDataDH)
  const [DirectHiringData, setDirectHiringData] = useState(parsedData)
  const saved1Intern = localStorage.getItem("add-intern");
  const savedAddIntern = localStorage.getItem("bulk-add");
  const addedIntern = JSON.parse(savedAddIntern);
  const singleIntern = JSON.parse(saved1Intern);
console.log(DirectHiringData, "COMPLETED")
console.log(addedIntern, "BULK INTERN")
console.log(singleIntern, "SINGLE");
const isBulk = location?.state?.isBulk;
const isSingle = location?.state?.isSingle;
console.log("If single:", isSingle);
console.log("If Bulk", isBulk)

  const onSubmit = async () => {
    onSubmitErrorHandler();
  };

  const onSubmitErrorHandler = async () => {
    const no_training_type = formVal.typeOfTrainings.some((item) =>
    item.name === "Others (please specify)" && (!item.other || item.other.trim() === '')
    );

    const no_additional_allowance = formVal.additionalAllowance.some((item) =>
    item.name === "Other Allowances" && (!item.other || item.other.trim() === '')
    );

    const no_learning_outcome = formVal.learningOutcome.some((item) =>
    item.name === "Others (please specify)" && (!item.other || item.other.trim() === '')
    );
   if(formVal.actual_allowance_per_intern === ''){
    toast.error(t("Please check your data"), {
                position: toast.POSITION.TOP_RIGHT,
                
              });
   }
   else if(no_training_type){
    toast.error(t("Please enter other type of training"), {
      position: toast.POSITION.TOP_RIGHT,
    });
   }else if(no_additional_allowance){
    toast.error(t("Please enter other additional allowance"), {
      position: toast.POSITION.TOP_RIGHT,
    });
   }else if(no_learning_outcome){
    toast.error(t("Please enter other learning outcome"), {
      position: toast.POSITION.TOP_RIGHT,
    });
   }
   else{
    const isValid = await handleSubmit(onHandleSubmitForm);
    if(isValid){
      onHandleSubmitForm()
    }
    
   }
  };
  const onUpdateIntern = async () => {
    // work around for visibility status - string bug
    if (typeof formVal?.visibility_status == "string")
      formVal.visibility_status = 0;
    const { data, res } = await onUpdatingInternInfo({
      code: org_code,
      id: id,
      data: {
        ...formVal,
        collection_radio_type: formVal.collection_radio_type
          ? formVal.collection_radio_type
          : null,
        collection_date: formVal.collection_date
          ? formVal.collection_date
          : null,
        is_activate: true,
        status: "pending",
      },
    });
    if (data && data.status === 1) {
      toast.success(t("Internship has been updated."), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      navigate("/internships");
    } else {
      if (
        data.message ===
        t("Someone has already edited the records, so please update the screen")
      ) {
        toast.warning(
          t(
            "Internship already updated by another user. Please refresh the page to load the latest details."
          ),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      setLoading(false);
    }
    if (res) {
      if (res.non_field_errors) {
        const msg = res.non_field_errors.join(" ");
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const [showSipTokenModal, setShowSipTokenModal] = useState(false)
  const handleCloseModal = () => {
    setShowSipTokenModal(false);
    setLoading(false);
  };
const [tokenData, setTokenData] = useState({});

  const TokenContent = ({data}) =>{
    return(
      <SIPModelContent>
        <SIPItemContent>
        {data?.status === 1? (
          <>
          <SIPModelLabel>{"There are total of "} <strong>{data?.data?.total_interns}</strong> {" interns for this internship."}</SIPModelLabel>
          <SIPModelLabel>{"Your available MySIP Token balance:"} <strong>{data?.data?.available_tokens}</strong></SIPModelLabel>
          <SIPModelLabel>{"MySIP Tokens used:"} <strong>{data?.data?.tokens_used}</strong></SIPModelLabel>
          <SIPModelLabel>{"Your available MySIP Token balance after admin approval:"} <strong>{data?.data?.tokens_after_deduction}</strong></SIPModelLabel>
          </>
        ): data?.status === 2? (
          <>
        <SIPModelLabel>{"There are total of "} <strong>{data?.data?.total_interns}</strong> {" interns for this internship."}</SIPModelLabel>
        <SIPModelLabel>{"Your available MySIP Token balance:"} <strong>{data?.data?.available_tokens}</strong></SIPModelLabel>
        <SIPModelLabel>{"MySIP Token used:"} <strong>{data?.data?.tokens_used}</strong></SIPModelLabel>
        <SIPModelLabel>{"Upon admin approval, you will be sent an invoice to your email at "} <strong>{data?.data?.contact_email}</strong></SIPModelLabel>
        <SIPModelLabel>{"You will need to make a payment for the remaining "} <strong>{data?.data?.pending_interns}</strong> {" interns."}</SIPModelLabel>
          </>
        ): ""}
        <Button name="Proceed" onClick={()=> navigate("/internships")}/>
        </SIPItemContent>
      </SIPModelContent>
    )
  }
const [sipTokens,setSipTokens] = useState();
const onHandleSubmitForm = async () =>{
  
  try {
        // const result3 = await getQuota(org_code);
        // console.log(result3,"tokenssipCompletion")
        // setSipTokens(result3?.data?.sip_tokens);
    // Step 1: Create the Internship
    const { data, res } = await createNewIntern({
      ...DirectHiringData,
      documents: DirectHiringData.documents.filter((x) => x.doc_type !== "All"),
      collection_radio_type: DirectHiringData.collection_radio_type
        ? DirectHiringData.collection_radio_type
        : null,
      collection_date: DirectHiringData.collection_date
        ? DirectHiringData.collection_date
        : null,
      org_code: org_code,
      is_activate: true,
    });

    if (data && data.status === 1) {
      setLoading(true);
      const internshipId = data.data;

      // Step 2: Add the Intern
      if(isSingle===true && isBulk===true){
        // Step 2: Add Bulk & single Interns
        // if(addedIntern.length < sipTokens){
        //   alert("Please Top Up your Sip Tokens")
        //   setShowSipTokenModal(true);
        // }else{
          
        // }

        addedIntern.forEach((intern) => {
          intern.internship = internshipId
          intern.intern_email = intern.email
        });
        console.log(addedIntern,"Bulk Interns when upload")
        const { data: addInternResponse } = await internBulkUpload(addedIntern, internshipId);
        console.log(addInternResponse,"addInternResponse222")
        console.log(formVal.attendance_records,"This is formVal")
        if (addInternResponse && addInternResponse.status === 1) {
          // Step 3: Add Completion for Bulk Interns
          const requestData = {
            internship: internshipId,
            internship_start_date: formVal.exp_start_date,
            internship_end_date:formVal.exp_end_date,
            attendance_records:formVal.attendance_records.map(record => record.file_path),
            actual_allowance_per_intern:formVal.actual_allowance_per_intern,
            is_external_training_given:formVal.is_external_training_given,
            is_internal_training_given:formVal.is_internal_training_given,
            total_allowance:formVal.total_allowance,
            expenditure_records: formVal.expenditure_records.map(record => record.file_path),
            is_acknowledged: formVal.is_acknowledged,
            additional_allowance: formVal.additionalAllowance,
            type_of_training_provided: formVal.typeOfTrainings,
            learning_outcomes: formVal.learningOutcome,
            external_training:[
              {
                external_training_course_title: formVal.external_training_course_title,
                external_training_provider: formVal.external_training_provider,
                external_training_duration: formVal.external_training_duration,
                is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp,
              }
            ],
            internal_training:[
              {
                internal_training_course_title: formVal.internal_training_course_title,
                internal_training_provider: formVal.internal_training_provider,
                internal_training_duration: formVal.internal_training_duration,
              }
            ],
            proof_of_training:formVal.proof_of_training.map(record => record.file_path),
            // external_training: formVal.external_training,
            // internal_training: formVal.internal_training,
          };
          console.log(requestData,"This is RequestData2")
          console.log(formVal.attendance_records,"This is formVal")
          const { data: completionBulkResponse } = await postCompletionform(internshipId, requestData);

          if (completionBulkResponse && completionBulkResponse.status === 1) {
            // All steps completed successfully for bulk interns

            // toast.success(t("Internship form submitted"), {
            //   position: toast.POSITION.TOP_RIGHT,
            // });
            // navigate("/internships");
            const {data: checkToken} = await CheckToken(internshipId);
              if(checkToken){
                setTokenData(checkToken);
                setShowSipTokenModal(true);
              }
          } else {
            toast.error(t("Failed to submit completion form for bulk interns"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
        } else {
          toast.error(t("Failed to add the bulk interns"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
      else{
        if (isSingle) {
        

          // if(sipTokens===0){
          //     alert("Please Top Up your Sip Tokens")
          //     setShowSipTokenModal(true);
          // }else{
            
          // }
          if(addedIntern?.length > 1){
            //singleIntern.internship = internshipId;
          //console.log(singleIntern,"Multiple Single intern when saving");
          addedIntern.forEach((intern) => {
            intern.internship = internshipId
            intern.intern_email = intern.email
          });
          //addedIntern
          const { data: addSingleResponse } = await internBulkUpload(addedIntern, internshipId);

          if (addSingleResponse && addSingleResponse.status === 1) {
            // Step 3: Add Completion for mulitple Single Interns
            const requestData = {
              internship: internshipId,
              internship_start_date: formVal.exp_start_date,
              internship_end_date:formVal.exp_end_date,
              attendance_records:formVal.attendance_records.map(record => record.file_path),
              actual_allowance_per_intern:formVal.actual_allowance_per_intern,
              is_external_training_given:formVal.is_external_training_given,
              is_internal_training_given:formVal.is_internal_training_given,
              total_allowance:formVal.total_allowance,
              expenditure_records: formVal.expenditure_records.map(record => record.file_path),
              is_acknowledged: formVal.is_acknowledged,
              additional_allowance: formVal.additionalAllowance,
              type_of_training_provided: formVal.typeOfTrainings,
              learning_outcomes: formVal.learningOutcome,
              external_training:[
                {
                  external_training_course_title: formVal.external_training_course_title,
                  external_training_provider: formVal.external_training_provider,
                  external_training_duration: formVal.external_training_duration,
                  is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp,
                }
              ],
              internal_training:[
                {
                  internal_training_course_title: formVal.internal_training_course_title,
                  internal_training_provider: formVal.internal_training_provider,
                  internal_training_duration: formVal.internal_training_duration,
                }
              ],
              proof_of_training: formVal.proof_of_training.map(record => record.file_path),
              // external_training: formVal.external_training,
              // internal_training: formVal.internal_training,
            };
            console.log(requestData,"RequestDataBeforeCompletion");

            const { data: completionSingleResponse } = await postCompletionform(internshipId, requestData);
            
            console.log(requestData,"RequestDataAfterCompletion");
            if (completionSingleResponse && completionSingleResponse.status === 1) {
              // All steps completed successfully for single intern
              // toast.success(t("Internship form submitted"), {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              // navigate("/internships");
              const {data: checkToken} = await CheckToken(internshipId);
              if(checkToken){
                setTokenData(checkToken);
                setShowSipTokenModal(true);
              }
            } else {
              toast.error(t("Failed to submit completion form for single intern"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            toast.error(t("Failed to add the single intern"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          } else if(addedIntern?.length === 1){
            singleIntern.internship = internshipId;
            console.log(singleIntern,"Single intern when saving");
            const { data: addSingleResponse } = await addNewIntern(singleIntern, internshipId);
  
            if (addSingleResponse && addSingleResponse.status === 1) {
              // Step 3: Add Completion for Single Intern
              const requestData = {
                internship: internshipId,
                internship_start_date: formVal.exp_start_date,
                internship_end_date:formVal.exp_end_date,
                attendance_records:formVal.attendance_records.map(record => record.file_path),
                actual_allowance_per_intern:formVal.actual_allowance_per_intern,
                is_external_training_given:formVal.is_external_training_given,
                is_internal_training_given:formVal.is_internal_training_given,
                total_allowance:formVal.total_allowance,
                expenditure_records: formVal.expenditure_records.map(record => record.file_path),
                is_acknowledged: formVal.is_acknowledged,
                additional_allowance: formVal.additionalAllowance,
                type_of_training_provided: formVal.typeOfTrainings,
                learning_outcomes: formVal.learningOutcome,
                external_training:[
                  {
                    external_training_course_title: formVal.external_training_course_title,
                    external_training_provider: formVal.external_training_provider,
                    external_training_duration: formVal.external_training_duration,
                    is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp,
                  }
                ],
                internal_training:[
                  {
                    internal_training_course_title: formVal.internal_training_course_title,
                    internal_training_provider: formVal.internal_training_provider,
                    internal_training_duration: formVal.internal_training_duration,
                  }
                ],
                proof_of_training: formVal.proof_of_training.map(record => record.file_path),
                // external_training: formVal.external_training,
                // internal_training: formVal.internal_training,
              };
              console.log(requestData,"RequestDataBeforeCompletion");

              const { data: completionSingleResponse } = await postCompletionform(internshipId, requestData);
              
              console.log(requestData,"RequestDataAfterCompletion");
              if (completionSingleResponse && completionSingleResponse.status === 1) {
                // All steps completed successfully for single intern
                // toast.success(t("Internship form submitted"), {
                //   position: toast.POSITION.TOP_RIGHT,
                // });
                // navigate("/internships");
                const {data: checkToken} = await CheckToken(internshipId);
              if(checkToken){
                setTokenData(checkToken);
                setShowSipTokenModal(true);
              }
              } else {
                toast.error(t("Failed to submit completion form for single intern"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            } else {
              toast.error(t("Failed to add the single intern"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          }
          
        } else if (isBulk) {
          // Step 2: Add Bulk Interns
          // if(addedIntern.length < sipTokens){
          //   alert("Please Top Up your Sip Tokens")
          //   setShowSipTokenModal(true);
          // }else{
            
          // }
          addedIntern.forEach((intern) => {
            intern.internship = internshipId
            intern.intern_email = intern.email
          });
          console.log(addedIntern,"Bulk Interns when upload")
          const { data: addInternResponse } = await internBulkUpload(addedIntern, internshipId);

          
          console.log(addInternResponse,"This is addInternResponse")

          if (addInternResponse && addInternResponse.status === 1) {
            // Step 3: Add Completion for Bulk Interns
            const requestData = {
              internship: internshipId,
              internship_start_date: formVal.exp_start_date,
              internship_end_date:formVal.exp_end_date,
              attendance_records:formVal.attendance_records.map(record => record.file_path),
              actual_allowance_per_intern:formVal.actual_allowance_per_intern,
              is_external_training_given:formVal.is_external_training_given,
              is_internal_training_given:formVal.is_internal_training_given,
              total_allowance:formVal.total_allowance,
              expenditure_records: formVal.expenditure_records.map(record => record.file_path),
              is_acknowledged: formVal.is_acknowledged,
              additional_allowance: formVal.additionalAllowance,
              type_of_training_provided: formVal.typeOfTrainings,
              learning_outcomes: formVal.learningOutcome,
              external_training:[
                {
                  external_training_course_title: formVal.external_training_course_title,
                  external_training_provider: formVal.external_training_provider,
                  external_training_duration: formVal.external_training_duration,
                  is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp,
                }
              ],
              internal_training:[
                {
                  internal_training_course_title: formVal.internal_training_course_title,
                  internal_training_provider: formVal.internal_training_provider,
                  internal_training_duration: formVal.internal_training_duration,
                }
              ],
              proof_of_training:formVal.proof_of_training.map(record => record.file_path),
              // external_training: formVal.external_training,
              // internal_training: formVal.internal_training,
            };
            const { data: completionBulkResponse } = await postCompletionform(internshipId, requestData);
  
            if (completionBulkResponse && completionBulkResponse.status === 1) {
              const {data: checkToken} = await CheckToken(internshipId);
              if(checkToken){
                setTokenData(checkToken);
                setShowSipTokenModal(true);
              }
              // All steps completed successfully for bulk interns
              // toast.success(t("Internship form submitted"), {
              //   position: toast.POSITION.TOP_RIGHT,
              // });
              // navigate("/internships");
            } else {
              toast.error(t("Failed to submit completion form for bulk interns"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }
          } else {
            toast.error(t("Failed to add the bulk interns"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
         
        } else {
          // Handle the case where neither isSingle nor isBulk is set
          toast.error(t("Invalid intern type"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          toast.error(t("Please check your data"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        
        }
      } 
      
  }
 } catch (error) {
    console.error("An error occurred:", error);
    // Handle error appropriately (e.g., show an error message).
  }
}

  const onNewIntern = async () => {
    // let ti = 0;
    // if (Object.keys(company).length > 0) {
    //   const result3 = await getOrganizationInternshipCoinDetail(company?.id);
    //   var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
    //   var today = new Date();
    //   if (varDate >= today) {
    //     setModalMode("empty-coin");
    //     ti = result3?.data?.data?.tokens;
    //     setCoins(ti);
    //   } else {
    //     setModalMode("empty-package");
    //   }
    // }
    // if (checkTrigger) {
      try {
        // Step 1: Create the Internship
        const { data, res } = await createNewIntern({
          ...DirectHiringData,
          documents: DirectHiringData.documents.filter((x) => x.doc_type !== "All"),
          collection_radio_type: DirectHiringData.collection_radio_type
            ? DirectHiringData.collection_radio_type
            : null,
          collection_date: DirectHiringData.collection_date
            ? DirectHiringData.collection_date
            : null,
          org_code: org_code,
          is_activate: true,
        });
    
        if (data && data.status === 1) {
          setLoading(true);
          const internshipId = await data?.data;
          await setLoading(true);
          await setInternshipAddId(internshipId);
    
          // Step 2: Add the Intern
          
          // const { addInternResponse } = await internBulkUpload(addedIntern, internshipId);
    
          // if (addInternResponse && addInternResponse.status === 1) {
            // await setLoading(true);
          // Step 3: Add Completion
          const requestData = {
            internship: internshipId,
            // internship_start_date: formVal.exp_start_date,
            // internship_end_date:formVal.exp_end_date,
            attendance_records:formVal.attendance_records.map(record => record.file_path),
            actual_allowance_per_intern:formVal.actual_allowance_per_intern,
            is_external_training_given:formVal.is_external_training_given,
            is_internal_training_given:formVal.is_internal_training_given,
            total_allowance:formVal.total_allowance,
            expenditure_records: formVal.expenditure_records.map(record => record.file_path),
            is_acknowledged: formVal.is_acknowledged,
            additional_allowance: formVal.additionalAllowance,
            type_of_training_provided: formVal.typeOfTrainings,
            learning_outcomes: formVal.learningOutcome,
            external_training:[
              {
                external_training_course_title: formVal.external_training_course_title,
                external_training_provider: formVal.external_training_provider,
                external_training_duration: formVal.external_training_duration,
                is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp,
              }
            ],
            internal_training:[
              {
                internal_training_course_title: formVal.internal_training_course_title,
                internal_training_provider: formVal.internal_training_provider,
                internal_training_duration: formVal.internal_training_duration,
              }
            ],
            proof_of_training:  formVal.proof_of_training.map(record => record.file_path),
            // external_training: formVal.external_training,
            // internal_training: formVal.internal_training,
          };
          const {data } = await postCompletionform(internshipId, requestData);

          console.log(data,"Completion response")
          if (data && data.status === 1) {
            // toast.success(t("Internship form submitted"), {
            //             position: toast.POSITION.TOP_RIGHT,
            //           });
            
            //  navigate("/internships")   
            const {data: checkToken} = await CheckToken(internshipId);
            if(checkToken){
              setTokenData(checkToken);
              setShowSipTokenModal(true);
            }
          }
          else{
            toast.error(t("Failed to submit internship"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          }
          
          // } else {
          //   setError(true);
          //   toast.error(t("Failed to add the intern"), {
          //     position: toast.POSITION.TOP_RIGHT,
          //   });
          // }
        } else {
          setError(true);
          toast.error(t("Please check your data"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error appropriately (e.g., show an error message).
      }
    // } 
    // else {
    //   if (ti < 1) {
    //     setModal(true);
    //     checkTrigger = false;
    //   } else {
    //     if (coins < parseInt(DirectHiringData.no_positions)) {
    //       setModalMode("position-coins");
    //       setModal(true);
    //       checkTrigger = false;
    //     } else {
    //       setModalMode("available-coins");
    //       setModal(true);
    //       checkTrigger = false;
    //     }
    //   }
    // }
  };
console.log(formVal.actual_allowance_per_intern, "ALLOWANCE")
  const showPaymentPopup = () => {
    setOpen(true);
  };
  const onInternshipSubmit = () => {
    setOpen(false);
    // setLoading(true);
    if (isUpdate) {
      onUpdateIntern();
    } else {
      onNewIntern(false);
    }
  };
  const radioChange = (value, name) => {
    setValue(name, value);
    if (name === "min_degree") {
      errors.min_degree = "";
    } else if (name === "grade") {
      errors.grade = "";
    }
    if (
      value === t("Receive appplications in a single package on a given date")
    ) {
      setEnableDate(true);
    } else {
      setEnableDate(false);
    }
  };

  const radioChangeForVisibilityStatus = (value, name) => {
    if (formVal.visibility_status) setValue(name, 0);
    else setValue(name, value);
  };

  const onMblNumberChange = (val) => {
    if (val) {
      setValue("contact_mob", val);
    }
  };

  const additionalAllowanceCheckboxChange = (option) => {
    const type = "additionalAllowance"; // Replace with the appropriate type
    const keyWord = "id"; // Assuming you want to use the ID
  
    let arr = Array.isArray(formVal[type]) ? [...formVal[type]] : [];
    const isChecked = arr.some((grd) => grd[keyWord] === option.id);
  
    if (isChecked) {
      arr = arr.filter((grd) => grd[keyWord] !== option.id);
    } else {
      const obj = { [keyWord]: option.id, name: option.name };
      arr.push(obj);
    }
  
    // Debugging: Log the updated array
    console.log(arr, "Allowance type");
  
    // Update the state
    setValue(type, arr);
    errors.additionalAllowance = "";
  };

  

  const learningOutcomesCheckboxChange = (option) => {
    const type = "learningOutcome"; // Replace with the appropriate type
    const keyWord = "id"; // Assuming you want to use the ID
  
    let arr = Array.isArray(formVal[type]) ? [...formVal[type]] : [];
    const isChecked = arr.some((grd) => grd[keyWord] === option.id);
  
    if (isChecked) {
      arr = arr.filter((grd) => grd[keyWord] !== option.id);
    } else {
      const obj = { [keyWord]: option.id, name: option.name };
      arr.push(obj);
    }
  
    // Debugging: Log the updated array
    console.log(arr, "LearningOutcomes");
  
    // Update the state
    setValue(type, arr);
    errors.learningOutcome = "";
  };
  console.log(formVal.typeOfTrainings, "type of training")
  console.log(formVal.additionalAllowance, "add allowane")
  console.log(formVal.learningOutcome, "leaningOutocme")

  const typeOfTrainingProvidedCheckBoxChange = (option) => {
    const type = "typeOfTrainings"; // Replace with the appropriate type
    const keyWord = "id"; // Assuming you want to use the ID
  
    let arr = Array.isArray(formVal[type]) ? [...formVal[type]] : [];
    const isChecked = arr.some((grd) => grd[keyWord] === option.id);
  
    if (isChecked) {
      arr = arr.filter((grd) => grd[keyWord] !== option.id);
    } else {
      const obj = { [keyWord]: option.id, name: option.name };
      arr.push(obj);
    }
  
    // Debugging: Log the updated array
    console.log(arr, "Allowance type");
  
    // Update the state
    setValue(type, arr);
    errors.typeOfTrainings = "";
  };

  const handleTextOth = (type, option, val) =>{

    const updatedFormVal = formVal[type].map((item) =>
      item.id === option.id ? { ...item, other: val } : item
    );
  
    setValue(type, updatedFormVal);
  }

  const docCheckboxChange = (name, type) => {
    let arr = [...formVal[type]];
    let keyWord = "doc_type";
    const isChecked = arr.some((grd) => grd[keyWord] === name);
    if (isChecked) {
      if (name === "All") {
        arr = [];
      }
      arr = [...arr].filter((grd) => grd[keyWord] !== name);
      arr = [...arr].filter((grd) => grd[keyWord] !== "All");
    } else {
      if (name === "All") {
        arr = completionOfInternshipObj.requiredDocs.map((m) => {
          return { doc_type: m };
        });
      } else {
        const obj = { [keyWord]: name };
        arr.push(obj);
      }
      if (arr.length === 4) {
        const obj = { [keyWord]: "All" };
        arr.push(obj);
      }
    }
    setValue(type, arr);
    errors.documents = "";
    // alert(name)
  };

  const addLocation = () => {
    let arr = [...formVal.locations];
    let obj = {};
    var myValid = false;
    if (formVal.state !== "" && cities.length === 0) {
      myValid = true;
      obj = {
        country: formVal.country,
        state: formVal.state,
      };
    } else if (formVal.country && formVal.state && formVal.city) {
      myValid = true;
      obj = {
        country: formVal.country,
        state: formVal.state,
        city: formVal.city,
      };
    }
    const isAlreadyAdded = arr.some(
      (loc) => JSON.stringify(loc) === JSON.stringify(obj)
    );
    if (myValid === true && !isAlreadyAdded) {
      arr.push(obj);
      setValue("locations", arr);
      setValue("state", "");
      setSelectedState([]);
      setValue("country", "");
      setValue("country", "Malaysia");
      setSelectedCountry({ label: "Malaysia", value: 132 });
      getState(132);
      setValue("city", "");
      setSelectedCity([]);
      setDuplicateLocErr("");
      setHideLocation(true);
      errors.locations = "";
    } else if (isAlreadyAdded) {
      setDuplicateLocErr(
        t("The selected location is already added to the list")
      );
    }
  };

  const onDeleteLocation = (index) => {
    let arr = [...formVal.locations];
    let newArr = arr.filter((loc, ind) => ind !== index);
    setValue("locations", newArr);
  };

  const contactPersonChange = (val) => {
    setValue("contact_person", val.label);
    setValue("contact_name", val.label);
    setValue("contact_email", val.email);
    setValue("contact_mob", val.mob_number);
    errors.contact_person = "";
  };
  const collectionTypeValue = (val) => {
    setValue("collection_type", val.label);
    if (val.label === t("Manually download applications")) {
      setValue("re_email_address", "");
      setValue("collection_radio_type", "");
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      errors.collection_type = "";
    } else if (val.label === t("Receive applications by E-mail")) {
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      errors.collection_type = "";
    } else if (val.label === t("Others")) {
      setValue("re_email_address", "");
      setValue("collection_radio_type", "");
      errors.collection_type = "";
    }
  };

  const scopeAddChange = (val) => {
    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setValue("scopes", newVal);
    setSelectedScope(newVal);
    errors.scopes = "";
  };

  
  
  const uploadFileexpenditure_records = async (event) => {
    const formdata = new FormData();
  
    let maxFileSize = 1024 * 1024 * 5; // 5 MB
    let allowedFileTypes = ['application/pdf'];
  
    const file = event.target.files[0];
  
    if (file.size > maxFileSize) {
      toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!allowedFileTypes.includes(file.type)) {
      toast.error("Please upload only PDF files!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      formdata.append("obj_content", file, file.name);
  
      const { data, status } = await uploadObject(formdata);
      setIsUpload(!isUpload);
      if (status === 200) onUploadedexpenditure_records(data);
    }
  };
  
  const onUploadedexpenditure_records = (data) => {
    const { status } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.expenditure_records];
      arr.push(obj);
      setValue("expenditure_records", arr);
      errors.expenditure_records= "";
    }
  };
  // const onUploadedexpenditure_records = (data) => {
  //   const { status } = data;
  //   if (status === 1) {
  //     setValue("expenditure_records", data.data.obj_content);
  //   }
  // };

  const onDeleteFilesexpenditure_records = (index) => {
    const arr = [...formVal.attendance_records];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("expenditure_records", newArr);
  };


  const uploadFileattendance_records = async (event) => {
    const formdata = new FormData();
    let maxFileSize = 1024 * 1024 * 5; // 5 MB
    let allowedFileTypes = ['application/pdf'];
  
    for (const file of event.target.files) {
      if (file.size > maxFileSize) {
        toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (!allowedFileTypes.includes(file.type)) {
        toast.error("Please upload only PDF files!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        formdata.append("obj_content", file, file.name);
      }
    }
  
    try {
      const { data, status } = await uploadObject(formdata);
      setIsUpload(!isUpload);
      if (status === 200) {
        onUploadedattendance_records(data);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  
  // const uploadFileattendance_records = async (event) => {
  //   const formdata = new FormData();

  //   let maxFileSize = 1024 * 1024 * 5; //100mb

  //   if (event.target.files[0].size > maxFileSize) {
  //     toast.error(
  //       t(
  //         "Only PDF files of size less than or equal to 5 MB can be uploaded!"
  //       ),
  //       {
  //         position: toast.POSITION.TOP_RIGHT,
  //       }
  //     );
  //   } else {
  //     formdata.append(
  //       "obj_content",
  //       event.target.files[0],
  //       event.target.files[0].name,
       
  //     );
  //     const { data, status } = await uploadObject(formdata);
  //     setIsUpload(!isUpload);
  //     if (status === 200) onUploadedattendance_records(data);
  //   }
  // };

  const onUploadedattendance_records = (data) => {
    const { status } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.attendance_records];
      arr.push(obj);
      setValue("attendance_records", arr);
      errors.attendance_records= "";
    }
    // if (status === 1) {
    //   setValue("attendance_records", data.data.obj_content);
    // }
  };
console.log(formVal.attendance_records, "RECORDS")
  // const onDeleteFilesattendance_records = (index) => {
  //   const arr = [...formVal.attendance_records];
  //   const newArr = arr.filter((skill, ind) => ind !== index);
  //   setValue("attendance_records", newArr);
  // };

  const onDeleteFilesattendance_records = (index) => {
    const arr = [...formVal.attendance_records];
    arr.splice(index, 1); // Remove the file at the specified index
    setValue("attendance_records", arr);
  };

  const uploadFileproof_of_training = async (event) => {
    const formdata = new FormData();
  
    let maxFileSize = 1024 * 1024 * 5; // 5 MB
    let allowedFileTypes = ['application/pdf'];
  
    for (const file of event.target.files) {
      if (file.size > maxFileSize) {
        toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (!allowedFileTypes.includes(file.type)) {
        toast.error("Please upload only PDF files!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        formdata.append("obj_content", file, file.name);
      }
    }
  
    try {
      const { data, status } = await uploadObject(formdata);
      setIsUpload(!isUpload);
      if (status === 200) {
        onUploadedproof_of_training(data);
      }
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  

  const onUploadedproof_of_training= (data) => {
    const { status } = data;
    if (status === 1) {
      console.log(data, "Uploaded file")
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.proof_of_training];
      arr.push(obj);
      setValue("proof_of_training", arr);
      errors.proof_of_training= "";
    }
    // if (status === 1) {
    //   setValue("attendance_records", data.data.obj_content);
    // }
  };

  const onDeleteFilesproof_of_training = (index) => {
    const arr = [...formVal.proof_of_training];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("proof_of_training", newArr);
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
    if (name === "position_start_date") {
      setValue(
        "position_end_date",
        moment(new Date(val).setDate(new Date(val).getDate() + 180)).format(
          "YYYY-MM-DD"
        )
      );
    }

    if(name === "exp_start_date"){
      setValue(
        "exp_end_date",
        moment(new Date(val).setDate(new Date(val).getDate() + 70)).format(
          "YYYY-MM-DD"
        )
      )
    }

    if (name === "collection_date") {
      errors.collection_date = "";
    }
  };

  var fileName = "";
  var ext = "";

  const getfileName = (file_path) => {
    // Split the URL by '/' and get the last part
    const parts = file_path.split('/');
    const fileNameWithExtension = parts[parts.length - 1];

    // Split the file name by '.' to separate the extension
    const fileNameParts = fileNameWithExtension.split('.');
     fileName = fileNameParts[0]; // The file name without extension
     ext = fileNameParts[1]; // The file extension

    //return { fileName, ext };
};

var fileNameattendance_records = "";
var extattendance_records = "";

const getfileNameattendance_records = (file_path) => {
  // Split the URL by '/' and get the last part
  const parts_attendance = file_path.split('/');
  const fileNameWithExtensionattendance = parts_attendance[parts_attendance.length - 1];

  // Split the file name by '.' to separate the extension
  const fileNamePartsattendance = fileNameWithExtensionattendance.split('.');
  fileNameattendance_records = fileNamePartsattendance[0]; // The file name without extension
  extattendance_records = fileNamePartsattendance[1]; // The file extension

  //return { fileName, ext };
};



  const updateCgpaValue = (val) => {
    if (val.length === 1) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + ".00");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 2) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + "00");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 3) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + "0");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 4) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val);
      } else {
        setValue("req_gpa", "");
      }
    } else {
      setValue("req_gpa", val);
    }

    validateReqCgpaValue();
  };

  const validateReqCgpaValue = () => {
    const reCgpa = formVal.req_gpa;

    if (reCgpa < 0 || reCgpa > 4 || isNaN(reCgpa)) {
      setError("req_gpa", {
        type: "string",
        message: t("Minimum required CGPA should be between 0.00 and 4.00"),
      });
      return;
    }

    clearErrors("req_gpa");
  };

  const updateWorkingHoursValue = (val) => {
    if (val.length === 1) {
      if (val >= 1 && val <= 24) {
        setValue("working_hours", val + ".0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 2) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", val + "0");
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val + ".0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 3) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", val);
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val + "0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 4) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", (Math.floor(val * 10) / 10).toPrecision(2));
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val);
      } else {
        setValue("working_hours", "");
      }
    } else {
      setValue("working_hours", "");
    }

    validateWorkingHoursValue();
  };



  const validateWorkingHoursValue = () => {
    const workingHours = formVal.working_hours;

    if (workingHours < 1 || workingHours > 24 || isNaN(workingHours)) {
      setError("working_hours", {
        type: "string",
        message: t("Working Hours should be between 1 and 24 hours per day"),
      });
      return;
    }

    clearErrors("working_hours");
  };

  const coinSet = async () => {
    const result3 = await getOrganizationInternshipCoinDetail(company?.id);
    var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
    var today = new Date();
    if (varDate >= today) {
      setCoins(result3?.data?.data?.tokens);
    } else {
      setCoins(0);
    }
  };


  const handleInternalTrainingRadio = (event)=>{
    const selectedValue = event.target.value === 'Yes' ? 1 : 0;
    setInternalTraningRadio(event.target.value)
  setValue('is_internal_training_given', selectedValue);
  }
  const handleMinDurationChange = (event) => {
    const selectedValue = event.target.value === 'Yes' ? 1 : 0;
    setExternalTrainingsRadio(event.target.value);
    setValue('is_external_training_given', selectedValue);
    
    // onSubmit({ studentDiffer: value });
  };

  const handlePayMinimumSKMChange = (event) =>{
    const selectedValue = event.target.value === 'Yes' ? 1 : 0;
    setEmploymentOpp(event.target.value);
    setValue('is_external_training_provider_certified_by_the_hrdcorp', selectedValue);
    
    
    // onSubmit({ employmentOpp: value });
  }

  const handleMinMonthCertChange = (event) => {
    const value = event.target.value;

    setFwa(value);
    // onSubmit({fWa: value});
  };
  useEffect(() => {
    if (company?.id !== undefined) {
      coinSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  useEffect(() => {
    setValue("country", "Malaysia");
    setSelectedCountry({ label: "Malaysia", value: 132 });
    getState(132);
    setValue("intern_type", "sip");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formVal.contact_name !== null) {
      setValue("contact_person", formVal.contact_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.contact_name]);

  const sipTokenContent=()=>{
    return(
      <>
      
       <ModalHeader></ModalHeader>
       <ModalHeaderText>{t("Insufficient MySIP Tokens")}</ModalHeaderText>
       <ModalHeaderTextTwo>
         
       </ModalHeaderTextTwo>
       <ModalHeaderTextThree>
       {"You Have "+sipTokens+" MySIP Tokens"}
        </ModalHeaderTextThree>
        <ModalHeaderTextFour>
            {"Please contact us at company@mynext.my to Top up your MySIP Token"}
          </ModalHeaderTextFour>
          <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
                  style={{ width: "213px" }}
                  margin="40px 0px 30px 0px"
                  name={"Contact Us"}
                  onClick={onSipModalSubmit}
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={handleCloseModal }
                ></Button>
          </Col>
        </Row>
          
       </>
    )
  }

  return (
    <>
      {isOpen === true ? (
        <PaymentPopup
          intershipName={formVal.title}
          companyName={companyData.name}
          setOpen={setOpen}
          isOpen={isOpen}
          onInternshipSubmit={onInternshipSubmit}
        />
      ) : (
        ""
      )}
      <MainLayout>
        {/* <HamburgerLayout>
        <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
      </HamburgerLayout>
      {isOpenham && <Menus />} */}
        {id === null ? (
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
              >
                {t("Internships")} &nbsp; &#62;
              </Link>
              <div className="mx-2" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("New Internship")}
              </span>
            </div>
          </div>
        ) : (
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
                // className="linkPre"
              >
                {t("Internships")} &nbsp; &#62;
              </Link>
              <div className="mx-2" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("Edit Internship")}
              </span>
            </div>
          </div>
        )}
        {pageLoading ? (
          <Loader />
        ) : (
          <NewInternPageWrapper>
            {/* <AlertModal show={showSipTokenModal} close={onClose} content={sipTokenContent()}/> */}
             <CreateInternshipModuleModal
             title={"Completion Internship"}
             subTitle={DirectHiringData.title}
             show={showSipTokenModal}
             close={onClose}
             content={<TokenContent data={tokenData}/>}
             />
            <SectionWrapper>
              <HeaderText>
                {!isUpdate
                  ? t(completionOfInternshipObj.heading)
                  : t(completionOfInternshipObj.editHeading)}
                  <PaginationDivider step={3}/>
              </HeaderText>
              <HeaderText2>{completionOfInternshipObj.completionForm}</HeaderText2>
              <Description>{t(completionOfInternshipObj.description)}</Description>
              <SubHeading>{completionOfInternshipObj.subHeading1}</SubHeading>
              <BreakSection>
                <hr/>
                </BreakSection>
              {/* <Description3>{completionOfInternshipObj.datesHeading}</Description3> */}
              <FormWrapper >
                {/* intern detail section */}
                <FormGroup>

                  <SingleField>
                    <UploadText>{t(completionOfInternshipObj.uploadTitle)}</UploadText>
                      <UploadNotes>
                        {t(completionOfInternshipObj.uploadNotes)}
                      </UploadNotes>
                      <UploadFileWrapper data-tip data-for="pdf">
                      <input
                        onChange={uploadFileattendance_records}
                        accept="application/pdf"
                        type="file"
                      />
                    <UploadFileText>
                    {t(completionOfInternshipObj.uploadFileText)}
                    </UploadFileText>
                    {isUpload===false?<UploadNotes>No file choosen</UploadNotes> :<UploadNotes></UploadNotes>}
                    <p className="uploadFileOne">
                      {t("The maximum file size limit is 5 MB")}
                    </p>
                  </UploadFileWrapper>
                  <FileWrapper>
                    {formVal?.attendance_records?.length > 0
                      ? formVal.attendance_records?.map((x, index) => (
                          <>
                            {getfileName(x.file_path)}
                            <SkillCard
                              key={index + x.file_path}
                              delete={() => onDeleteFilesattendance_records(index)}
                              // name={x.file_path}
                              name={fileName + "." + ext}
                            />
                          </>
                        ))
                      : ""}
                  </FileWrapper>

                   

                  {errors.attendance_records && (
                    <ErrorField err={t(errors.attendance_records?.message)} />
                  )}
                  </SingleField>

                  
                  <SingleField>
                    <FormLabel>{t(completionOfInternshipObj.minimumAllowance)}</FormLabel>
                    <UploadNotes style={{marginLeft:"9px",marginBottom:"5px"}}>
                        {t(completionOfInternshipObj.allowanceNotes)}
                      </UploadNotes>
                    <Controller
                      name="actual_allowance_per_intern"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("RM")}
                          dataId="actual_allowance_per_intern"
                        />
                      )}
                    />
                    {errors.actual_allowance_per_intern && (
                      <ErrorField err={t(errors.actual_allowance_per_intern.message)} />
                    )}
                  </SingleField>
                  <DividerLine/>
                  

                  <FormGroup>
                    <FormHeaderText>Training Information</FormHeaderText>
                    <FormLabel>{t(completionOfInternshipObj.internalTraning)}</FormLabel>
                    <UploadNotes style={{marginLeft:"12px", marginBottom:"10px"}}>
                        {t(completionOfInternshipObj.internalTraningNotes)}
                      </UploadNotes>
                      <Controller
                      name="is_internal_training_given"
                      control={control}
                      render={({ field }) => (
                        <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="Yes"
                          checked={internalTraningRadio === 'Yes'}
                          onChange={
                            handleInternalTrainingRadio
                            // setMinDuration("Yes")
                          }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="No"
                          checked={internalTraningRadio === 'No'}
                          onChange={
                            handleInternalTrainingRadio
                           }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                      )}
                    />
                    
                    {errors.intern_type && (
                      <ErrorField err={t(errors.intern_type.message)} />
                    )}
                </FormGroup>
                {internalTraningRadio==='Yes'?(
                  <FormGroup>
                  <FormLabel style={{marginBottom:"0px"}}>
                    {t(completionOfInternshipObj.providerDetails + "(Internal)")}
                  </FormLabel>
                  <Row className="mb-4">
                   <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                     <FormLabel>{(completionOfInternshipObj.courseTitle)}</FormLabel>
                     <Controller
                         name="internal_training_course_title"
                         control={control}
                         render={({ field }) => (
                           <Input
                             {...field}
                             placeholder={t("Course title")}
                             dataId="internal_training"
                           />
                         )}
                       />
                       {errors.internal_training_course_title && (
                        <ErrorField err={errors.internal_training_course_title.message}/>
                       )}
                   </Col>
                   <Col>
                   <FormLabel>{(completionOfInternshipObj.trainingProvider)}</FormLabel>
                     <Controller
                           name="internal_training_provider"
                           control={control}
                           render={({ field }) => (
                             <Input
                               {...field}
                               placeholder={t("Training Provider")}
                               dataId="internal_training"
                             />
                           )}
                         />
                          {errors.internal_training_provider && (
                        <ErrorField err={errors.internal_training_provider.message}/>
                       )}
                   </Col>
                   <Col>
                   <FormLabel>{(completionOfInternshipObj.durationOfTraning)}</FormLabel>
                     <Controller
                           name="internal_training_duration"
                           control={control}
                           render={({ field }) => (
                             <Input
                               {...field}
                               placeholder={t("Duration")}
                               dataId="internal_training"
                             />
                           )}
                         />
                          {errors.internal_training_duration && (
                        <ErrorField err={errors.internal_training_duration.message}/>
                       )}
                   </Col>
                   </Row>
                   {errors.providerDetails && (
                     <ErrorField err={t(errors.providerDetails.message)} />
                   )}
                </FormGroup>
                ):""}

                  <FormGroup style={{marginTop:"50px"}}>
                    <FormLabel>{t(completionOfInternshipObj.externalTrainingsQuestions)}</FormLabel>
                    <UploadNotes style={{marginLeft:"12px", marginBottom:"10px"}}>
                        {t(completionOfInternshipObj.externalTrainingsQuestionsNotes)}
                      </UploadNotes>
                      <Controller
                      name="is_external_training_given"
                      control={control}
                      render={({ field }) => (
                        <RadioWrapper>
                        <RadioButtonGroup>
                        <input 
                        type="radio" 
                        className="radio"
                        value="Yes"
                        checked={externalTrainingsRadio === 'Yes'}
                        onChange={
                          handleMinDurationChange
                          // setMinDuration("Yes")
                        }/>
                        <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                        </RadioButtonGroup>

                        <RadioButtonGroup>
                        <input 
                        type="radio" 
                        className="radio"
                        value="No"
                        checked={externalTrainingsRadio === 'No'}
                        onChange={
                          handleMinDurationChange
                         }/>
                        <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                        </RadioButtonGroup>
                  </RadioWrapper>
                      )}
                    />
                    
                    {errors.intern_type && (
                      <ErrorField err={t(errors.intern_type.message)} />
                    )}
                </FormGroup>
                <FormGroup>
                {externalTrainingsRadio === "Yes" ? (
                    <>
                      <FormLabel>{t(completionOfInternshipObj.hrdCertified)}</FormLabel>
                      <Controller
                      name="is_external_training_provider_certified_by_the_hrdcorp"
                      control={control}
                      render={({ field }) => (
                        <RadioWrapper>
                        <RadioButtonGroup>
                          <input
                            type="radio"
                            className="radio"
                            value="Yes"
                            checked={employmentOpp === 'Yes'}
                            onChange={handlePayMinimumSKMChange}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                        </RadioButtonGroup>

                        <RadioButtonGroup>
                          <input
                            type="radio"
                            className="radio"
                            value="No"
                            defaultChecked
                            checked={employmentOpp === 'No'}
                            onChange={handlePayMinimumSKMChange}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                        </RadioButtonGroup>
                      </RadioWrapper>
                      )}
                    />
                     
                      {errors.intern_type && (
                        <ErrorField err={t(errors.intern_type.message)} />
                      )}
                    </>
                  ) : null}

            
                </FormGroup>

                <SingleField>
                  {externalTrainingsRadio === "Yes"?(
                  <>
                 <FormGroup>
                   <FormLabel style={{marginBottom:"0px"}}>
                     {t(completionOfInternshipObj.providerDetails + "(External)")}
                   </FormLabel>
                   <Row className="mb-4">
                    <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                      <FormLabel>{(completionOfInternshipObj.courseTitle)}</FormLabel>
                      <Controller
                          name="external_training_course_title"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t("Course title")}
                              dataId="external_training"
                            />
                          )}
                        />
                         {errors.external_training_course_title && (
                      <ErrorField err={t(errors.external_training_course_title.message)} />
                    )}
                    </Col>
                    <Col>
                    <FormLabel>{(completionOfInternshipObj.trainingProvider)}</FormLabel>
                      <Controller
                            name="external_training_provider"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t("Training Provider")}
                                dataId="external_training"
                              />
                            )}
                          />
                    {errors.external_training_provider && (
                      <ErrorField err={t(errors.external_training_provider.message)} />
                    )}
                    </Col>
                    <Col>
                    <FormLabel>{(completionOfInternshipObj.durationOfTraning)}</FormLabel>
                      <Controller
                            name="external_training_duration"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t("Duration")}
                                dataId="external_training"
                              />
                            )}
                          />
                           {errors.external_training_duration && (
                      <ErrorField err={t(errors.external_training_duration.message)} />
                    )}
                    </Col>
                    </Row>
                 </FormGroup>
                    
                  </>): null}

                  {externalTrainingsRadio ==="Yes" || internalTraningRadio ==="Yes"? 
                  (
                    <>
                     <UploadText style={{marginTop:"50px"}}>{t(completionOfInternshipObj.hrdCertifiedUpload)}</UploadText>
                      <UploadNotes>
                        {t(completionOfInternshipObj.uploadProof)}
                      </UploadNotes>
                      <UploadFileWrapper data-tip data-for="pdf">
                      <input
                        onChange={uploadFileproof_of_training}
                        accept="application/pdf"
                        type="file"
                      />
                    <UploadFileText>
                    {t(completionOfInternshipObj.uploadFileText)}
                    </UploadFileText>
                    {isUpload===false?<UploadNotes>No file choosen</UploadNotes> :<UploadNotes></UploadNotes>}
                    <p className="uploadFileOne">
                      {t("The maximum file size limit is 5 MB")}
                    </p>
                  </UploadFileWrapper>
                  
                     <FileWrapper>
                     {formVal?.proof_of_training?.length > 0
                      ? formVal.proof_of_training?.map((x, index) => (
                          <>
                            {getfileName(x.file_path)}
                            <SkillCard
                              key={index + x.file_path}
                              delete={() => onDeleteFilesproof_of_training(index)}
                              // name={x.file_path}
                              name={fileName + "." + ext}
                            />
                          </>
                        ))
                      : ""}
                  </FileWrapper>

                  {errors.proof_of_training && (
                    <ErrorField err={t(errors.proof_of_training?.message)} />
                  )} 
                    
                   

                    <FormGroup>
                      <FormLabel>
                        {("Type of training provided?")}{" \n"}
                        
                      </FormLabel>
                      <UploadNotes style={{marginLeft:"12px"}}> {t("(Please select at least one)*")}</UploadNotes>
                      <Controller
                        name="typeOfTrainings"
                        control={control}
                        render={({ field }) =>
                        <div>
                          {getTraningProvideTypeData && 
                          getTraningProvideTypeData.map((option)=>(
                            <div key={option.id}>
                          <CheckboxInput
                            margin="15px"
                            onChange={() => typeOfTrainingProvidedCheckBoxChange(option)}
                            label={option.name}
                            name={option.id}
                            checked={formVal.typeOfTrainings?.some(
                              (grd) => grd.id === option.id
                            )}
                          />
                          {option.name === 'Others (please specify)' && formVal.typeOfTrainings.some(
                            (item)=> item.name === 'Others (please specify)'
                          )? (
                            <Input
                            placeholder="Other type of training"
                            onChange={(e)=> handleTextOth("typeOfTrainings", option, e.target.value)}
                            />
                          ): null}
                        </div>
                          ))}
                        </div>
                        }
                      />
                      {errors.typeOfTrainings && (
                        <ErrorField err={t(errors.typeOfTrainings.message)} />
                      )}
                </FormGroup>
                  
                    </>
                  ): null}

                 
                </SingleField>

                <DividerLine/>
               
               
                  <SingleField>
                    <FormLabel>{t(completionOfInternshipObj.allowance)}</FormLabel>
                    <UploadNotes style={{marginLeft:"9px",marginBottom:"5px"}}>
                        {t(completionOfInternshipObj.allowanceNotes)}
                      </UploadNotes>
                    <Controller
                      name="total_allowance"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("RM")}
                          dataId="total_allowance"
                        />
                      )}
                    />
                    {/* {errors.allowance && (
                      <ErrorField err={t(errors.allowance.message)} />
                    )} */}
                  </SingleField>

                  {/* <SingleField>
                    <FormLabel>{t(completionOfInternshipObj.additionalAllowance)}</FormLabel>
                    <UploadNotes style={{marginLeft:"9px",marginBottom:"5px"}}>
                        {t(completionOfInternshipObj.additionalAllowanceNotes)}
                      </UploadNotes>
                    <Controller
                      name="additionalAllowance"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("Education Level")}
                          dataId="additionalAllowance"
                        />
                      )}
                    />
                    {errors.additionalAllowance && (
                      <ErrorField err={t(errors.additionalAllowance.message)} />
                    )}
                  </SingleField> */}

             <SingleField>
              <FormLabel>{t(completionOfInternshipObj.additionalAllowance)}</FormLabel>
              <UploadNotes style={{ marginLeft: "9px", marginBottom: "5px" }}>
                {t(completionOfInternshipObj.additionalAllowanceNotes)}
              </UploadNotes>
              <Controller
                name="additionalAllowance"
                control={control}
                render={({ field }) => (
                  <div>
                    {getAllowanceData &&
                      getAllowanceData.map((option) => (
                        <div key={option.id}>
                          <CheckboxInput
                            margin="15px"
                            onChange={() => additionalAllowanceCheckboxChange(option)}
                            label={option.name}
                            name={option.id}
                            checked={formVal.additionalAllowance?.some(
                              (grd) => grd.id === option.id
                            )}
                          />
                          {option.name === "Other Allowances" && formVal.additionalAllowance.some(
                            (item) => item.name === "Other Allowances"
                          ) ? (
                            <Input
                            placeholder="Other additional allowances"
                            onChange={(e)=> handleTextOth("additionalAllowance", option, e.target.value)}
                            />
                          ): null}
                        </div>
                      ))}
                  </div>
                )}
              />
              {/* {errors.additionalAllowance && (
                <ErrorField err={t(errors.additionalAllowance.message)} />
              )} */}
            </SingleField>


                  <SingleField>
                    <UploadText>{t(completionOfInternshipObj.expenditures)}</UploadText>
                      <UploadNotes>
                        {t(completionOfInternshipObj.expendituresNotes)}
                      </UploadNotes>
                      <UploadFileWrapper data-tip data-for="pdf">
                      <input
                        onChange={uploadFileexpenditure_records}
                        accept="application/pdf"
                        type="file"
                      />
                      <UploadFileText>
                        {t(completionOfInternshipObj.uploadFileText)}
                      </UploadFileText>
                      {isUpload===false?<UploadNotes>No file choosen</UploadNotes> :<UploadNotes></UploadNotes>}
                      <p className="uploadFileOne" style={{marginTop:"5px"}}>
                        {t("The maximum file size limit is 5 MB")}
                      </p>
                    </UploadFileWrapper>
                    <FileWrapper>
                    {formVal?.expenditure_records?.length > 0
                      ? formVal.expenditure_records?.map((x, index) => (
                          <>
                            {getfileName(x.file_path)}
                            <SkillCard
                              key={index + x.file_path}
                              delete={() => onDeleteFilesexpenditure_records(index)}
                              // name={x.file_path}
                              name={fileName + "." + ext}
                            />
                          </>
                        ))
                      : ""}
                  </FileWrapper>

                  {errors.expenditure_records && (
                    <ErrorField err={t(errors.expenditure_records?.message)} />
                  )}
                    
                  </SingleField>



                  <FormGroup>
                      <FormLabel>
                        {(completionOfInternshipObj.learningOutcomes)}{" \n"}
                        
                      </FormLabel>
                      <UploadNotes style={{marginLeft:"12px"}}> {t(completionOfInternshipObj.learningOutcomesNotes)}</UploadNotes>
                      <Controller
                        name="documents"
                        control={control}
                        render={({ field }) =>
                        <div>
                          {getLearningOutcomeData &&
                      getLearningOutcomeData.map((option) => (
                        <div key={option.id}>
                          <CheckboxInput
                            margin="15px"
                            onChange={() => learningOutcomesCheckboxChange(option)}
                            label={option.name}
                            name={option.id}
                            checked={formVal.learningOutcome?.some(
                              (grd) => grd.id === option.id
                            )}
                          />
                          {option.name === "Others (please specify)" && formVal.learningOutcome.some(
                            (item) => item.name === "Others (please specify)"
                          )? (
                            <Input
                            placeholder="Other learning outcome"
                            onChange={(e)=> handleTextOth("learningOutcome", option, e.target.value)}
                            />
                          ): null}
                        </div>
                      ))}
                        </div>
                        
                        }
                      />
                      {errors.learningOutcome && (
                        <ErrorField err={t(errors.learningOutcome.message)} />
                      )}
                </FormGroup>
                </FormGroup>
                
                {/* Application information collection ends */}
                {loading ? (
                  <Loader />
                ) : (
                <ButtonWrapper>
                    <Button 
                    //redo submit logic when implementing back end 
                    // onClick={handleSubmit(onSubmit)}
                    // // onClick={() => { 
                    // //   handleSubmit(onSubmitErrorHandler());
                    // // onHandleSubmitForm}}
                    onClick={handleSubmit(onHandleSubmitForm, onSubmitErrorHandler)}
                    type = "submit"
                    cursor="pointer"
                    name={t("Continue")}
                    ></Button>
                    {/* <Button
                    secondary
                    color="#FFFFFF"
                    name={t("Back")}
                    variant="outlined"
                    onClick={handleSubmit(onSubmit, onSubmitErrorHandler)}
                    ></Button> */}
                </ButtonWrapper>
                )}
              </FormWrapper>
              <Tooltip id="internTitle" content={t("Enter internship title")} />
              <Tooltip id="uploadDocuments" content="Upload Documents" />
              <Tooltip
                id="cgpa"
                content={t("Enter required CGPA (Range 0.0 to 4.0)")}
              />
              <Tooltip
                id="skill"
                content={t("Type here to search and add skill(s)")}
              />
              <Tooltip
                id="document"
                content={t("Mention the optional documents, if any")}
              />
              <Tooltip id="hours" content={t("Enter hours (Range 1 to 24)")} />
              <Tooltip id="minComp" content={t("Min. compensation")} />
              <Tooltip id="maxComp" content={t("Max. compensation")} />
              <Tooltip id="country" content={t("Select Country")} />
              <Tooltip id="state" content={t("Select State")} />
              <Tooltip id="city" content={t("Select city")} />
              <Tooltip
                id="pdf"
                content={t(
                  "Upload PDF document to provide applicants with more information"
                )}
              />
              <Tooltip
                id="contact"
                content={t("DISPLAY CONTACT INFORMATION TO STUDENTS")}
              />
            </SectionWrapper>
          </NewInternPageWrapper>
        )}
        <AlertModal show={modal} close={onClose} content={content()} />
      </MainLayout>
    </>
  );
}
