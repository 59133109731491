import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { cloneDeep } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
    cutout: "65%",
    borderRadius: 10,
    aspectRatio: 1,
    rotation: 0,
    layout: {
        padding: {
            left: 40,
            right: 40,
            top: 0,
            bottom: 0,
        },
        margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
            width: 40,
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
    title: {
        display: false,
    },
};

const getData = (end, currentValue, donutColor, labels = []) => ({
    labels: labels || [],
    datasets: [
        {
            data: [currentValue, end - currentValue],
            backgroundColor: [donutColor, "#ECECEC"],
            borderColor: [donutColor, "#ECECEC"],
            borderWidth: [0.5, 0],
            hoverBackgroundColor: [donutColor, "#ECECEC"],
        },
    ],
});

const getPlugins = (currentValue) => ({
    id: "curves",
    beforeDraw(chart) {
        const {
            ctx,
        } = chart;
        ctx.save();
        const arc = chart.getDatasetMeta(0).data[0];
        ctx.strokeStyle = "#ECECEC";
        ctx.lineWidth = arc.outerRadius - arc.innerRadius;
        ctx.lineCap = "round";
        const radiusDifference = arc.outerRadius - arc.innerRadius;
        const unfillRadius = arc.innerRadius + radiusDifference / 2;
        const offsetAngle = Math.asin(ctx.lineWidth / 2 / unfillRadius);
        ctx.beginPath();
        ctx.arc(
            arc.x,
            arc.y,
            arc.innerRadius + ctx.lineWidth / 2,
            0 - offsetAngle,
            Math.PI * 2,
            false
        ); 
        ctx.stroke();
        ctx.font = `normal normal 700 36px/36px Oswald`;
        ctx.fillStyle = "#D04C8D";
        ctx.textAlign = "center";
        ctx.textBaseline = "middle";
        ctx.fillText(`${currentValue}%`, arc.x, arc.y);
    },
});

export default function ProgressChart({
    currentValue,
    optionsOverride,
    gradient
}) {
    let finalOptions = cloneDeep(defaultOptions);
    if (optionsOverride) {
        finalOptions = { ...finalOptions, ...optionsOverride };
    }
    const curves = getPlugins(currentValue);
    const chartRef = React.useRef(null);
    const data = getData(100, currentValue, "#ECECEC");
    const [chartData, setChartData] = React.useState(data);

    const plugins = [curves];

    React.useEffect(() => {
        const chart = chartRef.current;
        const ctx = chart && chart.ctx;

        if (ctx && gradient.length) {
            const angle = 95 * Math.PI / 180,
                x2 = chart.width * Math.cos(angle),
                y2 = chart.height * Math.sin(angle);
            const gradient2 = ctx.createLinearGradient(0, 0, x2, y2);
            const [gradientStart, gradientEnd] = gradient;
            gradient2.addColorStop(0.2321, gradientStart);
            gradient2.addColorStop(0.6835, gradientEnd);
            const data = getData(100, currentValue, gradient2);
            setChartData(data);
        }
    }, []);

    return (
        <Doughnut
            data={chartData}
            ref={chartRef}
            options={finalOptions}
            plugins={plugins}
        />
    );
}
