import Header from "../../components/employeeManagement/header";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { staticState } from "./staticContent.js";
import React, { useState, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { Form, Row, Col } from "react-bootstrap";
import EventBus from "../../utilities/eventBus";
import { isEmpty } from "../../utilities/commonFunctions";
import {
  ModalHeaderText,
  ModalHeaderTextTwo,
  ModalHeaderTextThree,
  DownloadTemplate,
  Upload,
  ComponentFile,
  Upload1,
} from "./indexComponents";
import Input from "../../components/input/input";
import FormErrors from "../../components/formErrors/formErrors";
import Button from "../../components/button/button";
import styled from "styled-components";
import CustomButton from "../../components/button/button";
import EmployeeBulk from "../../assets/template/Employee-Bulk-Template.xlsx";
import initialState from "./initialState";
import { yupResolver } from "@hookform/resolvers/yup";
import schema from "./studentValidation";
import ReactTooltip from "react-tooltip";
import StudentRingIcon from "../../assets/student-ring.png";
import * as yup from "yup";
import StudentShareIcon from "../../assets/student-share.png";
import { useSelector } from "react-redux";
import EmployeeDataTable from "./employeeDataTable";
import {
  LoaderBackdrop,
  LoaderWrapper,
  UserManagementModalContainer,
  UserModal,
} from "./commonStyles";
import { ReactComponent as LoaderSVG } from "../../assets/svg/loader.svg";
import userManagementService from "../../services/employeeManagement.service";
import { toast } from "react-toastify";
import ExportExcel from "./employeeReport.js";
import BulkStudentModalEdit from "../../components/bulkStudentUploadEdit/bulkStudentModalEdit";
import DataTable from "react-data-table-component";
import { useDispatch } from "react-redux";
import { updateModal as updateStudentModal } from "../../reducers/studentModalReducer";
import { ReactComponent as ViewIcon } from "../../assets/svg/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/trash.svg";
import Loader from "../../components/loader/loader";
import BulkModal from "../../components/bulkStudentUpload/bulkModal";
import BulkSuccessErrorModal from "../../components/bulkStudentUpload/bulkSuccessErrorModal";
import SkillCard from "../../components/skillCard/skillCard";
import ErrorField from "../../components/errorField/errorField";
import { Colors } from "../../utilities/colors";
import AlertModal from "../../components/singleStudentUpload/alertModal";
import StudentModal from "../../components/studentModal/studentModal";
import UniversityModal from "../../components/universityModal/universityModal";
import ConfirmUser from "../../assets/confirmUser.png";
import { updateModal } from "../../reducers/employeeModalReducer";
import Union from "../../assets/Union.png";
import { ReactComponent as InfoIcon } from "../../assets/svg/info.svg";
import { UnAuthEmPopup } from "../userManagement/unAuthPopup";
import { useSnapshot } from "valtio";
import { subsctipationsProxy } from "../Subscriptions/subscripationsProxy";
import moment from "moment";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { formatDate } from "../../utilities/commonFunctions";
// import { createTheme } from "@material-ui/core";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import DateFnsUtils from "@date-io/date-fns";
// import { ThemeProvider } from "@material-ui/styles";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { useTranslation } from "react-i18next";
import departmentList from "./staticDepartmentList";
import Dropdown from "../../components/dropdown/dropdown";
const materialTheme = createTheme({
  overrides: {
    MuiInput: {
      underline: {
        '&:before': {
          borderBottomColor: 'yourColorHere', // Change this to the desired color
        },
        '&:after': {
          borderBottomColor: 'yourColorHere', // Change this to the desired color
        },
      },
    },
  },
});

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";

  // @media (max-width: 76px) {
  //   display: none;
  // }
  // @media (max-width: 500px) {
  //   display: none;
  // }
  @media (max-width: 500px) {
    button {
      // display: ;
    }
    margin-bottom: 0px;
  }
`;
const FilterIcon = styled.div`
  background: rgba(255, 255, 255, 1);
  margin: 15px 20px 0px 0px;
  width: 100px;
  height: 50px;
  border-radius: 93px;
  @media (max-width: 500px) {
    margin-top: auto;
    position: relative;
    top: -10px;
    height: 41px;
  }
`;
const FilterImg = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 12%;
  text-align: center;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    bottom: 50px;
    left: 82%;
    width: 22px;
    height: 15px;
    padding: 10px 0px 0px 0px;
    margin: 3px 0px 0px 10px;
    img {
      // widith: 60%;
      position: relative;
      left: -50px;
      top: 45px;
    }
  }

  @media (min-width: 768px) and (max-width: 1100px) {
    img {
      widith: 60%;
      position: relative;
      left: 1px;
      top: auto;
    }
  }
`;
const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

const DataTableWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 3rem;
  .custom-selector {
    cursor: pointer !important;
    div[id*="option"] {
      cursor: pointer;  const [selectedDate, handleDateChange] = useState(new Date());

      &:hover {
        background-color: #f3f0f0 !important;
      }
    }
  }
  .rdt_TableHead {  const [selectedDate, handleDateChange] = useState(new Date());

    border-radius: 34px;
    height: 85px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-bottom: 15px;
    .rdt_TableCol_Sortable,
    rdt_TableCol {
      font-family: General Sans;
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }

  .rdt_TableBody {
    border-radius: 0px 0px 34px 34px;
    margin-left: 55px;
    .rdt_TableRow {
      cursor: pointer;
      &:hover {
        background-color: #ffe9f4;
      }
    }
  }
`;

const columnStyling = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "22px",
  background: "transparent",
  color: "#202020",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};

const TableButtonsNew = styled.div`
  display: flex;
`;

const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
`;

const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RecommendedJobsCard = styled.div`
  margin-top: 0.825rem;
  background-color: white;
  border-radius: 10px;
  padding: 0.5rem;
  label {
    position: relative;
  }
  @media (max-width: 500px) {
    margin-top: 4.5rem;
  }
`;

const StyledText = styled.div`
  font-size: 24px;
  color: #d04c8d;
  font-family: "General Sans";
  font-weight: 500;
  padding: 0 1rem;
`;

export const SelectorWrapper = styled.div`
  width: 100%;
`;

const UploadFileWrapper = styled.label`
  position: relative;
  align-self: center;
  cursor: pointer;
  > input {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  @media (max-width: 500px) {
    display: block;
    position: relative;
    left: 18%;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    display: block;
    position: relative;
    left: 15%;
  }
`;

const FilterInputTitle = styled.p`
  font-weight: 500;
  font-size: 18px;
  font-family: "General Sans";
  margin-bottom: 0.5rem;
`;

const Wrapper = styled.div`
  padding: 1rem;
`;

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const DatesWrapper = styled(FlexWrapper)`
  label {
    position: relative;
    width: -webkit-fill-available;
  }
  background: #f3f3f3 !important;
  border-radius: 25px !important;
`;
const ButtonDiv = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    left: 24%;
    margin-top: 65px;
  }
`;
const FormGroup = styled.div`
  width: 100%;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const CardContainer = styled.div`
  margin-top: 2%;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;

const Flex = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const FlexEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-basis: fit-content;
  // margin-top: 2.5rem;
  position: relative;
  right: 2%;
  @media (min-width: 320px) and (max-width: 480px) {
    justify-content: start;
    margin-top: 2.5rem;
    position: relative;
    right: 56%;
    top: 53px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    margin-top: -2.5rem;
    position: absolute;
    right: 80px;
  }
  // @media (min-width: 998px) and (max-width: 1100px) {
  //   margin-top: 1.5rem;
  //   right: 43%;
  // }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: absolute;
    top: 18%;
    right: 82px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    position: absolute;
    top: 26%;
    right: 25%;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    position: absolute;
    top: 20.5%;
    right: 82px;
  }
`;

const FlexCenter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0rem 2.5rem;
  @media (max-width: 1024px) {
    display: unset;
    justify-content: unset;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    display: flex;
    left: 29%;
    top: 16px;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    position: relative;
    left: 3%;
  }
`;

const FileWrapper = styled(FlexWrapper)`
  margin: 10px 0;
  flex-wrap: wrap;
  display: block;
`;

const UploadFileText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
`;

const DownloadTemplateInfoText = styled.div`
  color: #878787;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  @media (max-width: 576px) {
    margin-top: 25px;
  }
`;

const DownloadLink = styled.a`
  cursor: pointer;
`;
const DownloadText = styled.div`
  display: flex;
  @media (max-width: 576px) {
    display: block;
    justify-content: unset;
  }
`;
//React Hook Form For Filters

export default function Index() {
  useSnapshot(subsctipationsProxy);
  const [unAuth, setUnAuth] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [bulkModal, setBulkModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const delmodal = useSelector((state) => state.studentModalReducer.delmodal);
  const armodal = useSelector((state) => state.studentModalReducer.armodal);
  const full_name = useSelector((state) => state.studentModalReducer.full_name);
  const [usersData, setUsersData] = useState([]);
  const [bulkModalStudent, setbulkModalStudent] = useState(false);
  const [studentValidatedData, setStudentValidatedData] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loadingBulk, setLoadingBulk] = useState(false);
  const [bulkModalSuccessError, setBulkModalSuccessError] = useState(false);
  const [bulkModalSuccessErrorMode, setBulkModalSuccessErrorMode] =
    useState("error");
  const [bulkModalSuccessErrorLoading, setBulkModalSuccessErrorLoading] =
    useState(false);
  const [selectExcel, setSelectExcel] = useState(null);
  const modalCache = useSelector((state) => state.modal.cache);
  const cache = useSelector((state) => state.studentModalReducer.cache);
  const modalIsOpen = useSelector(
    (state) => state.studentModalReducer.modalIsOpen
  );
  const mode = useSelector((state) => state.studentModalReducer.mode);
  const id = useSelector((state) => state.studentModalReducer.id);
  const [selectExcelName, setSelectExcelName] = useState("");
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [orgCode, setOrgCode] = useState(undefined);
  const [studentIndex, setStudentIndex] = useState(0);
  const [companyid, setCompanyid] = useState("");
  const [multipleEmails, setMultipleEmails] = useState(undefined);
  const data = useSelector((state) => state.employeeManagementReducer.data);
  const [dateJoined, setDateJoined] = useState(new Date());
  const { t, i18n } = useTranslation();
  const [departmentSelected, setDepartmentSelected] = useState([]);
  const [registerationFilter, setRegisterationFilter] = useState();
  //getting posts

  useEffect(() => {
    getOrgCode();
  });

  useEffect(() => {}, [orgCode]);

  useEffect(() => {
    resetForm(cache);
  }, [cache]);

  useEffect(() => {
    if (!companyid || companyid.length === 0) {
      if (company && company.id) {
        setCompanyid(company.id);
      }
    } else {
      if (companyid !== company.id) {
        setCompanyid(company.id);
        getOrgCode();
      }
    }
  }, [company]);

  useEffect(() => {
    let dataArr = [];
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]) {
          let obj = {};
          obj["email"] = data[i].email;
          obj["name_of_assessment"] =
            data[i].assessment === "-" ? undefined : data[i].assessment;
          obj["date_joined"] = checkIsValidDate(data[i].joined_date)
            ? createDateFromString(data[i].joined_date)
            : undefined;
          obj["is_registered"] = data[i].is_registered === 1 ? true : false;
          dataArr.push(obj);
        }
      }
    }
    setMultipleEmails(dataArr);
  }, [data]);

  function checkIsValidDate(date) {
    if (date === undefined || date === "-") {
      return false;
    }
    return true;
  }

  function createDateFromString(dateStr) {
    if (!(dateStr === undefined || dateStr === "-")) {
      let parts = dateStr.split("/");
      let d = parts[2] + "-" + parts[1] + "-" + parts[0];
      let date = new Date(d);
      date.setDate(date.getDate() + 30);
      let dateString = moment(date).format("YYYY-MM-DD");
      return dateString;
    }
  }

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }
  const ref = React.useRef();
  const {
    formState: { errors },
    setValue: setValue,
    control,
    handleSubmit,
    getValues,
    reset,
  } = useForm({
    criteriaMode: "all",
  });

  //React Hook Form For AddEdit Student
  const {
    control: controlStudent,
    watch,
    reset: resetForm,
    setError: setErrorCustom,
    setValue: setValueStudent,
    handleSubmit: handleSubmitadd,
    formState: { errors: errorsAddForm },
  } = useForm({
    //defaultValues: { ...initialState },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

  const schemaBulkUpload = yup
    .object({
      file: yup.mixed().test("required", "Excel file is required", (file) => {
        if (file) return true;
        return false;
      }),
    })
    .required();
  const {
    register: registerBulk,
    formState: { errors: errorsBulk },
    control: controlBulk,
    handleSubmit: handleSubmitBulk,
    formState: { touchedFields: touchedFieldsBulk, isValid: isValidBulk },
    getValues: getValuesBulk,
    setValue: setValueBulk,
    reset: resetBulk,
    watch: watchBulk,
  } = useForm({
    defaultValues: {
      file_path: t("No file chosen"),
      file_size: "",
      file: null,
    },
    resolver: yupResolver(schemaBulkUpload),
    criteriaMode: "all",
  });

  function onCloseStudentBulkEdit() {
    setbulkModalStudent(false);
  }
  function onUpload() {
    setLoading(false);
    resetBulk({ file_path: t("No file chosen"), file_size: "", file: null });
    setBulkModal(true);
  }

  const conditionalRowStyles = [
    {
      when: (row) => row.is_valid,
      style: {
        backgroundColor: "transparent",
        userSelect: "#FF2C2C",
      },
    },
    {
      when: (row) => row.is_valid === 0,
      style: {
        backgroundColor: "#FF2C2C",
        userSelect: "transparent",
      },
    },
  ];

  function onCloseBulk() {
    setBulkModal(false);
  }

  function onCloseBulkSuccessError() {
    setBulkModalSuccessError(false);
    // setBulkModalSuccessErrorMode("error");
    setLoading(false);
    setLoadingBulk(false);
  }
  const formValBulk = watchBulk();

  const onDeleteFiles = () => {
    ref.current.value = "";
    resetBulk({ file_path: t("No file chosen"), file_size: "", file: {} });
  };

  const onBulkUpload = async (data) => {
    setLoading(true);
    var formdata = new FormData();
    formdata.append("excel_file", selectExcel);
    console.log(formdata,"Employee form data");
    console.log(selectExcel,"Se;ected Excel");
    var res = await userManagementService.importBulkStudents(orgCode, formdata);
    if (res.status === 200) {
      setStudentList(res?.data?.data);
      setStudentValidatedData(res?.data);
      await setBulkModal(false);
      setbulkModalStudent(true);
      setLoading(false);
    } else {
      toast.error(res?.data1?.message?.split("'").join(""));
      setLoading(false);
    }
  };
  const upload2 = () => {
    if (subsctipationsProxy.empcount < subsctipationsProxy.employe_equota) {
      onUpload();
    } else {
      setUnAuth(true);
    }
  };
  const formVal = watch();

  // console.log("formval------", formVal);

  const onAddStudent = async (data) => {
    console.log("dataDatadata", data);
    setLoading(true);
    var result = { organization: orgCode, ...data };

    if (modalIsOpen) {
      if (mode === "normal") {
        await userManagementService
          .updateSingleUniversityStudentById(id, result)
          .then((res) => {
            if (res.status === 200) {
              if (res?.data && res.data.status === 0) {
                toast.error(t(res.data.message));
                setLoading(false);
              } else {
                EventBus.dispatch("userAddEvent", { codeorg: orgCode });
                dispatch(updateStudentModal(false));
                setLoading(false);
                toast.success(t(res.data.message));
              }
            } else {
              if (res.status === 400) {
                if (res?.data?.message) {
                  toast.error(t(res.data.message));
                } else {
                  toast.error(t("Please check your data."));
                }
                setLoading(false);
                for (const key in res.data1.data) {
                  setErrorCustom(key, { message: res.data1.data[key][0] });
                }
              }
            }
          })
          .catch((error) => {
            setLoading(false);
            toast.error(t(error));
          });
      } else {
        await userManagementService
          .importBulkStudentSingalValidation(orgCode, formVal)
          .then((res) => {
            if (res.status === 200) {
              if (res?.data && res.data.status === 0) {
                toast.error(t(res.data.message));
                setLoading(false);
              } else {
                var mainResult = {
                  ...res?.data?.data,
                  is_valid: res?.data?.is_valid,
                  uuid: id,
                  student_email: res?.data?.data?.email,
                };
                var result1 = studentList?.map((e, i) =>
                  e.uuid === id ? mainResult : e
                );
                setStudentList(result1);
                dispatch(updateStudentModal(false));
                setLoading(false);
                toast.success(t(res.data.message));
              }
            } else {
              if (res.status === 400) {
                if (res?.data?.message) {
                  toast.error(t(res.data.message));
                } else {
                  toast.error(t("Please check your data."));
                }
                setLoading(false);
                for (const key in res.data1.data) {
                  setErrorCustom(key, { message: res.data1.data[key][0] });
                }
              }
            }
          })
          .catch((error) => {
            toast.error(t(error));
            setLoading(false);
          });
      }
    } else {
      await userManagementService
        .createStudent(orgCode, formVal)
        .then((res) => {
          if (res.status === 200) {
            if (res?.data && res.data.status === 0) {
              toast.error(t(res.data.message));
              setLoading(false);
            }
          }
          if (res.status === 201) {
            EventBus.dispatch("userAddEvent", { codeorg: orgCode });
            setModal(false);
            setLoading(false);
            toast.success(t(res.data.message));
          } else {
            if (res.status === 400) {
              if (res?.data?.message) {
                toast.error(t(res.data.message));
              } else {
                toast.error(t("Please check your data."));
              }

              setLoading(false);
              for (const key in res.data1.data) {
                setErrorCustom(key, { message: res.data1.data[key][0] });
              }
            }
          }
        })
        .catch((error) => {
          toast.error(t(error));
          setLoading(false);
        });
    }
  };

  const uploadFile = async (event) => {
    if (event.target.files[0].name) {
      let l = event.target.files[0].name.split(".");
      if (l.length > 0) {
        l = l[l.length - 1].toLowerCase();
        if (l !== "xlsx") {
          toast.error(t("File format not supported"));
          return;
        }
      }
    }
    const formdata = new FormData();

    let maxFileSize = 1024 * 1024 * 5; //100mb

    if (event.target.files[0].size > maxFileSize) {
      toast.error(
        t(
          "Only PDF files of size less than or equal to 5 MB can be uploaded!"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      formdata.append(
        "obj_content",
        event.target.files[0],
        event.target.files[0].name
      );

      setSelectExcel(event.target.files[0]);
      setSelectExcelName(event.target.files[0].name);
      setValueBulk("excel_file", event.target.files[0].name);
      resetBulk({
        file_path: event.target.files[0].name,
        file_size: event.target.files[0].size,
        file: event.target.files[0],
      });
    }
  };

  const onBulkUploadAfterValidationSuccess = async () => {
    var res = await userManagementService.importBulkStudentsAfterValidation(
      orgCode,
      studentList
    );
    if (res.status === 201) {
      EventBus.dispatch("userAddEvent", { codeorg: orgCode });
      setBulkModalSuccessErrorMode("error");
      setBulkModalSuccessErrorLoading(false);
      setBulkModalSuccessError(false);
      setLoadingBulk(false);
      toast.success(res.data.data);
      setbulkModalStudent(false);
    } else {
      if (res.status === 400) {
        setLoadingBulk(false);
        setBulkModalSuccessErrorLoading(false);
        setBulkModalSuccessErrorMode("error");
        setBulkModalSuccessError(true);
        toast.error(t("Please check your data"));
        setStudentList(res?.data1?.data);
        setStudentValidatedData(res?.data1);
        setLoading(false);
      }
    }
  };

  function onClose() {
    if (modalIsOpen) {
      dispatch(updateStudentModal(false));
    }
    setLoading(false);
    setModal(false);
    if (delmodal) {
      dispatch(
        updateStudentModal({ delmodal: false, cache: null, full_name: "" })
      );
    }
    if (armodal) {
      dispatch(updateStudentModal({ armodal: false, cache: null }));
    }
  }
  const yesContent = () => {
    EventBus.dispatch("userActivateDeactivateEvent", {
      userId: modalCache,
      activate: false,
      codeorg: orgCode,
    });
    dispatch(updateModal(false));
  };
  const yesContent2 = () => {
    EventBus.dispatch("userActivateDeactivateEvent", {
      userId: modalCache,
      activate: true,
      codeorg: orgCode,
    });
    dispatch(updateModal(false));
  };
  const getModalContent = () => {
    switch (modalMode) {
      case "deactivate":
        return (
          <UserModal>
            <div className="modal-wrapper rotated">
              <img
                src={ConfirmUser}
                alt="deactivate user"
                className="modal-image rotated"
              />
            </div>
            <div className="modal-title">{t("Confirm Deactivating User")}</div>
            <div className="modal-description">
              {t(
                "Are you sure you want to deactivate the user? This user will not be able to access the system again after this."
              )}
            </div>
            <div className="modal-buttons">
              <Button name="Yes" onClick={yesContent} />
              <Button
                name="No"
                className="inverted"
                onClick={() => {
                  dispatch(updateModal(false));
                }}
              />
            </div>
          </UserModal>
        );
      case "activate":
        return (
          <UserModal>
            <div className="modal-wrapper rotated">
              <img
                src={ConfirmUser}
                alt="activate user"
                className="modal-image rotated"
              />
            </div>
            <div className="modal-title">{t("Confirm Activate User")}</div>
            <div className="modal-description">
              {t(
                "Are you sure you want to reactivate the user? This user will be able to access the system again after this."
              )}
            </div>
            <div className="modal-buttons">
              <Button name={t("Yes")} onClick={yesContent2} />
              <Button
                name={t("No")}
                className="inverted"
                onClick={() => {
                  dispatch(updateModal(false));
                }}
              />
            </div>
          </UserModal>
        );
      default:
        break;
    }
  };
  const deleteYes = async () => {
    setLoading(true);
    let result = {};
    result.status = "deleted";
    let Keys = Object.keys(cache);
    for (let i = 0; i < Keys.length; i++) {
      if (Keys[i] !== "status") {
        result[Keys[i]] = cache[Keys[i]];
      }
    }
    await userManagementService
      .deleteSingleEmployee(cache.id, result)
      .then((res) => {
        if (res.status === 204) {
          EventBus.dispatch("userAddEvent", { codeorg: orgCode });
          setLoading(false);
          toast.success(
            t("Employee ") +
              full_name +
              t(" has been removed from ") +
              company.name
          );
          dispatch(
            updateStudentModal({
              delmodal: false,
              cache: null,
              full_name: "",
            })
          );
        } else {
          if (res.status === 400) {
            toast.error("Please check your data.");
            setLoading(false);
            for (const key in res.data1.data) {
              setErrorCustom(key, { message: res.data1.data[key][0] });
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const getdeleteContent = () => {
    return (
      <div
        className="modal-buttons"
        style={{ display: "flex", justifyContent: "center", margin: "30px 0" }}
      >
        <Button
          name={t("Yes")}
          style={{ marginRight: "20px", fontSize: "24px", height: "60px" }}
          onClick={deleteYes}
        />
        <Button
          name={t("No")}
          className="inverted"
          style={{
            marginRight: "20px",
            fontSize: "24px",
            height: "60px",
            background: "white",
            border: "1px solid #EE4BA0",
            color: "#EE4BA0",
          }}
          onClick={() => {
            dispatch(
              updateStudentModal({
                delmodal: false,
                cache: null,
                full_name: "",
              })
            );
          }}
        />
      </div>
    );
  };

  const rejectBut = async () => {
    setLoading(true);
    let result = {};
    result.status = "deleted";
    let Keys = Object.keys(cache);
    for (let i = 0; i < Keys.length; i++) {
      if (Keys[i] !== "status") {
        result[Keys[i]] = cache[Keys[i]];
      }
    }
    await userManagementService
      .updateSingleUniversityStudentById(cache.id, result)
      .then((res) => {
        if (res.status === 200) {
          EventBus.dispatch("userAddEvent", { codeorg: orgCode });
          setLoading(false);
          toast.success("Employee rejected successful");
          dispatch(updateStudentModal({ armodal: false, cache: null }));
        } else {
          if (res.status === 400) {
            toast.error("Please check your data.");
            setLoading(false);
            for (const key in res.data1.data) {
              setErrorCustom(key, {
                message: res.data1.data[key][0],
              });
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const approveBut = async () => {
    setLoading(true);
    let result = {};
    result.status = "accepted";
    let Keys = Object.keys(cache);
    for (let i = 0; i < Keys.length; i++) {
      if (Keys[i] !== "status") {
        result[Keys[i]] = cache[Keys[i]];
      }
    }
    await userManagementService
      .updateSingleUniversityStudentById(cache.id, result)
      .then((res) => {
        if (res.status === 200) {
          EventBus.dispatch("userAddEvent", { codeorg: orgCode });
          setLoading(false);
          toast.success("Employee approved successful");
          dispatch(updateStudentModal({ armodal: false, cache: null }));
        } else {
          // Iterate through the object
          if (res.status === 400) {
            toast.error("Please check your data.");
            setLoading(false);
            for (const key in res.data1.data) {
              setErrorCustom(key, {
                message: res.data1.data[key][0],
              });
            }
          }
        }
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  };

  const registerationUpdate = (event) => {
    console.log("Nameval", event.target.value);
    setValue("registration", event.target.value);
    setRegisterationFilter(event.target.value);
    if (event.target.value === "0") {
      setValue("is_assessment_done", undefined);
    }
  };

  const dropdownChange = (name, val) => {
    setValueStudent(name, val?.value);
    setDepartmentSelected(val);
  };
  const getarContent = () => {
    return (
      <>
        {cache && armodal ? (
          <CardContainer>
            <Row style={{ paddingLeft: "9px" }}>
              <Col md="6">Email address</Col>
              <Col md="6">{cache.email}</Col>

              <Col md="6">Full name</Col>
              <Col md="6">{cache.full_name}</Col>

              <Col md="6">Department</Col>
              <Col md="6">{cache.department}</Col>
              <Col md="6">Position</Col>
              <Col md="6">{cache.position}</Col>
            </Row>
          </CardContainer>
        ) : (
          <></>
        )}

        <div
          className="modal-buttons"
          style={{
            display: "flex",
            justifyContent: "center",
            margin: "30px 0",
          }}
        >
          <Button
            name="Reject"
            className="inverted"
            style={{
              marginRight: "20px",
              fontSize: "24px",
              height: "60px",
              background: "white",
              border: "1px solid #EE4BA0",
              color: "#EE4BA0",
            }}
            onClick={rejectBut}
          />
          <Button
            name="Approve"
            style={{ marginRight: "20px", fontSize: "24px", height: "60px" }}
            onClick={approveBut}
          />
        </div>
      </>
    );
  };
  // const handleDateJoined = (name, val) => {
  //   if (name === "date_joined") setDateJoined(formatDate(val));
  //   setValue("date_joined", "google");
  // };
  // console.log(dateJoined);

  const addOrEditStudentContent = ({ cache, fromEdit }) => {
    console.log("addOrEditStudentContent0", cache);
    return (
      <>
        <form>
          <CardContainer>
            <Row style={{ paddingLeft: "9px" }}>
              <Col md="6">
                <GroupWrapper>
                  <FormLabel>{t("Email address*")}</FormLabel>
                  <Controller
                    name="email"
                    control={controlStudent}
                    defaultValue={cache?.email}
                    render={({ field }) => (
                      <Input
                        className="w-100"
                        disabled={fromEdit ? true : false}
                        placeholder={t("Enter email address")}
                        {...field}
                      />
                    )}
                  />
                  {errorsAddForm.email && (
                    <ErrorField err={t(errorsAddForm.email.message)} />
                  )}
                </GroupWrapper>
              </Col>
              <Col md="6">
                <GroupWrapper>
                  <FormLabel>{t("Full name*")}</FormLabel>
                  <Controller
                    name="full_name"
                    control={controlStudent}
                    defaultValue={cache?.full_name}
                    render={({ field }) => (
                      <Input
                        className="w-100"
                        placeholder={t("Enter full name")}
                        {...field}
                      />
                    )}
                  />
                  {errorsAddForm.full_name && (
                    <ErrorField err={t(errorsAddForm.full_name.message)} />
                  )}
                </GroupWrapper>
              </Col>
            </Row>
            <Row style={{ paddingLeft: "9px" }}>
              <Col md="6">
                <GroupWrapper>
                  <FormLabel>{t("Department*")}</FormLabel>
                  <Controller
                    name="department"
                    control={controlStudent}
                    defaultValue={cache?.department}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={departmentList}
                        placeholder={t("Select Department")}
                        val={departmentSelected?.value}
                        label={departmentSelected?.label}
                        // disable={facultyDisable}
                        change={(val) => dropdownChange("department", val)}
                        {...field}
                        refCallback={field?.ref}
                        ref={null}
                      />
                      // <Input
                      //   className="w-100"
                      //   placeholder={t("Enter Department")}
                      //   {...field}
                      // />
                    )}
                  />
                  {errorsAddForm.department && (
                    <ErrorField err={t(errorsAddForm.department.message)} />
                  )}
                </GroupWrapper>
              </Col>
              <Col md="6">
                <GroupWrapper>
                  <FormLabel>{t("Position*")}</FormLabel>
                  <Controller
                    name="position"
                    control={controlStudent}
                    defaultValue={cache?.position}
                    render={({ field }) => (
                      <Input
                        className="w-100"
                        placeholder={t("Enter Position")}
                        {...field}
                      />
                    )}
                  />
                  {errorsAddForm.position && (
                    <ErrorField err={t(errorsAddForm.position.message)} />
                  )}
                </GroupWrapper>
              </Col>
            </Row>
            <Row style={{ paddingLeft: "9px" }}>
              <Col md="6">
                <GroupWrapper>
                  <FormLabel>{t("Date Of Joining*")}</FormLabel>
                  <Controller
                    as={DatePicker}
                    name="date_joined"
                    control={controlStudent}
                    defaultValue={
                      cache?.date_joined ? cache.date_joined : new Date()
                    }
                    // defaultValue={new Date()}
                    render={({ field }) => (
                      <label style={{ position: "relative", width: "100%" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                dateJoined ? new Date(dateJoined) : new Date()
                              }
                              {...field}
                              onChange={(newValue) => {
                                setDateJoined(newValue);
                                field.onChange(formatDate(newValue));
                              }}
                              fullWidth
                              disableFuture
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        <CalenderIconWrapper>
                          <CalenderIcon />
                        </CalenderIconWrapper>
                      </label>
                    )}
                  />
                  {errorsAddForm.date && (
                    <ErrorField err={t(errorsAddForm.date.message)} />
                  )}
                </GroupWrapper>
              </Col>
              <Col md="6">
                <GroupWrapper>
                  <FormLabel>{t("Gender")}*</FormLabel>
                  <SelectorWrapper
                    style={{ display: "flex", paddingLeft: "20px" }}
                    className="w-100 mt-4"
                  >
                    <Controller
                      control={controlStudent}
                      name="gender"
                      defaultValue={"male"}
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <input
                            type="radio"
                            id="gender"
                            name="gender"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="male"
                            checked={value === "male" || value === "Male"}
                          />
                          <label htmlFor="gender">{t("Male")}</label>
                          <input
                            type="radio"
                            id="secondarygender"
                            name="gender"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="female"
                            style={{ marginLeft: 17 }}
                            checked={value === "female" || value === "Female"}
                          />
                          <label htmlFor="secondarygender">{t("Female")}</label>
                        </>
                      )}
                    />
                  </SelectorWrapper>
                  {errorsAddForm.gender && (
                    <ErrorField err={t(errorsAddForm.gender.message)} />
                  )}
                </GroupWrapper>
              </Col>
            </Row>
          </CardContainer>
        </form>
        <Row>
          {loading ? (
            <Loader />
          ) : (
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                width="100px"
                margin="40px 0px 30px 0px"
                name={t("Submit")}
                onClick={handleSubmitadd(onAddStudent)}
              />
              <Button
                secondary
                color="#FFFFFF"
                name={t("Cancel")}
                style={{
                  margin: "40px 5px 30px 10px",
                }}
                variant="outlined"
                onClick={onClose}
              ></Button>
            </Col>
          )}
        </Row>
      </>
    );
  };

  const content = () => {
    return (
      <>
        <Form
          onSubmit={handleSubmitBulk(onBulkUpload)}
          encType="multipart/form-data"
        >
          <ModalHeaderTextThree>
            {t(
              "Please Select a Excel File that contains only 4 Columns. First Column must be the Employee Name , Employee organisation Email address, respective Position and Department."
            )}
          </ModalHeaderTextThree>
          <ComponentFile>
            <UploadFileWrapper data-tip data-for="csv">
              <input
                name="excel_file"
                onChange={uploadFile}
                accept=" .xlsx"
                type="file"
                ref={ref}
              />
              <UploadFileText>{t("Choose File")}</UploadFileText>
            </UploadFileWrapper>
            <FileWrapper>
              {formValBulk?.file_path?.length > 0 ? (
                <>
                  <SkillCard
                    key={formValBulk?.file_path}
                    delete={() => onDeleteFiles()}
                    name={formValBulk?.file_path}
                  />
                </>
              ) : (
                ""
              )}
              {errorsBulk.file && (
                <ErrorField err={t(errorsBulk.file?.message)} />
              )}
            </FileWrapper>
          </ComponentFile>
          <Row>
            {loading ? (
              <Loader />
            ) : (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  width="100px"
                  margin="40px 0px 30px 0px"
                  name={t("Upload")}
                  type="submit"
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onCloseBulk}
                ></Button>
              </Col>
            )}
          </Row>
        </Form>
      </>
    );
  };
  const contentSuccessError = () => {
    return (
      <>
        <ModalHeaderTextThree>
          {bulkModalSuccessErrorMode === "error"
            ? t("Please correct invalid data")
            : t("Would you like to add total of ") +
              studentList?.length +
              t(" employee to Company ?")}
        </ModalHeaderTextThree>
        <ComponentFile></ComponentFile>
        <Row>
          {bulkModalSuccessErrorLoading ? (
            <Loader />
          ) : bulkModalSuccessErrorMode === "error" ? (
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                secondary
                color="#FFFFFF"
                name="Ok"
                style={{
                  margin: "40px 5px 30px 10px",
                }}
                variant="outlined"
                onClick={onCloseBulkSuccessError}
              ></Button>
            </Col>
          ) : (
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                width="100px"
                margin="40px 0px 30px 0px"
                name={t("Upload")}
                onClick={() => {
                  setBulkModalSuccessErrorLoading(true);
                  onBulkUploadAfterValidationSuccess();
                }}
                type="button"
              />
              <Button
                secondary
                color="#FFFFFF"
                name={t("Cancel")}
                style={{
                  margin: "40px 5px 30px 10px",
                }}
                variant="outlined"
                onClick={onCloseBulkSuccessError}
              ></Button>
            </Col>
          )}
        </Row>
      </>
    );
  };
  const upload = () => {
    if (subsctipationsProxy.empcount < subsctipationsProxy.employe_equota) {
      onAdd();
    } else {
      setUnAuth(true);
    }
  };
  const onOpenFilerSearch = () => {
    setOpenFilter(!openFilter);
  };

  const onSubmit = (data) => {
    console.log("onSubmit", data);
    if (!isEmpty(errors)) {
      return;
    }
    EventBus.dispatch("userSearchEvent", {
      search: data.search,
      registration: data.registration,
      is_assessment_done: data.is_assessment_done,
      codeorg: orgCode,
    });
  };

  const onResetFilter = () => {
    reset();
    EventBus.dispatch("userSearchEvent", {
      search: getValues("search"),
      registration: null,
      is_assessment_done: null,
      codeorg: orgCode,
    });
  };

  function checkIsValid() {
    var isValid = true;
    if (studentList?.length > 0) {
      studentList?.forEach((e, i) => {
        if (e.is_valid !== 1) {
          isValid = false;
        }
      });
    } else {
      isValid = false;
    }
    return isValid;
  }

  const onChangeStudentEdit = (value, row, index) => {
    resetForm(row);
    setStudentIndex(index);
    dispatch(
      updateStudentModal({
        modalIsOpen: true,
        cache: row,
        mode: "excel",
        id: row.uuid,
      })
    );
  };
  const onBulkUploadAfterValidate = async () => {
    setLoadingBulk(true);
    const ck = checkIsValid();
    if (ck) {
      setBulkModalSuccessErrorMode("success");
      setBulkModalSuccessError(true);
      setLoadingBulk(false);
      setLoading(false);
    } else {
      setLoading(false);

      setLoadingBulk(false);
      setBulkModalSuccessErrorMode("error");
      setBulkModalSuccessError(true);
      toast.error(t("Please check your data"));
    }
  };

  const columns = [
    {
      name: t("Name"),
      selector: (row) => row.full_name,
      sortable: true,
      center: true,
      cell: (row, index) => {
        var backgroundStyle =
          row.is_valid === 0 ? "brightness(0) invert(1)" : "";
        return (
          <>
            <Row>
              <Col
                lg="12"
                style={{
                  fontSize: "14px",
                  filter: backgroundStyle,
                }}
              >
                <span>{row.full_name}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: t("Email"),
      selector: (row) => row.email,
      sortable: true,
      center: true,
      cell: (row, index) => {
        var backgroundStyle =
          row.is_valid === 0 ? "brightness(0) invert(1)" : "";
        return (
          <>
            <Row>
              <Col
                lg="12"
                style={{
                  fontSize: "14px",
                  filter: backgroundStyle,
                }}
              >
                <span>{row.email}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: t("Job Title"),
      selector: (row) => row.position,
      sortable: true,
      style: columnStyling,
      center: true,
      cell: (row, index) => {
        var backgroundStyle =
          row.is_valid === 0 ? "brightness(0) invert(1)" : "";
        return (
          <>
            <Row>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  filter: backgroundStyle,
                  fontSize: "14px",
                }}
              >
                <span>{row.position}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: t("Department"),
      selector: (row) => row.department,
      sortable: true,
      center: true,
      cell: (row, index) => {
        var backgroundStyle =
          row.is_valid === 0 ? "brightness(0) invert(1)" : "";
        return (
          <>
            <Row>
              <Col
                lg="12"
                style={{
                  fontSize: "14px",
                  filter: backgroundStyle,
                }}
              >
                <span>{row.department}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },
    {
      name: t("Actions"),
      selector: (row) => row.status,
      sortable: false,
      center: true,
      cell: (row, index) => {
        var backgroundStyle =
          row.is_valid === 0 ? "brightness(0) invert(1)" : "";
        return (
          <TableButtonsNew>
            <PencilButton
              onClick={(value) => onChangeStudentEdit(value, row, index)}
            >
              <ViewIcon  color={Colors.greyText} />
            </PencilButton>
            <TrashButton>
              <DeleteIcon
                type="button"
                onClick={(e) => {
                  onDeleteExcelStudents(row.uuid);
                }}
              />
            </TrashButton>
            {row.errors && <ReactTooltip />}
            <TrashButton
              style={{
                filter: backgroundStyle,
              }}
              data-tip={toolTipContent(row.errors)}
              data-html={true}
            >
              <InfoIcon
                style={{
                  filter: backgroundStyle,
                }}
              />
            </TrashButton>
          </TableButtonsNew>
        );
      },
    },
  ];
  const toolTipContent = (props) => {
    if (props) {
      let values = "";
      props.forEach((obj) => {
        Object.values(obj).forEach((val) => {
          values += `${t(val).replace(/_/g, " ")}<br/>`;
          console.log("valuesss",val);
        });
      });
      return values;
    } else {
      return "";
    }
  };
  const onDeleteExcelStudents = (uuid) => {
    setStudentList(studentList?.filter((item) => item.uuid !== uuid));
  };

  function onAdd() {
    resetForm(initialState);
    setModal(true);
  }

  const dataTableLoader = (
    <DataTableLoaderWrapper>
      <LoaderBackdrop />
      <LoaderWrapper>
        <LoaderSVG />
      </LoaderWrapper>
    </DataTableLoaderWrapper>
  );

  const sendMultipleReminders = async (type_of_reminder) => {
    var res = await userManagementService.sendMultipleReminders(orgCode, {
      emails: multipleEmails,
      type_of_reminder,
    });
    if (res.status === 200) {
      EventBus.dispatch("userAddEvent", { codeorg: orgCode });
      toast.success(res?.data?.message);
    }
  };

  const contentStudents = () => {
    return (
      <>
        <style type="text/css">
          {`
  .form-check-label > label {
    padding-left:10px
  }
  `}
        </style>
        <ModalHeaderText>{t(staticState.uploadFile)}</ModalHeaderText>
        <ModalHeaderTextTwo>
          <div>{t("Upload a file with Employee details")}</div>
          <div style={{ display: "flex" }}>
            <div style={{ fontSize: "15px" }}>
              {t("Total")}:{" "}
              <span style={{ color: "#D04C8D" }}>{studentList?.length}</span>
            </div>
            <div style={{ fontSize: "15px", paddingLeft: "23px" }}>
              {t("Valid")}:{" "}
              <span style={{ color: "#00E332" }}>
                {studentList
                  ? studentList?.filter((e) => e.is_valid === 1).length
                  : 0}
              </span>
            </div>
            <div style={{ fontSize: "15px", paddingLeft: "23px" }}>
              {t("Invalid")}:{" "}
              <span style={{ color: "#FF0000" }}>
                {studentList
                  ? studentList?.filter((e) => e.is_valid === 0).length
                  : 0}
              </span>
            </div>
            <div style={{ paddingLeft: "23px" }}>
              <div key={`default-invalid`} className="mb-3">
                <Form.Check
                  className="form-check-label"
                  type={"checkbox"}
                  checked={isInvalid}
                  id={`default-invalid`}
                  label={t("Show Invalid Data Only")}
                  style={{
                    fontSize: "15px",
                    alignItems: "center",
                    display: "flex",
                  }}
                  onClick={(e) => {
                    setIsInvalid(e.target.checked);
                  }}
                />
              </div>
            </div>
          </div>
        </ModalHeaderTextTwo>
        {/* <ComponentFile> */}
        <DataTableWrapper>
          <DataTable
            columns={columns}
            data={
              isInvalid
                ? studentList?.filter((e) => e.is_valid === 0)
                : studentList
            }
            highlightOnHover
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 15, 25, 50]}
            paginationComponentOptions={{
              rowsPerPageText: t("Records per page:"),
              rangeSeparatorText: t("out of"),
            }}
            conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableWrapper>

        {/* </ComponentFile> */}
        <Row>
          {loadingBulk ? (
            <Loader />
          ) : (
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                width="100px"
                margin="40px 0px 30px 0px"
                name={t("Upload")}
                onClick={onBulkUploadAfterValidate}
              />
              <Button
                secondary
                color="#FFFFFF"
                name={t("Cancel")}
                style={{
                  margin: "40px 5px 30px 10px",
                }}
                variant="outlined"
                onClick={() => {
                  setbulkModalStudent(false);
                }}
              ></Button>
            </Col>
          )}
        </Row>
        {/* </Form> */}
      </>
    );
  };

  return (
    <>
      <UnAuthEmPopup isOpen={unAuth} setOpen={setUnAuth}></UnAuthEmPopup>
      <MainLayout>
        <Header />
        {/* Page Filter Form  */}
        <form onSubmit={handleSubmit(onSubmit)}>
          <CompanySection>
            <InputBox>
              <Controller
                control={control}
                name="search"
                render={({ field: { onChange, onBlur } }) => (
                  <Input
                    color="#fff"
                    name="search"
                    onChange={onChange}
                    onBlur={onBlur}
                    placeholder={t("Search")}
                    className={errors.name ? "invalid" : ""}
                  />
                )}
              />
              <FormErrors errors={errors} formControlName="search" />
            </InputBox>

            <FilterIcon onClick={onOpenFilerSearch}>
              <FilterImg>
                <img src={Union} alt="imgUnion" style={{ cursor: "pointer" }} />
              </FilterImg>
            </FilterIcon>

            <ButtonDiv>
              <Button
                style={{ cursor: "pointer" }}
                name={t("Search")}
                type="submit"
              />
              {/* </div> */}
            </ButtonDiv>
          </CompanySection>

          {openFilter && (
            <RecommendedJobsCard id="em">
              <StyledText>{t("Filter")}</StyledText>
              <Row>
                <Col xl="5" lg="12" md="5">
                  <Wrapper>
                    <FilterInputTitle>{t("Registration")}</FilterInputTitle>
                    <SelectorWrapper
                      style={{ display: "flex", marginLeft: "6px" }}
                      className="mt-4"
                    >
                      <Controller
                        control={control}
                        name="registration"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <>
                            <input
                              type="radio"
                              id="primary"
                              name="registration"
                              // onChange={onChange}
                              onClick={registerationUpdate}
                              onBlur={onBlur}
                              value="1"
                              checked={registerationFilter === "1"}
                            />
                            <label htmlFor="primary">{t("Complete")}</label>
                            <input
                              type="radio"
                              id="secondary"
                              name="registration"
                              // onChange={onChange}
                              onClick={registerationUpdate}
                              onBlur={onBlur}
                              value="0"
                              style={{ marginLeft: 30 }}
                              checked={registerationFilter === "0"}
                            />
                            <label htmlFor="secondary">{t("Incomplete")}</label>
                          </>
                        )}
                      />
                      <FormErrors
                        errors={errors}
                        formControlName="registration"
                      />
                    </SelectorWrapper>
                  </Wrapper>
                </Col>

                {registerationFilter === "0" ? (
                  <></>
                ) : (
                  <Col xl="5" lg="12" md="5">
                    <Wrapper>
                      <FilterInputTitle>{t("Assessments")}</FilterInputTitle>
                      <SelectorWrapper
                        style={{ display: "flex", marginLeft: "6px" }}
                        className="mt-4"
                      >
                        <Controller
                          control={control}
                          name="is_assessment_done"
                          render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },
                          }) => (
                            <>
                              <input
                                type="radio"
                                id="primaryInc"
                                name="is_assessment_done"
                                onChange={onChange}
                                onBlur={onBlur}
                                value="1"
                                checked={value === "1"}
                              />
                              <label htmlFor="primaryInc">
                                {t("Complete")}
                              </label>
                              <input
                                type="radio"
                                id="secondaryInc"
                                name="is_assessment_done"
                                onChange={onChange}
                                onBlur={onBlur}
                                value="0"
                                style={{ marginLeft: 30 }}
                                checked={value === "0"}
                              />
                              <label htmlFor="secondaryInc">
                                {t("Incomplete")}
                              </label>
                            </>
                          )}
                        />
                        <FormErrors
                          errors={errors}
                          formControlName="education_level"
                        />
                      </SelectorWrapper>
                    </Wrapper>
                  </Col>
                )}
              </Row>
              <ButtonWrapper>
                <CustomButton
                  name={t("Search")}
                  style={{ margin: "0 0.5rem 1rem", minWidth: "120px" }}
                  type="submit"
                />

                <CustomButton
                  name={t("Reset")}
                  style={{ margin: "0 0.5rem 1rem", minWidth: "120px" }}
                  secondary
                  onClick={(e) => {
                    onResetFilter(e);
                  }}
                />
              </ButtonWrapper>
            </RecommendedJobsCard>
          )}
        </form>

        <Row>
          <Col xs="12" md="10" lg="10">
            <Row style={{ paddingLeft: "10px" }}>
                <Button secondary fontSize="14px" lineHeight="27px"
                  style={{ backgroundColor: "transparent", color: "#D04C8D", marginRight: "10px" }}
                  name={t(staticState.uploadInternships)}
                  onClick={() => upload()}
                />
                <Button secondary fontSize="14px" lineHeight="27px"
                  style={{ backgroundColor: "transparent", color: "#D04C8D", marginRight: "10px" }}
                  name={t(staticState.addBulk)}
                  onClick={() => upload2()}
                />
                <Button secondary
                  style={{ backgroundColor: "transparent", border:"none", margin:"0", padding:"0" }}
                  name={
                    <DownloadText>
                    <DownloadLink
                      href={EmployeeBulk}
                      download
                      className="downloadTemplate"
                    >
                      <DownloadTemplate>
                        {t(staticState.downloadTemplate)}
                      </DownloadTemplate>
                      <DownloadTemplateInfoText>
                        {t("to upload bulk employees details")}
                      </DownloadTemplateInfoText>
                    </DownloadLink>
                    </DownloadText>
                    }
                />
            </Row>
          </Col>
          <Col xs="12" md="2" lg="2">
            <FlexEnd>
              <div
                style={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
                onClick={() => {
                  sendMultipleReminders("registration");
                }}
                data-place="top"
                data-tip={t("Registration Reminder")}
              >
                <ReactTooltip />
                <img src={StudentRingIcon} alt="icon" />
              </div>
              <div
                style={{
                  marginRight: "20px",
                  cursor: "pointer",
                }}
              >
                <ExportExcel
                  data-tip={t("Send Assessments Remainder")}
                  excelData={usersData}
                  user={orgCode}
                  fileName={"Employee List"}
                />
              </div>
              <div
                onClick={() => {
                  sendMultipleReminders("assessment");
                }}
                style={{
                  marginTop: "0.5rem",
                  cursor: "pointer",
                }}
              >
                <ReactTooltip />
                <img
                  data-tip={t("Send Assessments Reminder")}
                  src={StudentShareIcon}
                  alt="icon"
                />
              </div>
            </FlexEnd>
          </Col>
        </Row>
        {/* Main Table */}
        {orgCode ? (
          <EmployeeDataTable
            setModalMode={setModalMode}
            fromUserManagement={true}
            t={t}
          />
        ) : (
          dataTableLoader
        )}
      </MainLayout>

      <BulkStudentModalEdit
        show={bulkModalStudent}
        close={onCloseStudentBulkEdit}
        valid={studentValidatedData?.valid_data_count ?? 0}
        total={studentValidatedData?.total_data_count ?? 0}
        content={contentStudents()}
      />
      <BulkModal
        title={t(staticState.uploadFile)}
        subTitle={t(staticState.subTitle)}
        show={bulkModal}
        close={onCloseBulk}
        content={content()}
      />
      <BulkSuccessErrorModal
        backdropMode={bulkModalSuccessErrorMode}
        title={t(staticState.uploadFile)}
        subTitle={
          bulkModalSuccessErrorMode === "error"
            ? t("Invalid Data")
            : t(staticState.subTitle)
        }
        show={bulkModalSuccessError}
        close={onCloseBulkSuccessError}
        content={contentSuccessError()}
      />

      <AlertModal
        title={t(staticState.uploadInternships)}
        subTitle={t(staticState.subTitleStudent) + " " + company.name}
        show={modal}
        close={onClose}
        content={addOrEditStudentContent({ fromEdit: false })}
      />

      <AlertModal
        title={t("Delete Employee ?")}
        subTitle={t("Remove ") + full_name + t(" from ") + company.name}
        show={delmodal}
        close={onClose}
        content={getdeleteContent()}
      />
      <AlertModal
        title={t("CONFIRM EMPLOYEE")}
        subTitle={
          t(
            "Please validate the information of the user before adding them to the organisation"
          ) + company.name
        }
        show={armodal}
        close={onClose}
        content={getarContent()}
      />
      <StudentModal
        title={t(staticState.uploadFile)}
        subTitle={t("Edit Employee Details")}
        content={addOrEditStudentContent({ cache: cache, fromEdit: true })}
      />
      <UniversityModal>
        <UserManagementModalContainer>
          {getModalContent()}
        </UserManagementModalContainer>
      </UniversityModal>
    </>
  );
}
