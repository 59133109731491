import React from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import "./userRegistration.css";
import code from "../../assets/Group 36.png";
import Form from "react-bootstrap/Form";
import Button from "../../components/button/button";
import rightarrow from "../../assets/icons/arrow_right.png";
import { Grid } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import ErrorField from "../errorField/errorField";
import Input from "../input/input";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { schemaNewObj } from "../../components/AddContact/staticContent";
import { useState, useEffect } from "react";
import Loader from "../loader/loader";
import { onEditUser, getAllUsers, getCompanies } from "../../services/apiCalls";
import { toast } from "react-toastify";
import {
  updateUsersForSelectedCompany,
  updateSelectedUser,
} from "../../pages/userManagement/userManagerReducer";
import {
  updateCompanyData,
  updateCurrentlySelectedCompanyData,
  updateCurrentlySelectedOrgData,
  updateSelectedGlobalOrgCode,
} from "../../pages/myCompany/reducer";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import "./userRegistration.css";
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;

  @media (max-width: 500px) {
    padding: 1rem 2rem 10rem 2rem;
    margin: 0.5rem 0;
  }
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin-bottom: 20px;
  margin-top: -10px;
  @media (max-width: 500px) {
    position: relative;
    left: 15px;
    top: 8px;
  }
`;
const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  @media (max-width: 767px) {
    margin: 50px 20px 0 -20px;
    display: flex;
    justify-content: center;
    right: 75px;
  }
  @media (min-width: 768px) and (max-width: 2560px) {
    display: flex;
  justify-content: center;
  right:100px;
  }
`;

const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  margin: 40px 0;
`;
const MediumText = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  width: 300px;
`;

const SmallText = styled.div`
  font-size: 16px;
`;

const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;

const EditUser = (props) => {
  let initialState = {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    job_title: undefined,
    mob_number: undefined,
    department: undefined,
    is_admin: "Y",
    internship_view: "Y",
    internship_modify: "Y",
    company_view: "Y",
    company_modify: "Y",
    user_management_view: "Y",
    user_management_modify: "Y",
    view_talents: "Y",
    search_talents: "Y",
    view_job: "Y",
    modify_job: "Y",
    view_application: "Y",
    modify_application: "Y",
  };
  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const selectedUserIdForEdit = useSelector(
    (state) => state.userManager.currentlySelectedEditUserId
  );
  const selectedIdForEdit = useSelector(
    (state) => state.userManager.currentlySelectedEditId
  );
  const users = useSelector(
    (state) => state.userManager.usersForSelectedCompany
  );
  const loggedInUser = useSelector((state) => state.user.userData);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [orgCode, setOrgCode] = useState(undefined);
  const [userDetails, setUserDetails] = useState({});
  const [disable, setDisable] = useState(false);
  const disableEditIfSameUser = loggedInUser.id === selectedUserIdForEdit;
  const dispatch = useDispatch();
  let org = undefined;
  let dis = undefined;
  const navigate = useNavigate();
  const userFields = [
    "id",
    "email",
    "first_name",
    "last_name",
    "job_title",
    "department",
    "mob_number",
  ];
  const allRoles = [
    "is_admin",
    "internship_view",
    "internship_modify",
    "company_view",
    "company_modify",
    "user_management_view",
    "user_management_modify",
    "view_talents",
    "search_talents",
    "view_job",
    "modify_job",
    "view_application",
    "modify_application",
  ];

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...userDetails },
    resolver: yupResolver(schemaNewObj),
  });
  
  const onMblNumberChange = (val) => {
    if (!disable && !dis && !disableEditIfSameUser) {
      if (val) {
        setValue("mob_number", val);
      }
    }
  };
  const formVal = watch();

  function onClose() {}

  useEffect(() => {
    getOrgCode();
    for (let i = 0; i < Object.keys(users).length; i++) {
      if (users[i].id === selectedIdForEdit) {
        let fileds = allRoles.concat(userFields);
        for (let j = 0; j < fileds.length; j++) {
          setValue(fileds[j], users[i][fileds[j]]);
        }
      }
    }
  }, [users]);

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name == company.name) {
          if (
            companies[i].user_details.user_management_modify == "Y" ||
            companies[i].user_details.is_admin == "Y"
          ) {
            setDisable(false);
            dis = false;
          } else {
            setDisable(true);
            dis = true;
          }
          setOrgCode(companies[i].org_code);
          org = companies[i].org_code;
          return;
        }
      }
    }
  }

  function handleToggle(e) {
    if (e.target.id != "is_admin") {
      let val = formVal[e.target.id] == "N" ? "Y" : "N";
      setValue(e.target.id, val);
    } else {
      let overrideVal = formVal["is_admin"] == "N" ? "Y" : "N";
      for (let i = 0; i < allRoles.length; i++) {
        setValue(allRoles[i], overrideVal);
      }
    }
  }
  const onSubmit = async () => {
    setLoading(true);
    getOrgCode();
    if (orgCode || org) {
      const { data } = await onEditUser(orgCode || org, { ...formVal });
      if (data && data.status === 1) {
        toast.success("User Details Edited successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
        const { data1 } = await getCompanies();
        if (data1 && data1.status === 1) {
          if (JSON.stringify(companies) != JSON.stringify(data.data)) {
            dispatch(updateCompanyData({ ...data.data }));
          }
          setLoading(false);
        } else {
          setLoading(false);
        }
        getAllUsersForSelectedCompany();
        setFormErrors("");
        navigate("/my-company");
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    }
  };

  const getAllUsersForSelectedCompany = async () => {
    if (orgCode) {
      const { data } = await getAllUsers(orgCode);
      if (data && data.status === 1) {
        setLoading(false);
        dispatch(updateUsersForSelectedCompany({ ...data.data }));
      }
    }
    setLoading(false);
  };
 
  return (
    <MainLayout>
      <div className="breadcrumb">
        <p
          onClick={() => navigate("/my-company")}
          style={{ cursor: "pointer" }}
        >
          MyCompany
        </p>
        <img
          src={rightarrow}
          alt="img"
          className="rightArrow"
        />
        <p className="textOne" >Edit User</p>
      </div>

      <PageWrapper>
        <HeaderText>{"Edit User"}</HeaderText>

        <form>
          <div className="row">
            <div className="col-lg-4">
              <div className="title">Email Address* </div>
             
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    className="inputMain"
                    {...field}
                    placeholder="Email"
                    disabled={true} 
                  />
                )}
              />
              {errors.email && <ErrorField err={errors.email.message} />}
            </div>

            <div className="col-lg-4">
              <div className="title">First Name* </div>
           
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="First Name"
                    disabled={true} 
                  />
                )}
              />
              {errors.first_name && (
                <ErrorField err={errors.first_name.message} />
              )}
            
            </div>

            <div className="col-lg-4">
              <div className="title">Last Name* </div>
              
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Last Name"
                    disabled={true} 
                  />
                )}
              />
              {errors.last_name && (
                <ErrorField err={errors.last_name.message} />
              )}
              
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="title">Job Title* </div>
            
              <Controller
                name="job_title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Job Title"
                    disabled={disable || dis || disableEditIfSameUser}
                  />
                )}
              />
              {errors.job_title && (
                <ErrorField err={errors.job_title.message} />
              )}
            
            </div>

            <div className="col-lg-4">
              <div className="title">Department* </div>
              
              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder="Department"
                    disabled={disable || dis || disableEditIfSameUser}
                  />
                )}
              />
              {errors.department && (
                <ErrorField err={errors.department.message} />
              )}
            </div>

          

            <div className="col-lg-4">
              <div className="title">Mobile Number* </div>
              <Controller
                name="mob_number"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    containerStyle={{ height: "48px" }}
                    inputStyle={{ height: "48px" }}
                    value={formVal.mob_number}
                    onChange={onMblNumberChange}
                    defaultCountry="MY"
                    international
                    withCountryCallingCode={true}
                    countryCallingCodeEditable={false}
                    maxLength={16}
                    minLength={12}
                    disabled={disable || dis || disableEditIfSameUser}
                  />
                )}
              />
              {errors.mob_number && (
                <ErrorField err={errors.mob_number.message} />
              )}
            </div>
            {formErrors && <ErrorField err={formErrors} />}
          </div>
        </form>

        <HrLine />

        <Row>
          <Col md="6">
            <HeaderText>{"Roles"}</HeaderText>
          </Col>
          <Col md="6">
            <Form.Group
              className="admin"
              controlId="is_admin"
              onClick={handleToggle}
            >
              <Form.Check
                type="checkbox"
                label="Admin "
                defaultChecked={formVal.is_admin == "Y" ? true : false}
                checked={formVal.is_admin == "Y" ? true : false}
                disabled={disable || dis || disableEditIfSameUser}
                className="margin"
              />
              <span className="defaultText">selects all by default</span>
            </Form.Group>
          </Col>
        </Row>

        <Form>
          <Row>
            <Col>
              <MediumText>Internship</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="internship_view"
                onClick={handleToggle}
              >
                <Form.Check
                  style={{ fontSize: "16px" }}
                  label="View"
                  defaultChecked={formVal.internship_view == "Y" ? true : false}
                  checked={formVal.internship_view == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="internship_modify"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="Create/Modify"
                  defaultChecked={
                    formVal.internship_modify == "Y" ? true : false
                  }
                  checked={formVal.internship_modify == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>

              <MediumText>Company</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="company_view"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="View"
                  defaultChecked={formVal.company_view == "Y" ? true : false}
                  checked={formVal.company_view == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="company_modify"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="Create/Modify"
                  defaultChecked={formVal.company_modify == "Y" ? true : false}
                  checked={formVal.company_modify == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>

              <MediumText>User Management</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="user_management_view"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="View"
                  defaultChecked={
                    formVal.user_management_view == "Y" ? true : false
                  }
                  checked={formVal.user_management_view == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="user_management_modify"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="Manage User"
                  defaultChecked={
                    formVal.user_management_modify == "Y" ? true : false
                  }
                  checked={formVal.user_management_modify == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
            </Col>
            <Col>
              <MediumText className=" ">Talent Search</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="view_talents"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="View Talents"
                  defaultChecked={formVal.view_talents == "Y" ? true : false}
                  checked={formVal.view_talents == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="search_talents"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="Search for Talents"
                  defaultChecked={formVal.search_talents == "Y" ? true : false}
                  checked={formVal.search_talents == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="view_job"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="View Job Descriptions"
                  defaultChecked={formVal.view_job == "Y" ? true : false}
                  checked={formVal.view_job == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="modify_job"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="Modify Job Descriptions"
                  defaultChecked={formVal.modify_job == "Y" ? true : false}
                  checked={formVal.modify_job == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="view_application"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="View Applicants"
                  defaultChecked={
                    formVal.view_application == "Y" ? true : false
                  }
                  checked={formVal.view_application == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="modify_application"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label="Manage Applicants"
                  defaultChecked={
                    formVal.modify_application == "Y" ? true : false
                  }
                  checked={formVal.modify_application == "Y" ? true : false}
                  disabled={disable || dis || disableEditIfSameUser}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            {disable || dis || disableEditIfSameUser ? (
              <></>
            ) : (
              <ButtonWrapper>
                <Button
                  name="Submit"
                  className="button-mobile"
                  onClick={handleSubmit(onSubmit)}
                />
              </ButtonWrapper>
            )}
          </Row>
        </Form>

        {/* </div> */}
      </PageWrapper>
    </MainLayout>
  );
};

export default EditUser;
