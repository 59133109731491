import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { Colors } from "../../utilities/colors";
import React from "react";
import ReactTooltip from "react-tooltip";

const CardWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 30px;
  padding: 15px;
`;
const Header = styled.h4`
  font-weight: 500;
  font-size: 40px;
  // line-height: 54px;
  color: #202020;
  margin: 0 0 10px 0;
`;
const Description = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${Colors.primaryText};
  margin: 0;
  margin-top: -5px;
`;
export default function NewDashboardCards({ name,value,datatip,label }) {
  const {t} = useTranslation();

  return (
    <>
    <ReactTooltip id={label} place="top" effect="solid">{t(datatip)}</ReactTooltip>
      <CardWrapper data-tip data-for={label} data-html={true} >
        <Header>{t(value)}</Header>
        <Description>{t(name)}</Description>
      </CardWrapper>
    </>
  );
}
