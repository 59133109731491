import React, { useState, useEffect } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Subscriptions from "./Subscriptions";
import Subscriptions2 from "./SubscriptionsPageTwo";
import Button from "../../components/button/button";
import Pagination from "@mui/material/Pagination";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { getCompanySubscription } from "../../services/apiCalls";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import { updateSubscriptionData } from "./reducer";
import moment from "moment";
import "./Subscriptions.css"
import { toast } from "react-toastify";
import { t } from "i18next";


const HeaderText = styled.div`
  margin-top: -8px;
  //margin-bottom: 1%;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  @media (max-width:500px){
    margin:20px 0 20px 5px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-around;
  @media (max-width:500px){
    position:relative;
    margin
  }
`;
const CardsWrapper = styled.div`
  margin-top: 24px;
  background: ${Colors.light};
  // width: 965px;
  height: 638px;
  border-radius: 32px;
  padding-bottom: 1%;
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));
export default function SubscriptionMainPage() {
  const [tabIndex, setTabIndex] = useState(1);
  const navigate = useNavigate();
  const classes = useStyles();
  const dispatch = useDispatch();
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const [loading, setLoading] = useState(true);
  const [subscriptionExpired, setSubscriptionExpired] = useState(null);

  const [initial , setInitial] = useState(true)

  const orgDetails = useSelector((state) => state.company.currentOrgData);
  const userStatus = orgDetails?.user_details?.status;
  const subscribedData = useSelector(
    (state) => state.subscription.subscriptionData
  );
  const newSubscriptionRequest = useSelector((state) => state.subscription.requestNewSubscription);
  const onNavigate = () => {
    navigate("/subscriptions/subscription-plan");
  };
  const displayPage = (val) => {
    setTabIndex(val);
  };

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

 

  useEffect(() => {
    if (org_code && subscribedData && subscribedData[org_code]) {
      checkIsSubscriptionExpired(subscribedData[org_code])
      if(subscribedData[org_code].paymentstatus=="failed" && initial){
         toast.error("Your Last Transaction is failed, Please try again", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setInitial(false)
      }
    }
  })


  const getAllSubscriptions = async () => {
    const { data } = await getCompanySubscription(org_code)
    let localObj ={}
    localObj[org_code] = data.data;
    dispatch(updateSubscriptionData(localObj));
  }

  useEffect(() => {
    if (
      subscribedData &&
      subscribedData[org_code] &&
      subscriptionExpired != null &&
      !subscriptionExpired
    ) {
      navigate("/my-company");
    }
  }, [orgDetails.status != "accepted"]);

  useEffect(() => {
    if (org_code && subscribedData && subscribedData[org_code])
      setLoading(false);
    if (
      org_code &&
      !(
        subscribedData[org_code] && Object.keys(subscribedData[org_code]).length
      )
    )
      getSubscription();
  });

  const getSubscription = async () => {
    const { data } = await getCompanySubscription(org_code);
    if (data && data.status === 1 && data.data.length) {
      let localObj = {};
      localObj[org_code] = data.data;
      let isExpired = checkIsSubscriptionExpired(data.data[0]);
      dispatch(updateSubscriptionData(localObj));
      
    }
    setLoading(false);
  };



  useEffect(()=>{

  })
  function checkIsSubscriptionExpired(data) {
    let today = moment(new Date()).format("MM-DD-YYYY"); //moment()
    today = new Date(today);
    let renew_date = data.renew_date.split("-");
    renew_date = new Date(
      renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2]
    );
    setSubscriptionExpired(today > renew_date);
    if (today < renew_date && data.paymentstatus ==="success") navigate("/subscriptions/subscriptions-renew")
    return today > renew_date;
  }

 

  return (
    <MainLayout>
      <HeaderText>{t("Subscriptions")}</HeaderText>
      {loading ? (
        <Loader />
      ) : (
        <>
          {userStatus == "accepted" ? (
            <CardsWrapper>
              {tabIndex === 1 ? <Subscriptions /> : <Subscriptions2 />}
              <div
               
                className="buttonText"
              >
                <Pagination
                  style={{ textColor: "red" }}
                  classes={{ ul: classes.ul }}
                  count={2}
                  page={tabIndex}
                  onChange={(event, value) => displayPage(value)}
                  className="paginationText"
                />
                <div
                className="subMain"
                  
                >
                  <ButtonWrapper>
                    <Button name={t("Subscribe")} onClick={onNavigate} />
                  </ButtonWrapper>
                </div>
              </div>
            </CardsWrapper>
          ) : (
            <>
              <p className="noDataFound">{t("No data found")}</p>
            </>
          )}
        </>
      )}
    </MainLayout>
  );
}
