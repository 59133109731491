import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import "./styles.css";
import { routes } from "./routes.js";
import { getOrgData, getCompanyData } from "./services/apiCalls.js";
import { updateOrgData } from "./pages/login/reducer.js";
import { updateCompanyData } from "./pages/myCompany/reducer.js";
import ScrollToTop from "./components/scrollToTop/scrollToTop";
import {
  fetchAllCitiesByCountry,
  fetchAllCountries,
  fetchAllScopeOfStudies,
  fetchAllStatesByCountry,
  fetchAllUniverisities,
} from "./slices/master.slice.js";
import ChatbotComponent from "../src/components/chatbot/chatbot.js";

function App() {
  const dispatch = useDispatch();
  const [orgData, setorgData] = useState(null);
  // const [companyDataDone, setCompanyDataDone] = useState(false);

  useEffect(() => {
    getOrganisationInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (orgData) {
      getData();
      // setCompanyDataDone(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(fetchAllUniverisities());
    dispatch(fetchAllCountries());
    dispatch(fetchAllStatesByCountry());
    dispatch(fetchAllCitiesByCountry());
    dispatch(fetchAllScopeOfStudies());
  }, [dispatch]);

  const getOrganisationInfo = async () => {
    const { data } = {}; //await getOrgData();
    if (data && data.status === 1) {
      setorgData({ ...data.data, domain: data.domain });
      dispatch(updateOrgData({ ...data.data, domain: data.domain }));
    }
  };

  const getData = async () => {
    const { data } = await getCompanyData({
      code: orgData?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      dispatch(updateCompanyData(data.data));
    }
  };

  if (process.env.NODE_ENV === "production")
    console.log = function no_console() { };

  return (
    <Router>
      <ScrollToTop />
      {/* <SearchCompany /> */}
      <Routes>
        {routes.map((x) => (
          <Route key={x.path} exact path={x.path} element={x.element} />
        ))}
      </Routes>
      <ChatbotComponent />

      <ToastContainer zIndex="10001" />
    </Router>
  );
}

export default App;
