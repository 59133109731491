import {
  Document,
  Font,
  Image,
  Page,
  Text,
  Link,
  View,
  Svg,
  Path,
} from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import EmployabilityChart from "./charts/employabilityChart";
import OceanChart from "./charts/oceanChart";
import PersonalityChart from "./charts/personalityChart";
import WorkValuesChart from "./charts/workValuesChart";
import WorkInterestChart from "./charts/workInterestChart";
import EnglishProficiencyChart from "./charts/englishProficiencyChart";
import FutureOfWorkChart from "./charts/futureOfWorkChart";
import moment from "moment";
import fs from 'fs';
import path from 'path';
import base64 from 'base64-js';

// import LogoImg from "../../assets/Logo.png";

Font.register({
  family: "General Sans",
  fonts: [
    {
      src: "//cdn.fontshare.com/wf/FSGJYPLPXRLVZAZIVVF74LDN3Q3S2WUF/S5KG2IZFL42XIGI5UEGBWGDAYOGDCUXQ/XC3ORZWTJXPLYORAWMH6ZXES6GBV3W5L.ttf",
      fontWeight: 200,
    },
    {
      src: "//cdn.fontshare.com/wf/HFTXPYOBEE22ON2ZH2TDSTBQLGERA7LU/4AOH2PGTMHZO2YX5HMTOAV52ONC3WIXE/TW4KNQIPR4LNP4I6I6C6HKQ23B2EQAU5.ttf",
      fontWeight: 300,
    },
    {
      src: "//cdn.fontshare.com/wf/MFQT7HFGCR2L5ULQTW6YXYZXXHMPKLJ3/YWQ244D6TACUX5JBKATPOW5I5MGJ3G73/7YY3ZAAE3TRV2LANYOLXNHTPHLXVWTKH.ttf",
      fontWeight: 400,
    },
    {
      src: "//cdn.fontshare.com/wf/3RZHWSNONLLWJK3RLPEKUZOMM56GO4LJ/BPDRY7AHVI3MCDXXVXTQQ76H3UXA63S3/SB2OEB6IKZPRR6JT4GFJ2TFT6HBB6AZN.ttf",
      fontWeight: 500,
    },
    {
      src: "//cdn.fontshare.com/wf/K46YRH762FH3QJ25IQM3VAXAKCHEXXW4/ISLWQPUZHZF33LRIOTBMFOJL57GBGQ4B/3ZLMEXZEQPLTEPMHTQDAUXP5ZZXCZAEN.ttf",
      fontWeight: 600,
    },
    {
      src: "//cdn.fontshare.com/wf/KWXO5X3YW4X7OLUMPO4X24HQJGJU7E2Q/VOWUQZS3YLP66ZHPTXAFSH6YACY4WJHT/NIQ54PVBBIWVK3PFSOIOUJSXIJ5WTNDP.ttf",
      fontWeight: 700,
    },
  ],
  format: "truetype",
});

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data,imageData }) => {
  const { t } = useTranslation();

  // const getImageBase64 = () => {
    // Read image file from assets folder
  //   const imagePath = path.resolve(__dirname, 'assets', 'Logo.png');
  //   const imageData2 = fs.readFileSync(imagePath);
  
  //   // Convert image data to Base64
  //   const base64Data = base64.fromByteArray(imageData2);
  // console.log("base64DataValue",`data:image/png;base64,${base64Data}`)
    // return `data:image/png;base64,${base64Data}`;
  // };


  console.log("ProfilePic",imageData)
  return (
    <Document
      title={`${data?.userData?.full_name} - Assessment Report`}
      language="English"
      author="mynext.my"
      subject="This was generated with @react-pdf/renderer."
      keywords={[]}
      producer="mynext.my"
      creator="mynext.my"
    >
      <Page
        style={{
          padding: 35,
        }}
      >
        <View
          style={{
            height: "90vh",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Image src={'/images/logo.png'} style={{ width: 200 }} />

          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 24,
              fontWeight: 600,
              textTransform: "uppercase",
              marginVertical: 14,
              letterSpacing: 1.0,
            }}
          >
            {t("Applicant Assessment Report")}
          </Text>

          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 14,
              fontWeight: 200,
              marginVertical: 4,
              letterSpacing: 1.0,
            }}
          >
            {moment().format("MMM YYYY")}
          </Text>

          <View
            fixed
            style={{
              width: "100%",
              position: "absolute",
              bottom: 0,
              left: 0,
              right: 0,
              fontSize: 10,
              paddingTop: 4,
              textAlign: "center",
              color: "#333333",
              borderTop: "2px solid #000000",
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 8,
                fontWeight: 400,
                marginVertical: 4,
                letterSpacing: 1.0,
              }}
            >
              @ {moment().format("YYYY")} MYNext Sdn. Bhd | A fully owned
              subsidiary of TalentCorp Malaysia.
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 8,
                fontWeight: 400,
                letterSpacing: 1.0,
              }}
            >
              All Rights Reserved.
            </Text>
          </View>
        </View>

        <View style={{}} break>
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 24,
              fontWeight: 500,
              textTransform: "uppercase",
              borderBottom: "2px solid #000000",
              paddingBottom: 10,
            }}
          >
            {t("Applicant Information")}
          </Text>
          <View
            style={{ display: "flex", flexDirection: "row", marginTop: 14 }}
          >
            <View style={{ flex: 1 }}>
              {data?.userData?.full_name && (
                <View style={{}}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Name")} :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {data?.userData?.full_name}
                  </Text>
                </View>
              )}

              {data?.userData?.scope_abbr && (
                <View style={{ marginTop: 14 }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Scope of Study")} :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {data?.userData?.scope_abbr}
                  </Text>
                </View>
              )}

              {data?.userData?.insti_name_abbr && (
                <View style={{ marginTop: 14 }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Education Institution")} :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {data?.userData?.insti_name_abbr}
                  </Text>
                </View>
              )}

              {data?.userData?.address && (
                <View style={{ marginTop: 14 }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Address")} :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {data?.userData?.address}
                  </Text>
                </View>
              )}

              {data?.userData?.email && (
                <View style={{ marginTop: 14 }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Email")} :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {data?.userData?.email}
                  </Text>
                </View>
              )}

              {data?.userData?.mobile && (
                <View
                  style={{
                    marginTop: 14,
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Mobile No")} :
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {data?.userData?.mobile}
                  </Text>
                </View>
              )}
            </View>
            <View style={{ flex: 1, alignItems: "flex-end" }}>
              {/* {data?.userData?.profile_pic && ( */}
                <View style={{}}>
                  <Image
                    src={"https://mynext.oss-ap-southeast-3.aliyuncs.com/mf/avatar/default.png"}
                    style={{
                      width: 200,
                    }}
                  />
                </View>
              {/* )} */}
            </View>
          </View>
        </View>

        {(typeof data.personality !== "undefined" ||
          data.personality !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <PersonalityChart image={imageData.personality} data={data.personality} />
          </View>
        )}

        {(typeof data.workValues !== "undefined" ||
          data.workValues !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <WorkValuesChart data={data.workValues} image={imageData?.work_values}/>
          </View>
        )}

        {(typeof data.workInterest !== "undefined" ||
          data.workInterest !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <WorkInterestChart data={data.workInterest} image={imageData?.work_interest} />
          </View>
        )}

        {(typeof data.employability !== "undefined" ||
          data.employability !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <EmployabilityChart data={data.employability} />
          </View>
        )}

        {(typeof data.futureOfWork !== "undefined" ||
          data.futureOfWork !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <FutureOfWorkChart data={data.futureOfWork} />
          </View>
        )}

        {(typeof data.englishProficiency !== "undefined" ||
          data.englishProficiency !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <EnglishProficiencyChart data={data.englishProficiency} />
          </View>
        )}

        {(typeof data.ocean !== "undefined" || data.ocean !== null) && (
          <View
            style={{
              width: "100%",
            }}
            break
          >
            <OceanChart data={data.ocean} />
          </View>
        )}

        <View
          style={{
            marginTop: "30%",
          }}
          break
        >
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 26,
              fontWeight: 400,
              marginBottom: 14,
            }}
          >
            {t("About mynext")}
          </Text>

          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 12,
              fontWeight: 200,
              textAlign: "justify",
              color: "#213343",
              lineHeight: 1.5,
            }}
          >
            {t("mynext by TalentCorp is an all-in-one talent solution and analytics platform for students, universities, companies, and the workforce. The tools in mynext help talents understand and improve their employability and career paths, for university administrators to add value to their offerings, and for employers to source the right talents for their companies.")}
          </Text>
          <Link src="https://www.mynext.my">
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginTop: 10,
              }}
            >
              www.mynext.my
            </Text>
          </Link>
          <View style={{ marginTop: 10 }}>
            <View
              style={{
                marginBottom: 10,
              }}
            >
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                }}
              >
                Level 6, Surian Tower No. 1
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                }}
              >
                Jalan PJU 7/3, Mutiara Damansara
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                }}
              >
                47810 Petaling Jaya, Selangor, Malaysia
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
              }}
            >
              hello@mynext.my
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
              }}
            >
              03 - 7839 7111
            </Text>
          </View>
          <View style={{ flexDirection: "row", marginTop: 10 }}>
            <Link src="http://www.facebook.com/mynext.talentcorp">
              <Svg
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="30"
                height="30"
                viewBox="0 0 50 50"
                fill="#000000"
              >
                <Path d="M41,4H9C6.24,4,4,6.24,4,9v32c0,2.76,2.24,5,5,5h32c2.76,0,5-2.24,5-5V9C46,6.24,43.76,4,41,4z M37,19h-2c-2.14,0-3,0.5-3,2 v3h5l-1,5h-4v15h-5V29h-4v-5h4v-3c0-4,2-7,6-7c2.9,0,4,1,4,1V19z" />
              </Svg>
            </Link>
          </View>
        </View>
      </Page>
    </Document>
  );
};
