import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    modalIsOpen: false,
    cache: null,
    mode: "normal",
    id: null,
    armodal: false,
    delmodal: false,
    full_name: "",
    errors: [],
};

export const studentModalReducer = createSlice({
    name: "studentModalReducer",
    initialState,
    reducers: {
        updateModal: (state, action) => {
            if (typeof action.payload === "boolean") {
                state.modalIsOpen = action.payload;
                state.cache = null;
            } else {
                state.modalIsOpen = action.payload.modalIsOpen;
                state.cache = action.payload.cache;
                state.mode = action.payload.mode;
                state.id = action.payload.id;
                state.full_name = action.payload.full_name;
                state.delmodal = action.payload.delmodal;
                state.armodal = action.payload.armodal;
                state.errors = action.payload.errors ;
                console.log("Updated errors:", state.errors);
            }
        },
        clearErrors1: (state) => {
            state.errors = [];
            console.log("Cleared errors:", state.errors);
        },
        clearCache: (state) => {
            state.cache = null;
        },
        updateErrors: (state, action) => {
            state.errors = action.payload;
            console.log("updateErrors errors:", state.errors);
        },
    },
});

// Action creators are generated for each case reducer function
export const { updateModal, clearErrors1, clearCache, updateErrors } = studentModalReducer.actions;

export default studentModalReducer.reducer;
