import { useEffect } from "react";
import styled from "styled-components";
import Sidebar from "../../components/sidebar/sidebar";
import Footer from "../../components/footer/footer";
import TopNavbar from "../../components/navbar/navbar";
import {Scrollbars} from 'react-custom-scrollbars';
// styling starts
const LayoutWrapper = styled.section`
  display: flex;
  //height: 160vh;
  @media (max-width: 1024px) {
    display: flex;
  }
  @media (max-width: 500px) {
    display: flex;
  }
`;
const NavbarSection = styled.div`
   min-width: 346px;
  position: sticky;
  top: 0;
  background: #ffffff;
  width: 346px;
  height:100vh;
  border-radius: 0px 10px 10px 0px;
  @media (max-width: 1024px) {
    // display: none;
  }
  @media (max-width: 500px) {
    display: none;
  }
  @media (max-width: 820px) {
    display: none;
  }
  @media (max-width: 880px) {
    display: none;
  }
  @media (max-width: 1022px) {
    display: none;
  }
`;
const ContentWrapper = styled.div`
  padding: 0rem 3rem;
  min-height: 65vh;
  overflow: hidden;
  
  @media (max-width: 767px) {
    padding: 0rem 0rem;
  }
`;
const FooterWrapper = styled.div`
  padding: 0rem 3rem;
  // position:absolute;
  height:fit-content;
  @media (max-width: 767px) {
    padding: 0rem 0rem;
  }
`;
const PageContentSection = styled.div`
  overflow-y: auto;
  flex-grow: 2;
  // position:absolute;
  &::-webkit-scrollbar {
    width: 2px;
  }
  @media (max-width: 1090px) {
    padding: 1rem 1rem;
  }
`;
// const HeaderWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: end;
//   > img {
//     margin-right: 10px;
//     max-width: 30px;
//     cursor: pointer;
//     &:last-child() {
//       margin-right: 0px;
//     }
//   }
// `;

// const Backbtn = styled(FlexWrapper)`
//   align-items: center;
//   cursor: pointer;
//   > img {
//     margin-right: 10px;
//   }
//   > label {
//     display: block;
//     font-weight: 500;
//     font-size: 18px;
//     line-height: 24px;
//     display: flex;
//     align-items: center;
//     cursor: pointer;
//     color: #d04c8d;
//   }
// `;
// styling ends

export default function MainLayout({ children, back }) {
  useEffect(() => {
    document.body.classList.add("bg-body-cover");
  }, []);
  // const navigate = useNavigate();
  // const onGoBack = () => {
  //   navigate(-1);
  // };

  return (
    <LayoutWrapper>
      
     
        <Sidebar />
      
      
      <PageContentSection>
        <TopNavbar />
        <ContentWrapper>{children}</ContentWrapper>
        <FooterWrapper>
          <Footer />
        </FooterWrapper>
      </PageContentSection>
    </LayoutWrapper>
  );
}
