import { Row, Col } from "react-bootstrap";
import { OceanDescription, OceanLabel, OceanSlider } from "./commonStyles";
import { StudentAd2Card } from "./ad2Card";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const predictiveReliabilityConstants = [
  {
    key:1,
    title: "Predictive Reliability Factor",
    description: "Test or assessment demonstrates reliability in predicting future outcomes or behaviors. It assesses the consistency and stability of the assessment scores in accurately predicting specific criteria or measures of interest."
  }
];
export default function PredictiveReliabilityFactorChart(data) {
  const { t } = useTranslation();
  const [isVisible0, setIsVisible0] = useState(false);
  const handleVisible0 = () => setIsVisible0(!isVisible0);
  const getLevel = (val) => {
    if (val < 25 ) { return "low" }
    else if (val < 75) { return "moderate" } 
    else { return "high" };
};
const finalData = data['data'];
  return <>
    <StudentAd2Card title="Predictive Reliability Factor" sm="12" lg="12">
      <Row>
        <Col>
          <OceanLabel onClick={handleVisible0}>{t("Predictive Reliability Factor")}</OceanLabel>
        </Col>
        <Col>
          <OceanSlider level={getLevel(finalData["Predictive Reliability Factor"])} value={finalData["Predictive Reliability Factor"] || 0} aria-label="Slider" valueLabelDisplay="auto" />
        </Col>
      </Row>
      {isVisible0 &&
        <Row>
          <Col>
            <OceanDescription>{t(predictiveReliabilityConstants[0].description)}</OceanDescription>
          </Col>
        </Row>
      }
    </StudentAd2Card>
  </>
}