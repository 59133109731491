import React from 'react'
import styled from 'styled-components'
import { BoxWrapper, NormalText, SpanText, SubHeading } from './common'
const Section = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:32px;
`;
export default function EmployementDetails() {
    return (
        <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>Employment Details</SubHeading>
            <hr />
            <Section>
                <div>
                    <NormalText>Senior Business Analyst</NormalText>
                    <SubHeading style={{ fontStyle: "italic",marginBottom:"0" }}>CXS Analytics Sdn Bhd </SubHeading>
                    <SpanText>Kuala Lumpur, Malaysia</SpanText>
                </div>
                <SpanText>24 Months</SpanText>
            </Section>
            <Section>
                <div>
                    <NormalText> Business Analyst</NormalText>
                    <SubHeading style={{ fontStyle: "italic",marginBottom:"0" }}>CXS Analytics Sdn Bhd </SubHeading>
                    <SpanText>Kuala Lumpur, Malaysia</SpanText>
                </div>
                <SpanText>60 Months</SpanText>
            </Section>
        </BoxWrapper>
    )
}
