import CustomModal from "../../components/modal/CustomModal";
import styled from "styled-components";
import { Row, Col } from "react-bootstrap";
import * as Mui from "@material-ui/core";
import { GrClose } from "react-icons/gr";
import { calculateAge } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";



// const ViewWrapper = styled.div`
//   max-width: 100%;

//   @media (max-width: 767px) {
//     text-align: none;
//   }
// `;

const ProfileWrapper = styled.div`
  position: relative;
  width: 470px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

const ResumeName = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #6b6b6b;
`;

const ResumeNameTitle = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
  text-transform: capitalize;
`;

const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  margin: 10px auto;
`;

const DetailText = styled.p`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  color: #d04c8d;
  margin-bottom: 0.5em;
`;

const Close = styled.div`
  display: flex;
  justify-content: end;
  font-size: 1.5em;
  color: #000000;
  cursor: pointer;
`;

export default function ViewProfile(props) {
  // const classes = useStyles();
  const {
    profile_pic,
    display_name,
    mob_number,
    gender,
    dob,
    // city,
    // state,
    // country,
    email,
    nationality,
    city_abbr,
    state_abbr,
    curr_country_abbr,
  } = props.data;
  const { t, } = useTranslation();

  return (
    <CustomModal open={props.open}>
      {/* <ViewWrapper> */}
      <Row>
        <Col sm={3} md={3}>
          <Mui.Avatar
            src={profile_pic}
            style={{ width: "100%", height: "48%" }}
            // className={classes.avatarStyle}
          />
        </Col>
        <ProfileWrapper>
          <Close className="modal_header">
            <GrClose onClick={props.onClose} />
          </Close>
          <Col>
            <ResumeNameTitle>{display_name}</ResumeNameTitle>
            <HrLine />
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t('Gender')}:</DetailText>
                </Col>
                <Col className="text-capitalize">{gender}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t('Age')}:</DetailText>
                </Col>
                <Col>{calculateAge(dob)}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t('Location')}:</DetailText>
                </Col>
                <Col className="text-capitalize">
                  {/* {formLocation(city, state, country)} */}
                  {city_abbr}, {state_abbr}, {curr_country_abbr}
                </Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t('E-mail Id')}:</DetailText>
                </Col>
                <Col>{email}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t('Mobile No.')}:</DetailText>
                </Col>
                <Col>{mob_number}</Col>
              </Row>
            </ResumeName>
            <ResumeName>
              <Row>
                <Col sm={3}>
                  <DetailText>{t('Nationality')}:</DetailText>
                </Col>
                <Col className="text-capitalize">{nationality}</Col>
              </Row>
            </ResumeName>
          </Col>
        </ProfileWrapper>
      </Row>
      {/* </ViewWrapper> */}
    </CustomModal>
  );
}
