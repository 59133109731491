import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  orgData: {},
};

export const orgDataReducer = createSlice({
  name: "org",
  initialState,
  reducers: {
    updateOrgData: (state, action) => {
      state.orgData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateOrgData, } =
  orgDataReducer.actions;

export default orgDataReducer.reducer;
