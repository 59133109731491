import styled from "styled-components";
import { useTranslation } from "react-i18next";

const Label = styled.label`
  color: red;
  font-size: 12px;
  // margin: 0;
  display: block;
  font-weight: 500;
  margin: 0px 18px;
`;

export default function ErrorField({ err }) {
  const { t } = useTranslation();

  return (
    <Label>
      {t(err.charAt(0).toUpperCase() + err.slice(1).replaceAll("_", " "))}
    </Label>
  );
}
