import React, { useEffect } from 'react'
import MainLayout from '../../layouts/mainLayout/mainLayout'
import styled from 'styled-components';
import { Ad2Card } from '../../components/analyticalDashboardPage/adCard';
import { useTranslation } from "react-i18next";
import AdGenderRatio from '../../components/analyticalDashboardPage/adGenderRatio/adGenderRatio';
import { Ad2StudentPopulation } from '../../components/analyticalDashboardPage/adPopulation/adPopulation';
import { Utils } from '../../utilities/utils';
import { getGenderRatio, getGenderPoputation } from '../../services/apiCalls';
import { Ad2EnglishProficiency } from '../../components/analyticalDashboardPage/adEnglishProficiency/adEnglishProficiency';
import { Ad2FutureOfWork } from '../../components/analyticalDashboardPage/adFutureOfWork/adFutureOfWork';
import Ad2EmployabilityFactors from '../../components/analyticalDashboardPage/adEmployability/adEmployability';
import { Ad2StudentWorkValues } from '../../components/analyticalDashboardPage/adWorkValues/addWorkValues';
import { Ad2StudentPersonalityProfile } from '../../components/analyticalDashboardPage/adPersonality/adPersonality';
import { Ad2StudentWorkInterest } from '../../components/analyticalDashboardPage/adWorkInterest/adWorkInterest';
import { format, getYear, isValid, parse, startOfYear } from "date-fns";
import { Ad2CompletionRate } from '../../components/analyticalDashboardPage/adCompletionRate/adCompletionRate';
import { Ad2Internships } from '../../components/analyticalDashboardPage/adInternships/adIntership';
import { Ad2InternshipIndustrySector } from '../../components/analyticalDashboardPage/adInternships/adInternshipIndustry';
const HeaderText = styled.div`
  margin-top: -8px;
  //margin-bottom: 1%;
  padding-bottom: 20px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  @media (max-width:500px){
    margin:20px 0 20px 5px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const CardWrapperThreeSection = styled.div`
  display:grid;
  grid-template-columns:0fr 1fr 1fr;
  grid-gap:12px;
`;

const CardWrapperTwoSection = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:12px;
`;

const ChartsWrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap:12px;
`;

export default function AnalyticalDashboard() {
    const { t, i18n } = useTranslation();
    const todayDate = new Date();
    let chartsLoadingController = new AbortController();
    const studentPop = {
        "status": {
            "registered": 18,
            "unregistered": 88
        },
        "total": 106
    }
    const genderRatio = {
        "data": {
            "status": {
                "registered": {
                    "male": 2,
                    "female": 16
                },
                "unregistered": {
                    "male": 58,
                    "female": 30
                }
            },
            "total": {
                "male": 60,
                "female": 46
            }
        },
        "status": 1
    }
    const [chartState, setChartState] = React.useState({
        studentPopulation: { data: null, status: "loading" },
        genderRatioData: { data: null, status: "loading" },
        internships: { data: null, status: "loading" },
        internshipIndustrySector: { data: null, status: "loading" },
        completionRate: { data: null, status: "loading" },
        studentWorkInterest: { data: null, status: "loading" },
        studentsWorkValues: { data: null, status: "loading" },
        employabilityFactors: { data: null, status: "loading" },
        futureOfWork: { data: null, status: "loading" },
        englishProficiency: { data: null, status: "loading" },
    });

    const [searchState, setSearchState] = React.useState({
        internshipStartDate: format(startOfYear(todayDate), "dd-MM-yyyy"),
        internshipEndDate: format(todayDate, "dd-MM-yyyy"),
        // internshipIndustrySectorFilter: yearNow,
        // studentWorkInterestFilter: "rank1",
        // selectedCampus: searchParams.get("selectedCampus"),
        // selectedFaculty: searchParams.get("selectedFaculty"),
        // selectedStudyProgram: searchParams.get("selectedStudyProgram"),
        // selectedYearOfStudy: searchParams.get("selectedYearOfStudy"),
        // selectedUniversity: [],
        // selectedScopeOfStudy: searchParams.get("selectedScopeOfStudy"),
        studentPersonalityProfile: [],
    });

    const setChartStateWrapper = (obj) => {
        setChartState((prevState) => ({ ...prevState, ...obj }));
    };
    const setSearchStateWrapper = (obj, callback) => {
        setSearchState((prevState) => ({ ...prevState, ...obj }));
    };

    const parseDateToString = (dateVal) => {
        const todayDate = new Date();
        let output = format(todayDate, "dd-MM-yyyy");
        const newOutput = format(dateVal, "dd-MM-yyyy");
        if (newOutput !== "Invalid Date" && dateVal) {
            output = newOutput;
        }
        return output;
    };

    const parseStringToDate = (dateVal) => {
        const todayDate = new Date();
        let output = todayDate;
        if (typeof dateVal === "string") {
            output = parse(dateVal, "dd-MM-yyyy", todayDate);
        }
        if (isValid(dateVal) && dateVal) {
            output = dateVal;
        }
        return output;
    };

    // const getParams = () => {
    //     return {
    //         campus:
    //             searchState &&
    //                 searchState.selectedCampus &&
    //                 Array.isArray(searchState.selectedCampus)
    //                 ? searchState.selectedCampus.join(",")
    //                 : searchState.selectedCampus,
    //         faculty:
    //             searchState &&
    //                 searchState.selectedFaculty &&
    //                 Array.isArray(searchState.selectedFaculty)
    //                 ? searchState.selectedFaculty.join(",")
    //                 : searchState.selectedFaculty,
    //         study_program:
    //             searchState &&
    //                 searchState.selectedStudyProgram &&
    //                 Array.isArray(searchState.selectedStudyProgram)
    //                 ? searchState.selectedStudyProgram.join(",")
    //                 : searchState.selectedStudyProgram,
    //         year_of_study:
    //             searchState &&
    //                 searchState.selectedYearOfStudy &&
    //                 Array.isArray(searchState.selectedYearOfStudy)
    //                 ? searchState.selectedYearOfStudy.join(",")
    //                 : searchState.selectedYearOfStudy,
    //         insti_name:
    //             searchState &&
    //                 searchState.selectedUniversity &&
    //                 Array.isArray(searchState.selectedUniversity)
    //                 ? searchState.selectedUniversity.join(",")
    //                 : searchState.selectedUniversity,
    //         scope_of_study:
    //             searchState &&
    //                 searchState.selectedScopeOfStudy &&
    //                 Array.isArray(searchState.selectedScopeOfStudy)
    //                 ? searchState.selectedScopeOfStudy.join(",")
    //                 : searchState.selectedScopeOfStudy,

    //     };
    // };
    const fetchGenderRatio = async () => {
        const labelMap = {
            registered: "Onboarded",
            unregistered: "Un-registered",
        };
        setChartStateWrapper({
            genderRatioData: { data: null, status: "loading" },
        });
        const filterParams = {};
        filterParams.insti_name = 141;

        // const { data, status } = await getGenderRatio({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });

        const data = {
            "status": {
                "registered": {
                    "male": 2,
                    "female": 16
                },
                "unregistered": {
                    "male": 58,
                    "female": 30
                }
            },
            "total": {
                "male": 60,
                "female": 46
            }
        }
        const status = 200;
        // let totalResponse = data && data.data && data.data.total;
        let totalResponse = [60, 46]
        const totalSum = totalResponse
            ? Utils.getObjectValuesSum(totalResponse)
            : 0;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            totalSum,
            status,
            "genderRatioData"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let response = data && data.status;
            const genderRatioData = Object.keys(response).map((key) => {
                return {
                    type: labelMap[key],
                    female: response[key].female,
                    male: response[key].male,
                };
            });
            genderRatioData.sort();
            setChartStateWrapper({
                genderRatioData: { data: genderRatioData, status },
            });
        }
    };

    const setFilter = (keyNew, func, update) => {
        if (keyNew === "internshipFilter") {
            setSearchStateWrapper({
                internshipStartDate: parseDateToString(
                    update && update[0] && update[0].startDate
                ),
                internshipEndDate: parseDateToString(
                    update && update[0] && update[0].endDate
                ),
            });
        } else {
            setSearchStateWrapper({ [keyNew]: update });
        }
    };

    const fetchStudentPoputation = async () => {
        setChartStateWrapper({
            studentPopulation: { data: null, status: "loading" },
        });
        // const filterParams = getParams();
        // const universityId =
        // user && user.university && user.university.universityId;
        // console.log("cba cn,cnz", universityID);
        // filterParams.insti_name = 141;
        // const { data, status } = await getGenderPoputation({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "status": {
                "registered": 18,
                "unregistered": 88
            },
            "total": 106
        }
        const status = 200;
        let total = [];
        total.push(data.total)
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "studentPopulation"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            const dataObj = data && data.status;
            setChartStateWrapper({ studentPopulation: { data: [dataObj], status } });
        }
    };

    const fetchEnglishProficiency = async () => {
        const labelMap = {
            'Grammar': t('Grammar'),
            'Comprehension': t('Comprehension'),
            'General': t('Total English Proficiency Level')
        }
        setChartStateWrapper({ englishProficiency: { data: null, status: 'loading' } });
        // const filterParams = getParams();
        // filterParams.insti_name = 141;
        // const { data, status } = await getEnglishProficiency({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });

        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Grammar": {
                    "id": 1,
                    "results": {
                        "level1": 279,
                        "level2": 238,
                        "level3": 156,
                        "level4": 14,
                        "level5": 0
                    }
                },
                "totalTalents": [694],
                "Comprehension": {
                    "id": 2,
                    "results": {
                        "level1": 319,
                        "level2": 204,
                        "level3": 149,
                        "level4": 15,
                        "level5": 0
                    }
                },
                "General": {
                    "id": 15,
                    "results": {
                        "level1": 318,
                        "level2": 271,
                        "level3": 97,
                        "level4": 1,
                        "level5": 0
                    }
                }
            }
        }
        const status = 200;
        let total = data.results.totalTalents;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "englishProficiency"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            const gridData = [];
            const chartDataMap = {};
            Object.keys(results)
                .filter((x) => x !== "totalTalents")
                .forEach((key) => {
                    const level1 = Utils.falsyNumberCaseHandle(
                        results[key] &&
                        results[key].results &&
                        results[key].results.level1
                    ),
                        level2 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level2
                        ),
                        level3 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level3
                        ),
                        level4 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level4
                        ),
                        level5 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level5
                        );
                    gridData.push({
                        type: labelMap[key],
                        level1,
                        level2,
                        level3,
                        level4,
                        level5,
                    });
                    chartDataMap[key] = {
                        level1,
                        level2,
                        level3,
                        level4,
                        level5,
                    };
                });
            setChartStateWrapper({
                englishProficiency: {
                    gridData,
                    data: chartDataMap,
                    status,
                    total,
                },
            });
        }
    };

    const fetchFutureofWork = async () => {
        setChartStateWrapper({ futureOfWork: { data: null, status: "loading" } });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const { data, status } = await getFutureOfWork({
        //   filters: filterParams,
        //   chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Cognitive": {
                    "id": 11,
                    "results": {
                        "low": 0,
                        "moderate": 570,
                        "high": 116
                    }
                },
                "totalTalents": 694,
                "Interpersonal": {
                    "id": 12,
                    "results": {
                        "low": 0,
                        "moderate": 585,
                        "high": 101
                    }
                },
                "Self Leadership": {
                    "id": 13,
                    "results": {
                        "low": 0,
                        "moderate": 584,
                        "high": 102
                    }
                },
                "Digital": {
                    "id": 14,
                    "results": {
                        "low": 0,
                        "moderate": 554,
                        "high": 132
                    }
                }
            }
        }
        let total = data.results.totalTalents;
        const status = 200
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "futureOfWork"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            const gridData = [];
            const chartDataMap = {};
            Object.keys(results)
                .filter((x) => x !== "totalTalents")
                .forEach((key) => {
                    const high = Utils.falsyNumberCaseHandle(
                        results[key] && results[key].results && results[key].results.high
                    ),
                        moderate = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.moderate
                        ),
                        low = Utils.falsyNumberCaseHandle(
                            results[key] && results[key].results && results[key].results.low
                        );
                    gridData.push({
                        type: t(key),
                        high,
                        moderate,
                        low,
                    });
                    chartDataMap[key] = {
                        high,
                        moderate,
                        low,
                    };
                });
            setChartStateWrapper({
                futureOfWork: {
                    gridData,
                    data: chartDataMap,
                    status,
                    total,
                },
            });
        }
    };

    const fetchEmployabilityFactors = async () => {
        setChartStateWrapper({
            employabilityFactors: { data: null, status: "loading" },
        });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const { data, status } = await getEmployability({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Ideas and Opportunities": {
                    "id": 7,
                    "percentage": 16
                },
                "totalTalents": 694,
                "Resources": {
                    "id": 8,
                    "percentage": 18
                },
                "Into Action": {
                    "id": 9,
                    "percentage": 15
                }
            }
        }
        let total = data.results.totalTalents;
        let status = 200;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "employabilityFactors"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            setChartStateWrapper({
                employabilityFactors: {
                    data: [
                        {
                            ideasAndOpportunities: Utils.falsyNumberCaseHandle(
                                results &&
                                results["Ideas and Opportunities"] &&
                                results["Ideas and Opportunities"].percentage
                            ),
                            resources: Utils.falsyNumberCaseHandle(
                                results &&
                                results["Into Action"] &&
                                results["Into Action"].percentage
                            ),
                            intoAction: Utils.falsyNumberCaseHandle(
                                results &&
                                results["Resources"] &&
                                results["Resources"].percentage
                            ),
                        },
                    ],
                    status,
                    total,
                },
            });
        }
    };

    const fetchWorkValues = async () => {
        setChartStateWrapper({
            studentsWorkValues: { data: null, status: "loading" },
        });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const { data, status } = await getWorkValues({
        //   filters: filterParams,
        //   chartsLoadingController: chartsLoadingController,
        // });

        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Mastery": {
                    "id": 1,
                    "percentage": 5
                },
                "Quality of Life": {
                    "id": 2,
                    "percentage": 3
                },
                "Fellowship": {
                    "id": 3,
                    "percentage": 3
                },
                "Creating Value": {
                    "id": 4,
                    "percentage": 5
                },
                "totalTalents": 694
            }
        }
        let total = data.results.totalTalents;
        let status = 200;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "studentsWorkValues"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            setChartStateWrapper({
                studentsWorkValues: {
                    data: [
                        {
                            mastery: Utils.falsyNumberCaseHandle(
                                results &&
                                results["Creating Value"] &&
                                results["Creating Value"].percentage
                            ),
                            qualityOfLife: Utils.falsyNumberCaseHandle(
                                results &&
                                results["Fellowship"] &&
                                results["Fellowship"].percentage
                            ),
                            fellowship: Utils.falsyNumberCaseHandle(
                                results && results["Mastery"] && results["Mastery"].percentage
                            ),
                            creativeValues: Utils.falsyNumberCaseHandle(
                                results &&
                                results["Quality of Life"] &&
                                results["Quality of Life"].percentage
                            ),
                        },
                    ],
                    status,
                    total,
                },
            });
        }
    };

    const fetchStudentPersonlityProfile = async () => {
        setChartStateWrapper({
            studentPersonalityProfile: { data: null, status: "loading" },
        });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const { data, status } = await getPersonalitySummary({
        //   filters: filterParams,
        //   chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "emotional_stability": {
                    "average": 3.59
                },
                "extraversion": {
                    "average": 3.62
                },
                "openness_to_experience": {
                    "average": 3.6
                },
                "agreeableness": {
                    "average": 3.61
                },
                "conscientiousness": {
                    "average": 3.57
                },
                "totalTalents": 694
            }
        }
        const status = 200;
        let total = data.results.totalTalents;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "studentPersonalityProfile"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            setChartStateWrapper({
                studentPersonalityProfile: {
                    data: [
                        {
                            agreeableness: Utils.falsyNumberCaseHandle(
                                results.agreeableness.average
                            ),
                            openess: Utils.falsyNumberCaseHandle(
                                results.openness_to_experience.average
                            ),
                            conscientiousness: Utils.falsyNumberCaseHandle(
                                results.conscientiousness.average
                            ),
                            extraversion: Utils.falsyNumberCaseHandle(
                                results.extraversion.average
                            ),
                            neuoroicism: Utils.falsyNumberCaseHandle(
                                results.emotional_stability.average
                            ),
                        },
                    ],
                    status,
                    total,
                },
            });
        }
    };

    const fetchStudentWorkInterest = async () => {
        setChartStateWrapper({
            studentWorkInterest: { data: null, status: "loading" },
        });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const rank = searchState.studentWorkInterestFilter.value;
        // if (rank) {
        //     filterParams.rank = rank;
        // }
        // const { data, status } = await getWorkInterest({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "totalTalents": 689,
                "Realistic": {
                    "male": 21,
                    "female": 10,
                    "total": 31
                },
                "Investigative": {
                    "male": 16,
                    "female": 14,
                    "total": 30
                },
                "Artistic": {
                    "male": 22,
                    "female": 10,
                    "total": 32
                },
                "Social": {
                    "male": 19,
                    "female": 18,
                    "total": 37
                },
                "Enterprising": {
                    "male": 7,
                    "female": 16,
                    "total": 23
                },
                "Conventional": {
                    "male": 14,
                    "female": 13,
                    "total": 27
                }
            }
        }
        const status = 200;
        let total = data.results.totalTalents;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "studentWorkInterest"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            const gridData = [];
            const chartDataMap = {};
            Object.keys(results)
                .filter((x) => x !== "totalTalents")
                .forEach((key) => {
                    gridData.push({
                        type: t(key),
                        male: Utils.falsyNumberCaseHandle(results[key].male),
                        female: Utils.falsyNumberCaseHandle(results[key].female),
                    });
                    chartDataMap[key] = {
                        male: Utils.falsyNumberCaseHandle(results[key].male),
                        female: Utils.falsyNumberCaseHandle(results[key].female),
                    };
                });
            setChartStateWrapper({
                studentWorkInterest: {
                    gridData,
                    data: chartDataMap,
                    status,
                    total,
                },
            });
        }
    };


    const fetchCompletionRate = async () => {
        // const filterParams = getParams();

        // filterParams.insti_name = universityID;

        setChartStateWrapper({ completionRate: { data: null, status: "loading" } });
        // const { data, status } = await getQuizzesCompletion({
        //   filters: filterParams,
        //   chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "totalUsers": 694,
                "quizzes": [
                    {
                        "id": 1,
                        "name": "work-values",
                        "title": "Work Values",
                        "completed": "687"
                    },
                    {
                        "id": 2,
                        "name": "interest-riasec",
                        "title": "Work Interests",
                        "completed": "687"
                    },
                    {
                        "id": 3,
                        "name": "five-factor",
                        "title": "Personality & Motivation",
                        "completed": "687"
                    },
                    {
                        "id": 4,
                        "name": "employability",
                        "title": "Employability",
                        "completed": "686"
                    },
                    {
                        "id": 6,
                        "name": "21-century-skills",
                        "title": "The Future of Work",
                        "completed": "686"
                    },
                    {
                        "id": 7,
                        "name": "english-test",
                        "title": "English Proficiency",
                        "completed": "687"
                    }
                ]
            }
        }
        const status = 200;
        let totalUsers =
            data.results.totalUsers;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            totalUsers,
            status,
            "completionRate"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let quizzes =
                data.results.quizzes;
            const quizCompletion = {
                totalUsers: Utils.falsyNumberCaseHandle(totalUsers),
            };
            quizzes.forEach((item) => {
                quizCompletion[item.name] = Utils.falsyNumberCaseHandle(item.completed);
            });
            setChartStateWrapper({
                completionRate: { data: quizCompletion, status },
            });
        }
    };

    const fetchInternships = async () => {
        setChartStateWrapper({ internships: { data: null, status: "loading" } });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const startDate = searchState.internshipStartDate;
        // if (startDate) {
        //     console.log(startDate, "===========================");
        //     var date = startDate;
        //     var newdate = date.split("-").reverse().join("-");
        //     filterParams.start_date = newdate;
        // }
        // const endDate = searchState.internshipEndDate;
        // if (endDate) {
        //     var date = endDate;
        //     var newdate = date.split("-").reverse().join("-");
        //     filterParams.end_date = newdate;
        // }
        // const { data, status } = await getInternship({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });
        const status = 200;
        const data = {
                "facet": [],
                "request": {
                    "start_date": "2023-01-01",
                    "end_date": "2023-06-15",
                    "university": "141"
                },
                "status": {
                    "totalTalents": 695,
                    "completed": 3,
                    "applied": 3,
                    "offered": 1,
                    "interested": 1
                }
            
        }
        // let total = data.total;
        // const { proceed, stateObj } = Utils.commonStateUpdateLogic(
        //     data,
        //     total,
        //     status,
        //     "internships",
        //     true
        // );
        // if (stateObj) {
        //     setChartStateWrapper(stateObj);
        // }
        // if (proceed) {
            let response = data.status;
            setChartStateWrapper({ internships: { data: [response], status } });
        // }
console.log("data",chartState);
    };

    const fetchInternshipIndustrySector = async () => {
        setChartStateWrapper({
          internshipIndustrySector: { data: null, status: "loading" },
        });
        // const filterParams = getParams();
        // filterParams.insti_name = universityID;
        // const internshipIndustrySectorFilter =
        //   searchState.internshipIndustrySectorFilter;
        // if (internshipIndustrySectorFilter) {
        //   filterParams.year = internshipIndustrySectorFilter;
        // }
        // const { data, status } = await getInternshipCompanySector({
        //   filters: filterParams,
        //   chartsLoadingController: chartsLoadingController,
        // });
        const data = {
            "total": 17,
            "sector": {
                "Arts / Creative Design Jobs": 15,
                "Advertising Jobs": 1,
                "IT - Software Jobs": 1
            }
        }
        const status = 200;
        let total = data.total;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
          data,
          total,
          status,
          "internshipIndustrySector"
        );
        if (stateObj) {
          setChartStateWrapper(stateObj);
        }
        if (proceed) {
          const convertedObject = Object.entries(data?.sector)
            .map((e) => ({
              id: e[0],
              value: parseInt((e[1] * 100) / data?.total),
              actualValue: e[1],
            }))
            .filter((x) => x.id && x.value);
          setChartStateWrapper({
            internshipIndustrySector: { data: convertedObject, status },
          });
        }
      };

    useEffect(() => {
        fetchGenderRatio()
        fetchStudentPoputation()
        fetchEnglishProficiency()
        fetchFutureofWork()
        fetchEmployabilityFactors()
        fetchWorkValues()
        fetchStudentPersonlityProfile()
        fetchStudentWorkInterest()
        fetchCompletionRate()
        fetchInternships()
        fetchInternshipIndustrySector()
    }, [])

    return (
        <>
 <HeaderText>Analytical Dashboard</HeaderText>
            <ChartsWrapper>
                <CardWrapperTwoSection>
                    <Ad2StudentPopulation data={chartState.studentPopulation} />
                    <AdGenderRatio data={chartState.genderRatioData}></AdGenderRatio>
                </CardWrapperTwoSection>
                {/* <CardWrapperTwoSection>
                    <Ad2Internships
                        data={chartState.internships}
                        internshipFilter={[
                            {
                                startDate: parseStringToDate(searchState.internshipStartDate),
                                endDate: parseStringToDate(searchState.internshipEndDate),
                                key: "selection",
                            },
                        ]}
                        setInternshipFilter={setFilter.bind(
                            this,
                            "internshipFilter",
                            fetchInternships
                        )}
                    />
                    <Ad2InternshipIndustrySector
                        data={chartState.internshipIndustrySector}
                        internshipIndustrySectorFilter={
                            searchState.internshipIndustrySectorFilter
                        }
                        setInternshipIndustrySectorFilter={setFilter.bind(
                            this,
                            "internshipIndustrySectorFilter",
                            fetchInternshipIndustrySector
                        )}
                    />
                </CardWrapperTwoSection> */}
                <Ad2CompletionRate data={chartState.completionRate} />
                <CardWrapperTwoSection>
                    <Ad2StudentPersonalityProfile data={chartState.studentPersonalityProfile} />
                    <Ad2StudentWorkInterest
                        data={chartState.studentWorkInterest}
                        studentWorkInterestFilter={searchState.studentWorkInterestFilter}
                        setStudentWorkInterestFilterFilter={setFilter.bind(
                            this,
                            "studentWorkInterestFilter",
                            fetchStudentWorkInterest
                        )}
                    />
                </CardWrapperTwoSection>
                <Ad2StudentWorkValues data={chartState.studentsWorkValues} />
                <Ad2EmployabilityFactors data={chartState.employabilityFactors} />
                <Ad2FutureOfWork data={chartState.futureOfWork} />
                <Ad2EnglishProficiency data={chartState.englishProficiency}></Ad2EnglishProficiency>
            </ChartsWrapper>
        </>
        // <MainLayout>
           
        // </MainLayout>
    )
}
