import { useState, useEffect } from "react";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Button from "../../components/button/button";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import UploadLogo from "../../components/uploadOrganistionLogo/uploadLogo";
import UploadIcon from "../../assets/uploadImg.png";
import UploadPlusIcon from "../../assets/uploader.png";
import CoverImage from "../../assets/uploadCover.png";
import Loader from "../../components/loader/loader";
import {
  registerNewCompany,
  onCompanyLogoUpdate,
  getCompanies,
} from "../../services/apiCalls";
import { updateCompanyData, updateCurrentlySelectedOrgData, updateSelectedGlobalOrgCode } from "../../pages/myCompany/reducer";

// import {
//   registerOrg_S1,
//   updateExistingOrganisation,
// } from "../../services/apiCalls";
import {
  updateSuccessObj,
  // updateFormErr,
} from "../registerorganisation/reducer";

import Tooltip from "../../components/reactTooltip/tooltip";
import { useTranslation } from "react-i18next";

// styling starts
const PageWrapper = styled.section`
  @media (max-width: 1010px) {
    width: 100%;
  }
`;
// const LabelText = styled.label`
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 24px;
// `;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
// const SaveExitBtn = styled(LabelText)`
//   color: #504f8c;
//   text-decoration: underline;
//   cursor: pointer;
//   margin-left: 30px;

// `;
const ImageUploader = styled(Flexbox)`
  margin: 20px auto;
  justify-content: center;
  width: 254px;
  height: 254px;
  position: relative;
`;
const Image = styled.img`
  border-radius: 50%;
  object-fit: contain;
  height: 100%;
  width: 100%;
`;
const PlusIcon = styled.img`
  position: absolute;
  bottom: 0;
  right: 0;
  cursor: pointer;
`;
const UploadWrapper = styled.div`
  // width: 75%;

  @media (max-width: 1090px) {
    width: 100%;
  }
`;
const CenterAlignText = styled.div`
  text-align: center;
`;
const UploadText = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration: ${(props) => (props.underline ? props.underline : "none")};
  color: ${(props) => (props.color ? props.color : "none")};
  margin: 35px 0px;
`;
const SkipText = styled(UploadText)`
  cursor: pointer;
`;
// styling ends

export default function UploadOrganisationLogo() {
  const [openModal, setUploadModal] = useState(false);
  // const [isNextStep, setNext] = useState(true);
  // const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({ showMessage: false });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const userDetail = useSelector((state) => state.organisation.value);
  const orgDetail = useSelector((state) => state.organisation.successData);
  const isUpdate = useSelector((state) => state.organisation.isUpdate);
  const [imgLogo, setImgLogo] = useState(null);
  const { is_completed } = orgDetail;
  const isRedirect = useSelector((state) => state.organisation.isRedirect);
  const [newOrg, setNewOrg] = useState(undefined);
  /* useEffect(() => {
    if (isRedirect) navigate("/search");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/
  const onOpenUploader = () => {
    setUploadModal(true);
    // dispatch(updateModal(true));
  };

  useEffect( async () => {
    if (newOrg) await getOrganisationInfo();
  }, [newOrg]);

  const onCloseModal = () => {
    setUploadModal(false);
  };

  // on FormSubmission starts
  const onBack = () => {
    navigate(`/register`);
  };

  // const onSaveAndExit = () => {
  //   // setNext(false);
  //   onFormSubmission();
  // };

  const pickedImage = (event) => {
    setImgLogo(event.target.files[0]);
  };

  const registerCompany = async (req) => {
    const { data } = await registerNewCompany({ ...req });
    if (data && data.status === 1) {
      updateLogo(data.code);
      setNewOrg(data.code)
      //dispatch(updateSelectedGlobalOrgCode(data.code))
      // getOrganisationInfo();
      //dispatch(updateCurrentlySelectedCompanyData({ ...data.data }))
    }
  };

  const updateLogo = async (org_code) => {
    const formdata = new FormData();
    formdata.append("logo_path", imgLogo);
    if (imgLogo) {
      const { data, status } = await onCompanyLogoUpdate({
        code: org_code, //org?.additional_info?.org_code,
        data: formdata,
      });
      if (data && data.status === 1) {

        getOrganisationInfo();
      }
    }
  };

  const getOrganisationInfo = async () => {
    const { data } = await getCompanies();
    if (data && data.status === 1) {
      //dispatch(updateOrgData({ ...data.data }));
      localStorage.setItem("companyData",JSON.stringify(data))
      dispatch(updateCompanyData({ ...data.data }));
      setLoading(false);
      if (newOrg){
        dispatch(updateSelectedGlobalOrgCode(newOrg));
        dispatch(updateCurrentlySelectedOrgData({
          ...data.data.filter((idx) => idx.org_code === newOrg)[0],
        }))
      }
      setNewOrg(undefined)
      //getData(data.data.org_code);
    } else {
      //navigate("/login");
      if (newOrg)
        dispatch(updateSelectedGlobalOrgCode(newOrg));
        dispatch(updateCurrentlySelectedOrgData({
          ...data.data.filter((idx) => idx.org_code === newOrg)[0],
        }))
      setLoading(false);
    }
  };
  useEffect( async () => {
    await getOrganisationInfo();
  }, []);
  const onFinish = async (skipLogoUpload = false) => {
    if (userDetail.logo_path || isUpdate || skipLogoUpload) {
      const req = { ...userDetail, step: 2, logo_path: undefined };
      await registerCompany(req);
      dispatch(updateSuccessObj(req));
      navigate("/dashboard");
      sessionStorage.clear()
      await getOrganisationInfo();
    } else {
      setState({ showMessage: true });
    }
  };

  // on FormSubmission starts
  const onNavigate = (path) => {
    if (userDetail.logo_path === "") {
      let compUser = { ...userDetail };
      delete compUser.logo_path;
      dispatch(updateSuccessObj(compUser));
    }
    navigate(path);
  };
  return (
    <UserCreationLayout coverImg={CoverImage}>
      <PageWrapper>
        <IntroSection name="uploadOrganisationLogo" margin="0" />
        <PaginationDivider step={2} />
        <UploadWrapper>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ImageUploader data-tip data-for="logoUpload">
              <Image
                src={
                  userDetail.logo_path
                    ? typeof userDetail.logo_path === "string"
                      ? userDetail.logo_path
                      : URL.createObjectURL(userDetail.logo_path)
                    : UploadIcon
                }
                alt="image-uploader"
              />
              {isUpdate ? (
                ""
              ) : (
                <>
                  {is_completed ? (
                    ""
                  ) : (
                    <PlusIcon
                      onClick={onOpenUploader}
                      src={UploadPlusIcon}
                      alt="plus-logo"
                    />
                  )}
                  <PlusIcon
                    onClick={onOpenUploader}
                    src={UploadPlusIcon}
                    alt="plus-logo"
                  />
                </>
              )}
            </ImageUploader>
            {state.showMessage && (
              <UploadText color="red">
                {t("Image Field is required.*")}
              </UploadText>
            )}
          </div>
          <CenterAlignText>
            <UploadText color="#6B6B6B">
              {t(
                "By uploading your Organisation Logo, your profile will be more discoverable and easy to be recognised. It is advisable to not leave your logo blank"
              )}
            </UploadText>
            {is_completed ? (
              ""
            ) : (
              <>
                <UploadText color="#D04C8D">
                  {t(
                    "You can update your organisation logo at any time in the settings."
                  )}
                </UploadText>
                <SkipText
                  onClick={() => onFinish(true)}
                  color="#504F8C"
                  underline="underline"
                >
                  {t("Skip this step, I will upload it later")}
                </SkipText>
              </>
            )}
          </CenterAlignText>
          <Flexbox>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Button onClick={(e) => onFinish()} width="auto" name={t("Finish")} />

                <Button
                  onClick={onBack}
                  width="auto"
                  secondary
                  name={t("Back")}
                />
              </>
            )}
          </Flexbox>

          <Tooltip id="logoUpload" content="BMP/,JPG/JPEG/PNG file type" />
        </UploadWrapper>
      </PageWrapper>
      {openModal && (
        <UploadLogo
          open={openModal}
          onClose={onCloseModal}
          onUploadChange={pickedImage}
        />
      )}
    </UserCreationLayout>
  );
}
