import { Col} from "react-bootstrap";
import { Slider } from "@mui/material";
import styled from "styled-components";

export const OceanCardDarker = styled(Col)`
    border: 1px solid rgba(228, 228, 228, 0.26);
    border-radius: 20px;
    padding:20px;
    background: #fdfdfd;
    .slide-wrapper {
        position: relative;
    }
    overflow: hidden;
    .hid-box {
        top: 200%;
        position: absolute;
        transition: all .3s ease-out;
        height: 100%;
    }
    .hid-box.visible{
        top: 0;
        position: absolute;
    }
    .hid-box > div, .hid-box > div > div{
        height: 100%;
    }
`;

export const OceanChartHeading = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  text-align: center;
  text-transform: capitalize;
  color: #202020;
  margin-bottom:20px;
`;

export const OceanSlider = styled(Slider)`
cursor: default !important;
.Mui-active{
  pointer-events:none;
}
  .MuiSlider-thumb{
    color: ${props =>
      props.level === "low" ? "#FF0000" 
      : props.level === "moderate" ? "#ff9800"
      : props.level === "high" ? "#2e7d32"
      : "#D04C8D"};
    size:small;
    box-shadow: 0px 5px 16px rgba(180, 104, 141, 0.9);
  }
  .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover {
    box-shadow:0px 5px 16px rgba(180, 104, 141, 0.9);
    cursor: default !important;
  }
  .MuiSlider-rail{
    background: #FAFAFA;
    border: 0.2px solid #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), inset 0px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
  }
  .MuiSlider-track{
    background: ${props => 
      props.level === "low" ? "#FF0000" 
      : props.level === "moderate" ? "#ff9800"
      : props.level === "high" ? "#2e7d32"
      : "linear-gradient(180deg, #F779B6 50%, #7A1245 100%)"};
    border: 0.2px solid #FFFFFF;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), inset 0px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
  }
  .Mui-disabled {
    color:#D04C8D;
  }
  .MuiSlider-valueLabel{
    background: #D04C8D;
  }
`;

export const OceanLabel = styled.label`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #D04C8D;
  display:block;
  float:left;
  width:auto;
  padding:10px 0px;
  cursor: pointer;
`;
export const OceanDescription = styled(OceanLabel)`
  color: #787878;
`;