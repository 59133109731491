import { createSlice } from "@reduxjs/toolkit";

import { internFields } from "./staticContent";

const initialState = {
  value: internFields,
  isUpdate: false,
};

export const internDataReducer = createSlice({
  name: "intern",
  initialState,
  reducers: {
    updateValues: (state, action) => {
      state.value = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateValues } =
  internDataReducer.actions;

export default internDataReducer.reducer;
