import React from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import "./userRegistration.css";
import code from "../../assets/Group 36.png";
import Form from "react-bootstrap/Form";
import Button from "../../components/button/button";
import rightarrow from "../../assets/icons/arrow_right.png";
import { Grid } from "@material-ui/core";
import PhoneInput from "react-phone-number-input";
import ErrorField from "../errorField/errorField";
import Input from "../input/input";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { schemaNewObj } from "../../components/AddContact/staticContent";
import { useState } from "react";
import Loader from "../loader/loader";
import { onAddNewUser, getAllUsers } from "../../services/apiCalls";
import { toast } from "react-toastify";
import {
  updateUsersForSelectedCompany,
  updateSelectedUser,
} from "../../pages/userManagement/userManagerReducer";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;

  @media (max-width: 500px) {
    padding: 1rem 2rem 10rem 2rem;
    margin: 0.5rem 0;
    //height: 160vh;
  }
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin-bottom: 20px;
  margin-top: -10px;
  @media (max-width: 500px) {
    position: relative;
    top: 8px;
  }
`;
const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
  // right: 10%;
  @media (max-width: 767px) {
    margin: 50px 20px 0 -20px;
    display: flex;
    justify-content: center;
    right: 75px;
  }
  @media (min-width: 768px) and (max-width: 2560px) {
    display: flex;
  justify-content: center;
  right:100px;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  margin: 40px 0;
`;
const MediumText = styled.div`
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  width: 300px;
`;

const SmallText = styled.div`
  font-size: 16px;
`;

const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;

const UserRegistration = (props) => {
  let initialState = {
    first_name: undefined,
    last_name: undefined,
    email: undefined,
    job_title: undefined,
    mob_number: undefined,
    department: undefined,
    is_admin: "N",
    internship_view: "N",
    internship_modify: "N",
    company_view: "N",
    company_modify: "N",
    user_management_view: "N",
    user_management_modify: "N",
    view_talents: "N",
    search_talents: "N",
    view_job: "N",
    modify_job: "N",
    view_application: "N",
    modify_application: "N",
  };
  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [orgCode, setOrgCode] = useState(undefined);
  const dispatch = useDispatch();
  let org = undefined;
  const navigate = useNavigate();
  const allRoles = [
    "is_admin",
    "internship_view",
    "internship_modify",
    "company_view",
    "company_modify",
    "user_management_view",
    "user_management_modify",
    "view_talents",
    "search_talents",
    "view_job",
    "modify_job",
    "view_application",
    "modify_application",
  ];

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialState },
    resolver: yupResolver(schemaNewObj),
  });
  const { t } = useTranslation();
  const onMblNumberChange = (val) => {
    if (val) {
      setValue("mob_number", val);
      localStorage.setItem("phone_num_user", val);
    }
  };
  const formVal = watch();

  function onClose() {}

  function handleToggle(e) {
    if (e.target.id != "is_admin") {
      let val = formVal[e.target.id] == "N" ? "Y" : "N";
      setValue(e.target.id, val);
    } else {
      let overrideVal = formVal["is_admin"] == "N" ? "Y" : "N";
      for (let i = 0; i < allRoles.length; i++) {
        setValue(allRoles[i], overrideVal);
      }
    }
  }
  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name == company.name) {
          setOrgCode(companies[i].org_code);
          org = companies[i].org_code;
          return;
        }
      }
    }
  }

  const onSubmit = async () => {
    setLoading(true);
    let emailEntered = getValues("email")
    setValue("email", emailEntered.toLowerCase())
    formVal["email"] = formVal["email"].toLowerCase()
    getOrgCode();
    if (orgCode || org) {
      const { data } = await onAddNewUser(orgCode || org, { ...formVal });
      if (data && data.status === 1) {
        toast.success(t("New User Added successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getAllUsersForSelectedCompany();
        setFormErrors("");
        navigate("/my-company");
      } else {
        toast.error(data.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
    }
  };

  const getAllUsersForSelectedCompany = async () => {
    if (orgCode) {
      const { data } = await getAllUsers(orgCode);
      if (data && data.status === 1) {
        setLoading(false);
        dispatch(updateUsersForSelectedCompany({ ...data.data }));
      }
    }
    setLoading(false);
  };
 
  return (
    <MainLayout>
      <div className="breadcrumb">
        <p
          onClick={() => navigate("/my-company")}
          style={{ cursor: "pointer" }}
        >
          {t("MyCompany")} 
        </p>
        <img
          src={rightarrow}
          alt="img"
          className="rightArrow"
        />
        <p className="textOne" >{t("Add New User")}</p>
      </div>

      <PageWrapper>
        <HeaderText>{t("Add New User")}</HeaderText>
        <form>
          <div className="row">
            <div className="col-lg-4">
              <div className="title">{t("Email Address*")} </div>
              {/* <input className="userinfo w-100 ps-3" placeholder="Enter E-mail ID"
                type="email" /> */}
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <Input
                    className="inputMain"
                    {...field}
                    placeholder={t("Email")}
                  />
                )}
              />
              {errors.email && <ErrorField err={t(errors.email.message)} />}
              {/* <p className="text-danger">{errors.fullName?.message}</p> */}
            </div>

            <div className="col-lg-4">
              <div className="title">{t("First Name*")} </div>
              {/* <input className="userinfo w-100 ps-3" placeholder="Enetr First Name"
                type="text" /> */}
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("First Name")}
                  />
                )}
              />
              {errors.first_name && (
                <ErrorField err={t(errors.first_name.message)} />
              )}
            </div>

            <div className="col-lg-4">
              <div className="title">{t("Last Name* ")}</div>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("Last Name")}
                  />
                )}
              />
              {errors.last_name && (
                <ErrorField err={t(errors.last_name.message)} />
              )}
              {/* <p className="text-danger">{errors.email?.message}</p> */}
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-lg-4">
              <div className="title">{t("Job Title*")} </div>
              {/* <input className="userinfo w-100 ps-3" placeholder="Enter Job Title"
                type="text" /> */}
              <Controller
                name="job_title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("Job Title")}
                  />
                )}
              />
              {errors.job_title && (
                <ErrorField err={t(errors.job_title.message)} />
              )}
              {/* <p className="text-danger">{errors.fullName?.message}</p> */}
            </div>

            <div className="col-lg-4">
              <div className="title">{t("Department*")} </div>
              {/* <input className="userinfo w-100 ps-3" placeholder="Enetr Department" type="text" /> */}
              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t("Department")}
                  />
                )}
              />
              {errors.department && (
                <ErrorField err={t(errors.department.message)} />
              )}
              {/* <p className="text-danger">{errors.dob?.message}</p> */}
            </div>

            {/* <div className="col-lg-4">
              <div className="title">Mobile Number* </div>
              <div className="code">
                <img src={code} alt="codeimg" />
                <input className="userinfo w-100 ps-3" style={{}} placeholder="Enter Last Name" type="number"></input>

              </div>
            </div> */}
            {/* <p className="text-danger">{errors.email?.message}</p> */}

            <div className="col-lg-4">
              <div className="title">{t("Mobile Number*")} </div>
              <Controller
                name="mob_number"
                control={control}
                render={({ field }) => (
                  <PhoneInput
                    containerStyle={{ height: "48px" }}
                    inputStyle={{ height: "48px" }}
                    value={formVal.mob_number}
                    onChange={onMblNumberChange}
                    defaultCountry="MY"
                    international
                    withCountryCallingCode={true}
                    countryCallingCodeEditable={false}
                    maxLength={16}
                    minLength={12}
                  />
                )}
              />
              {errors.mob_number && (
                <ErrorField err={t(errors.mob_number.message)} />
              )}
            </div>
            {formErrors && <ErrorField err={t(formErrors)} />}
          </div>
        </form>

        <HrLine />
        <Row>
          <Col md="6">
            <HeaderText>{t("Roles")}</HeaderText>
          </Col>
          <Col md="6">
            <Form>
              <Form.Group
                className="admin"
                controlId="is_admin"
                onClick={handleToggle}
              >
                <Form.Check type="checkbox" label="Admin" className="margin" />
                <span
                  className="defaultText"
                >
                  {t("selects all by default")}
                </span>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Form>
          {/* <div className="rolesWrapper">
            <div className="roles">
              <div className=""> */}
          <Row>
            <Col>
              <MediumText>{t("Internship")}</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="internship_view"
                onClick={handleToggle}
              >
                <Form.Check
                  style={{ fontSize: "16px" }}
                  label={t("View")}
                  defaultChecked={formVal.internship_view == "Y" ? true : false}
                  checked={formVal.internship_view == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="internship_modify"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("Create/Modify")}
                  defaultChecked={
                    formVal.internship_modify == "Y" ? true : false
                  }
                  checked={formVal.internship_modify == "Y" ? true : false}
                />
              </Form.Group>
              <MediumText>{t("Company")}</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="company_view"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("View")}
                  defaultChecked={formVal.company_view == "Y" ? true : false}
                  checked={formVal.company_view == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="company_modify"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("Create/Modify")}
                  defaultChecked={formVal.company_modify == "Y" ? true : false}
                  checked={formVal.company_modify == "Y" ? true : false}
                />
              </Form.Group>
              <MediumText>{t("User Management")}</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="user_management_view"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("View")}
                  defaultChecked={
                    formVal.user_management_view == "Y" ? true : false
                  }
                  checked={formVal.user_management_view == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="user_management_modify"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("Manage User")}
                  defaultChecked={
                    formVal.user_management_modify == "Y" ? true : false
                  }
                  checked={formVal.user_management_modify == "Y" ? true : false}
                />
              </Form.Group>
            </Col>
            <Col>
              <MediumText className=" ">{t("Talent Search")}</MediumText>
              <Form.Group
                className="groupLetter"
                controlId="view_talents"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("View Talents")}
                  defaultChecked={formVal.view_talents == "Y" ? true : false}
                  checked={formVal.view_talents == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="search_talents"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("Search for Talents")}
                  defaultChecked={formVal.search_talents == "Y" ? true : false}
                  checked={formVal.search_talents == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="view_job"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("View Job Descriptions")}
                  defaultChecked={formVal.view_job == "Y" ? true : false}
                  checked={formVal.view_job == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="modify_job"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("Modify Job Descriptions")}
                  defaultChecked={formVal.modify_job == "Y" ? true : false}
                  checked={formVal.modify_job == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="view_application"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("View Applicants")}
                  defaultChecked={
                    formVal.view_application == "Y" ? true : false
                  }
                  checked={formVal.view_application == "Y" ? true : false}
                />
              </Form.Group>
              <Form.Group
                className="groupLetter"
                controlId="modify_application"
                onClick={handleToggle}
              >
                <Form.Check
                  type="checkbox"
                  label={t("Manage Applicants")}
                  defaultChecked={
                    formVal.modify_application == "Y" ? true : false
                  }
                  checked={formVal.modify_application == "Y" ? true : false}
                />
              </Form.Group>
              {/* </div> */}
            </Col>
          </Row>
          <Row>
            <ButtonWrapper>
              <Button
                name={t("Submit")}
                onClick={handleSubmit(onSubmit)}
                className="button-mobile"
              />
            </ButtonWrapper>
          </Row>
        </Form>
      </PageWrapper>
    </MainLayout>
  );
};

export default UserRegistration;
