import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";



function UploadError(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.close} backdrop={props.backdropMode == "error" ? "false" : "static"} centered style={{ // Custom styles for the modal
                    backgroundColor: "rgba(0, 0, 0, 0.5)", // Dark translucent background
                    backdropFilter: "blur(3px)" // Optional: Adds a blur effect to the background
                }}>
                <div style={{ padding: '9px', }}>
                    <ModalHeader></ModalHeader>
                    <ModalHeaderText>{props.title}</ModalHeaderText>
                    <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
                    {props.content}
                </div>
            </Modal>
        </div>
    );
}

export default UploadError;
