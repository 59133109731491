import React from "react";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { Form, Row, Col, Image } from "react-bootstrap";
import edit from "../../assets/Vector.png";
import { ReactComponent as GreenCircle } from "../../assets/svg/greenCircle.svg";
import { ReactComponent as RedCircle } from "../../assets/svg/redCircle.svg";
import { ReactComponent as RightIcon } from "../../assets/svg/check_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross_icon.svg";
import { ReactComponent as ViewIcon } from "../../assets/svg/view.svg";
import { ReactComponent as GreenViewIcon } from "../../assets/svg/greenview.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/trash.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/pencil.svg";
import { ReactComponent as ReminderIcon } from "../../assets/svg/reminder.svg";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import moment from "moment";
import Button from "../../components/button/button";
import { Grid } from "@material-ui/core";
import AlertModal from "../../components/alertModal/alertModal";
import {
  Heading,
  LoaderBackdrop,
  LoaderWrapper,
} from "../employeeManagement/commonStyles";

import dummyApi from '../MySIPDirectHiring/dummyApiLoe';
import { borderColor, padding } from "@mui/system";
import { ConvertDate } from "../employeeManagement/utils/date-converter";
import ReactTooltip from "react-tooltip";
import { ReactComponent as StudentRing } from "../../assets/svg/studentRing.svg";
import { ReactComponent as StudentDownload } from "../../assets/svg/studentDownload.svg"

import { ReactComponent as StudentShare } from "../../assets/svg/studentShare.svg";
// import ReactExport from "react-export-excel";
import { sendSingleReminder,sendMultipleReminder, getUpdateDate } from "../../services/apiCalls";
import { async } from "q";
import { getInternlistDownload ,getApplicantDetails} from "../../services/apiCalls";
import EditDateModal from "../../components/editDateModal/editDateModal";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import DateFnsUtils from "@date-io/date-fns";
import { formatDate } from "../../utilities/commonFunctions";
import ErrorField from "../../components/errorField/errorField";
import { createTheme } from "@material-ui/core";
import { useLocation, useNavigate } from "react-router-dom";
import {  useForm, Controller  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { initialState, schema } from "./staticContent";
import InternShipDetails from "../../components/internShipDetails/internShipDetails";
import {
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
} from "../../services/apiCalls";
import Input from "../../components/input/input";
import Dropdown from "../../components/dropdown/dropdown";
import { differenceInWeeks, differenceInDays } from 'date-fns';

import * as FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});
const InternDetailsHeader = styled.th`
  padding: 10px;
  color: ${Colors.pink};

  
`;

const InternDetailsTable = styled.table`
  width: 100%;
  background-color: ${Colors.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  
  th {
    background-color: #f2f2f2;
    border-bottom: 20px solid #ddd; /* Add a border line after the <thead> */
    padding: 8px;
    text-align: left;
  }
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
`;

const InternDetailsRow = styled.tr`
  border-bottom: 1px solid ${Colors.lightGreyBorder};

  &:last-child {
    border-bottom: none;
  }
`;

const InternDetailsCell = styled.td`
  padding: 10px;
`;

const TableButtons = styled.div`
  display: flex;
  gap: 10px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;

const CustomHeading = styled.h2`
  color: ${Colors.pink};
`;

const InternDetailsWrapper = styled.div`

  margin-bottom: 20px;
  padding: 20px;
  // border: 1px solid ${Colors.lightGreyBorder};
  background-color: ${Colors.white};
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
`;

const Icon = styled.img`
  margin: auto;
  cursor: pointer;
  display: block;
`;

const customStyles = {

  style: {
    fontSize: "16px",
    width: "1px",
    overflow: "scroll",
  },

};

//Data table styles
export const DataTableWrapper = styled.div`

  background: #ffffff;

  position: relative;

  border-radius: 34px;

  overflow: hidden;

  margin-bottom: 3rem;

  margin-top: 12px;

  .rdt_TableHead .rdt_TableCol_Sortable div {

    font-size: medium;

    font-weight: 600;

  }

  .custom-selector {

    cursor: pointer !important;

    div[id*="option"] {

      cursor: pointer;

      &:hover {

        background-color: #f3f0f0 !important;

      }

    }

  }

  .rdt_TableHead {

    border-radius: 34px;

    height: 83px;

    box-shadow: 0px 0px 10px #d9d9d9;

    overflow: hidden;

    z-index: 0;

    position: relative;

    margin-bottom: 15px;

    .rdt_TableHeadRow {

      // margin: 0 -0.5rem;

    }

 

    .rdt_TableCol_Sortable,

    rdt_TableCol {

      font-family: General Sans;

      white-space: pre-line !important;

      font-style: normal;

      font-weight: 500;

      font-size: 18px;

      line-height: 108%;

      color: #d04c8d;

      div {

        white-space: pre-line !important;

      }

    }

  }

  .rdt_TableBody {

    border-radius: 0px 0px 34px 34px;

    overflow: hidden;

    position: relative;

    align-items: flex-start;

    .rdt_TableRow {

      cursor: pointer;

      margin-left: 31px;

      &:hover {

        background-color: #ffe9f4;

      }

    }

  }

  @media (max-width: 500px) {

    position: relative;

    margin-top: 64px;

    .rdt_TableBody {

      border-radius: 0px 0px 34px 34px;

      // overflow: scroll;

 

      .rdt_TableRow {

        cursor: pointer;

        margin-left: 33px;

        &:hover {

          background-color: #ffe9f4;

        }

      }

    }

  }

  @media (min-width: 821px) and (max-width: 1024px) {

   margin-top:90px;

  }

  @media (min-width: 768px) and (max-width: 1100px) {

    .rdt_TableHeadRow {

      margin: 0 1.5rem;

    }

    .rdt_TableBody {

      border-radius: 0px 0px 34px 34px;

      // overflow: scroll;

      position: relative;

      align-items: self-end .rdt_TableRow {

        cursor: pointer;

        &:hover {

          background-color: #ffe9f4;

        }

      }

    }

  }

`;
const columnStylingBigger = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "200px",
  color: "#202020",
  margin: " 1.5rem 0",
  textAlign: "left",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",

  },

};

const IconImage = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 15px 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : props.alignStart ? "flex-start" : props.spaceBetween ? "space-between" : "space-around")};
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;

const columnStyling = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 18,
  lineHeight: "24px",
  minWidth: "92px",
  color: "#202020",
  margin: " 0.5rem 1.5rem 0.5rem 0",
  justifyContent: "center",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },

};


const TableContent = styled.h4`
font-style: normal,
font-size: 12px;

`;
const TableButtonsNew = styled.div`
  display: flex;
`;

const FormWrapper = styled.div`
  margin: 25px 0px;
  padding: 25px 70px 29px 63px;
  background: #ffffff;
  border-radius: 10px;
  @media (max-width: 767px) {
    padding: 25px 8px 29px 8px;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0px;
    color: ${Colors.labelText};
  }
  > p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
  > p2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;

const TitleSection = styled.div`
margin-top: 10px;
padding: 9px;
`;
const TitleDescription = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;
// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const InternDetails = ({fromInternship,interns,internshipID, isAdvertised}) => {
  const [internInformation, setInternInformation] = useState([]);
   const [getInternlistDownload1, setGetInternListDownload1]= useState([]);
   const [showModal, setShowModal] = useState(false);
   const [fromDate, setFromDate] = useState("");
   const [toDate, setToDate] = useState("");
   const [selectedEditDate, setSelectedEditDate] = useState();

   const [selectedRowData, setSelectedRowData] = useState(null);


  const  [errorField,setErrorField] = useState(false);
  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedOccupation,setSelectedOccupation] = useState([]);
  const [selectedVulnarableGroup, setVulnarableGroup] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);
  const [selectedCourse,setSelectedCourse]=useState([]);
  const [selectedStudentRawData,setStudentRawData] = useState();
  const {
    control,
    watch,
    reset: resetForm,
    setValue,
    handleSubmit,
    formState: { errors },
    clearErrors
  } = useForm({
    defaultValue: { ...initialState },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  console.log("This is internshipid",internshipID,fromInternship);
  useEffect(() => {
    dummyApi.getInterships()
    console.log("This is internshipid",internshipID);
    downloadInternList(internshipID)
    
    
    
      .then((data) => {
        const marketingInternship = data.internshipModule.find(internship => internship.name === "Marketing");
        if (marketingInternship) {
          setInternInformation(marketingInternship.internInformation);
        } else {
          setInternInformation([]);
        }
      })
      .catch((error) => {
        console.error("Error fetching internships:", error);
      });
     getApplicantData();

     interns.forEach(intern => {
      setFromDate(formatDate(intern.internship_start_date))
      setValue("internship_start_date", formatDate(intern.internship_start_date))
      setToDate(formatDate(intern.internship_end_date));
      setValue("internship_end_date", formatDate(intern.internship_end_date))
  },[interns]);
  },[]);
  
  
  const [show, setShow] = React.useState(false);
  const [fullName, setFullName] = React.useState();
  const dateChange = (name, val) => {
    if (name === "fromDate") {
    const selectedStartDate = formatDate(val);
    setFromDate(selectedStartDate);
    setValue("internship_start_date",selectedStartDate);
    
    // Calculate the end date to be 10 weeks (70 days) after the selected start date
    const endDate = new Date(val);
    endDate.setDate(endDate.getDate() + 70);
    setToDate(formatDate(endDate));
  }
  if (name === "toDate") {
    const formattedDate = formatDate(val);
    setToDate(formattedDate);
    setValue("internship_end_date", formattedDate);
  }
  };
  const calculateDuration = () => {
    if (fromDate && toDate) {
      const durationInDays = differenceInDays(new Date(toDate), new Date(fromDate));
      const durationInWeeks = Math.floor(durationInDays / 7);
      const remainingDays = durationInDays % 7;
      return `${durationInWeeks} weeks ${remainingDays} days`;
    }
    return '';
  };

  const dateChange1 = (name, val) => {
    setValue(name, formatDate(val));
    if(name === 'date_of_hiring'){
      errors.date_of_hiring =''
    }
    if(name === "fromDate"){
      setValue(
        "internship_start_date",
        moment(new Date(val).setDate(new Date(val).getDate() + 70)).format(
          "YYYY-MM-DD"
        )
      );
    }
    if(name === "toDate"){
      setValue("internship_end_date", moment(new Date(val).setDate(new Date(val).getDate())).format(
        "YYYY-MM-DD"
      ))
    }
    
  };

  const formVal = watch();
  const getcountryList = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Countrylist = data.data;
        const country = Countrylist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCountry(country);
        if (formVal.country) {
          let selCountry = country.filter((x) => x.value === formVal.country);
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          setSelectedCountry(selCountry);
          getState(selCountry.value);
        }
      }
    }
  };
console.log(states,"COUNTRY")
  const getState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const Stateslist = data.data;
        const state = Stateslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setStates(state);
        if (formVal.state) {
          let selStates = state.filter((x) => x.value === formVal.state);
          if (selStates.length > 0) {
            selStates = selStates[0];
            setValue("state", selStates.value);
          }
          setSelectedState(selStates);
          // fetchCampus(selStates.value);
        }
      }
    }
  };
  const getCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        console.log("citiesCity",cities)
        setCities(cities);

        if (formVal.city) {
          let selcities = cities.filter((x) => x.value === formVal.city);
          if (selcities.length > 0) {
            selcities = selcities[0];
            setValue("city", selcities.value);
          }
          setSelectedCity(selcities);
          // fetchCampus(selStates.value);
        }
      }
    }
  };

  useEffect(()=>{
    setSelectedCountry({ label: "Malaysia", value: 132 });
    getcountryList();
    getState(132);
  },[])
  const ClosePopup = () => {
    setShow(false);
  };

 
  const dropdownChange = (name, val) => {
    switch (name) {
      case "vulnerable_group":
        setValue(name, val.label);
        setVulnarableGroup(val);
        errors.vulnerable_group= ""
        break;

      case "course":
        setValue(name, val.label);
        setSelectedCourse(val);
        errors.course= ""
        break;

        case "state":
        { console.log("state is being called", val) }
        setValue(name, val.label);
        setSelectedState(val);
        getCity(selectedCountry.value, val.value);
        errors.state= ""
        break;
      case "city":
        setValue(name, val.label);
        setSelectedCity(val);
        errors.city= ""
        break;
    }
  }
  const onClose =()=>{
    setShowModal(false);
    clearErrors();
    // setToDate("");
    // setFromDate("");
    }
    const delay = ms => new Promise(
      resolve => setTimeout(resolve, ms)
    );
    const handleRowSelectRow = async (data) => {
      if (data.state != "") {
        const selectedState = states.filter(item => item.Name === data.state)[0];
        const stateLabel = selectedState ? selectedState.label : ""; // Check if selectedState is defined
        setValue('state', stateLabel);
        setSelectedState(selectedState);
        getCity(selectedCountry.value, selectedState?.value); // Use optional chaining to access value property
        // await delay(200);
// if(selectedState && data.city != ""){
//   console.log("citieshandle",cities);
//   // console.log("formval",formVal);
//   const selectedCity = cities.filter(item => item.Name === data.city)[0];
//   setSelectedCity(selectedCity);
//   setValue('city',selectedCity);
// }
      }
      if (data.vulnerable_group != "") {
        const selectedVulnerable = vulnerableGroup.filter(item => item.label === data.vulnerable_group)[0];
        const vulnerableLabel = selectedVulnerable ? selectedVulnerable.label : ""; // Check if selectedVulnerable is defined
        setVulnarableGroup(selectedVulnerable);
        setValue('vulnerable_group', vulnerableLabel);
      }
      
      if(data.date_of_hiring != ""){
        setValue("date_of_hiring",data.date_of_hiring)
      }
      if (data.course != "") {
        const selectedCourse = courseList.filter(item => item.label === data.course)[0];
        const courseLabel = selectedCourse ? selectedCourse.label : ""; // Check if selectedCourse is defined
        setSelectedCourse(selectedCourse);
        setValue('course', courseLabel);
      }
      

      if(data.internship_start_date != ""){
        setFromDate(data.internship_start_date);
        setValue('internship_start_date',data.internship_start_date)
      }
      if(data.internship_end_date != ""){
        setToDate(data.internship_end_date)
        setValue("internship_end_date",data.internship_end_date);
      }
      if(data.address_1 != ""){
        setValue('address_1',data.address_1)
      }
      if(data.address_2 != ""){
        setValue('address_2',data.address_2)

      }
      if(data.postal_code != ""){
        setValue('postal_code',data.postal_code)

      }

      if(data.total_training_expenses != ""){
        setValue('total_training_expenses',data.total_training_expenses)
      }

      if(data.total_meal_expenses != ""){
        setValue('total_meal_expenses',data.total_meal_expenses)
      }

      if(data.total_accomodation_expenses != ""){
        setValue('total_accomodation_expenses',data.total_accomodation_expenses)
      }

      if(data.total_travelling_expenses != ""){
        setValue('total_travelling_expenses',data.total_travelling_expenses)
      }

      if(data.total_digital_communication_expenses != ""){
        setValue('total_digital_communication_expenses',data.total_digital_communication_expenses)
      }

      if(data.internship_allowance_per_month != ""){
        setValue('internship_allowance_per_month',data.internship_allowance_per_month)
      }
  

      if(data.total_internship_allowance_in_programme != ""){
        setValue('total_internship_allowance_in_programme',data.total_internship_allowance_in_programme)
      }

      if (data.academic_qualification !== "") {
        setValue("academic_qualification", data.academic_qualification);
      }
  
    }

    useEffect(()=>{
      console.log("citieshandleUseEffect",cities,selectedStudentRawData);

if(selectedStudentRawData){
  if (selectedStudentRawData.city != "") {
    console.log("citieshandle", cities);
    const selectedCity = cities.filter(item => item.Name === selectedStudentRawData.city)[0];
    const cityLabel = selectedCity ? selectedCity.label : ""; // Check if selectedCity is defined
    setSelectedCity(selectedCity);
    setValue('city', cityLabel);
  }
  
}
    },[cities])
  function calculateInternshipDurationInMonths(startDate, endDate) {
    const start = new Date(startDate);
    const end = new Date(endDate);
   console.log("startDate",startDate,"endDate",endDate)
    const months = (end.getFullYear() - start.getFullYear()) * 12 + (end.getMonth() - start.getMonth());
  
    if (months === 1) {
      return '1 Month';
    } else if (months > 1) {
      return `${months} Months`;
    } else {
      return 'Invalid Date Range';
    }
  }

  const vulnerableGroup =[
    {value:1, label:"Youth"},
    {value:2, label:"OKU"},
    {value:3, label:"Not Applicable"}
  ]

  const courseList = [
    { value: 1, label: "Generic programmes and qualifications not further defined" },
    { value: 2, label: "Basic programmes and qualifications" },
    { value: 3, label: "Literacy and numeracy" },
    { value: 4, label: "Personal skills" },
    { value: 5, label: "Generic programmes and qualifications not elsewhere classified" },
    { value: 6, label: "Education not further defined" },
    { value: 7, label: "Education science" },
    { value: 8, label: "Training for pre-school teachers" },
    { value: 9, label: "Educator training without subject specialisation" },
    { value: 10, label: "Educator training with subject specialisation" },
    { value: 11, label: "Programmes and qualifications involving education not elsewhere classified" },
    { value: 12, label: "Inter-disciplinary programmes and qualifications involving education" },
    { value: 13, label: "Arts and humanities not further defined" },
    { value: 14, label: "Arts not further defined" },
    { value: 15, label: "Audio-visual techniques and media production" },
    { value: 16, label: "Fashion, interior and industrial design" },
    { value: 17, label: "Fine arts" },
    { value: 18, label: "Craft skills" },
    { value: 19, label: "Music and performing arts" },
    { value: 20, label: "Film, television and screen studies" },
    { value: 21, label: "Arts not elsewhere classified" },
    { value: 22, label: "Humanities (except languages) not further defined" },
    { value: 23, label: "Religion and theology" },
    { value: 24, label: "History and archaeology" },
    { value: 25, label: "Philosophy and ethics" },
    { value: 26, label: "Islamic studies" },
    { value: 27, label: "Humanities (except languages) not elsewhere classified" },
    { value: 28, label: "Languages not further defined" },
    { value: 29, label: "Language acquisition" },
    { value: 30, label: "Literature and linguistics" },
    { value: 31, label: "National language" },
    { value: 32, label: "Languages not elsewhere classified" },
    { value: 33, label: "Inter-disciplinary programmes and qualifications involving arts and humanities" },
    { value: 34, label: "Arts and humanities not elsewhere classified" },
    { value: 35, label: "Social sciences, journalism and information not further defined" },
    { value: 36, label: "Social and behavioural sciences not further defined" },
    { value: 37, label: "Economics" },
    { value: 38, label: "Social Sciences and Public Policy" },
    { value: 39, label: "Psychology" },
    { value: 40, label: "Sociology and cultural studies" },
    { value: 41, label: "Social and behavioural sciences not elsewhere classified" },
    { value: 42, label: "Journalism and information not further defined" },
    { value: 43, label: "Journalism and reporting" },
    { value: 44, label: "Library, information and archival studies" },
    { value: 45, label: "Media and communication" },
    { value: 46, label: "Journalism, information and communication not elsewhere classified" },
    { value: 47, label: "Inter-disciplinary programmes and qualifications involving social sciences, journalism and information" },
    { value: 48, label: "Social sciences, journalism and information not elsewhere classified" },
    { value: 49, label: "Business, administration and law not further defined" },
    { value: 50, label: "Business and administration not further defined" },
    { value: 51, label: "Accounting, auditing and taxation" },
    { value: 52, label: "Finance, banking and insurance" },
    { value: 53, label: "Islamic banking and finance" },
    { value: 54, label: "Management and administration" },
    { value: 55, label: "Marketing and advertising" },
    { value: 56, label: "Secretarial and office studies" },
    { value: 57, label: "Work skills" },
    { value: 58, label: "Business and administration not elsewhere classified" },
    { value: 59, label: "Law" },
    { value: 60, label: "Inter-disciplinary programmes and qualifications involving business, administration and law" },
    { value: 61, label: "Business, administration and law not elsewhere classified" },
    { value: 62, label: "Natural sciences, mathematics and statistics not further defined" },
    { value: 63, label: "Biological and related sciences not further defined" },
    { value: 64, label: "Biology" },
    { value: 65, label: "Biochemistry" },
    { value: 66, label: "Biological and related sciences not elsewhere classified" },
    { value: 67, label: "Environment not further defined" },
    { value: 68, label: "Environmental sciences" },
    { value: 69, label: "Natural environments and wildlife" },
    { value: 70, label: "Environment not elsewhere classified" },
    { value: 71, label: "Physical sciences not further defined" },
    { value: 72, label: "Chemistry" },
    { value: 73, label: "Earth sciences" },
    { value: 74, label: "Physics" },
    { value: 75, label: "Physical sciences not elsewhere classified" },
    { value: 76, label: "Mathematics and statistics not further defined" },
    { value: 77, label: "Mathematics" },
    { value: 78, label: "Statistics" },
    { value: 79, label: "Inter-disciplinary programmes and qualifications involving natural sciences, mathematics and statistics" },
    { value: 80, label: "Natural sciences, mathematics and statistics not elsewhere classified" },
    { value: 81, label: "Information and communication technologies (ICTs) not further defined" },
    { value: 82, label: "Information technology and information system" },
    { value: 83, label: "Software engineering" },
    { value: 84, label: "Computer science" },
    { value: 85, label: "Computer use" },
    { value: 86, label: "Information and communication technologies (ICTs) not elsewhere classified" },
    { value: 87, label: "Inter-disciplinary programmes and qualifications involving information and" },
    { value: 88, label: "communication technologies" },
    { value: 89, label: "Engineering, manufacturing and construction not further defined" },
    { value: 90, label: "Engineering and engineering trades not further defined" },
    { value: 91, label: "Chemical engineering and processes" },
    { value: 92, label: "Electricity and energy" },
    { value: 93, label: "Electronics and automation" },
    { value: 94, label: "Mechanics and metal trades" },
    { value: 95, label: "Motor vehicles, ships and aircraft" },
    { value: 96, label: "Civil engineering" },
    { value: 97, label: "Environmental protection technology" },
    { value: 98, label: "Engineering and engineering trades not elsewhere classified" },
    { value: 99, label: "Manufacturing and processing not further defined" },
    { value: 100, label: "Food processing" },
    { value: 101, label: "Materials (glass, paper, plastic and wood)" },
    { value: 102, label: "Textiles (clothes, footwear and leather)" },
    { value: 103, label: "Mining and extraction" },
    { value: 104, label: "Manufacturing and processing not elsewhere classified" },
    { value: 105, label: "Architecture, surveying and town planning not further defined" },
    { value: 106, label: "Architecture" },
    { value: 107, label: "Urban and regional planning" },
    { value: 108, label: "Building and construction" },
    { value: 109, label: "Surveying" },
    { value: 110, label: "Engineering technology" },
    { value: 111, label: "Inter-disciplinary programmes and" },
    { value: 112, label: "qualifications involving engineering, manufacturing and construction" },
    { value: 113, label: "Engineering, manufacturing and construction not elsewhere classified" },
    { value: 114, label: "Agriculture, forestry, fisheries and veterinary not further defined" },
    { value: 115, label: "Agriculture not further defined" },
    { value: 116, label: "Crop and livestock production" },
    { value: 117, label: "Horticulture" },
    { value: 118, label: "Agriculture not elsewhere classified" },
    { value: 119, label: "Forestry" },
    { value: 120, label: "Fisheries" },
    { value: 121, label: "Veterinary" },
    { value: 122, label: "Inter-disciplinary programmes and qualifications involving agriculture, forestry, fisheries and veterinary" },
    { value: 123, label: "Agriculture, forestry, fisheries and veterinary not elsewhere classified" },
    { value: 124, label: "Health and welfare not further defined" },
    { value: 125, label: "Health not further defined" },
    { value: 126, label: "Dental studies" },
    { value: 127, label: "Medicine" },
    { value: 128, label: "Nursing, midwifery and medical assistance" },
    { value: 129, label: "Medical diagnostic and treatment technology" },
    { value: 130, label: "Therapy and rehabilitation" },
    { value: 131, label: "Pharmacy" },
    { value: 132, label: "Traditional and complementary medicine and therapy" },
    { value: 133, label: "Health not elsewhere classified" },
    { value: 134, label: "Welfare not further defined" },
    { value: 135, label: "Care of elderly and of disabled adults" },
    { value: 136, label: "Child care and youth services" },
    { value: 137, label: "Social work and counselling" },
    { value: 138, label: "Welfare not elsewhere classified" },
    { value: 139, label: "Inter-disciplinary programmes and qualifications involving health and welfare" },
    { value: 140, label: "Health and welfare not elsewhere classified" },
    { value: 141, label: "Services not further defined" },
    { value: 142, label: "Personal services not further defined" },
    { value: 143, label: "Domestic services" },
    { value: 144, label: "Hair and beauty services" },
    { value: 145, label: "Hotel, restaurants and catering" },
    { value: 146, label: "Sports" },
    { value: 147, label: "Travel, tourism and leisure" },
    { value: 148, label: "Personal services not elsewhere classified" },
    { value: 149, label: "Hygiene and occupational health services not further defined" },
    { value: 150, label: "Community sanitation" },
    { value: 151, label: "Occupational health and safety" },
    { value: 152, label: "Hygiene and occupational health services not elsewhere classified" },
    { value: 153, label: "Security services not further defined" },
    { value: 154, label: "Military and defence" },
    { value: 155, label: "Protection of persons and property" },
    { value: 156, label: "Security services not elsewhere classified" },
    { value: 157, label: "Transport services" },
    { value: 158, label: "Inter-disciplinary programmes and qualifications involving services" },
    { value: 159, label: "Services not elsewhere classified" },
    { value: 160, label: "Field unknown" }
  ]

  const submitForm = async ()  => {

    console.log(formVal,"This is fromVal")
      const {data} = await getUpdateDate({
        formdata: formVal,
        peram: selectedEditDate,
      });
      console.log("dateUpated", data);

      if (data.status === 1) {
        interns.map((item, index) => {
          if (item.internship_apply_id === selectedEditDate) {
            item.internship_start_date = fromDate;
            item.internship_end_date = toDate;
          }
        });
        // setToDate("");
        // setFromDate("");
        setErrorField(false);
        onClose();
        window.location.reload();
        toast.success("Intern Details Updated Successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
   
  }
  const calculateWeeksAndDays = (start_Date, end_date) => {
    const startDate = new Date(start_Date);
    const endDate = new Date(end_date);

    const timeDifference = endDate - startDate;
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
console.log("dausDiff",daysDifference)
    const totalWeeks = Math.floor(daysDifference / 7);
    const remainingDays = daysDifference % 7;

    return { totalWeeks, remainingDays };
  };

  // function calculateIntershipDurationsInWeek(duration){
    
  // }
  const excelColumn = [

    {
      name: "Name",
      selector: (row) => row.full_name,
    },
    {
      name: "IC Number",
      selector: (row) => row.id_number,
    },
    {
      name: "Higher Learning/TVET Institutions",
      selector: (row) => row.educational_institution,
    },
    {
      name: "Academic Qualification",
      selector: (row) => row.academic_qualification,
    },
    {
      name: "Internship Allowance per month (RM)",
      selector: (row) => row.internship_allowance_per_month,
    },
    {
      name: "Expected Start Date",
      selector: (row) =>
        ["-", "", null].includes(row.internship_start_date)
          ? "-"
          : ConvertDate(row.internship_start_date, "dd/mm/yyyy"),
    },
    {
      name: "Expected End Date",
      selector: (row) =>
        ["-", "", null].includes(row.internship_end_date)
          ? "-"
          : ConvertDate(row.internship_end_date, "dd/mm/yyyy"),
    },
    {
      name: "Duration",
      selector: (row) =>  {
        const { totalWeeks, remainingDays } = calculateWeeksAndDays(row.internship_start_date, row.internship_end_date);
        return `${totalWeeks} weeks ${remainingDays} days`;
    },
    },
    {
      name: "mynext Registration",
      selector: (row) => row.is_registered,
    },
    {
      name: "MySIP Eligibility",
      selector: (row) => row.is_eligible,
    },
   
  ];


  const column = [

    {
      name: "Full Name/ Email/ IC Number/ Academic Qualification",
      selector: (row) => row.internName + row.internNRIC + row.email,
      center: true,
      width: "220px",
      style:columnStylingBigger,
      cell: (row, index) => {
        return (
          <>
            <Row
              style={{
                position: "center",
                left: "29px" }}
              // onClick={() => onRowClicked(row)}
            >
              <Col lg="">
                <span
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "0px",
                    color: "#D04C8D",
                    fontSize: "14px" }}
                >
                  {row.full_name}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{row.email}</span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{row.id_number}</span>
              </Col>

              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{row.academic_qualification}</span>
              </Col>
            </Row>
            <br />
          </>
        );
      },
    },
    // {
    //   name: "Higher Learning /TVET Institutions /Academic Qualification",
    //   selector: (row)=> row.institution + row.qualification,
    //   center: true,
    //   center: true,
    //   style:columnStylingBigger,
    //   width: "250px",
    //   cell: (row, index) => {
    //     return (
    //       <>
    //         <Row
    //           style={{
    //             position: "center",
    //             left: "29px"  }}
    //           // onClick={() => onRowClicked(row)}
    //         >
    //           <Col lg="">
    //             <span
    //               style={{
    //                 textTransform: "capitalize",
    //                 marginLeft: "0px",
    //                 color: "#D04C8D",
    //                 fontSize: "14px" }}
    //             >
    //               {row.educational_institution}
    //             </span>
    //           </Col>
    //           <Col
    //             lg="12"
    //             style={{
    //               color: "#BCBCBC",
    //               fontSize: "14px",
    //               fontStyle: "italic",
    //             }}
    //           >
    //             <span>{row.academic_qualification}</span>
    //           </Col>
    //         </Row>
    //         <br />
    //       </>
    //     );
    //   },
    // },

    // {
    //   name: "Allowance Per Month(RM)",
    //   selector: (row)=> row.allowancePerMonth,
    //   style:columnStyling,
    //   center: true,
    //   cell: (row) =>{
    //     return(
    //       <span>RM 2500</span>
    //     )
    //   }
      
    // },
    

    {
      name: "Start Date",
      selector: (row) =>
      ["-", "", null].includes(row.internStartDate)
        ? "-"
        : ConvertDate(row.internship_start_date, "dd/mm/yyyy"),
      sortable: true,
      style:columnStyling,
      center: true,
      cell: (row) => {
        return (
          <>
          {
            ["-", "", null].includes(row.internStartDate)
            ? <span style={{paddingRight:"10px"}}>{"-"}</span> 
            :  <span style={{paddingRight:"10px"}}>{ConvertDate(row.internship_start_date, "dd/mm/yyyy")}</span> 

          }
          {/* <span style={{paddingRight:"10px"}}>{row.internship_start_date}</span> */}
          {/* {
         fromInternship ==="Direct Hiring" ?
        <></>:  <Image
        onClick={()=>{
          setShowModal(true);
          setSelectedEditDate(row.internship_apply_id)

        }}
        src={edit}></Image>} */}
          </>
          
        )
      }
    },

    {
      name: "End Date",
      selector: (row) =>
      ["-", "", null].includes(row.internEndDate)
        ? "-"
        : ConvertDate(row.internship_end_date, "dd/mm/yyyy"),
      sortable: true,
      style:columnStyling,
      center: true,
      cell: (row) => {
        return (
          <>
          {
            ["-", "", null].includes(row.internEndDate)
            ? <span style={{paddingRight:"10px"}}>{"-"}</span> 
            :  <span style={{paddingRight:"10px"}}>{ConvertDate(row.internship_end_date, "dd/mm/yyyy")}</span> 

          }
          {/* <span style={{paddingRight:"10px"}}>{row.internship_start_date}</span> */}
         {/* {
          fromInternship ==="Direct Hiring" ?
        <></>:  <Image
        onClick={()=>{
          setShowModal(true);
          setSelectedEditDate(row.internship_apply_id)

        }}
        src={edit}></Image>} */}
          </>
          
        )
      }
    },
    {
      name: "Duration",
      selector: (row)=> row.duration,
      center: true,
      style:columnStyling,
      cell: (row)=>{
        var datesMaps = calculateWeeksAndDays(row.internship_start_date, row.internship_end_date);
        return(
          <span>{datesMaps.totalWeeks > 0 ? `${datesMaps.totalWeeks} Weeks` : ""} {datesMaps.remainingDays > 0 ? `${datesMaps.remainingDays} Days` : ''}</span>
          // <span>{calculateInternshipDurationInMonths(row.internship_start_date, row.internship_end_date)}</span>
        )
      }
    },
    {
      name: "Monthly Allowance",
      selector: (row)=> row.internship_allowance_per_month,
      style:columnStyling,
      center: true,
      cell: (row) =>{
        return(
          <span>{row.internship_allowance_per_month}</span>
        )
      }
      
    },
    {
      name: "Total Allowance in programme",
      selector: (row)=> row.total_internship_allowance_in_programme,
      style:columnStyling,
      center: true,
      cell: (row) =>{
        return(
          <span>{row.total_internship_allowance_in_programme}</span>
        )
      }
      
    },
    {
      name: "MyNext Talent Registration",
      selector: (row)=> row.is_registered,
      center: true,
      cell: (row, index) => {
        return (
          <>
            {/* <Row
              style={{
                position: "absolute",
                left: "0" }}
              // onClick={() => onRowClicked(row)}
            >
              <Col lg=""> */}
                <span
                  style={{
                    textTransform: "capitalize",
                    marginLeft: "0px",
                    color: "#D04C8D",
                    position: "absolute",
                    fontSize: "14px" }}
                >
                  {row.is_registered ===1? (<RightIcon width={"25px"}/>) :( <CrossIcon width={"25px"}/>)}
                </span>
              {/* </Col>
            </Row> */}
            <br />
          </>
        );
        },
    },
    {
      name: "Action",
      selector: (row)=> row.action,
      style:columnStyling,
      center:true,
      cell: (row,index)=>{
        return(
          <TableButtonsNew>
            {fromInternship ==="Direct Hiring"? 
            <><IconImage data-tip="Send Registration Reminder"><StudentRing onClick={()=>{sendSingleRegistrationReminder(internshipID,row)}}/></IconImage>
            <IconImage data-tip="Send Assessments Reminder" style={{paddingTop:"7px"}} ><StudentShare onClick={()=>{sendSingleAssessmentReminder(internshipID,row)}}/></IconImage>
             </>: "" }
            {
          fromInternship ==="Direct Hiring" ?
        <></>:  <Image
        onClick={()=>{
          console.log("rowData",row)
          setStudentRawData(row);
          handleRowSelectRow(row);
          setShowModal(true);
          setSelectedEditDate(row.internship_apply_id);

        }}
        src={edit}></Image>}
          </TableButtonsNew>
        
          
        )
      }
    },
    // {
    //   name: "Eligible Interns",
    //   selector: (row)=> row.eligibleInterns,
    //   center: true,
    //   style: columnStyling,
    // },

    // {
    //   name: "Internship Evaluation",
    //   selector: (row)=> row.internshipEvaluation,
    //   left: true,
    //   style: columnStyling2,
    // },

    // {
    //   name: "Completion of Internship",
    //   selector: (row)=> row.completion,
    // },

   

  ];
  // const content = (name) => {
  //   return (
  //     <>
  //       <Grid style={{ paddingLeft: "7%", paddingRight: "7%" }}>
  //         <Grid
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <Message>{`Employee ${name} not registered in Mynext Talent Portal`}</Message>
  //           <Message></Message>
  //         </Grid>
  //         <Grid
  //           style={{
  //             display: "flex",
  //             alignItems: "center",
  //             justifyContent: "center",
  //           }}
  //         >
  //           <Button
  //             style={{ width: "25%" }}
  //             name="Ok"
  //             onClick={ClosePopup}
  //           />
  //         </Grid>
  //       </Grid>
  //     </>
  //   );
  // };
console.log(formVal, "DATA")

//to set defaultValue

const monthlyAllowance = selectedRowData ? selectedRowData.internship_allowance_per_month : "";
const total_digital_communication_expenses = selectedRowData ? selectedRowData.total_digital_communication_expenses : "";
const total_accomodation_expenses = selectedRowData ? selectedRowData.total_accomodation_expenses : "";
const total_internship_allowance_in_programme = selectedRowData ? selectedRowData.total_internship_allowance_in_programme : "";
const total_meal_expenses = selectedRowData ? selectedRowData.total_meal_expenses : "";
const total_training_expenses = selectedRowData ? selectedRowData.total_training_expenses: "";
const total_travelling_expenses = selectedRowData ? selectedRowData.total_travelling_expenses: "";
const course = selectedRowData ? selectedRowData.course: "";
const address_1 = selectedRowData ? selectedRowData.address_1: "";
const address_2 = selectedRowData ? selectedRowData.address_2: "";







console.log(monthlyAllowance,"This is monthly allowance default value")


  const Content = () => {
    const { address_1, address_2, state, city, postal_code, date_of_hiring, vulnerable_group, /* other fields */ } = selectedRowData || {};
    console.log(selectedRowData,"This is selectedRowData")
    return (
      <>
        <div style={{ margin: "10px 10px" }}>
        <TitleSection>
              <TitleDescription>Personal Details</TitleDescription>
              <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
            </TitleSection>

          <GroupWrapper>
              <Row>
              <Col md="4">
                  <FormLabel>Address 1*</FormLabel>
                  <Controller
                    name="address_1"
                    control={control}
                    defaultValue={address_1}
                    render={({ field }) => (
                      <Input
                        
                        value={address_1}
                        placeholder="Enter address 1"
                        {...field} />
                    )} />
                  {errors.address_1 && (
                    <ErrorField err={errors.address_1.message} />
                  )}
                </Col>
                <Col md="4">
                <FormLabel>Address 2*</FormLabel>
                <Controller
                  name="address_2"
                  control={control}
                  defaultValue={address_2}
                  //defaultValue={cache?.address_2}
                  render={({ field }) => (
                    <Input
                      value={address_2}
                      placeholder="Enter address 2"
                      {...field} />

                  )} />
                {errors.address_2 && (
                                  <ErrorField err={errors.address_2.message}/>
                                )}
              </Col>
              <Col md="4">
                  <FormLabel>State *</FormLabel>
                  <Controller
                    name="state"
                    control={control}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={states}
                        placeholder={"Select State"}
                        val={selectedState?.value}
                        label={selectedState?.label}
                        change={(val) => {
                          //clearErrors("state");
                          dropdownChange("state", val);
                        }}
                        {...field}
                      />
                    )} />
                  {errors.state && (
                    <ErrorField err={errors.state.message} />
                  )}
                </Col>
              
              
              </Row>
          </GroupWrapper>

          <GroupWrapper>
            <Row>

            <Col md="4">
                  <FormLabel>City *</FormLabel>
                  <Controller
                    name="city"
                    control={control}
                    render={({ field }) => (
                      // <Input
                      // placeholder="Enter city"
                      // {...field}
                      // />
                      <Dropdown
                        bgGrey
                        search={true}
                        options={cities}
                        placeholder={"Select City"}
                        val={selectedCity?.value}
                        label={selectedCity?.label}
                        disable={!formVal.state}
                        change={(val) => {
                          //clearErrors("city");
                          dropdownChange("city", val);
                        }}
                        {...field}
                      />
                    )} />
                  {errors.city && (
                    <ErrorField err={errors.city.message} />
                  )}
                </Col>

                <Col md="4">
                <FormLabel>Postcode *</FormLabel>
                <Controller
                  name="postal_code"
                  control={control}
                  defaultValue={postal_code}
                  //defaultValue={cache?.state}
                  render={({ field }) => (
                    <Input
                      value={postal_code}
                      placeholder="Enter postal code"
                      {...field}
                    />
                  )} />
                {errors.state && (
                  <ErrorField err={errors.state.message} />
                )}
              </Col>

              <Col md="4">
                <FormLabel>Date of Hiring*</FormLabel>
                <Controller
                  name="date_of_hiring"
                  control={control}
                  render={({ field }) => (
                    <label style={{ width: "max-content" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            style={{ marginTop: "-5px" }}
                            autoOk
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            views={["year", "month", "date"]}
                            value={
                              !formVal.date_of_hiring
                                ? null
                                : formVal.date_of_hiring &&
                                  formVal.date_of_hiring === "None"
                                  ? null
                                  : formVal.date_of_hiring === ""
                                    ? null
                                    : new Date(formVal.date_of_hiring)
                            }
                            onChange={(val) =>
                              dateChange1("date_of_hiring", val)
                            }
                            fullWidth
                          
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                      
                    </label>
                  )}
                />
                {errors.date_of_hiring && (
                  <ErrorField err={errors.date_of_hiring.message} />
                )}
              </Col>

            </Row>
          </GroupWrapper>

          <GroupWrapper>
            <Row>
                <Col md="4">
                          <FormLabel>Vulnerable Group *</FormLabel>
                            <Controller 
                              name="vulnerable_group"
                              control={control}
                              render={({field}) => (
                                <Dropdown
                                  placeholder="Select ..."
                                  options={vulnerableGroup.map(vulnarable => ({ value: vulnarable.value, label: vulnarable.label }))}
                                  val={selectedVulnarableGroup?.value}
                                  label={selectedVulnarableGroup?.label}
                                  change={(val) => {
                                    dropdownChange("vulnerable_group", val);
                                  }}
                                />
                              )}
                            />
                            {errors.vulnerable_group && (
                              <ErrorField err={errors.vulnerable_group.message}/>
                            )}
                  </Col>

            </Row>
          </GroupWrapper>
          <TitleSection>
              <TitleDescription>Internship Details</TitleDescription>
              <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
            </TitleSection>
          <GroupWrapper>
            <Row>
            <Col lg="4">
              <FormLabel>{"Start Date"}</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                
                <ThemeProvider theme={materialTheme}>
                  <DatePicker
                  name="internship_start_date"
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    placeholder={"From"}
                    views={["year", "month", "date"]}
                    value={fromDate ? new Date(fromDate) : null}
                    onChange={(val) => {
                      dateChange("fromDate", val);
                    }}
                    // maxDate={new Date()}
                    fullWidth
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Col>
            <Col lg="4">
              <FormLabel>{"End Date"}</FormLabel>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <ThemeProvider theme={materialTheme}>
                  <DatePicker
                    name="internship_end_date"
                    autoOk
                    variant="inline"
                    format="dd/MM/yyyy"
                    disabled={!fromDate}
                    placeholder={"From"}
                    views={["year", "month", "date"]}
                    value={toDate ? new Date(toDate) : null}
                    onChange={(val) => {
                      dateChange("toDate", val);
                    }}
                    // maxDate={new Date()}
                    minDate={fromDate}
                    fullWidth
                  />
                </ThemeProvider>
              </MuiPickersUtilsProvider>
            </Col>

            <Col lg="3">
              <FormLabel>{"Duration"}</FormLabel>
              <FormLabel>{calculateDuration()}</FormLabel>
            </Col>
            </Row>
            <Row>
            <Col md="4">
                  <FormLabel>Course *</FormLabel>
                    <Controller 
                      name="course"
                      control={control}
                      render={({field}) => (
                        <Dropdown
                          placeholder="Select ..."
                          options={courseList.map(course => ({ value: course.value, label: course.label }))}
                          val={selectedCourse?.value}
                          label={selectedCourse?.label}
                          change={(val) => {
                            //clearErrors("course");
                            dropdownChange("course", val);
                          }}
                        />
                      )}
                    />
                      {errors.course && (
                        <ErrorField err={errors.course.message}/>
                      )}
                  </Col>
            </Row>
          </GroupWrapper>

          <TitleSection>
              <TitleDescription>Internship Expenses</TitleDescription>
              <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
            </TitleSection>
            <GroupWrapper>
              <Row>
                <Col md="6">
                  <FormLabel>Total Training Expenses*</FormLabel>
                  <Controller
                    name="total_training_expenses"
                    control={control}
                    defaultValue={total_training_expenses} 

                    render={({ field }) => (
                      <Input
                        
                        value={total_training_expenses} 

                        placeholder="Enter total training expenses"
                        {...field}
                      />
                    )}
                  />
                  {errors.total_training_expenses && (
                    <ErrorField err={errors.total_training_expenses.message} />
                  )}
                </Col>

                <Col md="6">
                  <FormLabel>Total Meal Expenses*</FormLabel>
                  <Controller
                    name="total_meal_expenses"
                    control={control}
                    defaultValue={total_meal_expenses} 
                    render={({ field }) => (
                      <Input
                       
                        value={total_meal_expenses} 

                        placeholder="Enter total meal expenses"
                        {...field}
                      />
                    )}
                  />
                  {errors.total_meal_expenses && (
                    <ErrorField err={errors.total_meal_expenses.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormLabel>Total Accomodation Expenses*</FormLabel>
                  <Controller
                    name="total_accomodation_expenses"
                    control={control}
                    defaultValue={total_accomodation_expenses} 

                    render={({ field }) => (
                      <Input
                        
                        value={total_accomodation_expenses} 

                        placeholder="Enter total accomodation expenses"
                        {...field}
                      />
                    )}
                  />
                  {errors.total_accomodation_expenses && (
                    <ErrorField err={errors.total_accomodation_expenses.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormLabel>Total Travelling Expenses*</FormLabel>
                  <Controller
                    name="total_travelling_expenses"
                    control={control}
                    defaultValue={total_travelling_expenses} 

                    render={({ field }) => (
                      <Input
                       
                        value={total_travelling_expenses} 
                        placeholder="Enter total travelling expenses"
                        {...field}
                      />
                    )}
                  />
                  {errors.total_travelling_expenses && (
                    <ErrorField err={errors.total_travelling_expenses.message} />
                  )}
                </Col>
                <Col md="8">
                  <FormLabel>Total Digital And Communication Expenses*</FormLabel>
                  <Controller
                    name="total_digital_communication_expenses"
                    control={control}
                    defaultValue={total_digital_communication_expenses} 

                    render={({ field }) => (
                      <Input
                        
                        value={total_digital_communication_expenses} 

                        placeholder="Enter total digital and communications expenses"
                        {...field}
                      />
                    )}
                  />
                  {errors.total_digital_communication_expenses && (
                    <ErrorField err={errors.total_digital_communication_expenses.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormLabel>Monthly Allowance*</FormLabel>
                  <Controller
                    name="internship_allowance_per_month"
                    control={control}
                    defaultValue={monthlyAllowance} 
                    render={({ field }) => (
                      <Input
                      value={monthlyAllowance} 

                      placeholder="Enter monthly allowance"
                        {...field}
                        
                      />
                    )}
                  />
                  {errors.internship_allowance_per_month && (
                    <ErrorField err={errors.internship_allowance_per_month.message} />
                  )}
                </Col>

                <Col md="6">
                  <FormLabel>Total Allowance in Program*</FormLabel>
                  <Controller
                    name="total_internship_allowance_in_programme"
                    control={control}
                    
                    defaultValue={total_internship_allowance_in_programme} 
                    render={({ field }) => (
                      <Input
                        
                        value={total_internship_allowance_in_programme} 

                        placeholder="Enter total allowance"
                        {...field}
                      />
                    )}
                  />
                  {errors.total_internship_allowance_in_programme && (
                    <ErrorField err={errors.total_internship_allowance_in_programme.message} />
                  )}
                </Col>
              </Row>
            </GroupWrapper>

            <Row>
            <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                width="100px"
                margin="40px 0px 30px 0px"
                name={"Confirm"}
                onClick={handleSubmit(submitForm)}
                type="submit"
              />

              <Button
                secondary
                color="#FFFFFF"
                name={"Cancel"}
                style={{
                  margin: "40px 5px 30px 10px",
                }}
                variant="outlined"
                onClick={onClose}
              ></Button>
            </Col>
          </Row>
          {errorField && (
            <ErrorField
              err={"*Please make sure to complete all the details"}
            ></ErrorField>
          )}
        </div>
      </>
    );
  };


  const downloadInternList =async(internshipID)=>{
    const {data} = await getInternlistDownload(internshipID)

    setGetInternListDownload1(data.data); 
    console.log(data.data,"InternList Download")
  }

  const sendSingleRegistrationReminder = async(internshipID,row)=>{
    const requestData={
      type_of_reminder: "registration",
      email:row.email
    }
    const {data} = await sendSingleReminder(internshipID,requestData);
    if(data.status===1){
      toast.success(("Registration Reminder sent to choosen intern"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      toast.error((data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const sendSingleAssessmentReminder = async(internshipID,row)=>{
    const requestData = {
      type_of_reminder: "assessment",
      email:row.email
    }
    console.log("This is the Row.email",row)
    const {data} = await sendSingleReminder(internshipID,requestData);
    if(data.status===1){
      toast.success(("Assessment reminder sent to choosen intern"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      toast.error((data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const sendMultipleAssesmentReminder = async(internshipId)=>{
    const requestData = {
      type_of_reminder: "assessment",
    }
    const{data}= await sendMultipleReminder(internshipID,requestData)
    // const {data} = await singleInternBulkUploadValidate(requestData);
    if(data.status===1){
      toast.success(("Multiple assessment reminder sent to interns"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      toast.error((data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const sendMultipleRegistrationReminder = async(internshipId)=>{
    const requestData = {
      type_of_reminder: "registration",
    }
    const{data}= await sendMultipleReminder(internshipID,requestData)
    // const {data} = await singleInternBulkUploadValidate(requestData);
    if(data.status===1){
      toast.success(("Multiple registration reminder sent to choosen student"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      toast.error((data.message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  }

  const [applicantData, setApplicantData] = useState([]);
  const [applicantDataCount, setApplicantDataCount] = useState(0);

  const [theArray, setTheArray] = useState([]);


  const getApplicantData = async () => {
    const {data} = await getApplicantDetails(internshipID);
    if (data && data?.results?.length > 0) {
      setApplicantData(data?.results);
      setApplicantDataCount(data.count)
      console.log("=============================");
      console.log("saif");
      console.log(data.count);
    }
   // Iterate over each applicant and update their internship notes with unique keys
    data.results.forEach((app) => {
      var arr = app.internship_notes?.map((note, index) => ({
        ...note,
        key: `${app.applied_user_id}-${index}`,
      })) ?? [];
      setTheArray((prevArray) => [...prevArray, ...arr]);
    });
  
  };


  const navigate = useNavigate();
  
  // const org = useSelector((state) => state.org.orgData);
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const setShowApplicantStatus = () => {
    navigate("/internships/applicants?id=" + id);
    //setShowApplicants(true);
  };

  // for download 

  const downloadExcel = (data, columns, fileName) => {
    const worksheet = XLSX.utils.json_to_sheet(data, { header: columns });
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };

  const handleDownload = () => {
    const data = interns.map(intern => ({
      // Assuming your intern object structure matches the column selectors
      ...intern,
      // Add additional properties as needed
    }));
    console.log('Data for download:', data); // Add this line for debugging

    const columnHeaders = excelColumn.map(column => column.name);
    downloadExcel(data, columnHeaders, 'exportedData');
  };

  console.log("intenrsDXDDSD",interns)
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = ".xlsx";
  function exportExcel(columnData,exportFileName) {
      console.log("exportStudent",columnData)
      const ws = XLSX.utils.json_to_sheet(columnData);
      const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, exportFileName + fileExtension);
  }
  return (
    <>
    
    <ButtonWrapper alignEnd>
    <ButtonWrapper>
      {applicantDataCount > 0 ? 
      <>
       <div style={{ display: 'flex', alignItems: 'center' }}>
          <Button name="View Applicants" onClick={setShowApplicantStatus} />
          <h4 style={{paddingLeft:"15px", paddingTop:"5px", color: "#BCBCBC"}}>
            {applicantDataCount > 0
              ? `${applicantDataCount} Applications`
              : "No Applications"}
          </h4>
        </div>
      </>:""}
    
      </ButtonWrapper>
      <ReactTooltip effect="solid"/>
      {fromInternship ==="Direct Hiring"?<IconImage data-tip="Send Registration Reminder"><StudentRing onClick={()=>{sendMultipleRegistrationReminder(internshipID)}}/></IconImage>:""}
      
      <div>
      <IconImage onClick={()=>{
                    const newDataColumn = interns.map(row => {
                      const transformedRow = {};
                      excelColumn.forEach(column => {
                          const key = column.name || ''; // Default to empty string if name is empty
                          transformedRow[key] = row[column.selectorKey];
                      });
                      return transformedRow;
                  });

                  exportExcel(newDataColumn,'intern_list')
      }} data-tip="Download Intern List" ><StudentDownload /></IconImage>
      {/* <ExcelFile element={<IconImage data-tip="Download Intern List" ><StudentDownload /></IconImage>} filename={"exportFileName"}>
        <ExcelSheet data={interns} name={"exportFileName"}>
          {excelColumn && excelColumn.length && excelColumn.map((column, index) => <ExcelColumn key={index} label={column.name} value={column.selector} />
          )}
        </ExcelSheet>
      </ExcelFile> */}
    </div>
  {fromInternship ==="Direct Hiring"?<IconImage data-tip="Send Assessments Reminder"><StudentShare onClick={()=>{sendMultipleAssesmentReminder(internshipID)}}/></IconImage>:""}
      
      
    </ButtonWrapper>

    {/* <FormWrapper>
      <InternShipDetails
        data={interns}
        noOfApplicants={applicantData.length}
      />
    </FormWrapper> */}

     

    <DataTableWrapper className="users-management">
    <DataTable
      columns={column}
      data={interns}
      theme="solarized"
      customStyles={customStyles}
    />
    </DataTableWrapper>
      {/* {internInformation.map((internship, index) => (
        <InternDetailsWrapper key={index}>
          <CustomHeading>{internship.name}</CustomHeading>
          <InternDetailsTable >
            <thead  >
              <tr >
                <InternDetailsHeader>Intern Name</InternDetailsHeader>
                <InternDetailsHeader>NRIC</InternDetailsHeader>
                <InternDetailsHeader>Institution</InternDetailsHeader>
                <InternDetailsHeader>Qualification</InternDetailsHeader>
                <InternDetailsHeader>Allowance/Month</InternDetailsHeader>
                <InternDetailsHeader>Start Date</InternDetailsHeader>
                <InternDetailsHeader>End Date</InternDetailsHeader>
                <InternDetailsHeader>Duration</InternDetailsHeader>
                <InternDetailsHeader>MyNext Reg.</InternDetailsHeader>
                <InternDetailsHeader>MySIP Elig.</InternDetailsHeader>
                <InternDetailsHeader>Intern Eval.</InternDetailsHeader>
                <InternDetailsHeader>Skills Gap Eval.</InternDetailsHeader>
                <InternDetailsHeader>Actions</InternDetailsHeader>
              </tr>

            </thead>
            <tbody>
              {internship.internInformation.map((intern, index) => (
                <tr key={index}>
                  <InternDetailsCell>{intern.internName}</InternDetailsCell>
                  <InternDetailsCell>{intern.internNRIC}</InternDetailsCell>
                  <InternDetailsCell>{intern.institution}</InternDetailsCell>
                  <InternDetailsCell>{intern.qualification}</InternDetailsCell>
                  <InternDetailsCell>{intern.allowancePerMonth}</InternDetailsCell>
                  <InternDetailsCell>{intern.internStartDate}</InternDetailsCell>
                  <InternDetailsCell>{intern.internEndDate}</InternDetailsCell>
                  <InternDetailsCell>{intern.duration}</InternDetailsCell>
                  <InternDetailsCell>
                    {intern.myNextRegistration ? "Yes" : "No"}
                  </InternDetailsCell>
                  <InternDetailsCell>
                    {intern.mySipEligibility ? "Yes" : "No"}
                  </InternDetailsCell>
                  <InternDetailsCell>
                    {intern.internEvaluation ? "Done" : "No"}
                  </InternDetailsCell>
                  <InternDetailsCell>
                    {intern.skillsGapEvaluation ? "Done" : "No"}
                  </InternDetailsCell>
                  <InternDetailsCell>
                    <TableButtons>
                      {intern.is_assessment_done === 1 ? (
                        <GreenViewIcon />
                      ) : (
                        <ViewIcon />
                      )}
                      <ReminderIcon
                        color={Colors.greyText}
                        onClick={() => {
                          setShow(true);
                          setFullName(intern.internName);
                        }}
                      />
                    </TableButtons>
                  </InternDetailsCell>
                </tr>
              ))}
            </tbody>
          </InternDetailsTable>
          <AlertModal show={show} content={content(fullName)} />
        </InternDetailsWrapper>
      ))} */}
       <EditDateModal
           title={"Intern Details"}
           subTitle={"Update Intern Details"}
           show={showModal}
           close={onClose}
           content={Content()}

          />
    </>
  );
 };

export default InternDetails;