import employe from "../../assets/employe.png";
import unemploye from "../../assets/unemployee.png";


export const talentStatus = { 
    title: "Select MySIP Direct Hiring Intern Type",
    description:
      "Choose your internship type",
    name: "What is your Talent's status?",
    statuses: [
  
      {
        label: "Ongoing",
        value: "on-going",
        img: employe,
        descriptions:
          "Talent is currently in the internship process",
      },
  
      {
        label: "Completed",
        value: "completed",
        img: unemploye,
        descriptions:
          "Talent has completed the internship",
      },
    ],
    err: "Please select a status.",
  };
  
