// import { getPersonalityAndMotivationDrilldown } from "../../../../../services/CommonApi";
import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";
import { getDataWorkPersonality, getPersonalityAndMotivationDrilldown } from "../../../services/apiCalls";
// import Ad2DrilldownHOC from "../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

export const getPersonlityData = () => {
  var personlityData = {
    "status": 1,
    "data": {
        "facet": [],
        "request": {
            "insti_name": "141",
            "university": "141"
        },
        "results": {
            "Emotional Stability": {
                "totalTalents": 695,
                "results": {
                    "low": 0,
                    "moderate": 496,
                    "high": 192
                }
            },
            "Extraversion": {
                "totalTalents": 695,
                "results": {
                    "low": 0,
                    "moderate": 494,
                    "high": 194
                }
            },
            "Openness to Experience": {
                "totalTalents": 695,
                "results": {
                    "low": 0,
                    "moderate": 505,
                    "high": 183
                }
            },
            "Agreeableness": {
                "totalTalents": 695,
                "results": {
                    "low": 0,
                    "moderate": 502,
                    "high": 186
                }
            },
            "Conscientiousness": {
                "totalTalents": 695,
                "results": {
                    "low": 0,
                    "moderate": 514,
                    "high": 174
                }
            }
        }
    }
}
return personlityData;
}

export default Ad2DrilldownHOC(getPersonalityAndMotivationDrilldown, [
  {
    title: "Emotional Stability",
    dataKey: "Emotional Stability",
    url: 'emotional-stability',
    info:"Emotional Control relates talents' ability to keep their emotions steady and under control in response to life experiences."
  },
  {
    title: "Extraversion",
    dataKey: "Extraversion",
    url: 'extraversion',
    info:"Extraverts relate more strongly to their external world than their internal world."
  },
  {
    title: "Openness to Experience",
    dataKey: "Openness to Experience",
    url: 'openness-to-experience',
    info:"Talents that are high in Openness are usually open to new ideas and new experiences."
  },
  {
    title: "Agreeableness",
    dataKey: "Agreeableness",
    url: 'agreeableness',
    info:"High Agreeableness means talents are motivated to promote cooperation and harmony with others."
  },
  {
    title: "Conscientiousness",
    dataKey: "Conscientiousness",
    url: 'conscientiousness',
    info:"Being high in Conscientiousness means talents are strong in controlling and regulating their life and work."
  },
]);

