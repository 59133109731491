import React from 'react'
import styled from "styled-components"
import { useSelector } from "react-redux";
import Img from "../../assets/Ellipse 83.png";
import { SubHeading, ContentText } from './common'
import "./popup.css"
import Eye from "../../assets/icons/eye.png"
import Doc from "../../assets/icons/doc.png"
const FormLabel = styled.label`
font-family: 'General Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
color: #202020;
width:100%;
text-align:start;
padding-left:140px;
}
`;
;

const ModalContainer = styled.div`
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  width:900px;
  min-height:320px;;
  @media (max-width: 500px) {
    min-width: 380px;
    width:396px;
    height: auto;
  }
`;

const Image = styled.img`
   width:130px;
   height:130px;
   border-raidus:50%;
//    border: 2px solid #FFFFFF;
   margin-top:-36px;
   margin-left:24px;
`;

const ContentCard = styled.div`
  display:grid;
  grid-template-columns:1fr 3fr;
  padding:24px;
  gap:6px;
`;

const LinkText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 16px;
text-decoration-line: underline;
margin-left:8px;
color: #D04C8D;
`;

const LinkCard = styled.div`
  display:flex;
  flex-direction:row;
  width:200px;

`;

const LinkWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-around;
`;

const Wrapper = styled.div`
  max-width:720px;
  margin-left:auto;
  margin-right:24px;
  margin-top:-24px;
`;
export default function ProfilePopup({ isOpen, setOpen, proceed, submitForm }) {
    const company = useSelector((state) => state.company.currentlySelectedcompanyData);
    return (
        <div>
            {isOpen === true ? (
                <div className="modalHead">
                    <ModalContainer >
                        <div className="borderBox">
                            
                            <FormLabel>Zinhi, Abdullah</FormLabel>
                            <p
                                onClick={() => setOpen(false)}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                X
                            </p>
                        </div>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Image src={Img} />
                            <ContentCard>
                                <SubHeading>Gender:</SubHeading> <ContentText>Male</ContentText>
                                <SubHeading>E-mail ID:</SubHeading> <ContentText>hafidz@cxsanalytics.com</ContentText>
                                <SubHeading>Nationality:</SubHeading> <ContentText>Malaysian</ContentText>
                            </ContentCard>
                            <ContentCard>
                                <SubHeading>Age:</SubHeading> <ContentText>20</ContentText>
                                <SubHeading>Location:</SubHeading> <ContentText>Cheras, Kaula Lumpur, Malaysia</ContentText>
                                <SubHeading>Mobile No.:</SubHeading> <ContentText>0123456789</ContentText>
                            </ContentCard>
                        </div>
                        <Wrapper>
                            <hr />
                            <LinkWrapper>
                                <LinkCard>
                                    <img src={Eye} alt=''/>
                                    <LinkText>View Assessment </LinkText>
                                </LinkCard>
                                <LinkCard>
                                    <img src={Eye} alt=''/>
                                    <LinkText>View CV</LinkText>
                                </LinkCard>
                                <LinkCard>
                                    <img src={Doc} alt=''/>
                                    <LinkText>Supporting Document 1 </LinkText>
                                </LinkCard>
                                <LinkCard>
                                    <img src={Doc} alt=''/>
                                    <LinkText>Supporting Document 2</LinkText>
                                </LinkCard>
                            </LinkWrapper>
                        </Wrapper>
                    </ModalContainer>
                </div>
            ) : null
            }
        </div>
    )
}
