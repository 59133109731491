import { useEffect, useState } from "react";

import ReactTooltip from 'react-tooltip';
import Logo from "../../assets/companylogosettings.png";

import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { toast } from "react-toastify";
import { Colors } from "../../utilities/colors";
import DashboardCardsWrapper from "../../components/dashboardCardsWrapper/dashboardCardsWrapper";
import JobsCards from "../../components/jobsCards/jobsCards";
import InternCard from "../../components/InternshipCard/internCard";

import {
  getInternshipModuleList,
  getInternLists,
  getAnalyticData,
  getOrgData,
  getOrganizationInternshipCoinDetail,
  getQuota,
  getlistJobs,
  // getCompanyData,
  retrieveInternshipModuleByOrg
} from "../../services/apiCalls";
import DashboardModal from "../../components/dashboardModal/dashboardModal";
import AddNewIntern from "../../components/addNewIntern/addNewIntern";
import { staticState } from "./staticContent.js";
import Loader from "../../components/loader/loader";
import DashboardPopup from "../../components/dashboardPopup/dashboardPopup";
import { updateAdsData } from "../../pages/myCompany/reducer.js";
import Tooltip from "../../components/reactTooltip/tooltip";
import InternshipJobSection from "../../components/internshipJobsSection/internshipJobSection";
import Dropdown from "../../components/dropdown/dropdown";
import InfiniteScroll from "react-infinite-scroll-component";
import JobsDataCards from "../jobPortal/jobsDataCards";
import DashboardNewIntern from "../../components/dashboardNewIntern/dashboardNewIntern";
import { t } from "i18next";
import DashboardInternCard from "../../components/InternshipCard/DashboardInternCard.js";
import UploadPlusIcon from "../../assets/uploader.png";
import AnnouncementModal from "../../components/modal/AnnouncementModal.js";

const DashboardWrapper = styled.div``;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  //margin-top: -15px;
  text-transform: capitalize;
`;
const FlexDiv = styled.div`
  display: flex;
`;
const Section = styled.div`
  margin-bottom: 50px;
`;
const JobsWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  margin: 30px 0px 10px 0;
  > h4 {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: #000000;
  }
  > p {
    font-family: 'General Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: #D04C8D;
    cursor:pointer;
  }
  @media (max-width: 500px){
    h4{
    font-size: 18px;
    line-height: 24px;
    margin-top:90px;
    }
    p{
    font-size: 14px;
    line-height: 19px;
    margin-top:90px;
    }
  }
`;

const HeaderAddButtonWrapper = styled.div`
display: flex;
flex-direction: row;
gap: 20px;
`;

const AddInternshipModuleWrapper = styled.div`
width: 310px;
display: flex;
flex-direction: row;
padding: 2px;
gap: 20px;
border-radius: 60px;
margin-top: -10px;
border: 1px solid #D04C8D;
cursor: pointer;
&:hover{
  background-color: #D04C8D;
}
`

const ButtonLabel = styled.h3`
  font-weight: 500;
  font-size: 22px;
  line-height: 38px;
  color: ${Colors.dark};
  text-transform: capitalize;

  ${AddInternshipModuleWrapper}:hover & {
    color: ${Colors.light};
}
`;

const IconDiv = styled.img`
  margin-left: 10px;
  margin-top: 6px;
  width: 30px;
  height: 30px;
  filter: ${(props) => (props.hovered ? 'invert(1)' : 'none')};
  transition: filter 0.2s ease-in-out; 
`;
// const HamburgerLayout = styled.div`
//   margin-top: 10px;
//   @media (min-width: 1024px) {
//     display: none;
//   }
// `;
export default function Dashboard() {
  const dropItem = [
    { value: "internships", label: t("Internships") },
    { value: "jobs", label: t("Jobs") },
    { value: "Internship Modules", label: t("Internship Modules") }
  ]
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const [approvedLists, setApprovedList] = useState([]);
  const [pendingLists, setPendingList] = useState([]);
  const [analysedData, setAnalysedData] = useState("");
  const [greeting, setGreeting] = useState("");
  const [current_name, setCurrentName] = useState("");
  const [coin, setCoin] = useState("");
  const [userUsedCoins, setUserUsedCoin] = useState("");
  const [internshipVacancy, setInternshipVacancy] = useState("");
  const [jobPosting, setJobPosting] = useState("");
  const [talentSearch, setTalentSearch] = useState("");
  const [employeeQuota, setEmployeeQuota] = useState("");
  const [sipToken, setSipTokens] = useState("");
  const [jobInternDropValue, setJobInternDrop] = useState({ label: "Internships", value: "internships" });
  const company = useSelector((state) => state.company.currentlySelectedcompanyData);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const [jobsData, setJobdata] = useState([])
  // const [isOpenham, setOpenHam] = useState(false);
  // const org = useSelector((state) => state.org.orgData);
  // const dispatch = useDispatch();
  const subscriptionPlan = useSelector((state) => state.subscription.subscriptionData);
  const job_duration = subscriptionPlan[org_code]?.job_duration_day ? subscriptionPlan[org_code]?.job_duration_day : 60;
  const [isOpen, setOpen] = useState(false);
  const paymentAds = useSelector((state) => state.company.paymentAds);
  const [showAnnouncementModal, setShowAnnouncementModal] = useState(false);

  const [pop_status, setPopStatus] = useState("");

  useEffect(() => {
    let pop_status_initial = localStorage.getItem("pop_status");
    if (!pop_status_initial || pop_status_initial !== "2") {
      setShowAnnouncementModal(true);
    }
  }, []);

  const handleModalClose = () => {
    localStorage.setItem("pop_status", "2");
    setShowAnnouncementModal(false);
  };

  useEffect(() => {
    if (org_code) {
      checKLogin();
      let pop_status_initial = localStorage.getItem("compay_payment_ad_pop_status");
      if (pop_status_initial <= 0) {
        if (!paymentAds) {
          dispatch(updateAdsData(true));
          pop_status_initial = localStorage.setItem("compay_payment_ad_pop_status", 0);
          setOpen(true);
        }
      }
    }

    // const currentUser = orgUser.filter(item => item.email === currentUserEmail);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [org_code]);
  const showToast = () => {
    toast.success(t('Internship status updated successfully'), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };
  const getGreeting = () => {
    var today = new Date();
    var curHr = today.getHours();

    if (curHr < 12) {
      setGreeting("Good Morning");
    } else if (curHr < 18) {
      setGreeting("Good Afternoon");
    } else {
      setGreeting("Good Evening");
    }
  };

  const getAppData = async () => {
    const { data } = await getAnalyticData();
    if (data && data.status === 1) {
      setAnalysedData(data.data);
    }
  };
  // const [result, setResult] = useState("");

  const checKLogin = async () => {
    const result1 = await getOrgData();
    setCurrentName(result1.data.data.name);
    if (result1.status === "401") {
      navigate("/login");
    } else {
      getGreeting();
      getApprovedList();
      getAppData();
      getJoblist('?page=1&page_size=3')
    }
  };

  const getPendingList = async () => {
    const { data, } = await getInternLists({
      ...staticState,
      filters: { status: "pending" },
      org_code: org_code,
    });
    if (data && data.status === 1) {
      setPendingList(data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  function time_ago(d) {
    let date = new Date();

    var date1 = new Date(d);
    var date2 = new Date(
      date.getFullYear() +
      "-" +
      (parseInt(date.getMonth() + 1) >= 10
        ? parseInt(date.getMonth() + 1)
        : `0${parseInt(date.getMonth() + 1)}`) +
      "-" +
      (date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`)
    );
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days.toFixed(0);
  }
  const delay = ms => new Promise(
    resolve => setTimeout(resolve, ms)
  );
  const getJoblist = async (peram) => {
    if (org_code) {
      let data = await getlistJobs(org_code, peram);
      // console.log("jobAPIData")
      if (data.status === 200) {
        let li = []
        for (let i = 0; i < data.data.results.length; i++) {
          let list = data.data.results[i]
          let l = time_ago(list.created_at)
          l = Number(l)
          if (l == 0) {
            list.postago = t("Today")
          } else if (l >= Number(job_duration)) {
            list.postago = t("expired").toUpperCase();
          } else {
            list.postago = l + t(" days ago")
          }
          li.push(list);
          data.data.count = data.data.count - 1
        }
        data.data.results = li
        // let quo = Math.floor(data.data.count / 5);
        // let rem = data.data.count % 5;
        // setPaginationCount(rem !== 0 ? quo + 1 : quo);
        // await delay(200)
        setJobdata(data.data.results);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  const getApprovedList = async () => {
    const { data } = await getInternLists({ ...staticState });
    if (data && data.status === 1) {
      setApprovedList(data.data);
      getPendingList();
    } else {
      getPendingList();
    }
  };

  const navigate = useNavigate();

  // const onLogout = async () => {
  //   const { data, status } = await authLogout();
  //   if (status === 200) {
  //     if (data.status === 1) {
  //       localStorage.removeItem("auth_token");
  //       window.location.assign("/");
  //     }
  //   }
  // };

  // const onNavigate = () => {
  //   navigate("/details");
  // };

  const toInternPage = (status) => {
    navigate("/internships/" + status);
  };
  const toJobsPage = () => {
    navigate("/jobs");
  }

  //for internship module
    const [internshipModuleData, setInternshipModuleData] = useState([]);
    const [createdYear, setCreatedYear] = useState([]);
    const currentYear = new Date().getFullYear();
    const [isModuleCreated, setIsModuleCreated] = useState(false);

  useEffect(() => {
    const fetchDataLists = async () => {
      const result1 = await getOrgData();
      setCurrentName(result1.data.data.name);
    }
    
    fetchDataLists();
   
    
  }, []);
  // eslint-disable-next-line react-hooks/exhaustive-deps

  const fetchInternshipModuleList = async ()=>{
    const {data} = await retrieveInternshipModuleByOrg(org_code);
    console.log("Fetch IM Called",data);
    if(data){
      const createdYears = data.map(item => item.created_at);
      const years = createdYears.map(created_at => new Date(created_at).getFullYear());
      setCreatedYear(years);
      if (years.includes(currentYear)) {
        setIsModuleCreated(true);
      }
      setInternshipModuleData(data);
      setLoading(false);
    }else{
      setLoading(false);
    }
  }
  useEffect(async () => {
    if (Object.keys(company).length > 0) {
      const result3 = await getQuota(org_code);
      console.log("results#", result3)
      setCoin(result3?.data?.internship_tokens);
      setUserUsedCoin(result3?.data?.internship_tokens_in_use);
      setInternshipVacancy(result3?.data?.internship_tokens);
      setJobPosting(result3?.data.total_job);
      setTalentSearch(result3?.data.talent_search_tokens)
      setEmployeeQuota(result3?.data.total_emp)
      setSipTokens(result3?.data?.sip_tokens);
    }
    fetchInternshipModuleList();
  }, [company]);

  // for internship module cards 
  const [selectedCard, setSelectedCard] = useState([]);

  const handleCardClick = (cardId) => {
    if (selectedCard.includes(cardId)) {
      setSelectedCard(selectedCard.filter((id) => id !== cardId));
    } else {
      setSelectedCard([...selectedCard, cardId]);
    }
  };
const onAddInternshipModule = () =>{
  navigate("/internships/internship-module-eligibility-check")
}
  return (
    <>
      {isOpen === true ? <DashboardPopup setOpen={setOpen} isOpen={isOpen} popupStatus="compay_payment_ad_pop_status" /> : ""}
      {showAnnouncementModal === true ? (
        <AnnouncementModal
          setShowAnnouncementModal={setShowAnnouncementModal}
          show={showAnnouncementModal}
          pop_status={pop_status}
          close={handleModalClose}
        />
      ) : (
        ""
      )}
      {" "}
      <MainLayout back={true}>
        <DashboardWrapper>
          <HeaderText>
            {t(greeting)}, {current_name}
          </HeaderText>
          {/* <DashboardCardsWrapper data={analysedData} />
          <Tooltip id="Applications" content={t('Total number of internship postings created (All statuses)')} />
          <Tooltip id="Accepted" content={t('Number of approved internship postings')} />
          <Tooltip id="Pending Verification" content={t('Number of internship postings pending verification from Admin')} />
          <Tooltip id="Offered" content={t('Number of interns offered internship placement(s)')} /> */}
          <DashboardNewIntern internshipVacacny={internshipVacancy} jobPosting={jobPosting} talentSearch={talentSearch} coin={coin} userUsedCoins={userUsedCoins} employeeQuota={employeeQuota} sipToken={sipToken} />
          <InternshipJobSection analyticalData={analysedData} coin={coin} userUsedCoins={userUsedCoins}></InternshipJobSection>
          {loading ? (
            <Loader />
          ) : (
            <>
              <JobsWrapper style={{ justifyContent: "start" }}>
                <h3 style={{ paddingRight: "10px" }}>{t('Manage')}</h3>
                <Dropdown
                  // search={true}
                  options={dropItem}
                  val={jobInternDropValue?.value}
                  label={t(jobInternDropValue?.label)}
                  // disable={facultyDisable}
                  change={(val) => {
                    setJobInternDrop(val);
                    if (jobsData.length === 0) {
                      getJoblist('?page=1&page_size=3')

                    }
                  }}
                // {...field}
                // refCallback={field?.ref}
                // ref={null}
                />
                {/* <p onClick={() => toInternPage("accepted")}>{t('View all')}</p> */}
              </JobsWrapper>
              {jobInternDropValue.value == "internships" ? <>
                <Section>

                  <JobsWrapper>
                    <h4>{t('Your Internships')}</h4>
                    <p onClick={() => toInternPage("accepted")}>{t('View all')}</p>
                  </JobsWrapper>
                  {approvedLists.length > 0 ? (
                    approvedLists.map((list, index) => (
                      <JobsCards
                        showToast={showToast}
                        key={list + index}
                        datas={list}
                      />
                    ))
                  ) : (
                    <p class="noDataFound">{t('No data found')}</p>
                  )}
                </Section>
                <Section>
                  <JobsWrapper>
                    <h4 style={{ marginTop: "0px" }}>{t('Pending Verification')}</h4>
                    <p style={{ marginTop: "0px" }} onClick={() => toInternPage("pending")}>{t('View all')}</p>
                  </JobsWrapper>
                  {pendingLists.length > 0 ? (
                    pendingLists.map((list, index) => (
                      <JobsCards
                        showToast={showToast}
                        pending={true}
                        key={list + index}
                        datas={list}
                      />
                    ))
                  ) : (
                    <p class="noDataFound">{t('No data found')}</p>
                  )}
                </Section>
              </> :jobInternDropValue.value == "jobs" ? <>
                <Section>
                  <JobsWrapper>
                    <h4>{t('Your Jobs')}</h4>
                    <p onClick={() => toJobsPage()}>{t('View all')}</p>
                  </JobsWrapper>
                  {
                    jobsData.length > 0 ? (
                      jobsData.map((list, index) => (
                        <JobsDataCards list={list} index={index}></JobsDataCards>
                      ))
                    ) :
                      (
                        <p class="noDataFound">{t('No data found')}</p>
                      )
                  }
                </Section>


              </> : <>
              <Section>
                <JobsWrapper>
                  <HeaderAddButtonWrapper>
                  <h4>{t('Your Internship Modules')}</h4>
                  {/* <AddInternshipModuleWrapper onClick={onAddInternshipModule}>
                    <IconDiv src={UploadPlusIcon}/>
                    <ButtonLabel>Add Internship Module</ButtonLabel>
                  </AddInternshipModuleWrapper> */}
                  </HeaderAddButtonWrapper>
                    <p onClick={() => toJobsPage()}>{t('View all')}</p>
                </JobsWrapper>
                {internshipModuleData.length > 0 ? (
                        internshipModuleData.map((list, index) => (
                          <>
                          <DashboardInternCard 
                            key={list.id}
                            data={list}
                            selected={selectedCard}
                            handleCardClick={handleCardClick}/> 
                            </>
                          
                        ))
                      ) : (
                        <p className="noDataFound" style={{ whiteSpace: 'pre-line' }}>{"No Data Found "}</p>
                      )}
              </Section>
              </>}


            </>
          )}
        </DashboardWrapper>
        <DashboardModal />
      </MainLayout>
    </>
  );
}
