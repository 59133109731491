import React, { useState } from "react";
import Dropdown from "../../components/dropdown/dropdown";
import styled from "styled-components";
import Button from "../button/button";
import { useSnapshot } from "valtio";
import { addapplications, addShortlistTalentSearch } from "../../services/apiCalls";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import { toast } from "react-toastify";
import TextArea from "../description/description";

const CardsWrapper = styled.div`
  position: absolute;
  width: 645px;
  height: 605px;
  // left: 33%;
  top: 157px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;

  @media (max-width: 500px) {
    width: auto;
    height: 630px;
    position: relative;
    padding-top: 10px;
    // left: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 136px;
  margin: 10px;
  display: flex;
`;
const Icon = styled.div`
  position: absolute;
  top: 10px;
  right: 30px;
  @media (max-width: 500px) {
    right: 30px;
    top: 25px;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 30px 50px;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding: 50px 0px;
  margin: 10px 0 0 -120px;
`;

const SubContents = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  position: relative;
  top: 20px;
  span {
    display: block;
  }
`;

const SubContentstag = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  // text-align: center;
  position: relative;
  top: 20px;
  left: 70px;
  span {
    display: block;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: 501px;
  height: 128px;
  left: 72px;
  top: 20px;
  background: #f3f3f3;
  border-radius: 16px;
  @media (max-width: 500px) {
    left: 25px;
    width: 88%;
  }
`;
const Wrapper2 = styled.div`
  position: relative;
  width: 501px;
  height: 128px;
  left: 72px;
  top: 60px;
  @media (max-width: 500px) {
    position: relative;
    width: auto;
    height: 128px;
    left: 0px;
    top: 60px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
`;
const TalentShortlistModal = ({ shortlistedAdded, open, onClose, id, joblist, name }) => {
  useSnapshot(trackProxy);
  const [job, setJob] = useState([]);
  const [dis, setDis] = useState(true);
  const [tags, setTags] = useState("");

  const submit = async () => {
    var formData = {};
    // formData['job_id'] = job.value,
    // formData['user_id'] = id;
    const { data } = await addShortlistTalentSearch({
      job_id: job?.value,
      user_id: id,
      shortlist_jobposition: job?.value,
      shortlist_tag: tags

    });
    if (data) {
      shortlistedAdded();
      toast.success("Application Shortlisted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    close();
  };

  const close = () => {
    // if (trackProxy.setcheck === 0) {
    //   trackProxy.setcheck = 1;
    // } else {
    //   trackProxy.setcheck = 0;
    // }
    onClose();
  };

  //
  return (
    <div className="overlay-popup">
      <CardsWrapper>
        <CardWrapper id="headercard">
          <Title>Shortlist</Title>
          <Content>You are set to shortlist {name}.</Content>
          <Icon onClick={close} id="headericon" style={{ cursor: "pointer" }}>
            X
          </Icon>
        </CardWrapper>
        <SubContentstag>Tags</SubContentstag>
        <Wrapper style={{marginBottom:"28px"}}>
          <TextArea
            value={tags}
            onChange={(e) => {
              setTags(e.target.value);
              if (job.value !== undefined) {
                setDis(false);
              }
            }}
          ></TextArea>
        </Wrapper>
        <SubContents>
          {name} is being shortlisted for the following
          <span>job position.</span>
        </SubContents>
        <Wrapper2>
          <Dropdown
            name="Shortlist"
            search={true}
            options={joblist}
            placeholder="Please Select some options"
            minWidth="auto"
            width="200px"
            isMulti={false}
            val={job?.value}
            label={job?.label}
            change={(e) => {
              setJob(e);
              setDis(false);
            }}
          />
        </Wrapper2>
        <ButtonWrapper>
          <Button
            onClick={submit}
            cursor="pointer"
            name="Shortlist"
            disabled={dis}
          ></Button>
        </ButtonWrapper>
      </CardsWrapper>
    </div>
  );
};

export default TalentShortlistModal;
