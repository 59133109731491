import React from "react";
import styled from "styled-components";
import Eye from "../../assets/Group 1449.png";
import Document from "../../assets/Group 1523.png";
import "./hiredmodal.css";
import { useSnapshot } from "valtio";
import { useState } from "react";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import { useNavigate } from "react-router-dom";
import { jobProxy } from "../../pages/jobPortal/jobproxy";

const CardsWrapper = styled.div`
  position: absolute;
  width: 983px;
  // height: 329px;
  // left: 30%;
  top: 204px;
  background: #ffffff;
  border-radius: 10px;

  @media (max-width: 500px) {
    width: 302px;
    // height: 690px;
    position: relative;
    top: 10px;
    padding-top: 10px;
  }
  @media (min-width: 501px) and (max-width: 820px) {
    width: 700px;
    height: 630px;
    position: relative;
    top: 10px;
    padding-top: 10px;
    margin-left: 5px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 84px;
  margin: 10px;
  display: flex;
`;
const Name = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
  position: relative;
  top: 20px;
  left: 300px;
  @media (max-width: 500px) {
    left: auto;
    margin-left: 10px;
  }
`;
const Icon = styled.div`
  position: absolute;
  left: 96%;
  top: 16px;
  @media (max-width: 600px) {
    left: 170px;
  }
  @media (min-width: 601px) and (max-width: 820px) {
    left: 580px;
  }
  
`;
const Image = styled.img`
  width: 161px;
  height: 160px;
  position: relative;
  left: 30px;
  bottom: 40px;
  @media (max-width: 500px) {
    width: 161px;
    height: 160px;
    position: relative;
    margin-left: 5px;
    left: 0px;
    bottom: 0px;
  }
`;
const ProfileData = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #d04c8d;
  width:180px;
  
`;
const ProfileValue = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #6b6b6b;
  width:200px;
`;
const DataWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 0 0 10px 0;
  @media (max-width: 500px) {
    display: block;
  }
`;
const HRLine = styled.div`
  border: 0.4px solid rgba(32, 32, 32, 0.1);
  width: 690px;
  position: relative;
  left: 260px;
  bottom: 15px;
  @media (max-width: 500px) {
    border: 0.4px solid rgba(32, 32, 32, 0.1);
    width: auto;
    position: relative;
    left: 0px;
    bottom: 5px;
  }
  @media (min-width: 501px) and (max-width: 820px) {
    width: auto;
    position: relative;
    left: 0px;
    bottom: 0px;
    margin: auto;
  }
`;
const DocumentContent = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #d04c8d;
  position: relative;
  left: 285px;
  bottom: 15px;
  @media (max-width: 500px) {
    position: relative;
    left: 285px;
    bottom: 15px;
  }
`;
const DocumentContent2 = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-decoration-line: underline;
  color: #d04c8d;
  position: relative;
  left: 280px;
  bottom: 15px;
  @media (max-width: 500px) {
    position: relative;
    left: 278px;
    bottom: 15px;
  }
`;
const DocumentWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin: 0 0 10px 0;
  @media (max-width: 500px) {
    position: relative;
    right: 80%;
    padding: 0px 5px;
    margin: 5px 0 0 0;
  }
  @media (min-width: 501px) and (max-width: 820px) {
    position: relative;
    right: 34%;
    margin: 20px 0;
  }
`;
const Images = styled.img`
  position: relative;
  left: 260px;
  width: 20px;
  height: 12px;
  top: 2px;
`;
const Img = styled.img`
  position: relative;
  left: 260px;
  width: 14px;
  height: 17px;
  top: 2px;
`;
const ProfileModal = ({ open, onClose, id, t }) => {
  const navigate = useNavigate();
  useSnapshot(trackProxy);
  useSnapshot(jobProxy);
  const [app, setApp] = useState(null);
  if (id != null) {
    for (let i = 0; i < trackProxy.applist.length; i++) {
      if (id === trackProxy.applist[i]["id"]) {
        if (!app) {
          setApp(trackProxy.applist[i]);
        }
      }
    }
  }
  if (!open) {
    return null;
  }


  const navigateAssesment = () => {
    if (app) {
      jobProxy.userid = app.user_id;

      navigate("/jobs/view-profile", { state: { source: "track" } });
    }
  };
  const navigateCv = () => {
    if (app) {
      jobProxy.userid = app.user_id;

      navigate("/jobs/view-profile", { state: { source: "track" } });
    }
  };
  const navigateDoc = () => {
    if (app) {
      jobProxy.jobid = app.job_id;
      jobProxy.userid = app.user_id;
      navigate("/jobs/view-document", { state: { source: "track" } });
    }
  };
  const navigateDoc2 = () => {
    if (app) {
      jobProxy.jobid = app.job_id;
      jobProxy.userid = app.user_id;
      navigate("/jobs/view-document", { state: { source: "track" } });
    }
  };
  return (
    <div className="overlay-popup">
      {app ? (
        <CardsWrapper>
          <CardWrapper id="headercard">
            <Name>{app.name}</Name>
            <Icon
              onClick={onClose}
              id="headericon"
              style={{ cursor: "pointer" }}
            >
              X
            </Icon>
          </CardWrapper>

          <div className="hiredstyle">
            <Image src={app.profile_picture} alt="img" />

            <div className="wrapper">
              <DataWrapper>
                <ProfileData>{t("Gender")}:</ProfileData>
                <ProfileValue>{app.gender}</ProfileValue>
              </DataWrapper>
              <DataWrapper>
                <ProfileData>{t("E-mail ID")}:</ProfileData>
                <ProfileValue>{app.email}</ProfileValue>
              </DataWrapper>
              <DataWrapper>
                <ProfileData>{t("Location")}:</ProfileData>
                <ProfileValue>
                  {app.city_name}, {app.state_name}, {app.country_name}
                </ProfileValue>
              </DataWrapper>
            </div>

            <div className="wrapper23">
              <DataWrapper>
                <ProfileData>{t("Nationality")}:</ProfileData>
                <ProfileValue>{app.nationality}</ProfileValue>
              </DataWrapper>
              <DataWrapper>
                <ProfileData>{t("Mobile No")}:</ProfileData>
                <ProfileValue>{app.mob_number}</ProfileValue>
              </DataWrapper>

            </div>
          </div>
          <HRLine />
          <DocumentWrapper>
            <div className="wrapper2">
              <div className="Document1">
                <Images src={Eye} alt="img" />
                <DocumentContent
                  onClick={navigateAssesment}
                  style={{ cursor: "pointer" }}
                >
                  {t("View Assessment")}{" "}
                </DocumentContent>
              </div>
              <div className="Document2">
                <Images src={Eye} alt="img" />
                <DocumentContent
                  onClick={navigateCv}
                  style={{ cursor: "pointer" }}
                >
                  {t("View CV")}{" "}
                </DocumentContent>
              </div>
            </div>
            <div className="wrapper3">
              <div className="Document2">
                <Img src={Document} alt="img" />
                <DocumentContent2
                  onClick={navigateDoc}
                  style={{ cursor: "pointer" }}
                >
                  {t("Supporting Document 1")}{" "}
                </DocumentContent2>
              </div>
              {/* <div className="Document2">
                <Img src={Document} alt="img" />
                <DocumentContent2
                  onClick={navigateDoc2}
                  style={{ cursor: "pointer" }}
                >
                  {t("Supporting Document 2")}{" "}
                </DocumentContent2>
              </div> */}
            </div>
          </DocumentWrapper>
        </CardsWrapper>
      ) : (
        ""
      )}
    </div>
  );
};

export default ProfileModal;
