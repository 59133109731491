import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, getDatasetAtEvent, getElementAtEvent, getElementsAtEvent } from "react-chartjs-2";
import { customLabelsPlugin } from "../commonFunction";
import { cloneDeep } from "lodash";
import mystyles from '../../charts//multichartcss.module.css';
import { Col } from "react-bootstrap";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
  cutout: "75%",
  rotation: 250,
  aspectRatio: 1,
  layout: {
    padding: {
      left: 50,
      right: 50,
      top: 50,
      bottom: 50,
    },
    margin: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  responsive: true,
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  plugins: {
    customLabels: {
      labelColor: "#525252",
      arrowColor: "#525252",
      font: "14px 'General Sans-bold'",
    },
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
    },
  },
  title: {
    display: false,
  },
};

export const noDataFoundPlugin = {
  afterDraw: function (chart) {
    if (chart.data.datasets[0].data.every(item => item === 0)) {
      let ctx = chart.ctx;
      let width = chart.width;
      let height = chart.height;

      ctx.save();
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';
      ctx.font = "normal normal 400 22px/24px Inter";
      ctx.fillStyle = "#615E83";
      ctx.fillText('No Data Found', width / 2, height / 2);
      ctx.restore();
    }
  }
}

export default function DoughnutChart({
  data,
  gradient = [],
  optionsOverride,
  customStyles = {},
  onClick = () => { },
  showlabel
}) {
  let finalOptions = cloneDeep(defaultOptions);
  const chartRef = React.useRef(null);
  const [chartData, setChartData] = React.useState(data);
  const [gradientStart, gradientEnd] = gradient;
  React.useEffect(() => {
    const chart = chartRef.current;
    const ctx = chart && chart.ctx;

    if (ctx && gradient.length) {
      
      const gradient = ctx.createLinearGradient(
        chart.width / 2,
        0,
        chart.width / 2,
        chart.height
      );
      gradient.addColorStop(0, gradientStart);
      gradient.addColorStop(1, gradientEnd);
      setChartData({
        ...data,
        datasets: data.datasets.map((dataset) => ({
          ...dataset,
          backgroundColor: gradient,
          hoverBackgroundColor: gradient,
        })),
      });
    }
  }, []);
  if (optionsOverride) {
    finalOptions = { ...finalOptions, ...optionsOverride };
  }

console.log("finll;",finalOptions)
finalOptions.plugins.legend.display =true;
  const onClickEvent = (event) => {
    const { current: chart } = chartRef;

    if (!chart) {
      return;
    }
    const element = getElementAtEvent(chart, event);
    if (!element.length) return;

    const { datasetIndex, index } = element[0];

    console.log(data.labels[index], data.datasets[datasetIndex].data[index]);
    onClick({ label: data.labels[index], data: data.datasets[datasetIndex].data[index] });
  };
console.log("sss",showlabel)
  return (
    <Col lg="12">
    
                        <Doughnut
      data={chartData}
      style={customStyles}
      ref={chartRef}
      options={finalOptions}
      plugins={[customLabelsPlugin, noDataFoundPlugin]}
      onClick={onClickEvent}
    />
     <div  className="d-flex justify-content-center chart-type-toggle" style={{alignContent: 'center',paddingTop:"10px",
    flexWrap: 'wrap'}}>
                                                 {
                                                   showlabel ? chartData?.labels?.map((label,index)=>{
                                                        return <div style={{display:"contents"}}><span style={{backgroundColor:chartData.datasets[0].backgroundColor[index]}} className={mystyles.dot}></span> <p className={mystyles.label} >{label}</p></div>
                                                    })
                                                   :chartData?.labels?.map((label,index)=>{
                                                    return <div style={{display:"contents",visibility:"hidden"}}><span style={{backgroundColor:chartData.datasets[0].backgroundColor[index]}} className={mystyles.dot}></span> <p className={mystyles.label} >{label}</p></div>
                                                })
                                                 }       

                        </div>
    </Col>
   
  );
}
