import { useState } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateLoader } from "../../pages/myCompany/reducer";
import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schemaObj } from "./staticContent";
import ErrorField from "../errorField/errorField";
import {
  onCompanyBrandingArticleUpdate,
} from "../../services/apiCalls";

import Loader from "../loader/loader";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const ModalWrapper = styled.div`
  padding: 10px;
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;




export default function UpdateCompanyBrandingArticles(props) {
  
  const company= props.companyDetails;
  const onClose = props.onClose;
  const {t,} = useTranslation();

  const defaultVal = {
    description: undefined,
    article_url: undefined
  };

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const editLoading = useSelector((state) => state.company.loading);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);


  const {
    control,
   
    watch,
    setError,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schemaObj),
  });

  const formVal = watch();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLoading(true);
    const { data, status } = await onCompanyBrandingArticleUpdate ({
        code: org_code,
        data: formVal,
      });
    
    if (data && data.status === 1 ) {
      toast.success(t(`Article update successfully.`), {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(updateLoader(!editLoading))
      props.onClose();
      setFormErrors("");
    } else {
      toast.error(`${t(data.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };
  const regex = /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;

  
  
  return (
    <CustomModal
      open={props.open}
      onCloseModal={props.onClose}
      styles={{ modal: { overflowY: "unset" } }}
    >
      <ModalWrapper>
        <Grid container>

          <Grid item lg={12}>
            <h3 style={{ width: "auto" }}>{company && company.name}</h3>
            
            <FormWrapper>
             
              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t('Article Description')}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      style={{}}
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={company.description} />
                      )}
                    />
                    {errors.description && <ErrorField err={errors.description.message} />}
                  </Grid>
                </Grid>
              </SingleField>

              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t('Article Url')}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      control={control}
                      name="article_url"
                      type="url"
                      render={({ field }) => (
                        <Input {...field}    placeholder="e.g., https://www.organisation.com"  />
                      )}
                    />
                    {formVal.article_url && !regex.test(formVal.article_url) && <ErrorField err={t(
          "please enter the correct url eg:https://www.google.com"
        )} />}
                  </Grid>
                </Grid>
              </SingleField>


              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                  marginRight: "1%",
                }}
              >
                {formErrors && <ErrorField err={formErrors} />}
                {loading ? (
                  <Loader />
                ) : (
                  <Button name={t('Confirm')} disabled={(formVal.description && formVal.article_url && regex.test(formVal.article_url)) ? false : true} onClick={onSubmit} />
                )}
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t('Cancel')}
                  style={{
                    marginLeft: "10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
