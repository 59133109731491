import React from 'react';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import Male from "../../../assets/svg/male.svg";
import Female from "../../../assets/svg/female.svg";
import { cloneDeep } from 'lodash';
import { Utils } from '../utilities';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const defaultOptions = {
    indexAxis: 'y',
    barPercentage: 0.1,
    responsive: true,
    maintainAspectRatio: false,
    borderWidth: 1,
    maxBarThickness: 15,
    barThickness: 15,
    categoryPercentage: 2,
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 20,
        },
        margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            color: '#525252',
            font: {
                family: 'Oswald',
                style: 'normal',
                weight: 400,
                size: 14,
                lineHeight: 16,
            },
            padding: {
                left: 60,
            },
            formatter: function (value, context) {
                return value + '%';
            },
            align: 'right',
            anchor: 'end',
        },
        tooltip: {
            enabled: true,
            yAlign: 'bottom',
            xAlign: 'center',
            displayColors: false,
            padding: 10,
            callbacks: {
                label: (context) => {
                    return Utils.intToString(context.dataset.dataBackup[context.dataIndex]);
                },
                title: (context) => {
                    return '';
                }
            }
        }
    },
    scales: {
        y: {
            beginsAtZero: true,
            display: false,
            grid: {
                display: false,
            },
            barPercentage: 0.5,
            categoryPercentage: 0.1,
        },
        x: {
            display: false,
            beginsAtZero: true,
            max: 150,
            grid: {
                display: false,
            },
            barPercentage: 0.5,
            categoryPercentage: 0.1,
        },
    },
    title: {
        display: false,
    },
};
const female = new Image();
const male = new Image();
female.src = Female;
male.src = Male;

const genderImagePlugin = {
    id: 'genderImagePlugin',
    afterDatasetDraw(chart, args, options) {
        const { ctx, data: { datasets: [{ dataBackup }] } } = chart;
        ctx.save();
        ctx.drawImage(male, chart.getDatasetMeta(0).data[0].x + 7, chart.getDatasetMeta(0).data[0].y - 10, 20, 20);
        ctx.drawImage(female, chart.getDatasetMeta(0).data[1].x + 7, chart.getDatasetMeta(0).data[1].y - 10, 20, 20);

        ctx.font = "normal normal 700 14px/16px Oswald";
        ctx.fillStyle = "#615E83";
        ctx.fillText(Utils.intToString(dataBackup[0]), 5, chart.getDatasetMeta(0).data[0].y - 10);
        ctx.fillText(Utils.intToString(dataBackup[1]), 5, chart.getDatasetMeta(0).data[1].y - 10);

    }
}


export default function GenderRatioBarChart({ data, gradients = [], optionsOverride }) {

    let finalOptions = defaultOptions;
    const chartRef = React.useRef(null);
    const dataBar = cloneDeep(data.datasets[0].data);
    data.datasets[0].dataBackup = cloneDeep(data.datasets[0].data);
    const chartDataArrSum = dataBar.reduce(function (a, b) { return a + b; }, 0);
    const data2 = cloneDeep(data);
    data2.datasets[0].data = dataBar.map(x => x ? +((x / chartDataArrSum * 100).toFixed(2)) : 0);

    const [chartData, setChartData] = React.useState(data2);



    React.useEffect(() => {
        const chart = chartRef.current;
        const ctx = chart && chart.ctx;

        if (ctx && gradients.length) {
            setChartData({
                ...data,
                datasets: data.datasets.map((dataset, index) => {
                    
                    const linearGradients = gradients.map((gradient) => {
                        const [gradientStart, gradientEnd] = gradient;
                        const linearGradient = ctx.createLinearGradient(chart.width / 2, 0, chart.width / 2, chart.height);
                        linearGradient.addColorStop(0, gradientStart);
                        linearGradient.addColorStop(1, gradientEnd);
                        return linearGradient;
                    })
                    return ({
                        ...dataset,
                        backgroundColor: linearGradients,
                        hoverBackgroundColor: linearGradients,
                    })
                }),
            });
        }
    }, []);
    if (optionsOverride) {
        finalOptions = { ...defaultOptions, ...finalOptions };
    }


    return <div>
        <Bar data={data2}
            ref={chartRef}
            // width={"600px"}
            // height={"400px"}
            options={finalOptions} style={{
                height: '123px'
            }} plugins={[genderImagePlugin]} />
    </div >;
}