import { useEffect, useRef, useState } from "react";
import CustomButton from "../../../components/button/button";
import SkillCard from "./skillCard";
import styled from "styled-components";
import {
  deleteSkills,
  getSkills,
  saveSkills,
  getAllSkills,
} from "../../../services/apiCalls";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { toast } from "react-toastify";
import { t } from "i18next";

const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;


const FormGroup = styled.div`
  min-width: 240px;
  width: 100%;
  label {
    width: unset !important;
  }
`;
const GridWrapper = styled.div`
  display: grid;
  margin-bottom: 30px;
`;
const AcademicGridWrapper = styled(GridWrapper)`
  grid-template-columns: ${(props) =>
    props.template ? props.template : "3fr 1fr"};
  grid-column-gap: 20px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
  label {
    position: relative;
    //width: -webkit-fill-available;
  }
`;



const Skills = (props) => {
  const [addSkills, setAddSkills] = useState(false);
  const [existingSkillsList, setSkills] = useState([]);
  const [selectedSkill, setSelectedSkill] = useState({});
  const [skill, setSkill] = useState("");
  const [isEditable, setIsEditable] = useState(true);
  const typeaheadRef = useRef(null);
  useEffect(() => {
    fetchSkills();
  }, []);

  const dropdownChange = async (val) => {
    setSelectedSkill(val[0]);
  };

  const handleSave = async () => {
    if (!addSkills) {
      setAddSkills(true);
      const payload = {
        data: [selectedSkill.label],
      };
      var temp_arr = [];
      existingSkillsList.map((list) => temp_arr.push(list.label));
      if (temp_arr.includes(selectedSkill.label)) {
        toast.error("skill already added", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setAddSkills(false);
      } else {
        const { data, status } = await saveSkills(props.id, payload);
        if (status === 200 && data.status === 1) {
          fetchSkills();
          props.callback();
        }
        setSelectedSkill("");
        setAddSkills(false);
        typeaheadRef.current.clear();
      }
    }
  };

  const deleteSkill = async (id) => {
    const { data, status } = await deleteSkills(id);
    if (status === 200 && data.status === 1) {
      fetchSkills();
      resetForm();
    }
  };

  const resetForm = () => {
    setAddSkills(false);
    setSkill("");
  };

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const fetchAllSkills = async (searchKey) => {
    const { data, status } = await getAllSkills(searchKey);
    if (status === 200 && data.status === 1) {
      const options = data.data.map((x) => {
        return { ...x, label: x.Example, value: x.Id };
      });
      setOptions(options);
      setIsLoading(false);
    }
  };

  const fetchSkills = async (searchKey) => {
    const { data, status } = await getSkills(props.id);
    if (status === 200 && data.status === 1) {
      if (data.data.length < 30) {
        setIsEditable(true);
      } else if (data.data.length >= 30) {
        setIsEditable(false);
      }
      const existingSkills = data.data;
      const existingSkillsList = existingSkills.map((x) => {
        return { ...x, label: x.title, value: x.id };
      });
      setSkills(existingSkillsList);
    }
  };

  const filterBy = () => true;
  const handleSearch = (query) => {
    setIsLoading(true);
    fetchAllSkills(query);
  };


  return (
    <>
      <FormGroup>
        <AcademicGridWrapper>
          <AsyncTypeahead
            style={{ width: "100%", height: "80%" }}
            filterBy={filterBy}
            id="add-skills"
            isLoading={isLoading}
            labelKey="label"
            minLength={3}
            ref={typeaheadRef}
            onSearch={handleSearch}
            value={selectedSkill}
            options={options}
            placeholder={t("Search for a skill ...")}
            onChange={(val) => dropdownChange(val)}
          />
          <div className="alignSpaceBetween">
            <CustomButton
              style={{ marginTop: "0px" }}
              onClick={handleSave}
              margin="15px 10px 15px 0px"
              width="8em"
              fontSize="14px"
              lineHeight="18px"
              minWidth="0"
              name="Save"
              disabled={addSkills}
            />
          </div>
        </AcademicGridWrapper>
      </FormGroup>
      <FlexWrapper style={{ flexWrap: "wrap" }}>
        {existingSkillsList.length > 0 &&
          existingSkillsList.map((skill, index) => (
            <SkillCard
              id={skill.value}
              label={skill.label}
              delete={(id) => deleteSkill(id)}
            />
          ))}
      </FlexWrapper>
    </>
  );
};
export default Skills;
