import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
// import MainLayout from "../../../layouts/mainLayout/mainLayout";
import * as Mui from "@material-ui/core";
import { toast } from "react-toastify";
import { Colors } from "../../utilities/colors"
import { Col, Row, Dropdown } from "react-bootstrap";
import { PDFExport } from "@progress/kendo-react-pdf";
import "./my-cv-preview.css";
import { connect, useDispatch, useSelector } from "react-redux";
import { useReactToPrint } from "react-to-print";
import logo from "../../assets/svg/mynextLogo.svg";
import moment from "moment";
import { useTranslation } from "react-i18next";
import MainLayout from "../../layouts/mainLayout/mainLayout";
// import MyAssessmentComponent from "../jobPortal/assessmentForImage"
import html2canvas from "html2canvas";
import { TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator } from "@material-ui/lab";
import { isValidArray } from "../../utilities/validators";
import TalentTimeline from "./talentPreview";
import TalentCVCard from "../../components/talentCVCard/talentCVCard";
import { Image } from "../../components/talentProfile/common";



const StrengthWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Elipsis = styled.div`
  height: 6px;
  width: 6px;
  background-color: #aaaaaa;
  border-radius: 50%;
  display: inline-block;
`;

const DividerLine = styled.div`
  height: 1px;
  background-color: #d9d9d9;
  margin: 8px 0;
`;
const HrLine = styled.div`
  width: 100%;

  height: 10px;
  border-bottom: 0.6px solid #c4c4c4;
`;
const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const InternDescription = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3em;
  color: ${Colors.inputTextDark};
  margin-left: 30px;
`;
// const InternDescription = styled.div`
//   font-family: "General Sans";
//   font-style: normal;
//   font-weight: normal !important;
//   font-size: 16px;
//   line-height: 22px;
//   margin-bottom: 3em;
//   color: ${Colors.inputTextDark};
//   margin-left: 30px;
// `;
const useStyles = Mui.makeStyles((theme) => ({
  avatarStyle: {
    width: theme.spacing(20),
    height: theme.spacing(20),
    borderRadius: 100,
  },
}));

const previewDetails = {
  overview: {
    mob_number: "",
    email: "",
    insti_name: "",
    scope_of_study: "",
    city: "",
    curr_country: "",
    state: "",
    display_name: "",
    profile_pic: "",
    full_name: "",
    talent_status: "",
    university: "",
    insti_location_status: "",
    insti_country_status: "",
  },
  summary: "",
  employment: [],
  internship: [],
  educationDetails: [],
  eventDetails: [],
};

const MyCvPreview = (props) => {
  const workCompetencyConstants = [
    {
      key: 0,
      title: "Embracing & Managing",
      description:
        "Adjusts well to uncertainties. Skillfully handles pressure and difficulties.",
    },
    {
      key: 1,
      title: "Connecting & Portraying",
      description:
        "Communicates and networks effectively. Successfully persuades and influences others. Relates to others in a confident, relaxed manner.",
    },
    {
      key: 2,
      title: "Supervising & Determining",
      description:
        "Takes charge and assumes authority. Takes the initiative, provides guidance, and accepts accountability.",
    },
    {
      key: 3,
      title: "Ambitious & Result-Oriented",
      description:
        "Committed to setting personal goals and highly motivated to achieving results. Stimulated by accomplishments and the recognition of effort. Demonstrates knowledge of commerce, business and economic affairs. Solicits possibilities for professional and personal growth.",
    },
    {
      key: 4,
      title: "Innovating & Envisioning",
      description:
        "Works well in circumstances where being receptive to diverse perspectives is necessary. Pursues self improvement by seeking new knowledge. Uses imagination and ingenuity to overcome difficulties and issues. Regards situations comprehensively and systematically. Encourages positive organizational transformation.",
    },
    {
      key: 5,
      title: "Evaluating & Resolving",
      description:
        "Demonstrates evidence of analytical clarity. Competent at reaching the core of difficult challenges. Effectively uses own expertise. Adopts new technology quickly. Writes clearly and effectively.",
    },
    {
      key: 6,
      title: "Facilitating & Collaborating",
      description:
        "Supports others and demonstrates respect and favorable regard for them. Focuses on the needs of others and works well with various parties. Possesses reliable characteristics that are compatible with those of the organization.",
    },
    {
      key: 7,
      title: "Coordinating & Implementing",
      description:
        "Prepares for work in advance and organizes work systematically. Executes work by adhering to instructions. Emphasizes the fulfillment of client needs and provides service or product according to established guidelines.",
    },
  ];
  const { profileDetails, dataUser, oceanReport, futureOfWork, isMyCvDownLoad, downloadComplete, handleCvPrint, userDetails } = props;
  console.log("asdfghjkl",props);
  const classes = useStyles();
  const resumePdfTemplateRef = useRef();
  // const resumeData = useSelector((state) => state.profile.data.resume);
  const [resumeDetails, setResumeDetails] = useState(previewDetails);
  const dispatch = useDispatch();
  const [downloadOne, setDownloadOne] = useState(false);
  const [image, setImage] = useState(null);
  const [image2, setImage2] = useState(null);
  // const talentData = useSelector((state) => state?.talent?.userData);
  const { t } = useTranslation();
  const cities = useSelector((state) => state.masterData.cities);
  const scopeOfStudies = useSelector(
    (state) => state.masterData.scopeOfStudies
  );
  const strengths = [
    { name: "Visionary", color: "#8F5DA4" },
    { name: "Problem-solving", color: "#A45D90" },
    { name: "Building systems and organizations", color: "#D9649D" },
    { name: "High Standards and quality", color: "#5F55A5" },
    { name: "Decisive and proactive", color: "#5566A5" },
  ];
  const pdfExportComponent = React.useRef(null);

  const getCityValue = (val) => {
    const findCity = cities?.find((e) => e.Id == parseInt(val));
    return findCity?.Name;
  };


  useEffect(async () => {
    console.log("fuuuuuu", futureOfWork, "occccc", oceanReport)
    // captureImage();
    // const captureImage = async () => {
    //   const canvas = await html2canvas(document.getElementById('futreofWorkComponent'));
    //   const imageData = canvas.toDataURL();
    //   console.log("imageData",image)
    //   setImage(imageData);
    // };

    // captureImage();
    // Updated 12-07-2022 Code
    // await fetchAllDetails().then(async () => {
    //   await fetchResumeData();
    // if(image && image2){
    console.log("profilwDetalsis", profileDetails.profile_pic)

    await downloadFile();

    // }
    // });

    //Old Code
    // if (
    //   resumeDetails?.overview?.scope_of_study !== "" &&
    //   isMyCvDownLoad &&
    //   count === 0
    // ) {
    //   count = count + 1;  
    // }
  }, [isMyCvDownLoad, image]);

  const monthDiff = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    let months;
    months = (d2.getFullYear() - d1.getFullYear()) * 12;
    months -= d1.getMonth();
    months += d2.getMonth();
    return months <= 0 ? 0 : months;
  };

  const notification = (type, message) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const sortData = (list) => {
    const result =
      list.length > 0 &&
      list.sort((a, b) => {
        return b.end_date - a.end_date;
      });
    return result;
  };


  const handlePrint = useReactToPrint({
    content: () => resumePdfTemplateRef.current,
  });

  useEffect(() => {
    if (resumeDetails?.overview?.scope_of_study && handleCvPrint) {
      handlePrint();
      downloadComplete(false);
    }
  }, [handleCvPrint, resumeDetails?.overview?.scope_of_study]);

  const downloadFile = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      isMyCvDownLoad && downloadComplete(true);
    }
  };

  const getDuration = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    const date1 = moment(d1).format("DD/MM/YYYY");
    const date2 = moment(d2).format("DD/MM/YYYY");
    var startDate = new Date(
      date1.split("/")[2],
      date1.split("/")[1] - 1,
      date1.split("/")[0]
    );
    var endDate = new Date(
      date2.split("/")[2],
      date2.split("/")[1] - 1,
      date2.split("/")[0]
    );

    var diff = new Date(endDate - startDate);
    var diffResult = (diff.getFullYear() - 1970) * 12 + diff.getMonth();
    if (diffResult === 0) {
      return t("1 Month");
    } else {
      return `${diffResult}` + t(" Months");
    }
  };
  //   const getUniName = (talent_status, insti_country_status, insti_location_status, insti_name, university) => {
  //     if (talent_status === "studying" || talent_status === "internship") {
  //       if (insti_location_status === 1) { //study locally
  //         return insti_name;
  //       } else { //study abroad
  //         return university;
  //       }
  //     } else if (talent_status === "employed" || talent_status === "unemployed") {
  //       if (insti_country_status === 1) { //study locally
  //         return insti_name;
  //       } else { //study abroad
  //         return university;
  //       }
  //     }
  //   };

  const ImageWrapper = styled.div`
  width: 220px;
  padding: .5rem;
  .changeImage {
    font-family: General Sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #d04c8d;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    @media (max-width: 412px) {
      justify-content: start;
   }
  }
  p{
    @media (max-width: 802px) {
       width: 240px;
    }
  }
  @media (max-width: 767px) {
    // width: 80px;
    // padding:0px;
  }
  @media (min-width: 1024px) and (max-width: 1440px) {
    position: relative;
    top: 45px;
    right:20px;
  }
`;
  const PageTemplate = (props) => {
    return (
      <footer
        className="font-color-light-grey font-family-general-sans-medium"
        style={{ fontSize: "14px" }}
      >
        <div style={{ position: 'absolute', bottom: '15px', left: '40px' }}>© mynext. {t("All Rights Reserved")}</div>
        <div style={{ position: 'absolute', bottom: '15px', right: '40px' }}>
          <span>{t("Published")}&nbsp;&nbsp;</span>
          <span>
            {new Date().getDate()}&nbsp;
            {new Date().toLocaleString("default", { month: "short" })}
            &nbsp;
            {new Date().getFullYear()}
          </span>
        </div>
      </footer>
    );
  };
  // useEffect(()=>{
  //   const captureImage = async () => {
  //     const canvas = await html2canvas(document.getElementById('futreofWorkComponent'));
  //     const imageData = canvas.toDataURL();
  //     console.log("imageData",image)
  //     setImage(imageData);
  //   };

  //   captureImage();
  // },[])
  return (
    <MainLayout>
      <section className="min-height-80-percent font-family-general-sans">
        <div id="cv-loader">
          <div className="loader-container">
            <div className="common-loader">
              <div className="spinner">
                <div className="bounce1"></div>
                <div className="bounce2"></div>
                <div className="bounce3"></div>
              </div>
            </div>
          </div>
        </div>
        <PDFExport
          fileName={`${"myCV"}-${new Date().toISOString().split("T")[0]
            }`}
          // forcePageBreak='-'
          scale={0.65}
          paperSize="A4"
          margin={"0.6cm"}
          pageNum={5}
          keepTogether={".dont-split"}
          title={`${"MYCV"}-Resume`}
          ref={pdfExportComponent}
          pageTemplate={PageTemplate}
        >
          <div
            ref={
              resumePdfTemplateRef
            }
            id="praveen"
            className="preview-resume-container cursor-pointer bg-white max-width-900px mx-auto"
          >
            {/* <PdfContainer createPdf={createPdf}> */}
            <div className="d-flex justify-content-end cursor-pointer resume-download-floating-button pt-2 me-3">
              <Dropdown className="dropdown__toggle resume-download-floating-button">
                <Dropdown.Toggle variant="">
                  <Elipsis />
                  &nbsp;
                  <Elipsis />
                  &nbsp;
                  <Elipsis />
                </Dropdown.Toggle>
                <Dropdown.Menu className=" font-family-general-sans-medium">
                  <Dropdown.Item
                    onClick={() => {
                      //   downloadFile();
                    }}
                  >
                    {t("Download")}
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
            <div className=" inherit-parent-width d-flex justify-content-end">
              <img
                className="pe-4 mt-2"
                width={"auto"}
                height="40"
                alt=""
                src={logo}
              />
            </div>
            <section className="pt-4 pe-4 ps-4">
              <div className="d-flex justify-content-center">
                <ImageWrapper style={{ marginTop: "50px" }}>
                  <Mui.Avatar
                    src={
                      profileDetails.profile_pic
                    }
                    alt="Profile pic"
                    className={classes.avatarStyle}
                  />
                </ImageWrapper>
                <div className="m-auto w-75">
                  <div
                    className="preview-resume-title"
                    style={{ lineHeight: "32px", marginLeft: "50px" }}
                  >
                    {userDetails.first_name}
                  </div>
                  <DividerLine />

                  {/* <img height={"485.04"}  ={"867"} src={profileDetails.profile_pic} alt="Component Image" /> */}
                  <Row className="pb-2">

                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Scope of Study")}
                      </div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {scopeOfStudies.find(
                          (x) => x.Id === parseInt(userDetails.other_details.scope)
                        )?.Name}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Education Institution")}
                      </div>
                    </Col>
                    <Col>
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {profileDetails.insti_name_abbr}
                        {/* {getUniName(resumeDetails?.overview.talent_status, resumeDetails?.overview?.insti_country_status, resumeDetails?.overview?.insti_location_status, resumeDetails?.overview?.insti_name, resumeDetails?.overview?.university)} */}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>{t("Address")}</div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {getCityValue(profileDetails?.city)},&nbsp;{" "}
                        {profileDetails?.curr_country_abbr}-{" "}
                        {profileDetails?.postalcode}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Primary e-mail id")}
                      </div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {profileDetails.email}
                      </div>
                    </Col>
                  </Row>
                  <Row className="pb-2">
                    <Col md="5">
                      <div className="preview-resume-sub-title" style={{ marginLeft: "50px" }}>
                        {t("Primary mobile no")}
                      </div>
                    </Col>
                    <Col md="">
                      <div className="preview-resume-text" style={{ marginLeft: "50px" }}>
                        <span className=" font-color-pink me-2">:</span>
                        {userDetails.other_details?.mob_number}
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </section>
            {/* Summary section */}
            {dataUser?.summary?.summary && (
              <TalentCVCard
                titleClassName="preview-resume-sub-title dont-split"
                title={t("Summary")}
                showLock={false}
                childClassName="preview-resume-text"
              >
                <InternDescription
                  dangerouslySetInnerHTML={{
                    __html: dataUser?.summary?.summary.replace(
                      new RegExp("\n", "g"),
                      "<br>"
                    ),
                  }}
                />
              </TalentCVCard>
            )}
            {isValidArray(dataUser?.employement_details) && (
              <TalentCVCard
                titleClassName="preview-resume-sub-title dont-split"
                title={t("Employment Details")}
                showLock={false}
              >
                <TalentTimeline data={dataUser?.employement_details} />
              </TalentCVCard>
            )}

            {isValidArray(dataUser?.internship_details) && (
              <TalentCVCard

                titleClassName="preview-resume-sub-title"
                title={t("Internship Details")}
                showLock={false}
              >
                <TalentTimeline data={dataUser?.internship_details} />
              </TalentCVCard>
            )}

            {isValidArray(dataUser?.education) && (
              <TalentCVCard
                titleClassName="preview-resume-sub-title"
                title={t("Education Details")}
                showLock={false}
              >
                {dataUser?.education.map((m, i) => {
                  return (
                    <div>
                      <TimelineItem
                        key={i}
                        className="remove-timeline-before-padding"
                      >
                        <TimelineSeparator>
                          <TimelineDot
                            style={{ background: i === 0 ? "#D04C8D" : "" }}
                          />
                          {i < dataUser.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent
                          className="pt-0 text-capitalize "
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="">
                            <div className=" font-family-general-sans-medium font-color-dark-grey fw-500 pb-1">
                              {m.degree}
                            </div>
                            <div className="font-family-general-sans-medium font-color-pink fw-500 pb-1">
                              {m.institution}
                            </div>
                            <div className="font-family-general-sans-medium font-color-light-grey fw-500">
                              {t("Completion year")} : &nbsp;&nbsp;
                              {m.completion_year}
                            </div>
                            <div className="font-family-general-sans-regular font-color-light-grey">
                              {m.description}
                            </div>
                          </div>

                        </TimelineContent>
                      </TimelineItem>
                    </div>
                  );
                })}
              </TalentCVCard>
            )}
            
            {isValidArray(dataUser?.event_details) && (
              <TalentCVCard
                titleClassName="preview-resume-sub-title"
                title={t("Events & Projects")}
                showLock={false}
              >
                {dataUser?.event_details.map((m, i) => {
                  return (
                    <>
                      <TimelineItem
                        key={i}
                        className="remove-timeline-before-padding"
                      >
                        <TimelineSeparator>
                          <TimelineDot
                            style={{ background: i === 0 ? "#D04C8D" : "" }}
                          />
                          {i < dataUser.length - 1 && <TimelineConnector />}
                        </TimelineSeparator>
                        <TimelineContent
                          className="pt-0 text-capitalize "
                          style={{
                            display: "flex",
                            width: "100%",
                            justifyContent: "space-between",
                          }}
                        >
                          <div className="w-75">
                            <div className=" font-family-general-sans-medium font-color-dark-grey fw-500 pb-1">
                              {m.event_title}
                            </div>
                            <div className="font-family-general-sans-medium font-color-light-grey fw-500">
                              {m.description}
                            </div>
                          </div>
                          <p className="font-family-general-sans-regular font-color-light-grey">
                          
                            {getDuration(m.start_date, m.end_date)}
                          </p>
                        </TimelineContent>
                      </TimelineItem>
                    </>
                  );
                })}
              </TalentCVCard>
            )}

            {isValidArray(dataUser?.skills) && (
              <TalentCVCard
                titleClassName="preview-resume-sub-title"
                title={t("Skills")}
                showLock={false}
              >
                <StrengthWrapper>
                  {dataUser?.skills.map((m, i) => {
                    return (
                      <div style={{ textTransfrom: "capitalize" }}>
                        <StrengthChip name={m.title} color={m.color} key={i} />
                      </div>
                    );
                  })}
                </StrengthWrapper>
              </TalentCVCard>
            )}

          </div>
        </PDFExport>
      </section>
    </MainLayout>
  );
};

function mapStateToProps(state) {
  return {
    talent: state.talent,
    profile: state.profile,
  };
}
const mapDispatchToProps = (dispatch) => {
  return {
    // dispatching plain actions
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(MyCvPreview);
// const Foo = <StaticPdf />;

function StrengthChip(props) {
  // styling starts
  const CustomBtn = styled.button`
    width: ${props.width ? props.width : "fit-content"};
    font-family: "general-sans-medium";
    text-transform:"capitalize",
    font-style: normal;
    background: ${(props) => (props.color ? props.color : "#D9649D")};
    border-radius: 128px;
    outline: none;
    border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
    font-weight: 500;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "22px")};
    color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
    padding: 10px 20px;
    margin: ${(props) => (props.margin ? props.margin : "15px 15px 0 0")};
    min-width: ${(props) => (props.minWidth ? props.minWidth : "fit-content")};
    cursor: pointer;
    @media (max-width: 500px) {
      width: 100%;
    }
  `;
  // styling ends

  return (
    <CustomBtn secondary={props.secondary} margin={props.margin} {...props}>
      {props.name}
    </CustomBtn>
  );
}















