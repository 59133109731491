import React, { useState, useEffect } from 'react';
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import InternTypeDetails from './internTypeDetails';
import InternDetails from './internDetails';
import UpdateCompletionOfInternshipLoe from './updateCompletionOfInternshipLoe';
import dummyApi from '../MySIPDirectHiring/dummyApiLoe';
import { useLocation } from "react-router-dom";
import { getInternshipDetails, getInternDetails, getAdvertisedInternDetails } from '../../services/apiCalls';
import { useSelector } from "react-redux";
const ViewInternshipWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 15px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;

const HeaderDiv = styled.div`
margin-bottom: 45px;
`;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;

const SubHeaderText = styled.h3`
font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const TabText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: ${props => (props.isActive ? Colors.pink : Colors.greyText )};
  
`;

const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 100%;
    margin-top: 10px
}
`;

const Button = styled.button`
{
    
    text-align: center;
    background: none;
    border: none;
    padding-right:8%;
    padding-left:8%;
    margin-bottom:-10px;
    border-bottom: ${props => (props.isActive ? '3px solid '+Colors.pink : '#000')};

}`

const TabsContainer = styled.div`
  display: flex;
`;

const Tab = styled.div`
  padding: 10px 20px;
  cursor: pointer;
  background-color: ${props => (props.isActive ? '#007bff' : 'transparent')};
  color: ${props => (props.isActive ? '#fff' : '#333')};
  border-bottom: ${props => (props.isActive ? 'none' : '1px solid #ccc')};
`;

const TabContent = styled.div`
  padding: 20px;
`;

// function InternshipList() {
//   

export default function ViewInternship() {
    const [internships, setInternships] = useState([]);
    const location = useLocation();
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
    const searchParams = new URLSearchParams(location.search);
    const internshipId = searchParams.get('id');
    const [internDetails, setInternDetails] = useState([]);
    const [isAdvertised, setIsAdvertised] = useState(false);

    const [fromInternship, setfromInternship] = useState();


    useEffect(() => {
      const fetchData = async () => {
        try {
          const { data } = await getInternshipDetails(org_code, internshipId);
          setfromInternship(data?.data.intern_type)
          if (data?.status === 1) {
            setInternships(data?.data);
            console.log("calling from getIntershipDetails", data);

            if (data?.data.intern_type === "Advertise") {
              setIsAdvertised(true);
              const { data } = await getAdvertisedInternDetails(internshipId);
              if (data) {
                setInternDetails(data?.results);
                console.log("calling from Advertised", data);
              } else {
                console.error("Error fetching Advertised data:", data);
              }
            } else {
              const { data } = await getInternDetails(internshipId);
              if (data) {
                setInternDetails(data?.results);
                console.log("calling from nonAdvertised", data);
              } else {
                console.error("Error fetching nonAdvertised data:", data);
              }
            }
          }
        } catch (error) {
          // Handle errors if needed
          console.error("Error fetching data:", error);
        }
      };
    
      fetchData(); // Call the async function inside the useEffect
    
      // Dependency array ensures that useEffect re-runs when org_code or internshipId changes
    }, [org_code, internshipId]);
    

 


  const getInternTypeName = (intern_type) =>{

    switch(intern_type){
      
      case 'Advertise':
      case 'advertise':
      case 'sip':
        return 'MySIP Advertised';

      case 'Direct Hiring':
        return 'MySIP Direct Hiring';

      case 'general':
      case 'General Internship':
        return "General Internship"
    }
    
  }

  const getDirectHiringType = (type) =>{
    switch(type){
      case 'on-going':
        return "On-Going";
      
      case 'completed':
        return "Completed";
    }
  }
console.log(internDetails, "INTERNS DETAILS")
console.log(internships,"Internships details")
    const [activeTab, setActiveTab] = useState('InternTypeDetails');

    const handleTabClick = (tabName) => {
      setActiveTab(tabName);
    };
  
    return (
        <MainLayout>
            <ViewInternshipWrapper>
                <HeaderDiv>
                  <HeaderText>{internships.title}</HeaderText>
                  {internships.intern_type === "Direct Hiring"? (
                    <SubHeaderText>{getInternTypeName(internships.intern_type) +" ("+ getDirectHiringType(internships.direct_hiring_type) +")"}</SubHeaderText>
                  ): (
                    <SubHeaderText>{getInternTypeName(internships.intern_type)}</SubHeaderText>
                  )}
                </HeaderDiv>
            <div >
                <TabsContainer  >
                <Button
                    isActive={activeTab === 'InternTypeDetails'}
                    className={activeTab === 'InternTypeDetails' ? 'active' : ''}
                    onClick={() => handleTabClick('InternTypeDetails')}
                >
                    <TabText isActive={activeTab === 'InternTypeDetails'} >Internship Details</TabText>
                </Button>
                <Button
                    isActive={activeTab === 'InternDetails'}
                    className={activeTab === 'InternDetails' ? 'active' : ''}
                    onClick={() => handleTabClick('InternDetails')}
                >
                    <TabText isActive={activeTab === 'InternDetails'}>Interns' Details</TabText>
                </Button>
                <Button
                    isActive={activeTab === 'CompletionOfInternship'}
                    className={activeTab === 'CompletionOfInternship' ? 'active' : ''}
                    onClick={() => handleTabClick('CompletionOfInternship')}
                >
                    <TabText isActive={activeTab === 'CompletionOfInternship'}>Completion of Internship</TabText>
                </Button>
                </TabsContainer>
                <BreakSection>
                <hr/>
                </BreakSection>
                <div >
                {activeTab === 'InternTypeDetails' && <InternTypeDetails />}
                {activeTab === 'InternDetails' && <InternDetails fromInternship={fromInternship} interns={internDetails} internshipID={internshipId} isAdvertised={isAdvertised}/>} 
                {activeTab === 'CompletionOfInternship' && <UpdateCompletionOfInternshipLoe fromInternship={fromInternship} internshipsTitle={internships.title} />}
                </div>
            </div>

            </ViewInternshipWrapper>
        </MainLayout>
      
    );
}
