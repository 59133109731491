import styled from "styled-components";

const HeaderText = styled.h2`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #D04C8D;
`;

const ContentText = styled.p`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
//   max-width:750px;
`;

const TableWrapper = styled.table`
  display:block;
  // overflow-x:scroll;
`;
const TableRowWrapper = styled.tr`
  display: grid;
  grid-template-columns: ${(props) => props?.threeColumns ? "2fr 3fr 1.5fr 3fr" : "2fr 3fr 1fr"};
`;

const SectionWrapper = styled.div`
  background: #FBFBFB;
  border: 1px solid rgba(228, 228, 228, 0.26);
  border-radius: 10px;
  padding: 16px 32px;
  max-width:920px;
  margin:auto;
  margin-bottom:24px;
  @media (max-width: 500px) {
    padding:16px;
   }
`;

const BoldText = styled.div`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #1F1F1F;
`;

const FlexWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  padding-top:24px;
  @media (max-width: 500px) {
    flex-flow:wrap;
    gap:16px;
   }
`;
const TableContent = styled.th`
  min-width:200px;
`;
const ImagePreview = styled.div`
  width: 83px;
  height: 62px;
  background: url(${(props)=>props.background}) ,#D9D9D9;
  background-size: cover;
  border-radius: 8px;
  margin-right:16px;
`;
const TableText = styled.div`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #525252;
  padding:12px 0px;
`;
export { HeaderText, ContentText, TableRowWrapper, SectionWrapper, BoldText ,FlexWrapper,TableContent,ImagePreview,TableText,TableWrapper}