import { useRef, useState } from "react";
import "./paymentPopup.css";
// import pdfurl from "../../assets/termsandcondition/terms.pdf";
import Button from "../button/button";
// import { usePdf } from '@mikecousins/react-pdf';
import { useTranslation } from "react-i18next";


function PaymentPopup({ intershipName, companyName, isOpen, setOpen, onInternshipSubmit }) {
    const [agreeTerms, setAgreeTerms] = useState(false);

    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);
    // const { pdfDocument, } = usePdf({
    //     file: pdfurl,
    //     page,
    //     canvasRef,
    // });
    const displayCheck = (e) => {
        setAgreeTerms(e.target.checked);
    };
    const closeModal = () => {
        setOpen(false)
    };
    const { t, } = useTranslation();

    return (
        <>
            {isOpen === true ? (
                < div className="payment-modalHead">
                    <div className="payment-modalBackground"
                        onClick={() => setOpen(false)}
                    />
                    <div className="payment-modalContainer">
                        <div className="payment-titleCloseBtn">
                            <div id="payment-titleCloseBtn">
                                <p style={{ color: "#D04C8D" }}>{t("Create internship")}</p>
                                <p
                                    onClick={closeModal}
                                    style={{
                                        cursor: "pointer", marginTop: "-30px", width: "12px", height: "12px"
                                    }}
                                >{t(" X")}</p>
                            </div>
                            <p style={{ padding: "0px 15px 0px 15px", fontWeight: 'bolder' }}>{t("Confirm to create Internship")} {intershipName} {t("for Company")} {companyName} </p>
                        </div>

                        <div className="detauls-content">
                            {/* {!pdfDocument && <span>{t("Loading...")}</span>}
                            <canvas ref={canvasRef} />
                            {Boolean(pdfDocument && pdfDocument.numPages) && (
                                <div className="page-controls">
                                    <button disabled={page === 1} onClick={() => setPage(page - 1)} type="button">‹</button>
                                    <span>{page} of {pdfDocument.numPages}</span>
                                    <button disabled={page === pdfDocument?.numPages}
                                        onClick={() => setPage(page + 1)} type="button">›</button></div>
                            )} */}
                        </div>
                        <div className="payment-bottom_tab" alt="Internship Payment Details">
                            <label
                                style={{
                                    alignItems: "center",
                                    fontSize: 14,
                                    display: "flex",
                                }}
                            >
                                <input
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    onChange={displayCheck}
                                />
                                {t("I agree to the terms and conditions")} {agreeTerms}
                            </label>
                            <Button
                                width="100px"
                                margin="20px 0px 30px 70px"
                                name={t("Yes")}
                                disabled={!agreeTerms}
                                onClick={onInternshipSubmit}
                            />
                            <Button
                                secondary
                                color="#FFFFFF"
                                name={t("No")}
                                width="100px"
                                margin="20px 0px 30px 20px"
                                onClick={closeModal}
                            />
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )
            }
        </>
    );
}

export default PaymentPopup;
