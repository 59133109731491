import * as yup from "yup";

export const initialState={
    internship_start_date: "",
    internship_end_date: "",
    date_of_hiring: "",
    vulnerable_group: "",
    address_1: "",
    address_2: "",
    city: "",
    postal_code: "",
    state: "",
    course: "",
    total_training_expenses: "",
    total_meal_expenses: "",
    total_accomodation_expenses: "",
    total_travelling_expenses: "",
    total_digital_communication_expenses: "",
    internship_allowance_per_month: "",
    total_internship_allowance_in_programme: "",
    academic_qualification: ""
}

export const schema = yup.object().shape({
    address_1: yup.string().required('Address 1 is required'),
    address_2: yup.string().required('Address 2 is required'),
    state: yup.string().required('State is required'),
    city: yup.string().required('City is required'),
    postal_code: yup.string().required('Postal code is required'),
    date_of_hiring: yup.string().required('Date of hiring is required'),
    vulnerable_group: yup.string().required('Vulnerable group is required'),
    total_training_expenses: yup.string().required("Total training expenses required"),
    total_meal_expenses: yup.string().required("Total meal expenses is required"),
    total_accomodation_expenses: yup.string().required("Total accomodation expenses is required"),
    total_travelling_expenses: yup.string().required("Total travelling expenses is required"),
    total_digital_communication_expenses: yup.string().required("Total digital communication expenses is required"),
    internship_allowance_per_month: yup.string().required('Monthly allowance is required')
    .test('checkAllowance', function(value) {
      const academicQualification = this.parent.academic_qualification;
      if (!academicQualification) return true; // No qualification provided, let other validations handle it

      if (['SKM1', 'SKM2', 'SKM3', 'DKM', 'Diploma'].includes(academicQualification)) {
        return parseInt(value, 10) >= 500 || this.createError({ message: 'Monthly allowance should be more than 500' }); // Validate for SKM1, SKM2, SKM3, DKM, Diploma
      } else {
        return parseInt(value, 10) >= 600 || this.createError({ message: 'Monthly allowance should be more than 600' }); // Validate for other qualifications
      }
    }),
    total_internship_allowance_in_programme: yup.string().required("Total internship allowance in programme is required"),
    //total_internship_allowance_in_programme: yup.string().required("Total internship allowance "),
})