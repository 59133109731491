import { useState } from "react";

import styled from "styled-components";
import OtpInput from "react-otp-input";

import { Colors } from "../../utilities/colors";
import ErrorField from "../errorField/errorField";
import { registerOrganisationErrorMessages } from "../../utilities/commonMessages";
import { useTranslation } from "react-i18next";

// styling starts
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
`;
const LabelHighlightText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  margin-left: 10px;
  > span {
    margin-right: 10px;
  }
`;
const FlexBox = styled.div`
  display: flex;
`;
const InputWrapper = styled(FlexBox)`
  // justify-content: space-between;
`;
const Input = {
  background: Colors.inputBg,
  padding: "12px 20px",
  outline: "none",
  width: "-webkit-fill-available",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color: Colors.primary,
  boxSizing: "border-box",
  borderRadius: "10px",
  maxWidth: "54px",
  marginRight: "10px",
  border: `2px solid transparent`,
};
const focusInput = {
  border: `1px solid ${Colors.primary}`,
};
const VerifyCodeText = styled.p`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #504f8c;
  margin: 0;
  text-decoration: none;
  align-self: end;
  cursor: ${(props) => (props.cursor ? "default" : "pointer")};
  text-decoration: underline;
`;

const SuccessField = styled.label`
  color: green;
  font-size: 12px;
  margin: 0;
  display: block;
  font-weight: 500;
`;
// styling ends

export default function EmailVerification({
  onSendNewCode,
  verifyCode,
  isSuccess,
  otpResent,
  setOtpResent,
  dataId
}) {
  const {t} = useTranslation();
  const [otp, setOtp] = useState("");
  const [otpErr, setOtpErr] = useState("");

  const handleChange = (otp) => {
    setOtp(otp);
    if (otp.length === 5) {
      verifyCode(otp);
    }
  };

  const onVerify = () => {
    if (otp.length === 5) {
      verifyCode(otp);
      setOtpErr("");
    } else {
      setOtpErr(registerOrganisationErrorMessages.otp);
      setOtpResent('');
    }
  };

  const onRequestNewCode = () => {
    setOtp("");
    onSendNewCode(null, true);
  };

  return (
    <GroupWrapper>
      <FormLabel>
        {t("Verification code")}{" "}
        <LabelHighlightText onClick={onRequestNewCode}>
          {t("Send new code")}
        </LabelHighlightText>
      </FormLabel>
      <InputWrapper>
        <FlexBox data-tip data-for={dataId ? dataId : null}>
          <OtpInput
            value={otp}
            onChange={handleChange}
            numInputs={5}
            focusStyle={focusInput}
            inputStyle={Input}
            shouldAutoFocus
            isInputNum
            separator={<span></span>}
            isDisabled={isSuccess}
          />
        </FlexBox>
        <VerifyCodeText
          cursor={isSuccess}
          onClick={isSuccess ? () => { } : onVerify}
        >
          {isSuccess ? t("Verified") : t("Verify code")}
        </VerifyCodeText>
      </InputWrapper>
      {!otpResent && otpErr && <ErrorField err={t(otpErr)} />}
      {!otpErr && otpResent && <SuccessField> {t(otpResent)}</SuccessField>}
    </GroupWrapper >
  );
}
