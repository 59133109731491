import React from "react";
import ChartLoader from "../../components/chartLoader/chartLoader";
import VerticalBarChartDataLabels from "../../components/charts/verticalBarChartDataLabels";
// import NoDataChartContainer from "../../../../components/NoDataFoundChart/NoDataFoundChart";
import Selector from "../../components/select/select";
import { StudentAd2Card } from "./ad2Card";
import { Ad2SelectorWrapper } from "../styles/Ad2Styles";
import { useTranslation } from "react-i18next";
const columns = [
  {
    name: "",
    selectorKey: "type",
    selector: (row) => row.type,
    sortable: false,
  },
  {
    name: "Male",
    selectorKey: "male",
    selector: (row) => row.male,
    sortable: false,
  },
  {
    name: "Female",
    selectorKey: "female",
    selector: (row) => row.female,
    sortable: false,
  },
];

export function Ad2StudentWorkValues({ data,
  studentWorkInterestFilter,
  setStudentWorkInterestFilterFilter,
  onViewMoreClick,
}) {
  console.log("WORK VALUES DATA",data)
  const chartData = data;
  const gridData = data && data.gridData;
  const status = data && data.status;
  function RightContent() {
    const ranks = [
      "Rank 1",
      "Rank 2",
      "Rank 3",
    ].map((item) => {
      return { value: item, label: item };
    });
    return (
      <Ad2SelectorWrapper>
        <Selector
          onChange={(value) => {
            setStudentWorkInterestFilterFilter(value);
          }} options={ranks} defaultValue={{ value: studentWorkInterestFilter, label: studentWorkInterestFilter }} />
      </Ad2SelectorWrapper>
    );
  }
  const { t, i18n } = useTranslation();
  return (
    <>
      {
        chartData?.status !== "noDataFound"
        ?
      
      chartData?.Mastery?.points
        ? <StudentAd2Card
          title="Work Values"
          data={gridData}
          columns={columns}
          exportFileName="students_work_interest"
          rightContent={<RightContent />}
          status={status}
          onViewMoreClick={onViewMoreClick}
        >
          <VerticalBarChartDataLabels
            chartType = "work_values"
            data={{
              labels: [
                t("Creating Value"),
                t("Fellowship"),
                t("Mastery"),
                t("Quality of Life"),
              ],
              datasets: [
                {
                  // data: [20,23,15,28,24,8,21],
                  data: [
                    chartData && chartData['Creating Value'] && chartData['Creating Value'].points,
                    chartData && chartData['Fellowship'] && chartData['Fellowship'].points,
                    chartData && chartData['Mastery'] && chartData['Mastery'].points,
                    chartData && chartData['Quality of Life'] && chartData['Quality of Life'].points,
                    // chartData && chartData.Enterprising && chartData.Enterprising.points,
                    // chartData && chartData.Conventional && chartData.Conventional.points,
                  ], //this code is working. remove dummy data if needed.
                  backgroundColor: "#004B84",
                  borderRadius: Number.MAX_VALUE,
                  borderSkipped: false,
                  borderColor: "rgba(0,0,0,0)",
                  barPercentage: 1,
                  categoryPercentage: 0.8,
                }
              ],
            }}
          />
        </StudentAd2Card>
        : <ChartLoader></ChartLoader>
      
        : <div  className="noFound">
        <p style={{ margin: 0 }}>{t("Not Yet Complete")}</p>
      </div >
      }

    </>
  );
}