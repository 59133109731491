// import { getCognitiveDrilldown } from "../../../../../../services/CommonApi";
// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";


export const getCognitiveDrilldown = () => {
    const getCongitve = {
        "status": 1,
        "data": {
            "count": 571,
            "next": "http://api-uat-mynext.cxsanalytics.com/api/dashboard/university/analytical/results/assessment-get-by-type/?assessment-type=future-of-work&cognitive_level=2&comprehension_level=2&english_level=2&grammar_level=2&insti_name=141&page=2&page_size=10",
            "previous": null,
            "results": [
                {
                    "id": "000b4a74-3d76-4938-8a2e-5fc1402b5177",
                    "name": "MEGHAAN A/L KANIGASALAN",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 3",
                    "interestRiasec": [
                        "Conventional",
                        "Investigative",
                        "Social"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "005b004a-6ad7-4fab-a1e6-14fea8430eb2",
                    "name": "PUTRI HUMIRA YAP BINTI ABU BAKAR YAP",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 1",
                    "interestRiasec": [
                        "Enterprising",
                        "Investigative",
                        "Conventional"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "00fa3a9e-a670-4331-b290-f5300a3663a5",
                    "name": "Yusuf Mohammad",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 3",
                    "interestRiasec": [
                        "Social",
                        "Realistic",
                        "Conventional"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "04b8ba61-c9c8-49cb-adec-52ac874bbfc0",
                    "name": "NUR JESNITA BINTI OTHMAN",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 1",
                    "interestRiasec": [
                        "Investigative",
                        "Social",
                        "Conventional"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "05863a79-bba7-4711-89ff-972ee4a75b40",
                    "name": "AMIRUL HAKIM BIN ZAZLAN",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 3",
                    "interestRiasec": [
                        "Artistic",
                        "Investigative",
                        "Social"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "05ecb415-8454-485c-900d-d038ec46f242",
                    "name": "Deannardo Berjaya",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 1",
                    "interestRiasec": [
                        "Conventional",
                        "Realistic",
                        "Social"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "067eb281-b2fa-414e-8d20-b3b21940721b",
                    "name": "MONICA ANAK LEMBANG",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 2",
                    "interestRiasec": [
                        "Artistic",
                        "Realistic",
                        "Enterprising"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "078c4e4a-39df-490a-80f5-970c2803faca",
                    "name": "MUHAMMAD FIRDAUZ BIN MHD RAZALI",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 1",
                    "interestRiasec": [
                        "Artistic",
                        "Investigative",
                        "Conventional"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "07efe6f7-ff15-456d-bb70-94fdbe16ad9c",
                    "name": "REVATHI CHANDRASEGRAN",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 3",
                    "interestRiasec": [
                        "Artistic",
                        "Enterprising",
                        "Conventional"
                    ],
                    "internship_status": "Not Applied"
                },
                {
                    "id": "08345464-c7b8-42ed-b666-55ce308c76a3",
                    "name": "Magdelina Sattu Anak Nelson",
                    "employability": "Moderate",
                    "careerAlignment": "Moderate",
                    "motivationLevel": "High",
                    "futureOfWork": "Moderate",
                    "englishTest": "Level 1",
                    "interestRiasec": [
                        "Artistic",
                        "Enterprising",
                        "Conventional"
                    ],
                    "internship_status": "Not Applied"
                }
            ]
        }
    }

    return getCongitve
}

export default Ad2DrilldownHOC(getCognitiveDrilldown, [
    {
        title: "Critical Thinking",
        dataKey: "Critical Thinking",
        url: 'critical-thinking',
        info:"High critical thinking skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to solve problems using logical thinking, obtaining relevant information and having an awareness of the impact of cognitive biases is important in the modern workplace. Weighing up the advantages and disadvantages of a decision or course of action is also an important skill."
    },
    {
        title: "Planning and Ways of Working",
        dataKey: "Planning and Ways of Working",
        url: 'planning-and-ways-of-working',
        info:"High planning and ways of working skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to plan and prioritise one’s work efficiently to ensure its completion is important in the modern workplace. When starting a project, creating a plan to ensure that one stays on track, yet being flexible to switch tasks if required is also an important skill."
    },
    {
        title: "Communication",
        dataKey: "Communication",
        url: 'communication',
        info:"High communication skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to understand key messages and communicate them to others in ways that they can easily understand, listen well and understand the views of others are all important skills in the workplace. Asking the right questions so that one has all the information they need when starting a new piece of work is also important. Being able to speak in public could be an important skill to acquire."
    },
    {
        title: "Mental Flexibility",
        dataKey: "Mental Flexibility",
        url: 'mental-flexibility',
        info:"High mental flexibility skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to use creativity and imagination to help solve problems or come up with new ideas, to use learning from one area and apply it to another area for positive results, to see a situation from many perspectives and to be open to new learning are all important skills in the workplace. To use these effectively, one should easily adapt to change."
    },
]);
