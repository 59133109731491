import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  companyData: {},
  currentlySelectedcompanyData:{},
  currentOrgData: {},
  selectedGlobalOrgCode: null,
  loading: true,
  userAcceptedStatus: null,
  reloadPage: false,
};

export const myCompanyReducer = createSlice({
  name: "company",
  initialState,
  reducers: {
    updateCompanyData: (state, action) => {
      state.companyData = action.payload;
    },
    updateAdsData: (state, action) => {
      state.paymentAds = action.payload;
    },
    updateCurrentlySelectedCompanyData: (state, action) => {
      state.currentlySelectedcompanyData = action.payload;
    },
    updateCurrentlySelectedOrgData: (state, action) => {
      state.currentOrgData = action.payload;
    },
    updateSelectedGlobalOrgCode: (state, action) => {
      state.selectedGlobalOrgCode = action.payload;
    },
    updateLoader: (state, action) => {
      state.loading = action.payload;
    },
    updateUserAcceptedStatus: (state, action) => {
      state.userAcceptedStatus = action.payload;
    },
    setReloadPage: (state,action) => {
      state.reloadPage = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { setReloadPage, updateCompanyData, updateAdsData, updateCurrentlySelectedCompanyData, updateCurrentlySelectedOrgData, updateSelectedGlobalOrgCode, updateLoader, updateUserAcceptedStatus} = myCompanyReducer.actions;

export default myCompanyReducer.reducer;
