import React from "react";

const VideoMaker = (props) => {
  return (
    <div>
      {" "}
      <span>
        {
          props.showInputEle ? (
            <textarea
              type="text"
              value={props.value}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              style={{ width: "100%", maxWidth: "100%" }}
            />
          ) : (
            <span
              onDoubleClick={props.handleDoubleClick}
              style={{
                display: "inline-block",
                height: "25px",
                minWidth: "300px",
              }}
            >
              {props.value}
            </span>
          )
        }
      </span>
    </div>
  );
};

export default VideoMaker;
