import { createSlice } from "@reduxjs/toolkit";

import { organisationData } from "../../utilities/constants";

const initialState = {
  value: organisationData,
  successData: {},
  isUpdate: false,
  isRedirect: true,
  isSameAddress : false,
  formErr:''
};

export const organisationDataReducer = createSlice({
  name: "organisation",
  initialState,
  reducers: {
    updateValues: (state, action) => {
      state.value = action.payload;
    },
    updateSuccessObj: (state, action) => {
      state.successData = action.payload;
    },
    updateOrg: (state, action) => {
      state.isUpdate = action.payload;
    },
    updateRedirection: (state, action) => {
      state.isRedirect = action.payload;
    },
    updateFormErr: (state, action) => {
      state.formErr = action.payload;
    },
    updateisSameAddress: (state, action) => {
      state.isSameAddress = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateValues, updateSuccessObj,updateisSameAddress, updateOrg, updateRedirection,updateFormErr } =
  organisationDataReducer.actions;

export default organisationDataReducer.reducer;
