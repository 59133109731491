import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Input from "../input/input";
import { Form } from "react-bootstrap";
import { useSnapshot } from "valtio";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import Button from "../button/button";
import { changApplications } from "../../services/apiCalls";
// import "./offermodal.css";
import { useTranslation } from "react-i18next";
const CardsWrapper = styled.div`
  position: absolute;
  width: 740px !important;
  height: 706px;
  // left: 30%;
  top: 15%;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;

  @media (max-width: 500px) {
    width: 350px !important;
    height: 780px;
    position: relative;
    top: 3%;
    padding-top: 10px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 86px;
  margin: 10px;
  display: flex;
  @media (max-width: 500px) {
    height: 96px;
  }
`;
const Icon = styled.div`
  position: absolute;
  top: 25px;
  right: 30px;
  // @media (max-width: 500px) {
  //   right: 120px;
  // }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 20px 50px;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 50px 0px;
  margin: -10px 0 0 -120px;
`;
const FormLabel = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  margin: 20px 0 0 0px;
`;
const Wrapper = styled.div`
  position: relative;
  width: 501px;
  left: 67px;
  //bottom: 20px;
  Input {
    height: 40px;
  }
  @media (min-width: 375px) and (max-width: 500px) {
    margin-left: 15px;
    left: 0px;
    Input {
      height: 40px;
      width: 60%;
    }
  }
`;

const ButtonWrapper = styled.div`
  position: relative;
  top: 0px;
  left: 250px;
  button {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #ffffff;
    background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
    border-radius: 128px;
    border: none;
    width: 180px;
    height: 50px;
  }
  button:nth-child(2) {
    background: white;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    margin-left: 10px;
  }
  @media (min-width: 375px) and (max-width: 500px) {
    position: relative;
    left: 17px;
    top: 45px;
    button {
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      text-align: center;
      color: #ffffff;
      background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
      border-radius: 128px;
      border: none;
      width: 120px;
      // height: 40px;
      margin-left: 10px;
    }
    button:nth-child(2) {
      background: white;
      border: 1px solid #d04c8d;
      color: #d04c8d;
      margin-left: 10px;
      margin-top: -10px;
    }
  }
`;

const OfferModal = ({ open, onClose, id,t }) => {
  useSnapshot(trackProxy);
  const [jobtitle, setJobtitle] = useState(null);
  const [name, setName] = useState(null);
  const [stdate, setStdate] = useState("");
  const [address, setAddress] = useState("");
  const [email, setEmail] = useState("");
  const [term, setTerm] = useState("N");
  const [change, setChange] = useState(0);
  const [dis, setDis] = useState(true);

  useEffect(async () => {
    checkdis();
  }, [jobtitle, name, stdate, address, email, term]);

  useEffect(async () => {
    for (let i = 0; i < trackProxy.applist.length; i++) {
      if (trackProxy.applist[i]["id"] === id) {
        if (!jobtitle) {
          if (trackProxy.applist[i]["engage_jobposition"].length === 0) {
            setJobtitle(trackProxy.applist[i]["jobtitle"]);
          } else {
            setJobtitle(trackProxy.applist[i]["engage_jobposition"]);
          }
          setName(trackProxy.applist[i]["name"]);
        }
        i = trackProxy.applist.length + 1;
        ch();
      }
    }
  }, []);

  const checkdis = () => {
    if (
      jobtitle != null &&
      jobtitle.length > 0 &&
      name != null &&
      name.length > 0 &&
      stdate.length > 0 &&
      address.length > 0 &&
      email.length > 0 &&
      term === "Y"
    ) {
      setDis(false);
    } else {
      setDis(true);
    }
    ch();
  };

  const submit = async () => {
    let l = {
      job_title: jobtitle,
      candidate_name: name,
      start_date: stdate,
      employement_address: address,
      offer_email: email,
      terms_conditions: term,
    };

    const { data } = await changApplications("offered", id, l);

    close();
  };

  const ch = () => {
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };
  const close = () => {
    if (trackProxy.setcheck === 0) {
      trackProxy.setcheck = 1;
    } else {
      trackProxy.setcheck = 0;
    }
    onClose();
  };

  if (!open) {
    return null;
  }

  return (
    <div className="overlay-popup">
      <CardsWrapper>
        <CardWrapper id="headercard">
          <Title>{t("OFFERED")}</Title>
          <Content>{t("Offer a job for a selected candidate")} </Content>
          <Icon onClick={close} id="headericon" style={{ cursor: "pointer" }}>
            X
          </Icon>
        </CardWrapper>

        <Wrapper>
          <FormLabel>{t("Job Title")}</FormLabel>
          <Input
            name="Engage"
            search={true}
            placeholder="Type your job title"
            minWidth="auto"
            width="627px"
            style={{ margin: "10px 0 0 0px" }}
            value={jobtitle}
            onChange={(e) => {
              setJobtitle(e.target.value);
            }}
          />
          <FormLabel>{t("Candidate name")}</FormLabel>
          <Input
            name="Engage"
            search={true}
            placeholder="Type the name of your candidate"
            minWidth="auto"
            width="627px"
            style={{ margin: "10px 0 0 0px" }}
            value={name}
            onChange={(e) => {
              setName(e.target.value);
            }}
          />
          <FormLabel>{t("Start date")}</FormLabel>
          <Input
            type="date"
            name="Engage"
            search={true}
            placeholder={t("Type the joining date")}
            minWidth="auto"
            width="310px"
            style={{ margin: "10px 0 0 0px" }}
            value={stdate}
            onChange={(e) => {
              setStdate(e.target.value);
            }}
          />
          <FormLabel>{t("Employement address")}</FormLabel>
          <Input
            name="Engage"
            search={true}
            placeholder={t("Type Employement address")}
            minWidth="auto"
            width="627px"
            style={{
              margin: "10px 0 0 0px",
              height: "50px",
              borderRadius: "83px",
            }}
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
            }}
          />
          <FormLabel>{t("Offer email")}</FormLabel>

          <Input
            name="Engage"
            search={true}
            placeholder={t("Type your interview heading or agenda.")}
            minWidth="auto"
            width="627px"
            style={{
              margin: "10px 0 0 0px",
              height: "120px",
              borderRadius: "16px",
            }}
            value={email}
            onChange={(e) => {
              setEmail(e.target.value);
            }}
          />

          <ButtonWrapper>
            <Button
              onClick={submit}
              cursor="pointer"
              name={t("Send")}
              disabled={dis}
            ></Button>
            <Button
              secondary
              color="#FFFFFF"
              name={t("Cancel")}
              variant="outlined"
              onClick={close}
            ></Button>
          </ButtonWrapper>
        </Wrapper>

        <div
          style={{
            display: "flex",
            gap: "20px",
            position: "relative",
            left: "70px",
            bottom: "55px",
            width: "35%",
          }}
          className="textConditions"
        >
          <Form.Check
            checked={term === "Y"}
            onClick={() => {
              if (term === "Y") {
                setTerm("N");
              } else {
                setTerm("Y");
              }
              ch();
            }}
          ></Form.Check>
          <span>{t("Terms & Conditions")}</span>
        </div>
      </CardsWrapper>
    </div>
  );
};

export default OfferModal;
