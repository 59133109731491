import { SubHeading } from "../button/commonStyles";

export const staticData = {
    assessmentReport: {
        heading: "Assessments Report",
        content: [
            {
                type: "range",
                heading: "Motivation",
                subHeading: [
                    "Openness to Experience",
                    "Conscientiousness",
                    "Extraversion",
                    "Agreeableness",
                    "Neuoroicism"
                ]
            }, {
                type: "range",
                heading: "Interest",
                subHeading: [
                    "Realistic",
                    "Investigative",
                    "Social",
                    "Enterprising",
                    "Conventional"
                ]

            }, {
                type: "boxes",
                heading: "Employability",
                subHeading: [
                    "Ideas and Opportunities",
                    "Resources",
                    "Info & Action"
                ]

            }, {
                type: "boxes",
                heading: "The Future of Work ",
                subHeading: [
                    "Cognitive",
                    "Digital",
                    "Self Leadership",
                    "Interpersonal"
                ]

            }, {
                type: "range",
                heading: "English Proficiency",
                subHeading: [
                    "Total English Proficiency Level",
                    "Grammar",
                    "Comprehension"
                ]

            },
        ]
    },
    oceanReport: {
        heading: "OCEAN report",
        content: [
            {
                type: "range",
                heading: "Work Competency Factor",
                subHeading: [
                    "Embracing & Managing",
                    "Connecting & Portraying",
                    "Supervising & Determining",
                    "Ambitious & Result-Oriented",
                    "Innovating & Envisioning",
                    "Evaluating & Resolving",
                    "Facilitating & Collaborating",
                    "Coordinating & Implementing"
                ]
            }, {
                type: "range",
                heading: "Personality Aspects",
                subHeading: [
                    "Withdrawal",
                    "Volatility",
                    "Enthusiasm",
                    "Assertiveness",
                    "Aesthetics Openness",
                    "Intellect",
                    "Compassion",
                    "Politeness",
                    "Industriousness",
                    "Orderliness"
                ]
            },
            {
                type: "range",
                heading: "30 - Facets",
                subHeading: [
                    "E2-Gregariousness",
                    "E6-Cheerfulness",
                    "E1-Friendliness",
                    "E5-Excitement Seeking",
                    "E4-Activity Level",
                    "E3-Assertiveness",
                    "N2-Anger",
                    "N5-Immoderation",
                    "N1-Anxiety",
                    "N3-Depression",
                    "N4-Self - Consciousness",
                    "N6-Vulnerability",
                    "O5-Intellect",
                    "O4-Adventurousness",
                    "O2-Aesthetics",
                    "O1-Imagination",
                    "O3-Emotionality",
                    "O6-Liberalism",
                    "A6-Sympathy",
                    "A3-Altruism",
                    "A1-Trust",
                    "A4-Cooperation",
                    "A5-Modesty",
                    "A2-Morality",
                    "C4-Achievement Striving",
                    "C1-Self-Efficacy",
                    "C5-Self-Discipline",
                    "C6-Cautiousness",
                    "C3-Dutifulness",
                    "C2-Orderliness"
                ]
            },
            {
                type: "range",
                heading: "Predictive Reliability Factor",
                subHeading: [
                    "Predictive Reliability Factor",
                ]
            },
            {
                type: "boxes",
                heading: "Organisational Fit Factor",
                subHeading: [
                    "Persuasive",
                    "Receptive To Uncertainity",
                    "Go-Getter",
                    "Singular or United",
                    "Time Related Considerations",
                    "Liveliness"
                ]

            },
        ]
    },
    oceanReportForProfile: {
        heading: "OCEAN report",
        content: [
            {
                type: "range",
                heading: "Work Competency Factor",
                subHeading: [
                    "Embracing & Managing",
                    "Connecting & Portraying",
                    "Supervising & Determining",
                    "Ambitious & Result-Oriented",
                    "Innovating & Envisioning",
                    "Evaluating & Resolving",
                    "Facilitating & Collaborating",
                    "Coordinating & Implementing"
                ]
            }, {
                type: "range",
                heading: "Personality Aspects",
                subHeading: [
                    "Withdrawal",
                    "Volatility",
                    "Enthusiasm",
                    "Assertiveness",
                    "Aesthetics Openness",
                    "Intellect",
                    "Compassion",
                    "Politeness",
                    "Industriousness",
                    "Orderliness"
                ]
            },
            {
                type: "range",
                heading: "30 - Facets",
                subHeading: [
                    "E2-Gregariousness",
                    "E6-Cheerfulness",
                    "E1-Friendliness",
                    "E5-Excitement Seeking",
                    "E4-Activity Level",
                    "E3-Assertiveness",
                    "N2-Anger",
                    "N5-Immoderation",
                    "N1-Anxiety",
                    "N3-Depression",
                    "N4-Self - Consciousness",
                    "N6-Vulnerability",
                    "O5-Intellect",
                    "O4-Adventurousness",
                    "O2-Aesthetics",
                    "O1-Imagination",
                ]
            }, {
                type: "range",
                heading: "",
                subHeading: [
                    "O3-Emotionality",
                    "O6-Liberalism",
                    "A6-Sympathy",
                    "A3-Altruism",
                    "A1-Trust",
                    "A4-Cooperation",
                    "A5-Modesty",
                    "A2-Morality",
                    "C4-Achievement Striving",
                    "C1-Self-Efficacy",
                    "C5-Self-Discipline",
                    "C6-Cautiousness",
                    "C3-Dutifulness",
                    "C2-Orderliness"
                ]
            },
            {
                type: "boxes",
                heading: "Organisational Fit Factor",
                subHeading: [
                    "Persuasive",
                    "Receptive to uncertainty",
                    "Go-getter",
                    "Singular or United",
                    "Time-Related Considerations",
                    "Liveliness"
                ]

            },
            {
                type: "range",
                heading: "Predictive Reliability Factor",
                subHeading: [
                    "Predictive Reliability Factor",
                ]
            },
        ]
    }
}