import styled from "styled-components";

const HrLine = styled.hr`
border: ${(props) =>
  props.bg
    ? `1px solid ${props.bg}`
    : "1px solid rgba(235, 235, 235, 0.62)"};
max-width: ${props => props.width};
margin: 35px 0;
min-width: 200px;
background: ${(props) =>
  props.bg ? props.bg : "rgba(235, 235, 235, 0.62)"};
@media (max-width: 1024px) {
  max-width: 100%;
}
@media (max-width: 767px) {
  min-width: 25%;
}
`;

export default function Divider(props) {
  // styling starts

  // styling ends

  return <HrLine bg={props.bg} width={props.width}/>;
}