import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import rightarrow from "../../assets/icons/arrow_right.png";
import {
  NormalText,
  SpanText,
  SubHeading,
  ContentText,
  MainText,
  BoxWrapper,
} from "../../components/talentProfile/common";
import Skills1 from "../../assets/Saly-32skills1.png";
import { Col, Row, Container } from "react-bootstrap";
import WorkCompetencyFactorChart from "../studentOceanCharts/workCompetencyFactorChart";
import PersonalityAspectsChart from "../studentOceanCharts/personalityAspectsChart";
import ThirtyFacetsChart from "../studentOceanCharts/thirtyFacetsChart";
import PredictiveReliabilityFactorChart from "../studentOceanCharts/predictiveReliabilityFactorChart";
import { Ad2StudentPersonalityProfile } from "../studentOceanCharts/studentPersonalityChart";
import { Ad2StudentWorkInterest } from "../studentOceanCharts/studentWorkInterestChart";
import { Ad2EmployabilityFactors } from "../studentOceanCharts/studentEmployabilityChart";
import { Ad2FutureOfWork } from "../studentOceanCharts/studentFutureOfWorkChart";
import { Ad2EnglishProficiency } from "../studentOceanCharts/studentEnglishChart";
import { t } from "i18next";
import {
  addTalentSearchUser,
  getDataWorkEmployability,
  getDataWorkEnglishTest,
  getDataWorkFutureWorks,
  getDataWorkInterest,
  getDataWorkPersonality,
  getDataWorkValues,
  getjobslist,
  getMyCyOverall,
  getOverViewProfile,
  getTalentOceanReport,
  updateTalentSearchToken,
} from "../../services/apiCalls";
import { useSnapshot } from "valtio";
import { jobProxy } from "./jobproxy";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton } from "@material-ui/core";
import Button from "../../components/button/button";
import { useTranslation } from "react-i18next";
import "./job.css";
import { StyledDarkerCardCustom } from "../applicantAssessments/commonStyles";
import { Email, Phone } from "@mui/icons-material";
import { Colors } from "../../utilities/colors";
import { useSelector } from "react-redux";
import { ReactComponent as BadgeIcon } from "../../assets/icons/svg/checkPink.svg";
import { UnAuthEmPopup } from "../userManagement/unAuthPopup";
import TalentShortlistModal from "../../components/talentShortlistModal/talentShortlistModal";
import { companyProxy } from "../myCompany/companyproxy";
// import talentCV from "../TalentSearch/talentCV";
import MyCvPreview from "../TalentSearch/talentCV";
import { toast } from "react-toastify";
import FullProfileModal from "../../components/talentProfile/fullProfileModal/fullProfileModal";
import { Ad2StudentWorkValues } from "../studentOceanCharts/studentWorkValuesChart";
import { updateModal } from "../../reducers/detailModalReducer";
import InterestProfileModal from "./modals/interestProfileModal/interestProfileModal";
import PreferencesModal from "./modals/preferencesModal/preferencesModal";
import EmployabilityFactorModal from "./modals/employabilityFactorModal/employabilityFactorModal";
import ValueProfileModal from "./modals/valueProfileModal/valueProfileModal";
import FutureWorkFactorModal from "./modals/futureWorkFactorModal/futureWorkFactorModal";
import EnglishProficiencyModal from "./modals/englishProficiencyModal/englishProficiencyModal";
import MotivationLevelModal from "./modals/motivationLevelModal/motivationLevelModal";
import DetailModal from "../../components/detailModal/detailModal";
import { useDispatch } from "react-redux";
const FlexWrapper = styled.div`
  display: flex;

  @media (max-width: 599px) {
    display: block;
    text-align: center;
  }
`;

const FlexCenterWrapper = styled.div`
  display: flex;

  flex-wrap: wrap;
  justify-content: center;
  padding: 0 7rem;
  @media (max-width: 599px) {
    padding: 0;
  }
`;
const FlexCenter = styled.div`
  @media (max-width: 599px) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;
const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;
const LinkText = styled.p`
  color: rgb(208, 76, 141);
  font-family: "General Sans";
  font-weight: 500;
  // padding-right: 10px;
  padding-top: 2rem;
  font-size: 20px;
  text-transform: uppercase;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  @media (max-width: 599px) {
    padding-top: 1rem;
  }
`;

export const HRLine = styled.hr`
  border: 0.4px solid #202020;
  width: 1100px;
  height: 0px;
  @media (max-width: 500px) {
    width: 290px;
  }
  @media (min-width: 501px) and(max-width:768px) {
    width: 550px;
  }
  @media (min-width: 769px) and (max-width: 920px) {
    width: 600px;
  }
  @media (min-width: 921px) and (max-width: 1024px) {
    width: 450px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: 650px;
  }
`;
const Wrapper = styled.div`
  position: relative;
  width: 501px;
  height: 128px;
  left: 72px;
  top: 20px;
  background: #f3f3f3;
  border-radius: 16px;
  @media (max-width: 500px) {
    left: 25px;
    width: 88%;
  }
`;

const MainWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
`;
const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const HrLine = styled.div`
  width: 100%;

  height: 10px;
  border-bottom: 0.6px solid #c4c4c4;
`;
const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentCard = styled.div`
  display: grid;
  grid-template-columns: 0.7fr 3fr;
  @media (max-width: 500px) {
    display: block;
  }
  @media (max-width: 1200px) {
    grid-template-columns: 2fr 3fr;
  }
`;

const Card1 = styled.div`
  width: 260px;
  height: 280px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
  border-radius: 30px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  @media (min-width: 481px) and (max-width: 768px) {
    width: auto;
    height: auto;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    width: auto;
    height: auto;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    width: auto;
    height: auto;
  }
`;
const Image = styled.img`
  width: 100%;
  border-radius: 50%;
`;

const Image1 = styled.img`
  width: 92px;
  height: 92px;
  margin: 0 auto;
`;

const CardHeader = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 115.3%;
  /* or 21px */
  text-align: center;
  letter-spacing: 0.01em;

  color: #434343;
  margin-bottom: 12px;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin: 24px 20px 0 20px;
  flex-flow: wrap;
  justify-content: space-around;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: grid;
  }
`;
const Section = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
`;
const ModalContainer = styled(Container)`
  @media (min-width: 480px) {
    width: 80vw;
  }

  @media (min-width: 768px) {
    width: 100vw;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    width: 892px;
    // padding: 25px 70px;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: 892px;
    // padding: 25px 70px;
  }
`;

export default function TalentProfileSearch() {
  useSnapshot(jobProxy);
  useSnapshot(companyProxy);
  const dispatch = useDispatch();
  const [dataUser, setUserdata] = useState({});
  const [workValues, setWorkValues] = useState([]);
  const [workValuesView, setWorkValuesView] = useState([]);
  const [workValuesLabels, setWorkValuesLabels] = useState([]);
  const [workValuesColors, setWorkValuesColors] = useState([]);
  const [workValuesData, setWorkValuesData] = useState([]);

  const [workInterest, setWorkInterest] = useState([]);
  const [workInterestView, setWorkInterestView] = useState([]);
  const [workInterestLabels, setWorkInterestLabels] = useState([]);
  const [workInterestColors, setWorkInterestColors] = useState([]);
  const [workInterestData, setWorkInterestData] = useState([]);

  const [workPersonality, setWorkPersonality] = useState([]);
  const [workPersonalityData, setWorkPersonalityData] = useState([]);
  const [workPersonalityView, setWorkPersonalityView] = useState([]);
  const [workPersonalityLabels, setWorkPersonalityLabels] = useState([]);
  const [workPersonalityColors, setWorkPersonalityColors] = useState([]);

  const [workEmployability, setWorkEmployability] = useState([]);
  const [workEmployabilityData, setWorkEmployabilityData] = useState([]);
  const [workEmployabilityView, setWorkEmployabilityView] = useState([]);
  const [workEmployabilityDetails, setWorkEmployabilityDetails] = useState([]);

  const [workFutureWorks, setWorkFutureWorks] = useState([]);
  const [workFutureWorksView, setWorkFutureWorksView] = useState([]);
  const [workFutureWorksData, setWorkFutureWorksData] = useState([]);

  const [workEnglishTest, setWorkEnglishTest] = useState([]);
  const [workEnglishTestView, setWorkEnglishTestView] = useState([]);

  const [openAssessmentReport, setOpenAssessmentReport] = useState(true);
  const [openOceanReport, setOpenOceanReport] = useState(true);
  const { t, i18n } = useTranslation();
  const [openStudentHistory, setOpenStudentHistory] = useState(true);
  const [studentHistoryData, setStudentHistoryData] = useState([]);
  const [facetData, setFacetData] = useState([]);
  const [orgFitData, setOrgFitData] = useState([]);
  const [personalityAspectData, setPersonalityAspectData] = useState([]);
  const [reliabilityData, setReliabilityData] = useState([]);
  const [competencyData, setCompetencyData] = useState([]);
  const [profileDetails, setProfileDetails] = useState({});
  const [showOceanReport, setShowOceanReport] = useState(false);
  const [loading, setLoading] = useState(true);
  const [userFullData, updateUserData] = useState({});
  const [savedProfileLoading,setLoadingSavedProfile] = useState(false);
  const navigate = useNavigate();
  const stateUserDetail = useSelector(
    (state) => state.talentReducer.userDetails
  );
  const talentTokenUsed = useSelector((state) => state.talentReducer.tokenUsed);
  const talentTokenAvaialable = useSelector(
    (state) => state.talentReducer.tokenAvailable
  );
  const [modalMode, setModalMode] = useState("available-coins");
  const [detailModalMode, setDetailModalMode] = useState("");
  const [isMyCvDownLoad, setIsMyCvDownLoad] = useState(false);
  const [downloadingComplete, setDownloadingComplete] = useState(false);
    const [loader, setLoader] = useState(false);

  const getDataValue = async (id) => {
    const { data } = await getDataWorkValues(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
      var datasValues = [];
      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        datasValues.push(data?.data[e]["values"]);

        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });
      setWorkValuesLabels(datasLabel);
      setWorkValues(datasValue);
      setWorkValuesColors(datasColor);
      setWorkValuesView(datasValues);
      setWorkValuesData(data?.data);
    }
  };

  const getDataIntrest = async (id) => {
    const { data } = await getDataWorkInterest(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];

      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });
      setWorkInterestLabels(datasLabel);
      setWorkInterest(datasValue);
      setWorkInterestColors(datasColor);
      setWorkInterestView(data?.data);
      setWorkInterestData(data?.data);
    }
  };

  const getDataPersonality = async (id) => {
    const { data } = await getDataWorkPersonality(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];

      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });

      setWorkPersonalityData(data?.data);
      setWorkPersonalityLabels(datasLabel);
      setWorkPersonality(datasValue);
      setWorkPersonalityColors(datasColor);
      setWorkPersonalityView(data?.data);
    }
  };
  const getDataEmployability = async (id) => {
    const { data } = await getDataWorkEmployability(id);
    if (data?.status === 1) {
      console.clear();
      setWorkEmployabilityDetails(data);
      setWorkEmployabilityView(data?.data?.domains);
      setWorkEmployability(data?.data?.totalScore?.points);
      setWorkEmployabilityData(data?.data);
    }
  };

  const getDataFutureWorks = async (id) => {
    const { data } = await getDataWorkFutureWorks(id);
    if (data?.status === 1 && data?.data?.domains) {
      setWorkFutureWorksView(data?.data.domains);
      setWorkFutureWorks(data?.data.totalScore?.points);
      setWorkFutureWorksData(data?.data);
    }
  };
  const getDataEnglishTest = async (id) => {
    const { data } = await getDataWorkEnglishTest(id);
    if (data?.status === 1) {
      setWorkEnglishTestView(data?.data);
      setWorkEnglishTest(
        data?.data?.General && data?.data?.General.points !== undefined
          ? data?.data?.General.points
          : "0"
      );
    }
  };
  const getOceanReport = async (id) => {
    const { data } = await getTalentOceanReport(id);
    if (data?.status === 1) {
      const OCEANData = data?.data?.data[0];
      setFacetData(OCEANData["30 - Facets"]);
      setOrgFitData(OCEANData["Organizational Fit Factor"]);
      setPersonalityAspectData(OCEANData["Personality Aspects"]);
      setReliabilityData(OCEANData["Predictive Reliability Factor"]);
      setCompetencyData(OCEANData["Work Competency Factor"]);
    }
  };
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const org_user_id = useSelector((state) => state.org.orgData.id);
  console.log("orgDarta", org_user_id);
  const useTokenFullProfile = async () => {
    setProfileModal(true);
  };
  const updateTalentUser = async () => {
    setLoadingSavedProfile(true);
    var bodyparam = {};
    bodyparam["talent_id"] = userFullData.user_id;
    bodyparam["organization_id"] = company.id;
    bodyparam["organization_user_id"] = org_user_id;
    // let  p = `?talent_id=${userFullData.user_id}&organization_id=${company.id}&organization_user_id=${org_user_id}`;
    var { data } = await addTalentSearchUser(company.id, bodyparam);
    //  console.log("dataData",status)
    // if(status == 200)
    if (data) {
      var updateDetail = userFullData;
      updateDetail.is_saved = 1;
      updateUserData(updateDetail);
      localStorage.setItem("localUserData",JSON.stringify(updateDetail));
      setLoadingSavedProfile(false);
      toast.success(t("Talent Application Profile Saved"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deducteToken = async () => {
    var bodyparam = {};
    bodyparam["talent_id"] = userFullData.user_id;
    bodyparam["organization_id"] = company.id;
    bodyparam["organization_user_id"] = org_user_id;
    // let  p = `?talent_id=${userFullData.user_id}&organization_id=${company.id}&organization_user_id=${org_user_id}`;
    var { data, status } = await updateTalentSearchToken(bodyparam);
    console.log("dataData", status);
    // if(status == 200)
    var updateDetail = userFullData;
    updateDetail.is_tokenized = 1;
    updateUserData(updateDetail);
    localStorage.setItem("localUserData",JSON.stringify(updateDetail));
    setShowOceanReport(true);
    onClosePopUp();
  };
  const getUser = async (id) => {
    await getMyCv(id);
    await getProfileDetails(id);
    await getDataValue(id);
    await getDataIntrest(id);
    await getDataPersonality(id);
    await getDataEmployability(id);
    await getDataFutureWorks(id);
    await getDataEnglishTest(id);
    await getOceanReport(id); //this is static id for refren university
  };
  console.log("jooooo", jobProxy);

  const getMyCv = async (id) => {
    const { data } = await getMyCyOverall(id);
    if (data?.status === 1) {
      setUserdata(data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  const downloadComplete = (data) => {
    // debugger
    // setIsMyCvDownLoad(false)
    setDownloadingComplete(true);
    // setHandleCvPrint(false);
    setLoader(false);
  };
  const getProfileDetails = async (id) => {
    const { data } = await getOverViewProfile(id);
    if (data?.status === 1) {
      setProfileDetails(data.data);
      setLoading(false);
    } else {
      setLoading(false);
    }
  };
  if (downloadingComplete) {
    setTimeout(() => setIsMyCvDownLoad(false), 4000);
  }
  useEffect(async () => {
    console.log("localStore",localStorage.getItem("UserId"));
    updateUserData(JSON.parse(localStorage.getItem("localUserData")))
    console.log("UserJOnProxy",localStorage.getItem("localUserData"))
    await getUser(localStorage.getItem("UserId"));
  }, [jobProxy.userid]);

  useEffect(async () => {
    // await getUser(jobProxy.userid);
    // if (Object.keys(jobProxy.userFullDetails).length > 0) {
      // updateUserData(jobProxy.userFullDetails);
    // }
    if (talentTokenAvaialable ?? 0 > 0) {
      setModalMode("available-coins");
    } else {
      setModalMode("empty-coin");
    }
  }, [jobProxy.userFullDetails]);

  const handleOpenOceanReport = () => {
    setOpenOceanReport(!openOceanReport);
  };
  const handleOpenAssessmentReport = () => {
    setOpenAssessmentReport(!openAssessmentReport);
  };
  const pageBottomRef = useRef(null);

  const scrollToBottom = () => {
    pageBottomRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  var data1 = {
    // "empty-package": {
    //   subDesc: "Your internship wallet is empty",
    //   desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
    //   submit: "Submit",
    // },
    "empty-coin": {
      subDesc: "Looks like your wallet is empty.",
      desc: "You not have enough token to see full profile",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Token Deducted",
      desc: `${t("Thank you for purchasing our Graduate Talent Finder plan package.You are about to redeem 1 token out of your total")} ${
        talentTokenAvaialable ?? 0
      } ${t("tokens for this Talent Profile")}`,
      submit: "Proceed",
    },
    // "position-coins": {
    //   subDesc: `${t(
    //     "Unfortunately, you are unable to complete this transaction due to insufficient token balance."
    //   )}`,
    //   desc: `1. ${t("Reduce application to")} ${coins} ${t("positions")}`,
    //   desc2: `2. ${t("Create new internship with ")} ${
    //     parseInt(formVal.no_positions) - coins
    //   } ${t("position(s) using ala carte payment mode")}.`,
    //   submit: `${t("ok")}`,
    //   "tokens-available": `${t("Available token(s)")}: `,
    //   "required-tokens": `${t("Required token(s)")}: `,
    // },
  };
  console.log("ddddad", data1, modalMode);
  async function handleScroll() {
    setShowOceanReport(true);
    console.log("offsetHeight", document.body.offsetHeight);
    await delay(200);
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: "smooth",
    });
  }
  var StudentImage = styled.div`
    background-image: url(${(props) => props.image || ""});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding-bottom: 100%;
    position: relative;
    width: 100%;
    border-radius: 50% !important;
    max-width: 100%;
    height: auto;
  `;

  const MainTitle = styled.span`
    font-family: General Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: #202020;
    padding-left: 1rem;
  `;
  const [profileModal, setProfileModal] = useState(false);

  function onClosePopUp() {
    setProfileModal(false);
  }
  const ModalHeaderTextThree = styled.div`
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 35px;
    text-align: center;

    color: #202020;
    margin-top: 29px;
  `;

  console.log("userDetaiiils", stateUserDetail, talentTokenAvaialable);
  const [unAuth, setUnAuth] = useState(false);

  const ButtonPink = styled.button`
    width: ${(props) => props.width};
    background: ${(props) => (props.secondary ? Colors.light : "#FA4AA1")};
    border-radius: 128px;
    outline: none;
    border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
    line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
    color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
    padding: 13px 0px;
    margin: ${(props) => (props.margin ? props.margin : "15px 0")};
    // min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
    width: 192px;
    // min-width:fit-content;
    cursor: pointer;
    &:disabled {
      background: #d04c8d;
      color: #ccc;
      cursor: no-drop;
    }
    &:hover:not([disabled]) {
      color: ${Colors.light};
      background: ${(props) => (props.secondary ? Colors.pink : "#CB3B92")};
    }
    @media (max-width: 599px) {
      width: 100%;
    }
  `;
  const [jobs, setJobs] = useState([]);
  const [popup, setPopup] = useState(false);
  const componentRef = useRef(null);
  const oceanComponentRef = useRef(null);
  const imagePersonlityProfileRef = useRef(null);
  const imageWorkValuesRef = useRef(null);
  const imageEmployabilityRef = useRef(null);

const getModalContent = () => {
  switch (detailModalMode) {
    case "interest":
      return (
        <InterestProfileModal
          data={workValuesView}
          labels={workValuesLabels}
        />
      );
    case "preferences":
      return (
        <PreferencesModal
          result={workPersonalityData}
          data={workPersonality}
          labels={workPersonalityLabels}
          colors={workPersonalityColors}
        />
      );
    case "employability":
      return <EmployabilityFactorModal data={workEmployabilityDetails} />;
    case "valueProfile":
      return <ValueProfileModal data={workEmployabilityView} />;
    case "futureLearning":
      return <FutureWorkFactorModal data={workFutureWorksView} />;
    case "motivationLevel":
      return <MotivationLevelModal data={workInterestView} />;
    case "englishProficiency":
      return <EnglishProficiencyModal data={workEnglishTestView} />;
    default:
      break;
  }
};
useEffect(async () => {
  if (companyProxy.selectcompany) {
      const { data } = await getjobslist(companyProxy.selectcompany.org_code);
      if (data.status == 1) {
        setJobs(data.data);
      }
      console.log("dtaJobs", data?.data);
    }
  }, [companyProxy.selectcompany]);

  console.log("workCompetenciesData", competencyData, personalityAspectData);
  return (
    <>
      <style type="text/css">
        {`
  .child {
  
    position: absolute;
     top: -18px;
    right: -30px;
  }
  
  .parent {
    position: relative;
    width: 230px;
    height: 230px;
      @media (max-width: 599px) {
    margin :0 auto;
  }
}
  }`}
      </style>
      <UnAuthEmPopup isOpen={unAuth} setOpen={setUnAuth}></UnAuthEmPopup>
      {popup ? (
        <TalentShortlistModal
          shortlistedAdded={() => {
            var updateDetail = userFullData;
            updateDetail.is_shortlisted = 1;
            updateUserData(updateDetail);
            localStorage.setItem("localUserData",JSON.stringify(updateDetail));
          }}
          open={popup}
          onClose={() => setPopup(false)}
          id={userFullData.user_id}
          joblist={jobs}
          name={"demo"}
        />
      ) : null}
      <MainLayout>
        <Row>
          <Col>
            <div className="breadcrumb">
              {/* <p onClick={() => navigate("/jobs")} style={{ cursor: "pointer" }}>
            Jobs
          </p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "-10px" }}
          />
          <p
            onClick={() => navigate("/jobs/viewjobs")}
            style={{ cursor: "pointer" }}
          >
            View Job
          </p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "-10px" }}
          /> */}
              <p
                onClick={() => navigate("/talentsearch")}
                style={{ cursor: "pointer" }}
              >
                {t("Talent Search")}
              </p>
              <img
                src={rightarrow}
                alt="img"
                style={{ height: "14px", marginTop: "-10px" }}
              />
              <p
                style={{ cursor: "pointer" }}
                onClick={() =>
                  navigate("/talentsearch", { state: { tabIndex: 1 } })
                }
              >
                {t("Graduate Talent Finder")}
              </p>
              <img
                src={rightarrow}
                alt="img"
                style={{ height: "14px", marginTop: "-10px" }}
              />
              <p style={{ color: "#D04C8D" }}>{t("Talent Profile")}</p>
            </div>
            <div style={{ display: "flex" }}></div>
          </Col>

          {userFullData.is_tokenized == 1 ? (
            <Col>
              <div
                className="d-flex justify-content-end chart-type-toggle"
                style={{
                  alignContent: "center",
                  flexWrap: "wrap",
                }}
              >
                <LinkText style={{ paddingTop: "33px" }}>
                  {" "}
                  {t("Full Profile")}
                </LinkText>
              </div>
            </Col>
          ) : (
            <Col>
              <div
                className="d-flex justify-content-end chart-type-toggle"
                style={{
                  alignContent: "center",
                  flexWrap: "wrap",
                }}
              >
                {jobProxy.fromShortlist ? (
                  <></>
                ) : (
                  <Button
                    onClick={useTokenFullProfile}
                    margin={"0px 0px 10px 0px"}
                    name={t("View Full Profile")}
                  ></Button>
                )}
              </div>
            </Col>
          )}
        </Row>

        {/* <div style={{ display: "flex" }}> */}
        {/* <MainText>{"Ali muthu ahok"}</MainText>
          <ShortButton>Shorlisted</ShortButton> */}
        {/* </div> */}
        {userFullData.is_tokenized == 0 ? (
          <></>
        ) : (
          <MainWrapper style={{ marginBottom: "10px" }}>
            <Row>
              <Col lg="6"></Col>

              <Col lg="6">
                <FlexWrapper>
                  { savedProfileLoading ? 
                  <ButtonPink
                
                  style={{ marginTop: "1.5rem" }}
                >
                  {t("Saving...")}
                </ButtonPink>
                  :userFullData.is_saved === 0 ? (
                    <Col lg="4" xs="12">
                      <ButtonPink
                        onClick={() => {
                          updateTalentUser();
                        }}
                        style={{ marginTop: "1.5rem" }}
                      >
                        {t("Save Profile")}
                      </ButtonPink>
                    </Col>
                  ) : (
                    <Col lg="4" xs="12">
                      <LinkText>{t("Saved Profile")}</LinkText>
                    </Col>
                  )}
                  {jobs.length > 0 ? (
                    userFullData.is_shortlisted === 0 ? (
                      <Col lg="4" xs="12">
                        <ButtonPink
                          onClick={() => {
                            setPopup(true);
                          }}
                          style={{ marginTop: "1.5rem" }}
                        >
                          Shortlist
                        </ButtonPink>
                      </Col>
                    ) : (
                      <Col lg="4">
                        <LinkText>Shortlisted </LinkText>
                      </Col>
                    )
                  ) : (
                    <></>
                  )}

                  {loader ? (
                    <Col lg="4">
                      {" "}
                      <ButtonPink style={{ marginTop: "1.5rem" }}>
                        Downloading
                      </ButtonPink>
                    </Col>
                  ) : (
                    <Col lg="4">
                      <ButtonPink
                        onClick={() => {
                          setLoader(true);
                          // captureImage();
                          setIsMyCvDownLoad(true);
                        }}
                        style={{ marginTop: "1.5rem" }}
                      >
                        {t("Download CV")}
                      </ButtonPink>
                    </Col>
                  )}
                </FlexWrapper>
              </Col>
            </Row>
            <Row>
              <Col lg="2">
                <div className="parent">
                  <Image
                    alt="Profile"
                    src={profileDetails.profile_pic
                      // item.other_details.gender === "Male"
                      // ? 
                      // maleUser
                      // : femaleUser
                    }
                  />
                  <div className="child">
                    <BadgeIcon style={{ height: 70, width: 70 }}></BadgeIcon>
                  </div>
                </div>

                {/* <StudentImage className="ratio img-fluid rounded-circle" image={jobProxy.userFullDetails.profile_picture}/> */}
              </Col>

              <Col lg="10">
                <Row>
                  <Col>
                    <MainTitle>{userFullData.first_name}</MainTitle>
                  </Col>
                </Row>
                <Row>
                  <Col xs="12">
                    <hr />
                  </Col>
                  <Col xs="12">
                    <FlexCenterWrapper>
                      <StyledDarkerCardCustom>
                        <Phone />
                        {userFullData.other_details?.mob_number}
                      </StyledDarkerCardCustom>
                      <StyledDarkerCardCustom>
                        <Email />
                        {profileDetails.email}
                      </StyledDarkerCardCustom>
                      <StyledDarkerCardCustom>
                        {profileDetails.curr_country_abbr}
                      </StyledDarkerCardCustom>
                      <StyledDarkerCardCustom>{`Year ${userFullData?.curr_study_year}`}</StyledDarkerCardCustom>

                      {/* <br></br> */}
                      <StyledDarkerCardCustom>
                        <span className="label">{"DOB"}:</span>{" "}
                        <span className="value">{profileDetails.dob}</span>
                      </StyledDarkerCardCustom>
                      <StyledDarkerCardCustom>
                        <span className="value">
                          {profileDetails.insti_name_abbr}
                        </span>
                      </StyledDarkerCardCustom>
                      <StyledDarkerCardCustom>
                        <span className="value">
                          {profileDetails.curr_qualification_abbr}
                        </span>
                      </StyledDarkerCardCustom>
                    </FlexCenterWrapper>
                  </Col>
                </Row>
              </Col>
            </Row>
          </MainWrapper>
        )}
        <MainWrapper>
          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Employment Details")}
            </SubHeading>
            <hr />
            {dataUser?.employement_details?.map((i, index) => {
              return (
                <Section>
                  <div>
                    <NormalText className="m_title">{i?.role}</NormalText>
                    <SubHeading
                      className="m_title"
                      style={{ fontStyle: "italic", marginBottom: "0" }}
                    >
                      {i?.organization}
                    </SubHeading>
                    <SpanText className="m_title">{i?.location}</SpanText>
                  </div>
                  <SpanText className="m_title">
                    {i?.duration_months} {t("Months")}
                  </SpanText>
                </Section>
              );
            })}
          </BoxWrapper>
          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Internship Details")}
            </SubHeading>
            <hr />
            {dataUser?.internship_details?.map((list, index) => {
              return (
                <Section>
                  <div>
                    <NormalText className="m_title">
                      {list?.job_title}
                    </NormalText>
                    <SubHeading
                      className="m_title"
                      style={{ fontStyle: "italic", marginBottom: "0" }}
                    >
                      {list?.organization}
                    </SubHeading>
                    <SpanText className="m_title">{list?.location}</SpanText>
                  </div>
                  <SpanText className="m_title">
                    {list?.duration_months} {t("Months")}
                  </SpanText>
                </Section>
              );
            })}
          </BoxWrapper>
          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Educational Details")}
            </SubHeading>
            <hr />
            {dataUser?.education?.map((data, index) => {
              return (
                <div style={{ marginBottom: "36px" }}>
                  <NormalText className="m_title">{data?.degree}</NormalText>
                  <SubHeading className="m_title" style={{ marginBottom: "0" }}>
                    {data?.institution}
                  </SubHeading>
                  <SpanText
                    className="m_title"
                    style={{
                      fontStyle: "italic",
                      fontSize: "16px",
                      color: "#525252",
                      marginBottom: "12px",
                    }}
                  >
                    {t("Completion year")} {data?.completion_year}
                  </SpanText>
                  <ContentText>{data?.description}</ContentText>
                </div>
              );
            })}
          </BoxWrapper>

          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>
              {t("Events & Projects")}
            </SubHeading>
            <hr />
            {dataUser?.event_details?.map((link, index) => {
              return (
                <Section>
                  <div style={{ maxWidth: "70%" }}>
                    <NormalText>{link?.event_title}</NormalText>
                    <ContentText>{link?.description}</ContentText>
                  </div>
                </Section>
              );
            })}
          </BoxWrapper>

          <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>{t("Skills")}</SubHeading>
            <hr />
            <CardsWrapper style={{ marginTop: "24px" }}>
              {dataUser?.skills?.map((card, idx) => (
                <Card1 key={idx} style={{ marginTop: "12px" }}>
                  <Image1 src={Skills1} />
                  <CardHeader>{card?.title}</CardHeader>
                </Card1>
              ))}
            </CardsWrapper>
          </BoxWrapper>
          <br />
          <div>

            <SeparatorDiv onClick={handleOpenAssessmentReport}>
              <HrLine />
              <Separator>{t("Assessment Report")}</Separator>
              <HrLine />

              {openAssessmentReport ? (
                <IconButton>
                  <UpIcon />
                </IconButton>
              ) : (
                <IconButton>
                  <DownIcon />
                </IconButton>
              )}
            </SeparatorDiv>

            {openAssessmentReport ? (
              
              <Row>
                <div ref={imagePersonlityProfileRef}>
                <Col lg="12" style={{ marginTop: "20px" }}>
                  <Ad2StudentPersonalityProfile
                    onViewMoreClick={() => {
                      setDetailModalMode("preferences");
                      dispatch(updateModal(true));
                      console.log("Clicked personality details");
                    }}
                    data={workPersonalityData}
                  />
                </Col>
                </div>
               
               <div ref={imageWorkValuesRef} className="row">
               <Col lg="6" className="assementPart">
                  <Ad2StudentWorkValues
                    data={workValuesData}
                    fromAnalyticalDashboard={false}
                    onViewMoreClick={() => {
                      setDetailModalMode("interest");
                      dispatch(updateModal(true));
                      console.log("Clicked workvalue");
                    }}
                  />
                </Col>
                <Col lg="6" className="assementPart">
                  <Ad2StudentWorkInterest
                    onViewMoreClick={() => {
                      setDetailModalMode("motivationLevel");
                      dispatch(updateModal(true));
                      console.log("Clicked motivation");
                    }}
                    data={workInterestData}
                  />
                </Col>
               </div>
                
<div ref={imageEmployabilityRef}>
<Col lg="12" className="assementPart">
                  <Ad2EmployabilityFactors
                    data={workEmployabilityData}
                    onViewMoreClick={() => {
                      setDetailModalMode("employability");
                      dispatch(updateModal(true));
                      console.log("Clicked employ");
                    }}
                  ></Ad2EmployabilityFactors>
                </Col>
</div>
                
  {/* </div> */}
                
                <div  ref={componentRef}>
                <Col id="futreofWorkComponent" lg="12" className="assementPart">
                  <Ad2FutureOfWork
                    onViewMoreClick={() => {
                      setDetailModalMode("futureLearning");
                      dispatch(updateModal(true));
                      console.log("Clicked future");
                    }}
                    data={workFutureWorksData}
                  ></Ad2FutureOfWork>
                </Col>
                <Col lg="12" className="assementPartOne">
                  <Ad2EnglishProficiency
                    onViewMoreClick={() => {
                      setDetailModalMode("englishProficiency");
                      dispatch(updateModal(true));
                      console.log("Clicked english");
                    }}
                    data={workEnglishTestView}
                  ></Ad2EnglishProficiency>
                </Col>
                </div>
                
              </Row>
            ) : (
              ""
            )}
            <br />
          </div>

          <div ref={oceanComponentRef}>
            {userFullData.is_tokenized == 1 ? (
              <>
                <SeparatorDiv onClick={handleOpenOceanReport}>
                  <HrLine />
                  <Separator>{t("OCEAN Report")}</Separator>
                  <HrLine />

                  {openOceanReport ? (
                    <IconButton>
                      <UpIcon />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <DownIcon />
                    </IconButton>
                  )}
                </SeparatorDiv>

                {openOceanReport ? (
                  <>
                    <Row lg="12">
                      <Col lg="6" style={{ marginTop: "20px" }}>
                        <WorkCompetencyFactorChart data={competencyData}>
                          {" "}
                        </WorkCompetencyFactorChart>
                      </Col>
                      <Col lg="6" style={{ marginTop: "20px" }}>
                        <PersonalityAspectsChart
                          data={personalityAspectData}
                        ></PersonalityAspectsChart>
                      </Col>
                    </Row>
                    <Row lg="12">
                      {/* <FiveFactorChartSlider data={workPersonalityData}></FiveFactorChartSlider> */}
                      <ThirtyFacetsChart data={facetData}></ThirtyFacetsChart>
                      <Col lg="6" style={{ marginTop: "20px" }}>
                        <PredictiveReliabilityFactorChart
                          data={reliabilityData}
                        ></PredictiveReliabilityFactorChart>
                      </Col>
                    </Row>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              <></>
            )}
          </div>
        </MainWrapper>
      </MainLayout>
      <DetailModal>
        <ModalContainer>
          <Row>
            <Col>{getModalContent()}</Col>
          </Row>
        </ModalContainer>
      </DetailModal>
      {isMyCvDownLoad && (
        <div style={{ height: "0", display: "hidden" }}>
          <MyCvPreview
            profileDetails={profileDetails}
            dataUser={dataUser}
            isMyCvDownLoad={isMyCvDownLoad}
            downloadComplete={downloadComplete}
            handleCvPrint={false}
            userDetails={userFullData}
          />
        </div>
      )}
      <FullProfileModal
        title={"Talent Profile"}
        subTitle={"Token Deducted"}
        show={profileModal}
        close={onClosePopUp}
        data1={data1}
        modalMode={modalMode}
        deducteToken={deducteToken}
      ></FullProfileModal>
    </>
  );
}
