import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut, getElementAtEvent } from "react-chartjs-2";
import { cloneDeep, merge } from "lodash";
import { Utils } from "../utilities";
import { Col, Row } from "react-bootstrap";
import { noDataFoundPlugin } from "./doughnutChart";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
    cutout: "80%",
    circumference: 180,
    borderRadius: 30,
    rotation: -90,
    aspectRatio: 1,
    borderWidth: 2,
    spacing: 30,
    borderColor: 'transparent',
    onHover: (event, chartElement) => {
        event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default';
    },
    layout: {
        padding: {
            left: 40,
            right: 40,
            top: 30,
            bottom: 30,
        },
        margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
            width: 40,
        },
        datalabels: {
            display: false,
        },
        title: {
            color: '#000000',
            align: 'center',
            position: 'bottom',
            text: 'asdf',
            display: true,
            padding: 0,
            font: {
                family: 'Inter',
                size: '20px',
                style: 'normal',
                weight: 700,
                lineHeight: '24px',
            }
        },
        tooltip: {
            enabled: true,
            yAlign: 'bottom',
            xAlign: 'center',
            displayColors: false,
            padding: 10,
            callbacks: {
                label: (context) => {
                    return context.formattedValue;
                },
                title: (context) => {
                    return '';
                }
            }
        }
    },
    title: {
        display: false,
    },
};

const getPlugins = () => ({
    id: "curves",
    beforeDraw(chart) {
        const ctx = chart.ctx;
        const legendItems = chart.options.plugins.legend.labels.generateLabels(chart);
        ctx.save();
        chart.data.datasets.forEach((dataset, datasetIndex) => {
            const cloneBackgroundColorReversed = [];
            const datasetMeta = chart.getDatasetMeta(datasetIndex);
            const chartData = dataset && dataset.data;
            const reversed = [];
            datasetMeta.data.forEach((segment, segmentIndex) => {
                const legendItem = legendItems[segmentIndex];
                if (legendItem && !legendItem.hidden && chartData && chartData[segmentIndex]) {
                    reversed.unshift(segment);
                    cloneBackgroundColorReversed.unshift(dataset.backgroundColor[segmentIndex])
                }
            });
            reversed.forEach((segment, segmentIndex) => {
                const offset = 15;
                // if (segmentIndex === 0) {
                //     ctx.shadowColor = "rgba(0, 0, 0)";
                //     ctx.shadowBlur = 15;
                //     ctx.shadowOffsetX = 0;
                //     ctx.shadowOffsetY = 0;
                    
                // }
                ctx.strokeStyle = '#ffffff';
                ctx.lineWidth = 1;
                ctx.lineCap = "round";
                ctx.beginPath();
                ctx.arc(
                    segment.x,
                    segment.y,
                    segment.innerRadius + ctx.lineWidth / 2 + offset - 4,
                    -Math.PI + 0.114,
                    (segment.endAngle || 0) - 0.114,
                    false
                ); 
                ctx.stroke();
                // ctx.shadowColor = null;
                // ctx.shadowBlur = null;
                // ctx.shadowOffsetX = null;
                // ctx.shadowOffsetY = null;

                const backgroundColor = cloneBackgroundColorReversed[segmentIndex];
                ctx.strokeStyle = backgroundColor;
                ctx.lineWidth = segment.outerRadius - segment.innerRadius;
                ctx.lineCap = "round";
                ctx.beginPath();
                ctx.arc(
                    segment.x,
                    segment.y,
                    segment.innerRadius + ctx.lineWidth / 2 + offset,
                    -Math.PI + 0.119,
                    (segment.endAngle || 0) - 0.119,
                    false
                ); 
                ctx.stroke();
                ctx.closePath();
            });
        });
    },
});

export default function MultiProgressHalfDonut({
    data,
    showlabel,
    data2,
    optionsOverride,
    gradient,
    titles: [title1, title2],
    onClick = () => { },
}) {
    console.log("Date 2",data2);
    // const legendRef = React.useRef(null);
    let finalOptions = cloneDeep(defaultOptions);
    if (optionsOverride) {
        finalOptions = { ...finalOptions, ...optionsOverride };
    }
    const finalOptions1 = cloneDeep(finalOptions),
        finalOptions2 = cloneDeep(finalOptions);
    const curves = getPlugins();
    const chartRef1 = React.useRef(null);
    const chartRef2 = React.useRef(null);
    const htmlLegendPlugin3 = {
        id: 'htmlLegend',
    };

    const plugins = [curves, htmlLegendPlugin3, noDataFoundPlugin];

    React.useEffect(() => {
        htmlLegendPlugin3.afterUpdate = (function (chart, args, options) {
            // const ul = Utils.getOrCreateLegendList(chart, this.legendRef);

            
            // while (ul.firstChild) {
            //     ul.firstChild.remove();
            // }
            
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            console.log("itemList",items)
            items.forEach((item, index) => {
                console.log('this is working',item)
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        
                        chart.toggleDataVisibility(item.index);
                        this.chartRef2.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                        this.chartRef2.setDatasetVisibility(item.datasetIndex, !this.chartRef2.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                    this.chartRef2.update();
                };
                
                const boxSpan = document.createElement('span');
                boxSpan.style.background = item.fillStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.borderRadius = '10px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.height = '10px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '10px';
                
                const textContainer = document.createElement('p');
                textContainer.style.fontFamily = 'Inter';
                textContainer.style.fontStyle = 'normal';
                textContainer.style.margin = '0px';
                textContainer.style.color = '#585858';
                textContainer.style.fontWeight = '400';
                textContainer.style.lineHeight = '13px';
                textContainer.style.fontSize = '13px';
                textContainer.style.marginLeft = '3px';
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';
                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);
                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                // ul.appendChild(li);
            });
        }).bind({
            // legendRef: legendRef.current,
            chartRef2: chartRef2.current,
        });
    }, []);


    const onClickEvent = (where, event) => {
        const { current: chart } = where ? chartRef1 : chartRef2;

        if (!chart) {
            return;
        }
        const element = getElementAtEvent(chart, event);
        if (!element.length) return;

        const { datasetIndex, index } = element[0];

        console.log(data.labels[index], data.datasets[datasetIndex].data[index]);
        onClick(where, { label: data.labels[index], data: data.datasets[datasetIndex].data[index] });
    };

    return (
        <div>
               <Row>
                {/* <Col xs="12"><div id="legend-container" ref={legendRef}></div></Col> */}
                {/* <Col xs="12">
           
                    </Col> */}
                <Col md="6"><Doughnut
                    onClick={onClickEvent.bind(this, 0)}
                    style={{ maxHeight: '280px' }}
                    data={data}
                    ref={chartRef1}
                    options={merge(finalOptions1, { 
                        plugins: { 
                            title: { 
                                text: title1 
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        return context.formattedValue + '%';
                                     }
                                }
                            }
                        } 
                    })}
                    plugins={plugins}
                /></Col>
                <Col md="6"><Doughnut
                    onClick={onClickEvent.bind(this, 1)}
                    style={{ maxHeight: '280px' }}
                    data={data2}
                    ref={chartRef2}
                    options={merge(finalOptions2, { 
                        plugins: { 
                            title: { 
                                text: title2 
                            },
                            tooltip: {
                                callbacks: {
                                    label: function (context) {
                                        return context.formattedValue + '%';
                                     }
                                }
                            }
                        } 
                    })}
                    plugins={[curves, noDataFoundPlugin]}
                /></Col>
              
            </Row>
            
        </div>
    );
}
