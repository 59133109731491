import React from "react";
import ChartLoader from "../chartLoader";
import HorizontalBarChartMultiLabel from "../charts/horizantalBarChartMulti";
import NoDataChartContainer from "../noDataFoundChart";
import { Ad2Card } from "../adCard";
import { useTranslation } from "react-i18next";
import { HtmlLegendChartWrapper2 } from "../../../pages/applicantAssessments/styles/analyticalStyles";


const columns = [
    {
        name: 'Openess',
        selectorKey: 'openess',
        selector: row => row.openess,
        sortable: false,
    },
    {
        name: 'Conscientiousness',
        selectorKey: 'conscientiousness',
        selector: row => row.conscientiousness,
        sortable: false,
    },
    {
        name: 'Extraversion',
        selectorKey: 'extraversion',
        selector: row => row.extraversion,
        sortable: false,
    },
    {
        name: 'Agreeableness',
        selectorKey: 'agreeableness',
        selector: row => row.agreeableness,
        sortable: false,
    },
    {
        name: 'Neuroticism',
        selectorKey: 'neuoroicism',
        selector: row => row.neuoroicism,
        sortable: false,
    },
];

export function Ad2StudentPersonalityProfile({personlityProfileUser,totalEmployees, data,personalityProfile }) {
    const { t, i18n } = useTranslation();
    const chartData = data && data.data;
    const status = data && data.status;
    const total = data && data.total;
    
    const chartPersonlty = personalityProfile.data;
    const personalityMotivation = chartPersonlty && chartPersonlty["five-factor"];


    console.log("chartDataChart",personlityProfileUser);
    const staticData = personlityProfileUser
    const columns = [
        {
            name: t('Employee Name'),
            selectorKey: 'display_name',
            selector: row => row.display_name,
            sortable: false,
            
        },
        {
            name: t('Dept'),
            selectorKey: 'department',
            selector: row => row.department,
            sortable: false,
        },
        {
            name: t('O'),
            selectorKey: 'o',
            selector: row => row.o,
            sortable: false,
            width: "9%",
        },
        {
            name: t('C'),
            selectorKey: 'c',
            selector: row => row.c,
            sortable: false,
            width: "9%",

        },
        {
            name: t('E'),
            selectorKey: 'e',
            selector: row => row.e,
            sortable: false,
            width: "9%",

        }, {
            name: t('A'),
            selectorKey: 'a',
            selector: row => row.a,
            sortable: false,
            width: "9%",

        },{
            name: t('N'),
            selectorKey: 'n',
            selector: row => row.n,
            sortable: false,
            width: "9%",

        },{
            name: t('Personality'),
            width: "25%",
            selectorKey: 'personalityTitle',
            selector: row => row.personalityTitle,
            sortable: false,
        },
    ];
    // const columns = [
    //     {
    //         name: t('Openness'),
    //         selectorKey: 'openess',
    //         selector: row => row.openess,
    //         sortable: false,
    //     },
    //     {
    //         name: t('Conscientiousness'),
    //         selectorKey: 'conscientiousness',
    //         selector: row => row.conscientiousness,
    //         sortable: false,
    //     },
    //     {
    //         name: t('Extraversion'),
    //         selectorKey: 'extraversion',
    //         selector: row => row.extraversion,
    //         sortable: false,
    //     },
    //     {
    //         name: t('Agreeableness'),
    //         selectorKey: 'agreeableness',
    //         selector: row => row.agreeableness,
    //         sortable: false,
    //     },
    //     {
    //         name: t('Neuoroicism'),
    //         selectorKey: 'neuoroicism',
    //         selector: row => row.neuoroicism,
    //         sortable: false,
    //     },
    // ];

    return <>
        <Ad2Card title={t("Aggregated Employees Personality Profile")} data={personlityProfileUser?.data} columns={columns} exportFileName="employees_personality_profile" sumpersonal={totalEmployees} sum={personalityMotivation} status={status}>
            <HtmlLegendChartWrapper2>
            <HorizontalBarChartMultiLabel
            maxValue={5}
                data={{
                    labels: [
                        t('Openness'),
                        t('Conscientiousness'),
                        t('Extraversion'),
                        t('Agreeableness'),
                        t('Emotional Stability'),
                    ],
                    datasets: [{
                        data: [
                            chartData && chartData[0] && chartData[0].openess,
                            chartData && chartData[0] && chartData[0].conscientiousness,
                            chartData && chartData[0] && chartData[0].extraversion,
                            chartData && chartData[0] && chartData[0].agreeableness,
                            chartData && chartData[0] && chartData[0].neuoroicism,
                        ],
                        backgroundColor: [
                            '#1694F0',
                            '#0F87DE',
                            '#0F83D8',
                            '#0973C0',
                            '#0A6FB8',
                        ],
                        additionalText: [
                            { lt: t('Pragmatism'), lb: '', rt: t('Openness'), rb: '' },
                            { lt: t('Low Self Control'), lb: '', rt: t('High Self Control'), rb: '' },
                            { lt: t('Introversion'), lb: '', rt: t('Extraversion'), rb: '' },
                            { lt: t('Independence'), lb: '', rt: t('Agreeableness'), rb: '' },
                            { lt: t('High Anxiety'), lb: '', rt: t('Low Anxiety'), rb: '' }
                        ],
                        borderWidth: 0,
                        borderSkipped: false,
                        borderRadius: 4,
                        barPercentage: 0.01,
                        categoryPercentage: 1,
                    }],
                }} />
            </HtmlLegendChartWrapper2>
        
        </Ad2Card>
    </>;
}