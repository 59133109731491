
import { useEffect } from "react";

import styled from "styled-components";
import Companycover from "../../assets/company.png";

// styling starts
const LayoutWrapper = styled.section`
  height: 100vh;
  display: flex;

  @media (max-width: 900px) {
    display: block;
  }
`;
const ImageSection = styled.div`
  min-width: 340px;
  @media (max-width: 900px) {
    min-width: auto;
  }
`;
const CoverImageWrapper = styled.div`
  position: relative;
  line-height: 0px;
`;
const Image = styled.img`
  // height: 90vh;
  width:100%;
`;
const PageContentSection = styled.div`
  overflow-y: auto;
  max-width: 1000px;
  flex-grow: 2;
  padding: 4rem 7rem;
  &::-webkit-scrollbar {
    width: 0px;
  }

  @media (max-width: 1090px) {
    padding: 3rem 1.5rem;
  }
`;

const GradientCover = styled.div`
  // position: absolute;
  top: 0;
  left: 0;
  bottom: 0px;
  right: 0;
  background: linear-gradient(
    90deg,
    rgba(87, 80, 141, 0.4) 0%,
    rgba(245, 75, 161, 0.4) 100%
  );
  border-radius: 0px 42px 42px 0px;

  @media (min-width: 768px) and (max-width: 1023px) {
    border-radius: 0px 65px 65px 0px;
  }
  @media (min-width: 1440px) {
    border-radius: 0px 50px 50px 0px;
  }
  @media (min-width: 2000px) {
    border-radius: 0px 100px 100px 0px;
  }

  @media (min-width: 1024px) and (max-width: 1200px) {
    border-radius: 0px 50px 50px 0px;
  }
`;
// styling ends

export default function UserCreationLayout({ children, coverImg }) {
  useEffect(() => {
    document.body.classList.remove("bg-body-cover");
    // getDatas();
  }, []);
  // const getDatas = async () => {
  //   const { data, status } = await getFormOptions();
  //   if (data && data.status === 1) {
  //     onDataConstruct(data.data);
  //   }
  // };
  // const onDataConstruct = (data) => {
  //   let obj = {};
  //   let arr = Object.keys(data);
  //   for (let i = 0; i < arr.length; i++) {
  //     let key = arr[i];
  //     obj[key] = data[key].map((y) => {
  //       return { label: y, value: y };
  //     });
  //   }
  //   getCountry(obj)
  // };
  // const getCountry = async (obj) => {
  //   const { data, status } = await getCountries();
  //   if (status === 200) {
  //     setDatas(data,obj);
  //   }
  // };
  // const setDatas = (data,obj) => {
  //   if (data.status === 1) {
  //     const { location } = data.data;
  //     const country = location.map((x) => {
  //       return { ...x.country, label: x.country, value: x.country };
  //     });
  //     dispatch(updateFormOptions({ ...obj,country:country }));
  //   }
  // };


  return (
    <LayoutWrapper>
      <ImageSection>
        <CoverImageWrapper>
          <Image src={coverImg ? coverImg : Companycover} alt="Cover image" className="set_login" />
          <GradientCover />
        </CoverImageWrapper>
      </ImageSection>
      <PageContentSection>{children}</PageContentSection>
    </LayoutWrapper>
  );
}
