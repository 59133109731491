import React from 'react'
import styled from 'styled-components'
import ReactSpeedometer from "react-d3-speedometer"
import { ChartHeaderText } from '../common';
const HeaderText = styled.div`
font-family: 'Inter';
font-style: normal;
font-weight: 800;
font-size: 20px;
line-height: 20px;
/* or 100% */
color: #D84E8D;
width:100%;
text-align:center;
margin-top:36px;
margin-bottom:80px;
@media (max-width: 500px) {
    margin-bottom:36px;
  }
`;
export default function ChartsView({text}) {
    return (
        <div style={{display:"flex",flexDirection:"column",justifyContent:"space-around",width:"360px",alignItems:"center",maxHeight:"320px"}}>
            {text ? <HeaderText> {text} </HeaderText>: null}
            <ReactSpeedometer
               width={250}
               ringWidth={80}
               needleHeightRatio={0.7}
               needleColor={"#8FBBDF"}
                currentValueText=""
                customSegmentLabels={[
                    {
                        text: "Low",
                        position: "OUTSIDE",
                        color: "#000000",
                    },
                    {
                        text: "Moderate",
                        position: "OUTSIDE",
                        color: "#000000",
                    },
                    {
                        text: "High",
                        position: "OUTSIDE",
                        color: "#000000",
                    }
                ]}
                needleTransitionDuration={4000}
                needleTransition="easeElastic"
                segments={3}
                customSegmentStops={[0, 250, 750, 1000]}
                segmentColors={["#0B74C3", "#045694", "#053D67"]}
                value={100}

            />
        </div>
    )
}
