import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/addNewIntern.png";
import PlusIcon from "../../assets/plus.png";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import './MyComponent.css';
import { Col, Row } from "react-bootstrap";
import DashboardCards from "../dashboardCards/dashboardCards";
import { Colors } from "../../utilities/colors";
const AddNewInternWrapper = styled.div`
  background: transparent;
  border-radius: 10px;
//   padding: 15px;
  margin: 20px 0 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 50px 0 50px 0;
    > img {
      display: block;
      margin-left: auto;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    > img {
      display: block;
      margin-left: auto;
      bottom: -15%;
    }
  }
`;

const BackgroundContainer = styled.div`
  background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  padding: 15px;
  // margin: 50px 0 50px 0;
  display: flex;
  // position: absolute;
  // z-index: -1;
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  > img {
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    margin: 0px 0 50px 0;
    > img {
      display: block;
      margin-left: auto;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
    margin: 0px;

    > img {
      display: block;
      margin-left: auto;
      bottom: -15%;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 0px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 0px;
  }
`;
const StaticImg = styled.img`
  position: absolute;
  // z-index: 100;
  left: -18px;
  // /* top: 22%; */
  width: 350px;
  height: 350px;
  top: -12%;
  cursor: default !important;
  @media (max-width: 500px) {
    display: none;
    // bottom:5%;
    top:350px;
    left: -45px;
  }
`;
const ContentWrapper = styled.div`
  margin: 0 20px;
  margin-left: 115px;
  max-width: 543px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 10px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #ffffff;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
    margin-left: 0px;
    padding: 5px;
    max-width: 743px;
  }
  //  @media (min-width: 1200px) {
  //   max-width:543;
  // }
  //  @media (min-width: 1300px) {
  //   max-width:653;
  // }
  // @media (min-width: 1600px) {
  //   max-width:1035px;
  //   width:650px;
  // }
  // @media (min-width: 1700px) {
  //   max-width:1035px;
  //   width:700px;
  // }
  @media (min-width: 1800px) {
    max-width: 1035px;
    width: 776px;
  }
  // @media (max-width: 1640px) {
  //     margin-left: 35px;
  //     max-width:321px;
  // }
`;

export default function DashboardNewIntern({ internshipVacacny, jobPosting, talentSearch, setUnAuth, coin, userUsedCoins , employeeQuota, sipToken}) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.company.currentOrgData);
  const companyData = useSelector((state) => state.company.currentlySelectedcompanyData);
  const companies = useSelector((state) => state.company.companyData)
  const companyStatus = companyData.status
  const tooltipContent = "Company approval is pending <br/>with mynext Admin"
  const { t, i18n } = useTranslation();
  const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
`;
  var parentLeftMargin = i18n.language === "en" ? "-186px" : "-222px";
  var parentMinWidth = i18n.language === "en" ? "265px" : "285px";
  const clickableSpan = document.getElementById('clickable-span');

  var customStyle =
    i18n.language === "en"
      ? { display: "flex", marginBottom: "19px", marginLeft: "111px" }
      : {
        display: "flex",
        marginBottom: "19px",
        paddingLeft: "206px",
        paddingRight: "42px",
      };

  const onNavigate = () => {
    if (userRole?.user_details.internship_modify !== "Y") {
      return setUnAuth(true);
    }
    navigate("/internships/new-intern");
  };
  const [randomID, setRandomID] = useState(String(Math.random()))
  const arr = [
    { title: "Internship Posting", label: "applications", datatip: 'applications', value: internshipVacacny },
    { title: "Job Posting", label: "accepted", datatip: 'accepted', value: jobPosting },
    { title: "MySIP", value: sipToken},
    { title: "Talent Search", datatip: 'pending verification', value: talentSearch},
    { title: "Employee Quota", value: employeeQuota}
    // { title: "Graduate Talent Finder", label: "pending", datatip: 'pending verification', value: talentSearch },
    // { title: "Offered", label: "offered", datatip: 'offered'},


  ];
  // clickableSpan.addEventListener('click', function() {
  //   // Replace the URL below with the desired destination URL
  //   // window.location.href = 'https://www.example.com';
  //   console.log("ddddd")
  // });
  useEffect(() => {
    clickableSpan?.addEventListener('click', function () {
      // Replace the URL below with the desired destination URL
      // window.location.href = 'https://www.example.com';
      console.log("ddddd")
    });
  }, [clickableSpan])
  return (
    <>
      <style type="text/css">
        {`
.containerFirst {
  width: 100%;
  height: 113px;
  padding: 10px 25px;
  // padding-top: 7px;
  // padding-bottom: 12px;
  // padding-left: 23px;
  // padding-right: 31px;
  // margin-left: 20px;
  background: white; 
  border-radius: 30px; 
  overflow: hidden; 
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  gap: 11px; 
  display: inline-flex;
}
.childContainerFirst {
  width: 55px; 
  height: 40px; 
  color: #202020; 
  font-size: 40px; 
  font-family: General Sans; 
  font-weight: 500; 
  word-wrap: normal;
  line-height: 1 rem; 
}
.childContainerSecond {
  width: 175px; 
  height: 43px; 
  text-align: center; 
  color: #D04C8D; 
  font-size: 18px; 
  font-family: General Sans; 
  font-weight: 500; 
  line-height: 21.34px; 
  word-wrap: break-word;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
  // height:100vh;  
}
.centerVertical {
  display: flex;
  // justify-content: center;
  // align-items: center;
  margin: auto;
  float: left;
  // height:100vh;  
}
.sectionTwoContainer {
  width: 461px; 
  height: 363px; 
  transform: rotate(180deg); 
  transform-origin: 0 0; 
  background: #EDEAEA; 
  border-radius: 20px;
}`}

      </style>
      <AddNewInternWrapper>
        {/* <Tooltip
        id="Applicationss"
        content="Total number of internship postings created</br>(All statuses)"
      /> */}
        <BackgroundContainer>
          <Row>
            <Col lg="2">
              <StaticImg src={Logo} alt="new company" />
            </Col>
            <Col lg="10">
              <Row>
              <Col lg="3" className="centerVertical d-flex flex-column">
                <div style={{
                  color: "#FFF",
                  fontSize: "30px",
                  fontStyle: "normal",
                  fontWeight: "500",
                  lineHeight: "normal",
                  marginBottom: "20px",
                  fontFamily: "General Sans",
                  wordWrap: "break-word"
                }}>
                  {t("Available Tokens")}
                </div>
                <span style={{ fontSize: "18px", color: Colors.lightWhite, fontWeight: "100", fontFamily: "General Sans" }}>
                  {t("Please note that Internship Posting and Job Posting are now payable features. As such, we are now offering plan packages to cater to your hiring needs.")}
                </span>
                <span onClick={() => { console.log("this tapped"); navigate("/subscriptions"); }} style={{ fontSize: "16px", fontWeight: "600", cursor: "pointer", fontFamily: "General Sans", color: Colors.labelText }}>
                  <br />{t("Click here to see details of subscription packages.")}
                </span>
              </Col>
                <Col lg="9">
                  <GridWrapper>
                    {
                      arr.map((item, index) => {
                        return <div className="containerFirst center">
                          <div className="childContainerFirst">{item.value}</div>
                          <div className="childContainerSecond" style={{paddingTop:"20px"}}>{t(item.title)}</div>
                        </div>
                      })
                    }
                  </GridWrapper>
                </Col>
                
              </Row>
            </Col>
          </Row>

        </BackgroundContainer>


        {/* <StaticImg id="logoImage" src={Logo} alt="new intern" /> */}
        {/* <ContentWrapper>
        <h3>{t("Add new Internship")}</h3>
        <p className="custom-width">
          {t(
            "Create a new internship posting for your organisation. Seek for suitable talents within mynext"
          )}
          <br />
          <b style={{ fontSize: "13px" }}>
            {t(
              "Please note that internship posting via mynext is now a payable feature. As such, we are now offering plan packages to cater to your hiring needs."
            )}
          </b>
          <br />
          <b style={{ fontSize: "13px" }}>
            {t("For more information, kindly email us at")}{" "}
            <a
              style={{ color: "white", textDecoration: "none" }}
              href="mailto:company@mynext.my"
            >
              company@mynext.my
            </a>
            .
          </b>
        </p>
      </ContentWrapper> */}
        {/* <div style={customStyle}>
        <div
          style={{
            minWidth: parentMinWidth,
            minHeight: "34px",
            background: "#FFFFFF",
            border: "3.01518px solid #EE4E9C",
            marginTop: "14px",
            borderRadius: "12px",
            marginLeft: parentLeftMargin,
          }}
        >
          <p
            style={{
              position: "absolute",
              fontFamily: "Oswald",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "21.9611px",
              lineHeight: "27px",
              color: "#57508E",
              marginLeft: "11px",
            }}
          >
            {t("Internship Tokens")}
          </p>
        </div>

        <div
          style={{
            minWidth: "70px",
            minHeight: "69px",
            background:
              "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
            borderRadius: "36px",
            position: "absolute",
            marginLeft: "-26px",
            marginTop: "-4px",
          }}
        >
          <div
            style={{
              minWidth: "64px",
              minHeight: "64px",
              background: "rgb(40, 255, 0)",
              borderRadius: "36px",
              position: "absolute",
              marginLeft: "3px",
              marginTop: "2px",
            }}
          >
            <ReactTooltip />
            <p
              style={{
                height: "38px",
                fontFamily: "Oswald",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "31.5841px",
                lineHeight: "61px",
                background:
                  "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "#57508E",
                textAlign: "center",
              }}
              data-tip="Available"
            >
              {coin ?? ""}
            </p>
          </div>
        </div>

        <div
          style={{
            minWidth: "70px",
            minHeight: "69px",
            background:
              "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
            borderRadius: "36px",
            position: "absolute",
            marginLeft: "49px",
            textAlign: "center",
            marginTop: "-4px",
          }}
        >
          <div
            style={{
              minWidth: "64px",
              minHeight: "64px",
              background: "#FFFF00",
              borderRadius: "36px",
              position: "absolute",
              marginLeft: "3px",
              marginTop: "2px",
              textAlign: "center",
            }}
          >
            <ReactTooltip />
            <p
              style={{
                height: "38px",
                fontFamily: "Oswald",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "31.5841px",
                lineHeight: "61px",
                background:
                  "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "#57508E",
                textAlign: "center",
              }}
              data-tip="Used"
            >
              {userUsedCoins ?? ""}
            </p>
          </div>
        </div>
        
      </div> */}
        {/* <img src={PlusIcon} onClick={onNavigate} alt="new intern add" /> */}
        {/* {undefined != companyStatus ?
    'accepted' != companyStatus  ?
      <div >
        <img aria-describedby="none" data-for={randomID} data-tip={tooltipContent} data-html={true} src={PlusIcon} alt="new company add" />

        <ReactTooltip id={randomID}  globalEventOff="true" />

        </div>
    
    : companies && companies[0]?.user_details.status != 'accepted' ?
    <div >
    <img aria-describedby="none" data-for={randomID} data-tip={"Company access is pending.<br/> Please accept company Invite"} data-html={true} src={PlusIcon} alt="new company add" />

    <ReactTooltip id={randomID}  globalEventOff="true" />

    </div>
    :      <img onClick={()=>{
      onNavigate()
    }} src={PlusIcon} alt="new company add" /> 
    : 
    <img onClick={()=>{

    }} src={PlusIcon} alt="new company add" />
    } */}
      </AddNewInternWrapper>
    </>

  );
}
