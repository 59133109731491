import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Users from './Users';
import { useSelector, } from "react-redux";

const FlexDiv = styled.div`
  display: flex;
`;

const CardsWrapper = styled(FlexDiv)`
  background: ${Colors.light};
  border-radius: 10px;
  margin-bottom: 0px;
 
  &:last-child() {
    margin-bottom: 0px;
  }
  @media (max-width: 500px) {
    padding: 10px;
    display: block;
  }
`;

function UserManagementCard  (datas, showToast,showToastDeleted)  {
  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector((state) => state.company.currentlySelectedcompanyData);
  let view = undefined;
  let modify = undefined;
  const [u_view, setView] = useState(false);
  const [u_modify, setModify] = useState(false);
  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          if(companies[i].user_details.user_management_modify==='Y' || companies[i].user_details.is_admin==='Y'){
            setModify(false)
            modify = false
          }else{
            setModify(true)
            modify = true
          }
          if(companies[i].user_details.user_management_view==='Y' || companies[i].user_details.is_admin==='Y'){
            setView(false)
            view = false
          }else{
            setView(true)
            view = true
          }
          return
        }
      }

    }
  }
  

  useEffect(() => {
    getOrgCode()
  })
    
  const{profilepic,first_name,last_name,job_title,department,email,user_type,id,user_id, status, is_admin}=datas.datas;

    

  return (
    <CardsWrapper >
    <Users
          profilepic={profilepic}
          name={first_name+' '+ last_name}
          designation={job_title}
          department = {department}
          email={email}
          user_type={user_type}
          view={view || u_view}
          modify={modify || u_modify}
          id={id}
          user_id={user_id}
          showToastDeleted={datas.showToastDeleted}
          deleteUser={datas.deleteUser}
          status={status}
          isAdmin={is_admin}
        />

    </CardsWrapper>

    
  )
}

export default UserManagementCard
