import styled from "styled-components";

const IntroSectionWrapper = styled.div`
  @media (max-width: 1024px) {
    width: 80%;
  }
`;
const IntroSectionHeader = styled.h2`
  font-weight: 500;
  font-size: 30px;
  line-height: 41px;
  margin-bottom: 0px;
  @media (max-width: 767px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const IntroSectionSubHeader = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #858585;
  /* font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #707070; */
  margin: 0px;
  // width: 50%;
  @media (max-width: 767px) {
    font-size: 12px;
    line-height: 15px;
    padding-right: 40px;
  }
`;
export default function TypeHeaderSection({ name, description }) {
  return (
    <IntroSectionWrapper>
      <IntroSectionHeader>{name}</IntroSectionHeader>
      <IntroSectionSubHeader>{description}</IntroSectionSubHeader>
    </IntroSectionWrapper>
  )
}
