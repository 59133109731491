import React, { useEffect, useState } from "react";
import QuickChart from "quickchart-js/build/quickchart.cjs";
import { Text, View, Image } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data,image}) => {
  const chartData = data;

  const creatingValue = chartData && chartData["Creating Value"];
  const fellowship = chartData && chartData["Fellowship"];
  const mastery = chartData && chartData["Mastery"];
  const qualityOfLife = chartData && chartData["Quality of Life"];

  const [workValuesChartUrl, setWorkValuesChartUrl] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      creatingValue &&
      creatingValue["points"] !== undefined &&
      fellowship &&
      fellowship["points"] !== undefined &&
      mastery &&
      mastery["points"] !== undefined &&
      qualityOfLife &&
      qualityOfLife["points"] !== undefined
    ) {
      (async function () {
        const myChart = new QuickChart();
        myChart.setHost("127.0.0.1:3400");
        myChart.setScheme("http");
        myChart
          .setConfig({
            type: "bar",
            data: {
              labels: [
                t("Creating Value"),
                t("Fellowship"),
                t("Mastery"),
                t("Quality of Life"),
              ],
              datasets: [
                {
                  data: [
                    creatingValue["points"],
                    fellowship["points"],
                    mastery["points"],
                    qualityOfLife["points"],
                  ],
                  backgroundColor: "#004B84",
                  borderColor: "rgba(0,0,0,0)",
                  barPercentage: 0.6,
                  categoryPercentage: 0.6,
                  maxBarThickness: 20,
                  barThickness: 20,
                  borderRadius: Number.MAX_VALUE,
                  borderSkipped: false,
                },
              ],
            },
            options: {
              scales: {
                xAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    display: true,
                    gridLines: {
                      display: true,
                      drawBorder: false,
                    },
                    ticks: {
                      display: true,
                      min: 0,
                      max: 60,
                    },
                  },
                ],
              },
              legend: {
                display: false,
              },
              plugins: {
                datalabels: {
                  display: false,
                  align: "center",
                  anchor: "end",
                  backgroundColor: "#FFFDFD",
                  borderColor: "#ddd",
                  borderRadius: 10,
                  borderWidth: 2,
                  padding: 4,
                  color: "#666666",
                  font: {
                    family: "sans-serif",
                    size: 10,
                    style: "normal",
                  },
                },
              },
            },
            plugins: [
              {
                afterDatasetDraw: (chart, options) => {
                  var ctx = chart.ctx;
                  const legendItems = chart.legend.legendItems;
                  chart.data.datasets.forEach((dataset, datasetIndex) => {
                    const backgroundColors = dataset.backgroundColor;
                    const legendItem = legendItems[datasetIndex];
                    var datasetMeta = chart.getDatasetMeta(datasetIndex);
                    datasetMeta.data.forEach((segment, segmentIndex) => {
                      if (legendItem.hidden) {
                        return;
                      }

                      var posX = segment.tooltipPosition().x,
                        posY = segment.tooltipPosition().y;
                      ctx.beginPath();
                      ctx.arc(posX, posY, 15, 0, 2 * Math.PI);
                      ctx.strokeStyle =
                        typeof backgroundColors === "string"
                          ? backgroundColors
                          : backgroundColors[segmentIndex];
                      ctx.fillStyle = "#FFFDFD";
                      ctx.lineWidth = 5;
                      ctx.shadowBlur = 7;
                      ctx.shadowOffsetX = 0;
                      ctx.shadowOffsetY = 0;
                      ctx.shadowColor =
                        typeof backgroundColors === "string"
                          ? backgroundColors
                          : backgroundColors[segmentIndex];
                      ctx.stroke();
                      ctx.fill();
                      ctx.lineWidth = null;
                      ctx.shadowBlur = null;
                      ctx.shadowOffsetX = null;
                      ctx.shadowOffsetY = null;
                      ctx.textAlign = "center";
                      ctx.font = "normal normal 400 14px/16px Oswald";
                      ctx.textBaseline = "middle";
                      ctx.fillStyle = "#000000";
                      ctx.fillText(`${dataset.data[segmentIndex]}`, posX, posY);
                    });
                  });
                },
              },
            ],
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setWorkValuesChartUrl(await myChart.getUrl());
      })();
    }
  }, [creatingValue, fellowship, mastery, qualityOfLife, t]);

  return (
    <View style={{}}>
      <View style={{}}>
        <Text
          style={{
            fontFamily: "General Sans",
            fontSize: 24,
            fontWeight: 500,
            textTransform: "uppercase",
            borderBottom: "2px solid black",
            paddingBottom: 5,
          }}
        >
          {"2."} {t("Work Values")}
        </Text>

        {creatingValue && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.1"} {t("Creating Value")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.1.1"} {t("Aesthetic")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is very important for talents who rated Aestetic as being of high importance to them, to be involved in work that creates beauty, designs beautiful products, or creates beautiful spaces or landscapes for people to enjoy."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "They usually would thrive in an environment that values aesthetics and where they are given the opportunity to use their artistic skills to create beauty through the work that they do."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.1.2"} {t("Creativity")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Talents who value Creativity work best when they are free to be creative. This may involve solving difficult problems, creating new products, looking at an issue from different angles or creating innovative solutions."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "They understand that the creative process needs space to thrive, and they value environments that enables them to work in this way rather than sticking to specific rules or processes."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.1.2"} {t("Leadership")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is very important for talents that value Leadership to have opportunities to take on a leadership role."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "This may be through leading others, taking on responsibility for specific projects or developing strategies."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.1.3"} {t("Protecting the Planet")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important for talents who value Protecting the Planet that they work for an organisation that takes its responsibility for the planet seriously."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Roles that enable them to live and work in a sustainable way or facilitate them to actively protect the planet will align with their values."
                )}
              </Text>
            </View>
          </View>
        )}

        {fellowship && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.2"} {t("Fellowship")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.2.1"} {t("Altruism")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important to talents with high Altruism values to contribute to the welfare of others."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "They value being in a position where their work leads to positive outcomes for others and enhances their lives."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.2.2"} {t("Supervisory Relationship")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is very important to talents who rated Supervisory Relationship as being of high importance to them, to have a good relationship with their line manager or supervisor."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Having a manager who is approachable, fair and who values their contribution means that they will be motivated to do their best at work."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.2.3"} {t("Associates")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is very important for talents who rated Associates as being of high importance to them, to work as part of a team, and they thrive when working with others towards a common goal."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "They like to have a positive relationship with their colleagues and have opportunities to interact with them on a regular basis."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.2.4"} {t("Belonging")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is very important for talents who rated Belonging as being of high importance to them, to have a sense of belonging to the organisation that they work for."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "This is enhanced in organisations who have a clear sense of purpose that they believe in and values their individual employees."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.2.5"} {t("Family")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important for talents who value Family to have a career that their family would approve of and be proud of and that would bring benefits to their family as well as to themselves."
                )}
              </Text>
            </View>
          </View>
        )}

        {mastery && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.3"} {t("Mastery")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.3.1"} {t("Intellectual stimulation")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It will be important for talents that value Intellectual Stimulation to look for roles that enable them to use their intellect to achieve results."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "They value work that provides them with the opportunity to think independently, learn new things and be involved in innovation, critical thinking and creative problem solving."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.3.2"} {t("Recognition")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Talents who rated Recognition as being of high importance to them value work where they have the opportunity to receive acknowledgement or respect for both the effort they have put in, and the results that they have achieved."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It will be important for them to work in an environment where they will be able to gain recognition or status for their contribution."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.3.3"} {t("Achievement")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important for talents who value Achievement that they are in a role where they can gain a sense of achievement through setting goals, working hard and achieving results. They value developing new skills or achieving targets."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "A work environment that recognises achievement in both the short term and the long term will provide them with a sense of satisfaction and progress."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.3.4"} {t("Independence")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Talents who have rated Independence as being high importance to them value being able to make decisions on issues such as how they approach a task, how they prioritise their workload or diary and where and when they choose to carry out their work."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It will be important for them to work in a role with a high level of independence and autonomy."
                )}
              </Text>
            </View>
          </View>
        )}

        {qualityOfLife && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.4"} {t("Quality of Life")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.4.1"} {t("Variety")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Variety Talents who have rated Variety as being of high importance to them may come from having a role that involves a range of diverse tasks, settings, client groups or from learning new things."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.4.2"} {t("Security")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Talents who rated Security as being of high importance to them value knowing that they have a job with a high level of security, despite fluctuations in the job market or economy. This includes clarity about the work necessary to enhance their job security."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important to them to have a role that provides you with security through a permanent contract."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.4.3"} {t("Way of Life")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "Talents who rated Way of Life as being of high importance to them value being able to balance their working life with their personal life through a job that provides them with the means and the flexibility to pursue a range of interests in their own time."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important to them to have a job that allows them to lead the type of lifestyle that they choose."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.4.4"} {t("Surroundings")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is very important to talents who rated Surroundings as being of high importance to them that they work in an environment which is pleasant and suited to their preferences."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "For example, they may prefer to work outside, in a quiet environment where they can concentrate without distraction or value having the flexibility to choose where and when they complete their work. They also value having equipment that is fit for purpose."
                )}
              </Text>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"2.4.5"} {t("Economic Return")}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "It is important to talents who value Economic Return that their job pays well and provides them with the opportunity to have a good standard of living."
                )}
              </Text>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  textAlign: "justify",
                  color: "#213343",
                  lineHeight: 1.5,
                  textIndent: 18,
                }}
              >
                {t(
                  "This may include working in an organisation that provides a clear path of progression so that they have opportunities to enhance their salary as they gain skills, experience and qualifications."
                )}
              </Text>
            </View>
          </View>
        )}

        {image !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={image}
              style={{ marginVertical: 30, marginHorizontal: 60, width: 250, height: 250 }}
            />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Work Values")}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
