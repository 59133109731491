import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import PhoneInput from "react-phone-number-input";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { newInternStaticObj, schema } from "./staticContent";
import Input from "../../components/input/input";
import TextArea from "../../components/description/description";
import RadioInput from "../../components/radioInput/radioInput";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import SkillCard from "../../components/skillCard/skillCard";
import SIPSkillCard from "../../components/skillCard/sipSkillCard";
import Button from "../../components/button/button";
import ErrorField from "../../components/errorField/errorField";
import Loader from "../../components/loader/loader";
import Dropdown from "../../components/dropdown/dropdown";
import PaginationDivider from "./paginationDividerSip";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import ReactTooltip from "react-tooltip";
import Detail from "../../assets/Group 1346.png";
import {
  createNewIntern,
  getInternScrollList,
  uploadObject, // getCountries,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
  getScopeOfStudyMasterList,
  getSkillMasterList,
  ongettingInternInfo,
  onUpdatingInternInfo,
  getOrganizationInternshipCoinDetail,
  fetchTechnicalSkills,
  fetchSoftSkills
} from "../../services/apiCalls";
import LocationTable from "../../components/locationTable/locationTable";
import { formatDate } from "../../utilities/commonFunctions";
import { updateValues } from "./reducer";
import Tooltip from "../../components/reactTooltip/tooltip";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { internFields as staticInternFields } from "./staticContent";
import RichTextEditorComponent from "../../utilities/rich-text-editor";
import { Link } from "react-router-dom";
import moment from "moment";
import PaymentPopup from "../../components/paymentPopup/paymentPopup";
import { useTranslation } from "react-i18next";
import AlertModal from "../../components/newInternModal/alertModal";
import NewInternDropDown from "../../components/newInterndropdown/newInterndropdown";
import "./addNewInternship.css";
import PaginationLOEDivider from "./loeDataMigration/paginationDividerMigration";
import PaginationDividerTwo from "./loeDataMigration/paginationDividerTwo";
import { differenceInMonths } from 'date-fns';
import { differenceInWeeks, differenceInDays } from 'date-fns';

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});
// styling starts
const NewInternPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const SectionWrapper = styled.section``;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;

const HeaderText2 = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  color: #858585;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FileWrapper = styled(FlexWrapper)`
  margin: 10px 0;
  flex-wrap: wrap;
`;
const GridWrapper = styled.div`
  display: grid;
  margin-bottom: 10px;
`;
// const LogoWrapper = styled(FlexWrapper)`
//   margin: 20px 0;
//   > img {
//     margin-right: 20px;
//   }
//   @media (max-width: 650px) {
//     flex-direction: column;
//   }
// `;
// const CompanyHeader = styled.h4`
//   font-style: normal;
//   font-weight: 500;
//   font-size: 20px;
//   line-height: 27px;
//   margin: 0;
// `;
const FormWrapper = styled.div`
  margin-top: 20px;
`;
const FormGroup = styled.div`
  min-width: 240px;
  width: 100%;
  label {
    width: unset !important;
  }
`;
const SkillInputDiv = styled.div`
margin-bottom: 15px;
`;


const SingleField = styled.div`
  margin-bottom: 30px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const InternTypeWrapper = styled(FlexWrapper)``;
// const InternDateWrapper = styled(GridWrapper)`
//   grid-template-columns: 1fr 1fr 0.5fr;
//   grid-column-gap: 20px;
//   @media (max-width: 900px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
//   label {
//     position: relative;
//     width: -webkit-fill-available;
//   }
// `;
const UploadText = styled.h3`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  color: ${Colors.labelText};
`;
const UploadNotes = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
  color: #a5a0a0;
`;
const UploadFileText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
`;
const UploadFileWrapper = styled.label`
  position: relative;
  cursor: pointer;
  > input {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
`;
const DividerLine = styled.hr`
  border: 0.5px solid #acacac;
  margin: 30px 0;
`;
const FormHeaderText = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 25px;
  color: ${Colors.dark};
`;
const InfoHeaderText = styled.h3`
  margin: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 25px;
  color: ${Colors.dark};
`;
const AcademicGridWrapper = styled(GridWrapper)`
  grid-template-columns: ${(props) =>
    props.template ? props.template : "3fr 1fr"};
  grid-column-gap: 20px;
  @media (max-width: 650px) {
    grid-template-columns: repeat(1, 1fr);
  }
  label {
    position: relative;
    //width: -webkit-fill-available;
  }
`;

const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const SaveExitBtn = styled(LabelText)`
  color: #504f8c;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 800px) {
    width:100%;
    text-align:center;
  }
`;

const SaveButton = styled.p`
// font-weight: 600;
  // font-size: 14px;
  // line-height: 19px;
  cursor: pointer;
  // color: #d04c8d;
  background: linear-gradient(90deg, #504f8c 0%, #fa4aa1 100%);
  border-radius: 128px;
  outline: none;
  border: 0px;
  // font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 13px 28px;
  padding-top: 13px;
  padding-right: 28px;
  padding-bottom: 13px;
  padding-left: 40px;
  // margin: 15px 0;
  width: 120px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const AddButton = styled.p`
  // font-weight: 600;
  // font-size: 14px;
  // line-height: 19px;
  cursor: pointer;
  // color: #d04c8d;
  background: linear-gradient(90deg, #504f8c 0%, #fa4aa1 100%);
  border-radius: 128px;
  outline: none;
  border: 0px;
  // font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 13px 28px;
  padding-top: 13px;
  padding-right: 28px;
  padding-bottom: 13px;
  padding-left: 28px;
  // margin: 15px 0;
  width: 192px;
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const ErrorLabel = styled.label`
  color: red;
  font-size: 12px;
  margin: 0;
  display: block;
  font-weight: 500;
`;

//

// const ManualWrapper = styled.div``;
const ModalHeaderText = styled.div`
  position: absolute;
  left: 51px;
  top: 44px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
`;

const ModalHeaderTextTwo = styled.div`
  position: absolute;
  left: 51px;
  top: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 33px;
  color: #000000;
`;

const ModalHeaderTextThree = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 23px;
  line-height: 31px;
  text-align: center;

  color: #202020;
  margin-top: 29px;
`;
const ModalHeaderTextFour = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 31px;
  text-align: center;

  color: #202020;
  margin-top: 3px;
`;

const ModalHeader = styled.div`
  height: 136px;
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
`;

const SubHeading = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
  margin-top: 30px;
`;

const Description3 = styled.p`
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  color: #000;
  margin-top: 30px;
`;

const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 100%;
    margin-top: 10px
}
`;

const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;

// const HeaderWrapper = styled.div``;

// const NewHeaderText = styled.h2`
//   font-weight: 500;
//   font-size: 28px;
//   line-height: 38px;
//   color: ${Colors.dark};
//   margin-top: 0;
// `;

// const HeaderDescription = styled.p`
// height: 21px;
// font-family: 'Adamina';
// font-style: normal;
// font-weight: 400;
// font-size: 14px;
// line-height: 17px;
// color: #878787;
// `;

// const Label = styled.label`
//   font-weight: 500;
//   font-size: 16px;
//   line-height: 22px;
//   color: ${(props) => (props.color ? props.color : Colors.labelText)};
//   text-transform: ${(props) => (props.color ? "unset" : "capitalize")};
// `;

// const DownloadTemplate = styled(Label)`
//   color: ${Colors.inputHighlightText};
//   cursor: pointer;
//   font-family: 'Adamina';
//   font-style: normal;
//   font-weight: 400;
//   font-size: 14px;
//   line-height: 19px;
//   text-decoration-line: underline;
//   margin-top:8px;
//   margin-right:8px;
// `;

// const Upload = styled(Label)`
//   color: ${Colors.inputHighlightText};
//   cursor: pointer;
//   font-family: 'General Sans';
//   font-style: normal;
//   font-weight: 600;
//   font-size: 14px;
//   line-height: 22px;
//   border: 1px solid #D04C8D;
//   border-radius: 128px;
//   padding:7px;
// `;

// const UploadComponent = styled.div`
//   display:flex;
//   justify-content: end;
// `;

const ComponentFile = styled.div`
  display: flex;
`;


const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

// styling ends

export default function AddNewInternship() {
  const { t } = useTranslation();
  const [coins, setCoins] = useState(0);
  const location = useLocation();
  const typeOrigin = location.state?.typeOrigin;
  const internshipModuleId = location.state?.id;
  const fromDataMigration = location.state?.fromDataMigration;
  const fromLandingPage = location.state?.fromLandingPage;

  useEffect(()=>{
    localStorage.setItem("typeOriginBulk",typeOrigin);
    localStorage.setItem("internshipModuleIdBulk",internshipModuleId);
  })
  console.log(typeOrigin,"typeOriginState");

  //handle radio buttons
  const [studentDiffer, setStudentDiffer] = useState('Yes');
  const [employmentOpp, setEmploymentOpp] = useState('Yes');
  const [fWa, setFwa] = useState('Yes');

  var data1 = {
    "empty-package": {
      subDesc: "Your internship wallet is empty",
      desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
      submit: "Submit",
    },
    "empty-coin": { 
      subDesc: "Looks like your wallet is empty.",
      desc: "To proceed with internship posting via mynext, kindly renew your internship plan subscription. Should you choose to not renew, you will be charged on a pay-per-intern basis for your internship posting.",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Thank you for purchasing our internship plan package.",
      desc: `${t("You are about to redeem ")}1${
        t(" out of your total ") +
        coins +
        t(" coins for this internship posting")
      }.`,
      submit: "Proceed",
    },
  };
  const selectedRef = useRef(null);

  const internFields = useSelector((state) => state.intern.value);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const companyData = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const eligibleData = localStorage.getItem("eligibilityData");
  const AdvertiseEligible = JSON.parse(eligibleData);
  console.log(AdvertiseEligible, "FROM DH")
  // const monthly_allowance_1 = AdvertiseEligible.monthly_allowance_1
  // const monthly_allowance_2 = AdvertiseEligible.monthly_allowance_2
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    // status,
  } = useForm({
    defaultValues: { ...internFields },
    resolver: yupResolver(schema),
    context: {
      // monthly_allowance_1,
      // monthly_allowance_2,
    },
    mode: "onBlur",
  });
  const formVal = watch();
  console.log("DOC>>>"+JSON.stringify(formVal.documents));

  const softSkillOption = [
    {
      value: "evaluating",
      label: "Evaluating & Resolving",
      description: "Demonstrates evidence of analytical clarity. Competent at reaching the core of difficult challenges. Effectively uses own expertise. Adopts new technology quickly. Writes clearly and effectively."
    },
    {
      value: "innovating",
      label: "Innovating & Envisioning",
      description: "Works well in circumstances where being receptive to diverse perspectives is necessary. Pursues self improvement by seeking new knowledge. Uses imagination and ingenuity to overcome difficulties and issues. Regards situations comprehensively and systematically. Encourages positive organizational transformation."
    },
    {
      value: "connecting",
      label: "Connecting & Portraying",
      description: "Communicates and networks effectively. Successfully persuades and influences others. Relates to others in a confident, relaxed manner."
    },
    {
      value: "supervising",
      label: "Supervising & Determining",
      description: "Takes charge and assumes authority. Takes the initiative, provides guidance, and accepts accountability."
    },
    {
      value: "facilitating",
      label: "Facilitating & Collaborating",
      description: "Supports others and demonstrates respect and favorable regard for them. Focuses on the needs of others and works well with various parties. Possesses reliable characteristics that are compatible with those of the organization."
    },
    {
      value: "embracing",
      label: "Embracing & Managing",
      description: "Adjusts well to uncertainties. Skillfully handles pressure and difficulties."
    },
    {
      value: "coordinating",
      label: "Coordinating & Implmenting",
      description: "Prepares for work in advance and organizes work systematically. Executes work by adhering to instructions. Emphasizes the fulfillment of client needs and provides service or product according to established guidelines."
    },
    {
      value: "ambitious",
      label: "Ambitious & Result-Oriented",
      description: "Committed to setting personal goals and highly motivated to achieving results. Stimulated by accomplishments and the recognition of effort. Demonstrates knowledge of commerce, business and economic affairs. Solicits possibilities for professional and personal growth."
    }
  ]

  const handleSoftSkillOption = (soft_skill, type) =>{
    const arr = [...formVal[type]];
  const isChecked = arr.some((option) => option.soft_skill === soft_skill);

  let updatedArr;

  if (isChecked) {
    // If the option is already checked, remove it from the array
    updatedArr = arr.filter((option) => option.soft_skill !== soft_skill);
    setValue(type, updatedArr);
  } else {
    if(arr.length >= 3){
      toast.error(t("You have exceeded the max limit"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else{
      // If the option is not checked, add it to the array
    const newOption = { soft_skill: soft_skill };
    updatedArr = [...arr, newOption];
    setValue(type, updatedArr);
    }
  }

  
  }
  var data1 = {
    "empty-package": {
      subDesc: "Your internship wallet is empty",
      desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
      submit: "Submit",
    },
    "empty-coin": {
      subDesc: "Looks like your wallet is empty.",
      desc: "To proceed with internship posting via mynext, kindly renew your internship plan subscription. Should you choose to not renew, you will be charged on a pay-per-intern basis for your internship posting.",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Thank you for purchasing our internship plan package.",
      desc: `${t("You are about to redeem ")}${formVal.no_positions}${
        t(" out of your total ") +
        coins +
        t(" coins for this internship posting")
      }.`,
      submit: "Proceed",
    },
    "position-coins": {
      subDesc: `${t(
        "Unfortunately, you are unable to complete this transaction due to insufficient token balance."
      )}`,
      desc: `1. ${t("Reduce application to")} ${coins} ${t("positions")}`,
      desc2: `2. ${t("Create new internship with ")} ${
        parseInt(formVal.no_positions) - coins
      } ${t("position(s) using ala carte payment mode")}.`,
      submit: `${t("ok")}`,
      "tokens-available": `${t("Available token(s)")}: `,
      "required-tokens": `${t("Required token(s)")}: `,
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = () => {
    return (
      <>
        <ModalHeader></ModalHeader>
        <ModalHeaderText>{t("Internship Creation")}</ModalHeaderText>
        <ModalHeaderTextTwo>
          {modalMode === "position-coins"
            ? t("Insufficient token balance")
            : t("Internship Plan")}
        </ModalHeaderTextTwo>

        <ModalHeaderTextThree>
          {t(data1[modalMode]["subDesc"])}
        </ModalHeaderTextThree>
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour>
            {data1[modalMode]["tokens-available"]}
            <b>{coins}</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour>
            {data1[modalMode]["required-tokens"]}
            <b>{parseInt(formVal.no_positions) - coins}</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}

        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            <b>{t("To proceed, you may try the following")}:</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            {t(data1[modalMode]["desc"])}
          </ModalHeaderTextFour>
        ) : (
          <ModalHeaderTextFour>
            {t(data1[modalMode]["desc"])}
          </ModalHeaderTextFour>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            {t(data1[modalMode]["desc2"])}
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}

        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            <b>
              {t(
                "Should you require further assistance, please reach out to us at company@mynext.my"
              )}
            </b>
          </ModalHeaderTextFour>
        ) : (
          ""
        )}

        {modalMode === "empty-coin" ? (
          <ModalHeaderTextFour>
            <b>
              {t(
                "Note: Kindly email us at company@mynext.my for subscription renewal."
              )}
            </b>
          </ModalHeaderTextFour>
        ) : (
          ""
        )}

        <ComponentFile></ComponentFile>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            {modalMode !== "position-coins" ? (
              <>
                <Button
                  style={{ width: "213px" }}
                  margin="40px 0px 30px 0px"
                  name={t(data1[modalMode]["submit"])}
                  // onClick={onSipModalSubmit}
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </>
            ) : (
              <Button
                style={{ width: "213px" }}
                margin="40px 0px 30px 0px"
                name={t(data1[modalMode]["submit"])}
                onClick={onClose}
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  const [loading, setLoading] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [userArr, setUsersArr] = useState([]);
  const [newSkill, setSkill] = useState(null);

  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const [scope, setScope] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);

  const [enableDate, setEnableDate] = useState(false);
  const [duplicateLoc, setDuplicateLocErr] = useState("");
  const [hideLocation, setHideLocation] = useState(false);
  const collectionValueList = [
    { label: t("Manually download applications") },
    { label: t("Receive applications by E-mail") },
    { label: t("Others") },
  ];
  const [collectionValues, setCollectionValues] = useState(collectionValueList);
  const [resetorg, setResetOrg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMode, setModalMode] = useState("empty-coin");
  const [theArray, setTheArray] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [page, setPage] = useState(0);
  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;
  const [showCommentTable, setShowCommentTable] = useState(false);
console.log(states,"RETRIEVED STATE")
  // const formVal = watch();
  const org = useSelector((state) => state.org.orgData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const isFromInternshipPage = new URLSearchParams(search).get(
    "fromDirectHiringInternshipPage"
  );
  // const formVal = watch();
  if (resetorg === 0) {
    reset({
      description: "",
    });
    setResetOrg(1);
  }

  const [isOpen, setOpen] = useState(false);
  const bufferDate = 5;

  function onClose() {
    setModal(false);
    setLoading(false);
  }

  // function onSipModalSubmit() {
  //   setModal(false);
  //   onNewIntern(true);
  // }

  const [addPopup, setAddPopup] = useState(false);
  useEffect(() => {
    if (id && org_code) {
      getInternData();
     
    }
    if (isFromInternshipPage) {
      setUpdate(true);
      setShowCommentTable(true);
    }
    if (formVal && !formVal["payment_status"]) {
      setValue("payment_status", "paid");
    }
    //  getOptions("all/loc/country", "country");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, org]);

  useEffect(() => {
    const sip = "National Structured Internship Programme (MySIP)";
    if (formVal.intern_type === "sip" || formVal.intern_type === sip) {
      collectionValues.splice(
        collectionValues.findIndex((f) => f.label === sip),
        1
      );
      setValue("collection_type", null);
      setValue("re_email_address", "");
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      setCollectionValues(collectionValues);
      setValue("payment_status", "paid");
    } else {
      setCollectionValues([...collectionValueList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.intern_type]);

  useEffect(() => {
    if (formVal.contact_person === "" || formVal.contact_person === null) {
      // alert('hii')
      formVal.contact_person = formVal.contact_name;
      setValue("contact_person", formVal.contact_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.contact_person]);



  const getInternData = async () => {
    setPageLoading(true);
    const { data } = await ongettingInternInfo({ id: id, code: org_code });
    console.log(data?.result,"InternID")
    console.log(id);
    // alert(data.data.id)
    if (data && data.status === 1) {
      //document updation
      if (data.data.documents && data.data.documents.length >= 4) {
        data.data.documents.push({ doc_type: "All" });
      }
      dispatch(
        updateValues({
          ...data.data,
        })
      );
      setUpdate(true);
      let arr = Object.keys(data.data);
      for (let i = 0; i <= arr.length; i++) {
        let key = arr[i];
        setValue(key, data.data[key] ? data.data[key] : "");
      }
      const scopeArr = data.data.scopes.map((x) => {
        return { ...x, label: x.scope_abbr, value: x.scope };
      });
      setValue("scopes", scopeArr);
      setSelectedScope(scopeArr);

      const softArr = data.data.soft_skills.map((x) => {
        return{ soft_skill: x.soft_skill}
      })

      setValue("soft_skills", softArr)
      setValue("update_time", data.data.update_time);

      const postStart = data.data.position_start_date;
      const postEnd = data.data.position_end_date;
      
      setValue("position_start_date", postStart)
      setValue("position_end_date", postEnd)
      
      const collectType = data.data.collection_type;
      console.log(collectType, "collect fro edit")
    
      setValue("collection_type", collectType)
      setTheArray(data?.data?.review_comments);
    
      setPageLoading(false);
    } else {
      setUpdate(false);
      setPageLoading(false);
    }
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paidOptions = [
    { value: "paid", label: t("Paid") },
    { value: "unpaid", label: t("Unpaid") },
  ];

  useEffect(() => {
    getDropDownData();
    getcountryList();
    getScopeOfStudy();
    reset({ ...staticInternFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDropDownData = async () => {
    const { data } = await getInternScrollList();
    if (data && data.status === 0) {
      onDataConstruct(data.data);
    }
  };

  const dropdownChange = (name, val) => {
    switch (name) {
      case "country":
        setValue(name, val.label);
        dispatch(
          updateValues({
            ...formVal,
            ...internFields,
            [name]: val.label,
            state: [],
            city: [],
          })
        );
        setSelectedCountry(val);
        getState(val.value);
        break;
      case "state":
        setValue(name, val.label);
        dispatch(
          // ,
          updateValues({
            ...formVal,
            ...internFields,
            [name]: val.label,
          })
          //
        );
        setSelectedState(val);
        getCity(selectedCountry.value, val.value);
        break;
      case "city":
        setValue(name, val.label);
        dispatch(
          updateValues({
            ...formVal,
            ...internFields,
            [name]: val.label,
          })
        );
        setSelectedCity(val);
        break;
      default:
        setValue(name, val.value);
    }
  };

  const getcountryList = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Countrylist = data.data;
        const country = Countrylist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCountry(country);
        if (formVal.country) {
          let selCountry = country.filter((x) => x.value === formVal.country);
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          setSelectedCountry(selCountry);
          getState(selCountry.value);
        }
      }
    }
  };

  const getState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const Stateslist = data.data;
        const state = Stateslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setStates(state);
        if (formVal.state) {
          let selStates = state.filter((x) => x.value === formVal.state);
          if (selStates.length > 0) {
            selStates = selStates[0];
            setValue("state", selStates.value);
          }
          setSelectedState(selStates);
          // fetchCampus(selStates.value);
        }
      }
    }
  };
  const getCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCities(cities);
        if (formVal.city) {
          let selcities = cities.filter((x) => x.value === formVal.city);
          if (selcities.length > 0) {
            selcities = selcities[0];
            setValue("city", selcities.value);
          }
          setSelectedCity(selcities);
          // fetchCampus(selStates.value);
        }
      }
    }
  };

  const getScopeOfStudy = async (CountryId, StateId) => {
    const { data, status } = await getScopeOfStudyMasterList(
      CountryId,
      StateId
    );
    if (status === 200) {
      if (data.status === 1) {
        const Scopelist = data.data;
        const scope = Scopelist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setScope(scope);
        if (formVal.scopes) {
          let selcities = scope.filter((x) => x.value === formVal.scopes);
          setSelectedScope(selcities);
          // if (selcities.length > 0) {
          //   selcities = selcities[0];
          //   setValue("scopes", selcities.value);
          // }
          // setSelectedCity(selcities);
        }
      }
    }
  };

  const onDataConstruct = (data) => {
    // const skillsArr = data.skills.map((x) => {
    //   return { label: x, skill: x, value: x };
    // });
    let contactPersonArr = data.users.map((x) => {
      if (x.status === "accepted")
        return { ...x, label: x.first_name + " " + x.last_name, value: x.id };
      else {
        return null;
      }
    });
    contactPersonArr = contactPersonArr.filter(function (e) {
      return e;
    });
    // setSkillsArr(skillsArr);
    // setValue("skills", skillsArr);
    setUsersArr(contactPersonArr);
    if (formVal.contact_person === "" || formVal.contact_person === null) {
      // alert('hii')
      formVal.contact_person = formVal.contact_name;
      setValue("contact_person", formVal.contact_name);
    }
  };

  const fetchAllSkills = async (searchKey) => {
    const { data, status } = await getSkillMasterList(searchKey);
    if (status === 200 && data.status === 1) {
      const options = data.data.map((x) => {
        return { ...x, label: x.Example, value: x.Id };
      });
      setOptions(options);
      setIsLoading(false);
    }
  };

  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [softSkillsList, setSoftSkillsList] = useState([]);
  const [selectedTechSkills, setSelectedTechSkills] = useState([]);
  const [selectedSoftSkills, setSelectedSoftSkills] = useState([]);
  const [extraDetails, setExtraDetails] = useState('');
 
console.log(formVal.soft_skills, "SELECTED SOFT")
const getAllTechnicalSkills = async () =>{
  const {data} = await fetchTechnicalSkills();
  if(data){
    const options = data.map((x) => {
      return {...x, label: x.skill_name, value: x.id, data: x };
    });
    setTechnicalSkills(options);
  }
}
const getAllSoftSkills = async () =>{
  const {data} = await fetchSoftSkills();
  if(data){
    const options = data.map((x) => {
      return {...x, label: x.skill_name, value: x.id, data: x };
    });
    setSoftSkillsList(options);
  }
}
  console.log(technicalSkills, "RETRIEVED SKILLS")
  console.log(formVal.skills, "SELECTED SKILLS")
useEffect(()=>{
fetchAllSkills();
getAllTechnicalSkills();
getAllSoftSkills();
},[])

console.log(softSkillsList, "RETRIEVED SOFT SKILLS")
  const typeaheadRef = useRef(null);

  const filterBy = () => true;
  const handleSearch = (query) => {
    setIsLoading(true);
    fetchAllSkills(query);
  };
  const dropdownSkillChange = (name, val) => {
    // setSelectedSkill(val[0])
    // setSkill(val[0]?.label);
    // setSoftskills(val[0]?.label);

    switch(name){
      case "tech_skills":
        setSkill(val?.label);
        setSelectedTechSkills(val);
        
        break;
      case "soft_skills":
        setSoftskills(val?.label);
        setSelectedSoftSkills(val);
        break;
    }
  };
console.log(newSkill, "SKILLS")
  const [softSkills,setSoftskills] = useState();
  const addSoftSkills = ()=>{
    const arr = [...formVal.soft_skills];
    
    
    if (arr.length >= 3) {
      toast.error(t("The skills must be exactly 3."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const isNewSkill =
        arr.length > 0 ? arr.some((soft_skills) => soft_skills.label !== softSkills) : true;
      var added_skills = [];

      arr.map((skl) => added_skills.push(skl.label));

      if (added_skills.includes(softSkills)) {
        toast.error(t("Skill already added"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (isNewSkill) {
          if (softSkills === "" || softSkills === null) {
            toast.error(t("Please add at least one skill!"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            arr.push({ soft_skill: softSkills });
            setValue("soft_skills", arr);
            setSoftskills("");
            errors.soft_skill = "";
          }
        }
      }
    }
  }
 
  const addSkill = () => {
   
    const arr = [...formVal.skills];
    console.log(arr,"skills value");
    if (arr.length >= 3) {
      toast.error(t("The skills must be exactly 3."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const isNewSkill =
        arr.length > 0 ? arr.some((skill) => skill.label !== newSkill) : true;
      var added_skills = [];

      arr.map((skl) => added_skills.push(skl.label));

      if (added_skills.includes(newSkill)) {
        toast.error(t("Skill already added"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (isNewSkill) {
          if (newSkill === "" || newSkill === null) {
            toast.error(t("Please add at least one skill!"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            arr.push({ skill: newSkill , specify_text: extraDetails});
            setValue("skills", arr);
            setSkill("");
            setExtraDetails("");
            setSelectedTechSkills([]);
            errors.skills = "";
          }
        }
      }
    }
  };

  const deleteSkill = (index) => {
    const arr = [...formVal.skills];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("skills", newArr);
    setSkill(null);
  };

  const onCancle = async () => {
    navigate("/internships/upload-intern-details");

  }

  const onSubmit = async () => {
    // setTimeout(() => {
    //   if (selectedRef.current) {
    //     selectedRef.current.blur();
    //   }
    // }, 0);
    //onSubmitErrorHandler();
   // showPaymentPopup();
   // setAddPopup(true);
    
     handleSubmit(onSubmitErrorHandler);
     
  };
  const dataToSend = {
    internship_module_id: formVal.internship_module_id,
title: formVal.title,
description: formVal.description,
intern_type: formVal.intern_type,
exp_start_date: formVal.exp_start_date,
exp_end_date: formVal.exp_end_date,
position_start_date: null,
position_end_date: null,
no_positions: formVal.no_positions,
req_gpa: formVal.req_gpa,
min_degree: formVal.min_degree,
working_hours: formVal.working_hours,
max_compensation: formVal.max_compensation,
min_compensation: formVal.min_compensation,
country: formVal.country,
state: formVal.state,
city: formVal.city,
grade: formVal.grade,
file_paths: formVal.file_paths,
skills: formVal.skills,
soft_skills:formVal.soft_skills,
scopes: formVal.scopes,
documents: formVal.documents,
payment_status: "paid",
locations: formVal.locations,
contact_name: formVal.contact_name,
contact_email: formVal.contact_email,
contact_person: formVal.contact_person,
direct_hiring_type: formVal.direct_hiring_type,
contact_mob_code: formVal.contact_mob_code,
contact_mob: formVal.contact_mob,
visibility_status: formVal.visibility_status,
collection_type: formVal.collection_type,
collection_radio_type: formVal.collection_radio_type,
collection_date: formVal.collection_date,
re_email_address: formVal.re_email_address,
optional_documents: formVal.optional_documents,
application_instruction: formVal.application_instruction,
external_application_link: formVal.external_application_link,
  }
const sendData = () =>{
  localStorage.setItem("DH_Data" , JSON.stringify(dataToSend))
  if(fromDataMigration){
    navigate("/loe-application/select-interns",{state: {fromLandingPage: fromLandingPage}});

  }else{
    navigate("/internships/upload-intern-details");

  }
}
  const onSubmitErrorHandler = async () => {
    if (!formVal.title || formVal.title.trim().length === 0) {
      toast.error(t("Fill out all  the mandatory fields"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }else{
     // Manually trigger form validation
    // const isValid = await handleSubmit(onInternshipSubmit);
    // // handleSubmit(onSubmit) returns a function, and we call it immediately

    // if (isValid) {
    // // If the form is valid, call onSubmit
    onInternshipSubmit();
    // }
    }
  };
  

    const onUpdateIntern = async () => {
    // work around for visibility status - string bug
    if (typeof formVal?.visibility_status == "string")
      formVal.visibility_status = 0;
    const { data, res } = await onUpdatingInternInfo({
      code: org_code,
      id: id,
      data: {
        ...formVal,
        collection_radio_type: formVal.collection_radio_type
          ? formVal.collection_radio_type
          : null,
        collection_date: formVal.collection_date
          ? formVal.collection_date
          : null,
        is_activate: true,
        status: "pending",
      },
    });
    if (data && data.status === 1) {
      toast.success(t("Internship has been updated."), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      navigate("/internships");
    } else {
      // if (
      //   data.message ===
      //   t("Someone has already edited the records, so please update the screen")
      // ) 
      {
        toast.warning(
          t(
            "Internship already updated by another user. Please refresh the page to load the latest details."
          ),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      setLoading(false);
    }
    if (res) {
      if (res.non_field_errors) {
        const msg = res.non_field_errors.join(" ");
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  // const onNewIntern = async (checkTrigger) => {
  //   let ti = 0;
  //   if (Object.keys(company).length > 0) {
  //     const result3 = await getOrganizationInternshipCoinDetail(company?.id);
  //     var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
  //     var today = new Date();
  //     if (varDate >= today) {
  //       setModalMode("empty-coin");
  //       ti = result3?.data?.data?.tokens;
  //       setCoins(ti);
  //     } else {
  //       setModalMode("empty-package");
  //     }
  //   }
  //   if (checkTrigger) {
  //     const { data, res } = await createNewIntern({
  //       ...formVal,
  //       documents: formVal.documents.filter((x) => x.doc_type !== "All"),
  //       collection_radio_type: formVal.collection_radio_type
  //         ? formVal.collection_radio_type
  //         : null,
  //       collection_date: formVal.collection_date
  //         ? formVal.collection_date
  //         : null,
  //       org_code: org_code,
  //       is_activate: true,
  //     });
  //     if (data && data.status === 1) {
  //       toast.success(t("Internship form submitted"), {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //       setLoading(false);
  //       navigate("/internships");
  //     } else if (data && data.sip_status === true) {
  //       var mainUrl =
  //         data.framework_enrollment_url[process.env.REACT_APP_ENV_TYPE] +
  //         "&internship_id=" +
  //         data.post_data.id +
  //         "&token=" +
  //         localStorage.getItem("auth_token") +
  //         "&org_code=" +
  //         org_code;

  //       toast.success(t("Internship form submitted"), {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });

  //       const mynextCentralWindow = window.open(
  //         mainUrl,
  //         "_self",
  //         "credentials=include"
  //       );
  //       setTimeout(() => {
  //         mynextCentralWindow.postMessage(data.post_data, "*");
  //       }, 2000);
  //       setLoading(false);
  //       navigate("/internships");
  //     } else if (data && data.sip_status === false) {
  //       var mainUrl =
  //         data.framework_enrollment_url[process.env.REACT_APP_ENV_TYPE] +
  //         "&internship_id=" +
  //         data.post_data.id +
  //         "&token=" +
  //         localStorage.getItem("auth_token") +
  //         "&org_code=" +
  //         org_code;

  //       window.open(mainUrl, "_self", "credentials=include");
  //       setLoading(false);
  //     } else {
  //       toast.error(
  //         t(
  //           "Failed to create SIP enrolment form. Please try again or contact customer support."
  //         ),
  //         {
  //           position: toast.POSITION.TOP_RIGHT,
  //         }
  //       );
  //       setLoading(false);
  //       navigate("/internships");
  //     }
  //     if (res) {
  //       if (res.non_field_errors) {
  //         const msg = res.non_field_errors.join(" ");
  //         toast.error(msg, {
  //           position: toast.POSITION.TOP_RIGHT,
  //         });
  //       }
  //     }
  //   } else {
  //     if (ti < 1) {
  //       setModal(true);
  //       checkTrigger = false;
  //     } else {
  //       if (coins < parseInt(formVal.no_positions)) {
  //         setModalMode("position-coins");
  //         setModal(true);
  //         checkTrigger = false;
  //       } else {
  //         setModalMode("available-coins");
  //         setModal(true);
  //         checkTrigger = false;
  //       }
  //     }
  //   }
  // };

  //test function 
  const onNewIntern = async (checkTrigger) => {
    let ti = 0;
    if (Object.keys(company).length > 0) {
      const result3 = await getOrganizationInternshipCoinDetail(company?.id);
      var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
      
      console.log(varDate,"subs date")
      
      var today = new Date();
      if (varDate >= today) {
        setModalMode("empty-coin");
        ti = result3?.data?.data?.tokens;
        setCoins(ti);
      } else {
        setModalMode("empty-package");
      }
    }
  
    if (checkTrigger) {
      const { data, res } = await createNewIntern({
        ...formVal,
        documents: formVal.documents.filter((x) => x.doc_type !== "All"),
        collection_radio_type: formVal.collection_radio_type
          ? formVal.collection_radio_type
          : null,
        collection_date: formVal.collection_date
          ? formVal.collection_date
          : null,
        org_code: org_code,
        is_activate: true,
      });
      
  
      if (data && data.status === 1) {
        toast.success(t("Internship form submitted"), {
          position: toast.POSITION.TOP_RIGHT,
          
        });

        navigate("/internships/upload-intern-details", {state: {typeOrigin:typeOrigin,internshipID: data.data}});
        setLoading(false);
        // No redirection, stay on the current page
      } else if (data && data.sip_status === true) {
        toast.success(t("Internship form submitted"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        
  
        // Handle SIP status logic here
        // You may need to perform additional actions here based on your requirements
  
        setLoading(false);
        // No redirection, stay on the current page
      } else if (data && data.sip_status === false) {
        // Handle false SIP status logic here
        // You may need to perform additional actions here based on your requirements
  
        setLoading(false);
        // No redirection, stay on the current page
      } else {
        toast.error(
          t(
            "Failed to create SIP enrolment form. Please try again or contact customer support."
          ),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);
        // No redirection, stay on the current page
      }
  
      if (res) {
        if (res.non_field_errors) {
          const msg = res.non_field_errors.join(" ");
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      if (ti < 1) {
        setModal(true);
        checkTrigger = false;
      } else {
        if (coins < parseInt(formVal.no_positions)) {
          setModalMode("position-coins");
          setModal(true);
          checkTrigger = false;
        } else {
          setModalMode("available-coins");
          setModal(true);
          checkTrigger = false;
        }
      }
    }
  };
  

  const showPaymentPopup = () => {
    setOpen(true);
  };
  const onInternshipSubmit = () => {
    setOpen(false);
    setLoading(true);
    if (isUpdate) {
      onUpdateIntern();
} else {
      // onNewIntern(false);
      sendData();
    } 
      };
  const radioChange = (value, name) => {
    setValue(name, value);
    if (name === "min_degree") {
      errors.min_degree = "";
    } else if (name === "grade") {
      errors.grade = "";
    }
    if (
      value === t("Receive appplications in a single package on a given date")
    ) {
      setEnableDate(true);
    } else {
      setEnableDate(false);
    }
  };

  const radioChangeForVisibilityStatus = (value, name) => {
    if (formVal.visibility_status) setValue(name, 0);
    else setValue(name, value);
  };

  const onMblNumberChange = (val) => {
    if (val) {
      setValue("contact_mob", val);
    }
  };

  const docCheckboxChange = (name, type) => {
    let arr = [...formVal[type]];
    let keyWord = "doc_type";
    const isChecked = arr.some((grd) => grd[keyWord] === name);
    if (isChecked) {
      if (name === "All") {
        arr = [];
      }
      arr = [...arr].filter((grd) => grd[keyWord] !== name);
      arr = [...arr].filter((grd) => grd[keyWord] !== "All");
    } else {
      if (name === "All") {
        arr = newInternStaticObj.requiredDocs.map((m) => {
          return { doc_type: m };
        });
      } else {
        const obj = { [keyWord]: name };
        arr.push(obj);
      }
      if (arr.length === 4) {
        const obj = { [keyWord]: "All" };
        arr.push(obj);
      }
    }
    setValue(type, arr);
    errors.documents = "";
    // alert(name)
  };

  const addLocation = () => {
    let arr = [...formVal.locations];
    let obj = {};
    var myValid = false;
    if (formVal.state !== "" && cities.length === 0) {
      myValid = true;
      obj = {
        country: formVal.country,
        state: formVal.state,
      };
    } else if (formVal.country && formVal.state && formVal.city) {
      myValid = true;
      obj = {
        country: formVal.country,
        state: formVal.state,
        city: formVal.city,
      };
    }
    const isAlreadyAdded = arr.some(
      (loc) => JSON.stringify(loc) === JSON.stringify(obj)
    );
    if (myValid === true && !isAlreadyAdded) {
      arr.push(obj);
      setValue("locations", arr);
      setValue("state", "");
      setSelectedState([]);
      setValue("country", "");
      setValue("country", "Malaysia");
      setSelectedCountry({ label: "Malaysia", value: 132 });
      getState(132);
      setValue("city", "");
      setSelectedCity([]);
      setDuplicateLocErr("");
      setHideLocation(true);
      errors.locations = "";
    } else if (isAlreadyAdded) {
      setDuplicateLocErr(
        t("The selected location is already added to the list")
      );
    }
  };

  const onDeleteLocation = (index) => {
    let arr = [...formVal.locations];
    let newArr = arr.filter((loc, ind) => ind !== index);
    setValue("locations", newArr);
  };

  const contactPersonChange = (val) => {
    setValue("contact_person", val.label);
    setValue("contact_name", val.label);
    setValue("contact_email", val.email);
    setValue("contact_mob", val.mob_number);
    errors.contact_person = "";
  };
  const collectionTypeValue = (val) => {
    setValue("collection_type", val.label);
    if (val.label === t("Manually download applications")) {
      setValue("re_email_address", "");
      setValue("collection_radio_type", "");
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      errors.collection_type = "";
    } else if (val.label === t("Receive applications by E-mail")) {
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      errors.collection_type = "";
    } else if (val.label === t("Others")) {
      setValue("re_email_address", "");
      setValue("collection_radio_type", "");
      errors.collection_type = "";
    }
  };

  const scopeAddChange = (val) => {
    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setValue("scopes", newVal);
    setSelectedScope(newVal);
    errors.scopes = "";
  };

  const uploadFile = async (event) => {
    const formdata = new FormData();
  
    let maxFileSize = 1024 * 1024 * 5; // 5 MB
    let allowedFileTypes = ['application/pdf'];
  
    if (formVal.file_paths.length > 0) {
      toast.error("You can only upload one file", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const file = event.target.files[0];
  
      if (!allowedFileTypes.includes(file.type)) {
        toast.error("Please upload only PDF files!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (file.size > maxFileSize) {
        toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        formdata.append("obj_content", file, file.name);
  
        const { data, status } = await uploadObject(formdata);
        if (status === 200) onUploaded(data);
      }
    }
  };
  

  const onUploaded = (data) => {
    const { status } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.file_paths];
      arr.push(obj);
      setValue("file_paths", arr);
    }
  };

  const onDeleteFiles = (index) => {
    const arr = [...formVal.file_paths];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("file_paths", newArr);
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
    if (name === "position_start_date") {
    setValue(
    "position_end_date",
        moment(new Date(val).setDate(new Date(val).getDate() + 180)).format(
        "YYYY-MM-DD"
    )
      );
    }
    // if(name === "exp_start_date"){
    //   setValue(
    //     "exp_end_date",
    //     moment(new Date(val).setDate(new Date(val).getDate() + 70)).format(
    //       "YYYY-MM-DD"
    //     )
    //   )
    // }
    if (name === "collection_date") {
      errors.collection_date = "";
    }
  };

  const [minEndDate, setMinEndDate] = useState(null);

  const handleStartDateChange = (val) => {
    dateChange("exp_start_date", val);
    const minEndDate = new Date(val);
    minEndDate.setDate(minEndDate.getDate() + 70); // Adding 10 weeks
    setMinEndDate(minEndDate);
  };
  var fileName = "";
  var ext = "";

  const getfileName = (file_path) => {
    // var temp_name_2 = file_name.split(/[\\]/).pop();
    // var temp_name_arr = temp_name_2.split(".");
    // var temp_name = temp_name_arr[0].slice(0, -8);
    // fileName = temp_name;
    // ext = temp_name_arr[1];

    const parts = file_path.split('/');
    const fileNameWithExtension = parts[parts.length - 1];

    // Split the file name by '.' to separate the extension
    const fileNameParts = fileNameWithExtension.split('.');
     fileName = fileNameParts[0]; // The file name without extension
     ext = fileNameParts[1];
  };

  const updateCgpaValue = (val) => {
    if (val.length === 1) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + ".00");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 2) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + "00");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 3) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + "0");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 4) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val);
      } else {
        setValue("req_gpa", "");
      }
    } else {
      setValue("req_gpa", val);
    }

    validateReqCgpaValue();
  };

  const validateReqCgpaValue = () => {
    const reCgpa = formVal.req_gpa;

    if (reCgpa < 0 || reCgpa > 4 || isNaN(reCgpa)) {
      setError("req_gpa", {
        type: "string",
        message: t("Minimum required CGPA should be between 0.00 and 4.00"),
      });
      return;
    }

    clearErrors("req_gpa");
  };

  const updateWorkingHoursValue = (val) => {
    if (val.length === 1) {
      if (val >= 1 && val <= 24) {
        setValue("working_hours", val + ".0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 2) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", val + "0");
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val + ".0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 3) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", val);
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val + "0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 4) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", (Math.floor(val * 10) / 10).toPrecision(2));
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val);
      } else {
        setValue("working_hours", "");
      }
    } else {
      setValue("working_hours", "");
    }

    validateWorkingHoursValue();
  };


console.log(formVal.exp_start_date,"start date")
  const validateWorkingHoursValue = () => {
    const workingHours = formVal.working_hours;

    if (workingHours < 1 || workingHours > 24 || isNaN(workingHours)) {
      setError("working_hours", {
        type: "string",
        message: t("Working Hours should be between 1 and 24 hours per day"),
      });
      return;
    }

    clearErrors("working_hours");
  };

  const coinSet = async () => {
    const result3 = await getOrganizationInternshipCoinDetail(company?.id);
    var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
    var today = new Date();
    if (varDate >= today) {
      setCoins(result3?.data?.data?.tokens);
    } else {
      setCoins(0);
    }
  };


  const handleMinDurationChange = (event) => {
    const value = event.target.value;
    
    setStudentDiffer(value);
    // onSubmit({ studentDiffer: value });
  };

  const handlePayMinimumSKMChange = (event) =>{
    const value = event.target.value;
    
    setEmploymentOpp(value);
    // onSubmit({ employmentOpp: value });
  }

  const handleMinMonthCertChange = (event) => {
    const value = event.target.value;

    setFwa(value);
    // onSubmit({fWa: value});
  };
  useEffect(() => {
    if (company?.id !== undefined) {
      coinSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  useEffect(() => {
    setValue("country", "Malaysia");
    setSelectedCountry({ label: "Malaysia", value: 132 });
    getState(132);
    setValue("intern_type", "Direct Hiring");
    if(typeOrigin === "on-going"){
      setValue("direct_hiring_type", "on-going");
    }else{
      setValue("direct_hiring_type", "completed");
    }
    setValue("internship_module_id", internshipModuleId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formVal.contact_name !== null) {
      setValue("contact_person", formVal.contact_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.contact_name]);


  return (
    <>
      {/* {isOpen === true ? (
        <PaymentPopup
          intershipName={formVal.title}
          companyName={companyData.name}
          setOpen={setOpen}
          isOpen={isOpen}
          onInternshipSubmit={onInternshipSubmit}
        />
      ) : (
        ""
      )} */}
      <MainLayout>
        {/* <HamburgerLayout>
        <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
      </HamburgerLayout>
      {isOpenham && <Menus />} */}
        {id === null ? (
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
              >
                {t("Internships")} &nbsp; &#62;
              </Link>
              <div className="mx-2" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("New MySIP Direct Hiring Internship")}
              </span>
            </div>
          </div>
        ) : (
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
                // className="linkPre"
              >
                {t("Internships")} &nbsp; &#62;
              </Link>
              <div className="mx-2" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("Edit Internship")}
              </span>
            </div>
          </div>
        )}
        {showCommentTable === true? (
              <div className="container mb-5">
              <Root sx={{ width: 200, maxWidth: "50%" }}>
                <table aria-label="custom pagination table" 
                style={{
                  background: "white",
                  borderRadius: "20px"
                }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "rgb(250, 74, 161)",
                          color: "#fff",
                          borderTopLeftRadius: "20px"
                        }}
                      >
                        {"Comments"}
                      </th>
                      <th
                        style={{
                          background: "rgb(250, 74, 161)",
                          color: "#fff",
                          borderTopRightRadius: "20px"
                        }}
                      >
                        {"Created"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {theArray?.length > 0
                      ? (rowsPerPage > 0 && theArray?.length
                        ? theArray.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        : theArray
                      ).map((row) => (
                        <tr key={row.comment}>
                          <td>{row.comment}</td>
                          <td style={{ width: 120 }} align="right">
                            {moment(new Date(row.created_at)).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                        </tr>
                      ))
                      : ""}

                    {emptyRows > 0 && (
                      <tr style={{ height: 41 * emptyRows }}>
                        <td colSpan={3} />
                      </tr>
                    )}
                  </tbody>
                  {theArray?.length > 0 ? (
                    <tfoot>
                      <tr>
                        {/* <CustomTablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={3}
                          count={theArray?.length ?? "0"}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          componentsProps={{
                            select: {
                              "aria-label": "rows per page",
                            },
                            actions: {
                              showFirstButton: true,
                              showLastButton: true,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                      </tr>
                    </tfoot>
                  ) : (
                    <div className="container col-lg-12" style={{margin: "10px"}}>{"No Comments"}</div>
                  )}
                </table>
              </Root>
            </div>
            ):null}
        {pageLoading ? (
          <Loader />
        ) : (
          <NewInternPageWrapper>
            <SectionWrapper>
              <HeaderText>
                {!isUpdate
                  ? t(newInternStaticObj.heading)
                  : t(newInternStaticObj.editHeading)}
                  {!isUpdate?  fromDataMigration ? fromLandingPage ? <PaginationDividerTwo step={1}/>  : 
                    <PaginationLOEDivider step={2}/> : <PaginationDivider step={1}/>: null }
              </HeaderText>
              <HeaderText2>{newInternStaticObj.heading2}</HeaderText2>
              <Description>{t(newInternStaticObj.description)}</Description>
              <SubHeading>{newInternStaticObj.subHeading1}</SubHeading>
              <BreakSection>
                <hr/>
                </BreakSection>
              <Description3>{newInternStaticObj.description2}</Description3>
              <FormWrapper onSubmit={handleSubmit(onSubmit, onSubmitErrorHandler)}>
                {/* intern detail section */}
                <FormGroup>
                  <SingleField>
                    <FormLabel>{t(newInternStaticObj.formTitle)}</FormLabel>
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("HR Executive, Project Manager, etc.")}
                          dataId="internTitle"
                        />
                      )}
                    />
                    {errors.title && (
                      <ErrorField err={t(errors.title.message)} />
                    )}
                  </SingleField>
                  <SingleField>
                    <FormLabel>
                      {t(newInternStaticObj.formDescription)}
                    </FormLabel>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        // <TextArea
                        //   {...field}
                        //   rows="6"
                        //   placeholder="Enter internship description as elaborate as possible"
                        // />
                        <RichTextEditorComponent
                          // {...field}
                          value={formVal.description}
                          setValue={(val) => {
                            setValue("description", val);
                            errors.description = "";
                          }}
                        />
                      )}
                    />
                    {errors.description && (
                      <ErrorField err={t(errors.description.message)} />
                    )}
                  </SingleField>
                  {/* {formVal.status == "accepted" ? "hello" : "hii"} */}
                 
                  <Row className="mb-4">
                    <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                      <AcademicGridWrapper>
                        <FormGroup>
                          <FormLabel>
                            {t(newInternStaticObj.internStartDate)}
                          </FormLabel>
                          <Controller
                            name="exp_start_date"
                            control={control}
                            render={({ field }) => (
                              <label style={{ width: "max-content" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                      autoOk
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      placeholder="dd/mm/yyyy"
                                      views={["year", "month", "date"]}
                                      value={
                                        formVal.exp_start_date
                                          ? new Date(formVal.exp_start_date)
                                          : null
                                      }
                                      onChange={handleStartDateChange}
                                      fullWidth
                                      // minDate={new Date().setDate(
                                      //   new Date().getDate() + 14
                                      // )}
                                      // minDate={id && code ? ""}
                                      // minDate={
                                      //   id && org_code
                                      //     ? ""
                                      //     : new Date().setDate(
                                      //         new Date().getDate()
                                      //       )
                                      // }
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                                <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper>
                              </label>
                            )}
                          />
                          {formVal.exp_start_date === ""
                            ? errors.exp_start_date && (
                                <ErrorField
                                  err={t(errors.exp_start_date.message)}
                                />
                              )
                            : ""}
                        </FormGroup>
                      </AcademicGridWrapper>
                    </Col>
                    <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                      <AcademicGridWrapper>
                        <FormGroup>
                          <FormLabel>
                            {t(newInternStaticObj.internEndDate)}
                          </FormLabel>
                          <Controller
                            name="exp_end_date"
                            control={control}
                            render={({ field }) => (
                              <label style={{ width: "max-content" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                      autoOk
                                      disabled={!formVal.exp_start_date}
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      placeholder="dd/mm/yyyy"
                                      views={["year", "month", "date"]}
                                      value={
                                        formVal.exp_end_date
                                          ? new Date(formVal.exp_end_date)
                                          : null
                                      }
                                      onChange={(val) =>
                                        dateChange("exp_end_date", val)
                                      }
                                      fullWidth
                                      minDate={minEndDate} 
                                      // minDate={
                                      //   formVal.intern_type === "sip"
                                      //     ? new Date(
                                      //         formVal.exp_start_date
                                      //       ).setDate(
                                      //         new Date(
                                      //           formVal.exp_start_date
                                      //         ).getDate() + 70
                                      //       )
                                      //     : formVal.exp_start_date
                                      //     ? new Date(
                                      //         formVal.exp_start_date
                                      //       ).setDate(
                                      //         new Date(
                                      //           formVal.exp_start_date
                                      //         ).getDate() + 1
                                      //       )
                                      //     : new Date().setDate(
                                      //         new Date().getDate() + 14
                                      //       )
                                      // }
                                      // minDateMessage={
                                      //   formVal.intern_type === "sip"
                                      //     ? t(
                                      //         "For MySIP, the minimum duration of internship should be 10 weeks"
                                      //       )
                                      //     : t(
                                      //         "The minimum duration of internship should be 1 day"
                                      //       )
                                      // }
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                                <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper>
                              </label>
                            )}
                          />
                          { errors.exp_end_date && (
                                <ErrorField
                                  err={t(errors.exp_end_date.message)}
                                />
                              )
                            }
                        </FormGroup>
                      </AcademicGridWrapper>
                    </Col>
                    <Col md={4}>
                      <FormLabel>Duration</FormLabel>
                      <Controller
                          name="duration"
                          control={control}
                          render={({ field }) => {
                            const startDate = formVal.exp_start_date;
                            const endDate = formVal.exp_end_date;

                            // Calculate the duration in months
                            const durationWeeks = startDate && endDate
                            ? differenceInWeeks(new Date(endDate), new Date(startDate))
                            : null;

                          const durationDays = startDate && endDate
                            ? differenceInDays(new Date(endDate), new Date(startDate)) % 7
                            : null;

                            const calculateDuration = durationWeeks !== null
                            ? `${durationWeeks} weeks${durationDays > 0 ? ` ${durationDays} days` : ''}`
                            : null;
                            return (
                              <div>
                                {durationWeeks !== null ? (
                                  <p style={{
                                    marginLeft: '10px',
                                    color: 'black'
                                  }}>{calculateDuration}</p>
                                ) : (
                                  null
                                )}
                              </div>
                            );
                          }}
  />
                    </Col>
                  </Row>
                  {/* <Row>
                  <Col sm={12} md={4} style={{ minWidth: "300px", marginBottom: '50px' }}>
                      <FormGroup>
                        <FormLabel>
                          {t(newInternStaticObj.internPositions)}
                        </FormLabel>
                        <Controller
                          name="no_positions"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t("Enter no. of positions")}
                              type="number"
                              min="1"
                              max="10000"
                              disable={
                                formVal.status === ""
                                  ? false
                                  : formVal.status !== "pending"
                                  ? true
                                  : false
                              }
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                                if (event.target.value.length > 4) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {errors.no_positions && (
                          <ErrorField err={t(errors.no_positions.message)} />
                        )}
                      </FormGroup>
                    </Col>
                  </Row> */}
                  <UploadText>{t(newInternStaticObj.uploadTitle)}</UploadText>
                      <UploadNotes>
                        {t(newInternStaticObj.uploadNotes)}
                </UploadNotes>
                  <UploadFileWrapper data-tip data-for="pdf">
                    <input
                      onChange={uploadFile}
                      accept="application/pdf"
                      type="file"
                    />
                    <UploadFileText>
                      {t(newInternStaticObj.uploadFileText)}
                    </UploadFileText>
                    <p className="uploadFileOne">
                      {t("The maximum file size limit is 5 MB")}
                    </p>
                  </UploadFileWrapper>
                  <FileWrapper>
                    {formVal?.file_paths?.length > 0
                      ? formVal.file_paths?.map((x, index) => (
                          <>
                            {getfileName(x.file_path)}
                            <SkillCard
                              key={index + x.file_path}
                              delete={() => onDeleteFiles(index)}
                              // name={x.file_path}
                              name={fileName + "." + ext}
                            />
                          </>
                        ))
                      : ""}
                  </FileWrapper>

                  {errors.file_paths && (
                    <ErrorField err={t(errors.file_paths?.message)} />
                  )}

                  <DividerLine/>

                  <Row>
                    <FormHeaderText>
                      {t("Academic Requirements")}
                    </FormHeaderText>
                  <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                      <SingleField>
                       <FormGroup >
                          <FormLabel>
                            {t(newInternStaticObj.scopeOfStudy)}
                          </FormLabel>
                          <Controller
                            autoFocus={false}
                            name="scopes"
                            control={control}
                            render={({ field: { ref, ...field } }) => (
                              // <Dropdown
                              //   bgGrey
                              //   options={options1 && options1.SOS}
                              //   placeholder="Select scope of study"
                              //   val={formVal.scopes?.length > 0 ? formVal.scopes : ""}
                              //   change={scopeAddChange}
                              //   onFocus={() => {
                              //     // getOptions("hint/loc/SOS", "SOS");
                              //   }}
                              //   isMulti={true}
                              //   {...field}
                              // />
                              <NewInternDropDown
                                innerRef={ref}
                                search={true}
                                options={scope}
                                onFocus={() => {
                                  console.log("Ssss");
                                }}
                                // ref={selectedRef}
                                placeholder={t("Select Scope")}
                                // val={selectedCountry?.value}
                                val={selectedScope}
                                /// label={selectedScope?.label}
                                // label={selectedCountry?.label}
                                change={scopeAddChange}
                                isMulti={true}
                                {...field}
                              />
                            )}
                          />
                          {errors.scopes && (
                            <ErrorField err={t(errors.scopes.message)} />
                          )}
                        </FormGroup>
                      </SingleField>
                    </Col>
                    <Col>
                      <FormGroup >
                        <FormLabel>{t(newInternStaticObj.gpaText)}</FormLabel>
                        <Controller
                          name="req_gpa"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t(
                                "Enter required CGPA (Range 0.00 to 4.00)"
                              )}
                              type="text"
                              min={1}
                              maxLength={4}
                              onBlur={(e) => updateCgpaValue(e.target.value)}
                            />
                          )}
                        />
                        {errors.req_gpa && (
                          <ErrorField err={t(errors.req_gpa.message)} />
                        )}
                     </FormGroup>
                    </Col>
                  </Row>
                
                  <AcademicGridWrapper template="1fr 1fr">
                    <FormGroup>
                      <FormLabel>{t(newInternStaticObj.degreeText)}</FormLabel>
                      <Controller
                        name="min_degree"
                        control={control}
                        render={({ field }) =>
                          newInternStaticObj.degrees.map((degree, index) => (
                            <div key={degree + index}>
                              <RadioInput
                                {...field}
                                checked={degree === formVal.min_degree}
                                onChange={() =>
                                  radioChange(degree, "min_degree")
                                }
                                margin="15px"
                                label={t(degree)}
                              />
                            </div>
                          ))
                        }
                      />
                      {errors.min_degree && (
                        <ErrorField err={t(errors.min_degree.message)} />
                      )}
                    </FormGroup>
                    <FormGroup>
                      <FormLabel>{t("Minimum Year of Study*")}</FormLabel>
                      <Controller
                        name="grade"
                        control={control}
                        render={({ field }) =>
                          newInternStaticObj.grades?.map((grade, index) => (
                            <div key={grade + index}>
                              <RadioInput
                                {...field}
                                checked={grade === formVal.grade}
                                onChange={() => radioChange(grade, "grade")}
                                margin="15px"
                                label={t(grade)}
                              />
                              {/* <CheckboxInput
                              margin="15px"
                              onChange={() =>
                                checkboxChange(grade, "grade_levels")
                              }
                              label={grade}
                              name={grade}
                              checked={formVal.grade_levels?.some(
                                (grd) => grd?.grade === grade
                              )}
                            /> */}
                            </div>
                          ))
                        }
                      />
                      {errors.grade && (
                        <ErrorField err={t(errors.grade.message)} />
                      )}
                    </FormGroup>
                  </AcademicGridWrapper>

                  <FormGroup>
                        <Row>
                          <Col>
                          <FormLabel>{t(newInternStaticObj.addSkillText)}</FormLabel>
                        <AcademicGridWrapper>
                          <Controller
                          name="skills"
                          control={control}
                          render={({field}) =>(
                            <Dropdown
                          onSearch={handleSearch}
                          options={technicalSkills}
                          label={selectedTechSkills?.label}
                          val= {selectedTechSkills?.value}
                          placeholder={"Select a skill ..."}
                          change={(val) => dropdownSkillChange("tech_skills",val)}
                          {...field}
                          />
                          )}
                          />
                          
                         
                        </AcademicGridWrapper>
                          </Col>
                          <Col>
                          <SkillInputDiv>
                        <FormLabel>Please specify additional detail (Optional)</FormLabel>
                        <Input
                            placeholder={"Additional detail"}
                            style={{
                              width: "300px"
                            }}
                            value={extraDetails}
                            onChange={(e)=>{
                              const val = e.target.value;
                              setExtraDetails(val);
                            }}
                            />
                        </SkillInputDiv>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                          <Button
                            style={{ marginTop: "0px" }}
                            onClick={addSkill}
                            margin="15px 10px 15px 0px"
                            width="8em"
                            fontSize="14px"
                            lineHeight="18px"
                            minWidth="0"
                            name={t("Save")}
                          />
                          </Col>
                        </Row>
                      </FormGroup>
                      
                       
                      <FlexWrapper style={{ flexWrap: "wrap" }}>
                        {formVal.skills?.map((skill, index) => (
                          <SIPSkillCard
                            key={index + skill}
                            delete={() => deleteSkill(index)}
                            name={skill.skill}
                            detail={skill.specify_text}
                          />
                        ))}
                        {console.log(formVal.skills,"softSkills")}
                      </FlexWrapper>
                      {errors.skills && (
                        <ErrorField err={t(errors.skills.message)} />
                      )}


                        <FormGroup>
                        <FormLabel>{t(newInternStaticObj.threeSkills)}</FormLabel>
                        {/* <AcademicGridWrapper> */}
                          <Controller
                          name="soft_skills"
                          control={control}
                          render={({field}) =>(
                          //   <Dropdown
                          // onSearch={handleSearch}
                          // options={softSkillsList}
                          // label={selectedSoftSkills?.label}
                          // val= {selectedSoftSkills?.value}
                          // placeholder={"Select a soft skill ..."}
                          // change={(val) => dropdownSkillChange("soft_skills",val)}
                          // {...field}
                          // />
                          softSkillOption?.map((items, index) =>(
                            <div key={items + index} style={{ display: "flex", alignItems: "flex-start" }}>
                              <CheckboxInput
                              margin={"15px"}
                              label={items.label}
                              name={items.label}
                              onChange={()=> handleSoftSkillOption(items.label, "soft_skills")}
                              checked={formVal.soft_skills?.some((itm) =>
                                itm.soft_skill === items.label)}
                              />
                              <img
                                    src={Detail}
                                    alt="img"
                                    style={{ marginLeft: "5px" }}
                                    data-tip={items.description}
                                  />
                                  <ReactTooltip effect="solid" className="tooltip-custom-width" />
                            </div>
                          ))
                          )}
                          />
                          
                          {/* <Button
                            style={{ marginTop: "0px" }}
                            onClick={addSoftSkills}
                            margin="15px 10px 15px 0px"
                            width="8em"
                            fontSize="14px"
                            lineHeight="18px"
                            minWidth="0"
                            name={t("Save")}
                          /> */}
                        {/* </AcademicGridWrapper> */}
                      </FormGroup>
                       
                      {/* <FlexWrapper style={{ flexWrap: "wrap" }}>
                        {formVal.soft_skills?.map((skill, index) => (
                          <SIPSkillCard
                            key={index + skill}
                            delete={() => deleteSkill(index)}
                            name={skill.soft_skill}
                          />
                        ))}
                        {console.log(formVal.skills,"softSkills")}
                      </FlexWrapper> */}
                      {errors.soft_skills && (
                        <ErrorField err={t(errors.soft_skills.message)} />
                      )}
                  
                    
                  <DividerLine/>

                  <FormGroup>
                  <FormHeaderText>
                    {t(newInternStaticObj.hoursTitle)}
                  </FormHeaderText>
                  <AcademicGridWrapper template="1fr 1fr">
                    <FormGroup>
                      <FormLabel>
                        {t(newInternStaticObj.workingHours)}
                      </FormLabel>
                      <Controller
                        name="working_hours"
                        control={control}
                        render={({ field }) => (
                          <Input
                            {...field}
                            placeholder={t("Please enter number of working hours")}
                            dataId="hours"
                            maxLength={4}
                            onBlur={(e) =>
                              updateWorkingHoursValue(e.target.value)
                            }
                          />
                        )}
                      />
                      {errors.working_hours && (
                        <ErrorField err={t(errors.working_hours.message)} />
                      )}
                    </FormGroup>
                    {/* <FormGroup>
                      <FormLabel>{t(newInternStaticObj.padiText)}</FormLabel>
                      <Controller
                        name="payment_status"
                        control={control}
                        render={({ field }) => (
                          <Dropdown
                            bgGrey
                            options={paidOptions}
                            placeholder={t("Paid/UnPaid")}
                            val={t(formVal.payment_status)}
                            label={t(formVal.payment_status)}
                            change={(val) =>
                              dropdownChange("payment_status", val)
                            }
                            {...field}
                            disable={formVal.intern_type === "sip"}
                          />
                        )}
                      />
                      {errors.payment_status && (
                        <ErrorField err={t(errors.payment_status.message)} />
                      )}
                    </FormGroup> */}
                  </AcademicGridWrapper>
                  {formVal?.payment_status !== "unpaid" ? (
                    <AcademicGridWrapper template="1fr 1fr">
                      <FormGroup>
                        <FormLabel>
                          {t(newInternStaticObj.min_compensation)}
                        </FormLabel>
                        <Controller
                          name="min_compensation"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              // placeholder="Minimum Compensation. e.g., RM25"
                              dataId="minComp"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                                if (event.target.value.length > 6) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {errors.min_compensation && (
                          <ErrorField
                            err={t(errors.min_compensation.message)}
                          />
                        )}
                      </FormGroup>
                      <FormGroup>
                        <FormLabel>
                          {t(newInternStaticObj.max_compensation)}
                        </FormLabel>
                        <Controller
                          name="max_compensation"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              min="500"
                              // placeholder="Maximum Compensation. e.g., RM50"
                              dataId="maxComp"
                              onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                  event.preventDefault();
                                }
                                if (event.target.value.length > 6) {
                                  event.preventDefault();
                                }
                              }}
                            />
                          )}
                        />
                        {errors.max_compensation && (
                          <ErrorField
                            err={t(errors.max_compensation.message)}
                          />
                        )}
                      </FormGroup>
                    </AcademicGridWrapper>
                  ) : null}
                </FormGroup>
                <DividerLine />
                  <SingleField>
                  
                  <FormGroup>
                  <FormHeaderText>
                    {t(newInternStaticObj.location)}
                  </FormHeaderText>
                  {!hideLocation && (
                    <>
                    <FormLabel>{t(newInternStaticObj.country)}</FormLabel>
                    {/* <Controller
                    name="country"
                    control={control}
                    render={({ field }) => (
                      <AcademicGridWrapper template="1fr 1fr">
                        <FormGroup> */}
                    {/* <Input {...field} placeholder="Enter Location" />  */}
                    {/* <Dropdown
                            bgGrey
                            options={countries}
                            // options={options1.country}
                            placeholder="Select Country"
                            val={formVal.country}
                            dataId="country"
                            // onFocus={() => {
                            //   getOptions("all/loc/country", "country");
                            // }}
                            change={(val) => dropdownChange("country", val)}
                            {...field}
                          />
                          {errors.country && (
                            <ErrorField err={errors.country.message} />
                          )} */}
                    {/* </FormGroup>
                      </AcademicGridWrapper>
                    )}
                  /> */}
                    <Controller
                      name="country"
                      control={control}
                      render={({ field }) => (
                        <AcademicGridWrapper template="1fr 1fr">
                          <FormGroup>
                            <Dropdown
                              search={true}
                              options={countries}
                              placeholder={t("Select Country")}
                              val={selectedCountry?.value}
                              label={selectedCountry?.label}
                              change={(val) => dropdownChange("country", val)}
                              {...field}
                            />
                          </FormGroup>
                        </AcademicGridWrapper>
                      )}
                    />
                    {errors.insti_name && (
                      <ErrorField err={t(errors.insti_name.message)} />
                    )}
                      <AcademicGridWrapper template="1fr 1fr">
                        <FormGroup>
                          <FormLabel>{t(newInternStaticObj.state)}</FormLabel>
                          <Controller
                            name="state"
                            control={control}
                            render={({ field }) => (
                              // <Dropdown
                              //   bgGrey
                              //   dataId="state"
                              //   options={options1.state}
                              //   placeholder="Select State"
                              //   val={formVal.state ? formVal.state : ""}
                              //   // onFocus={() => {
                              //   //   getOptions(
                              //   //     `hint/${formVal.country}/state`,
                              //   //     "state"
                              //   //   );
                              //   // }}
                              //   disable={!formVal.country}
                              //   change={(val) => dropdownChange("state", val)}
                              //   {...field}
                              // />
                              <Dropdown
                                search={true}
                                options={states}
                                placeholder={t("Select State")}
                                val={selectedState?.value}
                                label={selectedState?.label}
                                change={(val) => dropdownChange("state", val)}
                                {...field}
                              />
                            )}
                          />
                          {errors.state && (
                            <ErrorField err={t(errors.state.message)} />
                          )}
                        </FormGroup>
                        <FormGroup>
                          <FormLabel>{t(newInternStaticObj.city)}</FormLabel>
                          <Controller
                            name="city"
                            control={control}
                            render={({ field }) => (
                              <Dropdown
                                bgGrey
                                search={true}
                                options={cities}
                                placeholder={t("Select City")}
                                val={selectedCity?.value}
                                label={selectedCity?.label}
                                disable={!formVal.state}
                                change={(val) => dropdownChange("city", val)}
                                {...field}
                              />
                            )}
                          />
                          {errors.city && (
                            <ErrorField err={t(errors.city.message)} />
                          )}
                        </FormGroup>
                      </AcademicGridWrapper>
                    </>
                  )}
                  {!hideLocation ? (
                    <SaveButton onClick={addLocation}>
                      {t("Save")}
                    </SaveButton>
                  ) : (
                    <AddButton
                      onClick={() => setHideLocation(false)}
                      className="w-100"
                    >
                      {t(newInternStaticObj.anotherLocation)}
                    </AddButton>
                  )}
                  {duplicateLoc && <ErrorLabel>{duplicateLoc}</ErrorLabel>}
                  {errors.locations && (
                    <ErrorField err={t(errors.locations.message)} />
                  )}
                </FormGroup>
                <FormGroup>
                  <Controller
                    name="locations"
                    control={control}
                    render={({ field }) => (
                      <LocationTable
                        delete={onDeleteLocation}
                        data={formVal.locations}
                      />
                    )}
                  />
                </FormGroup>
                <DividerLine/>

               </SingleField>
                
                </FormGroup>
               
                {/* Application information collection ends */}
                {/* contact information starts */}
                <FormGroup>
                  <FormHeaderText>
                    {t(newInternStaticObj.contactTitle)}
                  </FormHeaderText>
                  <AcademicGridWrapper template="1fr 1fr">
                    <FormGroup>
                      <FormLabel>
                        {t(newInternStaticObj.contactPerson)}
                      </FormLabel>
                      <Controller
                        name="contact_person"
                        control={control}
                        render={({ field: { ref, ...field } }) => (
                          <NewInternDropDown
                            innerRef={ref}
                            bgGrey
                            options={userArr}
                            placeholder={t("Select")}
                            //val={formVal.contact_name ? formVal.contact_name : ""}
                            val={formVal.contact_person}
                            label={formVal.contact_person}
                            // disable={formVal.nationality === 0}
                            change={contactPersonChange}
                            {...field}
                          />
                        )}
                      />
                      {errors.contact_person && (
                        <ErrorField err={t(errors.contact_person.message)} />
                      )}
                    </FormGroup>
                  </AcademicGridWrapper>
                  <SingleField>
                    <FormLabel>{t(newInternStaticObj.fullName)}</FormLabel>
                    <Controller
                      name="contact_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          disabled
                          placeholder={t("Full Name")}
                          maxLength="100"
                        />
                      )}
                    />
                    {errors.contact_name && (
                    <ErrorField err={errors.contact_name.message} />
                  )}
                  </SingleField>
                  <SingleField>
                    <FormLabel>{t("E-mail Address")}</FormLabel>
                    <Controller
                      name="contact_email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          disabled
                          placeholder={t("contactperson@company.com")}
                        />
                      )}
                    />
                    {errors.contact_email && (
                    <ErrorField err={errors.contact_email.message} />
                  )}
                  </SingleField>
                  <AcademicGridWrapper template="400px">
                    <FormGroup>
                      <FormLabel>
                        {t(newInternStaticObj.mobileNumber)}
                      </FormLabel>
                      <Controller
                        name="contact_mob"
                        control={control}
                        render={({ field }) => (
                          <PhoneInput
                            readOnly={false}
                            value={formVal.contact_mob}
                            onChange={onMblNumberChange}
                            //onClick={(e) => {alert('hi')}}
                            defaultCountry="MY"
                            international
                            countryCallingCodeEditable={false}
                            disableDropdown="true"
                          />
                        )}
                      />
                      {errors.contact_mob && (
                        <ErrorField err={errors.contact_mob.message} />
                      )}
                    </FormGroup>
                  </AcademicGridWrapper>
                  <SingleField>
                    <Controller
                      name="visibility_status"
                      control={control}
                      render={({ field }) => (
                        <CheckboxInput
                          margin="15px"
                          onChange={() =>
                            radioChangeForVisibilityStatus(
                              1,
                              "visibility_status"
                            )
                          }
                          checked={1 === formVal.visibility_status}
                          label={t(newInternStaticObj.displayContact)}
                        />
                      )}
                    />
                    {/* {errors.visibility_status && (
                    <ErrorField err={errors.visibility_status.message} />
                  )} */}
                  </SingleField>
                  {/* <SingleField></SingleField> */}
                </FormGroup>
                <DividerLine />
                {/* contact information ends */}
                {loading ? (
                  <Loader />
                ) : (
                <ButtonWrapper>
                    <Button 
                    //redo submit logic when implementing back end 
                    // onClick={handleSubmit(onSubmit)}
                    // // onClick={() => { 
                    // //   handleSubmit(onSubmitErrorHandler());
                    // // }}
                    onClick={handleSubmit(onSubmit, onSubmitErrorHandler)}
                    type = "submit"
                    cursor="pointer"
                    name={t("Continue")}
                    ></Button>
                    <Button
                    secondary
                    color="#FFFFFF"
                    name={t("Back")}
                    variant="outlined"
                    onClick={handleSubmit(onSubmit, onSubmitErrorHandler)}
                    ></Button>
                </ButtonWrapper>
                )}
              </FormWrapper>
              <Tooltip id="internTitle" content={t("Enter internship title")} />
              <Tooltip id="uploadDocuments" content="Upload Documents" />
              <Tooltip
                id="cgpa"
                content={t("Enter required CGPA (Range 0.0 to 4.0)")}
              />
              <Tooltip
                id="skill"
                content={t("Type here to search and add skill(s)")}
              />
              <Tooltip
                id="document"
                content={t("Mention the optional documents, if any")}
              />
              <Tooltip id="hours" content={t("Enter hours (Range 1 to 24)")} />
              <Tooltip id="minComp" content={t("Min. compensation")} />
              <Tooltip id="maxComp" content={t("Max. compensation")} />
              <Tooltip id="country" content={t("Select Country")} />
              <Tooltip id="state" content={t("Select State")} />
              <Tooltip id="city" content={t("Select city")} />
              <Tooltip
                id="pdf"
                content={t(
                  "Upload PDF document to provide applicants with more information"
                )}
              />
              <Tooltip
                id="contact"
                content={t("DISPLAY CONTACT INFORMATION TO STUDENTS")}
              />
            </SectionWrapper>
          </NewInternPageWrapper>
        )}
        {/* <AlertModal show={modal} close={onClose} content={content()} /> */}
      </MainLayout>
    </>
  );
}
