import React from 'react';
import { messagesData } from './messages';
import { accessValueByKey } from './searchFunction';
import { t } from 'i18next';

const ActionProvider = ({ createChatBotMessage, setState, children }) => {
  const addMessageToState = (message) => {
    setState((prev) => ({
      ...prev,
      messages: [...prev.messages, message],
    }));
  };

  const handleShowOptions = () => {
    const message = createChatBotMessage(t("Sure! Here are my options."),
      {
        widget: 'options',
      });
    addMessageToState(message);
  };

  const handleDontUnderstand = () => {
    const message = createChatBotMessage(t("Sorry, I don't know the answer to that. Try with a different keyword or select from my options."),
      {
        widget: 'options',
      });
    addMessageToState(message);
  }

  const handleListBenefits = () => {
    const message = createChatBotMessage(t("Mynext has many benefits for you such as:"),
      {
        widget: 'listComponent',
      });
    addMessageToState(message);
  }

  const handleListNoViews = () => {
    const message = createChatBotMessage(t("There are a couple of reasons why your internship posting may not be getting traction. Please allow us to elaborate below:"),
      {
        widget: 'listZeroViewsComponent',
      });
    addMessageToState(message);
  }

  const handleListLoginDetails = () => {
    const message = createChatBotMessage(t("Due to Personal Data Protection Act, we are not able to see any details on your login as it is private and confidential. Meanwhile, you are not required to re-register your company again, instead you may perform ‘Forget Password’ or your company's admin user can add a new user with your existing company. Below are the steps your company's admin user may follow to add a new user."),
      {
        widget: 'listLoginDetailsComponent',
      });
    addMessageToState(message);
  }

  const handleShowVideo = () => {
    const message = createChatBotMessage(t("Here is the new steps to get your LOE"),
      {
        widget: 'videoComponent',
      });
      addMessageToState(message);
  }

  const handleRating = () => {
    const message = createChatBotMessage(t("How would you rate this conversation?"),
    {
      widget: 'chatbotRating',
    });
    addMessageToState(message);
  }

  const handleFeedback = () => {
    const message = createChatBotMessage(t("Thank you for your rating. If you have further comments or feedback, please type in the box below."),
    {
      widget: 'feedback',
    });
    addMessageToState(message);
  }

  const handleReceivedFeedback = () => {
    const message = createChatBotMessage(t("We appreciate your feedback! It helps us strive for better service. Thank you!"));
    addMessageToState(message);
  }

  const handleDisplayMessage = (key) => {
    const message = createChatBotMessage(t(accessValueByKey(messagesData.talent.messages, key)),
    {
      widget: 'resolution',
    });
    addMessageToState(message);
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          actions: {
            handleShowOptions,
            handleDontUnderstand,
            handleDisplayMessage,
            handleListBenefits,
            handleListNoViews,
            handleListLoginDetails,
            handleShowVideo,
            handleRating,
            handleFeedback,
            handleReceivedFeedback,
          },
        });
      })}
    </div>
  );
};

export default ActionProvider;