// import { getResourcesDrilldown } from "../../../../../../services/CommonApi";
// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";\

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";



export const getResourcesDrilldown = () => {
    const getResources = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Self Awareness": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 15,
                        "moderate": 470,
                        "high": 202
                    }
                },
                "Self Efficacy": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 24,
                        "moderate": 460,
                        "high": 203
                    }
                },
                "Financial and Economic Literacy": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 22,
                        "moderate": 452,
                        "high": 213
                    }
                },
                "Mobilising Others": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 25,
                        "moderate": 475,
                        "high": 187
                    }
                },
                "Mobilising Resources": {
                    "id": 5,
                    "totalTalents": 695,
                    "results": {
                        "low": 25,
                        "moderate": 460,
                        "high": 202
                    }
                }
            }
        }
    }
    return getResources
}

export default Ad2DrilldownHOC(getResourcesDrilldown, [
    {
        title: "Self Awareness",
        dataKey: "Self Awareness",
        url: "self-awareness",
        info:"Talents with high scores for this factor are good at knowing their own abilities. They can deliver presentations well and weigh the value of different options when decision making."
    },
    {
        title: "Self Efficacy",
        dataKey: "Self Efficacy",
        url: "self-efficacy",
        info:"Talents with high scores for this factor are good at recognising and understanding when mistakes have been made when trying something new. This means that theu are able to learn from their mistakes, while completing their work. They are able to push through difficulties to complete the given task."
    },
    {
        title: "Financial and Economic Literacy",
        dataKey: "Financial and Economic Literacy",
        url: "financial-and-economic-literacy",
        info:"Talents with high scores for this factor are good at developing budgets for projects and evaluating the costs and benefits of new ideas. They can also develop financial plans for long term projects."
    },
    {
        title: "Mobilising Others",
        dataKey: "Mobilising Others",
        url: "mobilising-others",
        info:"Talents with high scores for this factor are good at inspiring and motivating others to support and contribute to projects in which they are involved. They are also able to build a balanced team, by recognising the skills and weaknesses of individual team members."
    },
    {
        title: "Mobilising Resources",
        dataKey: "Mobilising Resources",
        url: "mobilising-resources",
        info:"Talents with high scores for this factor are good at planning to use and gather the resources they need to complete their work. They can identify extra resources needed to overcome challenges found during the course of a project. They are able to find sponsors for a project and then manage the budget successfully."
    },
]);
