import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../reducers/studentModalReducer";



function StudentModal(props) {
    const dispatch = useDispatch();
    const modalIsOpen = useSelector((state) => state.studentModalReducer.modalIsOpen);
    const onCloseAction = () => {
        dispatch(updateModal(false));
    };


    return (
        <div>
            <Modal className="add-student-modal" size="lg" backdrop="static" show={modalIsOpen} onHide={onCloseAction} centered>
                <div style={{ padding: '9px' }}>
                    <ModalHeader></ModalHeader>
                    <ModalHeaderText>{props.title}</ModalHeaderText>
                    <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
                    {props.content}
                </div>
            </Modal>
        </div>
    );
}

export default StudentModal;
