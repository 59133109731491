import * as yup from "yup";

export const annualSurveyContent = {
    question1: "1. Do you think MySIP helps your organization attract more talent ?",
    question2: "2. What is the number of interns required annually by your organization ?",
    question2Option: [
        "1-10",
        "11-50",
        "51-100",
        "101-500",
        "501-1000",
        "others"
    ],
    question2otherText: "if you offered placement more than 1000, please specify actually numbers",
    question3: "3. What is your organisation preferred duration for an internship ?",
    question3Option:[
        "Less than 10 weeks",
        "10 to 12 weeks",
        "4 to 6 months",
        "More than 6 months",
        "others"
    ],
    question3otherText: "others (please specify)",
    question4: "4. Please select the percentage of interns hired (this can be full time, contract, temporary or part-time employee) since the start of your organisation's participation in MySIP",
    question4Option: [
        "0%",
        "1% to 25%",
        "25% to 50%",
        "51% to 75%",
        "76% to 100%"
    ],
    question5: "5. The current MySIP framework requires technical knowledge, interpersonal skills such as communication,presentation skills, and business competency including conflict resolution, teamwork, and teambuilding. What are other employability elements/skills needed to complement the MySIP framework in nurturing talents? (You can select more than one)",
    question5Option: [
        "Analytical thinking & Innovation",
        "Active learning & learning strategies",
        "Complex problem-solving Critical thinking & analysis",
        "Creativity, originality & initiative",
        "Leadership & social influence",
        "Technology use, monitoring & control",
        "Technology design & programming",
        "Resilience, stress tolerance & flexibility",
        "Reasoning, problem-solving & ideation",
        "others"
    ],
    question5otherText: "others (please specify)",
    question6: "6. Do you find the double tax deduction incentive under MySIP beneficial for your organisation? Please share your feedback on the incentive and related issues. We may use your feedback as the testimonial to be published for MySIP 2023.",
    question7: "7. Do you find the process of MySIP in mynext is easy?",
    question8: "8. What are the platforms used by your organisation to look for internship candidates? (You can select more than one)",
    question8Option: [
        "Advertisement through company's website",
        "Job Search Portals",
        "University Career Centre/Campus Recruitment Activities",
        "LinkedIn or Social Media",
        "Mynext.my",
        "others"
    ],
    question8otherText: "others (please specify)",
    question9: "9. In general, how do you think mynext eases/facilitates your process of hiring interns"


} 

export const annualServeyScheme = yup.object({
    attractMoreTalent: yup.string().required(""),
    internsRequiredAnnual: yup.string().required(""),
    preferredDurationInternship: yup.string().required(""),
    percentage_intern_hired: yup.string().required(""),
    required_technical_knowledge: yup.array().min(1),
    otherid6: yup.string().required(""),
    mysip_process_is_easy: yup.string().required(""),
    platform_user_organisation: yup.array().min(1),
    otherid9: yup.string().required(""),
    otherid2: yup.string().when('internsRequiredAnnual', {
        is: "Others",
        then: yup.string().required('this field is required'),
      }), 
      otherid3: yup.string().when('preferredDurationInternship', {
        is: "Others (Please Specify)",
        then: yup.string().required('this field is required'),
      }),   
      
      otherid5: yup.string().when('required_technical_knowledge', {
        is: (value) => {
            console.log("valueError22",value)
            return Array.isArray(value) && value.includes(28)},
                    then: yup.string().required('this field is required'),
      }),      
      
      otherid8: yup.string().when('platform_user_organisation', {
        is: (value) => {
            console.log("valueError",value)
            return Array.isArray(value) && value.includes(36)},
        then: yup.string().required('this field is required'),
      }),
})
export const initialStatesAnnual = {
    attractMoreTalent: "",
    internsRequiredAnnual: "",
    preferredDurationInternship: "",
    percentage_intern_hired: "",
    required_technical_knowledge: [],
    otherid6: "",
    mysip_process_is_easy: "",
    otherid9: "",
    otherid2: "",
    otherid3: "",
    otherid5: "",
    otherid8: "",
    platform_user_organisation: [],
    internsRequiredOtherText: "",


} 