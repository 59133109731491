import React from "react";
import DonutChartBottomOpen from "../charts/donoutChartBottomOpen";
import { Ad2Card } from "../adCard";
import { HtmlLegendChartWrapper } from "../adCardStyles";
import ChartLoader from "../chartLoader";
import NoDataChartContainer from "../noDataFoundChart";
import { useNavigate } from "react-router-dom";
import { Utils } from "../utilities";
import { useTranslation } from "react-i18next";


export function Ad2StudentPopulation({ filterParams, data }) {

    const { t, i18n } = useTranslation();
    const chartData = data && data.data;
    const status = data && data.status;
    const onboarded = chartData && chartData[0]?.registered,
        unregistered = chartData && chartData[0]?.unregistered;
    const navigate = useNavigate();
    const handleNavigate = Utils.handleNavigate.bind(this, navigate);
    const columns = [
        {
            name: t("Registered"),
            selectorKey: "registered",
            selector: (row) => row.registered,
            sortable: false,
        },
        {
            name: t("Not Registered"),
            selectorKey: "unregistered",
            selector: (row) => row.unregistered,
            sortable: false,
        },
    ];
    const clickEvent = (path) => {
        const routeMap = {
            [t("Registered")]: "registered",
            [t("Not Registered")]: "not-registered",
        }
        if(path == 'Registered'){
            handleNavigate(`./employee-${routeMap[path]}`,filterParams ? {department:filterParams?.value} : {});

        }
    }
    return (
        <>
            <Ad2Card
                title={t("Employee Population")}
                data={chartData}
                columns={columns}
                exportFileName="employee_population"
                status={status}
            >
                <HtmlLegendChartWrapper>
                    <DonutChartBottomOpen
                        clickEvent={clickEvent.bind(this)}
                        data={{
                            labels: [t("Registered"), t("Not Registered")],
                            datasets: [
                                {
                                    data: [onboarded, unregistered],
                                    backgroundColor: ["#004A84", "#BDDBF3"],
                                },
                            ],

                        }}
                    />
                </HtmlLegendChartWrapper>
            </Ad2Card>
        </>
    );
}
