import InternDetails from "../LoeApplication/internDetails";

const dummyApi = {
    getInterships: () => {
      return Promise.resolve(
        {
          id: 1,
          companyName: "Sony",
          internshipModule:[
            {
              name: "Marketing",
              internshipType:"MySIP Advertised Internship",
              // internShipTypeCode 1=Advertise 2=DirectHiring
              internshipTypeCode:1,
              startdate: "2023-08-01",
              enddate: "2023-12-31",
              eligibleInterns: "40/41",
              internshipEvaluation: "40/41",
              // completionOfInternship 0=NO 1=YES
              completionOfInternship:0,
              uniqueInternJobs:"2",
              totalVacanciesOffred:"21-30",
              internshipTitle:"myInternship",
              internJobDescription:"To assist developers with brain storming",
              minimumEducationLevel: "Level 4",
              scopeOfStudy:"Software Development",
              minimumYearOfStudy:"2 ",
              minimumCgpa:"3",
              skillsRequired:"Programming",
              requiredDocuments:"Resume",
              locationState:"Wilayah Persekutuan",
              locationCity:"Kuala Lumpur",
              status: "submitted",
              internInformation:[
                {
                  internName:"Adam",
                  internNRIC:"981231-14-8879",
                  institution:"KDU UOW Glenmarie",
                  qualification:"Degree",
                  allowancePerMonth:"900",
                  internStartDate:"2023/5/14",
                  internEndDate:"2023/7/14",
                  duration:"12 weeks",
                  myNextRegistration:true,
                  mySipEligibility:true,
                  //intenEvaluation 0=No 1=done
                  internEvaluation:1,
                  //skillsGapEvaluation 0=No 1=done
                  skillsGapEvaluation:1,
                },
                {
                  internName:"Eve",
                  internNRIC:"980101-14-8877",
                  institution:"Sunway University",
                  qualification:"Degree",
                  allowancePerMonth:"1000",
                  internStartDate:"2023/5/24",
                  internEndDate:"2023/8/24",
                  duration:"12 weeks",
                  myNextRegistration:true,
                  mySipEligibility:false,
                  //intenEvaluation 0=No 1=done
                  internEvaluation:0,
                  //skillsGapEvaluation 0=No 1=done
                  skillsGapEvaluation:0,
                },
                {
                  internName:"Lim Goh",
                  internNRIC:"971031-10-7779",
                  institution:"Inti International Subang",
                  qualification:"Degree",
                  allowancePerMonth:"700",
                  internStartDate:"2023/5/4",
                  internEndDate:"2023/8/1",
                  duration:"12 weeks",
                  myNextRegistration:true,
                  mySipEligibility:true,
                  //intenEvaluation 0=No 1=done
                  internEvaluation:1,
                  //skillsGapEvaluation 0=No 1=done
                  skillsGapEvaluation:1,
                },
                {
                  internName:"Ali Abu Bakar",
                  internNRIC:"991101-12-1179",
                  institution:"KDU UOW Glenmarie",
                  qualification:"Degree",
                  allowancePerMonth:"900",
                  internStartDate:"2023/4/15",
                  internEndDate:"2023/7/15",
                  duration:"12 weeks",
                  myNextRegistration:true,
                  mySipEligibility:true,
                  //intenEvaluation 0=No 1=done
                  internEvaluation:1,
                  //skillsGapEvaluation 0=No 1=done
                  skillsGapEvaluation:0,
                },
                {
                  internName:"Gerard",
                  internNRIC:"981231-14-8879",
                  institution:"Monash University",
                  qualification:"Degree",
                  allowancePerMonth:"600",
                  internStartDate:"2023/5/14",
                  internEndDate:"2023/8/3",
                  duration:"12 weeks",
                  myNextRegistration:false,
                  mySipEligibility:true,
                  //intenEvaluation 0=No 1=done
                  internEvaluation:1,
                  //skillsGapEvaluation 0=No 1=done
                  skillsGapEvaluation:1,
                },
              ],
              status: "pending",
            },
            {
              name: "Operations",
              internshipType:"MySIP Direct Hiring Internship",
              // internShipTypeCode 1=Advertise 2=DirectHiring
              internshipTypeCode:2,
              startdate: "2023-05-01",
              enddate: "2023-08-31",
              eligibleInterns: "40/41",
              internshipEvaluation: "40/41",
              // completionOfInternship 0=NO 1=YES
              completionOfInternship:1,
              uniqueInternJobs:"3",
              totalVacanciesOffred:"21-29",
              internshipTitle:"Operations",
              internJobDescription:"To assist project manager with operations",
              minimumEducationLevel: "Level 2",
              scopeOfStudy:"Software Development",
              minimumYearOfStudy:"3 ",
              minimumCgpa:"3",
              skillsRequired:"Project Planning",
              requiredDocuments:"Resume",
              locationState:"Selangor",
              locationCity:"Bandar Sunway",
              status: "pending"
            },
            {
              name: "Development",
              internshipType:"MySIP Direct Hiring Internship",
              // internShipTypeCode 1=Advertise 2=DirectHiring
              internshipTypeCode:2,
              startdate: "2023-08-01",
              enddate: "2023-12-31",
              eligibleInterns: "40/41",
              internshipEvaluation: "40/41",
              // completionOfInternship 0=NO 1=YES
              completionOfInternship:1,
              uniqueInternJobs:"2",
              totalVacanciesOffred:"21-30",
              internshipTitle:"Frontend Developer",
              internJobDescription:"To assist with application development",
              minimumEducationLevel: "Level 4",
              scopeOfStudy:"Software Development",
              minimumYearOfStudy:"2 ",
              minimumCgpa:"3",
              skillsRequired:"Programming",
              requiredDocuments:"Resume",
              locationState:"Johor",
              locationCity:"Johor Bahru",
              status: "pending"
            },
          ],
          submitDate: "2023/11/10",
          status: "awaitiing"
        }
      );
    },

    getInternshipModule: ()=>{
      return Promise.resolve(
        {
          company_type: "Malaysian MNC",
          msc_status: true,
          contact_information_1:{
            full_name: "",
            email: "",
            phone_no: "",
            mobile_no: ""
          },
          contact_information_2:{
            full_name: "",
            email: "",
            phone_no: "",
            mobile_no: ""
          },
          company_sector: {

          },
          company_sub_sector: {

          },
          objectives_student:[
            {
              id: 1,
              objective: ""
            },
            {
              id: 3,
              objective: ""
            },
            {
              id: 9,
              other_obj: ""
            }
          ],
          student_others: {

          },
          company_objectives: [
            {
              
            },
            {},
            {}
          ],
          activities_offered: [
            {},
            {},
            {}
          ],
          expected_learning_outcome: [
            {},
            {},
            {}
          ],
          additional_info:[

          ],
          provide_internal_training: true,
          accept_student_diff_scope: true,
          other_allowance:[

          ],
          declare: true
        }
      )
    },
    getCompanyStatus: ()=>{
      return Promise.resolve(
        {
          status_name:[
           {
            id: 1,
            status_name:  "Public Listed Company"
           },
            {
              id: 2,
              status_name: "Malaysian MNC"
            },
            {
              id: 3,
              status_name: "Foreign MNC"
            },
            {
              id: 4,
              status_name: "SME"
            },
            {
              id: 5,
              status_name: "GLC"
            },
            {
              id: 6,
              status_name: "Start up"
            }
          ]
        }
      )
    }
  };
  
  export default dummyApi;
  
//Implementation method :
// import React, { useState, useEffect } from 'react';
// import dummyApi from './path/to/dummyApi';

// function InternshipList() {
//   const [internships, setInternships] = useState([]);

//   useEffect(() => {
//     dummyApi.getInterships()
//       .then((data) => {
//         setInternships(data);
//       })
//       .catch((error) => {
//         console.error("Error fetching internships:", error);
//       });
//   }, []);

//   return (
//     <div>
//       <h1>Internships</h1>
//       <ul>
//         {internships.map((internship) => (
//           <li key={internship.name}>
//             {internship.name} - {internship.startdate}
//           </li>
//         ))}
//       </ul>
//     </div>
//   );
// }

// export default InternshipList;

  