import React from 'react'
import { Image } from "react-bootstrap";
import './User.css';
import Delete from '../../assets/icons/activeDelete.png';
import Statusicon from "../../assets/Group 711.svg";
import ProfileIcon from "../../assets/avatar.png";
import { useNavigate } from "react-router-dom";
import {  updateCurrentlySelectedEditUserId, updateCurrentlySelectedEditId} from "../../pages/userManagement/userManagerReducer";
import { useSelector, useDispatch } from "react-redux";
import {  userCompanyInvite, getCompanies } from "../../services/apiCalls";
import { updateCompanyData } from "../../pages/myCompany/reducer";


function Users  ({profilepic,name,designation,department,email,user_type,icon,view,modify,id,user_id,status,showToastDeleted,deleteUser,isAdmin})  {
  const navigate = useNavigate();
    const dispatch = useDispatch();
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode)
    const loggedInUser = useSelector((state) => state.user.userData);
    const isAdminUser = useSelector((state) => state.userManager.isUserAdmin);
    const onNavigate = () => {
      if(view===false){
        dispatch(updateCurrentlySelectedEditUserId(user_id))
        dispatch(updateCurrentlySelectedEditId(id))
        navigate("/user-management/edit-user");
      }
    };


function onDeleteClicked(){
  dispatch(updateCurrentlySelectedEditId(id))
  deleteUser(org_code, id)
}

function handleInviteStatus(){
  updateInviteResponse("pending")
}

const updateInviteResponse = async (response) => {
    const { data } = await userCompanyInvite(org_code, { id: id, status:response });
    if (data && data.status === 1) {
      showToastDeleted("Resend", "", "success")
      getOrganisationInfo()
    }else{
      showToastDeleted("Resend", data.message, "Failed")
    }
};

const getOrganisationInfo = async () => {
  const { data } = await getCompanies();
  if (data && data.status === 1) {
      dispatch(updateCompanyData({ ...data.data }))
  } 
};

function dontShowDeleteIcon(){
  if (isAdmin === 'N')
      return false
  if ((isAdmin === 'Y') === (!isAdminUser)){
    return true
  }

}

  return (
    <>
    <div classname="statusWrapper">
      <div className='userWrapper'>
        <div >
            <Image  src={profilepic ? profilepic : ProfileIcon } className="img" />
        </div>
        <div className="user" >
          <div className="nameWrapper">
            <div className='name'>{name} {(loggedInUser.id === user_id)?<span className='view'>{`(Me)`}</span>:""}</div>  
            <div className='designation'>{designation} | {department} </div>
            <div className='email'>{email}</div>    
          </div>
                        
          
                    
        </div>
        
            <div className="usertype">{user_type}</div>
            <>
            { ((status === 'rejected' )) ? "" :
              ((modify===false) && !(loggedInUser.id === user_id)) && !(dontShowDeleteIcon())? <div><Image onClick={onDeleteClicked} src={Delete} className="deleteicon"/></div> :""
            }
            </>
           {status === 'rejected' ?
            <div style={{display:"flex",gap:"5px",position:"absolute",right:"70px",marginTop:"10px"}}>
              <img src={Statusicon} className="statusic" alt=''/>
              <button onClick={handleInviteStatus} className="resendbtn">Resend</button>
            </div> : <></>}

            
                        
          
      </div>
      {(view===false)?<div className='view' style={{cursor:'pointer'}} onClick={onNavigate}><a>View Profile</a></div>:<></>}
      
        
    </div>      
              
   
   
    </>
  )
}

export default Users
