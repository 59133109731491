import { proxy } from 'valtio'
let jobProxy = proxy({
    jobdata:null,
    jobid:"",
    paginationCount:1, 
    setPaginationCount:(index)=>{jobProxy.paginationCount=index},
    paginationTabIndex:1, 
    setPaginationTabIndex:(index)=>{jobProxy.paginationTabIndex=index},
    data:[],
    setdata:(d)=>{jobProxy.data=d},
    userid:"",
    userFullDetails:{},
    fromShortlist: false,
    id:"",
   
})

export {
    jobProxy
}
