import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { useSelector} from "react-redux";

import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import globe from "../../assets/globe.png";
import edit from "../../assets/Vector.png";
import { useNavigate } from "react-router-dom";


import CompanyInformationSwiper from "../../components/companyBranding/companyInformationSwiper";
import CompanyPhotosSwiper from "../../components/companyBranding/companyPhotosSwiper";
import {
  getCompanyBranding,
  getCompanyBrandingImages,
  getCompanyBrandingArticles,
} from "../../services/apiCalls";
import TabBranding from "./tabBranding";
import { useTranslation } from "react-i18next";
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;
const CompanyWrapper = styled.div`
  display: flex;
  gap: 40px;
  @media (min-width: 768px) and (max-width:1100px) {
      display:block;
  }
`;
const Image = styled.img`
  width: 310px;
  height: 271px;
  margin: 40px 0 0 10px;
  @media (max-width: 500px) {
    width: 143px;
    height: 126px;
    margin: 5px 0 0 5px;
  }
`;

const ButtonWrapper = styled.div`
  margin: 20px 0 0 40px;
  @media (max-width: 500px) {
    display: none;
  }
  img {
    margin: 0 10px 0 0;
  }
  button {
    background: #ffe6f2;
    border-radius: 128px;
    border: none;
    padding: 10px 20px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: ${(props) => (props.secondary ? props.secondary : "#D04C8D")};
  }
`;

const CompanyName = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
  margin: 0 0 0 20px;
  @media (max-width: 500px) {
    font-size: 20px;
    line-height: 27px;
    margin: 20px 0 0 -30px;
  }
  @media (min-width: 768px) and (max-width:1100px){
    margin-top:20px;
  }
`;

const OutWrapper = styled.div`
  @media (max-width: 500px) {
    position: relative;
    top: 110px;
    right: 170px;
  }
`;
const CompDetailWrapper = styled.div`
  margin: 20px 0 10px 20px;
`;
const Wrapper = styled.div`
  display: flex;
  margin: 10px 0 20px 0;
  @media (min-width: 320px) and (max-width: 480px) {
    display: block;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    display: block;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    display: block;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: block;
  }
`;
const Location = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #525252;
  margin: 0 0 10px 20px;
  @media (max-width: 500px) {
    font-size: 14px;
    line-height: 19px;
    margin: 0 0 0 -30px;
    word-wrap: break-word;
    position: absolute;
  }
`;
const Website = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #525252;
  @media (min-width: 320px) and (max-width: 480px) {
    display: flex;
    position: relative;
    top: 98px;
    right: 220px;
  }
  img {
    margin: 0 0 0 50px;
  }
  span {
    position: relative;
    left: 24px;
  }
  @media (min-width: 481px) and (max-width: 1200px) {
    img {
      margin: 10px 22px;
    }
    span {
        position: relative;
        left: -10px;
      }
  }
 
`;
const CompanyDetail = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 17px;
  line-height: 23px;
  color: #525252;
  @media (max-width: 500px) {
    margin: 10px 0 0 -10px;
  }
`;
const CompanyDetails = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.secondary ? props.secondary : "#D04C8D")};
  @media (max-width: 500px) {
    margin: 5px 0 0 -10px;
  }
`;
const CompanyDetails2 = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #d04c8d;
  @media (max-width: 500px) {
    margin: -20px 0 0 0;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  @media (max-width: 500px) {
    position: relative;
    top: 110px;
  }
`;

const SectionWrapper = styled.div`
  background: ${(props) => (props.primary ? props.primary : "#FDFDFD")};
  border: 1px solid rgba(228, 228, 228, 0.26);
  border-radius: 10px;
  padding: 30px;
  margin: 40px 30px;

  @media (max-width: 500px) {
    margin: 140px 30px 40px 30px;
  }
  iframe {
    border-radius: 16px;
    margin: 10px 0 0 80px;
    @media (max-width: 500px) {
      margin: 20px 0 0 0;
    }
  }
`;

const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${(props) => (props.secondary ? props.secondary : "#D04C8D")};
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-top: 10px;
`;
const Video = styled.div`
@media (max-width:500px){
iframe{
width:200px;
}
}
`

const CompanyBrand = () => {
  const editCompany = useSelector((state) => state.company);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const [primary, setPrimary] = useState("");
  const [secondary, setSecondary] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [images, setImages] = useState(undefined);
  const [articles, setArticles] = useState(undefined);
  const [about, setAbout] = useState(undefined);
  const [videoDescription, setVideoDescription] = useState(undefined);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  useEffect(() => {
    if (org_code) {
      getCompanyBrandingDetails(org_code);
      getCompanyBrandingImagesAPI();
      getCompanyBrandingArticlesAPI();
    }
  }, [org_code]);

  const getCompanyBrandingDetails = async (org_code) => {
    const details = await getCompanyBranding({ code: org_code });
    if (details?.status === 200 && details?.data) {
      setPrimary(details?.data?.primary_color);
      setSecondary(details?.data?.secondary_color);
      setVideoUrl(details?.data?.video_url);
      setAbout(details?.data?.about);
      setVideoDescription(details?.data?.video_description)
    }
  };

  const getCompanyBrandingImagesAPI = async () => {
    if (org_code) {
      const { data } = await getCompanyBrandingImages({
        code: org_code,
      });
      if (data && data.status === 1) {
        setImages(data.data);
      }
    }
  };

  const getCompanyBrandingArticlesAPI = async () => {
    if (org_code) {
      const { data } = await getCompanyBrandingArticles({
        code: org_code,
      });
      if (data && data.status === 1) {
        setArticles(data.data);
      }
    }
  };

  function handleOnClick(){
    navigate("/companybrandingedit")
  }

  return (
    <div>
      <MainLayout>
        <PageWrapper>
          <CompanyWrapper>
            <div>
              <Image
                src={editCompany?.currentlySelectedcompanyData?.logo_path}
                alt="image"
              ></Image>
              <ButtonWrapper secondary={secondary}>
                <button onClick={handleOnClick}>
                  <img src={edit} alt="imge" />
                  {t("Edit Company Profile")}{" "}
                </button>
              </ButtonWrapper>
            </div>
            <div>
              <CompanyName>{editCompany?.currentOrgData?.org_name}</CompanyName>
              <Wrapper>
                <Location>
                  📍
                  {editCompany?.currentlySelectedcompanyData?.bsn_city_abbr +
                    " " +
                    editCompany?.currentlySelectedcompanyData?.bsn_state_abbr +
                    "," +
                    editCompany?.currentlySelectedcompanyData
                      ?.bsn_country_abbr}{" "}
                </Location>
                <Website>
                  <img src={globe} alt="img"/>
                  <span >
                    {editCompany?.currentlySelectedcompanyData?.website}
                  </span>
                </Website>
              </Wrapper>

              <OutWrapper>
                <CompDetailWrapper>
                  <CompanyDetail>Motto</CompanyDetail>
                  {/* <ButtonWrappers>
                    <img src={Edit} alt="imge" />
                  </ButtonWrappers> */}
                  <CompanyDetails2>
                    {editCompany.currentlySelectedcompanyData.moto}
                  </CompanyDetails2>
                </CompDetailWrapper>
                <CompDetailWrapper>
                  <CompanyDetail>{t("Type Of Organisation")}</CompanyDetail>
                  <CompanyDetails secondary={secondary}>
                    {editCompany.currentlySelectedcompanyData.type_of_org_abbr}
                  </CompanyDetails>
                </CompDetailWrapper>
                <CompDetailWrapper>
                  <CompanyDetail>{t("Sector")}</CompanyDetail>
                  <CompanyDetails secondary={secondary}>
                    {editCompany?.currentlySelectedcompanyData?.sector_abbr}
                  </CompanyDetails>
                </CompDetailWrapper>
                <CompDetailWrapper>
                  <CompanyDetail>{t("Nature Of Business")}</CompanyDetail>
                  <CompanyDetails secondary={secondary}>
                    {editCompany?.currentlySelectedcompanyData?.bsn_nature}
                  </CompanyDetails>
                </CompDetailWrapper>
                <CompDetailWrapper>
                  <CompanyDetail>{t("Company Size")}</CompanyDetail>
                  <CompanyDetails secondary={secondary}>
                    {editCompany?.currentlySelectedcompanyData?.org_size ||
                      0 + " "}{" "}
                    {t("Employees")}
                  </CompanyDetails>
                </CompDetailWrapper>
              </OutWrapper>
            </div>
          </CompanyWrapper>

          <HrLine />

          <SectionWrapper primary={primary}>
            <Title secondary={secondary}>{t("About")}</Title>
            <hr />
            <Content>
              {about}
            </Content>
          </SectionWrapper>

          <TabBranding  />

          <SectionWrapper primary={primary}>
            <Title secondary={secondary}>{t("Profile Video")}</Title>
            <hr />
            <div className="row">
              <div className="col-xlg-6">
                <Content>
                  {videoDescription}
                </Content>
              </div>
              <Video className="col-xlg-6">
               
                {
                  <iframe
                    src={videoUrl}
                    title="sample"
                    allowFullScreen
                    width="313px"
                    height="176px"
                  ></iframe>
                }
                </Video>
              </div>
            
          </SectionWrapper>

          {/*Section4*/}
          <CompanyPhotosSwiper
            primary={primary}
            secondary={secondary}
            data={images}
          />
        
          <CompanyInformationSwiper
            primary={primary}
            secondary={secondary}
            data={articles}
          />
        </PageWrapper>
      </MainLayout>
    </div>
  );
};
export default CompanyBrand;
