import React, { useState } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import TrackApplicationsComponent from "../../components/trackApplications/trackApplications";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import "./trackApplications.css";
import { useSnapshot } from "valtio";
import { companyProxy } from "../myCompany/companyproxy";
import { useEffect } from "react";
import {
  getApplications,
  getjobslist,
} from "../../services/apiCalls";
import { trackProxy } from "./trackProxy";
import Dropdown from "../../components/dropdown/dropdown1";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";
const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  @media (max-width: 767px) {
    // display: none;
  }
`;
const Wrapper = styled.div`
  display: block;
  padding: 24px;
  @media (min-width: 481px) and (max-width: 768px) {
    overflow: scroll;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    overflow: scroll;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    overflow: scroll;
  }
`;
const SectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 12px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export default function TrackApplications() {
  const [job, setJob] = useState([]);
  const [dis, setDis] = useState(true);
  useSnapshot(companyProxy);
  useSnapshot(trackProxy);
  const [enval, setEnval] = useState({ label: "Low", value: "0.2" });
  const [loading, setLoader] = useState(false);
  const { t, i18n } = useTranslation();

  const [task, setTask] = useState([]);

  const [filapplications, setFilapplications] = useState({});
  const [tags, setTags] = useState({});
  const [positions, setPositions] = useState({});

  const [search, setSearch] = useState("");
  useEffect(() => {
    chkser();
  }, [filapplications, tags, trackProxy.finallist, positions]);

  const chkser = () => {
    if (search.length === 0) {
      setTask(trackProxy.applist);
      checkfilapp(trackProxy.applist);
    } else {
      let s = search.toLowerCase();
      let a = [];
      for (let i = 0; i < trackProxy.applist.length; i++) {
        if (
          trackProxy.applist[i]["name"].toLowerCase().indexOf(s) !== -1 ||
          trackProxy.applist[i]["jobtitle"].toLowerCase().indexOf(s) !== -1
        ) {
          a.push(trackProxy.applist[i]);
        }
      }
      setTask(a);
      checkfilapp(a);
    }

    if (tags.value != "tags") {
    }
  };

  const checkfilapp = (al) => {
    if (filapplications.value !== "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (al[i]["status"] === filapplications.value) {
          a.push(al[i]);
        }
      }
      // console.log("setTask", a);
      setTask(a);
      // chkjob(a);
      // chktag(a);
    } else {
      chktag(al);
      chkjob(al);
    }
  };

  const chktag = (al) => {
    if (tags.value !== "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (
          al[i]["engage_tag"] &&
          al[i]["engage_tag"].toLowerCase().indexOf(tags.value) !== -1
        ) {
          a.push(al[i]);
        }
      }
      setTask(a);
    }
  };
  const chkjob = (al) => {
    if (positions.value != "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (
          al[i]["job_id"] &&
          al[i]["job_id"].indexOf(positions.value) !== -1
        ) {
          a.push(al[i]);
        }
      }
      setTask(a);
    }
  };
  // Function to get the position name based on the position ID

  const ch_low_high = (v) => {
    let l = v;
    if (v === "N") {
      l = "low";
    }
    if (v === "Y") {
      l = "high";
    }
    switch (l) {
      case "low":
        return "0.2";
      case "medium_low":
        return "0.4";
      case "medium":
        return "0.6";
      case "medium_high":
        return "0.8";
      case "high":
        return "1";
    }
  };

  const gettalenper = async (d, user_id) => {
    let k = {
      research: ch_low_high(d["research_evaluate"]),
      inception: ch_low_high(d["inception_formation"]),
      communication: ch_low_high(d["communication_presentation"]),
      leadership: ch_low_high(d["leadership"]),
      teamwork: ch_low_high(d["teamwork"]),
      collectedness: ch_low_high(d["collectedness"]),
      plan: ch_low_high(d["plan_execte"]),
      inventive: ch_low_high(d["inventive_accomplishment"]),
      ability: ch_low_high(d["ability_to_influence"]),
      comfort: ch_low_high(d["comfort_with_ambiguity"]),
      achievement: ch_low_high(d["achievement_orientation"]),
      individualism: ch_low_high(d["individualism_vs_collectivism"]),
      receiptiveness: ch_low_high(d["receiptiveness_to_change"]),
      space: ch_low_high(d["space_orientation"]),
      english: enval.value,
      user_id: `'${user_id}'`,
    };
  };

  const getapp = async () => {
    const { data } = await getApplications(companyProxy.selectcompany.org_code);
    if (data.status === 1) {
      if (JSON.stringify(trackProxy.applist) !== JSON.stringify(data.data)) {
        setTask(data.data);
        trackProxy.applist = data.data;
        let l = [{ label: "All", value: "-1" }];
        let ll = [];
        let lll = [{ label: "Positions", value: "-1" }];
        let llll = [];
        for (let i = 0; i < data.data.length; i++) {
          if (
            data.data[i]["engage_tag"] &&
            data.data[i]["engage_tag"].length > 0
          ) {
            let a = data.data[i]["engage_tag"].split(",");
            for (let j = 0; j < a.length; j++) {
              if (ll.indexOf(a[j].toLowerCase().trim()) === -1) {
                l.push({
                  label: a[j].trim(),
                  value: a[j].toLowerCase().trim(),
                });
              }
            }
          }
        }
        for (let i = 0; i < data.data.length; i++) {
          if (data.data[i]["job_id"] && data.data[i]["job_id"].length > 0) {
            let a1 = data.data[i]["job_id"].split(",");
            for (let j = 0; j < a1.length; j++) {
              if (llll.indexOf(a1[j]) != -1) {
                lll.push({ label: a1[j], value: a1[j] });
              }
            }
          }
        }
        trackProxy.tags = l;
        trackProxy.joblist = lll;
      }
    }
  };

  useEffect(async () => {
    if (companyProxy.selectcompany) {
      //setLoader(true)

      await getapp();
      const { data } = await getjobslist(companyProxy.selectcompany.org_code);
      if (data.status === 1) {
        trackProxy.joblist = data.data;
        trackProxy.joblist.unshift({ label: "All", value: "-1" });
        let l = {};
        for (let i = 0; i < data.data.length; i++) {
          l[data.data[i]["value"]] = data.data[i];
        }
        let k = trackProxy.applist;
        for (let i = 0; i < trackProxy.applist.length; i++) {
          k[i]["match"] = await gettalenper(
            l[trackProxy.applist[i]["job_id"]],
            trackProxy.applist[i]["user_id"]
          );
        }
        trackProxy.finallist = k;
      }
      //setLoader(false)
    }
  }, [companyProxy.selectcompany, trackProxy.setcheck]);

  return (
    <MainLayout>
      <CompanySection>
        <InputBox>
          <Input
            color="#fff"
            placeholder={t("Search Keywords for Job Title or Name")}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </InputBox>
        <div className="hideSearchBars" style={{ marginLeft: "0px" }}>
          <Button
            onClick={chkser}
            style={{ cursor: "pointer" }}
            name={t("Search")}
          />
        </div>
      </CompanySection>
      <SectionWrapper>
        <Dropdown
          name="Engage"
          search={true}
          options={trackProxy.joblist}
          placeholder={t("Select Position")}
          minWidth="auto"
          width="100px"
          isMulti={false}
          val={positions?.value}
          label={positions?.label}
          id="drop"
          change={(e) => {
            // setJob(e);
            setPositions(e);
            //setDis(false);
            chkser();
          }}
        />

        <Dropdown
          className="drop"
          name="main"
          search={true}
          options={[
            { label: t("All"), value: "-1" },
            { label: t("Application"), value: "pending" },
            { label: t("Shortlist"), value: "shortlist" },
            { label: t("Engage"), value: "engage" },
            { label: t("Interview"), value: "interview" },
            { label: t("Offered"), value: "offered" },
            { label: t("Hired"), value: "hire" },
            { label: t("Rejected"), value: "reject" },
          ]}
          placeholder={t("Select Application Status")}
          minWidth="auto"
          width="100px"
          isMulti={false}
          val={filapplications?.value}
          label={filapplications?.label}
          id="drop"
          change={(e) => {
            setFilapplications(e);

            chkser();
          }}
        />

        <Dropdown
          name="Engage"
          search={true}
          options={trackProxy.tags}
          placeholder={t("Select Tag")}
          minWidth="auto"
          width="100px"
          isMulti={false}
          val={tags?.value}
          label={tags?.label}
          id="drop"
          change={(e) => {
            setTags(e);

            chkser();
          }}
        />
      </SectionWrapper>
      {loading ? (
        <Loader />
      ) : (
        <Wrapper>
          <TrackApplicationsComponent
            tasks={
              Object.keys(filapplications).length === 0 ||
                Object.keys(positions).length === 0
                ? []
                :
                task
            }
            t={t}
          />
        </Wrapper>
      )}
    </MainLayout>
  );
}
