import React, { useEffect, useState } from "react";
import QuickChart from "quickchart-js/build/quickchart.cjs";
import { Text, View, Image, Svg, Circle } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data,image }) => {
  const chartData = data;

  const realistic = chartData && chartData["Realistic"];
  const investigative = chartData && chartData["Investigative"];
  const artistic = chartData && chartData["Artistic"];
  const social = chartData && chartData["Social"];
  const enterprising = chartData && chartData["Enterprising"];
  const conventional = chartData && chartData["Conventional"];

  const [workInterestChartUrl, setWorkInterestChartUrl] = useState(null);

  const { t } = useTranslation();

  // useEffect(() => {
  //   if (
  //     realistic &&
  //     realistic["points"] !== undefined &&
  //     investigative &&
  //     investigative["points"] !== undefined &&
  //     artistic &&
  //     artistic["points"] !== undefined &&
  //     social &&
  //     social["points"] !== undefined &&
  //     enterprising &&
  //     enterprising["points"] !== undefined &&
  //     conventional &&
  //     conventional["points"] !== undefined
  //   ) {
  //     (async function () {
  //       const myChart = new QuickChart();
  //       myChart.setHost("127.0.0.1:3400");
  //       myChart.setScheme("http");
  //       myChart
  //         .setConfig({
  //           type: "bar",
  //           data: {
  //             labels: [
  //               t("Realistic"),
  //               t("Investigative"),
  //               t("Artistic"),
  //               t("Social"),
  //               t("Enterprising"),
  //               t("Conventional"),
  //             ],
  //             datasets: [
  //               {
  //                 data: [
  //                   realistic["points"],
  //                   investigative["points"],
  //                   artistic["points"],
  //                   social["points"],
  //                   enterprising["points"],
  //                   conventional["points"],
  //                 ],
  //                 backgroundColor: "#004B84",
  //                 borderRadius: Number.MAX_VALUE,
  //                 borderSkipped: false,
  //                 borderColor: "rgba(0,0,0,0)",
  //                 barPercentage: 0.6,
  //                 categoryPercentage: 0.6,
  //               },
  //             ],
  //           },
  //           options: {
  //             scales: {
  //               xAxes: [
  //                 {
  //                   display: true,
  //                   gridLines: {
  //                     display: false,
  //                   },
  //                 },
  //               ],
  //               yAxes: [
  //                 {
  //                   display: true,
  //                   gridLines: {
  //                     display: true,
  //                     drawBorder: false,
  //                   },
  //                   ticks: {
  //                     display: true,
  //                     min: 0,
  //                     max: 40,
  //                   },
  //                 },
  //               ],
  //             },
  //             legend: {
  //               display: false,
  //             },
  //             plugins: {
  //               datalabels: {
  //                 display: false,
  //                 align: "center",
  //                 anchor: "end",
  //                 backgroundColor: "#FFFDFD",
  //                 borderColor: "#ddd",
  //                 borderRadius: 10,
  //                 borderWidth: 2,
  //                 padding: 4,
  //                 color: "#666666",
  //                 font: {
  //                   family: "sans-serif",
  //                   size: 10,
  //                   style: "normal",
  //                 },
  //               },
  //             },
  //           },
  //           plugins: [
  //             {
  //               afterDatasetDraw: (chart, options) => {
  //                 var ctx = chart.ctx;
  //                 const legendItems = chart.legend.legendItems;
  //                 chart.data.datasets.forEach((dataset, datasetIndex) => {
  //                   const backgroundColors = dataset.backgroundColor;
  //                   const legendItem = legendItems[datasetIndex];
  //                   var datasetMeta = chart.getDatasetMeta(datasetIndex);
  //                   datasetMeta.data.forEach((segment, segmentIndex) => {
  //                     if (legendItem.hidden) {
  //                       return;
  //                     }

  //                     var posX = segment.tooltipPosition().x,
  //                       posY = segment.tooltipPosition().y;
  //                     ctx.beginPath();
  //                     ctx.arc(posX, posY, 15, 0, 2 * Math.PI);
  //                     ctx.strokeStyle =
  //                       typeof backgroundColors === "string"
  //                         ? backgroundColors
  //                         : backgroundColors[segmentIndex];
  //                     ctx.fillStyle = "#FFFDFD";
  //                     ctx.lineWidth = 5;
  //                     ctx.shadowBlur = 7;
  //                     ctx.shadowOffsetX = 0;
  //                     ctx.shadowOffsetY = 0;
  //                     ctx.shadowColor =
  //                       typeof backgroundColors === "string"
  //                         ? backgroundColors
  //                         : backgroundColors[segmentIndex];
  //                     ctx.stroke();
  //                     ctx.fill();
  //                     ctx.lineWidth = null;
  //                     ctx.shadowBlur = null;
  //                     ctx.shadowOffsetX = null;
  //                     ctx.shadowOffsetY = null;
  //                     ctx.textAlign = "center";
  //                     ctx.font = "normal normal 400 14px/16px Oswald";
  //                     ctx.textBaseline = "middle";
  //                     ctx.fillStyle = "#000000";
  //                     ctx.fillText(`${dataset.data[segmentIndex]}`, posX, posY);
  //                   });
  //                 });
  //               },
  //             },
  //           ],
  //         })
  //         .setWidth(500)
  //         .setHeight(300)
  //         .setBackgroundColor("transparent");
  //       setWorkInterestChartUrl(await myChart.getUrl());
  //     })();
  //   }
  // }, [
  //   realistic,
  //   investigative,
  //   artistic,
  //   social,
  //   enterprising,
  //   conventional,
  //   t,
  // ]);

  return (
    <View style={{}}>
      <View>
        <Text
          style={{
            fontFamily: "General Sans",
            fontSize: 24,
            fontWeight: 500,
            textTransform: "uppercase",
            borderBottom: "2px solid black",
            paddingBottom: 5,
          }}
        >
          {"3."} {t("Work Interest")}
        </Text>

        {realistic && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                marginVertical: 4,
              }}
            >
              {"3.1"} {t("Realistic")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"1) "}
              {t(
                "Enjoy practical, hands-on work with materials and nature; problem-solving in real life Characteristics"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 30,
              }}
            >
              {t("Characteristics")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Practical")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Using tools or machines")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Outdoors work with nature or animals")}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"2) "}
              {t("Fields for Realistic people")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Construction")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Architecture")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Hospitality and Catering")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Professional sport")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Armed Forces")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Transportation - distribution")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Engineering")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Information technology")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Agriculture")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Forestry")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Horticulture")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Traditional crafts")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Landscape design and building")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Manufacturing")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {investigative && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                marginVertical: 4,
              }}
            >
              {"3.2"} {t("Investigative")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"1) "}
              {t(
                "Enjoy working with theory and abstract problem-solving, research and intellectual inquiry, investigating ideas"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 30,
              }}
            >
              {t("Characteristics")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Searching for information and facts")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Intellectual inquiry")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Working with data")}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"2) "}
              {t("Fields for Investigative people")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Medicine")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Health science - laboratory work")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Environmental investigation")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Engineering")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Communication development")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Innovative design")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("IT development")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Mathematics")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Science and Technology")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Academic research")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {artistic && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                marginVertical: 4,
              }}
            >
              {"3.3"} {t("Artistic")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"1) "}
              {t(
                "Enjoy working in the arts and in creative work that involves designing new approaches, solutions or products"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 30,
              }}
            >
              {t("Characteristics")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Using imagination and intuition")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Being creative")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Innovation")}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"2) "}
              {t("Fields for Artistic people")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Acting")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Film and theatre")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Writing - books, plays, film scripts, poetry")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("songs")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Media")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Clothing and Fashion design")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Music composition")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Music and singing performance")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Painting, pottery, sculpture")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Advertising")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Television - set design, programming")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Dance / Choreography")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Fitness Industry")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {social && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                marginVertical: 4,
              }}
            >
              {"3.4"} {t("Social")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"1) "}
              {t(
                "Enjoy helping, serving and assisting other people and promoting others' welfare"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 30,
              }}
            >
              {t("Characteristics")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Caring for adults or children")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Showing compassion and sympathy")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Practical")}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"2) "}
              {t("Fields for Social people")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Counselling")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Education")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Training and coaching")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Health care and services")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Human resources")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Charity work")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Medicine")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Hospitality")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Social work")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Child care")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {enterprising && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                marginVertical: 4,
              }}
            >
              {"3.5"} {t("Enterprising")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"1) "}
              {t(
                "Enjoy leading, motivating and influencing others and taking a lead in starting and initiating projects"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 30,
              }}
            >
              {t("Characteristics")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Take initiative")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Persuading")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Decision-making")}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"2) "}
              {t("Fields for Enterprising people")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Sales")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Marketing")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Business")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Government and Public Administration")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Management")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Law and Security")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Real Estate")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Entrepreneurship")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Finance")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Politics")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {conventional && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                marginVertical: 4,
              }}
            >
              {"3.6"} {t("Conventional")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"1) "}
              {t(
                "Enjoy organising and managing processes, information and data accurately"
              )}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 30,
              }}
            >
              {t("Characteristics")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Administration")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Methodical")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Orderly")}
                  </Text>
                </View>
              </View>
            </View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginLeft: 20,
              }}
            >
              {"2) "}
              {t("Fields for Conventional people")}
            </Text>
            <View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Administration")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Financial management")}
                  </Text>
                </View>
              </View>

              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Sales")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Office work")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Care services")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Logistics")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Retail")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Catering")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Transportation - driving")}
                  </Text>
                </View>
              </View>
              <View
                style={{
                  marginLeft: 30,
                  marginBottom: 4,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                  flexWrap: "nowrap",
                }}
              >
                <View style={{ flex: "0 0 16", marginTop: 2.5 }}>
                  <Svg width={12} height={12}>
                    <Circle cx={6} cy={6} r={2} fill="#333" />
                  </Svg>
                </View>
                <View style={{ flex: "1 1 auto" }}>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 400,
                      color: "#213343",
                      lineHeight: 1.5,
                    }}
                  >
                    {t("Information technology")}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}

        {image !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={image}
              style={{ marginVertical: 30, marginHorizontal: 60, width: 300, height: 300 }}
            />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Work Interest")}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
