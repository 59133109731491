// import { getDigitalDrilldown } from "../../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";


export const getDigitalDrilldown = () => {
    const getDiigital = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Digital Fluency": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 11,
                        "moderate": 470,
                        "high": 206
                    }
                },
                "Software Use": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 10,
                        "moderate": 467,
                        "high": 210
                    }
                },
                "Software Development": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 17,
                        "moderate": 464,
                        "high": 206
                    }
                },
                "Understanding Digital Systems": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 19,
                        "moderate": 442,
                        "high": 226
                    }
                }
            }
        }
    }

    return getDiigital
}

export default Ad2DrilldownHOC(getDigitalDrilldown, [
    {
        title: "Digital Fluency",
        dataKey: "Digital Fluency",
        url: 'digital-fluency',
        info:"High digital fluency skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to use technology and interact professionally in the digital world are important skills to some extent, in all careers in the future of work."
    },
    {
        title: "Software Use",
        dataKey: "Software Use",
        url: 'software-use',
        info:"High software use skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to learn about, adapt to and integrate new software into your work, are important skills to some extent, in all careers in the future of work."
    },
    {
        title: "Software Development",
        dataKey: "Software Development",
        url: 'software-development',
        info:"High software development skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to develop digital systems, understand and develop computer code and create digital programmes to solve problems are valuable assets if you see digital skills as a core element of your chosen career."
    },
    {
        title: "Understanding Digital Systems",
        dataKey: "Understanding Digital Systems",
        url: 'understanding-digital-systems',
        info:"High understanding digital systems skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to use, understand and develop digital systems, and use data to gain insights are valuable assets if you see digital skills as a core element of your chosen career."
    },
]);
