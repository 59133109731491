// import Cookies from 'js-cookie';  #unused variable#
import { cH } from "@fullcalendar/core/internal-common";
import { Navigate, useLocation  } from "react-router-dom";
import { getCompanies } from "../services/apiCalls";

function CompanyRegisteredRoute({ children }) {

  
  
  console.log("this routing");
  const currentCompany = JSON.parse(localStorage.getItem("companyData")) ;
  console.log("this routing",currentCompany);

  const currentUser = localStorage.getItem("auth_token");
  const location = useLocation();
  // JSON.stringify
  // JSON.parse
  // return currentUser ?getOrganisationInfo(): children  ;
  return currentUser ? currentCompany && Object.keys(currentCompany?.data).length > 0 ? <Navigate to='/dashboard' />: children: children  ;

}

export default CompanyRegisteredRoute;
 
