import React from "react";
import { useTranslation } from "react-i18next";
import "./nodata.css";
export default function NoDataChartContainer() {
  const { t } = useTranslation();
  return (
    <div className="nodataFound">
      <p style={{ margin: 0 }}>{t("No Data Found")}</p>
    </div>
  );
}
