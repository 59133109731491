import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Form from 'react-bootstrap/Form';
import RadioInput, { RadioInput1 } from "../../components/radioInput/radioInput";
import { Controller } from "react-hook-form";
import Input from "../../components/input/input";
import React, { useState, useEffect } from "react";
import { co } from "@fullcalendar/core/internal-common";

const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const FormWrapper = styled.div`
  margin-top: 20px;
`;
const FormGroup = styled.div`
  min-width: 240px;
  width: 100%;
  label {
    width: unset !important;
  }
`;
const SingleField = styled.div`
  margin-bottom: 30px;
`;

const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;
const Description2 = styled.p`
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 60px
}
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;
const WarningLable = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
`;
const WarningDescription = styled.li`
  font-weight: 500;
  font-size: 16px;
  margin-left: 20px;
  margin-bottom: 20px;
`;
export default function QuestionSection1 ({onSubmit}) {
  const [minDuration, setMinDuration] = useState('Yes');
  const [payMinimumSKM, setPayMinimumSKM] = useState('Yes');
  const [minMonthCert, setMinMonthCert] = useState('Yes');
  const [minSKM5, setMinSKM5] = useState('Yes');
  const [monthlyAllowance1, setMonthlyAllowance1] = useState('');
  const [monthlyAllowance2, setMonthlyAllowance2] = useState('');
  const [monthlyAllowance3, setMonthlyAllowance3] = useState('');
  const [showWarning, setShowWarning] = useState(false);
  const [radioBtn1, setRadioBtn1] = useState({});
  
  // const handleInternshipDurationChange = (event) => {
  //   setInternshipDuration(event.target.value);
  // };

  // const handlePayMinimumSKMChange = (event) => {
  //   setPayMinimumSKM(event.target.value);
  // };
  useEffect(() => {
    onSubmit({
      minDuration,
      payMinimumSKM,
      minMonthCert,
      minSKM5,
      monthlyAllowance1,
      monthlyAllowance2,
      monthlyAllowance3,
    });
  }, []);
  const handleMinDurationChange = (event) => {
    const value = event.target.value;
    
    setShowWarning(value === 'No');
    setMinDuration(value);
    onSubmit({ minDuration: value });
  };

  const handlePayMinimumSKMChange = (event) =>{
    const value = event.target.value;
    
    setShowWarning(value === 'No');
    setPayMinimumSKM(value);
    onSubmit({ payMinimumSKM: value });
  }

  const handleMinMonthCertChange = (event) => {
    const value = event.target.value;

    setShowWarning(value === 'No');
    setMinMonthCert(value);
    onSubmit({minMonthCert: value});
  };

  const handleMinSKM5Change = (event) => {
    const value = event.target.value;

    setShowWarning(value === 'No');
    setMinSKM5(value);
    onSubmit({minSKM5: value});
  };
    return (
        <div>
                <Description2>Internship Module Question for MYNEXT Advertised Internship 2023</Description2>
                <BreakSection>
                <hr/>
                </BreakSection>
                <GroupWrapper>
                    <FormLabel>Do you provide internship with a minimum duration of 10 weeks?*</FormLabel>
                    <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="Yes"
                          checked={minDuration === 'Yes'}
                          onChange={
                            handleMinDurationChange
                            // setMinDuration("Yes")
                          }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="No"
                          checked={minDuration === 'No'}
                          onChange={
                            handleMinDurationChange
                           }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                </GroupWrapper>
                <GroupWrapper>
                    <FormLabel>Do you pay a minimum RM500.00 per month or more for student undergoing Sijil Kemahiran Malaysia (SKM) Level 1 to Level 3, Certificate, Diploma, (SKM) Level 4 i.e Diploma Kemahiran Malaysia (DKM) or equivalent to do internship with you?</FormLabel>
                    <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="Yes"
                          checked={payMinimumSKM === 'Yes'}
                          onChange={handlePayMinimumSKMChange}
                            />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="No"
                          defaultChecked
                          checked={payMinimumSKM === 'No'}
                          onChange={handlePayMinimumSKMChange}/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                </GroupWrapper>
                <GroupWrapper>
                    <FormLabel>If yes, what is the actual monthly allowance paid for this tier?</FormLabel>
                    <span className="jonNation"></span>
                    <div style={{marginTop: "10px"}}>
                        <Input
                        placeholder={"RM 5000"}/>
                    </div>
                </GroupWrapper>
                <GroupWrapper>
                    <FormLabel>Do you pay a minimum RM500.00 per month or more for student undergoing Sijil Kemahiran Malaysia (SKM) Level 1 to Level 3, Certificate, Diploma, (SKM) Level 4 i.e Diploma Kemahiran Malaysia (DKM) or equivalent to do internship with you?</FormLabel>
                    <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value= 'Yes'
                          checked= {minMonthCert === 'Yes'}
                          onChange={handleMinMonthCertChange}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value= 'No'
                          checked= {minMonthCert === 'No'}
                          onChange={handleMinMonthCertChange}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                </GroupWrapper>
                <GroupWrapper>
                    <FormLabel>If yes, what is the actual monthly allowance paid for this tier?</FormLabel>
                    <span className="jonNation"></span>
                    <div style={{marginTop: "10px"}}>
                        <Input
                        placeholder={"RM 5000"}/>
                    </div>
                </GroupWrapper>
                <GroupWrapper>
                    <FormLabel>Do you pay a minimum RM600.00 per month or more for student undergoing (SKM) Level 5 i.e. Diploma Lanjutan Kemahiran Malaysia (DLKM), Bachelor’s Degree, Master’s Degree and Professional Certificate or equivalent to do internship with you?</FormLabel>
                    <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value= 'Yes'
                          checked= {minSKM5 === 'Yes'}
                          onChange={handleMinSKM5Change}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value= 'No'
                          checked= {minSKM5 === 'No'}
                          onChange={handleMinSKM5Change}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                </GroupWrapper>
                <GroupWrapper>
                    <FormLabel>If yes, what is the actual monthly allowance paid for this tier?</FormLabel>
                    <span className="jonNation"></span>
                    <div style={{marginTop: "10px"}}>
                        <Input
                        placeholder={"RM 5000"}/>
                    </div>
                </GroupWrapper>
                {showWarning && (
          <div>
            <WarningLable>Remarks</WarningLable>
            
            <WarningDescription>
            You are not eligible to participate in National Structured Internship Programme (MySIP) 2023. Please pay aminimun of RM 500.00 per month
            or more for SKM1-SKM5 or Diploma  or a minimum of RM 600.00 per month for Bachelor degree, Master degree or Professional Certificate.
            </WarningDescription>
           
          </div>
        )}
        </div>
    )
}
