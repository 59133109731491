import React from "react";
import { SectionWrapper, HeaderText } from "./common";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import AboutMaker from "./aboutMaker";
import { t } from "i18next";

export default function About({aboutName,setAboutDetail}) {
  const [showInputEle, setShowInputEle] = useState(false);
  return (
    <SectionWrapper>
      <Row>
        <Col lg={11}>
          <HeaderText>{t("About")}</HeaderText>
        </Col>
        <Col lg={1}>
          <HeaderText onClick={() => setShowInputEle(true)}>
            {t("Edit")} 
          </HeaderText>
        </Col>
      </Row>

      <hr />
      <AboutMaker
        value={aboutName}
        handleChange={(e) => setAboutDetail(e)}
        handleDoubleClick={() => setShowInputEle(true)}
        handleBlur={() => setShowInputEle(false)}
        showInputEle={showInputEle}
      />
    </SectionWrapper>
  );
}
