import React from 'react'
import styled from 'styled-components'
import { BoxWrapper, SubHeading } from './common'
import Skills1 from "../../assets/Saly-32skills1.png"
import Skills2 from "../../assets/Saly-44skills2.png"
import Skills3 from "../../assets/Saly-13skills3.png"
import Skills4 from "../../assets/Saly-31skills4.png"

const Card = styled.div`
width: 260px;
height: 280px;
background: #FFFFFF;
box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
border-radius: 10px;
padding:24px;
display:flex;
flex-direction:column;
`;

const Image = styled.img`
  width:92px;
  height:92px;
  margin:0 auto;
`;

const CardHeader = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 600;
font-size: 18px;
line-height: 115.3%;
/* or 21px */
text-align: center;
letter-spacing: 0.01em;

color: #434343;
margin-bottom:12px;
`;

const CardContent = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
display: flex;
align-items: center;
text-align: center;
color: #8F8F8F;
`;

const CardsWrapper = styled.div`
  display:flex;
  flex-direction:row;
  flex-flow:wrap;
  justify-content:space-around;
`;
export default function Skills() {
    const data = [{
        image: Skills1,
        heading: " Career skills",
        content: "Monitoring, Management of personal resources, Operation and control"
    }, {
        image: Skills2,
        heading: "English proficiency",
        content: "Intermediate - Able to communicate in situations using simple language to express feeling, opinions, experiences and ideas"
    }, {
        image: Skills3,
        heading: "	IT Skills",
        content: "Has basic IT skills and knowledge in the areas; Internet and Email, Spreadsheet and Databases, Graphics and Multimedia, and Word Processing"
    }]
    return (
        <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>Skills</SubHeading>
            <hr />
            <CardsWrapper style={{marginTop:"24px"}}>
                {data.map((card, idx) =>
                    <Card key={idx} style={{marginTop:"12px"}}>
                        <Image src={card.image} />
                        <CardHeader>
                            {card.heading}
                        </CardHeader>
                        <CardContent>
                            {card.content}
                        </CardContent>
                    </Card>
                )}
            </CardsWrapper>
            <Card style={{width:"300px",margin:"auto",marginTop:"36px"}}>
                <Image src={Skills4} />
                <CardHeader>
                    {"Employability"}
                </CardHeader>
                <CardContent>
                    {"Spotting opportunities, Creativity, Vision, Self-awareness, Self-efficacy, Mobilising others, Working with others, Mobilising resources, Taking the initiative, Valuing ideas"}
                </CardContent>
            </Card>
        </BoxWrapper>
    )
}
