import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import InternIcon from "../../assets/icons/intern.png";
import Salery from "../../assets/icons/salary.png";
import View from "../../assets/icons/view.png";
import { useDispatch, useSelector } from "react-redux";
import {  getlistJobs, updateJobs } from "../../services/apiCalls";
import { useNavigate } from "react-router-dom";
import { setId } from "../../reducers/jobReducer";
import  {
  EditToggleButton1,
} from "../../components/internIntoSection/editToggleButton";
import { useSnapshot } from "valtio";
import { jobProxy } from "./jobproxy";
import { Row, Col } from "react-bootstrap";
import Loader from "../../components/loader/loader";
import { useTranslation } from "react-i18next";
import { subsctipationsProxy } from "../Subscriptions/subscripationsProxy";
import InfiniteScroll from "react-infinite-scroll-component";
import { formatDate } from "../../utilities/commonFunctions";

const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: -10px 0 -10px 0;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";
`;

const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;


const FlexDiv = styled.div`
  display: flex;
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 10px 30px 0px 30px;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const JobTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  bottom: 30px;
`;

const PostedDate = styled.div`
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #d04c8d;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
  }
`;
const SmallText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
`;
const VacancyText = styled(SmallText)`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  letter-spacing: 1px;
  // margin-top:20px;

  > img {
    margin: 0 5px 0 30px;
    align-items: left;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0 0 0;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
const TimeText = styled(SmallText)`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
letter-spacing: 1px;
color: #525252;
@media (max-width:500px){
  font-size: 12px;
  line-height: 20px;
   }
@media (max-width: 767px) {
  display: flex;
  justify-content:start;
  }
}
`;

const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const ExpiryText = styled(DateText)`
  // color: #504f8c;
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 1px;
  color: #504f8c;
  position:relative;
  bottom:50px;
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    top:10px;
    right:20px;
    margin-bottom:10px;
  }
`;
const ContentWrapper = styled.div`
  padding: 5px;
`;
const DataWrapper = styled.div`
  display: flex;
  gap: 30px;
  padding: 5px;
  position: relative;
  bottom: 35px;
  @media (max-width: 500px) {
    bottom: 41px;
    margin: 0 0 -30px 0;
  }
`;
const ViewText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #b3b3b3;
  margin-bottom: -15px;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
  }
  > img {
    margin-right: 5px;
  }
`;
const AppText = styled.div`
  font-family: "General Sans";
  // font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #d04c8d;
  margin-left: 5px;
  margin-bottom: -15px;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 0 -20px;
  }
`;
const P = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #212121;
  margin-top: -30px;
  position: relative;
  bottom: 15px;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 -20px 0;
    text-transform: capitalize;
  }
`;
const FlexWrapper = styled(FlexDiv)`
  //position: relative;
  //left: 22%;
  margin: 0 0 -20px 0;
  // display:flex;
  // gap:100px;
  @media (max-width: 500px) {
    flex-direction: column;
    position: relative;
    left: -30px;
    margin: 0 0 -20px 0;
  }
`;

const ButtonDiv = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    left: 24%;
    margin-top: 60px;
  }
`;
const ActivateWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 135px;
  @media (max-width: 768px) {
    position: relative;
    bottom: 75px;
    left: 30px;
    font-size: 12px;
    line-height: 16px;
  }
`;

const JobsDataCards = ({list,index}) => {
    // console.log("dataUPdated",data)
  useSnapshot(jobProxy);
  useSnapshot(subsctipationsProxy)
  const [unAuth, setUnAuth] = useState(false)
  const [searchval, setSearchFilter] = useState("");
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const companies = useSelector((state) => state.company.companyData);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscriptionPlan = useSelector((state) => state.subscription.subscriptionData);
  const job_duration = subscriptionPlan[org_code]?.job_duration_day;
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [orgCode, setOrgCode] = useState(undefined);
//   const [jobdata, setJobdata] = useState(data);
  const [statusid, setStatusid] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  function viewapplicationgo(id) {
    jobProxy.setPaginationCount(1);
    jobProxy.setPaginationTabIndex(1);
    jobProxy.setdata([]);
    jobProxy.jobid = id;
    navigate("/jobs/Filterjobs");
  }

  function time_ago(d) {
    let date = new Date();

    var date1 = new Date(d);
    var date2 = new Date(
      date.getFullYear() +
        "-" +
        (parseInt(date.getMonth() + 1) >= 10
          ? parseInt(date.getMonth() + 1)
          : `0${parseInt(date.getMonth() + 1)}`) +
        "-" +
        (date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`)
    );
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days.toFixed(0);
  }

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }

  const view = (id) => {
    dispatch(setId(id));
    navigate("/jobs/viewjobs");
  };

  function formatToFullDate(thedate) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var b = thedate.split("-");
    return +b[2] + " " + monthNames[b[1] - 1] + " " + b[0];
  }
//   useEffect(() => {
//     getlist(`?page=${paginationTabIndex}&page_size=5`);
//   }, [orgCode]);
//   const fetchData = () => {
//     if (total > currentPage) {
//       setCurrentPage(currentPage + 1);
//       getlist("", currentPage + 1);
//     }
//   };
//   useEffect(() => {
//     getOrgCode();
//   }, [company]);


  
  const changestatus = async (l) => {
    if (!statusid || statusid.length === 0) {
      setStatusid(l.id);
      let la = l;
      if (la.activate) {
        if (la.activate === "Y") {
          la.activate = "N";
        } else {
          la.activate = "Y";
        }
      } else {
        la.activate = "Y";
      }
      const { dres } = await updateJobs(la);
    }
  };

  const postedby = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

 

  return (
    <div>
     

     

       

        {/* {loading ? (
          <Loader />
        ) : ( */}
          <>
           {/* <InfiniteScroll
              dataLength={jobdata?.length}
              next={fetchData}
              hasMore={currentPage < total}
              scrollThreshold={0.5}
            > */}
            {/* {jobdata && jobdata.length > 0 ? (
              jobdata.map((list, index) => ( */}
                <div key={index}>
                  <PageWrapper>
                    <ContentWrapper>
                        
                      <JobTitle
                        onClick={() => {
                          view(list.id);
                        }}
                      >
                        {list.title}
                      </JobTitle>
                      <PostedDate
                        onClick={() => {
                          view(list.id);
                        }}
                      >
                        {t("Posted")}:{formatToFullDate(list.created_at)}
                      </PostedDate>
                      <TimeText
                        onClick={() => {
                          view(list.id);
                        }}
                      >
                        {list.postago}
                      </TimeText>
                      
                    <ExpiryText>{t("Expires")}:{formatToFullDate(formatDate(new Date(list.job_expiry_day)))}</ExpiryText>
                    </ContentWrapper>

                    <Row>
                      <FlexWrapper
                        onClick={() => {
                          view(list.id);
                        }}
                      >
                        <Col xl={3}></Col>
                        <Col xl={3}>
                          <VacancyText>
                            <img src={InternIcon} alt="view" />
                            {t(list.employment_type)}
                          </VacancyText>
                        </Col>
                        <Col xl={6}>
                          <VacancyText>
                            <img src={Salery} alt="view" />
                            {list.salary_from}
                            {" MYR - "}
                            {list.salary_to}
                            {" MYR"}
                          </VacancyText>
                        </Col>
                      </FlexWrapper>
                    </Row>

                    <ActivateWrapper>
                      <EditToggleButton1
                        data={list}
                        setStatusid={setStatusid}
                        changestatus={changestatus}
                      />
                      
                    </ActivateWrapper>

                    <HrLine />

                    <DataWrapper>
                      <ViewText
                        onClick={() => {
                          view(list.id);
                        }}
                      >
                        <img src={View} alt="view" />
                        {list.view == null ? 0 : list.view}
                        {(list.view == null || list.view == '0') ? t(" view") : t(" views")}
                      </ViewText>
                      <AppText
                        onClick={() => {
                          viewapplicationgo(list.id);
                        }}
                      >
                        {list.application == null ? 0 : list.application}
                        {(list.application == null || list.application == '0') ? t(" Application") : t(" Applications")}
                      </AppText>
                    </DataWrapper>

                    <P>{t("Posted by")} : {postedby(list.posted_by)}</P>
                  </PageWrapper>
                </div>
          
            {/* </InfiniteScroll> */}
          </>
        {/* )} */}

       
    </div>
  );
};
export default JobsDataCards;
