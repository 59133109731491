import React, {  useState } from "react";
import styled from "styled-components";

import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./jobStyles.css";
import { makeStyles } from "@mui/styles";
import { Pagination } from "@mui/material";
import { useSnapshot } from "valtio";
import { jobProxy } from "../../pages/jobPortal/jobproxy";
import avatarLogo from "../../assets/avatar.png";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
const MainWrapper = styled.div`
  background: #ffffff;
  padding: 12px 24px 20px 24px;
  border-radius: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 12px auto;
  @media (max-width: 500px) {
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 280px;
    margin: 16px auto;
  }
`;
const Image = styled.img`
  width: 60px;
  height: 60px;
  border-radius: 50%;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MediumText = styled.h2`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const LinkText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #d04c8d;
  margin-top: 8px;
`;
const ButtonCard = styled.div`
  display: flex;
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 1px;
  color: #504f8c;
  margin-top: 20px;
  margin-right: 20px;
  @media (max-width: 500px) {
    margin-top: -24px;
  }
  @media (min-width: 501px) and (max-width: 1024px) {
    margin-right: 0px;
  }
`;

const PersonalityCard = styled.div`
  display: flex;
  @media (max-width: 500px) {
    margin-top: -36px;
  }
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));
export default function JobApplicants({ openPopUp }) {
  useSnapshot(jobProxy);
  const navigate = useNavigate();
  const data = jobProxy.data;
  
  const classes = useStyles();
  const id = useParams();
  const postedby = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  const profileNavigation = (id) => {
    jobProxy.userid = id;
    navigate(`/jobs/view-profile`);
  };
  const displayPage = (val) => {
    jobProxy.setPaginationTabIndex(val);

  };
  const { t, i18n } = useTranslation();
  return (
    <div style={{ marginTop: "48px" }}>
      
      <>
        {data.length && data.length > 0 ? (
          data.map((item, index) => (
            <MainWrapper>
              <div style={{ display: "flex" }}>
                <Image
                  src={item.profile_picture ? item.profile_picture : avatarLogo}
                  alt="profile-logo"
                />
                <ContentWrapper style={{ marginLeft: "12px" }}>
                  <MediumText>{postedby(item.name)}</MediumText>
                  <LinkText
                    onClick={() => {
                      profileNavigation(item.user_id);
                    }}
                    style={{ cursor: "pointer" }}
                  >
                    {t("View Profile")}
                  </LinkText>
                </ContentWrapper>
              </div>
              <PersonalityCard style={{ display: "flex" }}>
                <MediumText style={{ marginTop: "24px" }}>
                  {t("Personality Match")} :{" "}
                </MediumText>
                <CircularProgressbar
                  value={item.percentage}
                  text={`${item.percentage}%`}
                  styles={buildStyles({
                    textColor: "black",
                    pathColor: "#EE4BA0",
                  })}
                />
              </PersonalityCard>
              <ButtonCard>
                {item.status.toUpperCase() === "PENDING"
                  ? postedby(t("Applied"))
                  : postedby(t(item.status))}
              </ButtonCard>
            </MainWrapper>
          ))
        ) : (
          <p class="noDataFound">{t("No data found")}</p>
        )}
        {jobProxy.data.length > 0 ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Pagination
              style={{ textColor: "red" }}
              classes={{ ul: classes.ul }}
              count={jobProxy.paginationCount}
              page={jobProxy.paginationTabIndex} //{tabIndex}
              onChange={(event, value) => displayPage(value)}
            />
          </div>
        ) : (
          ""
        )}
      </>
    </div>
  );
}
