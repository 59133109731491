// import { getIntoActionDrilldown } from "../../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

export const getIntoActionDrilldown = () =>{
    const InfoAction = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Motivation and Perseverance": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 14,
                        "moderate": 481,
                        "high": 192
                    }
                },
                "Working with Others": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 27,
                        "moderate": 447,
                        "high": 213
                    }
                },
                "Planning and Management": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 17,
                        "moderate": 488,
                        "high": 182
                    }
                },
                "Learning Through Experience": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 25,
                        "moderate": 457,
                        "high": 205
                    }
                },
                "Coping with Uncertainty Ambiguity and Risk": {
                    "id": 5,
                    "totalTalents": 695,
                    "results": {
                        "low": 20,
                        "moderate": 463,
                        "high": 204
                    }
                },
                "Taking the Initiative": {
                    "id": 6,
                    "totalTalents": 695,
                    "results": {
                        "low": 24,
                        "moderate": 471,
                        "high": 192
                    }
                }
            }
        }
    }
    
    return InfoAction;
}

export default Ad2DrilldownHOC(getIntoActionDrilldown, [
    {
        title: "Motivation and Perseverance",
        dataKey: "Motivation and Perseverance",
        url: "motivation-and-perseverance",
        info:"Talents with high scores for this factor are motivated and work hard to achieve their goals. When facing challenges they will work even harder to ensure their goals are met. Being aware of their strengths enables them to make progress at work."
    },
    {
        title: "Working with Others",
        dataKey: "Working with Others",
        url: "working-with-others",
        info:"Talents with high scores for this factor are able to form teams and work with networks of people in order to meet the needs of a project. They are good team players and have confidence in their ability to work with diverse individuals and groups in order to achieve a common goal."
    },
    {
        title: "Planning and Management",
        dataKey: "Planning and Management",
        url: "planning-and-management",
        info:"Talents with high scores for this factor are able to plan projects, define priorities and give achievable milestones for progress. They can set clear goals at the beginning of a project, which they aim to achieve and are able to maximise the value of ideas they have encountered."
    },
    {
        title: "Learning Through Experience",
        dataKey: "Learning Through Experience",
        url: "learning-through-experience",
        info:"Talents with high scores for this factor are able to improve, reflect and identify areas of learning when they have completed a project. They find ways of improving themselves by reflecting on what they have learnt from projects. They learn from experience by reflecting on mistakes and successes after completing a project. They can also identify precise areas of new learning."
    },
    {
        title: "Coping with Uncertainty Ambiguity and Risk",
        dataKey: "Coping with Uncertainty Ambiguity and Risk",
        url: "coping-with-uncertainty-ambiguity-and-risk",
        info:"Talents with high scores for this factor are able to use their best judgment and the available information to make decisions, especially when the situation is ambiguous or uncertain. They are also adaptable when circumstances change and can change their plans in the course of completing their work. Alongside this, they have a clear idea of the goals and targets they aim to achieve."
    },
    {
        title: "Taking the Initiative",
        dataKey: "Taking the Initiative",
        url: "taking-the-initiative",
        info:"Talents with high scores for this factor are able to take responsibility in meeting challenges at work and in the past they have initiated action to make improvements in school or work. They have also used different types of resources effectively to achieve their goals to bring change."
    },
]);
