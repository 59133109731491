import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import logo from "../../assets/companylogosettings.png";
import InternIcon from "../../assets/icons/intern.png";
import Clock from "../../assets/icons/clock.png";

import Button from "../../components/button/button";
import Salery from "../../assets/icons/salary.png";
import Rectangle510 from "../../assets/Rectangle 510.png";
import Rectangle511 from "../../assets/Rectangle 511.png";
import Rectangle517 from "../../assets/Rectangle 517.png";
import Rectangle524 from "../../assets/Rectangle 524.png";
import Unions from "../../assets/Unions.png";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./viewjob.css";
import Rectangle521 from "../../assets/Rectangle 521.png";
import Rectangle545 from "../../assets/Rectangle 545.png";
import {

  getjobdocument,
  getJobs,
  getLocations,
  getScopeOfStudyMasterList,
  getSkills,

  updateJobs,
} from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { useSnapshot } from "valtio";
import { jobProxy } from "./jobproxy";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { companyProxy } from "../myCompany/companyproxy";
import { subsctipationsProxy } from "../Subscriptions/subscripationsProxy";
import JobPopup from "./jobPopUps";

const Logo = styled.img`
  width: 95px;
  height: 82px;
  @media (max-width: 500px) {
    width: 143px;
    height: 126px;
    margin: 10px 0 0 0;
  }
`;
const Wrapper = styled.div`
  margin-top: -5px;
  @media (max-width: 500px) {
    margin-top: 25px;
  }
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 3.5rem;
  margin: 0.5rem 0;

  @media (max-width: 500px) {
    padding: 2rem 2rem 2rem 2rem;
    margin: 0.5rem 0;
  }
`;
const Section = styled.div`
  display: flex;
  gap: 40px;
  @media (max-width: 500px) {
    gap: 0px;
  }
`;
const Designation = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 14px;
    margin: 0 0 0 10px;
  }
`;
const CompTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  letter-spacing: 1px;
  color: #d04c8d;
  @media (max-width: 500px) {
    font-size: 18px;
    margin: 0 0 0 10px;
  }
`;
const Address = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #525252;
  margin-left: -5px;
  @media (max-width: 500px) {
    font-size: 14px;
    margin: 0 0 0 10px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  right: 150px;
  top: 230px;
  @media (max-width: 500px) {
    position: absolute;
    right: 80px;
    top: 180px;
  }
  @media (min-width: 501px) and (max-width: 1023px) {
    position: absolute;
    right: 150px;
    top: 200px;
  }
`;
const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: center;
`;
const Edit = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  text-decoration-line: underline;
  color: #d04c8d;
  cursor: pointer;
  //position:relative;
  //left:200px;
  // @media (max-width:500px){
  //     left:-32%;
  // }
`;
const Delete = styled.div`
  font-family: "General Sans", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: right;
  text-decoration-line: underline;
  color: #d04c8d;
  position: relative;
  cursor: pointer;
  left: 40px;
  // @media (max-width:500px){
  //     left:-25%;
  // }
`;
const ParentApplicantWrapper = styled.div`
  @media (max-width: 500px) {
    position: relative;
    display: flex;
    justify-content: center;
  }
`;
const ApplicantWrapper = styled.div`
  display: flex;
  gap: 70px;
  justify-content: center;
  margin: 15px 0 0 0px;
  @media (max-width: 500px) {
    display: block;
    margin: 10px 0 0 0;
    align-items: center;
  }
`;
const FlexDiv = styled.div`
  display: flex;
`;
const FlexWrapper = styled(FlexDiv)`
  position: relative;
  width: fit-content;
  height: 53px;
  padding: 0 15px 0 0;
  background: #f8f8f8;
  border-radius: 54px;
  margin: 20px 50px 0 0px;
  @media (max-width: 500px) {
    margin: 10px 0 0 0;
    width: fit-content;
    justify-content: space-between;
  }
`;
const FlexWrapperWorking = styled(FlexDiv)`
  position: relative;
  height: 53px;
  width: auto;
  background: #f8f8f8;
  border-radius: 54px;
  margin: 20px 60px 0 0;
  @media (max-width: 500px) {
    margin: 10px 0 0 0px;
  }
`;

const VacancyText = styled(FlexDiv)`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  margin: 15px 5px 0px 45px;

  //position:relative;
  //left:55px;
`;
const Image = styled.img`
  width: 28px;
  height: 28px;
  position: absolute;
  bottom: 13px;
  left: 15px;
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  //bottom:30px;
`;
const AppText = styled.div`
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #b3b3b3;
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #202020;
  margin: 30px 0 0 0;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin: 7px 0 20px 0;
  @media (max-width: 500px) {
    margin: 7px 0 10px 0;
  }
`;
const SkillsWrapper = styled(FlexDiv)`
  background: #f8f8f8;
  border-radius: 10px;
  height: 38px;
  width: fit-content;
  margin: 10px 0 10px 0;
`;
const SkillsContent = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  color: #989898;

  padding: 10px;
`;
const DocumentWrapper = styled.div`
  background: #fcfcfc;
  border-radius: 10px;
  padding: 20px;
  margin: 10px 0 20px 0;
`;
const DocumentTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const ViewJob = () => {
  useSnapshot(jobProxy);
  useSnapshot(companyProxy);
  useSnapshot(subsctipationsProxy);
  useEffect(async () => {
    if (companyProxy.selectcompany) {
      if (companyProxy.selectcompany.org_code) {
        await subsctipationsProxy.setcount(companyProxy.selectcompany.org_code);
      }
    }
  }, [companyProxy.selectcompany]);
  useEffect(() => {
    console.log(subsctipationsProxy.plan);
  }, [subsctipationsProxy.plan]);
  const { t } = useTranslation();
  const job = useSelector((state) => state.job_reducer.id);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [jobdata, setJobdata] = useState({});
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscriptionPlan = useSelector((state) => state.subscription.subscriptionData);
  const job_duration = subscriptionPlan[org_code]?.job_duration_day;
  const [companyid, setCompanyid] = useState("");
  const [scopeOfStudies, setScopeOfStudies] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);
  const [document, setDocument] = useState([]);
  const [skills, setSkills] = useState([]);
  const [locations, setLocations] = useState([]);
  const [checkDelete, setCheckDelete] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const edit = () => {
    navigate("/jobs/editJob");
  };
  function viewapplicationgo(id) {
    jobProxy.setPaginationCount(1);
    jobProxy.setPaginationTabIndex(1);
    jobProxy.setdata([]);
    jobProxy.jobid = id;
    navigate("/jobs/Filterjobs");
  }
  const getscope = async () => {
    if (scopeOfStudies.length === 0) {
      const { data } = await getScopeOfStudyMasterList();
      if (data.status === 1) {
        const scopeOfStudydata = data.data.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setScopeOfStudies(scopeOfStudydata);
      }
    }
  };

  const getSkill = async (id) => {
    if (skills.length === 0) {
      const { data } = await getSkills(id);
      if (data && data.status === 1) {
        if (data.data && data.data.length > 0) {
          setSkills(data.data);
        }
      }
    }
  };

  const getlocation = async (id) => {
    if (locations.length === 0) {
      const data1 = await getLocations(id);
      if (data1.status === 200) {
        if (data1.data && data1.data.data.length > 0) {
          setLocations(data1.data.data);
        }
      }
    }
  };

  const getJob1 = async (id) => {
    if (document.length == 0) {
      const { data } = await getjobdocument(id);
      if (data.status == 1) {
        setDocument(data["data"]);
      }
    }
  };

  const update1 = async () => {
    let l = jobdata;
    l.status = "deleted";
    const { dres } = await updateJobs(l);
    toast.success(t("Job Deleted Successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    navigate("/jobs");
  };

  const del = async () => {
    setCheckDelete(true);
  };

  const getJobdata = async (id) => {
    if (!jobdata.id || jobdata.id.length === 0) {
      await getscope();

      if (JSON.stringify(jobdata) !== JSON.stringify({ id: id })) {
        setJobdata({ id: id });
      }
      if (jobdata.title === undefined) {
        const { data } = await getJobs(id);
        setJobdata(data["data"][0]);
        await getJob1(id);
        await getSkill(id);
        await getlocation(id);
        if (
          (jobProxy.jobdata &&
            JSON.stringify(jobProxy.jobdata) !==
              JSON.stringify(data["data"][0])) ||
          jobProxy.jobdata === null
        ) {
          jobProxy.jobdata = data["data"][0];
        }
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  function time_ago(d) {
    let date = new Date();

    var date1 = new Date(d);
    var date2 = new Date(
      date.getFullYear() +
        "-" +
        (parseInt(date.getMonth() + 1) >= 10
          ? parseInt(date.getMonth() + 1)
          : `0${parseInt(date.getMonth() + 1)}`) +
        "-" +
        (date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`)
    );
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days.toFixed(0);
  };

  const lmh = (v) => {
    switch (v) {
      case "low":
        return (
          <>
            <div className="lowdiv">
              <img src={Unions} alt="igtt" />
              <span style={{ position: "relative", right: "50px", top: "2px" }}>
                Low
              </span>
            </div>
            <div className="highgap">
              <img src={Rectangle510} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
            </div>
          </>
        );
      case "medium_low":
        return (
          <>
            <div className="mediumlowdiv">
              <img src={Unions} alt="igtt" className="medium-low" />
              <span className="medium-low-span">Medium Low</span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle521} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
            </div>
          </>
        );
      case "medium":
        return (
          <>
            <div className="mediumdiv">
              <img src={Unions} alt="igtt" className="medium" />
              <span
                className="medium-span"
              >
                Medium
              </span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle517} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
            </div>
          </>
        );
      case "medium_high":
        return (
          <>
            <div className="mediumhighdiv">
              <img src={Unions} alt="igtt" className="medium-high" />
              <span className="medium-high-span">Medium High</span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle545} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
            </div>
          </>
        );
      case "high":
        return (
          <>
            <div className="highdiv">
              <img src={Unions} alt="igtt" className="high" />
              <span
                className="high-span"
              >
                High
              </span>
            </div>
            <div className="highgap">
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle511} alt="igtt" className="card-width" />
              <img src={Rectangle524} alt="igtt" className="card-width" />
            </div>
          </>
        );
      default:
    }
  };
  const ch_low_high = (v) => {
    if (v == "N") {
      return "low";
    }
    if (v == "Y") {
      return "high";
    }
    return v;
  };

  useEffect(async () => {
    await getscope();
    if (job && (!jobdata.id || jobdata.length === 0)) {
      getJobdata(job);
    }
  }, []);

  useEffect(async () => {
    console.clear();
    if (
      jobdata.scope_of_study &&
      jobdata.length !== 0 &&
      scopeOfStudies.length > 0 &&
      selectedScope.length === 0
    ) {
      let scopevalue = [];

      let datascope = jobdata.scope_of_study.split(",");

      for (let i = 0; i < scopeOfStudies.length; i++) {
        for (let j = 0; j < datascope.length; j++) {
          if (scopeOfStudies[i].value === Number(datascope[j])) {
            scopevalue.push(scopeOfStudies[i]);
          }
        }
      }
      if (scopevalue.length > 0) {
        setSelectedScope(scopevalue);
      }
    }
  }, [scopeOfStudies, jobdata]);

  useEffect(() => {
    if (!companyid || companyid.length === 0) {
      if (company && company.id) {
        setCompanyid(company.id);
      }
    } else {
      if (companyid !== company.id) {
        navigate("/jobs");
      }
    }
  }, [company]);
  

  return (
    <>
      <JobPopup
        isOpen={checkDelete}
        setOpen={setCheckDelete}
        proceed={update1}
      />

      <div>
        <MainLayout>
          <div className="breadcrumb">
            <p onClick={() => navigate("/jobs")} style={{ cursor: "pointer" }}>
              {t("Jobs")}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>{t("View Job")}</p>
          </div>
          {loading ? (
            <Loader />
          ) : (
            <PageWrapper>
              <Section>
                <Logo src={company.logo_path} alt="logo"></Logo>

                <Wrapper>
                  <Designation>{jobdata.title}</Designation>
                  <CompTitle>{company.name}</CompTitle>
                  <Address>
                    📍{company.bsn_address}, {company.bsn_city_abbr},{" "}
                    {company.bsn_city} {company.bsn_country_abbr}
                  </Address>
                </Wrapper>
                <ButtonWrapper>
                  {time_ago(jobdata.job_expiry_day) < Number(job_duration) && 
                    <Edit onClick={edit}>{t("Edit")}</Edit>
                  }
                  <Delete onClick={del}>{t("Delete")}</Delete>
                </ButtonWrapper>
              </Section>

              <ParentApplicantWrapper>
                <ApplicantWrapper>
                  <Row>
                    <FlexWrapper>
                      <Image src={InternIcon} alt="view"></Image>
                      <VacancyText>{t(jobdata.employment_type)}</VacancyText>
                    </FlexWrapper>
                    <FlexWrapper>
                      <Image src={Salery} alt="view"></Image>
                      <VacancyText>
                        {jobdata.salary_from}
                        {" MYR - "}
                        {jobdata.salary_to}
                        {" MYR"}
                      </VacancyText>
                    </FlexWrapper>
                  </Row>
                </ApplicantWrapper>
              </ParentApplicantWrapper>
              <ButtonWrapper2>
                <Button
                  style={{ cursor: "pointer" }}
                  name={t("View Applicants")}
                  onClick={() => {
                    viewapplicationgo(jobdata.id);
                  }}
                />
              </ButtonWrapper2>
              <AppText style={{ display: "flex", justifyContent: "center" }}>
                {jobdata.application} {t("Applicants")}
              </AppText>
              <HrLine />
              <Title>{t("Job Description")}</Title>
              <Content>
                <div
                  dangerouslySetInnerHTML={{ __html: jobdata.description }}
                />
              </Content>
              <Title>{t("Responsibilities*")}</Title>
              <Content>
                <div
                  dangerouslySetInnerHTML={{ __html: jobdata.responsibilities }}
                />
              </Content>
              <Title>{t("Skills")}</Title>
              <div style={{ display: "flex", gap: "20px" }}>
                {skills?.map((e) => {
                  return (
                    <SkillsWrapper>
                      <SkillsContent>{e?.title}</SkillsContent>
                    </SkillsWrapper>
                  );
                })}
              </div>

              <Title>{t("Location")}</Title>
              <Content>
                {locations?.map((e) => {
                  return (
                    <Content style={{ margin: "0 0 0 0" }}>
                      {e.city_abbr}, {e.state_abbr}, {e.country_abbr}
                    </Content>
                  );
                })}
              </Content>
              <Title>{t("Nationality")}</Title>
              <Content>{jobdata.nationality}</Content>
              <Title>{t("Scope of Study")}*</Title>
              <div style={{ display: "flex", gap: "20px" }}>
                {selectedScope?.map((e) => {
                  return (
                    <SkillsWrapper>
                      <SkillsContent>{e?.Name}</SkillsContent>
                    </SkillsWrapper>
                  );
                })}
              </div>

              <Title>{t("Probation Period")}</Title>
              {jobdata.probation_period == "Not Applicable" ? (
                <Content>{t("Not Applicable")}</Content>
              ) : (
                <Content>{jobdata.probation_period} {t("Months")}</Content>
              )}

              <Title>{t("Working Arrangement*")}</Title>
              <Content>
                <div
                  dangerouslySetInnerHTML={{
                    __html: jobdata.working_arrangement,
                  }}
                />
              </Content>
              <Title>{t("Benefits*")}</Title>
              <Content>
                <div dangerouslySetInnerHTML={{ __html: jobdata.benefits }} />
              </Content>

              <Title>{t("Required Documents")}</Title>
              <DocumentWrapper>
                <DocumentTitle>
                  <table
                    style={{ background: "#FCFCFC", borderRadius: "33px" }}
                  >
                    <thead>
                      <tr>
                        <th>{t("Document")}</th>
                        <th>{t("Mandatory")}</th>
                      </tr>
                    </thead>
                  </table>
                  {document.map((list, index) => (
                    <div key={index} style={{ fontWeight: "500" }}>
                      <hr />
                      <tr>
                        <td>{list.document_type}</td>
                        {list.mandatory === "Y" ? (
                          <td style={{ position: "absolute", right: "36.5%" }}>
                            {t("Yes")}
                          </td>
                        ) : (
                          <td style={{ position: "absolute", right: "36.5%" }}>
                            {t("No")}
                          </td>
                        )}
                      </tr>
                    </div>
                  ))}
                </DocumentTitle>
              </DocumentWrapper>

              
                <Title>{t("Work Competency Factors")}</Title>
                {jobdata.research_evaluate == 0.6 &&

                  <SkillsWrapper>
                    <SkillsContent>{t("Evaluating & Resolving")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.inception_formation == 0.6 &&

                  <SkillsWrapper>
                    <SkillsContent>{t("Innovating & Envisioning")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.communication_presentation == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Connecting & Portraying")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.leadership == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Supervising & Determining")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.teamwork == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Facilitating & Collaborating")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.collectedness == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Embracing & Managing")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.plan_execte == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Coordinating & Implementing")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.inventive_accomplishment == 0.6 &&
                  <SkillsWrapper>
                    <SkillsContent>{t("Ambitious & Result-Oriented")}</SkillsContent>
                  </SkillsWrapper>
                }
                {jobdata.research_evaluate == false &&
                  jobdata.inception_formation == false &&
                  jobdata.communication_presentation == false &&
                  jobdata.leadership == false &&
                  jobdata.teamwork == false &&
                  jobdata.collectedness == false &&
                  jobdata.plan_execte == false &&
                  jobdata.inventive_accomplishment == false &&
                  <Row>
                    <Col xlg={3} >
                      <Content style={{ marginTop: "15px" }}>
                        {t("None")}
                      </Content>
                    </Col>
                  </Row>
                }

            </PageWrapper>
          )}
        </MainLayout>
      </div>
    </>
  );
};
export default ViewJob;
