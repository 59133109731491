import { styled } from "@mui/material/styles";
import "./CompaniesTitle.css"
import Switch from "@mui/material/Switch";
import { Image } from "react-bootstrap";
import Button from "../../components/button/button";
import { useSelector, useDispatch } from "react-redux";
import { userCompanyInvite, getCompanies } from "../../services/apiCalls";
import { updateCompanyData } from "../../pages/myCompany/reducer";
import { useTranslation } from "react-i18next";

const IOSSwitch = styled((props) => (
  <Switch
    focusVisibleClassName=".Mui-focusVisible"
    disableRipple
    {...props}
    onClick={() => console.log("toggled")}
  />
))(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  "& .MuiSwitch-switchBase": {
    padding: 0,
    margin: 2,
    transitionDuration: "300ms",
    "&.Mui-checked": {
      transform: "translateX(16px)",
      color: "#fff",
      "& + .MuiSwitch-track": {
        backgroundColor: theme.palette.mode === "dark" ? " #D04C8D" : "#D04C8D",
        opacity: 1,
        border: 0,
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: 0.5,
      },
    },
    "&.Mui-focusVisible .MuiSwitch-thumb": {
      color: "#33cf4d",
      border: "6px solid #fff",
    },
    "&.Mui-disabled .MuiSwitch-thumb": {
      color:
        theme.palette.mode === "light"
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    "&.Mui-disabled + .MuiSwitch-track": {
      opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
    },
  },
  "& .MuiSwitch-thumb": {
    boxSizing: "border-box",
    width: 22,
    height: 22,
  },
  "& .MuiSwitch-track": {
    borderRadius: 26 / 2,
    backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
    opacity: 1,
    transition: theme.transitions.create(["background-color"], {
      duration: 500,
    }),
  },
}));

function CompaniesCardTitle({
  title,
  navigation,
  isActivate,
  onToggle,
  status,
  position_end_date,
  user_type,
  logo,
  invite,
  keyIdx,
  userCount
}) {
  const companies = useSelector((state) => state.company.companyData);
  const userStatus = companies[keyIdx].user_details.status
  const isInvited = companies[keyIdx].user_details.status === 'pending'
  const isAdmin = companies[keyIdx].user_details.is_admin == "Y"
  const id = companies[keyIdx].user_details.id
  const dispatch = useDispatch();

  function handleInviteResponse(response){
    updateInviteResponse(response)
  }

  const updateInviteResponse = async (response) => {
      const { data } = await userCompanyInvite(companies[keyIdx].org_code, { id: id, status:response, do_not_trigger_email:true });
      if (data && data.status === 1) {
        getOrganisationInfo()
      }
  };

  const getOrganisationInfo = async () => {
    const { data } = await getCompanies();
    if (data && data.status === 1) {
        dispatch(updateCompanyData({ ...data.data }))
    } 
  };

  const { t, i18n } = useTranslation();
  return (
    <div className="ParentWrapper row">
      <div className="dataWrapper1 col-lg-7">
        <div className="CompanyIconSection">
          <Image src={logo} style={{ width: "35px", height: "33px" }} />
        </div>
        <div className="jobTitle" onClick={navigation}>
          {title}
        </div>
        {user_type!== undefined && !isInvited && userStatus!== 'rejected'?
          <div className='statusWrapper'>
            {user_type}
          </div> :
          <div className="inviteWrappers">
            {t("Invite")}
          </div>
        }

      </div>
      {isInvited ?
        <div className="dataWrapper col-lg-5">
          <div className="inviteWrapper">
            {t("Invite")}
          </div>
          <div className="buttonWrappers">
            <Button name={t("Accept")} onClick={() => handleInviteResponse("accepted")}/>
            <Button name={t("Reject")} secondary={true} onClick={() => handleInviteResponse("rejected")}/>
          </div>
          
        </div> :<>
        {status == "rejected" ? 
          <div className="denied col-lg-5">{t("DENIED")}</div> : //if TC Admin rejects company
          (isAdmin == true ) ? 
          <div className="accepted col-lg-5">{t(status.toUpperCase())}</div> : //if is company admin, see company status
          userStatus == "accepted" ? 
          <div className="accepted col-lg-5">{t("ACCEPTED INVITE")}</div> : //else, see user status
          <div className="denied col-lg-5">{t("REJECTED INVITE")}</div>}
        </>
      }

    </div>
  );
}

export default CompaniesCardTitle;