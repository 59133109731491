import React from "react";

import "./options.css";
import { t } from "i18next";

const Options = (props) => {
  const options = [
    {
      text: "I hired my intern outside of mynext. Can I still get the double tax deduction?",
      key: "tax deduction outside mynext", //should be key in messagesData
      handler: props.actionProvider.handleDisplayMessage,
      id: 1,
    },
    {
      text: "My interns have almost/already completed their internship. When will I receive the evaluation links for the Letter of Endorsement?",
      key: "evaluation link letter of endorsement LOE",
      handler: props.actionProvider.handleDisplayMessage,
      id: 2
    },
    {
      text: "How much do I have to pay for my posting to be published onto the mynext platform?",
      key: "price pricing",
      handler: props.actionProvider.handleDisplayMessage,
      id: 3
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button key={option.id} onClick={() => option.handler(option.key)} className="option-button">
      {t(option.text)}
    </button>
  ));

  return <div className="options-container">{buttonsMarkup}</div>;
};

export default Options;