import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";



export const completionFields = {
  internship_module_id: "",
  title: "",
  description: "",
  intern_type: "General Internship",
  exp_start_date: "",
  exp_end_date: "",
  no_positions: "1",
  req_gpa: "",
  min_degree: "",
  working_hours: "",
  max_compensation: "",
  min_compensation: "",
  country: "",
  state: "",
  city: "",
  grade: "",
  file_paths: [],
  skills: [],
  soft_skills:[],
  scopes: [],
  documents: [],
  payment_status: "",
  locations: [],
  position_start_date: null,
  position_end_date: null,
  contact_name: "",
  contact_email: "",
  contact_person: "",
  direct_hiring_type: "",
  contact_mob_code: "",
  contact_mob: "",
  visibility_status: 0,
  collection_type: null,
  collection_radio_type: "",
  collection_date: "",
  re_email_address: "",
  optional_documents: "",
  application_instruction: "",
  external_application_link: "",
  internship_id: "",
  file_paths: [],
  attendance_records:[],
  actual_allowance_per_intern:null,
  is_external_training_given:null,
  is_internal_training_given:null,
  total_allowance:"",
  expenditure_records:[],
  is_acknowledged:1,
  documents: [],
  additionalAllowance:[],
  learningOutcome:[],
  typeOfTrainings:[],
  min_allowance_per_intern:"",
  // external_training: [
  //   {
      external_training_course_title: '',
      external_training_provider: '',
      external_training_duration: null,
      is_external_training_provider_certified_by_the_hrdcorp: null,
  //   },
  // ],
  // internal_training: [
  //   {
      internal_training_course_title: '',
      internal_training_provider: '',
      internal_training_duration: null,
  //   },
  // ],
  proof_of_training:[]
};

export const schema = yup.object().shape({
  title: yup.string().required("Internship title is required"),
  total_allowance:yup.string().required("Total allowance is required"),
  allowance: yup.string().required("Internship allowance is required"),
  educationLevel: yup.string().required("Minimum education level required"),
  scopeOfStudy: yup.string().required("Scope of study is required"),
  minimumYearOfStudy: yup.string().required("Minimum year of study required"),
  description: yup.string().required("Internship description is required"),
  intern_type: yup.string().required(),

  exp_start_date: yup.string().required("Internship start date is required"),
  exp_end_date: yup.string().required("Internship end date is required"),
  no_positions: yup
    .string()
    .required("No. of positions available is required")
    .test(
      "no_positions",
      "Minimum position should be greater than 0",
      (val) => val === undefined || val === null || val > 0
    )
    .test(
      "no_positions",
      "No. of positions cannot exceed 10000",
      (val) => val < 10000
    ),
  req_gpa: yup.string().required("Minimum CGPA is required"),
  min_degree: yup.string().required("Minimum education level is required"),
  working_hours: yup.string().required("Working Hours is required"),
  contact_person: yup.string().required("Contact is required."),

  // min_compensation: yup
  //   .string()
  //   .max(7, "Minimum Commission should not be more than 7 digits")
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree === "Bachelor's Degree" ||
  //         min_degree === "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Bachelor's Degree and above is RM600",
  //         (val) => val >= 600
  //       ),
  //   })
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree !== "Bachelor's Degree" ||
  //         min_degree !== "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Diploma and below is RM500",
  //         (val) => val >= 500
  //       ),
  //   })
  //   .when("payment_status", {
  //     is: "paid",
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "Minimum Compensation cannot be 0",
  //         (val) => val >= 1
  //       ),
  //   }),
  min_compensation: yup
  .string()
  .required('Minimum compensation is required')
  .when('intern_type', {
    is: 'Direct Hiring',
    then: yup.string().test('is-greater-than-500', 'Min compensation should be more than 500', (value) => {
      return Number(value) > 500;
    }),
  })
  .test({
    name: "min_compensation",
    message: "Min compensation should be higher than monthly allowance provided in eligibility check",
    test: function (value) {
      const { monthly_allowance_1, monthly_allowance_2 } = this.options.context;

      // Check if min_compensation is lower than monthly_allowance_1 or monthly_allowance_2
      if (
        Number(value) < monthly_allowance_1 ||
        Number(value) < monthly_allowance_2
      ) {
        return false;
      }

      return true;
    },
  })
  ,
  max_compensation: yup
    .string()
    .required("Max compensation is required")
    .max(7, "Maximum Commission should not be more than 7 digits")
    .when("min_compensation", (min_compensation, schema) => {
      return schema.when("payment_status", {
        is: "paid",
        then: yup
          .string()
          .required()

          .test({
            test: (max_compensation) =>
              Number(max_compensation) > Number(min_compensation),
            message: "Value should be greater than minimum allowance",
          }),
      });
    }),
    // .when("payment_status", {
    //   is: "paid",
    //   then: yup.string().required("Maximum allowance is required"),
    // }),
  position_start_date: yup.string().required("Post start date is required"),
  position_end_date: yup.string().required("Post expiration date is required"),
  re_email_address: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup
      .string()
      .required("E-mail is required.")
      .matches(/^([\w+-._]+@[\w-.]+\.[A-Za-z0-9]{2,10},?)+$/, "Invalid Email"),
    // .email("Invalid email")
  }),
  application_instruction: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("Application instruction is required."),
  }),
  external_application_link: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("External application link is required."),
  }),

  collection_radio_type: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup.string().required(),
  }),
  collection_date: yup.string().when("collection_radio_type", {
    is: "Receive appplications in a single package on a given date",
    then: yup.string().required("Date is Required"),
  }),
 
  grade: yup.string().required("Year of Study is required"),

  documents: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select atleast one required document",
      test: (arr) => arr.length > 0,
    }),

    // additionalAllowance: yup
    // .array(
    //   yup.object({
    //     additionalAllowance: yup.string().required(),
    //   })
    // )
    // .test({
    //   message: "Please select atleast one additional allowance",
    //   test: (arr) => arr.length > 0,
    // }),

    learningOutcome: yup
    .array(
      yup.object({
        allowance_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select atleast one learning outcome",
      test: (arr) => arr.length > 0,
    }),

    typeOfTrainings: yup
    .array(
      yup.object({
        allowance_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select at least one type of training provided",
      test: (arr) => arr.length > 0,
    }),

  skills: yup
    .array(
      yup.object({
        skill: yup.string(),
      })
    )
    .when("intern_type", (intern_type, schema) => {
      return schema.test({
        test: (arr) => {
          if (intern_type === "General Internship") {
            return true;
          } else if (intern_type === "sip" && arr.length === 0) {
            return false;
          } else {
            return true;
          }
        },
        message: `For MySIP add at least one skill`,
      });
    }),
  file_paths: yup.array(
    yup.object({
      file_path: yup.string(),
    })
  ),
  
  attendance_records:  yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please upload at least 1 attendance record"),

  expenditure_records:  yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please uplaod at least 1 expenditure record"),
  proof_of_training: yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please uplaod at least 1 proof of training"),
  scopes: yup
    .array(
      yup.object({
        scope: yup.string().required(),
      })
    )
    .test({
      message: "Scope of study is required",
      test: (arr) => arr.length > 0,
    }),
  locations: yup
    .array(
      yup.object({
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
      })
    )
    .test({
      message: "Please add any one location.",
      test: (arr) => arr.length > 0,
    }),
    external_training: yup.array().of(
      yup.object().shape({
        external_training_course_title: yup.string().required(),
        external_training_provider: yup.string().required(),
        external_training_duration: yup.string().required(),
      })
    )
    .min(1, 'Please fill out all the details for external training'),
    //internal_training_course_title: yup.string().required('Course title is required'),
    //internal_training_provider: yup.string().required('Training provider is required'),
    internal_training_duration: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
    //external_training_course_title: yup.string().required('Course title is required'),
    //external_training_provider: yup.string().required('Training provider is required'),
    external_training_duration: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
    actual_allowance_per_intern: yup.string().required("Actual allowance is a required field")
});

export const schema2 = yup.object().shape({
  title: yup.string().required("Internship title is required"),
  total_allowance:yup.string().required("Total allowance is required"),
  allowance: yup.string().required("Internship allowance is required"),
  educationLevel: yup.string().required("Minimum education level required"),
  scopeOfStudy: yup.string().required("Scope of study is required"),
  minimumYearOfStudy: yup.string().required("Minimum year of study required"),
  description: yup.string().required("Internship description is required"),
  intern_type: yup.string().required(),

  exp_start_date: yup.string().required("Internship start date is required"),
  exp_end_date: yup.string().required("Internship end date is required"),
  no_positions: yup
    .string()
    .required("No. of positions available is required")
    .test(
      "no_positions",
      "Minimum position should be greater than 0",
      (val) => val === undefined || val === null || val > 0
    )
    .test(
      "no_positions",
      "No. of positions cannot exceed 10000",
      (val) => val < 10000
    ),
  req_gpa: yup.string().required("Minimum CGPA is required"),
  min_degree: yup.string().required("Minimum education level is required"),
  working_hours: yup.string().required("Working Hours is required"),
  contact_person: yup.string().required("Contact is required."),

  // min_compensation: yup
  //   .string()
  //   .max(7, "Minimum Commission should not be more than 7 digits")
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree === "Bachelor's Degree" ||
  //         min_degree === "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Bachelor's Degree and above is RM600",
  //         (val) => val >= 600
  //       ),
  //   })
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree !== "Bachelor's Degree" ||
  //         min_degree !== "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Diploma and below is RM500",
  //         (val) => val >= 500
  //       ),
  //   })
  //   .when("payment_status", {
  //     is: "paid",
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "Minimum Compensation cannot be 0",
  //         (val) => val >= 1
  //       ),
  //   }),
  // min_compensation: yup
  // .string()
  // .required('Minimum compensation is required')
  // .when('intern_type', {
  //   is: 'Direct Hiring',
  //   then: yup.string().test('is-greater-than-500', 'Min compensation should be more than 500', (value) => {
  //     return Number(value) > 500;
  //   }),
  // })
  // .test({
  //   name: "min_compensation",
  //   message: "Min compensation should be higher than monthly allowance provided in eligibility check",
  //   test: function (value) {
  //     const { monthly_allowance_1, monthly_allowance_2 } = this.options.context;

  //     // Check if min_compensation is lower than monthly_allowance_1 or monthly_allowance_2
  //     if (
  //       Number(value) < monthly_allowance_1 ||
  //       Number(value) < monthly_allowance_2
  //     ) {
  //       return false;
  //     }

  //     return true;
  //   },
  // })
  // ,
  // max_compensation: yup
  //   .string()
  //   .required("Max compensation is required")
  //   .max(7, "Maximum Commission should not be more than 7 digits")
  //   .when("min_compensation", (min_compensation, schema) => {
  //     return schema.when("payment_status", {
  //       is: "paid",
  //       then: yup
  //         .string()
  //         .required()

  //         .test({
  //           test: (max_compensation) =>
  //             Number(max_compensation) > Number(min_compensation),
  //           message: "Value should be greater than minimum allowance",
  //         }),
  //     });
  //   })
  //   .when("payment_status", {
  //     is: "paid",
  //     then: yup.string().required("Maximum allowance is required"),
  //   }),
  position_start_date: yup.string().required("Post start date is required"),
  position_end_date: yup.string().required("Post expiration date is required"),
  re_email_address: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup
      .string()
      .required("E-mail is required.")
      .matches(/^([\w+-._]+@[\w-.]+\.[A-Za-z0-9]{2,10},?)+$/, "Invalid Email"),
    // .email("Invalid email")
  }),
  application_instruction: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("Application instruction is required."),
  }),
  external_application_link: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("External application link is required."),
  }),

  collection_radio_type: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup.string().required(),
  }),
  collection_date: yup.string().when("collection_radio_type", {
    is: "Receive appplications in a single package on a given date",
    then: yup.string().required("Date is Required"),
  }),
 
  grade: yup.string().required("Year of Study is required"),

  documents: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select atleast one required document",
      test: (arr) => arr.length > 0,
    }),

    // additionalAllowance: yup
    // .array(
    //   yup.object({
    //     additionalAllowance: yup.string().required(),
    //   })
    // )
    // .test({
    //   message: "Please select atleast one additional allowance",
    //   test: (arr) => arr.length > 0,
    // }),

    learningOutcome: yup
    .array(
      yup.object({
        allowance_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select atleast one learning outcome",
      test: (arr) => arr.length > 0,
    }),

    typeOfTrainings: yup
    .array(
      yup.object({
        allowance_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select at least one type of training provided",
      test: (arr) => arr.length > 0,
    }),

  skills: yup
    .array(
      yup.object({
        skill: yup.string(),
      })
    )
    .when("intern_type", (intern_type, schema) => {
      return schema.test({
        test: (arr) => {
          if (intern_type === "General Internship") {
            return true;
          } else if (intern_type === "sip" && arr.length === 0) {
            return false;
          } else {
            return true;
          }
        },
        message: `For MySIP add at least one skill`,
      });
    }),
  file_paths: yup.array(
    yup.object({
      file_path: yup.string(),
    })
  ),
  
  attendance_records:  yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please upload at least 1 attendance record"),

  expenditure_records:  yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please uplaod at least 1 expenditure record"),
  proof_of_training: yup
  .array()
  .of(
    yup.object({
      file_path: yup.string(),
    })
  )
  .min(1, "Please uplaod at least 1 proof of training"),
  scopes: yup
    .array(
      yup.object({
        scope: yup.string().required(),
      })
    )
    .test({
      message: "Scope of study is required",
      test: (arr) => arr.length > 0,
    }),
  locations: yup
    .array(
      yup.object({
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
      })
    )
    .test({
      message: "Please add any one location.",
      test: (arr) => arr.length > 0,
    }),
    external_training: yup.array().of(
      yup.object().shape({
        external_training_course_title: yup.string().required(),
        external_training_provider: yup.string().required(),
        external_training_duration: yup.string().required(),
      })
    )
    .min(1, 'Please fill out all the details for external training'),
    //internal_training_course_title: yup.string().required('Course title is required'),
    //internal_training_provider: yup.string().required('Training provider is required'),
    internal_training_duration: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
   // external_training_course_title: yup.string().required('Course title is required'),
    //external_training_provider: yup.string().required('Training provider is required'),
    external_training_duration: yup.number()
    .integer('Duration must be an integer')
    .typeError('Duration must be a number')
    .required('Duration is required'),
    actual_allowance_per_intern: yup.string().required("Actual allowance is a required field")
});
