import React, { useState } from 'react'
import styled from 'styled-components'
import { staticData } from './staticData'
import {
    SubHeading, HeadingCard,
    ContentCard,
    CardWrapper, ContentHeading, Range,
    ColorBar,
    SectionHeading,
    NormalText,
    SectionWrapper,
    SpanText,
    Wrapper
} from './common'
import "./applicantsComparision.css"
const Content = styled.div`
  display:grid;
  grid-template-columns 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    overflow-x:scroll;
   }
   @media (min-width:501px) and (max-width: 1440px) {
     display:inline-block;
     overflow-x:scroll;
   }
`
export default function OceanReport() {
    const [data, setData] = useState([0])
    return (
        <div>
            <SectionWrapper>
                <hr />
                <SectionHeading style={{ width: "220px" }}> {staticData.oceanReportForProfile.heading}</SectionHeading>
                <Content>
                    {staticData.oceanReportForProfile.content.map((content, idx) =>
                        <CardWrapper>
                            <ContentCard noBg>
                                <ContentHeading>{""}</ContentHeading>
                                <HeadingCard>
                                    {content.subHeading.map((e) =>
                                        <SubHeading>{e}</SubHeading>
                                    )}
                                </HeadingCard>
                            </ContentCard>
                            {data.map((x, i) =>
                                <ContentCard>
                                    <ContentHeading>{content.heading}</ContentHeading>
                                    {content.subHeading.map((e, y) =>
                                        <SubHeading>
                                            {content.type === "range"
                                                ?
                                                <input style={{ margin: "auto" }} type="range" value={Math.random() * 100} />
                                                :
                                                (<div style={{ display: "flex" }}>
                                                    <ColorBar bg={y % 2 === 0 ? "#FF4D4D" : false} />
                                                    <ColorBar bg={y % 3 === 0 ? "#FFB422" : false} />
                                                    <ColorBar bg={y % 2 !== 0 ? "#4CD051" : false} />
                                                </div>
                                                )
                                            }

                                        </SubHeading>
                                    )}
                                </ContentCard>
                            )}
                        </CardWrapper>
                    )}
                </Content>
            </SectionWrapper>
        </div>
    )
}
