import React from 'react'
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { talentStatus } from "./staticInternStatus";
import TypeHeaderSection from "./typeHeaderSection";
import styled from "styled-components";
import InternTypeCards from './internTypeCards';
import Button from '../../components/button/button';
import { useSelector, useDispatch } from "react-redux";
import {Colors} from '../../utilities/colors'
import { useNavigate } from "react-router-dom";



const HeaderWrapper = styled.section`
  padding-bottom:25px;`;
const CardHeader = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 25px;
  color: #000;
  margin-top: 50px;
`;

const Detailsection = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  margin-top: 10%;
  grid-row-gap: 80px;
  grid-column-gap: 20px;
  @media (max-width: 900px) {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
  }
  @media (max-width: 576px) {
    display:flex;
    flex-direction:column;
    justify-content:space-around;
    align-items: center;
    margin-top:48px;
  }
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;

const SaveExitBtn = styled(LabelText)`
  color: #504f8c;
  text-decoration: underline;
  cursor: pointer;
  @media (max-width: 800px) {
    width:100%;
    text-align:center;
  }
`;
const MainWrapper = styled.div`
  display: block;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  height: auto;
  padding: 20px;
  margin-right: 10%;
  border-radius: 15px;
  width: 100%;
  @media (max-width: 900px) {
    max-width: 320px;
    margin-right: 0%;
  }
`;

export default function SelectInternshipType() {

// const formValues = useSelector((state) => state.selfIntroOne.value);
// const value = formValues.talent_status;
const navigate = useNavigate();

const onContinue = () =>{
  
  navigate("new-internship")
}
  return (
    <MainLayout>
      <MainWrapper>
      <TypeHeaderSection
        name={(talentStatus.title)}
        description={(talentStatus.description)}
      />
      <HeaderWrapper>
        <CardHeader>{(talentStatus.name)}</CardHeader>
      </HeaderWrapper>
      <Detailsection>
        {talentStatus.statuses.map((status, index) => (
          <InternTypeCards
            // bg={status.value === value ? Colors.pinkShade : ""}
            bg={""}
            key={status.label + index}
            cardName={(status.label)}
            descriptions={(status.descriptions)}
            cardImage={status.img}
            originType={status.value}
            //onSelect={() => changeStatus(status.value, status.label)}
          />
        ))}
      </Detailsection>

      </MainWrapper>
    
    </MainLayout>
  );
}

