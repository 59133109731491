import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import { Link, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { routeGenerator } from "../../components/analyticalDashboardPage/commonFunction";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { analyticalDashboardPageRoute } from "./analyticalPageRoute/analyticalPageRoute";
import { Utils } from "../../components/analyticalDashboardPage/utilities";
import { t } from "i18next";

const BreadCrumbContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  margin-bottom: 10px;
  .breadcrumb,
  span {
    text-decoration: none;
    margin: 0px 5px;
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #9a9a9a;
    &.active {
      color: #d04c8d;
    }
  }
`;
const compositeRoutes = [['student', '{id}']]

export default function AnalyticalMainDashboard() {
    const navigate = useNavigate();
    const handleNavigate = Utils.handleMainDashboardNavigate.bind(this, navigate);
    const location = useLocation();
    let [searchParams, setSearchParams] = useSearchParams();
    const pathName = location && location.pathname;
    const getBreadcrumbs = () => {
        const paths = pathName && pathName.split("/") && pathName.split("/");
        const filteredPaths = paths.filter((x) => x);
        compositeRoutes.forEach((item) => {
            const newPath = `${item[0]}/{id}`;
            const foundIndex = filteredPaths.findIndex((x) => x === item[0]);
            if (foundIndex > -1) {
                filteredPaths.splice(foundIndex, item.length);
                filteredPaths.splice(foundIndex, 0, newPath);
            }
        })
        if (filteredPaths && filteredPaths.length && filteredPaths.length > 1) {
            const breadcrumbContent = [];
            filteredPaths.forEach((path, pathIndex) => {
                console.log("pppppp",path,searchParams)
                const pathName = Utils.getPathName(path, searchParams)?.replace(/\+/g,' ');

                const toPath = filteredPaths.filter((_, index) => index <= pathIndex);
                console.log("ppppp22222p",toPath,pathName)

                breadcrumbContent.push(
                    <span
                        onClick={() => handleNavigate(`/${toPath.join("/")}`)}
                        key={`/${toPath.join("/")}`}
                        className={`breadcrumb ${pathIndex === filteredPaths.length - 1 ? "active" : ""
                            }`}
                    >
                        {t(pathName)}
                    </span>
                );
                if (pathIndex !== filteredPaths.length - 1) {
                    breadcrumbContent.push(
                        <span key={`spacer${pathIndex}`}>{" > "}</span>
                    );
                }
            });
            return breadcrumbContent;
        }
        return null;
    };

    return (
        <MainLayout>
            
            <Row className="mb-2" style={{paddingBottom:'20px'}}>
                <Col>
                    <BreadCrumbContainer>{getBreadcrumbs()}</BreadCrumbContainer>
                </Col>
            </Row>
            {routeGenerator(analyticalDashboardPageRoute)}
        </MainLayout>
    );
}
