import { useState } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";

import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schemaNewObj } from "./staticContent";
import ErrorField from "../errorField/errorField";
import { getCompanyData, onAddNewContact } from "../../services/apiCalls";
import { updateCompanyData, updateCurrentlySelectedCompanyData } from "../../pages/myCompany/reducer";
import Loader from "../loader/loader";
import { useTranslation } from "react-i18next";



const ModalWrapper = styled.div`
  padding: 10px;
  width: "100%";
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;



export default function AddContact(props) {
  const onClose = props.onClose;
  const {t} = useTranslation();
  

  const org = useSelector((state) => state.org.orgData);
  const contact = useSelector((state) => state.contact.contactData);
  const companyOrgs = useSelector((state) => state.company.companyData);

 
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...contact },
    resolver: yupResolver(schemaNewObj),
  });
 
  const onMblNumberChange = (val) => {
    if (val) {
      setValue("mob_number", val);
    }
  };
  const formVal = watch();
  const dispatch = useDispatch();

  const getData = async () => {
    const { data } = await getCompanyData({
      code: companyOrgs[props.companyIdx].org_code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCurrentlySelectedCompanyData(data.data));
    } else {
      setLoading(false);
    }
  };

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await onAddNewContact({
      org_code: companyOrgs[props.companyIdx].org_code,
      data: { ...formVal, org_code: companyOrgs[props.companyIdx].org_code },
    });
    if (data && data.status === 1) {
      toast.success(t("New Contact submitted for admin approval successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      getData();
      props.onClose();
      setFormErrors("");
    } else {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  return (
    <CustomModal open={props.open} onCloeModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3 style={{ width: "auto" }}>{t('Add Contact')}</h3>
            <hr />
            <FormWrapper>
              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Email Address*')}</FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t('Email')}
                        />
                      )}
                    />
                    {errors.email && <ErrorField err={t(errors.email.message)} />}
                  </SingleField>
                </Grid>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('First Name*')}</FormLabel>
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t('First Name')}
                        />
                      )}
                    />
                    {errors.first_name && (
                      <ErrorField err={t(errors.first_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Last Name*')}</FormLabel>
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t('Last Name')}
                        />
                      )}
                    />
                    {errors.last_name && (
                      <ErrorField err={t(errors.last_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Job Title*')}</FormLabel>
                    <Controller
                      name="job_title"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t('Job Title')}
                        />
                      )}
                    />
                    {errors.job_title && (
                      <ErrorField err={t(errors.job_title.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Department*')}</FormLabel>
                    <Controller
                      name="department"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#f3f3f3" }}
                          {...field}
                          placeholder={t('Department')}
                        />
                      )}
                    />
                    {errors.department && (
                      <ErrorField err={t(errors.department.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Mobile Number*')}</FormLabel>
                 
                    <Controller
                      name="mob_number"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          containerStyle={{ height: "48px" }}
                          inputStyle={{ height: "48px" }}
                          value={formVal.mob_number}
                          onChange={onMblNumberChange}
                          defaultCountry="MY"
                          international
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          maxLength={16}
                          minLength={12}
                          />
                        
                      )}
                    />
                    {errors.mob_number && (
                      <ErrorField err={t(errors.mob_number.message)} />
                    )}
                  </SingleField>
                </Grid>

              </Grid>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                }}
              >
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}
                >
                  {formErrors && <ErrorField err={t(formErrors)} />}
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button style={{ marginRight: "1.5%" }} name={t("Confirm")} onClick={handleSubmit(onSubmit)} />
                  )}
                  <Button
                    secondary
                    color="#FFFFFF"
                    name={t("Cancel")}
                    variant="outlined"
                    onClick={onClose}
                  >

                  </Button>
                </Grid>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );

}
