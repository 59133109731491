import styled from "styled-components";

export const Image = styled.img`
  width:230px;
  height:230px;
  border-radius:50%;
`;

export const SubHeading = styled.p`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 180%;
color: #D04C8D;

`;

export const ContentText = styled.p`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 180%;
color: #6B6B6B;
`;

export const NormalText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
/* identical to box height */
color: #202020;
`;

export const SpanText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
/* identical to box height */
color: #ADADAD;

`;
export const MainText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 600;
font-size: 24px;
line-height: 32px;
color: #202020;
margin-bottom:32px;
`;

export const BoxWrapper = styled.div`
background: #FDFDFD;
border: 1px solid rgba(228, 228, 228, 0.26);
border-radius: 10px;
padding:24px 42px;
margin:20px auto;
@media (max-width: 500px) {
    padding:16px;
}
`;


export const ChartsWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-around;
  @media (max-width: 1200px) {
    flex-flow:wrap;
}
`;

export const ChartHeaderText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 600;
font-size: 18.5255px;
line-height: 115.3%;
/* or 21px */

text-align: start;
letter-spacing: 0.01em;

color: #434343;
`