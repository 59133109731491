import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaEye, FaEyeSlash } from "react-icons/fa";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import InputGroup from "../../components/inputGroup/inputGroup";
import Button from "../../components/button/button";
import EmailVerification from "../../components/emailVerification/emailVerification";
import LoginSuccessModal from "../../components/loginSuccessModal/loginSuccessModal";
import Loader from "../../components/loader/loader";
import RegisterCoverImg from "../../assets/registerCover.png";
import { Colors } from "../../utilities/colors";
import {
  validateInputs,
  validEmailRegex,
  checkBothPasswords,
  validPassword
} from "../../utilities/commonFunctions";
import { errorMessages } from "../../utilities/commonMessages";
import ErrorField from "../../components/errorField/errorField";
import {
  authSignUp,
  onTriggerOtp,
  onOtpVerify,
  validateEmailAddress,
} from "../../services/apiCalls";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-number-input";
import { isValidPhoneNumber } from "react-phone-number-input";
// styling starts
const LoginPageWrapper = styled.section``;
const Anchor = styled.a`
  text-decoration: none;
  color: #696f79;
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
`;
const HeaderWrapper = styled(FlexBox)`
  margin: 0px 0 60px 0;
`;
const HRLine = styled.div`
border: 1px solid #F5F5F5;
width: 426.78px;
margin:20px 0 0 0;
`
const Label = styled.label`
  font-weight: 500;
  display: block;
`;

const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display:flex;
  align-items:center;
  justify-content:space-between;
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: ${Colors.primary};
  margin-left: 10px;
  cursor: pointer;
  text-decoration: none;
`;
const Visibility = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
  top: 3.2rem;
  cursor: pointer;
  svg {
    fill: gray;
  }
`;
const PasswordInput = styled.div`
  position: relative;
`;
const FormWrapper = styled.form`
  margin: 10px 0px;
  max-width: 440px;
  
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const TermsConditionsWrapper = styled(FlexBox)`
  // > p {
  //   font-weight: 500;
  //   font-size: 16px;
  //   margin-left: 10px;
  //   line-height: 22px;
  //   color: #696f79;
  //   > a {
  //     margin-left: 5px;
  //     text-decoration: none;
  //     color: #696f79;
  //   }
  // }
  font-size: 16px;
  color: #696f79;
`;
const PasswordError = styled.label`
  color: ${Colors.red};
  font-size: 16px;
  margin-top: 5px;
  display: block;
`;

// styling ends

export default function SignUpPage() {
  const [mobNumber, setMobNumber] = useState("")
  const [userDetail, setUserDetail] = useState({
    full_name: "",
    first_name: "",
    last_name: "",
    mob_number: mobNumber,
    email: "",
    password: "",
    confirm_password: "",
  });
  const [formErrors, setFormErrors] = useState("");
  const [fieldErrors, setErrors] = useState("");
  const [otpErrors, setOtpErrors] = useState("");
  const [emptyData, setEmptyData] = useState(true);
  const [mobNumberNotExists, setMobNumberNotExists] = useState(true)
  const [loading, setLoading] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [showEmailVerificationNew, setShowEmailVerificationNew] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const [openModal, setSuccessModal] = useState(false);
  const [radioCheck, setRadioCheck] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShownConfirm, setPasswordShownConfirm] = useState(false);

  const [mobNumberError, setMobNumberError] = useState("");


  const { email, password, confirm_password, full_name, mob_number
  } = userDetail;
  let { first_name, last_name } = userDetail;
  const navigate = useNavigate();

  useEffect(() => {
    if (![email, password, confirm_password, full_name].includes("")) {
      setEmptyData(false)
    }

  }, [email, password, confirm_password, full_name]);

  useEffect(() => {

    if (mobNumber?.length > 4) {
      setMobNumberNotExists(false)
    }
  }, [])


  // email verification starts
  const onEmailVerification = async () => {
    if (userDetail.email && !fieldErrors) {
      setErrors("");
      let body = {
        email_address: userDetail.email,
      };
      const { data, status } = await validateEmailAddress(body);
      if (status === 200) {
        if (data.status === 1) {
          validateEmail();
          setOtpErrors("");
          toast.success(t("OTP has been sent successfully to your entered Email ID"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setOtpErrors(data.message);
          setShowEmailVerification(false);
          setShowEmailVerificationNew(false);
        }
      }
    } else {
      setErrors({ email: t("Please enter a valid email.") });
    }
  };
  const validateEmail = async () => {
    const { data, status } = await onTriggerOtp({ email_address: email });
    if (status === 200) {
      onEmailTriggered(data);
    }
  };
  const onEmailTriggered = ({ data, message, status }) => {
    if (status === 1) {
      setShowEmailVerification(true);
      setShowEmailVerificationNew(true);
      setOtpVerification(false);
    }
  };
  // email verification ends

  // otp verification starts
  const onOtpVerification = async (otp) => {
    if (otp) {
      const { data, status } = await onOtpVerify({
        email_address: email,
        otp_in: otp,
      });
      if (status === 200) {
        onOtpVerified(data);
      }
    }
  };
  const onOtpVerified = ({ data, message, status }) => {
    if (status === 1) {
      setOtpVerification(true);
      setShowEmailVerification(false);
      setShowEmailVerificationNew(true);
      setOtpErrors("");
    } else {
      setOtpVerification(false);
      setOtpErrors(message);
    }
  };
  // otp verification ends

  const radiohandler = () => {
    setRadioCheck(!radioCheck);
  };

  function createFirstLastNames(value) {
    let splittedName = value.trim().split(' ');
    last_name = splittedName.pop();
    first_name = splittedName.join(" ");
  }

  // input change function starts
  const inputChange = (event) => {
    const { name, value } = event.target;
    let emailTolowerCase = value;
    if (name === "email") {
      emailTolowerCase = value && value.toLowerCase();
    }
    if (name === "full_name") {
      createFirstLastNames(value)
    }
    fieldValidations(name, emailTolowerCase);
    const newUserDetail = { ...userDetail, [name]: emailTolowerCase, first_name, last_name, mob_number: mobNumber };
    setUserDetail(newUserDetail);
  };
  // input change function ends

  // field validations
  const fieldValidations = (name, value) => {
    switch (name) {
      case "full_name":
        const full_name_errVal = (value.split(' ').length > 1 && value.split(' ')[1] !== '')
          ? ""
          : { [name]: t("Full Name should have atleast a space in between First and Last Names") };
        setErrors(full_name_errVal);
        break;
      case "email":
        const errVal = validEmailRegex.test(value)
          ? ""
          : { [name]: t("The e-mail address entered is incorrect") };
        setErrors(errVal);
        break;
      case "mob_number":
        const errMobNumber = isValidPhoneNumber(value)
          ? ""
          : { mob_number: t("Invalid mobile number") };
        setErrors(errMobNumber);
        break;
      case "password":
        const errValue = checkBothPasswords(value, confirm_password)
          ? ""
          : { confirm_password: errorMessages.passwords };
        setErrors(errValue);

        let valPass = validPassword.test(value)
          ? ""
          : { password: t("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character") };

        if (confirm_password.length > 0) {
          valPass = checkBothPasswords(password, value) ? ""
            : { confirm_password: t("Entered passwords do not match") }
        }
        setErrors(valPass);
        break;
      case "confirm_password":
        const errPwd = checkBothPasswords(password, value) ?
          validPassword.test(password)
            ? "" : { password: t("Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character") }
          : { confirm_password: t("Entered passwords do not match") }

        setErrors(errPwd);
        break;
      default:
        break;
    }
  };

  const onSignUp = () => {

    const isValid = validateInputs(userDetail);
    if (isValid && otpVerification && radioCheck) {
      setFormErrors("");
      setLoading(true);
      onFormSubmission();
    } else {
      if (userDetail.email === "" || userDetail.password === "" || userDetail.confirm_password === "") {
        setFormErrors(t("The email and password are mandatory fields."));
      } else {
        setFormErrors(
          !radioCheck
            ? errorMessages.terms
            : !isValid
              ? errorMessages.required
              : errorMessages.otp
        );
      }

    }
  };

  const onFormSubmission = async () => {
    const { data, status } = await authSignUp(userDetail);
    if (status === 200) {
      setLoading(false);
      userLoginFlow(data);
    }
  };
 
  const userLoginFlow = ({ data, message, status }) => {
    if (status === 0) {
      setFormErrors(message);
    } else {
      setFormErrors("");
      setSuccessModal(true);
      localStorage.setItem("companyData","null")
      localStorage.setItem("auth_token", data.token);
      window.allow_user_continue = true;

    }
  };




  const onNavigate = (path) => {
    navigate(path);
  };
  const togglePasswordVisiblity = () => {
    if (otpVerification) {
      setPasswordShown(passwordShown ? false : true);
    }
  };

  const toggleConfirmPasswordVisiblity = () => {
    if (otpVerification) {
      setPasswordShownConfirm(passwordShownConfirm ? false : true);
    }
  };

  const onMobileNumberChange = (e) => {
    const MAX_PHONE_NUMBER_LENGTH = 13;
    if (e && e.length <= MAX_PHONE_NUMBER_LENGTH) {
      if (e && isValidPhoneNumber(e)) {
        setMobNumber(e)
        setMobNumberError("");
        const newUserDetail = { ...userDetail, mob_number: e };
        setUserDetail(newUserDetail);
      } else {
        setMobNumber("");
        setMobNumberError("Please enter a valid mobile number.");
      };
    } else {
      setMobNumber("");
      setMobNumberError(`Phone number should not exceed 10 digits.`);
    }

  };
  const { t } = useTranslation();
  useEffect(()=>{
    localStorage.setItem("phone_num", mobNumber);
      },[])
  return (
    <UserCreationLayout sidebarImg={RegisterCoverImg}>
      <LoginPageWrapper>
        <HeaderWrapper>
          <svg
            width="11"
            height="18"
            viewBox="0 0 11 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1.68313L9.32219 0.105469L0 8.92969L9.33162 17.7539L11 16.1762L3.33676 8.92969L11 1.68313Z"
              fill="#D04C8D"
            />
          </svg>
          <LabelText onClick={() => onNavigate("/")}>{t("Back")}</LabelText>

        </HeaderWrapper>
        <IntroSection
          name={t("signup")}
          description={t("Please provide the following details to register yourself successfully")}

        /><HRLine />
        {/* <Divider /> */}
        <FormWrapper className="mt-3">
          <ReactTooltip
            id="emailId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("E-mail address that can be used to contact you.")}
          </ReactTooltip>

          <InputGroup
            name={t("Email address*")}
            err={fieldErrors && fieldErrors["email"]}
            input={{
              dataId: "emailId",
              placeholder: t("Enter email address"),
              value: email,
              type: "email",
              name: "email",
              disabled: showEmailVerificationNew,
              onChange: inputChange,
              messageTip: t("E-mail address that can be used to contact you."),
              className: "w-100"
            }}
            needHelp
            helpFunc={onEmailVerification}
            helpText={
              otpVerification || showEmailVerification
                ? ""
                : t("Send verification code")
            }
          />

          {showEmailVerification && (
            <EmailVerification
              verifyCode={onOtpVerification}
              isSuccess={otpVerification}
              onSendNewCode={onEmailVerification}
              dataId="otpId"
            />
          )}
          <ReactTooltip
            id="otpId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Enter verification code.")}
          </ReactTooltip>

          {otpErrors && <ErrorField err={t(otpErrors)} />}
          <ReactTooltip
            id="fullName"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Enter your Full Name")}
          </ReactTooltip>
          <PasswordInput>

            <InputGroup
              name={t("Full Name*")}
              err={fieldErrors && fieldErrors["full_name"]}
              input={{
                dataId: "fullName",
                placeholder: t("Enter your Full Name"),
                value: full_name,
                name: "full_name",
                disabled: otpVerification ? false : true,
                onChange: inputChange,
                messageTip: t("Enter your Full Name"),
                className: "w-100"
              }}
            />
          </PasswordInput>
          <PasswordInput>
            <FormLabel>{t("Mobile Number*")}</FormLabel>
            <PhoneInput
              // readOnly
              name="mob_number"
              value={userDetail?.mob_number}
              onChange={(e) => onMobileNumberChange(e)}
              defaultCountry="MY"
              international
              countryCallingCodeEditable={false}
            />
            {mobNumberError && <ErrorField err={t(mobNumberError)} />}

          </PasswordInput>
          <PasswordInput>
            <InputGroup
              name={t("Create password*")}
              err={fieldErrors && fieldErrors["password"]}
              input={{
                dataId: "passId",
                placeholder: t("Enter new Password"),
                value: password,
                type: passwordShown ? "text" : "password",
                name: "password",
                disabled: otpVerification ? false : true,
                onChange: inputChange,
                messageTip: t("Enter new Password"),
                className: "w-100"
              }}
            />
            <Visibility onClick={togglePasswordVisiblity}>
              {passwordShown ? <FaEyeSlash /> : <FaEye />}
            </Visibility>
          </PasswordInput>
          <ReactTooltip
            id="passId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Enter new Password")}
          </ReactTooltip>
          <ReactTooltip
            id="confirmId"
            place="top"
            effect="solid"
            globalEventOff="focusin"
          >
            {t("Confirm New password")}
          </ReactTooltip>
          <PasswordInput>
            <InputGroup
              name={t("Confirm password*")}
              err={fieldErrors && fieldErrors["confirm_password"]}
              dataId="conformId"
              input={{
                dataId: "confirmId",
                placeholder: t("Confirm new password"),
                value: confirm_password,
                //type: "password",
                type: passwordShownConfirm ? "text" : "password",
                name: "confirm_password",
                disabled: otpVerification ? false : true,
                onChange: inputChange,
                messageTip: t("Confirm new password"),
                className: "w-100"
              }}
            />
            <Visibility onClick={toggleConfirmPasswordVisiblity}>
              {passwordShownConfirm ? <FaEyeSlash /> : <FaEye />}
            </Visibility>
          </PasswordInput>

          <TermsConditionsWrapper>
            <CheckboxInput
              checked={radioCheck}
              onChange={radiohandler}
            />
            <label>
              <Anchor target="_blank" href="https://www.mynext.my/terms">
                {t("I agree to the Terms & Conditions")}
              </Anchor>
            </label>
          </TermsConditionsWrapper>
          {formErrors && <PasswordError>{t(formErrors)}</PasswordError>}
          {loading ? (
            <Loader />
          ) : (
            <Button
              disabled={fieldErrors || !mobNumber || emptyData || !radioCheck || password !== confirm_password}
              onClick={onSignUp}
              name={t("Register Account")}
              className="w-100 cTAlHL"
            />
          )}

        </FormWrapper>
      </LoginPageWrapper>
      {openModal && <LoginSuccessModal open={openModal} />}
    </UserCreationLayout>
  );
}
