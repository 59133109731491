import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/addNewIntern.png";
import PlusIcon from "../../assets/plus.png";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

const AddNeCompanyWrapper = styled.div`
  background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  padding: 15px;
  margin: 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    margin-top: 61px;
    > img {
      display: block;
      margin-left: auto;
      top: 67%;
      width: 180px;
      height: 169px;
    }
  }
`;
const StaticImg = styled.img`
  position: absolute;
  left: -35px;
  /* top: 22%; */
  width: 208px;
  height: 200px;
  bottom: -12%;
  cursor:default!important;
  @media (max-width:500px){
    top:
    display: inline-block;
    bottom: -25%;
    left: -35px;
    
  }
`;
const ContentWrapper = styled.div`
  margin: 0 20px;
  margin-left: 115px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0 0 10px 50px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 0 50px;
    color: #ffffff;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
    margin-left: 37px;
    padding: 0px;
  }
`;

export default function AddNewCompany(props) {
  const navigate = useNavigate();
  const status = useSelector((state) => state.company.userAcceptedStatus);
  const userRole = useSelector((state) => state.company.currentOrgData);
  const onNavigate = () => {
    if (userRole?.user_details.company_modify !== "Y" || status != 'accepted') {
      return props.setUnAuth(true);
    }
    navigate("/register");
  };
  const { t, i18n } = useTranslation();
  return (
    <AddNeCompanyWrapper>
      <StaticImg src={Logo} alt="new company" />
      <ContentWrapper>
        <h3>{t("Add New Company")}</h3>
        <p>
        {t("Organisations registered on mynext company platform will be able to post internship(s) for students. Upon submission of this form, your registration will be reviewed by TalentCorp. a new company under your organisation here. Please note that new company registration via mynext has become a payable feature.For more information, kindly visit our official website, or email us at company@mynext.my")}
          <br />
        </p>
      </ContentWrapper>
      {status ? (
        <img onClick={onNavigate} src={PlusIcon} alt="new company add" />
      ) : (
        <></>
      )}
    </AddNeCompanyWrapper>
  );
}
