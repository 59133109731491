import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  .modal-dialog {
    width: 1200px;
    max-width: 100%; 
    top: 30%;
  }
`;

function ReviewModal(props) {
    return (
        <div>
            <CustomModal show={props.show} onHide={props.close} backdrop={props.backdropMode == "error" ? "false" : "static"}>
            <div style={{ padding: '9px' }}>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"  
            onClick={props.close} style={{
            position: "absolute",
            top: "5%",
            left: "95%",
            border: "none",
            fontSize: "25px",
            }}>
          <span aria-hidden="true" style={{marginRight: "-20px",position: "relative",right: "20px",bottom:"20px"}}>&times;</span>
          </button>
          <ModalHeader></ModalHeader>
          <ModalHeaderText>{props.title}</ModalHeaderText>
          <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
          {props.content}
        </div>
            </CustomModal>
        </div>
    );
}

export default ReviewModal;
