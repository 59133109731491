import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../constants/apiRoutes";
import { setMessage } from "../reducers/messageReducer";
import { getError } from "../services/CommonApi";
import userManagementService from "../services/employeeManagement.service";
import { useSelector } from "react-redux";




export const getUsersByUniversityManagment = createAsyncThunk(
  API_ROUTES.GET_UNIVERSITY_USERS_MANAGMENT,
  async (id, thunkAPI) => {
    try {
      const { search, page, perPage, sortField, sortDirection, roles, registration, is_assessment_done } =
        await thunkAPI.getState().employeeManagementReducer;
      const params = {};
      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (registration) {
        params.is_registered = registration;
      }
      if (is_assessment_done) {
        params.is_assessment_done = is_assessment_done;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if(id && id.length>0){
        const usersResponse = await userManagementService.getUserManagmentUsers(id,params, null);
        var testobj = {
          count: usersResponse.data.count,
          next: null,
          previous: null,
          results: usersResponse.data.results,
        };
  
        // if (usersResponse.data && usersResponse.data.length !== 0) {
        return { data: testobj };
      }else{
        return { data: [] };
      }
      
      // } else {
      //   return { data: [] };
      // }
    } catch (error) {
      return thunkAPI.rejectWithValue();
    }
  }
);
export const getDrillDownDataTable = createAsyncThunk(
  API_ROUTES.GET_DRILLDOWN_DATA,
  async (urlParams, thunkAPI) => {
    console.log("this workmj g", urlParams)
    try {
      // const {url} = getDrillDownRoute();
      console.log("takeDaya", urlParams)

      var params = urlParams.params;
      const { search, page, perPage, sortField, sortDirection, roles } =
        thunkAPI.getState().drillDownTableReducer;

      let filtering = "";
      if (search) {
        params.search = search;
      }
      if (page) {
        params.page = page;
      }
      if (perPage) {
        params.page_size = perPage;
        // params.page_size = 10000;
      }
      if (sortField) {
        params.ordering = `${sortDirection}${sortField}`;
      }
      if (roles && roles.length) {
        filtering = roles
          .split(",")
          .map((role) => `roles__name=${role}`)
          .join("&");
      }

      const usersResponse = await userManagementService.getDrilldown(
        urlParams.url,
        {
          params: params,
          controller: null
        }
      );
      console.log("userDrill", urlParams.url);

      if (urlParams.url == "api/dashboard/company/analytical/demographic/registered-employees-list/") {
        if (
          usersResponse.data.results &&
          usersResponse.data.results.length !== 0
        ) {
          console.log("if working");
          return { data: usersResponse.data };
        } else {
          return { data: [] };
        }
      }
      else {
        if (
          usersResponse.data.data.results &&
          usersResponse.data.data.results.length !== 0
        ) {
          console.log("if working");
          return { data: usersResponse.data.data };
        } else {
          return { data: [] };
        }
      }


    } catch (error) {
      console.log('rejectedError', error)
      return thunkAPI.rejectWithValue();
    }
  }
);
