import styled from "styled-components";

import Input from "../input/input";
import { Colors } from "../../utilities/colors";
import ErrorField from "../errorField/errorField";
// styling starts
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
  display:flex;
  align-items:center;
  justify-content:space-between;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-align: right;
  margin:16px;
  text-decoration:none;
`;
const LinkText=styled(UserHelpText)`
cursor:pointer;
text-decoration:underline;
`;
// styling ends

export default function InputGroup({
  name,
  input,
  needHelp,
  helpFunc,
  helpText,
  err,
  changeaddress,
}) {
  return (
    <GroupWrapper>
      <FormLabel>
        {name} <LinkText>{changeaddress}</LinkText>
      </FormLabel>
      <Input {...input} />
      {err && <ErrorField err={err} />}
      {needHelp && <UserHelpText onClick={helpFunc}>{helpText}</UserHelpText>}
    </GroupWrapper>
  );
}
