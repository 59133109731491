import React from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { useSnapshot } from "valtio";
import { subsctipationsProxy } from "../Subscriptions/subscripationsProxy";

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const ModalContainer = styled.div`
  min-width: 480px;
  min-height: 270px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  @media (max-width: 500px) {
    min-width: 380px;
    width: 396px;
    height: auto;
    margin: 0 20px;
  }
`;

const TitleCloseBtn = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 10px;
  justify-content: space-between;
  border-radius: 12px;
  font-size: 15px;
`;

const BorderBox = styled.div`
  border-radius: 12px;
  padding: 15px;
  background: #f3f3f3;
`;

export default function UnAuthPopup({ isOpen, setOpen, proceed }) {
  const selectedIdForEdit = useSelector(
    (state) => state.userManager.currentlySelectedEditId
  );
  const users = useSelector(
    (state) => state.userManager.usersForSelectedCompany
  );
  const [username, setUserName] = useState("");

  useEffect(() => {
    if (users && Object.keys(users).length > 0) {
      for (let i = 0; i < Object.keys(users).length; i++) {
        if (users[i].id === selectedIdForEdit) {
          setUserName(users[i].first_name);
        }
      }
    }
  }, [selectedIdForEdit]);

  return (
    <div>
      {isOpen === true ? (
        <div className="modalHead">
          <ModalContainer>
            <BorderBox>
              <TitleCloseBtn>
                <div style={{ color: "#FA4AA1" }}>AUTHENTICATION</div>
                <div
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  X
                </div>
              </TitleCloseBtn>
              <div>
                <FormLabel>Unauthorized Access</FormLabel>
              </div>
            </BorderBox>

            <p
              style={{
                color: "#202020",
                textAlign: "center",
                fontWeight: 500,
                fontSize: 18,
                margin: "3rem 0rem 2rem",
              }}
            >
              You don’t have any access
            </p>
            <ButtonWrapper>
              <Button onClick={() => setOpen(false)} name="Ok" />
            </ButtonWrapper>
          </ModalContainer>
        </div>
      ) : null}
    </div>
  );
}

export function UnAuthEmPopup({ isOpen, setOpen, proceed }) {
  const selectedIdForEdit = useSelector(
    (state) => state.userManager.currentlySelectedEditId
  );
  const users = useSelector(
    (state) => state.userManager.usersForSelectedCompany
  );
  const [username, setUserName] = useState("");
  useSnapshot(subsctipationsProxy);

  useEffect(() => {
    if (users && Object.keys(users).length > 0) {
      for (let i = 0; i < Object.keys(users).length; i++) {
        if (users[i].id === selectedIdForEdit) {
          setUserName(users[i].first_name);
        }
      }
    }
  }, [selectedIdForEdit]);

  return (
    <div>
      {isOpen === true ? (
        <div className="modalHead">
          <ModalContainer>
            <div className="borderBox">
              <div className="titleCloseBtn" style={{ padding: "0px 10px" }}>
                <p style={{ color: "#FA4AA1" }}>AUTHENTICATION</p>
                <p
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  X
                </p>
              </div>
              <FormLabel style={{ marginTop: "-10px" }}>
                Unauthorized Access
              </FormLabel>
            </div>

            <p
              style={{
                color: "#202020",
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              Subscription {subsctipationsProxy.plan} Plan allows only{" "}
              {subsctipationsProxy.employe_equota} employees to add and manage.
              <br /> Kindly email us at company@mynext.my for subscription
              renewal.
            </p>
            <ButtonWrapper>
              <Button onClick={() => setOpen(false)} name="Close" />
            </ButtonWrapper>
          </ModalContainer>
        </div>
      ) : null}
    </div>
  );
}

export function UnAuthJobPopup({ isOpen, setOpen, proceed }) {
  const selectedIdForEdit = useSelector(
    (state) => state.userManager.currentlySelectedEditId
  );
  const users = useSelector(
    (state) => state.userManager.usersForSelectedCompany
  );
  const [username, setUserName] = useState("");
  useSnapshot(subsctipationsProxy);

  useEffect(() => {
    if (users && Object.keys(users).length > 0) {
      for (let i = 0; i < Object.keys(users).length; i++) {
        if (users[i].id === selectedIdForEdit) {
          setUserName(users[i].first_name);
        }
      }
    }
  }, [selectedIdForEdit]);

  return (
    <div>
      {isOpen === true ? (
        <div className="modalHead">
          <ModalContainer>
            <div className="borderBox">
              <div className="titleCloseBtn" style={{ padding: "0px 10px" }}>
                <p style={{ color: "#FA4AA1" }}>AUTHENTICATION</p>
                <p
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  X
                </p>
              </div>
              <FormLabel style={{ marginTop: "-10px" }}>
                Unauthorized Access
              </FormLabel>
            </div>

            <p
              style={{
                color: "#202020",
                textAlign: "center",
                marginTop: "24px",
              }}
            >
              Subscription {subsctipationsProxy.plan} Plan allows only{" "}
              {subsctipationsProxy.job_quota} job to add and manage.
              <br /> Kindly email us at company@mynext.my for subscription
              renewal.
            </p>
            <ButtonWrapper>
              <Button onClick={() => setOpen(false)} name="Close" />
            </ButtonWrapper>
          </ModalContainer>
        </div>
      ) : null}
    </div>
  );
}
