import React from "react";
import Select from "react-select";

class NewInternDropDown extends React.Component {

  customStyles = {
    dropdownIndicator: this.dropdownIndicator,
    control: (base, state) => ({
      ...base,
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      background: '#ECECEC',
      minWidth: "234px",
      // width:"500px",

      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      // "&:hover": {
      //   boxShadow: "0 0 0 1px #ff60b1",
      //   borderColor: "#ff60b1",
      // },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",
      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
      minWidth: "234px",
      height: "120px",
      textTransform: "capitalize",
      "&::-webkit-scrollbar": {
        width: "8px",
      },
      "&::-webkit-scrollbar-thumb": {
        backgroundColor: "#888",
        borderRadius: "4px", 
      },
      "&::-webkit-scrollbar-track": {
        backgroundColor: "#ECECEC",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      fontSize: `14px`,
      fontFamily: `General Sans`,
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#FFFFFF; !important`,
      },
    }),
  };

  state = {
    selectedOption: null,
  };
  handleChange = (selectedOption) => {
    this.setState({ selectedOption });
  };
  
  render() {
    // const val = this.props.val
    //   ? { label: this.props.label, value: this.props.val, data: this.props }
    //   : undefined;
    const val = this.props.isMulti
      ? this.props.val
      : this.props.val
        ? { label: this.props.label, value: this.props.val, data: this.props }
        : undefined;
    const loading = this.props.options
      ? this.props.options.length > 0
        ? false
        : true
      : true;


    return (
      <div data-tip data-for={this.props.dataId ? this.props.dataId : ""}>
        <Select
         innerRef={this.props.innerRef}
          className={this.props.classname}
          // components={{ DropdownIndicator }}
          formatOptionLabel={this.props.formatOptionLabel}
          styles={this.customStyles}
          value={val ? val : ""}
          onInputChange={this.props.inputChange}
          onChange={this.props.change}
          options={this.props.options}
          isSearchable
          isLoading={
            this.props.showLoader !== undefined
              ? this.props.showLoader
              : loading
          }
          isDisabled={this.props.disable}
          placeholder={this.props.placeholder}
          onFocus={this.props.onFocus}
          menuIsOpen={this.props.menuIsOpen}
          isMulti={this.props.isMulti}
          autoFocus
        />
      </div>
    );
  }
}

export default NewInternDropDown;
