import * as yup from "yup";

export const initialObj = {
  rgtr_address: "",
  country: "",
  state: "",
  city: "",
  status: "",
  country_code: "",
};

export const schema = yup.object().shape({
  rgtr_address: yup.string().required("Address is a required field."),
  country: yup.string().required(),
  state: yup.string().required(),
  city: yup.string().required(),
  status: yup.string().required(),
  country_code: yup.string().required(),
});
