import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

export const getQualityOfLifeDrilldown = () => {
    const getQuality = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Variety": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 111,
                        "moderate": 390,
                        "high": 187
                    }
                },
                "Security": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 110,
                        "moderate": 405,
                        "high": 173
                    }
                },
                "Way of Life": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 125,
                        "moderate": 364,
                        "high": 199
                    }
                },
                "Surroundings": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 103,
                        "moderate": 379,
                        "high": 206
                    }
                },
                "Economic Return": {
                    "id": 5,
                    "totalTalents": 695,
                    "results": {
                        "low": 89,
                        "moderate": 400,
                        "high": 199
                    }
                }
            }
        }
    }
    return getQuality
}

export default Ad2DrilldownHOC(getQualityOfLifeDrilldown, [
    {
        title: "Variety",
        dataKey: "Variety",
        url: "variety",
        info:"Rating Variety high may come from having a role that involves a range of diverse tasks, settings, client groups or from learning new things."
    },
    {
        title: "Security",
        dataKey: "Security",
        url: "security",
        info:"It is important for talents with high Security score to have a role that provides them with security through a permanent contract."
    },
    {
        title: "Way of Life",
        dataKey: "Way of Life",
        url: "way-of-life",
        info:"It is important for talents that scored high in Way of Life to you to have a job that allows them to lead the type of lifestyle that they choose."
    },
    {
        title: "Surroundings",
        dataKey: "Surroundings",
        url: "surroundings",
        info:"It is very important for talents with high Surroundings scores that they work in an environment which is pleasant and suited to your preferences."
    },
    {
        title: "Economic Return",
        dataKey: "Economic Return",
        url: "economic-return",
        info:"It is important for talents that scored high on Economic Return that their job pays well and provides them with the opportunity to have a good standard of living."
    },
]);