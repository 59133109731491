// import { getIdeasAndOpportunitiesDrilldown } from "../../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";


export const getIdeasAndOpportunitiesDrilldown = () => {
    const getIdeasOpport = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Spotting Opportunities": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 21,
                        "moderate": 456,
                        "high": 210
                    }
                },
                "Creativity": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 27,
                        "moderate": 467,
                        "high": 193
                    }
                },
                "Valuing Ideas": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 20,
                        "moderate": 462,
                        "high": 205
                    }
                },
                "Vision": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 22,
                        "moderate": 476,
                        "high": 189
                    }
                },
                "Ethical and Sustainable Thinking": {
                    "id": 5,
                    "totalTalents": 695,
                    "results": {
                        "low": 13,
                        "moderate": 489,
                        "high": 185
                    }
                }
            }
        }
    }
    return getIdeasOpport;
}

export default Ad2DrilldownHOC(getIdeasAndOpportunitiesDrilldown, [
    {
        title: "Spotting Opportunities", 
        dataKey: "Spotting Opportunities", 
        url: "spotting-opportunities",
        info: "Talents with high scores for this factor are good at spotting opportunities, which other people may not see, bringing benefits to themselves and others. They have the ability to create opportunities and to problem-solve in order to improve their performance in school or work."
    },
    {
        title: "Creativity", 
        dataKey: "Creativity", 
        url: "creativity",
        info: "Talents with high scores for this factor are good at brain storming to generate new ideas and finding ways to meet challenges at school or work. They have the ability to persuade people to get involved in projects."
    },
    {
        title: "Valuing Ideas", 
        dataKey: "Valuing Ideas", 
        url: "valuing-ideas",
        info: "Talents with high scores for this factor are good at estimating the value of ideas and finding solutions to problems in their work."
    },
    {
        title: "Vision", 
        dataKey: "Vision", 
        url: "vision",
        info: "Talents with high scores for this factor are good at building a vision of the future that inspires other people and reflects the future in which theu would like to live. They are also able to understand what decisions need to be made to achieve that vision for their lives."
    },
    {
        title: "Ethical and Sustainable Thinking", 
        dataKey: "Ethical and Sustainable Thinking", 
        url: "ethical-and-sustainable-thinking",
        info: "Talents with high scores for this factor know why integrity and ethical behaviour is important. Their own behaviour reflects this and they take responsibility to promote and advance ethical practices in work."
    },
]);
