import styled from "styled-components";

import Logo from "../../assets/skillCancel.png";

const SkillCardWrapper = styled.div`
  background: #f8f8f8;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 12px 20px;
  margin:0px 20px 10px 0;
  > img {
    cursor:pointer;
  }
`;
const TextDiv = styled.div`
display: flex;
flex-direction: column;
`;
const SkillText = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #989898;
  margin:0;
  margin-right:30px;
  margin-bottom: 10px;
`;
const SkillDetail = styled.h4`
font-style: normal;
font-weight: 600;
font-size: 14px;
line-height: 20px;
color: #989898;
margin:0;
margin-right:30px;
`;
export default function SIPSkillCard(props) {
  return (
    <SkillCardWrapper>
      <TextDiv>
      <SkillText>{props.name}</SkillText>
      <SkillDetail>{props.detail}</SkillDetail>
      </TextDiv>
      <img onClick={props.delete} src={Logo} alt='cancel skill' />
    </SkillCardWrapper>
  );
}
