export const errorMessages={
    required:'Organisation name is required.',
    emailField:'The e-mail address entered is incorrect.',
    websiteField:'The website entered is incorrect.',
    passwords:'Entered passwords do not match',
    username:'Username is required',
    password:'Password is required',
    email:'The email address entered is incorrect.',
    emailRequired:'E-mail address is required'
}

export const toolTips={
    username:'Enter username',
    password:'Enter password'
}

export const registerOrganisationErrorMessages={
    orgName:'Organisation name is required',
    usedSsmNumber:'SSM Number already exist',
    ssmNumber:'SSM Number is required',
    ssmDate:'SSM Registration date is required',
    invalidWebsite:'Invalid organisation website',
    address:'Address is required',
    country:'Country is required',
    state:'State is required',
    city:'City is required',
    postalCode:'Postcode is required',
    orgType:'Type of organisation is required',
    sector:'Sector is required',
    businessNature:'Nature of business is required',
    email:'E-mail address is required',
    otp:'Verification code is required',
    passwordMinLength:'The password must contain a minimum of 8 characters',
    invalidPassword:'The password must contain a mix of upper case and lower case alphabets, special characters and numbers',
    passwordMisMatch:'Entered passwords do not match',
    firstName:'First name is required',
    password:'Password is required',
    lastName:'Last name is required',
    jobTitle:'Job title is required',
    department:'Department is required',
    invalidNumber:'Incorrect mobile number',
    terms:'Please acknowledge and accept the terms and conditions'
}

export const registerOrgToolTip={
    orgName:'Enter the organisation name',
    ssmNumber:'Enter SSM Number',
    ssmDate:'Enter SSM Registration date',
    website:'Enter the organisation website',
    address:'Enter address',
    country:'Country',
    state:'State/Province',
    city:'City',
    code:'Enter Postcode',
    orgType:'Type of organisation',
    sector:'Sector',
    businessNature:'Enter nature of business',
    firstName:'Enter first name',
    lastName:'Enter last name',
    jobTitle:'Enter Job title',
    department:'Enter Department',
    password:'Enter new password',
    email:'Enter E-mail address'
}