import React from "react";
import { StudentAd2Card } from "./ad2Card";
import { Col, Row } from "react-bootstrap";
import {  StudentCoachingProfileBoxTitle } from "../styles/Ad2Styles";
import ReactSpeedometer from "react-d3-speedometer";
import { useTranslation } from "react-i18next";

export function Ad2EnglishProficiency({data, onViewMoreClick}) {
    console.log("daraData",data);
    const chartData = data && data;
    const status = data && chartData.status;
    const totalEnglishProficiencyLevel = chartData && chartData?.data?.General?.points;
    const grammar = chartData && chartData?.data?.General?.values?.Grammar?.points;
    const comprehension = chartData && chartData?.data?.General?.values?.Comprehension?.points;
    const { t} = useTranslation();
    return <>
       { 
       
       chartData?.status !== "noDataFound"
      ?  <StudentAd2Card title="English Proficiency" status={status} onViewMoreClick={onViewMoreClick}>
            <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <Col md="4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    <Row style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Col xs="12" md="4" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '30px 0px 30px 0px'
                        }}>
                            <ReactSpeedometer
                                height={170}
                                width={230}
                                minValue={0}
                                maxValue={1}
                                ringWidth={50} //thickness
                                value={parseInt(totalEnglishProficiencyLevel) / 100} //parseInt(value) / 100
                                needleColor="#542F41"
                                segmentColors={["#058AA5","#00B4D8","#62DBF1","#90E0EF","#CAF0F8"]}
                                valueFormat=".0%"
                                needleHeightRatio={0.7}
                                customSegmentStops={[0, 0.5, 0.6, 0.75, 0.9, 1.0]}
                            />
                        </Col>
                        <Col xs="12">
                            <StudentCoachingProfileBoxTitle>
                                <span>{t("Total English")}<br/>{t("Proficiency Level")}</span>
                            </StudentCoachingProfileBoxTitle>
                        </Col>
                    </Row>
                </Col>
                <Col md="4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    <Row style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}>
                        <Col xs="12" style={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '30px 0px 30px 0px'
                        }}>
                            <ReactSpeedometer
                                height={170}
                                width={230}
                                minValue={0}
                                maxValue={1}
                                ringWidth={50} //thickness
                                value={parseInt(grammar) / 100} //parseInt(value) / 100
                                needleColor="#542F41"
                                segmentColors={["#73284D","#9B3668","#BE598B","#D24C8E","#FF75B9"]}
                                valueFormat=".0%"
                                needleHeightRatio={0.7}
                                customSegmentStops={[0, 0.5, 0.6, 0.75, 0.9, 1.0]}
                            />
                        </Col>
                        <Col xs="12">
                            <StudentCoachingProfileBoxTitle>
                                <span>{t("Grammar")}</span>
                            </StudentCoachingProfileBoxTitle>
                        </Col>
                    </Row>
                </Col>
                <Col md="4" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                    <Row style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                        <Col xs="12" style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '30px 0px 30px 0px' }}>
                            <ReactSpeedometer
                                height={170}
                                width={230}
                                minValue={0}
                                maxValue={1}
                                ringWidth={50} //thickness
                                value={parseInt(comprehension) / 100} //parseInt(value) / 100
                                needleColor="#542F41"
                                segmentColors={["#0B2F4A","#053D67","#137BC9","#137BC9","#319FF2"]}
                                startColor="#"
                                endColor="#"
                                valueFormat=".0%"
                                needleHeightRatio={0.7}
                                customSegmentStops={[0, 0.5, 0.6, 0.75, 0.9, 1.0]}
                            />
                        </Col>
                        <Col xs="12">
                            <StudentCoachingProfileBoxTitle>
                                <span>{t("Comprehension")}</span>
                            </StudentCoachingProfileBoxTitle>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </StudentAd2Card>   : <div  className="noFound">
      <p style={{ margin: 0 }}>{t("Not Yet Complete")}</p>
    </div >  }
    </>;
}