import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  current: null,
  meta: null,
};

export const pageReducer = createSlice({
  name: "pages",
  initialState,
  reducers: {
    updateCurrentPage: (state, action) => { 
        state.current = action.payload;
    },
    updatePageMeta: (state, action) => { 
        state.meta = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateCurrentPage, updatePageMeta } = pageReducer.actions;

export default pageReducer.reducer;
