import { useState, useEffect } from "react";

import styled from "styled-components";
import { IoIosArrowBack } from "react-icons/io";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import OtpInput from "react-otp-input";
import { toast } from 'react-toastify';
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { Colors } from "../../utilities/colors";
import { useNavigate, useLocation } from "react-router-dom";
import { errorMessages } from "../../utilities/commonMessages";
import ErrorField from "../../components/errorField/errorField";
import { forgotPasswordValidation, forgotPasswordEmail, onTriggerOtp, onOtpVerify } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { registerOrganisationErrorMessages } from "../../utilities/commonMessages";
import { useTranslation } from "react-i18next";

// styling starts
const Label = styled.label`
  font-weight: 500;
  display: block;
`;

const LabelHighlightText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  align-self: center;
  cursor: pointer;
  text-decoration: underline;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  margin: 10px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const FlexBox = styled.div`
  display: flex;
`;
const EmailHeader = styled(FlexBox)`
  justify-content: space-between;
`;
const Fintext = styled(FlexBox)`
  margin-top: 20px;
`;
const TEXT = styled.span`
  font-size: 14px;
  color: #a2a2a2;
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  margin-right: 2px;
`;

const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const GroupWrapper = styled.div`
  margin-bottom: 40px;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
`;
const InputOtp = {
  background: Colors.blogTextBox,
  padding: "12px 20px",
  outline: "none",
  width: "-webkit-fill-available",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "22px",
  color: Colors.primary,
  boxSizing: "border-box",
  borderRadius: "10px",
  maxWidth: "54px",
  marginRight: "10px",
  border: `2px solid transparent`,
};
const focusInput = {
  border: `1px solid ${Colors.primary}`,
};
// styling ends

const schema = yup
  .object({
    email_address: yup
      .string()
      .email(errorMessages.email)
      .required(errorMessages.emailRequired),
    otp_in: yup
      .string()
      .required(registerOrganisationErrorMessages.otp)
      .min(5, "Please fill the valid otp."),
  })
  .required();

export default function Reset() {
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email_address: "",
      otp_in: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const [formErrors, setFormErros] = useState("");
  const [loading, setLoader] = useState(false);
  // const [enableEmail, setEnableEmail] = useState(false);
  const { t, } = useTranslation();
  const navigate = useNavigate();
  const onNavigate = (path) => {
    navigate(path);
  };
  const location = useLocation();
  const userDetail = watch();
  useEffect(() => {
    if (location.state.name) {
      setValue("email_address", location.state.name.email_address);
    }
    triggerOTP(location.state.name.email_address);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const triggerOTP = async (email) => {
    const { status } = await onTriggerOtp({ email_address: email });
    if (status === 200) {
      toast.success(t("OTP has been sent successfully to your entered Email ID"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const fetchOTPVerify = async (email) => {
    const { data } = await onOtpVerify(email);
    if (data.status === 0) {
      toast.error(t(data.message), {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      onOtpVerification(data.otp_in);
    }
  };

  const onNewOtp = async () => {
    // setEnableEmail(false);
    if (userDetail.email_address) {
      let req = {
        email_address: userDetail.email_address,
        en_type: "Y29tcGFueQ==",
      };
      const { data } = await forgotPasswordEmail(req);
      if (data && data.status === 1) {
        setValue("otp_in", "");
        setFormErros("");
      } else {
        setFormErros(data && data.message);
      }
      triggerOTP(userDetail.email_address);
    }
  };

  const onOtpVerification = async (otp) => {
    setLoader(true);
    const { data } = await forgotPasswordValidation({
      ...userDetail,
      otp_in: otp ? otp : userDetail.otp_in,
    });
    if (data && data.status === 1) {
      navigate("/change-password", { state: { name: data.code } });
      setFormErros("");
      setLoader(false);
    } else {
      setLoader(false);
      setFormErros(data && data.message);
    }
  };

  const handleChange = (val) => {
    if (val && val.length === 5) {
      setValue("otp_in", val);
      // onOtpVerification(val);
      fetchOTPVerify({
        ...userDetail,
        otp_in: val
      })
    } else {
      setValue("otp_in", val);
    }
  };

  // const onChangeEmail = () => {
  //   setEnableEmail(true);
  //   setValue("otp_in", "");
  // };

  return (
    <UserCreationLayout>
      <HeaderWrapper onClick={() => onNavigate("/forget-password")}>
        <IoIosArrowBack color={Colors.primary} size={20} />
        <SignUpBtn>{t('Back')}</SignUpBtn>
      </HeaderWrapper>
      <IntroSection name="reset_password" />
      <Divider />
      <FormWrapper>
        <GroupWrapper>
          <EmailHeader>
            <FormLabel>{t('Email address*')}</FormLabel>
            <UserHelpText onClick={() => onNavigate("/forget-password")}>
              {t('Change Email Address')}
            </UserHelpText>
          </EmailHeader>
          <Controller
            name="email_address"
            control={control}
            render={({ field }) => (
              <Input
                placeholder={t("Enter email address")}
                type="email"
                onBlur={onNewOtp}
                disabled
                {...field}
              />
            )}
          />
          {errors.email_address && (
            <ErrorField err={t(errors.email_address.message)} />
          )}
        </GroupWrapper>
        <GroupWrapper>
          <FormLabel>{t('Verification code*')}</FormLabel>
          <Controller
            name="otp_in"
            control={control}
            render={({ field }) => (
              <OtpInput
                value={userDetail.otp_in}
                onChange={handleChange}
                numInputs={5}
                focusStyle={focusInput}
                inputStyle={InputOtp}
                shouldAutoFocus
                isInputNum
                separator={<span></span>}
              />
            )}
          />
          <Fintext>
            <TEXT>{t('Not received the code?')} </TEXT>
            <LabelHighlightText onClick={onNewOtp}>
              {t('Send Again')}
            </LabelHighlightText>{" "}
          </Fintext>
          {errors.otp_in && <ErrorField err={t(errors.otp_in.message)} />}
        </GroupWrapper>
        {formErrors && <ErrorField err={t(formErrors)} />}
        {loading ? (
          <Loader />
        ) : (
          <Button
            width="100%"
            onClick={handleSubmit(fetchOTPVerify)}
            name={t("Verify")}
          />
        )}
      </FormWrapper>
    </UserCreationLayout>
  );
}
