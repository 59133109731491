import { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { useNavigate } from "react-router-dom";
import { getCompanyData, getOrgData, onGettingCompanyInfo } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";

import MyCompanyIntro from "../../components/myCompanyIntro/myCompanyIntro";
import SettingsChangePassword from "../../components/settingsChangePassword/settingsChangePassword";
import SettingsOrganisationDetail from "../../components/settingsOrganisationDetail/settingsOrganisationDetail";



const SettingsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;
  min-height: 90vh;
`;

export default function Settings() {
  const [loading, setLoading] = useState(true);
  const [userInfo, setUserInfo] = useState(undefined);
  const [orgUser, setOrgUser] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const org = useSelector((state) => state.org.orgData);
  const currentOrgData = useSelector((state) => state.company.currentOrgData);

;

  useEffect(() => {
    getOrgInfo();
  }, [currentUserEmail]);

  useEffect(() => {
    if (!userInfo) {
      getUserData(currentOrgData.org_code);
    }
  });

  const getOrgInfo = async () => {
    const { data, } = await getOrgData();
    if (data && data.status === 1) {
      setCurrentUserEmail(data.data["email"]);
    } else {
    }
  };





  const getUserData = async (code) => {
    const { data, } = await onGettingCompanyInfo({ code: code });
    if (data && data.status === 1) {
      setOrgUser(data.data["org_user"]);
      setUserInfo(data.data);
      setLoading(false)
    }
  };

  

  
  return (
    <MainLayout back={true}>
      <SettingsWrapper>
     
        {loading ? (
          <Loader />
        ) : (
          currentUserEmail &&
          userInfo && (
            <>
              <MyCompanyIntro data={userInfo} settings={true} orgUser={orgUser} currentUserEmail={currentUserEmail} />
              <SettingsOrganisationDetail
                data={userInfo}
                orgUser={orgUser}
                currentUserEmail={currentUserEmail}
              />
              <SettingsChangePassword />
            </>
          )
        )}
      </SettingsWrapper>
    </MainLayout>
  );
}
