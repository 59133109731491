import styled from "styled-components";
// import DownIcon from "../../assets/icons/down.png";
// import LockIcon from "../../assets/icons/lock.png";
import EditIcon from "../../assets/svg/pencil.svg";
// import { Colors } from "../../utilities/colors";

const DividerLine = styled.div`
  // height: 1px;
  // background-color: #d9d9d9;
  margin:8px 0px;
  display: inline-block;
  width: 100%;
  clear: both;
`;
// const OnlyMe = styled.p`
//   font-weight: 500;
//   font-size: 12px;
//   line-height: 25px;
//   color: #8f8f8f;
//   margin: 0 8px;
// `;
const Icon = styled.img`
  height: 18px;
  width: 18px;
`;
export default function TalentCVCard(props) {
  return (
    // <div className='py-4 ps-3 dont-split'>
    <div className='dont-split'>
      <div className='d-flex justify-content-between'>
        <div
          className={
            props.titleClassName
              ? props.titleClassName
              : "font-family-general-sans-medium fw-500 font-size-18px font-color-pink"
          }
        >
          {props.title}
        </div>
        <div style={{ display: "-webkit-box", float: "right" }}>
          {props.showLock && (
            <>
              {/* <img src={DownIcon} alt="down-icon" />
                                    <OnlyMe>Only me</OnlyMe>
                                    <img src={LockIcon} alt="lock icon" /> */}
            </>
          )}
          {props.showEdit && <Icon onClick={props.editHandler} src={EditIcon} />}
        </div>
      </div>
      <DividerLine />
      <div style={{ wordWrap: "break-word" }} className={props.childClassName}>{props.children}</div>
    </div>
  );
}
