import { Modal } from "react-responsive-modal";
import "./loginmodal.css"
import CloseIcon from "../../assets/close-80.png";

export default function LoginModal(props) {
 

  const closeIcon = (
    <div>
      <img
        style={{ marginLeft: "80%" }}
        src={CloseIcon}
        height="7%"
        width="7%"
        alt="close icon modal"
      />
    </div>
  );
  return (
    <Modal
      open={props.open}
      showCloseIcon={props.showCloseIcon === false ? props.showCloseIcon : true}
      closeIcon={closeIcon}
      center
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={props.onCloseModal}
      styles={{width:"400px"}}
      classNames="textModal"
    >
      {props.children}
    </Modal>
  );
}
