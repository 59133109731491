import { Col, Row } from "react-bootstrap";
import styled from 'styled-components';
import DataTable from "react-data-table-component";

import React, { useState, useEffect } from "react";

// import { staticContentObject } from "../../../components/MySipRequestDetail/staticContent";
import { staticContentObject } from "../staticContentAnalytical";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {  
  resetAll,
  setPage,
  setPerPage,
  setRoles,
  setSearch,
  setSortDirection,
  setSortField,} from "../../../reducers/employeeManagementReducer";
import { Utils } from "../../../components/analyticalDashboardPage/utilities";
import { DRILLDOWN_API_MAP } from "../../constants/ad2Constants";
import { getDrillDownDataTable } from "../../../actions/employeeManagement";
import { useTranslation } from "react-i18next";
import Loader from "../../../components/loader/loader";
import { LoaderBackdrop } from "../../../components/analyticalDashboardPage/commonStyles";

export default function AnalyticalDataTable(
  {
    backgroundColor = "#FFFFFF",

  }
){
    const PreferencesWrapper = styled.div`
    span {
      width: 31px;
      height: 31px;
      border-radius: 6px;
      display: inline-flex;
      text-align: center;
      vertical-align: middle;
      color: #fff;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      margin: 2px 1px;
      font-family: General Sans;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 22px;
      &:hover {
        transition: 0.3s;
      }
    }
    & span:nth-child(3) {
      background: linear-gradient(180deg, #891437 0%, #f27996 100%);
    }
    & span:nth-child(2) {
      background: linear-gradient(180deg, #134389 0%, #7abff3 100%);
    }
    & span:nth-child(1) {
      background: linear-gradient(180deg, #b74d00 0%, #ffb13d 100%);
    }
  `;
    const DataTableWrapper = styled.div`
    background: #ffffff;
    position: relative;
    border-radius: 34px;
    overflow: hidden;
    margin-bottom: 3rem;
    .custom-selector {
      cursor: pointer !important;
      div[id*="option"] {
        cursor: pointer;
        &:hover {
          background-color: #f3f0f0 !important;
        }
      }
    }
    .rdt_TableHead {
      border-radius: 34px;
      height: 83px;
      box-shadow: 0px 0px 10px #d9d9d9;
      overflow: hidden;
      z-index: 0;
      position: relative;
      margin-bottom: 15px;
      .rdt_TableCol_Sortable,
      rdt_TableCol {
        font-family: General Sans;
        white-space: pre-line !important;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 108%;
        color: #d04c8d;
        div {
          white-space: pre-line !important;
        }
      }
    }
    .rdt_TableBody {
      border-radius: 0px 0px 34px 34px;
  
      .rdt_TableRow {
        cursor: pointer;
        &:hover {
          background-color: #ffe9f4;
        }
      }
    }
  `;
  const TableButtonsNew = styled.div`
  display:flex;
`;

  const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
 const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    height: auto;
    width: 100px;

    @media (min-width: 768px) {
      width: 5vw;
    }
  }
  .arrowOne {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) 0s infinite running
      move_eye;
    position: absolute;
  }
  .arrowTwo {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -0.5s infinite running move_eye;
    position: absolute;
  }
  .arrowThree {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -1s infinite running move_eye;
    position: absolute;
  }
  .main {
    position: absolute;
  }

  @-webkit-keyframes move_eye {
    from {
      transform: translate(-50%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }

  @keyframes move_eye {
    from {
      transform: translate(-40%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(30%, 0%);
      opacity: 1;
    }
  }
`;
const FlexWrapper = styled.div`
  display: flex;
`;
const ActionIconWrapper = styled.button`
  background-color: transparent;
  border: 0px;
  margin-right: 10px;
`;
const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const [countRef, setCountRef] = useState(Math.random());

const columnStyling = {
    fontFamily: "General Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    lineHeight: "24px",
    minWidth: "92px",
    color: "#202020",
    "@media (max-width: 990px)": {
      display: "flex",
      fontSize: 14,
      justifyContent: "flex-start",
      padding: "0.5rem 0 0 0.5rem",
    },
  };

const columnStylingForSemester = {
    maxWidth: "69px",
    minWidth: "0px",
    padding: "0px",
    width: "130px"
  }
  const Heading = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;
  const CustomHeading = styled(Heading)`
  color: #acacac;
`;
const dataTableLoader = (
  <DataTableLoaderWrapper>
    <LoaderBackdrop />
    <LoaderWrapper>
      <Loader />
    </LoaderWrapper>
  </DataTableLoaderWrapper>
);
  const columnStylingstatus = {
    fontFamily: "General Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "24px",
    color: "#202020",
    "@media (max-width: 990px)": {
      display: "flex",
      justifyContent: "flex-start",
      padding: "0",
      fontSize: 16,
      width: "fit-content",
      backgroundColor: "#f3f3f3",
      margin: "8px",
      borderRadius: "123px",
      zIndex: "1",
    },
  };
  const { t } = useTranslation();

  const paginationOptions = {
    rowsPerPageText: t('Rows per page:'),
  };
  const totalRows = useSelector((state) => state.drillDownTableReducer.totalRows);
  const perPage = useSelector((state) => state.drillDownTableReducer.perPage);
  const data = useSelector((state) => state.drillDownTableReducer.data);
  const page = useSelector((state) => state.drillDownTableReducer.page);
  const completeData = useSelector((state) => state.drillDownTableReducer);
  const [loading, setLoading] = useState(false);
  const [gridLoading, setGridLoading] = useState(true);
  // const [countRef, setCountRef] = useState(Math.random());
  // const data = staticContentObject.talbeData;

  const dispatch = useDispatch();



  // const getSIPData = async () => {
  //   console.log("mmmmmm");
  //   // setLoading(true);
  //   dispatch(getMySIPTableData("9f6075bc-b716-444f-89ee-4bf50c4377de"))
  //     .unwrap()
  //     .then(() => {
  //       //setLoading(false);
  //       // setGridLoading(false);
  //     });
  // };

  // useEffect(() => {
    // getSIPData();
  // }, []);/

  const columns = [
    {
      name: t("Name"),
      selector: (row) => row.name,
      sortable: true,
      // width: "15%",
      style: columnStyling,
      center: true,
    },
    {
      name: t("Career Alignment"),
      sortable: true,
      style: columnStyling,
      selector: (row) => t(row.employability),
      center: true,
      // width: "14%",
    },
    // {
    //   name: "Motivation Level",
    //   sortable: true,
    //   style: columnStyling,
    //   selector: (row) => row.employability,
    //   center: true,
    //   width: "14%",
    // },
    {
      name: t("Work Interests"),
      selector: (row) => row?.interestRiasec,
      sortable: true,
      style: columnStyling,
      // width: "14%",
      center: true,
      cell: (row) => (
        <PreferencesWrapper>
          {row?.interestRiasec?.map((preference, index) => (
            <span
              style={{
                background:
                  preference == "Realistic"
                    ? "red"
                    : preference == "Investigative"
                      ? "blue"
                      : preference == "Enterprising"
                        ? "violet"
                        : preference == "Social"
                          ? "green"
                          : preference == "Artistic"
                            ? "orange"
                            : preference == "Conventional"
                              ? "navy"
                              : "",
              }}
              key={index}
            >
              {preference == "Realistic"
                ? "R"
                : preference == "Investigative"
                  ? "I"
                  : preference == "Artistic"
                    ? "A"
                    : preference == "Social"
                      ? "S"
                      : preference == "Enterprising"
                        ? "E"
                        : preference == "Conventional"
                          ? "C"
                          : ""}
            </span>
          ))}
        </PreferencesWrapper>
      ),
    },
    // {
    //   name: "Employability",
    //   sortable: true,
    //   style: columnStyling,
    //   selector: (row) => row.employability,
    //   center: true,
    //   width: "14%",
    // },
    {
      name: t("English Proficiency"),
      sortable: true,
      style: columnStyling,
      selector: (row) => t(row.englishTest),
      center: true,
      // width: "14%",
    },
    // {
    //   name: "Internship Status",
    //   sortable: true,
    //   style: columnStyling,
    //   selector: (row) => row.internship_status,
    //   center: true,
    //   width: "14%",
    // },

    // {
    //   name: "Future Learning",
    //   sortable: true,
    //   style: columnStyling,
    //   selector: (row) => row.futureLearing,
    //   center: true,
    //   width: "150px",
    // },
  ];
  const conditionalRowStyles = [
    {
      when: (row) => row.is_valid,
      style: {
        backgroundColor: "transparent",
        userSelect: "#FF2C2C",
      },
    },
    {
      when: (row) => row.is_valid === 0,
      style: {
        backgroundColor: "#FF2C2C",
        userSelect: "transparent",
      },
    },
  ];
const customStyles = {
    style: {
      fontSize: '16px!important',
      width: "1px"
    }
  };
  let [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const handleNavigate = Utils.handleNavigate.bind(this, navigate);

  const onRowClicked = (row) => {
    // var labelVar = searchParams.get("label");
    // console.log("labelVars", labelVar);
    // if (labelVar) {
    //   handleNavigate(`student/${row.id}`, { label: labelVar })
    //   // navigate({pathname:`student/${row.id}`, search:`&label=${labelVar}`});
    // } else {
    //   navigate(`student/${row.id}`);
    // }
  };
  const getUsersAction = () => {

    const pathName = location && location.pathname;
    const paths = pathName && pathName.split("/") && pathName.split("/");
    const currentPathStr = paths && paths.length ? paths[paths.length - 1] : "";
    const mandatoryParams = [
      { propertyKey: "selectedCampus", urlKey: "campus" },
      { propertyKey: "selectedFaculty", urlKey: "faculty" },
      { propertyKey: "selectedStudyProgram", urlKey: "study_program" },
      { propertyKey: "selectedYearOfStudy", urlKey: "year_of_study" },
      { propertyKey: "selectedUniversity", urlKey: "insti_name" },
      { propertyKey: "selectedScopeOfStudy", urlKey: "scope_of_study" },
    ];
    const params = {};
    mandatoryParams.forEach((param) => {
      params[param.urlKey] = searchParams.get(param.propertyKey);
    });
    var deapartmentFilter = searchParams.get("department");

    const levelMap = {
      low: 1,
      moderate: 2,
      high: 3,
      "Level 1": 1,
      "Level 2": 2,
      "Level 3": 3,
      "Level 4": 4,
      "Level 5": 5,
    };
    let url =
      DRILLDOWN_API_MAP &&
      DRILLDOWN_API_MAP[currentPathStr] &&
      DRILLDOWN_API_MAP[currentPathStr].table;

    try {
      if (paths.length > 1 && paths[paths.length - 2] == 'ideas-and-opportunities-drilldown' && paths[paths.length - 1] == 'creativity') {
        url =
          DRILLDOWN_API_MAP &&
          DRILLDOWN_API_MAP["ideas-and-opportunities-drilldown-creativity"] &&
          DRILLDOWN_API_MAP["ideas-and-opportunities-drilldown-creativity"].table
      }
    } catch (error) {

    }

    try {
      if (paths.length > 1 && paths[paths.length - 2] == 'resources-drilldown' && paths[paths.length - 1] == 'self-awareness') {
        url =
          DRILLDOWN_API_MAP &&
          DRILLDOWN_API_MAP["resources-drilldown-self-awareness"] &&
          DRILLDOWN_API_MAP["resources-drilldown-self-awareness"].table
      }
    } catch (error) {

    }





    switch (currentPathStr) {
      case "employee-registered":
        break;
      case "employee-not-registered":
        break;
      case "completed-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = currentPathStr.split("-")[0];
        params.type = "status";
        break;
      case "pending-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = currentPathStr.split("-")[0];
        params.type = "status";
        break;
      case "secured-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = currentPathStr.split("-")[0];
        params.type = "status";
        break;
      case "available-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = "interested";
        params.type = "status";
        break;
      case "internship-industry-sector":
        params.sector = searchParams.get("sector");
        params.type = 'sector';
        break;
      case "personality-and-motivation":
      case "emotional-stability":
      case "extraversion":
      case "openness-to-experience":
      case "agreeableness":
      case "conscientiousness":
        const label = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && label) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[label];
        }
        break;
      case "work-interest":
      case "mastery":
        const labelMastery = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelMastery) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelMastery];
        }
      case "quality-of-life":
        const labelQuality = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelQuality) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelQuality];
        }
      case "fellowship":
        const labelFellowship = searchParams.get("label");
        if (
          currentPathStr !== "personality-and-motivation" &&
          labelFellowship
        ) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelFellowship];
        }
      case "creating-value":
        const labelCreatingValue = searchParams.get("label");
        if (
          currentPathStr !== "personality-and-motivation" &&
          labelCreatingValue
        ) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelCreatingValue];
        }
      case "intellectual-stimulation":
        var labelSearch = searchParams.get("label");
        console.log("beforeSwicth", params)

        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
          console.log("switchParam", params)
        }
      case "recognition":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "achievement":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "independence":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "variety":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "security":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "way-of-life":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "surroundings":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "economic-return":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "altruism":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "supervisory-relationship":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "associates":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "belonging":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "family":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "aesthetic":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "creativity":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "protecting-the-planet":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "leadership":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "ideas-and-opportunities":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "spotting-opportunities":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "creativity":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "valuing-ideas":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "vision":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "ethical-and-sustainable-thinking":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "self-awareness":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "self-efficacy":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "financial-and-economic-literacy":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "mobilising-others":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "mobilising-resources":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "motivation-and-perseverance":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "working-with-others":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "planning-and-management":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "learning-through-experience":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "coping-with-uncertainty-ambiguity-and-risk":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "taking-the-initiative":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "resources":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "into-action":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "cognitive":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
          console.log("parrams", params)
        }
      case "critical-thinking":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "planning-and-ways-of-working":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "communication":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "mental-flexibility":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "interpersonal":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "mobilising-systems":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "developing-relationships":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "effective-teamwork":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "coaching-others":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "self-leadership":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "self-awareness":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "self-management":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "entrepreneurship":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "goal-achievement":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "digital":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "digital-fluency":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "software-use":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "software-development":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "understanding-digital-systems":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[labelSearch];
        }
      case "english-proficiency-drilldown":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && labelSearch) {
          params[`english_level`] = levelMap[labelSearch];
        }
      case "grammar":
        var labelSearch = searchParams.get("label");
        if (currentPathStr !== "english-proficiency-drilldown" && labelSearch) {
          params[`grammar_level`] = levelMap[labelSearch];
        }
      case "comprehension":
        var labelSearch = searchParams.get("label");
        if (
          currentPathStr !== "english-proficiency-drilldown" &&
          currentPathStr !== "grammar" &&
          labelSearch
        ) {
          params[`comprehension_level`] = levelMap[labelSearch];
        }
      default:
        break;
    }
    params['department'] = deapartmentFilter;
    console.log("pppppp", params, url);
    setLoading(true);
    // getCompleteRecords(url, params)
    dispatch(getDrillDownDataTable({ url: url, params: params }))
      .unwrap()
      .then(() => {
        setLoading(false);
        setGridLoading(false);
      });
  };
  useEffect(() => {
    dispatch(resetAll());
    getUsersAction();
  }, [location.pathname]);

  const handlePageChange = (page) => {
    dispatch(setPage(page));
    getUsersAction();
  };

  const handlePerRowsChange = (newPerPage, page) => {
    dispatch(setPage(page));
    dispatch(setPerPage(newPerPage));
    getUsersAction();

  };
  const handleSort = (column, sortDirection) => {
    // simulate server sort
    const sortMap = {
      Name_asc: "full_name",
      Name_desc: "fullname",
      Email_asc: "email",
      Email_desc: "email",
    };
    dispatch(setSortField(sortMap[`${column.name}_${sortDirection}`]));
    dispatch(setSortDirection(sortDirection === "desc" ? "-" : ""));
    getUsersAction();
  };
    return (
        <DataTableWrapper>
            <DataTable
        onRowClicked={onRowClicked}
        columns={columns}
        data={data}
        theme="solarized"
        sortServer
        onSort={handleSort}
        progressPending={gridLoading}
        progressComponent={
          <div
            style={{
              minHeight: "586px",
              backgroundColor: `${backgroundColor} !important`,
            }}
          >
            <DataTableLoaderWrapper>
              <LoaderWrapper>
                <Loader />
              </LoaderWrapper>
            </DataTableLoaderWrapper>
          </div>
        }
        noDataComponent={
          <div
            style={{
              minHeight: "586px",
              backgroundColor: `${backgroundColor} !important`,
            }}
          >
            <DataTableLoaderWrapper>
              <LoaderWrapper>
                <CustomHeading>{t("No Results Found")}</CustomHeading>
              </LoaderWrapper>
            </DataTableLoaderWrapper>
          </div>
        }
        pagination={true}
        paginationServer={true}
        paginationTotalRows={totalRows}
        onChangeRowsPerPage={handlePerRowsChange}
        onChangePage={handlePageChange}
        paginationComponentOptions={paginationOptions}
      />
            {loading && typeof data !== "undefined" && dataTableLoader}
    </DataTableWrapper>
    
    )
}