import React from 'react'
import styled from 'styled-components'
import ReactSpeedometer from "react-d3-speedometer"
const HeaderText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 20px;
/* or 101% */

text-align: center;

color: #202020;
margin-top:-84px;
@media (max-width: 500px) {
    margin-bottom:36px;
  }
`;
export default function ChartsView({ text, colors }) {

    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "360px", alignItems: "center" }}>
           
            <ReactSpeedometer
                minValue={0}
                maxValue={100}
                width={270}
                needleColor={"#532F54"}
                needleHeightRatio={0.7}
                customSegmentStops={[0, 50, 60, 75, 90, 100]}
                segmentColors={["#73284D", "#9B3668", "#BE598B", "#D24C8E", "#FF75B9"]}
                value={33}
            />
             {text ? <HeaderText> {text} </HeaderText>: null}
        </div>
    )
}
