import React from "react";
import { Col } from "react-bootstrap";
import { Ad2Card } from "../adCard";
import { Ad2CardVariantTwo } from "../adCardVariantTwo";
import { useNavigate } from "react-router-dom";
import { Utils } from "../utilities";
import { useTranslation } from "react-i18next";

export default function Ad2EmployabilityFactors({ data }) {
    const AD2_CONSTANTS = {
        MASTERY_DESCRIPTION: 'The Mastery domain contains the values Intellectual stimulation, Recognition, Achievement, Independence. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
        QUALITY_OF_LIFE_DESCRIPTION: 'The Quality of Life domain contains the values Variety, Security, Way of Life, Surroundings, and Economic Return. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
        FELLOWSHIP_DESCRIPTION: 'The Fellowship domain contains the values Altruism, Supervisory Relationship, Associates, Belonging, and Family. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
        CREATIVE_VALUES_DESCRIPTION: 'The Creating Value domain contains the values Aesthetic, Creativity, Leadership, and Protecting the Planet. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values.',
        IDEAS_AND_OPPORTUNITIES_DESCRIPTION: 'The Ideas and Opportunities domain contains the factors Spotting Opportunities, Creativity, Valuing ideas, Vision, and Ethical and Sustainable Thinking',
        RESOURCES_DESCRIPTION: 'The Resources domain contains the factors Self-efficacy, Self-awareness, Financial and economic literacy, Mobilising others, and Mobilising Resources.',
        INTO_ACTION_DESCRIPTION: 'The Into Action domain contains the factors Motivation and perseverance, Working with others, Planning and management, Learning through Experience, Coping with uncertainty, Taking the initiative.',
    }
    const { t, i18n } = useTranslation();
    const chartData = data && data.data;
    const status = data && data.status;
    const total = data && data.total;
    const columns = [
        {
            name: t('Ideas & Opportunities'),
            selectorKey: 'ideasAndOpportunities',
            selector: row => row.ideasAndOpportunities,
            sortable: false,
        },
        {
            name: t('Resources'),
            selectorKey: 'resources',
            selector: row => row.resources,
            sortable: false,
        },
        {
            name: t('Into Action'),
            selectorKey: 'intoAction',
            selector: row => row.intoAction,
            sortable: false,
        },
    ];
    const cardsData = [
        {
            title: t("Ideas & Opportunities"),
            description: t(AD2_CONSTANTS.IDEAS_AND_OPPORTUNITIES_DESCRIPTION),
            value: chartData && chartData[0] && chartData[0].ideasAndOpportunities,
        },
        {
            title: t("Resources"),
            description: t(AD2_CONSTANTS.RESOURCES_DESCRIPTION),
            value: chartData && chartData[0] && chartData[0].resources,
        },
        {
            title: t("Into Action"),
            description: t(AD2_CONSTANTS.INTO_ACTION_DESCRIPTION),
            value: chartData && chartData[0] && chartData[0].intoAction,
        },
    ];
    const navigate = useNavigate();
    const handleNavigate = Utils.handleNavigate.bind(this, navigate);


    const onClick = (where) => {
        switch (where) {
            case 0:
                handleNavigate('employability/ideas-and-opportunities-drilldown')
                break;
            case 1:
                handleNavigate('employability/resources-drilldown')
                break;
            case 2:
                handleNavigate('employability/into-action-drilldown')
                break;
            default:
                break;
        }
    }
    return <>
        <Ad2Card title={t("Employability Factors")} data={chartData} columns={columns} exportFileName="employability_factors" sum={total} status={status}>
            {
                cardsData.map((item, index) =>
                    <Col md="4" key={index}>
                        <Ad2CardVariantTwo {...item} onClick={onClick.bind(this, index)} />
                    </Col>)
            }
        </Ad2Card>
    </>;
}