import { useEffect, useState } from "react";
import styled from "styled-components";
import { HrLine, StudentModalHeader } from "../../../../components/analyticalDashboardPage/commonStyles";
import axios from "axios";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import PropTypes from "prop-types";
import Tabs, { tabsClasses } from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next"; //translation

const InterestProfileText = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  color: #000000;

  span {
    color: #d04c8d;
  }
`;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function InterestProfileModal(props) {
  const [modalData, setModalData] = useState("");
  const [usableData, setUsableData] = useState([]);
  const [usableDataLabels, setUsableDataLabels] = useState([]);

  //translation
  const { t, i18n } = useTranslation();
  const changeLanguageHandler = (lang) => {
    const languageValue = lang;
    i18n.changeLanguage(languageValue);
  };
  // end translation

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
        text: "",
      },
    },
    scales: {
      y: {
        max: 10,
      },
    },
  };

  const labels = [
    t("Mastery"),
    t("Quality of Life"),
    t("Fellowship"),
    t("Creating Value"),
  ];

  const fethUpdateData = () => {
    var datasLabel = [];
    var mainData = [];
    var backgroundColor = [];
    props?.data.map((v, ist) => {
      var x = Math.floor(Math.random() * 128 + 64);
      var y = Math.floor(Math.random() * 128 + 64);
      var z = Math.floor(Math.random() * 128 + 64);
      var bar_color = "rgba(" + x + "," + y + "," + z + ",0.5)";

      Object.keys(v).map((e, i) => {
        mainData.push(v[e]["points"]);
        backgroundColor.push(bar_color);
        datasLabel.push(labels[ist] + " - " + t(e));
      });
    });

    setUsableData({
      data: mainData,
      backgroundColor: backgroundColor,
      label: [t("work value")],
    });

    setUsableDataLabels(datasLabel);
  };

  useEffect(() => {
    fethUpdateData();
  }, []);

  const dataN = {
    labels: usableDataLabels,
    datasets: [usableData],
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <>
      <StudentModalHeader>{t("Work Values")}</StudentModalHeader>
      <HrLine />
      <Bar options={options} data={dataN} />
      {/* <StudentModalChartWrapper>
        <InterestProfileChart />
      </StudentModalChartWrapper> */}
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            variant="scrollable"
            scrollButtons
            aria-label="visible arrows tabs example"
            sx={{
              [`& .${tabsClasses.scrollButtons}`]: {
                "&.Mui-disabled": { opacity: 0.3 },
              },
            }}
          >
            {usableDataLabels.map((e, i) => {
              return <Tab label={e} {...a11yProps(i)} />;
            })}

            {/* <Tab label="Item Two" {...a11yProps(1)} />
            <Tab label="Item Three" {...a11yProps(2)} /> */}
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <p>
            {t(
              "It will be important for talents that value Intellectual Stimulation to look for roles that enable them to use their intellect to achieve results."
            )}
          </p>
          <br />
          <p>
            {t(
              "They value work that provides them with the opportunity to think independently, learn new things and be involved in innovation, critical thinking and creative problem solving."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <p>
            {t(
              "Talents who rated Recognition as being of high importance to them value work where they have the opportunity to receive acknowledgement or respect for both the effort they have put in, and the results that they have achieved."
            )}
          </p>
          <br />
          <p>
            {t(
              "It will be important for them to work in an environment where they will be able to gain recognition or status for their contribution."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <p>
            {t(
              "It is important for talents who value Achievement that they are in a role where they can gain a sense of achievement through setting goals, working hard and achieving results. They value developing new skills or achieving targets."
            )}
          </p>
          <br />
          <p>
            {t(
              "A work environment that recognises achievement in both the short term and the long term will provide them with a sense of satisfaction and progress."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={3}>
          <p>
            {t(
              "Talents who have rated Independence as being high importance to them value being able to make decisions on issues such as how they approach a task, how they prioritise their workload or diary and where and when they choose to carry out their work."
            )}
          </p>
          <br />
          <p>
            {t(
              "It will be important for them to work in a role with a high level of independence and autonomy."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <p>
            {t(
              "Variety Talents who have rated Variety as being of high importance to them may come from having a role that involves a range of diverse tasks, settings, client groups or from learning new things."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={5}>
          <p>
            {t(
              "Talents who rated Security as being of high importance to them value knowing that they have a job with a high level of security, despite fluctuations in the job market or economy. This includes clarity about the work necessary to enhance their job security."
            )}
          </p>
          <br />
          <p>
            {t(
              "It is important to them to have a role that provides you with security through a permanent contract."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={6}>
          <p>
            {t(
              "Talents who rated Way of Life as being of high importance to them value being able to balance their working life with their personal life through a job that provides them with the means and the flexibility to pursue a range of interests in their own time."
            )}
          </p>
          <br />
          <p>
            {t(
              "It is important to them to have a job that allows them to lead the type of lifestyle that they choose."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={7}>
          <p>
            {t(
              "It is very important to talents who rated Surroundings as being of high importance to them that they work in an environment which is pleasant and suited to their preferences."
            )}
          </p>
          <br />
          <p>
            {t(
              "For example, they may prefer to work outside, in a quiet environment where they can concentrate without distraction or value having the flexibility to choose where and when they complete their work. They also value having equipment that is fit for purpose."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={8}>
          <p>
            {t(
              "It is important to talents who value Economic Return that their job pays well and provides them with the opportunity to have a good standard of living."
            )}
          </p>
          <br />
          <p>
            {t(
              "This may include working in an organisation that provides a clear path of progression so that they have opportunities to enhance their salary as they gain skills, experience and qualifications."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={9}>
          <p>
            {t(
              "It is important to talents with high Altruism values to contribute to the welfare of others."
            )}
          </p>
          <br />
          <p>
            {t(
              "They value being in a position where their work leads to positive outcomes for others and enhances their lives."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={10}>
          <p>
            {t(
              "It is very important to talents who rated Supervisory Relationship as being of high importance to them, to have a good relationship with their line manager or supervisor."
            )}
          </p>
          <br />
          <p>
            {t(
              "Having a manager who is approachable, fair and who values their contribution means that they will be motivated to do their best at work."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={11}>
          <p>
            {t(
              "It is very important for talents who rated Associates as being of high importance to them, to work as part of a team, and they thrive when working with others towards a common goal."
            )}
          </p>
          <br />
          <p>
            {t(
              "They like to have a positive relationship with their colleagues and have opportunities to interact with them on a regular basis."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={12}>
          <p>
            {t(
              "It is very important for talents who rated Belonging as being of high importance to them, to have a sense of belonging to the organisation that they work for."
            )}
          </p>
          <br />
          <p>
            {t(
              "This is enhanced in organisations who have a clear sense of purpose that they believe in and values their individual employees."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={13}>
          <p>
            {t(
              "It is important for talents who value Family to have a career that their family would approve of and be proud of and that would bring benefits to their family as well as to themselves."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={14}>
          <p>
            {t(
              "It is very important for talents who rated Aestetic as being of high importance to them, to be involved in work that creates beauty, designs beautiful products, or creates beautiful spaces or landscapes for people to enjoy."
            )}
          </p>
          <br />
          <p>
            {t(
              "They usually would thrive in an environment that values aesthetics and where they are given the opportunity to use their artistic skills to create beauty through the work that they do."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={15}>
          <p>
            {t(
              "Talents who value Creativity work best when they are free to be creative. This may involve solving difficult problems, creating new products, looking at an issue from different angles or creating innovative solutions."
            )}
          </p>
          <br />
          <p>
            {t(
              "They understand that the creative process needs space to thrive, and they value environments that enables them to work in this way rather than sticking to specific rules or processes."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={16}>
          <p>
            {t(
              "It is very important for talents that value Leadership to have opportunities to take on a leadership role."
            )}
          </p>
          <br />
          <p>
            {t(
              "This may be through leading others, taking on responsibility for specific projects or developing strategies."
            )}
          </p>
        </TabPanel>
        <TabPanel value={value} index={17}>
          <p>
            {t(
              "It is important for talents who value Protecting the Planet that they work for an organisation that takes its responsibility for the planet seriously."
            )}
          </p>
          <br />
          <p>
            {t(
              "Roles that enable them to live and work in a sustainable way or facilitate them to actively protect the planet will align with their values."
            )}
          </p>
        </TabPanel>
      </Box>
    </>
  );
}

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
