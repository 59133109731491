import "./dashboardPopup.css";
import paymentAds from '../../assets/ads/payment-ads.png';
import { useTranslation } from "react-i18next";


function DashboardPopup({ isOpen, setOpen, popupStatus }) {
    const {t} = useTranslation();

    const displayCheck = () => {
        localStorage.setItem(popupStatus, 1);
    };
    return (
        <>
            {/* {isOpen === true ? ( */}
                {false? (
                <div className="modalHead">
                    <div className="modalBackground"
                        onClick={() => setOpen(false)}
                    />
                    <div className="modalContainer">
                        <div className="titleCloseBtn">
                            <p></p>
                            <p
                                onClick={() => setOpen(false)}
                                style={{
                                    cursor: "pointer",
                                }}
                            >
                                {t('Close X')}
                            </p>
                        </div>
                        <img src={paymentAds} alt={"intPay"}/>
                        <div className="bottom_tab" alt="Internship Payment Details">
                            <label
                            className="dashLabel"
                               
                            >
                                <input
                                    type="checkbox"
                                    style={{ marginRight: "5px" }}
                                    onClick={displayCheck}
                                />
                                '{t("Don't display this again")}'
                            </label>
                        </div>
                    </div>
                </div>
            ) : (
                ""
            )}
        </>
    );
}

export default DashboardPopup;
