import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  imageBaseData: {}
};

export const imageReduce = createSlice({
  name: "imageBase64",
  initialState,
  reducers: {
    updateImageBase: (state, action) => {  
        console.log("actionPayload",action.payload)
    state.imageBaseData[action.payload.name] = action.payload.value;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateImageBase } = imageReduce.actions;

export default imageReduce.reducer;
