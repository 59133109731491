import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";



function BulkStudentSuccessError(props) {
    return (
        <div>
            <Modal show={props.show} onHide={props.close} backdrop={props.backdropMode == "error" ? "false" : "static"} centered>
                <div style={{ padding: '9px' }}>
                    <ModalHeader></ModalHeader>
                    <ModalHeaderText>{props.title}</ModalHeaderText>
                    <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
                    {props.content}
                </div>
            </Modal>
        </div>
    );
}

export default BulkStudentSuccessError;
