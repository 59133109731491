import { Row, Col } from "react-bootstrap";
import ApplicantsHeader from "./ApplicantsHeader";
import Applicants from "./Applicants";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import moment from "moment";

import MainLayout from "../../layouts/mainLayout/mainLayout";
import AlertModalReview from "../../components/alertModalNew/alertModalReview";
import Loader from "../../components/loader/loader";
import TextArea from "../../components/description/description";
import ErrorField from "../../components/errorField/errorField";
import Button from "../../components/button/button";
import { useLocation, Link } from "react-router-dom";
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from "react-i18next";

import {
  ongettingInternInfo,
  getApplicantDetails,
  jobAddNotes,
} from "../../services/apiCalls";
import InfiniteScroll from "react-infinite-scroll-component";

const SingleField = styled.div`
  margin-bottom: 30px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const Root = styled("div")(
  ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${grey[200]};
      text-align: left;
      padding: 6px;
    }
  
    th {
      background-color: ${grey[100]};
    }
    `
);

const FormWrapper = styled.div`
  margin: 25px 0px;
  padding: 25px 70px 29px 63px;
  background: #ffffff;
  border-radius: 10px;
  @media (max-width: 767px) {
    padding: 25px 8px 29px 8px
  }

  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 18px 0px;
    color: ${Colors.labelText};
  }
  > p1 {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
  > p2 {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;

const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  margin: 10px auto;
`;

const HeaderTitle = styled.p`
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
`;

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
    & .MuiTablePaginationUnstyled-spacer {
      display: none;
    }
    & .MuiTablePaginationUnstyled-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
    & .MuiTablePaginationUnstyled-selectLabel {
      margin: 0;
    }
    & .MuiTablePaginationUnstyled-select {
      padding: 2px;
      border: 1px solid ${grey[200]};
      border-radius: 50px;
      background-color: transparent;
      &:hover {
        background-color: ${grey[50]};
      }
      &:focus {
        outline: 1px solid ${blue[200]};
      }
    }
    & .MuiTablePaginationUnstyled-displayedRows {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
    & .MuiTablePaginationUnstyled-actions {
      padding: 2px;
      border: 1px solid ${grey[200]};
      border-radius: 50px;
      text-align: center;
    }
    & .MuiTablePaginationUnstyled-actions > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: transparent;
      &:hover {
        background-color: ${grey[50]};
      }
      &:focus {
        outline: 1px solid ${blue[200]};
      }
    }
    `
);

export default function Application(props) {
  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");

  const org = useSelector((state) => state.org.orgData);
  const code = org?.additional_info?.org_code;

  const [showConfirmationModalAddNotes, setShowConfirmationModalAddNotes] =
    useState(false);
  const [idInternShip, setInternShip] = useState("");
  const [app_id, setAppId] = useState("");
  const [theArray, setTheArray] = useState([]);
  const [internData, setInternData] = useState([]);
  const [applicantData, setApplicantData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showApplicants,] = useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(50);
  const [page, setPage] = React.useState(0);
  const [selectedProfiles, setSelectedProfiles] = useState(0);
  const { t, } = useTranslation();
  const [total,setTotal] = useState(0);
  const [currentPage,setCurrentPage] = useState(1)
  const schema2 = yup.object().shape({
    notes: yup.string().required("Message is a required field."),
  });
  const page_size = 5;
  const {
    control: control2,
    handleSubmit: handleSubmit2,
    setValue: setValue2,
    formState: { errors: errors2 },
  } = useForm({ resolver: yupResolver(schema2) });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRowsAdd =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;

    const fetchDataLists = async () => {
      await getInternData();
      await getApplicantData();
    }
  useEffect(() => {

    fetchDataLists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getApplicantData = async (pageNumber) => {
    console.log("pageGettet",pageNumber)
    const params = {
      page_size :page_size,
      page: pageNumber || 1
    }
    const {data}  = await getApplicantDetails(id,params);
    if (data && data.results.length > 1) {

      setApplicantData(data?.results);
      const total = Math.ceil(data.count/ page_size) 
      setTotal(total)
      console.log("pageNumber",pageNumber,currentPage)
      if((pageNumber ?? currentPage) > 1){
        setApplicantData(
          applicantData.length ? [...applicantData, ...data?.results] : [...data?.results]
        );
      }else{
        setApplicantData(data?.results.length ? [...data?.results] : []);
      }
      console.log("applicationData123",applicantData)
      data.results.forEach((app) => {
        var arr = app.internship_notes?.map((note, index) => ({
          ...note,
          key: `${app.applied_user_id}-${index}`,
        })) ?? [];
        setTheArray((prevArray) => [...prevArray, ...arr]);
      });
      const profileCounter = data.results?.filter((item) => item.talent_intern_status === "offered").length;
      setSelectedProfiles(profileCounter);
    }
    setLoading(false);

  };

  const closeConfirmationModalAddNotes = () => {
    setShowConfirmationModalAddNotes(false);
  };

  const getInternData = async () => {
    setLoading(true);
    const { data } = await ongettingInternInfo({ id: id, code: code });
    if (data && data.status === 1) {
      setInternData(data?.data);
    } else {
      setLoading(false);
    }
  };

  const handleApproveAddNotes = (e) => {
    e.stopPropagation();
    setShowConfirmationModalAddNotes(true);
  };

  const handleIdNotes = (e, data) => {
    e.stopPropagation();
    setInternShip(data.user_documents);
    setAppId(data.applied_user_id);
    setTheArray(data.internship_notes);
    // setShowConfirmationModalAddNotes(true);
  };

  const showDetails = () => {
    showApplicants(false);
  };

  const fetchMoreApplicants = () =>{
    if(total > currentPage){
      setCurrentPage(currentPage + 1)
      getApplicantData(currentPage + 1);
    }
  }

  const onSubmit2 = async (data) => {
    const body = {
      internship: idInternShip.apply_internship,
      notes: data.notes,
      intern_user: idInternShip.apply_user,
    };

    // await setStatus(true);
    const result = await jobAddNotes(body);
    if (result.status === 200) {
      if (result.data.message === "success") {
        setShowConfirmationModalAddNotes(false);
        body.notes = "";
        data.notes = "";
        await toast.success(t("Notes added successfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        getApplicantData();
        setValue2("notes", "");
      }
    }
  };

  const noteModal = () => {
    return (
      <>
        <form onSubmit={handleSubmit2(onSubmit2)}>
          <div className="container">
            <SingleField>
              <FormLabel>{t('Add Notes')}</FormLabel>
              <Controller
                name="notes"
                control={control2}
                render={({ field }) => (
                  <TextArea
                    style={{ background: "#F3F3F3" }}
                    {...field}
                    rows="6"
                    placeholder={t("Enter Note")}
                  />
                )}
              />
              {errors2.notes && <ErrorField err={t(errors2.notes.message)} />}
            </SingleField>
            <Button
              width="100px"
              name={t("Save")}
              className="mt-0 mb-5"
              type="submit"
              style={{ float: "right" }}
            />
          </div>
        </form>
        <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t('Notes')}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t('Created')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                    ? theArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : theArray
                  ).map((row) => (
                    <>
                      {row.intern_user === app_id ? (
                        <tr key={row.notes}>
                          <td>{row.notes}</td>
                          <td style={{ width: 200 }} align="right">
                            {moment(new Date(row.created_at)).format(
                              "DD/MM/YYYY hh:mm:ssA"
                            )}
                          </td>
                        </tr>
                      ) : (
                        ""
                      )}
                    </>
                  ))
                  : ""}
                {emptyRowsAdd > 0 && (
                  <tr style={{ height: 41 * emptyRowsAdd }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              {theArray?.length > 0 ? (
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        10,
                        20,
                        30,
                        { label: t("All"), value: -1 },
                      ]}
                      colSpan={3}
                      count={theArray?.length ?? "0"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      componentsProps={{
                        select: {
                          "aria-label": t("rows per page"),
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tr>
                </tfoot>
              ) : (
                <div className="container col-lg-12">{t('No Comments')}</div>
              )}
            </table>
          </Root>
        </div>
      </>
    );
  };

  return (
    <MainLayout>
      {loading ? (
        <Loader />
      ) : (<>
        <div role="presentation">
          <div
            className="d-flex mb-5"
            style={{
              fontSize: "1.1rem",
              fontWeight: "400",
              marginLeft: "15px",
            }}
          >
            <Link
              to="/internships"
              style={{ textDecoration: "none", color: "#ACACAC" }}
            >
              {t('Internships')} &nbsp; &#62;
            </Link>{" "}
            <Link
              to={"/internships/details?id=" + id}
              style={{ textDecoration: "none", color: "#ACACAC" }}
              className="mx-2"
              // onClick={nav}
            >
              {t('View Internship')} &nbsp; &#62;
            </Link>
            <div className="" style={{ color: "#ACACAC" }}></div>
            <span style={{ textDecoration: "none", color: "#D04C8D" }}>
              {t('View Applicants')}
            </span>
          </div>
        </div>
        <FormWrapper>
          <ApplicantsHeader data={internData} />
          <HrLine />
          {applicantData.length <= 0 ? (
            <Row className="my-2">
              <Col sm={3}>
                <HeaderTitle>{t('Applicants')}</HeaderTitle>
              </Col>
              <Col sm={3} className="d-flex justify-content-center">
                <HeaderTitle>{t('Joining Date')}</HeaderTitle>
              </Col>
              <Col sm={3} className="d-flex justify-content-center">
                <HeaderTitle>{t('Leaving Date')}</HeaderTitle>
              </Col>
              <Col sm={3} className="d-flex justify-content-center">
                <HeaderTitle>{t('Status')}</HeaderTitle>
              </Col>
            </Row>
          ) : (
            <Row className="my-2">
              <Col sm={12} md={3} xd={6}>
                <HeaderTitle>{t('Applicants')}</HeaderTitle>
              </Col>
              <Col sm={12} md={3} xd={2} className="d-flex justify-content-center">
                <HeaderTitle>{t('Joining Date')}</HeaderTitle>
              </Col>
              <Col sm={12} md={3} xd={2} className="d-flex justify-content-center">
                <HeaderTitle>{t('Leaving Date')}</HeaderTitle>
              </Col>
              <Col sm={12} md={2} xd={5} className="d-flex justify-content-center">
                <HeaderTitle>{t('Status')}</HeaderTitle>
              </Col>
            </Row>
          )}

          {applicantData.length <= 0 ? (
            <p className="d-flex justify-content-center">{t('No Applicant found')}</p>
          ) : (
            <InfiniteScroll
            dataLength={applicantData.length}
            hasMore={currentPage < total}
            next={fetchMoreApplicants}
            scrollThreshold={0.5}
            >
{
   applicantData?.map((applicant, key) => {
    return (
      <div key={applicant.emailId + key}>
        <Applicants
          data={applicant}
          id={internData?.id}
          totalVacancy={internData?.no_positions}
          selectedProfiles={selectedProfiles}
          setSelectedProfiles={setSelectedProfiles}
          joiningDate={internData?.exp_start_date}
          leavingDate={internData?.exp_end_date}
          handleApproveAddNotes={handleApproveAddNotes}
          handleIdNotes={handleIdNotes}
        />
        {key !== applicantData.length - 1 ? <HrLine /> : null}
      </div>
    );
  })
}
            </InfiniteScroll>
           
          )}
        </FormWrapper>
      </>)}
      <AlertModalReview
        show={showConfirmationModalAddNotes}
        close={closeConfirmationModalAddNotes}
        content={noteModal()}
        id={idInternShip}
      />
    </MainLayout>
  );
}
