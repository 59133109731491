import React, { useState } from "react";

import styled from "styled-components";
import Button from "../button/button";
import { Colors } from "../../utilities/colors";
import DeleteIcon from "../../assets/icons/svg/trash.svg";
import EditIcon from "../../assets/icons/svg/pencil.svg";
import UpdateContact from "../updateContact/updateContact";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  viewContactData,
  deleteContactData,
  getCompanyData,
} from "../../services/apiCalls";
import { useSelector, useDispatch } from "react-redux";
import {
  updateContactData,
  updateEdit,
} from "../../components/AddContact/reducer";
import AlertModal from "../alertModal/alertModal";
import { updateCompanyData } from "../../pages/myCompany/reducer";
import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

const TableTop = styled.table`
  overflow-x: scroll;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: ${Colors.labelText};
  padding-bottom: 10px;
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 10px;
  color: #525252;
  text-transform: capitalize;
  overflow-wrap:anywhere;
`;
const SpanText = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #bcbcbc;
  text-transform: lowercase;
`;
const Icon = styled.img`
  margin: 0px 5px;
  cursor: pointer;
`;
const IconWrapper = styled.div`
  justify-content: flex-end;
  display: flex;
`;
const Message = styled.div`
  // padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
export default function Table({ data }) {
  const {t,} = useTranslation();
  const dispatch = useDispatch();
  const org = useSelector((state) => state.org.orgData);
  const contact = useSelector((state) => state.contact.contactNewData);
  const getData = async () => {
    const { data } = await getCompanyData({
      code: org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      dispatch(updateCompanyData(data.data));
    }
  };
  const defaultVal = {
    email: contact && contact.email ? contact.email : "",
    first_name: contact && contact.first_name ? contact.first_name : "",
    last_name: contact && contact.last_name ? contact.last_name : "",
    job_title: contact && contact.job_title ? contact.job_title : "",
    department: contact && contact.department ? contact.department : "",
    mob_number: contact && contact.mob_number ? contact.mob_number : "",
  };
  const [open, setOpen] = React.useState(false);
  const [ids, setids] = React.useState(false);
  const [id, setId] = useState(false);
  const [show, setShow] = useState(false);
  const closeModal = () => {
    setShow(false);
  };
  const deleteModal = (id) => {
    setShow(true);
    setId(id);
  };
  const onDelete = async () => {
    const { data } = await deleteContactData({
      id: id,
      code: org?.additional_info?.org_code,
    });
    closeModal();
    if (data && data.status === 1) {
      toast.success(t("Contact Successfully Deleted"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      getData();
    }
  };
  const content = () => {
    return (
      <>
        <Message>{t('Are you sure want to delete this contact?')}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="40px 0px 30px 0px"
              name={t('Yes')}
              onClick={onDelete}
            />
            <Button
                  secondary
                  color="#FFFFFF"
                  name={t('No')}
                    style={{
                      margin:"40px 5px 30px 10px",
                    }}
                    variant="outlined"
                    onClick={closeModal}
                  >
              </Button>
          </Col>
        </Row>
      </>
    );
  };
  const onChangeContent = async (id) => {
    setids(id);
    const { data } = await viewContactData({
      code: id,
      id: org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      dispatch(updateContactData(data.data));
      dispatch(updateEdit(true));
      setOpen(true);
    }
  };
  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <>
      <TableTop responsive>
        <thead>
          <tr>
            <TableHead style={{minWidth:'150px'}}>{t('Contact person')}</TableHead>
            <TableHead style={{minWidth:'100px'}}>{t('Job title')}</TableHead>
            <TableHead style={{minWidth:'100px'}}>{t('Date joined')}</TableHead>
            <TableHead></TableHead>
          </tr>
        </thead>
        <tbody>
          {data.length > 0 ? (
            data.map((x, index) => (
              <tr key={x.id}>
                <TableData>
                  {x.first_name}
                  <br />
                  <SpanText>{x.email}</SpanText>
                </TableData>
                <TableData>{x.job_title}</TableData>
                <TableData>{x.created_at}</TableData>
                {/*<TableData>
                  <IconWrapper>
                    <Icon
                      src={EditIcon}
                      onClick={() => {
                        onChangeContent(x.id);
                      }}
                    />
                    
                    <Icon onClick={() => deleteModal(x.id)} src={DeleteIcon} />
                  </IconWrapper>
                    </TableData>*/}
              </tr>
            ))
          ) : (
            <tr>
              <TableData><p class="noDataFound">{t('No data found')}</p></TableData>
            </tr>
          )}
        </tbody>
        {open && (
          <UpdateContact
            open={open}
            onClose={onCloseModal}
            id={ids}
            data={defaultVal}
          />
        )}
      </TableTop>
      <AlertModal show={show} close={closeModal} content={content()} />
    </>
  );
}
