import { getYear } from "date-fns";
import React from "react";
import { ButtonGroup, ToggleButton } from "react-bootstrap";
// import GenderBarLineChart from "../../../../components/charts/genderBarLineChart";
// import Selector from "../../../../components/select/select";
// import { Ad2Card } from "../../ad2-shared/ad2Card/ad2Card";

import { ReactComponent as Sum } from "../../../assets/svg/sum.svg";
import { ReactComponent as RatioPie } from "../../../assets/svg/ratioPie.svg";
// import {
//   Ad2ButtonGroupWrapper,
//   Ad2SelectorWrapper,
//   SumText,
//   SumWrapper,
// } from "../../styles/Ad2Styles";
// import { Utils } from "../../../../utilities/utils";
import { useTranslation } from "react-i18next";
import {
  Ad2ButtonGroupWrapper,
  Ad2SelectorWrapper,
  SumText,
  SumWrapper,
} from "../adCardStyles";
import { Utils } from "../utilities";
import { Ad2Card } from "../adCard";
import Selector from "../select";
import GenderBarLineChart from "../charts/genderBarLineChart";

export function AnalyticalGenderDrillDown({
  title,
  ratioStudent,
  TotalStudent,
  refreshData,
  data,
}) {
  const { t, i18n } = useTranslation();
  const chartData = data && data.data;
  const status = data && data.status;
  const [radioValue, setRadioValue] = React.useState("month");
  const yearVal = getYear(new Date());
  const [year, setYear] = React.useState({ value: yearVal, label: yearVal });
  const maleDistributionByDate = chartData && chartData.map((x) => x.male);

  const femaleDistributionByDate = chartData && chartData.map((x) => x.female);
  const dateDistributionByDate = chartData && chartData.map((x) => x.date);
  const totalDistributionByDate =
    chartData && chartData.map((x) => x.male + x.female);

  const columns = [
    {
      name: t("Date"),
      selectorKey: "date",
      selector: (row) => row.date,
      sortable: false,
    },
    {
      name: t("Male"),
      selectorKey: "male",
      selector: (row) => row.male,
      sortable: false,
    },
    {
      name: t("Female"),
      selectorKey: "female",
      selector: (row) => row.female,
      sortable: false,
    },
  ];

  React.useEffect(() => {
    refreshData(radioValue, year);
  }, []);

  React.useEffect(() => {
    refreshData(radioValue, year);
  }, [radioValue, year.value]);

  let value;
  if (title === "OnBoarded") {
    value = ratioStudent[0];
  } else if (title === "Un-Registered") {
    value = ratioStudent[1];
  } else {
    value = null;
  }

  function RightContent({ radioValue, setRadioValue, year, setYear }) {
    const yearNow = getYear(new Date());
    const years = new Array(10).fill(null).map((item, index) => {
      const year = yearNow - index;
      return { value: year, label: year };
    });

    return (
      <>
        <Ad2ButtonGroupWrapper>
          <ButtonGroup>
            {[
              { name: t("Day"), value: "day" },
              { name: t("Week"), value: "week" },
              { name: t("Month"), value: "month" },
            ].map((radio, idx) => (
              <ToggleButton
                key={idx}
                id={`radio-${idx}`}
                type="radio"
                name="radio"
                value={radio.value}
                variant="asdf"
                checked={radioValue === radio.value}
                onChange={(e) => setRadioValue(e.currentTarget.value)}
              >
                {radio.name}
              </ToggleButton>
            ))}
          </ButtonGroup>
        </Ad2ButtonGroupWrapper>
        <Ad2SelectorWrapper>
          <Selector
            onChange={(value) => {
              setYear(value);
            }}
            options={years}
            defaultValue={year}
          />
        </Ad2SelectorWrapper>
      </>
    );
  }
  function LeftContent({ sum, ratio }) {
    return (
      <>
        {sum && (
          <SumWrapper style={{ marginLeft: "10px" }}>
            <Sum />
            <SumText>&nbsp;:&nbsp;{Utils.intToString(sum)}</SumText>
          </SumWrapper>
        )}
        {ratio && (
          <SumWrapper>
            <RatioPie />
            <SumText>&nbsp;:&nbsp;{ratio}</SumText>
          </SumWrapper>
        )}
      </>
    );
  }
  console.log("datatat", data);

  return (
    <>
      <Ad2Card
        title={title.replace(/\+/g, " ")}
        data={chartData}
        columns={columns}
        exportFileName="registered_employees"
        status={status}
        leftContent={<LeftContent sum={TotalStudent} ratio={value} />}
        rightContent={
          <RightContent
            radioValue={radioValue}
            setRadioValue={setRadioValue}
            setYear={setYear}
            year={year}
          />
        }
      >
        <div>
          <GenderBarLineChart
            data={{
              datasets: [
                {
                  label: t("Total Employees"),
                  data: totalDistributionByDate,
                  borderColor: "#004B84",
                  borderWidth: 2,
                  type: "line",
                },
                {
                  label: t("Male"),
                  data: maleDistributionByDate,
                  backgroundColor: "#004B84",
                  borderRadius: Number.MAX_VALUE,
                  borderSkipped: false,
                  borderColor: "rgba(0,0,0,0)",
                  barPercentage: 1,
                  categoryPercentage: 0.6,
                  type: "bar",
                },
                {
                  label: t("Female"),
                  data: femaleDistributionByDate,
                  backgroundColor: "#D84E8D",
                  borderRadius: Number.MAX_VALUE,
                  borderSkipped: false,
                  borderColor: "rgba(0,0,0,0)",
                  barPercentage: 1,
                  categoryPercentage: 0.6,
                  type: "bar",
                },
              ],
              labels: dateDistributionByDate,
            }}
          />
        </div>
      </Ad2Card>
    </>
  );
}
