import { formatDate } from "../../utilities/commonFunctions";

const initialState = {
  full_name: "",
  email: "",
  position: "",
  department: "",
  date_joined: formatDate(new Date()),
  gender: "male"
};

export default initialState;
