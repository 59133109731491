import React from 'react'
import styled from 'styled-components'
import { BoxWrapper, NormalText, SpanText, SubHeading, ContentText } from './common'
const Section = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:space-between;
  margin-bottom:32px;
`;
export default function EventsProjects() {
    return (
        <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>Events & Projects</SubHeading>
            <hr />
            <Section>
                <div style={{ maxWidth: "70%" }}>
                    <NormalText>
                        NGO Organizer
                    </NormalText>
                    <ContentText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    </ContentText>
                </div>
                <SpanText>24 Months</SpanText>
            </Section>

            <Section>
                <div style={{ maxWidth: "70%" }}>
                    <NormalText>
                        NGO Organizer
                    </NormalText>
                    <ContentText>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                    </ContentText>
                </div>
                <SpanText>24 Months</SpanText>
            </Section>

        </BoxWrapper>
    )
}
