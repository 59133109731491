import styled from "styled-components";
import "./loginmodal.css";
import LoginModal from "../modal/LoginModal";
import RegisterLogo from "../../assets/RegisterCompany.png";
import { Colors } from "../../utilities/colors";
import { useTranslation } from "react-i18next";
import Button from "../../components/button/button";
import { useNavigate } from "react-router-dom";

const Logo = styled.img`
  max-height:420px;
  transform: rotate(-13.41deg);
  position: relative;
  top: 30px;
}
`;

const Wrapper = styled.div`
  text-align: center;
  // max-width: 420px;
  position: relative;
  // left: 180px;
  // width: 351px;
  height: 575px;
  display: flex;
  justify-content: center;
  @media (max-width: 500px) {
    left: 0px;
    width: auto;
    overflow: hidden;
    height: 661px;
  }
`;
const Header = styled.h3`
  font-weight: bold;
  font-size: 30px;
  line-height: 41px;
  color: ${Colors.primary};
  margin-left: 50px;
  @media (max-width: 500px) {
    position: relative;
    font-size: large;
    width: 431px;
    margin-left: 16px;
  }
`;
const TextContent = styled.div`
  position: absolute;
  bottom: 180px;
  left: 47%;
  top: 60%;
  transform: translatex(-50%);
  @media (max-width: 500px) {
    left: 160px;
    top: 54%;
  }
`;
const Description = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 25px;
  line-height: 40px;
  > span {
    color: #202020;
    margin-left: 50px;
    display: block;
  }
  @media (max-width: 500px) {
    position: relative;
    margin-left: -49px;
    font-size: 18px;
    line-height: 24px;
  }
`;
const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 100px;
  margin-left: 90px;
  @media (max-width:500px){
    position: absolute;
    bottom: 138px;
    margin-left: 73px;
  }
`;

export default function LoginSuccessModal(props) {
  const { t } = useTranslation();

  const navigate = useNavigate();
  return (
    <LoginModal
      open={props.open}
      showCloseIcon={false}
      onCloseModal={props.onClose}
      width="651px"
    >
      <Wrapper>
        <Logo
          src={RegisterLogo}
          alt="success logo"
          className="logoLogin"
        />
        <TextContent>
          <Header>{t("Welcome to MyNext Company")}</Header>
          <Description  className="loginDescription">
            <span>{t("You’re almost there!")} </span>

            <span>{t("You can start to register your company")}</span>
          </Description>
        </TextContent>
        <ButtonWrapper>
          <div className="col-lg-12 col-sm" >
            <Button
              style={{ cursor: "pointer" }}
              name={t("Register Company")}
              onClick={() => navigate("/register")}
              className="registerButton"
            />
          </div>
        </ButtonWrapper>
      </Wrapper>
    </LoginModal>
  );
}
