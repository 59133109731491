import { createSlice } from "@reduxjs/toolkit";
import { act } from "react-dom/test-utils";


const initialState = {
  subscriptionData: {},
  requestNewSubscription: false
};

export const subscriptionReducer = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    updateSubscriptionData: (state, action) => {
      let k = Object.keys(action.payload)[0]
      const findSub = action.payload[k].find((e)=>e.paymentstatus==='success')
      if(findSub) state.subscriptionData[k]=findSub
      else state.subscriptionData[k] = action.payload[k][0]
      
    },
    updateRequestNewSubscription: (state, action) => {
      state.requestNewSubscription = action.payload
    },
    
  },
});

// Action creators are generated for each case reducer function
export const { updateSubscriptionData, updateRequestNewSubscription} = subscriptionReducer.actions;

export default subscriptionReducer.reducer;
