import React from 'react'
import { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import "react-phone-number-input/style.css";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { completionOfInternshipObj, schemaCompletion } from "../MySIPDirectHiring/staticContent";

import {
  createNewIntern,
  getInternScrollList,
  uploadObject, // getCountries,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
  getScopeOfStudyMasterList,
  getSkillMasterList,
  ongettingInternInfo,
  onUpdatingInternInfo,
  getOrganizationInternshipCoinDetail,
  getCompletionForm,
  getCompletionLearningOutcomes,
  getCompletionTraningProviderType,
  getCompletionAdditionalAllowance,
  postCompletionform,
  getCompletionFormComments,
  updateCompletionForm,
  CheckToken
} from "../../services/apiCalls";
import PaginationDivider from '../MySIPDirectHiring/paginationDividerSip';
import LocationTable from "../../components/locationTable/locationTable";
import { formatDate } from "../../utilities/commonFunctions";
import { updateValues } from '../registerorganisation/reducer';
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { internFields as staticInternFields } from '../MySIPDirectHiring/staticContent';
import { Link } from "react-router-dom";
import moment from "moment";
import PaymentPopup from "../../components/paymentPopup/paymentPopup";
import { useTranslation } from "react-i18next";
import AlertModal from "../../components/newInternModal/alertModal";
import "../MySIPDirectHiring/addNewInternship.css";
import Tooltip from "../../components/reactTooltip/tooltip";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import Button from "../../components/button/button";
import Loader from "../../components/loader/loader";
import ErrorField from "../../components/errorField/errorField";
import Input from "../../components/input/input";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import SkillCard from "../../components/skillCard/skillCard";
import CompletionOfInternshipOverview from './completionOfInternshipOverview';
import CompletionOfInternshipFormLoe from './completionOfInternshipFormLoe';
import { initialAddIntern } from "../../pages/MySIPDirectHiring/addInternValidation";
import { TablePagination } from "@mui/base";
import { differenceInWeeks, differenceInDays } from 'date-fns';
import CreateInternshipModuleModal from "../../components/createInternshipModule/createInternshipModal";






const materialTheme = createTheme({
    palette: {
      primary: {
        main: "#D44B9C",
      },
    },
    overrides: {
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: "#D44B9C",
        },
      },
    },
  });
  // styling starts
  const NewInternPageWrapper = styled.div`
    background: ${Colors.light};
    border-radius: 10px;
    padding: 2rem;
    margin: 2rem 0;
    @media (min-width: 767px) and (max-width: 1023px) {
      margin: 0 -10px 0 -20px;
    }
  `;
  const SectionWrapper = styled.section``;
  const HeaderText = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    margin: 0;
    color: ${Colors.dark};
  `;
  
  const HeaderText2 = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    margin: 0;
    color: ${Colors.dark};
  `;
  const Description = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 5px 0;
    color: #858585;
  `;
  const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
  `;
  const FileWrapper = styled(FlexWrapper)`
    margin: 10px 0;
    flex-wrap: wrap;
  `;
  const GridWrapper = styled.div`
    display: grid;
    margin-bottom: 30px;
  `;
  // const LogoWrapper = styled(FlexWrapper)`
  //   margin: 20px 0;
  //   > img {
  //     margin-right: 20px;
  //   }
  //   @media (max-width: 650px) {
  //     flex-direction: column;
  //   }
  // `;
  // const CompanyHeader = styled.h4`
  //   font-style: normal;
  //   font-weight: 500;
  //   font-size: 20px;
  //   line-height: 27px;
  //   margin: 0;
  // `;
  const FormWrapper = styled.div`
    margin-top: 20px;
  `;
  const FormGroup = styled.div`
    min-width: 240px;
    width: 100%;
    label {
      width: unset !important;
    }
  `;
  const SingleField = styled.div`
    margin-bottom: 30px;
  `;
  const FormLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    display: block;
    color: ${Colors.labelText};
    visibility: ${(props) => (props.visible ? "hidden" : "default")};
    margin-bottom: 5px;
    > span {
      font-style: italic;
      font-weight: 300;
      font-size: 14px;
    }
  `;
  const InternTypeWrapper = styled(FlexWrapper)``;
  // const InternDateWrapper = styled(GridWrapper)`
  //   grid-template-columns: 1fr 1fr 0.5fr;
  //   grid-column-gap: 20px;
  //   @media (max-width: 900px) {
  //     grid-template-columns: repeat(1, 1fr);
  //   }
  //   label {
  //     position: relative;
  //     width: -webkit-fill-available;
  //   }
  // `;
  const UploadText = styled.h3`
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    margin-left: 5px;
    color: ${Colors.labelText};
  `;
  const UploadNotes = styled.p`
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    margin-bottom: 5px;
    color: #a5a0a0;
  `;
  const UploadFileText = styled.label`
    margin: 0px;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: ${Colors.primaryText};
  `;
  const UploadFileWrapper = styled.label`
    position: relative;
    cursor: pointer;
    > input {
      position: absolute;
      opacity: 0;
      width: 100%;
    }
  `;
  const DividerLine = styled.hr`
    border: 0.5px solid #acacac;
    margin: 30px 0;
  `;
  const FormHeaderText = styled.h3`
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 25px;
    color: ${Colors.dark};
  `;
  const InfoHeaderText = styled.h3`
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 25px;
    color: ${Colors.dark};
  `;
  const AcademicGridWrapper = styled(GridWrapper)`
    grid-template-columns: ${(props) =>
      props.template ? props.template : "3fr 1fr"};
    grid-column-gap: 20px;
    @media (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
    label {
      position: relative;
      //width: -webkit-fill-available;
    }
  `;
  
  const ButtonWrapper = styled(FlexWrapper)`
    justify-content: space-between;
    margin-top: 40px;
    @media (max-width: 600px) {
      flex-direction: column;
    }
    @media (max-width: 1024px) {
      justify-content: space-between;
    }
  `;
  const LabelText = styled.label`
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  `;
  const SaveExitBtn = styled(LabelText)`
    color: #504f8c;
    text-decoration: underline;
    cursor: pointer;
    @media (max-width: 800px) {
      width:100%;
      text-align:center;
    }
  `;
  
  const AddButton = styled.p`
    // font-weight: 600;
    // font-size: 14px;
    // line-height: 19px;
    cursor: pointer;
    // color: #d04c8d;
    background: linear-gradient(90deg, #504f8c 0%, #fa4aa1 100%);
    border-radius: 128px;
    outline: none;
    border: 0px;
    // font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    color: #fff;
    padding: 13px 28px;
    padding-top: 13px;
    padding-right: 28px;
    padding-bottom: 13px;
    padding-left: 28px;
    // margin: 15px 0;
    width: 192px;
    max-width: 250px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
  
  const CalenderIconWrapper = styled.div`
    position: absolute;
    right: 1rem;
    top: 0.5rem;
    cursor: pointer;
  `;
  
  const ErrorLabel = styled.label`
    color: red;
    font-size: 12px;
    margin: 0;
    display: block;
    font-weight: 500;
  `;
  
  //
  
  // const ManualWrapper = styled.div``;
  const ModalHeaderText = styled.div`
    position: absolute;
    left: 51px;
    top: 44px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height */
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #d04c8d;
  `;
  
  const ModalHeaderTextTwo = styled.div`
    position: absolute;
    left: 51px;
    top: 72px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    color: #000000;
  `;
  
  const ModalHeaderTextThree = styled.div`
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 23px;
    line-height: 31px;
    text-align: center;
  
    color: #202020;
    margin-top: 29px;
  `;
  const ModalHeaderTextFour = styled.div`
    font-family: "General Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 31px;
    text-align: center;
  
    color: #202020;
    margin-top: 3px;
  `;
  
  const ModalHeader = styled.div`
    height: 136px;
    background: rgba(83, 80, 141, 0.1);
    border-radius: 24px 24px 0px 0px;
  `;
  
  const SubHeading = styled.p`
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    color: #D04C8D;
    margin-top: 30px;
  `;
  
  const Description3 = styled.p`
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    color: #000;
    margin-top: 30px;
  `;
  
  const BreakSection = styled.div`
  > hr{
      opacity: 0.1;
      max-width: 100%;
      margin-top: 10px
  }
  `;
  
  const RadioWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-left: 10px;
  gap: 200px;
  `;
  
  const RadioButtonGroup = styled.div`
    margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
    cursor: pointer;
    minWidth:300px;
    marginRight:100px;
    margin-left:10px;
    display: flex;
    flex-direction: row;
  `;
  const RadioLabel = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
    text-transform: capitalize;
    // white-space: nowrap;
    text-overflow: ellipsis;
  `; 
  const ComponentFile = styled.div`
  display: flex;
`;

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);

const SIPModelContent = styled.div`
margin-top: 20px;
margin-bottom: 20px
`;

const SIPItemContent = styled.div`
display: flex;
justify-content: center;
align-items: center;
flex-direction: column;
padding-right: 20px;
padding-left: 20px;
`;

const SIPModelLabel = styled.h3`
  font-family: "General Sans";
  font-style: normal;
  font-size: 18px;
  line-height: 35px;
`;

export default function CompletionOfInternshipLoe({fromInternship}) {

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [theArray, setTheArray] = useState([]);
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;

  const [getAllowanceData,setGetAllowanceData] = useState();
  const getadditionalAllowanceData = async()=>{
    const allowanceData= await getCompletionAdditionalAllowance();
    
    setGetAllowanceData(allowanceData.data);
  }

  const [getLearningOutcomeData,setLearningOutcomeData] = useState();
  const getlearningOutcomeData= async()=>{
    const learningOutcomeData = await getCompletionLearningOutcomes();
    setLearningOutcomeData(learningOutcomeData.data);
  }

  const [getTraningProvideTypeData, setTraningProviderTypeData] = useState();
  console.log(getTraningProvideTypeData, "OPTIONS")
  const gettraningProviderTypeData = async()=>{
    const trainingProviderTypeData = await getCompletionTraningProviderType();
    setTraningProviderTypeData(trainingProviderTypeData.data);
  }
console.log(getTraningProvideTypeData, "Options");
  useEffect(()=>{
    getadditionalAllowanceData();
    getlearningOutcomeData();
    gettraningProviderTypeData();
  },[])
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


    const { t } = useTranslation();
  const [coins, setCoins] = useState(0);

  //handle radio buttons
  const [externalTrainingsRadio, setExternalTrainingsRadio] = useState('');
  const [internalTraningRadio,setInternalTraningRadio] = useState("");
  const [employmentOpp, setEmploymentOpp] = useState('');
  const [fWa, setFwa] = useState('Yes');

  var data1 = {
    "empty-package": {
      subDesc: "Your internship wallet is empty",
      desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
      submit: "Submit",
    },
    "empty-coin": { 
      subDesc: "Looks like your wallet is empty.",
      desc: "To proceed with internship posting via mynext, kindly renew your internship plan subscription. Should you choose to not renew, you will be charged on a pay-per-intern basis for your internship posting.",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Thank you for purchasing our internship plan package.",
      desc: `${t("You are about to redeem ")}1${
        t(" out of your total ") +
        coins +
        t(" coins for this internship posting")
      }.`,
      submit: "Proceed",
    },
  };
  const selectedRef = useRef(null);

  const internFields = useSelector((state) => state.intern.value);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const companyData = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
    reset,
    setError,
    clearErrors,
    // status,
  } = useForm({
    defaultValues: { ...internFields },
    resolver: yupResolver(schemaCompletion),
    mode: "onBlur",
  });
  const formVal = watch();
  console.log("DOC>>>"+JSON.stringify(formVal.documents));
  var data1 = {
    "empty-package": {
      subDesc: "Your internship wallet is empty",
      desc: "You are currently not subscribed to any internship plan packages. Therefore, your internship posting will be on a pay-per-intern basis.",
      submit: "Submit",
    },
    "empty-coin": {
      subDesc: "Looks like your wallet is empty.",
      desc: "To proceed with internship posting via mynext, kindly renew your internship plan subscription. Should you choose to not renew, you will be charged on a pay-per-intern basis for your internship posting.",
      submit: "Proceed Without Plan",
    },
    "available-coins": {
      subDesc: "Thank you for purchasing our internship plan package.",
      desc: `${t("You are about to redeem ")}${formVal.no_positions}${
        t(" out of your total ") +
        coins +
        t(" coins for this internship posting")
      }.`,
      submit: "Proceed",
    },
    "position-coins": {
      subDesc: `${t(
        "Unfortunately, you are unable to complete this transaction due to insufficient token balance."
      )}`,
      desc: `1. ${t("Reduce application to")} ${coins} ${t("positions")}`,
      desc2: `2. ${t("Create new internship with ")} ${
        parseInt(formVal.no_positions) - coins
      } ${t("position(s) using ala carte payment mode")}.`,
      submit: `${t("ok")}`,
      "tokens-available": `${t("Available token(s)")}: `,
      "required-tokens": `${t("Required token(s)")}: `,
    },
  };
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const content = () => {
    return (
      <>
        <ModalHeader></ModalHeader>
        <ModalHeaderText>{t("Internship Creation")}</ModalHeaderText>
        <ModalHeaderTextTwo>
          {modalMode === "position-coins"
            ? t("Insufficient token balance")
            : t("Internship Plan")}
        </ModalHeaderTextTwo>

        <ModalHeaderTextThree>
          {t(data1[modalMode]["subDesc"])}
        </ModalHeaderTextThree>
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour>
            {data1[modalMode]["tokens-available"]}
            <b>{coins}</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour>
            {data1[modalMode]["required-tokens"]}
            <b>{parseInt(formVal.no_positions) - coins}</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}

        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            <b>{t("To proceed, you may try the following")}:</b>
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            {t(data1[modalMode]["desc"])}
          </ModalHeaderTextFour>
        ) : (
          <ModalHeaderTextFour>
            {t(data1[modalMode]["desc"])}
          </ModalHeaderTextFour>
        )}
        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            {t(data1[modalMode]["desc2"])}
          </ModalHeaderTextFour>
        ) : (
          <></>
        )}

        {modalMode === "position-coins" ? (
          <ModalHeaderTextFour
            style={{ textAlign: "left", paddingLeft: "48px" }}
          >
            <b>
              {t(
                "Should you require further assistance, please reach out to us at company@mynext.my"
              )}
            </b>
          </ModalHeaderTextFour>
        ) : (
          ""
        )}

        {modalMode === "empty-coin" ? (
          <ModalHeaderTextFour>
            <b>
              {t(
                "Note: Kindly email us at company@mynext.my for subscription renewal."
              )}
            </b>
          </ModalHeaderTextFour>
        ) : (
          ""
        )}

        <ComponentFile></ComponentFile>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            {modalMode !== "position-coins" ? (
              <>
                <Button
                  style={{ width: "213px" }}
                  margin="40px 0px 30px 0px"
                  name={t(data1[modalMode]["submit"])}
                  onClick={onSipModalSubmit}
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </>
            ) : (
              <Button
                style={{ width: "213px" }}
                margin="40px 0px 30px 0px"
                name={t(data1[modalMode]["submit"])}
                onClick={onClose}
              />
            )}
          </Col>
        </Row>
      </>
    );
  };

  const [loading, setLoading] = useState(false);
  const [isUpdate, setUpdate] = useState(false);
  const [pageLoading, setPageLoading] = useState(false);
  const [userArr, setUsersArr] = useState([]);
  const [newSkill, setSkill] = useState(null);

  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const [scope, setScope] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);

  const [enableDate, setEnableDate] = useState(false);
  const [duplicateLoc, setDuplicateLocErr] = useState("");
  const [hideLocation, setHideLocation] = useState(false);
  const collectionValueList = [
    { label: t("Manually download applications") },
    { label: t("Receive applications by E-mail") },
    { label: t("Others") },
  ];
  const [collectionValues, setCollectionValues] = useState(collectionValueList);
  const [resetorg, setResetOrg] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [modal, setModal] = useState(false);
  const [modalMode, setModalMode] = useState("empty-coin");

  const [completionOverview,setCompletionOverview] = useState(false);

  //to check if file has been uploaded 
  const [isUpload,setIsUpload] = useState(false);

  // const formVal = watch();
  const org = useSelector((state) => state.org.orgData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );

  const search = useLocation().search;
  const id = new URLSearchParams(search).get("id");
  const isFromInternshipPage = new URLSearchParams(search).get(
    "fromInternshipPage"
  );
  // const formVal = watch();
  if (resetorg === 0) {
    reset({
      description: "",
    });
    setResetOrg(1);
  }

  const [isOpen, setOpen] = useState(false);
  const bufferDate = 5;

  function onClose() {
    setModal(false);
    setLoading(false);
  }

  function onSipModalSubmit() {
    setModal(false);
    onNewIntern(true);
  }
  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const internshipId = searchParams.get('id');
  const [completionData,setCompletionData]= useState([]);
  const [compleId, setCompleID] = useState("");
  const [isUpdateForm, setUpdateForm] = useState(false);
  const [showForm, setShowForm] = useState(false);
  console.log(completionData, "data");
  const [hideText, setHideText] = useState(false);
  console.log(formVal.is_internal_training_given, "Is internal radio button")
  console.log(formVal.is_external_training_given, "Is external radio button")
  console.log(formVal.is_external_training_provider_certified_by_the_hrdcorp, "training provide")

  const autoUploadattendance_records = async (fileData) => {
    const arr = [...formVal.attendance_records];
    arr.push(fileData);
    setValue("attendance_records", arr);
    console.log(arr, "This is updated attendance Records");
    errors.attendance_records = "";
};
const autoUploadexpenditure_records = async (fileData) => {
  const arr = [...formVal.attendance_records];
  arr.push(fileData);
  setValue("attendance_records", arr);
  console.log(arr, "This is updated attendance Records");
  errors.attendance_records = "";
};
const autoUploadproof_of_training = async (fileData) => {
  const arr = [...formVal.attendance_records];
  arr.push(fileData);
  setValue("attendance_records", arr);
  console.log(arr, "This is updated attendance Records");
  errors.attendance_records = "";
};

    const getCompletionFormData= async()=>{

    const {data}= await getCompletionForm(internshipId);
    setCompletionData(data);
    setCompleID(data?.id);
    }
    // if(getCompletionData){
    //   setCompletionData(getCompletionData);
    //   console.log(getCompletionData,"Completion in api");
    // }else{
    //   setCompletionData(null);
    //   console.log("No Completion Data");
    // }
    
    useEffect(()=>{
      if(completionData.total_allowance===""){
        setShowForm(true);
      }
      if(completionData.status === 'pending'){
        setCompletionOverview(true);
      }
      if(completionData.status === 'approved'){
        setCompletionOverview(true);
      }
      else if(completionData.status === null){
        setShowForm(true);
      }
      else if(completionData.status === 'review'){
        setUpdateForm(true);
        setIsUpload(!isUpload);
        setValue('exp_end_date', formatDate(completionData.internship_end_date))
        setValue('total_allowance', completionData.total_allowance);
        setValue('min_allowance_per_intern', completionData.min_allowance_per_intern);



        //handle files
        if (completionData?.attendance_records && completionData.attendance_records.length > 0) {
          const filesToUpload = completionData.attendance_records.map(file => file.file_path);
          if (filesToUpload.length > 0) {
            autoUploadattendance_records(filesToUpload);
          }
        }
        
        if (completionData?.expenditure_records && completionData.expenditure_records.length > 0) {
          const filesToUpload = completionData.expenditure_records.map(file => file.file_path);
          if (filesToUpload.length > 0) {
            autoUploadexpenditure_records(filesToUpload);
          }
        }
        
        if (completionData?.proof_of_training && completionData.proof_of_training.length > 0) {
          const filesToUpload = completionData.proof_of_training.map(file => file.file_path);
          if (filesToUpload.length > 0) {
            autoUploadproof_of_training(filesToUpload);
          }
        }
        


      
        if(completionData?.external_training){
          if(completionData?.external_training.length > 0 && 
            completionData?.external_training[0].external_training_course_title !== ""){
            setValue('is_external_training_given', 1)
            setExternalTrainingsRadio("Yes");

            setValue('external_training_duration', completionData.external_training.length >0 ? completionData.external_training[0].external_training_duration: "");
            setValue('external_training_course_title', completionData.external_training.length >0 ? completionData.external_training[0].external_training_course_title: "");
            setValue('external_training_provider', completionData.external_training.length >0 ? completionData.external_training[0].external_training_provider: "");
          }
          else{
            setValue("is_external_training_given", 0);
            setExternalTrainingsRadio("No");
          }
        }
        
        setValue('actual_allowance_per_intern', completionData.actual_allowance_per_intern)
        if(completionData.internal_training){
          if(completionData.internal_training.length >0 && 
            completionData.internal_training[0].internal_training_course_title !== ""){
            setValue("is_internal_training_given", 1)
            setInternalTraningRadio("Yes")

            setValue('internal_training_provider', completionData.internal_training.length >0? completionData.internal_training[0].internal_training_provider: "");
            setValue('internal_training_course_title', completionData.internal_training.length >0? completionData.internal_training[0].internal_training_course_title: "");
            setValue('internal_training_duration', completionData.internal_training.length >0? completionData.internal_training[0].internal_training_duration: "");
          }else{
            setValue("is_internal_training_given", 0)
            setInternalTraningRadio("No")
          }
          
        }
        if(completionData?.is_external_training_provider_certified_by_the_hrdcorp && 
          completionData?.is_external_training_provider_certified_by_the_hrdcorp !== null){
          setValue("is_external_training_provider_certified_by_the_hrdcorp", 1)
          setEmploymentOpp("Yes")
        }else{
          setValue('is_external_training_provider_certified_by_the_hrdcorp', 0);
          setEmploymentOpp("No")
        }
        
        const selectedTrainingType = completionData.type_of_training_provided.map((itm) =>({
            id: itm.id,
            name: itm.name,
            other: itm.other
        }));
        setValue('typeOfTrainings', selectedTrainingType);

        const selectedAdditionalAllowances = completionData.additional_allowance.map((itm) =>({
          id: itm.id,
          name: itm.name,
          other: itm.other
      }));
      setValue('additionalAllowance', selectedAdditionalAllowances);

        const selectedLearningOutcome = completionData.learning_outcome.map((itm) =>({
          id: itm.id,
          name: itm.name,
          other: itm.other
      }));
      setValue('learningOutcome', selectedLearningOutcome);
        const otherTrainingTypesFilter = completionData.type_of_training_provided.filter(item => item.name === "Other's (please specify)");
        if(otherTrainingTypesFilter){
          const selectedTrainingTypesOther = completionData.type_of_training_provided.map(item => ({
            id: item.id,
            name: item.name,
            other: item.other
          }));
          setValue('typeOfTrainings', selectedTrainingTypesOther);
        }else {
          const selectedTrainingTypes = completionData.type_of_training_provided.map(item => ({
            id: item.id,
            name: item.name
          }));
          setValue('typeOfTrainings', selectedTrainingTypes);
        }
        
        
        const otherAllowancesFilter = completionData.additional_allowance.filter(item => item.name === "Other Allowances");
        if(otherAllowancesFilter){
          const otherSelectedAddAllowances = completionData.additional_allowance.map(item => ({
            id: item.id,
            name: item.name,
            other: item.other
          }));
          setValue('additionalAllowance', otherSelectedAddAllowances);
        }else{
          const selectedAddAllowances = completionData.additional_allowance.map(item => ({
            id: item.id,
            name: item.name
          }));
          setValue('additionalAllowance', selectedAddAllowances);
        }
        
        const otherLearningOutcomeFilter = completionData.learning_outcome.filter(item => item.name === "Other's (please specify)");
        if(otherLearningOutcomeFilter){
          const selectedOtherLearningOut = completionData.learning_outcome.map(item => ({
            id: item.id,
            name: item.name,
            other: item.other
          }));
          setValue('learningOutcome', selectedOtherLearningOut);
        }else {
          const selectedLearningOut = completionData.learning_outcome.map(item => ({
            id: item.id,
            name: item.name
          }));
          setValue('learningOutcome', selectedLearningOut);
        }
    
        
       // Create an array directly from completionData.expenditure_records or use an empty array if it's falsy
const arrExpenditure = completionData.expenditure_records || [];
setValue("expenditure_records", arrExpenditure);

// Create an array directly from completionData.attendance_records or use an empty array if it's falsy
const arrAttendance = completionData.attendance_records || [];
setValue("attendance_records", arrAttendance);

// Create an array directly from completionData.proof_of_training or use an empty array if it's falsy
const arrProofOfTraining = completionData.proof_of_training || [];
setValue("proof_of_training", arrProofOfTraining);


        setValue('is_acknowledged', completionData.is_acknowledged);
      }
      // else if(showForm){
      // setValue('total_allowance', completionData.total_allowance);
      // setValue('min_allowance_per_intern', completionData.min_allowance_per_intern);
      // setValue('is_external_training_given', completionData.is_external_training_given);
      // setValue('external_training[0].external_training_duration', completionData.external_training ? completionData.external_training[0].external_training_duration: "");
      // setValue('external_training[0].external_training_course_title', completionData.external_training? completionData.external_training[0].external_training_course_title: "");
      // setValue('external_training[0].external_training_provider', completionData.external_training ? completionData.external_training[0].external_training_provider: "");
    
      // const selectedTrainingTypes = completionData.type_of_training_provided || [];
      // setValue('typeOfTrainings', selectedTrainingTypes);
    
      // const selectedAddAllowances = completionData.additional_allowance || [];
      // setValue('additionalAllowance', selectedAddAllowances);
      
      // const selectedLearningOutcome = completionData?.learning_outcome || [];
      // setValue('learningOutcome', selectedLearningOutcome);
    
      // setValue('is_internal_training_given', completionData.is_internal_training_given);
      // setValue('internal_training[0].internal_training_provider', completionData.internal_training? completionData.internal_training[0].internal_training_provider: "");
      // setValue('internal_training[0].internal_training_course_title', completionData.internal_training? completionData.internal_training[0].internal_training_course_title: "");
      // setValue('internal_training[0].internal_training_duration', completionData.internal_training? completionData.internal_training[0].internal_training_duration: "");
    
      // // setValue('expenditure_records', data.expenditure_records);
      //   console.log(completionData.expenditure_records)
      //   let obj = { file_path: completionData.expenditure_records };
      //   const arr = [];
      //   arr.push(obj);
      //   setValue("expenditure_records", arr);
    
      //   let obj2 = { file_path: completionData.attendance_records };
      //   const arr2 = [];
      //   arr2.push(obj2);
    
      //   setValue("attendance_records", arr2);
      // setValue('is_acknowledged', completionData.is_acknowledged);
      // }
      // setShowForm(true);
      
    },[completionData])
  console.log(formVal.typeOfTrainings,"CompletionData Trainings");
  const [commentCompany, setCompanyComments] = useState({});

  const RetrieveComments = async () =>{
    if (completionData?.id) {
      try {
        const { data } = await getCompletionFormComments(completionData.id);
        if (data) {
          setTheArray(data);
        }
      } catch (error) {
        // Handle error
        console.error('Error fetching comments:', error);
      }
    }
  }

  useEffect(()=>{
    RetrieveComments();
  },[completionData.id])
  
console.log(commentCompany, "COMMENTS")
const uploadFileproof_of_training = async (event) => {
  const formdata = new FormData();

  let maxFileSize = 1024 * 1024 * 5; // 5 MB
  let allowedFileTypes = ['application/pdf'];

  for (const file of event.target.files) {
    if (file.size > maxFileSize) {
      toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!allowedFileTypes.includes(file.type)) {
      toast.error("Please upload only PDF files!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      formdata.append("obj_content", file, file.name);
    }
  }

  try {
    const { data, status } = await uploadObject(formdata);
    setIsUpload(!isUpload);
    if (status === 200) {

      console.log(data,"This is uploaded file form uploadFileproof_of_training")
      onUploadedproof_of_training(data);
    }
  } catch (error) {
    console.error("Error uploading files:", error);
  }
};

console.log(formVal.proof_of_training, "EVIDENCE")
  const onUploadedproof_of_training= (data) => {
    const { status } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.proof_of_training];
      arr.push(obj);
      setValue("proof_of_training", arr);
      errors.proof_of_training= "";
    }
    // if (status === 1) {
    //   setValue("proof_of_training", data.data.obj_content);
    // }
  };

  const onDeleteFilesproof_of_training = (index) => {
    const arr = [...formVal.proof_of_training];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("proof_of_training", newArr);
    
  };
  var fileNameproof_of_training = "";
  var extproof_of_training = "";  
  // const getfileNameattendance_records = (file_path) => {
  //   // Split the URL by '/' and get the last part
  //   const parts_attendance = file_path.split('/');
  //   const fileNameWithExtensionattendance = parts_attendance[parts_attendance.length - 1];
  
  //   // Split the file name by '.' to separate the extension
  //   const fileNamePartsattendance = fileNameWithExtensionattendance.split('.');
  //   fileNameattendance_records = fileNamePartsattendance[0]; // The file name without extension
  //   extattendance_records = fileNamePartsattendance[1]; // The file extension
  
  //   return { fileName, ext };
  // };
  const getfileNameproof_of_training = (file_path) => {
  if (typeof file_path === 'string') {
    // Split the URL by '/' and get the last part
    const parts_proof_of_training = file_path.split('/');
    const fileNameWithExtensionproof_of_training = parts_proof_of_training[parts_proof_of_training.length - 1];

    // Split the file name by '.' to separate the extension
    const fileNamePartsproof_of_training = fileNameWithExtensionproof_of_training.split('.');
    fileNameproof_of_training = fileNamePartsproof_of_training[0]; // The file name without extension
    extproof_of_training = fileNamePartsproof_of_training[1]; // The file extension

    //return { fileName, ext };
  } else {
    // Handle the case where file_path is not a string
    console.error('File path is not a string:', file_path);
    // You might want to return or handle this case differently based on your requirements
  }
};

// {completionDataGet?.attendance_records?.length > 0 ? (
//   completionDataGet.attendance_records.map((record, index) => (
//     <SkillCard
//       key={index + record.file_path}
//       delete={() => onDeleteFilesattendance_records(index)}
//       name={record.file_name}
//     />
//   ))
// ) : null}

// testing multiple file upload 
const uploadFileattendance_records = async (event) => {
  const formdata = new FormData();
  let maxFileSize = 1024 * 1024 * 5; // 5 MB
  let allowedFileTypes = ['application/pdf'];

  for (const file of event.target.files) {
    if (file.size > maxFileSize) {
      toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (!allowedFileTypes.includes(file.type)) {
      toast.error("Please upload only PDF files!", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      formdata.append("obj_content", file, file.name);
    }
  }

  try {
    const { data, status } = await uploadObject(formdata);
    setIsUpload(!isUpload);
    if (status === 200) {
      onUploadedattendance_records(data);
    }
  } catch (error) {
    console.error("Error uploading files:", error);
  }
};

  const onUploadedattendance_records = (data) => {
    const { status } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.attendance_records];
      arr.push(obj);
      setValue("attendance_records", arr);
      console.log(arr,"THis is updated attendance Records")
      errors.attendance_records = "";
    }
    // if (status === 1) {
    //   setValue("attendance_records", data.data.obj_content);
    // }
  };
  
  var fileNameattendance_records = "";
  var extattendance_records = "";  
  // const getfileNameattendance_records = (file_path) => {
  //   // Split the URL by '/' and get the last part
  //   const parts_attendance = file_path.split('/');
  //   const fileNameWithExtensionattendance = parts_attendance[parts_attendance.length - 1];
  
  //   // Split the file name by '.' to separate the extension
  //   const fileNamePartsattendance = fileNameWithExtensionattendance.split('.');
  //   fileNameattendance_records = fileNamePartsattendance[0]; // The file name without extension
  //   extattendance_records = fileNamePartsattendance[1]; // The file extension
  
  //   return { fileName, ext };
  // };
  const getfileNameattendance_records = (file_path) => {
  if (typeof file_path === 'string') {
    // Split the URL by '/' and get the last part
    const parts_attendance = file_path.split('/');
    const fileNameWithExtensionattendance = parts_attendance[parts_attendance.length - 1];

    // Split the file name by '.' to separate the extension
    const fileNamePartsattendance = fileNameWithExtensionattendance.split('.');
    fileNameattendance_records = fileNamePartsattendance[0]; // The file name without extension
    extattendance_records = fileNamePartsattendance[1]; // The file extension

    //return { fileName, ext };
  } else {
    // Handle the case where file_path is not a string
    console.error('File path is not a string:', file_path);
    // You might want to return or handle this case differently based on your requirements
  }
};


  // const onDeleteFilesattendance_records = (index) => {
  //   const arr = [...formVal.attendance_records];
  //   const newArr = arr.filter((item, ind) => ind !== index);
  //   setValue("attendance_records", "");
  // };

  //testing delete

  const onDeleteFilesattendance_records = (index) => {
  const arr = [...formVal.attendance_records];
  arr.splice(index, 1); // Remove the file at the specified index
  setValue("attendance_records", arr);
};


  
//testing multiple files
const uploadFileexpenditure_records = async (event) => {
  const formdata = new FormData();

  let maxFileSize = 1024 * 1024 * 5; // 5 MB
  let allowedFileTypes = ['application/pdf'];

  const file = event.target.files[0];

  if (file.size > maxFileSize) {
    toast.error("Only PDF files of size less than or equal to 5 MB can be uploaded!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (!allowedFileTypes.includes(file.type)) {
    toast.error("Please upload only PDF files!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    formdata.append("obj_content", file, file.name);

    const { data, status } = await uploadObject(formdata);
    setIsUpload(!isUpload);
    if (status === 200) onUploadedexpenditure_records(data);
  }
};

  console.log(formVal.attendance_records, "ATTENDANCE");
console.log(formVal.expenditure_records, "EXPENDITURE");
  const onUploadedexpenditure_records = (data) => {
    const { status } = data;
    // if (status === 1) {
    //   setValue("expenditure_records", data.data.obj_content);
    // }

    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.expenditure_records];
      arr.push(obj);
      setValue("expenditure_records", arr);
      errors.expenditure_records= "";
    }
  };

  var fileNameedexpenditure_records = "";
  var extedexpenditure_records = "";  
  const getfileNameedexpenditure_records = (file_path) => {
    if (typeof file_path === 'string') {
      // Split the URL by '/' and get the last part
      const parts_expenditure = file_path.split('/');
      const fileNameWithExtensionexpenditure = parts_expenditure[parts_expenditure.length - 1];
      // Split the file name by '.' to separate the extension
      const fileNamePartsexpenditure = fileNameWithExtensionexpenditure.split('.');
      fileNameedexpenditure_records = fileNamePartsexpenditure[0]; // The file name without extension
      extedexpenditure_records = fileNamePartsexpenditure[1]; // The file extension
    
      //return { fileName, ext };
    } else {
      // Handle the case where file_path is not a string
      console.error('File path is not a string:', file_path);
      // You might want to return or handle this case differently based on your requirements
    }
  };

  const onDeleteFilesexpenditure_records = (index) => {
    const arr = [...formVal.expenditure_records];
    const newArr = arr.filter((item, ind) => ind !== index);
    setValue("expenditure_records", "");
  };

  


  const [addPopup, setAddPopup] = useState(false);
  useEffect(() => {
    if (id && org_code) {
      getInternData();
      getCompletionFormData();
      
    }
    if (isFromInternshipPage) {
      setUpdate(true);
    }
    if (formVal && !formVal["payment_status"]) {
      setValue("payment_status", "paid");
    }
    //  getOptions("all/loc/country", "country");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, org]);

  useEffect(() => {
    const sip = "National Structured Internship Programme (MySIP)";
    if (formVal.intern_type === "sip" || formVal.intern_type === sip) {
      collectionValues.splice(
        collectionValues.findIndex((f) => f.label === sip),
        1
      );
      setValue("collection_type", "");
      setValue("re_email_address", "");
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      setCollectionValues(collectionValues);
      setValue("payment_status", "paid");
    } else {
      setCollectionValues([...collectionValueList]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.intern_type]);

  useEffect(() => {
    if (formVal.contact_person === "" || formVal.contact_person === null) {
      // alert('hii')
      formVal.contact_person = formVal.contact_name;
      setValue("contact_person", formVal.contact_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.contact_person]);

  const getInternData = async () => {
    setPageLoading(true);
    const { data } = await ongettingInternInfo({ id: id, code: org_code });
    if (data && data.status === 1) {
      //document updation
      if (data.data.documents && data.data.documents.length >= 4) {
        data.data.documents.push({ doc_type: "All" });
      }
      dispatch(
        updateValues({
          ...data.data,
        })
      );
      setUpdate(true);
      let arr = Object.keys(data.data);
      for (let i = 0; i <= arr.length; i++) {
        let key = arr[i];
        setValue(key, data.data[key] ? data.data[key] : "");
      }
      const scopeArr = data.data.scopes.map((x) => {
        return { ...x, label: x.scope_abbr, value: x.scope };
      });
      setValue("scopes", scopeArr);
      setSelectedScope(scopeArr);
      setValue("update_time", data.data.update_time);
      setPageLoading(false);
    } else {
      setUpdate(false);
      setPageLoading(false);
    }
  };

  const completionDataGet = {
    internship: completionData.internship,
    internship_start_date: completionData.internship_start_date,
    internship_end_date:completionData.internship_end_date,
    attendance_records:completionData.attendance_records,
    actual_allowance_per_intern:completionData.actual_allowance_per_intern,
    is_external_training_given:completionData.is_external_training_given,
    is_internal_training_given:completionData.is_internal_training_given,
    total_allowance:completionData.total_allowance,
    expenditure_records: completionData.expenditure_records,
    is_acknowledged: completionData.is_acknowledged,
    additional_allowance: completionData.additional_allowance,
    type_of_training_provided: completionData.type_of_training_provided,
    learning_outcomes: completionData.learning_outcomes,
    external_training: completionData.external_training,
    internal_training: completionData.internal_training,
    proof_of_training: completionData.proof_of_training
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const paidOptions = [
    { value: "paid", label: t("Paid") },
    { value: "unpaid", label: t("Unpaid") },
  ];

  useEffect(() => {
    getDropDownData();
    getcountryList();
    getScopeOfStudy();
    reset({ ...staticInternFields });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDropDownData = async () => {
    const { data } = await getInternScrollList();
    if (data && data.status === 0) {
      onDataConstruct(data.data);
    }
  };

  // const dropdownChange = (name, val) => {
  //   switch (name) {
  //     case "country":
  //       setValue(name, val.label);
  //       dispatch(
  //         updateValues({
  //           ...formVal,
  //           ...internFields,
  //           [name]: val.label,
  //           state: [],
  //           city: [],
  //         })
  //       );
  //       setSelectedCountry(val);
  //       getState(val.value);
  //       break;
  //     case "state":
  //       setValue(name, val.label);
  //       dispatch(
  //         // ,
  //         updateValues({
  //           ...formVal,
  //           ...internFields,
  //           [name]: val.label,
  //         })
  //         //
  //       );
  //       setSelectedState(val);
  //       getCity(selectedCountry.value, val.value);
  //       break;
  //     case "city":
  //       setValue(name, val.label);
  //       dispatch(
  //         updateValues({
  //           ...formVal,
  //           ...internFields,
  //           [name]: val.label,
  //         })
  //       );
  //       setSelectedCity(val);
  //       break;
  //     default:
  //       setValue(name, val.value);
  //   }
  // };

  const getcountryList = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Countrylist = data.data;
        const country = Countrylist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCountry(country);
        if (formVal.country) {
          let selCountry = country.filter((x) => x.value === formVal.country);
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          setSelectedCountry(selCountry);
          getState(selCountry.value);
        }
      }
    }
  };

  const getState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const Stateslist = data.data;
        const state = Stateslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setStates(state);
        if (formVal.state) {
          let selStates = state.filter((x) => x.value === formVal.state);
          if (selStates.length > 0) {
            selStates = selStates[0];
            setValue("state", selStates.value);
          }
          setSelectedState(selStates);
          // fetchCampus(selStates.value);
        }
      }
    }
  };
  const getCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCities(cities);
        if (formVal.city) {
          let selcities = cities.filter((x) => x.value === formVal.city);
          if (selcities.length > 0) {
            selcities = selcities[0];
            setValue("city", selcities.value);
          }
          setSelectedCity(selcities);
          // fetchCampus(selStates.value);
        }
      }
    }
  };

  const getScopeOfStudy = async (CountryId, StateId) => {
    const { data, status } = await getScopeOfStudyMasterList(
      CountryId,
      StateId
    );
    if (status === 200) {
      if (data.status === 1) {
        const Scopelist = data.data;
        const scope = Scopelist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setScope(scope);
        if (formVal.scopes) {
          let selcities = scope.filter((x) => x.value === formVal.scopes);
          setSelectedScope(selcities);
          // if (selcities.length > 0) {
          //   selcities = selcities[0];
          //   setValue("scopes", selcities.value);
          // }
          // setSelectedCity(selcities);
        }
      }
    }
  };

  const onDataConstruct = (data) => {
    // const skillsArr = data.skills.map((x) => {
    //   return { label: x, skill: x, value: x };
    // });
    let contactPersonArr = data.users.map((x) => {
      if (x.status === "accepted")
        return { ...x, label: x.first_name + " " + x.last_name, value: x.id };
      else {
        return null;
      }
    });
    contactPersonArr = contactPersonArr.filter(function (e) {
      return e;
    });
    // setSkillsArr(skillsArr);
    // setValue("skills", skillsArr);
    setUsersArr(contactPersonArr);
    if (formVal.contact_person === "" || formVal.contact_person === null) {
      // alert('hii')
      formVal.contact_person = formVal.contact_name;
      setValue("contact_person", formVal.contact_name);
    }
  };

  const fetchAllSkills = async (searchKey) => {
    const { data, status } = await getSkillMasterList(searchKey);
    if (status === 200 && data.status === 1) {
      const options = data.data.map((x) => {
        return { ...x, label: x.Example, value: x.Id };
      });
      setOptions(options);
      setIsLoading(false);
    }
  };

  const typeaheadRef = useRef(null);

  const filterBy = () => true;
  const handleSearch = (query) => {
    setIsLoading(true);
    fetchAllSkills(query);
  };
  const dropdownSkillChange = async (val) => {
    // setSelectedSkill(val[0])
    setSkill(val[0]?.label);
  };

  const addSkill = () => {
    const arr = [...formVal.skills];
    if (arr.length >= 30) {
      toast.error(t("The skills must be exactly 30."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      const isNewSkill =
        arr.length > 0 ? arr.some((skill) => skill.skill !== newSkill) : true;
      var added_skills = [];

      arr.map((skl) => added_skills.push(skl.skill));

      if (added_skills.includes(newSkill)) {
        toast.error(t("Skill already added"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else {
        if (isNewSkill) {
          if (newSkill === "" || newSkill === null) {
            toast.error(t("Please add at least one skill!"), {
              position: toast.POSITION.TOP_RIGHT,
            });
          } else {
            arr.push({ skill: newSkill });
            setValue("skills", arr);
            setSkill("");
            typeaheadRef.current.clear();
            errors.skills = "";
          }
        }
      }
    }
  };

  const deleteSkill = (index) => {
    const arr = [...formVal.skills];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("skills", newArr);
    setSkill(null);
    typeaheadRef.current.clear();
  };

  const onCancle = async () => {
    navigate("/internships/upload-intern-details");

  }

  const [showSipTokenModal, setShowSipTokenModal] = useState(false)
      const handleCloseModal = () => {
        setShowSipTokenModal(false);
        setLoading(false);
      };
    const [tokenData, setTokenData] = useState({});
    
      const TokenContent = ({data}) =>{
        return(
          <SIPModelContent>
            <SIPItemContent>
            {data?.status === 1? (
              <>
              <SIPModelLabel>{"There are total of "} <strong>{data?.data?.total_interns}</strong> {" interns for this internship."}</SIPModelLabel>
              <SIPModelLabel>{"Your available MySIP Token balance:"} <strong>{data?.data?.available_tokens}</strong></SIPModelLabel>
              <SIPModelLabel>{"MySIP Tokens used:"} <strong>{data?.data?.tokens_used}</strong></SIPModelLabel>
              <SIPModelLabel>{"Your available MySIP Token balance after admin approval:"} <strong>{data?.data?.tokens_after_deduction}</strong></SIPModelLabel>
              </>
            ): data?.status === 2? (
              <>
            <SIPModelLabel>{"There are total of "} <strong>{data?.data?.total_interns}</strong> {" interns for this internship."}</SIPModelLabel>
            <SIPModelLabel>{"Your available MySIP Token balance:"} <strong>{data?.data?.available_tokens}</strong></SIPModelLabel>
            <SIPModelLabel>{"MySIP Token used:"} <strong>{data?.data?.tokens_used}</strong></SIPModelLabel>
            <SIPModelLabel>{"Upon admin approval, you will be sent an invoice to your email at "} <strong>{data?.data?.contact_email}</strong></SIPModelLabel>
            <SIPModelLabel>{"You will need to make a payment for the remaining "} <strong>{data?.data?.pending_interns}</strong> {" interns."}</SIPModelLabel>
              </>
            ): ""}
            <Button name="Proceed" onClick={()=> setShowSipTokenModal(false)}/>
            </SIPItemContent>
          </SIPModelContent>
        )
      }

  const onUpdateSubmit = async () =>{
    const requestData = {
      internship: internshipId,
      // internship_start_date: formVal.exp_start_date,
      // internship_end_date:formVal.exp_end_date,
      attendance_records: formVal.attendance_records.flatMap(record => {
        if (Array.isArray(record)) {
          return record.map(item => item.file_path);
        } else if (typeof record === 'object' && record.file_path ) {
          return [record.file_path];
        }
        return [];
      }),
      actual_allowance_per_intern:formVal.actual_allowance_per_intern,
      is_external_training_given:formVal.is_external_training_given,
      is_internal_training_given:formVal.is_internal_training_given,
      total_allowance:formVal.total_allowance,
      expenditure_records: formVal.expenditure_records.flatMap(record => {
        if (Array.isArray(record)) {
          return record.map(item => item.file_path);
        } else if (typeof record === 'object' && record.file_path ) {
          return [record.file_path];
        }
        return [];
      }),
      
      is_acknowledged: formVal.is_acknowledged,
      additional_allowance: formVal.additionalAllowance,
      type_of_training_provided: formVal.typeOfTrainings,
      learning_outcomes: formVal.learningOutcome,
      external_training:[
        {
          external_training_course_title: formVal.external_training_course_title,
          external_training_provider: formVal.external_training_provider,
          external_training_duration: formVal.external_training_duration,
          is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp
        }
      ],
      internal_training:[
        {
          internal_training_course_title: formVal.internal_training_course_title,
          internal_training_provider: formVal.internal_training_provider,
          internal_training_duration: formVal.internal_training_duration,
        }
      ],
      proof_of_training: formVal.proof_of_training.flatMap(record => {
        if (Array.isArray(record)) {
          return record.map(item => item.file_path);
        } else if (typeof record === 'object' && record.file_path) {
          return [record.file_path];
        }
        return [];
      })
    };
    console.log(requestData, "UPDATE");
    console.log(completionData.id, "comple id");
    const {data} = await updateCompletionForm(completionData.id, requestData);
      if(data && data.status === 1){
        toast.success(t("Completion form updated"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        await getCompletionFormData();
        // window.location.reload();
      }else{
        toast.error(t("Failed to submit completion form. Please check your data"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
  }

  // const onSubmit = async () => {
  //     const requestData = {
  //       internship: internshipId,
  //       // internship_start_date: formVal.exp_start_date,
  //       // internship_end_date:formVal.exp_end_date,
  //       attendance_records:formVal.attendance_records.map(record => record.file_path),
  //       actual_allowance_per_intern:formVal.actual_allowance_per_intern,
  //       is_external_training_given:formVal.is_external_training_given,
  //       is_internal_training_given:formVal.is_internal_training_given,
  //       total_allowance:formVal.total_allowance,
  //       expenditure_records: formVal.expenditure_records.map(record => record.file_path),
  //       is_acknowledged: formVal.is_acknowledged,
  //       additional_allowance: formVal.additionalAllowance,
  //       type_of_training_provided: formVal.typeOfTrainings,
  //       learning_outcomes: formVal.learningOutcome,
  //       external_training:[
  //         {
  //           external_training_course_title: formVal.external_training_course_title,
  //           external_training_provider: formVal.external_training_provider,
  //           external_training_duration: formVal.external_training_duration,
  //           is_external_training_provider_certified_by_the_hrdcorp: formVal.is_external_training_provider_certified_by_the_hrdcorp
  //         }
  //       ],
  //       internal_training:[
  //         {
  //           internal_training_course_title: formVal.internal_training_course_title,
  //           internal_training_provider: formVal.internal_training_provider,
  //           internal_training_duration: formVal.internal_training_duration,
  //         }
  //       ],
  //       proof_of_training: formVal.proof_of_training.map(record => record.file_path)
  //       // external_training: formVal.external_training,
  //       // internal_training: formVal.internal_training,
  //     };
  //     const {data } = await postCompletionform(internshipId, requestData);
    
  //     console.log(data,"Completion response")
  //     if (data && data.status === 1) {
  //       // All steps completed successfully for single intern
  //       // toast.success(t("Completion form submitted"), {
  //       //   position: toast.POSITION.TOP_RIGHT,
  //       // });
  //       const {data: checkToken} = await CheckToken(internshipId);
  //             if(checkToken){
  //               setTokenData(checkToken);
  //               setShowSipTokenModal(true);
  //               toast.success(t("You will be sent an invoice upon admin approval"), {
  //                 position: toast.POSITION.TOP_RIGHT,
  //               });
  //         }

  //       getCompletionFormData();
  //      // setCompletionOverview(true);
        
  //     }else {
  //       toast.error(t("Failed to submit completion form"), {
  //         position: toast.POSITION.TOP_RIGHT,
  //       });
  //     }
   
  //   // setTimeout(() => {
  //   //   if (selectedRef.current) {
  //   //     selectedRef.current.blur();
  //   //   }
  //   // }, 0);
  //   //onSubmitErrorHandler();
  //  // showPaymentPopup();
  //  // setAddPopup(true);
  
    
  //   // handleSubmit(onSubmitErrorHandler);
  // };
const onSubmitUpdateHandler = async () =>{
  const no_training_type = formVal.typeOfTrainings.some((item) =>
  item.name === "Others (please specify)" && (!item.other || item.other.trim() === '')
  );

  const no_additional_allowance = formVal.additionalAllowance.some((item) =>
  item.name === "Other Allowances" && (!item.other || item.other.trim() === '')
  );

  const no_learning_outcome = formVal.learningOutcome.some((item) =>
  item.name === "Others (please specify)" && (!item.other || item.other.trim() === '')
  );
  if (!formVal.actual_allowance_per_intern || formVal.actual_allowance_per_intern === '') {
    toast.error(t("Fill out all  the mandatory fields1"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  else if(no_training_type){
    toast.error(t("Please enter other type of training"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  else if(no_additional_allowance){
    toast.error(t("Please enter other additional allowance"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  else if(no_learning_outcome){
    toast.error(t("Please enter other learning outcome"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  }else if(formVal.learningOutcome.length === 0 || formVal.additionalAllowance.length === 0 || 
    formVal.typeOfTrainings.length === 0){
    toast.error(t("Fill out all  the mandatory fields11"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  else{
    onUpdateSubmit()
  }
}

  const onSubmitErrorHandler = async () => {
    const no_training_type = formVal.typeOfTrainings.some((item) =>
    item.name === "Others (please specify)" && (!item.other || item.other.trim() === '')
    );

    const no_additional_allowance = formVal.additionalAllowance.some((item) =>
    item.name === "Other Allowances" && (!item.other || item.other.trim() === '')
    );

    const no_learning_outcome = formVal.learningOutcome.some((item) =>
    item.name === "Others (please specify)" && (!item.other || item.other.trim() === '')
    );
    if (!formVal.actual_allowance_per_intern || formVal.actual_allowance_per_intern === '' ) {
      toast.error(t("Fill out all  the mandatory fields2"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if(no_training_type){
      toast.error(t("Please enter other type of training"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if(no_additional_allowance){
      toast.error(t("Please enter other additional allowance"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else if(no_learning_outcome){
      toast.error(t("Please enter other learning outcome"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    else{
      // Manually trigger form validation
      // const isValid = await handleSubmit(onSubmit);
      // // handleSubmit(onSubmit) returns a function, and we call it immediately

      // if (isValid) {
      //   // If the form is valid, call onSubmit
      //   if(showForm){
          // onSubmit();
      //   }
      //   else if (isUpdateForm){
      //     onUpdateSubmit()
      //   }
      //   // onSubmit();
      // }
    }
  };
  const onUpdateIntern = async () => {
    // work around for visibility status - string bug
    if (typeof formVal?.visibility_status == "string")
      formVal.visibility_status = 0;
    const { data, res } = await onUpdatingInternInfo({
      code: org_code,
      id: id,
      data: {
        ...formVal,
        collection_radio_type: formVal.collection_radio_type
          ? formVal.collection_radio_type
          : null,
        collection_date: formVal.collection_date
          ? formVal.collection_date
          : null,
        is_activate: true,
        status: "pending",
      },
    });
    if (data && data.status === 1) {
      toast.success(t("Internship has been updated."), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      navigate("/internships");
    } else {
      if (
        data.message ===
        t("Someone has already edited the records, so please update the screen")
      ) {
        toast.warning(
          t(
            "Internship already updated by another user. Please refresh the page to load the latest details."
          ),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
      }

      setLoading(false);
    }
    if (res) {
      if (res.non_field_errors) {
        const msg = res.non_field_errors.join(" ");
        toast.error(msg, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  const onNewIntern = async (checkTrigger) => {
    let ti = 0;
    if (Object.keys(company).length > 0) {
      const result3 = await getOrganizationInternshipCoinDetail(company?.id);
      var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
      var today = new Date();
      if (varDate >= today) {
        setModalMode("empty-coin");
        ti = result3?.data?.data?.tokens;
        setCoins(ti);
      } else {
        setModalMode("empty-package");
      }
    }
    if (checkTrigger) {
      const { data, res } = await createNewIntern({
        ...formVal,
        documents: formVal.documents.filter((x) => x.doc_type !== "All"),
        collection_radio_type: formVal.collection_radio_type
          ? formVal.collection_radio_type
          : null,
        collection_date: formVal.collection_date
          ? formVal.collection_date
          : null,
        org_code: org_code,
        is_activate: true,
      });
      if (data && data.status === 1) {
        toast.success(t("Internship form submitted"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        navigate("/internships");
      } else if (data && data.sip_status === true) {
        var mainUrl =
          data.framework_enrollment_url[process.env.REACT_APP_ENV_TYPE] +
          "&internship_id=" +
          data.post_data.id +
          "&token=" +
          localStorage.getItem("auth_token") +
          "&org_code=" +
          org_code;

        toast.success(t("Internship form submitted"), {
          position: toast.POSITION.TOP_RIGHT,
        });

        const mynextCentralWindow = window.open(
          mainUrl,
          "_self",
          "credentials=include"
        );
        setTimeout(() => {
          mynextCentralWindow.postMessage(data.post_data, "*");
        }, 2000);
        setLoading(false);
        navigate("/internships");
      } else if (data && data.sip_status === false) {
        var mainUrl =
          data.framework_enrollment_url[process.env.REACT_APP_ENV_TYPE] +
          "&internship_id=" +
          data.post_data.id +
          "&token=" +
          localStorage.getItem("auth_token") +
          "&org_code=" +
          org_code;

        window.open(mainUrl, "_self", "credentials=include");
        setLoading(false);
      } else {
        toast.error(
          t(
            "Failed to create SIP enrolment form. Please try again or contact customer support."
          ),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        setLoading(false);
        navigate("/internships");
      }
      if (res) {
        if (res.non_field_errors) {
          const msg = res.non_field_errors.join(" ");
          toast.error(msg, {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
      if (ti < 1) {
        setModal(true);
        checkTrigger = false;
      } else {
        if (coins < parseInt(formVal.no_positions)) {
          setModalMode("position-coins");
          setModal(true);
          checkTrigger = false;
        } else {
          setModalMode("available-coins");
          setModal(true);
          checkTrigger = false;
        }
      }
    }
  };

  const showPaymentPopup = () => {
    setOpen(true);
  };
  const onInternshipSubmit = () => {
    setOpen(false);
    setLoading(true);
    if (isUpdate) {
      onUpdateIntern();
    } else {
      onNewIntern(false);
    }
  };
  const radioChange = (value, name) => {
    setValue(name, value);
    if (name === "min_degree") {
      errors.min_degree = "";
    } else if (name === "grade") {
      errors.grade = "";
    }
    if (
      value === t("Receive appplications in a single package on a given date")
    ) {
      setEnableDate(true);
    } else {
      setEnableDate(false);
    }
  };

  const learningOutcomesCheckboxChange = (option) => {
    const type = "learningOutcome"; // Replace with the appropriate type
    const keyWord = "name"; // Assuming you want to use the ID
  
    let arr = [...formVal[type]];
    const isChecked = arr.some((grd) => grd[keyWord] === option.name);
  
    if (isChecked) {
      arr = arr.filter((grd) => grd[keyWord] !== option.name);
    } else {
      const obj = { id: option.id, name: option.name };
      arr.push(obj);
    }
  
    // Debugging: Log the updated array
    console.log(arr, "LearningOutcomes");
  
    // Update the state
    setValue(type, arr);
    errors.learningOutcome= "";
  };

  // const typeOfTrainingProvidedCheckBoxChange = (option) => {
  //   const type = "typeOfTrainings"; // Replace with the appropriate type
  //   const keyWord = "name"; // Assuming you want to use the ID
  
  //   let arr = [...formVal[type]];
  //   const isChecked = arr.some((grd) => grd[keyWord] === option.name);
  
  //   if (isChecked) {
  //     arr = arr.filter((grd) => grd[keyWord] !== option.name);
  //   } else {
  //     const obj = { id: option.id, name: option.name };
  //     arr.push(obj);
  //   }
  
  //   // Debugging: Log the updated array
  //   console.log(arr, "Allowance type");
  //   console.log("formVal.typeOfTrainings:", formVal.typeOfTrainings);
  
  //   // Update the state
  //   setValue(type, arr);
  //   errors.typeOfTrainings= "";
  // };

  const additionalAllowanceCheckboxChange = (option) => {
    const type = "additionalAllowance"; // Replace with the appropriate type
    const keyWord = "name"; // Assuming you want to use the ID
  
    let arr =  [...formVal[type]];
    const isChecked = arr.some((grd) => grd[keyWord] === option.name);
  
    if (isChecked) {
      arr = arr.filter((grd) => grd[keyWord] !== option.name);
    } else {
      const obj = { id: option.id, name: option.name };
      arr.push(obj);
    }
  
    // Debugging: Log the updated array
    console.log(arr, "Allowance type");
  
    // Update the state
    setValue(type, arr);
    errors.additionalAllowance= "";
  };
console.log(formVal.typeOfTrainings, "SELECTED TYPE")

  const typeOfTrainingProvidedCheckBoxChange = (option) => {
    const type = "typeOfTrainings"; // Replace with the appropriate type
    const keyWord = "name"; // Assuming you want to use the ID
  
    let arr = [...formVal[type]];
    const isChecked = arr.some((grd) => grd[keyWord] === option.name);
  
    if (isChecked) {
      arr = arr.filter((grd) => grd[keyWord] !== option.name);
    } else {
      const obj = { id: option.id, name: option.name };
      arr.push(obj);
    }
  
    // Debugging: Log the updated array
    console.log(arr, "Allowance type");
    console.log("formVal.typeOfTrainings:", formVal.typeOfTrainings);
  
    // Update the state
    setValue(type, arr);
    errors.typeOfTrainings= "";
  };

  const handleTextOth = (type, option, val) =>{

    const updatedFormVal = formVal[type].map((item) =>
      item.name === option.name ? { ...item, other: val } : item
    );
  
    setValue(type, updatedFormVal);
  }
  

  const radioChangeForVisibilityStatus = (value, name) => {
    if (formVal.visibility_status) setValue(name, 0);
    else setValue(name, value);
  };

  const onMblNumberChange = (val) => {
    if (val) {
      setValue("contact_mob", val);
    }
  };

  const docCheckboxChange = (name, type) => {
    let arr = [...formVal[type]];
    let keyWord = "doc_type";
    const isChecked = arr.some((grd) => grd[keyWord] === name);
    if (isChecked) {
      if (name === "All") {
        arr = [];
      }
      arr = [...arr].filter((grd) => grd[keyWord] !== name);
      arr = [...arr].filter((grd) => grd[keyWord] !== "All");
    } else {
      if (name === "All") {
        arr = completionOfInternshipObj.requiredDocs.map((m) => {
          return { doc_type: m };
        });
      } else {
        const obj = { [keyWord]: name };
        arr.push(obj);
      }
      if (arr.length === 4) {
        const obj = { [keyWord]: "All" };
        arr.push(obj);
      }
    }
    setValue(type, arr);
    errors.documents = "";
    // alert(name)
  };

  const addLocation = () => {
    let arr = [...formVal.locations];
    let obj = {};
    var myValid = false;
    if (formVal.state !== "" && cities.length === 0) {
      myValid = true;
      obj = {
        country: formVal.country,
        state: formVal.state,
      };
    } else if (formVal.country && formVal.state && formVal.city) {
      myValid = true;
      obj = {
        country: formVal.country,
        state: formVal.state,
        city: formVal.city,
      };
    }
    const isAlreadyAdded = arr.some(
      (loc) => JSON.stringify(loc) === JSON.stringify(obj)
    );
    if (myValid === true && !isAlreadyAdded) {
      arr.push(obj);
      setValue("locations", arr);
      setValue("state", "");
      setSelectedState([]);
      setValue("country", "");
      setValue("country", "Malaysia");
      setSelectedCountry({ label: "Malaysia", value: 132 });
      getState(132);
      setValue("city", "");
      setSelectedCity([]);
      setDuplicateLocErr("");
      setHideLocation(true);
      errors.locations = "";
    } else if (isAlreadyAdded) {
      setDuplicateLocErr(
        t("The selected location is already added to the list")
      );
    }
  };

  const onDeleteLocation = (index) => {
    let arr = [...formVal.locations];
    let newArr = arr.filter((loc, ind) => ind !== index);
    setValue("locations", newArr);
  };

  const contactPersonChange = (val) => {
    setValue("contact_person", val.label);
    setValue("contact_name", val.label);
    setValue("contact_email", val.email);
    setValue("contact_mob", val.mob_number);
    errors.contact_person = "";
  };
  const collectionTypeValue = (val) => {
    setValue("collection_type", val.label);
    if (val.label === t("Manually download applications")) {
      setValue("re_email_address", "");
      setValue("collection_radio_type", "");
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      errors.collection_type = "";
    } else if (val.label === t("Receive applications by E-mail")) {
      setValue("application_instruction", "");
      setValue("external_application_link", "");
      errors.collection_type = "";
    } else if (val.label === t("Others")) {
      setValue("re_email_address", "");
      setValue("collection_radio_type", "");
      errors.collection_type = "";
    }
  };

  const scopeAddChange = (val) => {
    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setValue("scopes", newVal);
    setSelectedScope(newVal);
    errors.scopes = "";
  };

  const uploadFile = async (event) => {
    const formdata = new FormData();

    let maxFileSize = 1024 * 1024 * 5; //100mb

    if (event.target.files[0].size > maxFileSize) {
      toast.error(
        t(
          "Only PDF files of size less than or equal to 5 MB can be uploaded!"
        ),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      formdata.append(
        "obj_content",
        event.target.files[0],
        event.target.files[0].name,
       
      );
      const { data, status } = await uploadObject(formdata);
      setIsUpload(!isUpload);
      if (status === 200) onUploaded(data);
    }
  };

  const onUploaded = (data) => {
    const { status } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      const arr = [...formVal.file_paths];
      arr.push(obj);
      setValue("file_paths", arr);
    }
  };

  const onDeleteFiles = (index) => {
    const arr = [...formVal.file_paths];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setValue("file_paths", newArr);
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
    if (name === "position_start_date") {
      setValue(
        "position_end_date",
        moment(new Date(val).setDate(new Date(val).getDate() + 180)).format(
          "YYYY-MM-DD"
        )
      );
    }
    if (name === "collection_date") {
      errors.collection_date = "";
    }
  };

  var fileName = "";
  var ext = "";

  // const getfileName = (file_name) => {
  //   var temp_name_2 = file_name.split(/[\\]/).pop();
  //   var temp_name_arr = temp_name_2.split(".");
  //   var temp_name = temp_name_arr[0].slice(0, -8);
  //   fileName = temp_name;
  //   ext = temp_name_arr[1];
  // };

  var fileName = "";
  var ext = "";

  const getfileName = (file_path) => {

    console.log(file_path,"This is form getFileNAme")
    if (!file_path) {
        // Handle the case where file_path is undefined or null
        return { fileName: "", ext: "" }; // Return empty values or handle it as per your requirement
    }

    // Split the URL by '/' and get the last part
    const parts = file_path.split('/');
    const fileNameWithExtension = parts[parts.length - 1];

    // Split the file name by '.' to separate the extension
    const fileNameParts = fileNameWithExtension.split('.');
    const fileName = fileNameParts[0]; // The file name without extension
    const ext = fileNameParts[1]; // The file extension

    return { fileName, ext };
};

const getfileNameExisitng = (file_path) => {

  console.log(file_path,"This is form getFileNAme")
  if (!file_path) {
      // Handle the case where file_path is undefined or null
      return { fileName: "", ext: "" }; // Return empty values or handle it as per your requirement
  }

  // Split the URL by '/' and get the last part
  const parts = file_path.split('/');
  const fileNameWithExtension = parts[parts.length - 1];

  // Split the file name by '.' to separate the extension
  const fileNameParts = fileNameWithExtension.split('.');
  const fileName = fileNameParts[0]; // The file name without extension
  const ext = fileNameParts[1]; // The file extension

  return { fileName, ext };
};

  const updateCgpaValue = (val) => {
    if (val.length === 1) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + ".00");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 2) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + "00");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 3) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val + "0");
      } else {
        setValue("req_gpa", "");
      }
    } else if (val.length === 4) {
      if (val >= 0 && val <= 4) {
        setValue("req_gpa", val);
      } else {
        setValue("req_gpa", "");
      }
    } else {
      setValue("req_gpa", val);
    }

    validateReqCgpaValue();
  };

  const validateReqCgpaValue = () => {
    const reCgpa = formVal.req_gpa;

    if (reCgpa < 0 || reCgpa > 4 || isNaN(reCgpa)) {
      setError("req_gpa", {
        type: "string",
        message: t("Minimum required CGPA should be between 0.00 and 4.00"),
      });
      return;
    }

    clearErrors("req_gpa");
  };

  const updateWorkingHoursValue = (val) => {
    if (val.length === 1) {
      if (val >= 1 && val <= 24) {
        setValue("working_hours", val + ".0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 2) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", val + "0");
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val + ".0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 3) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", val);
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val + "0");
      } else {
        setValue("working_hours", "");
      }
    } else if (val.length === 4) {
      if (val >= 1 && val < 10) {
        setValue("working_hours", (Math.floor(val * 10) / 10).toPrecision(2));
      } else if (val >= 10 && val <= 24) {
        setValue("working_hours", val);
      } else {
        setValue("working_hours", "");
      }
    } else {
      setValue("working_hours", "");
    }

    validateWorkingHoursValue();
  };



  const validateWorkingHoursValue = () => {
    const workingHours = formVal.working_hours;

    if (workingHours < 1 || workingHours > 24 || isNaN(workingHours)) {
      setError("working_hours", {
        type: "string",
        message: t("Working Hours should be between 1 and 24"),
      });
      return;
    }

    clearErrors("working_hours");
  };

  const coinSet = async () => {
    const result3 = await getOrganizationInternshipCoinDetail(company?.id);
    var varDate = new Date(result3?.data?.data?.subscription_end_date); //dd-mm-YYYY
    var today = new Date();
    if (varDate >= today) {
      setCoins(result3?.data?.data?.tokens);
    } else {
      setCoins(0);
    }
  };

  const handleInternalTrainingRadio = (event)=>{
    const selectedValue = event.target.value === 'Yes' ? 1 : 0;
    setInternalTraningRadio(event.target.value)
  setValue('is_internal_training_given', selectedValue);
  }

  const handleMinDurationChange = (event) => {
    
    const selectedValue = event.target.value === 'Yes' ? 1 : 0;
    setExternalTrainingsRadio(event.target.value)
    setValue('is_external_training_given', selectedValue);
    // onSubmit({ studentDiffer: value });
  };

  const handlePayMinimumSKMChange = (event) =>{
    
    const selectedValue = event.target.value === 'Yes' ? 1 : 0;
    setEmploymentOpp(event.target.value)
    setValue('is_external_training_provider_certified_by_the_hrdcorp', selectedValue);
    // onSubmit({ employmentOpp: value });
  }

  const handleMinMonthCertChange = (event) => {
    const value = event.target.value;

    setFwa(value);
    // onSubmit({fWa: value});
  };
  useEffect(() => {
    if (company?.id !== undefined) {
      coinSet();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company?.id]);

  useEffect(() => {
    setValue("country", "Malaysia");
    setSelectedCountry({ label: "Malaysia", value: 132 });
    getState(132);
    setValue("intern_type", "sip");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (formVal.contact_name !== null) {
      setValue("contact_person", formVal.contact_name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formVal.contact_name]);


  return (
    <>
      {isOpen === true ? (
        <PaymentPopup
          intershipName={formVal.title}
          companyName={companyData.name}
          setOpen={setOpen}
          isOpen={isOpen}
          onInternshipSubmit={onInternshipSubmit}
        />
      ) : (
        ""
      )}
        {/* <HamburgerLayout>
        <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
      </HamburgerLayout>
      {isOpenham && <Menus />} */}
        
        {pageLoading ? (
          <Loader />
        ) : (
          completionOverview ?(
            <CompletionOfInternshipOverview dataCompletion={completionData} />
          ):(
            isUpdateForm? (
              <NewInternPageWrapper>
            <SectionWrapper>
              
               

              
            <div className="container mb-5">
                    <Root sx={{ width: 200, maxWidth: "50%" }}>
                      <table aria-label="custom pagination table" 
                      style={{
                        background: "white",
                        borderRadius: "20px"
                      }}>
                        <thead>
                          <tr>
                            <th
                              style={{
                                background: "rgb(250, 74, 161)",
                                color: "#fff",
                                borderTopLeftRadius: "20px"
                              }}
                            >
                              {"Admin's Review"}
                            </th>
                            <th
                              style={{
                                background: "rgb(250, 74, 161)",
                                color: "#fff",
                                borderTopRightRadius: "20px"
                              }}
                            >
                              {"Created"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {theArray?.length > 0
                            ? (rowsPerPage > 0 && theArray?.length
                              ? theArray.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              : theArray
                            ).map((row) => (
                              <tr key={row.comment}>
                                <td>{row.comment}</td>
                                <td style={{ width: 120 }} align="right">
                                  {moment(new Date(row.created_at)).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                              </tr>
                            ))
                            : ""}

                          {emptyRows > 0 && (
                            <tr style={{ height: 41 * emptyRows }}>
                              <td colSpan={3} />
                            </tr>
                          )}
                        </tbody>
                        {theArray?.length > 0 ? (
                          <tfoot>
                            <tr>
                              <CustomTablePagination
                                rowsPerPageOptions={[
                                  5,
                                  10,
                                  25,
                                  { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={theArray?.length ?? "0"}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                componentsProps={{
                                  select: {
                                    "aria-label": "rows per page",
                                  },
                                  actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                  },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              />
                            </tr>
                          </tfoot>
                        ) : (
                          <div className="container col-lg-12" style={{margin: "10px"}}>{"No Comments"}</div>
                        )}
                      </table>
                    </Root>
                  </div>

              {/* <HeaderText>
                  {!isUpdate
                  ? t(completionOfInternshipObj.heading)
                  : t(completionOfInternshipObj.editHeading)}
              </HeaderText> */}
              <HeaderText2>{completionOfInternshipObj.completionForm}</HeaderText2>
              <Description>{t(completionOfInternshipObj.description)}</Description>
              <SubHeading>{completionOfInternshipObj.subHeading1}</SubHeading>
              <BreakSection>
                <hr/>
                </BreakSection>
              {/* <Description3>{completionOfInternshipObj.datesHeading}</Description3> */}
              <FormWrapper>
                {/* intern detail section */}
                <FormGroup>
                
                  <SingleField>
                    <UploadText>{t(completionOfInternshipObj.uploadTitle)}</UploadText>
                      <UploadNotes>
                        {t(completionOfInternshipObj.uploadNotes)}
                      </UploadNotes>
                      <UploadFileWrapper data-tip data-for="pdf">
                      <input
                        onChange={uploadFileattendance_records}
                        accept="application/pdf"
                        type="file"
                      />
                      <UploadFileText>
                        {t(completionOfInternshipObj.uploadFileText)}
                      </UploadFileText>
                      {isUpload===false?<UploadNotes>No file chosen</UploadNotes> :<UploadNotes></UploadNotes>}
                      <p className="uploadFileOne" style={{marginTop:"5px"}}>
                        {t("The maximum file size limit is 5 MB")}
                      </p>
                    </UploadFileWrapper>
                    <FileWrapper>
                    {formVal.attendance_records?.length > 0 ? (
                      formVal.attendance_records.map((file, index) => {
                        const filePath = file.file_path?.[index]?.file_path || file.file_path; // Check if file path exists, use file.file_path if not
                        if (!filePath) return null; // Skip processing if file path is empty, null, or undefined

                        const { fileName, ext } = getfileName(filePath);
                        return (
                          <React.Fragment key={index}>
                            {console.log(filePath, "This is file path for attendance_records")}
                            <SkillCard
                              key={index + filePath}
                              delete={() => onDeleteFilesattendance_records(index)}
                              name={`${fileName}.${ext}`} // Concatenate fileName and ext using `${}`
                            />
                          </React.Fragment>
                        );
                      })
                    ) : (
                      ""
                    )}



                    </FileWrapper>

                   

                  {errors.attendance_records && (
                    <ErrorField err={t(errors.attendance_records?.message)} />
                  )}
                  </SingleField>

                  
                  <SingleField>
                    <FormLabel>{t(completionOfInternshipObj.minimumAllowance)}</FormLabel>
                    <UploadNotes style={{marginLeft:"9px",marginBottom:"5px"}}>
                        {t(completionOfInternshipObj.allowanceNotes)}
                      </UploadNotes>
                    <Controller
                      name="actual_allowance_per_intern"
                      control={control}
                      defaultValue={completionDataGet.min_allowance_per_intern}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("RM")}
                          dataId={"actual_allowance_per_intern"}
                          onChange={(e) => {
                            // Convert the input value to an integer and update the state
                            const intValue = parseInt(e.target.value, 10);
                            field.onChange(intValue);
                          }}
                        />
                      )}
                    />
                    {errors.min_allowance_per_intern && (
                      <ErrorField err={t(errors.min_allowance_per_intern.message)} />
                    )}
                  </SingleField>
                  
                  <DividerLine/>
                  

                  <FormGroup>
                    <FormHeaderText>Training Information</FormHeaderText>
                    <FormLabel>{t(completionOfInternshipObj.internalTraning)}</FormLabel>
                    <UploadNotes style={{marginLeft:"12px", marginBottom:"10px"}}>
                        {t(completionOfInternshipObj.internalTraningNotes)}
                      </UploadNotes>
                      <Controller
                      name="is_internal_training_given"
                      control={control}
                      render={({ field }) => (
                        <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="Yes"
                          checked={internalTraningRadio === 'Yes'}
                          onChange={
                            handleInternalTrainingRadio
                            // setMinDuration("Yes")
                          }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="No"
                          checked={internalTraningRadio === 'No'}
                          onChange={
                            handleInternalTrainingRadio
                           }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                      )}
                    />
                    
                    {errors.intern_type && (
                      <ErrorField err={t(errors.intern_type.message)} />
                    )}
                </FormGroup>
                {internalTraningRadio==='Yes'?(
                  <FormGroup>
                  <FormLabel style={{marginBottom:"0px"}}>
                    {t(completionOfInternshipObj.providerDetails + "(Internal)")}
                  </FormLabel>
                  <Row className="mb-4">
                   <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                     <FormLabel>{(completionOfInternshipObj.courseTitle)}</FormLabel>
                     <Controller
                         name="internal_training_course_title"
                         control={control}
                         render={({ field }) => (
                           <Input
                             {...field}
                             placeholder={t("Course title")}
                             dataId="internal_training"
                           />
                         )}
                       />
                       {errors.internal_training_course_title && (
                        <ErrorField err={errors.internal_training_course_title.message}/>
                       )}
                   </Col>
                   <Col>
                   <FormLabel>{(completionOfInternshipObj.trainingProvider)}</FormLabel>
                     <Controller
                           name="internal_training_provider"
                           control={control}
                           render={({ field }) => (
                             <Input
                               {...field}
                               placeholder={t("Training Provider")}
                               dataId="internal_training"
                             />
                           )}
                         />
                         {errors.internal_training_provider && (
                        <ErrorField err={errors.internal_training_provider.message}/>
                       )}
                   </Col>
                   <Col>
                   <FormLabel>{(completionOfInternshipObj.durationOfTraning)}</FormLabel>
                     <Controller
                           name="internal_training_duration"
                           control={control}
                           render={({ field }) => (
                             <Input
                               {...field}
                               placeholder={t("Duration")}
                               dataId="internal_training"
                             />
                           )}
                         />
                         {errors.internal_training_duration && (
                        <ErrorField err={errors.internal_training_duration.message}/>
                       )}
                   </Col>
                   </Row>
                   {errors.providerDetails && (
                     <ErrorField err={t(errors.providerDetails.message)} />
                   )}
                </FormGroup>
                ):""}

                  <FormGroup style={{marginTop:"50px"}}>
                    <FormLabel>{t(completionOfInternshipObj.externalTrainingsQuestions)}</FormLabel>
                    <UploadNotes style={{marginLeft:"12px", marginBottom:"10px"}}>
                        {t(completionOfInternshipObj.externalTrainingsQuestionsNotes)}
                      </UploadNotes>
                      <Controller
                      name="is_external_training_given"
                      control={control}
                      render={({ field }) => (
                        <RadioWrapper>
                        <RadioButtonGroup>
                        <input 
                        type="radio" 
                        className="radio"
                        value="Yes"
                        checked={externalTrainingsRadio === 'Yes'}
                        onChange={
                          handleMinDurationChange
                          // setMinDuration("Yes")
                        }/>
                        <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                        </RadioButtonGroup>

                        <RadioButtonGroup>
                        <input 
                        type="radio" 
                        className="radio"
                        value="No"
                        checked={externalTrainingsRadio === 'No'}
                        onChange={
                          handleMinDurationChange
                         }/>
                        <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                        </RadioButtonGroup>
                  </RadioWrapper>
                      )}
                    />
                    
                    {errors.intern_type && (
                      <ErrorField err={t(errors.intern_type.message)} />
                    )}
                </FormGroup>
                <FormGroup>
                {externalTrainingsRadio === "Yes" ? (
                    <>
                      <FormLabel>{t(completionOfInternshipObj.hrdCertified)}</FormLabel>
                      <Controller
                      name="is_external_training_provider_certified_by_the_hrdcorp"
                      control={control}
                      render={({ field }) => (
                        <RadioWrapper>
                        <RadioButtonGroup>
                          <input
                            type="radio"
                            className="radio"
                            value="Yes"
                            checked={employmentOpp === 'Yes'}
                            onChange={handlePayMinimumSKMChange}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                        </RadioButtonGroup>

                        <RadioButtonGroup>
                          <input
                            type="radio"
                            className="radio"
                            value="No"
                            defaultChecked
                            checked={employmentOpp === 'No'}
                            onChange={handlePayMinimumSKMChange}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                        </RadioButtonGroup>
                      </RadioWrapper>
                      )}
                    />
                     
                      {errors.intern_type && (
                        <ErrorField err={t(errors.intern_type.message)} />
                      )}
                    </>
                  ) : null}

            
                </FormGroup>

                <SingleField>
                  {externalTrainingsRadio === "Yes"?(
                  <>
                 <FormGroup>
                   <FormLabel style={{marginBottom:"0px"}}>
                     {t(completionOfInternshipObj.providerDetails + "(External)")}
                   </FormLabel>
                   <Row className="mb-4">
                    <Col sm={12} md={4} style={{ minWidth: "300px" }}>
                      <FormLabel>{(completionOfInternshipObj.courseTitle)}</FormLabel>
                      <Controller
                          name="external_training_course_title"
                          control={control}
                          render={({ field }) => (
                            <Input
                              {...field}
                              placeholder={t("Course title")}
                              dataId="external_training"
                            />
                          )}
                        />
                         {errors.external_training_course_title && (
                        <ErrorField err={errors.external_training_course_title.message}/>
                       )}
                    </Col>
                    <Col>
                    <FormLabel>{(completionOfInternshipObj.trainingProvider)}</FormLabel>
                      <Controller
                            name="external_training_provider"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t("Training Provider")}
                                dataId="external_training"
                              />
                            )}
                          />
                           {errors.external_training_provider && (
                        <ErrorField err={errors.external_training_provider.message}/>
                       )}
                    </Col>
                    <Col>
                    <FormLabel>{(completionOfInternshipObj.durationOfTraning)}</FormLabel>
                      <Controller
                            name="external_training_duration"
                            control={control}
                            render={({ field }) => (
                              <Input
                                {...field}
                                placeholder={t("Duration")}
                                dataId="external_training"
                              />
                            )}
                          />
                          {errors.external_training_duration && (
                      <ErrorField err={t(errors.external_training_duration.message)} />
                    )}
                    </Col>
                    </Row>
                 </FormGroup>
                    
                  </>): null}

                  {externalTrainingsRadio ==="Yes" || internalTraningRadio ==="Yes"? 
                  (
                    <>
                    <SingleField>
                    <UploadText>{t(completionOfInternshipObj.hrdCertifiedUpload)}</UploadText>
                      <UploadNotes>
                        {t(completionOfInternshipObj.uploadProof)}
                      </UploadNotes>
                      <UploadFileWrapper data-tip data-for="pdf">
                      <input
                        onChange={uploadFileproof_of_training}
                        accept="application/pdf"
                        type="file"
                      />
                      <UploadFileText>
                        {t(completionOfInternshipObj.uploadFileText)}
                      </UploadFileText>
                      {isUpload===false?<UploadNotes>No file chosen</UploadNotes> :<UploadNotes></UploadNotes>}
                      <p className="uploadFileOne" style={{marginTop:"5px"}}>
                        {t("The maximum file size limit is 5 MB")}
                      </p>
                    </UploadFileWrapper>
                    <FileWrapper>
                    {formVal.proof_of_training?.length > 0 ? (
                    formVal.proof_of_training.map((file, index) => {
                      const filePath = file.file_path?.[index]?.file_path || file.file_path; // Check if file path exists, use file.file_path if not
                      if (!filePath) return null; // Skip processing if file path is empty, null, or undefined

                      const { fileName, ext } = getfileName(filePath);
                      return (
                        <React.Fragment key={index}>
                          {console.log(file.file_path, "This is file path for attendance_records")}
                          <SkillCard
                            key={index + filePath}
                            delete={() => onDeleteFilesproof_of_training(index)}
                            name={`${fileName}.${ext}`} // Concatenate fileName and ext using `${}`
                          />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    ""
                  )}

                    </FileWrapper>
                  {errors.proof_of_training && (
                    <ErrorField err={t(errors.proof_of_training?.message)} />
                  )}
                  </SingleField>

                    <FormGroup>
                      <FormLabel>
                        {("Type of training provided?")}{" \n"}
                        
                      </FormLabel>
                      <UploadNotes style={{marginLeft:"12px"}}> {t("(You may select more than one)*")}</UploadNotes>
                      <Controller
                        name="typeOfTrainings"
                        control={control}
                        render={({ field }) =>
                        <div>
                          {getTraningProvideTypeData && 
                          getTraningProvideTypeData.map((option)=>(
                            <div key={option.id}>
                          <CheckboxInput
                            margin="15px"
                            onChange={() => typeOfTrainingProvidedCheckBoxChange(option)}
                            label={option.name}
                            name={option.id}
                            checked={formVal.typeOfTrainings?.some(
                              (grd) => grd.name === option.name
                            )}
                          />
                          {option.name === 'Others (please specify)' && formVal.typeOfTrainings.some((item)=>
                          item.name === "Others (please specify)"
                          ) ? (
                            <>
                            <Input
                            placeholder="Other training provider"
                            onChange={(e)=> handleTextOth("typeOfTrainings", option, e.target.value)}
                            />
                            </>
                          ): null}
                        </div>

                          ))}
                        </div>
                        }
                      />
                      {errors.typeOfTrainings && (
                        <ErrorField err={t(errors.typeOfTrainings.message)} />
                      )}
                </FormGroup>
                  
                    </>
                  ): null}

                 
                </SingleField>

                <DividerLine/>
               
               
                  <SingleField>
                    <FormLabel>{t(completionOfInternshipObj.allowance)}</FormLabel>
                    <UploadNotes style={{marginLeft:"9px",marginBottom:"5px"}}>
                        {t(completionOfInternshipObj.allowanceNotes)}
                      </UploadNotes>
                    <Controller
                      name="total_allowance"
                      control={control}
                      defaultValue={completionData.total_allowance}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("RM")}
                          dataId="total_allowance"
                        />
                      )}
                    />
                    {/* {errors.total_allowance && (
                      <ErrorField err={t(errors.total_allowance.message)} />
                    )} */}
                  </SingleField>

                  {/* <SingleField>
                    <FormLabel>{t(completionOfInternshipObj.additionalAllowance)}</FormLabel>
                    <UploadNotes style={{marginLeft:"9px",marginBottom:"5px"}}>
                        {t(completionOfInternshipObj.additionalAllowanceNotes)}
                      </UploadNotes>
                    <Controller
                      name="additionalAllowance"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          placeholder={t("Education Level")}
                          dataId="additionalAllowance"
                        />
                      )}
                    />
                    {errors.additionalAllowance && (
                      <ErrorField err={t(errors.additionalAllowance.message)} />
                    )}
                  </SingleField> */}

             <SingleField>
              <FormLabel>{t(completionOfInternshipObj.additionalAllowance)}</FormLabel>
              <UploadNotes style={{ marginLeft: "9px", marginBottom: "5px" }}>
                {t(completionOfInternshipObj.additionalAllowanceNotes)}
              </UploadNotes>
              <Controller
                name="additionalAllowance"
                control={control}
                render={({ field }) => (
                  <div>
                    {getAllowanceData &&
                      getAllowanceData.map((option) => (
                        <div key={option.id}>
                          <CheckboxInput
                            margin="15px"
                            onChange={() => additionalAllowanceCheckboxChange(option)}
                            label={option.name}
                            name={option.id}
                            checked={formVal.additionalAllowance?.some(
                              (grd) => grd.name === option.name
                            )}
                          />
                          {option.name === 'Other Allowances' && formVal.additionalAllowance.some((item) => 
                          item.name === 'Other Allowances'
                          ) ? (
                            <Input
                            placeholder="Other allowances"
                            onChange={(e)=> handleTextOth("additionalAllowance", option, e.target.value)}
                            />
                          ): null}
                        </div>
                      ))}
                  </div>
                )}
              />
              {/* {errors.additionalAllowance && (
                <ErrorField err={t(errors.additionalAllowance.message)} />
              )} */}
            </SingleField>


                  <SingleField>
                    <UploadText>{t(completionOfInternshipObj.expenditures)}</UploadText>
                      <UploadNotes>
                        {t(completionOfInternshipObj.expendituresNotes)}
                      </UploadNotes>
                      <UploadFileWrapper data-tip data-for="pdf">
                      <input
                        onChange={uploadFileexpenditure_records}
                        accept="application/pdf"
                        type="file"
                      />
                      <UploadFileText>
                        {t(completionOfInternshipObj.uploadFileText)}
                      </UploadFileText>
                      {isUpload===false?<UploadNotes>No file chosen</UploadNotes> :<UploadNotes></UploadNotes>}
                      <p className="uploadFileOne" style={{marginTop:"5px"}}>
                        {t("The maximum file size limit is 5 MB")}
                      </p>
                    </UploadFileWrapper>
                    {/* <FileWrapper>
                    {formVal?.expenditure_records?.length > 0
                      ? formVal.expenditure_records?.map((x, index) => (
                          <>
                            {getfileName(x.file_path)}
                            <SkillCard
                              key={index + x.file_path}
                              delete={() => onDeleteFilesexpenditure_records(index)}
                              // name={x.file_path}
                              name={fileName + "." + ext}
                            />
                          </>
                        ))
                      : ""}
                  </FileWrapper> */}
                  <FileWrapper>
                  {formVal.expenditure_records?.length > 0 ? (
                    formVal.expenditure_records.map((file, index) => {
                      const filePath = file.file_path?.[index]?.file_path || file.file_path; // Check if file path exists, use file.file_path if not
                      if (!filePath) return null; // Skip processing if file path is empty, null, or undefined

                      const { fileName, ext } = getfileName(filePath);
                      return (
                        <React.Fragment key={index}>
                          {console.log(file.file_path, "This is file path for attendance_records")}
                          <SkillCard
                            key={index + filePath}
                            delete={() => onDeleteFilesexpenditure_records(index)}
                            name={`${fileName}.${ext}`} // Concatenate fileName and ext using `${}`
                          />
                        </React.Fragment>
                      );
                    })
                  ) : (
                    ""
                  )}

                  </FileWrapper>

                  {errors.expenditure_records && (
                    <ErrorField err={t(errors.expenditure_records?.message)} />
                  )}
                    
                  </SingleField>



                  <FormGroup>
                      <FormLabel>
                        {(completionOfInternshipObj.learningOutcomes)}{" \n"}
                        
                      </FormLabel>
                      <UploadNotes style={{marginLeft:"12px"}}> {t(completionOfInternshipObj.learningOutcomesNotes)}</UploadNotes>
                      <Controller
                        name="learningOutcome"
                        control={control}
                        render={({ field }) =>
                        <div>
                          {getLearningOutcomeData &&
                      getLearningOutcomeData.map((option) => (
                        <div key={option.id}>
                          <CheckboxInput
                            margin="15px"
                            onChange={() => learningOutcomesCheckboxChange(option)}
                            label={option.name}
                            name={option.id}
                            checked={formVal.learningOutcome?.some(
                              (grd) => grd.name === option.name
                            )}
                          />
                          {option.name === 'Others (please specify)' && formVal.learningOutcome.some(
                            (item) => item.name === 'Others (please specify)'
                          )? (
                            <Input
                            placeholder= "Other learning outcome"
                            onChange={(e)=> handleTextOth("learningOutcome", option, e.target.value)}
                            />
                          ): null}
                        </div>
                      ))}
                        </div>
                        
                        }
                      />
                      {errors.learningOutcome && (
                        <ErrorField err={t(errors.learningOutcome.message)} />
                      )}
                </FormGroup>
                </FormGroup>
                
                
                {/* Application information collection ends */}
                {loading ? (
                  <Loader />
                ) : (
                <ButtonWrapper>
                    <Button 
                    //redo submit logic when implementing back end 
                    // onClick={handleSubmit(onSubmit)}
                    // // onClick={() => { 
                    // //   handleSubmit(onSubmitErrorHandler());
                    // // }}
                    onClick={handleSubmit(onUpdateSubmit, onSubmitUpdateHandler)}
                    type = "submit"
                    cursor="pointer"
                    name={t("Continue")}
                    ></Button>
                    {/* <Button
                    secondary
                    color="#FFFFFF"
                    name={t("Back")}
                    variant="outlined"
                    onClick={handleSubmit(onSubmit, onSubmitErrorHandler)}
                    ></Button> */}
                </ButtonWrapper>
                )}
              </FormWrapper>
              <Tooltip id="internTitle" content={t("Enter internship title")} />
              <Tooltip id="uploadDocuments" content="Upload Documents" />
              <Tooltip
                id="cgpa"
                content={t("Enter required CGPA (Range 0.0 to 4.0)")}
              />
              <Tooltip
                id="skill"
                content={t("Type here to search and add skill(s)")}
              />
              <Tooltip
                id="document"
                content={t("Mention the optional documents, if any")}
              />
              <Tooltip id="hours" content={t("Enter hours (Range 1 to 24)")} />
              <Tooltip id="minComp" content={t("Min. compensation")} />
              <Tooltip id="maxComp" content={t("Max. compensation")} />
              <Tooltip id="country" content={t("Select Country")} />
              <Tooltip id="state" content={t("Select State")} />
              <Tooltip id="city" content={t("Select city")} />
              <Tooltip
                id="pdf"
                content={t(
                  "Upload PDF document to provide applicants with more information"
                )}
              />
              <Tooltip
                id="contact"
                content={t("DISPLAY CONTACT INFORMATION TO STUDENTS")}
              />
            </SectionWrapper>
          </NewInternPageWrapper>
            ): (
              showForm ?(
               <CompletionOfInternshipFormLoe fromInternship={fromInternship}/>
              ): (
                ""
              )
            )
          )
          
        )}
        <CreateInternshipModuleModal
             title={"Completion Internship"}
             //subTitle={"Internship Title"}
             show={showSipTokenModal}
             close={handleCloseModal}
             content={<TokenContent data={tokenData}/>}
             />
        <AlertModal show={modal} close={onClose} content={content()} />
    </>
  );
}
