import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    usersForSelectedCompany: {},
    selectedUser: {},
    currentlySelectedEditUserId: null,
    currentlySelectedEditId: null,
    currentUser:{},
    isUserAdmin: null
};

export const userManagementReducer = createSlice({
    name: "usermanager",
    initialState,
    reducers: {
        updateUsersForSelectedCompany: (state, action) => {
            state.usersForSelectedCompany = action.payload;
        },
        updateSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        updateCurrentlySelectedEditUserId: (state, action) => {
            state.currentlySelectedEditUserId = action.payload;
        },
        updateCurrentlySelectedEditId: (state, action) => {
            state.currentlySelectedEditId = action.payload;
        },
        updateCurrentUser: (state, action) => {
            state.currentUser = action.payload;
        },
        updateIsUserAdmin: (state, action) => {
            state.isUserAdmin = action.payload;
        }
    },
});

// Action creators are generated for each case reducer function
export const { updateUsersForSelectedCompany, updateSelectedUser, updateCurrentlySelectedEditUserId, updateCurrentlySelectedEditId, updateCurrentUser, updateIsUserAdmin } = userManagementReducer.actions;

export default userManagementReducer.reducer;
