import React from 'react';
import Ad2DrilldownHOC from '../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC';
// import { getMasteryDrilldown } from "../../../../../../services/CommonApi";
// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";


export const getMasteryDrilldown = () =>{
    const getMastery = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Intellectual stimulation": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 113,
                        "moderate": 412,
                        "high": 163
                    }
                },
                "Recognition": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 107,
                        "moderate": 380,
                        "high": 201
                    }
                },
                "Achievement": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 113,
                        "moderate": 393,
                        "high": 182
                    }
                },
                "Independence": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 111,
                        "moderate": 371,
                        "high": 206
                    }
                }
            }
        }
    }

    return getMastery;
}

export default Ad2DrilldownHOC(getMasteryDrilldown, [
    {
        title: "Intellectual Stimulation",
        dataKey: "Intellectual stimulation",
        url: 'intellectual-stimulation',
        info:"It will be important for talents with high Intellectual Stimulation to look for roles that enable them to use their intellect to achieve results."
    },
    {
        title: "Recognition",
        dataKey: "Recognition",
        url: 'recognition',
        info:"It will be important for talents with high Recognition values to work in an environment where they will be able to gain recognition or status for their contribution."
    },
    {
        title: "Achievement",
        dataKey: "Achievement",
        url: 'achievement',
        info:"It is important for talents that scored high in Achievement that they are in roles where they can gain a sense of achievement through setting goals, working hard and achieving results."
    },
    {
        title: "Independence",
        dataKey: "Independence",
        url: 'independence',
        info:"It will be important for talents with high Independence score to work in a role with a high level of independence and autonomy."
    },
]);

