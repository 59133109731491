import React from 'react';
import styled from "styled-components";
import Carousel from 'react-multi-carousel';
import "react-multi-carousel/lib/styles.css";
import './swiper.css';
import { useTranslation } from "react-i18next";
import { AspectRatio } from "react-aspect-ratio";
import 'react-aspect-ratio/aspect-ratio.css';

const SectionWrapper = styled.div`
background: ${(props) => props.primary ? props.primary : '#FDFDFD'};
border: 1px solid rgba(228, 228, 228, 0.26);
border-radius: 10px;
padding:30px;
margin:40px 30px;

@media (max-width:500px){
    margin:80px 30px 40px 30px;
  }
  
  }
`
const Title = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
color: ${(props) => props.secondary ? props.secondary : '#D04C8D'};
`
const CardOuter = styled.div`

`
const Card1 = styled.div`
background: #FDFDFD;
border: 1px solid #D04C8D;
border-radius: 16px;

`

const CardText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 19px;
color: #202020;
text-align:center;
margin:20px;
height:40px;
`


const Image2 = styled.img`
object-fit: cover;
width:100%;
height:250px;
border-radius:16px 16px 0 0;
marginLeft:1px;
`
const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 2,
    },
    Largedesktop: {
      breakpoint: { max: 1200, min: 1025 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

const Slider = (props) => {
  const { t, i18n } = useTranslation();

    return (
        <SectionWrapper primary={props.primary}>
            <Title secondary={props.secondary}>{t("Company Photos")}</Title><hr />
            <Carousel
            showDots={true}
            responsive={responsive}
            arrows={false}
            >
                {props?.data?.length>0 && props?.data?.map((list, index) =>
                        
                            <CardOuter className="mt-3 mb-5" key={index}>
                            <div className="mt-3 me-3" style={{justifyContent:'center'}} >
                                <Card1 >
                                  <AspectRatio ratio="4/3">
                                    <Image2 src={list?.image_path} alt="image" />
                                    </AspectRatio>
                                    <CardText>{list?.description}</CardText>
                                </Card1>
                            </div>
                            </CardOuter>
                           
                )}

            </Carousel>
        </SectionWrapper>

    )
}
export default Slider


