import styled from "styled-components";

import { Colors } from "../../utilities/colors";

// styling starts
// const InputWrapper=styled.
const InputField = styled.input`
  background: ${(props) => (props.color ? props.color : Colors.inputBg)};
  border-radius: 128px;
  border: 0px;
  padding: 12px 20px;
  outline: none;
  //width: -webkit-fill-available;
  width: ${(props) => (props.width ? props.width : '-webkit-fill-available')};
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-overflow: ellipsis;

  // color: ${Colors.dark};
 color:#202020;
  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Colors.inputText};
  }
  :-ms-input-placeholder {
    color: ${Colors.inputText};
  }
  &:hover,
  :focus,
  :active {
    box-shadow: ${(props) => (props.disabled ? "" : "0 0 0 1px #ff60b1")};
    border-color: ${(props) => (props.disabled ? "" : "#ff60b1")};
  }
`;
// styling ends

export default function Input(props) {
  return (
    <div data-tip data-for={props.dataId ? props.dataId : ""}>
      {" "}
      <InputField {...props} disabled={props.disabled } />{" "}
    </div>
  );
}
