import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { updateModal } from "../../reducers/detailModalReducer";
import crossIcon from "../../assets/closeIcon.png";
const CloseIconWrapper = styled.span`
  right: 40px;
  position: absolute;
  right: 50px;
  top: 30px;
  color: #d04c8d;
  cursor: pointer;
  z-index: 1;
`;

export default function UniversityModal({
  children,
  onClose = null,
  coaching,
}) {
  const dispatch = useDispatch();
  const modalIsOpen = useSelector((state) => state.detailModal.modalIsOpen);
  const onCloseAction = () => {
    if (onClose) {
      onClose();
    }
    dispatch(updateModal(false));
  };
  const closeButton = (
    <CloseIconWrapper>
      <img src={crossIcon} width={16}/>
    </CloseIconWrapper>
  );
  return (
    <Modal
      open={modalIsOpen}
      closeIcon={closeButton}
      onClose={onCloseAction}
      center
      classNames={{
        closeButton: `university`,
        root: `university`,
        modal: `university `,
      }}
    >
      {children}
    </Modal>
  );
}
