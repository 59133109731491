import { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import PhoneInput from "react-phone-number-input";
import { Grid } from "@material-ui/core";
import { toast } from "react-toastify";


import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schemaNewObj } from "./staticContent";
import ErrorField from "../errorField/errorField";
import { updateContactDataNew, getCompanyData } from "../../services/apiCalls";
import { updateCompanyData } from "../../pages/myCompany/reducer";
import Loader from "../loader/loader";
import { viewContactData } from "../../services/apiCalls";
import {
  updateContactData,
  updateEdit,
} from "../../components/AddContact/reducer";
import { useTranslation } from "react-i18next";

// const CalenderIconWrapper = styled.div`
//   position: absolute;
//   right: 1rem;
//   top: 0.5rem;
//   cursor: pointer;
// `;
// const materialTheme = createMuiTheme({
//   palette: {
//     primary: {
//       main: "#D44B9C",
//     },
//   },
//   overrides: {
//     MuiPickersCalendarHeader: {
//       switchHeader: {
//         color: "#D44B9C",
//       },
//     },
//   },
// });
const ModalWrapper = styled.div`
  padding: 10px;
  width: "100%";
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
// const FormGroup = styled.div``;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

// const AcademicGridWrapper = styled(GridWrapperx)`
//   grid-template-columns: ${(props) =>
//     props.template ? props.template : "3fr 1fr"};
//   grid-column-gap: 20px;
//   @media (max-width: 500px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
//   label {
//     position: relative;
//     //width: -webkit-fill-available;
//   }
// `;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;
// const GridWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-column-gap: 20px;
//   @media (max-width: 500px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
// `;
// const StatusWrapper = styled.div`
//   display: flex;
//   align-items: center;
// `;

// const statuses = ["Primary", "Secondary"];

export default function UpdateContact(props) {
  const onClose = props.onClose;
  const ids = props.id;
  // const company = useSelector((state) => state.company.companyData);
  const org = useSelector((state) => state.org.orgData);
  // const open = useSelector((state) => state.modal.open);
  const contact = useSelector((state) => state.contact.contactNewData);
  const {t,} = useTranslation();
  const defaultVal = {
    email: contact && contact.email ? contact.email : "",
    first_name: contact && contact.first_name ? contact.first_name : "",
    last_name: contact && contact.last_name ? contact.last_name : "",
    job_title: contact && contact.job_title ? contact.job_title : "",
    department: contact && contact.department ? contact.department : "",
    mob_number: contact && contact.mob_number ? contact.mob_number : "",
    // created_at: contact && contact.created_at ? contact.created_at : "",
  };

  const getData = async () => {
    const { data } = await viewContactData({
      code: ids,
      id: org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      dispatch(updateContactData(data.data));
      dispatch(updateEdit(true));
    }
  };
  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schemaNewObj),
  });

  const formVal = watch();
  const dispatch = useDispatch();
  const onMblNumberChange = (val) => {
    if (val) {
      setValue("mob_number", val);
    }
  };
  const [mobNumErr, setMobNumErr] = useState('');

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await updateContactDataNew({
      code: org?.additional_info?.org_code,
      id: ids,
      data: { ...formVal },
    });
    if (formVal.mob_number.length < 12) {
      setMobNumErr("Invalid ")
      setLoading(false);
      return;
    }
    else {
      if (data && data.status === 1) {
        toast.success(t('Details updated successfully.'), {
          position: toast.POSITION.TOP_RIGHT,
        });
        props.onClose();
        getMainData();
        setFormErrors("");
      } else {
        toast.error(`${t(data.message)}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
        setFormErrors(data && data.message);
      }
    }
  };
  const getMainData = async () => {
    const { data } = await getCompanyData({
      code: org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCompanyData(data.data));
    } else {
      setLoading(false);
    }
  };

  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3 style={{ width: "auto" }}>{t('Contact Edit')}</h3>
            <hr />
            <FormWrapper>
              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Email Address*')}</FormLabel>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          disabled={true}
                          placeholder={t("Email Address")}
                        />
                      )}
                    />
                    {errors.email && <ErrorField err={t(errors.email.message)} />}
                  </SingleField>
                </Grid>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('First Name*')}</FormLabel>
                    <Controller
                      name="first_name"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={t("First Name")} />
                      )}
                    />
                    {errors.first_name && (
                      <ErrorField err={t(errors.first_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Last Name*')}</FormLabel>
                    <Controller
                      name="last_name"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={t("Last Name")} />
                      )}
                    />
                    {errors.last_name && (
                      <ErrorField err={t(errors.last_name.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Job Title*')}</FormLabel>
                    <Controller
                      name="job_title"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={t("Job Title")} />
                      )}
                    />
                    {errors.job_title && (
                      <ErrorField err={t(errors.job_title.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Department*')}</FormLabel>
                    <Controller
                      name="department"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={t("Department")} />
                      )}
                    />
                    {errors.department && (
                      <ErrorField err={t(errors.department.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Mobile Number*')}</FormLabel>
                    <Controller
                      name="mob_number"
                      control={control}
                      render={({ field }) => (
                        <PhoneInput
                          value={formVal.mob_number}
                          onChange={onMblNumberChange}
                          defaultCountry="MY"
                          international
                          withCountryCallingCode={true}
                          countryCallingCodeEditable={false}
                          maxLength={16}
                          minLength={12}

                        // minLength={12}
                        />
                      )}
                    />
                    {errors.mob_number && (
                      <ErrorField err={t(errors.mob_number.message)} />
                    )}
                    {mobNumErr && (
                      <ErrorField err={t(mobNumErr)} />
                    )}
                  </SingleField>
                </Grid>
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}
                >
                  {formErrors && <ErrorField err={t(formErrors)} />}
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button style={{ marginRight: "1.5%" }} name={t("Confirm")} onClick={handleSubmit(onSubmit)} />
                  )}
                  <Button
                    secondary
                    color="#FFFFFF"
                    name={t("Cancel")}
                    variant="outlined"
                    onClick={onClose}
                  >

                  </Button>
                </Grid>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
