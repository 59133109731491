import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { useTranslation } from "react-i18next";

const FooterWrapper = styled.footer`
    margin-top: 100px;
    // position:absolute;
    border-top: 2px solid #B5B5B6;
    p {
        font-size: 12px;
        line-height: 16px;
        color: #9A9A9A;
    }
`;

const  FooterSection = styled.div`

bottom:0;
width: 100%;
`;

document.querySelector('body').height = (e) => {
    e.target.style.setProperty('--y',`${e.pageY}px`)
    
}
export default function Footer() {
    const {t} = useTranslation();
    return (
        <FooterSection >
            <Row>
                <Col>
                    <FooterWrapper >
                        <div className="d-md-flex justify-content-md-between">
                            <p>{t('Locate us')}: <br />
                                6th Floor, Surian Tower, 1, Jalan PJU 7/3, Mutiara Damansara,<br />
                                47810 Petaling Jaya, Selangor
                            </p>
                            { <p>Contact Us:<br />
                                +603 7839 7000
                            </p> }
                        </div>
                    </FooterWrapper>
                </Col>
            </Row>
        </FooterSection>
    )
} 