import React, { useState, useEffect } from 'react'
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import TalentSearch from "../../assets/Saly-14.png";
import Employee from "../../assets/3d illustration pack 4.png";
import './SubscriptionsRenew.css';
import Button from "../../components/button/button";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import SubscriptionExpiredPopup from '../../components/Subscriptions/subscriptionExpiredPopup';
import moment from "moment";
import { updateRequestNewSubscription } from "../../pages/Subscriptions/reducer";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { paymentGateWay } from '../../services/apiCalls';
import { getCompanySubscription } from '../../services/apiCalls';
import { orgDataReducer } from '../login/reducer';
import { updateSubscriptionData } from '../../pages/Subscriptions/reducer';

const HeaderText = styled.h2`
  margin-top:2%;
  margin-bottom:1%;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

const CardsWrapper = styled.div`
  background: ${Colors.light};
 
  border-radius:32px;
  padding-bottom:1%;  
  `
const Header = styled.div`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #D04C8D;
  padding:4% 0px 0px 5%;
  text-transform: capitalize;
`

const HrLine = styled.hr`
border: 0.6px solid #C4C4C4;
width:90%;
position:relative;
margin-top:5%;
margin-left:5%;
@media screen and (max-width:767px){
    display:none;
}
`;
const ButtonWrapper = styled.div`
  display:flex;
  width:100%;
  flex-direction:row;
  justify-content:space-around;
`;
const Subscriptions = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [subscriptionExpired, setSubscriptionExpired] = useState(false);
  const [monthBefore, setMonthBefore] = useState(true)

  const [daysLeft, setDaysLeft] = useState("")
  const [notified, setNotified] = useState(true)

  const [loading, setLoading] = useState(true)

  const [renewdata, setRenewdata] = useState([])

  const [existingSubscriptions,setExistingSubscriptions] = useState([])
  const subscribedData = useSelector((state) => state.subscription.subscriptionData);
  const orgDetails = useSelector((state) => state.company.currentOrgData);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const userStatus = orgDetails?.user_details?.status
  const dispatch = useDispatch();

  const company = useSelector((state) => state.company.currentlySelectedcompanyData);
  const loggedInUser = useSelector((state) => state.user.userData);
  const lang = sessionStorage.getItem("lang");

  const toggleModal = () => {
    setModal(!modal);
  };

  const { t } = useTranslation();

  const moveToNewSubscription = () => {
    dispatch(updateRequestNewSubscription(true))
    navigate("/subscriptions");
  }

  const plan = (e) => {
    if (e === "Basic") return "subbase"
    if (e === "Pro") return "subpro"
    if (e === "Premium") return "subprem"
    if (e === "Enterprise") return "subentr"
  }
  const checkIsSubscriptionExpired = (data) => {
    let today = moment(new Date()).format("MM-DD-YYYY"); //moment()
    today = new Date(today);
    let renew_date = data.renew_date.split("-");
    renew_date = new Date(
      renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2]
    );
    return today > renew_date;
  }

  const checkValidRenewDates = (renew_date) => {
    let oneMonthBefore = false
    let today = moment(new Date()).format("DD-MM-YYYY");
    today = today.slice(6) + "-" + today.slice(3, 5) + "-" + today.slice(0, 2)
    renew_date = renew_date?.slice(6) + "-" + renew_date?.slice(3, 5) + "-" + renew_date?.slice(0, 2)
    let todayEpoch = new Date(today).getTime() / 1000
    let renewEpoch = new Date(renew_date).getTime() / 1000
    if (renewEpoch - todayEpoch > 0) {
      oneMonthBefore = true
    }
    return oneMonthBefore
  }

  const checkDateBeforeMonth = () => {
    let oneMonthBefore = true
    let today = moment(new Date()).format("DD-MM-YYYY");
    let renew_date = subscribedData[org_code]?.renew_date
    today = today.slice(6) + "-" + today.slice(3, 5) + "-" + today.slice(0, 2)
    renew_date = renew_date.slice(6) + "-" + renew_date.slice(3, 5) + "-" + renew_date.slice(0, 2)

    let todayEpoch = new Date(today).getTime() / 1000
    let renewEpoch = new Date(renew_date).getTime() / 1000
    const monthGap = 30 * 24 * 60 * 60
    if (renewEpoch - todayEpoch <= monthGap) {
      oneMonthBefore = false
      const daysleftForSub = Math.floor((renewEpoch - todayEpoch) / (24 * 60 * 60))
      setDaysLeft(daysleftForSub)
    }
    setMonthBefore(oneMonthBefore)
    return oneMonthBefore
  }

  const renew = async () => {
    
    navigate("/subscriptions/subscription-plan")

  }

  useEffect(() => {
    if (subscribedData[org_code]?.renew_date) checkDateBeforeMonth()
  })
  const getAllSubscriptions = async () => {
    const { data } = await getCompanySubscription(org_code)
  
    if (data?.data?.length > 0) {
      const finaldates = data?.data?.filter((sub) => sub.paymentstatus === "success" && checkValidRenewDates(sub.renew_date))
      const exisitngExpiry = data?.data?.filter((sub) => sub.paymentstatus === "success")
      setExistingSubscriptions(exisitngExpiry)
      setRenewdata(finaldates)
    }
  }
  function formatDateToDDMMYYYY(inputDate) {
    if (typeof inputDate === 'string') {
      // If the input is a string, attempt to parse it as a date
      const parsedDate = new Date(inputDate);
      if (!isNaN(parsedDate)) {
        // If the parsing was successful, format the date
        const day = parsedDate.getDate().toString().padStart(2, '0');
        const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
        const year = parsedDate.getFullYear();
        return `${day}-${month}-${year}`;
      }
    }
    return inputDate;
  }

  useEffect(async () => {
    if (org_code && loading) {
      getAllSubscriptions()
      setLoading(false)
    }
  })


  useEffect(() => {
    if (org_code && subscribedData && subscribedData[org_code]) {
      let isExpired = checkIsSubscriptionExpired(subscribedData[org_code]);
      setSubscriptionExpired(isExpired)
    }
    if (org_code && subscribedData[org_code]?.paymentstatus !== "success" && !(subscribedData[org_code] && Object.keys(subscribedData[org_code]).length))
      navigate("/subscriptions");
  }, [org_code])


  useEffect(() => {
    if (!monthBefore && notified) {
      if(existingSubscriptions && existingSubscriptions?.length>0 && checkValidRenewDates(existingSubscriptions[0].renew_date)){
        toast.error(`${t("Your Subscription Expires in")} ${daysLeft} ${t("days")}`, {
          position: toast.POSITION.TOP_RIGHT,
        });
        setNotified(false)
      }
    }
  })

  return (
    <div>
      {/* <SubscriptionExpiredPopup isOpen={subscriptionExpired} proceed={moveToNewSubscription} /> */}
      <MainLayout>

        <HeaderText>
          {t("Subscriptions")}
        </HeaderText>
        <>{userStatus == 'accepted' ?
          <CardsWrapper>

            { lang == "my" ?
              <Header>
                Anda sedang melanggani ciri-ciri {renewdata.length > 0 ? renewdata[renewdata.length-1]?.plan : ''}
              </Header> : 
              <Header>
                Subscribed to {renewdata.length > 0 ? renewdata[renewdata.length-1]?.plan : ''} Features
              </Header >
            }

            <div style={{ paddingLeft: "5%" }}>{t("This is an annual subscription. This will give you access to these features:")}
            </div>


            <div className="talent">
              <img src={TalentSearch} alt="img" className="talImg" />
              <div>
                <p className="talTitle">{t("Talent Search")}</p>
                <p className="talContent">{t("Look for graduating talent")}</p>
              </div>
            </div>
            <HrLine />
            <div className="talent">
              <img src={Employee} alt="img" className="empImg" />
              <div>
                <p className="empTitle">{t("Employee")}</p>
                <p id='con' className="empcontent" style={{ margin: "0px 10px" }}>{t("Get your employee to perform psychometric assessment and see the results")}</p>
              </div>
            </div>

            {/* <div style={{borderLeft:"2px solid grey",height:"278px",marginTop:"3%"}}></div> */}
            <p className="subsmsg">
              { checkValidRenewDates(existingSubscriptions[0]?.renew_date) ? t(" This subscription ends on") : t(" This subscription ended on")}
             
              <br />
              {existingSubscriptions.length > 0 ?
                <span className="expDate">
                  
                  {formatDateToDDMMYYYY(existingSubscriptions[0]?.renew_date)} 
                  {renewdata[renewdata.length - 1]?.plan === renewdata[0]?.plan ? "" : "("+ " "+renewdata[0]?.plan+" Plan "+")"}
                </span>
                : null}

            </p>
            {/* <div className="buttonWrapper"> */}

            <ButtonWrapper>
              {monthBefore ? null : <Button name="Renew" onClick={renew} />}
            </ButtonWrapper>


            {/* </div> */}
          </CardsWrapper> : <><p className="noDataFound">No data found</p></>}</>

        {modal && (
          <div className="modal">
            <div onClick={toggleModal} className="overlay"></div>
            <div className="modal-content1">
              <h2>Hello Modal</h2>

              <p>
                Lorem ipsum dolor sit amet consectetur adipisicing elit. Provident
                perferendis suscipit officia recusandae, eveniet quaerat assumenda
                id fugit, dignissimos maxime non natus placeat illo iusto!
                Sapiente dolorum id maiores dolores? Illum pariatur possimus
                quaerat ipsum quos molestiae rem aspernatur dicta tenetur. Sunt
                placeat tempora vitae enim incidunt porro fuga ea.
              </p>
              <button className="close-modal" onClick={toggleModal}>
                CLOSE
              </button>
            </div>
          </div>
        )}
      </MainLayout>
    </div>
  )
}

export default Subscriptions
