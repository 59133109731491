import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  contactNewData: {
    first_name: "",
    last_name: "",
    email: "",
    job_title: "",
    department: "",
    mob_number: "",
  },
  isEdit: false,
};

export const newContactReducer = createSlice({
  name: "contact",
  initialState,
  reducers: {
    updateContactData: (state, action) => {
      state.contactNewData = action.payload;
    },
    updateEdit: (state, action) => {
      state.isEdit = action.payload;
    },
  },
});

export const { updateContactData, updateEdit } = newContactReducer.actions;

export default newContactReducer.reducer;
