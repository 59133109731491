import { createCustomMessage } from 'react-chatbot-kit';
import BotProfile from "../../assets/botProfile.png";
import Options from './widgets/options/options';
import ListComponent from './widgets/list/listBenefits';
import ListZeroViewsComponent from './widgets/list/listZeroViews';
import ListLoginDetailsComponent from './widgets/list/listLoginDetails';
import ChatbotRating from './widgets/chatbotRating/chatbotRating';
import FeedbackComponent from './widgets/feedback/feedback';
import Resolution from './widgets/resolution/resolution';
import VideoPlayerComponent from './widgets/video/video';
import CustomMessage from './customMessage.jsx';

const config = {
  initialMessages: [
    createCustomMessage('Test', "custom",{
      widget: "options",
    }),
  ],
  customComponents: {
    // Replaces the default header
    //  header: () => <div style={{ backgroundColor: 'red', padding: "5px", borderRadius: "3px" }}>This is the header</div>
    // Replaces the default bot avatar
    botAvatar: (props) => <img src={BotProfile} className='height-40px' {...props} />,
    // Replaces the default bot chat message container
    //  botChatMessage: (props) => <CustomChatMessage {...props} />,
    // Replaces the default user icon
    //  userAvatar: (props) => <MyUserAvatar {...props} />,
    // Replaces the default user chat message
    //  userChatMessage: (props) => <MyUserChatMessage {...props} />
  },
  customMessages: {
    custom: (props) => <CustomMessage {...props} />
  },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => <Options {...props} />,
    },
    {
      widgetName: 'listComponent',
      widgetFunc: (props) => <ListComponent {...props} />,
    },
    {
      widgetName: 'listZeroViewsComponent',
      widgetFunc: (props) => <ListZeroViewsComponent {...props} />,
    },
    {
      widgetName: 'listLoginDetailsComponent',
      widgetFunc: (props) => <ListLoginDetailsComponent {...props} />,
    },
    {
      widgetName: 'videoComponent',
      widgetFunc: (props) => <VideoPlayerComponent {...props} />,
    },
    {
      widgetName: 'chatbotRating',
      widgetFunc: (props) => <ChatbotRating {...props} />,
    },
    {
      widgetName: 'feedback',
      widgetFunc: (props) => <FeedbackComponent {...props} />,
    },
    {
      widgetName: 'resolution',
      widgetFunc: (props) => <Resolution {...props} />
    },
  ],
};

export default config;