import React, { useEffect, useState } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { cloneDeep } from "lodash";
import { Utils } from "../utilities";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useNavigate } from "react-router-dom";
import cookiesService from "../../../reducers/cookies.service";
import { t } from "i18next";

ChartJS.register(ArcElement, Tooltip, Legend);
ChartJS.unregister(ChartDataLabels);

const defaultOptions = {
  cutout: "85%",
  aspectRatio: 1,
  borderWidth: 1,
  borderRadius: 30,
  circumference: 300,
  rotation: -150,
  legend: {
    display: false,
  },
  layout: {
    padding: {
      left: 40,
      right: 40,
      top: 0,
      bottom: 0,
    },
    margin: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
    },
  },
  responsive: true,
  plugins: {
    datalabels: {
      display: false,
    },
    legend: {
      display: false,
      position: "bottom",
      labels: {
        usePointStyle: true,
        boxWidth: 6,
      },
    },
    tooltip: {
      enabled: true,
      yAlign: "bottom",
      xAlign: "center",
      displayColors: false,
      padding: 10,
      titleFont: {
        size: 12,
        lineHeight: 18,
        weight: 500,
        style: "normal",
        family: "Oswald",
      },
      callbacks: {
        label: (context) => {

          return Utils.intToString(context.raw);
        },
      },
    },
  },
  title: {
    display: false,
  },
};

const centeredText = {
  id: "text",
  beforeDraw: function (chart, a, b) {
    var width = chart.width,
      height = chart.height,
      ctx = chart.ctx;

    const legendItems = chart.boxes[1].legendItems;
    const data = chart.data.datasets[0].data;
    let sum = 0;
    if (legendItems && legendItems.length) {
      legendItems.forEach((item, index) => {
        if (!item.hidden) {
          sum += data[index];
        }
      });
    }
    const registeredUser = (data && data[0] + data[1]) || 0;
    console.log("registeredUser",registeredUser);
    ctx.restore();
    ctx.textBaseline = "middle";
    [t("Total"), t("Employee"), Utils.intToString(registeredUser)].forEach((item, index) => {
      if (index !== 3) {
        ctx.font = "normal normal 400 22px/24px Inter";
        ctx.fillStyle = "#615E83";
      } else {
        ctx.font = "normal normal 700 30px/32px Oswald";
        ctx.fillStyle = "#1E1B39";
      }
      ctx.textAlign = "center";
      var textX = width / 2,
        textY =
          index === 2 ?
            height / 2 + 32 :
            index === 1
              ? height / 2 + 6
              :
               index === 0
                ? height / 2 - 22
                : height / 2 + 80;
      ctx.fillText(item, textX, textY);
    });
    ctx.save();
  },
};

export default function DonutChartBottomOpen({ data, optionsOverride, clickEvent = null }) {

  let finalOptions = cloneDeep(defaultOptions);
  const chartRef = React.useRef(null);
  const legendRef = React.useRef(null);
  const navigate = useNavigate();

  if (optionsOverride) {
    finalOptions = { ...finalOptions, ...optionsOverride };
  }

  const htmlLegendPlugin2 = {
    id: "htmlLegend",
  };
  const plugins = [htmlLegendPlugin2, centeredText];

  useEffect(() => {
    htmlLegendPlugin2.afterUpdate = function (chart, args, options) {
      const ul = Utils.getOrCreateLegendList(chart, this.legendRef);

      while (ul.firstChild) {
        ul.firstChild.remove();
      }


      const items = chart.options.plugins.legend.labels.generateLabels(chart);


      const chartDataArr = chart.data.datasets[0].data;


      const chartDataArrSum = chartDataArr.reduce(function (a, b) {
        return a + b;
      }, 0);
      const chartDataStringArr = chartDataArr.map(x => Utils.convertAndRoundToPercentage(x / chartDataArrSum) + '%');
      items.forEach((item, index) => {
        const li = document.createElement("li");

        li.style.alignItems = "center";
        li.style.cursor = "pointer";
        li.style.display = "flex";
        li.style.flexDirection = "row";
        li.style.marginLeft = "10px";

        li.onclick = () => {
          const clickedLabelName = item.text;


          cookiesService.setItem("clickedLabelName", clickedLabelName);
          const ddata = chart.data;
          console.log("chartData", ddata);
          console.log("labelName", clickedLabelName);
          console.log("chartData", chartDataArrSum);
          console.log("percentageStudent", chartDataStringArr);
          if (clickEvent) {
            clickEvent(clickedLabelName)
          }

        };


        const boxSpan = document.createElement("span");
        boxSpan.style.background = item.fillStyle;
        boxSpan.style.borderColor = item.strokeStyle;
        boxSpan.style.borderWidth = item.lineWidth + "px";
        boxSpan.style.borderRadius = "10px";
        boxSpan.style.display = "inline-block";
        boxSpan.style.height = "10px";
        boxSpan.style.marginRight = "10px";
        boxSpan.style.width = "10px";


        const textContainer = document.createElement("p");

        textContainer.style.fontFamily = "Inter";
        textContainer.style.fontStyle = "normal";
        textContainer.style.margin = "0px";
        textContainer.style.color = "#585858";
        textContainer.style.fontWeight = "400";
        textContainer.style.lineHeight = "13px";
        textContainer.style.fontSize = "13px";
        textContainer.style.marginLeft = "3px";
        textContainer.style.textDecoration = item.hidden ? "line-through" : "";

        const text = document.createTextNode(item.text);
        textContainer.appendChild(text);
        const percentageContainer = document.createElement("span");
        percentageContainer.style.fontFamily = "Oswald";
        percentageContainer.style.fontStyle = "normal";
        percentageContainer.style.color = "#1E1B39";
        percentageContainer.style.fontWeight = "500";
        percentageContainer.style.lineHeight = "14px";
        percentageContainer.style.fontSize = "14px";
        percentageContainer.style.marginLeft = "3px";
        percentageContainer.textContent = chartDataStringArr[index];
        textContainer.appendChild(percentageContainer);

        li.appendChild(boxSpan);
        li.appendChild(textContainer);
        ul.appendChild(li);
      });
    }.bind({
      legendRef: legendRef.current,
    });
    chartRef.current.update();
  }, []);

  return (
    <>
      <>
        <Doughnut
          data={data}
          ref={chartRef}
          options={finalOptions}
          plugins={plugins}
        />
      </>
      <div id="legend-container" ref={legendRef}></div>
    </>
  );
}
