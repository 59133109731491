import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { cloneDeep } from "lodash";

ChartJS.register(ArcElement, Tooltip, Legend);

const defaultOptions = {
    cutout: "80%",
    borderRadius: 6,
    aspectRatio: 1,
    rotation: 0,
    layout: {
        padding: {
            left: 40,
            right: 40,
            top: 0,
            bottom: 0,
        },
        margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        },
    },
    responsive: true,
    plugins: {
        legend: {
            display: false,
            position: "top",
            width: 40,
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            enabled: false,
        },
    },
    title: {
        display: false,
    },
};

const getData = (end, currentValue, labels, donutColor) => ({
    labels: labels || [],
    datasets: [
        {
            data: [currentValue, end - currentValue],
            backgroundColor: [donutColor, "#F1F1F1"],
            borderColor: [donutColor, "#F1F1F1"],
            borderWidth: [0.5, 0],
            hoverBackgroundColor: [donutColor, "#F1F1F1"],
        },
    ],
});

const getPlugins = (
    gradientStart,
    gradientEnd,
    icon,
) => ({
    id: "curves",
    afterDraw(chart) {
        const {
            ctx,
        } = chart;
        ctx.save();
        const arc = chart.getDatasetMeta(0).data[0];
        const gradient = ctx.createRadialGradient(
            arc.x,
            arc.y,
            arc.innerRadius / 4,
            arc.x - 4,
            arc.y - 4,
            arc.innerRadius,
        );
        gradient.addColorStop(0.2917, gradientStart);
        gradient.addColorStop(0.8594, gradientEnd);
        ctx.beginPath();
        ctx.arc(
            arc.x,
            arc.y,
            arc.innerRadius,
            0,
            Math.PI * 2,
            false
        );
        ctx.fillStyle = gradient;
        ctx.fill();
        ctx.beginPath();
        ctx.drawImage(icon, arc.x - icon.width / 2, arc.y - icon.height / 2, icon.width, icon.height);
        ctx.stroke();
    },
    beforeDraw(chart) {
        const {
            ctx,
        } = chart;
        ctx.save();
        const arc = chart.getDatasetMeta(0).data[0];
        ctx.strokeStyle = "#F1F1F1";
        ctx.lineWidth = arc.outerRadius - arc.innerRadius;
        ctx.lineCap = "round";
        ctx.beginPath();
        ctx.arc(
            arc.x,
            arc.y,
            arc.innerRadius + ctx.lineWidth / 2,
            0,
            Math.PI * 2,
            false
        );
        ctx.stroke();
    },
});

export default function DonutChartWithImage({
    currentValue,
    optionsOverride,
    labels = null,
    showLegend = false,
    donutColor,
    icon,
    gradientStart,
    gradientEnd,
}) {
    const [start, end] = [0, 100];
    const data = getData(end, currentValue, labels, donutColor);
    let finalOptions = cloneDeep(defaultOptions);
    if (optionsOverride) {
        finalOptions = { ...finalOptions, ...optionsOverride };
    }
    finalOptions.plugins.legend.display = showLegend;
    const curves = getPlugins(
        gradientStart,
        gradientEnd,
        icon
    );
    const chartRef = React.useRef(null);

    const plugins = [curves];

   

    return (
        <Doughnut
            style={{ maxHeight: '120px' }}
            data={data}
            ref={chartRef}
            options={finalOptions}
            plugins={plugins}
        />
    );
}
