import styled from "styled-components";

import { Colors } from "../../utilities/colors";

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:50px;
`;
const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;

export default function RadioInput({
  name,
  label,
  margin,
  checked,
  onChange,
  dataId,
  defaultChecked,
  disabled,
}) {

  return (
    <RadioButtonGroup
      onClick={onChange}
      margin={margin}
      className="radio"
      data-tip


      data-for={dataId ? dataId : ""}
    >
      <input
        checked={checked}
        onChange={onChange}
        name={label}
        type="radio"
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
      <Label className="radio-label" style={{ margin: 0 }}>{label}</Label>
    </RadioButtonGroup>
  );
}

export function RadioInput1({
  name,
  label,
  margin,
  checked,
  onChange,
  dataId,
  defaultChecked,
  disabled,
}) {

  return (
    <RadioButtonGroup
      onClick={onChange}
      margin={margin}
      className="radio"
      data-tip


      data-for={dataId ? dataId : ""}
    >
      <input
        checked={checked}
        onChange={onChange}
        name={name}
        type="radio"
        defaultChecked={defaultChecked}
        disabled={disabled}
      />
      <Label className="radio-label" style={{ margin: 0 }}>{label}</Label>
    </RadioButtonGroup>
  );
}
