import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Img from "../../assets/Profile.png";
import femaleUser from "../../assets/female.png";
import maleUser from "../../assets/male.png";
import Form from "react-bootstrap/Form";
import Pagination from "@mui/material/Pagination";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import LeftArrow from "../../assets/Group 1577.png";
import RightArrow from "../../assets/Group 1578.png";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { jobProxy } from "../../pages/jobPortal/jobproxy";
import { useSnapshot } from "valtio";
import HamburgerDiv from "../../components/hamburger/hamburger";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
import "./talentSelection.css";
import { useDispatch, useSelector } from "react-redux";
import ReactTooltip from "react-tooltip";
import { ReactComponent as StarIcon } from "../../assets/svg/star_icon.svg";
import { useTranslation } from "react-i18next";
import { ReactComponent as BadgeIcon } from "../../assets/icons/svg/checkPink.svg";
import { updateUserDetails } from "./talentReducer";

const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: 20px 0 -10px 0;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  width: 157px;
  position: relative;
  left: 88%;
  bottom: 50px;
  @media (max-width: 500px) {
    position: relative;
    top: 20px;
    bottom: 20px;
    left: 80px;
  }
`;
const SectionWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  width: 858px;
  height: 104px;
  display: flex;
  margin: -50px 0 0 -20px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: -50px -10px 0 -20px;
    width: 320px;
    margin: 27px 2px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    width: 574px;
    height: auto;
    display: flex;
    margin: -30px 20px 0px;
  }
  @media (min-width: 820px) and (max-width: 1200px) {
    width: 680px;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #d04c8d;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 50px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0px 0px 0px 20px;
  }
`;
const ButtonWrapper2 = styled.div`
  > Button {
    width: 125px;
    height: 56px;
    position: relative;
    top: 10px;
    left: 480px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    > Button {
      position: absolute;
      top: 10%;
      left: 184px;
      width: 125px;
    }
  }

  @media (min-width: 481px) and (max-width: 768px) {
    > Button {
      width: 125px;
      height: 56px;
      position: relative;
      top: auto;
      left: 200px;
    }
  }
  @media (min-width: 820px) and (max-width: 1200px) {
    > Button {
      left: 270px;
    }
  }
`;

const MainWrapper = styled.div`
  background: #ffffff;
  padding: 2px 24px 0px 24px;
  border-radius: 10px;
  margin: 12px 0px 12px -20px;
  width: 858px;
  height: 176px;

  @media (min-width: 320px) and (max-width: 480px) {
    width: 340px;
    height: auto;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    padding: 12px 24px 0px;
    border-radius: 24px;
    margin: 12px 19px;
    /* width: 718px; */
    height: 176px;
    width: auto;
  }
  @media (min-width: 820px) and (max-width: 1200px) {
    width: 680px;
  }
`;

const MainWrapper1 = styled.div`
  background: #ffffff;
  padding: 2px 24px 0px 24px;
  border-radius: 10px;
  margin: 12px 0px;
  width: 858px;
  height: 176px;
  position: relative;
  bottom: 65px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    padding: 12px 24px 0px;
    border-radius: 24px;
    margin: 12px 19px;
    /* width: 718px; */
    height: 176px;
    width: auto;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    display: block;
  }
`;

const Image = styled.img`
  width: 61px;
  height: 61px;
  border-radius: 50%;
  position: relative;
  top: 24px;
  @media (max-width: 500px) {
    top: 28px;
  }
`;

const ContentWrapper = styled.div`
  // display: flex;
  // flex-direction: column;
  margin-top: 25px;
  margin-bottom: -5px;
`;
const MediumText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  // margin-top:10px;
  @media (min-width: 320px) and (max-width: 480px) {
    word-break: break-word;
  }
`;

const SmallText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 19px;
  color: #656565;
`;
const LinkText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #d04c8d;
  cursor: pointer;
  margin-top: -8px;
`;

const PersonalityCard = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: -20px;
    justify-content: center;
    margin-left: 74px;
  }
`;
const Location = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-align: right;
`;
const SectionWrapper2 = styled.div`
  background: #ffffff;
  border-radius: 10px;
  width: 600px;
  // height: 628px;
  padding: 20px 0 0 0;
  margin: -50px 0 0 0px;
  // overflow-y: scroll;
  //   display: absolute;
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    background: #ffffff;
    border-radius: 10px;
    width: auto;
    height: 61vh;
    /* padding: 20px 0 0 0; */
    /* margin: -50px 0 0 0; */
    overflow-y: scroll;
    margin: 30px 10px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    position: absolute;
    width: revert;
    height: 628px;
    padding: 20px 0px 0px;
    /* margin: -50px 0px 0px; */
    overflow-y: scroll;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    position: absolute;
    width: auto;
    height: 628px;
    padding: 20px 0px 0px;
    margin: -50px 0px 0px;
    overflow-y: scroll;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    background: rgb(255, 255, 255);
    border-radius: 10px;
    width: auto;
    position: absolute;
    height: 628px;
    padding: 20px 0px 0px;
    margin: -30px 0px 0px;
    overflow-y: scroll;
  }
`;
const SectionWrapperInner = styled.div`
  background: rgba(208, 76, 141, 0.1);
  border-radius: 12px;
  width: 90%;
  height: 41px;
  margin: 20px 0px 0px 40px;
`;
const CheckBoxTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
  padding: 10px 0 0 20px;
`;
const HamLayout = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    top: 30%;
    left: 83%;
    z-index: 100;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: absolute;
    top: 27%;
    left: 94%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    left: 95%;
    top: 26%;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    // display: none;
  }
  @media (min-width: 1200px) and (max-width: 2560px) {
    display: none;
  }
`;
const MainSec = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    display: none;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: none;
  }
`;

const ProfileSelection = ({
  setIndex,
  talentData,
  resetForm,
  states,
  addItem,
  setFilters,
  talentDataBackup,
  shortlistUsers,
  tabIndex,
  advancedFilter,
}) => {
  useSnapshot(jobProxy);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [statesList, setStatesList] = useState([]);
  const [isOpenham, setOpenHam] = useState(false);

  const [titlesList, setTitlesList] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [skillsList, setSkillsList] = useState([]);

  const [filtereedData, setFilteredData] = useState([]);

  const displayPage = (val) => {
    setPaginationTabIndex(val);
    // setPaginationUsers(getPaginationUsers(val))
  };
  const dispatch = useDispatch();

  const universities = useSelector((state) => state.masterData.universities);
  const cities = useSelector((state) => state.masterData.cities);
  const masterData = useSelector((state) => state.masterData);
  const scopeOfStudies = useSelector(
    (state) => state.masterData.scopeOfStudies
  );

  const [numFilters, setNumFilters] = useState([]);
  const [universityFilters, setUniversityFilters] = useState([]);
  const [skillsFilters, setSkillsFilters] = useState([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();

  // console.log("mmmmmasd",mas)
  // const [data, setData] = useState([
  //   {
  //     name: "Angie Wong",
  //     proffesion: "Accountant | M.Com (Economics)",
  //     match: 70,
  //     location: "Ampang, Selangor",
  //   },
  //   {
  //     name: "Angie Wong",
  //     proffesion: "Accountant | M.Com (Economics)",
  //     match: 65,
  //     location: "Ampang, Selangor",
  //   },
  //   {
  //     name: "Angie Wong",
  //     proffesion: "Accountant | M.Com (Economics)",
  //     match: 60,
  //     location: "Ampang, Selangor",
  //   },
  // ]);

  //to capitalize the first letter of talent_status
  const capitalizeFirstLetter = (string) => {
    return string?.charAt(0).toUpperCase() + string?.slice(1);
  };

  const getStateValue = (val) => {
    const findState = states?.find((e) => e.Id === +val);
    return findState?.Name;
  };

  const getCityValue = (val) => {
    const findCity = cities?.find((e) => e.Id == parseInt(val));
    return findCity?.Name;
  };

  const profileNavigation = (id, userDetail, isShortlist) => {
    console.log("userDetails", userDetail);
    jobProxy.userid = id;
    jobProxy.userFullDetails = userDetail;
    jobProxy.fromShortlist = isShortlist;
    console.log("thisCallingAgain", userDetail);
    localStorage.setItem("UserId", id.toString())
    localStorage.setItem("localUserData", JSON.stringify(userDetail));
    dispatch(updateUserDetails(userDetail));
    navigate(`/talentsearch/talentProfile`);
  };

  useState(() => {
    setFilteredData(...talentData);
  }, [talentData]);

  useEffect(() => {
    let dt = [];
    let titles = [];
    let institutesList = [];
    let skillsList = [];
    if (talentDataBackup?.length > 0) {
      talentDataBackup?.map((e) => {
        const val = getStateValue(e.state);
        if (!dt.includes(val)) {
          dt.push(val);
        }
        if (!titles.includes(e.title)) {
          titles.push(val);
        }
        if (institutesList.findIndex((x) => x.id == e?.insti_name) == -1) {
          universities.find((o, i) => {
            if (o.Id === parseInt(e?.insti_name)) {
              console.log("push");

              institutesList.push({
                id: o.Id,
                name: o.Name,
              });
              return true;
            }
          });
        }


        if (e?.titles != "") {
          var dataArray = e?.titles.slice(1, -1).split(',');
          dataArray?.map((x) => {
            if (!skillsList.includes(x)) skillsList.push(x);
          });
        }

      });
      setStatesList(dt);
      setTitlesList(titles);
      setInstitutes(institutesList);
      setSkillsList(skillsList);
    }
  }, [talentDataBackup]);

  const checkItem = (itm) => {
    const idx = shortlistUsers?.findIndex((e) => e.user_id === itm.user_id);
    if (idx !== -1) return true;
    else return false;
  };

  const saveData = (data, type) => {
    let percentFilters = [];
    let instiFilters = [];
    let skillsF = [];
    let dtNUm = [...numFilters];
    let dtUNi = [...universityFilters];
    let dtSkills = [...skillsFilters];
    if (type === "num") {
      const idx = dtNUm.findIndex((e) => e === data);
      if (idx > -1) {
        dtNUm.splice(idx, 1);
      } else {
        dtNUm.push(data);
      }
      setNumFilters(dtNUm);
      percentFilters = dtNUm;
    }

    if (type === "insti") {
      console.log("dtUNi", dtUNi);
      console.log("dtUNi data", data);

      const idx = dtUNi.findIndex((e) => e === data);
      if (idx > -1) {
        dtUNi.splice(idx, 1);
      } else {
        dtUNi.push(data);
      }
      setUniversityFilters(dtUNi);
      instiFilters = dtUNi;
    }

    if (type === "skills") {
      const idx = dtSkills.findIndex((e) => e === data);
      if (idx > -1) {
        dtSkills.splice(idx, 1);
      } else {
        dtSkills.push(data);
      }
      setSkillsFilters(dtSkills);
      skillsF = dtSkills;
    }
    setFilters(dtNUm, dtUNi, dtSkills);
    // let data = [...talentData]
    // setMainData(data)
    // setSelectedNUM(dt)
    // if(dt === "10% - 50%"){
    //     data = data.filter((e)=> +e.percentage>10 && +e.percentage<10)
    //     setMainData(data)
    // }
    // if(dt === "50% - 70%"){
    //     data = data.filter((e)=> +e.percentage>10 && +e.percentage<10)
    //     setMainData(data)
    // }
    // if(dt === "70% - 90%"){
    //     data = data.filter((e)=> +e.percentage>10 && +e.percentage<10)
    //     setMainData(data)
    // }
    // if(dt === "90% - 100%"){
    //     data = data.filter((e)=> +e.percentage>10 && +e.percentage<10)
    //     setMainData(data)
    // }
  };
  console.log("ttttalentData", talentData);
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768) ||
        setIsMobileView(window.innerWidth <= 820);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <>
      <style type="text/css">
        {`
  .child {
    /* ... */
    position: absolute;
    top: 14px;
    right: -4px;
  }
  
  .parent {
    position: relative;
  }`}
      </style>
      <div>
        {tabIndex != 3 ? (
          <>
            <HeaderText>{t("Graduate Talent Finder")}</HeaderText>
            <ButtonWrapper>
              {/* <Button
            cursor="pointer"
            name="Go to Shortlist"
            onClick={() => navigate("/applicants-comparision")}
          ></Button> */}
            </ButtonWrapper>
          </>
        ) : (
          ""
        )}

        {tabIndex === 3 ? (
          <>
            <HeaderText>{t("My Saved Profiles")}</HeaderText>
            <ButtonWrapper>
              <Button
                cursor="pointer"
                name={t("Go to Talent Pool")}
                onClick={() => setIndex(0)}
              ></Button>
            </ButtonWrapper>
          </>
        ) : (
          ""
        )}
        <div style={{ display: "flex", gap: "20px" }}>
          <div>
            {tabIndex === 3 ? (
              <div style={{ marginTop: "20px" }}>
                {talentData && talentData?.length > 0
                  ? talentData?.map((item, index) => (
                    <MainWrapper1>
                      <SubWrapper>
                        <div style={{ display: "flex" }}>
                          {/* <Form.Group className="talentMain">
                            <Form.Check
                              onChange={(e) => addItem(item)}
                              checked={checkItem(item)}
                            ></Form.Check>
                          </Form.Group> */}
                          <div className="parent">
                            <Image
                              src={
                                item.is_tokenized == 0 ? item.other_details.gender === "Male"
                                  ? maleUser
                                  : femaleUser
                                  : item.profile_picture
                              }
                            />
                            {item.is_tokenized == 0 ? <></> : <div className="child">
                              <BadgeIcon height="30px" width="30px"></BadgeIcon>
                            </div>}
                          </div>
                          <ContentWrapper style={{ marginLeft: "12px" }}>
                            <MediumText>
                              {
                                scopeOfStudies.find(
                                  (x) => x.Id === parseInt(item.scope)
                                )?.Name
                              }
                            </MediumText>
                            <SmallText>
                              {capitalizeFirstLetter(
                                item.other_details.talent_status
                              )}
                            </SmallText>
                            <LinkText
                              onClick={() => profileNavigation(item?.user_id, item, true)}
                            >
                              {t("View Profile")}
                            </LinkText>
                          </ContentWrapper>
                        </div>
                        <PersonalityCard>
                          <MediumText className="profilePercentage">
                            {t("Personality Match")} :{" "}
                          </MediumText>
                          <CircularProgressbar
                            value={item.percentage}
                            text={`${item.percentage}%`}
                            styles={buildStyles({
                              textColor: "black",
                              pathColor: "#EE4BA0",
                            })}
                          />
                        </PersonalityCard>
                      </SubWrapper>
                      <hr />
                      <Location>
                        {getCityValue(item.other_details.city) +
                          ", " +
                          getStateValue(item.state)}
                      </Location>
                    </MainWrapper1>
                  ))
                  : null}
              </div>
            ) : (
              ""
            )}
          </div>

          <div style={{ display: "flex", gap: "20px" }}>
            <div>
              {tabIndex !== 3 ? (
                <SectionWrapper>
                  <Title>{t("Filter Selection")}</Title>
                  <ButtonWrapper2>
                    <Button
                      style={{ marginLeft: isMobileView ? "40px" : null }}
                      cursor="pointer"
                      name={t("Filter")}
                      onClick={() => {
                        setIndex(0);
                        resetForm();
                      }}
                    ></Button>
                  </ButtonWrapper2>
                </SectionWrapper>
              ) : talentData && talentData?.length === 0 ? (
                <SectionWrapper>
                  <Title>No Result Found</Title>
                </SectionWrapper>
              ) : (
                ""
              )}

              {tabIndex !== 3 ? (
                <div style={{ marginTop: "20px" }}>
                  {talentData && talentData?.length > 0
                    ? talentData?.map((item, index) => (
                      <MainWrapper>
                        <SubWrapper>
                          <div style={{ display: "flex" }}>
                            {/* <div className=''>
                                     <CheckboxInput
                                        //margin="50px"
                                        style={{marginTop:"50px"}}
                                        // onChange={() =>
                                        //   docCheckboxChange(doc, "documents")
                                        // }
                                        label={""}
                                    // name={doc}
                                    // checked={formVal.documents?.some(
                                    //   (grd) => grd.doc_type === doc
                                    // )}
                                    />
                                </div>  */}
                            <Form.Group
                              style={{
                                position: "relative",
                                top: "38px",
                                right: "10px",
                                margin: "0 0 0 10px",
                              }}
                            >
                              {/* <Form.Check
                            onChange={(e) => addItem(item)}
                            checked={checkItem(item)}
                          ></Form.Check> */}
                            </Form.Group>
                            <div className="parent">
                              <Image
                                src={
                                  item.is_tokenized == 0 ? item.other_details.gender === "Male"
                                    ? maleUser
                                    : femaleUser
                                    : item.profile_picture
                                }
                              />
                              {item.is_tokenized == 0 ? <></> : <div className="child">
                                <BadgeIcon height="30px" width="30px"></BadgeIcon>
                              </div>}
                            </div>

                            <ContentWrapper style={{ marginLeft: "12px" }}>
                              <MediumText>
                                {" "}
                                {
                                  scopeOfStudies.find(
                                    (x) => x.Id === parseInt(item.scope)
                                  )?.Name
                                }
                              </MediumText>

                              <SmallText>
                                {capitalizeFirstLetter(
                                  item.other_details.talent_status
                                    ? item.other_details.talent_status
                                    : ""
                                )}
                              </SmallText>
                              <LinkText
                                onClick={() =>
                                  profileNavigation(
                                    item?.user_id,
                                    item,
                                    false
                                  )
                                }
                              >
                                {t("View Profile")}
                              </LinkText>
                            </ContentWrapper>
                          </div>
                          {!advancedFilter ? (
                            <PersonalityCard style={{ display: "flex" }}>
                              <MediumText style={{ marginTop: "34px" }}>
                                {t("Personality Match")} :{" "}
                              </MediumText>
                              <CircularProgressbar
                                value={item.percentage}
                                text={`${Math.trunc(item.percentage)}%`}
                                styles={buildStyles({
                                  textColor: "black",
                                  pathColor: "#EE4BA0",
                                })}
                              />
                            </PersonalityCard>
                          ) : (
                            ""
                          )}
                        </SubWrapper>
                        <hr />
                        <Location>
                          {getCityValue(item.other_details.city) +
                            ", " +
                            getStateValue(item.state)}
                        </Location>
                      </MainWrapper>
                    ))
                    : null}
                </div>
              ) : (
                " "
              )}
            </div>
            <HamLayout>
              <MdOutlineKeyboardDoubleArrowLeft
                onClick={() => setOpenHam(!isOpenham)}
                color="#d63384"
                size={40}
              />
            </HamLayout>

            {isOpenham && (
              <SectionWrapper2>
                <SectionWrapperInner>
                  <CheckBoxTitle>{t("University")}</CheckBoxTitle>
                </SectionWrapperInner>
                <Row>
                  <Form.Group style={{ margin: "20px 60px" }}>
                    {institutes.map((e) => (
                      <Form.Check
                        label={e.name}
                        onClick={() => saveData(e.id.toString(), "insti")}
                      ></Form.Check>
                    ))}
                  </Form.Group>
                </Row>
                <SectionWrapperInner>
                  <CheckBoxTitle>{t("Skills")}</CheckBoxTitle>
                </SectionWrapperInner>
                <Row>
                  <Form.Group style={{ margin: "20px 60px" }}>
                    {skillsList.map((e) => (
                      <Form.Check
                        label={e}
                        onClick={() => saveData(e, "skills")}
                      ></Form.Check>
                    ))}
                  </Form.Group>
                </Row>

                <SectionWrapperInner>
                  <CheckBoxTitle>{t("Match Percentage")}</CheckBoxTitle>
                </SectionWrapperInner>
                <Row>
                  <Form.Group style={{ margin: "20px 60px" }}>
                    <Form.Check
                      label="10% - 50%"
                      onClick={() => saveData("10% - 50%", "num")}
                    ></Form.Check>
                    <Form.Check
                      label="50% - 70%"
                      onClick={() => saveData("50% - 70%", "num")}
                    ></Form.Check>
                    <Form.Check
                      label="70% - 90%"
                      onClick={() => saveData("70% - 90%", "num")}
                    ></Form.Check>
                    <Form.Check
                      label="90% - 100%"
                      onClick={() => saveData("90% - 100%", "num")}
                    ></Form.Check>
                  </Form.Group>
                </Row>
              </SectionWrapper2>
            )}
            <MainSec>
              <SectionWrapper2>
                <SectionWrapperInner>
                  <CheckBoxTitle>{t("University")}</CheckBoxTitle>
                </SectionWrapperInner>
                <Row>
                  <Form.Group style={{ margin: "20px 60px" }}>
                    {institutes.map((e) => (
                      <Form.Check
                        label={e.name}
                        onClick={() => saveData(e.id.toString(), "insti")}
                      ></Form.Check>
                    ))}
                  </Form.Group>
                </Row>
                <SectionWrapperInner>
                  <CheckBoxTitle>{t("Skills")}</CheckBoxTitle>
                </SectionWrapperInner>
                <Row>
                  <Form.Group style={{ margin: "20px 60px" }}>
                    {skillsList.map((e) => (
                      <Form.Check
                        label={e}
                        onClick={() => saveData(e, "skills")}
                      ></Form.Check>
                    ))}
                  </Form.Group>
                </Row>

                <SectionWrapperInner>
                  <CheckBoxTitle>{t("Match Percentage")}</CheckBoxTitle>
                </SectionWrapperInner>
                <Row>
                  <Form.Group style={{ margin: "20px 60px" }}>
                    <Form.Check
                      label="10% - 50%"
                      onClick={() => saveData("10% - 50%", "num")}
                    ></Form.Check>
                    <Form.Check
                      label="50% - 70%"
                      onClick={() => saveData("50% - 70%", "num")}
                    ></Form.Check>
                    <Form.Check
                      label="70% - 90%"
                      onClick={() => saveData("70% - 90%", "num")}
                    ></Form.Check>
                    <Form.Check
                      label="90% - 100%"
                      onClick={() => saveData("90% - 100%", "num")}
                    ></Form.Check>
                  </Form.Group>
                </Row>
              </SectionWrapper2>
            </MainSec>
          </div>

          {/* {!loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        > */}
          {/* <PaginationMain>
        <Pagination
          style={{ textColor: "red" }}
          // color="secondary"
          classes={{ ul: classes.ul }}
          count={paginationCount}
          page={paginationTabIndex} //{tabIndex}
          onChange={(event, value) => displayPage(value)}
        />
      </PaginationMain> */}

          {/* </div>
      ) : (
        <></>
      )} */}

          {/* <div className="row mt-5">
        <div className="col-lg-4"></div>
        <div
          className="col-lg-4"
          style={{
            display: "flex",
            alignItems: "center",
            position: "relative",
            right: "70px",
            bottom: "30px",
            background: "white",
            borderRadius: "23px",
            width: "181px",
            height: "46px",
          }}
        >
          <img src={LeftArrow} alt="img" />
          <span style={{ margin: "0 10px", color: "#D04C8D" }}>1</span>
          <span style={{ margin: "0 10px", color: "#E5E5E5" }}>2</span>
          <span style={{ margin: "0 10px", color: "#E5E5E5" }}>3</span>
          <img src={RightArrow} alt="img" />
        </div>
        <div className="col-lg-4"></div>
      </div> */}
        </div>
      </div>
    </>
  );
};

export default ProfileSelection;
