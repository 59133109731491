import React from "react";
import { Modal } from "react-bootstrap";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../button/button";

const CustomModalWrapper = styled(Modal)`
  .modal-content {
    border-radius: 100px;
    padding: 20px;
  }
`;

const HeaderLabel = styled.h3`
font-size: 28px;
color: ${Colors.pink};
font-weigth: 500;
`;

const HeaderDiv = styled.div`
text-align: center;
padding-top: 10px;
margin-bottom: 10px;
`;

const ContentDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const ContentLabel = styled.h4`
font-size: 24px;
color: ${Colors.dark};
font-weigth: 500;
text-align: center;
`;

export default function AnnouncementModal({show, close, setShowAnnouncementModal, pop_status}){
    const displayCheck = () => {
        localStorage.setItem("pop_status", 2);
        setShowAnnouncementModal(false);
      };

    return(
        <CustomModalWrapper show={show} size="lg" backdrop="static" onHide={close} centered>
            <HeaderDiv>
                <HeaderLabel>Announcement</HeaderLabel>
            </HeaderDiv>

            <ContentDiv>
                <ContentLabel>Kindly note that effective 1st March 2024, the Sales & Service Tax (SST) will be increased from 6% to 8%.</ContentLabel>
                <Button name="I Understand" onClick= {displayCheck}/>
            </ContentDiv>
        </CustomModalWrapper>
    )
}