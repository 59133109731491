import React from 'react'
import { SectionWrapper, TableWrapper, HeaderText, FlexWrapper, TableRowWrapper, TableContent, BoldText, ImagePreview, TableText } from './common'
import Button from '../button/button'
import DeleteIcon from "../../assets/icons/svg/delete.svg";
import "./companyBranding.css"
import { useEffect, useState } from 'react';
import { useSelector } from "react-redux";
import { getCompanyBrandingImages, onCompanyBrandingImageDelete } from "../../services/apiCalls";
import UpdateCompanyBrandingImages from "../updateCompany/updateCompanyBrandingImages";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

export default function CompanyPhotos() {
    const [openModal, setOpenModal] = useState(false);
    const [images, setImages] = useState(undefined);
    const { t, } = useTranslation();
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

    const getCompanyBrandingImagesAPI = async () => {
        if (org_code) {
            const { data } = await getCompanyBrandingImages({
                code: org_code,
            });
            if (data && data.status === 1) {
                setImages(data.data)

            }
        }

    };


    useEffect(() => {
        if (org_code) {
            getCompanyBrandingImagesAPI()
        }
    }, [org_code]);

    function handleModal() {
        setOpenModal(!openModal)
    }



    useEffect(() => {
        if (!openModal) {
            getCompanyBrandingImagesAPI()
        }
    }, [openModal]);



    const deleteImage = async (id) => {
        const { data } = await onCompanyBrandingImageDelete({
            code: org_code,
            id: id,
        });
        if (data && data.status === 1) {
            getCompanyBrandingImagesAPI()
            toast.success(t(`Image Deleted successfully.`), {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(t(data.message), {
                position: toast.POSITION.TOP_RIGHT,
            });

        }

    }

    return (
        <SectionWrapper>
            <FlexWrapper>
                <HeaderText>{t("Company Photos")}</HeaderText>
                <Button onClick={handleModal} fontSize="14px" style={{ marginTop: "-12px" }} name={t("+ Add Image")} />
            </FlexWrapper>
            <hr />
            
            <TableWrapper>
                <TableRowWrapper>
                    <TableContent>
                        <BoldText>{t("Description")}</BoldText>
                    </TableContent>
                    <TableContent>
                        <BoldText>{t("Image")}</BoldText>
                    </TableContent>
                    <TableContent>
                    </TableContent>
                </TableRowWrapper>
                {images && images.length ? images.map((item, idx) =>
                    <div key={idx}>
                        <hr />
                        <TableRowWrapper>
                            <TableContent>
                                <TableText>{item.description}</TableText>
                            </TableContent>
                            <TableContent style={{ display: "flex" }}>
                                <ImagePreview background={item.image_path} />
                                <TableText>{t("Image")}</TableText>
                            </TableContent>
                            <TableContent style={{ display: "flex", alignItems: "center" }}>
                                <img src={DeleteIcon} onClick={() => deleteImage(item.id)} style={{ margin: "auto" }} alt=''/>
                            </TableContent>
                        </TableRowWrapper>
                    </div>
                ) : null}
            </TableWrapper>
            {openModal ?
                <UpdateCompanyBrandingImages
                    open={true}
                    onClose={handleModal}
                    companyDetails={{}}
                /> : <></>}
        </SectionWrapper>
    )
}
