import * as yup from "yup";
import { isValidPhoneNumber } from "react-phone-number-input";

export const initialAddIntern ={
    full_name: "",
    email: "",
    gender: "",
    id_type: "1",
    id_number: "",
    mobile: "",
    address1: "",
    address2: "",
    city: "",
    country: "",
    postcode: "",
    state: "",
    race: "",
    educational_institution: "",
    cgpa: "",
    status: "",
    academic_qualification: "",
    course: "",
    skill_level: "",
    expected_start_date: "",
    expected_end_date: "",
    date_of_hiring: "",
    occupation: "",
    vulnerable_group: "",
    internship_allowance_per_month: "",
    total_internship_allowance_in_programme: "",
}

const schema = yup
    .object({
        full_name: yup.string().required("Full name is required"),
        email: yup
            .string()
            .trim()
            .email("The entered e-mail address is incorrect")
            .required("E-mail address is required"),
        // full_name: yup
        //     .string()
        //     .trim()
        //     .required("Name is required."),
        // position: yup.string().required("Position is required."),
        // department: yup.string().required("Department is required."),
        // date_joined: yup.string().required("Date Of Joining is required."),
        gender: yup.string().required("Gender is required"),
        id_type: yup.string().required("Idendification type is required"),
        id_number: yup.string().required("Idendification number is required"),
        mobile: yup.string().required("Mobile no is required")
            //     .test("mobile", "Mobile no is not valid", (value) =>
            //     isValidPhoneNumber(value)
            // ),
            ,
        address_1: yup.string().required("Address 1 is required"),
        address_2: yup.string().required("Address 2 is required"),
        city: yup.string().required("City is required"),
        postcode: yup.string().required("Postcode is required"),
        state: yup.string().required("State is required"),
        race: yup.string().required("Race is required"),
        graduate_date: yup.string().required("Graduate date is required"),
        campus: yup.string().required("Campus is required"),
        faculty: yup.string().required("Faculty is required"),
        study_program: yup.string().required("Study program is required"),
        scope_of_study: yup.string().required("Scope of study is required"),
        cgpa: yup.string().required("Minimum CGPA is required").test(
          "req_gpa",
          "For MySIP, Minimum required CGPA should be between 1.0 and 4.0",
          (val) => val === undefined || val === null || (val <= 4 && val >= 1)
        ),
        year_of_study: yup.string().required("Year of study is required"),
        semester: yup.string().required("Semester is required"),
        status: yup.string().required("Status is required"),
        academic_qualification: yup.string().required("Academic qualification is required"),
        vulnerable_group: yup.string().required("Vulnerable group is required"),
        course: yup.string().required("Course is required"),
        skill_level: yup.string().required("Skill level is required"),
        training_status: yup.string().required("Training status is required"),
        start_date: yup.string().required("Start date is required"),
        end_date: yup.string().required("End date is required"),
        total_expenses: yup.string().required("Total expenses is required"),
        old_company_registration_no: yup.string().required("Old company registration no is required"),
        new_company_registration_no: yup.string().required("New company registration no is required"),
        total_meal_allowance: yup.string().required("Total meal allowance is required"),
        total_accomodation_allowance: yup.string().required("Total accomodation allowance is required"),
        total_travelling_allowance: yup.string().required("Total travelling allowance is required"),
        total_digital_communication_allowance: yup.string().required("Total digital communication allowance is required"),
        internship_allowance_per_month: yup.string().required("Internship allowance per month is required"),
        total_internship_allowance_programme: yup.string().required("Total internship allowance programme is required"),
        date_of_hiring: yup.string().required("Date of hiring is required"),
        internship_duration: yup.string().required("Internship duration is required"),
        completed_mynext: yup.string().required("Completed mynext talent assessment is required"),
        applied_internship_in_mynext: yup.string().required("Applied internship in mynext is required"),
        occupation: yup.string().required("Occupation is required"),
        pohigher_learning_tvet_institution: yup.string().required("PoHigher learning TVET instituation required"),
        expected_start_date: yup
        .string()
        .required("Internship Start Date is required"),
      
        expected_end_date: yup
        .string()
        .required("Internship End Date is required"),
        
        educational_institution: yup
        .string()
        .required("Educational Institution is required"),
        internship_start_date: yup.string().required("Internship Start date is required"),
        internship_end_date: yup.string().required("Internship End date is required"),
        address2: yup
        .string()
        .required("Address 2 is required"),
        total_training_expenses: yup.string().required("Total trainingexpenses is required"),
        total_meal_expenses: yup.string().required("Total meal expenses is required"),
        total_accomodation_expenses: yup.string().required("Total accomodation expanses is required"),
        total_travelling_expenses: yup.string().required("Total travelling expenses is required"),
        total_digital_communication_expenses: yup.string().required("Total digital communication expenses is required"),
        total_internship_allowance_in_programme: yup.string().required("Total internship allowance in programme is required"),
    })
    .required();



export default schema;

export const cgpa  = yup.number().typeError('CGPA must be a number')
    .min(0, 'CGPA must be at least 0')
    .max(4, 'CGPA cannot exceed 4')
    .required('CGPA is required');
