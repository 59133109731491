import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";



function BulkModal(props) {
  return (
    <>
    <style type="text/css">
    {
      `
      .modalClass > div{
        max-width: 900px
        
      }
      `
    }
        </style>
           <div>
      <Modal show={props.show} size="lg" backdrop="static" onHide={props.close} centered>
        <div style={{ padding: '9px' }}>
          <ModalHeader></ModalHeader>
          {props.content}
        </div>
      </Modal>
    </div>
    </>
  );
}

export default BulkModal;
