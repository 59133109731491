import { useRef, useState } from "react";
import "../../components/paymentPopup/paymentPopup.js";
import Button from "../../components/button/button.js";
// import { usePdf } from '@mikecousins/react-pdf';

import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";
import styled from "styled-components";

const TextModal = styled.p`
position: absolute;
  // left: 51px;
  margin-left: 10px;
  top: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
`;
function ViewLOE({ intershipName, selectedYear, isOpen, setOpen, onInternshipSubmit, title, pdf_file }) {
    
    
    const [page, setPage] = useState(1);
    const canvasRef = useRef(null);
    // const { pdfDocument, } = usePdf({
    //     file: pdf_file.file_path,
    //     page,
    //     canvasRef,
    // });
    
    const closeModal = () => {
        setOpen(false)
    };
   
    function handleDownload() {
        const pdfPath = pdf_file.file_path; // Replace with your PDF path
      
        fetch(pdfPath)
          .then((response) => response.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = pdf_file.file_name; // Set the desired filename for the downloaded PDF
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
          .catch((error) => {
            console.error('Error downloading PDF:', error);
          });
      }

    return (
        <>
            {isOpen === true ? (
                < div className="payment-modalHead">
                    <div className="payment-modalBackground"
                        onClick={() => setOpen(false)}
                    />
                    <div className="payment-modalContainer">
                        <div className="payment-titleCloseBtn">
                            <div id="payment-titleCloseBtn">
                                <p style={{ color: "#D04C8D" }}>{title}</p>
                                <p
                                    onClick={closeModal}
                                    style={{
                                        cursor: "pointer", marginTop: "-30px", width: "12px", height: "12px"
                                    }}
                                >{" X"}</p>
                            </div>
                            <p style={{ padding: "0px 15px 0px 15px", top: "72px",
                                fontFamily: "General Sans",
                                fontStyle: "normal",
                                fontWeight: 500,
                                fontSize:"18px",
                                lineHeight: "35px",
                                color: "#000000"}}> Letter Of Endorsement {selectedYear}
                            </p>
                        </div>

                        {/* <div className="detauls-content">
                            {!pdfDocument && <span>{"Loading..."}</span>}
                            <canvas ref={canvasRef} />
                            {Boolean(pdfDocument && pdfDocument.numPages) && (
                                <div className="page-controls">
                                    <button disabled={page === 1} onClick={() => setPage(page - 1)} type="button">‹</button>
                                    <span>{page} of {pdfDocument.numPages}</span>
                                    <button disabled={page === pdfDocument?.numPages}
                                        onClick={() => setPage(page + 1)} type="button">›</button></div>
                            )}
                           
                    </div> */}
                        
                        <div className="payment-bottom_tab" alt="Internship Payment Details">
                            
                            <Button
                                width="100px"
                                margin="20px 0px 30px 70px"
                                name={"Download"}
                                // disabled={!agreeTerms}
                                onClick={handleDownload}
                            />
                            <Button
                                secondary
                                color="#FFFFFF"
                                name={"Close"}
                                width="100px"
                                margin="20px 0px 30px 20px"
                                onClick={closeModal}
                            />
                        </div>
                        </div>
                    </div>
                
            ) : (
                ""
            )
            }
        </>
    );
}

export default ViewLOE;

