import { Button, Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/svg/Logo.svg";

export const Heading = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

export const RowSpaced = styled(Row)`
  margin-bottom: 65.92px;
`;
export const Card = styled.div`
  background: #ffffff;
  border-radius: 10px;
  text-align: center;
`;
export const CardPadded39 = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 39px;
`;

export const CardPadded39Darker = styled(CardPadded39)`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
`;

export const DropdownWrapper = styled(Col)`
  margin-top: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;
export const DropdownTitle = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
`;
export const SelectorWrapper = styled.div`
  width: 100%;
`;
export const RadioWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  @media (max-width: 599px) {
    margin-bottom: 20px;
  }
`;
export const CardTitle = styled.h2`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: inherit;
  color: #000000;
`;
export const CardDescription = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: inherit;
  color: #666666;
  white-space: pre-wrap;
  word-wrap: break-word;
`;
export const SameHeightCol = styled(Col)`
  height: 100% !important;
`;

export const BackToDashboard = styled(Button)`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #d04c8d;
  padding: 15px 45px;
  background: transparent;
  border: 1px solid #d04c8d;
  box-sizing: border-box;
  border-radius: 128px;
`;
export const BackToDashboardWrapper = styled.div`
  margin-top: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const ChartWrapper = styled.div`
  width: 100%;
  height: auto;
`;

export const UserSubHeading = styled.div`
  margin-bottom: 16px;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #858585;
`;

export const UserHeading = styled.div`
  margin-bottom: 8px;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

export const CreateUserForm = styled.div`
  margin: 40px 0px;
`;

export const CancelAndExit = styled.button`
  border: 0px;
  background-color: transparent;
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: #504f8c;
  width: 100%;
  border-radius: 128px;
  outline: none;
  border: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 20px;
  margin: 15px 0;
  cursor: pointer;
  &.cancelBtn {
    border-radius: 128px;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    text-decoration: none;
  }
  :hover {
    background: #d04c8d;
    color: white;
  }
`;

export const BackLink = styled.span`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #d04c8d;
`;

export const ArrowBackWrapper = styled.a`
  display: inline-flex;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  svg {
    margin-right: 15px;
    color: #9a9a9a;
    &.active {
      color: #d04c8d;
    }
  }
`;

export const InstituteTitle = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 21px;
  line-height: 28px;

  color: #000000;
`;
export const InstituteName = styled.h1`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #d04c8d;
`;

export const SearchColumn = styled(Col)`
  margin-top: 32px;
  input {
    font-family: General Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 24px;
    ::placeholder {
      color: #bcbcbc;
    }
  }
`;

export const ButtonColumn = styled(Col)`
  margin-top: 17px;
  button {
    padding: 11px 20px;
  }
`;

export const DataTableWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 3rem;
  .custom-selector {
    cursor: pointer !important;
    div[id*="option"] {
      cursor: pointer;
      &:hover {
        background-color: #f3f0f0 !important;
      }
    }
  }
  .rdt_TableHead {
    border-radius: 34px;
    height: 83px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-bottom: 15px;
    .rdt_TableCol_Sortable,
    rdt_TableCol {
      font-family: General Sans;
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 108%;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }
  .rdt_TableBody {
    border-radius: 0px 0px 34px 34px;

    .rdt_TableRow {
      cursor: pointer;
      &:hover {
        background-color: #ffe9f4;
      }
    }
  }
`;

export const StyledDarkerCard = styled.span`
  background: #f8f8f8;
  border-radius: 54px;
`;

export const ViewDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none !important;
  }
`;

export const ViewDetails = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #504f8c;
`;

export const SideNavHeaderImage = styled.div`
  background-image: url(${Logo});
  background-repeat: no-repeat;
  width: 202px;
  height: 66px;
  margin: 66px 104px 10px 70px;
  position: relative;
`;

export const SideNavHeaderImageSubTitle = styled.span`
  color: #d04d8b;
  position: absolute;
  top: 4vh;
  right: 1.5rem;
`;

export const StudentModalHeader = styled.div`
  text-align: left !important;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  /* identical to box height */

  text-align: center;

  color: #d04c8d;
`;

export const StudentModalChartWrapper = styled.div`
  padding: 0px 90px;
`;

export const UserModal = styled.div`
  .modal-wrapper {
    height: 0px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 100px;
    &.rotated {
      margin-bottom: 170px;
    }
    &.fixed {
      margin-bottom: 0px;
      height: auto;
    }
    .modal-image {
      position: absolute;
      top: -80px;
      &.rotated {
        transform: rotate(-81.99deg);
        top: -49px !important;
      }
      &.fixed {
        position: relative;
        top: 0px !important;
      }
    }
  }
  .modal-title {
    font-family: General Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 43px;
    text-align: center;
    color: #202020;
  }
  .modal-description {
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 26px;
    line-height: 35px;
    text-align: center;
    color: #202020;
  }
  .modal-buttons {
    margin-top: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      width: 45%;
    }
  }
`;

export const UserManagementModalContainer = styled(Container)`
  @media (min-width: 480px) {
    width: 80vw;
  }

  @media (min-width: 768px) {
    width: 100vw;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    .model-lg{
      max-width:1000px;
    }
    width: 587px;
    padding: 25px 70px;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: 587px;
    padding: 25px 70px;
  }
`;

export const MainTitle = styled.span`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
`;

export const StyledDarkerCardCustom = styled(StyledDarkerCard)`
  align-items: center;
  background-color: #f1f3f4;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  max-height: none;
  min-height: 48px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #525252;
  color: #525252;
  margin: 0px 20px 20px 0px;

  svg {
    margin-right: 10px;
  }
`;

export const SubTitle = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;

export const SectionTitle = styled.span`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #d04c8d;
`;

export const SectionTitleEdit = styled.span`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;

export const JustifyBetweenRow = styled(Col)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const SectionTextDescription = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
  margin-top: 20px;
  margin-bottom: 50px;
`;

export const OverviewDetailWrapper = styled.div`
  display: inline-flex;
  flex-direction: column;
  margin-right: 100px;

  & :nth-child(1) {
    font-family: General Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #525252;
    padding-bottom: 5px;
  }
  & :nth-child(2) {
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
  }
  a {
    color: #1b9dc6;
  }
`;

export const SectionTitleSecondary = styled.span`
  font-family: General Sans;
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-left: 10px;
  color: #a5a5a5;
`;

export const AddAnotherUser = styled(Link)`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;

export const AddUser = styled(Link)`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #d04c8d;
  text-decoration: none !important;
`;

export const AddUserWrapper = styled.div`
  margin-bottom: 2px;
`;

export const locationTitle = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #696969;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    height: auto;
    width: 100px;

    @media (min-width: 768px) {
      width: 5vw;
    }
  }
  .arrowOne {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) 0s infinite running
      move_eye;
    position: absolute;
  }
  .arrowTwo {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -0.5s infinite running move_eye;
    position: absolute;
  }
  .arrowThree {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -1s infinite running move_eye;
    position: absolute;
  }
  .main {
    position: absolute;
  }

  @-webkit-keyframes move_eye {
    from {
      transform: translate(-50%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }

  @keyframes move_eye {
    from {
      transform: translate(-40%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(30%, 0%);
      opacity: 1;
    }
  }
`;
export const LoaderBackdrop = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: lightgray;
  opacity: 0.5;
  height: 100%;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  width: 220px;
  padding: 1.5rem;
  .changeImage {
    font-family: General Sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #d04c8d;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;

export const Image = styled.img`
  width: 175px;
  height: 175px;
  margin-bottom: 11px;
`;

export const ImageResponsive = styled.img`
  object-fit: cover;
  width: 100%;
  height: auto;
`;

export const ImageWrapperResponsive = styled.div`
  .changeImage {
    font-family: General Sans;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #d04c8d;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
`;



export const HeadSettings = styled.div`
  margin-bottom: 16px;
`;

export const HeadingText = styled.div`
  .headText {
    font-size: 24px;
    line-height: 32px;
    color: #d04c8d;
    font-family: General Sans;
    font-weight: 600;
  }
`;

export const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 0.4px solid #202020;
  .changeText {
    font-family: General Sans;
    font-weight: 500;
    font-size: 14px;
    color: #d04c8d;
    cursor: pointer;
  }
`;

export const SubText = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #202020;
`;

export const SubHeadingText = styled(SubText)`
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
export const ReadOnly = styled.span`
  width: 100%;
  padding: 11px 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .value {
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #202020;
    margin-right: 100px;
  }
  .edit {
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-decoration: none;
    color: #d04c8d;
    cursor: pointer;
  }
`;

export const Label = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 16px;
  color: #979797;
  margin-bottom: 0.625rem;
`;

export const LabelHeading = styled(SubHeadingText)`
  margin-top: 10px;
  font-size: 16px;
  color: #000000;
  margin-bottom: 12px;
`;

export const ContentText = styled(SubText)`
  font-size: 14px;
  line-height: 17px;
  color: #878787;
`;
export const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;
export const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: "#202020";
  margin-bottom: 10px;
`;

export const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  // bottom: 30px;
  @media (max-width: 599px) {
    position: relative;
    top: 30px;
  }
`;