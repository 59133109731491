import { createAsyncThunk } from "@reduxjs/toolkit";
import { API_ROUTES } from "../constants/apiRoutes";
import { setMessage } from "../reducers/messageReducer";
import { forceChangePassword as forceChangePasswordAPI } from "../services/apiCalls";
import { getError } from "../services/CommonApi";

import { toast } from "react-toastify";
import i18n from "i18next";


export const forceChangePassword = createAsyncThunk(
    "company/api/force-change-password/",
    async ({ uid, token, newPassword, confirmNewPassword, email }, thunkAPI) => {
        try {
            const data = await forceChangePasswordAPI(
                uid,
                token,
                newPassword,
                confirmNewPassword,
                email
            );
            if (data && data.status == 200) {

                return true;
            } else {
                throw new Error("Change password failed.");
            }
        } catch (error) {
            const message = getError(error);
            thunkAPI.dispatch(setMessage(message));
            return thunkAPI.rejectWithValue();
        }
    }
);