import React from "react";
import styled from "styled-components";
import Button from "../button/button";
import { useNavigate } from "react-router-dom";
import "./compare.css";
import { useTranslation } from "react-i18next";
const Modal = styled.div`
  width: 75.5vw;
  // height: 100vh;
  top: 640px;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  // margin-top:80vh;
`;

const Conatiner = styled.div`
  width: 100%;
  //  max-width:900px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d04c8d;
  box-shadow: 2px 3px 2px 3px #d04c8d;
  padding: 4px 24px;
  //  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.18);
`;
const MediumText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #202020;
  text-align: center;
  margin: auto 0;
`;
export default function CompareModal({userShortlisted ,length, data,bulkData }) {
  const navigate = useNavigate();
  let obj ={};
  const goToCompare = () => {
    let idList = [];
    let shortlistId = [];
    let statusList =[];
    for (let i = 0; i < data.length; i++) {
      let dt = {};
      let statusDt ={};
     
      console.log(data[i],"data[i",data,bulkData)
      let findUser = bulkData?.find((e)=>e?.id===data[i])
      obj[findUser?.user_id]=findUser?.match
      dt["user_id"] = findUser?.user_id;
      statusDt["status"] = findUser?.status;
      statusList.push(statusDt);
      idList.push(dt);
     shortlistId.push(data[i]);
      
     console.log(idList,"statusList")
    }
    navigate("/talentsearch/applicants-comparision", {
      state: {shortlistId: shortlistId, shortlist: idList, selected:obj, selectedStatus:statusList, tabIndex: 1,type:"trackapplications" },
    });
  };
  const { t, i18n } = useTranslation();
  return (
    <Modal>
      <Conatiner>
        <MediumText>
          {t("You have selected")}
          <span className="compare">{length} </span>
          {t("candidates for comparison")}{` (Maximum 3)`}
        </MediumText>
        <Button name={t("Compare")} onClick={() => goToCompare()} />
      </Conatiner>
    </Modal>
  );
}
