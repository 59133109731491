import * as yup from "yup";
import { isPossiblePhoneNumber } from "react-phone-number-input";

export const newInternStaticObj = {
  heading: "Add a New Internship",
  editHeading: "Edit Internship",
  description:
    "Create a new Internship posting by filling in all the details. Posting will be reviewed by Talent Corp before being made public for students to apply.",
  formTitle: "Internship Title*",
  formDescription: "Internship Description*",
  internTypes: {
    sip: "National Structured Internship Programme (MySIP)",
    "General Internship": "General Internship",
  },
  formInternType: "Internship Type*",
  internStartDate: "Internship Start Date*",
  internEndDate: "Internship End Date*",
  internPositions: "No. of positions*",
  uploadTitle: "Upload More Documents",
  uploadFileText: "Choose File",
  uploadNotes:
    "e.g., Further job descriptions, company profile, etc. The job viewer can download this",
  academicTitle: "Academic Requirements",
  applicantDocumentTitle: "Applicant Documents",
  scopeOfStudy: "Scope of Study*",
  gpaText: "Minimum Required CGPA*",
  degreeText: "Minimum Education Level Required*",
  gradeText: "Grade Level(s)  you can select multiple",
  degrees: [
    "SKM 1",
    "SKM 2",
    "SKM 3",
    "DKM",
    "DLKM",
    "Diploma",
    "Bachelor's Degree",
    "Master's Degree",
  ],
  grades: ["Year 1", "Year 2", "Year 3", "Year 4", "Others"],
  requiredDocs: [
    "All",
    "Resume",
    "Cover Letter",
    "Transcript",
    "Writing Sample",
  ],
  collectionTypes: [
    "Receive applications when submitted",
    // "Receive appplications in a single package on a given date",
  ],
  addSkillText: "Please provide three (3) most important technical skills an intern should possess",
  threeSkills: "Please provide three (3) most important soft skills an intern should possess",
  hoursTitle: "Hours & Allowance",
  workingHours: "Working Hours/Day*",
  padiText: "Paid/ Unpaid*",
  min_compensation: "Minimum Compensation (in RM)", 
  max_compensation: "Maximum Compensation (in RM)",
  location: "Location(s)",
  country: "Country*",
  city: "City*",
  state: "State*",
  anotherLocation: "+ Add another location",
  advertiseMentDate: "Advertisement dates",
  advertiseStartDate: "Advertisement Start Date*",
  advertiseExpDate: "Advertisement Expiration Date*",
  contactTitle: "Contact Person Information",
  contactPerson: "Select Contact Information*",
  fullName: "Full Name",
  email: "E-mail Address",
  mobileNumber: "Mobile Number",
  displayContact: "Display contact information to students",
};

export const internFields = {
  internship_module_id: "",
  title: "",
  description: "",
  intern_type: "General Internship",
  exp_start_date: "",
  exp_end_date: "",
  no_positions: "1",
  req_gpa: "",
  min_degree: "",
  working_hours: "",
  max_compensation: "",
  min_compensation: "",
  country: "",
  state: "",
  city: "",
  grade: "",
  file_paths: [],
  skills: [],
  soft_skills:[],
  scopes: [],
  documents: [],
  payment_status: "",
  locations: [],
  position_start_date: "",
  position_end_date: "",
  contact_name: "",
  contact_email: "",
  contact_person: "",
  internship_type: "", 
  contact_mob_code: "",
  contact_mob: "",
  visibility_status: 0,
  collection_type: "",
  collection_radio_type: "Receive applications when submitted",
  collection_date: "",
  re_email_address: "",
  optional_documents: "",
  application_instruction: "",
  external_application_link: "",
  monthly_allowance_1: "",
  monthly_allowance_2: ""
};

export const schema = yup.object().shape({
  title: yup.string().required("Internship title is required"),
  description: yup.string().required("Internship description is required"),
  intern_type: yup.string().required(),

  exp_start_date: yup.string().required("Internship start date is required"),
  exp_end_date: yup.string().test('is-valid-duration', function(value, context) {
    const { exp_start_date } = context.parent;

    // If either date is missing, let the required validation handle it
    if (!value || !exp_start_date) {
      return true;
    }
    
    const startDate = new Date(exp_start_date);
    const endDate = new Date(value);
    
    // Calculate difference in milliseconds
    const differenceInTime = endDate.getTime() - startDate.getTime();
    
    // Convert difference to weeks (assuming 7 days per week)
    const differenceInWeeks = differenceInTime / (1000 * 3600 * 24 * 7);

    console.log("Difference in weeks:", differenceInWeeks);

    // Check if duration is less than 10 weeks
    if (differenceInWeeks < 10) {
      console.log("Duration is less than 10 weeks");
      // Return the error message if duration is less than 10 weeks
      return this.createError({
        path: context.path,
        message: 'Internship duration must be at least 10 weeks',
      });
    }
    
    // Validation passes if duration is at least 10 weeks
    return true;
  }).required("Internship end date is required"),
  no_positions: yup
    .string()
    .required("No. of positions available is required")
    .test(
      "no_positions",
      "Minimum position should be greater than 0",
      (val) => val === undefined || val === null || val > 0
    )
    .test(
      "no_positions",
      "No. of positions cannot exceed 10000",
      (val) => val < 10000
    ),
  req_gpa: yup.string().required("Minimum CGPA is required"),
  min_degree: yup.string().required("Minimum education level is required"),
  working_hours: yup.string().required("Working Hours is required"),
  max_compensation: yup
    .string()
    .required("Maximum allowance is required")
    .max(7, "Maximum Commission should not be more than 7 digits")
    .when("min_compensation", (min_compensation, schema) => {
      return schema.when("payment_status", {
        is: "paid",
        then: yup
          .string()
          .required()

          .test({
            test: (max_compensation) =>
              Number(max_compensation) > Number(min_compensation),
            message: "Value should be greater than minimum allowance",
          }),
      });
    })
    .test({
      test: (max_compensation) => {
          const maxCompensationNumber = Number(max_compensation);
          return !(maxCompensationNumber < 500);
      },
      message: "Maximum Compensation should be at least 500",
  }),
    // .when("payment_status", {
    //   is: "paid",
    //   then: yup.string().required("Maximum allowance is required"),
    // }),
  contact_person: yup.string().required("Contact is required."),

  // min_compensation: yup
  //   .string()
  //   .max(7, "Minimum Commission should not be more than 7 digits")
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree === "Bachelor's Degree" ||
  //         min_degree === "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Bachelor's Degree and above is RM600",
  //         (val) => val >= 600
  //       ),
  //   })
  //   .when(["intern_type", "min_degree"], {
  //     is: (intern_type, min_degree) =>
  //       intern_type === "sip" &&
  //       (min_degree !== "Bachelor's Degree" ||
  //         min_degree !== "Master's Degree"),
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "For MySIP, the minimum compensation for Diploma and below is RM500",
  //         (val) => val >= 500
  //       ),
  //   })
  //   .when("payment_status", {
  //     is: "paid",
  //     then: yup
  //       .string()
  //       .required("Minimum compensation is required")
  //       .test(
  //         "min_compensation",
  //         "Minimum Compensation cannot be 0",
  //         (val) => val >= 1
  //       ),
  //   }),
  //  min_compensation: yup
  //   .string()
  //   .required('Minimum compensation is required')
  //   .when('intern_type', {
  //     is: 'Advertise',
  //     then: yup.string().test('is-greater-than-500', 'Min compensation should be more than 500', (value) => {
  //       return Number(value) > 500;
  //     }),
  //   })
  //   .test({
  //     name: "min_compensation",
  //     message: "Min compensation should be higher than monthly allowance provided in eligibility check",
  //     test: function (value) {
  //       const { monthly_allowance_1, monthly_allowance_2 } = this.options.context;

  //       // Check if min_compensation is lower than monthly_allowance_1 or monthly_allowance_2
  //       if (
  //         Number(value) < monthly_allowance_1 ||
  //         Number(value) < monthly_allowance_2
  //       ) {
  //         return false;
  //       }

  //       return true;
  //     },
  //   })
  //  ,
  min_compensation: yup
  .string()
  .required('Minimum compensation is required')
  .when('min_degree', {
    is: (minDegree) => ['SKM 1', 'SKM 2', 'SKM 3', 'DKM', 'Diploma'].includes(minDegree),
    then: yup.string().test('is-greater-than-500', 'Min compensation should be more than 500', (value) => {
      return Number(value) >= 500;
    }),
    otherwise: yup.string().test('is-greater-than-600', 'Min compensation should be more than 600', (value) => {
      return Number(value) >= 600;
    }),
  }),
  // position_start_date: yup.string().required("Post start date is required"),
  // position_end_date: yup.string().required("Post expiration date is required"),
  contact_name: yup.string().required("Contact is required"),
  contact_email: yup.string().required("E-mail is required"),
  collection_type: yup.string().required(),
  re_email_address: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup
      .string()
      .required("E-mail is required.")
      .matches(/^([\w+-._]+@[\w-.]+\.[A-Za-z0-9]{2,10},?)+$/, "Invalid Email"),
    // .email("Invalid email")
  }),
  application_instruction: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("Application instruction is required."),
  }),
  external_application_link: yup.string().when("collection_type", {
    is: "Others",
    then: yup.string().required("External application link is required."),
  }),

  collection_radio_type: yup.string().when("collection_type", {
    is:
      "Receive applications by E-mail" ||
      "Receive appplications in a single package on a given date",
    then: yup.string().required(),
  }),
  collection_date: yup.string().when("collection_radio_type", {
    is: "Receive appplications in a single package on a given date",
    then: yup.string().required("Date is Required"),
  }),
  //re_email_address: yup.string().required(),
  // contact_mob_code: "N/A",
  contact_mob: yup
    .string()
    .required("Mobile Number is required field")
    .test("contact_mob", "Mobile Number is not valid", (value) =>
      isPossiblePhoneNumber(value)
    ),
  grade: yup.string().required("Year of Study is required"),

  documents: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select atleast one required document",
      test: (arr) => arr.length > 0,
    }),

  skills: yup
    .array(
      yup.object({
        skill: yup.string(),
      })
    )
    .test({
        message: "This is a required field",
        test: (arr) => arr.length > 0,
      })
    .test({
        message: "Please select exactly 3 skills",
        test: (arr) => arr.length === 3,
      })
    // .when("intern_type", (intern_type, schema) => {
    //   return schema.test({
    //     test: (arr) => {
    //       if (intern_type === "General Internship") {
    //         return true;
    //       } else if (intern_type === "sip" && arr.length === 0) {
    //         return false;
    //       } else {
    //         return true;
    //       }
    //     },
    //     message: `For MySIP add at least one skill`,
    //   });
    // })
    ,
    soft_skills: yup
    .array(
      yup.object({
        doc_type: yup.string().required(),
      })
    )
    .test({
      message: "Please select 3 soft skills",
      test: (arr) => arr.length > 0,
    }),
  file_paths: yup.array(
    yup.object({
      file_path: yup.string(),
    })
  ),

  scopes: yup
    .array(
      yup.object({
        scope: yup.string().required(),
      })
    )
    .test({
      message: "Scope of study is required",
      test: (arr) => arr.length > 0,
    }),
  locations: yup
    .array(
      yup.object({
        country: yup.string().required("Country is required"),
        state: yup.string().required("State is required"),
        city: yup.string().required("City is required"),
      })
    )
    .test({
      message: "Please add any one location.",
      test: (arr) => arr.length > 0,
    }),
});
