import { yupResolver } from "@hookform/resolvers/yup";
import { TableHead } from "@material-ui/core";
import { constants } from "crypto-browserify";
import { fi } from "date-fns/locale";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
// import { Loader } from "react-bootstrap-typeahead"
// import Loader from "../../components/loader/loader";;
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../components/button/button";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import ErrorField from "../../components/errorField/errorField";
import Input from "../../components/input/input";
import Loader from "../../components/loader/loader";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { getAnnualSurveyData, getfromDetails, postAnnulaSurvey } from "../../services/apiCalls";
import { Colors } from "../../utilities/colors";
import { annualServeyScheme, annualSurveyContent, initialStatesAnnual } from "./staticContentForm";
import { useNavigate } from "react-router-dom";

export default function AnnualSurveyScreen(){


    const {
        control,
        watch,
        reset: resetForm,
        setError: setErrorCustom,
        setValue,
        handleSubmit,
        formState: { errors: errorsQuestion },
      } = useForm({
        defaultValues: { ...initialStatesAnnual },
        resolver: yupResolver(annualServeyScheme),
        mode: "onSubmit",
      });
      // const formVal = watch();

    const SectionWrapper = styled.section``;
    const FlexWrapper = styled.div`
    display: flex;
    align-items: center;
  `;
  const ButtonWrapper = styled(FlexWrapper)`
  justify-content: space-between;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;
    const HeaderText = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    display: flex;
    justify-content: center;
    margin: 0;
    color: ${Colors.dark};
  `;
  const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;

const CardWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 20px;
 
  padding: 2rem;
//   margin: 2rem 2rem 0 2rem;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  max-width: 900px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const FormFilledAnswer = styled.label`
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
display: block;
color: ${Colors.labelText};
visibility: ${(props) => (props.visible ? "hidden" : "default")};
margin-bottom: 10px;
max-width: 900px;
> span {
  font-style: italic;
  font-weight: 300;
  font-size: 14px;
}
`

  const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

  const GroupWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;



const org_code = useSelector((state) => state.company.currentlySelectedcompanyData);
const userData = useSelector((state) => state.user.userData);
const navigate = useNavigate();

console.log("usersData",userData);
const [question1Eligible, setQuestion1Eligible] = useState(true);
const [employFormErrors, setEmployFormErrors] = useState(false);
const [questionDetailsList, setQuestionsList] = useState([]);
const [loader,setLoader] = useState(true);
const [formFilledValues,setFormValues] = useState([]);
const [selectedSoftSkills, setSoftSkills] = useState([]);
const [requiredTechKnowledge, setrequiredTechKnowledge] = useState([]);
const [OtherTextValues, setOtherTextValues] = useState();
const [isFilled,setFormisFilled] = useState(false);
const [filledAnswerList,setFilledAnswerList] = useState([]);
const onErrorSubmit = (error) => {
    
  console.log("ErrorSubmit",error);
  console.log("ErrorSubmitData",formFilledValues);
  setEmployFormErrors(true);
};
const handleValueUpdate = (otherQuestion) => {
  setFormValues((prevValues) => {
    const updatedValues = [...prevValues];
    for(const key in otherQuestion){
      if(otherQuestion.hasOwnProperty(key)){
        const match = key.match(/(\d+)$/);
        const id = parseInt(match[0]);
        const value = otherQuestion[key];
        const index = prevValues.findIndex((response) => response.question === id);
        console.log("myIndex",index,id,prevValues);
        if (index !== -1) {
          updatedValues[index] = {
            ...updatedValues[index],
            input_type_response: value
          };
        } else {
          updatedValues.push({
            user: userData.id,
            organization: org_code.id,
            question: id,
            input_type_response: value,
            selected_options: []
          });
          console.log("isCallingINdex",updatedValues,id)

        }
      }
    }
 
    return updatedValues;
  })

}
const onSubmit = async (data) => {
  console.log("allFormvalues",data);
  const arrayAnswer = handleOtherText(data)
  console.log("arrAnswerBefore",arrayAnswer)

  // setFormValues(...formFilledValues,arrayAnswer)
  // console.log("arrAnswer",formFilledValues,arrayAnswer)
  // const mergedArr = formFilledValues.concat(arrayAnswer);
  submitForm(arrayAnswer);

  setEmployFormErrors(false);
  }
  const handleOtherText = (val) =>{
    let arrayAnswer = [];
    const otherQuestion = extractItemsWithIds(val);
    const updatedValues = [...formFilledValues];
    for(const key in otherQuestion){
      if(otherQuestion.hasOwnProperty(key)){
        const match = key.match(/(\d+)$/);
        const id = parseInt(match[0]);
        const value = otherQuestion[key];
        const index = formFilledValues.findIndex((response) => response.question === id);
        console.log("myIndex",index,id,formFilledValues);
        if (index !== -1) {
          updatedValues[index] = {
            ...updatedValues[index],
            input_type_response: value
          };
        } else {
          updatedValues.push({
            user: userData.id,
            organization: org_code.id,
            question: id,
            input_type_response: value,
            selected_options: []
          });
          console.log("isCallingINdex",updatedValues,id)

        }
      }
    }
   
    return updatedValues;
    }
const fetchData = async () => {
// setLoader(true);
  if(Object.keys(org_code).length > 0){
    console.log("organizationCode",org_code)
    const { data } = await getAnnualSurveyData(org_code.id);
    console.log("dataData",data)
    setQuestionsList(data);
    // setLoader(false);
  }

  
};
const fetchCompleteform = async () => {
  // setLoader(true);
    if(Object.keys(org_code).length > 0){
      console.log("organizationCode",org_code)
      const { data } = await getfromDetails(org_code.id);
      console.log("dataDataAnswer",data)
      if(data && data.status === 1){
        if(data.data.length > 0){
          setFormisFilled(true);
          setAnswers(data.data);
          setFilledAnswerList(data.data)
        }


      }
      // setQuestionsList(data);
      setLoader(false);
    }
  
    
  };

const submitForm = async (formData) =>{
  console.log("formmmdata",formData);
  // const formData = formFilledValues;
    const { data } = await postAnnulaSurvey(formData,org_code.id);
    if (data && data.status === 1) {
    toast.success("Annual Survey Form Submitted!", {
        position: toast.POSITION.TOP_RIGHT,
      });
      navigate("/dashboard");
      // navifate
      
    }
   
 
}

const setAnswers = (data) => {
  data.map((item,index)=>{
    switch(item.question.id){
      case 1:
        setValue("attractMoreTalent",item.selected_options[0].option);
      break;
      case 2:
        setValue("internsRequiredAnnual",item.selected_options[0].option);
        if(item.selected_options?.some(smallItem => smallItem.option.includes("Others"))){
          setValue(`otherid${item.question.id}`,item.input_type_response);
        }
      break;
      case 3:
        setValue("preferredDurationInternship",item.selected_options[0].option);
        if(item.selected_options?.some(smallItem => smallItem.option.includes("Others (Please Specify)"))){
          setValue(`otherid${item.question.id}`,item.input_type_response);
        }
      break;  
      case 4:
        setValue("percentage_intern_hired",item.selected_options[0].option);
      break;
      case 5:
        let arrayValue = item.selected_options.map(item=>item.id)
        setrequiredTechKnowledge(arrayValue);
        if(item.selected_options?.some(smallItem => smallItem.option.includes("Others (Please Specify)"))){
          setValue(`otherid${item.question.id}`,item.input_type_response);
        }
      break;
      case 6:
        setValue(`otherid${item.question.id}`,item.input_type_response);
      break;
      case 7:
        setValue(`mysip_process_is_easy`,item.selected_options[0].option);
      break;
      case 8:
        let arraytwo = item.selected_options.map(item=>item.id)
        setSoftSkills(arraytwo);
        if(item.selected_options?.some(smallItem => smallItem.option.includes("Others (Please Specify)"))){
          setValue(`otherid${item.question.id}`,item.input_type_response);
        }
      break; 
       case 9:
        setValue(`otherid${item.question.id}`,item.input_type_response);

      break;
      
    }
// if(item.question.id === 1){
//   setValue("attractMoreTalent",item.input_type_response);
// }
  });
}

const handleSingleOption = ({questionID,selectedValue,option_id}) => {
  setFormValues((prevValues) => {
    const index = prevValues.findIndex((response) => response.question === questionID);
    if(index !== -1){
      prevValues[index] = {
        ...prevValues[index],
        selected_options: [option_id]
      }
      return [...prevValues]
    } else {
      return [
        ...prevValues,
        {
          user: userData.id,
          organization: org_code.id,
          question: questionID,
          input_type_response:selectedValue,
          selected_options: [option_id]
        }
      ]
    }
  })
}
const handleMultiOption = (questionID,selectedValue,option_id) => {
  console.log("selectedSoft",option_id,questionID);
  setFormValues((prevValues) => {
    const index = prevValues.findIndex((response) => response.question === questionID);
    if(index !== -1){
      prevValues[index] = {
        ...prevValues[index],
        selected_options: option_id
      }
      return [...prevValues]
    } else {
      return [
        ...prevValues,
        {
          user: userData.id,
          organization: org_code.id,
          question: questionID,
          input_type_response:selectedValue ?? "",
          selected_options: option_id
        }
      ]
    }
  })
}
const handleOtherInputText = (questionID,selectedValue,option_id) => {
  setFormValues((prevValues) => {
    const index = prevValues.findIndex((response) => response.question === questionID);
    if(index !== -1){
      prevValues[index] = {
        ...prevValues[index],
        input_type_response: selectedValue
      }
      return [...prevValues]
    } else {
      return [
        ...prevValues,
        {
          user: userData.id,
          organization: org_code.id,
          question: questionID,
          input_type_response:selectedValue,
          selected_options: [option_id]
        }
      ]
    }
  })
}
const handleInputText = (questionID,selectedValue) => {
  setFormValues((prevValues) => {
    const index = prevValues.findIndex((response) => response.question === questionID);
    if(index !== -1){
      prevValues[index] = {
        ...prevValues[index],
        input_type_response: selectedValue
      }
      return [...prevValues]
    } else {
      return [
        ...prevValues,
        {
          user: userData.id,
          organization: org_code.id,
          question: questionID,
          input_type_response:selectedValue,
          selected_options: []
        }
      ]
    }
  })

}

const extractItemsWithIds = (data) => {
  const regex = /id\d+/; // This regular expression matches strings that contain "id" followed by one or more digits.

  const itemsWithIds = Object.entries(data)
    .filter(([key, value]) => regex.test(key))
    .reduce((obj, [key, value]) => {
      obj[key] = value;
      return obj;
    }, {});

  console.log("Items with IDs:", itemsWithIds);

  return itemsWithIds;
};


const handleRadioValue = (questionID,selectedValue,option_id) => {
  setFormValues((prevValues) => {
    const index = prevValues.findIndex((response) => response.question === questionID);
    if(index !== -1){
      prevValues[index] = {
        ...prevValues[index],
        input_type_response: selectedValue
      }
      return [...prevValues]
    } else {
      return [
        ...prevValues,
        {
          user: userData.id,
          organization: org_code.id,
          question: questionID,
          input_type_response:selectedValue,
          selected_options: [option_id]
        }
      ]
    }
  })

}
const CheckboxGroup = styled.div`
margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
> input{
    width: 30px,
    height: 30px, 
    border: 2px solid #000, // Specify the border color
    borderRadius: 4px
}
`;
useEffect(() => {
  // Define an async function to fetch data


  // Call the fetchData function
  fetchData();
  fetchCompleteform();
}, [org_code]);

    return (<>
    
    <MainLayout>
    <CardWrapper>
      {
        loader ? (
          <Loader></Loader>
        ) : (
          <>
           <HeaderText>{"National Structured Internship Programme (MySIP) Survey"}</HeaderText>
                {
                  questionDetailsList.filter((question)=>{

                    return (question.id === 1)
                  }).map((question)=>{
                    return <GroupWrapper>
                   
  <>
  <FormLabel>{question.id}. {question.question}</FormLabel>
                  <Controller
                  name="attractMoreTalent"
                  control={control}
                  // defaultValue={"Yes"}
                  render={({field}) =>(
                    isFilled === true ?
                    <FormFilledAnswer className="radio-label" >{field.value}</FormFilledAnswer>
                    :
                    <RadioWrapper>
                      {question.question_options.map((items) => {
                        return  <RadioButtonGroup>
                        <input 
                        type="radio" 
                        className="radio"
                        checked={field.value === items.option}
                        onChange={()=> {
                          field.onChange(items.option)
                          handleRadioValue(question.id,items.option,items.id);
                        setQuestion1Eligible(true)
                        console.log("YEsRdaio",formFilledValues);
                      }}
                        />
                        <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
                        </RadioButtonGroup>
                      })}
                      </RadioWrapper>
                  )}
                  />
  </>
                      
                  
              </GroupWrapper>
                  })
                }
                {
                  questionDetailsList.filter((question) => question.id === 2).map((question) => {
                    return <GroupWrapper >

                    <FormLabel>{question.id}. {question.question}</FormLabel>
                    {
                      isFilled ?
filledAnswerList.filter((questionAns) => questionAns?.question?.id === question.id).map((answer)=>{
  return  <FormFilledAnswer className="radio-label" >{answer?.selected_options[0]?.option == "Others" ? answer?.input_type_response : answer?.selected_options[0]?.option}</FormFilledAnswer>

})
                      :
                question.question_options
                .map((items)=>(
                  items.option === "Others" ?
                  <Controller 
                  name="internsRequiredAnnual"
                  control={control}
                  render={({field}) =>(
                    <>
                    <RadioButtonGroup>
                  <input 
                  type="radio" 
                  className="radio"
                  checked={field.value === items.option}
                  onChange={()=> {
                    field.onChange(items.option)
                    handleSingleOption({selectedValue:items.option,option_id:items.id,questionID:question.id})
                  // setQuestion1Eligible(true)
                }}
                  />
                  <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
                  </RadioButtonGroup>
                  <Controller

                  name="otherid2"
                  control={control}
render={({field}) =>(
  <>
   <Input
                   placeholder={"Other"}
                  
                   {...field}
                
                   /></>
)}
                  >
   
                  </Controller>
                  {
    errorsQuestion.otherid2 && (
      <ErrorField
            err={errorsQuestion.otherid2.message}
          />
    )
   }
                  </>
                      
                  )}
                  />
                  :
                  <Controller 
                  name="internsRequiredAnnual"
                  control={control}
                  render={({field}) =>(
                      <RadioButtonGroup>
                  <input 
                  type="radio" 
                  className="radio"
                  checked={field.value === items.option}
                  onChange={()=> {
                    field.onChange(items.option)
                    // handleSingleOption(question.id,items.id)
                    handleSingleOption({selectedValue:items.option,option_id:items.id,questionID:question.id})

                  // setQuestion1Eligible(true)
                }}
                  />
                  <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
                  </RadioButtonGroup>
                  
                  )}
                  />
                  
                ))
                    }
                        {errorsQuestion.internsRequiredAnnual && (
                                    <ErrorField err={errorsQuestion.internsRequiredAnnual.message} />
                                  )}
                  </GroupWrapper>
                  })
                }
                
            {
              questionDetailsList.filter((question) => question.id === 3).map((question)=>{
                return      <GroupWrapper >
                <FormLabel>{question.id}. {question.question}</FormLabel>
                {
                    
                      isFilled ?
filledAnswerList.filter((questionAns) => questionAns?.question?.id === question.id).map((answer)=>{
  return  <FormFilledAnswer className="radio-label" >{answer?.selected_options[0]?.option === "Others (Please Specify)"  ? answer.input_type_response : answer?.selected_options[0]?.option}</FormFilledAnswer>

})
                      :
            question.question_options
            .map((items)=>(
              items.option === "Others (Please Specify)" ?
              <Controller 
              name="preferredDurationInternship"
              control={control}
              render={({field}) =>(
                <>
                <RadioButtonGroup>
              <input 
              type="radio" 
              className="radio"
              checked={field.value === items.option}
              onChange={()=> {
                field.onChange(items.option)
                handleSingleOption({selectedValue:items.option,option_id:items.id,questionID:question.id})
              // setQuestion1Eligible(true)
            }}
              />
              <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
              </RadioButtonGroup>
              <Controller
                  name="otherid3"
                  control={control}
                  render={({field}) => (
                    <>
                     <Input
               placeholder={"Other"}
              {...field}
               />
                    </>
                  )}
              >

              </Controller>
              {
    errorsQuestion.otherid3 && (
      <ErrorField
            err={errorsQuestion.otherid3.message}
          />
    )
   }
              </>
                  
              )}
              />
              :
              <Controller 
              name="preferredDurationInternship"
              control={control}
              render={({field}) =>(
                  <RadioButtonGroup>
              <input 
              type="radio" 
              className="radio"
              checked={field.value === items.option}
              onChange={()=> {
                field.onChange(items.option)
                handleSingleOption({selectedValue:items.option,option_id:items.id,questionID:question.id})

              // setQuestion1Eligible(true)
            }}
              />
              <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
              </RadioButtonGroup>
              )}
              />
              
            ))
                }
               
              
              </GroupWrapper>
              })
            }
{ questionDetailsList.filter((question) => question.id === 4).map((question)=>{
                return    <GroupWrapper >
                <FormLabel>{question.id}. {question.question}</FormLabel>
                {
                      isFilled ?
                      filledAnswerList.filter((questionAns) => questionAns?.question?.id === question.id).map((answer)=>{
                        return  <FormFilledAnswer className="radio-label" >{answer?.selected_options[0]?.option}</FormFilledAnswer>
                      
                      })
                                            :
            question.question_options
            .map((items)=>(
           
              <Controller 
              name="percentage_intern_hired"
              control={control}
              render={({field}) =>(
                  <RadioButtonGroup>
              <input 
              type="radio" 
              className="radio"
              checked={field.value === items.option}
              onChange={()=> {
                field.onChange(items.option)
                handleSingleOption({selectedValue:items.option,option_id:items.id,questionID:question.id})

              // setQuestion1Eligible(true)
            }}
              />
              <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
              </RadioButtonGroup>
              )}
              />
              
            ))
                }
               
              
              </GroupWrapper> 
                
                
                })}

                
<Controller 
      name="required_technical_knowledge"
      control={control}
      
      render={({field}) =>(
        questionDetailsList.filter((question)=>{

          return (question.id === 5)
        }).map((question)=>{
          return   <GroupWrapper >
          <FormLabel>{question.id}. {question.question}</FormLabel>
          {
             isFilled
             ? 
             <ul style={{ marginTop: "10px" }}>
             {filledAnswerList.filter((questionAns) => questionAns.question.id === question.id).map((answer) => (
               answer.selected_options.map((answerOption) => {
   
                 return  <li key={answerOption.id}>
                 <FormFilledAnswer>{answerOption.option === "Others (Please specify)" ? answer.input_type_response : answerOption.option }</FormFilledAnswer>
               </li>
               })
                
               ))}
           </ul>
             :
      question.question_options
      .map((items)=>(
        items.option === "Others (Please specify)" ?
        <>
        <CheckboxGroup key={items.id} style={{paddingBottom:"20px"}}>
        <Row style={{alignItems:"baseline"}}>
       <Col xs={"auto"} style={{ marginTop: "8px" }}>
         <CheckboxInput
           onChange={() => {
               if (requiredTechKnowledge.includes(items.id)) {
                   // If already selected, remove it
                   const index = requiredTechKnowledge.indexOf(items.id);
                   if (index > -1) {
                    requiredTechKnowledge.splice(index, 1);
                   }
                 } else {
                   // If not selected, add it
                   requiredTechKnowledge.push(items.id);
                 }
                 field.onChange(requiredTechKnowledge);
                 setrequiredTechKnowledge(requiredTechKnowledge);
                 handleMultiOption(question.id,"",requiredTechKnowledge);

           }}
      checked={requiredTechKnowledge.includes(items.id)}

         />
       </Col>
       <Col>
       <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
       </Col>
       </Row>
      
   </CheckboxGroup >
   <Controller
    name="otherid5"
    control={control}
    render={({field})=>(
      
      <>
         <Input
placeholder={"Others"}
{...field}
/>
      </>
    )}
   >

   </Controller>
   {
    errorsQuestion.otherid5 && (
      <ErrorField
            err={errorsQuestion.otherid5.message}
          />
    )
   }
   </>
        :
<CheckboxGroup key={items.id}>
<Row style={{alignItems:"baseline"}}>
  <Col xs={"auto"} style={{ marginTop: "8px" }}>
    <CheckboxInput
      onChange={() => {
        console.log("this runnig",requiredTechKnowledge);
          if (requiredTechKnowledge.includes(items.id)) {
              // If already selected, remove it
              const index = requiredTechKnowledge.indexOf(items.id);
              if (index > -1) {
                requiredTechKnowledge.splice(index, 1);
              }
            } else {
              // If not selected, add it
              requiredTechKnowledge.push(items.id);
            }
            field.onChange(requiredTechKnowledge);
            console.log("question_idIDID",question.id);
            setrequiredTechKnowledge(requiredTechKnowledge);
            handleMultiOption(question.id,"",requiredTechKnowledge);

          }}
 checked={requiredTechKnowledge.includes(items.id)}

    />
  </Col>
  <Col>
  <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
  </Col>
  </Row>
</CheckboxGroup>
        
      ))
          }
         
        
        </GroupWrapper> })
      )}     />
  
  {
    questionDetailsList.filter((question) => question.id === 6).map((question)=>{
                return    <GroupWrapper >
                <FormLabel>{question.id}. {question.question}</FormLabel>
                {
              <Controller 
              name="otherid6"
              control={control}
              render={({field}) =>  isFilled ?
              filledAnswerList.filter((questionAns) => questionAns?.question?.id === question.id).map((answer)=>{
                return  <FormFilledAnswer className="radio-label" >{answer.input_type_response}</FormFilledAnswer>
              
              })
                            : (
                
                <>
               <Input
               placeholder={"Something..."}
               {...field}
     
               /></>
                  
              )}
              />
                }
              </GroupWrapper>
                
                })}
  
  {
                  questionDetailsList.filter((question)=>{

                    return (question.id === 7)
                  }).map((question)=>{
                    return  <GroupWrapper>
                    <FormLabel>{question.id}. {question.question}</FormLabel>
                    <Controller
                    name="mysip_process_is_easy"
                    control={control}
                    // defaultValue={"Yes"}
                    render={({field}) =>   isFilled ?
                    filledAnswerList.filter((questionAns) => questionAns?.question?.id === question.id).map((answer)=>{
                      return  <FormFilledAnswer className="radio-label" >{answer.input_type_response}</FormFilledAnswer>
                    
                    })
                                          :(
                      
                      <RadioWrapper>
                      {question.question_options.map((items) => {
                        return  <RadioButtonGroup>
                        <input 
                        type="radio" 
                        className="radio"
                        checked={field.value === items.option}
                        onChange={()=> {
                          field.onChange(items.option)
                          handleRadioValue(question.id,items.option,items.id);
                        setQuestion1Eligible(true)
                        console.log("YEsRdaio",formFilledValues);
                      }}
                        />
                        <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
                        </RadioButtonGroup>
                      })}
                      </RadioWrapper>
                    )}
                    />
                </GroupWrapper>})}
                
                <Controller 
      name="platform_user_organisation"
      control={control}
      
      render={({field}) =>(
        questionDetailsList.filter((question)=>{

          return (question.id === 8)
        }).map((question)=>{
          return  <GroupWrapper >
          <FormLabel>{question.id}. {question.question}</FormLabel>
          {
               isFilled
               ? 
               <ul style={{ marginTop: "10px" }}>
               {filledAnswerList.filter((questionAns) => questionAns.question.id === question.id).map((answer) => (
                 answer.selected_options.map((answerOption) => {
     
                   return  <li key={answerOption.id}>
                   <FormFilledAnswer>{answerOption.option === "Others (Please specify)" ? answer.input_type_response : answerOption.option}</FormFilledAnswer>
                 </li>
                 })
                  
                 ))}
             </ul>
               :
      question.question_options
      .map((items)=>(
        items.option === "Others (Please specify)" ?
        <>
        <CheckboxGroup key={items.id} style={{paddingBottom:"20px"}}>
        <Row style={{alignItems:"baseline"}}>
       <Col xs={"auto"} style={{ marginTop: "8px" }}>
         <CheckboxInput
           onChange={() => {
               if (selectedSoftSkills.includes(items.id)) {
                   // If already selected, remove it
                   const index = selectedSoftSkills.indexOf(items.id);
                   if (index > -1) {
                     selectedSoftSkills.splice(index, 1);
                   }
                 } else {
                   // If not selected, add it
                   selectedSoftSkills.push(items.id);
                 }
                 field.onChange(selectedSoftSkills);
                 setSoftSkills(selectedSoftSkills);
                 handleMultiOption(question.id,"",selectedSoftSkills);

           }}
      checked={selectedSoftSkills.includes(items.id)}

         />
       </Col>
       <Col>
       <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
       </Col>
       </Row>
      
   </CheckboxGroup >
   <Controller
    name="otherid8"
    control={control}
    render={({field}) => (
      <>
      <Input
placeholder={"Others"}
{...field}

/>
      </>
    )}
   ></Controller>
       {
    errorsQuestion.otherid8 && (
      <ErrorField
            err={errorsQuestion.otherid8.message}
          />
    )
   }
   </>
        :
<CheckboxGroup key={items.id}>
<Row style={{alignItems:"baseline"}}>
  <Col xs={"auto"} style={{ marginTop: "8px" }}>
    <CheckboxInput
      onChange={() => {
        console.log("this runnig",selectedSoftSkills);
          if (selectedSoftSkills.includes(items.id)) {
              // If already selected, remove it
              const index = selectedSoftSkills.indexOf(items.id);
              if (index > -1) {
                selectedSoftSkills.splice(index, 1);
              }
            } else {
              // If not selected, add it
              selectedSoftSkills.push(items.id);
            }
            field.onChange(selectedSoftSkills);
            console.log("question_idIDID",question.id);
            setSoftSkills(selectedSoftSkills);
            handleMultiOption(question.id,"",selectedSoftSkills);

          }}
 checked={selectedSoftSkills.includes(items.id)}

    />
  </Col>
  <Col>
  <RadioLabel className="radio-label" style={{ margin: 0 }}>{items.option}</RadioLabel>
  </Col>
  </Row>
</CheckboxGroup>
        
      ))
          }
         
        
        </GroupWrapper> })
      )}     />

               
                  {
                  questionDetailsList.filter((question)=>{

                    return (question.id === 9)
                  }).map((question)=>{
                    return     <GroupWrapper >
                    <FormLabel>{question.id}. {question.question}</FormLabel>
                    {
              
                  <Controller 
                  name="otherid9"
                  control={control}
                  render={({field}) => isFilled ?
                  filledAnswerList.filter((questionAns) => questionAns?.question?.id === question.id).map((answer)=>{
                    return  <FormFilledAnswer className="radio-label" >{answer.input_type_response}</FormFilledAnswer>
                  
                  })
                                        :(
                    <>
                 
                   <Input
                   placeholder={"Something..."}
                   {...field}
                  //  onChange={(e) => {
                  //   // field.onChange(e);
                  //    const otherValue = e.target.value;
                  //    handleInputText(question.id,otherValue);
                  //   //  const questionCode = quest.question_code;
                  //   //  const answerCode = ans.answer_code;
                  //   //  handleInputFieldChangeTest(otherValue, questionCode, answerCode, "student_objective");
                  //  }}
                   /></>
                      
                  )}
                  />}
              
                  </GroupWrapper>
                    
                    })}
{
  isFilled === true ?
  <></>
  :
  <>
   <ButtonWrapper>
              <Button
                width="198px"
                onClick={handleSubmit(onSubmit,onErrorSubmit)}
                name={"Submit"}
              />
              {/* <Button
                width="198px"
                // onClick={() => onNavigate("/aboutme")}
                secondary={true}
                name={"Back"}
              /> */}
            </ButtonWrapper>
            {employFormErrors && (
          <ErrorField
            err={"*Please make sure all the question are filled"}
          />
        )}</>
       
}
   
          </>
        )
      }
               
    </CardWrapper>
   
    </MainLayout>
    </>);
}

//Please make sure all the mandatory fields have been inserted