import React from "react";
import styled from "styled-components";
import Input from "../input/input";
import { Form } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import "./interview.css";
import { useState } from "react";
import { useSnapshot } from "valtio";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import Button from "../button/button";
import { useEffect } from "react";
import { changApplications } from "../../services/apiCalls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";


const CardsWrapper = styled.div`
  position: absolute;
  width: 867px;
  height: max-content;
  margin-bottom: 10px;

  background: #ffffff;
  border-radius: 10px;
  padding-bottom: 35px;
  @media (max-width: 1200px) {
    width: 700px;
  }
  @media (max-width: 500px) {
    width: 350px;
    height: max-content;
    padding-bottom: 65px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 86px;
  margin: 10px;
  display: flex;
`;
const Icon = styled.div`
  position: absolute;
  top: 15px;
  right: 30px;
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 20px 50px;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  padding: 50px 0px;
  margin: -10px 0 0 -135px;
`;
const FormLabel = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #000000;

  margin: 20px 0px 0 10px;
`;
const Wrapper = styled.div`
  position: relative;
  width: 85%;
  left: 77px;
  Input {
    height: 40px;
    margin: 5px 0 0px -10px;
  }
  @media (max-width: 500px) {
    left: auto;
    margin-left: 10px;
    Input {
      height: 40px;
      width: 70%;
    }
  }
`;

const Wrapper2 = styled.div`
  position: relative;
  width: 501px;
  left: 77px;
  magin: 10px 0 0 0;
  @media (max-width: 500px) {
    position: relative;
    left: 19px;
  }
`;
const ButtonWrapper = styled.div`
  button {
    width: 100px;
    height: 39px;
    background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
    border-radius: 128px;
    border: none;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    cursor: pointer;
    color: #ffffff;
    position: relative;
    top: 14px;
  }
  @media (max-width: 500px) {
    position: relative;
    right: 9%;
    button {
      width: 100px;
      height: 39px;
      background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
      border-radius: 128px;
      border: none;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      text-align: center;
      cursor: pointer;
      color: #ffffff;
      position: relative;
      top: 14px;
    }
  }
`;
const Span = styled.span`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  left: 45%;
  button {
    background: #ffe6f2;
    border-radius: 128px;
    border: 1px solid #d04c8d;
    width: 200px;
    height: 50px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    text-align: center;
    color: #d04c8d;
  }
  button:nth-child(2) {
    background: white;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    position: relative;
  }
  @media (max-width: 500px) {
    position: relative;
    display: grid;
    left: -50%;
    padding: 2px 1px 2px 9px;
    margin: 1px 6p -1px;
    gap: 10px;
    button {
      position: relative;
      left: 8px;
    }
    button:nth-child(2) {
      left: 0px;
      bottom: 10px;
    }
    button:nth-child(3) {
      left: 0;
    }
  }
`;

const ButtonAddDocument = styled.div`
  // background: #f8f8f8;
  // border-radius: 83px;
  // width:700px;
  // height: 44px;
  // position: relative;
  // right: 10px;
  // bottom: 10px;
  button {
    width: 130px;
    height: 28px;
    background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
    border-radius: 128px;
    border: none;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #ffffff;
    position: relative;
    top: 8px;
    // left: 530px;
  }
  @media (max-width: 500px) {
    // background: #f8f8f8;
    border-radius: 83px;
    width: 33%;
    height: 44px;
    position: relative;
    left: -8%;
    // top: 180px;
    bottom: 10px;
    button {
      width: 100px;
      height: 28px;
      background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
      border-radius: 128px;
      border: none;
      font-family: "General Sans";
      font-style: normal;
      font-weight: 600;
      font-size: 10px;
      line-height: 19px;
      color: #ffffff;
      position: relative;
      top: 8px;
      right: 10px;
    }
  }
`;
const InterviewModal = ({ open, onClose, id }) => {
  useSnapshot(trackProxy);
  const [title, setTitle] = useState("");
  const [addemailsvalue, setAddemailsvalue] = useState("");
  const [addemails, setAddemails] = useState("");
  const [stdate, setSttdate] = useState(null);
  const [sttime, setSttime] = useState(null);
  const [endate, setEntdate] = useState(null);
  const [entime, setEntime] = useState(null);
  const [change, setChange] = useState(0);
  const [doc, setDoc] = useState("");
  const [dis, setDis] = useState(true);
  const [errormsg, setErrormsg] = useState("");
  const [errorMessage, setError] = useState("");
  const { t, i18n } = useTranslation();
  // const [error, setError] = useState(null);
  const [docvalue, setDocvalue] = useState("");
  const [doclist, setDoclist] = useState([
    "Resume",
    "Cover Letter",
    "Transcripts",
    "Writing Sample",
  ]);

  useEffect(async () => {
    checkdis();
  }, [title, addemails, stdate, sttime, endate, entime, doc]);

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const submit = async () => {
    let l = {
      interview_startdatetime:
        stdate.replaceAll("-", "") + sttime.replaceAll(":", ""),
      interview_enddatetime:
        endate.replaceAll("-", "") + entime.replaceAll(":", ""),
      interview_title: title,
      interview_startdate: stdate,
      interview_enddate: endate,
      interview_starttime: sttime,
      interview_endtime: entime,
      attendees_email: addemails,
      attendees_documents: doc,
    };
    if (stdate > endate) {
      toast.error(t("Start Date is greater than End Date"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError(t("Start Date is greater than End Date"));
      return;
    } else if (sttime > entime) {
      toast.error(t("Start Time is greater than end time"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setError(t("Start Date is greater than End Date"));
      return;
    }

    const { data } = await changApplications("interview", id, l);
    if (data && data["status"] === 0) {
      toast.error(data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      close();
    }
  };
  const checkdis = () => {
    if (
      title?.length > 0 &&
      addemails?.length > 0 &&
      stdate?.length > 0 &&
      sttime?.length > 0 &&
      endate?.length > 0 &&
      entime?.length > 0 &&
      doc?.length > 0 &&
      errormsg?.length === 0 &&
      errorMessage?.length === 0
    ) {
      setDis(false);
    } else {
      setDis(true);
    }
  };
 
  useEffect(async () => {
    if (stdate && sttime && endate && entime && addemails.length > 0) {
      let interview_startdatetime =
        stdate.replaceAll("-", "") + sttime.replaceAll(":", "");
      let interview_enddatetime =
        endate.replaceAll("-", "") + entime.replaceAll(":", "");

      let em = addemails.split(",");

      if (
        interview_startdatetime.length == 12 &&
        interview_enddatetime.length == 12
      ) {
        console.clear();
        for (let j = 0; j < em.length; j++) {
          for (let i = 0; i < trackProxy.applist.length; i++) {
            if (
              trackProxy.applist[i]["interview_startdatetime"] &&
              trackProxy.applist[i]["interview_enddatetime"] &&
              trackProxy.applist[i]["attendees_email"]
            ) {
              let a = Number(trackProxy.applist[i]["interview_startdatetime"]);
              let b = Number(trackProxy.applist[i]["interview_enddatetime"]);
              let c = Number(interview_startdatetime);
              let d = Number(interview_enddatetime);
              console.log(
                a,
                b,
                c,
                d,
                trackProxy.applist[i]["attendees_email"],
                em[j],
                a <= c && b >= c,
                a <= d && b >= d,
                trackProxy.applist[i]["attendees_email"].indexOf(em[j]) > -1
              );
              if (
                ((a <= c && b >= c) || (a <= d && b >= d)) &&
                trackProxy.applist[i]["attendees_email"].indexOf(em[j]) != -1
              ) {
                setErrormsg(
                  em[j] +
                    ` alredy schedule interview ${trackProxy.applist[i]["interview_startdate"]} ${trackProxy.applist[i]["interview_starttime"]} - ${trackProxy.applist[i]["interview_enddate"]} ${trackProxy.applist[i]["interview_enddatetime"]}`
                );
                i = trackProxy.applist.length + 1;
                j = em.length + 1;
              } else {
                setErrormsg("");
              }
            }
          }
        }
      }
    }
  }, [addemails, stdate, sttime, entime]);

  const startDate = (e) => {
    setSttdate(e.target.value);
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };
  const endDate = (e) => {
    setEntdate(e.target.value);

    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };
  const endTime = (e) => {
    setEntime(e.target.value);

    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };
  const startTime = (e) => {
    setSttime(e.target.value);
    let time = e.target.value;

    //replace time with e.target.value

    let lastDigits = e.target.value.slice(-2);

    let firstDigits = e.target.value.slice(0, -3);

    let finalVal = "";

    if (+firstDigits < 24 && time !== "23:59") {
      if (+lastDigits < 9) {
        lastDigits = "0" + (+lastDigits + 1);
        finalVal = firstDigits + ":" + lastDigits;
      } else if (+lastDigits === 59) {
        if (+firstDigits < 22) {
          firstDigits = +firstDigits + 1;
        } else if (+firstDigits < 23) {
          firstDigits = +firstDigits + 1;
          lastDigits = "00";
        } else lastDigits = "00";
        finalVal = firstDigits + ":" + lastDigits;
      } else {
        lastDigits = +lastDigits + 1;
        finalVal = firstDigits + ":" + lastDigits;
      }
    } else if (time === "23:59") finalVal = "24:00";
    else {
      finalVal = time;
    }

    setEntime(finalVal);
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };
  const close = () => {
    if (trackProxy.setcheck === 0) {
      trackProxy.setcheck = 1;
    } else {
      trackProxy.setcheck = 0;
    }
    onClose();
  };

  const adddoc = (d) => {
    if (doc.indexOf(d) === -1) {
      if (doc.length === 0) {
        setDoc(d);
      } else {
        setDoc(doc + "," + d);
      }
    } else {
      if (doc.indexOf(d + ",") !== -1) {
        setDoc(doc.replace(d + ",", ""));
      } else {
        if (doc.indexOf("," + d) !== -1) {
          setDoc(doc.replace("," + d, ""));
        } else {
          if (doc.indexOf(d) !== -1) {
            setDoc(doc.replace(d, ""));
          }
        }
      }
    }
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };

  const setdocarray = () => {
    if (docvalue.trim()?.length > 0) {
      let a = doclist;
      a.push(docvalue.trim());
      adddoc(docvalue.trim());
      setDocvalue("");
    }
  };

  const addemailslist = (e) => {
    if (addemails.indexOf(e) === -1) {
      if (addemails?.length === 0) {
        setAddemails(e);
      } else {
        setAddemails(addemails + "," + e);
      }
    }
    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
    setAddemailsvalue("");
  };

  const removeaddemail = (e) => {
    if (addemails.indexOf(e + ",") !== -1) {
      setAddemails(addemails.replace(e + ",", ""));
    } else {
      if (addemails.indexOf("," + e) !== -1) {
        setAddemails(addemails.replace("," + e, ""));
      } else {
        if (addemails.indexOf(e) !== -1) {
          setAddemails(addemails.replace(e, ""));
        }
      }
    }

    if (change === 0) {
      setChange(1);
    } else {
      setChange(0);
    }
  };

  if (!open) {
    return null;
  }

  var today = new Date();

  var dd = today.getDate();
  var mm = today.getMonth() + 1; //As January is 0.
  var yyyy = today.getFullYear();
  var todayDateString = yyyy + "-0" + mm + "-0" + dd;

  return (
    <div className="overlay-popup">
      <CardsWrapper className="">
        <CardWrapper id="headercard">
          <Title>{t("INTERVIEW")}</Title>
          <Content>{t("Schedule an interview")} </Content>
          <Icon onClick={close} id="headericon" style={{ cursor: "pointer" }}>
            X
          </Icon>
        </CardWrapper>

        <Wrapper>
          <FormLabel>{t("Interview Title")}</FormLabel>
          <Input
            name="Engage"
            search={true}
            placeholder={t("Type your interview heading or agenda.")}
            minWidth="auto"
            width="710px"
            style={{ margin: "10px 0 0 10px" }}
            onChange={(e) => {
              setTitle(e.target.value);
              if (change === 0) {
                setChange(1);
              } else {
                setChange(0);
              }
            }}
          />
          <Row style={{ margin: "0px" }}>
            <Col lg="6">
              <FormLabel style={{ margin: "20px 0 10px 0px" }}>
                {t("Start time")}
              </FormLabel>
              <Row style={{ marginLeft: "-15px" }}>
                <Col lg="6">
                  <Input
                    id="stdate"
                    name="stdate"
                    type="date"
                    min={todayDateString}
                    width="160px"
                    onChange={startDate}
                  ></Input>
                </Col>
                <Col lg="6">
                  <Input
                    id="sttime"
                    name="sttime"
                    type="time"
                    width="150px"
                    onChange={startTime}
                  ></Input>
                </Col>
              </Row>
            </Col>
            <Col lg="6" className="dateColumn">
              <FormLabel style={{ margin: "20px 0 10px 0px" }}>
                {t("End time")}
              </FormLabel>
              <Row style={{ margin: "0px" }}>
                <Col lg="6">
                  <Input
                    id="endate"
                    name="endate"
                    type="date"
                    min={todayDateString}
                    width="160px"
                    onChange={endDate}
                  ></Input>
                </Col>
                <Col lg="6">
                  <Input
                    id="entime"
                    name="entime"
                    type="time"
                    min="09:00"
                    width="150px"
                    onChange={endTime}
                  ></Input>
                </Col>
              </Row>
            </Col>
          </Row>
          <div>
            {errorMessage ? <span className="Error">{errorMessage}</span> : ""}
          </div>
          <FormLabel>{t("Attendees Email")}</FormLabel>
          <div>
            <div style={{ display: "flex", gap: "20px", width: "637px" }}>
              <Input
                name="Engage"
                search={true}
                placeholder={t("Type your attendees email addresses.")}
                minWidth="auto"
                width="587px"
                style={{ margin: "10px 0 0 10px" }}
                value={addemailsvalue}
                onChange={(e) => {
                  setAddemailsvalue(e.target.value);
                }}
              />
              <ButtonWrapper
                onClick={() => {
                  if (validateEmail(addemailsvalue)) {
                    if (
                      addemails
                        .toLowerCase()
                        .indexOf(addemailsvalue.toLowerCase()) === -1
                    ) {
                      addemailslist(addemailsvalue);
                      setErrormsg("");
                    } else {
                      setErrormsg(t("Email Already Exists"));
                    }
                  } else {
                    setErrormsg(t("Invalid Email"));
                  }
                }}
              >
                <button>{t("Add Email")}</button>
              </ButtonWrapper>
            </div>
            {/* <div style={{overflowY:"scroll",height:"50px"}}> */}
            {addemails.split(",").map((e) => {
              return e?.length > 0 ? (
                <span className="addemails">
                  {e} <span onClick={() => removeaddemail(e)}>x</span>
                </span>
              ) : (
                ""
              );
            })}
            {/* </div> */}
            {errormsg?.length ? <span className="Error">{errormsg}</span> : ""}
          </div>
        </Wrapper>
        <Wrapper2>
          <FormLabel>{t("Attachment In Document")}</FormLabel>
          <div style={{ margin: "10px 0 20px 20px" }}>
            {doclist.map((e, i) => {
              return (
                <div style={{ margin: "20px 0 0px 0" }}>
                  {i === 0 ? (
                    <div
                      style={{
                        position: "relative",
                        bottom: "10px",
                        right: "15px",
                        display: "flex",
                        gap: "20px",
                        width: "637px",
                      }}
                    >
                      <Input
                        minWidth="auto"
                        border="none"
                        height="45px"
                        width="557px"
                        background="#f8f8f8"
                        value={docvalue}
                        onChange={(e) => {
                          setDocvalue(e.target.value);
                        }}
                      />
                      <ButtonAddDocument>
                        <button
                          onClick={setdocarray}
                          style={{
                            fontSize: "14px",
                            width: "143px",
                            height: "39px",
                            marginRight: "40px",
                          }}
                        >
                          {t("+ Add document")}
                        </button>
                      </ButtonAddDocument>
                    </div>
                  ) : (
                    ""
                  )}
                  <div style={{ display: "flex", gap: "20px" }}>
                    <Form.Check
                      checked={doc.indexOf(e) !== -1}
                      onClick={() => {
                        adddoc(e);
                      }}
                    ></Form.Check>
                    <Span
                      onClick={() => {
                        adddoc(e);
                      }}
                    >
                      {e}
                    </Span>
                  </div>
                </div>
              );
            })}

            
          </div>

          <ButtonWrapper2>
            <Button
              onClick={submit}
              cursor="pointer"
              name={t("Save")}
              disabled={dis}
              className="interview"
            ></Button>
         
            <button onClick={close}>{t("Cancel")}</button>
          </ButtonWrapper2>
        </Wrapper2>
      </CardsWrapper>
    </div>
  );
};

export default InterviewModal;
