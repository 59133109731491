import React from 'react';
import { fuse } from './searchFunction';

const MessageParser = ({ children, actions }) => {
  const parse = (message) => {
    var lowerCaseMsg = message.toLowerCase();
    var searchResult = fuse.search(lowerCaseMsg)[0]?.item?.key;
    if (lowerCaseMsg.includes("options") || lowerCaseMsg.includes("option") || lowerCaseMsg.includes("pilihan")) {
      actions.handleShowOptions();
    }
    else if (lowerCaseMsg.includes("benefits") || lowerCaseMsg.includes("kelebihan")) {
      actions.handleListBenefits();
    }
    else if (lowerCaseMsg.includes("no views") || lowerCaseMsg.includes("no applications") || lowerCaseMsg.includes("tiada permohonan")) {
      actions.handleListNoViews();
    }
    else if (lowerCaseMsg.includes("login") || lowerCaseMsg.includes("log masuk") || lowerCaseMsg.includes("forgot my password") || lowerCaseMsg.includes("lupa kata laluan")) {
      actions.handleListLoginDetails();
    }
    else if (lowerCaseMsg.includes("video")) {
      actions.handleShowVideo();
    }
    else if (searchResult != undefined) {
      actions.handleDisplayMessage(searchResult);
    }
    else {
      actions.handleDontUnderstand(); //has widget
    };
  };

  return (
    <div>
      {React.Children.map(children, (child) => {
        return React.cloneElement(child, {
          parse: parse,
          actions,
        });
      })}
    </div>
  );
};

export default MessageParser;