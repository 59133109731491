import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJS, Tooltip, Legend, CategoryScale, LinearScale, BarElement, Title } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { updateImageBase } from '../../pages/jobPortal/imageReducer';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels
);

const defaultOptions = {
    indexAxis: 'y',
    maxBarThickness: 20,
    barThickness: 20,
    layout: {
        padding: {
            left: 0,
            right: 30,
            top: 0,
            bottom: 0,
        },
        margin: {
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
        }
    },
    plugins: {
        legend: {
            display: false
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            enabled: true,
            yAlign: 'bottom',
            xAlign: 'center',
            displayColors: false,
            padding: 10,
            callbacks: {
                label: (context) => {
                    return context.formattedValue;
                },
                title: (context) => {
                    return '';
                }
            }
        }
    },
    scales: {
        y: {
            beginsAtZero: true,
            display: true,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                padding: 20,
                font: {
                    family: 'Inter',
                    size: '14px',
                    style: 'normal',
                    weight: 400,
                    lineHeight: '16px',
                },
                color: '#615E83',
            },
        },
        x: {
            display: false,
            beginsAtZero: true,
            grid: {
                display: false,
            },
            font: {
                family: 'Inter',
                size: '14px',
                style: 'normal',
                weight: 400,
                lineHeight: '16px',
            },
            color: '#615E83',
        },
    },
    responsive: true,
    title: {
        display: false,
    },
    onAnimationComplete: () => {
        console.log("animationDOne")
    },
};

const grayBackgroundForBar = {
    beforeDatasetDraw: (chartInstance) => {
        var ctx = chartInstance.ctx,
            width = chartInstance.chartArea.right,
            left = chartInstance.chartArea.left;
        chartInstance.data.datasets.forEach((dataset, datasetIndex) => {
            const additionalText = dataset.additionalText;
            var datasetMeta = chartInstance.getDatasetMeta(datasetIndex);
            datasetMeta.data.forEach((segment, segmentIndex) => {

                var height = segment.height,
                    posX = segment.tooltipPosition().x,
                    posY = segment.tooltipPosition().y - (height / 2);

                ctx.save();
                ctx.strokeStyle = "#EEEEEE";
                ctx.fillStyle = "#EEEEEE";
                ctx.beginPath();
                ctx.roundRect(left, posY, width - left, height, 4);
                ctx.stroke();
                ctx.fill();

                const { lt, rt, lb, rb } = additionalText[segmentIndex];
                [
                    { text: lt, x: left, y: posY - 20 },
                    { text: lb, x: left, y: posY + 45 },
                    { text: rt, x: width, y: posY - 20 },
                    { text: rb, x: width, y: posY + 45 },
                ].forEach(({ text, x, y }, index) => {
                    if (text) {
                        ctx.textAlign = index > 1 ? 'end' : 'start';
                        ctx.font = "normal normal 500 11px/18px Inter";
                        ctx.fillStyle = "#787878";
                        ctx.fillText(text, x, y);
                    }
                });
            });
        });

    },
    afterDatasetDraw: (chartInstance) => {
        var ctx = chartInstance.ctx;
        chartInstance.data.datasets.forEach((dataset, datasetIndex) => {
            const backgroundColors = dataset.backgroundColor;
            var datasetMeta = chartInstance.getDatasetMeta(datasetIndex);
            datasetMeta.data.forEach((segment, segmentIndex) => {

                var posX = segment.tooltipPosition().x,
                    posY = segment.tooltipPosition().y;
                ctx.beginPath();
                ctx.arc(posX, posY, 18, 0, 2 * Math.PI);
                ctx.strokeStyle = backgroundColors[segmentIndex];
                ctx.fillStyle = "#FFFDFD";
                ctx.lineWidth = 5;
                ctx.shadowBlur = 7;
                ctx.shadowOffsetX = 0;
                ctx.shadowOffsetY = 0;
                ctx.shadowColor = backgroundColors[segmentIndex];
                ctx.stroke();
                ctx.fill();
                ctx.lineWidth = null;
                ctx.shadowBlur = null;
                ctx.shadowOffsetX = null;
                ctx.shadowOffsetY = null;
                ctx.textAlign = 'center';
                ctx.font = "normal normal 500 14px/16px Oswald";
                ctx.textBaseline = "middle";
                ctx.fillStyle = "#000000";
                ctx.fillText(`${segment.$context.raw}`, posX, posY);
            });
        });

    }
};

export default function HorizontalBarChartMultiLabel({ takeImage, maxValue, data, gradients = [], optionsOverride, fromAnalyticalDashboard }) {
    const canvasRef1 = useRef(null);
    const canvasRef2 = useRef(null);
    // const canvasImageRef = useRef(null);
    const [chartData, setChartData] = useState(data);
    const dispatch = useDispatch();
 
    useEffect(() => {
        const ctx1 = canvasRef1.current.getContext('2d');
        const ctx2 = canvasRef2.current.getContext('2d');
    
        const chart1 = new ChartJS(ctx1, {
            type: 'bar',
            data: chartData,
            options: defaultOptions,
            plugins: [grayBackgroundForBar]
        });
    
        const chart2 = new ChartJS(ctx2, {
            type: 'bar',
            data: chartData,
            options: defaultOptions,
            plugins: [grayBackgroundForBar]
        });
    
        setTimeout(() => {
            // console.log("chartBase2", chart2.toBase64Image());
            dispatch(updateImageBase({name:"personality",value:chart2.toBase64Image()}))
            // takeImage({personlity: chart2.toBase64Image()});
        }, 2000);
    
        return () => {
            chart1.destroy();
            chart2.destroy();
        };
    }, [chartData]);
    
    return (
        <>
            <canvas ref={canvasRef1} height={fromAnalyticalDashboard ? "270px" : "320px"}></canvas>
            <div style={{width:"500px"}}>
            <canvas ref={canvasRef2}  height={"300px"} style={{display:"none"}}></canvas>

            </div>
            {/* <canvas ref={canvasImageRef} height={"300px"} width={"500"} style={{display:"none"}}></canvas> */}
        </>
    );
}
