import React from 'react'
import styled from "styled-components"
import Button from "../../components/button/button";
import "./job.css"
import { t } from 'i18next';

const ButtonWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:${(props) => props.alignEnd ? "flex-end" : "space-around"};
  @media (max-width: 500px) {
   flex-direction:column-reverse;
   align-items:center;
  }
`;

const ModalContainer = styled.div`
  min-width: 480px;
  min-height: 270px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  @media (max-width: 500px) {
    min-width: 380px;
    width: 396px;
    height: auto;
    margin: 0px 20px
  }
`;

export default function JobPopup({isOpen, setOpen,proceed}) {

 

    return (
        <div>
            {isOpen === true ? (
                <div className="modalHead">
                    <ModalContainer >
                        <div className="borderBox">
                            <div className="titleCloseBtn" style={{ padding: "0px 10px" }}>
                                <p style={{ color: "#FA4AA1" }}>{t("DELETE JOB")}</p>
                                <p
                                    onClick={() => setOpen(false)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    X
                                </p>
                            </div>

                        </div>

                        <p className="jobPopup">
                            {t("Are you sure want to delete this job?")}
                        </p>
                        <ButtonWrapper>
                            <Button
                                onClick={proceed}
                                name={t("Proceed")}
                            />
                        </ButtonWrapper>

                    </ModalContainer>
                </div>
            ) : null
            }
        </div>
    )
}
