import { useEffect, useState } from "react";

import styled from "styled-components";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import ReactTooltip from "react-tooltip";
import Company from "../../assets/company.png";
import Loader from "../../components/loader/loader";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Input from "../../components/input/input";
import Logo from "../../assets/mynextLogo.png";
import { Colors } from "../../utilities/colors";
import LoginSuccessModal from "../../components/loginSuccessModal/loginSuccessModal";
import { authSignin, forceLogout, getCompanies } from "../../services/apiCalls";
import Button from "../../components/button/button";
import AlertModal from "../../components/alertModal/alertModal";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorMessages, toolTips } from "../../utilities/commonMessages";
import ErrorField from "../../components/errorField/errorField";
import { updateModal } from "../../components/modal/modalReducer";
import { updateOrgData } from "./reducer";
import Cookies from "js-cookie";
import englishFlag from "../../assets/en2.png";
import malayFlag from "../../assets/my2.png";
import { Dropdown } from "react-bootstrap";
import is from "date-fns/locale/is";

// styling starts
const LoginPageWrapper = styled.section``;
const LogoText = styled.label`
  color: ${Colors.primary};
  text-decoration: none;
  font-size: 25px;
  font-weight: 500;
  margin-left: 0px;
  cursor: pointer;
`;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1400px;
  @media (max-width: 650px) {
    display: block;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const LightText = styled(LabelText)`
  color: ${Colors.greyText};
`;
const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;
const FormWrapper = styled.form`
  margin: 10px 0px;
  max-width: 440px;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const IntroHeadSection = styled.div`
  display: flex;
  float: right;
`;
const Label = styled.label`
  font-weight: 500;
  display: block;
`;
const FormLabel = styled(Label)`
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  margin-bottom: 10px;
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  cursor: pointer;
  text-align: right;
  text-decoration: none;
`;
const HeaderTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: none;

  /* @media (max-width: 650px) {
  display: flex;
} */
`;
const Message = styled.div`
  padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const FlagLogo = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
`;

const DropdownList = styled.span`
  padding-left: 10px;
`;
// styling ends

const initialState = {
  username: "",
  password: "",
};

const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .email(errorMessages.email)
    .required(errorMessages.username),
  password: yup.string().required(errorMessages.password),
});

export default function LoginPage() {
  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialState },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const [show, setShow] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const { t, i18n } = useTranslation();
  const userDetail = watch();
  const dispatch = useDispatch();
  let navigate = useNavigate();
  const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();

  // const pathName = location && location.pathname;
  // const paths = pathName && pathName.split("/") && pathName.split("/");
  // const currentPathStr = paths && paths.length ? paths[paths.length - 1] : "";
console.log("logCons",searchParams.get("redirectTo"))

  const isForAnnualSurvey = searchParams.get("redirectTo");
  const evaluationid = searchParams.get("id");
  const changeLanguageHandler = (lang) => {
    const languageValue = lang
    i18n.changeLanguage(languageValue);
  }
  useEffect(() => {
    dispatch(updateModal(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onLogin = () => {
    setLoading(true);
    setFormErrors("");
    onFormSubmission();
  };
  

  const content = () => {
    return (
      <>
        <Grid style={{ paddingLeft: "7%", paddingRight: "7%" }}>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Message>
              {t('User is already logged in some device. Do you want to logout ?')}
            </Message>
            <Message></Message>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button style={{ width: "25%" }} name={t('Yes')} onClick={onLogout} />
            <Button
              secondary
              color="#FFFFFF"
              name={t('No')}
              style={{
                width: "25%",
                marginLeft: "10px",
              }}
              variant="outlined"
              onClick={closeModal}
            />
          </Grid>
        </Grid>
      </>
    );
  };

  const closeModal = () => {
    setShow(false);
  };
  const onLogout = async () => {
    const { data } = await forceLogout(
      userDetail.username.toLowerCase().trim()
    );

    if (data.status === 1) {
      setLoading(true);
      setShow(false);
      Cookies.remove("token");
      toast.dismiss();

      const { data, status } = await authSignin({
        username: userDetail.username.toLowerCase().trim(),
        password: userDetail.password,
      });
      setLoading(false);
      if (status === 200) {
        if (data.status === 1) {
          userLoginFlow(data);
        }
      }
    }
  };

  const onFormSubmission = async () => {
    setLoading(true);
    Cookies.remove("token");
    localStorage.removeItem("auth_token")
    // setValue("username", userDetail.username.toLowerCase());
    localStorage.removeItem("pop_status");
    const data = await authSignin({
      username: userDetail.username.toLowerCase().trim(),
      password: userDetail.password,
    });
    setLoading(false);
    if (data.status === 200) {
      if (data.data.status === 1) {
        userLoginFlow(data.data);
      } else {
        setFormErrors();
        if (data.data.message === 'User is already logged in some device') {
          setShow(true);
        } else {
          toast.error(t(data.data.message), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      }
    } else {
    }
  };
  const userLoginFlow = ({ data, message, status }) => {


    if (status === 1) {



      if (data?.additional_info?.updateLogin == false) {

        navigate("/create-password", {
          replace: true,
          state: {
            email: data.email,
            uid: data.additional_info?.user_id,
            token: data.token,
          },
        });

      }
      else {
        console.log("logigData",data)
        setFormErrors("");
        dispatch(updateOrgData(data));
        localStorage.setItem("auth_token", data.token);
        getOrganisationInfo()
        //navigate("/dashboard");
        toast.success(t("You're Login  Succesfully"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }


    } else {
      // setFormErrors(message);
      toast.error(t(message), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

  };

  const getOrganisationInfo = async () => {
    const { data } = await getCompanies();
    if (data && data.status === 1) {
      //dispatch(updateOrgData({ ...data.data }));
      if (Object.keys(data.data).length > 0) {
        localStorage.setItem("companyData",JSON.stringify(data))
        console.log("annualData",isForAnnualSurvey);
        if(isForAnnualSurvey && isForAnnualSurvey === "annual-survey"){
          navigate("/annual-survey");
          console.log("this is working annual",isForAnnualSurvey)

        }else if(isForAnnualSurvey && isForAnnualSurvey === "skill-gap-evaluation"){
          console.log("this is working skillgap",isForAnnualSurvey)
navigate("/skill-gap-evaluation/" + evaluationid);
        } 
        
        else{
          navigate("/dashboard");

        }
      } else {
        setOpenModal(true)
      }
    }
  };

  const onNavigate = (path) => {
    navigate(path);
  };
  return (
    <>
      <UserCreationLayout className="maxHeight:'95vh', overflow:'hidden'">
        <LoginPageWrapper>

          <HeaderWrapper>
          <HeaderTitle>
              <img height={'130px'} src={Logo} alt="logo my next" />
              {/* <LogoText> COMPANY </LogoText> */}
            </HeaderTitle>


            <IntroHeadSection>
              <Dropdown style={{ paddingRight: "10px" }} className="dropdown__toggle loginDropdown">
                <Dropdown.Toggle variant="">
                  <FlagLogo>
                    <img
                      src={
                        i18n.language === "my" ? malayFlag : englishFlag
                      }
                      alt="profile-logo"
                    />
                  </FlagLogo>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item href="#" onClick={() => changeLanguageHandler("en")}>
                    <img
                      width={32}
                      height={32}
                      src={englishFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"English"}</DropdownList>
                  </Dropdown.Item>
                  <Dropdown.Item href="#" onClick={() => changeLanguageHandler("my")}>
                    <img
                      width={30}
                      height={30}
                      src={malayFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"Malay"}</DropdownList>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>

              <LightText> {t('New user?')} </LightText>
              <SignUpBtn onClick={() => onNavigate("/signup")}>
                {t('Sign up')}
              </SignUpBtn>
            </IntroHeadSection>
          </HeaderWrapper>

          <IntroSection name="login" />
          <Divider width="450px" />
          <FormWrapper onSubmit={handleSubmit(onLogin)}>
            <ReactTooltip
              id="email"
              globalEventOff="focusin"
              place="top"
              effect="solid"
            >
              {t('Enter E-mail address')}
            </ReactTooltip>
            <GroupWrapper>
              <FormLabel>{t('Email address*')}</FormLabel>
              <Controller
                name="username"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    placeholder={t('Enter email address')}
                    autoFocus
                    dataId="email"
                    className="w-100"
                  />
                )}
              />
              {errors.username && <ErrorField err={t(errors.username.message)} />}
            </GroupWrapper>
            <ReactTooltip
              id="passId"
              globalEventOff="focusin"
              place="top"
              effect="solid"
            >
              Enter Password
            </ReactTooltip>
            <GroupWrapper>
              <FormLabel>{t('Password*')}</FormLabel>
              <Controller
                name="password"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    title={toolTips.password}
                    placeholder={t('Enter Password')}
                    type="password"
                    className="w-100"
                    dataId="passId"
                  />
                )}
              />
              <UserHelpText onClick={() => onNavigate("/forget-password")}>
                {t('Forgot password?')}
              </UserHelpText>
              {errors.password && <ErrorField err={t(errors.password.message)} />}
            </GroupWrapper>
            {formErrors && <ErrorField err={t(formErrors)} />}
            {loading ? (
              <Loader />
            ) : (
              <input
                type="submit"
                value={t('Sign in')}
                className="custom-submit-btn w-100"
              />
            )}
            {/* <Button name="Sign in" /> */}
          </FormWrapper>
        </LoginPageWrapper>
        {openModal && <LoginSuccessModal open={openModal} />}
        <AlertModal show={show} close={closeModal} content={content()} />
      </UserCreationLayout>
      {/* <LoginSuccessModal open={true} onClose={"onCloseModal"} /> */}
    </>
  );
}
