import React, { useState,useEffect } from 'react'
import * as FileSaver from "file-saver";
import XLSX from 'sheetjs-style';
import { useDispatch, useSelector } from "react-redux";
import StudentDownloadIcon from "../../assets/student-download.png";
import { updateGlobalLoader } from "../../reducers/universityLoadingReducer";
import ReactTooltip from 'react-tooltip';
import userManagementService from "../../services/employeeManagement.service";
import { toast } from "react-toastify";
import { t } from 'i18next';





const ExportExcel = ({ excelData, fileName, downloadList, user }) => {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = ".xlsx";
    const dispatch = useDispatch();
    const { search,  registration, is_assessment_done } = useSelector((state) => state.employeeManagementReducer);

    const companies = useSelector((state) => state.company.companyData);
    const company = useSelector((state) => state.company.currentlySelectedcompanyData);
    const [orgCode, setOrgCode] = useState(undefined);
    //getting posts

    useEffect(() => {
        getOrgCode()
    
      });
    
      useEffect(() => {
    
      }, [orgCode]);

      function getOrgCode() {
        if (Object.keys(companies).length > 0) {
          for (let i = 0; i < Object.keys(companies).length; i++) {
            if (companies[i].org_name === company.name) {
              setOrgCode(companies[i].org_code)
              return
            }
          }
    
        }
      }

    const onDownloadStudent = async () => {
        const params = {};
        if (search) {
            params.full_name = search;
        }
        if (registration) {
            params.is_registered = registration;
        }
        if (is_assessment_done) {
            params.is_assessment_done = is_assessment_done;
        }

        await userManagementService
            .downloadStudent(orgCode, params)
            .then((res) => {
                dispatch(updateGlobalLoader(true));
                const ws = XLSX.utils.json_to_sheet(res?.data?.data);
                const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: fileType });
                FileSaver.saveAs(data, fileName + fileExtension);
                dispatch(updateGlobalLoader(false));
            }).catch((error) => {
                toast.error(error);
            });
    }


    return (<>
        <ReactTooltip />
        <img data-tip={t('Download Employee List')} style={{width:"24px",height:"24px"}} onClick={(e) => { onDownloadStudent() }} src={StudentDownloadIcon} alt='icon'/>
    </>)

}
export default ExportExcel;