import React, { useState, useEffect } from 'react';
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import dummyApi from '../MySIPDirectHiring/dummyApiLoe';
import CompanyIcon from "../../assets/icons/Company.png"; 
import { useLocation,useNavigate } from "react-router-dom";
import { ongettingInternInfo, getInternshipDetails } from '../../services/apiCalls';
import { useSelector } from "react-redux";
import moment from "moment";
import { TablePagination } from "@mui/base";


import EditIcon from "../../assets/icons/svg/pencil.svg";


const InternTypeDetailsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 15px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;

const DetailsQuestion = styled.h3`
{
    font-weight: 500;
    font-size: 20px;
    color:#000;
    padding-bottom:3px;
}`

const CompanyInfo = styled.h4`
{
    padding-left:5px;
    font-size:18px;
    font-weight:500;
    color: ${Colors.greyText};
}`

const CompanyInfoPdf = styled.h4`
{
    text-decoration:underline;
    padding-left:5px;
    font-size:18px;
    font-weight:500;
    color: ${Colors.pink};
}`

const Icons = styled.img`
  margin: 5px 10px 10px 10px;
  cursor: pointer;
`;

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -10px;
  margin-bottom: 10px;
`;

const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};

const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);

const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};

export default function InternTypeDetails() {

    const [internships, setInternships] = useState([]);

    const [theArray, setTheArray] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const internshipId = searchParams.get('id');
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
    console.log(internshipId,"FROM LOE LANDING SCREEN > INTERNTYPE DETAILS")
    console.log(internships,"RETRIEVE INTERNSHIP DETAILS")

    // const RetrieveComments = async () =>{
    //   if (internshipId) {
    //     try {
    //       const { data } = await getCompletionFormComments(completionData.id);
    //       if (data) {
    //         setTheArray(data);
    //       }
    //     } catch (error) {
    //       // Handle error
    //       console.error('Error fetching comments:', error);
    //     }
    //   }
    // }


    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };


    useEffect(() => {

      const getIntershipDetails = async () =>{
        const {data} = await getInternshipDetails(org_code,internshipId);
        if(data?.status === 1){
          setInternships(data?.data);
          localStorage.setItem("internshipTitle", data?.data?.title)

          if(data?.data?.comments){
            setTheArray(data.data.comments)
          }
        }
        
      }
      getIntershipDetails();
        // const targetInternshipName = 'Marketing'; // Replace with the desired internship name
    
        // dummyApi.getInterships()
        //   .then((data) => {
        //     const targetInternship = data.internshipModule.find(internship => internship.name === targetInternshipName);
        //     setInternships(targetInternship);
        //     console.log("INTERN DETAIL:"+data)
        //   })
        //   .catch((error) => {
        //     console.error("Error fetching internships:", error);
        //   });
      }, []);
      const navigate = useNavigate();
      const onEdit = (e) => {
        e.stopPropagation();
        navigate({
          pathname: "/internships/new-intern",
          search: `?id=${internshipId}&fromInternshipPage=true`,
        });
        if(internships.intern_type === "Direct Hiring"){ 
          navigate({
            pathname: "/internship/create-internship/new-internship",
            search: `?id=${internshipId}&fromDirectHiringInternshipPage=true`,
          });
        }
      };

      function convertDatetime(datetimeString) {
        const date = new Date(Date.parse(datetimeString));
        const day = date.getUTCDate().toString().padStart(2, '0');
        const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
        const year = date.getUTCFullYear();
      
        return `${day}-${month}-${year}`;
      }

      const removeHtmlTags = (htmlString) => {
        // Create a new div element
        const doc = new DOMParser().parseFromString(htmlString, 'text/html');
        
        // Use textContent to get the text content without HTML tags
        return doc.body.textContent || "";
      };

     
  return (
    <InternTypeDetailsWrapper>

    {internships.status ==="review"?  (<>
     <Container>
      <Icons
        onClick={onEdit}
        src={EditIcon}
        alt="edit intern"
        color={Colors.greyText} 
      />
    </Container>
    
    <div className="container mb-5">
                    <Root sx={{ width: 200, maxWidth: "50%" }}>
                      <table aria-label="custom pagination table" 
                      style={{
                        background: "white",
                        borderRadius: "20px"
                      }}>
                        <thead>
                          <tr>
                            <th
                              style={{
                                background: "rgb(250, 74, 161)",
                                color: "#fff",
                                borderTopLeftRadius: "20px"
                              }}
                            >
                              {"Admin's Review"}
                            </th>
                            <th
                              style={{
                                background: "rgb(250, 74, 161)",
                                color: "#fff",
                                borderTopRightRadius: "20px"
                              }}
                            >
                              {"Created"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {theArray?.length > 0
                            ? (rowsPerPage > 0 && theArray?.length
                              ? theArray.slice(
                                page * rowsPerPage,
                                page * rowsPerPage + rowsPerPage
                              )
                              : theArray
                            ).map((row) => (
                              <tr key={row.comment}>
                                <td>{row.comment}</td>
                                <td style={{ width: 120 }} align="right">
                                  {moment(new Date(row.created_at)).format(
                                    "DD/MM/YYYY"
                                  )}
                                </td>
                              </tr>
                            ))
                            : ""}

                          {emptyRows > 0 && (
                            <tr style={{ height: 41 * emptyRows }}>
                              <td colSpan={3} />
                            </tr>
                          )}
                        </tbody>
                        {theArray?.length > 0 ? (
                          <tfoot>
                            <tr>
                              {/* <CustomTablePagination
                                rowsPerPageOptions={[
                                  5,
                                  10,
                                  25,
                                  { label: "All", value: -1 },
                                ]}
                                colSpan={3}
                                count={theArray?.length ?? "0"}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                componentsProps={{
                                  select: {
                                    "aria-label": "rows per page",
                                  },
                                  actions: {
                                    showFirstButton: true,
                                    showLastButton: true,
                                  },
                                }}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                              /> */}
                            </tr>
                          </tfoot>
                        ) : (
                          <div className="container col-lg-12" style={{margin: "10px"}}>{"No Comments"}</div>
                        )}
                      </table>
                    </Root>
                  </div>
                      </>): ""}
    <DetailsQuestion>
     Internship Title
    </DetailsQuestion>
    <CompanyInfo>{internships?.title}</CompanyInfo>

    <DetailsQuestion>
     Internship Job Description
    </DetailsQuestion>
    <CompanyInfo>{internships.description? removeHtmlTags(internships.description): ""}</CompanyInfo>

    <DetailsQuestion>Internship Start Date</DetailsQuestion>
    <CompanyInfo>{internships.exp_start_date? convertDatetime(internships.exp_start_date): ""}</CompanyInfo>

    <DetailsQuestion>Internship End Date</DetailsQuestion>
    <CompanyInfo>{internships.exp_end_date? convertDatetime(internships.exp_end_date): ""}</CompanyInfo>

    <DetailsQuestion>
     Minimum Education Level
    </DetailsQuestion>
    <CompanyInfo>{internships.min_degree}</CompanyInfo>

    <DetailsQuestion>
     Scope Of Study
    </DetailsQuestion>
    {internships?.scopes?.map((item) =>(
     <ul>
       <li><CompanyInfo>{item.scope_abbr}</CompanyInfo></li>
     </ul>
    ))}

    <DetailsQuestion>
     Preferred Minimum Year Of Study
    </DetailsQuestion>
    <CompanyInfo>{internships.grade}</CompanyInfo>

    <DetailsQuestion>
     Minimum Required CGPA
    </DetailsQuestion>
    <CompanyInfo>{internships.req_gpa}</CompanyInfo>

    <DetailsQuestion>
     Skills Required
    </DetailsQuestion>
   {internships?.skills?.map((item) =>(
     <ul>
       <li> <CompanyInfo>{item.skill}</CompanyInfo></li>
     </ul>
   ))}

    {internships?.intern_type === "Direct Hiring" ? (
     ""
    ): 
    <>
    <DetailsQuestion>
     Applicant Document(s) required
    </DetailsQuestion>
    {internships?.documents?.map((doc) =>(
     <CompanyInfo>{doc.doc_type}</CompanyInfo>
    ))}
    </>
    }

    {internships?.locations?.map((itms) =>(
     <>
     <DetailsQuestion>
     Internship Location-State
    </DetailsQuestion>
    <CompanyInfo>{itms.state}</CompanyInfo>

    <DetailsQuestion>
     Internship Location-City
    </DetailsQuestion>
    <CompanyInfo>{itms.city}</CompanyInfo>
     </>
    ))}

    <DetailsQuestion>
     Supporting Job Description Document
    </DetailsQuestion>
    {internships?.file_path?.map((path)=>(
     <CompanyInfoPdf><img src={CompanyIcon} alt="Company Icon" />{path.file_path}</CompanyInfoPdf>
    ))}
    
   </InternTypeDetailsWrapper>

       
  )
}
