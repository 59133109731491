import React from "react";

import "./chatbotRating.css";
import { t } from "i18next";
import { ReactComponent as Terrible } from "../../../../assets/svg/terrible_smiley.svg";
import { ReactComponent as Bad } from "../../../../assets/svg/bad_smiley.svg";
import { ReactComponent as Neutral } from "../../../../assets/svg/neutral_smiley.svg";
import { ReactComponent as Good } from "../../../../assets/svg/good_smiley.svg";
import { ReactComponent as Great } from "../../../../assets/svg/great_smiley.svg";

const ChatbotRating = (props) => {
  const options = [
    {
      handler: props.actionProvider.handleFeedback,
      imageSource:<Terrible className="emoji-rating"/>,
      id: 1,
    },
    { 
      handler: props.actionProvider.handleFeedback,
      imageSource:<Bad className="emoji-rating"/>,
      id: 2
    },
    { 
      handler: props.actionProvider.handleFeedback,
      imageSource:<Neutral className="emoji-rating"/>,
      id: 3
    },
    { 
      handler: props.actionProvider.handleFeedback,
      imageSource:<Good className="emoji-rating"/>,
      id: 4
    },
    { 
      handler: props.actionProvider.handleFeedback,
      imageSource:<Great className="emoji-rating"/>,
      id: 5
    },
  ];

  const buttonsMarkup = options.map((option) => (
    <button 
      key={option.id} 
      onClick={() => 
        {
          option.handler();
        }
      }
      className="rating-button">
        {option.imageSource}
    </button>
  ));

  return <div className="rating-container padding-sides">{buttonsMarkup}</div>;
};

export default ChatbotRating;