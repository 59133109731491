import { useState, useCallback, useEffect } from "react";

import styled from "styled-components";
import { MdKeyboardArrowLeft } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import SearchCompanyImage from "../../assets/company_cover_2.png";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Dropdown from "../../components/dropdown/dropdown";
import { Colors } from "../../utilities/colors";
import {
  getCompanySearchData,
  getExistingOrganistion,
} from "../../services/apiCalls";
import {
  updateValues,
  updateOrg,
  updateSuccessObj,
  updateRedirection,
} from "../registerorganisation/reducer";
import ErrorField from "../../components/errorField/errorField";
import Loader from "../../components/loader/loader";
import { errorMessages } from "../../utilities/commonMessages";
import { organisationData } from "../../utilities/constants";
import { debounce } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

// styling starts
const LoginPageWrapper = styled.section``;
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;

const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: none !important;
  margin-left: 0px;
  cursor: pointer;
  align-items: center;
  display: flex;
`;
const FormWrapper = styled.form`
  margin: 10px 0px;
  max-width: 70%;
  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
const BottomtextWrapper = styled.p`
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;
  color: #707070;
  margin: 0px;
  width: 70%;
  display: flex;
  justify-content: center;
  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;
const Register = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
  align-items: center;
  font-size: 16px;
  display: flex;
`;

export default function Searchcompany() {
  const [formErrors, setFormErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [org_in, setOrganisationName] = useState("");
  // const [searchVal, setSearchValue] = useState("");
  const [searchResluts, setSearchResults] = useState([]);
  const [searching, setSearching] = useState(false);
  const { t, } = useTranslation();
  let navigate = useNavigate();
  // const userDetail = useSelector((state) => state.organisation.value);
  const dispatch = useDispatch();

  // const inputChange = (e) => {
  //   setOrganisationName(e.target.value);
  // };

  const onSelect = (e) => {
    e.preventDefault();
    if (org_in) {
      setFormErrors("");
      setLoading(true);
      onSubmit();
    } else {
      setFormErrors(errorMessages.required);
    }
  };

  useEffect(() => {
    getDatas("");
  }, []);

  const onSubmit = async () => {
    const { data, status } = await getExistingOrganistion({ org_in: org_in });
    if (status === 200) onGettingData(data);
    setLoading(false);
  };

  const onGettingData = (data) => {
    const { message, status } = data;
    if (status === 1) {
      setFormErrors("");
      dispatch(updateValues(data.data));
      dispatch(updateOrg(true));
      dispatch(updateRedirection(false));
      dispatch(updateSuccessObj(data.data));
      onHandlingNavigation(data.data);
    } else {
      setFormErrors(message);
    }
  };

  const onHandlingNavigation = (data) => {
    const { is_completed } = data;
    if (is_completed) {
      navigate("/new-person");
    } else {
      navigate("/upload");
    }
  };

  const onRegister = () => {
    dispatch(updateRedirection(false));
    dispatch(updateValues(organisationData));
    dispatch(updateOrg(false));
    dispatch(updateSuccessObj({}));
    navigate("/register");
  };

  const onNavigate = (path) => {
    navigate(path);
  };

  const getDatas = async (name) => {
    const { data } = await getCompanySearchData({ org_search: name });
    if (data && data.status === 1) {
      const result = data.data;
      const newArr = result.map((x) => {
        return { ...x, label: x.name, value: x.name };
      });
      setSearchResults(newArr);
    }
  };

  const debounceChange = useCallback(debounce(getDatas, 1000), []);// eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (value) => {
    // setSearchValue(value);
    setSearching(true);
    debounceChange(value);
  };

  const onSelectVal = (value) => {
    setOrganisationName(value.value);
    setSearching(false);
  };

  return (
    <UserCreationLayout coverImg={SearchCompanyImage}>
      <LoginPageWrapper>
        <HeaderWrapper>
          <div>
            <SignUpBtn onClick={() => onNavigate("/")}>
              <MdKeyboardArrowLeft size={30} />
              {t('Back')}
            </SignUpBtn>
          </div>
        </HeaderWrapper>
        <IntroSection name="searchOrganisation" />
        <Divider width="70%" />
        <FormWrapper onSubmit={onSelect}>
          <Dropdown
            inputChange={onSearch}
            placeholder={t('Search Organisation Here')}
            options={searchResluts}
            showLoader={searching}
            change={onSelectVal}
            label={org_in}
            val={org_in}
          />
          {formErrors && <ErrorField err={t(formErrors)} />}
          {loading ? (
            <Loader />
          ) : (
            <input
              type="submit"
              value={t('Select Organisation')}
              className="w-100 custom-submit-btn"
            />
          )}
        </FormWrapper>
        <br />
        <BottomtextWrapper>
          {t('My Company has not been registered.')}
          <Register onClick={onRegister}>{t('Register Here')}</Register>
        </BottomtextWrapper>
      </LoginPageWrapper>
    </UserCreationLayout>
  );
}
