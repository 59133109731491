import MainLayout from "../../layouts/mainLayout/mainLayout";
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import PaginationDivider from "./paginationDividerSip";
import Button from "../../components/button/button";
import { ReactComponent as StudentRing } from "../../assets/svg/studentRing.svg";
import { ReactComponent as StudentDownload } from "../../assets/svg/studentDownload.svg"
import { ReactComponent as StudentShare } from "../../assets/svg/studentShare.svg";
import ReactTooltip from "react-tooltip";
import InternDataTable from "../../components/createInternshipModule/internDataTable";
import { useTranslation } from "react-i18next";
import UploadBulkIntern from "../../components/createInternshipModule/uploadBulkIntern"
import CloseIcon from "../../assets/close-80.png";
import InternBulk from "../../assets/template/interns-template.xlsx";
import { toast } from "react-toastify";
import SkillCard from "../../components/skillCard/skillCard";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import AddInternDetails from "../../components/createInternshipModule/uploadInternDetails";
import AddInternForm from "../../components/addInternDetails/InternDetails";
import { ConvertDate } from "../employeeManagement/utils/date-converter";
// import ReactExport from "react-export-excel";
import schema from "../../pages/MySIPDirectHiring/addInternValidation";
import { createTheme } from "@material-ui/core";
import UploadError from "../../components/createInternshipModule/uploadErrorModal";
import PaymentModal from "../../components/createInternshipModule/paymentModal";
import TermsAndConditionModal from "../../components/createInternshipModule/termsAndCondition";
//new bulk
import { cgpa } from "../../pages/MySIPDirectHiring/addInternValidation";
import { useSelector } from 'react-redux';
import userManagementService from "../../services/employeeManagement.service";
import BulkStudentModalEdit from "../../components/bulkStudentUploadEdit/bulkStudentModalEdit";
import {
  ModalHeaderText,
  ModalHeaderTextTwo,
  ModalHeaderTextThree,
  Upload,
  ComponentFile,
  Upload1,
} from "../../pages/employeeManagement/indexComponents";
import { Form, Row, Col } from "react-bootstrap";
import DataTable from "react-data-table-component";
import EventBus from "../../utilities/eventBus";
import Loader from "../../components/loader/loader";
import BulkSuccessErrorModal from "../../components/bulkStudentUpload/bulkSuccessErrorModal";
import { ReactComponent as ViewIcon } from "../../assets/svg/pencil.svg";
import { ReactComponent as DeleteIcon } from "../../assets/svg/trash.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/info.svg";
import { useDispatch } from "react-redux";
import { updateModal as updateStudentModal } from "../../reducers/studentModalReducer";
import Input from "../../components/input/input";
import ErrorField from "../../components/errorField/errorField";
import Dropdown from "../../components/dropdown/dropdown";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { formatDate } from "../../utilities/commonFunctions";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import StudentModal from "../../components/studentModal/studentModal";
import { clearCache } from "../../reducers/studentModalReducer";
import { clearErrors1 } from "../../reducers/studentModalReducer";
import { updateErrors } from "../../reducers/studentModalReducer";

import AlertModal from "../../components/singleStudentUpload/alertModal";
import { initialAddIntern } from "../../pages/MySIPDirectHiring/addInternValidation";

import { differenceInWeeks, differenceInDays } from 'date-fns';
import {
  addNewIntern,
  internBulkUpload,
  internBulkUploadValidate,
  singleInternBulkUploadValidate,
  getUniversitiesMasterList,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
  getAcademicQualificationList,
  createNewIntern,
} from "../../services/apiCalls";
import moment from "moment";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});



// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const NewInternPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 50px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const SectionWrapper = styled.section``;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : props.alignStart ? "flex-start" : props.spaceBetween ? "space-between" : "space-around")};
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const IconImage = styled.div`
  cursor: pointer;
  width: 50px;
  height: 50px;
  margin: 15px 0;
`;
const DescriptionWrapper = styled.div`
margin-top: 15px;
text-align: center;
> p{
  font-weight: 500;
  font-size: 18px;
  color: black;
}
`;
const UploadSection = styled.div`
 display: flex;
justify-content: space-around;
margin-top: 20px;
margin-bottom: 20px;
`;
const UploadFileText = styled.label`
 font-weight: 500;
 font-size: 16px;
 line-height: 20px;
 text-decoration-line: underline;
 color: ${Colors.primaryText};
 margin-top: 15px;
 margin-left: 10px;
 
`;
const UploadFileWrapper = styled.label`
 position: relative;
 cursor: pointer;
 > input {
 position: absolute;
 opacity: 0;
 //width: 100%;
}
`;

// const FileWrapper = styled.div`
//   display:flex;
//   flex-direction:row;
//   height:50px;
//   max-width:400px;
//   margin:auto;
//   margin-bottom:28px;
//   width:fit-content;
// `;

const ModalText = styled.div`
font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 35px;
  text-align: center;

  color: #202020;
  margin-top: 29px;
`;

export const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${(props) => (props.color ? props.color : Colors.labelText)};
  text-transform: ${(props) => (props.color ? "unset" : "capitalize")};
`;
export const DownloadTemplate = styled(Label)`
  color: ${Colors.inputHighlightText};
  cursor: pointer;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  // margin-top: 48px;
  margin-left: 3px;

  @media (max-width: 500px) {
    position: relative;
    top: 40%;
    left: 25%;
    padding: 4px 10px;
    margin-top: 20px;
  }
`;
const DownloadLink = styled.a`
  cursor: pointer;
`;
const DownloadLinkWrapper = styled.div`
text-align: center;
`;

const CenterButton = styled.div`
display: flex;
justify-content: center;
`;

const ModalLabel = styled.label`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 35px;
`;

const ModalLabel2 = styled.label`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 35px;
  max-width: 500px;
  text-align: center;
`;

const ModalSection = styled.div`
margin-top: 20px;
margin-bottom: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
`;

//new bulk
const TableButtonsNew = styled.div`
  display: flex;
`;
const PencilButton = styled.button`
  background-color: transparent;
  border: 0px;
`;

const TrashButton = styled.button`
  background-color: transparent;
  border: 0px;
`;
const DataTableWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 3rem;
  overflow-x: auto;
  /* Custom scrollbar styles */
  &::-webkit-scrollbar {
    width: 0px;
    background: #9494944a;
  }

  /* More scrollbar styling if needed */
  &::-webkit-scrollbar-thumb {
    background: #9494944a;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
  }

  .custom-selector {
    cursor: pointer !important;
    div[id*="option"] {
      cursor: pointer;  const [selectedDate, handleDateChange] = useState(new Date());

      &:hover {
        background-color: #f3f0f0 !important;
      }
    }
  }
  .rdt_TableHead {  const [selectedDate, handleDateChange] = useState(new Date());

    border-radius: 34px;
    height: 85px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-bottom: 15px;
    .rdt_TableCol_Sortable,
    rdt_TableCol {
      font-family: General Sans;
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }

  .rdt_TableBody {
    border-radius: 0px 0px 34px 34px;
    margin-left: 55px;
    .rdt_TableRow {
      cursor: pointer;
      &:hover {
        background-color: #ffe9f4;
      }
    }
  }
`;
const CardContainer = styled.div`
  margin-top: 2%;
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;
export const SelectorWrapper = styled.div`
  width: 100%;
`;

const TitleSection = styled.div`
margin-top: 10px;
padding: 9px;
`;
const TitleDescription = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;

const columnStylingBigger = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "200px",
  color: "#202020",
  margin: " 1.5rem 0",
  paddingLeft: "10px",
  marginLeft: '-50px'
}
const columnStyling2 = {
  // fontFamily: "General Sans",
  // fontStyle: "normal",
  // fontWeight: 500,
  fontSize: 12,
  lineHeight: "24px",
  minWidth: "150px",
  color: "#202020",
  marginLeft: "-30px",
  justifyContent: "center",
  "@media (max-width: 990px)": {
    display: "flex",
    fontSize: 16,
    justifyContent: "flex-start",
    padding: "0.5rem 0 0 0.5rem",
  },
};
export default function ViewBulkInternDetail() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [formDataSection1, setFormDataSection1] = useState({});
  const [formDataSection2, setFormDataSection2] = useState({});
  const [formDataSection3, setFormDataSection3] = useState({});
  const [internModal, setInternModal] = useState(false);
  const [openBulkModal, setOpenBulkModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null)
  const [selectExcel, setSelectExcel] = useState(null);
  const [selectExcelName, setSelectExcelName] = useState("");
  const [bulkModalSuccessError, setBulkModalSuccessError] = useState(false);
  const [uploadBulkModelSucessError, setUploadBulkModelSucessError] = useState(false);
  const [bulkModalSuccessErrorMode, setBulkModalSuccessErrorMode] = useState("success");
  const [bulkModalSuccessErrorMode2, setBulkModalSuccessErrorMode2] = useState("success");

  const [showPaymentModal, setShowPaymentModal] = useState(false);
  const [modalMode, setModalMode] = useState("");
  const { t } = useTranslation();
  const [internData, setInternData] = useState(false);

  // bulk upload
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const companies = useSelector((state) => state.company.companyData);
  const [orgCode, setOrgCode] = useState(undefined);
  const [bulkModal, setBulkModal] = useState(false);
  const [bulkModalStudent, setbulkModalStudent] = useState(false);
  const [studentValidatedData, setStudentValidatedData] = useState({});
  const [studentList, setStudentList] = useState([]);
  const [isInvalid, setIsInvalid] = useState(false);
  const [loadingBulk, setLoadingBulk] = useState(false);
  const [bulkModalSuccessErrorLoading, setBulkModalSuccessErrorLoading] = useState(false);
  const [studentIndex, setStudentIndex] = useState(0);
  const dispatch = useDispatch();
  const [dateJoined, setDateJoined] = useState(new Date());
  const cache = useSelector((state) => state.studentModalReducer.cache);
  const errors = useSelector((state) => state.studentModalReducer.errors);
  const [isOpen, setOpen] = useState(false);

  console.log(errors, "Errors form cache")

  //states to handle bulk user delete
  const [deleteName, setDeleteName] = useState("");
  const [showDelete, setShowDelete] = useState(false);
  const [deleteUUID, setDeleteUUID] = useState("");
  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);
  const [selectedOccupation,setSelectedOccupation] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

    const [univeristies, setUniversityList] = useState([]);
    const [selectedEducationalInstitution, setSelectedEducationalInstitution] = useState([]);
    const [status, setStatus] = useState([]);
    const [selectedStatus, setSelectedStatus] = useState([]);
    const [selectedAcademicQualifications, setSelectedAcademicQualifications] = useState([]);
    const [skillLevelList, setSkillLevelList] = useState([])
    const [selctedSkillLevel, setSelectedSkillLevel] = useState([]);
    const [selectedCourse,setSelectedCourse]=useState([]);
    const [selectedRace, setSelectedRace] = useState([]);
    const [selectedVulnarableGroup, setVulnarableGroup] = useState([]);

  const [academicQualificationList, setAcademicQualificationList] = useState([]);
  const location = useLocation();
  const internDataDH = localStorage.getItem("DH_Data");
  const parsedData = JSON.parse(internDataDH)
  const [DirectHiringData, setDirectHiringData] = useState(parsedData)
  const typeOriginBulk = localStorage.getItem("typeOriginBulk");
  const internship_id = localStorage.getItem("internshipModuleIdBulk")
  console.log(DirectHiringData, "FROM DH PAGE")
  console.log(internship_id, "internDetailUpload");

  console.log(typeOriginBulk, "typeOriginBulk")
  console.log(internship_id, "Internship IdBulk")
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const changeInternData = (InternData) => {
    setInternData(InternData);
  };

  const schemaBulkUpload = yup
    .object({
      file: yup.mixed().test("required", "Excel file is required", (file) => {
        if (file) return true;
        return false;
      }),
    })
    .required();
  
    // const cgpa  = yup.number().typeError('CGPA must be a number')
    // .min(0, 'CGPA must be at least 0')
    // .max(4, 'CGPA cannot exceed 4')
    // .required('CGPA is required');
    
  const {
    register: registerBulk,
    formState: { errors: errorsBulk },
    control: controlBulk,
    handleSubmit: handleSubmitBulk,
    formState: { touchedFields: touchedFieldsBulk, isValid: isValidBulk },
    getValues: getValuesBulk,
    setValue: setValueBulk,
    reset: resetBulk,
    watch: watchBulk,
  } = useForm({
    defaultValues: {
      file_path: "No file chosen",
      file_size: "",
      file: null,
    },
    resolver: yupResolver(schemaBulkUpload),
    criteriaMode: "all",
  });
  const columns = [
    {
      name: "Name",
      style: columnStylingBigger,
      selector: (row) => row.full_name,
      width: "200px",
      cell: (row, index) => {
        return (
            <>
                <Row
                    style={{ position: "relative"}}
                >
                   
                        <span>{row.full_name}</span>

                        <span style={{
                            color: "#BCBCBC",
                            fontSize: "14px",
                            fontStyle: "italic",
                            marginTop: '5px'
                        }}>{row.academic_qualification}</span>
                </Row>
                <Row>
                {/* <Col
                        style={{
                            color: "#BCBCBC",
                            fontSize: "14px",
                            fontStyle: "italic",
                        }}
                    >
                        <span>{row.academic_qualification}</span>
                    </Col> */}
                </Row>


                <br />
            </>
        );
    },
    },
    {
      name: "IC Number",
      style: columnStyling2,
      selector: (row) => row.id_number,
    },
    // {
    //   name: "Internship Allowance per month (RM)",
    //   selector: (row) => row.allowance,
    // },
    {
      name: "Start Date",
      selector: (row) =>
        ["-", "", null].includes(row.internship_start_date)
          ? "-"
          : ConvertDate(row.internship_start_date, "dd/mm/yyyy"),
    },
    {
      name: "End Date",
      selector: (row) =>
        ["-", "", null].includes(row.internship_end_date)
          ? "-"
          : ConvertDate(row.internship_end_date, "dd/mm/yyyy"),
    },
    {
      name: "Duration",
      selector: (row) => {
        const startDate = row.internship_start_date;
        const endDate = row.internship_end_date;

        // Calculate the duration in weeks and days
        const durationWeeks = startDate && endDate
          ? differenceInWeeks(new Date(endDate), new Date(startDate))
          : null;

        const durationDays = startDate && endDate
          ? differenceInDays(new Date(endDate), new Date(startDate)) % 7
          : null;

        const calculateDuration = durationWeeks !== null
          ? `${durationWeeks} weeks${durationDays > 0 ? ` ${durationDays} days` : ''}`
          : null;

        return (
          <div>
            {durationWeeks !== null ? (
              <p style={{
                marginLeft: '0px',
                marginTop:'15px',
                color: 'black'
              }}>{calculateDuration}</p>
            ) : null}
          </div>
        );
      },
    },
    {
      name: "Total Training Expenses",
      selector: (row) => row.total_training_expenses,
    },
    {
      name: "Total Meal Expenses",
      selector: (row) => row.total_meal_expenses,
    },
    {
      name: "Total Accomodation Expenses",
      selector: (row) => row.total_accomodation_expenses,
    },
    {
      name: "Total Travelling Expenses",
      selector: (row) => row.total_travelling_expenses,
    },
    {
      name: "Total Digital And Communication Expenses",
      selector: (row) => row.total_digital_communication_expenses,
    },
    {
      name: "Monthly Internship Allowance",
      selector: (row) => row.internship_allowance_per_month,
    },
    {
      name: "Total Allowance in Programme",
      selector: (row) => row.total_internship_allowance_in_programme,
    },
    // {
    //   name: "mynext Registration",
    //   selector: (row) => row.is_registered,
    // },
    // {
    //   name: "MySIP Eligibility",
    //   selector: (row) => row.is_eligible,
    // },
    {
      name: "Actions",
      selector: (row) => row.status,
      sortable: false,
      center: true,
      cell: (row, index) => {
        var backgroundStyle =
          row.is_valid === 0 ? "brightness(0) invert(1)" : "";
        return (
          <TableButtonsNew>
            <PencilButton
              onClick={(value) => onChangeStudentEdit(value, row, index)}
            >
              <ViewIcon color={Colors.greyText} />
            </PencilButton>
            <TrashButton>
              <DeleteIcon
                type="button"
                onClick={(e) => {
                  onDeleteExcelStudents(row.uuid);
                }}
              />
            </TrashButton>
            {row.errors && <ReactTooltip />}
            <TrashButton
              style={{
                filter: backgroundStyle,
              }}

            >
              <InfoIcon
                style={{
                  filter: backgroundStyle,
                }}
                data-tip={toolTipContent(row.errors)}
                data-html={true}
              />
            </TrashButton>
          </TableButtonsNew>
        );
      },
    },
  ];
  const formValBulk = watchBulk();
  const uploadFile = async (event) => {
    if (event.target.files[0].name) {
      let l = event.target.files[0].name.split(".");
      if (l.length > 0) {
        l = l[l.length - 1].toLowerCase();
        if (l !== "xlsx") {
          toast.error("File format not supported");
          return;
        }
      }
    }
    const formdata = new FormData();

    let maxFileSize = 1024 * 1024 * 5; //5mb

    if (event.target.files[0].size > maxFileSize) {
      toast.error(

        "Only PDF files of size less than or equal to 5 MB can be uploaded!"
        ,
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      formdata.append(
        "obj_content",
        event.target.files[0],
        event.target.files[0].name
      );

      setSelectExcel(event.target.files[0]);
      setSelectExcelName(event.target.files[0].name);
      setValueBulk("excel_file", event.target.files[0].name);
      resetBulk({
        file_path: event.target.files[0].name,
        file_size: event.target.files[0].size,
        file: event.target.files[0],
      });
    }
  };
  const clearFile = () => {
    ref.current.value = "";
    resetBulk({ file_path: "No file chosen", file_size: "", file: {} });
  }
  const openBulkInternModal = () => {
    setOpenBulkModal(true);
  }
  const openInternModal = () => {
    setInternModal(true);
  }
  const onInternshipSubmit = () => {
    setOpen(false);
  }
  const closeModal = () => {
    setOpenBulkModal(false);
  }
  const closeModal2 = () => {
    setInternModal(false);
  }
  //new Bulk
  function onCloseuploadBulkSuccessError() {
    setUploadBulkModelSucessError(false);
    setLoading(false);
    setLoadingBulk(false);
  }
  function onCloseBulkSuccessError() {
    setBulkModalSuccessError(false);
    // setBulkModalSuccessErrorMode("error");
    setLoading(false);
    setLoadingBulk(false);
  }
  const ref = React.useRef();

  const {
    control: controlIntern,
    watch: addWatch,
    reset: resetForm,
    setError: setErrorCustom,
    setValue: setValueStudent,
    handleSubmit,
    clearErrors,
    formState: { errors: errorsAddForm },
  } = useForm({
    defaultValues: { ...initialAddIntern },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const formVal = addWatch();
  const [formData, setFormData] = useState(null);
  const handleSaveFormData = (data) => {
    console.log('Form data:', data);

  };

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }
  useEffect(() => {
    getOrgCode();
    getUniversityList();
    setSelectedCountry({ label: "Malaysia", value: 132 });
    getcountryList();
    getState(132);
    //getStatusList();
    retreivercademicQualificationList();
    //getSkillLevelList();

    setValueStudent("occupation", occupationList[0].label);
    setSelectedOccupation(occupationList[0]);
    // setBulkModalSuccessErrorMode("error");
  }, [])
  // const openSample = ()=>{
  //   setBulkModalSuccessError(true);
  // }
  const closeErrorModal = () => {
    setBulkModalSuccessError(false);
  }

  // const getStatusList = () => {
  //   const statusList = statusOption?.map((item) => {
  //     return {
  //       ...item,
  //       label: item.status_name,
  //       value: item.id,
  //       data: item
  //     };
  //   })
  //   console.log(statusList, "OPTIONS")
  //   setStatus(statusList)
  // }

  // const getSkillLevelList = () => {
  //   const skillOption = skillLevel?.map((y) => {
  //     return {
  //       ...y,
  //       label: y.skill_level,
  //       value: y.id,
  //       data: y
  //     }
  //   })
  //   setSkillLevelList(skillOption)
  // }
  const retreivercademicQualificationList = async () => {
    const { data, status } = await getAcademicQualificationList();
    if (status === 200) {
      if (data?.status === 1) {
        const academic = data?.data;
        const academicList = academic?.map((item) => {
          // Exclude "SRP/PMR", "STPM", "SPM" from the list
          if (item.Name !== "SRP/PMR" && item.Name !== "STPM" && item.Name !== "SPM") {
            return {
              ...item,
              label: item.Name,
              value: item.Id,
              data: item
            };
          }
          return null; // Exclude the item
        }).filter(item => item !== null); // Remove null items
        const filteredAcademicList = academicList.filter(item => item.Name !== "Others");
        setAcademicQualificationList(filteredAcademicList);
        if (formVal.insti_name) {
          let selAcademicQua = academicList.filter(
            (x) => x.value == formVal.academic_qualification
          );
          setSelectedAcademicQualifications(selAcademicQua);
        }
      }
    }
  };
  
  console.log(academicQualificationList, "LIST")
  const getUniversityList = async () => {
    const { data, status } = await getUniversitiesMasterList();

    if (status === 200) {
      if (data?.status === 1) {
        const uniList = data?.data;
        const institution = uniList?.map((item) => {
          return {
            ...item,
            label: item.Name,
            value: item.Id,
            data: item
          };
        })
        setUniversityList(institution);
        if (formVal.insti_name) {
          let selInstitue = institution.filter(
            (x) => x.value == formVal.insti_name
          );
          setSelectedEducationalInstitution(selInstitue);
        }
      }
    }
  }
  console.log(univeristies, "GET UNI")
  const dropdownChange = (name, val) => {
    switch (name) {
      // case "country":
      //   setValueStudent(name, val.label);
      //   setSelectedCountry(val);
      //   getState(val.value);
      //   break;

      case "occupation":
        setValueStudent(name, val.label);
        setSelectedOccupation(val);
        break;
      case "state":
        { console.log("state is being called", val) }
        setValueStudent(name, val.label);
        setSelectedState(val);
        getCity(selectedCountry.value, val.value);
        break;
      case "city":
        setValueStudent(name, val.label);
        setSelectedCity(val);
        break;

      case "educational_institution":
        setValueStudent(name, val.value)
        setSelectedEducationalInstitution(val);
        
        break;

      case "status":
        setValueStudent(name, val.label);
        setSelectedStatus(val);
        break;

      case "academic_qualification":
        setValueStudent(name, val.label);
        setSelectedAcademicQualifications(val);
        break;

        case "course":
          setValueStudent(name, val.label);
          setSelectedCourse(val);
          break;

        case "race":
          setValueStudent(name, val.label);
          setSelectedRace(val);
          break;
   
        case "skill_level":
          setValueStudent(name, val.label);
          setSelectedSkillLevel(val);
        break;

        case "vulnerable_group":
          setValueStudent(name, val.label);
          setVulnarableGroup(val);
        break;
        
        default:
          setValueStudent(name, val.value);
      }
    };
console.log(selectedVulnarableGroup, "SELECTED STAT")
    const getcountryList = async () => {
      const { data, status } = await getCountriesMasterList();
      if (status === 200) {
        if (data.status === 1) {
          const Countrylist = data.data;
          const country = Countrylist.map((x) => {
            return { ...x, label: x.Name, value: x.Id, data: x };
          });
          setCountry(country);
          if (formVal.country) {
            let selCountry = country.filter((x) => x.value === formVal.country);
            if (selCountry.length > 0) {
              selCountry = selCountry[0];
              setValueStudent("country", selCountry.value);
            }
            setSelectedCountry(selCountry);
            getState(selCountry.value);
          }
        }
      }
    };
console.log(states,"COUNTRY")
    const getState = async (CountryId) => {
      const { data, status } = await getSatesMasterList(CountryId);
      if (status === 200) {
        if (data.status === 1) {
          const Stateslist = data.data;
          const state = Stateslist.map((x) => {
            return { ...x, label: x.Name, value: x.Id, data: x };
          });
          setStates(state);
          if (formVal.state) {
            let selStates = state.filter((x) => x.value === formVal.state);
            if (selStates.length > 0) {
              selStates = selStates[0];
              setValueStudent("state", selStates.value);
            }
            setSelectedState(selStates);
            // fetchCampus(selStates.value);
          }
        }
      }
    };
    const getCity = async (CountryId, StateId) => {
      const { data, status } = await getCitieMasterList(CountryId, StateId);
      if (status === 200) {
        if (data.status === 1) {
          const Citieslist = data.data;
          const cities = Citieslist.map((x) => {
            return { ...x, label: x.Name, value: x.Id, data: x };
          });
          setCities(cities);
          if (formVal.city) {
            let selcities = cities.filter((x) => x.value === formVal.city);
            if (selcities.length > 0) {
              selcities = selcities[0];
              setValueStudent("city", selcities.value);
            }
            setSelectedCity(selcities);
            // fetchCampus(selStates.value);
          }
        }
      }
    };

  const inputChange = (e) => {
    const { name } = e.target;
    let value = e.target.value;

    console.log(value, name);

    if (value) {
      switch (name) {
        case "id_number":
          if (formVal.id_type == 1) {
            var oldValue = ("" + value).replace(/\D/g, "");

            var isNum = true;
            if (oldValue.length > 0) {
              let lastDigit = oldValue.charAt(oldValue.length - 1);
              isNum = !isNaN(lastDigit);
            }

            let newValue = `${oldValue.substring(0, 6)}${oldValue.length > 6 ? "-" : ""
              }${oldValue.substring(6, 8)}${oldValue.length > 8 ? "-" : ""
              }${oldValue.substring(8, 12)}`;

            if (/^[a-zA-Z\d-]+$/.test(oldValue) && isNum) {
              setValueStudent("id_number", newValue);
              // setValueStudent("graduation_date", null);
              // setValueStudent("internship_expected_start_date", null);
              // setValueStudent("internship_expected_end_date", null);
            }
          } else {
            formVal.id_number = formVal?.id_number?.toUpperCase();
            setValueStudent(
              "id_number",
              /^[a-zA-Z\d]+$/.test(value?.toUpperCase())
                ? value?.toUpperCase()
                : formVal.id_number
            );
            // setValueStudent("graduation_date", null);
            // setValueStudent("internship_expected_start_date", null);
            // setValueStudent("internship_expected_end_date", null);
          }
          break;
        case "disability_code":
          setValueStudent(
            name,
            /^[0-9]+$/.test(value) ? value : formVal.disability_code
          );
          break;
        default:
          break;
      }
    } else {
      setValueStudent(name, value);
    }
  };

    const dateChange = (name, val) => {
      setValueStudent(name, formatDate(val));
      if(name === "expected_start_date"){
        setValueStudent(
          "expected_end_date",
          moment(new Date(val).setDate(new Date(val).getDate() + 70)).format(
            "YYYY-MM-DD"
          )
        );
      }
      
    };
    const onSubmitErrorHandler = () => {
      if (!formVal.first_name || formVal.first_name.trim().length === 0) {
        toast.error("Fill out all  the mandatory fields", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      
    };
console.log(formValBulk,"full name");
const [dataSaved, setDataSaved] = useState(false);
const requestData = {
  email: formVal.email,
  full_name: formVal.full_name,
  gender: formVal.gender,
  id_type: formVal.id_type,
  id_number: formVal.id_number,
  mobile_no: formVal.mobile,
  address_1: formVal.address_1,
  address_2: formVal.address_2,
  city: formVal.city,
  postal_code: formVal.postcode,
  state: formVal.state,
  race: selectedRace.label,
  university: selectedEducationalInstitution.value,
  univeristy_id: selectedEducationalInstitution.value,
  educational_institution:selectedEducationalInstitution.label,
  cgpa: formVal.cgpa,
  status: formVal.status,
  academic_qualification: formVal.academic_qualification,
  course: selectedCourse.label,
  internship_start_date: formVal.expected_start_date,
  internship_end_date: formVal.expected_end_date,
  date_of_hiring: formVal.date_of_hiring ===""? formVal.expected_start_date:formVal.date_of_hiring ,
  occupation: selectedOccupation.label,
  skill_level: formVal.skill_level,
  vulnerable_group: formVal.vulnerable_group,
  total_training_expenses: formVal?.total_training_expenses,
  total_meal_expenses: formVal?.total_meal_expenses,
  total_accomodation_expenses: formVal?.total_accomodation_expenses,
  total_travelling_expenses: formVal?.total_travelling_expenses,
  total_digital_communication_expenses: formVal?.total_digital_communication_expenses,
  internship_allowance_per_month: formVal?.internship_allowance_per_month,
  total_internship_allowance_in_programme: formVal?.total_internship_allowance_in_programme,
  uploaded_by: "", // You can replace this with the appropriate value
};

  const requestDataWithoutUniversities = { ...requestData };
  delete requestDataWithoutUniversities.universities;


  const [isSingle, setIsSingle] = useState(false);
  const handleSave = async (internship_id) => {
   

    const response = await singleInternBulkUploadValidate(requestData);
    console.log("Full Response from backend:", response);
    const responseData = {
      email: response.data.data.email,
      full_name: response.data.data.full_name,
      gender: response.data.data.gender,
      id_type: response.data.data.id_type,
      id_number: response.data.data.id_number,
      mobile_no: response.data.data.mobile_no,
      address_1: response.data.data.address_1,
      address_2: response.data.data.address_2,
      city: response.data.data.city,
      postal_code: response.data.data.postal_code,
      state: response.data.data.state,
      race: response.data.data.race,
      university: response.data.data.university,
      univeristy_id: response.data.data.univeristy_id,
      educational_institution:response.data.data.educational_institution,
      cgpa: response.data.data.cgpa,
      is_valid:response.data.is_valid,
      status: response.data.data.status,
      academic_qualification: response.data.data.academic_qualification,
      course: response.data.data.course,
      internship_start_date: response.data.data.internship_start_date,
      internship_end_date: response.data.data.internship_end_date,
      date_of_hiring: response.data.data.date_of_hiring ,
      occupation: response.data.data.occupation,
      skill_level: response.data.data.skill_level,
      vulnerable_group: response?.data?.data.vulnerable_group,
      total_training_expenses: response?.data?.data?.total_training_expenses,
      total_meal_expenses: response?.data?.data?.total_meal_expenses,
      total_accomodation_expenses: response?.data?.data?.total_accomodation_expenses,
      total_travelling_expenses: response?.data?.data?.total_travelling_expenses,
      total_digital_communication_expenses: response?.data?.data?.total_digital_communication_expenses,
      internship_allowance_per_month: response?.data?.data?.internship_allowance_per_month,
      total_internship_allowance_in_programme: response.data?.data?.total_internship_allowance_in_programme,
      uploaded_by: "", // You can replace this with the appropriate value
    };
 
    const { data, status } = response;
    console.log("Data from backend:", data);
    // const {data, status} = await singleInternBulkUploadValidate(requestData);
    // console.log("Response from backend:", data);
    if (data && data.status === 1 && data.is_valid === 1) {
      await setDataSaved(true);
      setInternModal(false);
      const dataToAdd = Array.isArray(responseData) ? responseData : [responseData]; // Ensure dataToAdd is an array
     setStudentList((prevStudentList) => [...prevStudentList, ...dataToAdd]);
      localStorage.setItem("add-intern", JSON.stringify(responseData));
      localStorage.setItem("studentList", JSON.stringify([...studentList, ...dataToAdd]));
      setIsSingle(true);
      resetForm();
      clearFormDropdowns();
    } else {
      toast.error(data?.message, {
        position: toast.POSITION.TOP_RIGHT,
 
      });
      const formErrors = data.data;

      // Update form state with errors
      Object.keys(formErrors).forEach((fieldName) => {
        setErrorCustom(fieldName, {
          type: 'manual',
          message: formErrors[fieldName][0],
        });
      });
    }


  }
  // useEffect(()=>{

  // },[])


  //navigate("/internship/create-internship/completion-of-internship", {state: {typeOrigin,internshipID: internship_id}})):(navigate("/loe-application")))
  const newData = { ...formVal, internship_id };
  console.log(newData, "Datatosend")

  const addInternBulk = ({ cache, fromEdit }) => {
    console.log(cache, "CacheBulk")
    return (
      <div>
        <TitleSection>
          <TitleDescription>Intern Personal Information</TitleDescription>
          <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
        </TitleSection>
        <CardContainer>
          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel>Full Name*</FormLabel>
                <Controller
                  name="full_name"
                  control={controlIntern}
                  defaultValue={cache?.full_name}
                  render={({ field }) => (
                    <>
                      <Input
                        // className="w-100"
                        disabled={fromEdit ? true : false}
                        placeholder={"Enter first name"}
                        {...field}
                      />
                    </>

                  )}
                />
                {errorsAddForm.full_name && (
                  <ErrorField err={errorsAddForm.full_name.message} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>Email Address*</FormLabel>
                <Controller
                  name="email"
                  control={controlIntern}
                  defaultValue={cache?.email}
                  render={({ field }) => (
                    <>
                      <Input
                        {...field}
                        placeholder="Enter email address"
                      />
                    </>
                  )} />
                {errorsAddForm.email && (
                  <ErrorField err={errorsAddForm.email.message} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>Gender*</FormLabel>
                <SelectorWrapper
                  style={{ display: "flex", paddingLeft: "20px" }}
                  className="w-100 mt-4"
                >
                  <Controller
                    name="gender"
                    control={controlIntern}
                    defaultValue={cache?.gender}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error }, }) => (
                      <>
                        <input
                          type="radio"
                          id="gender"
                          name="gender"
                          onChange={onChange}
                          onBlur={onBlur}
                          value="male"
                          checked={value === "male" || value === "Male"}
                        />
                        <label htmlFor="gender">{"Male"}</label>
                        <input
                          type="radio"
                          id="secondarygender"
                          name="gender"
                          onChange={onChange}
                          onBlur={onBlur}
                          value="female"
                          style={{ marginLeft: 20 }}
                          checked={value === "female" || value === "Female"}
                        />
                        <label htmlFor="secondarygender">{"Female"}</label>
                      </>

                    )}
                  />
                </SelectorWrapper>
                {errorsAddForm.gender && (
                  <ErrorField err={errorsAddForm.gender.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>

          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel>Identification*</FormLabel>
                <div style={{ paddingLeft: "15px" }}>
                  <Controller
                    control={controlIntern}
                    name="id_type"
                    defaultValue={cache?.id_type}
                    render={({
                      field: { onChange, onBlur, value, ref },
                      fieldState: { error },
                    }) => (
                      <>
                        <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                          <input
                            type="radio"
                            id="identification"
                            name="id_type"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="1"
                            checked={value == "1"}
                          />
                          <label htmlFor="identification">{"IC"}</label>
                          <input
                            type="radio"
                            id="secondaryidentification"
                            name="id_type"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="0"
                            style={{ marginLeft: 60 }}
                            checked={value == "0"}
                          />
                          <label style={{ fontSize: 14 }}>{"Passport"}</label>
                        </div>
                      </>
                    )}
                  />
                </div>
                <Row>
                  <Col md="12">
                    <Controller
                      name="id_number"
                      control={controlIntern}
                      defaultValue={cache?.id_number}
                      render={({ field }) => (
                        <Input

                          placeholder={"Enter Identification Number"}
                          {...field}
                          onChange={inputChange}
                          maxLength={formVal.id_type == 1 ? 14 : 16}
                        />
                      )}
                    />
                  </Col>
                </Row>
                {errorsAddForm.identification && (
                  <ErrorField err={errorsAddForm.identification.message} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>Mobile No*</FormLabel>
                <Controller
                  name="mobile"
                  control={controlIntern}
                  defaultValue={cache?.mobile_no}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter mobile no" />
                  )} />
                {errorsAddForm.mobile && (
                  <ErrorField err={errorsAddForm.mobile.message} />
                )}
              </Col>
              <Col md="4">
                <FormLabel>Address 1*</FormLabel>
                <Controller
                  name="address1"
                  control={controlIntern}
                  defaultValue={cache?.address_1}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter address 1" />
                  )} />
                {errorsAddForm.address1 && (
                  <ErrorField err={errorsAddForm.address1.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>

          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel>Address 2*</FormLabel>
                <Controller
                  name="address2"
                  control={controlIntern}
                  defaultValue={cache?.address_2}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter address 2"
                      {...field} />

                  )} />
                {/* {errorsAddForm.address2 && (
                                  <ErrorField err={errorsAddForm.address2.message}/>
                                )} */}
              </Col>

              <Col md="4">
                <FormLabel>City *</FormLabel>
                <Controller
                  name="city"
                  control={controlIntern}
                  defaultValue={cache?.city}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter city"
                      {...field}
                    />
                  )} />
                {errorsAddForm.city && (
                  <ErrorField err={errorsAddForm.city.message} />
                )}
              </Col>

              <Col md="4">
                <FormLabel>Postcode*</FormLabel>
                <Controller
                  name="postcode"
                  control={controlIntern}
                  defaultValue={cache?.postal_code}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter postcode"
                      {...field}
                    />
                  )} />
                {errorsAddForm.postcode && (
                  <ErrorField err={errorsAddForm.postcode.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>

          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel>State *</FormLabel>
                <Controller
                  name="state"
                  control={controlIntern}
                  defaultValue={cache?.state}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter state"
                      {...field}
                    />
                  )} />
                {errorsAddForm.state && (
                  <ErrorField err={errorsAddForm.state.message} />
                )}
              </Col>

              <Col md="4">
                <FormLabel>Race *</FormLabel>
                <Controller
                  name="race"
                  control={controlIntern}
                  defaultValue={cache?.race}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter race"
                      {...field}
                    />
                  )} />
                {errorsAddForm.race && (
                  <ErrorField err={errorsAddForm.race.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>
        </CardContainer>

        <TitleSection>
          <TitleDescription>Education Information</TitleDescription>
          <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
        </TitleSection>
        <CardContainer>
          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel>Educational Institution*</FormLabel>
                <Controller
                  name="educational_institution"
                  control={controlIntern}
                  defaultValue={cache?.educational_institution}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter Educational Institution"
                      {...field}
                    />
                  )}
                />
                {/* {errorsAddForm.campus && (
                          <ErrorField err={errorsAddForm.campus.message}/>
                        )} */}
              </Col>

              <Col md="4">
                <FormLabel>CGPA*</FormLabel>
                <Controller
                  name="cgpa"
                  control={controlIntern}
                  defaultValue={cache?.cgpa}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter CGPA"
                    />
                  )}
                />
                {errorsAddForm.cgpa && (
                  <ErrorField err={errorsAddForm.cgpa.message} />
                )}
              </Col>

              <Col md="4">
                <FormLabel>Academic Qualifications *</FormLabel>
                <Controller
                  name="academic_qualification"
                  control={controlIntern}
                  defaultValue={cache?.academic_qualification}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter academic qualification"
                    />
                  )}
                />
                {errorsAddForm.academic_qualification && (
                  <ErrorField err={errorsAddForm.academic_qualification.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>


          <GroupWrapper>
            <Row>

              <Col md="8">
                <FormLabel>Course *</FormLabel>
                <Controller
                  name="course"
                  control={controlIntern}
                  defaultValue={cache?.course}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter course"
                    />
                  )}
                />
                {errorsAddForm.course && (
                  <ErrorField err={errorsAddForm.course.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>
        </CardContainer>

        <TitleSection>
          <TitleDescription>Internship Details</TitleDescription>
          <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
        </TitleSection>
        <CardContainer>
          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel> Internship Start Date*</FormLabel>
                <Controller
                  name="expected_start_date"
                  control={controlIntern}
                  render={({ field }) => (
                    <label style={{ width: "max-content" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            style={{ marginTop: "-5px", marginTop: "20px" }}
                            autoOk
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            views={["year", "month", "date"]}
                            value={
                              !formVal.expected_start_date
                                ? null
                                : formVal.expected_start_date &&
                                  formVal.expected_start_date === "None"
                                  ? null
                                  : formVal.expected_start_date === ""
                                    ? null
                                    : new Date(formVal.expected_start_date)
                            }
                            fullWidth
                            onChange={(val) => {
                              dateChange("expected_start_date", val);
                              // console.log(formVal.intern_dur_year);
                            }}
                          // minDate={new Date().setDate(
                          //   new Date().getDate() + 14
                          // )}
                          // minDate={id && code ? ""}
                          //   minDate={
                          //     id && org_code
                          //       ? ""
                          //       : new Date().setDate(
                          //           new Date().getDate()
                          //         )
                          //   }
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                      {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                    </label>
                  )}
                />
                {/* {errorsAddForm.start_date && (
                          <ErrorField err={errorsAddForm.start_date.message}/>
                        )} */}
              </Col>

              <Col md="4">
                <FormLabel> Internship End Date*</FormLabel>
                <Controller
                  name="expected_end_date"
                  control={controlIntern}
                  defaultValue={ cache?.internship_end_date
                    ? new Date(cache.internship_end_date)
                    : null}
                  render={({ field }) => (
                    <label style={{ width: "max-content" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            style={{ marginTop: "-5px", marginTop: "20px" }}
                            autoOk
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            views={["year", "month", "date"]}
                            value={
                              !formVal.expected_end_date
                                ? null
                                : formVal.expected_end_date &&
                                  formVal.expected_end_date === "None"
                                  ? null
                                  : formVal.expected_end_date === ""
                                    ? null
                                    : new Date(formVal.expected_end_date)
                            }
                            fullWidth
                            onChange={(val) => {
                              dateChange("expected_end_date", val);
                              // console.log(formVal.intern_dur_year);
                            }}
                          // minDate={new Date().setDate(
                          //   new Date().getDate() + 14
                          // )}
                          // minDate={id && code ? ""}
                          //   minDate={
                          //     id && org_code
                          //       ? ""
                          //       : new Date().setDate(
                          //           new Date().getDate()
                          //         )
                          //   }
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                      {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                    </label>
                  )}
                />
                {/* {errorsAddForm.end_date && (
                          <ErrorField err={errorsAddForm.end_date.message}/>
                        )} */}
              </Col>
              <Col md={4}>
                      <FormLabel>Duration</FormLabel>
                      <Controller
                          name="duration"
                          control={controlIntern}
                          render={({ field }) => {
                            const startDate = formVal.expected_start_date;
                            const endDate = formVal.expected_end_date;

                            // Calculate the duration in months
                            const durationWeeks = startDate && endDate
                            ? differenceInWeeks(new Date(endDate), new Date(startDate))
                            : null;

                          const durationDays = startDate && endDate
                            ? differenceInDays(new Date(endDate), new Date(startDate)) % 7
                            : null;

                            const calculateDuration = durationWeeks !== null
                            ? `${durationWeeks} weeks${durationDays > 0 ? ` ${durationDays} days` : ''}`
                            : null;
                            return (
                              <div >
                                {durationWeeks !== null ? (
                                  <TitleDescription style={{
                                    marginTop:'40px',
                                    marginLeft: '10px',
                                  }}>{calculateDuration}</TitleDescription>
                                ) : (
                                  null
                                )}
                              </div>
                            );
                          }}
  />
                    </Col>


              <Col md="4">
                <FormLabel>Date of Hiring*</FormLabel>
                <Controller
                  name="date_of_hiring"
                  control={controlIntern}
                  defaultValue={cache?.date_of_hiring}
                  render={({ field }) => (
                    <label style={{ width: "max-content" }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DatePicker
                            style={{ marginTop: "-5px" }}
                            autoOk
                            variant="inline"
                            format="dd/MM/yyyy"
                            placeholder="dd/mm/yyyy"
                            views={["year", "month", "date"]}
                            value={
                              !formVal.date_of_hiring
                                ? null
                                : formVal.date_of_hiring &&
                                  formVal.date_of_hiring === "None"
                                  ? null
                                  : formVal.date_of_hiring === ""
                                    ? null
                                    : new Date(formVal.date_of_hiring)
                            }
                            onChange={(val) =>
                              dateChange("date_of_hiring", val)
                            }
                            fullWidth
                          // minDate={new Date().setDate(
                          //   new Date().getDate() + 14
                          // )}
                          // minDate={id && code ? ""}
                          //   minDate={
                          //     id && org_code
                          //       ? ""
                          //       : new Date().setDate(
                          //           new Date().getDate()
                          //         )
                          //   }
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                      {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                    </label>
                  )}
                />
                {errorsAddForm.date_of_hiring && (
                  <ErrorField err={errorsAddForm.date_of_hiring.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>


          <GroupWrapper>
            <Row>
              <Col md="4">
                <FormLabel>Occupation(For MySIP, it would be under Professionals)*</FormLabel>
                <Controller
                  name="occupation"
                  control={controlIntern}
                  defaultValue={cache?.occupation}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Occupation"
                    />
                  )}
                />
                {errorsAddForm.occupation && (
                  <ErrorField err={errorsAddForm.occupation.message} />
                )}
              </Col>

              <Col md="4">
                <FormLabel>Skill Level*</FormLabel>
                <Controller
                  name="skill_level"
                  control={controlIntern}
                  render={({ field }) => (
                    <Input
                      placeholder="Enter skill level"
                      {...field}
                    />
                  )}
                />
                {errorsAddForm.skill_level && (
                  <ErrorField err={errorsAddForm.skill_level.message} />
                )}
              </Col>
            </Row>
          </GroupWrapper>
        </CardContainer>
        <ButtonWrapper>
          <Button name="Save" onClick={() => handleSave(internship_id)} />
          <Button secondary name="Cancel" onClick={() => { setInternModal(false) }} />
        </ButtonWrapper>
      </div>
      // <AddInternForm closeModal2={closeModal2}
      // //  controlIntern={controlIntern}
      // //  addWatch={addWatch}
      // //  schema={schema}
      // //  errorsAddForm={errorsAddForm}
      // />

    )
  }
  const addIntern = () => {
    return (
      <form onSubmit={handleSubmit(handleSave)}>
       <div>
          <TitleSection>
            <TitleDescription>Intern Personal Information</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <CardContainer>
            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Full Name*</FormLabel>
                  <Controller
                    name="full_name"
                    control={controlIntern}
                    render={({ field }) => (
                      <>
                        <Input
                          // className="w-100"
                          // disabled=  {fromEdit ? true:false}
                          placeholder={"Enter first name"}
                          {...field}
                          onChange={(e) => {
                            // Clear errors when the user starts typing
                            clearErrors("full_name");
                            field.onChange(e);
                          }}
                        />
                      </>

                    )}
                  />
                 {errorsAddForm.full_name && (
                    <ErrorField err={errorsAddForm.full_name.message} />
                  )}
                </Col>
                <Col md="4">
                  <FormLabel>Email Address*</FormLabel>
                  <Controller
                    name="email"
                    control={controlIntern}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          placeholder="Enter email address"
                          onChange={(e) => {
                            // Clear errors when the user starts typing
                            clearErrors("email");
                            field.onChange(e);
                          }}
                        />
                      </>
                    )} />
                  {errorsAddForm.email && (
                    <ErrorField err={errorsAddForm.email.message} />
                  )}
                </Col>
                <Col md="4">
                  <FormLabel>Gender*</FormLabel>
                  <SelectorWrapper
                    style={{ display: "flex", paddingLeft: "20px" }}
                    className="w-100 mt-4"
                  >
                    <Controller
                      name="gender"
                      control={controlIntern}
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error }, }) => (
                        <>
                          <input
                            type="radio"
                            id="gender"
                            name="gender"
                            onChange={(e) => {
                              clearErrors("gender");
                              onChange(e);
                            }}
                            onBlur={onBlur}
                            value="male"
                            checked={value === "male" || value === "Male"}
                          />
                          <label htmlFor="gender">{"Male"}</label>
                          <input
                            type="radio"
                            id="secondarygender"
                            name="gender"
                            onChange={(e) => {
                              clearErrors("gender");
                              onChange(e);
                            }}
                            onBlur={onBlur}
                            value="female"
                            style={{ marginLeft: 20 }}
                            checked={value === "female" || value === "Female"}
                          />
                          <label htmlFor="secondarygender">{"Female"}</label>
                        </>

                      )}
                    />
                  </SelectorWrapper>
                  {errorsAddForm.gender && (
                    <ErrorField err={errorsAddForm.gender.message} />
                  )}
                </Col>
              </Row>
            </GroupWrapper>

                  <GroupWrapper>
                      <Row>
                      <Col md="4">
                          <FormLabel>Identification*</FormLabel>
                          <div style={{paddingLeft: "15px"}}>
                          <Controller
                        control={controlIntern}
                        name="id_type"
                        render={({
                          field: { onChange, onBlur, value, ref },
                          fieldState: { error },
                        }) => (
                          <>
                              <div style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                            <input
                              type="radio"
                              id="identification"
                              name="id_type"
                              onChange={onChange}
                              onBlur={onBlur}
                              value="1"
                              checked={value === "1"}
                            />
                            <label htmlFor="identification">{"IC"}</label>
                            <input
                              type="radio"
                              id="secondaryidentification"
                              name="id_type"
                              onChange={onChange}
                              onBlur={onBlur}
                              value="0"
                              style={{ marginLeft: 60 }}
                              checked={value === "0"}
                            />
                            <label style={{ fontSize: 14 }}>{"Passport"}</label>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <Row>
                    <Col md="12">
                      <Controller
                        name="id_number"
                        control={controlIntern}
                        render={({ field }) => (
                          <Input

                            placeholder={"Enter Identification Number"}
                            {...field}
                            onChange={(e) => {
                              clearErrors("id_number");
                              inputChange(e);
                            }}
                            maxLength={formVal.id_type == 1 ? 14 : 16}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {errorsAddForm.id_number && (
                    <ErrorField err={errorsAddForm.id_number.message} />
                  )}
                </Col>
                <Col md="4">
                  <FormLabel>Mobile No*</FormLabel>
                  <Controller
                    name="mobile"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(e) => {
                          clearErrors("mobile_no");
                          field.onChange(e);
                        }}
                        placeholder="Enter mobile no" />
                    )} />
                  {errorsAddForm.mobile && (
                    <ErrorField err={errorsAddForm.mobile.message} />
                  )}
                </Col>
                <Col md="4">
                  <FormLabel>Address 1*</FormLabel>
                  <Controller
                    name="address_1"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        onChange={(e) => {
                          clearErrors("address_1");
                          field.onChange(e);
                        }}
                        placeholder="Enter address 1" />
                    )} />
                  {errorsAddForm.address_1 && (
                    <ErrorField err={errorsAddForm.address_1.message} />
                  )}
                </Col>
              </Row>
            </GroupWrapper>

            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Address 2*</FormLabel>
                  <Controller
                    name="address_2"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        placeholder="Enter address 2"
                        {...field} />

                    )} />
                  {errorsAddForm.address_2 && (
                                    <ErrorField err={errorsAddForm.address_2.message}/>
                                  )}
                </Col>


                <Col md="4">
                  <FormLabel>State *</FormLabel>
                  <Controller
                    name="state"
                    control={controlIntern}
                    render={({ field }) => (
                      <Dropdown
                        search={true}
                        options={states}
                        placeholder={"Select State"}
                        val={selectedState?.value}
                        label={selectedState?.label}
                        change={(val) => {
                          clearErrors("state");
                          dropdownChange("state", val);
                        }}
                        {...field}
                      />
                    )} />
                  {errorsAddForm.state && (
                    <ErrorField err={errorsAddForm.state.message} />
                  )}
                </Col>
                <Col md="4">
                  <FormLabel>City *</FormLabel>
                  <Controller
                    name="city"
                    control={controlIntern}
                    render={({ field }) => (
                      // <Input
                      // placeholder="Enter city"
                      // {...field}
                      // />
                      <Dropdown
                        bgGrey
                        search={true}
                        options={cities}
                        placeholder={"Select City"}
                        val={selectedCity?.value}
                        label={selectedCity?.label}
                        disable={!formVal.state}
                        change={(val) => {
                          clearErrors("city");
                          dropdownChange("city", val);
                        }}
                        {...field}
                      />
                    )} />
                  {errorsAddForm.city && (
                    <ErrorField err={errorsAddForm.city.message} />
                  )}
                </Col>

              </Row>
            </GroupWrapper>

                  <GroupWrapper>
                      <Row>
                      <Col md="4">
                              <FormLabel>Postcode*</FormLabel>
                              <Controller
                                  name="postcode"
                                  control={controlIntern}
                                  render={({field}) =>(
                                      <Input
                                      placeholder="Enter postcode"
                                      onChange={(e) => {
                                        clearErrors("postal_code");
                                        field.onChange(e);
                                      }}
                                      {...field}
                                      />
                                  )}/>
                                  {errorsAddForm.postcode && (
                                    <ErrorField err={errorsAddForm.postcode.message}/>
                                  )}
                          </Col>
                          <Col md="4">
                          <FormLabel>Race *</FormLabel>
                            <Controller 
                              name="race"
                              control={controlIntern}
                              render={({field}) => (
                                <Dropdown
                                  placeholder="Select ..."
                                  options={raceList.map(race => ({ value: race.value, label: race.label }))}
                                  val={selectedRace?.value}
                                  label={selectedRace?.label}
                                  change={(val) => {
                                    clearErrors("race");
                                    dropdownChange("race", val);
                                  }}
                                />
                              )}
                            />
                            {errorsAddForm.race && (
                              <ErrorField err={errorsAddForm.race.message}/>
                            )}
                          </Col>

                          <Col md="4">
                          <FormLabel>Vulnerable Group *</FormLabel>
                            <Controller 
                              name="vulnerable_group"
                              control={controlIntern}
                              render={({field}) => (
                                <Dropdown
                                  placeholder="Select ..."
                                  options={vulnerableGroup.map(vulnarable => ({ value: vulnarable.value, label: vulnarable.label }))}
                                  val={selectedVulnarableGroup?.value}
                                  label={selectedVulnarableGroup?.label}
                                  change={(val) => {
                                    clearErrors("vulnerable_group");
                                    dropdownChange("vulnerable_group", val);
                                  }}
                                />
                              )}
                            />
                            {errorsAddForm.vulnerable_group && (
                              <ErrorField err={errorsAddForm.vulnerable_group.message}/>
                            )}
                          </Col>
                      </Row>
                  </GroupWrapper>
              </CardContainer>

          <TitleSection>
            <TitleDescription>Education Information</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <CardContainer>
            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Educational Institution*</FormLabel>
                  <Controller
                    name="educational_institution"
                    control={controlIntern}
                    render={({ field }) => (
                      <Dropdown
                        options={univeristies}
                        placeholder="Select ..."
                        val={selectedEducationalInstitution?.value}
                        label={selectedEducationalInstitution?.label}
                        change={(val) => {
                          clearErrors("educational_institution");
                          dropdownChange("educational_institution", val);
                        }}
                        {...field}
                      />
                    )}
                  />
                  {errorsAddForm.educational_institution && (
                            <ErrorField err={errorsAddForm.educational_institution.message}/>
                          )}
                </Col>

                <Col md="4">
                  <FormLabel>CGPA*</FormLabel>
                  <Controller
                    name="cgpa"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter CGPA"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("cgpa");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.cgpa && (
                    <ErrorField err={errorsAddForm.cgpa.message} />
                  )}
                </Col>
                <Col md="4">
                  <FormLabel>Academic Qualifications *</FormLabel>
                  <Controller
                    name="academic_qualification"
                    control={controlIntern}
                    render={({ field }) => (
                      <Dropdown
                        placeholder="Select ..."
                        options={academicQualificationList}
                        val={selectedAcademicQualifications?.value}
                        label={selectedAcademicQualifications?.label}
                        change={(val) => {
                          clearErrors("academic_qualification");
                          dropdownChange("academic_qualification", val);
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.academic_qualification && (
                    <ErrorField err={errorsAddForm.academic_qualification.message} />
                  )}
                </Col>
              </Row>
            </GroupWrapper>


            <GroupWrapper>
              <Row>
                <Col md="8">
                  <FormLabel>Course *</FormLabel>
                    <Controller 
                      name="course"
                      control={controlIntern}
                      render={({field}) => (
                        <Dropdown
                          placeholder="Select ..."
                          options={courseList.map(course => ({ value: course.value, label: course.label }))}
                          val={selectedCourse?.value}
                          label={selectedCourse?.label}
                          change={(val) => {
                            clearErrors("course");
                            dropdownChange("course", val);
                          }}
                        />
                      )}
                    />
                      {errorsAddForm.course && (
                        <ErrorField err={errorsAddForm.course.message}/>
                      )}
                  </Col>
                </Row>
              </GroupWrapper>
            </CardContainer>

          <TitleSection>
            <TitleDescription>Internship Details</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <CardContainer>
            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel> Internship Start Date*</FormLabel>
                  <Controller
                    name="expected_start_date"
                    control={controlIntern}
                    render={({ field }) => (
                      <label style={{ width: "max-content" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              style={{ marginTop: "-5px", marginTop: "20px" }}
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                !formVal.expected_start_date
                                  ? null
                                  : formVal.expected_start_date &&
                                    formVal.expected_start_date === "None"
                                    ? null
                                    : formVal.expected_start_date === ""
                                      ? null
                                      : new Date(formVal.expected_start_date)
                              }
                              fullWidth
                              onChange={(val) => {
                                clearErrors("internship_start_date");
                                dateChange("expected_start_date", val);
                              }}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        {/* <CalenderIconWrapper>
                                    <CalenderIcon />
                                  </CalenderIconWrapper> */}
                      </label>
                    )}
                  />
                  {errorsAddForm.expected_start_date && (
                            <ErrorField err={errorsAddForm.expected_start_date.message}/>
                          )}
                </Col>

                <Col md="4">
                  <FormLabel> Internship End Date*</FormLabel>
                  <Controller
                    name="expected_end_date"
                    control={controlIntern}
                    render={({ field }) => (
                      <label style={{ width: "max-content" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              style={{ marginTop: "-5px", marginTop: "20px" }}
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                !formVal.expected_end_date
                                  ? null
                                  : formVal.expected_end_date &&
                                    formVal.expected_end_date === "None"
                                    ? null
                                    : formVal.expected_end_date === ""
                                      ? null
                                      : new Date(formVal.expected_end_date)
                              }
                              fullWidth
                              onChange={(val) => {
                                clearErrors("internship_end_date");
                                dateChange("expected_end_date", val);
                              }}
                            // minDate={new Date().setDate(
                            //   new Date().getDate() + 14
                            // )}
                            // minDate={id && code ? ""}
                            //   minDate={
                            //     id && org_code
                            //       ? ""
                            //       : new Date().setDate(
                            //           new Date().getDate()
                            //         )
                            //   }
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        {/* <CalenderIconWrapper>
                                    <CalenderIcon />
                                  </CalenderIconWrapper> */}
                      </label>
                    )}
                  />
                  {errorsAddForm.expected_end_date && (
                            <ErrorField err={errorsAddForm.expected_end_date.message}/>
                          )}
                </Col>
                <Col md={4}>
                      <FormLabel>Duration</FormLabel>
                      <Controller
                          name="duration"
                          control={controlIntern}
                          render={({ field }) => {
                            const startDate = formVal.expected_start_date;
                            const endDate = formVal.expected_end_date;

                            // Calculate the duration in months
                            const durationWeeks = startDate && endDate
                            ? differenceInWeeks(new Date(endDate), new Date(startDate))
                            : null;

                          const durationDays = startDate && endDate
                            ? differenceInDays(new Date(endDate), new Date(startDate)) % 7
                            : null;

                            const calculateDuration = durationWeeks !== null
                            ? `${durationWeeks} weeks${durationDays > 0 ? ` ${durationDays} days` : ''}`
                            : null;
                            return (
                              <div>
                                {durationWeeks !== null ? (
                                 <TitleDescription style={{
                                  marginTop:'40px',
                                  marginLeft: '10px',
                                }}>{calculateDuration}</TitleDescription>
                                ) : (
                                  null
                                )}
                              </div>
                            );
                          }}
  />
                    </Col>


                <Col md="4">
                  <FormLabel>Date of Hiring*</FormLabel>
                  <Controller
                    name="date_of_hiring"
                    control={controlIntern}
                    defaultValue={formVal.date_of_hiring===""?formVal.expected_start_date :formVal.date_of_hiring }
                    render={({ field }) => (
                      <label style={{ width: "max-content" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              style={{ marginTop: "-5px" }}
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                !formVal.date_of_hiring
                                  ? (formVal.expected_start_date?formVal.expected_start_date:null)
                                  : formVal.date_of_hiring &&
                                    formVal.date_of_hiring === "None"
                                    ? (formVal.expected_start_date?formVal.expected_start_date:null)
                                    : formVal.date_of_hiring === ""
                                      ? (formVal.expected_start_date?formVal.expected_start_date:null)
                                      : new Date(formVal.date_of_hiring)
                              }
                              onChange={(val) => {
                                clearErrors("date_of_hiring");
                                dateChange("date_of_hiring", val);
                              }}
                              fullWidth
                            // minDate={new Date().setDate(
                            //   new Date().getDate() + 14
                            // )}
                            // minDate={id && code ? ""}
                            //   minDate={
                            //     id && org_code
                            //       ? ""
                            //       : new Date().setDate(
                            //           new Date().getDate()
                            //         )
                            //   }
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        {/* <CalenderIconWrapper>
                                    <CalenderIcon />
                                  </CalenderIconWrapper> */}
                      </label>
                    )}
                  />
                  {errorsAddForm.date_of_hiring && (
                    <ErrorField err={errorsAddForm.date_of_hiring.message} />
                  )}
                </Col>
              </Row>
            </GroupWrapper>

            <TitleSection>
              <TitleDescription>Internship Expenses</TitleDescription>
              <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
            </TitleSection>
            <GroupWrapper>
              <Row>
                <Col md="6">
                  <FormLabel>Total Training Expenses*</FormLabel>
                  <Controller
                    name="total_training_expenses"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total training expenses"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("total_training_expenses");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.total_training_expenses && (
                    <ErrorField err={errorsAddForm.total_training_expenses.message} />
                  )}
                </Col>

                <Col md="6">
                  <FormLabel>Total Meal Expenses*</FormLabel>
                  <Controller
                    name="total_meal_expenses"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total meal expenses"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("total_meal_expenses");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.total_meal_expenses && (
                    <ErrorField err={errorsAddForm.total_meal_expenses.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormLabel>Total Accomodation Expenses*</FormLabel>
                  <Controller
                    name="total_accomodation_expenses"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total accomodation expenses"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("total_accomodation_expenses");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.total_accomodation_expenses && (
                    <ErrorField err={errorsAddForm.total_accomodation_expenses.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="4">
                  <FormLabel>Total Travelling Expenses*</FormLabel>
                  <Controller
                    name="total_travelling_expenses"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total travelling expenses"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("total_travelling_expenses");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.total_travelling_expenses && (
                    <ErrorField err={errorsAddForm.total_travelling_expenses.message} />
                  )}
                </Col>
                <Col md="8">
                  <FormLabel>Total Digital And Communication Expenses*</FormLabel>
                  <Controller
                    name="total_digital_communication_expenses"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total digital and communications expenses"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("total_digital_communication_expenses");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.total_digital_communication_expenses && (
                    <ErrorField err={errorsAddForm.total_digital_communication_expenses.message} />
                  )}
                </Col>
              </Row>
              <Row>
                <Col md="6">
                  <FormLabel>Monthly Allowance*</FormLabel>
                  <Controller
                    name="internship_allowance_per_month"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter monthly allowance"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("internship_allowance_per_month");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.internship_allowance_per_month && (
                    <ErrorField err={errorsAddForm.internship_allowance_per_month.message} />
                  )}
                </Col>

                <Col md="6">
                  <FormLabel>Total Allowance in Program*</FormLabel>
                  <Controller
                    name="total_internship_allowance_in_programme"
                    control={controlIntern}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total allowance"
                        onChange={(e) => {
                          field.onChange(e)
                          clearErrors("total_internship_allowance_in_programme");
                        }}
                      />
                    )}
                  />
                  {errorsAddForm.total_internship_allowance_in_programme && (
                    <ErrorField err={errorsAddForm.total_internship_allowance_in_programme.message} />
                  )}
                </Col>
              </Row>
            </GroupWrapper>
          </CardContainer>
          <ButtonWrapper>
          <Button name="Save" type="button" onClick={() => handleSave()} />

            <Button secondary name="Cancel" onClick={() => { setInternModal(false) ;clearFormDropdowns()}} />
          </ButtonWrapper>
        </div>
      </form>
     
      // <AddInternForm closeModal2={closeModal2}
      // //  controlIntern={controlIntern}
      // //  addWatch={addWatch}
      // //  schema={schema}
      // //  errorsAddForm={errorsAddForm}
      // />

    )
  }
  const uploadSuccessError = () => {
    return (
      <>
        <ModalText>
          {bulkModalSuccessErrorMode === "error"? "Please correct invalid data":
          "Would you like to add total of " + bulkUploadStudentList?.length +" interns?"}
        </ModalText>
        {bulkModalSuccessErrorMode === "error" ?
          (
            <>
            
             <CenterButton>
             <Button secondary name="OK" onClick={closeErrorModal}/>
             </CenterButton>
            </>
           )
      :(
      <>
      <ButtonWrapper>
      <Button name="Upload" onClick={()=>{onBulkUploadAfterValidationSuccess(); setIsUpload(true)}}/>
      <Button name="Cancel" secondary onClick={closeErrorModal}/>
      </ButtonWrapper>
      </>
      )}
        </>
      )
    }
    //new bulk
    const updatedBy="Pravin";
    // Function to validate the edited data

    const clearFormDropdowns = ()=>{
      setSelectedRace([]);
      setSelectedCourse([]);
      setSelectedCity([]);
      setSelectedState([]);
      setSelectedSkillLevel([]);
      setSelectedAcademicQualifications([]);
      setSelectedStatus([]);
      setSelectedEducationalInstitution([]);
      
      clearErrors("race");
      clearErrors("course");
      clearErrors("city");
      clearErrors("state");
      clearErrors("skill_level");
      clearErrors("academic_qualifications");
      clearErrors("status");
      clearErrors("educational_institution");
      clearErrors("internship_start_date");
      clearErrors("internship_end_date");
      clearErrors("address_1");

    }

  const [isDataError, setIsDataError] = useState(false);
  const [localErrors, setLocalErrors] = useState([]);
  const validateEditedData = async () => {
    
  try {
    // Construct the data to send to the API, including internship ID and the edited data
    
    
    const validationData = {
      email: formValBulk.email,
      full_name: formValBulk.full_name,
      gender: formValBulk.gender,
      id_type: formValBulk.id_type,
      id_number: formValBulk.id_number,
      mobile_no: formValBulk.mobile_no,
      address_1: formValBulk.address1,
      address_2: formValBulk.address2,
      city: formValBulk.city,
      postal_code: formValBulk.postal_code,
      state: formValBulk.state,
      race: selectedRace.value,
      university: selectedEducationalInstitution.value,
      educational_institution:selectedEducationalInstitution.label,
      univeristy_id: selectedEducationalInstitution.value,
      cgpa: formValBulk.cgpa,
      status: formValBulk.status,
      academic_qualification: formValBulk.academic_qualification,
      course: selectedCourse.value,
      internship_start_date: formVal.expected_start_date ===""? cache.internship_start_date: formVal.expected_start_date,
      internship_end_date: formVal.expected_end_date===""? cache.internship_end_date: formVal.expected_end_date,
      date_of_hiring: formVal.date_of_hiring===""? cache.date_of_hiring: formVal.date_of_hiring,
      occupation: selectedOccupation.label,
      skill_level: formValBulk.skill_level,
      vulnerable_group: formValBulk?.vulnerable_group,
      total_training_expenses: formValBulk?.total_training_expenses,
      total_meal_expenses: formValBulk?.total_meal_expenses,
      total_accomodation_expenses: formValBulk?.total_accomodation_expenses,
      total_travelling_expenses: formValBulk?.total_travelling_expenses,
      total_digital_communication_expenses: formValBulk?.total_digital_communication_expenses,
      internship_allowance_per_month: formValBulk?.internship_allowance_per_month,
      total_internship_allowance_in_programme: formValBulk?.total_internship_allowance_in_programme,
      uploaded_by: "", // You can replace this with the appropriate value
    };
    console.log(validationData,"validationDataBulkSingle")
    // Send a request to the singleInternBulkUploadValidate API
    const response = await singleInternBulkUploadValidate(validationData,internship_id);
    console.log(response, "response?.data Sucess Outside")
      if (response.data.status === 1 && response.data.is_valid===1) {
        dispatch(clearCache());
        // Validation successful
        const validationDataBulk = {
          email: response.data.data.email,
          full_name: response.data.data.full_name,
          gender: response.data.data.gender,
          id_type: response.data.data.id_type,
          id_number: response.data.data.id_number,
          mobile_no: response.data.data.mobile_no,
          address_1: response.data.data.address_1,
          address_2: response.data.data.address_2,
          city: response.data.data.city,
          postal_code: response.data.data.postal_code,
          state: response.data.data.state,
          race: response.data.data.race,
          university: selectedEducationalInstitution.value,
          educational_institution: response.data.data.educational_institution,
          univeristy_id: selectedEducationalInstitution.value,
          cgpa: response.data.data.cgpa,
          status: response.data.data.status,
          academic_qualification: response.data.data.academic_qualification,
          course: response.data.data.course,
          internship_start_date: response.data.data.internship_start_date,
          internship_end_date: response.data.data.internship_end_date,
          date_of_hiring: formValBulk.date_of_hiring,
          occupation: response.data.data.occupation,
          is_valid: response.data.is_valid,
          uuid: formValBulk.uuid,
          skill_level: response.data.data.skill_level,
          vulnerable_group: response?.data?.data?.vulnerable_group,
          total_training_expenses: response?.data?.data?.total_training_expenses,
          total_meal_expenses: response?.data?.data?.total_meal_expenses,
          total_accomodation_expenses: response?.data?.data?.total_accomodation_expenses,
          total_travelling_expenses: response?.data?.data?.total_travelling_expenses,
          total_digital_communication_expenses: response?.data?.data?.total_digital_communication_expenses,
          internship_allowance_per_month: response?.data?.data?.internship_allowance_per_month,
          total_internship_allowance_in_programme: response.data?.data?.total_internship_allowance_in_programme,
          uploaded_by: "",
        };
        onSingleEditBulkUpload(validationDataBulk);
        setStudentValidatedData(response?.data);
        resetForm();
        clearFormDropdowns();
        // setSelectedRace([]);
        // setSelectedCourse([]);
        // setSelectedCity([]);
        // setSelectedState([]);
        // setSelectedSkillLevel([]);
        // setSelectedAcademicQualifications([]);
        // setSelectedStatus([]);
        // setSelectedEducationalInstitution([]);
        console.log(response, "response?.data Sucess")
      } else {
        // Handle the case where the API request was not successful
        console.log(response.data.data, "response?.data")
        const normalizeErrors = (nestedErrors) => {
          const normalizedErrors = [];
        
          // Iterate over each field in the nested errors
          for (const fieldName in nestedErrors) {
            if (nestedErrors.hasOwnProperty(fieldName)) {
              // Extract the array of errors for the current field
              const errorArray = nestedErrors[fieldName];
        
              // Normalize the structure of the errors for the current field
              const fieldErrors = errorArray.map((errorMessage) => ({
                [fieldName]: errorMessage,
              }));
        
              // Add the normalized errors to the overall array
              normalizedErrors.push(...fieldErrors);
            }
          }
        
          return normalizedErrors;
        };
        dispatch(clearErrors1()); 
        const normalizedErrors = normalizeErrors(response.data.data);
        console.log(normalizedErrors,"newErrors")
        console.log("Updated Redux StorenewErrors:", errors);
        setLocalErrors(normalizedErrors);
        

        dispatch(updateErrors(normalizedErrors));

        toast.error(
          `Error: ${response.status} - ${response?.message?.split("'").join("")}`
        );
        setLoading(true);
      }
    } catch (error) {
      // Handle any errors that may occur during the API request
      // Display an error message or take appropriate action.
      console.error("API request error:", error);
      toast.error("An error occurred.Please check your data.");
    }
  };

  const onSingleEditBulkUpload = async (validationDataBulk) => {
    // Assuming formValBulk contains the modified data
    const updatedData = validationDataBulk;

      // Find the index of the student to be updated based on a unique identifier (e.g., student ID)
      const studentIndex = studentList.findIndex(student => student.uuid  === updatedData.uuid );
      const studentBulkIndex = bulkUploadStudentList.findIndex(student => student.uuid  === updatedData.uuid );

      if (studentIndex !== -1 && studentBulkIndex !== -1) {
        // Create a new copy of the studentList array with the updated data
        const updatedStudentList = [...studentList];
        updatedStudentList[studentIndex] = updatedData;

        const updatedBulkStudentList = [...bulkUploadStudentList];
        updatedBulkStudentList[studentBulkIndex] = updatedData;





        // Update the state with the modified studentList
        setStudentList(updatedStudentList);
        setBulkUploadStudentList(updatedBulkStudentList)
        console.log(updatedData,"updatedData")
        console.log(updatedStudentList,"updatedData.uuid")

        
      }
      onClose();

    // setLoading(true);
    // onClose();

    // var formdata = new FormData();
    // formdata.append("excel_file", selectExcel);
    // formdata.append("uploaded_by",updatedBy);
    // console.log(selectExcel,"onSingleEditBulkUploadBulkUploadTest")
    // console.log(formdata,"InternonSingleEditBulkUpload")
    // var res = await singleInternBulkUploadValidate(formdata, internship_id);
    // if (res.status === 200) {
    //   setStudentList(res?.data?.data);
    //   setStudentValidatedData(res?.data);
    //   await setBulkModal(false);
    //   setbulkModalStudent(true);
    // } else {
    //   toast.error(res?.data1?.message?.split("'").join(""));
    //   setLoading(false);
    // }
  };

  const [bulkUploadStudentList, setBulkUploadStudentList] = useState([]);
  console.log(bulkUploadStudentList, "AFTER UPLOAD")
  const onBulkUpload = async () => {
    setLoading(true);

    var formdata = new FormData();
    formdata.append("excel_file", selectExcel);
    formdata.append("uploaded_by", updatedBy);
    console.log(selectExcel, "InternBulkUploadTest");
    console.log(formdata, "InternBulkUploadTest1");
    const response = await internBulkUploadValidate(formdata, internship_id);
    console.log(response, "resTest2")
    if (response.data.status === 1) {
      setBulkUploadStudentList(response?.data?.data);
      setStudentList((prevStudentList) => [...prevStudentList, ...response?.data?.data]);
      setStudentValidatedData(response?.data);
      await setBulkModal(false);
      setbulkModalStudent(true);
    } else if(response.data.status===0) {
      toast.error(response.data.message?.split("'").join(""));
      // toast.error(t("Please upload the right excel file."), {
      //   position: toast.POSITION.TOP_RIGHT,
      // });
      setLoading(false);
    }
  };

  const toolTipContent = (props) => {
    if (props) {
      let values = "";
      props.forEach((obj) => {
        Object.values(obj).forEach((val) => {
          values += `${val.replace(/_/g, " ")}<br/>`;
        });
      });
      return values;
    } else {
      return "";
    }
  };

  //onClose is only for bulk delete user
  const onClose = () => {
    setShowDelete(false);
    dispatch(updateStudentModal(false));
    dispatch(clearCache());
    setOpenBulkModal(false);
    clearFormDropdowns();
    

  }



  // handleDeleteclick is only used for bulk delete user 
  const handleDeleteClick = () => {

      // Update the studentList by filtering out the student with the given uuid
      const updatedStudentList = studentList.filter((item) => item.uuid !== deleteUUID);
      setStudentList(updatedStudentList);   
      const updatedBulkUploadStudentList = bulkUploadStudentList.filter((item) => item.uuid !== deleteUUID);
      setBulkUploadStudentList(updatedBulkUploadStudentList);
      setShowDelete(false);
    };


  //onDeleteExcelStudentData is to display content for the AlertModlal during delete popup
  const onDeleteExcelStudentsData = () => {
    return (
      <>
        <ModalText style={{ zIndex: 2 }}>
          {"Would you like to delete this intern ?"}
        </ModalText>
        <>
          <CenterButton>
            <Button secondary name="OK" onClick={handleDeleteClick} />
          </CenterButton>
        </>
      </>
    )

  }

  //to handle delete function and popup during bulk delete user 
  const onDeleteExcelStudents = (uuid) => {
    setShowDelete(true);
    setDeleteUUID(uuid);
    const studentToDelete = studentList.find((item) => item.uuid === uuid);
    setDeleteName(studentToDelete.full_name);

  };
  const onChangeStudentEdit = (value, row, index) => {
    resetBulk(row);
    setStudentIndex(index);
    dispatch(
      updateStudentModal({
        modalIsOpen: true,
        cache: row,
        mode: "excel",
        id: row.uuid,
        errors: row.errors,
      })
    );
  };
  function checkIsValid() {
    var isValid = true;
    if (bulkUploadStudentList?.length > 0) {
      bulkUploadStudentList?.forEach((e, i) => {
        if (e.is_valid !== 1) {
          isValid = false;
        }
      });
    } else {
      isValid = true;
    }
    return isValid;
  }
  const onUploadBulkUploadAfterValidate = async () => {
    setLoadingBulk(true);
    const ck = checkIsValid();
    // setBulkModalSuccessErrorMode2("success");
    // setUploadBulkModelSucessError(false);
    // setBulkModalSuccessError(true);

      // setLoadingBulk(false);
      // setLoading(false);
      if (ck) {
        setBulkModalSuccessErrorMode2("success");
        setUploadBulkModelSucessError(false);
        setBulkModalSuccessError(true);
        setLoadingBulk(false);
        setLoading(false);
        onClose();
      } else {
        setLoading(false);
  
        setLoadingBulk(false);
        setBulkModalSuccessErrorMode2("error");
        setUploadBulkModelSucessError(true);
        setBulkModalSuccessError(true);
        toast.error("Please check your data");
      }
    };
    const onBulkUploadAfterValidate = async () => {
      setLoadingBulk(true);
      const ck = checkIsValid();
      if (ck) {
        setBulkModalSuccessErrorMode("success");
        setBulkModalSuccessError(false);
        setUploadBulkModelSucessError(false);

        setLoadingBulk(false);
        setLoading(false);
      } else {
        setLoading(false);
  
        setLoadingBulk(false);
        setBulkModalSuccessErrorMode("error");
        setUploadBulkModelSucessError(true);
        setBulkModalSuccessError(true);
        toast.error("Please check your data");
      }
    };
    function onCloseStudentBulkEdit() {
      setbulkModalStudent(false);
    }
    const [isBulk, setIsbulk] = useState(false);
    const onBulkUploadAfterValidationSuccess = async () => {
      localStorage.setItem("upload_bulk", studentList);
      
        setBulkModalSuccessErrorMode("success");
        setBulkModalSuccessErrorLoading(false);
        setBulkModalSuccessError(false);
        setLoadingBulk(false);
        setIsbulk(true);
          setbulkModalStudent(false);
      // var res = await internBulkUpload(studentList,internship_id);
      // if (res.status === 201) {
      //   EventBus.dispatch("userAddEvent", { codeorg: orgCode });
      //   setBulkModalSuccessErrorMode("success");
      //   setBulkModalSuccessErrorLoading(false);
      //   setBulkModalSuccessError(false);
      //   setLoadingBulk(false);
      //   toast.success(res.data.data);
      //   setbulkModalStudent(false);
      // } else {
      //   if (res.status === 400) {
      //     setLoadingBulk(false);
      //     setBulkModalSuccessErrorLoading(false);
      //     setBulkModalSuccessErrorMode("error");
      //     setBulkModalSuccessError(true);
      //     toast.error(t("Please check your data"));
      //     setStudentList(res?.data1?.data);
      //     setStudentValidatedData(res?.data1);
      //     setLoading(false);
      //   }
      // }
    };

    
    localStorage.setItem("bulk-add", JSON.stringify(studentList));
    console.log(studentList, "INTERN")
    const savedAddIntern = localStorage.getItem("add-intern");
    const addedIntern = JSON.parse(savedAddIntern);
    console.log(addedIntern, "INTERN YOOO")

    let responseData;
    const savedAddIntern2 = localStorage.getItem("add-intern");
    const addedInterns = savedAddIntern2 ? JSON.parse(savedAddIntern2) : [];

// Check if there are any interns saved previously and add them to addedInterns
    if (responseData) {
      addedInterns.push(responseData);
    }
    console.log(addedInterns, "ARRAY OF SINGLE")
    
    const [internshipAddId, setInternshipAddId] = useState();
    const [error, setError] = useState(false);
    const createInternship = async ()=>{
      try {
        // Step 1: Create the Internship
        const { data, res } = await createNewIntern({
          ...DirectHiringData,
          documents: DirectHiringData.documents.filter((x) => x.doc_type !== "All"),
          collection_radio_type: DirectHiringData.collection_radio_type
            ? DirectHiringData.collection_radio_type
            : null,
          collection_date: DirectHiringData.collection_date
            ? DirectHiringData.collection_date
            : null,
          org_code: org_code,
          is_activate: true,
        });
    
        if (data && data.status === 1 ) {
          setLoading(true);
          const internshipId = await data?.data;
          await setLoading(true);
          await setInternshipAddId(internshipId);
    
          // Step 2: Add the Intern
          if(isBulk ===true && isSingle ===true){
            console.log("Bulk&Single Saving")
            studentList.forEach((intern) => {
              intern.intern_email = intern.email
              intern.internship = internshipId
            });
            console.log(studentList,"studentList.intern_email")
            const { data: addInternResponse } = await internBulkUpload(studentList, internshipId);
    
            if (addInternResponse) {
              toast.success(t("Internship form submitted"), {
                position: toast.POSITION.TOP_RIGHT,
              });
              navigate("/internships");
            } else {
              setError(true);
              toast.error(t("Failed to add the intern"), {
                position: toast.POSITION.TOP_RIGHT,
              });
            }

          }else{
            if(isBulk){
              console.log("Form bulk intern save ongoing")
              studentList.forEach((intern) => {
                intern.intern_email = intern.email
                intern.internship = internshipId
              });
              console.log(studentList,"studentList.intern_email")
              const { data: addInternResponse } = await internBulkUpload(studentList, internshipId);
      
              if (addInternResponse) {
                toast.success(t("Internship form submitted"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/internships");
              } else {
                setError(true);
                toast.error(t("Failed to add the intern"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
            }
            if(isSingle){
              
                if(studentList?.length > 1){
                  studentList.forEach((intern) => {
                    intern.intern_email = intern.email
                    intern.internship = internshipId
                  });
                  const { data: singleResponse } = await internBulkUpload(studentList, internshipId);
              
                  // Check if the API call was successful
                  if (singleResponse) {
                      toast.success(t("Internship form submitted"), {
                          position: toast.POSITION.TOP_RIGHT,
                      });
                       navigate("/internships");
                  } else {
                      setError(true);
                      toast.error(t("Failed to add the intern"), {
                          position: toast.POSITION.TOP_RIGHT,
                      });
                  }
                } else if(studentList?.length === 1){
                  addedIntern.internship=internshipId;
              const { data: singleResponse } = await addNewIntern(addedIntern, internshipId);
      
              if (singleResponse) {
                toast.success(t("Internship form submitted"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
                navigate("/internships");
              } else {
                setError(true);
                toast.error(t("Failed to add the intern"), {
                  position: toast.POSITION.TOP_RIGHT,
                });
              }
                }
            // }
              
              

            }
          }
          
        } else {
          setError(true);
          toast.error(t("Please check your data"), {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      } catch (error) {
        console.error("An error occurred:", error);
        // Handle error appropriately (e.g., show an error message).
      }
    }
    console.log(requestData, internshipAddId+ "TO SEND")
    const addInternAfterInternship = async (requestData, internId) =>{
         const { data } = await addNewIntern(requestData,internId);
          if (data) {
            toast.success(t("Internship form submitted"), {
              position: toast.POSITION.TOP_RIGHT,
              
            });
    }
    }

    const occupationList = [
      {value:1,label:"Professionals"}
    ]

    const raceList = [
      {value:1,label:"Bumiputera Sabah"},
      {value:2,label:"Bumiputera Sarawak"},
      {value:3,label:"Chinese"},
      {value:4,label:"Indian"},
      {value:5,label:"Malay"},
      {value:6,label:"Others"},
    ]

    const vulnerableGroup =[
      {value:1, label:"Youth"},
      {value:2, label:"OKU"},
      {value:3, label: "Not Applicable"}
    ]

  const courseList = [
    { value: 1, label: "Generic programmes and qualifications not further defined" },
    { value: 2, label: "Basic programmes and qualifications" },
    { value: 3, label: "Literacy and numeracy" },
    { value: 4, label: "Personal skills" },
    { value: 5, label: "Generic programmes and qualifications not elsewhere classified" },
    { value: 6, label: "Education not further defined" },
    { value: 7, label: "Education science" },
    { value: 8, label: "Training for pre-school teachers" },
    { value: 9, label: "Educator training without subject specialisation" },
    { value: 10, label: "Educator training with subject specialisation" },
    { value: 11, label: "Programmes and qualifications involving education not elsewhere classified" },
    { value: 12, label: "Inter-disciplinary programmes and qualifications involving education" },
    { value: 13, label: "Arts and humanities not further defined" },
    { value: 14, label: "Arts not further defined" },
    { value: 15, label: "Audio-visual techniques and media production" },
    { value: 16, label: "Fashion, interior and industrial design" },
    { value: 17, label: "Fine arts" },
    { value: 18, label: "Craft skills" },
    { value: 19, label: "Music and performing arts" },
    { value: 20, label: "Film, television and screen studies" },
    { value: 21, label: "Arts not elsewhere classified" },
    { value: 22, label: "Humanities (except languages) not further defined" },
    { value: 23, label: "Religion and theology" },
    { value: 24, label: "History and archaeology" },
    { value: 25, label: "Philosophy and ethics" },
    { value: 26, label: "Islamic studies" },
    { value: 27, label: "Humanities (except languages) not elsewhere classified" },
    { value: 28, label: "Languages not further defined" },
    { value: 29, label: "Language acquisition" },
    { value: 30, label: "Literature and linguistics" },
    { value: 31, label: "National language" },
    { value: 32, label: "Languages not elsewhere classified" },
    { value: 33, label: "Inter-disciplinary programmes and qualifications involving arts and humanities" },
    { value: 34, label: "Arts and humanities not elsewhere classified" },
    { value: 35, label: "Social sciences, journalism and information not further defined" },
    { value: 36, label: "Social and behavioural sciences not further defined" },
    { value: 37, label: "Economics" },
    { value: 38, label: "Social Sciences and Public Policy" },
    { value: 39, label: "Psychology" },
    { value: 40, label: "Sociology and cultural studies" },
    { value: 41, label: "Social and behavioural sciences not elsewhere classified" },
    { value: 42, label: "Journalism and information not further defined" },
    { value: 43, label: "Journalism and reporting" },
    { value: 44, label: "Library, information and archival studies" },
    { value: 45, label: "Media and communication" },
    { value: 46, label: "Journalism, information and communication not elsewhere classified" },
    { value: 47, label: "Inter-disciplinary programmes and qualifications involving social sciences, journalism and information" },
    { value: 48, label: "Social sciences, journalism and information not elsewhere classified" },
    { value: 49, label: "Business, administration and law not further defined" },
    { value: 50, label: "Business and administration not further defined" },
    { value: 51, label: "Accounting, auditing and taxation" },
    { value: 52, label: "Finance, banking and insurance" },
    { value: 53, label: "Islamic banking and finance" },
    { value: 54, label: "Management and administration" },
    { value: 55, label: "Marketing and advertising" },
    { value: 56, label: "Secretarial and office studies" },
    { value: 57, label: "Work skills" },
    { value: 58, label: "Business and administration not elsewhere classified" },
    { value: 59, label: "Law" },
    { value: 60, label: "Inter-disciplinary programmes and qualifications involving business, administration and law" },
    { value: 61, label: "Business, administration and law not elsewhere classified" },
    { value: 62, label: "Natural sciences, mathematics and statistics not further defined" },
    { value: 63, label: "Biological and related sciences not further defined" },
    { value: 64, label: "Biology" },
    { value: 65, label: "Biochemistry" },
    { value: 66, label: "Biological and related sciences not elsewhere classified" },
    { value: 67, label: "Environment not further defined" },
    { value: 68, label: "Environmental sciences" },
    { value: 69, label: "Natural environments and wildlife" },
    { value: 70, label: "Environment not elsewhere classified" },
    { value: 71, label: "Physical sciences not further defined" },
    { value: 72, label: "Chemistry" },
    { value: 73, label: "Earth sciences" },
    { value: 74, label: "Physics" },
    { value: 75, label: "Physical sciences not elsewhere classified" },
    { value: 76, label: "Mathematics and statistics not further defined" },
    { value: 77, label: "Mathematics" },
    { value: 78, label: "Statistics" },
    { value: 79, label: "Inter-disciplinary programmes and qualifications involving natural sciences, mathematics and statistics" },
    { value: 80, label: "Natural sciences, mathematics and statistics not elsewhere classified" },
    { value: 81, label: "Information and communication technologies (ICTs) not further defined" },
    { value: 82, label: "Information technology and information system" },
    { value: 83, label: "Software engineering" },
    { value: 84, label: "Computer science" },
    { value: 85, label: "Computer use" },
    { value: 86, label: "Information and communication technologies (ICTs) not elsewhere classified" },
    { value: 87, label: "Inter-disciplinary programmes and qualifications involving information and" },
    { value: 88, label: "communication technologies" },
    { value: 89, label: "Engineering, manufacturing and construction not further defined" },
    { value: 90, label: "Engineering and engineering trades not further defined" },
    { value: 91, label: "Chemical engineering and processes" },
    { value: 92, label: "Electricity and energy" },
    { value: 93, label: "Electronics and automation" },
    { value: 94, label: "Mechanics and metal trades" },
    { value: 95, label: "Motor vehicles, ships and aircraft" },
    { value: 96, label: "Civil engineering" },
    { value: 97, label: "Environmental protection technology" },
    { value: 98, label: "Engineering and engineering trades not elsewhere classified" },
    { value: 99, label: "Manufacturing and processing not further defined" },
    { value: 100, label: "Food processing" },
    { value: 101, label: "Materials (glass, paper, plastic and wood)" },
    { value: 102, label: "Textiles (clothes, footwear and leather)" },
    { value: 103, label: "Mining and extraction" },
    { value: 104, label: "Manufacturing and processing not elsewhere classified" },
    { value: 105, label: "Architecture, surveying and town planning not further defined" },
    { value: 106, label: "Architecture" },
    { value: 107, label: "Urban and regional planning" },
    { value: 108, label: "Building and construction" },
    { value: 109, label: "Surveying" },
    { value: 110, label: "Engineering technology" },
    { value: 111, label: "Inter-disciplinary programmes and" },
    { value: 112, label: "qualifications involving engineering, manufacturing and construction" },
    { value: 113, label: "Engineering, manufacturing and construction not elsewhere classified" },
    { value: 114, label: "Agriculture, forestry, fisheries and veterinary not further defined" },
    { value: 115, label: "Agriculture not further defined" },
    { value: 116, label: "Crop and livestock production" },
    { value: 117, label: "Horticulture" },
    { value: 118, label: "Agriculture not elsewhere classified" },
    { value: 119, label: "Forestry" },
    { value: 120, label: "Fisheries" },
    { value: 121, label: "Veterinary" },
    { value: 122, label: "Inter-disciplinary programmes and qualifications involving agriculture, forestry, fisheries and veterinary" },
    { value: 123, label: "Agriculture, forestry, fisheries and veterinary not elsewhere classified" },
    { value: 124, label: "Health and welfare not further defined" },
    { value: 125, label: "Health not further defined" },
    { value: 126, label: "Dental studies" },
    { value: 127, label: "Medicine" },
    { value: 128, label: "Nursing, midwifery and medical assistance" },
    { value: 129, label: "Medical diagnostic and treatment technology" },
    { value: 130, label: "Therapy and rehabilitation" },
    { value: 131, label: "Pharmacy" },
    { value: 132, label: "Traditional and complementary medicine and therapy" },
    { value: 133, label: "Health not elsewhere classified" },
    { value: 134, label: "Welfare not further defined" },
    { value: 135, label: "Care of elderly and of disabled adults" },
    { value: 136, label: "Child care and youth services" },
    { value: 137, label: "Social work and counselling" },
    { value: 138, label: "Welfare not elsewhere classified" },
    { value: 139, label: "Inter-disciplinary programmes and qualifications involving health and welfare" },
    { value: 140, label: "Health and welfare not elsewhere classified" },
    { value: 141, label: "Services not further defined" },
    { value: 142, label: "Personal services not further defined" },
    { value: 143, label: "Domestic services" },
    { value: 144, label: "Hair and beauty services" },
    { value: 145, label: "Hotel, restaurants and catering" },
    { value: 146, label: "Sports" },
    { value: 147, label: "Travel, tourism and leisure" },
    { value: 148, label: "Personal services not elsewhere classified" },
    { value: 149, label: "Hygiene and occupational health services not further defined" },
    { value: 150, label: "Community sanitation" },
    { value: 151, label: "Occupational health and safety" },
    { value: 152, label: "Hygiene and occupational health services not elsewhere classified" },
    { value: 153, label: "Security services not further defined" },
    { value: 154, label: "Military and defence" },
    { value: 155, label: "Protection of persons and property" },
    { value: 156, label: "Security services not elsewhere classified" },
    { value: 157, label: "Transport services" },
    { value: 158, label: "Inter-disciplinary programmes and qualifications involving services" },
    { value: 159, label: "Services not elsewhere classified" },
    { value: 160, label: "Field unknown" }
  ]

  const getCurrentYear = () => {
    return new Date().getFullYear();
  };
  
  const companyName = company.name;

  // Function to generate the download filename
  // const generateFileName = (companyName) => {
  //   const year = getCurrentYear();
  //   const companyNameWIthUnderScore = companyName.replace(/\s+/g, '_');
  //   return `Intern_Details_${companyNameWIthUnderScore}_${year}.xlsx`;
  // };
  
  const generateFileName = (companyName) => {
    // Check if companyName is provided and replace spaces with underscores
    if (!companyName) {
      return ''; // Return empty string if companyName is not provided
    }
    const companyNameWIthUnderScore = companyName.replace(/\s+/g, '_');
    const year = getCurrentYear();
    return `Intern_Details_${companyNameWIthUnderScore}_${year}.xlsx`;
  };
  
  const fileName = generateFileName(companyName);

    const onSubmit = () =>{
      if (typeOriginBulk !== "completed"){
        if(error !== true){
          createInternship();
          setLoading(true)
        }else{
          toast.error(t("Failed to create internship"), {
            position: toast.POSITION.TOP_RIGHT,
            
          });
        }
      }else{
        navigate("/internship/create-internship/completion-of-internship", {state: {typeOriginBulk,internshipID: internshipAddId, isBulk, isSingle}})
      }
    }
    const contentSuccessError = () => {
      return (
        <>
        {console.log(studentList.length,"studentList.length")}
          <ModalHeaderTextThree>
            {bulkModalSuccessErrorMode2 === "error" ?  ( "Please correct invalid data"):("Would you like to add total of " + studentList?.length +" intern to Company ?" )}
          </ModalHeaderTextThree>
          <ComponentFile/>
          <Row>
            {bulkModalSuccessErrorLoading ? (
              <Loader />
            ) : bulkModalSuccessErrorMode2 === "error" ? (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  secondary
                  color="#FFFFFF"
                  name="Ok"
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onCloseuploadBulkSuccessError}
                ></Button>
              </Col>
            ) : (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  width="100px"
                  margin="40px 0px 30px 0px"
                  name={t("Upload")}
                  onClick={() => {
                    setBulkModalSuccessErrorLoading(true);
                    onBulkUploadAfterValidationSuccess();
                  }}
                  type="button"
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onCloseuploadBulkSuccessError}
                ></Button>
              </Col>
            )}
          </Row>
        </>
      );
    };
    //Remove later
    const addOrEditStudentContent = ({cache,fromEdit,errors }) => {
      console.log("addOrEditStudentContent0", cache);
      return (
        <>
          <form>
        <TitleSection>
            <TitleDescription>Intern Personal Information</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <CardContainer>
            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Full Name*</FormLabel>
                  <Controller
                    name="full_name"
                    control={controlBulk}
                    defaultValue={cache?.full_name}
                    render={({ field }) => (
                      <>
                        <Input
                          // className="w-100"
                          disabled={fromEdit === true ? false : true}
                          placeholder={"Enter first name"}
                          {...field}
                        />
                      </>

                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.full_name && <ErrorField err={error.full_name} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
                <Col md="4">
                  <FormLabel>Email Address*</FormLabel>
                  <Controller
                    name="email"
                    control={controlBulk}
                    defaultValue={cache?.email}
                    render={({ field }) => (
                      <>
                        <Input
                          {...field}
                          placeholder="Enter email address"
                          change={(val) => {
                            const updatedErrors = errors.filter((error) => !error.email);
                            dispatch(updateErrors(updatedErrors));}}
                        />
                      </>
                    )} />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.email && <ErrorField err={error.email} />}
                        {/* Add more fields as needed */}
                        
                      </div>
                    ))
                  ) : null}
                  {Array.isArray(localErrors) ? (
                    localErrors.map((error, index) => (
                      <div key={index}>
                        {error.email && <ErrorField err={error.email} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                  {console.log(localErrors,"emailerrorsdd")}
                </Col>
                <Col md="4">
                  <FormLabel>Gender*</FormLabel>
                  <SelectorWrapper
                    style={{ display: "flex", paddingLeft: "20px" }}
                    className="w-100 mt-4"
                  >
                    <Controller
                      name="gender"
                      control={controlBulk}
                      defaultValue={cache?.gender}
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error }, }) => (
                        <>
                          <input
                            type="radio"
                            id="gender"
                            name="gender"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="male"
                            checked={value === "male" || value === "Male"}
                          />
                          <label htmlFor="gender">{"Male"}</label>
                          <input
                            type="radio"
                            id="secondarygender"
                            name="gender"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="female"
                            style={{ marginLeft: 20 }}
                            checked={value === "female" || value === "Female"}
                          />
                          <label htmlFor="secondarygender">{"Female"}</label>
                        </>

                      )}
                    />
                  </SelectorWrapper>
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.gender && <ErrorField err={error.gender} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
              </Row>
            </GroupWrapper>

            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Identification*</FormLabel>
                  <div style={{ paddingLeft: "15px" }}>
                    <Controller
                      control={controlBulk}
                      name="id_type"
                      defaultValue={cache?.id_type}
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <>
                          <div style={{ display: "flex", flexDirection: "row", marginBottom: 5 }}>
                            <input
                              type="radio"
                              id="identification"
                              name="id_type"
                              onChange={onChange}
                              onBlur={onBlur}
                              value="1"
                              checked={value == "1"}
                            />
                            <label htmlFor="identification">{"IC"}</label>
                            <input
                              type="radio"
                              id="secondaryidentification"
                              name="id_type"
                              onChange={onChange}
                              onBlur={onBlur}
                              value="0"
                              style={{ marginLeft: 60 }}
                              checked={value == "0"}
                            />
                            <label style={{ fontSize: 14 }}>{"Passport"}</label>
                          </div>
                        </>
                      )}
                    />
                  </div>
                  <Row>
                    <Col md="12">
                      <Controller
                        name="id_number"
                        control={controlBulk}
                        defaultValue={cache?.id_number}
                        render={({ field }) => (
                          <Input

                            placeholder={"Enter Identification Number"}
                            {...field}
                            maxLength={formVal.id_type == 1 ? 14 : 16}
                            change={(val) => {
                              const updatedErrors = errors.filter((error) => !error.id_number);
                              inputChange(val)
                              dispatch(updateErrors(updatedErrors));}}
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.id_number && <ErrorField err={error.id_number} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
                <Col md="4">
                  <FormLabel>Mobile No*</FormLabel>
                  <Controller
                    name="mobile_no"
                    control={controlBulk}
                    defaultValue={cache?.mobile_no}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter mobile no"
                        change={(val) => {
                          const updatedErrors = errors.filter((error) => !error.mobile_no);
                          dispatch(updateErrors(updatedErrors));}} />
                    )} />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.mobile && <ErrorField err={error.mobile} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
                <Col md="4">
                  <FormLabel>Address 1*</FormLabel>
                  <Controller
                    name="address1"
                    control={controlBulk}
                    defaultValue={cache?.address_1}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter address 1" 
                        change={(val) => {
                          const updatedErrors = errors.filter((error) => !error.address_1);
                          dispatch(updateErrors(updatedErrors));}}/>
                    )} />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.address1 && <ErrorField err={error.address1} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
              </Row>
            </GroupWrapper>

            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Address 2</FormLabel>
                  <Controller
                    name="address2"
                    control={controlBulk}
                    defaultValue={cache?.address_2}
                    render={({ field }) => (
                      <Input
                        placeholder="Enter address 2"
                        change={(val) => {
                          dropdownChange("state", val);
                          const updatedErrors = errors.filter((error) => !error.address_2);
                          dispatch(updateErrors(updatedErrors));}}
                        {...field} />

                    )} />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.address2 && <ErrorField err={error.address2} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
                <Col md="4">
                  <FormLabel>State *</FormLabel>
                  <Controller
                    name="state"
                    control={controlBulk}
                    defaultValue={cache?.state}
                    render={({ field }) => {
                      const cachedValue = cache?.state;

                      // Define the value to be used when using the cache
                      const cacheValue = {
                        label: cachedValue,
                        value: states.find((state) => state.label === cachedValue)?.value, // You may need to adjust this based on your data structure
                      };

                      // Check if selectedState?.value is undefined and call dropdownChange
                      if (selectedState?.value === undefined) {
                        if (cacheValue.value !== undefined) {
                          dropdownChange("state", cacheValue);
                        }
                      }

                      return (
                        <Dropdown
                          search={true}
                          options={states}
                          placeholder={"Select State"}
                          val={
                            selectedState?.value === undefined
                              ? states.find((state) => state.label === cachedValue)?.value
                              : selectedState?.value
                          }
                          label={
                            selectedState?.label === undefined ? cachedValue : selectedState?.label
                          }
                          change={(val) => {
                            dropdownChange("state", val);
                            const updatedErrors = errors.filter((error) => !error.state);
                            dispatch(updateErrors(updatedErrors));}}
                          {...field}
                        />
                      );
                    }}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.state && <ErrorField err={error.state} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>

                <Col md="4">
                  <FormLabel>City *</FormLabel>
                  <Controller
                    name="city"
                    control={controlBulk}
                    defaultValue={cache?.city}
                    render={({ field }) => {
                      const cachedValue = cache?.city;

                      // Define the value to be used when using the cache
                      const cacheValue = {
                        label: cachedValue,
                        value: cachedValue, // You may need to adjust this based on your data structure
                      };

                      // Check if selectedCity?.value is undefined and call dropdownChange
                      if (selectedCity?.value === undefined) {
                        if (cacheValue.value !== undefined) {
                          dropdownChange("city", cacheValue);
                        }
                      }

                      return (
                        <Dropdown
                          bgGrey
                          search={true}
                          options={cities}
                          placeholder={"Select City"}
                          val={
                            selectedCity?.value === undefined
                              ? cities.find((city) => city.label === cachedValue)?.value
                              : selectedCity?.value
                          }
                          label={
                            selectedCity?.label === undefined ? cachedValue : selectedCity?.label
                          }
                          // disable={!formVal.state}
                          change={(val) => {
                            dropdownChange("city", val);
                            const updatedErrors = errors.filter((error) => !error.city);
                            dispatch(updateErrors(updatedErrors));}}
                          {...field}
                        />
                      );
                    }}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.city && <ErrorField err={error.city} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>



              </Row>
            </GroupWrapper>

                <GroupWrapper>
                  
                    <Row>
                    
                    <Col md="4">
                            <FormLabel>Postcode*</FormLabel>
                            <Controller
                                name="postal_code"
                                control={controlBulk}
                                defaultValue={cache?.postal_code}
                                render={({field}) =>(
                                    <Input
                                    placeholder="Enter postcode"
                                    {...field}
                                    change={(val) =>
                                      {
                                      const updatedErrors = errors.filter((error) => !error.postal_code);
                                      dispatch(updateErrors(updatedErrors)); 
                                    }}
                                    />
                                )}/>
                                {Array.isArray(errors) ? (
                                  errors.map((error, index) => (
                                    <div key={index}>
                                      {error.postcode && <ErrorField err={error.postcode} />}
                                      {/* Add more fields as needed */}
                                    </div>
                                  ))
                                ) : null}
                        </Col>
                       

                        <Col md="4">
                        <FormLabel>Race *</FormLabel>
                          <Controller 
                            name="race"
                            control={controlBulk}
                            defaultValue={cache?.race}
                            render={({field}) => {
                              const cachedValue = cache?.race;

                              // Define the value to be used when using the cache
                              const cacheValue = {
                                label: cachedValue,
                                value: raceList.find((race) => race.label === cachedValue)?.value, // You may need to adjust this based on your data structure
                              };
        
                              // Check if selectedEducationalInstitution?.value is undefined and call dropdownChange
                              if (selectedRace?.value === undefined) {
                                if (cacheValue.value !== undefined) {
                                  dropdownChange("race", cacheValue);
                                }
                                
                              }
        
                              return (
                                <Dropdown
                                  options={raceList}
                                  placeholder="Select ..."
                                  val={
                                    selectedRace?.value === undefined
                                      ? raceList.find((uni) => uni.label === cachedValue)?.value
                                      : selectedRace?.value
                                  }
                                  label={
                                    selectedRace?.label === undefined
                                      ? cachedValue
                                      : selectedRace?.label
                                  }
                                  change={(val) =>
                                    {dropdownChange("race", val);
                                    const updatedErrors = errors.filter((error) => !error.race);
                                    dispatch(updateErrors(updatedErrors)); 
                                  }}
                                  {...field}
                                />
                              );
                            }}
                          />
                          {Array.isArray(errors) ? (
                                errors.map((error, index) => (
                                  <div key={index}>
                                    {error.race && <ErrorField err={error.race} />}
                                    {/* Add more fields as needed */}
                                  </div>
                                ))
                              ) : null}
                        </Col>

                        <Col md="4">
                        <FormLabel>Vulnerable Group *</FormLabel>
                          <Controller 
                            name="vulnarable_group"
                            control={controlBulk}
                            defaultValue={cache?.vulnerable_group}
                            render={({field}) => {
                              const cachedValue = cache?.vulnerable_group;

                              // Define the value to be used when using the cache
                              const cacheValue = {
                                label: cachedValue,
                                value: vulnerableGroup.find((vulnarable) => vulnarable.label === cachedValue)?.value, // You may need to adjust this based on your data structure
                              };
        
                              // Check if selectedEducationalInstitution?.value is undefined and call dropdownChange
                              if (selectedVulnarableGroup?.value === undefined) {
                                if (cacheValue.value !== undefined) {
                                  dropdownChange("vulnerable_group", cacheValue);
                                }
                                
                              }
        
                              return (
                                <Dropdown
                                  options={vulnerableGroup}
                                  placeholder="Select ..."
                                  val={
                                    selectedVulnarableGroup?.value === undefined
                                      ? vulnerableGroup.find((vulnerable) => vulnerable.label === cachedValue)?.value
                                      : selectedVulnarableGroup?.value
                                  }
                                  label={
                                    selectedVulnarableGroup?.label === undefined
                                      ? cachedValue
                                      : selectedVulnarableGroup?.label
                                  }
                                  change={(val) =>
                                    {dropdownChange("vulnerable_group", val);
                                    const updatedErrors = errors.filter((error) => !error.vulnerable_group);
                                    dispatch(updateErrors(updatedErrors)); 
                                  }}
                                  {...field}
                                />
                              );
                            }}
                          />
                          {Array.isArray(errors) ? (
                                errors.map((error, index) => (
                                  <div key={index}>
                                    {error.vulnerable_group && <ErrorField err={error.vulnerable_group} />}
                                    {/* Add more fields as needed */}
                                  </div>
                                ))
                              ) : null}
                        </Col>
                    </Row>
                </GroupWrapper>
            </CardContainer>

          <TitleSection>
            <TitleDescription>Education Information</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <CardContainer>
            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Educational Institution*</FormLabel>
                  <Controller
                    name="educational_institution"
                    control={controlBulk}
                    defaultValue={cache?.educational_institution}
                    render={({ field }) => {
                      const cachedValue = cache?.educational_institution;

                      // Define the value to be used when using the cache
                      const cacheValue = {
                        label: cachedValue,
                        value: univeristies.find((educational_institution) => educational_institution.label === cachedValue)?.value, // You may need to adjust this based on your data structure
                      };

                      // Check if selectedEducationalInstitution?.value is undefined and call dropdownChange
                      if (selectedEducationalInstitution?.value === undefined) {
                        if (cacheValue.value !== undefined) {
                          dropdownChange("educational_institution", cacheValue);
                        }
                      }

                      return (
                        <Dropdown
                          options={univeristies}
                          placeholder="Select ..."
                          val={
                            selectedEducationalInstitution?.value === undefined
                              ? univeristies.find((uni) => uni.label === cachedValue)?.value
                              : selectedEducationalInstitution?.value
                          }
                          label={
                            selectedEducationalInstitution?.label === undefined
                              ? cachedValue
                              : selectedEducationalInstitution?.label
                          }
                          change={(val) =>
                           { dropdownChange("educational_institution", val)
                            const updatedErrors = errors.filter((error) => !error.cgpa);
                            dispatch(updateErrors(updatedErrors)); 
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.educational_institution && (
                          <ErrorField err={error.educational_institution} />
                        )}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>


                <Col md="4">
                  <FormLabel>CGPA*</FormLabel>
                  <Controller
                    name="cgpa"
                    control={controlBulk}
                    defaultValue={cache?.cgpa}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter CGPA"
                        change={(val) => {
                          const updatedErrors = errors.filter((error) => !error.cgpa);
                                dispatch(updateErrors(updatedErrors)); 
                        }}
                        
                      />
                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.cgpa && <ErrorField err={error.cgpa} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
                <Col md="4">
                  <FormLabel>Academic Qualifications *</FormLabel>
                  <Controller
                    name="academic_qualification"
                    control={controlBulk}
                    defaultValue={cache?.academic_qualification}
                    render={({ field }) => {
                      const cachedValue = cache?.academic_qualification;

                      // Define the value to be used when using the cache
                      const cacheValue = {
                        label: cachedValue,
                        value: academicQualificationList.find((academic_qualification) => academic_qualification.label === cachedValue)?.value, // You may need to adjust this based on your data structure
                      };

                      // Check if selectedEducationalInstitution?.value is undefined and call dropdownChange
                      if (selectedAcademicQualifications?.value === undefined) {
                        if (cacheValue.value !== undefined) {
                          dropdownChange("academic_qualification", cacheValue);
                        }
                      }

                      return (
                        <Dropdown
                          options={academicQualificationList}
                          placeholder="Select ..."
                          val={
                            selectedAcademicQualifications?.value === undefined
                              ? academicQualificationList.find((uni) => uni.label === cachedValue)?.value
                              : selectedAcademicQualifications?.value
                          }
                          label={
                            selectedAcademicQualifications?.label === undefined
                              ? cachedValue
                              : selectedAcademicQualifications?.label
                          }
                          change={(val) =>
                           { dropdownChange("academic_qualification", val)
                            const updatedErrors = errors.filter((error) => !error.cgpa);
                            dispatch(updateErrors(updatedErrors)); 
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.academic_qualification && <ErrorField err={error.academic_qualification} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
              </Row>
            </GroupWrapper>


            <GroupWrapper>
              <Row>
                <Col md="8">
                  <FormLabel>Course *</FormLabel>
                  <Controller
                    name="course"
                    control={controlBulk}
                    defaultValue={cache?.course}
                    render={({ field }) => {
                      const cachedValue = cache?.course;

                      // Define the value to be used when using the cache
                      const cacheValue = {
                        label: cachedValue,
                        value: courseList.find((course) => course.label === cachedValue)?.value, // You may need to adjust this based on your data structure
                      };

                      // Check if selectedEducationalInstitution?.value is undefined and call dropdownChange
                      if (selectedCourse?.value === undefined) {
                        if (cacheValue.value !== undefined) {
                          dropdownChange("course", cacheValue);
                        }
                      }

                      return (
                        <Dropdown
                          options={courseList}
                          placeholder="Select ..."
                          val={
                            selectedCourse?.value === undefined
                              ? courseList.find((course) => course.label === cachedValue)?.value
                              : selectedCourse?.value
                          }
                          label={
                            selectedCourse?.label === undefined
                              ? cachedValue
                              : selectedCourse?.label
                          }
                          change={(val) =>
                           { dropdownChange("course", val)
                            const updatedErrors = errors.filter((error) => !error.course);
                            dispatch(updateErrors(updatedErrors)); 
                          }}
                          {...field}
                        />
                      );
                    }}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.course && <ErrorField err={error.course} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
              </Row>
            </GroupWrapper>
          </CardContainer>

          <TitleSection>
            <TitleDescription>Internship Details</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <CardContainer>
            <GroupWrapper>
              <Row>
                <Col md="4">
                  <FormLabel>Internship Start Date*</FormLabel>
                  <Controller
                    name="expected_start_date"
                    control={controlBulk}
                    defaultValue={cache?.internship_start_date===""? null: cache?.internship_start_date}
                    render={({ field }) => (
                      <label style={{ width: "max-content" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              style={{ marginTop: "-5px", marginTop: "20px" }}
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                !formVal.expected_start_date 
                                  ? (cache?.internship_start_date===""? null: cache?.internship_start_date)
                                  : formVal.expected_start_date &&
                                  formVal.expected_start_date === "None"
                                    ? (cache?.internship_start_date===""? null: cache?.internship_start_date)
                                    : formVal.expected_start_date === ""
                                      ? (cache?.internship_start_date===""? null: cache?.internship_start_date)
                                      : new Date(formVal.expected_start_date)
                              }
                              fullWidth
                              onChange={(val) => {
                                dateChange("expected_start_date", val);

                                const updatedErrors = errors.filter((error) => !error.internship_start_date);
                                  dispatch(updateErrors(updatedErrors));
                              }}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        {/* <CalenderIconWrapper>
                                      <CalenderIcon />
                                  </CalenderIconWrapper> */}
                      </label>
                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.internship_start_date && <ErrorField err={error.internship_start_date} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>

                <Col md="4">
                  <FormLabel>Internship End Date*</FormLabel>
                  <Controller
                    name="expected_end_date"
                    control={controlBulk}
                    defaultValue={cache?.internship_end_date===""? null: cache?.internship_end_date}
                    render={({ field }) => (
                      
                      <label style={{ width: "max-content" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              style={{ marginTop: "-5px", marginTop: "20px" }}
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                !formVal.expected_end_date
                                  ? (cache?.internship_end_date===""? null: cache?.internship_end_date)
                                  : formVal.expected_end_date &&
                                  formVal.expected_end_date === "None"
                                    ? (cache?.internship_end_date===""? null: cache?.internship_end_date)
                                    : formVal.expected_end_date === ""
                                      ? (cache?.internship_end_date===""? null: cache?.internship_end_date)
                                      : new Date(formVal.expected_end_date)
                              }
                              fullWidth
                              onChange={(val) => {
                                dateChange("expected_end_date", val);
                                const updatedErrors = errors.filter((error) => !error.internship_end_date);
                                dispatch(updateErrors(updatedErrors));
                                // console.log(formVal.intern_dur_year);
                              }}
                              
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                        {/* <CalenderIconWrapper>
                                          <CalenderIcon />
                                      </CalenderIconWrapper> */}
                      </label>
                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.internship_end_date && <ErrorField err={error.internship_end_date} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                  
                </Col>
                <Col md={4}>
                  <FormLabel>Duration</FormLabel>
                  <Controller
                    name="duration"
                    control={controlBulk}
                    render={({ field }) => {
                      // Check if formVal is null
                      if (!formVal || !cache) {
                        return null; // Or handle the case where formVal or cache is null
                      }

                      const startDate = formVal.expected_start_date === "" ? cache.internship_start_date : formVal.expected_start_date;
                      const endDate = formVal.expected_end_date === "" ? cache.internship_end_date : formVal.expected_end_date;

                      // Calculate the duration in months
                      const durationWeeks = startDate && endDate
                        ? differenceInWeeks(new Date(endDate), new Date(startDate))
                        : null;

                      const durationDays = startDate && endDate
                        ? differenceInDays(new Date(endDate), new Date(startDate)) % 7
                        : null;

                      const calculateDuration = durationWeeks !== null
                        ? `${durationWeeks} weeks${durationDays > 0 ? ` ${durationDays} days` : ''}`
                        : null;

                      return (
                        <div>
                          {durationWeeks !== null ? (
                            <TitleDescription style={{
                              marginTop: '40px',
                              marginLeft: '10px',
                            }}>{calculateDuration}</TitleDescription>
                          ) : (
                            null
                          )}
                        </div>
                      );
                    }}
                  />
                </Col>


                <Col md="4">
                  <FormLabel>Date of Hiring*</FormLabel>
                  <Controller
                    name="date_of_hiring"
                    control={controlBulk}
                    defaultValue={cache?.date_of_hiring===""? null: cache?.date_of_hiring}
                    render={({ field }) => (
                      <label style={{ width: "max-content" }}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              style={{ marginTop: "-5px" }}
                              autoOk
                              variant="inline"
                              format="dd/MM/yyyy"
                              placeholder="dd/mm/yyyy"
                              views={["year", "month", "date"]}
                              value={
                                !formVal.date_of_hiring
                                  ? (cache?.date_of_hiring===""? null: cache?.date_of_hiring)
                                  : formVal.date_of_hiring &&
                                  formVal.date_of_hiring === "None"
                                    ? (cache?.date_of_hiring===""? null: cache?.date_of_hiring)
                                    : formVal.date_of_hiring === ""
                                      ? (cache?.date_of_hiring===""? null: cache?.date_of_hiring)
                                      : new Date(formVal.date_of_hiring)
                              }
                              onChange={(val) => {
                                dateChange("date_of_hiring", val);
                                const updatedErrors = errors.filter((error) => !error.date_of_hiring);
                                dispatch(updateErrors(updatedErrors)); 
                                // console.log(formVal.intern_dur_year);
                                
                              }}
                              
                              fullWidth
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>
                      </label>
                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.date_of_hiring && <ErrorField err={error.date_of_hiring} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
              </Row>
            </GroupWrapper>

          <TitleSection>
            <TitleDescription>Internship Expenses</TitleDescription>
            <hr style={{ opacity: 0.1, marginTop: '-10px' }} />
          </TitleSection>
          <GroupWrapper>
            <Row>
              <Col md="6">
                <FormLabel>Total Training Expenses*</FormLabel>
                <Controller
                  name="total_training_expenses"
                  control={controlBulk}
                  defaultValue={cache?.total_training_expenses}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter total training expenses"
                      change={(val) => {
                        const updatedErrors = errors.filter((error) => !error.total_training_expenses);
                              dispatch(updateErrors(updatedErrors)); 
                      }}
                      
                    />
                  )}
                />
                {Array.isArray(errors) ? (
                  errors.map((error, index) => (
                    <div key={index}>
                      {error.total_training_expenses && <ErrorField err={error.total_training_expenses} />}
                      {/* Add more fields as needed */}
                    </div>
                  ))
                ) : null}
              </Col>

              <Col md="6">
                <FormLabel>Total Meal Expenses*</FormLabel>
                <Controller
                  name="total_meal_expenses"
                  control={controlBulk}
                  defaultValue={cache?.total_meal_expenses}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter total meal expenses"
                      change={(val) => {
                        const updatedErrors = errors.filter((error) => !error.total_meal_expenses);
                              dispatch(updateErrors(updatedErrors)); 
                      }}
                      
                    />
                  )}
                />
                {Array.isArray(errors) ? (
                  errors.map((error, index) => (
                    <div key={index}>
                      {error.total_meal_expenses && <ErrorField err={error.total_meal_expenses} />}
                      {/* Add more fields as needed */}
                    </div>
                  ))
                ) : null}
              </Col>
            </Row>
            <Row>
              < Col md="6">
                <FormLabel>Total Accomodation Expenses*</FormLabel>
                <Controller
                  name="total_accomodation_expenses"
                  control={controlBulk}
                  defaultValue={cache?.total_accomodation_expenses}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter total accomodation expenses"
                      change={(val) => {
                        const updatedErrors = errors.filter((error) => !error.total_accomodation_expenses);
                              dispatch(updateErrors(updatedErrors)); 
                      }}
                      
                    />
                  )}
                />
                {Array.isArray(errors) ? (
                  errors.map((error, index) => (
                    <div key={index}>
                      {error.total_accomodation_expenses && <ErrorField err={error.total_accomodation_expenses} />}
                      {/* Add more fields as needed */}
                    </div>
                  ))
                ) : null}
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <FormLabel>Total Travelling Expenses*</FormLabel>
                <Controller
                  name="total_travelling_expenses"
                  control={controlBulk}
                  defaultValue={cache?.total_travelling_expenses}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter total travelling expenses"
                      change={(val) => {
                        const updatedErrors = errors.filter((error) => !error.total_travelling_expenses);
                              dispatch(updateErrors(updatedErrors)); 
                      }}
                      
                    />
                  )}
                />
                {Array.isArray(errors) ? (
                  errors.map((error, index) => (
                    <div key={index}>
                      {error.total_travelling_expenses && <ErrorField err={error.total_travelling_expenses} />}
                      {/* Add more fields as needed */}
                    </div>
                  ))
                ) : null}
              </Col>
              <Col md="8">
                <FormLabel>Total Digital And Communication Expenses*</FormLabel>
                <Controller
                  name="total_digital_communication_expenses"
                  control={controlBulk}
                  defaultValue={cache?.total_digital_communication_expenses}
                  render={({ field }) => (
                    <Input
                      {...field}
                      placeholder="Enter total digital and communications expenses"
                      change={(val) => {
                        const updatedErrors = errors.filter((error) => !error.total_digital_communication_expenses);
                              dispatch(updateErrors(updatedErrors)); 
                      }}
                      
                    />
                  )}
                />
                {Array.isArray(errors) ? (
                  errors.map((error, index) => (
                    <div key={index}>
                      {error.total_digital_communication_expenses && <ErrorField err={error.total_digital_communication_expenses} />}
                      {/* Add more fields as needed */}
                    </div>
                  ))
                ) : null}
              </Col>
            </Row>
            <Row>
            <Col md="6">
                  <FormLabel>Monthly Allowance*</FormLabel>
                  <Controller
                    name="internship_allowance_per_month"
                    control={controlBulk}
                    defaultValue={cache?.internship_allowance_per_month}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter monthly allowance"
                        change={(val) => {
                          const updatedErrors = errors.filter((error) => !error.internship_allowance_per_month);
                                dispatch(updateErrors(updatedErrors)); 
                        }}
                        
                      />
                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.internship_allowance_per_month && <ErrorField err={error.internship_allowance_per_month} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>

                <Col md="6">
                  <FormLabel>Total Allowance in Programme*</FormLabel>
                  <Controller
                    name="total_internship_allowance_in_programme"
                    control={controlBulk}
                    defaultValue={cache?.total_allowance_in_programme}
                    render={({ field }) => (
                      <Input
                        {...field}
                        placeholder="Enter total allowance"
                        change={(val) => {
                          const updatedErrors = errors.filter((error) => !error.total_internship_allowance_in_programme);
                                dispatch(updateErrors(updatedErrors)); 
                        }}
                        
                      />
                    )}
                  />
                  {Array.isArray(errors) ? (
                    errors.map((error, index) => (
                      <div key={index}>
                        {error.total_allowance_in_programme && <ErrorField err={error.total_allowance_in_programme} />}
                        {/* Add more fields as needed */}
                      </div>
                    ))
                  ) : null}
                </Col>
              </Row>
            </GroupWrapper>
          </CardContainer>
        </form>
        <Row>

          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="40px 0px 30px 0px"
              name={t("Submit")}
              onClick={() => { validateEditedData() }}
            />
            <Button
              secondary
              color="#FFFFFF"
              name={t("Cancel")}
              style={{
                margin: "40px 5px 30px 10px",
              }}
              variant="outlined"
              onClick={onClose}
            ></Button>
          </Col>

        </Row>
      </>
    );
  };




    //  const reUploadExcel =()=>{
    //    setStudentList([]);
    //    setBulkUploadStudentList([]);
    //    setIsUpload(false);
    //    resetBulk({ file_path: "No file chosen", file_size: "", file: {} })
    //  }

    
    const reUploadExcel = () => {
      console.log(studentList,"StudentList1");
      console.log(bulkUploadStudentList,"BulkUploadStudentList1")
      // Filter out students from studentList that are present in bulkUploadStudentList
      const filteredStudentList = studentList.filter(existingStudent => {
        // Assuming email is a unique identifier
        return !bulkUploadStudentList.some(uploadStudent => uploadStudent.email === existingStudent.email);
      });
    
      setStudentList(filteredStudentList);
      setBulkUploadStudentList([]);
      setIsUpload(false);
      resetBulk({ file_path: "No file chosen", file_size: "", file: {} });
    };
    
    
    


    const [isUpload,setIsUpload] = useState(false);

  const uploadBulkModal = () => {

    return (
      <div>
          {isUpload === true? 
          <>
           <DescriptionWrapper>
          <Description>Re-Uploading will override the previous upload. Would you like to re-upload?</Description>
          </DescriptionWrapper>
          <ButtonWrapper>
                <Button name={"Re-Upload"}  onClick={()=>reUploadExcel()}/>
                <Button name={"Cancel"} secondary onClick={closeModal}/>
            </ButtonWrapper>
            </>
          
          
          :
          <>
           <DescriptionWrapper>
          <Description>Please download the template and fill in the interns details. Ensure all details are accurate before uploading.</Description>
          </DescriptionWrapper>
          <UploadSection>
          <UploadFileWrapper data-tip data-for="csv">
            <input
              name="excel_file"
              onChange={uploadFile}
              accept=".xlsx"
              type="file"
              ref={ref}
            />
            <UploadFileText>Choose File</UploadFileText>
          </UploadFileWrapper>
          {formValBulk?.file_path?.length > 0 ? (
                <>
                  <SkillCard
                    key={formValBulk?.file_path}
                    delete={() => clearFile()}
                    name={formValBulk?.file_path}
                  />
                </>
              ) : (
                ""
              )}
          </UploadSection>
            <DownloadLinkWrapper>
            <DownloadLink
                href={InternBulk}
                download={fileName}
                className="downloadTemplate"
              >
                <DownloadTemplate>{"Download Template"}</DownloadTemplate>
            </DownloadLink>
            </DownloadLinkWrapper>
            <ButtonWrapper>
                <Button name={"Upload"} disabled={isUpload === true? true:false} onClick={()=>onBulkUpload()}/>
                <Button name={"Cancel"} secondary onClick={closeModal}/>
            </ButtonWrapper>
          </>
         }
        
        
        </div>
      )
    }

  const PaymentContent = () => {
    return (
      <div>
        <ModalSection>
          <ModalLabel>Please proceed to payment gateway</ModalLabel>
          <ModalLabel2>You are about to upload a MySIP Direct Hiring Internship with 20 interns.</ModalLabel2>
        </ModalSection>
        <CenterButton>
          <Button name="Proceed" />
        </CenterButton>
      </div>
    )
  }
  const conditionalRowStyles = [
    {
      when: (row) => row.is_valid,
      style: {
        backgroundColor: "transparent",
        userSelect: "#FF2C2C",
      },
    },
    {
      when: (row) => row.is_valid === 0,
      style: {
        backgroundColor: "#FF2C2C",
        userSelect: "transparent",
      },
    },
  ];
  const contentStudents = () => {
    return (
      <>
        <style type="text/css">
          {`
    .form-check-label > label {
      padding-left:10px
    }
    ::-webkit-scrollbar {
      width: 12px; /* Width of the scrollbar */
    }
    
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #888;
    }
    
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #aaa; /* Color of the scrollbar handle */
      border-radius: 10px; /* Roundness of the scrollbar handle */
    }
    
    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1; /* Color of the scrollbar track */
    }
    
    /* Add shadows to both ends of the scrollbar */
    ::-webkit-scrollbar-corner {
      background: transparent; /* Background of scrollbar corner */
    }
    `}
          </style>
          <ModalHeaderText>{t("Upload File")}</ModalHeaderText>
          <ModalHeaderTextTwo>
            <div>{t("Upload a file with Intern details.")}</div>
            <div style={{ display: "flex" }}>
              <div style={{ fontSize: "15px" }}>
                Total:{" "}
                <span style={{ color: "#D04C8D" }}>{bulkUploadStudentList?.length}</span>
              </div>
              <div style={{ fontSize: "15px", paddingLeft: "23px" }}>
                Valid:{" "}
                <span style={{ color: "#00E332" }}>
                  {bulkUploadStudentList
                    ? bulkUploadStudentList?.filter((e) => e.is_valid === 1).length
                    : 0}
                </span>
              </div>
              <div style={{ fontSize: "15px", paddingLeft: "23px" }}>
                Invalid:{" "}
                <span style={{ color: "#FF0000" }}>
                  {bulkUploadStudentList
                    ? bulkUploadStudentList?.filter((e) => e.is_valid === 0).length
                    : 0}
                </span>
              </div>
              <div style={{ paddingLeft: "23px" }}>
                <div key={`default-invalid`} className="mb-3">
                  <Form.Check
                    className="form-check-label"
                    type={"checkbox"}
                    checked={isInvalid}
                    id={`default-invalid`}
                    label={`Show Invalid Data Only`}
                    style={{
                      fontSize: "15px",
                      alignItems: "center",
                      display: "flex",
                    }}
                    onClick={(e) => {
                      setIsInvalid(e.target.checked);
                    }}
                  />
                </div>
              </div>
            </div>
          </ModalHeaderTextTwo>
          {/* <ComponentFile> */}
        <DataTableWrapper>
          <DataTable
            style={{ minWidth: "100%" }}
            columns={columns}
            data={isInvalid ? bulkUploadStudentList.filter((e) => e.is_valid === 0) : bulkUploadStudentList}
            highlightOnHover
            pagination
            paginationPerPage={5}
            paginationRowsPerPageOptions={[5, 15, 25, 50]}
            paginationComponentOptions={{
              rowsPerPageText: "Records per page:",
              rangeSeparatorText: "out of",
            }}
            conditionalRowStyles={conditionalRowStyles}
          />
        </DataTableWrapper>
  
          {/* </ComponentFile> */}
          <Row>
            {loadingBulk ? (
              <Loader />
            ) : (
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  width="100px"
                  disabled={ bulkUploadStudentList?.filter((e) => e.is_valid === 0).length>0? true:false}
                  margin="40px 0px 30px 0px"
                  name="Upload"
                  onClick={onUploadBulkUploadAfterValidate}
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name="Cancel"
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={() => {
                    setbulkModalStudent(false);
                    reUploadExcel();
                    setOpenBulkModal(false);
                  }}
                ></Button>
              </Col>
            )}
          </Row>
          {/* </Form> */}
        </>
      );
    };
  
    return(
      <>
      {isOpen === true ? (
        <TermsAndConditionModal
          intershipName={"Internship02"}
          companyName={"Sony"}
          setOpen={setOpen}
          isOpen={isOpen}
          onInternshipSubmit={onInternshipSubmit}
        />
      ) : (
        ""
      )}
      <MainLayout>
        <div className="breadcrumb">
          <p onClick={() => navigate("/internships")} style={{ cursor: "pointer" }}>
            {"Internship"}
          </p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "-10px" }}
          />
          <p style={{ color: "#D04C8D" }}>{"Choose Internship"}</p>
          <img
            src={rightarrow}
            alt="img"
            style={{ height: "14px", marginTop: "-10px" }}
          />
          <p style={{ color: "#D04C8D" }}>{"Add New MySIP Direct Hiring"}</p>
        </div>
        <NewInternPageWrapper>
          <SectionWrapper>
            <HeaderText>
              Add a New MySIP Direct Hiring Internship
              <PaginationDivider step={2} />
            </HeaderText>
            <HeaderText>Intern Detail Upload</HeaderText>
            <Description>Upload the details of your hired interns. Evaluation form to both companies and interns will be sent upon receiving details of interns. Posting will be reviewed by TalentCorp before issuance of LOE.</Description>
            <ButtonWrapper spaceBetween>
              <ButtonWrapper alignStart>
                <Button name="Add Intern Detail" secondary onClick={openInternModal} />
                <Button name="Add Bulk Intern Detail" secondary style={{ width: "240px" }} onClick={openBulkInternModal} />
              </ButtonWrapper>
              {/* <ButtonWrapper alignEnd>
                  <ReactTooltip effect="solid"/>
                  <IconImage data-tip="Send Registration Reminder"><StudentRing/></IconImage>
              <ExcelFile element={<IconImage data-tip="Download Intern List"><StudentDownload /></IconImage>} filename={"exportFileName"}>
                <ExcelSheet data={studentList} name={"exportFileName"}>
                  {columns && columns.length && columns.map((column, index) => <ExcelColumn key={index} label={column.name} value={column.selector} />
                  )}
                </ExcelSheet>
              </ExcelFile>
                  <IconImage data-tip="Send Assessments Reminder"><StudentShare/></IconImage>
                </ButtonWrapper> */}
            </ButtonWrapper>
            <InternDataTable
              setModalMode={setModalMode}
              fromUserManagement={true}
              t={t}
              changeInternData={changeInternData}
              data1={studentList}
              setDeleteName={setDeleteName}
              setDeleteUUID={setDeleteUUID}
              setShowDelete={setShowDelete}
              setStudentIndex={setStudentIndex}
              resetBulk={resetBulk}
              
            />
            {internData.length > 0 ?
              <ButtonWrapper spaceBetween>
                <Button name="Continue" onClick={onSubmit} />
                <Button name="Back" secondary onClick={() => navigate(-1)} />
              </ButtonWrapper>
              :
              <ButtonWrapper alignEnd>
                <Button name="Back" secondary onClick={() => navigate(-1)} />
              </ButtonWrapper>}
          </SectionWrapper>
        </NewInternPageWrapper>

        <AddInternDetails
          title={"ADD INTERN"}
          subTitle={"Add Intern Details"}
          show={internModal}
          content={addIntern()}
          close={()=>{setInternModal(false)}}
          />
          {/*Bulk upload*/}
          <BulkStudentModalEdit
            show={bulkModalStudent}
            close={onCloseStudentBulkEdit}
            valid={studentValidatedData?.valid_data_count ?? 0}
            total={studentValidatedData?.total_data_count ?? 0}
            content={contentStudents()}
          />
          <BulkSuccessErrorModal
            backdropMode={bulkModalSuccessErrorMode2}
            title={t("Upload File")}
            subTitle={
              bulkModalSuccessErrorMode2 === "error"
                ? "Invalid Data"
                : "Upload a file with Intern detail"
            }
            show={uploadBulkModelSucessError}
            close={onCloseuploadBulkSuccessError}
            content={contentSuccessError()}
          />
          {/*pass student data from excel to edit form */ }
         <StudentModal
            title={"UPLOAD FILE"}
            subTitle={"Edit Intern Details"}
            content={addOrEditStudentContent({cache:cache,fromEdit:true,errors})}
          />
          <UploadBulkIntern
          title={"UPLOAD FILE"}
          subTitle={"Upload a file with intern details"}
          show={openBulkModal}
          content={uploadBulkModal()}
        />

        <UploadError
          backdropMode={bulkModalSuccessErrorMode}
          title={"UPLOAD FILE"}
          subTitle={bulkModalSuccessErrorMode === "error"? "Invalid Data":
        "File with intern details"
        }
        show={bulkModalSuccessError}
        content={uploadSuccessError()}
          />

            {/* Called when delete user form bulk edit  */}
           <AlertModal
            title={t("Remove Intern ")}
            subTitle={t("Remove ") + deleteName + t(" from ") + company.name}
            show={showDelete}
            close={onClose}
            content={onDeleteExcelStudentsData()}
          />

        <PaymentModal
          title={"INTERNSHIP CREATION"}
          subTitle={"Internship Plan - MySIP Direct Hiring"}
          show={showPaymentModal}
          content={PaymentContent()}
          close={() => { setShowPaymentModal(false) }}
        />
      </MainLayout>
    </>
  )
}