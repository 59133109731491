import React from 'react'
import { SectionWrapper,TableWrapper, HeaderText,  FlexWrapper, TableRowWrapper, TableContent, BoldText, TableText } from './common'
import Button from '../button/button'
import DeleteIcon from "../../assets/icons/svg/delete.svg";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from 'react';
import { useSelector} from "react-redux";
import { getCompanyBrandingArticles, onCompanyBrandingArticleDelete } from "../../services/apiCalls";
import UpdateCompanyBrandingArticles from "../updateCompany/updateCompanyBrandingArticles";
import "./aboutMaker.css"
export default function Articles() {

    const [openArticleModal, setOpenArticleModal] = useState(false);
    const [articles, setArticles] = useState(undefined);
    const { t } = useTranslation();
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

    const getCompanyBrandingArticlesAPI = async () => {
        if (org_code) {
            const { data } = await getCompanyBrandingArticles({
                code: org_code,
            });
            if (data && data.status === 1) {
                setArticles(data.data)

            }
        }

    };

    useEffect(() => {
        if (org_code) {
            getCompanyBrandingArticlesAPI()
        }
    }, [org_code]);

        
    function handleArticleModal() {
        setOpenArticleModal(!openArticleModal)
    }

    useEffect(() => {
        if (!openArticleModal) {
            getCompanyBrandingArticlesAPI()
        }
    }, [openArticleModal]);
    
    const deleteArticle = async (id) => {
        const { data} = await onCompanyBrandingArticleDelete({
            code: org_code,
            id: id,
        });
        if (data && data.status === 1) {
            getCompanyBrandingArticlesAPI()
            toast.success(t(`Article Deleted successfully.`), {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            toast.error(t(data.message), {
                position: toast.POSITION.TOP_RIGHT,
            });

        }

    }

    return (
        <SectionWrapper>
            <FlexWrapper>
                <HeaderText>{t("Articles & Websites")}</HeaderText>
                <Button onClick={handleArticleModal}  fontSize="14px" className="articleButton" name={t("+ Add Articles and Websites")} />
            </FlexWrapper>
            <hr />
           
            <TableWrapper>
                <TableRowWrapper twoColumns>
                    <TableContent>
                        <BoldText>{t("Description")}</BoldText>
                    </TableContent>
                    <TableContent>
                        <BoldText>{t("URL")}</BoldText>
                    </TableContent>
                    <TableContent>
                    </TableContent>
                </TableRowWrapper>
                {articles && articles.length ? articles.map((item, idx) =>
                    <div key={idx}>
                        <hr />
                        <TableRowWrapper twoColumns>
                            <TableContent>
                                <TableText>{item.description}</TableText>
                            </TableContent>
                        
                            <TableContent>
                                {/*<Input placeholder="Enter the URL" style={{ borderRadius: "8px" }} />*/}
                                <TableText>{item.article_url}</TableText>
                            </TableContent>

                            <TableContent style={{ display: "flex", alignItems: "center" }}>
                                <img src={DeleteIcon} onClick={() => deleteArticle(item.id)} className="articleImage"  alt='' />
                            </TableContent>

                        </TableRowWrapper>
                    </div>
                ):<></>}
            </TableWrapper>
            {openArticleModal ?
                <UpdateCompanyBrandingArticles
                    open={true}
                    onClose={handleArticleModal}
                    companyDetails={{}}
                /> : <></>}
        </SectionWrapper>
    )
}
