import React from 'react'
import styled from "styled-components";
import Button from "../../components/button/button";
import { Colors } from "../../utilities/colors";
import TextArea from "../../components/description/description";
import Dropdown from "../dropdown/dropdown";
const ModalContainer = styled.div`
      min-width: 600px;
      min-height: 320px;
      border-radius: 12px;
      background-color: white;
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      display: flex;
      flex-direction: column;
      padding: 10px;
      z-index: 1000;
      @media (max-width: 480px) {
        min-width:320px;
        width: 360px;
        min-height: 400px;
        border-radius:32px;
      }
`;
const ButtonWrapper = styled.div`
     display:flex;
     flex-direction:row;
     flex-flow:wrap;
     justify-content:space-around;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  margin-top:-8px;
  margin-left:16px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}
`;
const MediumText = styled.div`
   font-family: 'General Sans';
   font-style: normal;
   font-weight: 500;
   font-size: 18px;
   line-height: 24px;
   color: #000000;
`;
const SmallText = styled.div`
   font-family: 'General Sans';
   font-style: normal;
   font-weight: 500;
   font-size: 12px;
   line-height: 16px;
   color: #A8A8A8;
   margin-top:12px;
   margin-bottom:12px;
`;
const ContentWrapper = styled.div`
  padding:12px 42px;
  @media (max-width: 480px) {
    padding:12px;
  }
`;
export default function JobPopup({ isOpen, setOpen }) {
    return (
        <div className="modalHead">
            <ModalContainer >
                <div className="popupBorderBox">
                    <div className="titleCloseBtn">
                        <p style={{ color: "#FA4AA1" }}>SHORTLIST</p>
                        <p
                            onClick={() => setOpen()}
                            style={{
                                cursor: "pointer",
                            }}
                        >
                            X
                        </p>
                    </div>
                    <FormLabel >You are addding ALi to the shortlist </FormLabel>
                </div>
                <ContentWrapper>
                    <MediumText>Tags</MediumText>
                    <SmallText>Add tags to help your team better manage your shortlist</SmallText>
                    <TextArea
                        style={{ background: "#F3F3F3" }}
                        rows="6"
                        placeholder=""
                    />
                    <MediumText style={{maxWidth:"360px",textAlign:"center",margin:"auto",padding:"24px 0px"}}>
                        Ali is being shortlisted for the following
                        job position.
                    </MediumText>
                    <Dropdown
                        name="country"
                        search={true}
                        placeholder="Please Select some options"
                        minWidth="auto"
         
                    />
                </ContentWrapper>

                <div style={{ textAlign: "center", paddingTop: "15px" }}>

                    <ButtonWrapper>
                        <Button
                            name="Engage"
                        />
                    </ButtonWrapper>
                </div>
            </ModalContainer>
        </div>
    )
}
