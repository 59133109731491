import React from "react";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import { useSelector } from "react-redux";
// import { useTranslation } from "react-i18next";
import "./companyBranding.css";
import { t } from "i18next";
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const ModalContainer = styled.div`
  min-width: 480px;
  min-height: 270px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  @media (max-width: 500px) {
    min-width: 380px;
    width: 396px;
    height: auto;
  }
`;

export default function BrandingPopup({
  isOpen,
  setOpen,
  proceed,
  submitForm,
}) {
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  // const t = useTranslation();
  return (
    <div>
      {isOpen === true ? (
        <div className="modalHead">
          <ModalContainer>
            <div className="popUpBox">
              <div className="popTwo">
                <p className="popAlert">{("ALERT")}</p>
                <p
                  onClick={() => setOpen(false)}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  {/* X */}
                </p>
              </div>
              <FormLabel className="brandPop">
                {t("Updating Company Branding details")}
              </FormLabel>
            </div>

            <p className="brandEnd">
              {t("Would you like to apply these changes to Company")}{" "}
              {company.name} {t("profile page?")}
            </p>
            <ButtonWrapper>
              <Button secondary onClick={proceed} name={t("No")} />
              <Button onClick={submitForm} name={t("Yes")} />
            </ButtonWrapper>
          </ModalContainer>
        </div>
      ) : null}
    </div>
  );
}
