import React from "react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AnalyticalCardVarientThree } from "../analyticalCardVarientFour/analyticalCardVarienthree";
import { RowSpaced } from "../../../components/analyticalDashboardPage/commonStyles";
import { Utils } from "../../../components/analyticalDashboardPage/utilities";


export default function Ad2DrilldownHOC(getterFunc, uiMap, rootUrl = '', extraParams = []) {
    return function Ad2DrilldownHOCChild() {
        const { t, i18n } = useTranslation();
        let [searchParams, setSearchParams] = useSearchParams();
        const controller = new AbortController();
        const [state, setState] = useState({ data: null, status: 'loading' });
        const navigate = useNavigate();
        const handleNavigate = Utils.handleNavigate.bind(this, navigate);
        // const user = useSelector((state) => (state.auth.user ? state.auth.user : []));
        // const universityId = user && user?.university && user?.university?.universityId;
        var deapartmentFilter = searchParams.get("department");


        const getData = async () => {
            setState({ data: null, status: 'loading' });
            const mandatoryParams = [
                { propertyKey: 'selectedCampus', urlKey: 'campus', },
                { propertyKey: 'selectedFaculty', urlKey: 'faculty' },
                { propertyKey: 'selectedStudyProgram', urlKey: 'study_program' },
                { propertyKey: 'selectedYearOfStudy', urlKey: 'year_of_study' },
                { propertyKey: 'selectedUniversity', urlKey: 'insti_name' },
                { propertyKey: 'selectedScopeOfStudy', urlKey: 'scope_of_study' }];
            const params = {};
            [...mandatoryParams, ...extraParams].forEach((param) => {
                params[param.urlKey] = searchParams.get(param.propertyKey);
            })
            // params.insti_name = universityId;
            console.log("ParamsData",params);
            params['department'] = deapartmentFilter;
            const { data, status } = await getterFunc({
                filters: params,
                chartsLoadingController: controller,
            });
            const { proceed, stateObj } = Utils.commonStateUpdateLogic(data, 0, status, '', true);
            if (stateObj) {
                setState(stateObj);
            }
            if (proceed) {
                let response = data && data.data && data.data.results;
                const output = {};
                Object.keys(response).forEach((key) => {
                    output[key] = response[key];
                })
                setState({ data: output, status });
            }
        };

        const onClick = (baseUrl, data) => {
            const labelLowerCase = data && data.label && data.label.toLowerCase();
            console.log("lowercaseLa",labelLowerCase);
            handleNavigate(baseUrl, { label: labelLowerCase })
        }

        useEffect(() => {
            getData();
        }, []);

        return <>
            <RowSpaced className="gy-4">
                {
                    uiMap.map((x, index) => {
                        const data = state.data && state.data[x.dataKey] && state.data[x.dataKey].results
                        return <Col md="6"
                            key={index}>
                            <AnalyticalCardVarientThree
                                onClick={onClick.bind(this, (x && x.url) || '')}
                                title={x && t(x.title)}
                                info={x && t(x.info)}
                                high={data && data.high}
                                moderate={data && data.moderate}
                                low={data && data.low}
                                status={state.status}
                                url={`${(rootUrl || '')}${(x && x.url) || ''}`}
                                showDrilldown={x.showDrilldown} />
                        </Col >
                    })
                }
            </RowSpaced>
        </>;
    }
};