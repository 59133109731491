import React from 'react'
import styled from 'styled-components'
import ReactSpeedometer from "react-d3-speedometer"

const HeaderText = styled.div`
font-family: 'General Sans';
font-style: normal;
font-weight: 600;
font-size: 20px;
line-height: 25px;
/* identical to box height, or 124% */

text-align: center;

color: #000000;
margin-top:-84px;
margin-bottom:36px
`;
export default function ChartsView({ text }) {
    return (
        <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-around", width: "360px", alignItems: "center",padding:"8px 24px" }}>
            <ReactSpeedometer
                currentValueText=""
                needleColor='#542F41'
                needleHeightRatio={0.7}
                customSegmentLabels={[
                    {
                        text: "Low",
                        position: "OUTSIDE",
                        color: "#000000",
                    },
                    {
                        text: "Moderate",
                        position: "OUTSIDE",
                        color: "#000000",
                    },
                    {
                        text: "High",
                        position: "OUTSIDE",
                        color: "#000000",
                    }
                ]}
                needleTransitionDuration={4000}
                needleTransition="easeElastic"
                customSegmentStops={[0, 250, 750, 1000]}
                segments={3}
                segmentColors={["#FF75B9", "#D24C8E", "#9B3668"]}
                value={100}

            />
              {text ? <HeaderText> {text} </HeaderText> : null}
        </div>
    )
}
