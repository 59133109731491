import React, { useState, useEffect } from 'react';
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import CompanyIcon from "../../assets/icons/Company.png"; 
import dummyApi from '../MySIPDirectHiring/dummyApiLoe';
import { completionOfInternshipOverviewObj } from '../MySIPDirectHiring/staticContent';



const InternTypeDetailsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 15px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;

const DetailsQuestion = styled.h3`
{
    font-weight: 500;
    font-size: 20px;
    color:#000;
    padding-bottom:3px;
}`

const CompanyInfo = styled.h4`
{
    padding-left:5px;
    font-size:18px;
    font-weight:500;
    color: ${Colors.greyText};
}`
const CompanyInfoPdf = styled.h4`
{
    text-decoration:underline;
    padding-left:5px;
    font-size:18px;
    font-weight:500;
    color: ${Colors.pink};
}`
const CompanyInfo2 = styled.h4`
{
    padding-left:5px;
    font-size:23px;
    font-weight:500;
}`
const InfoDiv = styled.div`
margin-top:20px;
margin-bottom: 20px;
`;

const ImgDiv = styled.img`
margin-right: 10px;
`;

export default function CompletionOfInternshipOverview(dataCompletion) {

  console.log(dataCompletion,"dataCompletion")
  const title = localStorage.getItem("internshipTitle");
  console.log(title, "LOCALLY STORED")
    const [internships, setInternships] = useState([]);
    useEffect(() => {
        const targetInternshipName = 'Marketing'; // Replace with the desired internship name
    
        // dummyApi.getInterships()
        //   .then((data) => {
        //     const targetInternship = data.find(internship => internship.name === targetInternshipName);
        //     setInternships(targetInternship);
        //   })
        //   .catch((error) => {
        //     console.error("Error fetching internships:", error);
        //   });
      }, []);


const getFileName = (url) => {
  const pathname = new URL(url).pathname;
  return pathname.split("/").pop();
};

const dateFormat = (dateString) =>{
  const date = new Date(Date.parse(dateString));
  const day = date.getUTCDate().toString().padStart(2, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const year = date.getUTCFullYear();
    
      return `${day}-${month}-${year}`;
}

  return (
    <InternTypeDetailsWrapper>

    {dataCompletion || dataCompletion.dataCompletion.additional_allowance || dataCompletion.dataCompletion.additional_allowance.length > 0 || dataCompletion.dataCompletion.type_of_training_provided || dataCompletion.dataCompletion.type_of_training_provided.length > 0 ?(
     <>
     <DetailsQuestion>
      Attendance Records
    </DetailsQuestion>

    {dataCompletion.dataCompletion?.attendance_records?.map((record, index) => (
      <a key={index} href={record.file_path} target="_blank">
        <CompanyInfoPdf>
          <ImgDiv src={CompanyIcon} alt="Company Icon" /> 
          {/* <img src={CompanyIcon} alt="Company Icon" /> */}
          {getFileName(record.file_path)}
        </CompanyInfoPdf>
      </a>
    ))}

   
    <DetailsQuestion>
     Internship Title
    </DetailsQuestion>
    <CompanyInfo>{title}</CompanyInfo>

    {/* <DetailsQuestion>Internship Start Date</DetailsQuestion>
    <CompanyInfo>{dateFormat(dataCompletion?.dataCompletion?.internship_start_date)}</CompanyInfo>

    <DetailsQuestion>Internship End Date</DetailsQuestion>
    <CompanyInfo>{dateFormat(dataCompletion?.dataCompletion?.internship_end_date)}</CompanyInfo> */}

    <DetailsQuestion>
     Total allowance paid for the whole duration of the programme.
    </DetailsQuestion>
    <CompanyInfo>{dataCompletion.dataCompletion.total_allowance}</CompanyInfo>

    <DetailsQuestion>
     Additional Allowance
    </DetailsQuestion>
    {dataCompletion?.dataCompletion.additional_allowance? (
      dataCompletion.dataCompletion.additional_allowance.map((item) =>(
  <ul>
    <li>
    <CompanyInfo>{item.name === "Other Allowances"? item.other: item.name}</CompanyInfo>
    </li>
  </ul>
 ))
    ): null}
    {/* {dataCompletion.dataCompletion.additional_allowance.map((item) =>(
     <ul>
       <li>
       <CompanyInfo>{item.name}</CompanyInfo>
       </li>
     </ul>
    ))} */}

    <DetailsQuestion>
      Expenditure Records
    </DetailsQuestion>

    {dataCompletion.dataCompletion?.expenditure_records?.map((record, index) => (
      <a key={index} href={record.file_path} target="_blank">
        <CompanyInfoPdf>
          <ImgDiv src={CompanyIcon} alt="Company Icon" />
          {/* <img src={CompanyIcon} alt="Company Icon" /> */}
          {getFileName(record.file_path)}
        </CompanyInfoPdf>
      </a>
    ))}


     <InfoDiv>
     <CompanyInfo2>Internal Training</CompanyInfo2>
     <DetailsQuestion>
       Internal training provided for the interns
     </DetailsQuestion>
     <CompanyInfo>{dataCompletion.dataCompletion.is_internal_training_given === 1? "Yes": "No"}</CompanyInfo>

     {dataCompletion.dataCompletion.is_internal_training_given === 1?(
       dataCompletion.dataCompletion.internal_training.map((items)=>(
         <>
         <DetailsQuestion>Course Title</DetailsQuestion>
         <CompanyInfo>{items.internal_training_course_title}</CompanyInfo>

         <DetailsQuestion>Training Provider</DetailsQuestion>
         <CompanyInfo>{items.internal_training_provider}</CompanyInfo>

         <DetailsQuestion>Duration</DetailsQuestion>
         <CompanyInfo>{items.internal_training_duration} days</CompanyInfo>
         </>
       ))
     ): ""}
     </InfoDiv>

     <InfoDiv>
       <CompanyInfo2>External Training</CompanyInfo2>

       <DetailsQuestion>
       External training provided for the interns
     </DetailsQuestion>
       <CompanyInfo>{dataCompletion.dataCompletion.is_external_training_given === 1? "Yes": "No"}</CompanyInfo>

      
     {dataCompletion.dataCompletion.is_external_training_given === 1 ?(
       dataCompletion.dataCompletion.external_training.map((items)=>(
         <>
          <DetailsQuestion>
          The external trainings provider certified by the Human Resources Development Corporation(HRDCorp)
         </DetailsQuestion>
         <CompanyInfo>{items.is_external_training_provider_certified_by_the_hrdcorp === 1? "Yes": "No"}</CompanyInfo>
         <DetailsQuestion>Course Title</DetailsQuestion>
         <CompanyInfo>{items.external_training_course_title}</CompanyInfo>

         <DetailsQuestion>Training Provider</DetailsQuestion>
         <CompanyInfo>{items.external_training_provider}</CompanyInfo>

         <DetailsQuestion>Duration</DetailsQuestion>
         <CompanyInfo>{items.external_training_duration} days</CompanyInfo>
         </>
       ))
     ): ""}
     </InfoDiv>

     <DetailsQuestion>
      Proof Of Training
    </DetailsQuestion>

    {dataCompletion.dataCompletion?.proof_of_training?.map((record, index) => (
      <a key={index} href={record.file_path} target="_blank">
        <CompanyInfoPdf>
          <ImgDiv src={CompanyIcon} alt="Company Icon" />
          {/* <img src={CompanyIcon} alt="Company Icon" /> */}
          {getFileName(record.file_path)}
        </CompanyInfoPdf>
      </a>
    ))}

    {/* <DetailsQuestion>
     Did you provide any internal tranings during the internship period(e.g Sending intern for a short course or traning by a recognised training provider )
    </DetailsQuestion>
    <CompanyInfo>{data?.is_internal_training_given === 1? "Yes": "No"}</CompanyInfo>

    <DetailsQuestion>
     If yes, were the external trainings provider certified by the Human Resources Development Corporation(HRDCorp)?
    </DetailsQuestion>
    <CompanyInfo>{data?.is_external_training_given === 1? "Yes": "No"}</CompanyInfo>

    <DetailsQuestion>
     If yes, please attached the evidance of traning here.
    </DetailsQuestion>
    <CompanyInfoPdf><img src={CompanyIcon} alt="Company Icon" />{completionOfInternshipOverviewObj.externalTrainingsEvidence}</CompanyInfoPdf> */}

    <DetailsQuestion>
     Type of training provided
    </DetailsQuestion>
    {dataCompletion?.dataCompletion?.type_of_training_provided? (
      dataCompletion.dataCompletion.type_of_training_provided.map((item) =>(
        <ul>
          <li>
          <CompanyInfo>{item.name === "Others (please specify)"? item.other: item.name}</CompanyInfo>
          </li>
        </ul>
       ))
    ): null}

<DetailsQuestion>
     Learning Outcome
    </DetailsQuestion>
    {dataCompletion?.dataCompletion?.learning_outcome? (
      dataCompletion.dataCompletion.learning_outcome.map((item) =>(
        <ul>
          <li>
          <CompanyInfo>{item.name === "Others (please specify)"? item.other: item.name}</CompanyInfo>
          </li>
        </ul>
       ))
    ): null}
    {/* {dataCompletion.dataCompletion.type_of_training_provided.map((item) =>(
     <ul>
       <li>
       <CompanyInfo>{item.name}</CompanyInfo>
       </li>
     </ul>
    ))} */}

    {/* <DetailsQuestion>
     Course Title
    </DetailsQuestion>
    <CompanyInfo>{completionOfInternshipOverviewObj.courseTitle}</CompanyInfo>

    <DetailsQuestion>
     Training Provider
    </DetailsQuestion>
    <CompanyInfo>{completionOfInternshipOverviewObj.traningProvider}</CompanyInfo>

    <DetailsQuestion>
     Duration
    </DetailsQuestion>
    <CompanyInfo>{completionOfInternshipOverviewObj.duration}</CompanyInfo> */}
     </>
    ): <CompanyInfo2 style={{textAlign: "center"}}>Internship has not been completed</CompanyInfo2>}
    
   </InternTypeDetailsWrapper>
    
  )
}
