import React from 'react'
import { Ad2Card } from '../adCard';
import { useTranslation } from "react-i18next";
import { ChartTitle } from '../adCardStyles';
import GenderRatioBarChart from '../charts/genderRatioBarChart';
import { HtmlCanvasGender, HtmlLegendChartWrapper, HtmlLegendChartWrapper2 } from '../../../pages/applicantAssessments/styles/analyticalStyles';

export default function AnalyticalEnhancedGender({ data }) {

    const { t, i18n } = useTranslation();
    const chartData = data && data.data;
    const status = data && data.status;
    const registeredMale = chartData && chartData[0] && chartData[0]?.male;
    const registeredFemale = chartData && chartData[0] && chartData[0]?.female;
    const unregisteredMale = chartData && chartData[1] && chartData[1]?.male;
    const unregisteredFemale = chartData && chartData[1] && chartData[1]?.female;
  
    const columns = [
        {
            name: "",
            selectorKey: "type",
            selector: (row) => t(row.type),
            sortable: false,
            width: "230px",
        },
        {
            name: t("Male"),
            selectorKey: "male",
            selector: (row) => row.male,
            sortable: false,
        },
        {
            name: t("Female"),
            selectorKey: "female",
            selector: (row) => row.female,
            sortable: false,
        },
    ];

    return (
        <Ad2Card
            title={t("Gender Ratio")}
            data={chartData}
            columns={columns}
            exportFileName="gender_ratio"
            status={status}
        >

            <div style={{paddingTop:"7%"}}>
                <ChartTitle>{t("Registered Employee")}</ChartTitle>
                <GenderRatioBarChart
                    data={{
                        labels: ["Registered", "Not-Registered"],
                        datasets: [
                            {
                                data: [registeredMale, registeredFemale],
                                backgroundColor: ["#004A84", "#D84E8D"],
                                borderRadius: Number.MAX_VALUE,
                                borderWidth: 1,
                                borderSkipped: false,
                                barPercentage: 0.5,
                                categoryPercentage: 0.5,
                            },
                        ],
                    }}
                />
                
                <ChartTitle>{t("Not Registered Employee")}</ChartTitle>
                <GenderRatioBarChart
                    data={{
                        labels: ["Registered", "Not-Registered"],
                        datasets: [
                            {
                                data: [unregisteredMale, unregisteredFemale],
                                backgroundColor: ["#004A84", "#D84E8D"],
                                borderRadius: Number.MAX_VALUE,
                                borderWidth: 1,
                                borderSkipped: false,
                                barPercentage: 0.5,
                                categoryPercentage: 0.5,
                            },
                        ],
                    }}
                />
            </div>
        </Ad2Card>
    )
}
