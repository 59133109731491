import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Col,  Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { ReactComponent as Loader } from "../../assets/svg/loader.svg";
import { getDashboardData } from "../../services/apiCalls";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { useRef } from "react";
import { onApiCall } from "../../services/CommonApi";
const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";

  @media (max-width: 767px) {
    display: none;
  }
`;

const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;


const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const DataTableWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 3rem;
  .custom-selector {
    cursor: pointer !important;
    div[id*="option"] {
      cursor: pointer;
      &:hover {
        background-color: #f3f0f0 !important;
      }
    }
  }
  .rdt_TableHead {
    border-radius: 34px;
    height: 83px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    .rdt_TableCol_Sortable,
    rdt_TableCol {
      font-family: General Sans;
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 108%;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }
  .rdt_TableBody {
    border-radius: 0px 0px 34px 34px;

    .rdt_TableRow {
      cursor: pointer;
      &:hover {
        background-color: #ffe9f4;
      }
    }
  }
`;

const PreferencesWrapper = styled.div`
  span {
    width: 31px;
    height: 31px;
    border-radius: 6px;
    display: inline-flex;
    text-align: center;
    vertical-align: middle;
    color: #fff;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 2px 1px;
    font-family: General Sans;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    &:hover {
      transition: 0.3s;
    }
  }
  & span:nth-child(3) {
    background: linear-gradient(180deg, #891437 0%, #f27996 100%);
  }
  & span:nth-child(2) {
    background: linear-gradient(180deg, #134389 0%, #7abff3 100%);
  }
  & span:nth-child(1) {
    background: linear-gradient(180deg, #b74d00 0%, #ffb13d 100%);
  }
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    height: auto;
    width: 100px;

    @media (min-width: 768px) {
      width: 5vw;
    }
  }
  .arrowOne {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) 0s infinite running
      move_eye;
    position: absolute;
  }
  .arrowTwo {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -0.5s infinite running move_eye;
    position: absolute;
  }
  .arrowThree {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -1s infinite running move_eye;
    position: absolute;
  }
  .main {
    position: absolute;
  }

  @-webkit-keyframes move_eye {
    from {
      transform: translate(-50%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }

  @keyframes move_eye {
    from {
      transform: translate(-40%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(30%, 0%);
      opacity: 1;
    }
  }
`;

export const schema = yup.object().shape({
  email_address: yup.string().required("Email Address is required."),
  position: yup.string().required("Position is required."),
  dapartment: yup.string().required("Department is required."),
});

const CoachingDashboard = () => {
  const { t, i18n } = useTranslation();
  const [keyword, setKeyword] = useState(null);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [datas, setData] = useState([]);

  const {
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const getData = async () => {
    const { data } = await getDashboardData();
    if (data?.status === 1) {
      setData(data?.data?.results?.data);
      setTotalRows(data?.data?.results?.total);
    }
  };

  const onTalentSearch = () => {
    let p = {};
    let url = "/api/dashboard/coaching/company/results?";
    if (keyword && keyword.replace(/\s/g, "+").length > 0) {
      url =
        url +
        `display_name=${keyword.replace(
          /\s/g,
          "+"
        )}&page=1&per_page=${perPage}`;
    } else {
      url = url + `page=1&per_page=${perPage}`;
    }

    onApiCall({
      url: url,
      method: "GET",
    }).then((res) => {
      if (res.status === 200) {
        setData(res?.data?.data?.results.data);
        setTotalRows(res?.data?.data?.results.total);
      }
    });
  };

  const handlePageChange = (page) => {
    onApiCall({
      url: `/api/dashboard/coaching/company/results`,
      method: "GET",
      params: {
        display_name: keyword,
        page: page,
        per_page: perPage,
      },
    }).then((res) => {
      if (res.status === 200) {
        setData(res?.data?.data?.results.data);
        setTotalRows(res?.data?.data?.results.total);
      }
    });
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    onApiCall({
      url: `/api/dashboard/coaching/company/results`,
      method: "GET",
      params: {
        display_name: keyword,
        page: page,
        per_page: newPerPage,
      },
    }).then((res) => {
      if (res.status === 200) {
        setData(res?.data?.data?.results.data);
        setPerPage(newPerPage);
      }
    });
  };
  const useDebounce = (callback) => {
    const ref = useRef();

    useEffect(() => {
      ref.current = callback;
    }, [callback]);

    const debouncedCallback = useMemo(() => {
      const func = () => {
        ref.current?.();
      };
    }, []);

    return debouncedCallback;
  };

 

  useEffect(() => {
    getData();
  }, []);
  const navigateRow = (id) => {
    navigate(`/coaching-dashboard/student/${id}`);
  };
  const navigate = useNavigate();
  const onRowClicked = (row) => navigateRow(row.id);

  const columnStyling = {
    fontFamily: "General Sans",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 18,
    lineHeight: "24px",
    color: "#202020",
    alignItems: "center",
  };
  const paginationOptions = {
    rowsPerPageText: t("Rows per page:"),
  };
  const columns = [
    {
      name: t("Name"),
      selector: (row) => row.name,
      sortable: true,

      style: columnStyling,
      center: true,
    },
    {
      name: t("Work Interests"),
      selector: (row) => row?.interestRiasec,
      sortable: true,
      style: columnStyling,

      center: true,
      cell: (row) => (
        <PreferencesWrapper>
          {row?.interestRiasec?.map((preference, index) => (
            <span
              style={{
                background:
                  preference === "Realistic"
                    ? "red"
                    : preference === "Investigative"
                    ? "blue"
                    : preference === "Enterprising"
                    ? "violet"
                    : preference === "Social"
                    ? "green"
                    : preference === "Artistic"
                    ? "orange"
                    : preference === "Conventional"
                    ? "navy"
                    : "",
              }}
              key={index}
            >
              {preference === "Realistic"
                ? "R"
                : preference === "Investigative"
                ? "I"
                : preference === "Artistic"
                ? "A"
                : preference === "Social"
                ? "S"
                : preference === "Enterprising"
                ? "E"
                : preference === "Conventional"
                ? "C"
                : ""}
            </span>
          ))}
        </PreferencesWrapper>
      ),
    },
    {
      name: t("English Proficiency"),
      sortable: true,
      style: columnStyling,
      selector: (row) => t(row.englishTest),
      center: true,
    },
  ];

  return (
    <div>
      <MainLayout>
        <CompanySection>
          <InputBox>
            <Input
              color="#fff"
              placeholder={t("Search Keywords for User Name")}
              value={keyword === null ? "" : keyword}
              onChange={(e) => setKeyword(e.target.value)}
            />
          </InputBox>

          <div style={{ marginLeft: "0px" }}>
            <Button
              style={{ cursor: "pointer" }}
              name="Search"
              onClick={onTalentSearch}
            />
          </div>
        </CompanySection>

        <Row>
          <Col>
            <DataTableWrapper>
              <DataTable
                onRowClicked={onRowClicked}
                columns={columns}
                data={datas}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                onChangeRowsPerPage={handlePerRowsChange}
                s
                paginationComponentOptions={paginationOptions}
                onChangePage={handlePageChange}
                theme="solarized"
                progressComponent={
                  <div
                    style={{
                      minHeight: "586px",
                      backgroundColor: `#FFFFFF !important`,
                    }}
                  >
                    <DataTableLoaderWrapper>
                      <LoaderWrapper>
                        <Loader />
                      </LoaderWrapper>
                    </DataTableLoaderWrapper>
                  </div>
                }
              />
            </DataTableWrapper>
          </Col>
        </Row>
      </MainLayout>
    </div>
  );
};

export default CoachingDashboard;
