const departmentList = [
  {value:"All Department",label:"All Department"},
    {
      label: "Human Resources",
      value: "Human Resources"
    },  
    {
      label: "Sales",
      value: "Sales"
    },  
    {
      label: "Marketings",
      value: "Marketing"
    },  
    {
      label: "Finance",
      value: "Finance"
    },  
    {
      label: "Information Technology",
      value: "Information Technology"
    },  
    {
      label: "Operations",
      value: "Operations"
    },  
    {
      label: "Customer Service",
      value: "Customer Service"
    },  
    {
      label: "Research and Development",
      value: "Research and Development"
    },  
    {
      label: "Legal",
      value: "Legal"
    },  
    {
      label: "Procurement",
      value: "Procurement"
    },  
    {
      label: "Production",
      value: "Production"
    },  
    {
      label: "Quality Assurance",
      value: "Quality Assurance"
    },  
    {
      label: "Logistics and Supply Chain",
      value: "Logistics and Supply Chain"
    },  
    {
      label: "Public Relations",
      value: "Public Relations"
    },  
    {
      label: "Health and Safety",
      value: "Health and Safety"
    },  
    {
      label: "Administration",
      value: "Administration"
    },  
    {
      label: "Others",
      value: "Others"
    },
  
  ]
  
  
  export default departmentList;
  