import React from "react";
import "./aboutMaker.css"
const AboutMaker = (props) => {
  return (
    <div>
      {" "}
      <span>
        {
          // Use JavaScript's ternary operator to specify <span>'s inner content
          props.showInputEle ? (
            <textarea
              type="text"
              value={props.value}
              onChange={props.handleChange}
              onBlur={props.handleBlur}
              className="aboutMaker"
            />
          ) : (
            <span
              onDoubleClick={props.handleDoubleClick}
              className="aboutClick"
            >
              {props.value}
            </span>
          )
        }
      </span>
    </div>
  );
};

export default AboutMaker;
