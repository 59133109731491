import { useEffect, useState, useCallback, useRef } from "react";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { formatDate } from "../../utilities/commonFunctions";
import Input from "../../components/input/input";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { Colors } from "../../utilities/colors";
import JobsCards from "../../components/jobsCards/jobsCards";
import {
  getInternLists,
  getOrgData,
  getOrganizationInternshipCoinDetail,
  getQuota
} from "../../services/apiCalls";
import DashboardModal from "../../components/dashboardModal/dashboardModal";
import AddNewIntern from "../../components/addNewIntern/addNewIntern";
import { staticState } from "../dashboard/staticContent";
import Loader from "../../components/loader/loader";
import Button from "../../components/button/button";
import Pending from "../../assets/icons/svg/pendingIcon.svg";
import All from "../../assets/icons/svg/all.svg";
import Expired from "../../assets/icons/svg/expiredIcon.svg";
import Remarks from "../../assets/icons/svg/remarks.svg";
import PendingPayment from "../../assets/icons/pending-payment.png";
import Accepted from "../../assets/icons/svg/acceptedIcon.svg";
import Reject from "../../assets/icons/reject-48.png";
import { debounce, getToday } from "../../utilities/commonFunctions";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import Select from "react-select";
import "react-phone-number-input/style.css";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import InfiniteScroll from "react-infinite-scroll-component";
import ErrorField from "../../components/errorField/errorField";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import UnAuthPopup from "../userManagement/unAuthPopup";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});
const DashboardWrapper = styled.div`
@media (min-width:767px) and (max-width:1023px){
  margin:80px -10px 0 -25px;
}
`;
// const HeaderText = styled.h2`
//   font-weight: 500;
//   font-size: 28px;
//   line-height: 38px;
//   color: ${Colors.dark};
//   margin-top: 0;
// `;
// const FlexDiv = styled.div`
//   display: flex;
// `;
const Section = styled.div`
  margin-bottom: 50px;
`;
// const FilterSection = styled.div`
//   display: flex;
//   grid-template-columns: 1fr 1fr 2fr 0.5fr;
//   align-items: center;
//   grid-column-gap: 16px;
//   margin: 20px 0;
//   @media (max-width: 900px) {
//     flex-direction: column;
//     justify-content: space-between;
//   }
// `;

const PageTitle = styled.h1`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: 20px 0 10px 0;
  @media (max-width: 500px) {
    margin: 160px 0 10px 0;
  }
`;
const FilterHead = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin: 0;
  margin-bottom: 10px;
  color: #000000;
  visibility: ${(props) => (props.visibility ? props.visibility : "initial")};
`;

const SingleSection = styled.div`
  // position: relative;
  // flex-grow: 1;

  width: 100%;
  @media (max-width: 900px) {
    width: 100%;
  }
  label {
    position: relative;
    width: inherit;
    right: 10px;
  }
  button {
    position: relative;
    width: inherit;
  }
`;

const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

const ImageDropdown = styled.img`
  width: 20px;
  height: 20px;
`;

export default function Internships() {
  const [loading, setLoading] = useState(true);
  const [unAuth, setUnAuth] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [searchval, setSearchFilter] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [qty, setQty] = useState();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const { dashboardStatus } = useParams();
  const [status, setStatus] = useState(
    dashboardStatus ? dashboardStatus : "all"
  );
  const selectRef = useRef();

  const [fromDateError, setFromDateError] = useState(false);
  const [coin, setCoin] = useState("");
  const [userUsedCoins, setUserUsedCoin] = useState("");

  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

  let navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    getDatas("", 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (org_code) getDatas("", 1);
  }, [org_code]);

  useEffect(() => {
    setFromDateError(false);
  }, [status, fromDate, toDate, searchval]);

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  const fetchData = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
      getDatas("", currentPage + 1);
    }
  };

  const getDatas = async (name, pageNumber, updatedStatus) => {
    var result1 = await getOrgData();
    if (result1.status === "401") {
      navigate("/login");
    }
    if (org_code) {
      let req = {};
      let filter = {};
      req["start_date"] = fromDate || "";
      req["end_date"] = toDate || "";
      if (req["start_date"] || req["end_date"]) {
        if (!req["start_date"]) {
          setFromDateError(true);
          return false;
        }
        if (!req["end_date"]) {
          req["end_date"] = getToday();
        }
        filter = { period: { ...req } };
      }

      const body = {
        ...staticState,
        search: name ? name : searchval,
        filters: {
          ...filter,
          status: updatedStatus ? updatedStatus : status ? status : "all",
        },
        page_number: pageNumber || 1,
        org_code: org_code,
      };

      const { data } = await getInternLists({
        ...body,
      });

      if (data && data.status === 1) {
        // if page number is greater than 2 then its obvious the second page
        // hence data is been appended
        if (data?.pagination?.current_page > 1) {
          setDataList(
            dataList.length ? [...dataList, ...data.data] : [...data.data]
          );
        } else {
          setDataList(data?.data?.length ? [...data.data] : []);
        }
        setLoading(false);
        setTotal(data?.pagination?.total_pages);
        setCurrentPage(data?.pagination?.current_page);
      } else {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    setDataList([]);
  }, [status]);

  const debounceChange = useCallback(debounce(getDatas, 1000), []); // eslint-disable-line react-hooks/exhaustive-deps

  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    debounceChange(e.target.value, 1, status);
  };

  const dateChange = (name, val) => {
    if (name === "fromDate") setFromDate(formatDate(val));
    if (name === "toDate") setToDate(formatDate(val));
  };
  const showToast = () => {
    toast.success(t("Internship status updated successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const Icon = styled.div`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    background: ${(props) => (props.bg ? props.bg : "#33ff00")};
  `;

  const options = [
    {
      value: "all",
      label: t("All"),
      customAbbreviation: qty ? qty.all : null,
      image: <ImageDropdown src={All} />,
    },
    {
      value: "pending",
      label: t("Pending"),
      customAbbreviation: qty ? qty.pending : null,
      image: <ImageDropdown src={Pending} />,
    },
    {
      value: "accepted",
      label: t("Approved"),
      customAbbreviation: qty ? qty.accepted : null,
      image: <ImageDropdown src={Accepted} />,
    },
    {
      value: "expired",
      label: t("Expired"),
      customAbbreviation: qty ? qty.expired : null,
      image: <ImageDropdown src={Expired} />,
    },
    {
      value: "activate",
      label: t("Activate"),
      customAbbreviation: qty ? qty.activate : null,
      image: <Icon bg="#33ff00" />,
    },
    {
      value: "deactivate",
      label: t("Deactivate"),
      customAbbreviation: qty ? qty.deactivate : null,
      image: <Icon bg="#FF0000" />,
    },
    {
      value: "rejected",
      label: t("Denied"),
      customAbbreviation: qty ? qty.rejected : null,
      image: <ImageDropdown src={Reject} />,
    },
    {
      value: "pending_payment",
      label: t("Pending Payment"),
      customAbbreviation: qty ? qty.pending_payment : null,
      image: <ImageDropdown src={PendingPayment} />,
    },
    {
      value: "review",
      label: t("Review"),
      customAbbreviation: qty ? qty.pendingPayment : null,
      image: <ImageDropdown src={Remarks} />,
    },
  ];

  const formatOptionLabel = ({ value, label, customAbbreviation, image }) => (
    <>
      <Row>
        <Col md={3}>{image}</Col>
        <Col md={3}>{label}</Col>
        <Col md={6}>{customAbbreviation}</Col>
      </Row>
    </>
  );

  const handleSelect = (value) => {
    setLoading(true);
    setStatus(value.value);
    debounceChange(searchval, 1, value.value);
  };

  const compo = {
    IndicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  const styles = {
    control: (base, state) => ({
      ...base,
      // height: "45px",
      padding: "5.5px 5px",
      transition: "none",
      textTransform: "capitalize",
      width: "100%",
      background: `${Colors.light}`,
      border: "0px",
      outline: "none",
      boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
      borderRadius: "128px",
      "&:hover": {
        boxShadow: "0 0 0 1px #ff60b1",
        borderColor: "#ff60b1",
      },
    }),
    menu: (base) => ({
      ...base,
      marginTop: "0px",

      outline: "none",
      zIndex: "3",
      textTransform: "capitalize",
      border: "0px solid white",
      transition: "none",
      // minWidth: "234px",
    }),
    menuList: (base) => ({
      ...base,
      color: "#575757;",
      fontSize: "12px",
      // minWidth: "234px",
      textTransform: "capitalize",
    }),
    option: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      fontSize: `14px`,
      fontFamily: `General Sans`,
      fontWeight: `500`,
      backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
      color: state.isSelected ? `white` : `#202020`,
      "&:hover": {
        backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
        Color: `#D04C8D`,
      },
    }),
    input: (base, state) => ({
      ...base,
      '[type="text"]': {
        color: `#575757; !important`,
      },
    }),
  };

  // ARCHMAGE X-0170
  const resetFilters = () => {
    setLoading(true);

    setSearchFilter("");
    setFromDate("");
    setToDate("");

    selectRef.current?.selectOption(options[0]);

    debounceChange("", 1, "all");
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (Object.keys(company).length > 0) {
      const result3 = await getQuota(org_code);
      setCoin(result3?.data?.internship_tokens);
      setUserUsedCoin(result3?.data?.internship_tokens_in_use);
    }
  }, [company]);

  return (<>
    <UnAuthPopup
      isOpen={unAuth}
      setOpen={setUnAuth}
    ></UnAuthPopup>
    <MainLayout>
      <div role="presentation">
        <div
          className="d-flex mb-5"
          style={{
            fontSize: "1.1rem",
            fontWeight: "400",
            marginLeft: "15px",
            textDecoration: "none",
            color: "#ACACAC",
          }}
        >
          {/* <Link
            to="/internships"
            style={{ textDecoration: 'none', color: '#ACACAC' }}
          > */}

          {/* </Link> */}
          {/* <div className="mx-2" style={{ color: '#ACACAC' }}></div>
          <span style={{ textDecoration: 'none', color: '#D04C8D' }}>
            Internships
          </span> */}
        </div>
      </div>
      <DashboardWrapper>
        {/* <HamburgerLayout>
          <HamburgerLay onToggle={() => setOpenHam(!isOpenham)} />
        </HamburgerLayout> */}
        {/* {isOpenham && <Menus />} */}
        <div style={{ marginTop: "-50px" }}>
          <AddNewIntern setUnAuth={setUnAuth} coin={coin} userUsedCoins={userUsedCoins} />
        </div>

        <PageTitle>{t("Manage Internships")}</PageTitle>

        <Input
          color="#fff"
          placeholder={t("Search Keywords for internship or Company Name")}
          value={searchval}
          onChange={onSearch}
        />
        <Row xs={1} md={3} lg={5} className="my-4">
          <Col>
            <SingleSection>
              <FilterHead style={{ minWidth: "125px" }}>
                {t("Date Published")}{" "}
              </FilterHead>
              <label className="custom-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      placeholder={t("From")}
                      views={["year", "month", "date"]}
                      value={fromDate ? new Date(fromDate) : null}
                      onChange={(val) => dateChange("fromDate", val)}
                      maxDate={new Date()}
                      fullWidth
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <CalenderIconWrapper>
                  <CalenderIcon />
                </CalenderIconWrapper>
              </label>
            </SingleSection>
            <div className="m-2">
              {fromDateError && (
                <ErrorField err={t("From date is mandatory")} className="m-2" />
              )}
            </div>
          </Col>
          <Col>
            <SingleSection>
              <FilterHead visibility="hidden">{t("Date")}</FilterHead>
              <label className="custom-date">
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <ThemeProvider theme={materialTheme}>
                    <DatePicker
                      autoOk
                      variant="inline"
                      disabled={!fromDate}
                      format="dd/MM/yyyy"
                      placeholder={t("To")}
                      views={["year", "month", "date"]}
                      value={toDate ? new Date(toDate) : null}
                      maxDate={new Date()}
                      minDate={fromDate}
                      minDateMessage={t("To date can not be before from date.")}
                      onChange={(val) => dateChange("toDate", val)}
                      fullWidth
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <CalenderIconWrapper>
                  <CalenderIcon />
                </CalenderIconWrapper>
              </label>
            </SingleSection>
          </Col>
          <Col>
            <SingleSection>
              <FilterHead>Status</FilterHead>
              <Select
                // search={true}
                ref={selectRef}
                formatOptionLabel={formatOptionLabel}
                options={options}
                components={compo}
                styles={styles}
                onChange={handleSelect}
                defaultValue={options.find((f) => f.value === status)}
              />
            </SingleSection>
          </Col>
          <Col>
            <SingleSection>
              <FilterHead className="invisible mb-2">Button</FilterHead>
              <Button
                className="mb-1 mt-0 py-auto"
                onClick={() => getDatas("", 0)}
                name={t("Search")}
              />
            </SingleSection>
          </Col>
          <Col>
            <SingleSection>
              <FilterHead className="invisible mb-2">Reset Button</FilterHead>
              <Button
                className="mb-1 mt-0 py-auto"
                onClick={resetFilters}
                secondary
                name={t("Reset")}
              />
            </SingleSection>
          </Col>
        </Row>
        {loading ? (
          <Loader />
        ) : (
          <Section>
            <InfiniteScroll
              dataLength={dataList?.length}
              next={fetchData}
              hasMore={currentPage < total}
              scrollThreshold={0.5}
            >
              {dataList.length > 0 ? (
                dataList.map((list, index) => (
                  <JobsCards
                    intern={true}
                    pending={status === "pending"}
                    onUpdate={() => getDatas()}
                    key={list + index}
                    datas={list}
                    current_new={status}
                    showToast={showToast}
                  />
                ))
              ) : (
                <p className="noDataFound">{t("No data found")}</p>
              )}
            </InfiniteScroll>
          </Section>
        )}
      </DashboardWrapper>
      <DashboardModal />
    </MainLayout></>
  );
}
