import React from 'react'
import { BoxWrapper, ChartsWrapper, ChartHeaderText } from './common'
import ChartsView from './charts/pinkCharts';
export default function FutureOfWork() {
    return (
        <BoxWrapper style={{ background: "#FFFFFF" }}>
            <ChartHeaderText>The Future of Work</ChartHeaderText>
            <hr />
            <ChartsWrapper style={{marginTop:"42px"}}>
                <ChartsView text={"Cognitive"} />
                <ChartsView text={"Digital"} />
            </ChartsWrapper>
            <ChartsWrapper>
                <ChartsView text={"Self Leadership"} />
                <ChartsView text={"Interpersonal"} />
            </ChartsWrapper>
        </BoxWrapper>
    )
}
