import { createSlice } from "@reduxjs/toolkit";
import { getUsersByUniversityManagment } from "../actions/employeeManagement";

const initialState = {
  masterdataRoles: [],
  data: undefined,
  totalRows: 0,
  page: 1,
  perPage: 10,
  sortField: null,
  search: null,
  roles: null,
  sortDirection: null,
  registration:null,
  is_assessment_done:null,
};

export const employeeManagementReducer = createSlice({
  name: "employeeManagementReducer",
  initialState,
  reducers: {
    setTotalRows: (state, action) => {
      state.totalRows = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setPerPage: (state, action) => {
      state.perPage = action.payload;
    },
    setSortField: (state, action) => {
      state.sortField = action.payload;
    },
    setSearch: (state, action) => {
      state.search = action.payload;
    },
    setRegistration: (state, action) => {
      state.registration = action.payload;
    },
    setAssessment: (state, action) => {
      state.is_assessment_done = action.payload;
    },
    setRoles: (state, action) => {
      state.roles = action.payload;
    },
    setSortDirection: (state, action) => {
      state.sortDirection = action.payload;
    },
    setMasterdataRoles: (state, action) => {
      state.masterdataRoles = action.payload;
    },
    resetAll: (state) => {
      state.data = undefined;
      state.totalRows = 0;
      state.page = 1;
      state.perPage = 10;
      state.sortField = null;
      state.search = null;
      state.roles = null;
      state.sortDirection = null;
      state.registration = null;
      state.is_assessment_done = null;

    },
  },
  extraReducers: {
    [getUsersByUniversityManagment.fulfilled]: (state, action) => {
      state.data = action.payload.data.results;
      state.totalRows = action.payload.data.count;
    },
    [getUsersByUniversityManagment.rejected]: (state) => {
      state.data = [];
      state.totalRows = 0;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  setTotalRows,
  setPage,
  setPerPage,
  setSortField,
  setSearch,
  setRoles,
  setSortDirection,
  resetAll,
  setMasterdataRoles,
  setRegistration,
  setAssessment,
} = employeeManagementReducer.actions;

export default employeeManagementReducer.reducer;
