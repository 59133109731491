import { useState, useEffect, useCallback } from "react";
import { Colors } from "../../utilities/colors";
import { useSelector, useDispatch } from "react-redux";

import styled from "styled-components";

import { menus, menusNewList } from "../../menuItems";
import Logo from "../../assets/mynextLogo.png";

import smallLogo from "../../assets/svg/smallLogo.svg";
import sidebarToggle from "../../assets/pngwing.png";

import { updateOrgData } from "../../pages/login/reducer";
import {
  setReloadPage,
  updateCurrentlySelectedCompanyData,
  updateUserAcceptedStatus,
} from "../../pages/myCompany/reducer";
import { updateSubscriptionData } from "../../pages/Subscriptions/reducer";
import { updateCurrentUser } from "../../pages/userManagement/userManagerReducer";
import { useNavigate, NavLink, useLocation } from "react-router-dom";
import {
  getCompanyData,
  getOrgData,
  authLogout,
  getCompanySubscription,
} from "../../services/apiCalls";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import moment from "moment";
import { useMediaQuery } from 'react-responsive';
import { updateSubMenu } from "./sidebarReducer";

const SidebarWrapper = styled.nav`
  // padding: 0px 3rem;
  //overflow-y:scroll;
  @media (max-width: 767px) {
    left: -100%;
  }
`;
const screenWidth = window.innerWidth;



const MenuDiv = styled.div`
  // min-height:35vh;
  width: 100%;
  // height: 100%;
  margin: 0px;
  display: flex;
  flex-direction: column;
  padding: 1rem 0;
`;



const SideNavHeaderImageSubTitle = styled.span`
  color: #d04d8b;
  position: absolute;
  bottom: -1vh;
  right: 2rem;
`;
export default function Sidebar() {
 
  // //navebar open/close
  // const [sidebar, setSidebar] = useState(localStorage.getItem("sidebar") === "true" ? true : false);
  // const [isSidebarClicked, setIsSidebarClick] = useState(localStorage.getItem("isSidebarClicked") === "true" ? true : false);
  // const [menuItemDisplay, setMenuItemDisplay] = useState("flex" );
  // // const [menuItemDisplay, setMenuItemDisplay] = useState(localStorage.getItem("menuItemDisplay") === "flex" ? "flex" : "none");
  // const [navSize, setNavSize] = useState( "346px");
  // //menuitems
  // const [menuListMargin, setMenuListMargin] = useState("3rem" );
  // const [menuIconsMargiin, setMenuIconsMargin] = useState("0px");

  const [sidebar, setSidebar] = useState(localStorage.getItem("sidebar") === "true" ? true : false);
  const [isSidebarClicked, setIsSidebarClick] = useState(localStorage.getItem("isSidebarClicked") === "true" ? true : false);
  const [menuItemDisplay, setMenuItemDisplay] = useState(localStorage.getItem("menuItemDisplay") === "flex" ? "flex" : "none");
  const [navSize, setNavSize] = useState(localStorage.getItem("navSize") === "346px" ? "346px" : "120px");
  //menuitems
  const [menuListMargin, setMenuListMargin] = useState(localStorage.getItem("menuListMargin") === "5rem" ? "5rem" : "2rem");
  const [menuIconsMargiin, setMenuIconsMargin] = useState(localStorage.getItem("menuIconsMargiin") === "0px" ? "0px" : "20px");

//subMenuIcon
const [subMenuIcon, setSubMenuIcon] = useState(localStorage.getItem("subMenuIcon") === "-40px" ? "-40px" : "-20px");
//subMenuItems
const [subMenuItems, setSubMenuItems] = useState(localStorage.getItem("subMenuItems") === "block" ? "block" : "none");
const [subMenuItemsArrow, setSubMenuItemsArrow] = useState(localStorage.getItem("subMenuItemsArrow") === "false" ? false : true);

//Logo Values
const [mainLogo, setMainLogo] =  useState(localStorage.getItem("mainLogo") === "Logo" ? Logo : smallLogo);
const [widthMainLogo, setWidthMainLogo] = useState(localStorage.getItem("widthMainLogo") === "202px" ? "202px" : "120px");
const [marginMainLogo, setMarginMainLogo] = useState(localStorage.getItem("marginMainLogo") === "66px 104px 10px 70px" ? "66px 104px 10px 70px" : "66px 104px 10px 40px");

  // //subMenuIcon
  // const [subMenuIcon, setSubMenuIcon] = useState("-40px");
  // //subMenuItems
  // const [subMenuItems, setSubMenuItems] = useState(localStorage.getItem("subMenuItems") === "block" ? "block" : "none");
  // const [subMenuItemsArrow, setSubMenuItemsArrow] = useState(localStorage.getItem("subMenuItemsArrow") === "false" ? false : true);

  // //Logo Values
  // const [mainLogo, setMainLogo] =  useState(localStorage.getItem("mainLogo") === "Logo" ? Logo : smallLogo);
  // const [widthMainLogo, setWidthMainLogo] = useState(localStorage.getItem("widthMainLogo") === "202px" ? "202px" : "120px");
  // const [marginMainLogo, setMarginMainLogo] = useState(localStorage.getItem("marginMainLogo") === "66px 104px 10px 70px" ? "66px 104px 10px 70px" : "66px 104px 10px 40px");

  const [dynamicChildrenCheck,setDynamicChildrenCheck] = useState({});
  const { t, } = useTranslation();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const [activeLink, setActiveLink] = useState("");
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const [userStatus, setUserStatus] = useState(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const org = useSelector((state) => state.org.orgData);
  const companyData = useSelector((state) => state.company.currentlySelectedcompanyData);
  const selected_org_code = useSelector((state) => state.company.selectedGlobalOrgCode)
  const cpyOrgData = useSelector((state) => state.company.currentOrgData)
  const isCompanyChange = useSelector((state) => state.company.reloadPage)
  const companyStatus = companyData.status
  const subscribedData = useSelector((state) => state.subscription.subscriptionData);
  const user = useSelector((state) => state.user.userData);
  const sideBarState = useSelector((state) => state.sideBar.subMenuState);

  /*const getUserData = async (code) => {
    if (code) {
      const { data, status } = await onGettingCompanyInfo({ code: code });
      if (data && data.status === 1) {
        // setOrgUser(data.data["org_user"]);
        // setUserInfo(data.data);
      }
    }
  };*/


  const MenuList = styled(NavLink)`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  font-family: "General Sans";

  font-weight: 500;
  color: rgb(172, 172, 172);

  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-decoration: none;
  // padding-left: ${menuListMargin};
  padding-left : ${!sidebar===true?"5rem" : "4rem"};
  cursor: pointer;
  @media (min-height: 1024px) {
    padding-left : ${!sidebar===true?"3rem" : "4rem"};
  
  }
  &:hover {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
   
  }

  &.active {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
    // background-color: ${Colors.lightPink};
    //box-shadow: -5px 2px 5px 0px rgba(0,0,0,0.28) inset;
    border-radius:5px;
    border-left: 1px solid rgba(0,0,0,0.08);
    padding-bottom: 10px;
  }
`;


const StaticMenuList = styled.span`
padding: 10px 0px;
display: flex;
align-items: center;
font-family: "General Sans";

font-weight: 500;
color: rgb(172, 172, 172);

font-size: 18px;
line-height: 24px;
letter-spacing: 1px;
text-decoration: none;
// padding-left: ${menuListMargin};
padding-left : ${!sidebar===true?"5rem" : "4rem"};
cursor: pointer;
@media (min-height: 1024px) {
  padding-left : ${!sidebar===true?"3rem" : "4rem"};

}
&:hover {
  color: ${Colors.pink};
  border-right: 5px solid ${Colors.pink};
 
}

// &.active {
//   color: ${Colors.pink};
//   border-right: 5px solid ${Colors.pink};
//   // background-color: ${Colors.lightPink};
//   //box-shadow: -5px 2px 5px 0px rgba(0,0,0,0.28) inset;
//   border-radius:5px;
//   border-left: 1px solid rgba(0,0,0,0.08);
    padding-bottom: 10px;
// }
`;
  const SideNavHeaderImage = styled.div`
  //width: ${widthMainLogo};
  width: ${sidebar===false ? "202px" : "102px"};
  margin: ${sidebar===false ? "66px 104px 10px 33px" : "66px 104px 10px 55px" };
  height: 66px;
  //margin: ${marginMainLogo};
  position: relative;
  cursor: pointer;
  @media (max-width: 768px) {
    width: 12px;
    height: 56px;
    margin: ${sidebar===false ? "66px 104px 10px 70px" : "66px 104px 10px 55px" };
  }
  
`;

  const MenuSpanText = styled.p`
  width: 350px;
  font-weight: 500;
  font-size: 18px;
  // display: ${menuItemDisplay};
  display: ${!sidebar===true ? "flex" : "none"};
  line-height: 24px;
  margin-left: 18px;
  margin-top: 7px;
  @media (max-width: 1024px) {
    display: none;
  }
  
 `;
  const SubMenuList = styled(NavLink)`
  padding: 10px 0px;
  display: flex;
  align-items: center;
  font-family: "General Sans";

  font-weight: 500;
  color: rgb(172, 172, 172);

  line-height: 24px;
  letter-spacing: 1px;
  text-decoration: none;
  // padding-left: ${menuListMargin};
  padding-left : ${!sidebar===true?"6rem" : "5rem"};
  @media (min-height: 1024px) {
    padding-left : ${!sidebar===true?"4rem" : "4rem"};
  
  }
  cursor: pointer;
  &:hover {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
   
  }

  &.active {
    color: ${Colors.pink};
    border-right: 5px solid ${Colors.pink};
    // background-color: ${Colors.lightPink};
    //box-shadow: -5px 2px 5px 0px rgba(0,0,0,0.28) inset;
    border-radius:5px;
   // border-left: 1px solid rgba(0,0,0,0.08);
    padding-bottom: 10px;
  }
  // @media (max-width: 1024px) {
  //   width: 40px;
  // }
  `;


  const NavbarSection = styled.div`
  // min-width: 346px;
  height: 150vh;
  position: sticky;
  
  top: 0;
  margin:${sidebar? '10px' : '0px'};
  background: #ffffff;
  // width: ${navSize};
  width:${!sidebar===true? '346px' : '160px'};
  
  border-radius:${sidebar? '10px 10px 10px 10px' : '0px 10px 10px 0px'} ;
  @media (max-width: 767px) {
   // display: none;
    left : ${sidebar? '-36%' : '0%'};
    position : absolute;
    width: 130px; 
  }
   @media (min-width: 738px) and (max-width: 991px) {
    // display: none;
     //left : ${sidebar? '-19%' : '0%'};
     right : ${sidebar? `99%` : 'null'};
     position : absolute;
     width: 130px; 
   }
  
`;
const MenuSpanSubIcons = styled.span`
  width: auto;
  margin-bottom: 1rem;
  position : absolute;
  margin-left: ${subMenuIcon};
  padding-top: 5px;
  @media (max-width: 767px) {
    margin-left: -60px;
  }
 `;

//  const InnerMenuSpanSubIcons = styled.span`
//  width: auto;
//  margin-bottom: 1rem;
 
//  margin-left : 15px;
// `; 
const InnerMenuSpanSubIcons = styled.span`
width: auto;
margin-bottom: 1rem;
margin-left : ${menuIconsMargiin};
@media (max-width: 767px) {
  margin-left: -20px;
}
`;

const SubMenuSpanText = styled.p`
  width: 250px;
  font-weight: 500;
  // display: ${menuItemDisplay};
  display: ${!sidebar===true ? "flex" : "none"};
  font-size: 15px;
  line-height: 24px;
  margin-left: 16px;
  margin-top: unset;
  @media (max-width: 1024px) {
    display: none;
  }
  
 `;

 const OpenCloseNavIcon = styled.div`
  margin-bottom: -45px;
  margin-top:-45px;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  box-shadow: 0px 2px 5px 0px rgba(0,0,0,0.28) ;
  border-radius:5px;
  background-color: ${Colors.pink};
  
  
  `;

  const MenuSpanIcons = styled.span`
  width: auto;
  margin-bottom: 1rem;
  margin-left : ${menuIconsMargiin};
  @media (max-width: 767px) {
    margin-left: -20px;
  }
`;

  const SubMenu = styled.div`
  display:  ${ props => {
   return JSON.parse(JSON.stringify(sideBarState))[props.index] ? JSON.parse(JSON.stringify(sideBarState))[props.index] : 'none'}};
    // display: ${subMenuItems};

  
  padding-left: 0px;
  cursor: pointer;
  &:active {
    border-radius:5px;
  };

`;

const showSubMenuItems = () => {
  setSubMenuItems(subMenuItems === 'none' ? 'block' : 'none');
  //setSubMenuItems(subMenuItems === 'flex'? 'none': 'flex');
  setSubMenuItemsArrow(!subMenuItemsArrow);

}
const showIsSidebarClicked = ()=>{
  setIsSidebarClick(!isSidebarClicked);
  showSidebar();
}
const showSidebar = () => {
  
  setSubMenuIcon(subMenuIcon === '-40px' ? '-20px' : '-40px');
  // setSubMenuItems(subMenuItems === 'flex'? 'none': 'flex');
  setMenuItemDisplay(menuItemDisplay === 'flex' ? 'none' : 'flex');
  setNavSize(navSize === '346px' ? '120px' : '346px');
  setMenuListMargin(menuListMargin === '5rem' ? '4rem' : '5rem');
  setMainLogo(mainLogo === Logo && !sidebar? smallLogo : Logo);
  setWidthMainLogo(widthMainLogo === "202px" ? '102px' : '202px');
  setMarginMainLogo(marginMainLogo === '66px 104px 10px 70px' ? '66px 104px 10px 40px' : '66px 104px 10px 70px')
  setMenuIconsMargin(menuIconsMargiin === '0px' ? '20px' : '0px');
  setSidebar(!sidebar);
};

  useEffect(() => {
    if (companyData && companyData.org_user !== undefined) {
      let currentUser = companyData.org_user.filter(
        (item) => item.email === user.email
      )[0];
      dispatch(updateUserAcceptedStatus(currentUser?.status));
      setUserStatus(currentUser?.status);
      dispatch(updateCurrentUser(currentUser));
    }
    localStorage.setItem("companyCheck",companyStatus);
  }, [companyData, selected_org_code]);

  useEffect(() => {
    if (isEmpty(companyData) && selected_org_code) {
      getData(selected_org_code);
    } else if (isEmpty(org)) {
      getOrganisationInfo();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    if (
      !isEmpty(subscribedData) &&
      isEmpty(subscribedData[selected_org_code])
    ) {
      getSubscription();
    }
  }, []);

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  const getSubscription = async () => {
    const { data } = await getCompanySubscription(selected_org_code);
    if (data && data.status === 1 && data.data.length) {
      let localObj = {};
      localObj[selected_org_code] = data.data;
      console.log("localObject",localObj);
      dispatch(updateSubscriptionData(localObj));
    }
  };
  const getData = async (code) => {
    var result1 = await getOrgData();
    if (result1.status === "401") {
      navigate("/login");
    }
    const { data } = await getCompanyData({
      code: code,
    });

    if (data && data.status === 1) {
      setLoading(false);
      dispatch(updateCurrentlySelectedCompanyData(data.data));
    } else {
      setLoading(false);
    }
  };

  const getOrganisationInfo = async () => {
    const { data } = await getOrgData();
    if (data && data.status === 1) {
      dispatch(updateOrgData(data.data));
      setCurrentUserEmail(data.data["email"]);
    } else {
      setLoading(false);
    }
  };
  const MenuDisabledList = styled.div`
    padding: 10px 0px;
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: 1px;
    display: flex;
    align-items: center;
    color: #acacac;
    margin-left: 5rem;
    text-decoration: none;
  `;
  const [companyDetails, setCompanyDetails] = useState({
    name: "",
    profile_picture: "",
    address: "",
  });
  const onNavigate = () => {
    navigate({ pathname: "/" });
  };

  const getActiveLocation = useCallback(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    getActiveLocation();
    if (companyData.hasOwnProperty("name")) {
      const { name, logo_path } = companyData;

      setCompanyDetails({
        name: name,
        address: "companyData",
        profile_picture: logo_path,
      });
    }
    localStorage.setItem("sidebar", JSON.stringify(sidebar));
    localStorage.setItem("isSidebarClicked", isSidebarClicked);
    localStorage.setItem("subMenuIcon", subMenuIcon);
    localStorage.setItem("menuItemDisplay", menuItemDisplay);
    localStorage.setItem("navSize", navSize);
    localStorage.setItem("menuListMargin", menuListMargin);
    localStorage.setItem("mainLogo", mainLogo);
    localStorage.setItem("widthMainLogo", widthMainLogo);
    localStorage.setItem("marginMainLogo", marginMainLogo);
    localStorage.setItem("menuIconsMargiin", menuIconsMargiin);
    localStorage.setItem("subMenuItems", subMenuItems);
    localStorage.setItem("subMenuItemsArrow", subMenuItemsArrow);
  }, [companyData, getActiveLocation,isSidebarClicked,sidebar,subMenuIcon,menuItemDisplay,navSize,menuListMargin,mainLogo,widthMainLogo,marginMainLogo,menuIconsMargiin,subMenuItems,subMenuItemsArrow]);
  const onUserLogout = async (title, path) => {
    if (title === t("Logout")) {
      const { data } = await authLogout();
      if (data && data.status === 1) {
        localStorage.removeItem("auth_token");
        window.location.assign("/login");
      }
    }
    if (title === "Help") {
      window.open("https://www.mynext.my/contactus", "_blank");
      setTimeout(() => {
        navigate(-1);
      }, 600);
    }
  };

  const rgtr_address =
    companyData.locations && companyData.locations.length > 0
      ? companyData.locations[0].status === "primary"
        ? companyData.locations[0].rgtr_address
        : companyData.locations[1].status === "primary"
        ? companyData.locations[1].rgtr_address
        : ""
      : "";
  const state =
    companyData.locations && companyData.locations.length > 0
      ? companyData.locations[0].status === "primary"
        ? companyData.locations[0].state_abbr
        : companyData.locations[1].status === "primary"
        ? companyData.locations[1].state_abbr
        : ""
      : "";

  const city =
    companyData.locations && companyData.locations.length > 0
      ? companyData.locations[0].status === "primary"
        ? companyData.locations[0].city_abbr
        : companyData.locations[1].status === "primary"
        ? companyData.locations[1].city_abbr
        : ""
      : "";

  function creteDisabledMenu(menuItem, i) {
    return menuItem.name == 'Dashboard' || menuItem.name == 'My Profile' ?
      <MenuList
        key={i}
        to={menuItem.path}
        data-tip={menuItem.name}
        data-for={menuItem.name}
        data-tip-disable={!sidebar ? true : false}
        onClick={() => {
          onUserLogout(menuItem.name, menuItem.path);
        } }

      >

        <MenuSpanSubIcons onClick={(e) => {
          e.stopPropagation(); // prevent click event from bubbling up to the parent menuItem element
          e.preventDefault();
          showSubMenuItems();
        }}>
          {/* {menuItem.isOpen || menuItem.isClosed ? menuItem.isOpen : menuItem.isClosed ? menuItem.isClosed : null} */}
          {menuItem.children && !subMenuItemsArrow ? menuItem.isOpen : menuItem.isClosed ? menuItem.isClosed : null}

        </MenuSpanSubIcons>

        <MenuSpanIcons>
          {activeLink === menuItem.path ? menuItem.icon : menuItem.icon}
        </MenuSpanIcons>

        <MenuSpanText >{t(menuItem.name)}</MenuSpanText>

        <ReactTooltip id={menuItem.name} place="right" effect="solid" delayShow={300} >
          {menuItem.name}
        </ReactTooltip>

      </MenuList>
     : (
      <div>
        {/* <ReactTooltip className="dataToolTip"/> */}
        <MenuDisabledList key={i} data-html={true} data-place="right" >


          <MenuSpanIcons >{menuItem.icon}</MenuSpanIcons>
          <MenuSpanText data-html={true} data-tip={t('Company approval is pending<br />with mynext Admin')} >{t(menuItem.name)}</MenuSpanText>
          <ReactTooltip  className="dataToolTip"/>

        </MenuDisabledList>
      </div>
    );
  }

  function creteDisabledMenuRenew(menuItem, i) {
    return menuItem.name == 'Dashboard' || menuItem.name == 'Settings' ?
      <MenuList
        key={i}
        to={menuItem.path}
        data-tip={menuItem.name}
        data-for={menuItem.name}
        data-tip-disable={!sidebar ? true : false}
        onClick={() => {
          onUserLogout(menuItem.name, menuItem.path);
        } }

      >

        <MenuSpanSubIcons onClick={(e) => {
          e.stopPropagation(); // prevent click event from bubbling up to the parent menuItem element
          e.preventDefault();
          showSubMenuItems();
        }}>
          {/* {menuItem.isOpen || menuItem.isClosed ? menuItem.isOpen : menuItem.isClosed ? menuItem.isClosed : null} */}
          {menuItem.children && !subMenuItemsArrow ? menuItem.isClosed : menuItem.isOpen ? menuItem.isOpen : null}

        </MenuSpanSubIcons>

        <MenuSpanIcons>
          {activeLink === menuItem.path ? menuItem.icon : menuItem.icon}
        </MenuSpanIcons>

        <MenuSpanText >{t(menuItem.name)}</MenuSpanText>

        <ReactTooltip id={menuItem.name} place="right" effect="solid" delayShow={300} >
          {menuItem.name}
        </ReactTooltip>

      </MenuList>
     : 
      <div>
        {/* <ReactTooltip className="dataToolTip"/> */}
        <MenuDisabledList key={i} data-tip='Your subscription is expired. Please renew to use this feature' data-html={true} data-place="right" >



          <MenuSpanIcons >{menuItem.icon}</MenuSpanIcons>
          <MenuSpanText>{t(menuItem.name)}</MenuSpanText>
          <ReactTooltip className="dataToolTip" />
        </MenuDisabledList>
      </div>
  }

  function createSubMenus(menuItemSub, j){
    return (
      <SubMenuList
        key={j}
        to={menuItemSub.path}
        data-tip={menuItemSub.name}
        data-for={menuItemSub.name}
        data-tip-disable={!sidebar ? true : false}
      >
        <InnerMenuSpanSubIcons>
          {activeLink === menuItemSub.path ? menuItemSub.icon : menuItemSub.icon}
        </InnerMenuSpanSubIcons>
        <SubMenuSpanText>{t(menuItemSub.name)}</SubMenuSpanText>
        <ReactTooltip id={menuItemSub.name} place="right" effect="solid" delayShow={300} >
          {menuItemSub.name}
        </ReactTooltip>
      </SubMenuList>
    );
  }
  function createMenus(menuItem, i) {
    return menuItem.name === "Talent Acquision" || menuItem.name === "Talent Management" || menuItem.name === "Users" ?
    <StaticMenuList
      key={i}
      // to={menuItem.name}
      data-tip={menuItem.name}
      data-for={menuItem.name}
      data-tip-disable={!sidebar ? true : false}
      onClick={() => {
        onUserLogout(menuItem.name, menuItem.path);
      } }

    >

      <MenuSpanSubIcons onClick={(e) => {
        let getDynamicCheck = JSON.parse(JSON.stringify(sideBarState)) ;
        getDynamicCheck[i] =  getDynamicCheck[i] === 'block' ? 'none' : 'block';
        setDynamicChildrenCheck(getDynamicCheck);
        dispatch(updateSubMenu(getDynamicCheck));
        e.stopPropagation(); // prevent click event from bubbling up to the parent menuItem element
        e.preventDefault();
        showSubMenuItems();
      }}>
        {/* {menuItem.isOpen || menuItem.isClosed ? menuItem.isOpen : menuItem.isClosed ? menuItem.isClosed : null} */}
        {menuItem.children && JSON.parse(JSON.stringify(sideBarState))[i] === 'block' ? menuItem.isClosed : menuItem.isOpen ? menuItem.isOpen : null}

      </MenuSpanSubIcons>

      <MenuSpanIcons>
        {activeLink === menuItem.path ? menuItem.icon : menuItem.icon}
      </MenuSpanIcons>

      <MenuSpanText >{t(menuItem.name)}</MenuSpanText>

      <ReactTooltip id={menuItem.name} place="right" effect="solid" delayShow={300} >
        {menuItem.name}
      </ReactTooltip>

    </StaticMenuList>
    :
     <MenuList
      key={i}
      to={menuItem.path}
      data-tip={menuItem.name}
      data-for={menuItem.name}
      data-tip-disable={!sidebar ? true : false}
      onClick={() => {
        onUserLogout(menuItem.name, menuItem.path);
      } }

    >

      <MenuSpanSubIcons onClick={(e) => {
        let getDynamicCheck = JSON.parse(JSON.stringify(sideBarState)) ;
        getDynamicCheck[i] =  getDynamicCheck[i] === 'block' ? 'none' : 'block';
        setDynamicChildrenCheck(getDynamicCheck);
        dispatch(updateSubMenu(getDynamicCheck));
        e.stopPropagation(); // prevent click event from bubbling up to the parent menuItem element
        e.preventDefault();
        showSubMenuItems();
      }}>
        {/* {menuItem.isOpen || menuItem.isClosed ? menuItem.isOpen : menuItem.isClosed ? menuItem.isClosed : null} */}
        {menuItem.children && JSON.parse(JSON.stringify(sideBarState))[i] === 'block' ? menuItem.isOpen : menuItem.isClosed ? menuItem.isClosed : null}

      </MenuSpanSubIcons>

      <MenuSpanIcons>
        {activeLink === menuItem.path ? menuItem.icon : menuItem.icon}
      </MenuSpanIcons>

      <MenuSpanText >{t(menuItem.name)}</MenuSpanText>

      <ReactTooltip id={menuItem.name} place="right" effect="solid" delayShow={300} >
        {menuItem.name}
      </ReactTooltip>

    </MenuList>
  
  }

  useEffect(async()=>{
    console.log("this is calling",isCompanyChange);
    if(isCompanyChange){
      dispatch(updateSubMenu({}));
      // window.location.reload();
    // await delay(200);
      dispatch(setReloadPage(false))
    }
  },[isCompanyChange])
  useEffect(() => {
    // console.log("subscriptData",subscribedData,"selectedOrf",selected_org_code)
    if(subscribedData && selected_org_code){
      localStorage.setItem("subscriptionStatus",subscribedData[selected_org_code]?.paymentstatus);
    }
  
  }, [subscribedData,selected_org_code]);
  
  function createMenuItem(menuItem, i) {
    localStorage.setItem("userStatusStore",userStatus);

    // console.log("subscriptData",subscribedData,"selectedOrf",selected_org_code)

    if (companyStatus == 'accepted' &&  subscribedData[selected_org_code]  && subscribedData[selected_org_code].paymentstatus == 'success' && userStatus !== undefined) {
      
      
      //&&  !(menuItem.name.toLowerCase() == 'employee management' && !(isEmpty(subscribedData) || isEmpty(subscribedData[selected_org_code])) ) ){
      // if((cpyOrgData?.user_details?.internship_view != 'Y' && menuItem.name.toLowerCase() == 'internships') || (menuItem.name.toLowerCase() == 'internships' && userStatus != 'accepted')){
      //   return  <></>
      // }
      // else 
      // if (cpyOrgData?.user_details?.company_view != 'Y' && menuItem.name.toLowerCase() == 'my company' && userStatus == 'accepted') {
      //   return <></>
      // }
      // else
       if ((cpyOrgData?.user_details?.user_management_view != 'Y' && menuItem.name.toLowerCase() == 'user management') || (menuItem.name.toLowerCase() == 'user management' && userStatus != 'accepted')) {
        return <></>
      }
      else if ((cpyOrgData?.user_details?.view_job != 'Y' && menuItem.name.toLowerCase() == 'jobs') || (menuItem.name.toLowerCase() == 'jobs' && userStatus != 'accepted')) {
        return <></>
      }
      else if (userStatus != 'accepted' && (!["dashboard", "my company", "my profile", "subscriptions", "internships", "national structured internship programme (mysip) application"].includes(menuItem.name.toLowerCase())))
      {
        return <></>
      }
      // else if(checkIsSubscriptionExpired(subscribedData[selected_org_code]) && !["dashboard", "my company", "my profile", "subscriptions", "internships"].includes(menuItem.name.toLowerCase())){
      //   console.log("this Menu Disable Subscriptions");
      //   return creteDisabledMenuRenew(menuItem, i)
      // }
       else if(checkIsSubscriptionExpired(subscribedData[selected_org_code]) && ["dashboard", "my company", "my profile", "subscriptions", "internships", "national structured internship programme (mysip) application"].includes(menuItem.name.toLowerCase())){
          console.log("this Menu Disable Subscriptions",checkIsSubscriptionExpired(subscribedData[selected_org_code]) );
          return createMenus(menuItem, i, companyStatus)
        }
      else if (!checkIsSubscriptionExpired(subscribedData[selected_org_code]) && (![ "my company", "my profile", "subscriptions", "internships"].includes(menuItem.name.toLowerCase()))) {
        return createMenus(menuItem, i)
      }else{
        return <></>
      }
    } else if (companyStatus == 'accepted' &&  subscribedData[selected_org_code]  && subscribedData[selected_org_code].paymentstatus == 'success' && userStatus !== undefined){
      return <></>
    } else if ('accepted' != companyStatus && undefined != companyStatus && ["dashboard", "my company", "my profile", "subscriptions", "internships", "national structured internship programme (mysip) application"].includes(menuItem.name.toLowerCase())) {
      console.log("this Menu Disable Accepteds");
      return creteDisabledMenu(menuItem, i)
    } else if (['accepted', 'pending', 'denied', 'review'].includes(companyStatus) && ["dashboard", "my company", "my profile", "subscriptions", "internships", "national structured internship programme (mysip) application"].includes(menuItem.name.toLowerCase())) {

      // console.log("this Menu Disable Subscription Not taken",subscribedData[selected_org_code].paymentstatus,i);
      // console.log("this Menu Disable CompanyStatus Not taken",companyStatus);
      return createMenus(menuItem, i, companyStatus)
    }
    else {
      return <></>
    }
  }

  function checkIsSubscriptionExpired(data) {
    let today = moment(new Date()).format("MM-DD-YYYY");
    today = new Date(today);
    let renew_date = data.renew_date.split("-");
    renew_date = new Date(
      renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2]
    );
    return today > renew_date;
  }

  const Mobile = ({children}) => {
    const isMobile = useMediaQuery({ maxWidth: 767 });
    return isMobile ? children : null;
  } 
  
  const Desktop = ({children}) => {
    const isDesktop = useMediaQuery({ minWidth: 992 });
    return isDesktop ? children : null;
  }
  const Tablet = ({ children }) => {
    const isTablet = useMediaQuery({ minWidth: 768, maxWidth: 991 });
    return isTablet ? children : null;
  };
  return (
    <> 
      {  <Desktop>
      <NavbarSection style={{ zIndex: 1 }}>     
      <SidebarWrapper>      
           <OpenCloseNavIcon onClick={showSidebar} style={{ marginLeft: sidebar===false ? "330px" : "145px" }}>       
               <img src={sidebar===false ? sidebarToggle : sidebarToggle} style={{ width: sidebar===false ? "20px" : "20px", transform: sidebar===false ? 'rotate(180deg)' : 'rotate(0deg)' }} />         </OpenCloseNavIcon>
               {sidebar===false ?  <SideNavHeaderImage onClick={onNavigate}  >      
                  <img height={'100px'}  src={ Logo}  />       
                  {/* <SideNavHeaderImageSubTitle  >  
                     <h5>COMPANY</h5>           
                  </SideNavHeaderImageSubTitle>          */}
                </SideNavHeaderImage> : <SideNavHeaderImage onClick={onNavigate}  >      
                  <img height={'50px'}  src={ smallLogo}  />       
                  {/* <SideNavHeaderImageSubTitle  >  
                     <h5>COMPANY</h5>           
                  </SideNavHeaderImageSubTitle>          */}
                </SideNavHeaderImage>
     }
     
     <MenuDiv>
         {menus.map((menuItem, i) => {
           return  <>
           {createMenuItem(menuItem, i)}
           <SubMenu index={i}>
           {menuItem.children && menuItem.children.length > 0 && menuItem.children.map((menuItemSub, j) => createSubMenus(menuItemSub,j))}
           </SubMenu>
           {/*companyStatus == 'accepted' ?
             createMenuItem(menuItem, i) : <>
               {(['pending', 'denied'].includes(companyStatus) && ["dashboard", "my company", "settings"].includes(menuItem.name.toLowerCase())) ?
                 createMenuItem(menuItem, i) : <></>
               }</>
             */}
         </>
         } )}
        {companyDetails.name && (
             <div className="company_profile_set" style={!sidebar ? { textAlign: "center" } : { textAlign: "center", marginLeft: "20px" }} >
               <img
                 src={companyDetails.profile_picture}
                 alt="company_profile_picture"
                 style={!sidebar ? { width: "100px", height: "100px", borderRadius: "50%" } : { width: "70px", height: "70px", borderRadius: "50%", marginLeft: "7px" }}
               />
               <p style={!sidebar ? { fontSize: "40px", marginBottom: "0", color: "#D14B8D" } : { fontWeight: '600', textAlign: 'center', width: '90px', fontSize: "20px", marginTop: "10px", color: "#D14B8D" }}>
                 {companyDetails.name}
               </p>
               <p
                 style={!sidebar ? { fontSize: "16px", marginBottom: "0" } : { display: 'none' }}
               >{`${rgtr_address} ${city}
         ${state}`}</p>
             </div>
           )}
       </MenuDiv>
      
      
     
     </SidebarWrapper>
     </NavbarSection>
      </Desktop>}
      <Mobile>
     <NavbarSection style={{ zIndex: 1 }}>
     <SidebarWrapper>
         <OpenCloseNavIcon onClick={showSidebar} style={{ marginLeft: sidebar===false ? "120px" : "135px" }}>
           <img src={sidebar===false ? sidebarToggle : sidebarToggle} style={{ width: sidebar===false ? "20px" : "20px", transform: sidebar===false ? 'rotate(180deg)' : 'rotate(0deg)' }} />
         </OpenCloseNavIcon>
      
         <SideNavHeaderImage onClick={onNavigate} style={{ width: "89px", margin:"66px 104px 10px 50px" }} >
         <img src={smallLogo} style={{ width: "30px", marginLeft:"5px"}}  />
           <SideNavHeaderImageSubTitle  >
             <h5 style={{fontSize:"15px"}} >COMPANY</h5>
           </SideNavHeaderImageSubTitle>
         </SideNavHeaderImage>
     
       <MenuDiv>
         {menus.map((menuItem, i) => (
           <>
             {createMenuItem(menuItem, i)}
             <SubMenu index={i}>
             {menuItem.children && menuItem.children.length > 0 && menuItem.children.map((menuItemSub, j) => createSubMenus(menuItemSub,j))}
             </SubMenu>
             {/*companyStatus == 'accepted' ?
               createMenuItem(menuItem, i) : <>
                 {(['pending', 'denied'].includes(companyStatus) && ["dashboard", "my company", "settings"].includes(menuItem.name.toLowerCase())) ?
                   createMenuItem(menuItem, i) : <></>
                 }</>
               */}
           </>
         ))}
        {companyDetails.name && (
             <div className="company_profile_set" style={!sidebar ? { textAlign: "center" } : { textAlign: "center", marginLeft: "20px" }} >
               <img
                 src={companyDetails.profile_picture}
                 alt="company_profile_picture"
                 style={!sidebar ? { width: "100px", height: "100px", borderRadius: "50%" } : { width: "70px", height: "70px", borderRadius: "50%", marginLeft: "7px" }}
               />
               <p style={!sidebar ? { fontSize: "20px", marginBottom: "0", color: "#D14B8D" } : { fontWeight: '600', textAlign: 'center', width: '90px', fontSize: "20px", marginTop: "10px", color: "#D14B8D" }}>
                 {companyDetails.name}
               </p>
               <p
                 style={!sidebar ? { fontSize: "16px", marginBottom: "0" } : { display: 'none' }}
               >{`${rgtr_address} ${city}
         ${state}`}</p>
             </div>
           )}
       </MenuDiv>
      
      
     
     </SidebarWrapper>
     </NavbarSection>
      </Mobile>
<Tablet>
<NavbarSection style={{ zIndex: 1 }}>
     <SidebarWrapper>
         <OpenCloseNavIcon onClick={showSidebar} style={{ marginLeft: sidebar===false ? "120px" : "135px" }}>
           <img src={sidebar===false ? sidebarToggle : sidebarToggle} style={{ width: sidebar===false ? "20px" : "20px", transform: sidebar===false ? 'rotate(180deg)' : 'rotate(0deg)' }} />
         </OpenCloseNavIcon>
      
         <SideNavHeaderImage onClick={onNavigate} style={{ width: "102px", margin:"66px 104px 10px 50px" }} >
         <img src={smallLogo}  />
           <SideNavHeaderImageSubTitle  >
             <h5>COMPANY</h5>
           </SideNavHeaderImageSubTitle>
         </SideNavHeaderImage>
     
       <MenuDiv>
         {menus.map((menuItem, i) => (
           <>
             {createMenuItem(menuItem, i)}
             <SubMenu index={i}>
             {menuItem.children && menuItem.children.length > 0 && menuItem.children.map((menuItemSub, j) => createSubMenus(menuItemSub,j))}
             </SubMenu>
             {/*companyStatus == 'accepted' ?
               createMenuItem(menuItem, i) : <>
                 {(['pending', 'denied'].includes(companyStatus) && ["dashboard", "my company", "settings"].includes(menuItem.name.toLowerCase())) ?
                   createMenuItem(menuItem, i) : <></>
                 }</>
               */}
           </>
         ))}
        {companyDetails.name && (
             <div className="company_profile_set" style={!sidebar ? { textAlign: "center" } : { textAlign: "center", marginLeft: "20px" }} >
               <img
                 src={companyDetails.profile_picture}
                 alt="company_profile_picture"
                 style={!sidebar ? { width: "100px", height: "100px", borderRadius: "50%" } : { width: "70px", height: "70px", borderRadius: "50%", marginLeft: "7px" }}
               />
               <p style={!sidebar ? { fontSize: "20px", marginBottom: "0", color: "#D14B8D" } : { fontWeight: '600', textAlign: 'center', width: '90px', fontSize: "20px", marginTop: "10px", color: "#D14B8D" }}>
                 {companyDetails.name}
               </p>
               <p
                 style={!sidebar ? { fontSize: "16px", marginBottom: "0" } : { display: 'none' }}
               >{`${rgtr_address} ${city}
         ${state}`}</p>
             </div>
           )}
       </MenuDiv>
      
      
     
     </SidebarWrapper>
     </NavbarSection>
</Tablet>
    </>
  )

  
}
