import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  userData: {},
};

export const userDataReducer = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUserData: (state, action) => {
      state.userData = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateUserData, } =
  userDataReducer.actions;

export default userDataReducer.reducer;
