import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { isAuthorizedToAccess } from "../analyticalDashboardPage/commonFunction";
// import { isAuthorizedToAccess } from "../../utilities/commonFunctions";

// import { isAuthorizedToAccess } from "../../utilities/utils";
export default function RequireAuth({ children, authorizationFor }) {
  const isLoggedIn = useSelector((state) => state.auth.isLoggedIn);
  const user = useSelector((state) => state.auth.user);
  const location = useLocation();

  return isLoggedIn && isAuthorizedToAccess(authorizationFor, user) ? (
    children
  ) : (
    <Navigate to="/login" replace state={{ path: location.pathname }} />
  );
}
