import { useEffect, useState } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import "react-phone-number-input/style.css";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Divider from "../../components/divider/divider";
import Button from "../../components/button/button";
import CoverImage from "../../assets/registerCover.png";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import {
  // registerOrg_S1,
  // updateExistingOrganisation,
  onStepOneVerification,
  onValidateDuplicateValues,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
  getSectorMasterList,
  getOrganisationTypesMasterList,
  
} from "../../services/apiCalls";
import { updateValues, updateisSameAddress } from "./reducer";
import Dropdown from "../../components/dropdown/dropdown";
import { formatDate } from "../../utilities/commonFunctions";
import { errorMessages } from "../../utilities/commonMessages";
import ErrorField from "../../components/errorField/errorField";
import Loader from "../../components/loader/loader";
import { schema } from "./staticContent";
import Input from "../../components/input/input";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import { registerOrgToolTip } from "../../utilities/commonMessages";
import Tooltip from "../../components/reactTooltip/tooltip";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { createTheme } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/styles";
import { useTranslation } from "react-i18next";

const materialTheme = createTheme({
  palette: {
    primary: {
      main: "#D44B9C",
    },
  },
  overrides: {
    MuiPickersCalendarHeader: {
      switchHeader: {
        color: "#D44B9C",
      },
    },
  },
});

// styling starts
const LoginPageWrapper = styled.section``;
// const LabelText = styled.label`
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 24px;
// `;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
// const SaveExitBtn = styled(LabelText)`
//   color: #504f8c;
//   text-decoration: underline;
//   cursor: pointer;
// `;
const FormWrapper = styled.div`
  margin: 10px 0px;
  //   max-width: 440px;
`;
const GridContainer = styled.div`
  display: grid;
  grid-column-gap: ${(props) => (props.gap ? props.gap : "30px")};
  grid-template-columns: ${(props) =>
    props.columns ? props.columns : "auto auto"};

  @media (max-width: 1060px) {
    display: block;
  }
`;
const FormLabel = styled.span`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  margin-bottom: 10px;
  display: block;
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
// const CheckOne = styled.div`
//   width: 20px;
//   margin-right: 15px;
// `;
const CheckController = styled.div`
  // display: flex;
  width: 100%;
  justify-content: left;
  margin-bottom: 10px;
  margin-top: 10px;
`;
const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;

// styling ends

export default function RegisterOrganisation() {
  const [formErrors, setFormErrors] = useState("");
  // const [fieldErrors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  // const [isNextStep, setNext] = useState(true);
  // const [countries, setCountry] = useState([]);
  // const [selectCity, setSelectCity] = useState({});
  // const [isSameAddress, setSameAddress] = useState(false);
  // const [selectState, setSelectState] = useState({});
  // const [scrollData, setScrollDatas] = useState([]);
  // const [options1, setOptions1] = useState({});
  // const [isSameAddressActive, setIsSameAddressActive] = useState(false);
  const navigate = useNavigate();
  const userDetail = useSelector((state) => state.organisation.value);
  // const orgDetail = useSelector((state) => state.organisation.successData);
  const isUpdate = useSelector((state) => state.organisation.isUpdate);
  const formErr = useSelector((state) => state.organisation.formErr);
  const isSameAddress = useSelector(
    (state) => state.organisation.isSameAddress
  );
  const [countries, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  // const [countries2, setCountry2] = useState([]);

  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState([]);

  const [bsnCountries, setBsnCountries] = useState([]);
  const [selectedBsnCountry, setSelectedBsnCountry] = useState([]);

  const [bsnState, setBsnStates] = useState([]);
  const [selectedBsnState, setSelectedBsnState] = useState([]);

  const [cities, setCities] = useState([]);
  const [selectedCity, setSelectedCity] = useState([]);

  const [bsnCities, setBsnCities] = useState([]);
  const [selectedBsnCity, setSelectedBsnCity] = useState([]);

  const [typeOfOrganization, setTypeOfOrganization] = useState([]);
  const [selectedTypeOfOrganization, setSelectedTypeOfOrganization] = useState(
    []
  );

  const [resetorg, setResetOrg] = useState(0);

  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState([]);

  const [temporaryStore, setTemporaryStore] = useState(false)
  const dispatch = useDispatch();
  const isRedirect = useSelector((state) => state.organisation.isRedirect);
  const { t } = useTranslation();
  /*useEffect(() => {
    if (isRedirect) navigate("/search");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);*/
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    setError,
    clearErrors,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: { ...userDetail },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const removeExtraSpace = (s) => s.trim().split(/ +/).join(' ');

  const formVal = watch();
  if (resetorg === 0) {
    reset({
      "name": "",
      "step": 1,
      "ssm_number": "",
      "ssm_rgtr_date": "",
      "website": "",
      "rgtr_address": "",
      "country": "Malaysia",
      "state": "",
      "city": "",
      "country_code": "",
      "logo": "",
      "bsn_address": "",
      "bsn_country": "Malaysia",
      "bsn_city": "",
      "bsn_state": "",
      "bsn_country_code": "",
      "type_of_org": "",
      "sector": "",
      "bsn_nature": ""
    })
    setResetOrg(1)
  }

  const isDisableSsmDate = formVal.ssm_number === undefined || formVal.ssm_number.length === 0 || errors.ssm_number;

  // useEffect(() => {
  //   getCountry();
  // }, []);

  // const [business_nature, setBusinessNature] = useState(formVal.bsn_nature);

  useEffect(() => {
    setValue("country_code", formVal.country_code.replace(/[^\w\s]/gi, ""));
    addressUpdate();

    // if (city && country && country_code && rgtr_address && state) {
    //   setIsSameAddressActive(true);
    // } else {
    //   setIsSameAddressActive(false);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    isSameAddress,
    formVal.state,
    formVal.city,
    formVal.country,
    formVal.rgtr_address,
    formVal.country_code,
  ]);

  const setDefaults = () => {
    sessionStorage.setItem("org_name", formVal?.name)
    sessionStorage.setItem("type_of_org", formVal?.type_of_org)
    sessionStorage.setItem("ssm_number", formVal?.ssm_number)
    sessionStorage.setItem("ssm_rgtr_date", formVal?.ssm_rgtr_date)
    sessionStorage.setItem("website", formVal?.website)
    sessionStorage.setItem("rgtr_address", formVal?.rgtr_address)
    sessionStorage.setItem("country", formVal?.country)
    sessionStorage.setItem("state", formVal?.state)
    sessionStorage.setItem("city", formVal?.city)
    sessionStorage.setItem("country_code", formVal?.country_code)
    sessionStorage.setItem("bsn_address", formVal?.bsn_address)
    sessionStorage.setItem("bsn_country", formVal?.bsn_country)
    sessionStorage.setItem("bsn_state", formVal?.bsn_state)
    sessionStorage.setItem("bsn_city", formVal?.bsn_city)
    sessionStorage.setItem("bsn_country_code", formVal?.bsn_country_code)
    sessionStorage.setItem("bsn_nature", formVal?.bsn_nature)
    sessionStorage.setItem("sector", formVal?.sector)
  }


  useEffect(() => {
  
  })

  const getDefaults = () => {
    const val = sessionStorage.getItem("saveExists")
    if (typeOfOrganization.length > 0 && countries.length > 0 && sectors.length >0 && bsnCountries.length>0&& !temporaryStore && val) {
      if (sessionStorage.getItem("org_name")) {
        setValue("name", sessionStorage.getItem("org_name"))
      }
      if (sessionStorage.getItem("type_of_org")) {
        const val = sessionStorage.getItem("type_of_org")
        const findVal = typeOfOrganization?.find((e) => e.value === val)
        setValue("type_of_org", val)
        setSelectedTypeOfOrganization({ label: findVal?.label, value: findVal?.value })
      }
      if (sessionStorage.getItem("ssm_number")) {
        setValue("ssm_number", sessionStorage.getItem("ssm_number"))
      }
      if (sessionStorage.getItem("ssm_rgtr_date")) {
        setValue("ssm_rgtr_date", sessionStorage.getItem("ssm_rgtr_date"))
      }
      if (sessionStorage.getItem("website")) {
        setValue("website", sessionStorage.getItem("website"))
      }
      if (sessionStorage.getItem("rgtr_address")) {
        setValue("rgtr_address", sessionStorage.getItem("rgtr_address"))
      }
      if (sessionStorage.getItem("country")) {
        const val = sessionStorage.getItem("country")
        const findVal = countries?.find((e) => e.value === val)
        setSelectedCountry({ label: findVal?.label, value: findVal?.value })
        setValue("country", sessionStorage.getItem("country"))
        
      }
      if (sessionStorage.getItem("state")) {
        getStateandApply(sessionStorage.getItem("country"),"state")
        setValue("state", sessionStorage.getItem("state"))
      }
      if (sessionStorage.getItem("city")) {
        getCitiesandApply(sessionStorage.getItem("country"),sessionStorage.getItem("state"),"city")
        setValue("city", sessionStorage.getItem("city"))
      }
      if (sessionStorage.getItem("country_code")) {
        setValue("country_code", sessionStorage.getItem("country_code"))
      }
      if (sessionStorage.getItem("bsn_address")) {
        setValue("bsn_address", sessionStorage.getItem("bsn_address"))
      }
      if (sessionStorage.getItem("bsn_country")) {
        const val = sessionStorage.getItem("bsn_country")
        const findVal = bsnCountries?.find((e) => e.value === val)
        setSelectedBsnCountry({ label: findVal?.label, value: findVal?.value })
        setValue("bsn_country", sessionStorage.getItem("bsn_country"))
      }
      if (sessionStorage.getItem("bsn_state")) {
        getStateandApply(sessionStorage.getItem("bsn_country"),"bsn_state")
        setValue("bsn_state", sessionStorage.getItem("bsn_state"))
      }
      if (sessionStorage.getItem("bsn_city")) {
        getCitiesandApply(sessionStorage.getItem("bsn_country"),sessionStorage.getItem("bsn_state"),"bsn_city")
        setValue("bsn_city", sessionStorage.getItem("bsn_city"))
      }
      if (sessionStorage.getItem("bsn_country_code")) {
        setValue("bsn_country_code", sessionStorage.getItem("bsn_country_code"))
      }
      if (sessionStorage.getItem("bsn_nature")) {
        setValue("bsn_nature", sessionStorage.getItem("bsn_nature"))
      }
      if (sessionStorage.getItem("sector")) {
        const val = sessionStorage.getItem("sector")
        const findVal = sectors?.find((e) => e.value === val)
        setSelectedSector({ label: findVal?.label, value: findVal?.value })
        setValue("sector", sessionStorage.getItem("sector"))
      }
      setTemporaryStore(true)
    }
  }


  const onContinue = async () => {
    sessionStorage.setItem("saveExists", true)
    setDefaults()
    navigate(`/upload`);
    setLoading(true);
    const isValid = validateFormFields();
    if (isUpdate) {
      setFormErrors("");
      setLoading(false);
      navigate(`/upload`);
    }
    if (isValid) {
      const { data } = await onStepOneVerification({ ...formVal });
      if (data && data.status === 1) {
        //registerCompany()
        setFormErrors("");
        setLoading(false);
        navigate(`/upload`);
        
      } else {
        setFormErrors(data.message);
        setLoading(false);
      }
    } else {
      setLoading(false);
      setFormErrors(errorMessages.required);
      
    }
  };

  const validateFormFields = () => {
    const formValues = { ...userDetail, ...formVal };
    dispatch(updateValues(formValues));
    return true;
  };


  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
  };

  const onFillSameAddress = (e) => {
    if (e === false) {
      dispatch(updateisSameAddress(true));
      errors.bsn_address = "";
    } else {
      dispatch(updateisSameAddress(false));
      setSelectedBsnCountry({});
      setSelectedBsnState({});
      setSelectedBsnCity({});
    }
  };

  const fetchSameAddress = () => {
    if (isSameAddress) {
      getBsnSameAddress();
    }
  };

  const getBsnSameAddress = () => {
    if (formVal.country) {
      let selCountry = countries.filter((x) => x.value === formVal.country);
      if (selCountry.length > 0) {
        selCountry = selCountry[0];
      }
      setSelectedBsnCountry(selCountry);
    }
    if (formVal.state) {
      let selState = states.filter((x) => x.value === formVal.state);
      if (selState.length > 0) {
        selState = selState[0];
      }
      setSelectedBsnState(selState);
    }
    if (formVal.city) {
      let selCity = cities.filter((x) => x.value === formVal.city);
      if (selCity.length > 0) {
        selCity = selCity[0];
      }
      setSelectedBsnCity(selCity);
    }
  };

  const addressUpdate = () => {
    const formValues = {
      ...userDetail,
      ...formVal,
      bsn_address: isSameAddress ? formVal.rgtr_address : formVal.bsn_address,
      bsn_country: isSameAddress ? formVal.country : formVal.bsn_country,
      bsn_city: isSameAddress ? formVal.city : formVal.bsn_city,
      bsn_state: isSameAddress ? formVal.state : formVal.bsn_state,
      bsn_country_code: isSameAddress
        ? formVal.country_code
        : formVal.bsn_country_code,
    };
    dispatch(updateValues(formValues));
    setValue(
      "bsn_address",
      isSameAddress ? formVal.rgtr_address : formVal.bsn_address
    );
    setValue(
      "bsn_country",
      isSameAddress ? formVal.country : formVal.bsn_country
    );
    setValue("bsn_city", isSameAddress ? formVal.city : formVal.bsn_city);
    setValue("bsn_state", isSameAddress ? formVal.state : formVal.bsn_state);
    setValue(
      "bsn_country_code",
      isSameAddress ? formVal.country_code : formVal.bsn_country_code
    );

    fetchSameAddress();
  };

  const onGotoBack = () => {
    if (company?.id) return navigate("/my-company");
    else return navigate("/");
  };

  const validate = async (name) => {
    if (isUpdate) {
      return true;
    }
    if (formVal[name]) {
      const code = name === "name" ? "NAME" : "SSM";
      const { data } = await onValidateDuplicateValues({
        data: { data: formVal[name] },
        code: code,
      });
      if (data && data.status === 1) {
        clearErrors(name);
      } else {
        setError(name, { message: data && data.message });
      }
    }
  };

  const validateSSM = (e) => {
    const { value } = e.target;
    const regex = /([0-9]{7})-[A-Z]+$/;
    const regex2 = /([0-9]{12})+$/;
    if (!regex.test(value) && !regex2.test(value)) {
      setError("ssm_number", {
        message: t(
          "Please provide a valid SSM number with xxxxxxx-x or xxxxxxxxxxxx format"
        ),
      });
    } else {
      validate("ssm_number");
    }
  };
  const validateURL = (e) => {
    const { value } = e.target;
    const regex = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;

    if (!regex.test(value)) {
      setError("website", {
        message: t(
          "please enter the correct website eg:https://www.google.com"
        ),
      });
    } else {
      validate("website");
    }
  };
  const handleSSM = (e) => {
    const { value } = e.target;
    if (value.length <= 12) {
      setValue("ssm_number", value);
    }
  };


  const getcountryList = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Countrylist = data.data;
        const country = Countrylist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCountry(country);
        if (formVal.country) {
          let selCountry = country.filter((x) => x.value === formVal.country);
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          getState(selCountry.value);
        }
      }
    }
  };

  const getBsnCountryList = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const Countrylist = data.data;
        const country = Countrylist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setBsnCountries(country);
        if (formVal.bsn_country) {
          let selCountry = country.filter(
            (x) => x.value === formVal.bsn_country
          );
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("bsn_country", selCountry.value);
          }
          // setSelectedBsnCountry(selCountry);
          getBsnState(selCountry.value);
        }
      }
    }
  };


  const getCitiesandApply = async(CountryId, StateId,type)=>{
    const { data} = await getCitieMasterList(CountryId, StateId);

      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        if(type==='city'){
          setCities(cities);
          const val = sessionStorage.getItem("city");
          const fiStateslist =  cities?.find((e) => e.value === val)
          setSelectedCity({label:fiStateslist?.label,value:fiStateslist?.value})  
        }
        if(type==='bsn_city'){
          setBsnCities(cities)
          const val = sessionStorage.getItem("bsn_city");
          const fiStateslist =  cities?.find((e) => e.value === val)
          setSelectedBsnCity({label:fiStateslist?.label,value:fiStateslist?.value})  
        }

      }
  }

  const getStateandApply = async (CountryId,type) => {
     const { data, } = await getSatesMasterList(CountryId);
     if(data.status===1){
      const state = data.data;
      const Stateslist = state.map((x) => {
        return { ...x, label: x.Name, value: x.Id, data: x };
      });

      if(type==='state'){
        setStates(Stateslist);
        const val = sessionStorage.getItem("state");
        const fiStateslist =  Stateslist?.find((e) => e.value === val)
        setSelectedState({label:fiStateslist?.label,value:fiStateslist?.value})  
      }
      if(type==='bsn_state'){
        setBsnStates(Stateslist)
        const val = sessionStorage.getItem("bsn_state");
        const fiStateslist =  Stateslist?.find((e) => e.value === val)
        setSelectedBsnState({label:fiStateslist?.label,value:fiStateslist?.value})  
      }
     
     }
  }

  const getState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const Stateslist = data.data;
        const state = Stateslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setStates(state);
        if (formVal.state) {
          let selStates = state.filter((x) => x.value === formVal.state);
          if (selStates.length > 0) {
            selStates = selStates[0];
            setValue("state", selStates.value);
          }
          getCity(CountryId, selStates.value);
          setSelectedState(selStates);
          // fetchCampus(selStates.value);
        }
      }
    }
  };
  // Bsn states
  const getBsnState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const Stateslist = data.data;
        const state = Stateslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setBsnStates(state);
        if (formVal.bsn_state) {
          let selState = state.filter((x) => x.value === formVal.bsn_state);
          if (selState.length > 0) {
            selState = selState[0];
            setValue("bsn_state", selState.value);
          }
          setSelectedBsnState(selState);
          getBsnCity(CountryId, selState.value);
        }
      }
    }
  };

  const getCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setCities(cities);
        if (formVal.city) {
          let selcities = cities.filter((x) => x.value === formVal.city);
          if (selcities.length > 0) {
            selcities = selcities[0];
            setValue("city", selcities.value);
          }
          setSelectedCity(selcities);
          // fetchCampus(selStates.value);
        }
      }
    }
  };

  // Bsn cities
  const getBsnCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const Citieslist = data.data;
        const cities = Citieslist.map((x) => {
          return { ...x, label: x.Name, value: x.Id, data: x };
        });
        setBsnCities(cities);
        if (formVal.bsn_city) {
          let selCity = cities.filter((x) => x.value === formVal.bsn_city);
          if (selCity.length > 0) {
            selCity = selCity[0];
            setValue("bsn_city", selCity.value);
          }
          setSelectedBsnCity(selCity);
        }
      }
    }
  };

  const fetchSector = async () => {
    const { data, status } = await getSectorMasterList();
    if (status === 200) {
      const sectors = data.data;
      const sector = sectors.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setSectors(sector);

      // Edit mode
      if (formVal.sector) {
        let selSector = sector.filter((x) => x.value === formVal.sector);
        if (selSector.length > 0) {
          selSector = selSector[0];
          setValue("sector", selSector.value);
        }
        setSelectedSector(selSector);
      }
    }
  };

  const fetchTypeOfOrganization = async () => {
    const { data, status } = await getOrganisationTypesMasterList();
    if (status === 200) {
      const typeOfOrgs = data.data;
      const typeOfOrg = typeOfOrgs.map((x) => {
        return { ...x, label: x.Name, value: x.Id };
      });
      setTypeOfOrganization(typeOfOrg);

      // Edit mode
      if (formVal.type_of_org) {
        let selTypeOfOrg = typeOfOrg.filter(
          (x) => x.value === formVal.type_of_org
        );
        if (selTypeOfOrg.length > 0) {
          selTypeOfOrg = selTypeOfOrg[0];
          setValue("type_of_org", selTypeOfOrg.value);
        }
        setSelectedTypeOfOrganization(selTypeOfOrg);
      }
    }
  };

  const dropdownChange = (name, val) => {
    setValue(name, val.value);
    switch (name) {
      case "country":
        setValue(name, val.value);
        setSelectedCountry(val);
        getState(val.value);
        dispatch(
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
            state: [],
            city: [],
          })
        );

        break;
      case "state":
        dispatch(
          // ,
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
          })
          //
        );
        setSelectedState(val);
        getCity(formVal.country, val.value);
        errors.state = "";
        break;
      case "city":
        dispatch(
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
          })
        );
        setSelectedCity(val);
        errors.city = "";
        break;
      case "bsn_country":
        setValue("bsn_state", "");
        setValue("bsn_city", "");
        dispatch(
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
            state: [],
            city: [],
          })
        );
        getBsnState(val.value);
        setSelectedBsnCity("");
        setSelectedBsnState("");
        setSelectedBsnCountry(val);
        // getState2(val.value)
        break;
      case "bsn_state":
        setValue("bsn_city", "");
        dispatch(
          // ,
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
          })
          //
        );
        setSelectedBsnCity("");
        setSelectedBsnState(val);
        getBsnCity(formVal.bsn_country, val.value);
        errors.bsn_state = "";
        // getCity2(selectedCountry.value, val.value);
        break;
      case "bsn_city":
        dispatch(
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
          })
        );
        setSelectedBsnCity(val);
        errors.bsn_city = "";
        break;
      case "type_of_org":
        dispatch(
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
          })
        );
        setSelectedTypeOfOrganization(val);
        errors.type_of_org = "";
        break;
      case "sector":
        dispatch(
          updateValues({
            ...formVal,
            // ...internFields,
            [name]: val.label,
          })
        );
        setSelectedSector(val);
        errors.sector = "";
        break;
      default:
        setValue(name, val.value);
    }
  };

  useEffect(() => {
    getcountryList();
    getBsnCountryList();
    fetchSector();
    fetchTypeOfOrganization();

    // setSelectedCountry({ label: "Malaysia", value: 132 })
    // getState(132);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setValue("country", 132);
    setSelectedCountry({ label: "Malaysia", value: 132 });
    getState(132);
    setValue("bsn_country", 132);
    setSelectedBsnCountry({ label: "Malaysia", value: 132 });
    getBsnState(132);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);



  useEffect(() => {

    getDefaults()

  })

  return (
    <UserCreationLayout coverImg={CoverImage}>
      <LoginPageWrapper>
        <IntroSection name="register" margin="0" />
        <PaginationDivider step={1} />
        <FormWrapper>
          <GroupWrapper>
            <FormLabel>{t("Organisation Name*")}</FormLabel>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <Input
                  disabled={isUpdate}
                  {...field}
                  dataId="orgName"
                  onBlur={() => validate("name")}
                  placeholder={t("Enter Organisation Name")}
                />
              )}
            />
            {errors.name && <ErrorField err={t(errors.name.message)} />}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t("Type of Organisation")}*</FormLabel>
            <Controller
              name="type_of_org"
              control={control}
              render={({ field }) => (
                <Dropdown
                  bgGrey
                  search={true}
                  options={typeOfOrganization}
                  placeholder={t("Type of Organisation")}
                  val={selectedTypeOfOrganization?.value}
                  label={selectedTypeOfOrganization?.label}
                  // disable={!formVal.state}
                  change={(val) => dropdownChange("type_of_org", val)}
                  {...field}
                  disable={isUpdate}
                />
              )}
            />
            {errors.type_of_org && (
              <ErrorField err={errors.type_of_org.message} />
            )}
          </GroupWrapper>
          <GridContainer columns="auto auto">
            <GroupWrapper>
              <FormLabel>{t("SSM Number*")}</FormLabel>
              <Controller
                name="ssm_number"
                control={control}
                render={({ field }) => (
                  <Input
                    // disabled={
                    //   isUpdate || formVal.name.length === 0 || errors.name
                    // }
                    {...field}
                    dataId="ssmNum"
                    type="text"
                    onChange={(e) => handleSSM(e)}
                    onBlur={validateSSM}
                    placeholder={t("SSM Number")}
                    disabled={isUpdate}
                  />
                )}
              />
              {errors.ssm_number && ( 
                <div style={{width:"400px"}}>
                <ErrorField err={t(errors.ssm_number.message)} />
                </div>
              )}
            </GroupWrapper>
            <GroupWrapper>
              <FormLabel>{t("SSM Registration Date*")}</FormLabel>
              <Controller
                name="ssm_rgtr_date"
                control={control}
                render={({ field }) => (
                  <label>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DatePicker
                          autoOk
                          variant="inline"
                          format="dd/MM/yyyy"
                          placeholder={t("Enter SSM Registration date")}
                          views={["year", "month", "date"]}
                          value={
                            formVal.ssm_rgtr_date
                              ? new Date(formVal.ssm_rgtr_date)
                              : null
                          }
                          onChange={(val) => dateChange("ssm_rgtr_date", val)}
                          disabled={isUpdate || isDisableSsmDate}
                          fullWidth
                          disableFuture
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                    <CalenderIconWrapper>
                      <CalenderIcon />
                    </CalenderIconWrapper>
                  </label>
                )}
              />
              {errors.ssm_rgtr_date && (
                <ErrorField err={t(errors.ssm_rgtr_date.message)} />
              )}
            </GroupWrapper>
          </GridContainer>
          <GroupWrapper>
            <FormLabel>{t("Website")}</FormLabel>
            <Controller
              control={control}
              name="website"
              render={({ field }) => (
                <Input
                  disabled={isUpdate}
                  {...field}
                  type="url"
                  placeholder="e.g., https://www.organisation.com"
                  dataId="orgWeb"
                  onBlur={validateURL}
                // onChange={(e) => {
                //   onChange(e.target.value.trim());
                // }}
                // onChange={(e) => validate(e.target.value)}
                />
              )}
            />
            {errors.website && <ErrorField err={t(errors.website.message)} />}
          </GroupWrapper>
          <Divider />
          <GroupWrapper>
            <FormLabel>{t("Registered Address*")}</FormLabel>
            <Controller
              name="rgtr_address"
              control={control}
              render={({ field }) => (
                <Input
                  disabled={isUpdate}
                  dataId="address"
                  title={registerOrgToolTip.address}
                  {...field}
                  placeholder={t("Enter Address")}
                />
              )}
            />
            {errors.rgtr_address && (
              <ErrorField err={t(errors.rgtr_address.message)} />
            )}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t("Country*")}</FormLabel>
            <Controller
              name="country"
              control={control}
              render={({ field }) => (
                // <AcademicGridWrapper template="1fr 1fr">
                //   <FormGroup>
                <Dropdown
                  search={true}
                  options={countries}
                  placeholder={t("Select Country")}
                  val={selectedCountry?.value}
                  label={selectedCountry?.label}
                  change={(val) => dropdownChange("country", val)}
                  {...field}
                  disable={isUpdate}
                />
                //   </FormGroup>
                // </AcademicGridWrapper>
              )}
            />
            {errors.country && <ErrorField err={t(errors.country.message)} />}
          </GroupWrapper>
          <GridContainer gap="5px" columns="repeat(3,1fr)">
            <GroupWrapper>
              <FormLabel>{t("State*")}</FormLabel>
              <Controller
                name="state"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    options={states}
                    placeholder={t("Select State")}
                    val={selectedState?.value}
                    label={selectedState?.label}
                    change={(val) => dropdownChange("state", val)}
                    {...field}
                    disable={isUpdate}
                  />
                )}
              />
              {errors.state && <ErrorField err={t(errors.state.message)} />}
            </GroupWrapper>
            <GroupWrapper>
              <FormLabel>{t("City*")}</FormLabel>
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    bgGrey
                    search={true}
                    options={cities}
                    placeholder={t("Select City")}
                    val={selectedCity?.value}
                    label={selectedCity?.label}
                    // disable={!formVal.state}
                    change={(val) => dropdownChange("city", val)}
                    {...field}
                    disable={isUpdate}
                  />
                )}
              />
              {errors.city && <ErrorField err={t(errors.city.message)} />}
            </GroupWrapper>
            <GroupWrapper>
              <FormLabel>{t("Postcode*")}</FormLabel>
              <Controller
                name="country_code"
                control={control}
                render={({ field }) => (
                  <Input
                    disabled={isUpdate}
                    {...field}
                    type="number"
                    placeholder={t("Postcode")}
                    dataId="post"
                  />
                )}
              />
              {errors.country_code && (
                <ErrorField err={t(errors.country_code.message)} />
              )}
            </GroupWrapper>
          </GridContainer>
          <Divider />
          {isUpdate ? (
            ""
          ) : (
            <CheckController style={{ width: "fit-content" }} disabled={true}>
              <Controller
                name="same_as"
                control={control}
                render={({ field }) => (
                  <CheckboxInput
                    name="same_as"
                    label={t("Same as Registered Address")}
                    {...field}
                    checked={isSameAddress}
                    onChange={(e) => {
                      onFillSameAddress(isSameAddress);
                    }}
                    margin="20px 20px 0"
                    disabled={isUpdate}
                  />
                )}
              />
            </CheckController>
          )}
          <GroupWrapper>
            <FormLabel>{t("Business Address*")}</FormLabel>
            <Controller
              name="bsn_address"
              control={control}
              render={({ field }) => (
                <Input
                  disabled={isUpdate || isSameAddress}
                  {...field}
                  placeholder={t("Address")}
                  dataId="address"
                />
              )}
            />
            {formVal.bsn_address !== ""
              ? ""
              : errors.bsn_address && (
                <ErrorField err={t(errors.bsn_address.message)} />
              )}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t("Country*")}</FormLabel>
            <Controller
              name="bsn_country"
              control={control}
              render={({ field }) => (
                // <AcademicGridWrapper template="1fr 1fr">
                //   <FormGroup>
                <Dropdown
                  search={true}
                  options={bsnCountries}
                  placeholder={t("Select Country")}
                  val={selectedBsnCountry?.value}
                  label={selectedBsnCountry?.label}
                  change={(val) => dropdownChange("bsn_country", val)}
                  {...field}
                  // disabled={(isUpdate || isSameAddress === true) ? true : false}
                  disable={isUpdate || isSameAddress}
                />
                //   </FormGroup>
                // </AcademicGridWrapper>
              )}
            />
            {errors.bsn_country && (
              <ErrorField err={t(errors.bsn_country.message)} />
            )}
          </GroupWrapper>
          <GridContainer gap="5px" columns="repeat(3,1fr)">
            <GroupWrapper>
              <FormLabel>{t("State*")}</FormLabel>
              <Controller
                name="bsn_state"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    search={true}
                    options={bsnState}
                    placeholder={t("Select State")}
                    val={selectedBsnState?.value}
                    label={selectedBsnState?.label}
                    change={(val) => dropdownChange("bsn_state", val)}
                    {...field}
                    disable={isUpdate || isSameAddress}
                  />
                )}
              />
              {errors.bsn_state && (
                <ErrorField err={t(errors.bsn_state.message)} />
              )}
            </GroupWrapper>
            <GroupWrapper>
              <FormLabel>{t("City*")}</FormLabel>
              <Controller
                name="bsn_city"
                control={control}
                render={({ field }) => (
                  <Dropdown
                    bgGrey
                    search={true}
                    options={bsnCities}
                    placeholder={t("Select City")}
                    val={selectedBsnCity?.value}
                    label={selectedBsnCity?.label}
                    // disable={!formVal.state}
                    change={(val) => dropdownChange("bsn_city", val)}
                    {...field}
                    disable={isUpdate || isSameAddress}
                  />
                )}
              />
              {errors.bsn_city && (
                <ErrorField err={t(errors.bsn_city.message)} />
              )}
            </GroupWrapper>
            <GroupWrapper>
              <FormLabel>{t("Postcode*")}</FormLabel>
              <Controller
                name="bsn_country_code"
                control={control}
                render={({ field }) => (
                  <Input
                    //disabled={isUpdate || isSameAddress || !formVal.bsn_city}
                    disabled={isUpdate || isSameAddress}
                    {...field}
                    type="number"
                    placeholder={t("Postcode")}
                    dataId="post"
                  />
                )}
              />
              {errors.bsn_country_code && (
                <ErrorField err={t(errors.bsn_country_code.message)} />
              )}
            </GroupWrapper>
          </GridContainer>
          <Divider />
          {/* <GroupWrapper>
            <FormLabel>{t("Type of Organisation*")}</FormLabel>
            <Controller
              name="type_of_org"
              control={control}
              render={({ field }) => (
                <Dropdown
                  bgGrey
                  search={true}
                  options={typeOfOrganization}
                  placeholder={t("Type of Organisation")}
                  val={selectedTypeOfOrganization?.value}
                  label={selectedTypeOfOrganization?.label}
                  // disable={!formVal.state}
                  change={(val) => dropdownChange("type_of_org", val)}
                  {...field}
                  disable={isUpdate}
                />
              )}
            />
            {errors.type_of_org && (
              <ErrorField err={t(errors.type_of_org.message)} />
            )}
          </GroupWrapper> */}
          <GroupWrapper>
            <FormLabel>{t("Sector*")}</FormLabel>
            <Controller
              name="sector"
              control={control}
              render={({ field }) => (
                <Dropdown
                  bgGrey
                  search={true}
                  options={sectors}
                  placeholder={t("Sector")}
                  val={selectedSector?.value}
                  label={selectedSector?.label}
                  // disable={!formVal.state}
                  change={(val) => dropdownChange("sector", val)}
                  {...field}
                  disable={isUpdate}
                />
              )}
            />
            {errors.sector && <ErrorField err={t(errors.sector.message)} />}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t("Nature Of Business*")}</FormLabel>
            <Controller
              name="bsn_nature"
              control={control}
              render={({ field }) => (
                <Input
                  disabled={isUpdate}
                  // onChange={(e) => setBusinessNature(e.target.value)}
                  {...field}
                  placeholder={t("Enter Nature Of Business")}
                  title={t(registerOrgToolTip.businessNature)}
                  dataId="natureBusiness"
                />
              )}
            />
            {errors.bsn_nature && (
              <ErrorField err={t(errors.bsn_nature.message)} />
            )}
          </GroupWrapper>
          {formErrors ? "" : formErr && <ErrorField err={t(formErr)} />}
          {formErrors && <ErrorField err={t(formErrors)} />}
          <Flexbox>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Button
                  // disabled={fieldErrors}
                  width="auto"
                  name={t("Continue")}
                  onClick={handleSubmit(onContinue)}
                />
                {isUpdate ? (
                  ""
                ) : (
                  <Button onClick={onGotoBack} secondary name={t("Back")}>
                    Back
                  </Button>
                )}
              </>
            )}
          </Flexbox>
          <Tooltip id="orgName" content={t("Enter the organisation name")} />
          <Tooltip id="ssmNum" content={t("Enter SSM Number")} />
          <Tooltip id="ssmDate" content={t("Enter SSM Registration date")} />
          <Tooltip
            id="orgWebsite"
            content={t("Enter the organisation website")}
          />
          <Tooltip id="address" content={t("Enter address")} />
          <Tooltip id="country" content={t("Country")} />
          <Tooltip id="state" content={t("State/Province")} />
          <Tooltip id="city" content={t("City")} />
          <Tooltip id="post" content={t("Enter Postcode")} />
          <Tooltip id="typeOrg" content={t("Type of organisation")} />
          <Tooltip id="sector" content={t("Sector")} />
          <Tooltip
            id="natureBusiness"
            content={t("Enter nature of business")}
          />
          <Tooltip
            id="orgWeb"
            content={t(
              "Enter the organisation website e.g. http://example.com"
            )}
          />
        </FormWrapper>
      </LoginPageWrapper>
    </UserCreationLayout>
  );
}
