import { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import PhoneInput from "react-phone-number-input";
import { isPossiblePhoneNumber } from "react-phone-number-input";

import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";
import IntroSection from "../../components/introSection/introSection";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import CoverImage from "../../assets/newPerson.png";
import PaginationDivider from "../../components/paginationDivider/paginationDivider";
import {
  registerOrg_S3,
  updateExistingOrganisation,
  registerOrg_S1,
  getEmailVerification,
  onTriggerOtp,
  onOtpVerify,
  validateEmailAddress,
} from "../../services/apiCalls";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { errorMessages } from "../../utilities/commonMessages";
import ErrorField from "../../components/errorField/errorField";
import Loader from "../../components/loader/loader";
import { updatePersonValues } from "./newPersonReducer";
import LoginSuccessModal from "../../components/loginSuccessModal/loginSuccessModal";
import {
  updateSuccessObj,
  updateFormErr,
} from "../registerorganisation/reducer";
import { newPersonData } from "../../utilities/constants";
import Divider from "../../components/divider/divider";
import { Colors } from "../../utilities/colors";
import EmailVerification from "../../components/emailVerification/emailVerification";
import { registerOrganisationErrorMessages } from "../../utilities/commonMessages";
import { registerOrgToolTip } from "../../utilities/commonMessages";
import Tooltip from "../../components/reactTooltip/tooltip";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { useTranslation } from "react-i18next";

const errorLogs = { ...registerOrganisationErrorMessages };

// styling starts
const LoginPageWrapper = styled.section``;
// const LabelText = styled.label`
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 24px;
// `;
const Flexbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
// const SaveExitBtn = styled(LabelText)`
//   color: #504f8c;
//   text-decoration: underline;
//   cursor: pointer;
// `;
const FormWrapper = styled.div`
  margin: 10px 0px;
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  margin-bottom: 10px;
  display: block;
`;
const GroupWrapper = styled.div`
  margin-bottom: 25px;
  width: ${(props) => (props.width ? props.width : "100%")};
`;
const GroupElement = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;
const InputWrapper = styled.div`
  margin-left: 20px;
  margin-bottom: 25px;
`;
const UserHelpText = styled.label`
  color: ${Colors.inputHighlightText};
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
  cursor: pointer;
  text-align: right;
  text-decoration: underline;
  display: block;
  font-weight: 500;
`;
// styling ends
const PasswordInput = styled.div`
  position: relative;
`;
const Visibility = styled.div`
  position: absolute;
  display: inline;
  right: 14px;
  top: 9px;
  cursor: pointer;
  svg {
    fill: gray;
  }
`;
const schema = yup.object().shape({
  first_name: yup.string().required(errorLogs.firstName),
  last_name: yup.string().required(errorLogs.lastName),
  password: yup
    .string()
    .required(errorLogs.password)
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#%&])(?=.{8,})/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
    ),
  confirm_password: yup
    .string()
    .required()
    .test("passwords-match", errorLogs.passwordMisMatch, function (value) {
      return this.parent.password === value;
    }),
  department: yup.string().required(),
  mob_number: yup
    .string()
    .required()
    .test("mob_number", errorLogs.invalidNumber, (value) =>
      isPossiblePhoneNumber(value)
    ),
  email: yup.string().email(errorMessages.email).required(errorLogs.email),
  job_title: yup.string().required(errorLogs.jobTitle),
});

export default function NewPerson() {
  const [formErrors, setFormErrors] = useState("");
  // const [fieldErrors, setErrors] = useState("");
  const [loading, setLoading] = useState(false);
  const [open, setOpenModal] = useState(false);
  const [showEmailVerification, setShowEmailVerification] = useState(false);
  const [otpVerification, setOtpVerification] = useState(false);
  const [otpErrors, setOtpErrors] = useState("");
  const [otpResent, setOtpResent] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  let navigate = useNavigate();
  const orgDetail = useSelector((state) => state.organisation.successData);
  const orgValues = useSelector((state) => state.organisation.value);
  const userDetail = useSelector((state) => state.newPerson.value);
  const isUpdate = useSelector((state) => state.organisation.isUpdate);
  const dispatch = useDispatch();
  const isRedirect = useSelector((state) => state.organisation.isRedirect);
  const { t, } = useTranslation();
  useEffect(() => {
    dispatch(updatePersonValues(newPersonData));
    if (isRedirect) navigate("/search");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => { }, [showEmailVerification]);

  const { is_completed } = orgDetail;

  const {
    control,
    handleSubmit,
    setValue,
    setError,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...userDetail },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  const formVal = watch();

  // on FormSubmission starts
  const onBack = () => {
    navigate(`/upload`);
  };

  const onFinish = async () => {
    validateFormFields();
  };

  const validateFormFields = () => {
    const newUserDetail = { ...userDetail, ...formVal };
    dispatch(updatePersonValues(newUserDetail));
    // const isValid = validateInputs(formVal);
    // if (isValid) {
    setFormErrors("");
    setLoading(true);
    onSubmitOrganisation();
    // } else {
    //   setFormErrors(errorMessages.required);
    // }
  };

  const onSubmitOrganisation = async () => {
    if (is_completed) {
      onFormSubmission();
    } else {
      checkEmail();
    }
  };

  const checkEmail = async () => {
    const { data, status } = await getEmailVerification({
      email: formVal.email,
    });
    if (status === 200) {
      if (data.status === 1) {
        //submitRegisterStepValidation();
        afterVerification();
      } else {
        setLoading(false);
        setFormErrors(data.message);
      }
    }
  };

  const afterVerification = async () => {
    const req = { ...orgValues, step: 3 };
    var formdata = new FormData();

    if (!isUpdate) {
      if (req.logo_path !== "") {
        formdata.append("logo_path", orgDetail.logo_path);
        formdata.append("logo", "");
        formdata.append("bsn_address", orgDetail.bsn_address);
        formdata.append("bsn_city", orgDetail.bsn_city);
        formdata.append("bsn_country", orgDetail.bsn_country);
        formdata.append("bsn_country_code", orgDetail.bsn_country_code);
        formdata.append("bsn_nature", orgDetail.bsn_nature);
        formdata.append("bsn_state", orgDetail.bsn_state);
        formdata.append("city", orgDetail.city);
        formdata.append("country", orgDetail.country);
        formdata.append("country_code", orgDetail.country_code);
        formdata.append("rgtr_address", orgDetail.rgtr_address);
        formdata.append("name", orgDetail.name);
        formdata.append("sector", orgDetail.sector);
        formdata.append("same_as", orgDetail.same_as);
        formdata.append("ssm_number", orgDetail.ssm_number);
        formdata.append("ssm_rgtr_date", orgDetail.ssm_rgtr_date);
        formdata.append("state", orgDetail.state);
        formdata.append("step", orgDetail.step);
        formdata.append("type_of_org", orgDetail.type_of_org);
        formdata.append("website", orgDetail.website);

        const { data, status } = isUpdate
          ? await updateExistingOrganisation(req)
          : await registerOrg_S1(formdata);
        if (status === 200) {
          onSuccessRegistration(data);
        }
      } else {
        const { data, status } = isUpdate
          ? await updateExistingOrganisation(orgDetail)
          : await registerOrg_S1(orgDetail);
        if (status === 200) {
          onSuccessRegistration(data);
        }
      }
    }
  };

  const onSuccessRegistration = (data) => {
    const { status } = data;
    if (status === 1) {
      dispatch(updateSuccessObj(data));
      dispatch(updateFormErr(""));
      onFormSubmission(data.code);
    } else {
      setLoading(false);
      if (data.step && data.step === 1) {
        dispatch(updateFormErr(data.message));
        navigate("/register");
      } else {
        setFormErrors(data.message);
        dispatch(updateFormErr(""));
      }
    }
  };

  const onFormSubmission = async (code) => {
    const req = { ...formVal, code: code ? code : orgDetail.code };
    const { data, status } = await registerOrg_S3(req);
    if (status === 200) {
      onSuccessOrganisationRegistration(data);
      dispatch(updatePersonValues(newPersonData));
    }
  };
  const onSuccessOrganisationRegistration = (data) => {
    const { status } = data;
    if (status === 1) {
      setFormErrors("");
      // dispatch(updateModal(true));
      setOpenModal(true);
      setLoading(false);
      setTimeout(() => {
        navigate("/login");
        setOpenModal(false);
      }, 5000);
    } else {
      errorHandling(data);
    }
  };

  const errorHandling = (data) => {
    setFormErrors(data.message);
    setLoading(false);
  };
  // on FormSubmission starts

  const onCloseModal = () => {
    setOpenModal(false);
  };

  const onMblNumberChange = (val) => {
    if (val) {
      setValue("mob_number", val);
    }
  };
  const onEmailChanged = (event) => {
    const { name, value } = event.target;
    if (value) {
      setValue(name, value.toLowerCase());
    } else {
      setValue(name, value);
    }
  };
  // otp verification starts
  const onOtpVerification = async (otp) => {
    if (otp) {
      const { data, status } = await onOtpVerify({
        email_address: formVal.email,
        otp_in: otp,
      });
      if (status === 200) {
        onOtpVerified(data);
      }
    }
  };
  const onOtpVerified = ({ data, message, status }) => {
    if (status === 1) {
      setOtpVerification(true);
      setShowEmailVerification(false);
      setOtpErrors("");
    } else {
      setOtpVerification(false);
      setOtpErrors(message);
      setOtpResent("");
    }
  };
  // otp verification ends

  // email verification starts
  const onEmailVerification = async (e, isResent) => {
    if (formVal.email && !errors.email) {
      let body = {
        email_address: formVal.email,
      };
      const { data } = await validateEmailAddress(body);
      if (data.status === 1) {
        validateEmail();
        setOtpErrors("");
        toast.success(
          t("OTP has been sent successfully to your entered Email ID"),
          {
            position: toast.POSITION.TOP_RIGHT,
          }
        );
        if (isResent) {
          setOtpResent("Verification code has been sent");
          setShowEmailVerification(false);
        }
        setShowEmailVerification(true);
      } else {
        setOtpErrors(data.message);
        setShowEmailVerification(false);
        setOtpResent("");
      }
    } else {
      setError("email", {
        type: "manual",
        message: errorLogs.email,
      });
    }
  };
  // const validateEmail = async () => {
  //   const { data, status } = await onTriggerOtp({
  //     email_address: formVal.email,
  //   });
  //   if (status === 200) {
  //     onEmailTriggered(data);
  //   }
  // };
  const validateEmail = async () => {
    const { data, status } = await onTriggerOtp({
      email_address: formVal.email,
    });
    if (status === 200) {
      onEmailTriggered(data);
    }
  };
  const onEmailTriggered = ({ data, message, status }) => {
    if (status === 1) {
      setShowEmailVerification(true);
      setOtpVerification(false);
    }
  };
  // email verification ends
  const togglePasswordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };
  return (
    <UserCreationLayout coverImg={CoverImage}>
      <LoginPageWrapper>
        <IntroSection name="newPerson" margin="0" />
        <PaginationDivider step={3} />
        <FormWrapper>
          <GroupWrapper>
            <FormLabel>{t('Email address*')}</FormLabel>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  disabled={otpVerification || showEmailVerification}
                  placeholder={t('Enter email address')}
                  title={registerOrgToolTip.email}
                  dataId="email"
                  onChange={onEmailChanged}
                />
              )}
            />
            {otpVerification ? (
              ""
            ) : (
              <UserHelpText onClick={(e) => onEmailVerification(e, false)}>
                {t('Send verification code')}
              </UserHelpText>
            )}
            {errors.email && <ErrorField err={t(errors.email.message)} />}
          </GroupWrapper>
          <GroupWrapper>
            {showEmailVerification && (
              <EmailVerification
                verifyCode={onOtpVerification}
                isSuccess={otpVerification}
                onSendNewCode={(e, isResent) =>
                  onEmailVerification(e, isResent)
                }
                otpResent={t(otpResent)}
                setOtpResent={setOtpResent}
              />
            )}
            {otpErrors && <ErrorField err={t(otpErrors)} />}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t('Create Password*')}</FormLabel>
            <Controller
              name="password"
              control={control}
              render={({ field }) => (
                <Input
                  type="password"
                  {...field}
                  dataId="password"
                  disabled={!otpVerification}
                  placeholder={t('Enter password')}
                />
              )}
            />
            {errors.password && <ErrorField err={t(errors.password.message)} />}
          </GroupWrapper>
          <GroupWrapper>
            <FormLabel>{t('Repeat Password*')}</FormLabel>
            <Controller
              name="confirm_password"
              control={control}
              render={({ field }) => (
                <PasswordInput>
                  <Input
                    placeholder={t('Password')}
                    type={passwordShown ? "text" : "password"}
                    {...field}
                  />
                  <Visibility onClick={togglePasswordVisiblity}>
                    {passwordShown ? <FaEyeSlash /> : <FaEye />}
                  </Visibility>
                </PasswordInput>
              )}
            />
            {errors.confirm_password && (
              <ErrorField err={t(errors.confirm_password.message)} />
            )}
          </GroupWrapper>
          <Divider />
          <GroupElement>
            <GroupWrapper>
              <FormLabel>{t('First Name*')}</FormLabel>
              <Controller
                name="first_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    // title={registerOrgToolTip.firstName}
                    dataId="firstName"
                    disabled={!otpVerification}
                    placeholder={t('Full Name')}
                    maxLength="100"
                  />
                )}
              />
              {errors.first_name && (
                <ErrorField err={t(errors.first_name.message)} />
              )}
            </GroupWrapper>
            <InputWrapper>
              <FormLabel>{t('Last Name*')}</FormLabel>
              <Controller
                name="last_name"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    // title={registerOrgToolTip.lastName}
                    dataId="lastName"
                    disabled={!otpVerification}
                    placeholder={t('Last Name')}
                  />
                )}
              />
              {errors.last_name && (
                <ErrorField err={t(errors.last_name.message)} />
              )}
            </InputWrapper>
          </GroupElement>
          <GroupElement>
            <GroupWrapper>
              <FormLabel>{t('Job Title*')}</FormLabel>
              <Controller
                name="job_title"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    // title={registerOrgToolTip.jobTitle}
                    dataId="jobTitle"
                    disabled={!otpVerification}
                    placeholder={t('e.g., Hiring Manager')}
                  />
                )}
              />
              {errors.job_title && (
                <ErrorField err={t(errors.job_title.message)} />
              )}
            </GroupWrapper>

            <InputWrapper>
              <FormLabel>{t('Department*')}</FormLabel>
              <Controller
                name="department"
                control={control}
                render={({ field }) => (
                  <Input
                    {...field}
                    // title={registerOrgToolTip.department}
                    placeholder={t('e.g., Finance')}
                    disabled={!otpVerification}
                    dataId="department"
                  />
                )}
              />
              {errors.department && (
                <ErrorField err={t(errors.department.message)} />
              )}
            </InputWrapper>
          </GroupElement>
          <GroupWrapper width="60%">
            <FormLabel>{t("Organisation Mobile Number*")}</FormLabel>
            <Controller
              name="mob_number"
              control={control}
              render={({ field }) => (
                <PhoneInput
                  value={formVal.mob_number}
                  defaultCountry="MY"
                  international
                  disabled={!otpVerification}
                  countryCallingCodeEditable={false}
                  withCountryCallingCode={true}
                  onChange={onMblNumberChange}
                  maxLength={16}
                  minLength={12}
                />
              )}
            />
            {errors.mob_number && (
              <ErrorField err={t(errors.mob_number.message)} />
            )}
          </GroupWrapper>
          {formErrors && <ErrorField err={t(formErrors)} />}
          <Flexbox>
            {loading ? (
              <Loader />
            ) : (
              <>
                <Button
                  onClick={handleSubmit(onFinish)}
                  width="auto"
                  name={t('Finish')}
                />
                {!is_completed && <Button secondary name={t('Back')} onClick={onBack}>
                  {t("Back")}
                </Button>}
              </>
            )}
          </Flexbox>
        </FormWrapper>
        <Tooltip id="email" content={t('Enter E-mail address')} />
        <Tooltip id="firstName" content={t('Enter first name')} />
        <Tooltip id="lastName" content={t('Enter last name')} />
        <Tooltip id="password" content={t('Enter new password')} />
        <Tooltip id="confirmPassword" content={t('Confirm new password')} />
        <Tooltip id="jobTitle" content={t('Enter Job Title')} />
        <Tooltip id="department" content={t('Enter Department')} />
      </LoginPageWrapper>
      {open && <LoginSuccessModal open={open} onClose={onCloseModal} />}
    </UserCreationLayout>
  );
}
