import React, { useState } from 'react'
import styled from 'styled-components'
import { SectionWrapper, HeaderText, BoldText } from './common'
import "./companyBranding.css"
import { SketchPicker } from 'react-color'
import Button from '../../components/button/button'
import { t } from 'i18next'

const CardWrapper = styled.div`
   background: #F3F3F3;
   border-radius: 8px;
   width: 100%;
   height:64px;
   display:flex;
   flex-direction:row;
   justify-content:space-between;
   margin:24px auto;
   @media (max-width: 500px) {
    height:auto;
    flex-flow:wrap;
    justify-content:space-around;
   }
`;
const StyleText = styled.div`
  font-family: 'General Sans';
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #959595;
`;
const ColorBox = styled.div`
  width: 105px;
  height: 32px;
  background: ${(props) => props.background};
  border: 1px solid #CFCFCF;
  border-radius: 8px;
`;

const ColorWrapper = styled.div`
  background: #FFFFFF;
  border-radius: 4px;
  height:48px;
  padding:8px;
  width:320px;
  display:flex;
  justify-content:space-between;
  margin:8px;
`;

const ColorInput = styled.input`
  width:120px;
  border:none;
  margin-right:4px;
`;

export default function Page({ primaryColor, secondaryColor, setPrimary, setSecondary }) {
    const [currentPrimaryColor, setCurrentPrimaryColor] = useState(primaryColor && primaryColor !== "" ? primaryColor : "#FDFDFD");
    const [currentSecondaryColor, setCurrentSecondaryColor] = useState(secondaryColor && secondaryColor !== "" ? secondaryColor : "#D04C8D");
    const [primaryPicker, setPrimaryPicker] = useState(false);
    const [secondaryPicker, setSecondaryPicker] = useState(false);
    function handleOnChange(color){
        if (primaryPicker){
            setCurrentPrimaryColor(color.hex)
            setPrimary(color)
        }else{
            setCurrentSecondaryColor(color.hex)
            setSecondary(color)
        }
    }

    function handlePickerButton(buttonName){
        if (buttonName == 'primary'){
            setPrimaryPicker(!primaryPicker)
        }else if (buttonName == 'secondary'){
            setSecondaryPicker(!secondaryPicker)
        }
    }
    return (
        <SectionWrapper>
            <HeaderText>{t("Page Theme")}</HeaderText>
            <hr />
            {((primaryPicker || secondaryPicker) && (currentPrimaryColor  || currentSecondaryColor))?
            <SketchPicker
                        width={300}
                        height={100}
                        color={primaryPicker ? currentPrimaryColor : currentSecondaryColor}
                        onChangeComplete={handleOnChange}
                    /> : <></>}
            <CardWrapper>
                <div className='pageThemeAlignment' style={{ display: "flex" }}>
                    <BoldText className='paddingAlignment' style={{ fontSize: "18px", fontWeight: "500" }}>{t("Brand color 1")}</BoldText>
                    <StyleText className='paddingAlignment' >{t("primary color")}</StyleText>
                </div>
                <Button disabled={secondaryPicker} style={{height:"55px",marginTop:"5px"}} onClick={() => handlePickerButton("primary")} name={primaryPicker ?  t("Close Picker") :t("Color Picker")} />
                <ColorWrapper>
                    <BoldText style={{ fontSize: "18px", fontWeight: "500", margin: "auto" }}>{"Hex:"}</BoldText>
                    
                    <ColorInput placeholder={t('Enter Hex Value')} defaultValue={primaryColor} type="text" onChange={(e) => setPrimary(e)} />
                    <ColorBox background={primaryColor}>
                    </ColorBox>
                </ColorWrapper>
            </CardWrapper>
            <CardWrapper>
                <div className='pageThemeAlignment' style={{ display: "flex" }}>
                    <BoldText className='paddingAlignment' style={{ fontSize: "18px", fontWeight: "500" }}>{t("Brand color 2")}</BoldText>
                    <StyleText className='paddingAlignment'>{t("secondary color")}</StyleText>
                </div>
                <Button disabled={primaryPicker} style={{height:"55px",marginTop:"5px"}} onClick={() => handlePickerButton("secondary")} name={secondaryPicker ?  t("Close Picker") :t("Color Picker")} />
                <ColorWrapper>
                    <BoldText style={{ fontSize: "18px", fontWeight: "500", margin: "auto" }}>{"Hex:"}</BoldText>
                    {/* <StyleText>Enter hex value</StyleText> */}
                    <ColorInput placeholder={t('Enter Hex Value')} defaultValue={secondaryColor} type="text" onChange={(e) => setSecondary(e)} />
                    <ColorBox background={secondaryColor}>
                    </ColorBox>
                </ColorWrapper>

            </CardWrapper>
        </SectionWrapper>
    )
}
