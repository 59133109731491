import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import Form from "react-bootstrap/Form";
import "./talentShortlist.css";
import { makeStyles } from "@mui/styles";
import { useNavigate } from "react-router-dom";
import { jobProxy } from "../../pages/jobPortal/jobproxy";
import { useSnapshot } from "valtio";
import { MdOutlineKeyboardDoubleArrowLeft } from "react-icons/md";
import { Row, Col } from "react-bootstrap";
import { companyProxy } from "../myCompany/companyproxy";
import { getApplications } from "../../services/apiCalls";
import { useTranslation } from "react-i18next";

const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: 20px 0 -10px 0;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  width: 157px;
  position: relative;
  left: 870px;
  bottom: 50px;
  @media (max-width: 500px) {
    position: relative;
    top: 20px;
    bottom: 20px;
    left: 130px;
  }
`;
const SectionWrapper = styled.div`
  background: #ffffff;
  border-radius: 55px;
  width: 718px;
  height: 104px;
  display: flex;
  margin: -50px 0 0 0;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 320px;
    margin: 27px 2px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    border-radius: 55px;
    width: 674px;
    height: auto;
    display: flex;
    margin: -50px 0px 0px;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #d04c8d;
  display: flex;
  align-items: center;
  margin: 0px 0px 0px 50px;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0px 0px 0px 20px;
  }
`;
const ButtonWrapper2 = styled.div`
  > Button {
    width: 125px;
    height: 56px;
    position: relative;
    top: 10px;
    left: 350px;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    > Button {
      position: absolute;
      top: 23%;
      left: 184px;
      width: 125px;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    > Button {
      width: 125px;
      height: 56px;
      position: relative;
      top: auto;
      left: 350px;
    }
  }
`;

const MainWrapper = styled.div`
  background: #ffffff;
  padding: 12px 24px 0px 24px;
  border-radius: 24px;
  margin: 12px 0px;
  width: 718px;
  height: 176px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 100%;
    height: auto;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    padding: 12px 24px 0px;
    border-radius: 24px;
    margin: 12px 19px;
    /* width: 718px; */
    height: 176px;
    width: auto;
  }
`;

const SubWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  @media (max-width: 500px) {
    display: block;
  }
`;

const Image = styled.img`
  width: 41px;
  height: 41px;
  border-radius: 50%;
  position: relative;
  top: 18px;
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const MediumText = styled.h2`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #202020;
`;

const SmallText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #656565;
`;
const LinkText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
  color: #d04c8d;
  margin-top: -8px;
`;

const PersonalityCard = styled.div`
  display: flex;
  @media (min-width: 320px) and (max-width: 480px) {
    margin-top: -20px;
    justify-content: center;
    margin-left: 74px;
  }
`;
const Location = styled.div`
  font-family: "Gelasio";
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 18px;
  color: #000000;
  text-align: right;
`;
const SectionWrapper2 = styled.div`
  background: #ffffff;
  border-radius: 55px;
  width: auto;
  height: 628px;
  padding: 20px 0 0 0;
  margin: -50px 0 0 0;
  overflow-y: scroll;
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    background: #ffffff;
    border-radius: 23px;
    width: auto;
    height: 61vh;

    overflow-y: scroll;
    margin: 30px 10px;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    background: rgb(255, 255, 255);
    border-radius: 55px;
    position: absolute;
    width: revert;
    height: 628px;
    padding: 20px 0px 0px;
    overflow-y: scroll;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    background: rgb(255, 255, 255);
    border-radius: 55px;
    position: absolute;
    width: auto;
    height: 628px;
    padding: 20px 0px 0px;
    margin: -50px 0px 0px;
    overflow-y: scroll;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    background: rgb(255, 255, 255);
    border-radius: 55px;
    width: auto;
    position: absolute;
    height: 628px;
    padding: 20px 0px 0px;
    margin: -30px 0px 0px;
    overflow-y: scroll;
  }
`;
const SectionWrapperInner = styled.div`
  background: rgba(208, 76, 141, 0.1);
  border-radius: 12px;
  width: 257px;
  height: 41px;
  margin: 20px 0 10px 35px;
`;
const CheckBoxTitle = styled.div`
  font-family: "Gelasio";
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #202020;
  padding: 10px 0 0 20px;
`;
const HamLayout = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    top: 30%;
    left: 83%;
    z-index: 100;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    position: absolute;
    top: 27%;
    left: 94%;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    position: absolute;
    left: 95%;
    top: 26%;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    // display: none;
  }
  @media (min-width: 1200px) and (max-width: 2560px) {
    display: none;
  }
`;
const MainSec = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    display: none;
  }
  @media (min-width: 481px) and (max-width: 768px) {
    display: none;
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    display: none;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    display: none;
  }
`;
const PaginationMain = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  margin-top: 180px;
`;

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));

const TalentShortlist = ({}) => {
  useSnapshot(jobProxy);
  useSnapshot(companyProxy);
  const [talentData, setTalentData] = useState([]);
  const [talentDataBackup, setTalentDataBackup] = useState([]);
  const [states, setStates] = useState([]);
  const [addItem, setAddItem] = useState([]);
  const [setFilters, setSetFilters] = useState([]);

  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [statesList, setStatesList] = useState([]);
  const [isOpenham, setOpenHam] = useState(false);

  const [titlesList, setTitlesList] = useState([]);
  const [institutes, setInstitutes] = useState([]);
  const [skillsList, setSkillsList] = useState([]);

  const [filtereedData, setFilteredData] = useState([]);

  const classes = useStyles();

  const displayPage = (val) => {
    setPaginationTabIndex(val);
  };

  const [numFilters, setNumFilters] = useState([]);
  const [universityFilters, setUniversityFilters] = useState([]);
  const [skillsFilters, setSkillsFilters] = useState([]);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [data, setData] = useState([
    {
      name: "Angie Wong",
      proffesion: "Accountant | M.Com (Economics)",
      match: 70,
      location: "Ampang, Selangor",
    },
    {
      name: "Angie Wong",
      proffesion: "Accountant | M.Com (Economics)",
      match: 65,
      location: "Ampang, Selangor",
    },
    {
      name: "Angie Wong",
      proffesion: "Accountant | M.Com (Economics)",
      match: 60,
      location: "Ampang, Selangor",
    },
  ]);

  const getStateValue = (val) => {
    const findState = states.find((e) => e.Id === +val);
    return findState?.Name;
  };

  const profileNavigation = (id) => {
    jobProxy.userid = id;
    navigate(`/jobs/view-profile`);
  };

  useState(() => {
    setFilteredData(...talentData);
  }, [talentData]);

  const getApps = async () => {
    const { data } = await getApplications(
      companyProxy?.selectcompany?.org_code
    );
    if (data?.status == 1) {
      const talents = data?.data?.filter((e) => e.status === "shortlist");
      setTalentData(talents);
      setTalentData(talents);
    }
  };
  useEffect(() => {
    getApps();
  }, [companyProxy?.selectcompany?.org_code]);

  useEffect(() => {
    let dt = [];
    let titles = [];
    let institutesList = [];
    let skillsList = [];
    if (talentDataBackup?.length > 0) {
      talentDataBackup?.map((e) => {
        const val = getStateValue(e.state);
        if (!dt.includes(val)) {
          dt.push(val);
        }
        if (!titles.includes(e.title)) {
          titles.push(val);
        }
        if (!institutesList.includes(e?.insti_name)) {
          institutesList.push(e?.insti_name);
        }

        e?.titles?.map((x) => {
          if (!skillsList.includes(x)) skillsList.push(x);
        });
      });
      setStatesList(dt);
      setTitlesList(titles);
      setInstitutes(institutesList);
      setSkillsList(skillsList);
    }
  }, [talentDataBackup]);

  const saveData = (data, type) => {
    let percentFilters = [];
    let instiFilters = [];
    let skillsF = [];
    let dtNUm = [...numFilters];
    let dtUNi = [...universityFilters];
    let dtSkills = [...skillsFilters];
    if (type === "num") {
      const idx = dtNUm.findIndex((e) => e === data);
      if (idx > -1) {
        dtNUm.splice(idx, 1);
      } else {
        dtNUm.push(data);
      }
      setNumFilters(dtNUm);
      percentFilters = dtNUm;
    }

    if (type === "insti") {
      const idx = dtUNi.findIndex((e) => e === data);
      if (idx > -1) {
        dtUNi.splice(idx, 1);
      } else {
        dtUNi.push(data);
      }
      setUniversityFilters(dtUNi);
      instiFilters = dtUNi;
    }

    if (type === "skills") {
      const idx = dtSkills.findIndex((e) => e === data);
      if (idx > -1) {
        dtSkills.splice(idx, 1);
      } else {
        dtSkills.push(data);
      }
      setSkillsFilters(dtSkills);
      skillsF = dtSkills;
    }
    setFilters(dtNUm, dtUNi, dtSkills);
  };

  return (
    <MainLayout>
      <HeaderText>{t("My Saved Profiles")}</HeaderText>
      <ButtonWrapper>
        <Button
          cursor="pointer"
          name={t("Go to Talent Pool")}
          onClick={() => navigate("/talentsearch")}
        ></Button>
      </ButtonWrapper>

      <div style={{ display: "flex", gap: "20px" }}>
        <div>
          <SectionWrapper>
            <Title>{t("Filter Selection")}</Title>
            <ButtonWrapper2>
              <Button cursor="pointer" name={t("Filter")}></Button>
            </ButtonWrapper2>
          </SectionWrapper>

          <div style={{ marginTop: "20px" }}>
            {talentData?.length > 0
              ? talentData?.map((item, index) => (
                  <MainWrapper>
                    <SubWrapper>
                      <div style={{ display: "flex" }}>
                        <Form.Group className="shorListMain">
                          <Form.Check
                            onChange={(e) => addItem(item)}
                          ></Form.Check>
                        </Form.Group>
                        <Image src={item.profile_picture} />
                        <ContentWrapper className="shortlistName">
                          <MediumText>{item.name}</MediumText>
                          <SmallText>{item.proffesion}</SmallText>
                          <LinkText
                            onClick={() => profileNavigation(item?.user_id)}
                          >
                            {t("View Profile")}
                          </LinkText>
                        </ContentWrapper>
                      </div>
                      <PersonalityCard>
                        <MediumText className="ShorlistMatch">
                          {t("Personality Match")} :{" "}
                        </MediumText>
                        <CircularProgressbar
                          value={item.percentage}
                          text={`${item.percentage}%`}
                          styles={buildStyles({
                            textColor: "black",
                            pathColor: "#EE4BA0",
                          })}
                        />
                      </PersonalityCard>
                    </SubWrapper>
                    <hr />
                    <Location>{item?.state_name}</Location>
                  </MainWrapper>
                ))
              : null}
          </div>
        </div>
        <HamLayout>
          <MdOutlineKeyboardDoubleArrowLeft
            onClick={() => setOpenHam(!isOpenham)}
            color="#d63384"
            size={40}
          />
        </HamLayout>

        {isOpenham && (
          <SectionWrapper2>
            <SectionWrapperInner>
              <CheckBoxTitle> {t("University")}</CheckBoxTitle>
            </SectionWrapperInner>
            <Row>
              <Form.Group className="shorlistInstitue">
                {institutes.map((e) => (
                  <Form.Check
                    label={e}
                    onClick={() => saveData(e, "insti")}
                  ></Form.Check>
                ))}
              </Form.Group>
            </Row>
            <SectionWrapperInner>
              <CheckBoxTitle>{t("Skills")}</CheckBoxTitle>
            </SectionWrapperInner>
            <Row>
              <Form.Group className="shorlistSkill">
                {skillsList.map((e) => (
                  <Form.Check
                    label={e}
                    onClick={() => saveData(e, "skills")}
                  ></Form.Check>
                ))}
              </Form.Group>
            </Row>

            <SectionWrapperInner>
              <CheckBoxTitle>{t("Match Percentage")}</CheckBoxTitle>
            </SectionWrapperInner>
            <Row>
              <Form.Group className="ShorlistPercentage">
                <Form.Check
                  label="10% - 50%"
                  onClick={() => saveData("10% - 50%", "num")}
                ></Form.Check>
                <Form.Check
                  label="50% - 70%"
                  onClick={() => saveData("50% - 70%", "num")}
                ></Form.Check>
                <Form.Check
                  label="70% - 90%"
                  onClick={() => saveData("70% - 90%", "num")}
                ></Form.Check>
                <Form.Check
                  label="90% - 100%"
                  onClick={() => saveData("90% - 100%", "num")}
                ></Form.Check>
              </Form.Group>
            </Row>
          </SectionWrapper2>
        )}
        <MainSec>
          <SectionWrapper2>
            <SectionWrapperInner>
              <CheckBoxTitle>{t("University")}</CheckBoxTitle>
            </SectionWrapperInner>
            <Row>
              <Form.Group className="shorlistInstitue">
                {institutes.map((e) => (
                  <Form.Check
                    label={e}
                    onClick={() => saveData(e, "insti")}
                  ></Form.Check>
                ))}
              </Form.Group>
            </Row>
            <SectionWrapperInner>
              <CheckBoxTitle>{t("Skills")}</CheckBoxTitle>
            </SectionWrapperInner>
            <Row>
              <Form.Group className="shorlistSkill">
                {skillsList.map((e) => (
                  <Form.Check
                    label={e}
                    onClick={() => saveData(e, "skills")}
                  ></Form.Check>
                ))}
              </Form.Group>
            </Row>

            <SectionWrapperInner>
              <CheckBoxTitle>{t("Match Percentage")}</CheckBoxTitle>
            </SectionWrapperInner>
            <Row>
              <Form.Group className="ShorlistPercentage">
                <Form.Check
                  label="10% - 50%"
                  onClick={() => saveData("10% - 50%", "num")}
                ></Form.Check>
                <Form.Check
                  label="50% - 70%"
                  onClick={() => saveData("50% - 70%", "num")}
                ></Form.Check>
                <Form.Check
                  label="70% - 90%"
                  onClick={() => saveData("70% - 90%", "num")}
                ></Form.Check>
                <Form.Check
                  label="90% - 100%"
                  onClick={() => saveData("90% - 100%", "num")}
                ></Form.Check>
              </Form.Group>
            </Row>
          </SectionWrapper2>
        </MainSec>
      </div>
    </MainLayout>
  );
};

export default TalentShortlist;
