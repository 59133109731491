import { Navigate, useLocation } from "react-router-dom";

function AuthRoute({ children }) {
  const location = useLocation();
  const currentURL = window.location.href;
  const regex = /\/([^\/]+)\/?$/
  const parts = currentURL.match(regex);
  const id = parts ? parts[1] : null;
  let pathName = location && location.pathname.replace('/', '');
  console.log("ppppPath", pathName,id)
  
  const currentUser = localStorage.getItem("auth_token");
  let redirectTo = false;
  if(pathName.includes("skill-gap-evaluation")){
    redirectTo = true;
pathName = "skill-gap-evaluation";
  }

  return currentUser ? children : pathName === "annual-survey" || "skill-gap-evaluation"   ? <Navigate to={"/login?redirectTo=" + pathName + (id ? "&id=" + id : "")}  /> : <Navigate to="/login" />;
}

export default AuthRoute;
