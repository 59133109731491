import React from "react";
import { Col, Dropdown, Row } from "react-bootstrap";
import { Ad2CellCard, Ad2DataTableWrapper, Ad2Dropdown, CardHeaderRightWrapper, CardHeaderTitle, CardHeaderVariantOne, HtmlLegendChartWrapper, LeftWrapper, RightWrapper, SumText, SumWrapper } from "./adCardStyles";
import { ReactComponent as Table } from "../../assets/svg/table.svg";
import { ReactComponent as Share } from "../../assets/svg/share.svg";
import { ReactComponent as Sum } from "../../assets/svg/sum.svg";
// import ReactExport from "react-export-excel";
import { CSVLink } from "react-csv";
import { Utils } from "./utilities";
import DataTable from "react-data-table-component";
import NoDataChartContainer from "./noDataFoundChart";
import ChartLoader from "./chartLoader";
import { useTranslation } from "react-i18next";
import "./adCard.css"
import * as FileSaver from "file-saver";
import XLSX from 'sheetjs-style';

// const ExcelFile = ReactExport.ExcelFile;
// const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
// const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export function Ad2Card({ showButton, columns, data, exportFileName = "download", title,sumpersonal, sum, ...props }) {
    const { t, i18n } = useTranslation();
    const [csvData, setCsvData] = React.useState(null);
    const [isTable, setIsTable] = React.useState(false);

    React.useEffect(() => {
        if (data && data.length) {
            setCsvData(Utils.convertToCSV(data));
        }
    }, []);
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = ".xlsx";
    function exportExcel(columnData) {
        console.log("exportStudent",columnData)
        const ws = XLSX.utils.json_to_sheet(columnData);
        const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: fileType });
        FileSaver.saveAs(data, exportFileName + fileExtension);
    }
    return <>
        <Ad2CellCard>
            <CardHeaderVariantOne>
                <Row>
                    <Col className="d-flex align-items-center justify-content-between" xs="12">
                        <LeftWrapper>
                            <CardHeaderTitle>{title}</CardHeaderTitle>
                            {props.leftContent ? props.leftContent : ''}
                        </LeftWrapper>
                       {
                       
                      showButton ? <RightWrapper style={{paddingBottom:"18px",paddingTop:"17px"}}>
                      {props.rightContent ? props.rightContent : ''}
                      {typeof sum !== 'undefined' ? <SumWrapper>
                          <Sum /><SumText>&nbsp;:&nbsp;{sum ? Utils.intToString(sum) : 0 } / {sumpersonal}</SumText>
                      </SumWrapper> : ''}
            
                  </RightWrapper> : <RightWrapper>
                            {props.rightContent ? props.rightContent : ''}
                            {typeof sum !== 'undefined' ? <SumWrapper>
                                <Sum /><SumText>&nbsp;:&nbsp;{sum ? Utils.intToString(sum) : 0 } / {sumpersonal}</SumText>
                            </SumWrapper> : ''}
                          { 
                          
                          <CardHeaderRightWrapper className="cardHead">
                                <div className="cardHeadTwo" ><Table onClick={() => setIsTable(!isTable)} /></div>
                                <Ad2Dropdown>
                                    <Dropdown.Toggle variant="asdf" id="dropdown-basic">
                                        <Share />
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item as="div" className="dropdiv">{csvData && <CSVLink as="div" className="dropTwo" data={csvData} filename={exportFileName}>{t("CSV Export")}</CSVLink>}</Dropdown.Item>
                                        <Dropdown.Item onClick={()=>{
                                                   const newDataColumn = data.map(row => {
                                                    const transformedRow = {};
                                                    columns.forEach(column => {
                                                        const key = column.name || ''; // Default to empty string if name is empty
                                                        transformedRow[key] = row[column.selectorKey];
                                                    });
                                                    return transformedRow;
                                                });

                                                exportExcel(newDataColumn)
                                        }} as="div">
                                            <div>Export Excel</div>
                                            {/* <ExcelFile element={<div>{t("Excel Export")}</div>} filename={exportFileName}>
                                                <ExcelSheet data={data} name={exportFileName}>
                                                    {columns && columns.length && columns.map((column, index) => <ExcelColumn key={index} label={column.name} value={column.selectorKey} />
                                                    )}
                                                </ExcelSheet>
                                            </ExcelFile> */}
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Ad2Dropdown>
                            </CardHeaderRightWrapper>}
                        </RightWrapper>}
                    </Col>
                </Row>
            </CardHeaderVariantOne>
            <Row className="slide-wrapper">
                {props.status !== 'loading' ? props.status === 'noDataFound' ? <NoDataChartContainer /> :
                    <>{props.children}</> : <ChartLoader />}
                <Ad2DataTableWrapper className={`hid-box ${isTable ? 'visible' : ''}`}>
                    <DataTable columns={columns} data={data || []} />
                </Ad2DataTableWrapper>
            </Row>
        </Ad2CellCard>
    </>;
}