import React from 'react'
import styled from 'styled-components';
import { Image,  SubHeading, ContentText, MainText } from './common'
import Img from "../../assets/Ellipse 83.png";
const ContentCard = styled.div`
  display:grid;
  grid-template-columns:1fr 3fr;
`;
const ProfileWrapper= styled.div`
  display:flex;
  flex-direction:column;
`;
const Wrapper= styled.div`
  display:flex;
  flex-direction:row;
  padding:24px 42px;
  gap:24px;
  padding-bottom:72px;
  @media (max-width: 1200px) {
    padding:16px;
    flex-direction:column;
   }
`;
export default function Profile({open}) {
    return (
        <Wrapper>
            <Image src={Img} onClick={open}/>
            <ProfileWrapper>
                <MainText style={{marginBottom:"0px"}}>Zinhi, Abdullah</MainText>
                <hr />
                <ContentCard>
                <SubHeading>Scope of Study:</SubHeading> <ContentText>Analysis System</ContentText>
                <SubHeading>Education Institution:</SubHeading> <ContentText>Advance Tertiary College</ContentText>
                <SubHeading>Address:</SubHeading> <ContentText>Medan Maju Jaya, Jalan Lama, Wilayah <br/> Persekutuan, Klang, Selangor, Malaysia, 46000 hafidz@cxsanalytics.com</ContentText>
                <SubHeading>Primary e-mail id:</SubHeading> <ContentText>hafidz@cxsanalytics.com</ContentText>
                <SubHeading>Primary mobile no:</SubHeading> <ContentText>0123456789</ContentText>
                </ContentCard>
            </ProfileWrapper>
        </Wrapper>
    )
}
