import React, { useState } from "react";
import share from "../../../../assets/student-share.png"
import "./feedback.css";
import { t } from "i18next";
import styled from 'styled-components';

const FeedbackInput = styled.input`
  background: rgb(243, 243, 243);
  border-radius: 0px !important;
  border: 1px solid #d04c8d;
  padding: 4px !important;
  outline: none;
  width: 190px !important;
  font-weight: 400 !important;
  font-size: 15px !important;
  margin-left: 0px !important;
`;

const FeedbackComponent = (props) => {
  const [feedback, setFeedback] = useState("");
  const [disableInput, setDisableInput] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
  }

  return <form onSubmit={handleSubmit} className="feedback-container">
    <FeedbackInput type="text" id="feedback" name="feedback" placeholder={t("Type your feedback here")} onChange={(e) => setFeedback(e.target.value)} disabled={disableInput}/>
    <span className="icon-submit-btn">
      <button type="submit" className="feedback-submit-btn" disabled={disableInput} onClick={() => {
        props.actionProvider.handleReceivedFeedback();
        setDisableInput(true);
      }}>
      <img className="icon-submit-icon" src={share}/>
      </button>
    </span>
  </form>;
};

export default FeedbackComponent;