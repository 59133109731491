import { proxy } from 'valtio'
import { subsctipationsProxy } from '../Subscriptions/subscripationsProxy'
let companyProxy = proxy({
    setcompany:(v)=>{
        companyProxy.selectcompany = v
        if(v && v.org_code){
            subsctipationsProxy.setcount(v.org_code)
        }
    },
    selectcompany:null,
    joblist:[],
    applist:[]
})


export {
    companyProxy
}
