import React from "react";

import "./list.css";
import { t } from "i18next";

const ListComponent = (props) => {
  const options = [
    {
      text: "Overcome the boundaries of space and gain access to a wide range of qualified candidates from all over the country via one online platform.",
      id: 1,
    },
    {
      text: "Gain access to competent potential candidates that have undergone the mynext Profiling Suite, which includes assessments related to work values, work interests, personality and motivation, employability.",
      id: 2
    },
    {
      text: "Ensure candidates' English competency is at par with your organisation's requirements via an English proficiency test made compulsory to all students prior to applying for internships.",
      id: 3
    },
    {
      text: "Provide internships that cater to your needs by choosing between two type of internship availabilities, General Internship or TalentCorp's specialised National Structured Internship Programme (MySIP), that comes with double tax deduction benefits.",
      id: 4
    },
    {
      text: "Reach wider, more diverse talent pools by advertising internship postings across all mynext social media platforms, and website, inclusive of paid advertising.",
      id: 5
    },
    {
      text: "Enjoy start-to-end internship management tools and a 360' review feature that enables you to assess your internship experience with us, all on one seamless platform.",
      id: 6
    },
  ];

  const listMarkup = options.map((option) => (
    <li key={option.id} className="list-item">
      {t(option.text)}
    </li>
  ));

  return <div className="list-container"><ul className="no-bullets">{listMarkup}</ul></div>;
};

export default ListComponent;