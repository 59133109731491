import React from "react";
import { Route, Routes } from "react-router-dom";
import Loader from "../loader/loader";
import RequireAuth from "../requireAuth/requireAuth";


// required fields starts
export const validateInputs = (obj) => {
    const values = Object.values(obj);
    const isValid = values.every((val) => val.length > 1);
   
    return isValid;
};
// required fields ends

// email validation starts
export const validEmailRegex = RegExp(
    /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
// email validation ends

export const checkBothPasswords = (pwdOne, pwdTwo) => {
    let errVal = true;
    if (pwdTwo.length > 0) {
        errVal = pwdOne === pwdTwo ? true : false;
    }
    return errVal;
};



export const customLabelsPlugin = {
    id: "customLabels",
    afterDraw: (chart, args, options) => {
        const {
            plugins: {
                customLabels: { labelColor: customLabelColor, arrowColor, font, display },
            },
        } = chart.config.options;
        if (display === false) {
            return;
        }
        const ctx = chart.ctx;
        ctx.save();
        ctx.font = font ? font : "14px 'Averta Std CY'";
        const leftLabelCoordinates = [];
        const rightLabelCoordinates = [];
        const chartCenterPoint = {
            x:
                (chart.chartArea.right - chart.chartArea.left) / 2 +
                chart.chartArea.left,
            y:
                (chart.chartArea.bottom - chart.chartArea.top) / 2 +
                chart.chartArea.top,
        };
        chart.config.data.labels.forEach((label, i) => {
            const arc = chart.getDatasetMeta(0).data[i];
            const dataset = chart.config.data.datasets[0];

            // Prepare data to draw
            // important point 1
            const centerPoint = arc.getCenterPoint();
            const model = arc.options;
            let color = model.borderColor;
            let labelColor = model.borderColor;
            if (!arrowColor) {
                if (dataset.polyline && dataset.polyline.color) {
                    color = dataset.polyline.color;
                }
            } else {
                color = arrowColor;
            }
            if (!customLabelColor) {
                if (dataset.polyline && dataset.polyline.labelColor) {
                    labelColor = dataset.polyline.labelColor;
                }
            } else {
                labelColor = customLabelColor;
            }

            const angle = Math.atan2(
                centerPoint.y - chartCenterPoint.y,
                centerPoint.x - chartCenterPoint.x
            );
        
            const point2X =
                chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + 15);
            let point2Y =
                chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + 15);

            let suitableY;
            if (point2X < chartCenterPoint.x) {
                // on the left
                suitableY = getSuitableY(point2Y, leftLabelCoordinates, "left");
            } else {
                // on the right

                suitableY = getSuitableY(point2Y, rightLabelCoordinates, "right");
            }

            point2Y = suitableY;

            let value = chart.config.data.labels[i];
            if (dataset.polyline && dataset.polyline.formatter) {
                value = dataset.polyline.formatter(value);
            }
            let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

            if (point2X < chartCenterPoint.x) {
                leftLabelCoordinates.push(point2Y);
            } else {
                rightLabelCoordinates.push(point2Y);
            }
            //DRAW CODE
            // first line: connect between arc's center point and outside point
            ctx.strokeStyle = color;
            ctx.lineWidth = 1;
            ctx.beginPath();
            ctx.moveTo(centerPoint.x, centerPoint.y);
            ctx.lineTo(point2X, point2Y);
            ctx.stroke();
            // second line: connect between outside point and chart's edge
            ctx.beginPath();
            ctx.moveTo(point2X, point2Y);
            ctx.lineTo(edgePointX, point2Y);
            ctx.stroke();
            //fill custom label
            const labelAlignStyle =
                edgePointX < chartCenterPoint.x ? "left" : "right";
            const labelX = edgePointX;
            const labelY = point2Y;
            ctx.textAlign = labelAlignStyle;
            ctx.textBaseline = "bottom";

            ctx.fillStyle = labelColor;
            ctx.fillText(value, labelX, labelY);
        });
        ctx.restore();
    },
};

const getSuitableY = (y, yArray = [], direction) => {
    let result = y;
    yArray.forEach((existedY) => {
        if (existedY - 14 < result && existedY + 14 > result) {
            if (direction === "right") {
                result = existedY + 14;
            } else {
                result = existedY - 14;
            }
        }
    });

    return result;
};

export function isEmpty(obj) {
    for (var prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export const stringFormat = function (url, ...args) {
    let i = 0;
    console.log(
        url.replace(/{}/g, function () {
            return typeof args[i] != "undefined" ? args[i++] : "";
        })
    );
    i = 0;
    return url.replace(/{}/g, function () {
        return typeof args[i] != "undefined" ? args[i++] : "";
    });
};
export const routeGenerator = (routes) => {
    const routeCreate = (route) => {
      if (route.children && route.children.length) {
        return addAuthRoute(
          <Route key={route.path} exact path={route.path} element={route.element}>
            {route.children.map((childRoute) => {
              return routeCreate(childRoute);
            })}
          </Route>,
          route.isAuthRoute,
          route.path,
          route.authorizationFor
        );
      } else {
        return generateRoute(route);
      }
    };
    const addAuthRoute = (component, isAuthRoute, key, authorizationFor) => {
      if (isAuthRoute) {
        return (
          <RequireAuth key={key} authorizationFor={authorizationFor}>
            {component}
          </RequireAuth>
        );
      } else {
        return component;
      }
    };
    const generateRoute = (route) => {
      if (route.lazyRoute) {
        const Component = route.lazyRoute;
        return (
          <Route
            path={route.path}
            key={route.path}
            element={addAuthRoute(
              <React.Suspense fallback={<Loader />}>
                <Component />
              </React.Suspense>,
              route.isAuthRoute,
              route.path,
              route.authorizationFor
            )}
          />
        );
      } else {
        return (
          <Route
            key={route.path}
            exact
            path={route.path}
            element={route.element}
          />
        );
      }
    };
    return <Routes>{routes.map((route) => routeCreate(route))}</Routes>;
  };
export const isAuthorizedToAccess = (authorizationFor, user) => {
    if (user && user.roles && user.roles.length) {
        return authorizationFor.some((authRole) =>
            user.roles.find((x) => x.name === authRole)
        );
    } else {
        return true;
    }
};
export const getLocationFormatted = (location) => {
    const addCommaIfNeeded = (value) => `${value}${value && ", "}`;
    return (
        <div>
            <div>{addCommaIfNeeded(location.address_line_one)}</div>
            <div>{addCommaIfNeeded(location.address_line_two)}</div>
            <div>{`${addCommaIfNeeded(location.city)}${addCommaIfNeeded(
                location.state
            )} ${addCommaIfNeeded(location.country)} ${location.postcode}`}</div>
        </div>
    );
};
export const formatDate = (date) => {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [year, month, day].join("-");
};
export const formatTime = (time) => {
    var t = new Date(time),
        hour = "" + t.getHours(),
        minute = "" + t.getMinutes();

    if (hour.length < 2) hour = "0" + hour;
    if (minute.length < 2) minute = "0" + minute;

    return [hour, minute].join(":");
};
