import { API_ROUTES } from "../constants/apiRoutes";
import { stringFormat } from "../utilities/commonFunctions";
import { onApiCall } from "./CommonApi";

class UserManagementService {
  getUserManagmentUsers(id, params, controller) {
    return onApiCall({
      url: stringFormat(API_ROUTES.GET_COMPANY_EMPLOYEE_MANAGMENT, id),
      method: "GET",
      params: params,
      controller,
    });
  }
  updateUniversityStudentById(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_COMPANY_EMPLOYEE_DETAILS, userId),
      method: "PUT",
      params: userData,
    });
  }
  createStudent(id, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.CREATE_COMPANY_EMPLOYEE, id),
      method: "POST",
      data: userData,
    });
  }
  updateSingleUniversityStudentById(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_COMPANY_EMPLOYEE_DETAILS, userId),
      method: "PUT",
      data: userData,
    });
  }

  deleteSingleEmployee(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.UPDATE_COMPANY_EMPLOYEE_DETAILS, userId),
      method: "DELETE",
      data: userData,
    });
  }
  getDrilldown = (url, { params, controller }) => {
    return onApiCall({
      url,
      method: "GET",
      params: params,
      controller,
    });
  };
  importBulkStudents(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.IMPORT_BULK_EMPLOYEE, userId),
      method: "POST",
      data: userData,
    });
  }
  importBulkStudentsAfterValidation(userId, userData) {
    return onApiCall({
      url: stringFormat(
        API_ROUTES.IMPORT_BULK_EMPLOYEE_AFTER_VALIDATION,
        userId
      ),
      method: "POST",
      data: userData,
    });
  }
  importBulkStudentSingalValidation(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.IMPORT_BULK_EMPLOYEE, userId),
      method: "PUT",
      data: userData,
    });
  }
  downloadStudent(userId, params) {
    return onApiCall({
      url: stringFormat(API_ROUTES.DOWNLOAD_EMPLOYEE, userId),
      method: "GET",
      params: params,
    });
  }
  sendSingleReminder(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.SINGLE_REMINDER, userId),
      method: "POST",
      data: userData,
    });
  }
  sendMultipleReminders(userId, userData) {
    return onApiCall({
      url: stringFormat(API_ROUTES.MULTIPLE_REMINDERS, userId),
      method: "POST",
      data: userData,
    });
  }
}

export default new UserManagementService();
