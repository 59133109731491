import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import { Colors } from "../../utilities/colors";
import DeleteIcon from "../../assets/icons/svg/trash.svg";
import EditIcon from "../../assets/icons/svg/pencil.svg";
import AddNewLocation from "../addNewLocation/addNewLocation";
import { updateLoader } from "../../pages/myCompany/reducer";
import {
  onDeleteLocation,
  getCompanyData,
  onViewLocation,
} from "../../services/apiCalls";
import { updateEdit, updateLocationData } from "../addNewLocation/reducer";
import { initialObj } from "../addNewLocation/staticContent";
import AlertModal from "../alertModal/alertModal";
import Button from "../button/button";
import { useTranslation } from "react-i18next";
import { updateCompanyData } from "../../pages/myCompany/reducer";

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrapper = styled.div`
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 10px;
  padding: 25px;
  margin: 30px 0;
`;
const HeaderSection = styled(FlexWrapper)`
  align-items: center;
  border-bottom: 0.3px solid ${Colors.labelText};
  padding-bottom: 10px;
  > h3 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.primaryText};
    margin: 0;
  }
`;

const DividerLine = styled.hr`
  border: 0.4px solid rgba(32, 32, 32, 0.22);
`;
const LocationSection = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
  margin: 20px 0;
`;
const SingleSection = styled.div`
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
    text-transform: capitalize;
  }
  > p {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0;
    color: #202020;
    text-transform: capitalize;
  }
`;
const Icon = styled.img`
  margin: 0px 10px;
  cursor: pointer;
  display: block;
`;
const AddButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;
const Message = styled.div`
  margin-bottom:20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
export default function Location(props) {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.company.loading);
  const companyOrgs = useSelector((state) => state.company.companyData);
  const [company,setCompany] = useState(props.companyDetails)
  const company_status = company.status//companyOrgs[props.companyIdx].org_status;
  const [open, setOpen] = useState(false);
  const [show, setShow] = useState(false);
  const [id, setId] = useState(false);
  const isUserPermittedToEdit = companyOrgs[props.companyIdx].user_details.company_modify === 'Y' ? true : false;
  const closeModal = () => {
    setShow(false);
  };


  const [isPrimaryLocation, setIsPrimayLocation] = useState(false);
  const {t} = useTranslation();
  useEffect(() => {
    for (var i = 0; i < company?.locations?.length; i++) {
      if (company.locations[i]?.status === "primary") {
        setIsPrimayLocation(true);
        break;
      }
 
      if (company.locations.length === i + 1) {
        setIsPrimayLocation(false);
      }
    }
  }, [company.location]);

  const content = () => {
    return (
      <>
        <Message>{t('Are you sure want to delete this location?')}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="20px 10px 0px 0px"
              name={t("Yes")}
              onClick={onDelete}
            />
            <Button
              margin="20px 10px 0px 0px"
              secondary
              color="#FFFFFF"
              name={t("Cancel")}
              variant="outlined"
              onClick={closeModal}
            >

            </Button>
          </Col>
        </Row>
      </>
    );
  };
  const onAdd = () => {
    dispatch(updateEdit(false));
    dispatch(updateLocationData(initialObj));
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
    getData()
  };

  const onEdit = async (id) => {
    const { data } = await onViewLocation({
      id: id,
    });
    if (data && data.status === 1) {
      dispatch(updateLocationData(data.data));
      dispatch(updateEdit(true));
      setOpen(true);
    }
  };
  const deleteModal = (id) => {
    setShow(true);
    setId(id);
    
  };
  const onDelete = async () => {
    const { data } = await onDeleteLocation({ id: id });
    closeModal();
    if (data && data.status === 1) {
      toast.success(t("Location Successfully Deleted"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    getData()
  };

  const getData = async () => {
    const { data } = await getCompanyData({
      code: companyOrgs[props.companyIdx].org_code,
    });
    if (data && data.status === 1) {
      
      setCompany(data?.data)
    }
  };

  return (
    <SectionWrapper style={{background:props?.primaryColor}}>
      <HeaderSection>
        <h3 style={{color:props?.secondaryColor}}>{t("Location")}</h3>
      
        </HeaderSection>
        {company && company.locations ? (
          company.locations.map((location, index) => (
            <>
              {location.status.toLowerCase() === "primary" ?
                <LocationSection key={location + index}>
                  <SingleSection>
                    <h3>{t(location.status)}</h3>
                    <p>
                      {t(location.rgtr_address)}, {t(location.city_abbr)}
                    </p>
                    <p>
                      {t(location.state_abbr)}, {t(location.country_abbr)}
                    </p>
                    <p>
                      {t(location.country_code)}
                    </p>
                    
                  </SingleSection>
                  {(company_status != 'denied' && isUserPermittedToEdit) ? <FlexWrapper>
                    <Icon onClick={() => onEdit(location.id)} src={EditIcon}  color={Colors.greyText}  />
                    {location.status.toLowerCase() === "primary" ? (
                      ""
                    ) : (
                      <Icon
                        onClick={() => deleteModal(location.id)}
                        src={DeleteIcon}
                      />
                    )}
                  </FlexWrapper> : <></>}
                </LocationSection>
                :
                ""
              }
            </>
          ))
        ) : (
          <p><p class="noDataFound">{t('No data found')}</p></p>
        )}
        {company && company.locations ? (
          company.locations.map((location, index) => (
            <>
              {location.status.toLowerCase() === "secondary" ?
                <LocationSection key={location + index}>
                  <SingleSection>
                    <h3>{t(location.status)}</h3>
                    <p>
                      {t(location.rgtr_address)}, {t(location.city_abbr)}
                    </p>
                    <p>
                      {t(location.state_abbr)}, {t(location.country_abbr)}
                    </p>
                    <p>
                      {t(location.country_code)}
                    </p>
                  </SingleSection>
                  <FlexWrapper>
                    {(!(['denied'].includes(company_status)) &&isUserPermittedToEdit) ? <>
                      <Icon onClick={() => onEdit(location.id)} src={EditIcon} />
                      {location.status.toLowerCase() === "primary" ? (
                        ""
                      ) : (
                        <Icon
                          onClick={() => deleteModal(location.id)}
                          src={DeleteIcon}
                        />
                      )}</> : <></>}
                  </FlexWrapper>
                </LocationSection>
                :
                ""
              }
            </>
          ))
        ) : (
          <p><p class="noDataFound">{t('No data found')}</p></p>
        )}
        <DividerLine />
        {!isPrimaryLocation || company?.locations?.length < 2 ? (!(['denied'].includes(company_status)) && isUserPermittedToEdit) ? (
          <AddButton onClick={onAdd}>
            {t(!isPrimaryLocation ? t("+ Add location") : t("+ Add secondary location"))}
          </AddButton>
        ) : <></> : (
          ""
        )}
        {open && (
          <AddNewLocation
            companyIdx={props.companyIdx}
            companyDetails={company}
            open={open}
            onClose={onCloseModal}
            isPrimaryLocation={isPrimaryLocation}
          />
        )}
        <AlertModal show={show} close={closeModal} content={content()} />
      </SectionWrapper>
  );
}
