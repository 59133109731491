import React from "react";
import ReactPlayer from "react-player";
import "./video.css";
import { t } from "i18next";

const VideoPlayerComponent = (props) => {

  return <div className="player-wrapper">
    <ReactPlayer
      url="https://www.youtube.com/watch?v=9OdsiS0JXYg"
      className="react-player"
      playing={false}
      width="100%"
      height="100%"
    />
  </div>;
};

export default VideoPlayerComponent;