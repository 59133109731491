import React, { useState } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import MyCalendarView from '../../components/myCalendar/myCalendar'
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import { useSnapshot } from "valtio";
import { companyProxy } from "../myCompany/companyproxy";
import { useEffect } from "react";
import { getApplications, getjobslist } from "../../services/apiCalls";
import { mycalendarProxy } from "./mycalendarproxy";
import Dropdown from "../../components/dropdown/dropdown1";
import { useTranslation } from "react-i18next";


const HeaderText = styled.h2`
  // margin-top:-20px;
  margin-bottom:1%;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  @media (max-width: 500px) {
  margin:20px 0 0 5px;
    }
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";
  @media (max-width: 767px) {
    // display: none;
  }
`;

const SectionWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 12px;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;

const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export default function MyCalendar() {
  useSnapshot(companyProxy);
  useSnapshot(mycalendarProxy);

  const [task, setTask] = useState([]);
  const { t, i18n } = useTranslation();
  const [filapplications, setFilapplications] = useState({
    label: t("All"),
    value: "-1",
  });
  const [tags, setTags] = useState({ label: "Tags", value: "-1" });

  const [search, setSearch] = useState("");
  useEffect(() => {
    chkser();
  }, [filapplications, tags]);

  const chkser = () => {
    if (search.length === 0) {
      setTask(mycalendarProxy.applist);
      checkfilapp(mycalendarProxy.applist);
    } else {
      let s = search.toLowerCase();
      let a = [];
      for (let i = 0; i < mycalendarProxy.applist.length; i++) {
        if (
          mycalendarProxy.applist[i]["name"].toLowerCase().indexOf(s) !== -1 ||
          (mycalendarProxy.applist[i]["engage_jobposition"] == "" &&
            mycalendarProxy.applist[i]["interview_title"].toLowerCase().indexOf(s) !== -1) ||
          (mycalendarProxy.applist[i]["engage_jobposition"] &&
            mycalendarProxy.applist[i]["engage_jobposition"]
              .toLowerCase()
              .indexOf(s) !== -1)
        ) {
          a.push(mycalendarProxy.applist[i]);
        }
      }
      setTask(a);
      checkfilapp(a);
    }

    if (tags.value !== "tags") {
    }
  };

  const checkfilapp = (al) => {
    if (filapplications.value !== "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (al[i]["status"] === filapplications.value) {
          a.push(al[i]);
        }
      }
      setTask(a);
      chktag(a);
    } else {
      chktag(al);
    }
  };

  const chktag = (al) => {
    if (tags.value !== "-1") {
      let a = [];
      for (let i = 0; i < al.length; i++) {
        if (
          al[i]["engage_tag"] &&
          al[i]["engage_tag"].toLowerCase().indexOf(tags.value) !== -1
        ) {
          a.push(al[i]);
        }
      }
      setTask(a);
    }
  };

  const getapp = async () => {
    const { data } = await getApplications(companyProxy.selectcompany.org_code);
    if (data.status === 1) {
      
      let t = []
      for (let i = 0; i < data.data.length; i++) {
        let a = data.data[i]["interview_startdatetime"]
        let b = data.data[i]["interview_enddatetime"]
        if (
          a &&
          a.length > 0 &&
          b &&
          b.length &&
          ["pending","shortlist","engage"].indexOf(data.data[i]['status'])===-1
        ){
          t.push(data.data[i])
        }
      }
      setTask(t);
      mycalendarProxy.applist = t;
      let l = [{ label: "Tags", value: "-1" }];
      let ll = [];
      for (let i = 0; i < t.length; i++) {
        if (
          t[i]["engage_tag"] &&
          t[i]["engage_tag"].length > 0
        ) {
          let a = t[i]["engage_tag"].split(",");
          for (let j = 0; j < a.length; j++) {
            if (ll.indexOf(a[j].toLowerCase().trim()) === -1) {
              l.push({ label: a[j].trim(), value: a[j].toLowerCase().trim() });
            }
          }
        }
      }
      mycalendarProxy.tags = l;
    }
  };

  useEffect(async () => {
    if (companyProxy.selectcompany) {
      getapp();
      const { data } = await getjobslist(companyProxy.selectcompany.org_code);
      if (data.status === 1) {
        mycalendarProxy.joblist = data.data;
      }
    }
  }, [companyProxy.selectcompany, mycalendarProxy.setcheck]);

    return (
        <MainLayout>
            <HeaderText>{t("My Calendar")}</HeaderText>
            <CompanySection>
        <InputBox>
          <Input
            color="#fff"
            placeholder={t("Search Keywords for Interview Title or Candidate Name")}
            value={search}
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          />
        </InputBox>
        <div className="hideSearchBars" style={{ marginLeft: "0px" }}>
          <Button
            onClick={chkser}
            style={{ cursor: "pointer" }}
            name={t("Search")}
          />
        </div>
      </CompanySection>
      {/* <SectionWrapper>
        <Dropdown
          className="drop"
          name="Engage"
          search={true}
          options={[
            { label: t("All"), value: "-1" },
            { label: t("Application"), value: "pending" },
            { label: t("Shortlist"), value: "shortlist" },
            { label: t("Engage"), value: "engage" },
            { label: t("Interview"), value: "interview" },
            { label: t("Offered"), value: "offered" },
            { label: t("To Hired"), value: "hire" },
            { label: t("Reject"), value: "reject" },
          ]}
          placeholder="Please Select some options"
          minWidth="auto"
          width="200px"
          isMulti={false}
          val={filapplications?.value}
          label={filapplications?.label}
          id="drop"
          change={(e) => {
            setFilapplications(e);

            chkser();
          }}
        />
        <Dropdown
          name="Engage"
          search={true}
          options={mycalendarProxy.tags}
          placeholder="Please Select some options"
          minWidth="auto"
          width="200px"
          isMulti={false}
          val={tags?.value}
          label={tags?.label}
          id="drop"
          change={(e) => {
            setTags(e);
            chkser();
          }}
        />
       
      </SectionWrapper> */}
            <MyCalendarView tasks={task}/>
        </MainLayout>
    )
}
