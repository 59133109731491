import { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import * as yup from "yup";
import { Col, Container, Row } from "react-bootstrap";
import PhoneInput from "react-phone-number-input";
// import { Colors } from "../../utilities/colors";
import Input from "../input/input";
import Button from "../../components/button/button";
import FormErrors from "../formErrors/formErrors.js"
import { SubHeading, SubHeadingText, Label, SelectorWrapper, StyledSimpleCardCustom } from "../button/commonStyles";
import { toast } from "react-toastify";
import { Grid } from "@material-ui/core";
import { updateCompanyData } from "../../pages/myCompany/reducer";
import { getCompanyData, onCompanyUserProfileUpdate } from "../../services/apiCalls";
// import { updateCompanyData } from "../../pages/myCompany/reducer";
// import ErrorField from "../errorField/errorField";
import Loader from "../loader/loader";
// import { updateOrgData } from "../../pages/login/reducer.js";
import { useTranslation } from "react-i18next";



const schema = yup.object().shape({
  mob_number: yup.string().required(),
  email: yup.string().email("Entered email id is invalid").required(),
  department: yup.string().required(),
  name: yup.string().required(),
});

const SettingsOrganisationDetail = ({ data, updateUserData, orgUser, currentUserEmail }) => {
  const [isEditable, setIsEditable] = useState(false)
  // const company = useSelector((state) => state.company.companyData);
  const comp_org = useSelector((state) => state.org.orgData);
  const currentUser = orgUser.filter(item => item.email === currentUserEmail);

  const { first_name, last_name, department, mob_number, email } = currentUser[0];
  const [defaultValues, ] = useState(
    {
      name: first_name + " " + last_name,
      department: department,
      mob_number: mob_number,
      email: email
    }
  )

  const [loading, setLoading] = useState(false);
  const {t,} = useTranslation();
  const {
    // register,
    formState: { errors },
    control,
    handleSubmit,
    // formState: { touchedFields },
    // setValue,
    getValues,
    reset,
  } = useForm({
    criteriaMode: "all",
    resolver: yupResolver(schema),
    defaultValues: defaultValues,
  });

  // Update user details
  const getUpdatedCompanyUserProfile = async ({ name, mob_number, department }) => {
    const { data, status } = await onCompanyUserProfileUpdate(
      {
        name: name,
        phone_number: mob_number,
        department: department
      }
    );

    if (status === 200) {
      if (data.status === 0) {
        toast.error(t(data.message));
      } else if (data.status === 1) {
        toast.success(t(data.message))
        // alert('hi');
        // to re - redux the company data
        reInitializeCompanyData();
      }
    }

  };

  const reInitializeCompanyData = async () => {
    const { data } = await getCompanyData({
      code: comp_org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      dispatch(updateCompanyData(data.data));
    }
  }

  // const formVal = watch();
  const dispatch = useDispatch();

  const onFormSubmit = (data) => {
    // e.preventDefault();
    setLoading(true);
    if (!(errors)) {
      return;
    } else {
      setIsEditable(false);
      getUpdatedCompanyUserProfile(data)
      // getUpdatedUserDetails(data)

    }
    setLoading(false);
  };

  const onCancel = () => {
    reset(defaultValues);
    setIsEditable(false);
    setLoading(false);
  };
  return (
    <>
      {currentUserEmail && currentUser && currentUser[0] && <form onSubmit={handleSubmit(onFormSubmit)}>
        <Row>
          <Col>
            <Container className="p-0 p-sm-4 container-br">
              <SubHeading>
                <SubHeadingText>{t('Profile Information')}</SubHeadingText>
                {!isEditable && (
                  <div
                    className="changeText"
                    onClick={() => setIsEditable(true)}
                  >
                    {t('Edit')}
                  </div>
                )}
              </SubHeading>
              <div>
                <Row>
                  <Col lg="6">
                    <div className="mb-2 mt-2">
                      <Label>{t('Name*')} </Label>
                      <SelectorWrapper>
                        {isEditable ? (
                          <>
                            <SelectorWrapper>
                              <Controller
                                control={control}
                                name="name"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    placeholder={t('Name')}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className={error ? "invalid" : ""}
                                  />
                                )}
                              />
                              <FormErrors
                                errors={t(errors)}
                                formControlName="name"
                              />
                            </SelectorWrapper>
                          </>
                        ) : (
                          <>
                            <StyledSimpleCardCustom className="inputReadOnly">
                              {getValues().name}
                            </StyledSimpleCardCustom>
                          </>
                        )}
                      </SelectorWrapper>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-4 mt-2">
                      <Label>{t('Email*')}</Label>
                      <SelectorWrapper>
                        <>
                          <StyledSimpleCardCustom className="inputReadOnly">
                            {getValues().email}
                          </StyledSimpleCardCustom>
                        </>
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col lg="6">
                    <div className="mb-4 mt-4">
                      <Label>{t('Mobile Number*')}</Label>
                      <SelectorWrapper>
                        {isEditable ? (
                          <>
                            <SelectorWrapper>
                              <Controller
                                name="mob_number"
                                control={control}
                                render={({ field: { onChange, onBlur, value, ref },
                                  fieldState: { error }, }) => (
                                  <PhoneInput
                                    value={value}
                                    defaultCountry="MY"
                                    withCountryCallingCode
                                    onChange={(val) => {
                                      let newVal = val;
                                      if(!val) {
                                        newVal = "";
                                      }
                                      onChange(newVal);
                                    }}
                                    placeholder={t('Area code + Number')}
                                    className={error ? "invalid" : ""}
                                    maxLength={16}
                                    minLength={12}
                                  />
                                )}
                              />
                              <FormErrors
                                errors={t(errors)}
                                formControlName="mob_number"
                              />
                            </SelectorWrapper>
                          </>
                        ) : (
                          <>
                            <StyledSimpleCardCustom className="inputReadOnly">
                              {getValues().mob_number}
                            </StyledSimpleCardCustom>
                          </>
                        )}
                      </SelectorWrapper>
                    </div>
                  </Col>
                  <Col lg="6">
                    <div className="mb-4 mt-4">
                      <Label>{t('Department*')} </Label>
                      <SelectorWrapper>
                        {isEditable ? (
                          <>
                            <SelectorWrapper>
                              <Controller
                                control={control}
                                name="department"
                                render={({
                                  field: { onChange, onBlur, value, ref },
                                  fieldState: { error },
                                }) => (
                                  <Input
                                    placeholder={t('Human Resource')}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    value={value}
                                    className={error ? "invalid" : ""}
                                  />
                                )}
                              />
                              <FormErrors
                                errors={errors}
                                formControlName="department"
                              />
                            </SelectorWrapper>
                          </>
                        ) : (
                          <>
                            <StyledSimpleCardCustom className="inputReadOnly">
                              {getValues().department}
                            </StyledSimpleCardCustom>
                          </>
                        )}
                      </SelectorWrapper>
                    </div>
                  </Col>
                </Row>
              </div>


              {isEditable && (
                <Grid
                  container
                  style={{
                    display: "flex",
                    alignItems: "end",
                    justifyContent: "end",
                  }}>
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button name={t('Save')} type="submit" light />
                  )}
                  <Button
                    secondary
                    color="#FFFFFF"
                    name={t('Cancel')}
                    style={{
                      marginLeft: "10px",
                    }}
                    type="button"
                    className="cancelBtn"
                    onClick={onCancel}
                  >

                  </Button>
                </Grid>
              )}
            </Container>
          </Col>
        </Row>
      </form>}
    </>
  );
}


export default SettingsOrganisationDetail