import styled from "styled-components";

export const ChartHeading = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  @media (min-width: 320px) and (max-width: 480px) {
    text-align: start;
  }
`;

export const NoDataText = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #000000;
  text-align: center;
`;

export const ChartDescription = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  color: #242424;
  padding-left: 40px;
  padding-right: 20px;
  @media (min-width: 320px) and (max-width: 480px) {
    width: 300px;
    text-align: left;
  }
`;

export const ChartWrapper = styled.div`
  padding: 25px 25px 25px 25px;
  text-align: center;
  @media (max-width: 500px) {
    padding: 10px;
  }
`;

export const ChartWrappers = styled.div`
  display: flex;
  padding: 25px 25px 25px 25px;
  text-align: center;
  @media (max-width: 500px) {
    padding: 10px;
    display: block;
  }
`;
export const ViewDetailsWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  a {
    text-decoration: none !important;
  }
`;

export const ViewDetails = styled.span`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  color: #504f8c;
`;

export const CardPadded39 = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 20px 20px 20px 20px;
  position: relative;
`;

export const StudentModalHeader = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  /* identical to box height */

  text-align: center;

  color: #000000;
`;

export const StudentModalChartWrapper = styled.div`
  padding: 0px 90px;
`;

export const MainTitle = styled.span`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
`;

export const StyledDarkerCard = styled.span`
  background: #f8f8f8;
  border-radius: 54px;
`;

export const StyledDarkerCardCustom = styled(StyledDarkerCard)`
  align-items: center;
  background-color: #f1f3f4;
  border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  max-height: none;
  min-height: 48px;
  padding-left: 17px;
  padding-right: 17px;
  position: relative;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #525252;
  color: #525252;
  margin: 0px 20px 20px 0px;

  svg {
    margin-right: 10px;
  }
`;
