import React, { useEffect, useState } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import RadioInput from "../radioInput/radioInput";
import Button from "../button/button";
import { schema } from "./staticContent";
import ErrorField from "../errorField/errorField";
import { Grid } from "@material-ui/core";
import {
  onAddNewLocation,
  getCompanyData,
  onUpdateLocation,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
} from "../../services/apiCalls";
import { updateLoader } from "../../pages/myCompany/reducer";
import { updateLocationData } from "./reducer";
import { initialObj } from "./staticContent";
import Loader from "../loader/loader";
import Dropdown from "../dropdown/dropdown";
import { useTranslation } from "react-i18next";

const ModalWrapper = styled.div`
  padding: 20px;
  min-width: 320px;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 900px) {
    padding: 0px;
    min-width: auto;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 320px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 400px) {
    overflow-x: auto;
  }
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 10px;
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const statuses = ["primary", "secondary"];

export default function AddNewLocation(props) {
  const onClose = props.onClose;
  const location = useSelector((state) => state.location.locationData);
  const isEdit = useSelector((state) => state.location.isEdit);
  const org = useSelector((state) => state.org.orgData);
  const companyOrgs = useSelector((state) => state.company.companyData);
  const company = useSelector((state) => state.company.companyData);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const editLoading = useSelector((state) => state.company.loading);

  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState([]);

  const [stateList, setStateList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState([]);

  const [cityList, setCityList] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState([]);

  const [stateDisabled, setStateDisabled] = useState(true);
  const [cityDisabled, setCityDisabled] = useState(true);
  const { t } = useTranslation();

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...location },
    resolver: yupResolver(schema),
  });

  const formVal = watch();
  const dispatch = useDispatch();
  /* eslint-disable no-unused-vars */
  const onSubmit = async () => {
    if (isEdit) {
      if (company.location && company.location.length) {
        company.locations.forEach(async (element) => {
          if (formVal.id !== element.id) {
            const result = await onUpdateLocation({
              id: element.id,
              data: { status: "primary" },
              code: companyOrgs[props.companyIdx].org_code,
            });
          } else {
            const result = await onUpdateLocation({
              id: element.id,
              data: { status: "secondary" },
              code: companyOrgs[props.companyIdx].org_code,
            });
          }
        });
      }
    }
    setLoading(true);

    const { data } = isEdit
      ? await onUpdateLocation({
        id: formVal.id,
        data: { ...formVal },
        code: companyOrgs[props.companyIdx].org_code,
      })
      : await onAddNewLocation({
        code: companyOrgs[props.companyIdx].org_code,
        data: { ...formVal },
      });
    if (data && data.status === 1) {
      toast.success(t(`Details updated successfully`), {
        position: toast.POSITION.TOP_RIGHT,
      });
      getData();
      dispatch(updateLoader(!editLoading))
      props.onClose();
      dispatch(updateLocationData(initialObj));
      setFormErrors("");
    } else {
      toast.error(`${data.message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setFormErrors(data.message);
    }
  };
  /* eslint-disable no-unused-vars */

  const fetchCounty = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const countryList = data.data;
        const country = countryList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCountryList(country);
        if (formVal.country) {
          let selCountry = country.filter(
            (x) => x.value === formVal.country
          );
          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("country", selCountry.value);
          }
          setSelectedCountry(selCountry);
          setStateDisabled(false);
        }
      }
    }
  };

  const fetchState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const StateList = data.data;
        const state = StateList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setStateList(state);
        if (formVal.state) {
          let selState = state.filter(
            (x) => x.value === formVal.state
          );
          if (selState.length > 0) {
            selState = selState[0];
            setValue("state", selState.value);
          }
          setSelectedState(selState);
          setStateDisabled(false);
        }
      }
    }
  };
  const fetchSavedState = () => {
    if (formVal.country) {
      fetchState(formVal.country)
    }
  }


  const fetchCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const CityList = data.data;
        const city = CityList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCityList(city);
        if (formVal.city) {
          let selCity = city.filter((x) => x.value === formVal.city);
          if (selCity.length > 0) {
            selCity = selCity[0];
            setValue("city", selCity.value);
            setCityDisabled(false);
          }
          setSelectedCity(selCity);
        }
      }
    }
  };
  const fetchSavedCity = () => {
    if (formVal.country || formVal.state) {
      fetchCity(formVal.country, formVal.state)
    }
  }

  useEffect(() => {
    getData();
    fetchCounty();
    fetchSavedState();
    fetchSavedCity();
  }, []);

  const getData = async () => {
    const { data } = await getCompanyData({
      code: companyOrgs[props.companyIdx].org_code,
    });
    if (data && data.status === 1) {
      setLoading(false);

      if (!formVal.status) {
        setValue("status", "secondary");
      }
    } else {
      setLoading(false);
    }
  };

  const radioChange = (value, name) => {
    setValue(name, value);
  };

  const dropdownChange = (name, val, index) => {
    setValue(name, val.value);
    switch (name) {
      case "country":
        setSelectedCountry(val);
        setValue('state', '')
        setValue('city', '')
        fetchState(val.value)
        setCityList([]);
        setSelectedCity([]);
        setStateDisabled(false)
        setCityDisabled(true)
        break;
      case "state":
        setSelectedState(val);
        setValue('city', '')
        fetchCity(formVal.country, val.value)
        setCityDisabled(false)
        break;
      case "city":
        setSelectedCity(val);
        break;
      default:
        break;
    }
  }

  const [is_disabled, setIsDisabled] = useState(false);
  useEffect(() => {
    if (formVal.status === "primary") {
      setIsDisabled(true);
    }
  }, [formVal.status])

  useEffect(() => {
    if (formVal.country) setSelectedCountry(countryList.find((e) => e.value === +formVal.country))
    const findState = stateList.find((e) => e.value === +formVal.state)
    if (formVal.state) setSelectedState(findState)
    if (formVal.state) {
      if (findState) {
        setCityDisabled(false)
      }
      setSelectedCity(cityList.find((e) => e.value === +formVal.city))
    }
  })
  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3>{t(formVal.id ? t("Edit Location") : t("Add new location"))}</h3>
            <hr />
            <FormWrapper>
              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid item lg={8}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('Address*')}</FormLabel>
                    <Controller
                      name="rgtr_address"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          value={formVal.rgtr_address}
                          placeholder={t('Address')}
                        />
                      )}
                    />
                    {errors.rgtr_address && (
                      <ErrorField err={t(errors.rgtr_address.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('Country*')}</FormLabel>
                    <Dropdown
                      name="country"
                      control={control}
                      search={true}
                      options={countryList}
                      placeholder={t('Enter Country')}
                      minWidth="auto"
                      val={selectedCountry?.value}
                      label={selectedCountry?.label}
                      change={(val) => dropdownChange("country", val)}
                    />
                    {errors.country && (
                      <ErrorField err={t(errors.country.message)} />)}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('State*')}</FormLabel>
                    <Dropdown
                      name="state"
                      control={control}
                      options={stateList}
                      placeholder={t('Enter Location')}
                      disable={stateDisabled}
                      minWidth="auto"
                      val={selectedState?.value}
                      label={selectedState?.label}
                      change={(val) => dropdownChange("state", val)}
                    />
                    {errors.state && <ErrorField err={t(errors.state.message)} />}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('City*')}</FormLabel>
                    <Dropdown
                      name="city"
                      control={control}
                      options={cityList}
                      placeholder={t('Enter Location')}
                      disable={cityDisabled}
                      minWidth="auto"
                      val={selectedCity?.value}
                      label={selectedCity?.label}
                      change={(val) => dropdownChange("city", val)}
                    />
                    {errors.city && <ErrorField err={t(errors.city.message)} />}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('Postcode*')}</FormLabel>
                    <Controller
                      name="country_code"
                      control={control}

                      render={({ field }) => (
                        <Input
                          {...field}
                          onKeyPress={(event) => {
                            if (!/^[0-9]*$/.test(event.key)) {
                              event.preventDefault();
                            }
                            if (event.target.value.length > 9) {
                              event.preventDefault();
                            }
                          }}
                          placeholder={t('Postcode')}
                          maxLength="10"
                          
                        />
                      )}
                    />
                    {errors.country_code &&
                      <ErrorField err={t(errors.country_code.message)} />
                    }
                  </SingleField>
                </Grid>
              </Grid>
              <Grid item lg={4} className="statusButton">
                <SingleField style={{ margin: "1%" }}>
                  <FormLabel>Status</FormLabel>
                  {is_disabled === true ?
                    t("Primary") :
                    <StatusWrapper>
                      <Controller
                        name="status"
                        control={control}
                        render={({ field }) =>
                          statuses.map((val, index) => {
                            if (
                              val === "secondary" &&
                              props.isPrimaryLocation &&
                              formVal.status !== "primary"
                            ) {
                              return (
                                <RadioInput
                                  key={val + index}
                                  {...field}
                                  checked={props.isPrimaryLocation}
                                  onChange={() => radioChange(val, "status")}
                                  label={t(val)}
                                  disabled={is_disabled}
                                />
                              );
                            } else {
                              return (
                                <RadioInput
                                  key={val + index}
                                  {...field}
                                  checked={val === formVal.status}
                                  onChange={() => radioChange(val, "status")}
                                  label={t(val)}
                                  disabled={is_disabled}
                                />
                              );
                            }
                          })
                        }
                      />
                    </StatusWrapper>
                  }
                  {errors.status && <ErrorField err={t(errors.status.message)} />}
                </SingleField>
              </Grid>

              <Grid
                container
                className="button-company"
              >
                <Grid>
                  {formErrors && <ErrorField err={t(formErrors)} />}
                  {loading ? (
                    <Loader />
                  ) : (
                    <Button name={t('Confirm')} onClick={handleSubmit(onSubmit)} />
                  )}
                </Grid>
                <Grid>
                  <Button
                    secondary
                    color="#FFFFFF"
                    name={t('Cancel')}
                    style={{
                      marginLeft: "10px",
                    }}
                    variant="outlined"
                    onClick={onClose}
                  >

                  </Button>
                </Grid>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}