import * as yup from "yup";
import { store } from "../../store";

import { isUrlValid } from "../../utilities/commonFunctions";
import { registerOrganisationErrorMessages } from "../../utilities/commonMessages";
import { onValidateDuplicateValues } from "../../services/apiCalls";

const errors = { ...registerOrganisationErrorMessages };

const validate = async (name, val) => {
  if (store.getState().organisation.isUpdate) {
    return true;
  }
  // if (name === "ssm_number" && val.length !== 12) {
  //   return false;
  // }
  const code = name === "name" ? "NAME" : "SSM";
  const { data } = await onValidateDuplicateValues({
    data: { data: val },
    code: code,
  });
  if (data && data.status === 1) {
    return true;
  } else {
    return false;
  }
};

export const schema = yup.object().shape({
  name: yup
    .string()
    .required(errors.orgName)
    .test(
      "code",
      "Organisation name already exist. Please contact mynext administrator or customer service for more information",
      (val) => validate("name", val)
    ),
  ssm_number: yup.string().required(errors.ssmNumber)
  .test("code", "SSM Number already exist. Please contact mynext administrator or customer service for more information", (val) =>
  validate("ssm_number", val)
  ),
  ssm_rgtr_date: yup.string().required(errors.ssmDate),
  website: yup
    .string()
    .test("website", "The website entered is incorrect.", (val) => {
      if (val.length === 0) {
        return true;
      } else {
        return isUrlValid(val);
      }
    }),
  rgtr_address: yup.string().required(errors.address),
  country: yup.string().required(errors.country),
  state: yup.string().required(errors.state),
  city: yup.string().required(errors.city),
  bsn_address: yup.string().required("Address is required."),
  bsn_country: yup.string().required("Country is required."),
  bsn_city: yup.string().required("City is required."),
  bsn_state: yup.string().required("State is required."),
  bsn_country_code: yup.string().required("Post code is required."),
  type_of_org: yup.string().required(errors.orgType),
  sector: yup.string().required(errors.sector),
  bsn_nature: yup
    .string()
    .required(errors.businessNature)
    .test(
      "code",
      "Ensure nature of business is not more than 255 characters",
      (val) => val.toString().length < 255
    ),
  country_code: yup
    .string()
    .required(errors.postalCode)
    .test(
      "code",
      "Must be exactly 8 characters",
      (val) => val.toString().length <= 8
    ),
});
