import React, { useEffect } from 'react'
import MainLayout from '../../layouts/mainLayout/mainLayout'
import styled from 'styled-components';
import { Ad2Card } from '../../components/analyticalDashboardPage/adCard';
import { useTranslation } from "react-i18next";
import AdGenderRatio from '../../components/analyticalDashboardPage/adGenderRatio/adGenderRatio';
import { Ad2StudentPopulation } from '../../components/analyticalDashboardPage/adPopulation/adPopulation';
import { Utils } from '../../utilities/utils';
import { getGenderRatio, getGenderPoputation, getQuizzesCompletion, getPersonalitySummary, getPersonalityProfileUser } from '../../services/apiCalls';
import { Ad2EnglishProficiency } from '../../components/analyticalDashboardPage/adEnglishProficiency/adEnglishProficiency';
import { Ad2FutureOfWork } from '../../components/analyticalDashboardPage/adFutureOfWork/adFutureOfWork';
import Ad2EmployabilityFactors from '../../components/analyticalDashboardPage/adEmployability/adEmployability';
import { Ad2StudentWorkValues } from '../../components/analyticalDashboardPage/adWorkValues/addWorkValues';
import { Ad2StudentPersonalityProfile } from '../../components/analyticalDashboardPage/adPersonality/adPersonality';
import { Ad2StudentWorkInterest } from '../../components/analyticalDashboardPage/adWorkInterest/adWorkInterest';
import { format, getYear, isValid, parse, startOfYear } from "date-fns";
import { Ad2CompletionRate } from '../../components/analyticalDashboardPage/adCompletionRate/adCompletionRate';
import { Ad2Internships } from '../../components/analyticalDashboardPage/adInternships/adIntership';
import { Ad2InternshipIndustrySector } from '../../components/analyticalDashboardPage/adInternships/adInternshipIndustry';
import { Ad2CompletionRateEnhanced } from '../../components/analyticalPersonalityDoughnut.js/analyticalPersonalityDoughnut';
import AnalyticalEnhancedGender from '../../components/analyticalDashboardPage/analyticalEnhancedGender/analyticalEnhancedGender';
import { Col, FormLabel, Row } from 'react-bootstrap';
import Dropdown from '../../components/dropdown/dropdown';
import departmentList from '../employeeManagement/staticDepartmentList';
import { CardPadded39 } from '../employeeManagement/commonStyles';
const HeaderText = styled.div`
  margin-top: -8px;
  //margin-bottom: 1%;
  padding-bottom: 20px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  @media (max-width:500px){
    margin:20px 0 20px 5px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const CardWrapperThreeSection = styled.div`
  display:grid;
  grid-template-columns:0fr 1fr 1fr;
  grid-gap:12px;
`;

const CardWrapperTwoSection = styled.div`
  display:grid;
  grid-template-columns:1fr 1fr;
  grid-gap:12px;
`;

const ChartsWrapper = styled.div`
  display:flex;
  flex-direction:column;
  gap:12px;
`;
const GroupWrapper = styled.div`
  display: flex;
  gap: 20px;
  label {
    position: relative;
    top: 5px;
  }
  .container div {
    position: relative;
    width: 500px;
  }

  @media (max-width: 500px) {
    display: block;
  }
`;
export default function AnalyticalDashboardEnhanced() {
    const { t, i18n } = useTranslation();
    const todayDate = new Date();
    let chartsLoadingController = new AbortController();
    const studentPop = {
        "status": {
            "registered": 18,
            "unregistered": 88
        },
        "total": 106
    }
    const genderRatio = {
        "data": {
            "status": {
                "registered": {
                    "male": 2,
                    "female": 16
                },
                "unregistered": {
                    "male": 58,
                    "female": 30
                }
            },
            "total": {
                "male": 60,
                "female": 46
            }
        },
        "status": 1
    }
    const [chartState, setChartState] = React.useState({
        studentPopulation: { data: null, status: "loading" },
        genderRatioData: { data: null, status: "loading" },
        personlityProfileUser: {data: null, status:"loading"},
        internships: { data: null, status: "loading" },
        internshipIndustrySector: { data: null, status: "loading" },
        completionRate: { data: null, status: "loading" },
        studentWorkInterest: { data: null, status: "loading" },
        studentsWorkValues: { data: null, status: "loading" },
        employabilityFactors: { data: null, status: "loading" },
        futureOfWork: { data: null, status: "loading" },
        englishProficiency: { data: null, status: "loading" },
    });

    const [searchState, setSearchState] = React.useState({
        internshipStartDate: format(startOfYear(todayDate), "dd-MM-yyyy"),
        internshipEndDate: format(todayDate, "dd-MM-yyyy"),

        // internshipIndustrySectorFilter: yearNow,
        // studentWorkInterestFilter: "rank1",
        // selectedCampus: searchParams.get("selectedCampus"),
        // selectedFaculty: searchParams.get("selectedFaculty"),
        // selectedStudyProgram: searchParams.get("selectedStudyProgram"),
        // selectedYearOfStudy: searchParams.get("selectedYearOfStudy"),
        // selectedUniversity: [],
        // selectedScopeOfStudy: searchParams.get("selectedScopeOfStudy"),
        studentPersonalityProfile: [],
    });
   const [updatedFilter,updateFilter] = React.useState()
   const [dropUpdatedFilter,dropUpdateFilter] = React.useState()
   const [totalEmployees,updateTotalEmployees] = React.useState()
    const setChartStateWrapper = (obj) => {
        setChartState((prevState) => ({ ...prevState, ...obj }));
    };
    const setSearchStateWrapper = (obj, callback) => {
        setSearchState((prevState) => ({ ...prevState, ...obj }));
    };

    const parseDateToString = (dateVal) => {
        const todayDate = new Date();
        let output = format(todayDate, "dd-MM-yyyy");
        const newOutput = format(dateVal, "dd-MM-yyyy");
        if (newOutput !== "Invalid Date" && dateVal) {
            output = newOutput;
        }
        return output;
    };

    const parseStringToDate = (dateVal) => {
        const todayDate = new Date();
        let output = todayDate;
        if (typeof dateVal === "string") {
            output = parse(dateVal, "dd-MM-yyyy", todayDate);
        }
        if (isValid(dateVal) && dateVal) {
            output = dateVal;
        }
        return output;
    };

    // const getParams = () => {
    //     return {
    //         campus:
    //             searchState &&
    //                 searchState.selectedCampus &&
    //                 Array.isArray(searchState.selectedCampus)
    //                 ? searchState.selectedCampus.join(",")
    //                 : searchState.selectedCampus,
    //         faculty:
    //             searchState &&
    //                 searchState.selectedFaculty &&
    //                 Array.isArray(searchState.selectedFaculty)
    //                 ? searchState.selectedFaculty.join(",")
    //                 : searchState.selectedFaculty,
    //         study_program:
    //             searchState &&
    //                 searchState.selectedStudyProgram &&
    //                 Array.isArray(searchState.selectedStudyProgram)
    //                 ? searchState.selectedStudyProgram.join(",")
    //                 : searchState.selectedStudyProgram,
    //         year_of_study:
    //             searchState &&
    //                 searchState.selectedYearOfStudy &&
    //                 Array.isArray(searchState.selectedYearOfStudy)
    //                 ? searchState.selectedYearOfStudy.join(",")
    //                 : searchState.selectedYearOfStudy,
    //         insti_name:
    //             searchState &&
    //                 searchState.selectedUniversity &&
    //                 Array.isArray(searchState.selectedUniversity)
    //                 ? searchState.selectedUniversity.join(",")
    //                 : searchState.selectedUniversity,
    //         scope_of_study:
    //             searchState &&
    //                 searchState.selectedScopeOfStudy &&
    //                 Array.isArray(searchState.selectedScopeOfStudy)
    //                 ? searchState.selectedScopeOfStudy.join(",")
    //                 : searchState.selectedScopeOfStudy,

    //     };
    // };
    const fetchGenderRatio = async () => {
        const labelMap = {
            registered: "Registered",
            unregistered: "Not Registered",
        };
        setChartStateWrapper({
            genderRatioData: { data: null, status: "loading" },
        });
        
        const filterParams = {};
        // filterParams.insti_name = 141;
        if(updatedFilter){
            filterParams['department'] = updatedFilter.value;
        }
        console.log("filterParma",filterParams,updatedFilter)
        const { data, status } = await getGenderRatio({
            filters: filterParams,
            chartsLoadingController: chartsLoadingController,
        });
        let totalResponse = data && data.data && data.data.total;
        // let totalResponse = [60, 46]
        const totalSum = totalResponse
            ? Utils.getObjectValuesSum(totalResponse)
            : 0;
            console.log("totalResponse",totalResponse,totalSum)

        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data?.data,
            totalSum,
            status,
            "genderRatioData"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let response = data && data?.data.status;
            const genderRatioData = Object.keys(response).map((key) => {
                return {
                    type: labelMap[key],
                    female: response[key].female,
                    male: response[key].male,
                };
            });
            genderRatioData.sort();
            setChartStateWrapper({
                genderRatioData: { data: genderRatioData, status },
            });
        }
    };

    // const setFilter = (keyNew, func, update) => {
    //     if (keyNew === "internshipFilter") {
    //         setSearchStateWrapper({
    //             internshipStartDate: parseDateToString(
    //                 update && update[0] && update[0].startDate
    //             ),
    //             internshipEndDate: parseDateToString(
    //                 update && update[0] && update[0].endDate
    //             ),
    //         });
    //     } else {
    //         setSearchStateWrapper({ [keyNew]: update });
    //     }
    // };

    const fetchStudentPoputation = async () => {
        setChartStateWrapper({
            studentPopulation: { data: null, status: "loading" },
        });
        const filterParams = {};
        if(updatedFilter){
            filterParams['department'] = updatedFilter.value;
        }
        console.log("filterParma",filterParams,updatedFilter)
        const { data, status } = await getGenderPoputation({
            filters: filterParams,
            chartsLoadingController: chartsLoadingController,
        });
        console.log("sttaeStudent",data?.data)

        const total = data && data?.data && data?.data?.total;
        console.log("mapValues",total)
        updateTotalEmployees(total);
    
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data?.data,
            total,
            status,
            "studentPopulation"
        );
        console.log("sssssSSS",stateObj,proceed);
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            const dataObj = data && data?.data?.status;
            setChartStateWrapper({ studentPopulation: { data: [dataObj], status } });
        }
        console.log("chartPopulation",chartState.studentPopulation)

    };

    const fetchPersonalityProfileUser = async () => {
        setChartStateWrapper({
            studentPopulation: { data: null, status: "loading" },
        });
        const filterParams = {};
        if(updatedFilter){
            filterParams['department'] = updatedFilter.value;
        }
        console.log("filterParma",filterParams,updatedFilter)
        const { data, status } = await getPersonalityProfileUser({
            filters: filterParams,
            chartsLoadingController: chartsLoadingController,
        });
        console.log("sttaeStudentPesonal",data?.data)

        const total = 5;
        // console.log("mapValues",total)
        // updateTotalEmployees(total);
    
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data?.data,
            total,
            status,
            "studentPopulation"
        );
        console.log("sssssSSSPersonl",stateObj,proceed);
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            const dataObj = data && data?.data?.results;
            console.log("proceddData",dataObj)
            setChartStateWrapper({ personlityProfileUser: { data: dataObj, status } });
        }
        console.log("chartPopulation",chartState.personlityProfileUser)

    };

    const fetchEnglishProficiency = async () => {
        const labelMap = {
            'Grammar': t('Grammar'),
            'Comprehension': t('Comprehension'),
            'General': t('Total English Proficiency Level')
        }
        setChartStateWrapper({ englishProficiency: { data: null, status: 'loading' } });
        // const filterParams = getParams();
        // filterParams.insti_name = 141;
        // const { data, status } = await getEnglishProficiency({
        //     filters: filterParams,
        //     chartsLoadingController: chartsLoadingController,
        // });

        const data = {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Grammar": {
                    "id": 1,
                    "results": {
                        "level1": 279,
                        "level2": 238,
                        "level3": 156,
                        "level4": 14,
                        "level5": 0
                    }
                },
                "totalTalents": [694],
                "Comprehension": {
                    "id": 2,
                    "results": {
                        "level1": 319,
                        "level2": 204,
                        "level3": 149,
                        "level4": 15,
                        "level5": 0
                    }
                },
                "General": {
                    "id": 15,
                    "results": {
                        "level1": 318,
                        "level2": 271,
                        "level3": 97,
                        "level4": 1,
                        "level5": 0
                    }
                }
            }
        }
        const status = 200;
        let total = data.results.totalTalents;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "englishProficiency"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data.results;
            const gridData = [];
            const chartDataMap = {};
            Object.keys(results)
                .filter((x) => x !== "totalTalents")
                .forEach((key) => {
                    const level1 = Utils.falsyNumberCaseHandle(
                        results[key] &&
                        results[key].results &&
                        results[key].results.level1
                    ),
                        level2 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level2
                        ),
                        level3 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level3
                        ),
                        level4 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level4
                        ),
                        level5 = Utils.falsyNumberCaseHandle(
                            results[key] &&
                            results[key].results &&
                            results[key].results.level5
                        );
                    gridData.push({
                        type: labelMap[key],
                        level1,
                        level2,
                        level3,
                        level4,
                        level5,
                    });
                    chartDataMap[key] = {
                        level1,
                        level2,
                        level3,
                        level4,
                        level5,
                    };
                });
            setChartStateWrapper({
                englishProficiency: {
                    gridData,
                    data: chartDataMap,
                    status,
                    total,
                },
            });
        }
    };

  

   

   
    const fetchStudentPersonlityProfile = async () => {
        setChartStateWrapper({
            studentPersonalityProfile: { data: null, status: "loading" },
        });
        const filterParams = {};
        // filterParams.insti_name = 141;
        if(updatedFilter){
            filterParams['department'] = updatedFilter.value;
        }
        console.log("filterParma",filterParams,updatedFilter)        // filterParams.insti_name = universityID;
        const { data, status } = await getPersonalitySummary({
          filters: filterParams,
          chartsLoadingController: chartsLoadingController,
        });
        // const data = {
        //     "facet": [],
        //     "request": {
        //         "insti_name": "141",
        //         "university": "141"
        //     },
        //     "results": {
        //         "emotional_stability": {
        //             "average": 3.59
        //         },
        //         "extraversion": {
        //             "average": 3.62
        //         },
        //         "openness_to_experience": {
        //             "average": 3.6
        //         },
        //         "agreeableness": {
        //             "average": 3.61
        //         },
        //         "conscientiousness": {
        //             "average": 3.57
        //         },
        //         "totalTalents": 694
        //     }
        // }
        // const status = 200;
        let total = data?.data?.results.totalTalents;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data,
            total,
            status,
            "studentPersonalityProfile"
        );
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let results = data?.data.results;
            setChartStateWrapper({
                studentPersonalityProfile: {
                    data: [
                        {
                            agreeableness: Utils.falsyNumberCaseHandle(
                                results.agreeableness.average
                            ),
                            openess: Utils.falsyNumberCaseHandle(
                                results.openness_to_experience.average
                            ),
                            conscientiousness: Utils.falsyNumberCaseHandle(
                                results.conscientiousness.average
                            ),
                            extraversion: Utils.falsyNumberCaseHandle(
                                results.extraversion.average
                            ),
                            neuoroicism: Utils.falsyNumberCaseHandle(
                                results.emotional_stability.average
                            ),
                        },
                    ],
                    status,
                    total,
                },
            });
        }
    };

 

    const fetchCompletionRate = async () => {
        const filterParams = {};
        // filterParams.insti_name = 141;
        if(updatedFilter){
            filterParams['department'] = updatedFilter.value;
        }
        console.log("filterParma",filterParams,updatedFilter)
        // filterParams.insti_name = universityID;

        setChartStateWrapper({ completionRate: { data: null, status: "loading" } });
        const { data, status } = await getQuizzesCompletion({
          filters: filterParams,
          chartsLoadingController: chartsLoadingController,
        });
    
        console.log("tootlFender",data?.data?.results?.totalUsers)
        let totalUsers =
            data?.data?.results?.totalUsers;
        const { proceed, stateObj } = Utils.commonStateUpdateLogic(
            data?.data,
            totalUsers,
            status,
            "completionRate"
        );
        console.log("obejctsss",proceed,stateObj);
        if (stateObj) {
            setChartStateWrapper(stateObj);
        }
        if (proceed) {
            let quizzes =
                data?.data?.results?.quizzes;
            const quizCompletion = {
                totalUsers: Utils.falsyNumberCaseHandle(totalUsers),
            };
            quizzes.forEach((item) => {
                quizCompletion[item.name] = Utils.falsyNumberCaseHandle(item.completed);
            });
            quizCompletion['totalUsers'] = totalUsers;
            console.log("quizCompletion",quizCompletion)
            setChartStateWrapper({
                completionRate: { data: quizCompletion, status },
            });
        }
    };
    var anayticalDashboardDeapartmentList = departmentList;


//   const updateAllDeaprtment = () => {
//   var department = anayticalDashboardDeapartmentList;
//   department.unshift({value:"All Department",label:"All Department"})
//   anayticalDashboardDeapartmentList = department;
//   }
    // anayticalDashboardDeapartmentList.push({value:"All Deaprtment",label:"All"})
    useEffect(() => {
        fetchGenderRatio()
        fetchStudentPoputation()
        // fetchEnglishProficiency()
        // fetchFutureofWork()
        // fetchEmployabilityFactors()
        // fetchWorkValues()
        fetchStudentPersonlityProfile()
        fetchPersonalityProfileUser()
        // fetchStudentWorkInterest()
        fetchCompletionRate()
        // fetchInternships()
    }, [dropUpdatedFilter])

    // useEffect(()=>{
    //     updateAllDeaprtment();

    // },[])

    return (
        <>
 {/* <HeaderText>Analytical Dashboard</HeaderText>
  */}
 <CardPadded39 style={{ margin: "20px 0 40px 0" }}>

            <Row>
              <Col lg={12}>
                <GroupWrapper>
                  <FormLabel>{t("Department")} </FormLabel>
                  <div  className="dropdownabc">
                  <Dropdown
                      val={dropUpdatedFilter?.value}
                      label={dropUpdatedFilter?.label}
                      name="country"
                    //   control={control}
                    //   search={true}
                      change={(val)=>{
                        if(val.label == "All Department"){
                            updateFilter();
dropUpdateFilter(val);
                        }else{
                            updateFilter(val);
                            dropUpdateFilter(val);
                        }
                      }}
                      options={anayticalDashboardDeapartmentList}
                      placeholder={t("Select Department")}
                      width="500px"
                    //   isMulti
                    />
                  </div>

                  {/* {errors.country && (
                    <ErrorField err={errors.country.message} />
                  )} */}
                </GroupWrapper>
              </Col>
            </Row>

        </CardPadded39>
            <ChartsWrapper>
        
                <CardWrapperTwoSection>
                    <Ad2StudentPopulation filterParams={updatedFilter} data={chartState.studentPopulation} />
                    <Ad2CompletionRateEnhanced totalEmployees={totalEmployees } filterParams={updatedFilter} data={chartState.completionRate} />

                </CardWrapperTwoSection>
                {/* <CardWrapperTwoSection>
                    <Ad2Internships
                        data={chartState.internships}
                        internshipFilter={[
                            {
                                startDate: parseStringToDate(searchState.internshipStartDate),
                                endDate: parseStringToDate(searchState.internshipEndDate),
                                key: "selection",
                            },
                        ]}
                        setInternshipFilter={setFilter.bind(
                            this,
                            "internshipFilter",
                            fetchInternships
                        )}
                    />
                    <Ad2InternshipIndustrySector
                        data={chartState.internshipIndustrySector}
                        internshipIndustrySectorFilter={
                            searchState.internshipIndustrySectorFilter
                        }
                        setInternshipIndustrySectorFilter={setFilter.bind(
                            this,
                            "internshipIndustrySectorFilter",
                            fetchInternshipIndustrySector
                        )}
                    />
                </CardWrapperTwoSection> */}
                <CardWrapperTwoSection>
                <AnalyticalEnhancedGender data={chartState.genderRatioData}></AnalyticalEnhancedGender>
                    <Ad2StudentPersonalityProfile personlityProfileUser={chartState.personlityProfileUser} personalityProfile={chartState.completionRate} totalEmployees={totalEmployees } data={chartState.studentPersonalityProfile} />
                    {/* <Ad2StudentWorkInterest
                        data={chartState.studentWorkInterest}
                        studentWorkInterestFilter={searchState.studentWorkInterestFilter}
                        setStudentWorkInterestFilterFilter={setFilter.bind(
                            this,
                            "studentWorkInterestFilter",
                            fetchStudentWorkInterest
                        )}
                    /> */}
                </CardWrapperTwoSection>
                {/* <Ad2StudentWorkValues data={chartState.studentsWorkValues} />
                <Ad2EmployabilityFactors data={chartState.employabilityFactors} />
                <Ad2FutureOfWork data={chartState.futureOfWork} />
                <Ad2EnglishProficiency data={chartState.englishProficiency}></Ad2EnglishProficiency> */}
            </ChartsWrapper>
        </>
        // <MainLayout>
           
        // </MainLayout>
    )
}
