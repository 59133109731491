import { proxy } from 'valtio'
let trackProxy = proxy({
    joblist:[],
    applist:[],
    tags:[],
    setcheck:0,
    finallist:[]
})
export {
    trackProxy
}
