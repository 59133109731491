import moment from "moment";

// required fields starts
export const validateInputs = (obj) => {
  const values = Object.values(obj);
  const isValid = values.every((val) => val?.toString()?.length > 0);
  return isValid;
};
// required fields ends

// email validation starts
export const validEmailRegex = RegExp(
  /^(([^<>()\],;:\s@]+(\.[^<>()\],;:\s@]+)*)|(.+))@(([^<>()[\],;:\s@]+)+[^<>()[\],;:\s@]{2,})$/i
);
// email validation ends

export const validPassword = RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/
);

export const checkBothPasswords = (pwdOne, pwdTwo) => {
  let errVal = true;
  if (pwdTwo.length > 0) {
    errVal = pwdOne === pwdTwo ? true : false;
  }
  return errVal;
};

export const deleteObjKeys = (obj, keyArray) => {
  for (let i = 0; i < keyArray.length; i++) {
    delete obj[keyArray[i]];
  }
  return obj;
};

export function isEmpty(obj) {
  for (var prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }

  return JSON.stringify(obj) === JSON.stringify({});
}

export const isUrlValid = (userInput) => {
  var res = userInput.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_.~#?&//=]*)/g
  );
  if (res == null) return false;
  else return true;
};

export const allowOnlyNumbers = RegExp("^[0-9]+$");

export const formatDate = (date) => {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("-");
};

export const debounce = (fn, delay) => {
  let timer;
  return function (...args) {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(() => {
      timer = null;
      fn(...args);
    }, delay);
  };
};

export const dateToDateMonthInWordsYear = (date) => {
  return moment(date).format("DD MMMM YYYY");
};

export const isThisDatePastToday = (date) => {
  return moment(date).diff(moment(new Date(), "days")) > 0 ? false : true;
};

export const filePathToFileName = (filePath) => {
  return filePath?.split("/").reverse()[0];
};

export const calculateAge = (birthDate) => {
  birthDate = new Date(birthDate);
  let today = new Date();

  var years = today.getFullYear() - birthDate.getFullYear();

  if (
    today.getMonth() < birthDate.getMonth() ||
    (today.getMonth() === birthDate.getMonth() &&
      today.getDate() < birthDate.getDate())
  ) {
    years--;
  }

  return years;
};

export const formLocation = (city, state, country) => {
  return [city, state, country].filter(Boolean).join(", ");
};

export const getToday = () => {
  return moment().format("YYYY-MM-DD");
};

export const countPlaces = (num) => {
  var sep = String(23.32).match(/\D/)[0];
  var b = String(num).split(sep);
  return b[1] ? b[1].length : 0;
};

export const downloadPdf = (file) => {
  fetch(file, {
    method: "GET",
    headers: { "Content-Type": "application/json" },
  }).then((response) =>
    response.blob().then((blob) => {
      let filename = file;
      filename = filename.split("/");
      filename = filename[filename.length - 1];
      let url = window.URL.createObjectURL(blob);
      let a = document.createElement("a");
      a.href = url;
      a.download = filename;
      a.click();
    })
  );
};

export const customLabelsPlugin = {
  id: "customLabels",
  afterDraw: (chart, args, options) => {
    const ctx = chart.ctx;
    ctx.save();
    const {
      plugins: {
        customLabels: { labelColor: customLabelColor, arrowColor, font },
      },
    } = chart.config.options;
    ctx.font = font ? font : "14px 'Averta Std CY'";
    const leftLabelCoordinates = [];
    const rightLabelCoordinates = [];
    const chartCenterPoint = {
      x:
        (chart.chartArea.right - chart.chartArea.left) / 2 +
        chart.chartArea.left,
      y:
        (chart.chartArea.bottom - chart.chartArea.top) / 2 +
        chart.chartArea.top,
    };
    chart.config.data.labels.forEach((label, i) => {
      const arc = chart.getDatasetMeta(0).data[i];
      const dataset = chart.config.data.datasets[0];

      // Prepare data to draw
      // important point 1
      const centerPoint = arc.getCenterPoint();
      const model = arc.options;
      let color = model.borderColor;
      let labelColor = model.borderColor;
      if (!arrowColor) {
        if (dataset.polyline && dataset.polyline.color) {
          color = dataset.polyline.color;
        }
      } else {
        color = arrowColor;
      }
      if (!customLabelColor) {
        if (dataset.polyline && dataset.polyline.labelColor) {
          labelColor = dataset.polyline.labelColor;
        }
      } else {
        labelColor = customLabelColor;
      }

      const angle = Math.atan2(
        centerPoint.y - chartCenterPoint.y,
        centerPoint.x - chartCenterPoint.x
      );
      // important point 2, this point overlapsed with existed points
      // so we will reduce y by 14 if it's on the right
      // or add by 14 if it's on the left
      const point2X =
        chartCenterPoint.x + Math.cos(angle) * (arc.outerRadius + 15);
      let point2Y =
        chartCenterPoint.y + Math.sin(angle) * (arc.outerRadius + 15);

      let suitableY;
      if (point2X < chartCenterPoint.x) {
        // on the left
        suitableY = getSuitableY(point2Y, leftLabelCoordinates, "left");
      } else {
        // on the right

        suitableY = getSuitableY(point2Y, rightLabelCoordinates, "right");
      }

      point2Y = suitableY;

      let value = chart.config.data.labels[i];
      if (dataset.polyline && dataset.polyline.formatter) {
        value = dataset.polyline.formatter(value);
      }
      let edgePointX = point2X < chartCenterPoint.x ? 10 : chart.width - 10;

      if (point2X < chartCenterPoint.x) {
        leftLabelCoordinates.push(point2Y);
      } else {
        rightLabelCoordinates.push(point2Y);
      }
      //DRAW CODE
      // first line: connect between arc's center point and outside point
      ctx.strokeStyle = color;
      ctx.lineWidth = 1;
      ctx.beginPath();
      ctx.moveTo(centerPoint.x, centerPoint.y);
      ctx.lineTo(point2X, point2Y);
      ctx.stroke();
      // second line: connect between outside point and chart's edge
      ctx.beginPath();
      ctx.moveTo(point2X, point2Y);
      ctx.lineTo(edgePointX, point2Y);
      ctx.stroke();
      //fill custom label
      const labelAlignStyle =
        edgePointX < chartCenterPoint.x ? "left" : "right";
      const labelX = edgePointX;
      const labelY = point2Y;
      ctx.textAlign = labelAlignStyle;
      ctx.textBaseline = "bottom";

      ctx.fillStyle = labelColor;
      ctx.fillText(value, labelX, labelY);
    });
    ctx.restore();
  },
};

const getSuitableY = (y, yArray = [], direction) => {
  let result = y;
  yArray.forEach((existedY) => {
    if (existedY - 14 < result && existedY + 14 > result) {
      if (direction === "right") {
        result = existedY + 14;
      } else {
        result = existedY - 14;
      }
    }
  });

  return result;
};

export const stringFormat = function (url, ...args) {
  let i = 0;
 
  i = 0;
  return url.replace(/{}/g, function () {
    return typeof args[i] != "undefined" ? args[i++] : "";
  });
};