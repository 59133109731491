import styled from "styled-components";
import { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import Pending from "../../assets/icons/svg/pendingIcon.svg";
import Accepted from "../../assets/icons/svg/acceptedIcon.svg";
import Remarks from "../../assets/icons/svg/remarks.svg";
import EditIcon from "../../assets/icons/svg/pencil.svg";
import { Colors } from "../../utilities/colors";
import { formatDate } from "../../utilities/commonFunctions";
import { useNavigate } from "react-router-dom";
import ReactTooltip from "react-tooltip";

const FlexDiv = styled.div`
  display: flex;
`;
const CardsWrapper = styled(FlexDiv)`
  
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px 20px;
  margin-top: 20px;
  background: ${Colors.light};
  box-shadow: 3px 3px 6px rgba(0, 0, 0, 0.1);
  border: ${(props) => (props.selected ? '2px solid #FF63C1' : '2px solid transparent')};
  &:last-child() {
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    padding: 10px 20px;
    display: block;
  }
`;
const ContentSection = styled.div`
  margin-left: 10px;
  width: calc(100% - 26px);
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    right: 25px;
    margin-top: -23px;
    margin-left: 14px;
  }
`;
const IconSection = styled.div`
  padding: 7px;
`;
const IconSection2 = styled.div`
    padding: 7px;
    cursor: pointer;
`
const TextWrapper = styled.div`
    display: flex;
    flex-direction: row;
    gap: 10px
`;
const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const BigText = styled.p`
  font-weight: 600;
  font-size: 24px;
  line-height: 19px;
  margin: 0;
`;

const SmallText = styled.p`
font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: 20px;
`;
const JobPostedDate = styled(DateText)`
  // color: ${Colors.primaryText};
  margin: -10px 0 0 0;
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #d04c8d;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0px 0px 0px 0px;
    position: relative;
    // bottom:20px;
    right:25px;
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 971px) and (max-width: 1200px) {
    margin: 20px 0px 0px 0px;
  }
`;
const JobExpiredDate = styled(DateText)`
  // color: #504f8c;
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 1px;
  color: #504f8c;
  position:relative;
  bottom:10px;
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    top:10px;
    right:20px;
    margin-bottom:10px;
  }
`;
const DateWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    position:relative;
    bottom:10px;
  }
`;
const FlexWrapper = styled(FlexDiv)`
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const P = styled.p`
  font-family: "General Sans";
  font-style: normal;
  display: contents;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #212121;
  margin-top: -30px;
  position: relative;
  bottom: 15px;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 0px 0;
    text-transform: capitalize;
  }
`;
const OtherTextWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  @media (max-width: 500px) {
    flex-direction: column;
    position:relative;
    bottom:25px;
  }
`;
const JobDescriptionSection = styled(FlexDiv)`
  position: relative;
  display: block;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    margin-left: 45px;
  }
`;
const IconSingleSection = styled(FlexDiv)`
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
  @media (max-width: 500px) {
    margin-bottom: 5px;
    position: relative;
    // left:20px;
  }
`;
const OtherText = styled.p`
  margin: 0;
  margin-left: 3px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  @media (max-width: 500px) {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    text-align: right;
    margin-bottom: 10px;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  @media (max-width: 500px) {
    left: 30px;
    bottom:25px;
  }
`;
const VacancyText = styled(BigText)`
  color: #b3b3b3;
  display: flex;
  align-items: center;
  // margin-top:-10px;
  > img {
    margin-right: 5px;
  }
`;
const Icons = styled.img`
  margin: 5px 10px 10px 10px;
  cursor: pointer;
`;
const Message = styled.div`
  margin-bottom: 20px;
  // padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const ApplicationText = styled(BigText)`
  color: ${Colors.primaryText};
  margin-left: ${(props) => (props.margin ? props.margin : "")};
`;

export default function InternCard({data, selected, handleCardClick}){

  //FOr internship Module overview(enhancements )
  // const onClickNavigation = () =>{
  //   const queryParams = new URLSearchParams();
  //   queryParams.set("id", data.id);
  //   queryParams.set("additionalData",JSON.stringify(data));
  //   navigate({
  //     pathname: "/dashboard/internship-model-overview",
  //     search: `?${queryParams}`,
  //   });
  // }
    const {created_at, expiredDate, status, expired_at} = data
    const [imageSrc, setImageSrc] = useState("");
    const [selectedCard, setSelectedCard] = useState(null);
    const navigate = useNavigate();
    const cardClickHandler = () => {
      if (status !== "pending") {
        handleCardClick(data.id);
      }
      console.log("IDDD>>>"+data.id)
    };

    console.log(status,"Status")
    
    function extractYearFromCreatedAt(created_at) {
      const createdAtDate = new Date(created_at);
      const year = createdAtDate.getFullYear();
      console.log(year,"EXTRACTED")
      return year;
      
    }
    function calculateExpiredDate(created_at) {
      const createdAtDate = new Date(created_at);
      createdAtDate.setFullYear(createdAtDate.getFullYear() + 1);
      const expiredDate = createdAtDate.toISOString();
    
      return expiredDate;
    }
    function convertDatetime(datetimeString) {
      const date = new Date(Date.parse(datetimeString));
      const day = date.getUTCDate().toString().padStart(2, '0');
      const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
      const year = date.getUTCFullYear();
    
      return `${day}-${month}-${year}`;
    }

    const getStatusName = (name)=>{
      switch(name){
        case 'approved':
          return "Approved";
        case 'review':
          return "Review";
        case 'pending':
          return "Pending"
      }
    }
    return(
        <CardsWrapper  
        onClick={cardClickHandler}
        selected={selected.includes(data.id)} data-tip={getStatusName(status)}>
         <ReactTooltip effect="solid" className="tooltip-custom-width" />
            <IconSection>
            {status && (
            status === "pending"?(
              <Image src={Pending} className="imagePending" />
            ) : status === "approved" ? (
              <Image src={Accepted} className="imagePending" />
            ) : status === "review" ? (
              <Image src={Remarks} className="imagePending" />
            ) : null
           )}
            </IconSection>
            <ContentSection>
                <BigText>Internship Module {extractYearFromCreatedAt(created_at)}</BigText>
                <TextWrapper>
                    <SmallText>Valid Until:</SmallText>
                    <SmallText>{convertDatetime(expired_at)}</SmallText>
                </TextWrapper>

            </ContentSection>
            {status === "review" ? 
            <IconSection2 onClick={()=> navigate("/view-internship-module", {state: {origin: "loe", id: data.id}})}>
            <Image src={EditIcon}/>
          </IconSection2> : "" }
            
        </CardsWrapper>
    )
}