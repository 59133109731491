import React, { useEffect, useState, useRef } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import Dropdown from "../../components/dropdown/dropdown";
import ErrorField from "../../components/errorField/errorField";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import "./talentSearch.css";
import * as yup from "yup";
import RadioInput, {
  RadioInput1,
} from "../../components/radioInput/radioInput";
import { makeStyles } from "@mui/styles";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import DownArrow from "../../assets/Polygon 5.png";
import Detail from "../../assets/Group 1346.png";
import {
  getScopeOfStudyMasterList,
  getSkillMasterList,
  getYearOfStudies,
  getStates,
  talentSearch,
  getApplications,
  gettalentSearchScroll,
  getTalentSearhUser,
} from "../../services/apiCalls";
import ProfileSelection from "./talentProfileSelection";
import { useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton } from "@material-ui/core";
import { init, t } from "i18next";
import { companyProxy } from "../myCompany/companyproxy";
import { useSnapshot } from "valtio";
import { Col, Row } from "react-bootstrap";
import { Pagination } from "@mui/material";
import { subsctipationsProxy } from "../Subscriptions/subscripationsProxy";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import SkillCard from "../../components/skillCard/skillCard";
import { useDispatch, useSelector } from "react-redux";
import Logo from "../../assets/addNewIntern.png";
import ReactTooltip from "react-tooltip";
import { getQuota } from "../../services/apiCalls";
import { useTranslation } from "react-i18next";
import { updateTokenAvailable, updateTokenUsed } from "./talentReducer";

const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: 20px 0 -80px 0;
  @media (max-width: 500px) {
    text-align: center;
  }
`;

const ButtonWrapper = styled.div`
  width: 157px;
  position: relative;
  left: 89%;
  margin: 20px 0 0 0;
  @media (max-width: 500px) {
    position: relative;
    top: 20px;
    margin: 70px 0 50px 0;
    left: 80px;
  }
  @media (min-width: 501px) and (max-width: 1200px) {
    left: 70%;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #d04c8d;
  margin: 0px 10px 10px 10px;
`;
const FormWrapper = styled.div``;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 1rem 0;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const SmallText = styled.h4`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #858585;
`;
const RadioBoxWrapper = styled.div`
  display: grid;
  @media (max-width: 767px) {
    grid-template-columns: 1fr;
  }
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  padding-top: 14px;
  color: ${Colors.labelText};
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;

const FilterText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #202020;
  position: relative;
  top: 25px;
  margin: 0 35px;
  @media (max-width: 500px) {
    margin: 0;
    width: 200px;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  margin: 40px 0;
  width: 40%;
  @media (max-width: 500px) {
    position: relative;
    top: 0px;
  }
`;

const SectionWrapper3 = styled.div`
  width: 77.5%;
  height: 110px;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid #d04c8d;
  box-shadow: 2px 3px 2px 3px #d04c8d;
  display: flex;
  justify-content: space-between;
  position: fixed;
  top: 600px;
  margin: 0px;
  @media (max-width: 500px) {
    width: 90%;
    display: block;
    height: 180px;
    padding: 20px 10px;
  }
`;
const ButtonWrapper3 = styled.div`
  width: 161px;
  height: 56px;
  position: relative;
  top: 13px;
  right: 50px;
  @media (max-width: 500px) {
    position: relative;
    left: 30px;
  }
  @media (min-width: 1200px) and (max-width: 1440px) {
  }
`;
const MediumTexts = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  color: #202020;
  display: flex;
  align-items: center;
  margin: 0 0 0 40px;
  @media (max-width: 500px) {
    margin: 0px 0 0 40px;
    flex-wrap: wrap;
    width: 300px;
  }
`;

const Image = styled.img`
  width: 14px;
  height: 14px;
  position: relative;
  top: 35px;
`;
const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const ButtonDiv = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  gap: 25px;
`;

const TalentSearchContentWrapper = styled.div`
  background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  padding: 15px 50px 15px 15px;
  margin: 10px 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    margin-top: 61px;
    > img {
      display: block;
      margin-left: auto;
    }
  }
`;

const ContentWrapper = styled.div`
  margin: 0 0 0 30px;
  margin-left: 115px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0 0 10px 50px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 0 50px;
    color: #ffffff;
  }
  @media (max-width: 500px) {
    margin: 0;
    margin-left: 0px;
    padding: 5px;
  }
`;

const StaticImg = styled.img`
  position: absolute;
  left: -15px;
  /* top: 22%; */
  width: 208px;
  height: 190px;
  cursor: default !important;
  @media (max-width: 500px) {
    display: inline-block;
    margin-top: 50px;
    left: -40px;
    width: 150px;
    height: 150px;
  }
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));
export const schema = yup.object().shape({
  email_address: yup.string().required("Email Address is required."),
  position: yup.string().required("Position is required."),
  dapartment: yup.string().required("Department is required."),
});

const TalentSearch = () => {
  const [show, setShow] = useState(false);

  const [shortlistedObj, setShortlistObj] = useState({});
  const state = useLocation();
  const { t, i18n } = useTranslation();
  useSnapshot(companyProxy);
  useSnapshot(subsctipationsProxy);
  useEffect(async () => {
    if (companyProxy.selectcompany) {
      if (companyProxy.selectcompany.org_code) {
        await subsctipationsProxy.setcount(companyProxy.selectcompany.org_code);
      }
    }
  }, [companyProxy.selectcompany]);
  const typeaheadRef = useRef(null);

  const classes = useStyles();
  const options = [
    {
      key: "Low",
      value: "0.2",
    },
    {
      key: "Medium Low",
      value: "0.4",
    },
    {
      key: "Medium",
      value: "0.6",
    },
    {
      key: "Medium High",
      value: "0.8",
    },
    {
      key: "High",
      value: "1",
    },
  ];

  const [talentData, setTalentData] = useState([]);
  const [talentDataBackup, setTalentDataBackup] = useState([]);
  const navigate = useNavigate();

  const scope = useSelector((state) => state.masterData.scopeOfStudies);

  const [selectedScope, setSelectedScope] = useState({});
  console.log("--------------------", selectedScope);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkils] = useState([]);
  const [selectedSkillsValue, setSelectedSkillsValue] = useState("");
  const [yearOfStudy, setYearOfStudy] = useState([]);
  const [selectedYearOfStudy, setSelectedYearOfStudy] = useState({});
  const [states, setStates] = useState([]);
  const [selectedStates, setSelectedStates] = useState({});

  const [selectedEnglish, setSelectedEnglish] = useState({});

  const [shortlistedData, setShortlistedData] = useState([]);

  const [tabIndex, setTabIndex] = useState(0);
  const [searchval, setSearchFilter] = useState("");
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [shortlistUsers, setShortlistUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [advancedFilter, setAdvancedFilter] = useState(false);
  const [talentSearchToken, setTalentSearchToken] = useState();
  const [talentSearchUsedToken, setTalentSearchUsedToken] = useState();
  const [orgFactors, setOrgFactors] = useState({
    research: 0,

    inception: 0,

    communication: 0,

    leadership: 0,

    teamwork: 0,

    collectedness: 0,

    plan: 0,

    inventive: 0,

    // embracing: 0,

    // connecting: 0,

    // supervising: 0,

    // ambitious: 0,

    // innovating: 0,

    // evaluating: 0,

    // facilitating: 0,

    // coordinating: 0,

    ability: 0,

    comfort: 0,

    achievement: 0,

    individualism: 0,

    receiptiveness: 0,

    space: 0,

    english: 0,

    skills: null,

    nationality: null,
    curr_study_year: null,
    state: null,
    scope: null,
  });
  const [newSkill, setSkill] = useState([]);
  const companyData = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  console.log("ooorg", companyData.id);
  const getshortlist = async (p) => {
    setPaginationTabIndex(p);
    setIsLoading(true);

    const { data } = await getTalentSearhUser(companyData.id);
    let a = data?.data;
    // if (data?.data) {
    //   for (let i = 0; i < data?.data?.length; i++) {
    //     if (data?.data[i]["status"] == "shortlist") {
    //       a.push(data?.data[i]["user_id"]);
    //     }
    //   }
    // }
    if (a.length > 0) {
      await talentSearchScroll(a, `?page=${p}&page_size=5`);
    } else {
      toast.error(t("No saved profiles found"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setIsLoading(false);
  };

  const getskils = async (e) => {
    if (e.trim().length != 0) {
      const { data } = await getSkillMasterList(e);
      if (data.status == 1) {
        const slicedArray = data.data.slice(0, 100);
        const skillsdata = slicedArray.map((x) => {
          return { ...x, label: x.Example, value: x.Id };
        });
        setSkills(skillsdata);
      }
    }
  };
  const dropdownSkillChange = async (val) => {
    setSelectedSkils(val);
    setOrgFactors({ ...orgFactors, skills: val[0]?.label + "" });
  };

  const handleSearch = (query) => {
    // setIsLoading(true);
    getskils(query);
  };

  // const skillsAddChange = (val) => {

  //   // const newVal = val.map((x) => {
  //   //   return { ...x, scope: x.value };
  //   // });
  //   setOrgFactors({ ...orgFactors, skills: val.value + "" });
  //   setSelectedSkils(val);
  // };

  const talentSearchScroll = async (user, p) => {
    const { data } = await gettalentSearchScroll({ user_id: user }, p);
    setTabIndex(3);
    if (data && data?.results?.length > 0) {
      let l = data.count;
      let quo = Math.floor(l / 10);
      let rem = l % 10;
      setPaginationCount(rem != 0 ? quo + 1 : quo);
      setTalentData(data.results);
      setTalentDataBackup(data.results);
      setIsLoading(false);
    }
  };
  console.log("TALENT DATA>>>" + JSON.stringify(talentData));
  function handleClick() {
    setShow(true);
  }
  const urlNavigate = () => {
    navigate("/talentSearch");
  };
  const {
    control,
    watch,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const CheckBoxes = [
    {
      key: "Ability To Influence",
      value: "ability",
    },
    {
      key: "Comfort with ambiguity",
      value: "comfort",
    },
    {
      key: "Achievement Orientation",
      value: "achievement",
    },
    {
      key: "Individualism vs. Collectivism",
      value: "individualism",
    },
    {
      key: "Receiptiveness to Change",
      value: "receiptiveness",
    },
    {
      key: "Space Orientation",
      value: "space",
    },
  ];

  const CheckBoxes1 = [
    {
      key: "Evaluating & Resolving",
      value: "research",
      num: "0.6",
      tip: "Demonstrates evidence of analytical clarity. Competent at reaching the core of difficult challenges. Effectively uses own expertise. Adopts new technology quickly. Writes clearly and effectively."
    },
    {
      key: "Innovating & Envisioning",
      value: "inception",
      num: "0.6",
      tip: "Works well in circumstances where being receptive to diverse perspectives is necessary. Pursues self improvement by seeking new knowledge. Uses imagination and ingenuity to overcome difficulties and issues. Regards situations comprehensively and systematically. Encourages positive organizational transformation."
    },
    {
      key: "Connecting & Portraying",
      value: "communication",
      num: "0.6",
      tip: "Communicates and networks effectively. Successfully persuades and influences others. Relates to others in a confident, relaxed manner."
    },
    {
      key: "Supervising & Determining",
      value: "leadership",
      num: "0.6",
      tip: "Takes charge and assumes authority. Takes the initiative, provides guidance, and accepts accountability."
    },
    {
      key: "Facilitating & Collaborating",
      value: "teamwork",
      num: "0.6",
      tip: "Supports others and demonstrates respect and favorable regard for them. Focuses on the needs of others and works well with various parties. Possesses reliable characteristics that are compatible with those of the organization."
    },
    {
      key: "Embracing & Managing",
      value: "collectedness",
      num: "0.6",
      tip: "Adjusts well to uncertainties. Skillfully handles pressure and difficulties."
    },
    {
      key: "Coordinating & Implementing",
      value: "plan",
      num: "0.6",
      tip: "Prepares for work in advance and organizes work systematically. Executes work by adhering to instructions. Emphasizes the fulfillment of client needs and provides service or product according to established guidelines."
    },
    {
      key: "Ambitious & Result-Oriented",
      value: "inventive",
      num: "0.6",
      tip: "Committed to setting personal goals and highly motivated to achieving results. Stimulated by accomplishments and the recognition of effort. Demonstrates knowledge of commerce, business and economic affairs. Solicits possibilities for professional and personal growth."
    },
  ];

  const english = [
    { label: t("Level 1"), value: "0.2" },
    { label: t("Level 2"), value: "0.4" },
    { label: t("Level 3"), value: "0.6" },
    { label: t("Level 4"), value: "0.8" },
    { label: t("Level 5"), value: "1" },
  ];

  const radioChange = (name, val) => {
    const prev = orgFactors;
    setOrgFactors({ ...prev, [name]: val });
  };
  const [error, setError] = useState("");
  const checkBoxChange = (name, num) => {
    setOrgFactors((prevState) => {
      const prevValue = prevState[name];
      const workFactors = CheckBoxes1.map((checkbox) => checkbox.value);
      const selectedWorkFactorsCount = workFactors.filter(
        (key) => prevState[key] !== undefined && prevState[key] !== 0
      ).length;
      const selectedCount = Object.values(prevState).filter(
        (value) => value
      ).length;

      if (workFactors.includes(name)) {
        if (selectedWorkFactorsCount < 3 || prevValue) {
          const newValue = prevValue ? 0 : num;
          return { ...prevState, [name]: newValue };
        } else {
          setError("Maximum work factors reached.");
          toast.error(t("Maximum work factors reached"), {
            position: toast.POSITION.TOP_RIGHT,
          });
          return prevState;
        }
      } else {
        const newValue = prevValue ? 0 : num;
        return { ...prevState, [name]: newValue };
      }
    });
    // const prev = orgFactors;
    // const selectedCount = Object.values(prev).filter((value) => value).length;

    // if (selectedCount < 3 || prev[name]) {
    //   const newValue = prev[name] ? 0 : num;
    //   setOrgFactors((prevState) => ({ ...prevState, [name]: newValue }));
    //   setError(""); // Clear the error if selection is valid
    // } else {
    //   setError("Maximum factors reached."); // Set the error message
    //   toast.error(t("Maximum factors reached"), {
    //     position: toast.POSITION.TOP_RIGHT,
    //   });
    // }
  };

  const getSelectedWorkFactorsCount = () => {
    // const workFactors = Object.keys(orgFactors).filter(
    //   (key) => CheckBoxes1.some((checkbox) => checkbox.value === key)
    // );

    // return workFactors.filter(
    //   (key) => orgFactors[key] !== undefined && orgFactors[key] !== 0
    // ).length;
    const workFactors = Object.keys(orgFactors).filter((key) =>
      CheckBoxes1.some((checkbox) => checkbox.value === key)
    );

    if (workFactors.length === 0) {
      // Return 0 if no work factors are available
      return 0;
    }

    return workFactors.filter(
      (key) => orgFactors[key] !== undefined && orgFactors[key] !== 0
    ).length;
  };
  // const getScopeOfStudies = async () => {
  //   const { data } = await getScopeOfStudyMasterList();
  //   const modifiedData = data?.data?.map((e) => {
  //     return { ...e, label: e.Name, value: e.Id };
  //   });
  //   setScope(modifiedData);
  // };

  const getlevelsofStudy = async () => {
    const { data } = await getYearOfStudies();
    const modifiedData = data?.data?.map((e) => {
      return { ...e, label: e.Name, value: e.Value };
    });
    setYearOfStudy(modifiedData);
  };

  const getLocations = async () => {
    const { data } = await getStates("132");
    const modifiedData = data?.data?.map((e) => {
      return { ...e, label: e.Name, value: e.Id };
    });
    setStates(modifiedData);
  };
  console.log("orggggggg------------", orgFactors);

  console.log("orggggggg------------", Object.keys(orgFactors).length);
  const dropdownChange = (type, val) => {
    if (type === "scope") {
      setSelectedScope({ label: val?.label, value: val?.value });
      setOrgFactors({ ...orgFactors, scope: val?.value + "" });
    }
    if (type === "skills") {
      setSelectedSkils({ label: val?.label, value: val?.value });
      setOrgFactors({ ...orgFactors, skills: val?.value + "" });
    }
    if (type === "yearOfStudy") {
      setSelectedYearOfStudy({ label: t(val?.label), value: val?.value });
      setOrgFactors({ ...orgFactors, curr_study_year: val?.value + "" });
    }
    if (type === "states") {
      setSelectedStates({ label: val?.label, value: val?.value });
      setOrgFactors({ ...orgFactors, state: val?.value + "" });
    }
    if (type === "english") {
      setSelectedEnglish({ label: val?.label, value: val?.value });
      setOrgFactors({ ...orgFactors, english: val?.value });
    }
  };
  const handleOpenAdvancedFilter = () => {
    setAdvancedFilter(!advancedFilter);
  };

  const displayPage = (val) => {
    if (tabIndex == 3) {
      getshortlist(val);
    } else {
      let p = "";
      if (searchval && searchval.length > 0) {
        p = `?search=${searchval}&page=${val}&page_size=5&organization_id=${company.id}`;
      } else {
        p = `?page=${val}&page_size=5&organization_id=${company.id}`;
      }
      setPaginationTabIndex(val);
      postTalentSearch(p);
    }
  };
  const postTalentSearch = async (p) => {
    console.log("parmsPa", p, orgFactors);
    if (orgFactors.skills !== "" || orgFactors.english !== null) {
      setIsLoading(true);
      const { data } = await talentSearch(orgFactors, p);
      setTabIndex(1);
      if (data && data?.results?.length > 0) {
        let n = 0;
        switch (subsctipationsProxy.plan) {
          case "enterprise":
            n = 550;
            break;
          case "premium":
            n = 250;
            break;
          case "pro":
            n = 100;
            break;
          case "basic":
            n = 50;
            break;
        }
        let l = 0;
        if (data.count <= n) {
          l = data.count;
        } else {
          l = n;
        }
        let quo = Math.floor(l / 10);
        let rem = l % 10;
        setPaginationCount(rem != 0 ? quo + 1 : quo);
        setTalentData(data.results);
        setTalentDataBackup(data.results);
        setIsLoading(false);
      }
    } else {
      toast.error("Please Fill any one field", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const setIndex = (e) => {
    setTabIndex(0);
  };
  const resetForm = () => {
    setSelectedScope("");
    setSelectedSkils("");
    setSelectedStates("");
    setSelectedYearOfStudy("");
    setSelectedEnglish("");
    setOrgFactors({
      research: 0,
      inception: 0,
      communication: 0,
      leadership: 0,
      teamwork: 0,
      collectedness: 0,
      plan: 0,
      inventive: 0,
      ability: 0,
      comfort: 0,
      achievement: 0,
      individualism: 0,
      receiptiveness: 0,
      space: 0,
      english: 0,
      skills: null,
      nationality: null,
      curr_study_year: null,
      state: null,
      scope: null,
    });
  };
  useEffect(() => {
    // getScopeOfStudies();
    getlevelsofStudy();
    getlevelsofStudy();
    getLocations();
  }, []);

  const addItem = (e) => {
    let dt = shortlistedObj;
    dt[e?.user_id] = e.percentage;
    if (shortlistUsers.length < 3) {
      let items = [...shortlistUsers];
      const filterItemsIndex = items.findIndex(
        (user) => user.user_id === e.user_id
      );
      if (filterItemsIndex > -1) {
        items.splice(filterItemsIndex, 1);
      }
      if (filterItemsIndex === -1) {
        items.push(e);
      }
      setShortlistUsers(items);
    }
    setShortlistObj(dt);
  };

  useEffect(() => {
    if (state?.state?.tabIndex) {
      if (state?.state?.tabIndex !== 3) {
        postTalentSearch("");
        setTabIndex(state?.state?.tabIndex);
      } else {
        getshortlist(1);
        setTabIndex(state?.state?.tabIndex);
      }
    }
  }, []);

  const compareProfiles = () => {
    navigate("/talentsearch/applicants-comparision", {
      state: {
        shortlist: shortlistUsers,
        tabIndex: tabIndex,
        type: tabIndex !== 3 ? "graduate" : "",
        selected: shortlistedObj,
      },
    });
  };

  const setFilters = async (num, university, skills) => {
    const percent = num;
    const universityF = university;
    let initData = talentDataBackup;
    let user_ids = [];
    let testData = [];
    if (num?.length === 0) testData = talentDataBackup;
    await num?.map((e1) => {
      for (let i = 0; i < talentDataBackup.length; i++) {
        let e = talentDataBackup[i];
        if (e1 === "10% - 50%") {
          if (
            +e.percentage > 10 &&
            +e.percentage <= 50 &&
            user_ids.indexOf(e.user_id) == -1
          ) {
            testData.push(e);
            user_ids.push(e.user_id);
          }
        }
        if (e1 === "50% - 70%") {
          if (
            +e.percentage > 50 &&
            +e.percentage <= 70 &&
            user_ids.indexOf(e.user_id) == -1
          ) {
            testData.push(e);
            user_ids.push(e.user_id);
          }
        }
        if (e1 === "70% - 90%") {
          if (
            +e.percentage > 70 &&
            +e.percentage <= 90 &&
            user_ids.indexOf(e.user_id) == -1
          ) {
            testData.push(e);
            user_ids.push(e.user_id);
          }
        }
        if (e1 === "90% - 100%") {
          if (
            +e.percentage > 90 &&
            +e.percentage <= 100 &&
            user_ids.indexOf(e.user_id) == -1
          ) {
            testData.push(e);
            user_ids.push(e.user_id);
          }
        }
        console.log(testData, "bulkdata");
      }
    });
    initData = testData;
    if (university.length > 0) {
      initData = initData.filter((e) => university.includes(e?.insti_name));
    }
    if (skills.length > 0) {
      let dupData = [...initData];
      skills.map((e) => {
        initData = dupData.filter((item) => item.titles.includes(e));
      });
    }
    setTalentData(initData);
  };
  const searchv1 = () => {
    setPaginationTabIndex(1);
    setShortlistUsers([]);
    postTalentSearch(
      `?page=${paginationTabIndex}&page_size=5&organization_id=${company.id}`
    );
  };

  const dispatch = useDispatch();

  // const customStyle =
  //   i18n.language === "en"
  //     ? { display: "flex", marginBottom: "19px", marginLeft: "111px" }
  //     : {
  //       display: "flex",
  //       marginBottom: "19px",
  //       paddingLeft: "206px",
  //       paddingRight: "42px",
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

  const [coin, setCoin] = useState("");
  const [userUsedCoins, setUserUsedCoin] = useState("");
  useEffect(async () => {
    if (Object.keys(company).length > 0) {
      const result3 = await getQuota(org_code);
      const totalJobsToken = result3?.data?.total_job;
      const usedJobsToken = result3?.data?.job;
      setTalentSearchToken(result3?.data?.talent_search_tokens);
      setTalentSearchUsedToken(result3?.data?.talent_search_tokens_used);
      dispatch(updateTokenAvailable(result3?.data?.talent_search_tokens));
      dispatch(updateTokenUsed(result3?.data?.talent_search_tokens_used));
      setCoin(totalJobsToken - usedJobsToken);
      setUserUsedCoin(result3?.data?.job);
    }
  }, [company]);
  console.log("TOKESSS>>>" + coin);
  //     };
  const [isMobileView, setIsMobileView] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768) ||
        setIsMobileView(window.innerWidth <= 820);
    };
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div>
      <MainLayout>
        {tabIndex == 0 ? (
          <div>
            <TalentSearchContentWrapper>
              <StaticImg src={Logo} alt="new company" />
              <ContentWrapper>
                <h3>{t("Graduate Talent Finder")}</h3>
                <p>
                  {t(
                    "Unlock the power of discovery and connect with exceptional talent that match your unique requirements effortlessly."
                  )}{" "}
                  <br></br>
                  {t(
                    "Please note that talent search via mynext is a payable feature.For more information, kindly email us at company@mynext.my."
                  )}
                </p>
              </ContentWrapper>
              <div
                style={{
                  display: "flex",
                  marginBottom: "19px",
                  marginLeft: "111px",
                }}
              >
                <div
                  style={{
                    minWidth: "245px",
                    minHeight: "34px",
                    background: "#FFFFFF",
                    border: "3.01518px solid #EE4E9C",
                    marginTop: "14px",
                    borderRadius: "12px",
                    marginLeft: "-155px",
                  }}
                >
                  <p
                    style={{
                      position: "absolute",
                      fontFamily: "Oswald",
                      fontStyle: "normal",
                      fontWeight: "400",
                      fontSize: "21.9611px",
                      lineHeight: "27px",
                      color: "#57508E",
                      marginLeft: "11px",
                    }}
                  >
                    {t("Talent Tokens")}
                  </p>
                </div>

                <div
                  style={{
                    minWidth: "70px",
                    minHeight: "69px",
                    background:
                      "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
                    borderRadius: "36px",
                    position: "absolute",
                    marginLeft: "-26px",
                    marginTop: "-4px",
                  }}
                >
                  <div
                    style={{
                      minWidth: "64px",
                      minHeight: "64px",
                      background: "rgb(40, 255, 0)",
                      borderRadius: "36px",
                      position: "absolute",
                      marginLeft: "3px",
                      marginTop: "2px",
                    }}
                  >
                    <ReactTooltip effect="solid" className={"tooltip-custom-width"} />
                    <p
                      style={{
                        height: "38px",
                        fontFamily: "Oswald",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "31.5841px",
                        lineHeight: "61px",
                        background:
                          "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "#57508E",
                        textAlign: "center",
                      }}
                      data-tip="Available"
                    >
                      {talentSearchToken ?? 0}
                    </p>
                  </div>
                </div>

                <div
                  style={{
                    minWidth: "70px",
                    minHeight: "69px",
                    background:
                      "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
                    borderRadius: "36px",
                    position: "absolute",
                    marginLeft: "49px",
                    textAlign: "center",
                    marginTop: "-4px",
                  }}
                >
                  <div
                    style={{
                      minWidth: "64px",
                      minHeight: "64px",
                      background: "#FFFF00",
                      borderRadius: "36px",
                      position: "absolute",
                      marginLeft: "3px",
                      marginTop: "2px",
                      textAlign: "center",
                    }}
                  >
                    <p
                      style={{
                        height: "38px",
                        fontFamily: "Oswald",
                        fontStyle: "normal",
                        fontWeight: "400",
                        fontSize: "31.5841px",
                        lineHeight: "61px",
                        background:
                          "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                        WebkitBackgroundClip: "text",
                        WebkitTextFillColor: "#57508E",
                        textAlign: "center",
                      }}
                      data-tip="Used"
                    >
                      {talentSearchUsedToken ?? 0}
                    </p>
                  </div>
                </div>
              </div>
            </TalentSearchContentWrapper>
            <HeaderText>{t("Graduate Talent Finder")}</HeaderText>
            <ButtonWrapper>
              <Button
                cursor="pointer"
                name={t("My Saved Profiles")}
                onClick={() => getshortlist(1)}
                disabled={
                  !companyProxy.selectcompany ||
                  companyProxy.selectcompany.org_code == null
                }
              ></Button>
            </ButtonWrapper>
            <PageWrapper>
              <Title>{t("Filter Selection")}</Title>
              <FormWrapper>
                <Row>
                  <Col>
                    <GroupWrapper>
                      <FormLabel>
                        {t("Scope of Study")}{" "}
                        <img src={Detail} alt="img" className="talentScope" data-tip={t("Narrow your search by talents' scope of study. Note: Some filters may not have talent data yet. Please widen your search if no results found.")} />
                      </FormLabel>

                      <Dropdown
                        name="country"
                        search={true}
                        options={scope?.map((e) => {
                          return { ...e, label: e.Name, value: e.Id };
                        })}
                        val={selectedScope?.value}
                        label={selectedScope?.label}
                        change={(val) => dropdownChange("scope", val)}
                        placeholder={t("Any")}
                        minWidth="auto"
                        isClearable={true}
                      />
                      {errors.country && (
                        <ErrorField err={errors.country.message} />
                      )}
                    </GroupWrapper>
                  </Col>

                  <Col xs={isMobileView ? 14 : null}>
                    <GroupWrapper>
                      <FormLabel>
                        {t("Skills")}
                        <img src={Detail} alt="img" className="talentSkill" data-tip={t("Narrow your search by the skills that talents have listed in their mynext CV. Note: Some filters may not have talent data yet. Please widen your search if no results found.")} />
                      </FormLabel>
                      {/* <Dropdown
                        name="Skills"
                        search={true}
                        options={skills}
                        minWidth="auto"
                        val={selectedSkills?.value}
                        label={selectedSkills?.label}
                        placeholder={t("Type to Search")}
                        change={(val) => dropdownChange("skills", val)}
                        isClearable={true}
                        inputChange={(e) => {
                          getskils(e);
                        }}
                      /> */}
                      <AsyncTypeahead
                        style={{}}
                        id="add-skills"
                        className="add-skills-typehead"
                        labelKey="label"
                        ref={typeaheadRef}
                        onSearch={handleSearch}
                        options={skills}
                        selected={selectedSkills}
                        placeholder={t("Search for a skill ...")}
                        onChange={(val) => dropdownSkillChange(val)}
                      />
                      {errors.skills && (
                        <ErrorField err={errors.skills.message} />
                      )}
                    </GroupWrapper>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <GroupWrapper>
                      <FormLabel>
                        {t("Level of Study")}
                        <img src={Detail} alt="img" className="talentStudy" data-tip={t("Narrow your search by the talents' current year of study. Note: Some filters may not have talent data yet. Please widen your search if no results found.")} />
                      </FormLabel>
                      <Dropdown
                        name="year"
                        search={true}
                        options={yearOfStudy}
                        change={(val) => {
                          dropdownChange("yearOfStudy", val);
                        }}
                        val={selectedYearOfStudy?.value}
                        label={selectedYearOfStudy?.label}
                        placeholder={t("Enter Level Of Study")}
                        minWidth="auto"
                        isClearable={true}
                      />
                      {errors.levelofstudy && (
                        <ErrorField err={t(errors.levelofstudy.message)} />
                      )}
                    </GroupWrapper>
                  </Col>
                  <Col>
                    <GroupWrapper
                      style={{ display: "flex", flexDirection: "column" }}
                    >
                      <FormLabel>
                        {t("Nationality")}
                        <img
                          src={Detail}
                          alt="img"
                          className="talentNationality"
                          data-tip={t("Narrow your search by the talents' nationality (Malaysian or Non-Malaysian only). Note: Some filters may not have talent data yet. Please widen your search if no results found.")}
                        />
                      </FormLabel>
                      <div className="talentRadio">
                        <RadioInput1
                          checked={orgFactors["nationality"] === "0"}
                          onChange={() => radioChange("nationality", "0")}
                          label={t("Malaysian")}
                        />
                        <RadioInput1
                          checked={orgFactors["nationality"] === "1"}
                          onChange={() => radioChange("nationality", "1")}
                          label={t("Non Malaysian")}
                        />
                      </div>
                    </GroupWrapper>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <GroupWrapper>
                      <FormLabel>
                        {t("Minimum English Proficiency")}
                        <img src={Detail} alt="img" className="talentEnglish" data-tip={t("Narrow your search by the talents' mynext english assessment results. Note: Some filters may not have talent data yet. Please widen your search if no results found.")} />
                      </FormLabel>
                      <Dropdown
                        name="mef"
                        control={control}
                        search={true}
                        options={english}
                        change={(val) => {
                          dropdownChange("english", val);
                        }}
                        val={selectedEnglish?.value}
                        label={selectedEnglish?.label}
                        minWidth="auto"
                        isClearable={true}
                        defaultValue={{ label: t("Medium"), value: 0.6 }}
                        placeholder={t("Select...")}
                      />
                      {errors.mef && <ErrorField err={t(errors.mef.message)} />}
                    </GroupWrapper>
                  </Col>
                  <Col>
                    <GroupWrapper>
                      <FormLabel>
                        {t("Locations")}
                        <img
                          src={Detail}
                          alt="img"
                          className="talentLocation"
                          data-tip={t("Narrow your search by the talents' location (Malaysian states only). Note: Some filters may not have talent data yet. Please widen your search if no results found.")}
                        />
                      </FormLabel>
                      <Dropdown
                        name="locations"
                        search={true}
                        options={states}
                        change={(val) => {
                          dropdownChange("states", val);
                        }}
                        val={selectedStates?.value}
                        label={selectedStates?.label}
                        placeholder={t("Enter Location")}
                        minWidth="auto"
                        isClearable={true}
                      />
                      {errors.location && (
                        <ErrorField err={errors.location.message} />
                      )}
                    </GroupWrapper>
                  </Col>
                </Row>

                <SeparatorDiv onClick={handleOpenAdvancedFilter}>
                  <HrLine />
                  <Separator>{t("Advanced Filter")}</Separator>
                  <HrLine />

                  {advancedFilter ? (
                    <IconButton>
                      <UpIcon />
                    </IconButton>
                  ) : (
                    <IconButton>
                      <DownIcon />
                    </IconButton>
                  )}
                </SeparatorDiv>

                {advancedFilter ? (
                  <>
                    <GroupWrapper>
                      <FormLabel>{t("Work Competency Factors")}</FormLabel>
                      <SmallText style={{ paddingLeft: "12px" }}>
                        {t(
                          "Select the 3 preferred competencies you are looking for in your candidates."
                        )}
                      </SmallText>
                      <div className="advancedWorkOne">
                        {CheckBoxes1.map((checkbox, index) => (
                          <RadioBoxWrapper key={index} className="fitfactors">
                            <Row>
                              <Col
                                xs={isMobileView ? 8 : 2}
                                style={{
                                  marginTop: isMobileView ? "15px" : null,
                                }}
                              >
                                <TableHead
                                  style={{
                                    fontWeight: "500",
                                    minWidth: "260px",
                                  }}
                                >
                                  {t(checkbox.key)}
                                  <img
                                    src={Detail}
                                    alt="img"
                                    style={{ marginLeft: "5px" }}
                                    data-tip={t(checkbox.tip)}
                                  />
                                  <ReactTooltip effect="solid" className="tooltip-custom-width" />
                                </TableHead>
                              </Col>
                              <Col xs={2}>
                                <div
                                  className="radioWrapper"
                                  style={{
                                    marginLeft: isMobileView ? "50px" : null,
                                  }}
                                >
                                  <CheckboxInput
                                    style={{ maxWidth: "110px !important" }}
                                    key={checkbox.value}
                                    // {...field}
                                    checked={
                                      orgFactors[checkbox.value] !==
                                      undefined &&
                                      orgFactors[checkbox.value] !== 0
                                    }
                                    onChange={() =>
                                      checkBoxChange(
                                        checkbox.value,
                                        checkbox.num
                                      )
                                    }
                                  />
                                </div>
                              </Col>
                            </Row>
                            {/* <TableHead
                              style={{ fontWeight: "500", minWidth: "260px" }}
                            >
                              {checkbox.key}
                              <img
                                src={Detail}
                                alt="img"
                                className="talentCheckbox"
                              />
                            </TableHead>

                            <div className="radioWrapper">
                                <CheckboxInput
                                  style={{ maxWidth: "110px !important" }}
                                  key={checkbox.value}
                                  // {...field}
                                  checked={orgFactors[checkbox.value]}
                                  onChange={() =>  checkBoxChange(checkbox.value, checkbox.num)}
                                />
                            </div>
                            <div className="radioWrapper">
                              {options.map((option, idx) => (
                                <CheckboxInput
                                  style={{ maxWidth: "110px !important" }}
                                  key={checkbox.value + option.key}
                                  checked={
                                    option.value === orgFactors[checkbox.value]
                                  }
                                  onChange={() =>
                                    radioChange(checkbox.value, option.value)
                                  }
                                  label={option.key}
                                />
                              ))}
                            </div> */}
                          </RadioBoxWrapper>
                        ))}
                      </div>
                      {error && <span style={{ color: "red" }}>{error}</span>}
                    </GroupWrapper>
                    {/* <GroupWrapper style={{ paddingTop: "24px" }}>
                      <FormLabel>{"Organisation Fit Factors"}</FormLabel>

                      <SmallText className="organisationText">
                        Further job descriptions, company profile, etc. The job
                        viewer can download this
                      </SmallText>
                      <div className="advancedWorkOne">
                        {CheckBoxes.map((checkbox, index) => (
                          <RadioBoxWrapper key={index} className="fitfactors">
                            <TableHead
                              style={{ fontWeight: "500", minWidth: "0px" }}
                            >
                              {checkbox.key}
                              <img
                                src={Detail}
                                alt="img"
                                className="talentCheckbox"
                              />
                            </TableHead>

                            <div className="radioWrapper">
                              {options.map((option, idx) => (
                                <RadioInput1
                                  key={checkbox.value + option.key}
                                  checked={
                                    option.value === orgFactors[checkbox.value]
                                  }
                                  onChange={() =>
                                    radioChange(checkbox.value, option.value)
                                  }
                                  label={option.key}
                                />
                              ))}
                            </div>
                          </RadioBoxWrapper>
                        ))}
                      </div>
                    </GroupWrapper>{" "} */}
                  </>
                ) : (
                  " "
                )}
                <ButtonDiv>
                  <Button
                    style={{ width: isMobileView ? "150px" : null }}
                    cursor="pointer"
                    name={t("Search")}
                    disabled={
                      !companyProxy.selectcompany ||
                      companyProxy.selectcompany.org_code == null ||
                      (advancedFilter && getSelectedWorkFactorsCount() < 3) ||
                      Object.values(orgFactors).every((value) => {
                        if (value === null || value === 0) {
                          return true;
                        }
                      })
                    }
                    onClick={searchv1}
                  />

                  <div>
                    <Button
                      style={{ width: isMobileView ? "150px" : null }}
                      secondary
                      onClick={() => resetForm()}
                      name={t("Reset")}
                      variant="outlined"
                    />
                  </div>
                </ButtonDiv>
              </FormWrapper>
            </PageWrapper>
          </div>
        ) : (
          <div>
            <ProfileSelection
              setIndex={setIndex}
              talentData={talentData}
              states={states}
              addItem={addItem}
              setFilters={setFilters}
              talentDataBackup={talentDataBackup}
              shortlistUsers={shortlistUsers}
              advancedFilter={getSelectedWorkFactorsCount() < 3}
              tabIndex={tabIndex}
              shortlistObj={shortlistedObj}
              resetForm={resetForm}
            />
            {/* {isLoading ? <Loader /> : ""} */}
            {talentData?.length > 0 ? (
              <div className="talentData">
                <Pagination
                  style={{ textColor: "red" }}
                  classes={{ ul: classes.ul }}
                  count={paginationCount}
                  page={paginationTabIndex} //{tabIndex}
                  onChange={(event, value) => displayPage(value)}
                />
              </div>
            ) : (
              <>
                <p class="noDataFound">{t("No Records Found")}</p>
              </>
            )}
            {shortlistUsers.length > 1 ? (
              <SectionWrapper3>
                <MediumTexts>
                  {t("You have selected")}{" "}
                  <span className="shortlistUser">
                    {shortlistUsers.length}{" "}
                  </span>
                  {t("candidates for comparison")}
                  {t(` (Maximum 3)`)}
                </MediumTexts>
                <ButtonWrapper3>
                  <Button
                    cursor="pointer"
                    name={t("Compare")}
                    onClick={() => compareProfiles()}
                  ></Button>
                </ButtonWrapper3>
              </SectionWrapper3>
            ) : null}
          </div>
        )}
      </MainLayout>
    </div>
  );
};

export default TalentSearch;
