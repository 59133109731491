import jsPDF from "jspdf";
import { createSearchParams } from "react-router-dom";

export const TITLE_MAP = {
    "psychometric-analytics": "Psychometric Analytics",
    "personality-and-motivation": "Personality & Motivation",
    "work-interest": "Work Interest",
    "work-values": "Work Values",
    'employee-registered': 'Employee\'s Population (Registered)',
    'employee-not-registered': 'Employee\'s Population (Not Registered)',
    // internship pyramid
    'completed-internships': 'Internships (Completed)',
    'pending-internships': 'Internships (Pending)',
    'secured-internships': 'Internships (Secured)',
    'available-internships': 'Internships (Available)',
    // internship industry/sector
    'internship-industry-sector': 'Internship Industry/Sector $sector',
    // personality and motivation
    'emotional-stability': 'Emotional Stability $label',
    'extraversion': 'Extraversion $label',
    'openness-to-experience': 'Openness to Experience $label',
    'agreeableness': 'Agreeableness $label',
    'conscientiousness': 'Conscientiousness $label',
    // work values
    'mastery': 'Mastery $label',
    'mastery-drilldown': 'Mastery',
    'quality-of-life': 'Quality of Life $label',
    'quality-of-life-drilldown': 'Quality of Life',
    'fellowship': 'Fellowship $label',
    'fellowship-drilldown': 'Fellowship',
    'creating-value': 'Creating Value $label',
    'creating-value-drilldown': 'Creating Value',
    "intellectual-stimulation": "Intellectual Stimulation $label",
    "recognition": "Recognition $label",
    "achievement": "Achievement $label",
    "independence": "Independence $label",
    "variety": "Variety $label",
    "security": "Security $label",
    "way-of-life": "Way of Life $label",
    "surroundings": "Surroundings $label",
    "economic-return": "Economic Return $label",
    "altruism": "Altruism $label",
    "supervisory-relationship": "Supervisory Relationship $label",
    "associates": "Associates $label",
    "belonging": "Belonging $label",
    "family": "Family $label",
    "aesthetic": "Aesthetic $label",
    "creativity": "Creativity $label",
    "leadership": "Leadership $label",
    "protecting-the-planet": "Protecting the Planet $label",
    // employability
    'employability': 'Employability',
    'ideas-and-opportunities': "Ideas and Opportunities $label",
    'ideas-and-opportunities-drilldown': "Ideas and Opportunities",
    "spotting-opportunities": "Spotting Opportunities $label",
    "valuing-ideas": "Valuing Ideas $label",
    "vision": "Vision $label",
    "ethical-and-sustainable-thinking": "Ethical and Sustainable Thinking $label",
    'resources': "Resources $label",
    'resources-drilldown': "Resources",
    "self-efficacy": "Self-Efficacy $label",
    "financial-and-economic-literacy": "Financial and Economic Literacy $label",
    "mobilising-others": "Mobilising Others $label",
    "mobilising-resources": "Mobilising Resources $label",
    'into-action': "Into Action $label",
    'into-action-drilldown': "Into Action",
    "motivation-and-perseverance": "Motivation and Perseverance $label",
    "working-with-others": "Working with Others $label",
    "planning-and-management": "Planning and Management $label",
    "learning-through-experience": "Learning Through Experience $label",
    "coping-with-uncertainty-ambiguity-and-risk": "Coping with Uncertainty Ambiguity and Risk $label",
    "taking-the-initiative": "Taking the Initiative $label",
    "future-of-work": "Future Of Work",
    "cognitive": "Cognitive $label",
    "cognitive-drilldown": "Cognitive",
    "critical-thinking": "Critical Thinking $label",
    "planning-and-ways-of-working": "Planning and Ways of Working $label",
    "communication": "Communication $label",
    "mental-flexibility": "Mental Flexibility $label",
    "interpersonal": "Interpersonal $label",
    "interpersonal-drilldown": "Interpersonal",
    "mobilising-systems": "Mobilising Systems $label",
    "developing-relationships": "Developing Relationships $label",
    "effective-teamwork": "Effective Teamwork $label",
    "coaching-others": "Coaching Others $label",
    "self-leadership-action": "Self Leadership $label",
    "self-leadership": "Self Leadership $label",
    "self-leadership-drilldown": "Self Leadership",
    "self-awareness": "Self-Awareness $label",
    "self-management": "Self-Management $label",
    "entrepreneurship": "Entrepreneurship $label",
    "goal-achievement": "Goal Achievement $label",
    "digital": "Digital $label",
    "digital-drilldown": "Digital",
    "digital-fluency": "Digital Fluency $label",
    "software-use": "Software Use $label",
    "software-development": "Software Development $label",
    "understanding-digital-systems": "Understanding Digital Systems $label",
    "english-proficiency": "English Proficiency",
    "english-proficiency-drilldown": "English Proficiency $label",
    "comprehension": "Comprehension $label",
    "grammar": "Grammar $label",
    "student/{id}": "$name",

};

export const Utils = {
  intToString(value) {
    var suffixes = ["", "K", "M", "B", "T"];
    var suffixNum = Math.floor(("" + value).length / 3);
    var shortValue = parseFloat(
      (suffixNum != 0 ? value / Math.pow(1000, suffixNum) : value).toPrecision(
        2
      )
    );
    if (shortValue % 1 != 0) {
      shortValue = shortValue.toFixed(1);
    }
    return shortValue + suffixes[suffixNum];
  },
  replaceStringWithValues(str, ...params) {
    if (params && params.length) {
      params.forEach((item, i) => {
        const dRegex = new RegExp("\\{" + i + "\\}", "gm");
        str = str.replace(dRegex, item);
      });
    }
    return str;
  },
  getOrCreateLegendList(chart, legendContainer, justifyContent = "center") {
    let listContainer = legendContainer.querySelector("ul");
    if (!listContainer) {
      listContainer = document.createElement("ul");
      listContainer.style.display = "flex";
      listContainer.style.flexDirection = "row";
      listContainer.style.justifyContent = justifyContent;
      listContainer.style.margin = 0;
      listContainer.style.padding = 0;

      legendContainer.appendChild(listContainer);
    }
    return listContainer;
  },
  convertToCSV(arr) {
    const array = [Object.keys(arr[0])].concat(arr);
    return array
      .map((it) => {
        return Object.values(it).toString();
      })
      .join("\n");
  },
  appendAlpha(str, alpha) {
    var replace = "\\)";
    var re = new RegExp(replace, "g");
    return str.replace(re, `, ${alpha})`);
  },
  exportPDF(
    tableData = [],
    title = "Custom Report",
    fileName = "custom_report",
    customTitles = []
  ) {
    const unit = "pt";
    const size = "A4";
    const orientation = "portrait";

    const marginLeft = 40;
    const doc = new jsPDF(orientation, unit, size);

    doc.setFontSize(15);

    const headers =
      tableData && tableData.length ? Object.keys(tableData[0]) : [];

    const data =
      tableData && tableData.length
        ? tableData.map((item) => {
            return headers.map((x) => item[x]);
          })
        : [];
    let content = {
      startY: 50,
      head: [customTitles && customTitles.length ? customTitles : headers],
      body: data,
      styles: {
        halign: "center",
      },
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save(`${fileName}.pdf`);
  },
  titleCase(string) {
    var sentence = string.toLowerCase().split(" ");
    for (var i = 0; i < sentence.length; i++) {
      sentence[i] = sentence[i][0].toUpperCase() + sentence[i].slice(1);
    }
    document.write(sentence.join(" "));
    return sentence;
  },
  falsyNumberCaseHandle(value) {
    return +(value || 0);
  },
  convertAndRoundToPercentage(value) {
    const nanCase = value || 0;
    return +((nanCase && nanCase) * 100).toFixed(1);
  },
  getObjectValuesSum(arr) {
    return Object.values(arr).reduce(function (a, b) {
      return a + b;
    }, 0);
  },
  getLabels(obj) {
    return (obj && Object.keys(obj).filter((key) => obj[key])) || [];
  },
  commonStateUpdateLogic(data, totalSum, status, key, skipSumLogic = false) {
    if (skipSumLogic || ((totalSum || 0) > 0 && data !== "api error")) {
      if (status === 200) {
        return {
          proceed: true,
          stateObj: null,
        };
      }
      return {
        proceed: false,
        stateObj: key ? { [key]: { data: [], status } } : { data: [], status },
      };
    } else {
      return {
        proceed: false,
        stateObj: key
          ? { [key]: { data: [], status: "noDataFound" } }
          : { data: [], status: "noDataFound" },
      };
    }
  },
  getUrlWithParams() {
    let urlParams = "";
    if (typeof window !== "undefined") {
      urlParams = window.location.search;
    }
    return urlParams;
  },
  handleNavigate(navigate, path, extraParams = {}, state = {}) {
    const queryParams = Utils.getUrlWithParams().replace("?", "");

    const params = queryParams.split("&").reduce(function (prev, curr, i, arr) {
      var p = curr.split("=");
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
    for (var key in extraParams) {
      params[key] = extraParams[key];
    }
    const pathFinal = {
      pathname: path,
      search: createSearchParams(params).toString(),
    };
    navigate(pathFinal);
  },

  handleMainDashboardNavigate(navigate, path, extraParams = {}, state = {}) {
    const queryParams = Utils.getUrlWithParams().replace("?", "");
    console.log("query", queryParams);

    const params = queryParams.split("&").reduce(function (prev, curr, i, arr) {
      var p = curr.split("=");
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
    delete params["label"];
    delete params["department"];
    console.log("queip", params);
    for (var key in extraParams) {
      params[key] = extraParams[key];
    }
    const pathFinal = {
      pathname: path,
      search: createSearchParams(params).toString(),
    };
    console.log("pppp", pathFinal);
    navigate(pathFinal);
  },
  getParams(searchParams, extraParams = []) {
    const mandatoryParams = [
      { propertyKey: "selectedCampus", urlKey: "campus" },
      { propertyKey: "selectedFaculty", urlKey: "faculty" },
      { propertyKey: "selectedStudyProgram", urlKey: "study_program" },
      { propertyKey: "selectedYearOfStudy", urlKey: "year_of_study" },
      { propertyKey: "selectedUniversity", urlKey: "insti_name" },
      { propertyKey: "selectedScopeOfStudy", urlKey: "scope_of_study" },
    ];
    const params = {};
    [...mandatoryParams, ...extraParams].forEach((param) => {
      params[param.urlKey] = searchParams.get(param.propertyKey);
    });
    return params;
  },
  getPathName(path, searchParams) {
    let pathName = TITLE_MAP[path];
    const regexp = /\$([a-zA-Z0-9_]+)/g;
    const matches = pathName ? [...pathName.matchAll(regexp)] : [];
    if (matches && matches.length) {
      for (const match of matches) {
        const found = match && match[1];
        if (found) {
          const re = new RegExp(`\\$${found}`, "g");
          const foundSearchParam = searchParams.get(found);
          if (foundSearchParam && found) {
            pathName =
              found === "name"
                ? pathName.replace(re, `${foundSearchParam}`)
                : pathName.replace(re, `(${foundSearchParam}) `);
          } else {
            pathName = pathName.replace(re, "");
          }
        }
      }
    }
    return pathName;
  },
  getSearchParams(paramsArray, searchParams) {
    const outputParams = {};
    paramsArray.forEach((item) => {
      const value = searchParams[item];
      if (value) {
        outputParams[item] = value;
      }
    });
    return outputParams;
  },
  getArrayFromString(str) {
    const strSplit = str && str.split(",");
    return strSplit && strSplit.length
      ? strSplit.map((x) => (typeof x === "string" ? +x : x))
      : [];
  },
};
