import { Modal } from "react-bootstrap";
// import styled from "styled-components";


function AlertModal(props) {
  return (
    <div>
      <Modal show={props.show} onHide={props.close} size="lg" centered>
        <div style={{ padding: '9px' }}>{props.content}</div>
      </Modal>
    </div>
  );
}

export default AlertModal;
