export const API_ROUTES = {

  GET_COMPANY_EMPLOYEE_MANAGMENT: "company/api/company-employees/{}/",
  GET_DRILLDOWN_DATA: "/dashboard/university/analytical/results/assessment-get-by-type/",
  UPDATE_COMPANY_EMPLOYEE_DETAILS: "company/api/company-employee/{}/",
  CREATE_COMPANY_EMPLOYEE: "company/api/company-employees/{}/",
  IMPORT_BULK_EMPLOYEE: "company/api/validate-import-company-employee/{}/",
  IMPORT_BULK_EMPLOYEE_AFTER_VALIDATION: "company/api/import-company-employee/{}/",
  DOWNLOAD_EMPLOYEE: "company/api/download-employee-list/{}/",
  SINGLE_REMINDER: "company/api/send-single-reminder/{}/",
  MULTIPLE_REMINDERS: "company/api/send-multiple-reminders/{}/",

};

