import React from 'react'
import styled from "styled-components"
import { Colors } from "../../utilities/colors";
import { Modal } from "react-bootstrap";

export const ModalHeader = styled.div`
  height: 136px;
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
`;

export const ModalHeaderText = styled.div`
  position: absolute;
  // left: 51px;
  margin-left: 10px;
  top: 44px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
`;
export const ModalHeaderTextTwo = styled.div`
  position: absolute;
  // left: 51px;
  margin-left: 10px;
  top: 72px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 35px;
  color: #000000;
`;

export const CustomModal = styled(Modal)`
.modal-dialog {
    max-width: 1400px;
  }
`;
export default function SubscriptionDetailModal (props) {
    return (
        <div>
          <CustomModal show={props.show} onHide={props.close} backdrop="static">
          <div style={{ padding: '9px' }}>

            <button type="button" class="close" data-dismiss="modal" aria-label="Close"  
            onClick={props.close} style={{
            position: "absolute",
            top: "4%",
            left: "97%",
            border: "none",
            fontSize: "27px",
            }}>

            <span aria-hidden="true" style={{marginRight: "-20px",position: "relative",right: "20px",bottom:"20px"}}>&times;</span>
            </button>
              <ModalHeader></ModalHeader>
              <ModalHeaderText>{props.title}</ModalHeaderText>
              <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
              {props.content}
            </div>
          </CustomModal>
        </div>
      );
}