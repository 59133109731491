import { Row, Col } from "react-bootstrap";
import {
  OceanDescription,
  OceanLabel,
  OceanSlider,
} from "./commonStyles";
import { StudentAd2Card } from "./ad2Card";
import { useState } from "react";
import { useTranslation } from "react-i18next";
const personalityAspectsConstants = [
  {
    key: 0,
    title: "Withdrawal",
    description:
      "Individuals who score high in withdrawal tend to be more resilient and cope better with stress. They are less likely to feel discouraged, overwhelmed, or excessively self-conscious. They are also less prone to depression and anxiety. On the other hand, individuals who score low in withdrawal may have difficulties dealing with social situations or changes in their environment, and may need extra support in building their confidence and resilience.",
  },
  {
    key: 1,
    title: "Volatility",
    description:
      "Individual's tendency to experience unpredictable and intense emotions. Individuals who score high in Volatility tend to have a stable and consistent emotional state and can regulate their emotions effectively. On the other hand, individuals who score low in Volatility tend to be more emotionally reactive, experiencing frequent mood swings, and having difficulties with impulse control. They are also prone to irritability, anxiety, and anger outbursts. It is important to note that some level of emotional volatility is a normal part of the human experience, and only extreme levels may cause significant distress and interfere with daily functioning.",
  },
  {
    key: 2,
    title: "Enthusiasm",
    description:
      "Enthusiasm refers to an individual's disposition towards expressing positive emotions such as joy, excitement, and happiness. People who score high in enthusiasm are typically outgoing, sociable, and energetic. They are often seen as lively and fun-loving, and they enjoy being around other people. They tend to have a positive outlook on life and are not afraid to show their emotions. Conversely, those who score low in enthusiasm may be more reserved and introverted and may not express their emotions as openly. However, it is important to note that excessive focus on enthusiasm and excitement can lead to impulsivity and a lack of consideration for consequences.",
  },
  {
    key: 3,
    title: "Assertiveness",
    description:
      "Assertiveness is a personality trait that describes individuals who are confident, self-assured, and motivated to achieve social power and status. They are not afraid to take charge of situations and often take a leadership role in group settings. High scorers on assertiveness tend to be provocative and speak their minds, even in challenging situations. However, it's important to note that assertiveness is not the same as aggression. Individuals with high assertiveness can still be respectful of others and work collaboratively towards common goals. Low scorers on assertiveness may be more reserved and avoid confrontation or leadership roles.",
  },
  {
    key: 4,
    title: "Aesthetics Openness",
    description:
      "Aesthetic openness is an aspect of the broader trait of openness to experience. Individuals who score high in this aspect tend to have a strong appreciation for beauty in various forms, such as art, music, nature, and literature. They have a rich inner world and are often drawn to activities that allow them to express themselves creatively, such as writing, painting, or playing an instrument. These individuals may also have a tendency towards daydreaming and fantasizing, and they are often able to immerse themselves deeply in their own thoughts and emotions. Overall, high scorers on this scale value creativity, imagination, and self-expression. On the other hand, those who score low in Aesthetic Openness may have a more limited interest in art and may prefer more familiar or traditional forms of expression.",
  },
  {
    key: 5,
    title: "Intellect",
    description:
      "Individuals who score high in the aspect of Intellect are characterized by their intellectual curiosity and love of exploring complex and challenging ideas. They enjoy engaging in deep and analytical thinking and are often reflective about their experiences. They are open to new perspectives and are curious about the world around them, and often seek out opportunities for learning and growth. On the other hand, individuals who score low in Intellect may be more practical and prefer to focus on concrete, tangible matters rather than abstract ideas and theories.",
  },
  {
    key: 6,
    title: "Compassion",
    description:
      "Compassion refers to the ability and willingness to empathize with others and show concern for their emotional and physical well-being. Those who score high in compassion are typically warm, kind, and nurturing, and are sensitive to the needs and feelings of others. They are often motivated to help others and may pursue careers in fields such as healthcare, social work, or counselling. Conversely, low scores in compassion may indicate a lack of concern for others, or an inability to understand or connect with others on an emotional level. However, it is important to note that excessive focus on compassion and care for others can lead to neglect of one's own needs and well-being.",
  },
  {
    key: 7,
    title: "Politeness",
    description:
      "Individuals who score high in politeness are typically empathetic and cooperative, striving to be considerate and respectful towards others. They are sensitive to social norms and etiquette and prioritize the needs and desires of others. They tend to be patient, calm, and diplomatic, avoiding conflict and focusing on finding peaceful solutions. High scores on politeness can indicate an individual who is courteous, well-mannered, and socially skilled, although excessively high scores can lead to a tendency to be overly compliant or people-pleasing, at the expense of one's own needs and interests. Low scores on politeness can suggest a person who is more self-focused, less aware of social conventions, and may struggle with interpersonal relationships. However, it is important to note that excessive focus on politeness and consideration can lead to a tendency to avoid conflict or to be overly accommodating.",
  },
  {
    key: 8,
    title: "Industriousness",
    description:
      "Industriousness refers to the personality trait of being diligent and hardworking. People who score high in industriousness are typically very goal-oriented, disciplined, and efficient. They have a strong desire to achieve their goals and are willing to work hard to make them happen. This trait is also linked to ‘grit’ which is a combination of passion and perseverance for long-term goals. Industrious individuals tend to be reliable and productive, and they often take pride in their accomplishments. On the other hand, those who score low in industriousness may struggle with procrastination, lack of motivation, and a tendency to avoid hard work. It is also important to note that excessive focus on industriousness and grit can lead to burnout and neglect of other important aspects of life such as self-care and relationships.",
  },
  {
    key: 9,
    title: "Orderliness",
    description:
      "People who score high in orderliness have a strong desire for structure and organization in their daily lives. They thrive on predictability and routine and often have a specific way of doing things that they adhere to strictly. They have a tendency to keep their environment neat and tidy and are often uncomfortable in chaotic or cluttered settings. High scorers on orderliness also tend to be detail-oriented and have a perfectionistic streak, often setting high standards for themselves and others. However, it is important to note that excessive focus on orderliness and perfectionism can lead to rigidity and difficulty adapting to change.",
  },
];
export default function PersonalityAspectsChart(data) {
  const { t} = useTranslation();
  const [visible0, setVisible0] = useState(false);
  const [visible1, setVisible1] = useState(false);
  const [visible2, setVisible2] = useState(false);
  const [visible3, setVisible3] = useState(false);
  const [visible4, setVisible4] = useState(false);
  const [visible5, setVisible5] = useState(false);
  const [visible6, setVisible6] = useState(false);
  const [visible7, setVisible7] = useState(false);
  const [visible8, setVisible8] = useState(false);
  const [visible9, setVisible9] = useState(false);
  const handleVisible0 = () => setVisible0(!visible0);
  const handleVisible1 = () => setVisible1(!visible1);
  const handleVisible2 = () => setVisible2(!visible2);
  const handleVisible3 = () => setVisible3(!visible3);
  const handleVisible4 = () => setVisible4(!visible4);
  const handleVisible5 = () => setVisible5(!visible5);
  const handleVisible6 = () => setVisible6(!visible6);
  const handleVisible7 = () => setVisible7(!visible7);
  const handleVisible8 = () => setVisible8(!visible8);
  const handleVisible9 = () => setVisible9(!visible9);
  const getLevel = (val) => {
    if (val < 25) {
      return "low";
    } else if (val < 75) {
      return "moderate";
    } else {
      return "high";
    }
  };
  const finalData = data["data"];
  return (
    <>
      <StudentAd2Card title="Personality Aspects" sm="12" lg="12">
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible0}>
              {t(personalityAspectsConstants[0].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Withdrawal"])}
              value={finalData["Withdrawal"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible0 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[0].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible1}>
              {t(personalityAspectsConstants[1].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Volatility"])}
              value={finalData["Volatility"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible1 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[1].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible2}>
              {t(personalityAspectsConstants[2].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Enthusiasm"])}
              value={finalData["Enthusiasm"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible2 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[2].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible3}>
              {t(personalityAspectsConstants[3].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Assertiveness"])}
              value={finalData["Assertiveness"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible3 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[3].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible4}>
              {t(personalityAspectsConstants[4].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Aesthetics Openness"])}
              value={finalData["Aesthetics Openness"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible4 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[4].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible5}>
              {t(personalityAspectsConstants[5].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Intellect"])}
              value={finalData["Intellect"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible5 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[5].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible6}>
              {t(personalityAspectsConstants[6].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Compassion"])}
              value={finalData["Compassion"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible6 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[6].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible7}>
              {t(personalityAspectsConstants[7].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Politeness"])}
              value={finalData["Politeness"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible7 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[7].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible8}>
              {t(personalityAspectsConstants[8].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Industriousness"])}
              value={finalData["Industriousness"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible8 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[8].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <OceanLabel onClick={handleVisible9}>
              {t(personalityAspectsConstants[9].title)}
            </OceanLabel>
          </Col>
          <Col>
            <OceanSlider
              level={getLevel(finalData["Orderliness"])}
              value={finalData["Orderliness"] || 0}
              aria-label="Slider"
              valueLabelDisplay="auto"
            />
          </Col>
        </Row>
        {visible9 && (
          <Row>
            <Col>
              <OceanDescription>
                {t(personalityAspectsConstants[9].description)}
              </OceanDescription>
            </Col>
          </Row>
        )}
      </StudentAd2Card>
    </>
  );
}
