import styled from "styled-components";
import Button from '../../components/button/button';

import { Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import { useNavigate, useLocation } from "react-router-dom";


const CardWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(props) => (props.bg ? props.bg : "#f3f3f3")};
  height: 186px;
  margin-right: 10%;
  border-radius: 15px;
  // cursor: pointer;
  width: 100%;
  @media (max-width: 900px) {
    max-width: 320px;
    margin-right: 0%;
  }
`;

const CardText = styled.p`
  font-size: 22px;
  font-style: normal;
  font-weight: 800;
  letter-spacing: 0em;
  text-align: center;
  color: #000000;
  span {
    font-size: 14px;
  }
`;
const CardDescription = styled.p`
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 0em;
  text-align: center;
  color: #504f8c;
  span {
    font-size: 14px;
  }
`;
const ImgContainer = styled.div`
  width: 175px;
`;

const AboutImage = styled.img`
  height: 200px;
  width: 100%;
  @media (max-width: 900px) {
    height: 100px;
  }
`;

const ContentWrapper = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const ButtonWrapper = styled(FlexWrapper)`
  justify-content: center;
  margin-top: 40px;
  @media (max-width: 600px) {
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    justify-content: space-between;
  }
`;
const CardLabel = styled.h3`
font-size: 20px;
font-weight: 200px;
`;
export default function InternTypeCards(props) {
  const navigate = useNavigate();

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const selectedIds = queryParams.get("selectedIds");
  console.log(selectedIds, "SELECTED FRM ELIGIBIILITY")
  const onContinue = () =>{
  if(props.originType ==="on-going"){
    navigate("/internship/create-internship/new-internship", { state: { typeOrigin: 'on-going' , id: selectedIds} }) 
    console.log(props.originType, "InternType")
  } else{
    navigate("/internship/create-internship/new-internship", { state: { typeOrigin: 'completed', id: selectedIds } }) 
    console.log(props.originType, "InternType")

  }
   
  }
  return (
    <CardWrapper bg={props.bg} onClick={props.onSelect}>
      <ContentWrapper>
        <ImgContainer>
          <AboutImage src={props.cardImage} alt="Cover image" />
        </ImgContainer>
        <CardText>
          {props.cardName}
        </CardText>
        <br />
        <CardDescription>
        <CardLabel>{props.descriptions}</CardLabel>
        </CardDescription>
          
        <ButtonWrapper>
          <Button width="198px" onClick={onContinue} name={("Continue")} />
          </ButtonWrapper>
          
        
      </ContentWrapper>
    </CardWrapper>
  );
}


// import styled from "styled-components";
// import { Row, Col } from "react-bootstrap";
// import { useTranslation } from "react-i18next";

// const CardWrapper = styled.div`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   background-color: ${(props) => (props.bg ? props.bg : "#f3f3f3")};
//   height: 186px;
//   margin-right: 10%;
//   border-radius: 15px;
//   cursor: pointer;
//   width: 100%;
//   @media (max-width: 900px) {
//     max-width: 320px;
//     margin-right: 0%;
//   }
// `;
// const CardText = styled.p`
//   display: grid;
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 600;
//   letter-spacing: 0em;
//   text-align: center;
//   color: #504f8c;
//   span {
//     font-size: 14px;
//   }
// `;
// const AboutContentWrapper = styled.div`
//   position: relative;

//   align-itmes: center;
//   @media (max-width: 900px) {
//     top: -10px;
//   }
// `;
// const ImgContainer = styled.div`
//   width: 175px;
//   position: relative;
// `;

// const AboutImage = styled.img`
//   position: absolute;
//   height: 200px;
//   top: -5rem;
//   left: 0;
//   width: 100%;
//   z-index: 0;
//   @media (max-width: 900px) {
//     height: 100px;
//     left: 5px;
//     width:auto;
//   }
// `;


// export default function InternTypeCards(props) {
//   return (
//     <CardWrapper bg={props.bg} onClick={props.onSelect}>
//       <AboutContentWrapper>
//         <Row>
//           <Col lg="4">
//             <ImgContainer>
//               <AboutImage src={props.cardImage} alt="Cover image" />
//             </ImgContainer>
//           </Col>
//           <Col lg="8">
//             <CardText>
//               {(props.cardName)}
//               <span>{(props.descriptions)}</span>
//             </CardText>
//           </Col>
//         </Row>
//       </AboutContentWrapper>
//     </CardWrapper>
//   );
// }
