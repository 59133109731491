import MainLayout from "../../layouts/mainLayout/mainLayout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Form from 'react-bootstrap/Form';
import RadioInput from "../../components/radioInput/radioInput";
import { Controller, useWatch } from "react-hook-form";
import Input from "../../components/input/input";
import QuestionSection1 from "../../components/createInternshipModule/internshipModuleQuestion";
import QuestionSection2 from "../../components/createInternshipModule/internshipModuleQuestion2";
import QuestionSection3 from "../../components/createInternshipModule/internshipModuleQuestion3";
import Button from "../../components/button/button";
import { internshipModule } from "./staticContentInternship";
import {  useForm, FormProvider  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./staticContentInternship";
import { initialStates, iniialEligibleState } from "./staticContentInternship";
import { Row, Col } from "react-bootstrap";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import { toast } from "react-toastify";
import CloseIcon from "../../assets/close-80.png";
import Dropdown from "../../components/dropdown/dropdown";
import dummyApi from "./dummyApiLoe";
import SkillCard from "../../components/skillCard/skillCard";
import { uploadObject } from "../../services/apiCalls";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getQuestionAnswers , postInternshipModule, getInternshipModuleByID, editEligibility, getComments} from "../../services/apiCalls";
import { eligibility } from "./staticContentInternship";
import { TablePagination } from "@mui/base";
import moment from "moment";
import ErrorField from "../../components/errorField/errorField";

const NewPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 50px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;

const SectionWrapper = styled.section``;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 40px
}
`;
const Description2 = styled.p`
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;
const BreakSection1 = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 20px
}
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const InputWrapper = styled.div`
margin-top: 20px;
`
const CheckboxGroup = styled.div`
margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
> input{
    width: 30px,
    height: 30px, 
    border: 2px solid #000, // Specify the border color
    borderRadius: 4px
}
`;

const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding-top: 14px;
  color: ${Colors.labelText};
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;

export const UploadBtn = styled.button`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 30px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;

const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;

const Uploader = styled.div`
  display: flex;
  flex-direction: row;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  >label{
    margin-left:30px;
    margin-top: 25px;
  }
  `;

  const SmallText = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`;
const UploadFileBox = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: center;
  height:36px;
  width: 240px;
  border-radius:30px;
  padding:8px 16px;
  background:#F3F3F3;
  margin:auto;
  margin-top:12px;
`;
 
const FileNameWrapper = styled.div`
  width:160px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important
`;
const Icon = styled.img`
  margin: 5px;
  width:12px;
  height:12px;
  z-index:1000;
`;

const UploadFileWrapper = styled.label`
  position: relative;
  cursor: pointer;
  > input {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
`;
const UploadFileText = styled.div`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 30px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
text-align: center;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FileWrapper = styled(FlexWrapper)`
 margin: 10px 0;
 flex-direction: row;
 gap: 5px;
`;

const WarningLable = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
`;
const WarningDescription = styled.li`
  font-weight: 500;
  font-size: 20px;
  margin-left: 20px;
  margin-bottom: 50px;
  color: ${props => (props.overallEligibility ? "black" : "red")};`;

  const grey = {
    50: "#F3F6F9",
    100: "#E7EBF0",
    200: "#E0E3E7",
    300: "#CDD2D7",
    400: "#B2BAC2",
    500: "#A0AAB4",
    600: "#6F7E8C",
    700: "#3E5060",
    800: "#2D3843",
    900: "#1A2027",
  };
  const blue = {
    200: "#A5D8FF",
    400: "#3399FF",
  };
  const Root = styled("div")(
    ({ theme }) => `
    table {
      font-family: IBM Plex Sans, sans-serif;
      font-size: 0.875rem;
      border-collapse: collapse;
      width: 100%;
    }
  
    td,
    th {
      border: 1px solid ${grey[200]};
      text-align: left;
      padding: 6px;
    }
  
    th {
      background-color: ${grey[100]};
    }
    `
  );
  const CustomTablePagination = styled(TablePagination)(
    ({ theme }) => `
    & .MuiTablePaginationUnstyled-spacer {
      display: none;
    }
    & .MuiTablePaginationUnstyled-toolbar {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
  
      @media (min-width: 768px) {
        flex-direction: row;
        align-items: center;
      }
    }
    & .MuiTablePaginationUnstyled-selectLabel {
      margin: 0;
    }
    & .MuiTablePaginationUnstyled-select {
      padding: 2px;
      border: 1px solid ${grey[200]};
      border-radius: 50px;
      background-color: transparent;
      &:hover {
        background-color: ${grey[50]};
      }
      &:focus {
        outline: 1px solid ${blue[200]};
      }
    }
    & .MuiTablePaginationUnstyled-displayedRows {
      margin: 0;
  
      @media (min-width: 768px) {
        margin-left: auto;
      }
    }
    & .MuiTablePaginationUnstyled-actions {
      padding: 2px;
      border: 1px solid ${grey[200]};
      border-radius: 50px;
      text-align: center;
    }
    & .MuiTablePaginationUnstyled-actions > button {
      margin: 0 8px;
      border: transparent;
      border-radius: 2px;
      background-color: transparent;
      &:hover {
        background-color: ${grey[50]};
      }
      &:focus {
        outline: 1px solid ${blue[200]};
      }
    }
    `
  );
export default function ViewInternshipModule(){
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState("")
    const {
      control,
      watch,
      reset: resetForm,
      setError: setErrorCustom,
      setValue: setInternshipValue,
      handleSubmit,
      formState: { errors: errorsQuestion },
    } = useForm({
      defaultValues: { ...iniialEligibleState, ...initialStates },
      resolver: yupResolver(schema),
      mode: "onSubmit",
    });

    const formVal = watch();
    console.log('Org Code:', org_code);
   
    
    const location = useLocation();

  const internship = location.state?.from;  
  const [questionsList, setQuestionsList] = useState([]);
  const [answersList, setAnswersList] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [theArray, setTheArray] = useState([]);
  const emptyRows =
  page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;

  const [commentsDetails, setCommentDetails] = useState();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

useEffect(() => {
  // Define an async function to fetch data
  const fetchData = async () => {
    const { data } = await getQuestionAnswers();
    if (data) {
      setQuestionsList(data?.questions);
      setAnswersList(data?.answers);
    }
  };

  // Call the fetchData function
  fetchData();
}, []);
console.log(answersList, "DATA RETRIEVEDDDD")

 
const handleDeclaration = () =>{
  const isChecked = watch("terms_condition");
  setInternshipValue("terms_condition", !isChecked);
  errorsQuestion.terms_condition="";
};


const handleBack = () => {
        
  navigate(-1);
};
// const updateURL = () =>{
//   const url = formVal.file_name

// // Split the URL by '/' to get the parts
// const parts = url.split('/');

// // The file name will be the last part of the array
// const fileName = parts[parts.length - 1];

// console.log(fileName, "UPDATED");
// }
// useEffect(()=>{
// updateURL();
// },[])
// const handleOnSubmit = async  () => {
//   const formData = {
   
//     organization: formVal.organization,
//     min_10_weeks: formVal.min_10_weeks,
//     min_500_SKM1_Diploma: formVal.min_500_SKM1_Diploma,
//     // actual_allowance_SKM1_Diploma: formval.monthly_allowance_1,
//     min_600_ProfCert_Master: formVal.min_600_ProfCert_Master,
//     // actual_allowance_ProfCert_Master: formval.monthly_allowance_2,
//     contact_name1: formVal.contact_full_name_1,
//     contact_email1: formVal.contact_email_1,
//     contact_mob_code1: formVal.contact_mob_code1,
//     contact_mob1: formVal.contact_mobile_1,
//     contact_name2: formVal.contact_full_name_2,
//     contact_email2: formVal.contact_email_2,
//     contact_mob_code2: formVal.contact_mob_code2,
//     contact_mob2: formVal.contact_mobile_2,
//     // file_path: formVal.file_name,
//     // file_name: formVal.file_name,
//     // status: ,
//     terms_conditions: formVal.terms_condition,
//     responses:
//       responses
  
    
//   }
//   try {
//     const { data } = await postInternshipModule(formData);
//     if (data?.id) {
//       // Assuming 'id' is present in the response data when successful
//       toast.success("Internship module submitted!", {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//       if(internship === "add-intern"){
//         navigate("/internships/internship-module-eligibility-check", {state: {createdInternshipModule: "success"}})
//       } else{
//         navigate("/loe/internship-module-eligibility-check", {state: {createdInternshipModule: "success"}})
//       }
//       // Optionally, you can redirect to another page or take other actions upon success
//     } else {
//       toast.error("Failed to create internship module.", {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     }
//   } catch (error) {
//     console.error("An error occurred:", error);
//     toast.error("An error occurred while submitting the form.", {
//       position: toast.POSITION.TOP_RIGHT,
//     });
//   } finally {
//     setLoading(false);
//   }
// };
    const id = location?.state?.id;
    const [internModuledetails, setInternModuleDetails] = useState({});
    const [orgId, setOrgId] = useState("");
    const [retrievedResponses, setRetrievedResponses] = useState([]);
    const [response_code1, setResponse_code1] = useState([]);
    const [response_code1_Other, setResponse_code1_Other] = useState();
    const [response_code2, setResponse_code2] = useState([]);
    const [response_code3, setResponse_code3] = useState([]);
    const [response_code4, setResponse_code4] = useState([]);
    const [response_code5, setResponse_code5] = useState([]);
    const getInternshipModuleDetail = async () =>{
        const {data} = await getInternshipModuleByID(id);
        if(data){
            setInternModuleDetails(data);
            setRetrievedResponses(data?.responses);
            setOrgId(data?.organization)
        }
    }
    console.log("QUEST CODE 1:", internModuledetails);
    useEffect(()=>{
        getInternshipModuleDetail();

        
    },[id]);

    const retrieveComments = async () =>{
      const {data} = await getComments(id)
        if(data){
          setTheArray(data);
        }
      }
      
    const comp =[
      {
        question_code: 1,
        answer_code: 9,
        others: "TEST HELOOIDH"
      },
      {
        question_code: 1,
        answer_code: 7
      },
      {
        question_code: 1,
        answer_code: 3
      }
    ]
    useEffect(()=>{
      retrieveComments();
    },[id])

    console.log(formVal.student_objectives, "STU OBJ")
    useEffect(() => {
      // This useEffect will run whenever retrievedResponses changes
        setInternshipValue("question_1", internModuledetails.min_10_weeks === 1 ?"Yes" : "No")
        setInternshipValue("question_2",internModuledetails.min_500_SKM1_Diploma === 1 ? "Yes" : "No");
        setInternshipValue("monthly_allowance_1", internModuledetails.actual_allowance_SKM1_Diploma)
        setInternshipValue("question_3", internModuledetails.min_600_ProfCert_Master  === 1 ? "Yes" : "No");
        setInternshipValue("monthly_allowance_2", internModuledetails.actual_allowance_ProfCert_Master);

        if(retrievedResponses){
          const question_1_response = retrievedResponses?.filter(response => response.question_code === 1);
          const question_2_response = retrievedResponses?.filter(response => response.question_code === 2);
          const question_3_response = retrievedResponses?.filter(response => response.question_code === 3);
          const question_4_response = retrievedResponses?.filter(response => response.question_code === 4);
          const question_5_response = retrievedResponses?.filter(response => response.question_code === 5);


          if(question_1_response){
            const hasOthersAnswer = question_1_response.some(answer => {
              const answerDesc = answersList.find(ans => ans.answer_code === answer.answer_code)?.answer_desc;
              return answerDesc === "Others (please specify)";
          });
          console.log(hasOthersAnswer, "OTHERS EXIST")
            if(hasOthersAnswer){
              const respond_1 = question_1_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code,
                others: item.others
              }));
              setInternshipValue("student_objectives", respond_1);
            }else{
              const respond_1 = question_1_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code
              }));
              setInternshipValue("student_objectives", respond_1);
            }
          }

          if(question_2_response){
            const hasOthersAnswer = question_2_response.some(answer => {
              const answerDesc = answersList.find(ans => ans.answer_code === answer.answer_code)?.answer_desc;
              return answerDesc === "Others (please specify)";
          });
            if(hasOthersAnswer){
              const respond_2 = question_2_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code,
                others: item.others
              }))
              setInternshipValue("company_objectives", respond_2)
            }else{
              const respond_2 = question_2_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code
              }))
              setInternshipValue("company_objectives", respond_2)
            }
          }

          if(question_3_response){
            const hasOthersAnswer = question_3_response.some(answer => {
              const answerDesc = answersList.find(ans => ans.answer_code === answer.answer_code)?.answer_desc;
              return answerDesc === "Others (please specify)";
          });

            if(hasOthersAnswer){
              const respond_3 = question_3_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code,
                others: item.others
              }))
              setInternshipValue("activities_offered", respond_3)
            }else {
              const respond_3 = question_3_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code
              }))
              setInternshipValue("activities_offered", respond_3)
            }
          }

          if(response_code4){
            const hasOthersAnswer = question_4_response.some(answer => {
              const answerDesc = answersList.find(ans => ans.answer_code === answer.answer_code)?.answer_desc;
              return answerDesc === "Others (please specify)";
          });

            if(hasOthersAnswer){
              const respond_4 = question_4_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code,
                others: item.others
              }))
              setInternshipValue("learning_outcome", respond_4)
            }else {
              const respond_4 = question_4_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code
              }))
              setInternshipValue("learning_outcome", respond_4)
            }
          }

          if(response_code5){
            const hasOthersAnswer = question_4_response.some(answer => {
              const answerDesc = answersList.find(ans => ans.answer_code === answer.answer_code)?.answer_desc;
              return answerDesc === "Other (please specify)";
          });

            if(hasOthersAnswer){
              const respond_5 = question_5_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code,
                others: item.others
              }))
              setInternshipValue("other_allowance", respond_5)
            }else {
              const respond_5 = question_5_response.map(item => ({
                question_code: item.question_code,
                answer_code: item.answer_code
              }))
              setInternshipValue("other_allowance", respond_5)
            }
          }
        }
        if(internModuledetails.file_path){
          let obj = { file_path:  internModuledetails.file_path };
          setInternshipValue("upload", [obj])
        }
        if(internModuledetails.terms_conditions){
          setInternshipValue("terms_condition", internModuledetails.terms_conditions)
        }
    }, [internModuledetails, retrievedResponses]);
    
console.log(formVal.company_objectives, "COMP")
    const handleStudentCheck = (type, question_code, answer_code, answer_desc) =>{
      let arr = [...formVal[type]];
      const keyWord1 = "question_code";
      const keyWord2 = "answer_code";
     
  // Check if the maximum limit of 3 selections has been reached
  if (arr.length >= 3 && !arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code)) {
    toast.error("Maximum selection of 3 answers reached!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }

  const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

  if (isChecked) {
    arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
  } else {
      const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
      arr.push(obj);
  }

  // Update the state with the selected items
  setInternshipValue(type, arr);
     if(type === "student_objectives"){
      errorsQuestion.student_objectives="";
     }
}

const handleCompanyCheck = (type, question_code, answer_code) =>{
  let arr = [...formVal[type]];
  const keyWord1 = "question_code";
  const keyWord2 = "answer_code";

// Check if the maximum limit of 3 selections has been reached
if (arr.length >= 3 && !arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code)) {
toast.error("Maximum selection of 3 answers reached!", {
  position: toast.POSITION.TOP_RIGHT,
});
return;
}

const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

if (isChecked) {
arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
} else {

  const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
  arr.push(obj);

}

setInternshipValue(type, arr);
if(type === "company_objectives"){
errorsQuestion.company_objectives="";
}
}

const handleSingleCheck = (type, question_code, answer_code) =>{
let arr = [...formVal[type]];
  const keyWord1 = "question_code";
  const keyWord2 = "answer_code";

const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

if (isChecked) {
arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
} else {
  const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
  arr.push(obj);
}

// Update the state with the selected items
setInternshipValue(type, arr);
if(type === "activities_offered"){
errorsQuestion.activities_offered="";
}
}

const handleLearningCheck = (type, question_code, answer_code) =>{
let arr = [...formVal[type]];
const keyWord1 = "question_code";
const keyWord2 = "answer_code";

// Check if the maximum limit of 3 selections has been reached
if (arr.length >= 3 && !arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code)) {
toast.error("Maximum selection of 3 answers reached!", {
position: toast.POSITION.TOP_RIGHT,
});
return;
}

const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

if (isChecked) {
arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
} else {
const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
arr.push(obj);
}

setInternshipValue(type, arr);
if(type === "learning_outcome"){
errorsQuestion.learning_outcome="";
}
}

const handleOtherAllowance = (type, question_code, answer_code) =>{
let arr = [...formVal[type]];
const keyWord1 = "question_code";
const keyWord2 = "answer_code";

const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

if (isChecked) {
arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
} else {
const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
arr.push(obj);
}

// Update the state with the selected items
setInternshipValue(type, arr);
if(type === "other_allowance"){
errorsQuestion.other_allowance="";
}
}

const handleTextOth = (name, question_code, answer_code, answer_desc, val) =>{
  const existingObjects = formVal[name].filter(
    (item) => item.question_code === question_code && item.answer_code !== answer_code
  );

  const obj = { question_code, answer_code, others: val };

  setInternshipValue(name, [...existingObjects, obj]);
}

const uploadFile = async (event) => {
  const formdata = new FormData();

  let maxFileSize = 1024 * 1024 * 5;

  if(formVal.upload.length > 0){
    toast.error("You can only upload one file", {
      position: toast.POSITION.TOP_RIGHT,
    });
  }else if (event.target.files[0].size > maxFileSize) {
    toast.error("File size exceeds maximum limit", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
      formdata.append(
          "obj_content",
          event.target.files[0],
          event.target.files[0].name
      );
      const { data, status } = await uploadObject(formdata);
      if (status === 200) onUploaded(data);
  }
};
const onUploaded = (data) => {
  const { status, message } = data;
    if (status === 1) {
      let obj = { file_path: data.data.obj_content };
      // const arr = [...formVal.file_name];
      // arr.push(obj);
      // setInternshipValue("upload", arr);
      // setInternshipValue("file_name", arr)
      setInternshipValue("upload", [obj]);
      setInternshipValue("file_name", [obj]);
    }else{
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      })
    }


  // const { status, message } = data;
  //   if (status === 1) {
  //     let obj = { file_path: data.data.obj_content };
  //     const arr = [...formVal.file_name];
  //     arr.push(obj);
  //     setInternshipValue("upload", arr);
  //   }else{
  //     toast.error(message, {
  //       position: toast.POSITION.TOP_RIGHT,
  //     })
  //   }
};

var fileName = "";
var ext = "";

const getfileName = (file_path) => {

  const parts = file_path.split('/');
  const fileNameWithExtension = parts[parts.length - 1];

  // Split the file name by '.' to separate the extension
  const fileNameParts = fileNameWithExtension.split('.');
   fileName = fileNameParts[0]; // The file name without extension
   ext = fileNameParts[1];
};
const onDeleteFiles = (index) => {
    const arr = [...formVal.upload];
    const newArr = arr.filter((skill, ind) => ind !== index);
    setInternshipValue("upload", newArr);

    // setInternshipValue("file_name", "");
};
console.log(formVal.student_objectives, "SELECTED")
    console.log(internModuledetails.organization)
    const [question1Eligible, setQuestion1Eligible] = useState(true);
    const [question2Eligible, setQuestion2Eligible] = useState(true);
    const [question3Eligible, setQuestion3Eligible] = useState(true);
    const [question4Eligible, setQuestion4Eligible] = useState(true);
    // const location = useLocation();
    const origin = location.state?.origin;
    const successInternshipModule = location.state?.createdInternshipModule;
    // const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
    const overallEligibility = (
      (question1Eligible && question2Eligible) &&  // Both first two questions must be "Yes"
      (!question3Eligible || question4Eligible)
    )
    

    const [eligibleData, setEligibleData] = useState(formVal);

  
   const editEligible = async () =>{
    const {data} = await editEligibility(internModuledetails.id,{
      // ...formVal,
      organization: orgId,
      min_10_weeks: formVal.question_1 === "Yes"? 1: 0,
      min_500_SKM1_Diploma: formVal.question_2 === "Yes"? 1: 0,
      actual_allowance_SKM1_Diploma: formVal.monthly_allowance_1,
      min_600_ProfCert_Master: formVal.question_3 === "Yes"? 1: 0,
      actual_allowance_ProfCert_Master: formVal.monthly_allowance_2,
      responses: [
        ...formVal.student_objectives,
        ...formVal.company_objectives,
        ...formVal.learning_outcome,
        ...formVal.activities_offered,
        ...formVal.other_allowance,
      ],
    });
    if(data){
      toast.success("Internship module updated successfully and sent to admin for approval.", {
        position: toast.POSITION.TOP_RIGHT,
      });
      //navigate("/dashboard");
      navigate("/dashboard/view-internship-module-details", {state: {state: "sucess", id: id}});
      // if(origin === "loe"){
      //   navigate("/dashboard");
      // }
      // if(origin === "dashboard"){
      //   navigate("/dashboard");
      // }
      // else{
      //   navigate("/internships")
      // }
    }
   }

   const handleEdit = () =>{
    editEligible()
   }
  //  useEffect(()=>{
  //   editEligible();
  //  },[])
    console.log(eligibleData);

    return(
        <MainLayout>
            <div className="breadcrumb">
            {origin === "loe" ? (
                <p onClick={() => navigate("/loe/internship-module-eligibility-check")} style={{ cursor: "pointer" }}>
                {"Eligibility Check"}
              </p>
            ): (
                <p onClick={() => navigate("/internships/internship-module-eligibility-check")} style={{ cursor: "pointer" }}>
              {"Eligibility Check"}
            </p>
            )}
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>{"Edit Eligibility Check"}</p>
          </div>
         
          <div className="container mb-5">
          <Root sx={{ width: 200, maxWidth: "50%" }}>
            <table aria-label="custom pagination table" 
            style={{
              background: "white",
              borderRadius: "20px"
            }}>
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                      borderTopLeftRadius: "20px"
                    }}
                  >
                    {"Admin's Review"}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                      borderTopRightRadius: "20px"
                    }}
                  >
                    {"Created"}
                  </th>
                </tr>
              </thead>
              <tbody>
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                    ? theArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : theArray
                  ).map((row) => (
                    <tr key={row.comment}>
                      <td>{row.comment}</td>
                      <td style={{ width: 120 }} align="right">
                        {moment(new Date(row.created_at)).format(
                          "DD/MM/YYYY"
                        )}
                      </td>
                    </tr>
                  ))
                  : ""}

                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              {theArray?.length > 0 ? (
                <tfoot>
                  <tr>
                    <CustomTablePagination
                      rowsPerPageOptions={[
                        5,
                        10,
                        25,
                        { label: "All", value: -1 },
                      ]}
                      colSpan={3}
                      count={theArray?.length ?? "0"}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      componentsProps={{
                        select: {
                          "aria-label": "rows per page",
                        },
                        actions: {
                          showFirstButton: true,
                          showLastButton: true,
                        },
                      }}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                  </tr>
                </tfoot>
              ) : (
                <div className="container col-lg-12" style={{margin: "10px"}}>{"No Comments"}</div>
              )}
            </table>
          </Root>
        </div>
          
          <NewPageWrapper>
          <SectionWrapper>
                <HeaderText>{"Edit Internship Module"}</HeaderText>
                <Description>{eligibility.subHeading}</Description>
            </SectionWrapper>
            <BreakSection>
                <hr/>
            </BreakSection>
            <form onSubmit={handleSubmit(handleEdit)}>
            <GroupWrapper>
                <FormLabel>{eligibility.question1}</FormLabel>
                <Controller
                name="question_1"
                control={control}
                defaultValue={"Yes"}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes")
                          setQuestion1Eligible(true)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=>{ 
                            field.onChange("No")
                            setQuestion1Eligible(false)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.question2}</FormLabel>
                <Controller
                name="question_2"
                control={control}
                defaultValue={"Yes"}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes")
                            setQuestion2Eligible(true)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=> {
                            field.onChange("No")
                            setQuestion2Eligible(false)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.subQuestion}</FormLabel>
                <span className="jonNation">{eligibility.subQuestionDescription}</span>
                <div style={{ marginTop: "10px" }}>
                  <Controller
                    name="monthly_allowance_1"
                    control={control}
                    defaultValue="" 
                    render={({ field }) => (
                      <Input
                        placeholder="RM 5000"
                        {...field} 
                      />
                    )}
                  />
        </div>
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.question3}</FormLabel>
                <Controller
                name="question_3"
                control={control}
                defaultValue={"Yes"}
                render={({field}) => (
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes");
                            setQuestion3Eligible(true);
                          }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=> {
                            field.onChange("No")
                            setQuestion3Eligible(false)
                          }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.subQuestion}</FormLabel>
                <span className="jonNation">{eligibility.subQuestionDescription}</span>
                <div style={{ marginTop: "10px" }}>
                    <Controller
                      name="monthly_allowance_2"
                      control={control}
                      defaultValue="" 
                      render={({ field }) => (
                        <Input
                          placeholder="RM 5000"
                          {...field} 
                        />
                      )}
                    />
              </div>
            </GroupWrapper>

<WarningLable>Remarks</WarningLable>
       {question1Eligible && question2Eligible? (
         <WarningDescription overallEligibility={question1Eligible}>You are eligible to participate in National Structured Internship (MySIP) 2023.</WarningDescription>
       ): ""}
        {!question1Eligible ? (
              
              <WarningDescription overallEligibility={question1Eligible}>You are not eligible to participate in National Structured Internship (MySIP) 2023. You are required to provide minimum 10 weeks of internship duration</WarningDescription>
             
            ): ( "" )}

        {!question2Eligible ? (
                      
                      <WarningDescription overallEligibility={question2Eligible}>You are not eligible to participate in National Structured Internship (MySIP) 2023. Please pay a minimum RM500.00 per month or more 
                      for students undergoing Sijil Kemahiran Malaysia Level 1 (SKM1) to Diploma Kemahiran Malaysia (DKM) or Diploma to do internship</WarningDescription>
                  
                  ): (
                     "" 
                  )}

            
                    {questionsList
          .filter((question) => {
            // Filter for the specific question codes you want to display
            return (
              question.question_code === 1 
            );
          })
          .map((question) => (
            <GroupWrapper key={question.id}>
              <FormLabel>{question.question_desc}</FormLabel>
              <Controller 
              name="student_objectives"
              control={control}
              render={({field}) =>(
                answersList.filter((answer) => answer.question_code === question.question_code
                  ).map((items)=>(
                    <CheckboxGroup key={items.id}>
                      <Row>
                        <Col xs={"auto"} style={{ marginTop: "8px" }}>
                          <CheckboxInput
                            onChange={() => handleStudentCheck("student_objectives",question.question_code, items.answer_code, items.answer_desc)}
                            checked={formVal.student_objectives.some(
                              (answer) =>
                                answer.question_code === question.question_code &&
                                answer.answer_code === items.answer_code
                            )}
                          />
                        </Col>
                        <Col>
                          <TableHead>{items.answer_desc}</TableHead>
                        </Col>
                        {items.answer_desc === "Others (please specify)" && formVal.student_objectives.some(
                  (answer) =>
                    answer.question_code === question.question_code &&
                    answer.answer_code === items.answer_code
                ) ? (
                  <div style={{ marginTop: "10px" }}>
                    <Input
                      placeholder="Others"
                      value={formVal.student_objectives.find((answer) => answer.question_code === question.question_code && answer.answer_code === items.answer_code)?.others || ''}
                      onChange={(e) => handleTextOth("student_objectives", question.question_code, items.answer_code, items.answer_desc, e.target.value)}
                    />
                  </div>
                ) : null}
                        </Row>
                    </CheckboxGroup>
                    
                  ))
              )}
              />
            </GroupWrapper>
          ))}
        <BreakSection >
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>
            
        {questionsList.filter((questions)=>{
            return(
              questions.question_code === 2
            )
            }).map((questions)=>(
                  <GroupWrapper key={questions.id}>
                              <FormLabel>{questions.question_desc}</FormLabel>
                              <Controller
                              name="company_objectives"
                              control={control}
                              render={({field}) =>(
                                answersList.filter((ans)=> ans.question_code === questions.question_code )
                                .map((items)=>(
                                  <CheckboxGroup key={items.id}>
                            <Row>
                              <Col xs={"auto"} style={{ marginTop: "8px" }}>
                                <CheckboxInput
                                  onChange={() => handleCompanyCheck("company_objectives",questions.question_code, items.answer_code)}
                                  checked={formVal.company_objectives.some(
                                    (answer) =>
                                      answer.question_code === questions.question_code &&
                                      answer.answer_code === items.answer_code
                                  )}
                                />
                              </Col>
                              <Col>
                                <TableHead>{items.answer_desc}</TableHead>
                              </Col>
                              {items.answer_desc === "Others (please specify)" && formVal.company_objectives.some(
                                (answer) =>
                                  answer.question_code === questions.question_code &&
                                  answer.answer_code === items.answer_code
                              ) ? (
                                <div style={{ marginTop: "10px" }}>
                                  <Input
                                    placeholder="Others"
                                    value={formVal.company_objectives.find((answer) => answer.question_code === questions.question_code && answer.answer_code === items.answer_code)?.others || ''}
                                    onChange={(e) => handleTextOth("company_objectives", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                                  />
                                </div>
                              ) : null}
                              </Row>
                          </CheckboxGroup>
                                ))
                              )}
                              />
                          </GroupWrapper> 
                        )

                        )}
           
                    {errorsQuestion.company_objectives && (
                      <ErrorField err={errorsQuestion.company_objectives.message}/>
                    )}
                              <BreakSection>
                              <hr style={{marginTop: "40px"}}/>
                              </BreakSection>


                              {questionsList.filter((questions)=>{
                    return(
                      questions.question_code === 3
                    )
                    }).map((questions)=>(
                      <GroupWrapper key={questions.id}>
                                  <FormLabel>{questions.question_desc}</FormLabel>
                                  <Controller
                                  name="activities_offered"
                                  control={control}
                                  render={({field}) =>(
                                    answersList.filter((ans)=> ans.question_code === questions.question_code)
                                    .map((items)=>(
                                      <CheckboxGroup key={items.id}>
                                <Row>
                                  <Col xs={"auto"} style={{ marginTop: "8px" }}>
                                    <CheckboxInput
                                      onChange={() => handleSingleCheck("activities_offered", questions.question_code, items.answer_code, items.answer_desc)}
                                      checked={formVal.activities_offered.some(
                                        (answer) =>
                                          answer.question_code === questions.question_code &&
                                          answer.answer_code === items.answer_code
                                      )}
                                    />
                                  </Col>
                                  <Col>
                                    <TableHead>{items.answer_desc}</TableHead>
                                  </Col>
                                  {items.answer_desc === "Others (please specify)" && formVal.activities_offered.some(
                                    (answer) => 
                                    answer.question_code === questions.question_code &&
                                    answer.answer_code === items.answer_code
                                    ) ? (
                                  <div style={{marginTop: "10px"}}>
                                    <Input
                                    placeholder="Others"
                                    value={formVal.activities_offered.find((answer) => answer.question_code === questions.question_code && answer.answer_code === items.answer_code)?.others || ''}
                                    onChange={(e) => handleTextOth("activities_offered", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                                    />
                                  </div>
                                  ): null}
                                  </Row>
                              </CheckboxGroup>
                                    ))
                                  )}
                                  />
                              </GroupWrapper> 
                    )

                    )}
                            
                              {errorsQuestion.activities_offered && (
                      <ErrorField err={errorsQuestion.activities_offered.message}/>
                    )}
            <BreakSection>
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>

                          {questionsList.filter((questions)=>{
                return(
                  questions.question_code === 4
                )
                }).map((questions)=>(
                  <GroupWrapper key={questions.id}>
                              <FormLabel>{questions.question_desc}</FormLabel>
                              <Controller
                              name="learning_outcome"
                              control={control}
                              render={({field}) =>(
                                answersList.filter((ans)=> ans.question_code === questions.question_code )
                                .map((items)=>(
                                  <CheckboxGroup key={items.id}>
                            <Row>
                              <Col xs={"auto"} style={{ marginTop: "8px" }}>
                                <CheckboxInput
                                  onChange={() => handleLearningCheck("learning_outcome", questions.question_code, items.answer_code, items.question_desc)}
                                  checked={formVal.learning_outcome.some(
                                    (answer) =>
                                      answer.question_code === questions.question_code &&
                                      answer.answer_code === items.answer_code
                                  )}
                                />
                              </Col>
                              <Col>
                                <TableHead>{items.answer_desc}</TableHead>
                              </Col>
                              {items.answer_desc === "Others (please specify)" && formVal.learning_outcome.some(
                                (answer) =>
                                answer.question_code === questions.question_code &&
                                answer.answer_code === items.answer_code
                              ) ? (
                                <div style={{marginTop: "10px"}}>
                                  <Input
                                placeholder="Others"
                                value={formVal.learning_outcome.find((answer) => answer.question_code === questions.question_code && answer.answer_code === items.answer_code)?.others || ''}
                                onChange={(e) => handleTextOth("learning_outcome", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                                />
                                </div>
                              ): null}
                              </Row>
                          </CheckboxGroup>
                                ))
                              )}
                              />
                          </GroupWrapper> 
                )

                )}
              {errorsQuestion.learning_outcome && (
                  <ErrorField err={errorsQuestion.learning_outcome.message}/>
                )}

<GroupWrapper>
                <FormLabel>{internshipModule.uploadFile}</FormLabel>
                
                
                <Uploader>
                <UploadFileWrapper data-tip data-for="pdf">
                    <input
                      onChange={uploadFile}
                      accept="application/pdf"
                      type="file"
                      multiple
                    />
                    <UploadFileText secondary style={{ width: "200px" }}>
                      {"Choose File"}
                    </UploadFileText>
                    <p className="uploadFileOne">
                      {"The maximum file size limit is 5 MB"}
                    </p>
                  </UploadFileWrapper>
                  <FileWrapper>
                  {formVal?.upload?.length > 0
                      ? formVal.upload?.map((x, index) => {
                        return(
                          <>
                            {getfileName(x.file_path)}
                            <SkillCard
                              key={index + x.file_path}
                              delete={() => onDeleteFiles(index)}
                              // name={x.file_path}
                              name={fileName + "." + ext}
                            />
                            
                          </>
                        )
                      }
                        )
                      : ""}
                      {/* {formVal.file_name && (
                        <UploadFileBox style={{ fontSize: "12px", color: "gray" }}>
                        <FileNameWrapper className="selectedFile"> {getfileName(formVal.file_name)}</FileNameWrapper>
                        <Icon
                          onClick={() => onDeleteFiles()}
                          src={CloseIcon}
                        />
                      </UploadFileBox>
                      )} */}
                  </FileWrapper>
                </Uploader>
                      
            </GroupWrapper>

            
              <Description2>{internshipModule.subHeading2}</Description2>
            <BreakSection>
            <hr/>
            </BreakSection>
            {questionsList.filter((questions)=>{
  return(
    questions.question_code === 5
  )
  }).map((questions)=>(
     <GroupWrapper key={questions.id}>
                <FormLabel>{questions.question_desc}</FormLabel>
                <Controller
                name="other_allowance"
                control={control}
                render={({field}) =>(
                  answersList.filter((ans)=> ans.question_code === questions.question_code)
                  .map((items)=>(
                    <CheckboxGroup key={items.id}>
              <Row>
                <Col xs={"auto"} style={{ marginTop: "8px" }}>
                  <CheckboxInput
                    onChange={() => handleOtherAllowance("other_allowance",questions.question_code, items.answer_code, items.question_desc)}
                    checked={formVal.other_allowance.some(
                      (answer) =>
                        answer.question_code === questions.question_code &&
                        answer.answer_code === items.answer_code
                    )}
                  />
                </Col>
                <Col>
                  <TableHead>{items.answer_desc}</TableHead>
                </Col>
                {items.answer_desc === "Other (please specify)" && formVal.other_allowance.some(
                  (answer) =>
                  answer.question_code === questions.question_code &&
                  answer.answer_code === items.answer_code
                ) ? (
                 <div style={{marginTop: "10px"}}>
                   <Input
                  placeholder="Others"
                  value={formVal.other_allowance.find((answer) => answer.question_code === questions.question_code && answer.answer_code === items.answer_code)?.others || ''}
                  onChange={(e) => handleTextOth("other_allowance", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                  />
                 </div>
                ): null}
                </Row>
            </CheckboxGroup>
                  ))
                )}
                />
            </GroupWrapper> 
  )

  )}

 
{errorsQuestion.other_allowance && (
    <ErrorField err={errorsQuestion.other_allowance.message}/>
  )}
            <GroupWrapper>
                <FormLabel>Thank you for completing this internship module details.</FormLabel>
                <FormLabel>We shall review and notify the approval via email.</FormLabel>
                <FormLabel>PERSONAL DATA PROTECTION ACT 2010*</FormLabel>
                <Controller
                name="declaration"
                control={control}
                render={({field}) =>(
                  <CheckboxGroup className='checkbox-group'>
                    
                    <Row>
                    <Col xs="auto" style={{marginTop:"10px"}}>
                        <CheckboxInput
                        onChange={handleDeclaration}
                        checked={watch("terms_condition")}
                        />
                    </Col>
                    <Col>
                    <TableHead>{internshipModule.declaration}</TableHead>
                    </Col>
                    </Row>
                </CheckboxGroup>
                )}
                />

            </GroupWrapper>
            {errorsQuestion.terms_condition && (
              <ErrorField err={errorsQuestion.terms_condition.message}/>
            )}

            <ButtonWrapper>
                <Button name="Cancel" type="button" onClick={handleBack} secondary/>
                <Button name="Continue" type={"submit"}/>
            </ButtonWrapper>
            </form>
          </NewPageWrapper>
        </MainLayout>
    )
}