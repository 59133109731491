import styled from "styled-components";

import { Colors } from "../../utilities/colors";

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "0px")};
`;
const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
`;



export default function CheckboxInput({ name, label, margin, onChange, checked }) {
  return (
    <RadioButtonGroup onClick={onChange} margin={margin} className='checkbox-group' >
      <input checked={checked}   name={name} type="checkbox"/>
      <Label>
        {label}
      </Label>
    </RadioButtonGroup>
  );
}