// import { getInterpersonalDrilldown } from "../../../../../../services/CommonApi";
// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

export const getInterpersonalDrilldown = () => {
    const getInternPeronality = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Mobilising Systems": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 18,
                        "moderate": 496,
                        "high": 173
                    }
                },
                "Developing Relationships": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 21,
                        "moderate": 469,
                        "high": 197
                    }
                },
                "Effective Teamwork": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 14,
                        "moderate": 451,
                        "high": 222
                    }
                },
                "Coaching Others": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 19,
                        "moderate": 472,
                        "high": 196
                    }
                }
            }
        }
    }

    return getInternPeronality
}

export default Ad2DrilldownHOC(getInterpersonalDrilldown, [
    {
        title: "Mobilising Systems",
        dataKey: "Mobilising Systems",
        url: 'mobilising-systems',
        info:"High mobilising systems skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to be a role model of positive behaviour, negotiating with others so that everyone feels that they have gained a positive result and creating an inspiring vision around a project or idea to build motivation and engagement in others are all important skills in the modern workplace. Gaining the information required to understand how organisations work and what is required of you is also significant."
    },
    {
        title: "Developing Relationships",
        dataKey: "Planning and Ways of Working",
        url: 'planning-and-ways-of-working',
        info:"High developing relationships skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to have empathy for others by putting yourself in their position to understand their point of view, build trusting relationship with others by being open, honest and supportive and demonstrate humility in your interactions with others are important skills in the workplace. Being sociable opens the way to building relationships with work colleagues or clients."
    },
    {
        title: "Effective Teamwork",
        dataKey: "Effective Teamwork",
        url: 'effective-teamwork',
        info:"High effective teamwork skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to work with a diverse group of people towards a common goal and ensure that they feel included and their views are heard is important in the workplace today. Getting to know people as individuals, understanding what motivates them and supporting them to resolve conflicts are significant skills for the workplace."
    },
    {
        title: "Coaching Others",
        dataKey: "Coaching Others",
        url: 'coaching-others',
        info:"High coaching others skills position one well to access exciting opportunities in the world of work for the future.<br/><br/>Being able to help others think about what they want to achieve, to empower them by helping them focus on their strengths, to help set goals for themselves and to facilitate the development of their skills and knowledge are all important coaching skills needed in the workplace today."
    },
]);
