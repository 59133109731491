// import { getFellowshipDrilldown } from "../../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";



export const getFellowshipDrilldown = () =>{
    const getFellowship = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Altruism": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 113,
                        "moderate": 366,
                        "high": 209
                    }
                },
                "Supervisory Relationship": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 109,
                        "moderate": 387,
                        "high": 192
                    }
                },
                "Associates": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 119,
                        "moderate": 393,
                        "high": 176
                    }
                },
                "Belonging": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 98,
                        "moderate": 400,
                        "high": 190
                    }
                },
                "Family": {
                    "id": 5,
                    "totalTalents": 695,
                    "results": {
                        "low": 114,
                        "moderate": 375,
                        "high": 199
                    }
                }
            }
        }
    }
    return getFellowship
}

export default Ad2DrilldownHOC(getFellowshipDrilldown, [
    {
        title: "Altruism",
        dataKey: "Altruism",
        url: "altruism",
        info:"It is important for talents with high Altruism value scores to contribute to the welfare of others."
    },
    {
        title: "Supervisory Relationship",
        dataKey: "Supervisory Relationship",
        url: "supervisory-relationship",
        info:"It is very important for talents that scored high in Supervisory Relationships to have a good relationship with their line manager or supervisor."
    },
    {
        title: "Associates",
        dataKey: "Associates",
        url: "associates",
        info:"It is very important for talents with a high Associates value score to work as part of a team, and they thrive when working with others towards a common goal."
    },
    {
        title: "Belonging",
        dataKey: "Belonging",
        url: "belonging",
        info:"It is very important for talents that score high on Belonging to have a sense of belonging to the organisation that they work for."
    },
    {
        title: "Family",
        dataKey: "Family",
        url: "family",
        info:"It is important for talents with high Family value scores to have a career that their family would approve of and be proud of and that would bring benefits to their family as well as themselves."
    },
]);