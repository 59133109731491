import ReactTooltip from "react-tooltip";

function Tooltip(props) {
  return (
    <div>
      <ReactTooltip
        id={props.id}
        globalEventOff="focusin"
        place="top"
        effect="solid"
      >
        {props.content}
      </ReactTooltip>
    </div>
  );
}

export default Tooltip;
