import { useLocation, useNavigate } from "react-router-dom";
import MainLayout from "../../../layouts/mainLayout/mainLayout";
import { internshipModule } from "../staticContentInternship";
import rightarrow from "../../../assets/icons/arrow_right.png";
import PaginationLOEDivider from "./paginationDividerMigration";
import styled from "styled-components";
import { Colors } from "../../../utilities/colors";
import { Col, Row } from "react-bootstrap";
import CheckboxInput from "../../../components/checkboxInput/checkboxInput";
import { createNewIntern, getQuota, getSelectInternList, postNewInterns } from "../../../services/apiCalls";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Button from "../../../components/button/button";
import SelectInternsModal from "../../../components/selectInternsPreviewModal/selectInternsModal";
import { toast } from "react-toastify";
import PaginationDividerTwo from "./paginationDividerTwo";



export default function SelectInternsPage(){
    
const NewInternPageWrapper = styled.div`
background: ${Colors.light};
border-radius: 50px;
padding: 2rem;
margin: 2rem 0;
@media (min-width: 767px) and (max-width: 1023px) {
  margin: 0 -10px 0 -20px;
}
`;
const SectionWrapper = styled.section``;
const MainHeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 40px
}
`;
const TableHeader = styled.div`
border-radius: 16px;
background: #FFF;
width: 100%;
box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.11);
`;

const TableHeaderText = styled.span`
color: #D04C8D;
font-family: General Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;

`;
const TableBodyText = styled.span`
color: #202020;
font-family: General Sans;
font-size: 18px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const  ModalDescription = styled.span`
color: #202020;
text-align: center;
font-family: General Sans;
font-size: 20px;
justify-content: center;
display:flex;
padding-top: 20px;
padding-bottom: 20px;
font-style: normal;
font-weight: 500;
line-height: normal;
`;

const TableCol = styled(Col)`
display: flex;
justify-content: center;
padding-top: 20px;
padding-bottom: 20px;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;

    const navigate = useNavigate();
    
    const stacticData = [
        {
            name: "Alia",
            email: "alia@qlco.com",
            id_number: "nn2n3n2oo33"
        },   {
            name: "Alia2",
            email: "alia@qlco2.com",
            id_number: "nn2n3n2oo33"
        },   {
            name: "Alia3",
            email: "alia@qlco3.com",
            id_number: "nn2n3n2oo33"
        },   {
            name: "Alia4",
            email: "alia@qlco4.com",
            id_number: "nn2n3n2oo33"
        },
    ]
    const companyData = useSelector((state) => state.company.currentlySelectedcompanyData);
    const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

    const [internsList,setInternsList] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedInterns,setInternsSelected] = useState([]);
    const [checkMark,setCheckMark] = useState(false);
    const internDataDH = localStorage.getItem("DH_Data");
    const parsedData = JSON.parse(internDataDH)
    const [DirectHiringData, setDirectHiringData] = useState(parsedData)
    const location = useLocation();
    const fromLandingPage = location.state?.fromLandingPage;

    const [sipTokens,setSipTokens] = useState();

    const [loading, setLoading] = useState(false);
    const [showSipTokenModal, setShowSipTokenModal] = useState(false)

const handleSubmitForm = async () => {
    const result3 = await getQuota(org_code);
    console.log(result3,"tokenssipCompletion")
    setSipTokens(result3?.data?.sip_tokens);
// Step 1: Create the Internship
const { data, res } = await createNewIntern({
  ...DirectHiringData,
  documents: DirectHiringData.documents.filter((x) => x.doc_type !== "All"),
  collection_radio_type: DirectHiringData.collection_radio_type
    ? DirectHiringData.collection_radio_type
    : null,
  collection_date: DirectHiringData.collection_date
    ? DirectHiringData.collection_date
    : null,
  org_code: org_code,
  is_activate: true,
});

if(data && data.status === 1){
    let formData = {};
formData.organization = companyData.id;
formData.interns = selectedInterns.map((item) => item.id);
    setLoading(true);
    const internshipId = data.data;

    const { data: uploadedInternResponse } = await postNewInterns({formData:formData,internship_id:internshipId})
    console.log("uploadedIntenrsResp",uploadedInternResponse);
    if(uploadedInternResponse){
        const responseMessage = uploadedInternResponse.message.split(/\s+/);
            //   const wordCount = responseMessage.length;
            onClose();
              if(responseMessage.length > 3){
                toast.info(uploadedInternResponse.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 5000, // Adjust the duration as needed
                    
                    style: {
                      width: "600px",
                      // whiteSpace: 'pre-line', // This allows line breaks in the toast
                    },
                  });
                // toast.info(uploadedInternResponse.message, {
                //     position: toast.POSITION.TOP_CENTER,
                //   });
              }else{
                toast.success(uploadedInternResponse.message, {
                    position: toast.POSITION.TOP_RIGHT,
                  });
              }
      
     
          navigate("/loe-application");
    }
 
    // if(sipTokens===0){
    //     alert("Please Top Up your Sip Tokens")
    //     setShowSipTokenModal(true);
    // }else{
        
    // }
}

}
    const getInternsList = async () => {
        const {data} = await getSelectInternList(companyData.id);
        console.log("gettedDaata",data,companyData.id)
        if(data){
            setInternsList(data.data);
        //   setDataMigrationDataIntern(data.data);
        }
      }

      useEffect(()=>{
        if(companyData.id){
            getInternsList();
        }
      },[companyData])
      const onClose =()=>{
        setShowModal(false);
        }

        const Content =()=>{
 
            console.log( "SELECTED")
              return(
                <div>
               <ModalDescription>
               Please confirm that you wish to assign the following interns to
               </ModalDescription>
               <TableHeader>
              <Row>
                <TableCol lg="4">
             <TableHeaderText>
                Name
             </TableHeaderText>
                </TableCol>
                
                <TableCol  lg="4">
                <TableHeaderText>
                Email
             </TableHeaderText>
                </TableCol>
                <TableCol  lg="4">
                <TableHeaderText>
                Id Number
             </TableHeaderText>
                </TableCol>
             </Row>
              </TableHeader>
             {
                selectedInterns.map((item,index) => {
                    return  <Row>
                    <TableCol lg="4">
                   
                      
                        <TableBodyText>
                        {item.full_name}

                        </TableBodyText>

                  
                    </TableCol>
                    
                    <TableCol  lg="4">
                    <TableBodyText>
                    {item.email}
                 </TableBodyText>
                    </TableCol>
                    <TableCol  lg="4">
                    <TableBodyText>
                    {item.id_number}
                 </TableBodyText>
                    </TableCol>
                 </Row>
                })
             }
             <Row>
          
              <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
              >
                <Button
                  width="100px"
                  margin="40px 0px 30px 0px"
                  name={"Confirm"}
                  onClick={handleSubmitForm}
                  type="submit"
                />
                <Button
                  secondary
                  color="#FFFFFF"
                  name={"Cancel"}
                  style={{
                    margin: "40px 5px 30px 10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </Col>
            
          </Row>
                </div>
               
                
              )
            }


            const InternCheckbox = ({ item, selectedInterns, setInternsSelected }) => {
                const isChecked = selectedInterns.some((selectedItem) => selectedItem?.full_name === item.full_name);
              
                const handleChange = async () => {
                  let updatedInterns;
            //   console.log("filteredMap",internsList.map((item) => item.id));
                  if (isChecked) {
                    // If already selected, remove it
                    updatedInterns = selectedInterns.filter((selectedItem) => selectedItem.full_name !== item.full_name);
                  } else {
                    // If not selected, add it
                    updatedInterns = [...selectedInterns, item];
                  }
              
                  await setInternsSelected(updatedInterns);
                  console.log("updatedIntens",updatedInterns)
                };
              
                return (
                  <CheckboxInput checked={isChecked} onChange={handleChange}>
                    {/* Your checkbox label or other content here */}
                  </CheckboxInput>
                );
              };

return (
    <MainLayout>
         <div className="breadcrumb">
            <p onClick={() => navigate("/loe-application")} style={{ cursor: "pointer" }}>
              {"National Structured Internship Programme (MySIP) Application"}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
              <p onClick={() => navigate("/loe-application/loe-internship-module")} style={{ cursor: "pointer" }}>
              {"Internship Module"}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            
            <p style={{ color: "#D04C8D" }}>{"Select Interns"}</p>
          </div>
          <NewInternPageWrapper>
            <SectionWrapper>
            <MainHeaderText>
            Add a New MySIP Direct Hiring Internship
                 {
                    fromLandingPage ? <PaginationDividerTwo step={2}/> :<PaginationLOEDivider step={3}/>
                 } 
              </MainHeaderText>
            <HeaderText>{"Select Interns"}</HeaderText>
                <Description>{internshipModule.subHeading}</Description>
              <BreakSection>
              <hr/>
              </BreakSection>
              <TableHeader>
              <Row>
                <TableCol lg="4">
             <TableHeaderText>
                Name
             </TableHeaderText>
                </TableCol>
                
                <TableCol  lg="4">
                <TableHeaderText>
                Email
             </TableHeaderText>
                </TableCol>
                <TableCol  lg="4">
                <TableHeaderText>
                Id Number
             </TableHeaderText>
                </TableCol>
             </Row>
              </TableHeader>
             {
                internsList.map((item,index) => {
                    return  <Row>
                    <TableCol lg="4">
                    <Row className="justify-content-center">
                        <Col  style={{display:"flex",justifyContent:"end",}}>
                            <InternCheckbox
                            selectedInterns={selectedInterns}
                            setInternsSelected={setInternsSelected}
                            item={item}
                            key={item.full_name}
                            >

                            </InternCheckbox>
                        {/* <CheckboxInput
                    checked={selectedInterns.some((items) => items?.full_name === item.full_name)}
                    onChange={ async () =>{
                        if (selectedInterns.includes(item)) {
                            // If already selected, remove it
                            const index = selectedInterns.indexOf(item);
                            if (index > -1) {
                                selectedInterns.splice(index, 1);
                            }
                          } else {
                            // If not selected, add it
                            selectedInterns.push(item);
                          }
                        //   console.log("checkSelecteTech",selectedTechnicalSkill)
                        //   field.onChange(selectedTechnicalSkill);
                       await setInternsSelected(selectedInterns);

                    }}>

                    </CheckboxInput> */}
                        </Col>
                        <Col style={{paddingTop:"5px"}}>
                        <TableBodyText>
                        {item.full_name}

                        </TableBodyText>

                        </Col>
                    </Row>
                  
                    </TableCol>
                    
                    <TableCol  lg="4">
                    <TableBodyText>
                    {item.email}
                 </TableBodyText>
                    </TableCol>
                    <TableCol  lg="4">
                    <TableBodyText>
                    {item.id_number}
                 </TableBodyText>
                    </TableCol>
                 </Row>
                })
             }
              <ButtonWrapper>
                <Button name="Continue" type="submit"
                onClick={()=>{setShowModal(true)}}/>
                <Button name="Cancel" secondary/>
              </ButtonWrapper>
            </SectionWrapper>
          </NewInternPageWrapper>
          <SelectInternsModal
           title={"CREATE INTERNSHIP"}
           subTitle={"Select Interns"}
           show={showModal}
           close={onClose}
           content={Content()}

          />
    </MainLayout>
)
}