import { useState, useEffect } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Colors } from "../../utilities/colors";
import Logo from "../../assets/companylogosettings.png";
import Phone from "../../assets/icons/phone.png";
import Mail from "../../assets/icons/mail.png";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { getCompanyData } from "../../services/apiCalls";
import {
  updateCompanyData,
  updateCurrentlySelectedCompanyData,
  updateLoader,
} from "../../pages/myCompany/reducer";
import UpdateCompanyIntroSection from "../updateCompany/updateCompanyIntroSection";
import { useTranslation } from "react-i18next";
import Loader from "../../components/loader/loader";
import "./oraganisationCard.css";
const FlexWrapper = styled.div`
  display: flex;
`;

const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem;
  margin: 2rem 0;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 0.5rem 0;
    height: auto;
  }
`;

const SectionWrappper = styled(FlexWrapper)`
  display: flex;
  @media (max-width: 725px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 210px;
  height: 210px;
  border-radius: 50%;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    height: 126px;
    width: 143px;
    left: 26px;
    top: 275px;
    border-radius: 50%;
  }
`;
const ContentWrapper = styled.div`
  margin-left: 10px;
  width: 70%;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.labelText};
    margin: 0;
    margin-bottom: 5px;
    text-transform: capitalize;
    @media (max-width: 500px) {
      position: relative;
      left: 52%;
      top: -136px;
    }
  }
  > h {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;

    color: #525252;
  }
  > h4 {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    @media (max-width: 500px) {
      position: relative;
      top: -139px;
      left: 52%;
      font-size: 16px;
    }
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    @media (max-width: 500px) {
      position: relative;
      top: -143px;
      left: 52%;
      width: 165px;
    }
  }
  @media (max-width: 500px) {
    margin-left: 0px;
    width: 100%;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
  @media (max-width: 500px) {
    position: relative;
    top: -122px;
  }
`;


const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  text-transform: capitalize;
`;
const ImageWrapper = styled.div`
  text-align: center;
  width: 30%;
  min-width: 210px;
  @media (max-width: 500px) {
    position: relative;
    display: flex;
    left: -18%;
    text-align: center;
    width: 30%;
    min-width: 210px;
  }
`;

const DetailsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  background: #f8f8f8;
  border-radius: 54px;
  margin: 8px 0px;
  padding: 10px 20px;
  > p {
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #525252;
    word-break: break-all;
    margin: 0;
  }
`;
const Icon = styled.img`
  height: 20px;
  width: 20px;
  margin-right: 8px;
`;

const Label = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: ${(props) => (props.color ? props.color : Colors.labelText)};
  text-transform: ${(props) => (props.color ? "unset" : "capitalize")};
`;
const UserHelpText = styled(Label)`
  color: ${Colors.inputHighlightText};
  cursor: pointer;
  text-align: right;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-decoration-line: underline;
`;
const HeaderSection = styled.div`
  display: grid;
  margin: 0;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    margin: 0;
  }
  @media (max-width: 500px) {
    position: relative;
    top: -150px;
    width: 156px;
    left: 45%;
  }
`;
const HeadTitle = styled.div`
  @media (max-width: 500px) {
    width: 220px;
    word-wrap: break-word;
    position: relative;
    left: 45%;
    top: -142px;
  }
`;
export default function MyCompanyCard(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const org = useSelector((state) => state.org.orgData);
  const companyOrgs = useSelector((state) => state.company.companyData);
  const company = props.companyDetails;
  const loading = useSelector((state) => state.company.loading);
  const company_status = companyOrgs[props.companyIdx].org_status;
  const isUserPermittedToEdit =
    companyOrgs[props.companyIdx].user_details.company_modify === "Y"
      ? true
      : false;

  const country =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].country_abbr
        : company.locations[1].status === "primary"
        ? company.locations[1].country_abbr
        : ""
      : "";

  const rgtr_address =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].rgtr_address
        : company.locations[1].status === "primary"
        ? company.locations[1].rgtr_address
        : ""
      : "";
  const state =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].state_abbr
        : company.locations[1].status === "primary"
        ? company.locations[1].state_abbr
        : ""
      : "";

  const city =
    company.locations && company.locations.length > 0
      ? company.locations[0].status === "primary"
        ? company.locations[0].city_abbr
        : company.locations[1].status === "primary"
        ? company.locations[1].city_abbr
        : ""
      : "";

  const [open, setOpen] = useState(false);

  const onChangeContent = () => {
    setOpen(true);
  };

  const onCloseModal = () => {
    setOpen(false);
  };

  return (
    <div>
      <PageWrapper>
        <SectionWrappper>
          <ImageWrapper>
            <Image
              src={company && company.logo_path ? company.logo_path : Logo}
              alt="company logo"
            />
          </ImageWrapper>
          <ContentWrapper>
            <HeadTitle>
              <h3>{company && company.name}</h3>
              <h4 style={{fontSize:"18px"}}>SSM Number: {company && company.ssm_number}</h4>
              <p>
                {company && rgtr_address} , {city}, {state}
              </p>
            </HeadTitle>

            <HeaderSection>
              {company && <p> {country}</p>}
              {!["denied"].includes(company_status) && isUserPermittedToEdit ? (
                <UserHelpText onClick={onChangeContent}>
                  {t("Edit")}
                </UserHelpText>
              ) : (
                <></>
              )}
            </HeaderSection>

            <DividerLine />
            <div className="organisationText">
              <p>{company && company.moto}</p>
              <br />

              <h>{t("Type Of Organisation")}</h>
              <br />
              <LinkText>
                {company && company.type_of_org_abbr === "N/A"
                  ? company.type_of_org
                  : company.type_of_org_abbr}
              </LinkText>
              <br />
              <h>{t("Sector")}</h>
              <LinkText>
                {company && company.sector_abbr === "N/A"
                  ? company.sector
                  : company.sector_abbr}
              </LinkText>
            </div>

            <br />
            {company && company.phone_number ? (
              <DetailsWrapper>
                <Icon src={Phone} />
                <p>{"9932029430328"}</p>
              </DetailsWrapper>
            ) : null}
            {company && company.org_email ? (
              <DetailsWrapper>
                <Icon src={Mail} />
                <p>{"teenshankar@gmail.com"}</p>
              </DetailsWrapper>
            ) : null}
          </ContentWrapper>
          {open && (
            <UpdateCompanyIntroSection
              open={open}
              onClose={onCloseModal}
              companyIdx={props.companyIdx}
              companyDetails={company}
            />
          )}
        </SectionWrappper>
      </PageWrapper>
    </div>
  );
}
