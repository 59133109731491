import React from 'react'
import styled from 'styled-components';
import { ChartsWrapper,ChartHeaderText } from './common'
import ChartsView from './charts/blueCharts';

export const BoxWrapper = styled.div`
background: #FDFDFD;
border: 1px solid rgba(228, 228, 228, 0.26);
border-radius: 10px;
padding:24px 42px;
margin:20px 10px;
// height:400px;
@media (max-width: 500px) {
    padding:16px;
    height:auto;
}
`;
export default function EmployabilityCharts() {
    return (
        <BoxWrapper style={{background:"#FFFFFF"}}>
           <ChartHeaderText>Employability</ChartHeaderText>
            <hr/>
            <ChartsWrapper style={{marginTop:"36px"}}>
                <ChartsView text={"Ideas & Opportunities"} />
                <ChartsView text={"Resources"} />
                <ChartsView text={"Into Action"} />
            </ChartsWrapper>
        </BoxWrapper>
    )
}
