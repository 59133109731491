import { useEffect, useState } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { Colors } from "../../utilities/colors";
import Logo from "../../assets/companylogosettings.png";
import { toast } from "react-toastify";
import { ReactComponent as EmailIcon } from "../../assets/icons/svg/email.svg";
import { ReactComponent as PhoneIcon } from "../../assets/icons/svg/phone.svg";
import { StyledSimpleCardCustom } from "../button/commonStyles";
import { getOrgData, onUserProfilePicUpdate } from "../../services/apiCalls";
import { updateOrgData } from "../../pages/login/reducer";
import { useTranslation } from "react-i18next";
import Loader from "../loader/loader";

const FlexWrapper = styled.div`
  display: flex;
`;
const SectionWrappper = styled(FlexWrapper)`
  @media (max-width: 500px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;
const Image = styled.img`
  width: 217px;
  height: 217px;
  @media (max-width: 767px) {
    width: 100px;
    height: 100px;
  }
`;
const ContentWrapper = styled.div`
  margin-left: 30px;
  width: 100%;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.labelText};
    margin: 0;
    margin-bottom: 5px;
    text-transform: capitalize;
  }
  > p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: ${Colors.labelText};
    margin: 0;
    text-transform: capitalize;
  }
  @media (max-width: 500px) {
    margin-left: 0px;
  }
`;
const DividerLine = styled.hr`
  margin: 10px 0;
  border: 0.4px solid ${Colors.labelText};
`;

const LinkText = styled.label`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  // cursor: pointer !important;
`;
const ImageWrapper = styled.div`
  text-align: center;
`;
const Uploader = styled.div`
  position: relative;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  cursor: pointer !important;
`;

export default function MyCompanyIntro(props) {
  const company = useSelector((state) => state.company.companyData);
  const org = useSelector((state) => state.org.orgData);
  const code = useSelector((state) => state?.company?.selectedGlobalOrgCode); //org?.additional_info?.org_code;
  const currentOrgData = useSelector((state) => state?.company?.currentOrgData); //org?.additional_info?.org_code;
  const [isLoading,setLoading] = useState(false)
  const dispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState("");
  //const userProfilePic = selectedImage || org?.profile_picture
  // const userProfilePic=props['data'].logo_path
  const currentUser = props.orgUser.filter(
    (item) => item.email === props.currentUserEmail
  );
  
  const { mob_number, profile_picture } = currentUser[0];
  useEffect(()=>{
localStorage.setItem("phone", mob_number);
  },[])
  const {t} = useTranslation();
  const uploadFile = async (event) => {
    setSelectedImage("");
    const formdata = new FormData();
    let maxFileSize = 1024 * 1024 * 5; //5mb

    if (event.target.files[0].size > maxFileSize) {
      toast.error(
        t("File size exceeds maximum limit."),
        {
          position: toast.POSITION.TOP_RIGHT,
        }
      );
    } else {
      setLoading(true);


      formdata.append("profile_picture", event.target.files[0]);
      const data = await onUserProfilePicUpdate({
        code: code ? code : currentOrgData?.org_code,
        data: formdata,
      });

      if (data && data.status === 200) {

        setSelectedImage(data.data.data.logo_path);
        getData();
        toast.success(t("Profile Image uploaded successfully!"), {
          position: toast.POSITION.TOP_RIGHT,
        });
        setLoading(false);
      }
      setLoading(false);
    }
  };

  const getData = async () => {
    const { data } = await getOrgData();
    if (data && data.status === 1) {
      dispatch(
        updateOrgData({ ...org, profile_picture: data.data.profile_picture })
      );
    }
  };

  return (
    <SectionWrappper>
      <ImageWrapper>
        { isLoading == false ? <Image
          src={
           
            selectedImage
              ? selectedImage
              : profile_picture
           
          }
          alt="company logo"
        /> : <Loader></Loader>}
        {props.settings && (
          <Uploader>
            <LinkText>{t('Change Profile Photo')}</LinkText>
            <input
              onChange={uploadFile}
              accept="image/x-png,image/jpeg"
              type="file"
            />
          </Uploader>
        )}
        <p style={{ fontSize: "12px", color: "gray" }}>
          {t('The maximum image size limit is 5 MB')}
        </p>
      </ImageWrapper>

      <ContentWrapper>
        <h3>
          {props.data && props.data.first_name}{" "}
          {props.data && props.data.last_name}
        </h3>
        <p>{company && company.name}</p>
        <p>{company && company?.locations?.[0]?.country_abbr}</p>
        <DividerLine />
        <p>{props.data && props.data.job_title}</p>
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "flex-start",
          }}
        >
          {/* {company && company.contact_number ? ( */}
          <StyledSimpleCardCustom style={{ margin: 0, marginRight: "20px" }}>
            <PhoneIcon />
            {mob_number}
          </StyledSimpleCardCustom>
          {/* ) : (
            ""
          )} */}
          {org && org?.email ? (
            <StyledSimpleCardCustom style={{ margin: 0 }}>
              <EmailIcon />
              {`${org && org?.email}`}
            </StyledSimpleCardCustom>
          ) : (
            ""
          )}
        </div>
      </ContentWrapper>
    </SectionWrappper>
  );
}
