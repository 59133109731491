import * as yup from "yup";

const schema = yup
    .object({
        email: yup
            .string()
            .trim()
            .email("The entered e-mail address is incorrect")
            .required("E-mail address is required."),
        full_name: yup
            .string()
            .trim()
            .required("Name is required."),
        position: yup.string().required("Position is required."),
        department: yup.string().required("Department is required."),
        date_joined: yup.string().required("Date Of Joining is required."),
        gender: yup.string().required("Gender is required.")
    })
    .required();



export default schema;
