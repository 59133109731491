import React, { useEffect, useState } from "react";
import Rect from "../../assets/Group 2238.png";
import styled from "styled-components";
import { SubscriptionData } from "../../assets/data/subscriptionData";
import Check from "../../assets/icons/Check.png";
import Multiply from "../../assets/icons/Multiply.png";
import SubscriptionForm from "./subscriptionForm";
import { useSelector } from "react-redux";
import { t } from "i18next";
import Button from "../button/button";
import SubscriptionDetailModal from "./subscriptionDetailModal";
import { Row , Col} from "react-bootstrap";

const TableWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 30px 16px 9px 12px;
  @media (max-width: 800px) {
    padding: 0px;
  }
`;
const Wrapper = styled.div`
  border-radius: 12px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding-bottom: 18px;
  @media (max-width: 500px) {
    box-shadow: none;
    overflow: scroll;
  }
  @media (min-width: 768px) and (max-width: 1200px) {
    overflow: scroll;
  }
`;
const PlanWrappper = styled.div`
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  border-radius: 12px;
  min-width: 760px;
  //  border-bottom:1px solid ${(props) => (props.bg ? props.bg : "#DADADA")};
`;
// const PlanCard = styled.div`
//   background: ${(props) => (props.bg ? props.bg : "none")};
//   display: flex;
//   flex-direction: column;
//   align-items: center;
//   color: #ffffff;
//   font-family: "General Sans";
//   font-style: normal;
//   font-weight: 500;
//   height: 50px;
//   border-top: 1px solid
//     ${(props) => (props.bgBottom ? props.bgBottom : "#DADADA")};
//   min-width: 148px;
// `;
const Img = styled.img`
  margin: auto;
  margin-top: -8px;
  // width: 100%;
  width: 165.5px;
  height: 53px;
  @media (max-width: 500px) {
    width: 120px;
    height: 53.25px;
  }
`;
const HeaderText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: ${(props) => (props.fontSize ? props.fontSize : "35.285px")};
  letter-spacing: 1.96028px;
  color: ${(props) => (props?.textColor ? props?.textColor : "#FFFFFF")};
`;
const StyleText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 20.0033px;
  color: ${(props) => (props?.textColor ? props?.textColor : "#FFFFFF")};
  margin: -65px 0 50px 0;
  z-index: 1000;
  @media (max-width: 500px) {
    margin: -40px 0 50px 0;
    font-size: 12.1714px;
    line-height: 16px;
  }
`;
const SmallText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #d04c8d;
  margin-top: -15px;
  margin-bottom: 0;
  z-index: 1000;
  background: #ffffff;
  padding: 0 8px;
`;
const StatusIcon = styled.img`
  margin: auto;
`;
const PlanButton = styled.button`
  margin-top: 8px;
  width: 117px;
  height: 37.16px;
  left: 763px;
  top: 2778px;
  background: ${(props) => props.color};
  border-radius: 88.0941px;
  border: 3px solid
    ${(props) => (props.selected ? props.hoverColor : props.color)};

  font-family: "General Sans";
  font-style: normal;
  font-weight: ${(props) => (props.selected ? 600 : 500)};
  font-size: ${(props) => (props.selected ? "14px" : "12px")};
  line-height: 17px;
  text-align: center;
  color: #ffffff;
  box-shadow: 4px 4px 8px rgba(0, 145, 251, 0.31);
  &:hover:not([disabled]) {
    border: 2px solid ${(props) => props.hoverColor};
  }
`;

const PlanCardDiv = styled.div`
display: flex;
flex-direction: row;
gap: 30px;
`;

const PlanCard = styled.div`
height: auto;
width: 500px;
padding-bottom: 20px;
border-radius: 20px;
box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;
const PlanCardText = styled.h3`
font-family: General Sans;
font-size: 25px;
font-weight: 500;
line-height: 18.75px;
padding: 15px;
`;
const PlanCardContentWrapper = styled.div`
text-align: center;
display: flex;
flex-direction: column;
`;

const PlanCardContentText = styled.h3`
font-family: General Sans;
font-size: 35px;
font-style: italic;
line-heigth: 31.09px;
font-weight: 600;
margin-left: -120px;
margin-bottom: -20px
`;

const PlanCardSubContentText = styled.h1`
font-size: 60px;
font-family: General Sans;
font-style: italic;
font-weight: 600;
line-heigth: 57.72px;
`;

const PlanButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`;
const CustomPlanButton = styled.div`
color: white;
width: 200px;
height: 50px;
border-radius: 12px;
cursor: pointer;
display: flex;
justify-content: center;
align-items: center; 
background: ${(props) => props.color};
&:hover:not([disabled]) {
  border: 2px solid ${(props) => props.hoverColor};
}
`;
const CustomPlanButtonText = styled.h3`
font-family: General Sans;
font-size: 25px;
font-style: italic;
line-height: 25.78px;
font-weight: 500;
`;

const SelectContentHeaderDiv = styled.div`
margin-top: 50px;
padding: 10px;
`;

const SelectContentHeaderText = styled.h3`
font-size: 24px;
font-weigth: 200;
`;

const SelectContentDiv = styled.div`
padding-left: 50px;
display: flex;
gap: 50px;
`;

const CustomSelectCard = styled.div`
color: ${(props) => (props.selected ? 'white' : 'grey')};
  width: 300px;
  height: 50px;
  border-radius: 12px;
  cursor: pointer;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 15px;
  background: ${(props) => (props.selected ? props.selectedColor : props.unselectedColor)};
  border: ${(props) => (props.selected ? '2px solid white' : 'none')};
  box-shadow: ${(props) =>
    props.selected ? `0 4px 4px ${props.selectedColor}` : 'none'};
  transition: box-shadow 0.3s ease;
  &:hover:not([disabled]) {
    background: ${(props) => props.hoverColor};
    color: white;
  }
`;

const CustomSelectCardText = styled.h3`
font-family: General Sans;
font-size: 20px;
line-height: 25.78px;
font-weight: 400;
margin-top:5px;
`;

const ModalContentDiv = styled.div`
padding: 15px;

`;
const ModalHeading = styled.h4`
font-family: General Sans;
font-size: 20px;
font-weight: 600;
color: ${(props) => props.color};
`;
const ModalHeading2 = styled.h4`
font-family: General Sans;
font-size: 18px;
font-weight: 600;
color: ${(props) => props.color};
text-align: center;
`;
const ModalContentHeading = styled.h4`
font-family: General Sans;
font-size: 18px;
font-weight: 600;
margin-bottom: 20px;
`;

const ModalContentHeading1 = styled.h4`
font-family: General Sans;
font-size: 18px;
font-weight: 600;
margin-bottom: 20px;
text-align: center;
`;
const ButtonWrapper = styled.div`
margin-top: 10px;
display: flex;
justify-content: center;
gap: 20px;
`;


const SubscriptionPlanTable = ({ planDetails }) => {
  const data = SubscriptionData;
  const [selectedPlan, setSelectedPlan] = useState("");
  const [prevData,setPrevData] = useState(true)
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscribedData = useSelector((state) => state.subscription.subscriptionData);
  const [isPlanSelected, setIsPlanSelected] = useState(false);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (org_code && subscribedData[org_code] && prevData) {
      setSelectedPlan(subscribedData[org_code].plan)
      setPrevData(false)
    }

  })
const openModal =()=>{
  setShowModal(true);
}
  const ModalContent =() =>{
    return(
      <div>
        <ModalContentDiv>
        <Row>
            <Col md="4">
           <ModalHeading color="#D04C8D"> {data?.talent_acquisition?.heading}</ModalHeading>
            </Col>

            <Col md="2">
              <ModalHeading2 color="#63BDFF">Enterprise</ModalHeading2>
            </Col>
            <Col md="2">
              <ModalHeading2 color="#FF63C1">Premium</ModalHeading2>
            </Col>
            <Col md="2">
              <ModalHeading2 color="#6373FF">Pro</ModalHeading2>
            </Col>
            <Col md="2">
              <ModalHeading2 color="#42C1A6">Basic</ModalHeading2>
            </Col>
          </Row>
          <hr style={{color: "#D04C8D", marginTop: "-2px"}}/>
          {data?.talent_acquisition ? 
        data?.talent_acquisition?.features.map((items, index)=>(
          <>
          <Row key={index}>
            <Col md="4">
             <ModalContentHeading> {items.name} </ModalContentHeading>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items.enterprise}</ModalContentHeading1>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items.premium}</ModalContentHeading1>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items.pro}</ModalContentHeading1>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items.basic}</ModalContentHeading1>
            </Col>
          </Row>
          </>
        ))
        : null}

        </ModalContentDiv>
          
        
        <ModalContentDiv style={{marginBottom: "20px"}}>
        <Row>
          <Col md="4">
          <ModalHeading color="#D04C8D">{data?.talent_management?.heading}</ModalHeading>
          </Col>
        </Row>
        <hr style={{color: "#D04C8D", marginTop: "-2px"}}/>
        {data?.talent_management? 
        data?.talent_management?.features.map((items2, index) =>(
          <>
          <Row key={index}>
            <Col md="4">
              <ModalContentHeading>{items2.name}</ModalContentHeading>
            </Col>
            <Col md="2">
              <ModalContentHeading1>{items2.enterprise}</ModalContentHeading1>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items2.premium}</ModalContentHeading1>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items2.pro}</ModalContentHeading1>
            </Col>

            <Col md="2">
              <ModalContentHeading1>{items2.basic}</ModalContentHeading1>
            </Col>
          </Row>
          </>
        ))
        : null}
        </ModalContentDiv>

        <ModalContentDiv>
          <ModalContentHeading>Select Package</ModalContentHeading>

          <SelectContentDiv>
        <CustomSelectCard
              selected={selectedPlan === "Enterprise"}
              selectedColor="rgba(99, 189, 255, 1)"
              unselectedColor="rgba(99, 189, 255, 0.4)"
              hoverColor="rgba(99, 189, 255, 0.9)"
              onClick={() => {
                setSelectedPlan("Enterprise");
                planDetails("Enterprise", "subentr");
                setIsPlanSelected(true);
              }}
        >
          <CustomSelectCardText>Enterprise</CustomSelectCardText>
        </CustomSelectCard>

        <CustomSelectCard  
              selected={selectedPlan === "Premium"}
              selectedColor="rgba(255, 99, 193, 0.9)"
              unselectedColor="rgba(255, 99, 193, 0.4)"
              hoverColor="rgba(255, 99, 193, 0.9)"
              onClick={() => {
                setSelectedPlan("Premium");
                planDetails("Premium", "subprem");
                setIsPlanSelected(true);
              }}>
          <CustomSelectCardText>Premium</CustomSelectCardText>
        </CustomSelectCard>

        <CustomSelectCard        
              selected={selectedPlan === "Pro"}
              selectedColor="rgba(99, 115, 255, 0.9)"
              unselectedColor="rgba(99, 115, 255, 0.4)"
              hoverColor="rgba(99, 115, 255, 0.9)"
              onClick={() => {
                setSelectedPlan("Pro");
                planDetails("Pro", "subpro");
                setIsPlanSelected(true);
              }}>
          <CustomSelectCardText>Pro</CustomSelectCardText>
        </CustomSelectCard>

        <CustomSelectCard 
        selected={selectedPlan === "Basic"}
        selectedColor="rgba(66, 193, 166, 0.9)"
        unselectedColor="rgba(66, 193, 166, 0.4)"
        hoverColor="rgba(66, 193, 166, 0.9)"
        onClick={() => {
          setSelectedPlan("Basic");
          planDetails("Basic", "subbase");
          setIsPlanSelected(true);
        }}
        >
          <CustomSelectCardText>Basic</CustomSelectCardText>
        </CustomSelectCard>
      </SelectContentDiv>          
        </ModalContentDiv>
        <ButtonWrapper>
          <Button name="Continue" onClick={()=>setShowModal(false)}/>
          <Button secondary name="Cancel" onClick={()=>setShowModal(false)}/>
        </ButtonWrapper>
      </div>
      
    )
  }
  return (
  //   <TableWrapper>
  //     <Wrapper>
  //       <PlanWrappper>
  //         <PlanCard
  //           id="planform"
  //           className="planTextform"
  //           style={{ minWidth: "280px" }}
  //         ></PlanCard>
  //         <PlanCard
  //           bg="#3993D5"
  //           bgBottom="#3E9EE4"
  //           style={{ minHeight: "155px" }}
  //         >
  //           <Img src={Rect} />
  //           <StyleText textColor="#3993D5">ENTERPRISE</StyleText>
  //           <HeaderText
  //             textColor="#143C59"
  //             fontSize="18.88px"
  //             style={{ marginTop: "-8px", width: "72%", textAlign: "start" }}
  //           >
  //             RM
  //           </HeaderText>
  //           <HeaderText textColor="#143C59" style={{ marginTop: "-32px" }}>
  //             12,500
  //           </HeaderText>
  //         </PlanCard>
  //         <PlanCard
  //           bg="#E145A3"
  //           style={{ minHeight: "155px" }}
  //           bgBottom="##DF50A6"
  //         >
  //           <Img src={Rect} />
  //           <StyleText textColor="#EF53B1">PREMIUM</StyleText>
  //           <HeaderText
  //             textColor="#143C59"
  //             fontSize="18.88px"
  //             style={{ marginTop: "-8px", width: "64%", textAlign: "start" }}
  //           >
  //             RM
  //           </HeaderText>
  //           <HeaderText textColor="#143C59" style={{ marginTop: "-32px" }}>
  //             7,500
  //           </HeaderText>
  //         </PlanCard>
  //         <PlanCard
  //           bg="#3949E6"
  //           style={{ minHeight: "155px" }}
  //           bgBottom="#4656F1"
  //         >
  //           <Img src={Rect} />
  //           <StyleText textColor="#3949E6">PRO</StyleText>
  //           <HeaderText
  //             textColor="#143C59"
  //             fontSize="18.88px"
  //             style={{ marginTop: "-8px", width: "64%", textAlign: "start" }}
  //           >
  //             RM
  //           </HeaderText>
  //           <HeaderText textColor="#143C59" style={{ marginTop: "-32px" }}>
  //             5,000
  //           </HeaderText>
  //         </PlanCard>
  //         <PlanCard
  //           bg="#108F74"
  //           style={{ minHeight: "155px" }}
  //           bgBottom="#15A284"
  //         >
  //           <Img src={Rect} />
  //           <StyleText textColor="#108F74">BASIC</StyleText>
  //           <HeaderText
  //             textColor="#143C59"
  //             fontSize="18.88px"
  //             style={{ marginTop: "-8px", width: "64%", textAlign: "start" }}
  //           >
  //             RM
  //           </HeaderText>
  //           <HeaderText textColor="#143C59" style={{ marginTop: "-32px" }}>
  //             2,500
  //           </HeaderText>
  //         </PlanCard>
  //       </PlanWrappper>

  //       {data?.talent_acquisition
  //         ? data?.talent_acquisition?.features.map((feature, index) => (
  //             <PlanWrappper key={index}>
  //               <PlanCard
  //                 bgBottom={index === 0 ? "#D04C8D" : "#DADADA"}
  //                 style={{
  //                   color: "#000000",
  //                   padding: "0px 8px",
  //                   textAlign: "center",
  //                   minWidth: "280px",
  //                 }}
  //               >
  //                 {index === 0 ? (
  //                   <SmallText>{t("Talent Acquisition")}</SmallText>
  //                 ) : null}
  //                 <p style={{ margin: "auto" }}>{t(feature.name)}</p>
  //               </PlanCard>
  //               <PlanCard bg="#4FA9EB" bgBottom="#3E9EE4">
  //                 {feature.enterprise === "yes" ? (
  //                   <StatusIcon src={Check} />
  //                 ) : null}
  //                 {feature.enterprise === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.enterprise !== "yes" &&
  //                 feature.enterprise !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.enterprise)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#EF53B1" bgBottom="##DF50A6">
  //                 {feature.premium === "yes" ? (
  //                   <StatusIcon src={Check} />
  //                 ) : null}
  //                 {feature.premium === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.premium !== "yes" && feature.premium !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.premium)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#5262EE" bgBottom="#4656F1">
  //                 {feature.pro === "yes" ? <StatusIcon src={Check} /> : null}
  //                 {feature.pro === "no" ? <StatusIcon src={Multiply} /> : null}
  //                 {feature.pro !== "yes" && feature.pro !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.pro)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#2AA98E" bgBottom="#15A284">
  //                 {feature.basic === "yes" ? <StatusIcon src={Check} /> : null}
  //                 {feature.basic === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.basic !== "yes" && feature.basic !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.basic)}</p>
  //                 ) : null}
  //               </PlanCard>
  //             </PlanWrappper>
  //           ))
  //         : null}

  //       {data?.talent_management
  //         ? data?.talent_management?.features.map((feature, index) => (
  //             <PlanWrappper key={index}>
  //               <PlanCard
  //                 bgBottom={index === 0 ? "#D04C8D" : "#DADADA"}
  //                 style={{
  //                   color: "#000000",
  //                   padding: "0px 8px",
  //                   textAlign: "center",
  //                   minWidth: "280px",
  //                 }}
  //               >
  //                 {index === 0 ? (
  //                   <SmallText>{t("Talent Management")}</SmallText>
  //                 ) : null}
  //                 <p
  //                   style={{
  //                     margin: "auto",
  //                     fontWeight: feature?.tag ? "600" : "500",
  //                   }}
  //                 >
  //                   {t(feature.name)}
  //                 </p>
  //               </PlanCard>
  //               <PlanCard bg="#4FA9EB" bgBottom="#3E9EE4">
  //                 {feature.enterprise === "yes" ? (
  //                   <StatusIcon src={Check} />
  //                 ) : null}
  //                 {feature.enterprise === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.enterprise !== "yes" &&
  //                 feature.enterprise !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.enterprise)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#EF53B1" bgBottom="##DF50A6">
  //                 {feature.premium === "yes" ? (
  //                   <StatusIcon src={Check} />
  //                 ) : null}
  //                 {feature.premium === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.premium !== "yes" && feature.premium !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.premium)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#5262EE" bgBottom="#4656F1">
  //                 {feature.pro === "yes" ? <StatusIcon src={Check} /> : null}
  //                 {feature.pro === "no" ? <StatusIcon src={Multiply} /> : null}
  //                 {feature.pro !== "yes" && feature.pro !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.pro)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#2AA98E" bgBottom="#15A284">
  //                 {feature.basic === "yes" ? <StatusIcon src={Check} /> : null}
  //                 {feature.basic === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.basic !== "yes" && feature.basic !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.basic)}</p>
  //                 ) : null}
  //               </PlanCard>
  //             </PlanWrappper>
  //           ))
  //         : null}
  //         {data?.service_support
  //         ? data?.service_support?.features.map((feature, index) => (
  //             <PlanWrappper key={index}>
  //               <PlanCard
  //                 bgBottom={index === 0 ? "#D04C8D" : "#DADADA"}
  //                 style={{
  //                   color: "#000000",
  //                   padding: "0px 8px",
  //                   textAlign: "center",
  //                   minWidth: "280px",
  //                 }}
  //               >
  //                 {index === 0 ? (
  //                   <SmallText>{t("Service, Support & Other Signature Initiatives")}</SmallText>
  //                 ) : null}
  //                 <p
  //                   style={{
  //                     margin: "auto",
  //                     fontWeight: feature?.tag ? "600" : "500",
  //                   }}
  //                 >
  //                   {t(feature.name)}
  //                 </p>
  //               </PlanCard>
  //               <PlanCard bg="#4FA9EB" bgBottom="#3E9EE4">
  //                 {feature.enterprise === "yes" ? (
  //                   <StatusIcon src={Check} />
  //                 ) : null}
  //                 {feature.enterprise === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.enterprise !== "yes" &&
  //                 feature.enterprise !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.enterprise)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#EF53B1" bgBottom="##DF50A6">
  //                 {feature.premium === "yes" ? (
  //                   <StatusIcon src={Check} />
  //                 ) : null}
  //                 {feature.premium === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.premium !== "yes" && feature.premium !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.premium)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#5262EE" bgBottom="#4656F1">
  //                 {feature.pro === "yes" ? <StatusIcon src={Check} /> : null}
  //                 {feature.pro === "no" ? <StatusIcon src={Multiply} /> : null}
  //                 {feature.pro !== "yes" && feature.pro !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.pro)}</p>
  //                 ) : null}
  //               </PlanCard>
  //               <PlanCard bg="#2AA98E" bgBottom="#15A284">
  //                 {feature.basic === "yes" ? <StatusIcon src={Check} /> : null}
  //                 {feature.basic === "no" ? (
  //                   <StatusIcon src={Multiply} />
  //                 ) : null}
  //                 {feature.basic !== "yes" && feature.basic !== "no" ? (
  //                   <p style={{ margin: "auto" }}>{t(feature.basic)}</p>
  //                 ) : null}
  //               </PlanCard>
  //             </PlanWrappper>
  //           ))
  //         : null}
  //       <PlanWrappper>
  //         <PlanCard style={{ minWidth: "280px" }}></PlanCard>
  //         <PlanCard style={{ height: "50px" }}>
  //           <PlanButton
  //             selected={selectedPlan === "Enterprise"}
  //             color="#63BDFF"
  //             hoverColor="#0091FB"
  //             onClick={() => {
  //               setSelectedPlan("Enterprise");
  //               planDetails("Enterprise", "subentr");
  //               setIsPlanSelected(true);
  //             }}
  //           >
  //             {selectedPlan === "Enterprise" ? t("Selected") : t("Select")}
  //           </PlanButton>
  //         </PlanCard>
  //         <PlanCard>
  //           <PlanButton
  //             selected={selectedPlan === "Premium"}
  //             color="#FF63C1"
  //             hoverColor="#FF0099"
  //             onClick={() => {
  //               setSelectedPlan("Premium");
  //               planDetails("Premium", "subprem");
  //               setIsPlanSelected(true);
  //             }}
  //           >
  //             {selectedPlan === "Premium" ? t("Selected") : t("Select")}
  //           </PlanButton>
  //         </PlanCard>
  //         <PlanCard>
  //           <PlanButton
  //             selected={selectedPlan === "Pro"}
  //             color="#6373FF"
  //             hoverColor="#001AFF"
  //             onClick={() => {
  //               setSelectedPlan("Pro");
  //               planDetails("Pro", "subpro");
  //               setIsPlanSelected(true);
  //             }}
  //           >
  //             {selectedPlan === "Pro" ? t("Selected") : t("Select")}
  //           </PlanButton>
  //         </PlanCard>
  //         <PlanCard>
  //           <PlanButton
  //             selected={selectedPlan === "Basic"}
  //             color="#42C1A6"
  //             hoverColor="#098B6F"
  //             onClick={() => {
  //               setSelectedPlan("Basic");
  //               planDetails("Basic", "subbase");
  //               setIsPlanSelected(true);
  //             }}
  //           >
  //             {selectedPlan === "Basic" ? t("Selected") : t("Select")}
  //           </PlanButton>
  //         </PlanCard>
  //       </PlanWrappper>
       
  // {/* <p style={{ textAlign: "center", marginTop: "10px", color: "red" }}>
  //   Please contact the Mynext team at company@mynext.my for more information about the package details.
  // </p> */}

  //     </Wrapper>
  //   </TableWrapper>
  <TableWrapper>
    <PlanCardDiv>
      <PlanCard>
        <PlanCardText style={{color: "#63BDFF", textShadow: "2px 2px 4px rgba(99, 189, 255, 0.4)"}}>ENTERPRISE</PlanCardText>
        <PlanCardContentWrapper>
          <PlanCardContentText>RM</PlanCardContentText>
          <PlanCardSubContentText>12,000</PlanCardSubContentText>
        </PlanCardContentWrapper>
        <PlanButtonWrapper>
        <CustomPlanButton 
        style={{backgroundColor: "#63BDFF", 
        boxShadow: "0 4px 4px rgba(99, 189, 255, 0.4)"}}
        onClick={openModal}
        >
          <CustomPlanButtonText style={{justifyItems: "center"}}>Explore</CustomPlanButtonText>
        </CustomPlanButton>
        </PlanButtonWrapper>
      </PlanCard>

      <PlanCard>
        <PlanCardText 
        style={{ color: "#FF63C1", textShadow: "2px 2px 4px rgba(255, 99, 193, 0.4)"}}
          >PREMIUM</PlanCardText>
        <PlanCardContentWrapper>
          <PlanCardContentText>RM</PlanCardContentText>
          <PlanCardSubContentText style={{marginLeft: "-20px"}}>7,500</PlanCardSubContentText>
        </PlanCardContentWrapper>
        <PlanButtonWrapper>
          <CustomPlanButton 
          style={{
            backgroundColor: "#FF63C1", 
            boxShadow: "0 4px 4px rgba(255, 99, 193, 0.4)"}}
          onClick={openModal}
            >
            <CustomPlanButtonText>Explore</CustomPlanButtonText>
          </CustomPlanButton>
        </PlanButtonWrapper>
      </PlanCard>

      <PlanCard>
        <PlanCardText 
        style={{color: "#6373FF", textShadow: "2px 2px 4px rgba(99, 115, 255, 0.4)"}}
          >PRO</PlanCardText>
        <PlanCardContentWrapper>
          <PlanCardContentText>RM</PlanCardContentText>
          <PlanCardSubContentText style={{marginLeft: "-20px"}}>5,000</PlanCardSubContentText>
        </PlanCardContentWrapper>
        <PlanButtonWrapper>
          <CustomPlanButton 
          style={{
            backgroundColor: "#6373FF", 
            boxShadow: "0 4px 4px rgba(99, 115, 255, 0.4)"}}
          onClick={openModal}  
            >
            <CustomPlanButtonText>Explore</CustomPlanButtonText>
          </CustomPlanButton>
        </PlanButtonWrapper>
      </PlanCard>

      <PlanCard>
        <PlanCardText 
        style={{color: "#42C1A6", textShadow: "2px 2px 4px rgba(66, 193, 166, 0.4)"}}>BASIC</PlanCardText>
        <PlanCardContentWrapper>
          <PlanCardContentText>RM</PlanCardContentText>
          <PlanCardSubContentText style={{marginLeft: "-20px"}}>2,500</PlanCardSubContentText>
        </PlanCardContentWrapper>
        <PlanButtonWrapper>
          <CustomPlanButton 
          style={{
            backgroundColor: "#42C1A6", 
            boxShadow: "0 4px 4px rgba(99, 115, 255, 0.4)"}}
          onClick={openModal} 
            >
            <CustomPlanButtonText>Explore</CustomPlanButtonText>
          </CustomPlanButton>
        </PlanButtonWrapper>
      </PlanCard>
    </PlanCardDiv>

    <SubscriptionDetailModal
     title={"Subscriptions"}
     subTitle={"Subscription Detail"}
     show={showModal}
     close={()=>setShowModal(false)}
     content={ModalContent()}
     />

    <SelectContentHeaderDiv>
      <SelectContentHeaderText>Select Package</SelectContentHeaderText>
      <SelectContentDiv>
        <CustomSelectCard
              selected={selectedPlan === "Enterprise"}
              selectedColor="rgba(99, 189, 255, 1)"
              unselectedColor="rgba(99, 189, 255, 0.4)"
              hoverColor="rgba(99, 189, 255, 0.9)"
              onClick={() => {
                setSelectedPlan("Enterprise");
                planDetails("Enterprise", "subentr");
                setIsPlanSelected(true);
              }}
        >
          <CustomSelectCardText>Enterprise</CustomSelectCardText>
        </CustomSelectCard>

        <CustomSelectCard  
              selected={selectedPlan === "Premium"}
              selectedColor="rgba(255, 99, 193, 0.9)"
              unselectedColor="rgba(255, 99, 193, 0.4)"
              hoverColor="rgba(255, 99, 193, 0.9)"
              onClick={() => {
                setSelectedPlan("Premium");
                planDetails("Premium", "subprem");
                setIsPlanSelected(true);
              }}>
          <CustomSelectCardText>Premium</CustomSelectCardText>
        </CustomSelectCard>

        <CustomSelectCard        
              selected={selectedPlan === "Pro"}
              selectedColor="rgba(99, 115, 255, 0.9)"
              unselectedColor="rgba(99, 115, 255, 0.4)"
              hoverColor="rgba(99, 115, 255, 0.9)"
              onClick={() => {
                setSelectedPlan("Pro");
                planDetails("Pro", "subpro");
                setIsPlanSelected(true);
              }}>
          <CustomSelectCardText>Pro</CustomSelectCardText>
        </CustomSelectCard>

        <CustomSelectCard 
        selected={selectedPlan === "Basic"}
        selectedColor="rgba(66, 193, 166, 0.9)"
        unselectedColor="rgba(66, 193, 166, 0.4)"
        hoverColor="rgba(66, 193, 166, 0.9)"
        onClick={() => {
          setSelectedPlan("Basic");
          planDetails("Basic", "subbase");
          setIsPlanSelected(true);
        }}
        >
          <CustomSelectCardText>Basic</CustomSelectCardText>
        </CustomSelectCard>
      </SelectContentDiv>
    </SelectContentHeaderDiv>
    

  </TableWrapper>
  );
};

export default SubscriptionPlanTable;
