import React, { useState, useEffect } from 'react'
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import SubscriptionPlanTable from "../../components/Subscriptions/SubscriptionPlanTable";
import SubscriptionForm from '../../components/Subscriptions/subscriptionForm';
import SubscriptionPopup from '../../components/Subscriptions/subscriptionPopup';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import { paymentGateWay } from '../../services/apiCalls';
import { companySubscription, getCompanySubscription } from "../../services/apiCalls";
import { updateSubscriptionData } from "./reducer";
import { toast } from "react-toastify";
import { SubscriptionData } from '../../assets/data/subscriptionData';
import { subsctipationsProxy } from './subscripationsProxy';
import { t } from 'i18next';

const HeaderText = styled.h2`
  margin-top:2%;
  margin-bottom:1%;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  @media (max-width:500px){
    margin:20px 0 20px 5px;
  }
`;
const CardsWrapper = styled.div`
  background: ${Colors.light};

  border-radius:32px;
  padding-bottom:1%;  
  `;

const SubscriptionsPageThree = () => {
  const [subscribePopup, setSubscribePopup] = useState(false)
  const [closePopup, setClosePopup] = useState(false)
  const orgDetails = useSelector((state) => state.company.currentOrgData);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscribedData = useSelector((state) => state.subscription.subscriptionData);
  const [subscriptionExpired, setSubscriptionExpired] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [details, setDetails] = useState(undefined);
  const [plan, setPlan] = useState("")
  const [price, setPrice] = useState("")
  const [prevData,setPrevData] = useState(true)
  const loggedInUser = useSelector((state) => state.user.userData);
  const company = useSelector((state) => state.company.currentlySelectedcompanyData);
  const phone_number = useSelector((state) => state.usermanager.currentUser.mob_number);
  const org = useSelector((state) => state.org.orgData);

  const openPreview = () => {

    if (plan === "") {
      return toast.error("Plan not Selected", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
   
    setSubscribePopup(true)
    setDetails({
      address: localStorage.getItem("address"),
      name: localStorage.getItem("name"),
      department: localStorage.getItem("department"),
      position: localStorage.getItem("position"),
      plan: plan,
      price: price,
      country: localStorage.getItem("country"),
      state: localStorage.getItem("state"),
      city: localStorage.getItem("city"),
      country_code: localStorage.getItem("country_code"),
      renew_date: localStorage.getItem("renew_date"),
      paymentstatus: localStorage.getItem("paymentstatus"),
      address_type:localStorage.getItem("address_type"),
    })
  }
  
  const setDefault = () => {
    localStorage.setItem("name", "");
    localStorage.setItem("position", "");
    localStorage.setItem("department", "");
    localStorage.setItem("plan", "");
    localStorage.setItem("price", "");
  }

  const proceed = () => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${day}.${month}.${year + 1}`;
    localStorage.setItem("expires", currentDate)
    subscribe()
  }
  const planDetails = (plan, price) => {
    setPlan(plan)
    setPrice(price)
  }

  useEffect(() => {
    if (subscribedData && subscribedData[org_code] && subscriptionExpired != null && !subscriptionExpired) {
      navigate("/my-company")
    }
  }, [orgDetails.status != 'accepted'])

  useEffect(() => {
    if (org_code && subscribedData && subscribedData[org_code]) {
      checkIsSubscriptionExpired(subscribedData[org_code])
      if(prevData){
        setPlan(subscribedData[org_code].plan)
        setPrice(subscribedData[org_code].price)
        setPrevData(false)
      }
    }
  })


  const checkDateBeforeMonth = () => {
    let oneMonthBefore = false
    let today = moment(new Date()).format("DD-MM-YYYY");
    let renew_date = subscribedData[org_code].renew_date
    today = today.slice(6) + "-" + today.slice(3, 5) + "-" + today.slice(0, 2)
    renew_date = renew_date.slice(6) + "-" + renew_date.slice(3, 5) + "-" + renew_date.slice(0, 2)

    let todayEpoch = new Date(today).getTime() / 1000
    let renewEpoch = new Date(renew_date).getTime() / 1000
    const monthGap = 30 * 24 * 60 * 60
    if (renewEpoch - todayEpoch <= monthGap) {
      oneMonthBefore = true
    }
    return oneMonthBefore
  }

  function checkIsSubscriptionExpired(data) {
    let today = moment(new Date()).format("MM-DD-YYYY")
    today = new Date(today)
    let renew_date = data.renew_date.split('-')
    renew_date = new Date(renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2])
    setSubscriptionExpired(today > renew_date)
    if (today < renew_date && !checkDateBeforeMonth() && data.paymentstatus == 'success') navigate("/subscriptions/subscriptions-renew")
    return (today > renew_date)
  }

  const subscribe = async () => {
    console.log("deDetailss",details);
    let d = details
    if (details.plan) {
      let pl = {}
      if (SubscriptionData[d.plan.toLowerCase()]) {
        pl = SubscriptionData[d.plan.toLowerCase()]
      } else {
        pl = SubscriptionData['basic']
      }
      d.job_quota = pl.job_quota
      d.job_duration_day = pl.job_duration_day
      d.internship_quota = pl.internship_quota
      d.internship_duration_month = pl.internship_duration_month
      d.employe_equota = pl.employe_equota
      d.graduate_talent_search = parseInt(SubscriptionData.talent_acquisition.features[0][d.plan.toLowerCase()]);
    }

    const { data } = await companySubscription(org_code, d);
    if (data && data.status === 1) {
      subsctipationsProxy.setcount(org_code)
      const { data } = await getCompanySubscription(org_code);

      if (data && data.status === 1) {
        let localObj = {}
        localObj[org_code] = data.data
        dispatch(updateSubscriptionData(localObj))
      }
      console.log("dataData",data?.data)
      const body = {
        "client_reference_item": [
          { "id": details?.plan, "type": "Subscription", "user_id": loggedInUser.id, "subscription_id": data.data[0]['sub_id'], "url": window.location.origin.toString() + "/subscriptions/subscriptions-renew", "base_url": process.env.REACT_APP_BASE_URL + "company/api/internship-status-after-payment/" }
        ],
        "customer": {
          "name": details?.name,
          "phone": phone_number,
          "email": `${org && org?.email}`,
          "designation": details?.position,
          "department": details?.department,
          "company_name": company?.name,
          "company_ssm_num": company?.ssm_number,
          "address": [
            {
              "address": details?.address,
              "city": details?.city,
              "postcode": details?.country_code,
              "state": details?.state,
              "country": details?.country
            }
          ]
        },
        "items": [
          {
            "price_id": details?.price,
            "quantity": "1"
          }
        ],
        "callback_url": `${process.env.REACT_APP_FRONT_END_URL}subscriptions/subscriptions-renew`
      }
      console.log("before Payment")

      await paymentGateWay(body).then((res) => {
        window.location.replace(res?.data?.payment_link_url)
        setDefault()
      })
    }
  };

  return (
    <>
      {subscribePopup === true ? <SubscriptionPopup setOpen={setSubscribePopup} isOpen={subscribePopup} closePopup={closePopup} proceed={proceed} /> : ""}
      <MainLayout>
        <HeaderText>
          {t("Purchase")}
        </HeaderText>
        <CardsWrapper style={{ padding: "0px" }}>
          <SubscriptionPlanTable planDetails={planDetails} />
          <SubscriptionForm openPreview={openPreview} />
        </CardsWrapper>
      </MainLayout>
    </>
  )
}

export default SubscriptionsPageThree
