import React,{useState} from 'react'
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Table from 'react-bootstrap/Table';
import MainLayout from '../../layouts/mainLayout/mainLayout';
import './commentsSection.css';

const FlexDiv = styled.div`
  display: flex;
`;

const CardsWrapper = styled(FlexDiv)`
  background: ${Colors.light};
  border-radius: 16px;
  margin-bottom: 15px;
 
  @media (max-width: 500px) {
    padding: 10px;
    display: block;
  }
`;

function CommentsSection  (props)  {
    
    let data = props.comments 

    try {
      data = JSON.parse(data)
    } catch (error) {
    }
    
    return (
    
      <>
      {(data)?<CardsWrapper className="mt-3">
       <table style={{background:"#F84B9C",borderRadius:"16px",overflow:"hidden"}}>
          <thead style={{background:props?.secondaryColor}}>
            <tr >
                <th className="comments">Comments</th>
                <th className="comments">Created</th>
            </tr>
          </thead>
          <tbody style={{paddingBottom:"25px"}}>
            {data.length> 0 ? 
            data.map((comment,index)=>
            <>
                <tr key={index} style={{background:"white",borderRadius:`${data?.length-1==index ? "16px" :"5px"}`}}>
            <td>{comment.comment}</td>
            <td>{comment.date}</td>
            </tr>
            </>
            ) : null
            }
            
            
          </tbody>        
        </table>     

    </CardsWrapper>:""}
      </>
    
    
    
  )
}

export default CommentsSection
