import React, { useEffect } from 'react';
import * as d3 from 'd3';
import { scaleLinear } from "d3-scale";
import { lineRadial, curveCardinal } from "d3-shape";

export default function DashedProgressChart({ percentage }) {
    const domRef = React.useRef();

    useEffect(() => {
        const radius = 100,
            padding = 20,
            radians = 2 * Math.PI;

        const dimension = 300,
            points = 60,
            points2 = 60;

        const angle = scaleLinear()
            .domain([0, points - 1])
            .range([0, radians]);
        const percentageParsed = percentage / 100;
        const line = lineRadial()
            .curve(curveCardinal).radius(radius)
            .angle((d, i) => {
                if (i < (points * percentageParsed + 1)) {
                    return angle(i);
                }
            });
        const line2 = lineRadial()
            .curve(curveCardinal).radius(radius)
            .angle((d, i) => {
                if (i < (points2 * 1 + 1)) {
                    return angle(i);
                }
            });
        domRef.current.innerHTML = '';
        const container = d3.select(domRef.current);
        container.selectAll().remove();
        const svg = container.append("svg")
            .attr("width", dimension)
            .attr("height", dimension)
            .append("g")
            .attr("transform", `translate(${dimension / 2},${dimension / 2}) `);

        var defs = svg.append('defs');

        var gradient = defs.append('linearGradient')
            .attr('id', 'svgGradient')
            .attr('x1', '37%')
            .attr('x2', '63%')
            .attr('y1', '0%')
            .attr('y2', '100%')
            .attr("transform", `rotate(-3)`)


        gradient.append('stop')
            .attr('class', 'start')
            .attr('offset', '2.07%')
            .attr('stop-color', '#004B84')
            .attr('stop-opacity', 1);

        gradient.append('stop')
            .attr('class', 'end')
            .attr('offset', '98.84%')
            .attr('stop-color', '#D04C8D')
            .attr('stop-opacity', 1);


        svg.append("path").datum(d3.range(points2))
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke-dasharray", "9 9")
            .attr("stroke-width", "35px")
            .attr("stroke", "#E8E8E8")
            .attr("d", line2)


        svg.append("path").datum(d3.range(points))
            .attr("class", "line")
            .attr("fill", "none")
            .attr("stroke-dasharray", "9 9")
            .attr("stroke-width", "35px")
            .attr("stroke", "url(#svgGradient)")
            .attr("d", line)
        svg.append("text")
            .text(`${percentage}%`)
            .attr("class", "center-text")
            .style("text-anchor", "middle")
            .style("alignment-baseline", "central")
            .attr('font-family', 'Oswald')
            .attr('font-style', 'normal')
            .attr('font-weight', '700')
            .attr('font-size', '48px')
            .attr('fill', '#004B84');
    }, [percentage]);

    return <span ref={domRef}></span>;
}