import React, { useState } from 'react'
import JobsFilter from '../../components/jobPortal/jobsFilter'
import MainLayout from '../../layouts/mainLayout/mainLayout'
import rightarrow from "../../assets/icons/arrow_right.png";
import JobApplicants from '../../components/jobPortal/applicants';
import JobPopup from '../../components/jobPortal/jobPopup';
import { useNavigate } from 'react-router-dom';
import "./job.css"
import { useTranslation } from "react-i18next";
export default function FilterJobs() {
    const navigate = useNavigate();
    const [popup, setPopup] = useState(false)
    const setOpen = ()=>{
          setPopup(false)
    }
    const openPopUp =()=>{
        setPopup(true)
    }
    const { t, i18n } = useTranslation();
    return (
        <>
            {popup === true ? <JobPopup setOpen={setOpen} isOpen={popup} /> : null}
            <MainLayout>
                <div className="breadcrumb">
                    <p onClick={() => navigate('/jobs')}>{t("Jobs")}</p>
                    <img src={rightarrow} alt="img" className="jobImage"  />
                    <p onClick={() => navigate("/jobs/viewjobs")} style={{ cursor: "pointer" }}>{t("View Job")}</p>
                    <img src={rightarrow} alt="img" className="jobImage"  />
                    <p className="jobText" >{t("View Applicants")}</p>
                </div>
                <JobsFilter />
                <JobApplicants openPopUp={openPopUp} />
            </MainLayout>
        </>
    )
}
