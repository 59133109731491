import React, { useEffect } from 'react'
import MainLayout from '../../layouts/mainLayout/mainLayout'
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from "styled-components"
import CompanyCard from '../../components/jobPortal/companyCard';
import JobForm from '../../components/jobPortal/jobForm';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import "./job.css"
import { useTranslation } from "react-i18next";
const HeaderText = styled.h2`
  margin-top:2%;
  margin-bottom:1%;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;
const SmallText = styled.h4`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #858585;
`;
const Wrapper = styled.div`
  background:#FFFFFF;
  border-radius:12px;
  padding:24px 42px;
`;
export default function EditJob() {
  const navigate = useNavigate();
  const job = useSelector((state) => state.job_reducer.id);
  useEffect(async () => {
    console.clear()
    if((job && job.length==0) || job==undefined){
      navigate('/jobs')
    }
  });
  const { t, i18n } = useTranslation();
    return (
        <MainLayout>
            <div className="breadcrumb">
                <p onClick={() => navigate('/jobs')}>{t("Jobs")}</p>
                <img src={rightarrow} alt="img" className="jobImage"  />
                <p className="jobText">{t("Edit Job")}</p>
            </div>
            <Wrapper id="jobform">
                <HeaderText>{t("Edit Job")}</HeaderText>
                <SmallText>{t("Create a new Job posting by filling in all the details.")}</SmallText>
                <CompanyCard />
                <JobForm type="edit"/>
            </Wrapper>
        </MainLayout>
    )
}
