import React from "react";
import Loader from "../loader/loader";
import "./charloader.css";
export default function ChartLoader() {
  return (
    <div className="chartOne">
      <Loader  color="red" size="lg" className="chartLoader" />
    </div>
  );
}
