import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/addNewIntern.png";
import PlusIcon from "../../assets/plus.png";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import './MyComponent.css'; 
import { Col, Row } from "react-bootstrap";
import DashboardCards from "../dashboardCards/dashboardCards";
import NewDashboardCards from "../newDashboardCards/newDashboardCards";
const AddNewInternWrapper = styled.div`
  background: transparent;
  border-radius: 10px;
  // padding: 15px;
  margin: 50px 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 50px 0 50px 0;
    > img {
      display: block;
      margin-left: auto;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    > img {
      display: block;
      margin-left: auto;
      bottom: -15%;
    }
  }
`;

const BackgroundContainer = styled.div`
//   background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  // padding: 15px;
//   margin: 50px 0 50px 0;
  display: flex;
  // position: absolute;
  z-index: -1;
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  > img {
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    margin: 0px 0 50px 0;
    > img {
      display: block;
      margin-left: auto;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
    margin: 0px;

    > img {
      display: block;
      margin-left: auto;
      bottom: -15%;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 0px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 0px;
  }
`;
const StaticImg = styled.img`
  position: absolute;
  z-index: -1;
  left: -10px;
  /* top: 22%; */
  width: 208px;
  height: 200px;
  top: 5%;
  cursor: default !important;
  @media (max-width: 500px) {
    display: inline-block;
    // bottom:5%;
    top:350px;
    left: -45px;
  }
`;
const ContentWrapper = styled.div`
  margin: 0 20px;
  margin-left: 115px;
  max-width: 543px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 10px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #ffffff;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
    margin-left: 0px;
    padding: 5px;
    max-width: 743px;
  }
  //  @media (min-width: 1200px) {
  //   max-width:543;
  // }
  //  @media (min-width: 1300px) {
  //   max-width:653;
  // }
  // @media (min-width: 1600px) {
  //   max-width:1035px;
  //   width:650px;
  // }
  // @media (min-width: 1700px) {
  //   max-width:1035px;
  //   width:700px;
  // }
  @media (min-width: 1800px) {
    max-width: 1035px;
    width: 776px;
  }
  // @media (max-width: 1640px) {
  //     margin-left: 35px;
  //     max-width:321px;
  // }
`;

export default function InternshipJobSection({ analyticalData, setUnAuth, coin, userUsedCoins }) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.company.currentOrgData);
  const companyData = useSelector((state) => state.company.currentlySelectedcompanyData);
  const companies = useSelector((state)=> state.company.companyData)
  const companyStatus = companyData.status
 const tooltipContent = "Company approval is pending <br/>with mynext Admin"
  const { t, i18n } = useTranslation();
  const GridWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
`;
  var parentLeftMargin = i18n.language === "en" ? "-186px" : "-222px";
  var parentMinWidth = i18n.language === "en" ? "265px" : "285px";

  var customStyle =
    i18n.language === "en"
      ? { display: "flex", marginBottom: "19px", marginLeft: "111px" }
      : {
          display: "flex",
          marginBottom: "19px",
          paddingLeft: "206px",
          paddingRight: "42px",
        };

  const onNavigate = () => {
    if (userRole?.user_details.internship_modify !== "Y") {
      return setUnAuth(true);
    }
    navigate("/internships/new-intern");
  };
  console.log("internshipJonSect",analyticalData)
  const [randomID, setRandomID] = useState(String(Math.random()))
  const internshipArr = [
    {title: "All", label: "allInternships", datatip: "Total number of internship postings created (All statuses)",value: analyticalData.all_internships},
    { title: "Accepted Internships", label: "accceptedInternships", datatip: "Number of approved internship postings", value: analyticalData.approved_internships},
    { title: "Pending Verification", label: "pendingInternships", datatip: "Number of internship postings pending verification from Admin", value: analyticalData.pending_internships},
    { title: "Expired", label: "expiredInternships", datatip: "Number of expired internship postings",value: analyticalData.expired_internships},
   ];

   const jobsArr = [
    {title: "All", label: "allJobs", datatip: "Total number of job postings created (All statuses)",value: analyticalData.all_jobs},
    { title: "Active Jobs", label: "activeJobs", datatip: "Number of active job postings", value: analyticalData.active_jobs},
    { title: "Non - Active Jobs", label: "inactiveJobs", datatip: "Number of inactive job postings", value: analyticalData.non_active_jobs},
    { title: "Expired", label: "expiredJobs", datatip: "Number of expired job postings",value: analyticalData.expired_jobs},
   ];
  return (
    <>
     <style type="text/css">
        {`

.containerFirstTwo {
    width: 100%;
     height: 320px;
      padding-top: 7px;
       padding-bottom: 12px;
        padding-left: 23px;
         padding-right: 31px;
        //  margin-left: 20px;
          background: #ECE9E9; 
          border-radius: 30px; 
          overflow: hidden; 
          flex-direction: column; 
          justify-content: flex-start; 
          align-items: flex-start; 
          gap: 11px; 
          display: inline-flex;
          @media (max-width: 500px) {
            width: 100%;
            gap: 10px;
            height: 420px;
          }
  }




`
}

      </style>
     <BackgroundContainer>
<Row>
    <Col lg="12">
      <GridWrapper>
      <div className="containerFirstTwo">
    <div style={{
        color: "#000",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        marginBottom:"20px"
      }}>{t("Internships")}</div>
  <GridWrapper>
  {internshipArr.map((obj, index) => (
        <NewDashboardCards name={obj.title} value={obj.value} key={obj.title + index} datatip={obj.datatip} label={obj.label}/>
      ))}
  </GridWrapper>
       
      </div>
      <div className="containerFirstTwo">
    <div style={{
        color: "#000",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        marginBottom:"20px"
      }}>{t("Jobs")}</div>
  <GridWrapper>
  {jobsArr.map((obj, index) => (
        <NewDashboardCards name={obj.title} value={obj.value} key={obj.title + index} datatip={obj.datatip} label={obj.label}/>
      ))}
  </GridWrapper>
       
      </div>
      </GridWrapper>
    {/* <Row>
      <Col lg="6">
      <div className="containerFirstTwo">
    <div style={{
        color: "#000",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        marginBottom:"20px"
      }}>Internships</div>
  <GridWrapper>
  {arr.map((obj, index) => (
        <DashboardCards name={obj.title} value={"7"} key={obj.title + index} datatip={obj.datatip}/>
      ))}
  </GridWrapper>
       
      </div>
      </Col>
      <Col lg="6">
      <div className="containerFirstTwo">
      <div style={{
        color: "#000",
        fontSize: "30px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        marginBottom:"20px"
      }}>Jobs</div>
  <GridWrapper>
  {arr.map((obj, index) => (
        <DashboardCards name={obj.title} value={"7"} key={obj.title + index} datatip={obj.datatip}/>
      ))}
  </GridWrapper>
       
      </div>
      </Col>
    </Row> */}
    
     
    </Col>
</Row>
     {/* <Row>
        <Col lg="6">
        <div className="sectionTwoContainer">

        </div>
        </Col>
        <Col lg="6">
        <div className="sectionTwoContainer">

        </div>
        </Col>
      </Row> */}
     </BackgroundContainer>
    </>
    
  );
}
