import { Col } from 'react-bootstrap';
import styled from 'styled-components';
import AlertModal from './alertModal';
import CustomButton from "../button/button";
import { useTranslation } from "react-i18next";

const Message = styled.div`
  font-size: 22px;
  padding-bottom: 5px;
  margin-bottom:20px
`;


const ConfirmationDialog = ({ id, show, onAccept, closeModal, message }) => {
    const {t} = useTranslation();

    let content = <>
        <Message>{message}</Message>
        <div>
            <Col md="6" />
            <Col
                md="12"
                className="d-flex justify-content-center align-items-center"
            >
                <CustomButton
                    width="100px"
                    margin="20px 0px 30px 0px"
                    name={t("Yes")}
                    onClick={() => onAccept(id)}
                />
                <CustomButton
                    secondary
                    width="100px"
                    name={t("No")}
                    color="#FFFFFF"
                    margin="20px 0px 30px 20px"
                    onClick={closeModal}
                />
            </Col>
        </div>
    </>
    return (<AlertModal show={show} close={closeModal} content={content} />)
}

export default ConfirmationDialog;