import { useState } from "react";

import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import CustomModal from "../modal/modal";
import Button from "../button/button";
import Loader from "../loader/loader";
import { updateValues} from "../../pages/registerorganisation/reducer";
import ErrorField from "../errorField/errorField";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import UploadIconEnglish from "../../assets/uploadEnglish.png";
import UploadIconMalay from "../../assets/uploadMalay2.png";

const Uploader = styled.img`
  cursor: pointer;
  width: 100%;
  object-fit: contain;
 
`;
const UploadWrapper = styled.div`
  max-width: 565px;
 margin-left:auto;
 margin-right:auto;
  @media (max-width: 767px) {
   
    text-align: none;
  }
`;
const Input = styled.input`
  position: absolute;
  left: 0;
  right: -0px;
  top: 0px;
  bottom: 0px;
  display: block;
  width: 100%;
  opacity: 0;
`;
const InputWrapper = styled.div`
  position: relative;
  width: 500px;
  @media (max-width: 767px) {
    width: 200px;
  }
`;

// const initState={
//   obj_content:'',
// }
export default function UploadLogo(props) {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  // const [is_disable, setIsDisable] = useState(true);
  const { t, i18n } = useTranslation();

  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.organisation.value);
  var imgType = '';
  const pickImage = (event) => {
    setFormErrors("");

    if (event.target.files[0].size <= 5000000) {

      setImage(event.target.files[0]);
      props.onUploadChange(event)
    } else {

      setFormErrors(t("File size exceeds maximum limit."));

    }

  };

  const uploadImage = async () => {
    setLoading(true);
    const formdata = new FormData();
    formdata.append("obj_content", image);
    var temp_name_arr = image.name.split('.')
    imgType = temp_name_arr[1];
    if (imgType === 'png' || imgType === 'jpeg' || imgType === 'jpg') {
      onUploaded(image);
    } else {
      toast.error(t("The uploaded file is not valid"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setLoading(false)
  };

  const onUploaded = (image) => {
    setLoading(false);
    // const { status, message } = data;
    // if (status === 1) {
    const newVal = {
      ...userDetail,
      // domain: data.data.domain,
      logo_path: image,
    };
    setFormErrors("");
    dispatch(updateValues(newVal));
    props.onClose()
    // } else {
    //   setFormErrors(message);
    // }
  };

  return (
    <CustomModal open={props.open} onCloseModal={props.onClose} className="upload-modal" style={{ maxWidth: 700 }}>
      <UploadWrapper>
        <InputWrapper>
          <Input
            type="file"
            name="file"
            accept="image/x-png,image/jpeg"
            onChange={pickImage}
            //onChange={(e) => props.onUploadChange(e)}
          />
          <Uploader
            src={image ? URL.createObjectURL(image) : i18n.language === "en" ? UploadIconEnglish:UploadIconMalay}
            alt="uploader logo"
          />
        </InputWrapper>
        {formErrors && <ErrorField err={t(formErrors)} />}
        {image ? loading ? (
          <Loader />
        ) : (
          <Button width="auto" name={t('Upload')} onClick={uploadImage} />
        ) : ''}
      </UploadWrapper>
    </CustomModal>
  );
}
