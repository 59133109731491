// import { getFutureOfWorkDrilldown } from "../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

export const getFutureOfWorkDrilldown = () => {
    const getFuture = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Cognitive": {
                    "id": 11,
                    "totalTalents": 695,
                    "results": {
                        "low": 0,
                        "moderate": 571,
                        "high": 116
                    }
                },
                "Interpersonal": {
                    "id": 12,
                    "totalTalents": 695,
                    "results": {
                        "low": 0,
                        "moderate": 586,
                        "high": 101
                    }
                },
                "Self Leadership": {
                    "id": 13,
                    "totalTalents": 695,
                    "results": {
                        "low": 0,
                        "moderate": 585,
                        "high": 102
                    }
                },
                "Digital": {
                    "id": 14,
                    "totalTalents": 695,
                    "results": {
                        "low": 0,
                        "moderate": 555,
                        "high": 132
                    }
                }
            }
        }
    }
    return getFuture
}

export default Ad2DrilldownHOC(getFutureOfWorkDrilldown, [
    {
        title: "Cognitive",
        dataKey: "Cognitive",
        url: 'cognitive',
        showDrilldown: true,
        info: "The Cognitive domain relates to the ability to obtain relevant information and use critical thinking to solve problems, make clear plans and manage one’s time and communicate effectively.<br/><br/>It also relates to the ability to apply mental flexibility – creativity, imagination, seeing things from different perspectives and learning – to adapt to change and meet one’s goals."
    },
    {
        title: "Interpersonal",
        dataKey: "Interpersonal",
        url: 'interpersonal',
        showDrilldown: true,
        info: "The Interpersonal domain describes the ability to relate to others and build relationships with colleagues and clients, enhancing motivation and engagement so that others work together towards inspiring goals.<br/><br/>It also relates to the skills required for effective teamwork including listening to, coaching, collaborating alongside and problem solving with, a diverse group of people."
    },
    {
        title: "Self Leadership",
        dataKey: "Self Leadership",
        url: 'self-leadership',
        showDrilldown: true,
        info: "This domain relates to the ability to use self-awareness, self-management including looking after one’s wellbeing and building stress resilience, and the setting and meeting important goals.<br/><br/>This domain also refers to the skills required for entrepreneurship including the ability to take appropriate risks, be innovate and infuse one’s work with energy, passion and optimism."
    },
    {
        title: "Digital",
        dataKey: "Digital",
        url: 'digital',
        showDrilldown: true,
        info: "This domain relates to the ability to use digital technologies in the course of one’s work to gather information, communicate with others and enhance insights into relevant trends.<br/><br/>A key part of this domain refers to the ability to adapt to new technologies as the pace of change will be accelerated in the future of work."
    },
], '/psychometric-analytics/future-of-work/');
