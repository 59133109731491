import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Utils } from "../analyticalDashboardPage/utilities";
import NoDataChartContainer from "../analyticalDashboardPage/noDataFoundChart";
import DashedProgressChart from "../analyticalDashboardPage/charts/dashboardProgressChart";
import { CompletionRateTitle } from "../analyticalDashboardPage/adCardStyles";
import ChartLoader from "../analyticalDashboardPage/chartLoader";
import { Ad2Card } from "../analyticalDashboardPage/adCard";


export function Ad2CompletionRateEnhanced({totalEmployees, filterParams, data }) {
  const { t, i18n } = useTranslation();
  const chartData = data && data.data;
  const status = data && data.status;
  const navigate = useNavigate();
console.log("dataDagta",data);
  const handleNavigate = Utils.handleNavigate.bind(this, navigate);
  const totalUsers = chartData && chartData.totalUsers;
  const personalityMotivation = chartData && chartData["five-factor"];
  const workInterest = chartData && chartData["interest-riasec"];
  const workValues = chartData && chartData["work-values"];
  const employability = chartData && chartData.employability;
  const futureOfWork = chartData && chartData['21-century-skills'];
  const englishProficiency = chartData && chartData['english-test'];
  const totalSum = personalityMotivation
console.log("tttolta",totalUsers,personalityMotivation);
    const columns = [
      {
        name: t("Personality & Motivation"),
        selectorKey: 'personalityMotivation',
        selector: (row) => row.personalityMotivation,
        sortable: false,
      },
      {
        name: t("Work Interest"),
        selectorKey: 'workInterest',
        selector: (row) => row.workInterest,
        sortable: false,
      },
      {
        name: t("Work Values"),
        selectorKey: 'workValues',
        selector: (row) => row.workValues,
        sortable: false,
      },
      {
        name: t("Employability"),
        selectorKey: 'employability',
        selector: (row) => row.employability,
        sortable: false,
      },
      {
        name: t("Future of Work"),
        selectorKey: 'futureOfWork',
        selector: (row) => row.futureOfWork,
        sortable: false,
      },
      {
        name: t("English Proficiency"),
        selectorKey: 'englishProficiency',
        selector: (row) => row.englishProficiency,
        sortable: false,
      },
    ];

  return (
    <>
      <Ad2Card
        title={t("Completion Rate")}
        showButton={true}
        data={[{
          personalityMotivation,
          workInterest,
          workValues,
          employability,
          futureOfWork,
          englishProficiency,
        }]}
        columns={columns}
        exportFileName="completion_rate"
        sum={personalityMotivation}
        sumpersonal={totalEmployees}
        status={status}

      >
        {/* <Col
        md="4"
        className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
        onClick={() => handleNavigate("./personality-and-motivation")}
      > */}
        <div onClick={()=>handleNavigate("./personality-and-motivation",filterParams ? {department:filterParams?.value} : {})} style={{width:"100%", textAlign:"-webkit-center"}}>
        <DashedProgressChart percentage={Utils.convertAndRoundToPercentage(personalityMotivation / totalEmployees)} />
          {/* <CompletionRateTitle>{t("Personality & Motivation")}</CompletionRateTitle> */}
        </div>
         
        {/* </Col> */}
        {/* <Col
          md="4"
          className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
          onClick={() => handleNavigate("/psychometric-analytics/work-interest")}
        >
          <DashedProgressChart percentage={Utils.convertAndRoundToPercentage(workInterest / totalSum)} />
          <CompletionRateTitle>{t("Work Interest")}</CompletionRateTitle>
        </Col>
        <Col
          md="4"
          className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
          onClick={() => handleNavigate("/psychometric-analytics/work-values")}
        >
          <DashedProgressChart percentage={Utils.convertAndRoundToPercentage(workValues / totalSum)} />
          <CompletionRateTitle>{t("Work Values")}</CompletionRateTitle>
        </Col>
        <Col
          md="4"
          className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
          onClick={() => handleNavigate("/psychometric-analytics/employability")}
        >
          <DashedProgressChart percentage={Utils.convertAndRoundToPercentage(employability / totalSum)} />
          <CompletionRateTitle>{t("Employability")}</CompletionRateTitle>
        </Col>
        <Col
          md="4"
          className="d-flex justify-content-center align-items-center flex-column cursor-pointer"
          onClick={() => handleNavigate("/psychometric-analytics/future-of-work")}
        >
          <DashedProgressChart
            percentage={Utils.convertAndRoundToPercentage(futureOfWork / totalSum)}
          />
          <CompletionRateTitle>{t("Future Of Work")}</CompletionRateTitle>
        </Col>
        <Col
          md="4"
          className="d-flex justify-content-center align-items-center flex-column pe-auto cursor-pointer"
          onClick={() => handleNavigate("/psychometric-analytics/english-proficiency")}

        >
          <DashedProgressChart percentage={Utils.convertAndRoundToPercentage(englishProficiency / totalSum)} />
          <CompletionRateTitle>{t("English Proficiency")}</CompletionRateTitle>
        </Col> */}
      </Ad2Card>
    </>
  );
}
