export const staticContent = {
  login: {
    name: "Welcome to mynext Company",
    description:
      "A proprietary national smart system designed for professional development.",
    descriptionStyle: {
      fw: "normal",
      lh: "25px",
      color: "#707070",
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },  
  signup: {
    name: "Register your Account!",
    description:
      "Please provide the following details to register yourself successfully",
    descriptionStyle: {
      fw: "normal",
      lh: "25px",
      color: "#707070",
      
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },
  register: {
    name: "Register your Organisations",
    description:
      "Organisations registered on mynext company platform will be able to post internship(s) for students. Upon submission of this form, your registration will be reviewed by TalentCorp.",
    descriptionStyle: {
      fw: "500",
      lh: "22px",
      color: "#858585",
    },
    nameStyle: {
      size: "28px",
      lh: "38px",
    },
  },
  newPerson: {
    name: "Add Contact Person",
    description:
      "We will need some basic information about you before we get started.",
    descriptionStyle: {
      fw: "500",
      lh: "22px",
      color: "#858585",
    },
    nameStyle: {
      size: "28px",
      lh: "38px",
    },
  },
  searchOrganisation: {
    name: "Search for Existing Company",
    description:
      "If your company has registered before, you can search from the list below and skip the organisation registration process.",
    descriptionStyle: {
      fw: "normal",
      lh: "20px",
      color: "#707070",
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },
  uploadOrganisationLogo: {
    name: "Upload Organisation Logo",
    description:
      "We will need some basic information about you before we get started.",
    descriptionStyle: {
      fw: "normal",
      lh: "20px",
      color: "#707070",
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },
  forget: {
    name: "Reset Password",
    description: "Please provide the following details to reset your password.",
    descriptionStyle: {
      fw: "normal",
      lh: "20px",
      color: "#707070",
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },
  reset_password: {
    name: "Reset Password",
    description: "",
    descriptionStyle: {
      fw: "normal",
      lh: "20px",
      color: "#707070",
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },
  change_password: {
    name: "Reset Password",
    description: "Please provide the following details to reset your password.",
    descriptionStyle: {
      fw: "normal",
      lh: "20px",
      color: "#707070",
    },
    nameStyle: {
      size: "30px",
      lh: "41px",
    },
  },
};
