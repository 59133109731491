import { configureStore } from "@reduxjs/toolkit";

import organisationDataReducer from "./pages/registerorganisation/reducer";
import modalReducer from "./components/modal/modalReducer";
import newPersonReducer from "./pages/newPerson/newPersonReducer";
import orgDataReducer from "./pages/login/reducer";
import userDataReducer from "./pages/login/userReducer";
import myCompanyReducer from "./pages/myCompany/reducer";
import newLocationReducer from "./components/addNewLocation/reducer";
import newContactPersonReducer from "./components/AddContact/reducer";
import internDataReducer from "./pages/newIntern/reducer";
//import universityModalReducer from "./reducers/universityModalReducer";
import applicantAssessmentsModalReducer from "./pages/applicantAssessments/reducer";
import pageReducer from "./pages/reducer";
import userManagementReducer from "./pages/userManagement/userManagerReducer"
import subscriptionReducer from "./pages/Subscriptions/reducer"
import studentModalReducer from "./reducers/studentModalReducer";
import employeeManagementReducer from "./reducers/employeeManagementReducer";
import messageReducer from "./reducers/messageReducer";
import jobReducer from './reducers/jobReducer'
import masterDataReducer from "./slices/master.slice";
import  talentDataReducer  from "./pages/TalentSearch/talentReducer";
import drillDownStudentTableReducer from "./pages/analyticalDahsboardEnhanced/drilldownRecuder/drillDownStudentTableReducer";

import detailModalReducer from "./reducers/detailModalReducer";
import  SideBarDataReducer  from "./components/sidebar/sidebarReducer";
import userManagerReducer from "./pages/userManagement/userManagerReducer";
import imageReducer from "./pages/jobPortal/imageReducer";

export const store = configureStore({
  reducer: {
    organisation: organisationDataReducer,
    modal: modalReducer,
    newPerson: newPersonReducer,
    imageBase64: imageReducer,
    drillDownTableReducer:drillDownStudentTableReducer,
    talentReducer: talentDataReducer,
    org: orgDataReducer,
    user: userDataReducer,
    company: myCompanyReducer,
    usermanager: userManagerReducer,
    location: newLocationReducer,
    intern: internDataReducer,
    contact: newContactPersonReducer,
    applicantAssessmentsModal: applicantAssessmentsModalReducer,
    page: pageReducer,
    userManager: userManagementReducer,
    subscription: subscriptionReducer,
    studentModalReducer:studentModalReducer,
    message: messageReducer,
    employeeManagementReducer: employeeManagementReducer,
    job_reducer:jobReducer,
    masterData: masterDataReducer,
    detailModal: detailModalReducer,
    sideBar: SideBarDataReducer,
  },
});
