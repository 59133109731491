import React from "react";
import styled from "styled-components";
import { SectionWrapper, HeaderText, BoldText } from "./common";
import Input from "../input/input";
import ErrorField from "../errorField/errorField";
import "./companyBranding.css";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import VideoMaker from "./videoMaker";
import { t } from "i18next";

const VideoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  @media (max-width: 480px) {
    grid-template-columns: 1fr;
  }
`;
const UrlSection = styled.div`
  background: #f3f3f3;
  border-radius: 8px;
  //    width: 80%;
  height: 48px;
  max-width: 549px;
`;
export default function Profile({
  onVideoUrl,
  url,
  dataVideo,
  setVideoDetail,
  error,
}) {
  const [showInputEle, setShowInputEle] = useState(false);
  return (
    <SectionWrapper>
      <Row>
        <Col lg={11}>
          <HeaderText>{t("Profile Video")}</HeaderText>
        </Col>
        <Col lg={1}>
          <HeaderText onClick={() => setShowInputEle(true)}>{t("Edit")}</HeaderText>
        </Col>
      </Row>
      <hr />
      <VideoMaker
        value={dataVideo}
        handleChange={(e) => setVideoDetail(e)}
        handleDoubleClick={() => setShowInputEle(true)}
        handleBlur={() => setShowInputEle(false)}
        showInputEle={showInputEle}
      />
      <VideoWrapper>
        <BoldText className="textStyling">{t("Video URL:")}</BoldText>
        <UrlSection>
          <Input
            style={{ background: "#f3f3f3" }}
            placeholder=""
            onChange={(e) => onVideoUrl(e)}
            defaultValue={url}
          />
          {error && <ErrorField err={t(error)} />}
        </UrlSection>
      </VideoWrapper>
    </SectionWrapper>
  );
}
