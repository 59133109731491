import config from './config.js';
import MessageParser from './messageParser.jsx';
import ActionProvider from './actionProvider.jsx';
import "react-chatbot-kit/build/main.css";
import Chatbot from 'react-chatbot-kit';
import { useState } from 'react';
// import { ReactComponent as BotProfile } from "../../assets/svg/bot.svg";
import BotProfile from "../../assets/botProfile.png"
import CloseIcon from "../../assets/closeIcon.png";
import "./chatbot.css";
import { t } from 'i18next';

export default function ChatbotComponent() {
  const [showBot, toggleBot] = useState(false);
  const [showBotPrompt, toggleBotPrompt] = useState(false);
  return (
    <div className="app-chatbot-container">
      <div style={{ maxWidth: "500px" }}>
        {showBot && (
          <Chatbot
            config={config}
            actionProvider={ActionProvider}
            messageParser={MessageParser}
            headerText={
              <>
                <div><img src={BotProfile} className='height-40px' /></div>
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", color: "#D04C8D" }}>
                  <div style={{ fontSize: "12" }}>{t("Ask mynextBot")}</div>
                  <div style={{ fontSize: "9", fontWeight: "100" }}>{t("I'm here to help you anytime")}</div>
                </div>
                <div onClick={() => toggleBot((prev) => !prev)}>
                  <img src={CloseIcon} height={"15px"} />
                </div>
              </>
            }
            placeholderText={t("Type something...")}
          />
        )}
        {!showBot &&
          <button
            className="app-chatbot-button"
            onClick={() => {
              toggleBot((prev) => !prev);
              toggleBotPrompt(false);
            }}
            onMouseEnter={() => toggleBotPrompt(true)}
            onMouseLeave={() => toggleBotPrompt(false)}
          >
            <div>
              <img src={BotProfile} className="chatbot-float height-40px" />
            </div>
          </button>
        }
        {showBotPrompt &&
          <div className="chatbotprompt-float">{t("Ask mynextBot a question!")}</div>
        }
      </div>
    </div>
  );
};