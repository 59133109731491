import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";

import {
  Image,
  SubHeading,
  ContentText,
  MainText,
} from "../../components/talentProfile/common";

import { Col, Row, Container } from "react-bootstrap";
import WorkCompetencyFactorChart from "../studentOceanCharts/workCompetencyFactorChart";
import PersonalityAspectsChart from "../studentOceanCharts/personalityAspectsChart";
import ThirtyFacetsChart from "../studentOceanCharts/thirtyFacetsChart";
import OrganizationalFitFactor from "../studentOceanCharts/organizationalFitFactorChart";
import PredictiveReliabilityFactorChart from "../studentOceanCharts/predictiveReliabilityFactorChart";
import { Ad2StudentPersonalityProfile } from "../studentOceanCharts/studentPersonalityChart";
import { Ad2StudentWorkInterest } from "../studentOceanCharts/studentWorkInterestChart";
import { Ad2EmployabilityFactors } from "../studentOceanCharts/studentEmployabilityChart";
import { Ad2FutureOfWork } from "../studentOceanCharts/studentFutureOfWorkChart";
import { Ad2EnglishProficiency } from "../studentOceanCharts/studentEnglishChart";
import { Ad2StudentWorkValues } from "../studentOceanCharts/studentWorkValuesChart";
import { updateModal } from "../../reducers/detailModalReducer";
import StudentModal from "../../components/studentModal/studentModal";
import InterestProfileModal from "./modals/interestProfileModal/interestProfileModal";
import PreferencesModal from "./modals/preferencesModal/preferencesModal";
import EmployabilityFactorModal from "./modals/employabilityFactorModal/employabilityFactorModal";
import ValueProfileModal from "./modals/valueProfileModal/valueProfileModal";
import FutureLearningModal from "./modals/futureLearningModal/futureLearningModal";
import FutureWorkFactorModal from "./modals/futureWorkFactorModal/futureWorkFactorModal";
import EnglishProficiencyModal from "./modals/englishProficiencyModal/englishProficiencyModal";
import MotivationLevelModal from "./modals/motivationLevelModal/motivationLevelModal";
import DetailModal from "../../components/detailModal/detailModal";
import { t } from "i18next";
import {
  getDataWorkEmployability,
  getDataWorkEnglishTest,
  getDataWorkFutureWorks,
  getDataWorkInterest,
  getDataWorkPersonality,
  getDataWorkValues,
  getMyCyOverall,
  getOverViewProfile,
  getTalentOceanReport,
} from "../../services/apiCalls";
import { Link, useLocation } from "react-router-dom";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { useSearchParams, useParams } from "react-router-dom";
import FiveFactorChartSlider from "../studentOceanCharts/fiveFactorSlider";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import avatar from "../../assets/avatar.png";
import { PDFExport } from "@progress/kendo-react-pdf";
import { useReactToPrint } from "react-to-print";
import { PDFViewer, PDFDownloadLink } from "@react-pdf/renderer";
import AssessmentPdfReport from "../../components/assessmentPDFReport/assessmentPdfReport";

const ModalContainer = styled(Container)`
  @media (min-width: 480px) {
    width: 80vw;
  }

  @media (min-width: 768px) {
    width: 100vw;
  }

  /* Medium devices (desktops, 992px and up) */
  @media (min-width: 992px) {
    width: 892px;
    // padding: 25px 70px;
  }

  /* Large devices (large desktops, 1200px and up) */
  @media (min-width: 1200px) {
    width: 892px;
    // padding: 25px 70px;
  }
`;
const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 24px 42px;
  gap: 24px;
  padding-bottom: 72px;
  @media (max-width: 500px) {
    padding: 16px;
    flex-direction: column;
  }
`;

const MainWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
  padding: 24px;
`;
const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
const HrLine = styled.div`
  width: 100%;

  height: 10px;
  border-bottom: 0.6px solid #c4c4c4;
`;
const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const ProfileWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;
const ContentCard = styled.div`
  display: grid;
  grid-template-columns: 2fr 3fr;
`;

const ButtonWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: end;
`;
const Button = styled.button`
  background: linear-gradient(90deg, #fa4aa1 0%, #504f8c 100%);
  border-radius: 128px;
  outline: none;
  border: 0px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  color: #fff;
  padding: 13px 0px;
  margin: 15px 0;
  width: 192px;
`;
export default function ViewStudentJob() {
  const dispatch = useDispatch();
  const [dataUser, setUserdata] = useState({});
  const [modalMode, setModalMode] = useState("");
  const [workValues, setWorkValues] = useState([]);
  const [workValuesView, setWorkValuesView] = useState([]);
  const [workValuesLabels, setWorkValuesLabels] = useState([]);
  const [workValuesColors, setWorkValuesColors] = useState([]);
  const [workValuesData, setWorkValuesData] = useState([]);

  const [workInterest, setWorkInterest] = useState([]);
  const [workInterestView, setWorkInterestView] = useState([]);
  const [workInterestLabels, setWorkInterestLabels] = useState([]);
  const [workInterestColors, setWorkInterestColors] = useState([]);
  const [workInterestData, setWorkInterestData] = useState([]);

  const [workPersonality, setWorkPersonality] = useState([]);
  const [workPersonalityData, setWorkPersonalityData] = useState([]);
  const [workPersonalityView, setWorkPersonalityView] = useState([]);
  const [workPersonalityLabels, setWorkPersonalityLabels] = useState([]);
  const [workPersonalityColors, setWorkPersonalityColors] = useState([]);

  const [workEmployability, setWorkEmployability] = useState([]);
  const [workEmployabilityData, setWorkEmployabilityData] = useState([]);
  const [workEmployabilityView, setWorkEmployabilityView] = useState([]);
  const [workEmployabilityDetails, setWorkEmployabilityDetails] = useState([]);

  const [workFutureWorks, setWorkFutureWorks] = useState([]);
  const [workFutureWorksView, setWorkFutureWorksView] = useState([]);
  const [workFutureWorksData, setWorkFutureWorksData] = useState([]);

  const [workEnglishTest, setWorkEnglishTest] = useState([]);
  const [workEnglishTestView, setWorkEnglishTestView] = useState([]);

  const [facetData, setFacetData] = useState([]);
  const [orgFitData, setOrgFitData] = useState([]);
  const [personalityAspectData, setPersonalityAspectData] = useState([]);
  const [reliabilityData, setReliabilityData] = useState([]);
  const [competencyData, setCompetencyData] = useState([]);
  const [profileDetails, setProfileDetails] = useState({});
  const [profileImage, setProfileImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [fromInternship, setFromInternship] = useState(false);
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const pdfExportComponent = React.useRef(null);
  const resumePdfTemplateRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => resumePdfTemplateRef.current,
  });
  const imageBaseData = useSelector((state)=> state.imageBase64.imageBaseData)
  const isImageReady = useSelector((state)=> state.imageBase64.isImagesReady)

  // useEffect(() => {
  //   if (resumeDetails?.overview?.scope_of_study && handleCvPrint) {
  //     handlePrint();
  //     downloadComplete(false);
  //   }
  // }, [handleCvPrint, resumeDetails?.overview?.scope_of_study]);

  const downloadFile = () => {
    if (pdfExportComponent.current) {
      pdfExportComponent.current.save();
      // isMyCvDownLoad && downloadComplete(true);
    }
  };
  // async function convertImageUrlToBase64(imageUrl) {
  //   const response = await fetch(imageUrl);
  //   const blob = await response.blob();
  //   const reader = new FileReader();
  //   reader.readAsDataURL(blob);

  //   return new Promise((resolve, reject) => {
  //     reader.onloadend = () => {
  //       resolve(reader.result);
  //     };
  //     reader.onerror = reject;
  //   });
  // };
  // useEffect(() => {
  //   setProfileImage(convertImageUrlToBase64('https://mynext.oss-ap-southeast-3.aliyuncs.com/mf%2Favatar%2FIdris_Elba-4580_cropped-min_f82jVk6.jpg?OSSAccessKeyId=LTAI5tMsPhRRbavncAz4Mdo7&Expires=1692169343&Signature=gyQvJYhQEDsdfqj3FHfRPCJ%2BqB4%3D'));
  // },[]);
  console.log(profileImage);
  const downloadPDF = () => {
    const capture = document.querySelector(".testpdf");
    setLoader(true);
    html2canvas(capture).then((canvas) => { 
      const part1 = document.createElement("canvas");
      const part2 = document.createElement("canvas");

      const context1 = part1.getContext("2d");
      const context2 = part2.getContext("2d");

      const width = canvas.width;
      const height = canvas.height;
      const splitHeight = 1950;
      part1.width = width;
      part1.height = splitHeight - 100;

      part2.width = width;
      part2.height = height - splitHeight + 100;

      context1.drawImage(
        canvas,
        0,
        0,
        width,
        splitHeight,
        0,
        0,
        width,
        splitHeight
      );
      context2.drawImage(
        canvas,
        0,
        splitHeight - 50,
        width,
        height - splitHeight,
        0,
        0,
        width,
        height - splitHeight
      );

      // Convert the canvas parts to images
      const image1 = part1.toDataURL("image/png");
      const image2 = part2.toDataURL("image/png");

      // const imgData = canvas.toDataURL("image");
      const doc = new jsPDF({
        orientation: "potrait",
        unit: "pt",
        format: "a4",
      });
      doc.internal.scaleFactor = 30;
      doc.setFillColor(245);
      doc.rect(0, 0, 210, 700, "F");
      const componentWidth1 = doc.internal.pageSize.getWidth();
      const componentHeight1 = doc.internal.pageSize.getHeight();

      doc.addImage(
        image1,
        "PNG",
        0.5,
        0.5,
        componentWidth1 - 1,
        componentHeight1 - 2
      );
      // doc.addImage(image1, "JPEG", 0.5, 0.5, componentWidth-1, componentHeight-2);
      // doc.addImage(image1, "JPG", 0.5, 0.5, componentWidth-1, componentHeight-2);
      doc.addPage();
      doc.internal.scaleFactor = 30;
      doc.setFillColor(245);
      doc.rect(0, 0, 210, 700, "F");
      const componentWidth2 = doc.internal.pageSize.getWidth();
      const componentHeight2 = doc.internal.pageSize.getHeight();
      doc.addImage(
        image2,
        "PNG",
        0.5,
        0.5,
        componentWidth2 - 1,
        componentHeight2 - 2
      );
      // doc.addImage(image2, "JPEG", 0.5, 0.5, componentWidth-1, componentHeight-2);
      // doc.addImage(image2, "JPG", 0.5, 0.5, componentWidth-1, componentHeight-2);
      setLoader(false);
      doc.save(`${dataUser?.users?.full_name}.pdf`);
    });
  };
  const getDataValue = async (id) => {
    const { data } = await getDataWorkValues(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
      var datasValues = [];
      if(Array.isArray(data?.data) && data?.data.length === 0){
        setWorkValuesData({data:[],status:"noDataFound"});
      }else{
        Object.keys(data?.data).map((e, i) => {
          datasLabel.push(t(e));
          datasValue.push(data?.data[e]["points"]);
          datasValues.push(data?.data[e]["values"]);
  
          var x = Math.floor(Math.random() * 256);
          var y = 25 + Math.floor(Math.random() * 256);
          var z = 50 + Math.floor(Math.random() * 256);
          datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
        });
        setWorkValuesLabels(datasLabel);
        setWorkValues(datasValue);
        setWorkValuesColors(datasColor);
        setWorkValuesView(datasValues);
        setWorkValuesData(data?.data);
      }
   
    }
  };
  const getDataIntrest = async (id) => {
    const { data, status } = await getDataWorkInterest(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
      console.log("DataStatusWorkInterest",status);
      if(Array.isArray(data?.data) && data?.data.length === 0){
        setWorkInterestData({data:[],status:"noDataFound"});
      }else{
        Object.keys(data?.data).map((e, i) => {
          datasLabel.push(t(e));
          datasValue.push(data?.data[e]["points"]);
          var x = Math.floor(Math.random() * 256);
          var y = 25 + Math.floor(Math.random() * 256);
          var z = 50 + Math.floor(Math.random() * 256);
          datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
        });
        console.log("DataEctract",data?.data)
        setWorkInterestLabels(datasLabel);
        setWorkInterest(datasValue);
        setWorkInterestColors(datasColor);
        setWorkInterestView(data?.data);
        setWorkInterestData(data?.data);
      }
     
    }
  };
  const getDataPersonality = async (id) => {
    const { data } = await getDataWorkPersonality(id);
    if (data?.status === 1) {
      var datasLabel = [];
      var datasValue = [];
      var datasColor = [];
    if( Array.isArray(data?.data) && data?.data?.length === 0){
      setWorkPersonalityData({data:[],status:"noDataFound"});
    }else{
      Object.keys(data?.data).map((e, i) => {
        datasLabel.push(t(e));
        datasValue.push(data?.data[e]["points"]);
        var x = Math.floor(Math.random() * 256);
        var y = 25 + Math.floor(Math.random() * 256);
        var z = 50 + Math.floor(Math.random() * 256);
        datasColor.push("rgb(" + x + "," + y + "," + z + ",0.5)");
      });

      setWorkPersonalityData(data?.data);
      setWorkPersonalityLabels(datasLabel);
      setWorkPersonality(datasValue);
      setWorkPersonalityColors(datasColor);
      setWorkPersonalityView(data?.data);
    }
   
    }
  };
  const getDataEmployability = async (id) => {
    const { data } = await getDataWorkEmployability(id);
    if (data?.status === 1) {
      // console.clear();
      console.log("dataEmployemnrt",data);
      if(Array.isArray(data?.data?.domains) && data?.data?.domains.length === 0){
        setWorkEmployabilityData({data:[],status:"noDataFound"});
      }else{
        setWorkEmployabilityDetails(data);
        setWorkEmployabilityView(data?.data?.domains);
        setWorkEmployability(data?.data?.totalScore?.points);
        setWorkEmployabilityData(data?.data);
      }

    }
  };

  const getDataFutureWorks = async (id) => {
    const { data } = await getDataWorkFutureWorks(id);
    if (data?.status === 1 && data?.data?.domains) {
      if(Array.isArray(data?.data?.domains) && data?.data?.domains.length === 0){
        setWorkFutureWorksData({data:[],status:"noDataFound"});
      }else{
        console.log("DataData",data?.data);
        setWorkFutureWorksView(data?.data?.domains);
        // setWorkFutureWorks(data?.data?.totalScore?.points);
        setWorkFutureWorksData(data?.data);
      }

    }
  };
  const getDataEnglishTest = async (id) => {
    const { data } = await getDataWorkEnglishTest(id);
    if (data?.status === 1) {
      console.log("DataEnglish",data);
      if(Array.isArray(data?.data) && data?.data?.length === 0 || data?.data?.General.points === 0){
        setWorkEnglishTestView({data:[],status:"noDataFound"});
      }else{
        setWorkEnglishTestView(data);
        setWorkEnglishTest(
          data?.data?.General && data?.data?.General.points !== undefined
            ? data?.data?.General.points
            : "0"
        );
      
      }
   
    }
  };
  const getOceanReport = async (id) => {
    const { data } = await getTalentOceanReport(id);
    if (data?.status === 1) {
      const OCEANData = data?.data?.data[0];
      setFacetData(OCEANData["30 - Facets"]);
      setOrgFitData(OCEANData["Organizational Fit Factor"]);
      setPersonalityAspectData(OCEANData["Personality Aspects"]);
      setReliabilityData(OCEANData["Predictive Reliability Factor"]);
      setCompetencyData(OCEANData["Work Competency Factor"]);
    }
  };

  const getMyCv = async (id) => {
    const { data } = await getMyCyOverall(id);
    if (data?.status === 1) {
      setUserdata(data.data);
    }
  };
  const getProfileDetails = async (id) => {
    const { data } = await getOverViewProfile(id);
    if (data?.status === 1) {
      setProfileDetails(data.data);
    }
  };

  useEffect(() => {
    const pathName = location && location.pathname;

    if (pathName.includes("internships")) {
      setFromInternship(true);
      let a = window.location.href.split(
        "/internships/details/viewAssessment/"
      );

      if (a.length > 1) {
        let id = a[1];
        getMyCv(id);
        getDataValue(id);
        getDataIntrest(id);
        getDataPersonality(id);
        getDataEmployability(id);
        getDataFutureWorks(id);
        getDataEnglishTest(id);
        getOceanReport(id);
        getProfileDetails(id);
      }
    } else {
      setFromInternship(false);
      let a = window.location.href.split("/employeeManagement/student/");

      if (a.length > 1) {
        let id = a[1];
        getMyCv(id);
        getDataValue(id);
        getDataIntrest(id);
        getDataPersonality(id);
        getDataEmployability(id);
        getDataFutureWorks(id);
        getDataEnglishTest(id);
        getOceanReport(id);
        getProfileDetails(id);
      }
    }
  }, []);
  const search = useLocation().search;
  const internship_id = new URLSearchParams(search).get("id");

  const getModalContent = () => {
    switch (modalMode) {
      case "interest":
        return (
          <InterestProfileModal
            data={workValuesView}
            labels={workValuesLabels}
          />
        );
      case "preferences":
        return (
          <PreferencesModal
            result={workPersonalityData}
            data={workPersonality}
            labels={workPersonalityLabels}
            colors={workPersonalityColors}
          />
        );
      case "employability":
        return <EmployabilityFactorModal data={workEmployabilityDetails} />;
      case "valueProfile":
        return <ValueProfileModal data={workEmployabilityView} />;
      case "futureLearning":
        return <FutureWorkFactorModal data={workFutureWorksView} />;
      case "motivationLevel":
        return <MotivationLevelModal data={workInterestView} />;
      case "englishProficiency":
        return <EnglishProficiencyModal data={workEnglishTestView} />;
      default:
        break;
    }
  };
  return (
    <>
      <MainLayout>
        {fromInternship ? (
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to="/internships"
                style={{ textDecoration: "none", color: "#ACACAC" }}
              >
                {t("Internships")} &nbsp; &#62;
              </Link>{" "}
              <Link
                to={"/internships/details?id=" + internship_id}
                style={{ textDecoration: "none", color: "#ACACAC" }}
                className="mx-2"
                // onClick={showDetails}
              >
                {t("Internship Detail")} &nbsp; &#62;
              </Link>
              <Link
                to={"/internships/applicants?id=" + internship_id}
                style={{ textDecoration: "none", color: "#ACACAC" }}
                className="mx-2"
                // onClick={showDetails}
              >
                {t("View Applicants")} &nbsp; &#62;
              </Link>
              <div className="" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("View Assessment")}
              </span>
            </div>
          </div>
        ) : (
          <div role="presentation">
            <div
              className="d-flex mb-5"
              style={{
                fontSize: "1.1rem",
                fontWeight: "400",
                marginLeft: "15px",
              }}
            >
              <Link
                to={"/employeeManagement"}
                style={{ textDecoration: "none", color: "#ACACAC" }}
                className="mx-2"
              >
                {t("Employee Management")} &nbsp; &#62;
              </Link>
              <div className="" style={{ color: "#ACACAC" }}></div>
              <span style={{ textDecoration: "none", color: "#D04C8D" }}>
                {t("Talent Profile")}
              </span>
            </div>
          </div>
        )}
        <div style={{ display: "flex" }}></div>

        <MainWrapper>
          <Wrapper>
            <Image
              src={
                dataUser?.users?.profile_pic
                  ? dataUser?.users?.profile_pic
                  : avatar
              }
            />
            <ProfileWrapper>
              <MainText style={{ marginBottom: "0px" }}>
                {dataUser?.users?.full_name}
              </MainText>
              <hr />
              <ContentCard>
                <SubHeading>{t("Scope of Study")}:</SubHeading>{" "}
                <ContentText>{dataUser?.users?.scope_abbr}</ContentText>
                <SubHeading>{t("Education Institution")}:</SubHeading>{" "}
                <ContentText>{profileDetails?.insti_name_abbr}</ContentText>
                <SubHeading>{t("Address")}:</SubHeading>{" "}
                <ContentText>
                  {dataUser?.users?.city_abbr}, {dataUser?.users?.state_abbr},{" "}
                  {dataUser?.users?.curr_country_abbr},{" "}
                  {dataUser?.users?.postalcode}
                </ContentText>
                <SubHeading>{t("Primary e-mail id")}:</SubHeading>{" "}
                <ContentText>{profileDetails?.email}</ContentText>
                <SubHeading>{t("Primary mobile no")}:</SubHeading>{" "}
                <ContentText>{dataUser?.users?.mob_number}</ContentText>
              </ContentCard>
            </ProfileWrapper>
          </Wrapper>
          <br />

          <SeparatorDiv>
            <HrLine />
            <Separator>{t("Assessment Report")}</Separator>
            <HrLine />
            {/* {openAssessmentReport
              ? <IconButton><UpIcon /></IconButton>
              : <IconButton><DownIcon /></IconButton>
            } */}
          </SeparatorDiv>

          <Row>
            <Col lg="12" style={{ marginTop: "20px" }}>
              <Ad2StudentPersonalityProfile
                onViewMoreClick={() => {
                  setModalMode("preferences");
                  dispatch(updateModal(true));
                  console.log("Clicked personality details");
                }}
                data={workPersonalityData}
              />
            </Col>
            <Col lg="6" style={{ marginTop: "20px" }}>
              <Ad2StudentWorkValues
                onViewMoreClick={() => {
                  setModalMode("interest");
                  dispatch(updateModal(true));
                  console.log("Clicked workvalue");
                }}
                data={workValuesData}
              />
            </Col>
            <Col lg="6" style={{ marginTop: "20px" }}>
              <Ad2StudentWorkInterest
                onViewMoreClick={() => {
                  setModalMode("motivationLevel");
                  dispatch(updateModal(true));
                  console.log("Clicked motivation");
                }}
                data={workInterestData}
              />
            </Col>

            <Col lg="12" style={{ marginTop: "20px" }}>
              <Ad2EmployabilityFactors
                data={workEmployabilityData}
                onViewMoreClick={() => {
                  setModalMode("employability");
                  dispatch(updateModal(true));
                  console.log("Clicked employ");
                }}
              ></Ad2EmployabilityFactors>
            </Col>
            <Col lg="12" style={{ marginTop: "20px" }}>
              <Ad2FutureOfWork
                onViewMoreClick={() => {
                  setModalMode("futureLearning");
                  dispatch(updateModal(true));
                  console.log("Clicked future");
                }}
                data={workFutureWorksData}
              ></Ad2FutureOfWork>
            </Col>
            <Col
              lg="12"
              style={{
                marginTop: fromInternship ? "30px" : "60px",
                marginBottom: "20px",
              }}
            >
              <Ad2EnglishProficiency
                onViewMoreClick={() => {
                  setModalMode("englishProficiency");
                  dispatch(updateModal(true));
                  console.log("Clicked english");
                }}
                data={workEnglishTestView}
              ></Ad2EnglishProficiency>
            </Col>
          </Row>

          <br />

          <SeparatorDiv>
            <HrLine />
            <Separator>{t("OCEAN Report")}</Separator>
            <HrLine />
          </SeparatorDiv>

          <>
            <Row lg="12">
              <Col lg="6" style={{ marginTop: "20px" }}>
                <WorkCompetencyFactorChart data={competencyData}>
                  {" "}
                </WorkCompetencyFactorChart>
              </Col>
              <Col lg="6" style={{ marginTop: "20px" }}>
                <PersonalityAspectsChart
                  data={personalityAspectData}
                ></PersonalityAspectsChart>
              </Col>
            </Row>

            <Row lg="12">
              <ThirtyFacetsChart data={facetData}> </ThirtyFacetsChart>
              {/* <FiveFactorChartSlider data={workPersonalityData}></FiveFactorChartSlider> */}
              {/* <Col lg="6" style={{ marginTop: "20px" }}>
                  <OrganizationalFitFactor
                    data={orgFitData}
                  ></OrganizationalFitFactor>
                </Col> */}
              <Col lg="8" style={{ marginTop: "20px", marginBottom: "25px" }}>
                <PredictiveReliabilityFactorChart
                  data={reliabilityData}
                ></PredictiveReliabilityFactorChart>
              </Col>
            </Row>
          </>
          <ButtonWrapper>
          { <PDFDownloadLink
              document={
                <AssessmentPdfReport
                // imageData={image}
                imageData={imageBaseData}
                  data={{
                    userData: {
                      profile_pic: dataUser?.users?.profile_pic
                      ? dataUser?.users?.profile_pic
                      : avatar,
                      full_name: dataUser?.users?.full_name,
                      scope_abbr: dataUser?.users?.scope_abbr,
                      insti_name_abbr: profileDetails?.insti_name_abbr,
                      address: [
                        dataUser?.users?.city_abbr,
                        dataUser?.users?.state_abbr,
                        dataUser?.users?.curr_country_abbr,
                        dataUser?.users?.postalcode,
                      ].join(", "),
                      email: profileDetails?.email,
                      mobile: dataUser?.users?.mob_number,
                    },
                    personality: workPersonalityData,
                    workValues: workValuesData,
                    workInterest: workInterestData,
                    employability: workEmployabilityData,
                    futureOfWork: workFutureWorksData,
                    englishProficiency: workEnglishTestView,
                    ocean: {
                      workCompetency: competencyData,
                      personalityAspect: personalityAspectData,
                      thirtyFacets: facetData,
                      predictiveReliability: reliabilityData,
                    },
                  }}
                />
              }
              fileName={`${dataUser?.users?.full_name} - Assessment Report`}
            >
              {({ blob, url, loading, error }) =>
              // {
              //   console.log("dddddERr",error)
              //   if (loading) return 'Loading document...';
              //   if (error) return 'Error occurred while loading PDF!';
              //   return 'Download PDF';
              // }
    

                loading ? (
                  <Button disabled={!(loading === true)}>
                    {t("Loading document...")}
                  </Button>
                ) : imageBaseData && Object.keys(imageBaseData).length > 2 ?
                
                (
                  <Button disabled={!(loading === false)}>
                    {t("Download")}
                  </Button>
                )
                :
                
                (
                  <Button disabled={!(loading === true)}>
                    {t("Loading document...")}
                  </Button>
                )
              }
            </PDFDownloadLink>}
          </ButtonWrapper>
        </MainWrapper>
      </MainLayout>
      <DetailModal>
        <ModalContainer>
          <Row>
            <Col>{getModalContent()}</Col>
          </Row>
        </ModalContainer>
      </DetailModal>
    </>
  );
}
