import { Colors } from "../../utilities/colors";
import { Stepper, Step, StepLabel, Typography } from '@mui/material';
import styled from "styled-components";
import { useEffect, useState } from "react";
import { getSkillGap } from "../../services/apiCalls";
import SkillGapEvaluationForm from "./skillGapEvaluationForm";
import Button from "../../components/button/button";
import { useSelector } from "react-redux";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { createTheme, makeStyles, ThemeProvider, withStyles } from "@material-ui/core";


export default function SkillGapLandingPage(){

    const CardWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 20px;
 
  padding: 2rem;
  margin: 2rem 2rem 0 2rem;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const HeaderText = styled.h2`
font-style: normal;
font-weight: 500;
font-size: 28px;
line-height: 38px;
// display: flex;
// justify-content: center;
margin: 0;
color: ${Colors.dark};
`;
const Description = styled.p`
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 22px;
margin: 5px 0;
max-width: 800px;
color: #858585;
padding-bottom:20px;
`;
const FlexWrapper = styled.div`
display: flex;
align-items: center;
`;
const ButtonWrapper = styled(FlexWrapper)`
justify-content: end;
margin-top: 40px;
@media (max-width: 600px) {
  flex-direction: column;
}
@media (max-width: 1024px) {
  justify-content: end;
}
`;
const [activeStep, setActiveStep] = useState(0);
const [internsData, setInternsData] = useState([]);
const [internshipData, setInternshipData] = useState([]);
const [allInternshipFilledData, setAllInternshipfilledData] = useState([]);
const [internship_id, setInternshipId] = useState();
const org_code = useSelector((state) => state.company.currentlySelectedcompanyData);
const userData = useSelector((state) => state.user.userData);
const items = ['Item 1', 'Item 2', 'Item 3', 'Item 4']; // Your list of items

console.log("userData",userData,"org_code",org_code)
const handleNext = () => {
  setActiveStep((prevActiveStep) => prevActiveStep + 1);
};

const useStyles = makeStyles((theme) => ({
  stepper: {
    backgroundColor: Colors.pink, // Change the background color of the stepper
  },
  step: {
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-active': {
      color: Colors.pink,
    },  
    '& .css-1u4zpwo-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed': {
      color: Colors.pink,
    },
  },
  stepLabel: {
    '&.MuiStepLabel-active': {
      color: 'your-active-color-here', // Change the color of the active step label
    },
    '&.MuiStepLabel-completed': {
      color: 'your-completed-color-here', // Change the color of completed step label
    },
  },
}));
const ColoredStep = withStyles((theme) => ({
  active: {
    backgroundColor: Colors.pink, // Change the background color of the active step circle
  },
}))(Step);
const classes = useStyles();

const handleBack = () => {
  setActiveStep((prevActiveStep) => prevActiveStep - 1);
};

function ItemComponent({ item }) {
    return (
      <div>
        <Typography>{item}</Typography>
        {/* Add your component logic here using the 'item' prop */}
      </div>
    );
  }
  useEffect(() => {
    document.body.classList.add("bg-body-cover");
  }, []);

 const filledFormValues = (dataVal) => {
console.log("consoleLog",dataVal);
let allinternData = [...allInternshipFilledData];
allinternData.push(dataVal);
setAllInternshipfilledData(allinternData);
console.log("AllInternshipData",allInternshipFilledData)
 }


const fetchData = async (id) => {
  // setLoader(true);
 
      const { data } = await getSkillGap(id);
    // console.log("dtata",data.data.intern_data,id);
    setInternsData(data.data.intern_data);
    setInternshipData(data.data.internship_data);
    
    };

    useEffect(()=>{
      const currentURL = window.location.href;
      const regex = /\/([^\/]+)\/?$/
      const parts = currentURL.match(regex);
      const id = parts ? parts[1] : null;
      // const currentURL = "http://localhost:3000/skill-gap-evaluation/f7d04522-778b-4f51-ad1c-71cdc2fd005a/";
      // const parts = currentURL.split('/'); // Split the URL by '/'
// const id = parts[parts.length - 2]; 
setInternshipId(id);
console.log(id,"currentUrl");
if(id){
  fetchData(id);

}
    },[])

    return (<>
    <MainLayout>

    <CardWrapper>
<HeaderText>{"Skills Gap Evaluation"}</HeaderText>
<Description>{"Complete your endorsement for National Structured Internship Programme (MySIP) , by filling in the internship module."}</Description>
<Stepper  activeStep={activeStep} alternativeLabel>
        {internsData?.length > 0 ? internsData.map((item, index) => (
          <Step className={classes.step} key={index}>
            <StepLabel>{`${ internsData[index]?.intern_name}`}</StepLabel>
          </Step>
        )): <></>}
      </Stepper>


      <div>
        {/* {activeStep === items.length ? (
          <div>
            <Typography>All steps completed - you're finished!</Typography>
          </div>
        ) : ( */}
          <div>
            {/* Render the ItemComponent for the current step */}
            {/* <ItemComponent item={items[activeStep]} /> */}
            <SkillGapEvaluationForm org_code={org_code} userData={userData}  internship_id={internship_id} internData={internsData[activeStep]} setPassValues={filledFormValues} internshipData={internshipData} handleNext={handleNext} allInternshipsData={allInternshipFilledData} isFinished={activeStep === internsData.length - 1}></SkillGapEvaluationForm>
            <div>
         
                
              {/* <Button
                disabled={activeStep === 0}
                variant="contained"
                color="default"
                onClick={handleBack}
              >
                Back
              </Button>

              <Button
                variant="contained"
                color="primary"
                onClick={handleNext}
              >
                {activeStep === items.length - 1 ? 'Finish' : 'Next'}
              </Button> */}
            </div>
          </div>
        {/* // )} */}
      </div>
      {/* <ButtonWrapper>
                <Button
                width="198px"
                onClick={handleNext}
                name={"Next"}
              />
                </ButtonWrapper> */}
    </CardWrapper>
    </MainLayout>
    
    </>

    )
}