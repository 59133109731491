import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  userDetails: {},
  tokenUsed: "",
  tokenAvailable: ""
};

export const talentDataReducer = createSlice({
  name: "talentReducer",
  initialState,
  reducers: {
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    updateTokenUsed: (state, action) => {
      state.tokenUsed = action.payload;
    },
     updateTokenAvailable: (state, action) => {
      console.log("tttuser",action.payload)
      state.tokenAvailable = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { updateUserDetails,updateTokenAvailable,updateTokenUsed } =
  talentDataReducer.actions;

export default talentDataReducer.reducer;
