import Select from "react-select";
import { ReactComponent as Cross } from "../../assets/svg/cross.svg";
import { components } from "react-select";
import { cloneDeep } from "lodash";
import React, { Component } from "react";

import { t } from "i18next";

const getCustomStyles = (backgroundColor, props) => {
  const controlObj = cloneDeep({
    padding: 5,
    borderRadius: 40,
  });
  const isInvalid =
    props.className &&
    props.className.length &&
    props.className.includes("invalid");
  if (isInvalid) {
    controlObj.border = "1px solid red";
  } else {
    controlObj.border = "1px solid #DCDCDC";
  }
  const object = {
    menu: (provided, state) => ({
      ...provided,
      zIndex: 101,
    }),
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused
        ? `${isInvalid ? "red" : "#FF60B1"}`
        : "#cccccc",
      boxShadow: state.isFocused
        ? `0 0 0 1px ${isInvalid ? "red" : "#FF60B1"}`
        : "none",
      "&:hover, :focus, :active": {
        boxShadow: `0 0 0 1px ${isInvalid ? "red" : "#FF60B1"} !important`,
        borderColor: `${isInvalid ? "red" : "#FF60B1"} !important`,
      },
      ...controlObj,
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? undefined
          : isSelected
          ? "#D04C8D"
          : isFocused
          ? "#ffe9f4"
          : undefined,

        ":active": {
          ...styles[":active"],
          backgroundColor: !isDisabled
            ? isSelected
              ? "#D04C8D"
              : "#ffe9f4"
            : undefined,
        },
      };
    },
    indicatorSeparator: () => ({
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      color: "inherit",
    }),
    singleValue: (base) => ({
      ...base,
      color: "inherit",
    }),
  };
  if (backgroundColor) {
    controlObj.backgroundColor = backgroundColor;
  }
  if (props.isMulti) {
    object.multiValue = (styles, { data }) => {
      return {
        ...styles,
        backgroundColor: "#FFFFFF",
        borderRadius: "33px",
        margin: "0px",
        marginRight: "5px",
        padding: "4px 12px",
        "&:hover, :focus, :active": {
          opacity: "1",
          backgroundColor: "#FFFFFF",
        },
      };
    };
    object.indicatorContainer = (styles) => ({
      ...styles,
      padding: "0px",
      height: "auto",
      width: "35px",
    });
    object.clearIndicator = (styles, { data }) => {
      return {
        ...styles,
        opacity: "0.8",
        height: "auto",
        width: "35px",
        padding: "0px",
        backgroundColor: "##ACACAC",
        background: "transparent",
        "&:hover, :focus, :active": {
          opacity: "1",
          padding: "0px",
          height: "auto",
          width: "35px",
          background: "transparent",
        },
      };
    };
    object.multiValueLabel = (styles, { data }) => {
      return {
        ...styles,
        fontFamily: "General Sans",
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "15px",
        lineHeight: "20px",
        color: "#202020",
        marginRight: "5px",
      };
    };
    object.multiValueRemove = (styles, { data }) => {
      return {
        ...styles,
        opacity: "0.8",
        height: "auto",
        width: "22px",
        backgroundColor: "##ACACAC",
        background: "transparent",
        "&:hover, :focus, :active": {
          opacity: "1",
          background: "transparent",
        },
      };
    };
    object.placeholder = (styles, { data }) => {
      return {
        ...styles,
        marginLeft: "15px",
      };
    };
    object.valueContainer = (styles) => ({
      ...styles,
      padding: "7px 10px",
    });
  }
  return object;
};

export default function Selector({
  userCustomStyles = null,
  backgroundColor = null,
  userOptions = null,
  currentValue,
  ...props
}) {
  const customStyles = getCustomStyles(backgroundColor, props);
  let finalStyles = customStyles;
  if (userCustomStyles) {
    finalStyles = { ...customStyles, ...userCustomStyles };
  }
  let finalOptions = [];
  if (userOptions) {
    finalOptions = userOptions;
  }
  const MultiValueRemove = (props) => {
    return (
      <components.MultiValueRemove {...props}>
        <Cross />
      </components.MultiValueRemove>
    );
  };
  const ClearIndicator = (props) => {
    return (
      <components.ClearIndicator {...props}>
        <Cross />
      </components.ClearIndicator>
    );
  };
  const ValueContainer = ({ children, getValue, ...props }) => {
    let maxToShow = 1;
    var length = getValue().length;
    let displayChips = React.Children.toArray(children).slice(0, maxToShow);
    let shouldBadgeShow = length > maxToShow;
    let displayLength = length - maxToShow;

    return (
      <components.ValueContainer {...props}>
        <div
          style={{ display: "flex", alignItems: "center" }}
          onClick={() => {}}
        >
          {!props.selectProps.inputValue && (
            <div id="chipSets" className="disChips">
              {displayChips}
            </div>
          )}
          {shouldBadgeShow && (
            <div className="circle">
              <p className="text">{displayLength}</p>
            </div>
          )}
        </div>
      </components.ValueContainer>
    );
  };
  const componentsMap = {};
  if (props.isMulti) {
    componentsMap.MultiValueRemove = MultiValueRemove;

    componentsMap.ValueContainer = ValueContainer;
  }
  return (
    <Select
      placeholder={t("Select...")}
      styles={finalStyles}
      options={finalOptions}
      value={currentValue}
      {...props}
      {...(props.isMulti ? { components: componentsMap } : {})}
    />
  );
}
