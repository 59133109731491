import { useState } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { updateLoader } from "../../pages/myCompany/reducer";
import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schemaObj } from "./staticContent";
import ErrorField from "../errorField/errorField";
import {
 
  onCompanyBrandingImageUpdate,
} from "../../services/apiCalls";

import Loader from "../loader/loader";
import { Grid } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { ReactComponent as UploadIcon } from "../../assets/share.svg";

const ModalWrapper = styled.div`
  padding: 10px;
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 900;
    font-size: 18px;
    line-height: 22px;
    color: #696969;
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: 200px;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 0px;
`;

const Uploader = styled.div`
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    cursor: pointer !important;
  }
  > LinkText {
    cursor: pointer !important;
  }
`;
const ImageWrapper = styled.div`
  text-align: center;
`;

const FileWrapper = styled.div`
  cursor: pointer;
  position: relative;
  text-align: center;
  padding: 2rem;
  border: 2px dashed #d04c8d;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Image = styled.img`
  width: 210px;
  height: 210px;
  border-radius: 50%;
  margin-bottom: 20px;
`;
const LinkText = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  display: block;
  color: #d04c8d;
  text-transform: capitalize;
  cursor: pointer !important;
  margin-left: 6px;
`;

export default function UpdateCompanyBrandingImages(props) {
  const company = props.companyDetails;
  const onClose = props.onClose;
  const { t } = useTranslation();

  const defaultVal = {
    description: undefined,
  };

  const [loading, setLoading] = useState(false);
  const [formDataForUpload, setFormDataForUpload] = useState(undefined);
  const [logoLocalPath, setLogoLocalPath] = useState(undefined);
  const [formErrors, setFormErrors] = useState("");
  const editLoading = useSelector((state) => state.company.loading);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

  const {
    control,
   
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schemaObj),
  });

  const uploadFile = async (event) => {
    const formdata = new FormData();
    const filesFormats = ["image/png", "image/jpeg"];
    let maxFileSize = 1024 * 1024 * 5; //5mb

    const isRightFormat = filesFormats.includes(event?.target?.files[0]?.type);
    if (!isRightFormat) {
      toast.error(t("Invalid File Format."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (event?.target?.files[0]?.size > maxFileSize) {
      toast.error(t("File size exceeds maximum limit."), {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      formdata.append("image_path", event?.target?.files[0]);
      formdata.append("description", console.log(formVal?.description));
      setFormDataForUpload(formdata);
      let reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.onload = () => {
        setLogoLocalPath(reader.result);
      };
    }
  };

  const formVal = watch();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    debugger;
    setLoading(true);
    formDataForUpload.set("description", formVal.description);
    const { data, status } = await onCompanyBrandingImageUpdate({
      code: org_code,
      data: formDataForUpload,
    });

    if (data && data.status === 1) {
      toast.success(t(`Image uploaded successfully.`), {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(updateLoader(!editLoading));
      props.onClose();
      setFormErrors("");
    } else {
      toast.error(`${t(data.message)}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  return (
    <CustomModal
      open={props.open}
      onCloseModal={props.onClose}
      styles={{ modal: { overflowY: "unset" } }}
    >
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            {
              <ImageWrapper>
                {logoLocalPath ? (
                  <>
                    <Image src={logoLocalPath} alt="company logo"  style={{objectFit:"cover"}}/>
                    <Uploader style={{ position: "relative" }}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "center",
                          width: "150px",
                          margin: " 0 auto",
                        }}
                      >
                        <UploadIcon />
                        <LinkText>{t("Upload Picture")}</LinkText>
                      </div>

                      <input
                        style={{
                          width: "206px",
                          margin: " 0 auto",
                          height: "25px",
                          left: "-83px",
                        }}
                        type="file"
                        onChange={uploadFile}
                        accept="image/png,image/jpeg"
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          marginBottom: "unset",
                        }}
                      >
                        {t("The maximum image size limit is 5 MB")}
                      </p>
                    </Uploader>
                  </>
                ) : (
                  <FileWrapper>
                    <Uploader>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "baseline",
                          justifyContent: "center",
                        }}
                      >
                        <UploadIcon />
                        <LinkText>{t("Upload Picture")}</LinkText>
                      </div>

                      <input
                        type="file"
                        onChange={uploadFile}
                        accept="image/png,image/jpeg"
                      />
                      <p
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          marginBottom: "unset",
                        }}
                      >
                        {t("The maximum image size limit is 5 MB")}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          marginBottom: "unset",
                        }}
                      >
                        {t("For best results, image should be in 4:3 aspect ratio")}
                      </p>
                      <p
                        style={{
                          fontSize: "12px",
                          color: "gray",
                          marginBottom: "unset",
                        }}
                      >
                        {t("(e.g. 800px × 600px, 1024px × 768px, 1600px × 1200px)")}
                      </p>
                    </Uploader>
                  </FileWrapper>
                )}
              </ImageWrapper>
            }
          </Grid>
          <Grid item lg={12}>
            <h3 style={{ width: "auto" }}>{company && company.name}</h3>
            <hr />
            <FormWrapper>
              <SingleField
                style={{ display: "flex", alignItems: "space-between" }}
              >
                <Grid container alignItems="center">
                  <Grid item lg={3}>
                    <FormLabel>{t("Image Description")}</FormLabel>
                  </Grid>
                  <Grid item lg={9}>
                    <Controller
                      style={{}}
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={company.description} />
                      )}
                    />
                    {errors.description && (
                      <ErrorField err={t(errors.description.message)} />
                    )}
                  </Grid>
                </Grid>
              </SingleField>

              <Grid
                container
                style={{
                  display: "flex",
                  alignItems: "end",
                  justifyContent: "end",
                  marginRight: "1%",
                }}
              >
                {formErrors && <ErrorField err={t(formErrors)} />}
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    name={t("Confirm")}
                    disabled={
                      formVal.description && logoLocalPath !== undefined
                        ? false
                        : true
                    }
                    onClick={onSubmit}
                  />
                )}
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t("Cancel")}
                  style={{
                    marginLeft: "10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
