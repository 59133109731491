import React, { useState } from "react";
import styled from "styled-components";
import Info from "../../assets/icons/info.png";
import ProfileModal from "./ProfileModal";
import EngageModal from "./EngageModal";
import RejectModal from "./RejectModal";
import InterviewModal from "./InterviewModal";
import Loader from "../loader/loader";
import OfferModal from "./OfferModal";
import HiredModal from "./HiredModal";
import Location from "../../assets/icons/location.png";
import Hand from "../../assets/icons/hands.png";
import CheckboxInput from "../checkboxInput/checkboxInput";
import ShortlistModal from "./shortlistModal";
import { faIR } from "date-fns/locale";
import { changApplications } from "../../services/apiCalls";
import "../../pages/trackApplications/trackApplications.css";
import { toast } from "react-toastify";
import CompareModal from "./compare";
import { useTranslation } from "react-i18next";
const Card = styled.div`
  box-sizing: border-box;
  width: 105%;
  height: 180px;
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  // padding: 15px;
  background: #fff;
  box-shadow: 3px 1px 12px #0000001a;
  border-radius: 8px;
  margin-bottom: 15px;
  margin-left: -5px;
  cursor: move;
  border: 1px solid #86d04c;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.2);
  position: relative;
  // margin-top:35px;
`;
const CardText = styled.div`
  width: 185px;
  position: relative;
  // right: 2px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #202020;
  background: #f7f7f7;
  border-radius: 12px;
  height: 38px;
  margin-top: 7px;
  padding-top: 7px;
  @media (max-width: 500px) {
    width: 350px;
    background: #f7f7f7;
    position: relative;
    left: 10px;
    justify-content: center;
  }
`;

const DragContainer = styled.div`
  min-height: 300px;
  margin: 0 0px 0px 0px;
  border-radius: 12px;
  width: 200px;
  background: white;
  @media (max-width: 500px) {
    width: 360px;
    display: flex;
    flex-direction: row;
    // max-width:120px;
    // flex-wrap:wrap;
    // justify-content: space-between;
  }
`;
const Hr = styled.div`
width: 97px;
border: 1px solid #f6f6f6;
position: relative;
margin-bottom:7px;
}
`;

const Button = styled.div`
  width: 165px;
  height: 36px;
  padding: 10px;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 700;
  font-size: 11px;
  line-height: 15px;
  text-align: center;
  color: #ffffff;
  background: #86d04c;
  border-radius: 128px;
  margin: 0px 0 10px 0;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    width: 135px;
  }
`;

const Img = styled.img`
  width: 48px;
  height: 48px;
  position: relative;
  top: 0px;
  border-radius: 50%;
  border: solid 3px #d04c8d;
  aspect-ratio: 0.5;
  transition: 0.5s ease;
`;
const Img1 = styled.img`
  width: 42px;
  height: 42px;
  position: relative;
  top: 0px;
  filter: grayScale(100%);
  border-radius: 50%;
  aspect-ratio: 0.5;
  transition: 0.5s ease;
`;
const Imag = styled.img`
  width: 48px;
  height: 48px;
  position: relative;
  border: solid 3px #d04c8d;
  border-radius: 50%;
  aspect-ratio: 0.5;
`;
const Imag1 = styled.img`
  width: 42px;
  height: 42px;
  filter: grayScale(100%);
  border-radius: 50%;
  aspect-ratio: 0.5;
`;
const Container = styled.div`
  display: flex;
  gap: 9px;
  flex-direction: row;
  //justify-content:space-evenly;
  margin: 0 -20px 0 -22px;
  @media (max-width: 500px) {
    display: grid;
    position: relative;
    justify-content: center;
    overflow: hidden;
  }
`;

const BoxContainer = styled.div`
  // margin: 4px;
  width: 180px;
  // height: 130px;
  margin: 0 0 0 10px;
  position: relative;
  @media (max-width: 500px) {
    width: 130px;
    display: flex-start;
  }
`;

const Icon = styled.img`
  width: 14px;
  height: 14px;
  position: relative;
  bottom: 2px;
`;

const IconWrapper = styled.div`
  //   display:flex;
  //   flex-direction:row;
  //   justify-content:space-between;
  display: grid;
  grid-template-columns: 1fr 7fr;
  width: 100%;
  margin-top: 5px;
  position: relative;
  left: 30px;
`;

export default class TrackApplicationsComponent extends React.Component {
  state = {
    tasks: [],
    selected: [],

    profile: false,
    engage: false,
    shortlist: false,
    reject: false,
    checked: true,
    interview: false,
    offered: false,
    hired: false,
    currentstatus: "",
    id: null,
    isLoading: true,
  };

  findId = (id) => {
    const item = this.state.selected.findIndex((e) => e === id);
    if (item > -1) {
      return false;
    }
    return true;
  };
  componentDidMount() {
    const { tasks } = this.props;
    this.setState({
      tasks,
      isLoading: false,
    });
  }

  componentDidUpdate() {
    const { tasks } = this.props;
    if (JSON.stringify(tasks) != JSON.stringify(this.state.tasks)) {
      this.setState({
        tasks,
      });
    }
  }
  onDragStart = (evt, status) => {
    this.state.currentstatus = status;
    let element = evt.currentTarget;
    element.classList.add("dragged");
    evt.dataTransfer.setData("text/plain", evt.currentTarget.id);
    evt.dataTransfer.effectAllowed = "move";
  };
  onDragEnd = (evt) => {
    evt.currentTarget.classList.remove("dragged");
  };
  onDragEnter = (evt) => {
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.add("dragged-over");
    evt.dataTransfer.dropEffect = "move";
  };
  onDragLeave = (evt) => {
    let currentTarget = evt.currentTarget;
    let newTarget = evt.relatedTarget;
    if (newTarget.parentNode === currentTarget || newTarget === currentTarget)
      return;
    evt.preventDefault();
    let element = evt.currentTarget;
    element.classList.remove("dragged-over");
  };
  onDragOver = (evt) => {
    evt.preventDefault();
    evt.dataTransfer.dropEffect = "move";
  };

  // new OnDrop
  onDrop = (evt, value, status) => {
    evt.preventDefault();
    evt.currentTarget.classList.remove("dragged-over");
    const data = evt.dataTransfer.getData("text/plain");
    const tasks = this.state.tasks;
    let up = false;
    this.state.id = data;
    const draggedTask = tasks.find(
      (task) => task.id.toString() === data.toString()
    );
    const draggedStatus = draggedTask.status;

    if (draggedStatus === "engage" && status === "shortlist") {
      up = true;
      this.movetoshortlist(data);
    } else if (draggedStatus === "shortlist" && status === "engage") {
      up = true;
      this.setState({ engage: true });
    } else if (
      (draggedStatus === "pending" || draggedStatus === "engage") &&
      status === "shortlist"
    ) {
      up = true;
      this.movetoshortlist(data);
    } else if (
      (draggedStatus === "pending" || draggedStatus === "shortlist") &&
      status === "engage"
    ) {
      up = true;
      this.movetoshortlist(data);
      this.setState({ engage: true });
    } else if (
      ["pending", "engage", "shortlist", "interview"].includes(draggedStatus) &&
      status === "interview"
    ) {
      up = true;
      this.setState({ interview: true });
    } else if (
      ["pending", "engage", "shortlist", "interview", "offered"].includes(
        draggedStatus
      ) &&
      status === "offered"
    ) {
      up = true;
      this.setState({ offered: true });
    } else if (
      [
        "pending",
        "engage",
        "shortlist",
        "interview",
        "offered",
        "hired",
      ].includes(draggedStatus) &&
      status === "hired"
    ) {
      up = true;
      this.setState({ hired: true });
    } else if (
      [
        "pending",
        "engage",
        "shortlist",
        "interview",
        "offered",
        "hired",
        "reject",
      ].includes(draggedStatus) &&
      status === "reject"
    ) {
      up = true;
      this.setState({ reject: true });
    }

    if (up) {
      const updatedTasks = tasks.map((task) => {
        if (task.id.toString() === data.toString()) {
          task.status = status;
        }
        return task;
      });
      this.setState({ tasks: updatedTasks });
    }
  };

  openProfile = (id = null) => {
    this.state.id = id;
    this.setState({ profile: true });
  };
  addToShortlist = (evt) => {
    let selectedIds = [...this.state.selected];
    const findEle = selectedIds.findIndex((e) => e === evt);

    if (findEle === -1) {
      selectedIds.push(evt);

      //to restric comparing user with different jobTitle
      if (selectedIds.length === 2) {
        const user1 = this.state.tasks?.find((e) => e?.id === selectedIds[0]);
        const user2 = this.state.tasks?.find((e) => e?.id === selectedIds[1]);

        if (user1?.jobtitle !== user2?.jobtitle) {
          console.log(user1?.shortlist_jobposition, "This is it");
          return toast.error(
            "Job positions are not compatible. Please select job positions of the same type to proceed with the comparison.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      } else if (selectedIds.length === 3) {
        const user1 = this.state.tasks?.find((e) => e?.id === selectedIds[0]);
        const user2 = this.state.tasks?.find((e) => e?.id === selectedIds[1]);
        const user3 = this.state.tasks?.find((e) => e?.id === selectedIds[2]);

        if (
          user1?.jobtitle !== user2?.jobtitle ||
          user1?.jobtitle !== user3?.jobtitle ||
          user2?.jobtitle !== user3?.jobtitle
        ) {
          console.log(user1?.shortlist_jobposition, "This is it");
          return toast.error(
            "Job positions are not compatible. Please select job positions of the same type to proceed with the comparison.",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
        }
      }

      //to ensure that cannot compare more than 3 users
      if (selectedIds.length > 3) {
        return toast.error("Cannot compare more than 3 profiles", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else {
      selectedIds.splice(findEle, 1);
    }

    this.setState({ selected: selectedIds });
  };

  searchId = (id) => {
    const foundId = this.state.selected.find((e) => e === id);
    if (foundId) {
      return true;
    }
    return false;
  };
  checkBoxFunction = (e) => {
    console.log("checkingCheck",e);
    this.setState({
      checked: !this.state.checked,
    });
  };

  namecap = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  movetoshortlist = async (id) => {
    const { data } = await changApplications("shortlist", id, {
      shortlist_jobposition: id,
      shortlist_tag: "shortlist_tag",
    });
  };
  userShortlitted = () => {
    console.log("userShortlistedCall");
  }

  // "349f5cd5-9b3d-4a73-b7db-403ca72a10b8" Job_ID
  // "a2f808a6-2025-4a10-9029-35902b741993" ID
  // "abc5a2ed-1dad-43d2-87f4-aefcb1b6db86" User_ID
  render() {
    const { tasks } = this.state;
    const { t } = this.props;
    console.log("tasks",tasks);
    let application = tasks.filter((data) => data.status === "pending");
    let shortlist = tasks.filter((data) => data.status === "shortlist");
    let engage = tasks.filter((data) => data.status === "engage");
    let interview = tasks.filter((data) => data.status === "interview");
    let offered = tasks.filter((data) => data.status === "offered");
    let hired = tasks.filter((data) => data.status === "hire");
    let reject = tasks.filter((data) => data.status === "reject");

 console.log("shortlisted",shortlist,this.state.id);
    return (
      <>
        {this.state.isLoading ? (
          <Loader />
        ) : (
          <Container>
            {this.state.engage ? (
              <EngageModal
                open={this.state.engage}
                onClose={() => this.setState({ engage: false })}
                id={this.state.id}
              />
            ) : null}
            {this.state.shortlist ? (
              <ShortlistModal
                open={this.state.shortlist}
                onClose={() => this.setState({ shortlist: false })}
                id={this.state.id}
                t={t}
              />
            ) : null}
            {this.state.reject ? (
              <RejectModal
                open={this.state.reject}
                onClose={() => this.setState({ reject: false })}
                id={this.state.id}
                t={t}
              />
            ) : null}
            {this.state.profile ? (
              <ProfileModal
                open={this.state.profile}
                onClose={() => this.setState({ profile: false })}
                id={this.state.id}
                t={t}
              />
            ) : null}
            {this.state.selected.length > 1 ? (
              <CompareModal
                length={this.state.selected.length}
                userShortlisted={this.userShortlitted}
                data={this.state.selected}
                bulkData={this.state.tasks}
              />
            ) : null}
            {this.state.interview ? (
              <InterviewModal
                open={this.state.interview}
                onClose={() => this.setState({ interview: false })}
                id={this.state.id}
                t={t}
              />
            ) : null}
            {this.state.offered ? (
              <OfferModal
                open={this.state.offered}
                onClose={() => this.setState({ offered: false })}
                id={this.state.id}
                t={t}
              />
            ) : null}
            {this.state.hired ? (
              <HiredModal
                open={this.state.hired}
                onClose={() => this.setState({ hired: false })}
                id={this.state.id}
              />
            ) : null}

            <div
              className="order small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, false, "pending")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText>
                      {t("Application")} <Icon src={Info} />
                    </CardText>
                    <div className="drag_row">
                      {application.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, "pending")}
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Imag src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                  position: "relative",
                                  top: "-25px",
                                }}
                              >
                                <Imag1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}

                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-190px",
                                    marginLeft: "4px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}

                            <div className="card_right">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              <p title={task.jobtitle}>
                                {task.jobtitle.substring(0, 20)}
                                {task.jobtitle.length > 20 ? "..." : ""}
                              </p>
                              <IconWrapper>
                                <img src={Hand} className="handIcon" alt="" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img
                                  src={Location}
                                  className="locationIcon"
                                  alt=""
                                />
                                <div
                                  className="time"
                                  style={{ marginLeft: "4px" }}
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
            <div
              className="pending small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, false, "shortlist")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText>
                      {t("Shortlist")} <Icon src={Info} />
                    </CardText>
                    <div className="drag_row">
                      {shortlist.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) =>
                              this.onDragStart(e, "shortlist")
                            }
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Imag src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                  position: "relative",
                                  top: "-25px",
                                }}
                              >
                                <Imag1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}

                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-170px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}

                            <div className="card_right">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              <p title={task.jobtitle}>
                                {task.jobtitle.substring(0, 20)}
                                {task.jobtitle.length > 20 ? "..." : ""}
                              </p>
                              <IconWrapper>
                                <img src={Hand} className="handIcon" alt="" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img
                                  src={Location}
                                  className="locationIcon"
                                  alt=""
                                />
                                <div
                                  className="time"
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
            <div
              className="waiting small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, true, "engage")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText>
                      {t("Engage")} <Icon src={Info} />
                    </CardText>
                    <div className="drag_row">
                      {engage.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, "engage")}
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Img src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                }}
                              >
                                <Img1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}
                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-190px",
                                    marginLeft: "4px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}{" "}
                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-190px",
                                    marginLeft: "4px",
                                  }}
                                  className="checkboxInput"
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}
                            <div className="card_rights">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              {task.engage_jobposition &&
                              task.engage_jobposition.length > 0 ? (
                                <p title={task.engage_jobposition}>
                                  {task.engage_jobposition.substring(0, 20)}
                                  {task.engage_jobposition.length > 20
                                    ? "..."
                                    : ""}
                                </p>
                              ) : (
                                <p title={task.jobtitle}>
                                  {task.jobtitle.substring(0, 6)}
                                  {task.jobtitle.length > 6 ? "..." : ""}
                                </p>
                              )}
                              <IconWrapper>
                                <img src={Hand} className="handIcon" alt="" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img
                                  src={Location}
                                  className="locationIcon"
                                  alt=""
                                />
                                <div
                                  className="time"
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
            <div
              className="done small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, true, "interview")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText>
                      {t("Interview")} <Icon src={Info} />
                    </CardText>
                    <div className="drag_row">
                      {interview.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) =>
                              this.onDragStart(e, "interview")
                            }
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Img src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                }}
                              >
                                <Img1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}

                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-190px",
                                    marginLeft: "4px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}

                            <div className="card_rights">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              <p title={task.jobtitle}>
                                {task.jobtitle.substring(0, 20)}
                                {task.jobtitle.length > 20 ? "..." : ""}
                              </p>
                              <IconWrapper>
                                <img src={Hand} className="handIcon" alt="" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img
                                  src={Location}
                                  className="locationIcon"
                                  alt=""
                                />
                                <div
                                  className="time"
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
            <div
              className="done small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, true, "offered")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText>
                      {t("Offered")} <Icon src={Info} />
                    </CardText>
                    <div className="drag_row">
                      {offered.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, "offered")}
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Img src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                }}
                              >
                                <Img1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}

                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "relative",
                                    top: "5px",
                                    right: "5px",
                                    width: "10px",
                                    height: "10px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}

                            <div className="card_rights">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              {task.engage_jobposition &&
                              task.engage_jobposition.length > 0 ? (
                                <p
                                  className="job_title"
                                  title={task.engage_jobposition}
                                >
                                  {task.engage_jobposition.substring(0, 20)}
                                  {task.engage_jobposition.length > 20
                                    ? "..."
                                    : ""}
                                </p>
                              ) : (
                                <p className="job_title" title={task.jobtitle}>
                                  {task.jobtitle.substring(0, 6)}
                                  {task.jobtitle.length > 6 ? "..." : ""}
                                </p>
                              )}
                              <IconWrapper>
                                <img src={Hand} className="handIcon" alt="" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img
                                  src={Location}
                                  className="locationIcon"
                                  alt=""
                                />
                                <div
                                  className="time"
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
            <div
              className="done small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, true, "hired")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText style={{ background: "#E7FFE3" }}>
                      {t("Hired")} <Icon src={Info} />
                    </CardText>
                    <div className="drag_row">
                      {hired.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, "hired")}
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Img src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                }}
                              >
                                <Img1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}

                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-190px",
                                    marginLeft: "4px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}

                            <div className="card_rights">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              {task.engage_jobposition &&
                              task.engage_jobposition.length > 0 ? (
                                <p title={task.engage_jobposition}>
                                  {task.engage_jobposition.substring(0, 20)}
                                  {task.engage_jobposition.length > 20
                                    ? "..."
                                    : ""}
                                </p>
                              ) : (
                                <p title={task.jobtitle}>
                                  {task.jobtitle.substring(0, 6)}
                                  {task.jobtitle.length > 6 ? "..." : ""}
                                </p>
                              )}
                              <IconWrapper>
                                <img src={Hand} className="handIcon" alt="" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img
                                  src={Location}
                                  className="locationIcon"
                                  alt=""
                                />
                                <div
                                  className="time"
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
            <div
              className="done small-box"
              onDragLeave={(e) => this.onDragLeave(e)}
              onDragEnter={(e) => this.onDragEnter(e)}
              onDragEnd={(e) => this.onDragEnd(e)}
              onDragOver={(e) => this.onDragOver(e)}
              onDrop={(e) => this.onDrop(e, true, "reject")}
            >
              <DragContainer>
                <BoxContainer>
                  <div className="drag_column">
                    <CardText style={{ background: "#FFE4E4" }}>
                      {t("Rejected")}
                      <Icon src={Info} style={{ left: "6px" }} />
                    </CardText>
                    <div className="drag_row">
                      {reject.map((task) => (
                        <div className="Card-outer">
                          <Card
                            key={task.name}
                            id={task.id}
                            draggable
                            onDragStart={(e) => this.onDragStart(e, "reject")}
                            onDragEnd={(e) => this.onDragEnd(e)}
                            onClick={(e) => this.addToShortlist(task.id)}
                          >
                            {this.findId(task.id) ? (
                              <Img src={task.profile_picture} alt="box" />
                            ) : (
                              <div
                                style={{
                                  border: "3px solid #d04c8d",
                                  borderRadius: "50%",
                                }}
                              >
                                <Img1 src={task.profile_picture} alt="box" />{" "}
                              </div>
                            )}

                            {this.searchId(task.id) ? (
                              <div className="checkboxInput">
                                <CheckboxInput
                                  style={{
                                    position: "absolute",
                                    marginTop: "-190px",
                                    marginLeft: "4px",
                                  }}
                                  checked={this.state.checked}
                                  onChange={() => this.checkBoxFunction}
                                />
                              </div>
                            ) : null}

                            <div className="card_rights">
                              <div
                                className="status"
                                title={this.namecap(task.name)}
                              >
                                {this.namecap(task.name).substring(0, 10)}
                                {task.name.length > 10 ? "..." : ""}
                              </div>
                              {task.engage_jobposition &&
                              task.engage_jobposition.length > 0 ? (
                                <p title={task.engage_jobposition}>
                                  {task.engage_jobposition.substring(0, 20)}
                                  {task.engage_jobposition.length > 20
                                    ? "..."
                                    : ""}
                                </p>
                              ) : (
                                <p title={task.jobtitle}>
                                  {task.jobtitle.substring(0, 22)}
                                  {task.jobtitle.length > 22 ? "..." : ""}
                                </p>
                              )}
                              <IconWrapper>
                                <img src={Hand} className="handIcon" />
                                <div className="days">
                                  {task.match !== undefined
                                    ? task.match + t("% Match")
                                    : "loading... "}
                                </div>
                              </IconWrapper>
                              <Hr />
                              <IconWrapper>
                                <img src={Location} className="locationIcon" />
                                <div
                                  className="time"
                                  style={{ marginLeft: "4px" }}
                                  title={this.namecap(task.state_name)}
                                >
                                  {this.namecap(task.state_name).substring(
                                    0,
                                    15
                                  )}
                                  {task.state_name.length > 15 ? "..." : ""}
                                </div>
                              </IconWrapper>
                            </div>
                            <Button
                              onClick={() => {
                                this.openProfile(task.id);
                              }}
                            >
                              {t("View Profile")}
                            </Button>
                          </Card>
                        </div>
                      ))}
                    </div>
                  </div>
                </BoxContainer>
              </DragContainer>
            </div>
          </Container>
        )}
      </>
    );
  }
}
