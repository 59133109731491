import { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import "./style.css";

const RichTextEditorComponent = ({ value, setValue, readonly }) => {
    let updateVal = value ? value : '';
    const [richText, setRichText] = useState(RichTextEditor.createValueFromString(updateVal, "html"));

    useEffect(() => {
        if(richText.getEditorState().getCurrentContent().getPlainText().trim().length==0 && value!=undefined && value.length>0){
            setRichText(RichTextEditor.createValueFromString(updateVal, "html"))
        }
    }, [value])

    const handleRichtext = (val) => {
        setRichText(val);

        const isNotEmpty = val.getEditorState().getCurrentContent().getPlainText().trim()
        if (setValue) {
            if (isNotEmpty) {
                setValue(val.toString("html"));
            } else {
                setValue('');
            }
        }
    }

    return (
        <RichTextEditor disabled={readonly} onChange={handleRichtext} value={richText} />
    )
}

export default RichTextEditorComponent;