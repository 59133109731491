import React from "react";
import { useSearchParams } from "react-router-dom";
// import { getWorkInterest } from "../../../../../services/CommonApi";
// import { Utils } from "../../../../../utilities/utils";
import AnalyticalCardVarientFour from "../../../pages/applicantAssessments/analyticalCardVarientFour/analyticalCardVarientFour";
import { Utils } from "../utilities";
import { Ad2StudentWorkInterest } from "./adWorkInterest";
// import Ad2CardVariantFour from "../../../ad2-shared/ad2CardVariantFour/ad2CardVariantFour";
// import { Ad2StudentWorkInterest } from "../../ad2StudentWorkInterest/ad2StudentWorkInterest";

// import AnalyticalCardVarientFour from "../../../pages/applicantAssessments";


export default function Ad2WorkInterestDrilldown() {
  let controller = new AbortController();
  let [searchParams, setSearchParams] = useSearchParams();
  const [studentWorkInterestFilter, setStudentWorkInterestFilter] =
    React.useState("rank1");
  const [studentWorkInterest, setStudentWorkInterest] = React.useState({
    data: null,
    status: "loading",
  });

  React.useEffect(() => {
    // fetchStudentWorkInterest();

    return () => {
      controller.abort();
    };
  }, [studentWorkInterestFilter]);

//   const fetchStudentWorkInterest = async () => {
//     setStudentWorkInterest({ data: null, status: "loading" });
//     const filterParams = Utils.getParams(searchParams, [
//       {
//         propertyKey: "studentWorkInterestFilter",
//         urlKey: "rank",
//       },
//     ]);
//     filterParams.rank = studentWorkInterestFilter;
//     console.log("fiiii",filterParams,studentWorkInterestFilter);
//     const { data, status } = await getWorkInterest({
//       filters: filterParams,
//       chartsLoadingController: controller,
//     });
//     let total =
//       data && data.data && data.data.results && data.data.results.totalTalents;
//     const { proceed, stateObj } = Utils.commonStateUpdateLogic(
//       data,
//       total,
//       status,
//       ""
//     );
//     if (stateObj) {
//       setStudentWorkInterest(stateObj);
//     }
//     if (proceed) {
//       let results = data && data.data && data.data.results;
//       const gridData = [];
//       const chartDataMap = {};
//       Object.keys(results)
//         .filter((x) => x !== "totalTalents")
//         .forEach((key) => {
//           gridData.push({
//             type: key,
//             male: Utils.falsyNumberCaseHandle(results[key].male),
//             female: Utils.falsyNumberCaseHandle(results[key].female),
//           });
//           chartDataMap[key] = {
//             male: Utils.falsyNumberCaseHandle(results[key].male),
//             female: Utils.falsyNumberCaseHandle(results[key].female),
//           };
//         });
//       setStudentWorkInterest({
//         gridData,
//         data: chartDataMap,
//         status,
//         total,
//       });
//     }
//   };
  return (
    <AnalyticalCardVarientFour>
      <Ad2StudentWorkInterest
        data={studentWorkInterest}
        studentWorkInterestFilter={studentWorkInterestFilter}
        setStudentWorkInterestFilterFilter={(val) =>
          setStudentWorkInterestFilter(val && val.value ? val.value : val)
        }
      />
    </AnalyticalCardVarientFour>
  );
}
