import React, { useState } from "react";
import { staticData } from "./staticData";

import {
  SubHeading,
  HeadingCard,
  ContentCard,
  CardWrapper,
  ContentHeading,
  Range,
  ColorBar,
  SectionHeading,
  NormalText,
  SectionWrapper,
  SpanText,
  Wrapper,
  RangeInput,
  ButtonWrapper,
} from "./common";
import Button from "../button/button";
import "./applicantsComparision.css";
import OceanReport from "./oceanReport";
import { Slider } from "@mui/material";
import styled from "styled-components";
import Location from "../../assets/location pin.png";
import { t } from "i18next";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton, capitalize } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;
export const OceanSlider = styled(Slider)`
  cursor: default !important;
  .Mui-active {
    pointer-events: none;
  }
  .MuiSlider-thumb {
    color: ${(props) =>
    props.level === "low" ||
      props.level === "Low" ||
      props.level === "level 1"
      ? "#FF0000"
      : props.level === "moderate" ||
        props.level === "Moderate" ||
        props.level === "level 2"
        ? "#548235"
        : props.level === "high" ||
          props.level === "High" ||
          props.level === "level 3"
          ? "#7030A0"
          : "#FF0000"};
    size: small;
    box-shadow: 0px 5px 16px rgba(180, 104, 141, 0.9);
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 5px 16px rgba(180, 104, 141, 0.9);
    cursor: default !important;
  }
  .MuiSlider-rail {
    background: #fafafa;
    border: 0.2px solid #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15),
      inset 0px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
  }
  .MuiSlider-track {
    background: ${(props) =>
    props.level === "low" ||
      props.level === "Low" ||
      props.level === "level 1"
      ? "#FF0000"
      : props.level === "moderate" ||
        props.level === "Moderate" ||
        props.level === "level 2"
        ? "#548235"
        : props.level === "high" ||
          props.level === "High" ||
          props.level === "level 3"
          ? "#7030A0"
          : "#FF0000"};
    border: 0.2px solid #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15),
      inset 0px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
  }
  .Mui-disabled {
    color: #d04c8d;
  }
  .MuiSlider-valueLabel {
    background: #d04c8d;
  }
`;

const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  margin: 40px 0 40px 20px;
  width: 40%;
  @media (max-width: 500px) {
    position: relative;
    top: 0px;
  }
`;
const UserStatusHeader = styled.div`
  display: flex;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 1px;
  margin-top: 0;
  flex-direction: row;
  padding-left:140px;
  color: #504f8c;
  font-style: italic;
  text-transform: uppercase;
`;
export default function Comparision({
  shortlistIds,
  isUserShortlisted,
  data,
  shortlist,
  userData,
  tabIndex,
  profileDetails,
  profileData,
  location,
  experience,
  education,
  selectedData,
  status
}) {
  // const [data, setData] = useState([0, 1, 2])

  console.log(userData, "userData")
  console.log(shortlistIds, "shortlistIds")
  console.log(data, "dataDataData")
  console.log(shortlist, "shortlist")
  console.log(profileData, "profileData")
  console.log(selectedData, "selectedData")
  const colors = [
    "#504F8C",
    "#D04C8D",
    "#0656f8",
    "#00ffb4",
    "#ff0000",
    "#ffa500",
    "#56bb0f",
    "#ebff00",
    "#F9E105",
    "#DDBC11",
    "#ff004d",
    "#0200fe",
  ];
  const getMatch = (id) => {
    let findUser = userData.find((e) => e.user_id === id);
  };

  const [openAssessmentReport, setOpenAssessmentReport] = useState(true);
  //   const [openOceanReport, setOpenOceanReport] = useState(true);
  const handleOpenAssesmentReport = () => {
    setOpenAssessmentReport(!openAssessmentReport);
  };
  const cards = (100 / userData?.length).toFixed(2) + "%";
  const getDob = (id) => {
    const findDob = profileData.find((e) => e.id === id);
    return findDob?.dob;
  };
  const getLocation = (id) => {
    const findLocation = location?.find((e) => e?.user === id);
    return findLocation;
  };
  const getEducation = (id) => {
    const findEducation = education?.find((e) => e.user_id === id);
    return findEducation;
  };
  const getExperience = (id) => {
    const findExperience = experience?.find((e) => e?.user_id === id);
    return findExperience;
  };
  function normalizeBetween0To5 (val){
    var newMin = 0;
    var newMax = 5;
    var oldMin = 0;
    var oldMax = 120;
    var newVal = newMin + (((val - oldMin) * (newMax - newMin)) / (oldMax - oldMin))
    return Math.round(newVal * 100) / 100;
  };
  console.log("mySTatys",status);
  const { t, i18n } = useTranslation();


  return (
    <Wrapper>
      <SectionWrapper>
        {tabIndex != 3 ? (
          <CardWrapper>
            <ContentCard noBg style={{ width: cards }}></ContentCard>
            {status?.map((x, i) => (
              <ContentCard noBg style={{ width: cards }}>
                <div>
                              {/* <Button name={t("Shortlist")} onClick={() => shortlist(x)} /> */}

                              <div style={{paddingLeft:"134px"}}>
                  { x.includes("pending") ?
                  <Button name={t("Shortlist")} onClick={() => shortlist({id:shortlistIds[i],currentStatus:i})} />

                  :
                  <Button name={t("Shortlisted")} />
                  }
                  </div>
                  <UserStatusHeader >{x}</UserStatusHeader>
                </div>
              </ContentCard>
            ))}
          </CardWrapper>
        ) : (
          ""
        )}
        {/* {userData?.map((a, i) => (
          <>
          </>
        ))} */}

        <CardWrapper>
          <ContentCard noBg style={{ width: cards }}>
            <ContentHeading>{""}</ContentHeading>
            <HeadingCard>
              <SubHeading style={{ fontSize: "18px" }}>
                {t("Personality Matching")}
              </SubHeading>
              <SubHeading style={{ fontSize: "18px" }}>{t("Location")}</SubHeading>
              <SubHeading style={{ fontSize: "18px" }}>
                {t("Date Of Birth")}
              </SubHeading>
            </HeadingCard>
          </ContentCard>

          {data?.map((x, i) => (
            <ContentCard style={{ width: cards }}>
              <ContentHeading>{x?.user?.display_name}</ContentHeading>
              <HeadingCard style={{ textAlign: "center" }}>
                <div
                  style={{
                    fontSize: "20px",
                    fontWeight: "600",
                    color: "#D04C8D",
                  }}
                >
                  {selectedData[x?.id] ? selectedData[x?.id]+"%" : ""}
                </div>
                <SubHeading>
                  <NormalText style={{ margin: "5px auto 0 auto" }}>
                    {x?.location && x?.location?.length > 0 ? x?.location[0]?.state_abbr + "," : ""}
                    {
                      x?.location && x?.location?.length > 0 ? x?.location[0].city_abbr + "," : ""
                    }
                    {""}
                    {""}
                    {
                      x?.location && x?.location?.length > 0 ? x?.location[0].country_abbr : ""
                    }
                  </NormalText>
                </SubHeading>
                <SubHeading>
                  <NormalText style={{ margin: "auto" }}>
                    {x?.user && x?.user.dob ? x?.user?.dob : ""}
                  </NormalText>
                </SubHeading>
              </HeadingCard>
            </ContentCard>
          ))}
        </CardWrapper>
        <CardWrapper>
          <ContentCard noBg style={{ width: cards }}>
            <HeadingCard>
              <SubHeading style={{ fontSize: "18px", marginTop: "10px" }}>
                {t("Study Background")}
              </SubHeading>
              <SubHeading style={{ fontSize: "18px", marginTop: "52px" }}>
                {t("Work Experience")}
              </SubHeading>
            </HeadingCard>
          </ContentCard>
          {data?.map((x, i) => (
            <ContentCard style={{ width: cards }}>
              <HeadingCard>
                <div>
                  <NormalText>
                    {x?.education && x?.education?.length > 0 ? x?.education[0]?.degree : ""}
                    {","}
                    {/* {getEducation(x?.user_id)?.scope_of_study
                      ? " - " + getEducation(x?.user_id)?.scope_of_study
                      : ""} */}
                    {x?.education && x?.education?.length > 0 ? x?.education[0]?.scope_of_study : ""}
                  </NormalText>
                  <SubHeading style={{ fontStyle: "italic" }}>
                    {""}
                    {/* {getEducation(x?.user_id)?.institution} */}
                    {x?.education && x?.education?.length > 0 ? x?.education[0]?.institution : ""}
                  </SubHeading>
                  {x?.location?.length > 0 ? (
                    <SpanText>
                      <img
                        src={Location}
                        alt="img"
                        style={{ marginRight: "10px" }}
                      />
                      {/* {getLocation(x?.user_id)?.city_abbr}
                      {getLocation(x?.user_id)?.country_abbr
                        ? ", " + getLocation(x?.user_id)?.country_abbr
                        : ""} */}
                      {x?.location && x?.location?.length > 0 ? x?.location[0]?.state_abbr + "," : ""}
                      {
                        x?.location && x?.location?.length > 0 ? x?.location[0].city_abbr + "," : ""
                      }
                      {""}
                      {""}
                      {
                        x?.location && x?.location?.length > 0 ? x?.location[0].country_abbr : ""
                      }
                    </SpanText>
                  ) : (
                    ""
                  )}
                </div>
                <div>
                  <NormalText>
                    {x?.employement_details && x?.employement_details?.length>0 ?  x?.employement_details[0]?.role : ""}
                    </NormalText>
                  <SubHeading style={{ fontStyle: "italic" }}>
                    {/* {getExperience(x?.user_id)?.organization} */}
                    {x?.employement_details && x?.employement_details?.length>0 ?  x?.employement_details[0]?.organization : ""}
                  </SubHeading>
                  {experience.length > 0 ? (
                    <SpanText>
                      <img
                        src={Location}
                        alt="img"
                        style={{ marginRight: "10px" }}
                      />{" "}
                      {getExperience(x?.user_id)?.location}
                    </SpanText>
                  ) : (
                    ""
                  )}
                </div>
              </HeadingCard>
            </ContentCard>
          ))}
        </CardWrapper>
        <SeparatorDiv onClick={handleOpenAssesmentReport}>
          <HrLine />
          <Separator>{t("Assessment Report")}</Separator>
          <HrLine />

          {openAssessmentReport ? (
            <IconButton>
              <UpIcon />
            </IconButton>
          ) : (
            <IconButton>
              <DownIcon />
            </IconButton>
          )}
        </SeparatorDiv>
        {/* <SectionHeading>{staticData.assessmentReport.heading}</SectionHeading> */}
        {openAssessmentReport ? (
          <>
            {staticData?.assessmentReport?.content?.map((content, idx) => (
              <CardWrapper>
                <ContentCard noBg style={{ width: cards }}>
                  <ContentHeading>{""}</ContentHeading>
                  <HeadingCard>
                    {content.subHeading.map((e) => (
                      <SubHeading>{t(e)}</SubHeading>
                    ))}
                  </HeadingCard>
                </ContentCard>
                {data?.map((x, i) => (
                  <ContentCard style={{ width: cards }}>
                    <ContentHeading>{t(content.heading)}</ContentHeading>
                    {content.subHeading.map((e, y) => (
                      <SubHeading>
                        {content.type === "range" ? (
                          // <input style={{ margin: "auto"}} readOnly type="range" value={x[e]?.points ? x[e]?.points : 0} />
                          content.heading === "Motivation"? (
                            <OceanSlider
                          level={x[e]?.score?.toLowerCase()}
                          value={normalizeBetween0To5(x[e]?.points) ? normalizeBetween0To5(x[e]?.points) : 0}
                          aria-label="Slider"
                          valueLabelDisplay="auto"
                          max={5}
                        />
                          ): 
                          <OceanSlider
                          level={x[e]?.score?.toLowerCase()}
                          value={x[e]?.points ? x[e]?.points : 0}
                          aria-label="Slider"
                          valueLabelDisplay="auto"
                        />
                        ) : (
                          <div style={{ display: "flex", width: "100%" }}>
                            <ColorBar
                              bg={x[e]?.score === "Low" ? "#FF4D4D" : false}
                              style={{ width: cards }}
                            />
                            <ColorBar
                              bg={
                                x[e]?.score === "Moderate" ? "#FFB422" : false
                              }
                              style={{ width: cards }}
                            />
                            <ColorBar
                              bg={x[e]?.score === "High" ? "#4CD051" : false}
                              style={{ width: cards }}
                            />
                          </div>
                        )}
                      </SubHeading>
                    ))}
                  </ContentCard>
                ))}
              </CardWrapper>
            ))}
          </>
        ) : (
          ""
        )}
      </SectionWrapper>
      <OceanReport data={data} cards={cards} />
    </Wrapper>
  );
}
