import { GrClose } from 'react-icons/gr';
import { Modal } from 'react-bootstrap';

function alertModalReview(props) {
  return (
    <div>
      <style type="text/css">
        {`
 @media (min-width: 576px){
.modal-dialog {
    max-width: 671px;
    margin: 1.75rem auto;
}
}
    `}
      </style>
      <Modal show={props.show} onHide={props.close} centered>
        <div className="post-modal mx-2">
          <div
            className=" d-flex justify-content-end align-items-center p-4 modal_header"
            style={{ cursor: 'pointer' }}
          >
            <GrClose onClick={props.close} />
          </div>
          <div>{props.content}</div>
        </div>
      </Modal>
    </div>
  );
}

export default alertModalReview;
