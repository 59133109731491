export const SubscriptionData = {
    "enterprise":{
        "job_quota":15,
        "job_duration_day":60,
        "internship_quota":15,
        "internship_duration_month":6,
        "employe_equota":50,
        "apply_loe_token":50
    },
    "premium":{
        "job_quota":10,
        "job_duration_day":60,
        "internship_quota":10,
        "internship_duration_month":6,
        "employe_equota":50,
        "apply_loe_token":30
    },
    "pro":{
        "job_quota":6,
        "job_duration_day":60,
        "internship_quota":6,
        "internship_duration_month":6,
        "employe_equota":50,
        "apply_loe_token":20
    },
    "basic":{
        "job_quota":3,
        "job_duration_day":60,
        "internship_quota":3,
        "internship_duration_month":6,
        "employe_equota":50,
        "apply_loe_token":10
    },
    "talent_acquisition": {
        "heading": "Talent Acquisition",
        "features": [
            {
            "name": "Graduate Talent Finder Token",
            "enterprise": "80",
            "premium": "50",
            "pro": "30",
            "basic": "20"
        }, 
        {
            "name": "Talent Job Posting Token",
            "enterprise": "15",
            "premium": "10",
            "pro": "6",
            "basic": "3"
        }, {
            "name": "Job Post duration",
            "enterprise": "60 Days",
            "premium": "60 Days",
            "pro": "60 Days",
            "basic": "60 Days"
        }, {
            "name": "Internship Posting (MySIP & General) Token",
            "enterprise": "15",
            "premium": "10",
            "pro": "6",
            "basic": "3"
        }, {
            "name": "Internship Post Duration",
            "enterprise": "6 Months",
            "premium": "6 Months",
            "pro": "6 Months",
            "basic": "6 Months"
        }, {
            "name": "MySIP Token",
            "enterprise": "50",
            "premium": "30",
            "pro": "20",
            "basic": "10"
        }
    ]
    },
    "talent_management": {
        "heading": "Talent Management",
        "features": [
            // {
            //     "name": "Employee Management",
            //     "enterprise": "",
            //     "premium": "",
            //     "pro": "",
            //     "basic": "",
            //     "tag": true
            // },
            {
                "name": "Employee Quota",
                "enterprise": "Up to 50",
                "premium": "Up to 50",
                "pro": "Up to 50",
                "basic": "Up to 50"
            }, {
                "name": "Additional Employee headcount",
                "enterprise": "RM 12",
                "premium": "RM 12",
                "pro": "RM 12",
                "basic": "RM 12"
            }, 
            {
                "name": "Engagement with University",
                "enterprise": "2",
                "premium": "1",
                "pro": "-",
                "basic": "-"
            }
        ]
    }

}