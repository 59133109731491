import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/button";
import Logo from "../../assets/addNewIntern.png";
import PlusIcon from "../../assets/plus.png";
import Export from "../../assets/Group 1599.png";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import "./addnewjob.css";
import { useSnapshot } from "valtio";
import { subsctipationsProxy } from "../../pages/Subscriptions/subscripationsProxy";
import { getOrganizationInternshipCoinDetail, getQuota } from "../../services/apiCalls";
import ReactTooltip from "react-tooltip";
import { useTranslation } from "react-i18next";

const AddNeCompanyWrapper = styled.div`
  background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  padding: 15px 50px 15px 15px;
  margin: 50px 0 70px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    margin-top: 61px;
    > img {
      display: block;
      margin-left: auto;
    }
  }
`;
const StaticImg = styled.img`
  position: absolute;
  left: -15px;
  /* top: 22%; */
  width: 208px;
  height: 190px;
  cursor: default !important;
  @media (max-width: 500px) {
    display: inline-block;
    bottom: -23%;
    left: -35px;
  }
`;
const ContentWrapper = styled.div`
  margin: 0 0 0 30px;
  margin-left: 115px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0 0 10px 50px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0 0 0 50px;
    color: #ffffff;
  }
  @media (max-width: 500px) {
    margin: 0;
    margin-left: 0px;
    padding: 5px;
  }
`;
const PlusIconWrapper = styled.div`
  @media (max-width: 500px) {
    margin: 0px 30px 0 0px;
  }
`;
export default function AddNewUser(props) {
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const { t, i18n } = useTranslation();
  // const parentLeftMargin = i18n.language === "en" ? "-186px" : "-222px";
  // const parentMinWidth = i18n.language === "en" ? "265px" : "285px";
  const customStyle =
    i18n.language === "en"
      ? { display: "flex", marginBottom: "19px", marginLeft: "111px" }
      : {
        display: "flex",
        marginBottom: "19px",
        paddingLeft: "206px",
        paddingRight: "42px",
      };
  const navigate = useNavigate();
  useSnapshot(subsctipationsProxy)
  const onNavigate = () => {
    if (subsctipationsProxy.jobcount < subsctipationsProxy.job_quota) {
      navigate("/jobs/addNewJob");
    } else {
      props.setUnAuth(true)
    }

  };
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [coin, setCoin] = useState("");
  const [userUsedCoins, setUserUsedCoin] = useState("");
  useEffect(async () => {
    if (Object.keys(company).length > 0) {
      const result3 = await getQuota(org_code);
      const totalJobsToken = result3?.data?.total_job;
      const usedJobsToken = result3?.data?.job;
      setCoin(totalJobsToken - usedJobsToken);
      setUserUsedCoin(result3?.data?.job);
    }
  }, [company]);
  return (
    <AddNeCompanyWrapper>
      <StaticImg src={Logo} alt="new company" />
      <ContentWrapper>
        <h3>{t("Add New Job")}</h3>
        <p>
          {t("Create a new job posting for your organisation. Seek for suitable talents within mynext.")}<br></br> 
          {t("Please note that job posting via mynext has become a payable feature.")}<br></br> 
          {t("For more information, kindly visit our official website, or email us at company@mynext.my")}
        </p>
      </ContentWrapper>
      <div style={customStyle}>
        <div
          style={{
            minWidth: "275px",
            minHeight: "34px",
            background: "#FFFFFF",
            border: "3.01518px solid #EE4E9C",
            marginTop: "14px",
            borderRadius: "12px",
            marginLeft: "-195px",
          }}
        >
          <p
            style={{
              position: "absolute",
              fontFamily: "Oswald",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "21.9611px",
              lineHeight: "27px",
              color: "#57508E",
              marginLeft: "11px",
            }}
          >
            {t("Job Posting Tokens")}
          </p>
        </div>

        <div
          style={{
            minWidth: "70px",
            minHeight: "69px",
            background:
              "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
            borderRadius: "36px",
            position: "absolute",
            marginLeft: "-26px",
            marginTop: "-4px",
          }}
        >
          <div
            style={{
              minWidth: "64px",
              minHeight: "64px",
              background: "rgb(40, 255, 0)",
              borderRadius: "36px",
              position: "absolute",
              marginLeft: "3px",
              marginTop: "2px",
            }}
          >
            <ReactTooltip />
            <p
              style={{
                height: "38px",
                fontFamily: "Oswald",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "31.5841px",
                lineHeight: "61px",
                background:
                  "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "#57508E",
                textAlign: "center",
              }}
              data-tip="Available"
            >
              {coin ?? ""}
            </p>
          </div>
        </div>

        <div
          style={{
            minWidth: "70px",
            minHeight: "69px",
            background:
              "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
            borderRadius: "36px",
            position: "absolute",
            marginLeft: "49px",
            textAlign: "center",
            marginTop: "-4px",
          }}
        >
          <div
            style={{
              minWidth: "64px",
              minHeight: "64px",
              background: "#FFFF00",
              borderRadius: "36px",
              position: "absolute",
              marginLeft: "3px",
              marginTop: "2px",
              textAlign: "center",
            }}
          >
            <ReactTooltip />
            <p
              style={{
                height: "38px",
                fontFamily: "Oswald",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "31.5841px",
                lineHeight: "61px",
                background:
                  "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "#57508E",
                textAlign: "center",
              }}
              data-tip="Used"
            >
              {userUsedCoins ?? ""}
            </p>
          </div>
        </div>

      </div>
      <PlusIconWrapper>
        <img
          onClick={onNavigate}
          src={PlusIcon}
          style={{ cursor: "pointer" }}
          alt="new company add"
          className="add-new-job"
        />
      </PlusIconWrapper>
    </AddNeCompanyWrapper>
  );
}
