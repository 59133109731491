import React from "react";
import "./alertinterview.css";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { t } from "i18next";
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    flex-direction: column-reverse;
    align-items: center;
  }
`;

const ModalContainer = styled.div`
  min-width: 480px;
  min-height: 270px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  @media (max-width: 500px) {
    min-width: 380px;
    width: 396px;
    height: auto;
  }
`;
const AlertChangeModal = (props) => {
  return (
    <div className="popup-box">
      <ModalContainer>
        <div className="popUp">
          <div className="" style={{ padding: "0px 10px" }}>
            <p style={{ color: "#FA4AA1" }}>{t("ALERT")}</p>
            <p onClick={props.handleClose} className="alertMain">
              X
            </p>
          </div>
          <FormLabel style={{ marginTop: "-10px" }}>{props.title}</FormLabel>
        </div>

        {props.content}
      </ModalContainer>
    </div>
  );
};

export default AlertChangeModal;
