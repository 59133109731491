
import React from "react";
import { useTranslation } from "react-i18next";
import "./nofound.css"
export default function NoDataChartContainer() {
  const { t, i18n } = useTranslation();
  return (
    <div  className="noFound">
      <p style={{ margin: 0 }}>{t("No Data Found")}</p>
    </div >
  )
}