import React from "react";
import ChartLoader from "../../components/chartLoader/chartLoader";
import VerticalBarChartDataLabels from "../../components/charts/verticalBarChartDataLabels";
import Selector from "../../components/select/select";
import { StudentAd2Card } from "./ad2Card";
import { Ad2SelectorWrapper } from "../styles/Ad2Styles";
import { useTranslation } from "react-i18next";
import NoDataChartContainer from "../NoDataFoundChart/NoDataFoundChart";
const columns = [
  {
    name: "",
    selectorKey: "type",
    selector: (row) => row.type,
    sortable: false,
  },
  {
    name: "Male",
    selectorKey: "male",
    selector: (row) => row.male,
    sortable: false,
  },
  {
    name: "Female",
    selectorKey: "female",
    selector: (row) => row.female,
    sortable: false,
  },
];

export function Ad2StudentWorkInterest({ data,
  studentWorkInterestFilter,
  setStudentWorkInterestFilterFilter,
  onViewMoreClick
}) {
  const chartData = data;
  const gridData = data && data.gridData;
  const status = data && data.status;
  console.log("chartData/in ",data);
  function RightContent() {
    const ranks = [
      "Rank 1",
      "Rank 2",
      "Rank 3",
    ].map((item) => {
      return { value: item, label: item };
    });
    return (
      <Ad2SelectorWrapper>
        <Selector
          onChange={(value) => {
            setStudentWorkInterestFilterFilter(value);
          }} options={ranks} defaultValue={{ value: studentWorkInterestFilter, label: studentWorkInterestFilter }} />
      </Ad2SelectorWrapper>
    );
  }
  const { t} = useTranslation();
  return (
    <>
    {
    chartData?.status !== "noDataFound"
    ?
    chartData?.Artistic?.points 
    ?  
    <StudentAd2Card
        title="work_interest"
        data={gridData}
        columns={columns}
        exportFileName="students_work_interest"
        rightContent={<RightContent />}
        status={status}
        onViewMoreClick={onViewMoreClick} 
      >
        <VerticalBarChartDataLabels
        chartType="work_interest"
          data={{
            labels: [
              t("Realistic"),
              t("Investigative"),
              t("Artistic"),
              t("Social"),
              t("Enterprising"),
              t("Conventional"),
            ],
            datasets: [
              {
                // data: [20,23,15,28,24,8,21],
                data: [
                  chartData && chartData.Realistic && chartData.Realistic.points,
                  chartData && chartData.Investigative && chartData.Investigative.points,
                  chartData && chartData.Artistic && chartData.Artistic.points,
                  chartData && chartData.Social && chartData.Social.points,
                  chartData && chartData.Enterprising && chartData.Enterprising.points,
                  chartData && chartData.Conventional && chartData.Conventional.points,
                ], //this code is working. remove dummy data if needed.
                backgroundColor: "#004B84",
                borderRadius: Number.MAX_VALUE,
                borderSkipped: false,
                borderColor: "rgba(0,0,0,0)",
                barPercentage: 1,
                categoryPercentage: 0.8,
              }
            ],
          }}
        />
      </StudentAd2Card>
    :<ChartLoader></ChartLoader>
  :    <div  className="noFound">
  <p style={{ margin: 0 }}>{t("Not Yet Complete")}</p>
</div >
  } 
      
    </>
  );
}