import React, { useEffect, useState } from "react";
import QuickChart from "quickchart-js/build/quickchart.cjs";
import { Text, Image, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const chartData = data && data;
  const totalEnglishProficiencyLevel = chartData && chartData?.data?.General;
  const grammar = chartData && chartData?.data?.General?.values?.Grammar;
  const comprehension =
    chartData && chartData?.data?.General?.values?.Comprehension;

  const [totalEnglishProficiencyChartUrl, setTotalEnglishProficiencyChartUrl] =
    useState(null);
  const [grammarChartUrl, setGrammarChartUrl] = useState(null);
  const [comprehensionChartUrl, setComprehensionChartUrl] = useState(null);

  const { t } = useTranslation();

  const levelsGrammar = {
    "Level 1": {
      min: 0,
      max: 49,
      message:
        "The talent has demonstrated basic knowledge of English Grammar and must aim to develop your knowledge to a higher level. He/she can do this through taking English classes, talking to others who speak English well, reading and listening to English language media.",
    },
    "Level 2": {
      min: 50,
      max: 59,
      message:
        "The talent has demonstrated average knowledge of English Grammar and must aim to develop your knowledge to a higher level.He/she can do this through taking English classes, talking to others who speak English well, reading and listening to English language media.",
    },
    "Level 3": {
      min: 60,
      max: 75,
      message:
        "The talent has demonstrated above average knowledge of English.The talent has a good basis to learn more and develop and may choose to challenge oneself to increase his/her knowledge by taking regular English classes, reading English novels, seeking out opportunities to engage in English conversation and regularly following English language media.",
    },
    "Level 4": {
      min: 76,
      max: 90,
      message:
        "The talent has demonstrated good knowledge of English.The talent is well placed to move to a higher level.  He/she must challenge oneself by taking advanced English classes, reading English literature, seeking out opportunities to engage in English conversation and regularly following English language media.",
    },
    "Level 5": {
      min: 91,
      max: 100,
      message:
        "The talent has demonstrated strong knowledge of English.The talent must keep challenging oneself to remain at this advanced level by considering a higher qualification in English, taking advanced English classes, reading a variety of English language texts and literature regularly, seeking out opportunities to engage in English conversation and following English language media.",
    },
  };

  const levelsComprehension = {
    "Level 1": {
      min: 0,
      max: 49,
      message:
        "The talent has demonstrated basic understanding of a text in English and now must aim to develop this understanding to a higher level One can do this by taking regular classes in English and through reading English language news articles and stories, listening to English language podcasts and watching movies.",
    },
    "Level 2": {
      min: 50,
      max: 59,
      message:
        "The talent has demonstrated average understanding of a text in English and now must aim to develop this understanding to a higher level.One can do this through reading and listening to English language media, reading novels in English, and taking English classes.",
    },
    "Level 3": {
      min: 60,
      max: 75,
      message:
        "The talent has demonstrated above average understanding of a text in English.The talent must challenge oneself to increase your understanding by taking regular English classes, reading English literature, seeking out opportunities to engage in English conversation and regularly following English language media.",
    },
    "Level 4": {
      min: 76,
      max: 90,
      message:
        "The talent has demonstrated good understanding of a text in English.The talent must oneself to raise his/her understanding by taking advanced English classes, reading a variety of English texts and literature regularly, seeking out opportunities to engage in English conversation and following English language media.",
    },
    "Level 5": {
      min: 91,
      max: 100,
      message:
        "The talent has demonstrated excellent understanding of a text in English.The talent must keep challenging oneself to remain at this level by considering a higher qualification in English, taking advanced English classes, reading a variety of English texts and literature regularly, seeking out opportunities to engage in English conversation and following English language media. He/she can also consider producing content in English.",
    },
  };

  useEffect(() => {
    if (totalEnglishProficiencyLevel !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(totalEnglishProficiencyLevel["points"]) / 100,
                  data: [0.5, 0.6, 0.75, 0.9, 1.0],
                  backgroundColor: [
                    "#058AA5",
                    "#00B4D8",
                    "#62DBF1",
                    "#90E0EF",
                    "#CAF0F8",
                  ],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#8FBBDF",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setTotalEnglishProficiencyChartUrl(await myChart.getUrl());
      })();
    }
    if (grammar !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(grammar["points"]) / 100,
                  data: [0.5, 0.6, 0.75, 0.9, 1.0],
                  backgroundColor: [
                    "#73284D",
                    "#9B3668",
                    "#BE598B",
                    "#D24C8E",
                    "#FF75B9",
                  ],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100  + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#542F41",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setGrammarChartUrl(await myChart.getUrl());

      })();
    }
    if (comprehension !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(comprehension["points"]) / 100,
                  data: [0.5, 0.6, 0.75, 0.9, 1.0],
                  backgroundColor: [
                    "#0B2F4A",
                    "#053D67",
                    "#137BC9",
                    "#137BC9",
                    "#319FF2",
                  ],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100  + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 50,
              needle: {
                color: "#8FBBDF",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
        setComprehensionChartUrl(await myChart.getUrl());
      })();
    }
  }, [totalEnglishProficiencyLevel, grammar, comprehension]);

  return (
    <View style={{}}>
      <View style={{}}>
        <Text
          style={{
            fontFamily: "General Sans",
            fontSize: 24,
            fontWeight: 500,
            textTransform: "uppercase",
            borderBottom: "2px solid black",
            paddingBottom: 5,
          }}
        >
          {"6."} {t("English Proficiency")}
        </Text>

        {comprehension && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 16,
                fontWeight: 500,
                textTransform: "uppercase",
                marginTop: 8,
                marginBottom: 4,
              }}
            >
              {"6.1"} {t("Comprehension")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginTop: 4,
              }}
            >
              {t(comprehension?.score)}
              {" - "}
              {t(levelsComprehension[comprehension?.score]["message"])}
            </Text>
          </View>
        )}

        {grammar && (
          <View
            style={{
              marginRight: 20,
            }}
          >
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 16,
                fontWeight: 500,
                textTransform: "uppercase",
                marginTop: 8,
                marginBottom: 4,
              }}
            >
              {"6.2"} {t("Grammar")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                marginTop: 4,
                marginBottom: 4,
              }}
            >
              {t(grammar?.score)}
              {" - "}
              {t(levelsGrammar[grammar?.score]["message"])}
            </Text>
          </View>
        )}
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 14,
        }}
      >
        {totalEnglishProficiencyChartUrl !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image
              src={totalEnglishProficiencyChartUrl}
              style={{ margin: 15 }}
            />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Total English Proficiency")}
            </Text>
          </View>
        )}

        {grammarChartUrl !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={grammarChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Grammar")}
            </Text>
          </View>
        )}

        {comprehensionChartUrl !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={comprehensionChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Comprehension")}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
