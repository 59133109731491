import React, { useRef, useState } from "react";
import { Row, Col } from 'react-bootstrap';
import styled from "styled-components";
import Button from "../../components/button/button";
import ErrorField from "../../components/errorField/errorField";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../components/input/input";
import { useForm, Controller } from "react-hook-form";
import { Colors } from "../../utilities/colors";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import Dropdown from "../dropdown/dropdown";
import RadioInput, { RadioInput1 } from "../radioInput/radioInput";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import Delete from "../../assets/icons/svg/trash.svg";
import "./jobStyles.css";
import * as yup from "yup";
import {
  createJobs,
  deletejobdocument,
  getjobdocument,
  getJobs,
  getLocations,
  getScopeOfStudyMasterList,
  getSkills,
  getTitleList,
  savejobdocument,
  updateJobs,
} from "../../services/apiCalls";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import Skills from "../../pages/jobPortal/skills/skills";
import { toast } from "react-toastify";
import CvLocation from "../../pages/jobPortal/location/cv-location";
import { useNavigate } from "react-router-dom";
import RichTextEditorComponent from "../../utilities/rich-text-editor";
import { useTranslation } from "react-i18next";
import { useSnapshot } from "valtio";
import { subsctipationsProxy } from "../../pages/Subscriptions/subscripationsProxy";
import { companyProxy } from "../../pages/myCompany/companyproxy";
import { FormControl, FormGroup, FormControlLabel, FormHelperText, Checkbox } from "@mui/material";
import Dropdown1 from "../dropdown/dropdown2";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import { ThemeProvider } from "@material-ui/styles";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import DateFnsUtils from "@date-io/date-fns";
import { createTheme } from "@material-ui/core";
import { formatDate } from "../../utilities/commonFunctions";

const FormControlLabels = styled(FormControlLabel)`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
`;


const GridWrapper = styled.div`
  display: grid;
  margin-bottom: 30px;
`;

const FormWrapper = styled.div``;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  width: 100%;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;

const TableWrapper = styled.div`
  display: grid;
  grid-template-columns: ${(props) =>
    props?.threeLayout ? "2fr 2fr 1fr" : "2fr 2fr 2fr 1fr"};
`;

const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: ${Colors.labelText};
  // text-align:center;
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 8px;
  color: #525252;
  text-transform: capitalize;
  overflow-wrap: anywhere;
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;

const TableLayout = styled.div`
  padding: 12px 32px;
  background: #fdfdfd;
  border-radius: 10px;
  @media (max-width: 500px) {
    overflow-x: scroll;
  }
`;

const SmallText = styled.h4`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #858585;
`;
const RadioBoxWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  @media (max-width: 1440px) {
    display: block;
    // margin-top:10px;
  }
`;
export const schema = yup.object().shape({
  email_address: yup.string().required("Email Address is required."),
  position: yup.string().required("Position is required."),
  dapartment: yup.string().required("Department is required."),
});

export default function JobForm({ type, setUnAuth }) {
  useSnapshot(subsctipationsProxy);
  useSnapshot(companyProxy);

  useEffect(async () => {
    if (companyProxy.selectcompany) {
      if (companyProxy.selectcompany.org_code) {
        await subsctipationsProxy.setcount(companyProxy.selectcompany.org_code)
      }
    }
  }, [companyProxy.selectcompany])
  useEffect(() => {
    console.log(subsctipationsProxy.plan)
  }, [subsctipationsProxy.plan])
  const navigate = useNavigate();
  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const [orgCode, setOrgCode] = useState(undefined);
  const [jobdata, setJobdata] = useState({});
  const job = useSelector((state) => state.job_reducer.id);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscriptionPlan = useSelector((state) => state.subscription.subscriptionData);
  const job_duration = subscriptionPlan[org_code]?.job_duration_day;
  const { t, i18n } = useTranslation();

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }

  const {
    control,
    watch,
    formState: { },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const formVal = watch();

  const [resetOrg, setResetOrg] = useState(0);
  const [tabIndex, setTabIndex] = useState(1);
  const [showForm, setShowForm] = useState(false);
  const [companyid, setCompanyid] = useState("");
  const [nationality, setNationality] = useState("");
  const [scopeOfStudies, setScopeOfStudies] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);
  const [selectedEt, setSelectedEt] = useState({});
  const [probation, setProbation] = useState(null);
  const [mandatory, setMandatory] = useState("Y");
  const [documentType, setDocumentType] = useState(null);
  const [fromDate, setFromDate] = useState("");
  const [checkset, setCheckset] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedTitle, setSelectedTitle] = useState([]);
  const [options, setOptions] = useState([]);
  const typeaheadRef = useRef(null);
  const [adddoc, setAdddoc] = useState(false);
  const [ch, setCh] = useState(1);
  const filterBy = () => true;
  if (resetOrg === 0) {
    reset({
      document_type: "",
    });
    setResetOrg(1);
  }
  const handleSearch = (query) => {
    if (query.length > 2) {
      setIsLoading(true);
      fetchAllTitle(query);
    }
  };

  const fetchAllTitle = async (searchKey) => {
    const { data, status } = await getTitleList(
      i18n.language,
      searchKey,
      "list"
    );
    if (status === 200 && data.status === 1) {
      const options = data.data.map((x) => {
        return { ...x, label: x.title, value: x.title };
      });
      setOptions(options);
      setIsLoading(false);
    }
  };

  const [employmentType, setEmploymentType] = useState([
    { label: t("Permanent"), value: "Permanent" },
    { label: t("Contract"), value: "Contract" },
  ]);

  const [document, setDocument] = useState([]);
  const [errors, setErrors] = useState({});
  const [checkBoxes, setCheckBoxes] = useState([
    { id: "ability_to_influence", lable: "Ability To Influence", value: "low" },
    {
      id: "comfort_with_ambiguity",
      lable: "Comfort with ambiguity",
      value: "low",
    },
    {
      id: "achievement_orientation",
      lable: "Achievement Orientation",
      value: "low",
    },
    {
      id: "individualism_vs_collectivism",
      lable: "Individualism vs. Collectivism",
      value: "low",
    },
    {
      id: "receiptiveness_to_change",
      lable: "Receiptiveness to Change",
      value: "low",
    },
    { id: "space_orientation", lable: "Space Orientation", value: "low" },
  ]);

  const [checkBoxes1, setCheckBoxes1] = useState([
    { id: "research_evaluate", lable: "Research & Evaluate", value: "low" },
    {
      id: "inception_formation",
      lable: "Inception & Formation",
      value: "low",
    },
    {
      id: "communication_presentation",
      lable: "Communication & Presentation",
      value: "low",
    },
    {
      id: "leadership",
      lable: "Leadership",
      value: "low",
    },
    {
      id: "teamwork",
      lable: "Teamwork",
      value: "low",
    },
    { id: "collectedness", lable: "Collectedness", value: "low" },
    { id: "plan_execte", lable: "Plan & Execute", value: "low" },
    {
      id: "inventive_accomplishment",
      lable: "Inventive & Accomplishment",
      value: "low",
    },
  ]);

  const notification = (type, message) => {
    toast[type](message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const getscope = async () => {
    if (scopeOfStudies.length === 0) {
      const { data } = await getScopeOfStudyMasterList();
      if (data.status === 1) {
        const scopeOfStudydata = data.data.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setScopeOfStudies(scopeOfStudydata);
      }
    }
  };

  const ch_low_high = (v) => {
    if (v === "N") {
      return "low";
    }
    if (v === "Y") {
      return "high";
    }
    return v;
  };

  const getJob = async () => {
    const { data } = await getjobdocument(jobdata["id"]);
    if (data.status === 1) {
      setDocument(data["data"]);
      setValue("document_type", data["data"]);
    }
  };

  const getJob1 = async (id) => {
    const { data } = await getjobdocument(id);
    if (data.status === 1) {
      setDocument(data["data"]);
    }
  };

  const add_document = async () => {
    let l = 0;

    for (let i = 0; i < document.length; i++) {
      if (document[i]["document_type"] === documentType) {
        l = 1;
        i = document.length + 1;
      }
    }
    if (l == 0) {
      const { data } = await savejobdocument(jobdata["id"], {
        data: [{ document_type: documentType, mandatory: mandatory }],
      });
      if (data.status === 1) {
        setAdddoc(false);
        chcheck();
        notification("success", t("Document Type Added"));
        setDocumentType("");
        getJob();
      }
    } else {
      setAdddoc(false);
      chcheck();
      notification("error", t("Document Type already exists"));
    }
  };

  const detete_document = async (id) => {
    const { data } = await deletejobdocument(id);
    if (data.status === 1) {
      notification("success", t("Document Type Deleted"));
      getJob();
    }
  };

  const getJobdata = async (id) => {
    if (!jobdata.id || jobdata.id.length === 0) {
      await getscope();
      setJobdata({ id: id });
      const { data } = await getJobs(id);

      setJobdata(data["data"][0]);
      if (options.length === 0) {
        setSelectedTitle([
          {
            title: data["data"][0]["title"],
            label: data["data"][0]["title"],
            value: data["data"][0]["title"],
          },
        ]);
      }
      setOptions([
        {
          title: data["data"][0]["title"],
          label: data["data"][0]["title"],
          value: data["data"][0]["title"],
        },
      ]);

      setValue("title", data["data"][0]["title"]);
      setValue("description", data["data"][0]["description"]);
      setValue("responsibilities", data["data"][0]["responsibilities"]);
      setValue("salary_from", data["data"][0]["salary_from"]);
      setValue("salary_to", data["data"][0]["salary_to"]);
      setValue("working_arrangement", data["data"][0]["working_arrangement"]);
      setValue("benefits", data["data"][0]["benefits"]);
      //setValue("job_expiry_day", data["data"][0]["job_expiry_day"]);
      setNationality(data["data"][0]["nationality"]);
      await getJob1(id);
      let scopevalue = [];
      let datascope = data["data"][0]["scope_of_study"].split(",");
      if (selectedScope.length === 0) {
        for (let i = 0; i < scopeOfStudies.length; i++) {
          for (let j = 0; j < datascope.length; j++) {
            if (scopeOfStudies[i].value === Number(datascope[j])) {
              scopevalue.push(scopeOfStudies[i]);
            }
          }
        }
        if (scopevalue.length > 0) {
          setSelectedScope(scopevalue);
        }
      }
      for (let i = 0; i < employmentType.length; i++) {
        if (employmentType[i].value === data["data"][0]["employment_type"]) {
          setSelectedEt(employmentType[i]);
          i = employmentType.length + 1;
        }
      }
      setProbation(data["data"][0]["probation_period"]);

      setCheckBoxes1([
        {
          id: "research_evaluate",
          lable: "Research & Evaluate",
          value: ch_low_high(data["data"][0]["research_evaluate"]),
        },
        {
          id: "inception_formation",
          lable: "Inception & Formation",
          value: ch_low_high(data["data"][0]["inception_formation"]),
        },
        {
          id: "communication_presentation",
          lable: "Communication & Presentation",
          value: ch_low_high(data["data"][0]["communication_presentation"]),
        },
        {
          id: "leadership",
          lable: "Leadership",
          value: ch_low_high(data["data"][0]["leadership"]),
        },
        {
          id: "teamwork",
          lable: "Teamwork",
          value: ch_low_high(data["data"][0]["teamwork"]),
        },
        {
          id: "collectedness",
          lable: "Collectedness",
          value: ch_low_high(data["data"][0]["collectedness"]),
        },
        {
          id: "plan_execte",
          lable: "Plan & Execute",
          value: ch_low_high(data["data"][0]["plan_execte"]),
        },
        {
          id: "inventive_accomplishment",
          lable: "Inventive & Accomplishment",
          value: ch_low_high(data["data"][0]["inventive_accomplishment"]),
        },
      ]);

      setCheckBoxes([
        {
          id: "ability_to_influence",
          lable: "Ability To Influence",
          value: data["data"][0]["ability_to_influence"],
        },
        {
          id: "comfort_with_ambiguity",
          lable: "Comfort with ambiguity",
          value: data["data"][0]["comfort_with_ambiguity"],
        },
        {
          id: "achievement_orientation",
          lable: "Achievement Orientation",
          value: data["data"][0]["achievement_orientation"],
        },
        {
          id: "individualism_vs_collectivism",
          lable: "Individualism vs. Collectivism",
          value: data["data"][0]["individualism_vs_collectivism"],
        },
        {
          id: "receiptiveness_to_change",
          lable: "Receiptiveness to Change",
          value: data["data"][0]["receiptiveness_to_change"],
        },
        {
          id: "space_orientation",
          lable: "Space Orientation",
          value: data["data"][0]["space_orientation"],
        },
      ]);
      setShowForm(true);
    }
  };
  function initialPointsConverter(initialValue) {
    if (initialValue == "low") {
      return 0;
    }
    else {
      return initialValue;
    };
  };
  function boolToPointsConverter(initialValue) {
    if (initialValue == true) {
      return 0.6;
    }
    else if (initialValue == false) {
      return 0;
    } else {
      return 0;
    }
  };
  function pointsToBoolConverter(initialValue) {
    if (initialValue == 0.6) {
      return true;
    } else if (initialValue == 0) {
      return false;
    } else {
      return false;
    };
  };
  const [state, setState] = React.useState({//jobdata retrieves from db as 0.6 for true, 0 for false
    research_evaluate: pointsToBoolConverter(jobdata?.research_evaluate),
    inception_formation: pointsToBoolConverter(jobdata?.inception_formation),
    communication_presentation: pointsToBoolConverter(jobdata?.communication_presentation),
    leadership: pointsToBoolConverter(jobdata?.leadership),
    teamwork: pointsToBoolConverter(jobdata?.teamwork),
    collectedness: pointsToBoolConverter(jobdata?.collectedness),
    plan_execte: pointsToBoolConverter(jobdata?.plan_execte),
    inventive_accomplishment: pointsToBoolConverter(jobdata?.inventive_accomplishment),
  });
  useEffect(() => {
    setState({
      research_evaluate: pointsToBoolConverter(jobdata?.research_evaluate),
      inception_formation: pointsToBoolConverter(jobdata?.inception_formation),
      communication_presentation: pointsToBoolConverter(jobdata?.communication_presentation),
      leadership: pointsToBoolConverter(jobdata?.leadership),
      teamwork: pointsToBoolConverter(jobdata?.teamwork),
      collectedness: pointsToBoolConverter(jobdata?.collectedness),
      plan_execte: pointsToBoolConverter(jobdata?.plan_execte),
      inventive_accomplishment: pointsToBoolConverter(jobdata?.inventive_accomplishment),
    })
  }, [jobdata]);
  const { research_evaluate, inception_formation, communication_presentation, leadership, teamwork, collectedness, plan_execte, inventive_accomplishment } = state;
  const error = [research_evaluate, inception_formation, communication_presentation, leadership, teamwork, collectedness, plan_execte, inventive_accomplishment].filter((v) => v).length < 3;
  const handleChange = (event) => {
    if ([research_evaluate, inception_formation, communication_presentation, leadership, teamwork, collectedness, plan_execte, inventive_accomplishment].filter((v) => v).length <= 2) {
      setState({
        ...state,
        [event.target.name]: event.target.checked,
      });
      jobdata[event.target.name] = boolToPointsConverter(event.target.checked); //convert "event checked" to 0.6 for true, 0 for false and send to db
    } else {
      if (event.target.checked == true) {
        toast.error(t("Maximum factors reached"), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      setState({
        ...state,
        [event.target.name]: false,
      });
      jobdata[event.target.name] = boolToPointsConverter(false);//convert "event checked" to 0.6 for true, 0 for false and send to db
    }
  };

  const add = async () => {
    if (formVal.title) {
      if (formVal.title.length === 0) {
        setErrors({ title: { message: "Title is required." } });
      } else {
        setErrors({});
        const { data } = await createJobs(orgCode, formVal);

        if (data["status"] !== 0) {
          setJobdata(data["data"]);
          setNationality(data["data"]["nationality"]);
          setShowForm(true);
        } else {
          if (data["message"] === "unauth") {
            setUnAuth(true);
          }
        }
      }
    } else {
      setErrors({ title: { message: "Title is required." } });
    }
  };

  const update1 = async () => {
    let l = jobdata;
    setErrors({});
    l.status = "accepted";
    const { dres } = await updateJobs(l);
    navigate("/jobs");
    if (type === "add") {
      toast.success(t("Job Added Succesfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (type === "edit") {
      toast.success(t("Job Updated Succesfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const chcheck = async () => {
    if (ch === 0) {
      setCh(1);
    } else {
      setCh(0)
    }
  };

  const checkskill = async () => {
    const { data } = await getSkills(jobdata["id"]);
    if (data && data.status === 1) {
      if (data.data && data.data.length > 0) {
        errors.skills = "";
        chcheck();
      }
    }
  };

  const checkloc = async () => {
    const data1 = await getLocations(jobdata["id"]);
    if (data1.status === 200) {
      if (data1.data && data1.data.data.length > 0) {
        errors.location = "";
        chcheck();
      }
    }
  };
  const update = async () => {
    let l = 0;
    let er = {};

    if (!formVal.description) {
      l = 1;
      er["description"] = { message: "Description is required." };
    }
    /*if (!formVal.job_expiry_day) {
      l = 1;
      er["job_expiry_day"] = { message: "Job Expiry Day is required." };
    }*/
    if (!formVal.responsibilities) {
      l = 1;
      er["responsibilities"] = { message: "Responsibilities is required." };
    }
    if (!formVal.salary_from) {
      l = 1;
      er["salary_from"] = { message: "Salary From is required." };
    }
    if (!formVal.salary_to) {
      l = 1;
      er["salary_to"] = { message: "Salary To is required." };
    }
    if (Number(formVal.salary_from) > Number(formVal.salary_to)) {
      l = 1;
      er["salary_to"] = {
        message: "Value should be greater than minimum allowance",
      };
    }
    if (!formVal.working_arrangement) {
      l = 1;
      er["working_arrangement"] = {
        message: "Working Arrangement is required.",
      };
    }
    if (!formVal.benefits) {
      l = 1;
      er["benefits"] = { message: "Benefits is required." };
    }
    if (selectedScope.length === 0) {
      l = 1;
      er["scope"] = { message: "Scope is required." };
    }
    if (!selectedEt.value) {
      l = 1;
      er["et"] = { message: "Employment Type is required." };
    }
    if (!probation || probation.length === 0) {
      l = 1;
      er["et"] = { message: "Probation Period is required." };
    }

    const { data } = await getSkills(jobdata["id"]);
    if (data && data.status === 1) {
      if (data.data && data.data.length > 0) {
      } else {
        l = 1;
        er["skills"] = { message: "skills is required." };
      }
    } else {
      l = 1;
      er["skills"] = { message: "skills is required." };
    }

    const data1 = await getLocations(jobdata["id"]);
    if (data1.status === 200) {
      if (data1.data && data1.data.data.length > 0) {
      } else {
        l = 1;
        er["location"] = { message: "location is required." };
      }
    } else {
      l = 1;
      er["location"] = { message: "location is required." };
    }

    if (document.length > 0) {
    } else {
      l = 1;
      er["document"] = { message: "document is required." };
    }

    if (l === 0) {
      setErrors({});
      let d = jobdata;
      d.description = formVal.description;
      //d.job_expiry_day = formVal.job_expiry_day;
      d.responsibilities = formVal.responsibilities;
      d.salary_from = formVal.salary_from;
      d.salary_to = formVal.salary_to;
      d.working_arrangement = formVal.working_arrangement;
      d.benefits = formVal.benefits;
      d.title = formVal.title;
      if (subsctipationsProxy.plan === "basic") {
        d.status = "accepted";
      }
      const { dres } = await updateJobs(d);
      // if (subsctipationsProxy.plan === "basic") {
      //   navigate("/jobs");
      // } else {
      setTabIndex(2);
      // }
    } else {
      toast.error(t("Fill All Mandatory Details"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setErrors(er);
    }
  };

  const dropdownChange = (name, value) => {
    let data = jobdata;
    switch (name) {
      case "scope":
        data["scope_of_study"] = value;
        setJobdata(data);
        setSelectedScope(value);
        break;
      case "et":
        data["employment_type"] = value.value;
        setJobdata(data);
        setSelectedEt(value);
        break;
      default:
    }
  };

  const settitle = async (value) => {
    if (value.length > 0) {
      setSelectedTitle([value[0]]);
      setIsLoading(true);
      const { data, status } = await getTitleList(
        i18n.language,
        value[0].value,
        "list1"
      );
      if (status === 200 && data.status === 1) {
        setShowForm(false);
        setValue("title", data["data"][0]["title"]);
        setValue("description", data["data"][0]["descr"]);
        setValue("responsibilities", data["data"][0]["task"]);
        setShowForm(true);
        setIsLoading(false);
      }
    }
  };

  const scopeAddChange = (val) => {
    let data = jobdata;
    let a = [];
    const newVal = val.map((x) => {
      a.push(x.value);
      return { ...x, scope: x.value };
    });
    data["scope_of_study"] = a.join(",");
    setJobdata(data);
    setSelectedScope(newVal);
    errors.scope = "";
    if (ch === 0) {
      setCh(1);
    } else {
      setCh(0);
    }
  };

  useEffect(async () => {
    if (!orgCode || orgCode == undefined) {
      await getOrgCode();
    }
    await getscope();
    if (job && type == "edit" && (!jobdata.id || jobdata.length == 0)) {
      getJobdata(job);
    }
  });

  useEffect(() => {
    if (!companyid || companyid.length === 0) {
      if (company && company.id) {
        setCompanyid(company.id);
      }
    } else {
      if (companyid !== company.id) {
        navigate("/jobs");
      }
    }
  }, [company]);

  return (
    <FormWrapper>
      <GroupWrapper>
        <FormLabel>{t("Job Title*")}</FormLabel>
        <AsyncTypeahead
          filterBy={filterBy}
          id="searchtitle1"
          className="searchtitle"
          isLoading={isLoading}
          labelKey="label"
          minLength={1}
          ref={typeaheadRef}
          onSearch={() => { }}
          onInputChange={(e) => {
            if (selectedTitle.length > 0) {
              setIsLoading(true);
              setSelectedTitle("");
              setOptions([]);
              setIsLoading(false);
            }
            setValue("title", e);
            handleSearch(e);
          }}
          selected={selectedTitle}
          options={options}
          placeholder="HR Executive, Project Manager, etc."
          onChange={(val) => settitle(val)}
        />

        {errors.title && <ErrorField err={t(errors.title.message)} />}
      </GroupWrapper>
      {tabIndex === 1 ? (
        <div>
          {type === "add" && (!showForm || !jobdata.id) ? (
            <GroupWrapper className="alignSpaceBetween">
              <div></div>
              <Button
                style={{ marginTop: "0px", width: "144px" }}
                onClick={add}
                name={t("Proceed")}
              />
            </GroupWrapper>
          ) : null}
          {type === "edit" ||
            (type === "add" && showForm === true && jobdata.id) ? (
            <div>
              {showForm ? (
                <GroupWrapper>
                  <FormLabel>{t("Job Description*")}</FormLabel>

                  <Controller
                    name="description"
                    control={control}
                    render={({ field }) => (
                      <RichTextEditorComponent
                        {...field}
                        value={formVal.description}
                        setValue={(val) => {
                          setValue("description", val);
                          errors.description = "";
                        }}
                      />
                    )}
                  />
                  {errors.description && (
                    <ErrorField err={t(errors.description.message)} />
                  )}
                </GroupWrapper>
              ) : (
                ""
              )}
              {showForm ? (
                <GroupWrapper>
                  <FormLabel>{t("Responsibilities*")}</FormLabel>
                  <Controller
                    name="responsibilities"
                    control={control}
                    render={({ field }) => (
                      <RichTextEditorComponent
                        {...field}
                        value={formVal.responsibilities}
                        setValue={(val) => {
                          setValue("responsibilities", val);
                          errors.responsibilities = "";
                        }}
                      />
                    )}
                  />
                  {errors.responsibilities && (
                    <ErrorField err={t(errors.responsibilities.message)} />
                  )}
                </GroupWrapper>
              ) : (
                ""
              )}
              {jobdata.id ? (
                <>
                  <FormLabel className="jobLabel">
                    {t("Add Skills")} (Max 30)
                  </FormLabel>
                  <GroupWrapper>
                    <Skills id={jobdata.id} callback={checkskill} />
                    {errors.skills && (
                      <ErrorField err={t(errors.skills.message)} />
                    )}
                  </GroupWrapper>
                  <FormLabel className="jobLabel">
                    {t("Location(s)")}
                  </FormLabel>
                  <GroupWrapper>
                    <CvLocation
                      jobid={jobdata.id}
                      notification={notification}
                      callback={checkloc}
                    />
                    {errors.location && (
                      <ErrorField err={t(errors.location.message)} />
                    )}
                  </GroupWrapper>
                </>
              ) : (
                <></>
              )}

              <GroupWrapper
                style={{ display: "flex", flexDirection: "column" }}
              >
                <FormLabel>{t("Nationality*")}</FormLabel>
                <div

                  className="jonNation"
                >
                  <RadioInput
                    checked={nationality === "Malaysian"}
                    onChange={() => {
                      let data = jobdata;
                      data["nationality"] = "Malaysian";
                      setJobdata(data);
                      setNationality("Malaysian");
                      errors.nationality = "";
                    }}
                    label={t("Malaysian")}
                    value={nationality}
                  />
                  <RadioInput
                    checked={nationality === "Non Malaysian"}
                    onChange={() => {
                      let data = jobdata;
                      data["nationality"] = "Non Malaysian";
                      setJobdata(data);
                      setNationality("Non Malaysian");
                      errors.nationality = "";
                    }}
                    label={t("Non Malaysian")}
                    name="nationality"
                    value={nationality}
                  />
                </div>
                {errors.nationality && (
                  <ErrorField err={t(errors.nationality.message)} />
                )}
              </GroupWrapper>
              <hr />
              <GroupWrapper>
                <FormLabel>{t("Scope of Study*")}</FormLabel>
                <Controller
                  name="scopes"
                  control={control}
                  render={({ field }) => (
                    <Dropdown
                      search={true}
                      options={scopeOfStudies}
                      placeholder={t("Select Scope of Study")}
                      val={selectedScope}
                      change={scopeAddChange}
                      isMulti={true}
                      {...field}
                    />
                  )}
                />

                {errors.scope && <ErrorField err={t(errors.scope.message)} />}
              </GroupWrapper>
              <GroupWrapper className="alignBetweenAlignAround">
                <div className="adjustInputWidth">
                  <FormLabel>{t("Employment Type*")}</FormLabel>
                  <Dropdown1
                    search={true}
                    options={employmentType}
                    placeholder={t("Select Employment Type")}
                    label={selectedEt?.label}
                    val={selectedEt?.value}
                    change={(value) => {
                      dropdownChange("et", value);
                      errors.et = "";
                    }}
                  />
                  {errors.et && <ErrorField err={t(errors.et.message)} />}
                </div>
                <div className="adjustInputWidth adjustPadding">
                  <FormLabel>{t("Probation Period*")}</FormLabel>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      paddingLeft: "12px",
                    }}
                  >
                    <div style={{ display: "flex", paddingBottom: "12px" }}>
                      <div style={{ marginTop: "12px" }}>
                        <RadioInput
                          checked={probation !== "Not Applicable"}
                          onChange={() => {
                            let data = jobdata;
                            data["probation_period"] = null;
                            setJobdata(data);
                            setProbation(null);
                            errors.probation = "";
                          }}
                          label={""}
                        />
                      </div>
                      <Input
                        disabled={probation === "Not Applicable"}
                        className="decreaseLeftPadding"
                        placeholder={t("Enter value in months")}
                        dataId="job_title"
                        type="number"
                        value={jobdata.probation_period}
                        onChange={(e) => {
                          let data = jobdata;
                          data["probation_period"] = e.target.value;
                          setJobdata(data);
                          setProbation(e.target.value);
                          errors.probation = "";
                        }}
                      />
                    </div>

                    <RadioInput
                      checked={probation === "Not Applicable"}
                      onChange={() => {
                        let data = jobdata;
                        data["probation_period"] = "Not Applicable";
                        setJobdata(data);
                        setProbation("Not Applicable");
                        errors.probation = "";
                      }}
                      label={t("Not Applicable")}
                    />
                    {errors.probation && (
                      <ErrorField err={t(errors.probation.message)} />
                    )}
                  </div>
                </div>
                <div>
                  {/*<SingleSection>
              <FilterHead style={{ minWidth: "125px" }}>
                {t("Job Expiry Date")}{" "}
              </FilterHead>
              <label className="" >
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <ThemeProvider theme={materialTheme} >
                    <DatePicker
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      placeholder={t("Job Expiry Date ")}
                      views={["year", "month", "date"]}
                      value={
                        formVal.job_expiry_day
                          ? new Date(formVal.job_expiry_day)
                          : null
                      }
                      onChange={(val) =>
                        setValue("job_expiry_day", formatDate(val))
                      }
                      // onChange={(val) => dateChange("fromDate", val)}
                      // maxDate={new Date()}
                      maxDate ={new Date(new Date().setDate(new Date().getDate() + Number(job_duration)))}
                      fullWidth
                      
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <CalenderIconWrapper>
                  <CalenderIcon />
                </CalenderIconWrapper>
              </label>
                    </SingleSection>*/}

                  {/*<GroupWrapper>
                <FormLabel>Job Expiry Date*</FormLabel>
                <Controller
                  name="job_expiry_day"
                  control={control}
                  render={({ field }) => (
                    <label className="" >
                <MuiPickersUtilsProvider utils={DateFnsUtils} >
                  <ThemeProvider theme={materialTheme} >
                    <DatePicker
                      autoOk
                      variant="inline"
                      format="dd/MM/yyyy"
                      placeholder={t("Job Expiry Date ")}
                      views={["year", "month", "date"]}
                      value={
                        formVal.job_expiry_day
                          ? new Date(formVal.job_expiry_day)
                          : new Date()
                      }
                      onChange={(val) =>
                        setValue("job_expiry_day", formatDate(val))
                      }
                      // onChange={(val) => dateChange("fromDate", val)}
                      // maxDate={new Date()}
                      maxDate ={new Date(new Date().setDate(new Date().getDate() + Number(job_duration)))}
                      fullWidth
                      
                    />
                  </ThemeProvider>
                </MuiPickersUtilsProvider>
                <CalenderIconWrapper>
                  <CalenderIcon />
                </CalenderIconWrapper>
              </label>
                  )}
                />

                {errors.job_expiry_day && <ErrorField err={errors.job_expiry_day.message} />}
              </GroupWrapper>*/}
                </div>
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Salary Range")}</FormLabel>
                <div className="alignBetweenAlignAround">
                  <div className="adjustInputWidth">
                    <Controller
                      name="salary_from"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#F3F3F3" }}
                          {...field}
                          rows="6"
                          placeholder={t("From")}
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                            if (event.target.value.length > 6) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            setValue("salary_from", e.target.value);

                            errors.salary_from = "";
                            chcheck();
                          }}
                        />
                      )}
                    />
                    {errors.salary_from && (
                      <ErrorField err={t(errors.salary_from.message)} />
                    )}
                  </div>
                  <div className="adjustInputWidth inputMarginTop">
                    <Controller
                      name="salary_to"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#F3F3F3" }}
                          {...field}
                          rows="6"
                          onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                            if (event.target.value.length > 6) {
                              event.preventDefault();
                            }
                          }}
                          onChange={(e) => {
                            errors.salary_to = "";
                            setValue("salary_to", e.target.value);
                            chcheck();
                          }}
                          placeholder={t("To")}
                        />
                      )}
                    />
                    {errors.salary_to && (
                      <ErrorField err={t(errors.salary_to.message)} />
                    )}
                  </div>
                </div>
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Working Arrangement*")}</FormLabel>
                <Controller
                  name="working_arrangement"
                  control={control}
                  render={({ field }) => (
                    <RichTextEditorComponent
                      {...field}
                      value={formVal.working_arrangement}
                      setValue={(val) => {
                        setValue("working_arrangement", val);
                        errors.working_arrangement = "";
                      }}
                    />
                  )}
                />
                {errors.working_arrangement && (
                  <ErrorField err={t(errors.working_arrangement.message)} />
                )}
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Benefits*")}</FormLabel>
                <Controller
                  name="benefits"
                  control={control}
                  render={({ field }) => (
                    <RichTextEditorComponent
                      {...field}
                      value={formVal.benefits}
                      setValue={(val) => {
                        setValue("benefits", val);
                        errors.benefits = "";
                      }}
                    />
                  )}
                />
                {errors.benefits && (
                  <ErrorField err={t(errors.benefits.message)} />
                )}
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel style={{ fontSize: "24px", marginBottom: "24px" }}>
                  {t("Required Documents")}
                </FormLabel>
                <FormLabel>{t("Document Type*")}</FormLabel>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    flexFlow: "wrap",
                  }}
                >
                  <div style={{ width: "420px" }}>
                    <Controller
                      name="document_type"
                      control={control}
                      render={({ field }) => (
                        <Input
                          style={{ background: "#F3F3F3" }}
                          {...field}
                          rows="6"
                          placeholder="Resume, Cover Letter, etc.."
                          value={documentType}
                          onChange={(e) => {
                            setDocumentType(e.target.value);

                            errors.document = "";
                          }}
                        />
                      )}
                    />
                    {errors.document && (
                      <ErrorField err={t(errors.document.message)} />
                    )}
                  </div>
                  <div className="checkboxMargin">
                    <CheckboxInput
                      margin="15px"
                      label={t("Mandatory")}
                      checked={mandatory === "Y"}
                      onChange={() => {
                        if (mandatory === "Y") {
                          setMandatory("N");
                        } else {
                          setMandatory("Y");
                        }
                      }}
                    />
                  </div>
                  {/* <Row xs={1} md={3} > */}
                  {/* <Col> */}

                  {/* </Col>
            </Row> */}
                </div>

                <GroupWrapper className="alignSpaceBetween">
                  <Button
                    style={{ marginTop: "24px" }}
                    onClick={async () => {
                      if (!adddoc) {
                        setAdddoc(true);
                        chcheck();
                        await add_document();
                        setAdddoc(false);
                      }
                    }}
                    name={t("+ Add Document")}
                    disabled={adddoc}
                  />
                </GroupWrapper>
              </GroupWrapper>
              <GroupWrapper>
                <TableLayout>
                  <TableWrapper threeLayout>
                    <TableHead>{t("Document")}</TableHead>
                    <TableHead>{t("Mandatory")}</TableHead>
                  </TableWrapper>
                  {document.length > 0
                    ? document.map((item, index) => (
                      <div>
                        <hr />
                        <TableWrapper key={index} threeLayout>
                          <TableData>{item.document_type}</TableData>
                          {item.mandatory === "Y" ? (
                            <TableData>{t("Yes")}</TableData>
                          ) : (
                            <TableData>{t("No")}</TableData>
                          )}

                          <TableData>
                            {" "}
                            <img
                              src={Delete}
                              onClick={() => {
                                detete_document(item.id);
                              }}
                            />
                          </TableData>
                        </TableWrapper>
                      </div>
                    ))
                    : null}
                </TableLayout>
              </GroupWrapper>
              <GroupWrapper
                className="alignSpaceBetween"
                style={{ marginTop: "36px" }}
              >
                <div></div>
                <Button
                  style={{ marginTop: "0px", width: "152px" }}
                  onClick={() => {
                    update();
                  }}
                  name={t("Proceed")}
                />
              </GroupWrapper>
            </div>
          ) : null}
        </div>
      ) : null}
      {(type === "add" || type === "edit") && tabIndex === 2 ? (
        <div>
          <GroupWrapper>
            <FormLabel>{t("Work Competency Factors")}</FormLabel>
            <SmallText style={{ paddingLeft: "12px" }}>
              {t("Select the 3 preferred competencies you are looking for in your candidates.")}
            </SmallText>
            <FormControl
              required
              error={error}
              component="fieldset"
              sx={{ m: 3 }}
              variant="standard"
            >
              <FormGroup>
                <FormControlLabels
                  control={
                    <Checkbox checked={research_evaluate} onChange={handleChange} name="research_evaluate" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Evaluating & Resolving")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={inception_formation} onChange={handleChange} name="inception_formation" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Innovating & Envisioning")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={communication_presentation} onChange={handleChange} name="communication_presentation" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Connecting & Portraying")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={leadership} onChange={handleChange} name="leadership" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Supervising & Determining")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={teamwork} onChange={handleChange} name="teamwork" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Facilitating & Collaborating")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={collectedness} onChange={handleChange} name="collectedness" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Embracing & Managing")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={plan_execte} onChange={handleChange} name="plan_execte" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Coordinating & Implementing")}
                />
                <FormControlLabels
                  control={
                    <Checkbox checked={inventive_accomplishment} onChange={handleChange} name="inventive_accomplishment" sx={{
                      color: "#D04C8D",
                      '&.Mui-checked': {
                        color: "#D04C8D",
                      },
                    }} />
                  }
                  label={t("Ambitious & Result-Oriented")}
                />
              </FormGroup>
              {error && <FormHelperText>{t("Must select 3")}</FormHelperText>}
            </FormControl>
          </GroupWrapper>
          <GroupWrapper
            className="alignSpaceBetween"
            style={{ marginTop: "36px" }}
          >
            <div></div>
            <Button
              style={{ marginTop: "0px", width: "152px" }}
              onClick={update1}
              margin="15px 10px 15px 0px"
              disabled={error ? true : false}
              name={t("Publish")}
            />
          </GroupWrapper>
        </div>
      ) : null}
    </FormWrapper>
  );
}
