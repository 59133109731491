import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image, Tooltip } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import "./jobCardTitle.css";
import { Colors } from "../../utilities/colors";
import JobIcon from "../../assets/icons/svg/businessman.svg";
import PendingPayment from "../../assets/icons/pending-payment.png";
import SalaryIcon from "../../assets/icons/svg/salary.svg";
import ViewIcon from "../../assets/icons/svg/view.svg";
import EditIcon from "../../assets/icons/svg/pencil.svg";
import DeleteIcon from "../../assets/icons/svg/trash.svg";
import Expired from "../../assets/icons/svg/expiredIcon.svg";
import Remarks from "../../assets/icons/svg/remarks.svg";
import Accepted from "../../assets/icons/svg/acceptedIcon.svg";
import Pending from "../../assets/icons/svg/pendingIcon.svg";
import Reject from "../../assets/icons/reject-48.png";
import AlertModal from "../alertModal/alertModal";
import Button from "../button/button";
import Duration from "../../assets/icons/svg/duration.svg";
import { onDeletingInternship, jobActionReview } from "../../services/apiCalls";
import { dateToDateMonthInWordsYear } from "../../utilities/commonFunctions";
import JobCardTitle from "./jobCardTitle";
import moment from "moment";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";

const FlexDiv = styled.div`
  display: flex;
`;
const CardsWrapper = styled(FlexDiv)`
  background: ${Colors.light};
  border-radius: 10px;
  margin-bottom: 15px;
  padding: 15px 20px;
  &:last-child() {
    margin-bottom: 0px;
  }
  @media (max-width: 767px) {
    padding: 10px 20px;
    display: block;
  }
`;
const ContentSection = styled.div`
  margin-left: 10px;
  width: calc(100% - 26px);
  cursor: pointer;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    right: 25px;
    margin-top: -23px;
    margin-left: 14px;
  }
`;
const IconSection = styled.div`
  padding: 7px;
  cursor: pointer;
`;

const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const SmallText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
`;
const JobPostedDate = styled(DateText)`
  // color: ${Colors.primaryText};
  margin: -10px 0 0 0;
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #d04c8d;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0px 0px 0px 0px;
    position: relative;
    // bottom:20px;
    right:25px;
    font-size: 12px;
    line-height: 16px;
  }
  @media (min-width: 971px) and (max-width: 1200px) {
    margin: 20px 0px 0px 0px;
  }
`;
const JobExpiredDate = styled(DateText)`
  // color: #504f8c;
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-align: right;
  letter-spacing: 1px;
  color: #504f8c;
  position:relative;
  bottom:10px;
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    top:10px;
    right:20px;
    margin-bottom:10px;
  }
`;
const DateWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
    position:relative;
    bottom:10px;
  }
`;
const FlexWrapper = styled(FlexDiv)`
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const P = styled.p`
  font-family: "General Sans";
  font-style: normal;
  display: contents;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #212121;
  margin-top: -30px;
  position: relative;
  bottom: 15px;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 0px 0;
    text-transform: capitalize;
  }
`;
const OtherTextWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  @media (max-width: 500px) {
    flex-direction: column;
    position:relative;
    bottom:25px;
  }
`;
const JobDescriptionSection = styled(FlexDiv)`
  position: relative;
  display: block;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    margin-left: 45px;
  }
`;
const IconSingleSection = styled(FlexDiv)`
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
  @media (max-width: 500px) {
    margin-bottom: 5px;
    position: relative;
    // left:20px;
  }
`;
const OtherText = styled.p`
  margin: 0;
  margin-left: 3px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  @media (max-width: 500px) {
    font-family: "General Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #525252;
    text-align: right;
    margin-bottom: 10px;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  @media (max-width: 500px) {
    left: 30px;
    bottom:25px;
  }
`;
const VacancyText = styled(SmallText)`
  color: #b3b3b3;
  display: flex;
  align-items: center;
  // margin-top:-10px;
  > img {
    margin-right: 5px;
  }
`;
const Icons = styled.img`
  margin: 5px 10px 10px 10px;
  cursor: pointer;
`;
const Message = styled.div`
  margin-bottom: 20px;
  // padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;
const ApplicationText = styled(SmallText)`
  color: ${Colors.primaryText};
  margin-left: ${(props) => (props.margin ? props.margin : "")};
`;
export const dateDifference = (date) => {
  let date1 = new Date(date);
  let date2 = new Date();
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
};

export default function JobsCards({
  datas,
  pending,
  intern,
  onUpdate,
  showToast,
  current_new,
}) {
  const org = useSelector((state) => state.org.orgData);
  const company = useSelector((state) => state.company.companyData);
  // const [orgDetails, setOrgDetails] = useState({});
  const orgDetails = useSelector((state) => state.company.currentOrgData);
  const code = useSelector((state) => state.company.selectedGlobalOrgCode); //org?.additional_info?.org_code;
  const isUserPermittedToEdit =
    orgDetails?.user_details?.internship_modify === "Y" ? true : false;
  // const [toggleStatus, setToggleStatus] = useState("");
  const {
    title,
    // end_date,
    // req_date,
    viewed_count,
    intern_applied_count,
    is_activate,
    offered_count,
    created_at,
    position_end_date,
    position_start_date,
    exp_end_date,
    exp_start_date,
    no_positions,
    min_compensation,
    max_compensation,
    intern_type,
    direct_hiring_type,
    status,
    contact_name,
    payment_status,
  } = datas;

  console.log("DataCall",datas)

  const navigate = useNavigate();

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  
  const getStatusText = () => {
    switch (status) {
      case 'pending_payment':
        return 'Pending Payment';
      case 'accepted':
        return 'Accepted';
      case 'rejected':
        return 'Rejected';
      case 'pending':
        return "Pending"
      case 'review':
        return "In-Review"
      default:
        return '';
    }
  };
  const onNavigate = () => {
   
    if(intern_type === "Direct Hiring" || intern_type === "Advertise"){ 
      navigate(`/loe-application/view-internship?id=${datas.id}&fromInternship=${true}`);
    }else{
      navigate({ pathname: "/internships/details", search: `?id=${datas.id}` });
    }
   
  };

  const positionStartDuration = (created_at) => {
    let dateDifferences = dateDifference(created_at);
    if (dateDifferences === 1) {
      return dateDifferences + t(" day ago");
    } else if (dateDifferences > 1) {
      return dateDifferences + t(" days ago");
    } else if (dateDifferences === 0) {
      return t("Today");
    } else {
    }
  };

  useEffect(() => {
    positionStartDuration();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getDuration = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    const date1 = moment(d1).format("DD/MM/YYYY");
    const date2 = moment(d2).format("DD/MM/YYYY");
    var startDate = new Date(
      date1.split("/")[2],
      date1.split("/")[1] - 1,
      date1.split("/")[0]
    );
    var endDate = new Date(
      date2.split("/")[2],
      date2.split("/")[1] - 1,
      date2.split("/")[0]
    );

    var diff = Math.abs(endDate - startDate);
    var days = Math.ceil(diff / (1000 * 60 * 60 * 24));

    var weeks = Math.floor(days / 7);
    var remainingDays = days % 7;

    if (weeks === 0 && remainingDays === 0) {
      return `1` + " " + "day";
    } else if (weeks === 0) {
      return `${remainingDays}` + " " + (remainingDays === 1 ? "day" : "days");
    } else if (remainingDays === 0) {
      return `${weeks}` + " " + (weeks === 1 ? "week" : "weeks");
    } else {
      return `${weeks} ${weeks === 1 ? "week" : "weeks"} and ${remainingDays} ${
        remainingDays === 1 ? "day" : "days"
      }`;
    }
  };

  const onEdit = (e) => {
    e.stopPropagation();
    navigate({
      pathname: "/internships/new-intern",
      search: `?id=${datas.id}&fromInternshipPage=true`,
    });
    if(intern_type === "Direct Hiring"){ 
      navigate({
        pathname: "/internship/create-internship/new-internship",
        search: `?id=${datas.id}&fromDirectHiringInternshipPage=true`,
      });
    }
  };

  const [show, setShow] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  const getInternTypeName = (intern_type) =>{

    switch(intern_type){
      
      case 'Advertise':
      case 'advertise':
      case 'sip':
        return 'MySIP Advertised';

      case 'Direct Hiring':
        return 'MySIP Direct Hiring';

      case 'general':
      case 'General Internship':
        return "General Internship"
    }
    
  }

  const getDirectHiringType = (type) =>{
    switch(type){
      case 'on-going':
        return "On-Going";
      
      case 'completed':
        return "Completed";
    }
  }
  useEffect(() => {
    if (intern_type === "General Internship") {
      if (status === "pending") {
        setShowEditIcon(true);
        setImageSrc(Pending);
      } else if (status === "accepted") {
        setImageSrc(Accepted);
        setShowEditIcon(false);
      } else if (status === "expired") {
        setShowEditIcon(false);
        setImageSrc(Expired);
      } else if (status === "pending_payment") {
        setShowEditIcon(true);
        setImageSrc(PendingPayment);
      } else if (status === "review") {
        setImageSrc(Remarks);
        setShowEditIcon(true);
      }
    } else if (intern_type === "SIP" || intern_type === "sip" || intern_type === "Advertise") {
      if (status === "pending") {
        setShowEditIcon(true);
        setImageSrc(Pending);
      } else if (status === "accepted") {
        setImageSrc(Accepted);
        setShowEditIcon(false);
      } else if (status === "expired") {
        setShowEditIcon(false);
        setImageSrc(Expired);
      } else if (status === "pending_payment") {
        setShowEditIcon(true);
        setImageSrc(PendingPayment);
      } else if (status === "review") {
        setImageSrc(Remarks);
        setShowEditIcon(true);
      }
    } else if(intern_type === "Direct Hiring"){
      if (status === "pending") {
        setShowEditIcon(true);
        setImageSrc(Pending);
      } else if (status === "accepted") {
        setImageSrc(Accepted);
        setShowEditIcon(false);
      } else if (status === "expired") {
        setShowEditIcon(false);
        setImageSrc(Expired);
      } else if (status === "pending_payment") {
        setShowEditIcon(true);
        setImageSrc(PendingPayment);
      } else if (status === "review") {
        setImageSrc(Remarks);
        setShowEditIcon(true);
      }
    }


    if (
      status === "expired" ||
      moment.utc(position_end_date).format("YYYY-MM-DD") <
        moment.utc().format("YYYY-MM-DD")
    ) {
      setImageSrc(Expired);
      setShowEditIcon(false);
    }

    if (status === "rejected") {
      setImageSrc(Reject);
      setShowEditIcon(false);
    }

    if (payment_status !== "paid") {
    }
  }, [intern_type, payment_status, position_end_date, status]);

  const onConfirmDeletion = async () => {
    const { data } = await onDeletingInternship({
      code: code,
      id: datas.id,
      update_time: datas.update_time,
    });

    if (data && data.status === 1) {
      setShow(false);
      toast.success(t("Internship deleted successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      onUpdate();
    } else {
      toast.error(t("Unable to delete Internship"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const postedby = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };
  const closeModal = () => {
    setShow(false);
  };
  const onDelete = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const content = () => {
    return (
      <>
        <Message>{t("Are you sure want to delete the Internship?")}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="20px 0px 30px 0px"
              name={t("Yes")}
              onClick={onConfirmDeletion}
            />
            <Button
              secondary
              width="100px"
              name={t("No")}
              color="#FFFFFF"
              margin="20px 0px 30px 20px"
              onClick={closeModal}
            />
          </Col>
        </Row>
      </>
    );
  };

  const makeActivateOrDeactivate = ($cks) => {
    var mainStatus = "";
    if ($cks === false) {
      mainStatus = t("deactivate");

    } else {
      mainStatus = t("activate");
    }
    const body = {
      id: datas.id,
      status: mainStatus,
      update_time: datas.update_time,
    };
    const result = jobActionReview(body);
    showToast();
    if (result.status === 1) {
      toast.success(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (result.data.status === 1) {
        
      }
    }
  };
  const { t } = useTranslation();
  return (
    <CardsWrapper onClick={onNavigate}>
      <IconSection data-tip={getStatusText()} data-for={"status-tooltip"}>
        {status === "pending_payment" || status === "rejected" ? (
          <Image src={imageSrc} className="imagePending"  />
        ) : (
          <Image src={imageSrc} className="imagePending" />
        )}
         <ReactTooltip
            id="status-tooltip"
            effect="solid"
            place="top"
            backgroundColor="black"
          />
      </IconSection>
      <ContentSection>
        <JobCardTitle
          title={title}
          navigation={onNavigate}
          onToggle={makeActivateOrDeactivate}
          pending payment is an assumption for now
          isActivate={is_activate}
          status={status}
          position_end_date={position_end_date}
        />
        <DateWrapper onClick={onNavigate}>
          <JobPostedDate>
            {/* {status === "pending" ? t("Requested") : t("Posted")}:{" "} */}
            {status !== "pending"
              ? position_start_date
                ? dateToDateMonthInWordsYear(position_start_date)
                : position_start_date
              : created_at
              ? dateToDateMonthInWordsYear(created_at)
              : created_at}
            {/* {created_at
              ? dateToDateMonthInWordsYear(created_at)
              : created_at} */}
          </JobPostedDate>
          {status === "pending" && current_new !== "expired" ? (
            ""
          ) : current_new === "expired" ||
            status === "expired" ||
            moment.utc(position_end_date).format("YYYY-MM-DD") <
              moment.utc().format("YYYY-MM-DD") ? (
            t("Expired")
          ) : status === "review" ? (
            t("Sent back for Review")
          ) : status === "rejected" ? (
            t("Denied")
          ) : (
            // <JobExpiredDate>
            //   {t("Expires")}:{" "}
            //   {position_end_date
            //     ? dateToDateMonthInWordsYear(position_end_date)
            //     : position_end_date}
            // </JobExpiredDate>
            null
          )}
        </DateWrapper>
        <OtherText id="textCreate">
          {positionStartDuration(created_at)}
        </OtherText>  
        {intern_type === "Direct Hiring"?(
          null
        ): (
          <OtherText id="textCreate">
          Offered: {offered_count}
        </OtherText>
        )}
        <OtherTextWrapper onClick={onNavigate}>
          <JobDescriptionSection className="col-lg-12">
            <Row>
              <Col lg={3}>
                <IconSingleSection>
                  <OtherText>
                    {t("Vacancies")}: {no_positions}
                  </OtherText>
                </IconSingleSection>
              </Col>
              <Col lg={3}>
                <IconSingleSection
                  margin="20px"
                  className="iconOne"
                >
                  <img style={{ marginLeft: "5px" }} src={JobIcon} alt="job" />
                  {intern_type === "Direct Hiring" ?(
                    <OtherText>
                    {getInternTypeName(intern_type) +" ("+ getDirectHiringType(direct_hiring_type) +")"} 
                    {/* {" "}
                    {intern_type === "sip" ? "MySIP" : intern_type } */}
                  </OtherText>
                  ): 
                  (
                    <OtherText>{getInternTypeName(intern_type)}</OtherText>
                  )
                  }
                </IconSingleSection>
              </Col>
              <Col lg={3}>
                <IconSingleSection>
                  <img src={SalaryIcon} alt="job" />
                  {payment_status === "unpaid" ? (
                    <OtherText>0 MYR </OtherText>
                  ) : (
                    <OtherText>
                      {min_compensation} {" MYR - "} {max_compensation} {" MYR"}
                    </OtherText>
                  )}
                </IconSingleSection>
              </Col>
              <Col lg={3}>
                <IconSingleSection>
                  <img
                    src={Duration}
                    alt="duration"
                    className="durationImage"
                  />
                  <OtherText className="textStyle">
                    {getDuration(exp_start_date, exp_end_date)}
                  </OtherText>
                </IconSingleSection>
              </Col>
            </Row>
          </JobDescriptionSection>
        </OtherTextWrapper>
        <>
          <HrLine />
          {!intern ? (
            <DateWrapper onClick={onNavigate}>
              <FlexWrapper>
                <VacancyText>
                  <img src={ViewIcon} alt="view" />{" "}
                  {viewed_count ? viewed_count + t(" views") : t("No views")}
                </VacancyText>
                {/* <ApplicationText margin="30px">
                  {intern_applied_count > 0
                    ? intern_applied_count + t(" Applications")
                    : t("No Applications")}{" "}
                </ApplicationText> */}
              </FlexWrapper>
              <FlexWrapper>
                <P>{t("Posted by")} : {postedby(contact_name)}</P>
              </FlexWrapper>
            </DateWrapper>
          ) : (
            <DateWrapper onClick={onNavigate}>
              <FlexWrapper>
                <VacancyText>
                  <img src={ViewIcon} alt="view" />{" "}
                  {viewed_count ? viewed_count + t(" views") : t("No views")}
                </VacancyText>
                {/* <ApplicationText margin="30px">
                  {intern_applied_count > 0
                    ? intern_applied_count + t(" Applications")
                    : t("No Applications")}
                </ApplicationText> */}
              </FlexWrapper>
              <FlexWrapper>
                <P>{t("Posted by")} : {postedby(contact_name)}</P>
              </FlexWrapper>
              {showEditIcon &&
              isUserPermittedToEdit &&
              current_new !== "expired" &&
              moment.utc(position_end_date).format("YYYY-MM-DD") >=
                moment.utc().format("YYYY-MM-DD") ? (
                <FlexWrapper onClick={onNavigate}>
                      <Icons
                        onClick={onEdit}
                        src={EditIcon}
                        alt="edit intern"
                        color={Colors.greyText} 
                      />
                      <Icons
                        onClick={onDelete}
                        src={DeleteIcon}
                        alt="delete intern"
                      />
                </FlexWrapper>
              ) : null}
            </DateWrapper>
          )}
        </>
      </ContentSection>
      <AlertModal show={show} close={closeModal} content={content()} />
    </CardsWrapper>
  );
}
