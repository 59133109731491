import { useEffect, useState, useRef } from "react";

import { useSelector, useDispatch } from "react-redux";
import styled from "styled-components";
import { CONSTANTS } from "../constants/constants";
import { ReactComponent as Help } from "../../assets/icons/svg/helpIcon.svg";
import ProfileIcon from "../../assets/avatar.png";
import { Dropdown, Nav, Navbar } from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import { IoIosLogOut } from "react-icons/io";
import { authLogout } from "../../services/apiCalls";
import {
  getOrgData,
  getUserData,
  getCompanies,
  getCompanyData,
  getCompanySubscription,
} from "../../services/apiCalls.js";
import { updateOrgData } from "../../pages/login/reducer.js";
import { updateUserData } from "../../pages/login/userReducer";
import {
  updateCompanyData,
  updateCurrentlySelectedCompanyData,
  updateCurrentlySelectedOrgData,
  updateSelectedGlobalOrgCode,
  setReloadPage
} from "../../pages/myCompany/reducer";
import { updateSubscriptionData } from "../../pages/Subscriptions/reducer";
import ConfirmationDialog from "../alertModal/confirmation-dialog";
import HamburgerLay from "../../components/hamburger/hamburger";
import Menus from "../../components/hamburger/menus";
import { useTranslation } from "react-i18next";
import englishFlag from "../../assets/en2.png";
import malayFlag from "../../assets/my2.png";
import Logo from "../../assets/companylogosettings.png";
import { Colors } from "../../utilities/colors";
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import "./navbar.css";
import { useSnapshot } from "valtio";
import { companyProxy } from "../../pages/myCompany/companyproxy";
import { menuDropDown } from "../../menuItems";
import ReactTooltip from "react-tooltip";
import moment from "moment";
const styles = {
  control: (base, state) => ({
    ...base,
    height: "45px",
    padding: "5.5px 5px",
    transition: "none",
    textTransform: "capitalize",
    width: "100%",
    background: `${Colors.light}`,
    border: "0px",
    outline: "none",
    boxShadow: " inset 0px 0px 4px rgba(0, 0, 0, 0)",
    borderRadius: "128px",
  }),
  menu: (base) => ({
    ...base,
    marginTop: "0px",
    outline: "none",
    zIndex: "3",
    textTransform: "capitalize",
    border: "0px solid white",
    transition: "none",
  }),
  menuList: (base) => ({
    ...base,
    color: "#575757;",
    fontSize: "12px",
    overflow: "scroll",
    textTransform: "capitalize",
  }),
  option: (provided, state) => ({
    ...provided,
    width: "150%",
    height: "100%",
    fontSize: "20px",
    fontFamily: "General Sans",
    fontWeight: `500`,
    overflow:"hidden",
    backgroundColor: state.isSelected ? `#D04C8D` : `transparent`,
    color: state.isSelected ? `white` : `#202020`,
    "&:hover": {
      backgroundColor: state.isSelected ? `#D04C8D` : `#FFE9F4`,
      Color: `#D04C8D`,
    },
    // "@media (max-width:500px)":{
    //   width:"260px"
    // }
  }),
  input: (base, state) => ({
    ...base,
    '[type="text"]': {
      color: `#575757; !important`,
    },
  }),
};

const HeaderWrapper = styled.div`
  display: flex;
  -webkit-justify-content: flex-end;

  -webkit-align-items: flex-end;
  justify-content: space-between;
  padding: 0rem 0rem 0rem 3rem;
  margin-bottom: 40px;

  > img {
    margin-right: 10px;
    max-width: 30px;
    cursor: pointer;
    &:last-child() {
      margin-right: 0px;
    }
  }
`;
const NavHeader = styled.div`
  padding: 10px 0.5rem;
  @media (max-width: 767px) {
    margin-right: -80px;
  }
  a {
    padding: 0.5rem;
    // color: rgba(0, 0, 0, 0.55);
    text-decoration: none;
  }
  .dropdown-item {
    padding: 0.25rem 1rem;
  }
`;
const NavHeader1 = styled.div`
  a {
    padding: 0.5rem;
    color: rgba(0, 0, 0, 0.55);
    text-decoration: none;
  }
  .dropdown-item {
    padding: 0.25rem 1rem;
  }
  @media (min-width: 768px) and (max-width: 1100px) {
    position: relative;
    margin: 0px 30px 0px 10px;
    width: 192px;
  }
  @media (max-width: 500px) {
    width: 173px;
    margin-left: 15px;
  }
`;

const ProfileLogo = styled.div`
  width: 30px;
  height: 30px;
  background: #ffffff;
  display: flex;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 28px;
    width: 28px;
  }
`;
const DropdownList = styled.span`
  padding-left: 10px;
`;

const HamburgerLayout = styled.div`
  margin-bottom: 55px;
  @media (min-width: 1023px) {
    display: none;
  }
`;

const HamburgerLayout1 = styled.div`
  @media (min-width: 1023px) {
    display: none;
  }
  // @media (max-width: 500px){
  //   margin-left:10px;
  // }
`;
const FlagLogo = styled.div`
  width: 30px;
  height: 30px;
  background: rgb(208, 76, 141);
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img {
    height: 32px;
    width: 32px;
  }
`;

const CardsWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 30px;
  margin: 10px 0 10px 0;
  padding: 0px 0px 70px 10px;
  // width: 40%;
  height: 50px;
  line-height: 18px;
  font-size: 15px;

  @media (max-width: 768px) {
    // padding: 0px 0px 25px 10px;
    display: block;
    width: 100%;
    margin-left: -60px;
    font-size: 10px;
    > div h7 {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

const CardsWrapper1 = styled.div`
  background: ${Colors.light};
  border-radius: 30px;
  margin: 10px 0 10px 0;
  padding: 0px 0px 70px 10px;
  height: 50px;
  line-height: 18px;
  font-size: 15px;

  @media (min-width: 768px) and (max-width: 1100px) {
    background: #fff;
    border-radius: 30px;
    margin: 10px 0 10px 25px;
    padding: 0px 0px 70px 10px;
    height: 50px;
    line-height: 18px;
  }
  @media (max-width: 768px) {
    // padding: 0px 0px 25px 10px;
    display: block;
    width: 100%;
    font-size: 10px;
    > div h7 {
      font-size: 12px;
      font-weight: bold;
    }
  }
  @media (max-width: 500px) {
    padding: 0px 0px 25px 0px;
    display: block;
    width: 100%;
    font-size: 10px;
     margin-left: 30px;
    > div h7 {
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

const DropNavLink = styled(NavLink)`
padding: 10px 0px;
display: flex;
align-items: center;
color: #acacac;
&:hover {
  color:  rgb(208, 76, 141);
  // border-right: 5px solid ${Colors.pink};
}
&.active {
  color: rgb(208, 76, 141);
  // border-right: 5px solid ${Colors.pink};
  // // background-color: ${Colors.lightPink};
  // //box-shadow: -5px 2px 5px 0px rgba(0,0,0,0.28) inset;
  // border-radius:5px;
  // border-left: 1px solid rgba(0,0,0,0.08);
  // padding-bottom: 10px;
}
`;
const MenuDisabledList = styled.div`
padding: 10px 0px;
display: flex;
align-items: center;
color: #acacac;
`;
const DropItem = styled.div`
display: block;
width: 100%;
clear: both;
font-weight: 400;
text-align: inherit;
text-decoration: none;
white-space: nowrap;
background-color: transparent;
border: 0;
padding-right: 17px;
padding-left: 10px;


`;
const SingleSection = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  letter-spacing: 0.01em;
  color: #000000;
  width: 100%;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    width: 100%;
    flex-wrap: wrap;
  }
`;

const ImageDropdown = styled.img`
  width: 58px;
  height: 54px;
  @media (max-width: 500px) {
    width: 30px;
    height: 28px;
  }
`;

const Location = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  margin: -22px 0 0 65px;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 12px;
    margin: -10px 0 0 35px;
  }
`;
const Title = styled.div`
  margin: -50px 0 0 70px;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 12px;
    margin: -25px 0 0 40px;
  }
`;
export default function TopNavbar() {
  const [logoutConfirmation, setLogoutConfirmation] = useState(false);
  // const navigate = useNavigate();
  const org = useSelector((state) => state?.org?.orgData);
  const [isOpenham, setOpenHam] = useState(false);
  const [currentUserEmail, setCurrentUserEmail] = useState("");
  const selectValue = useSelector(
    (state) => state.company.selectedGlobalOrgCode
  );
  const subscribedData = useSelector(
    (state) => state.subscription.subscriptionData
  );
  const selected_org_code = useSelector((state) => state.company.selectedGlobalOrgCode)
  const companyData = useSelector((state) => state.company.currentlySelectedcompanyData);
  const userStatus = useSelector((state) => state.company.userAcceptedStatus);
  const cpyOrgData = useSelector((state) => state.company.currentOrgData)

  // const userStatus = currentStateUser?.status;

  const dispatch = useDispatch();
  const company = useSelector((state) => state.company.companyData);
  const selectedCompany = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );

  const user = useSelector((state) => state.user.userData);
  const { t, i18n } = useTranslation();
  var currentUser = "";
  const [profile_picture, setProfilePicture] = useState("");
  const [selectedOrgCode, setSelectedOrgCode] = useState(undefined);
  const [options, setOptions] = useState([]);
  const selectRef = useRef();
  const companyStatus = companyData.status

  useSnapshot(companyProxy);

  useEffect(() => {
    if (isEmpty(user)) getUserInfo();
    if (isEmpty(company)) getOrganisationInfo();
  }, []);

  useEffect(() => {
    if (isEmpty(company)) getOrganisationInfo();
  },[selectValue]);

  useEffect(() => {
    getCompanyInfo(selectValue);
    if (Object.keys(company).length > 0) generateCompanyOptions();
  }, [company]);

  useEffect(() => {
    if (company && Object.keys(company).length > 0) {
      companyProxy.setcompany(
        Object.entries(company).filter(
          (idx) => idx[1]?.org_code === selectValue
        )[0][1]
      );
      dispatch(
        updateCurrentlySelectedOrgData({
          ...Object.entries(company).filter(
            (idx) => idx[1].org_code === selectValue
          )[0][1],
        })
      );
    }
  }, [selectValue]);

  const compo = {
    IndicatorSeparator: () => null,
    dropdownIndicator: () => null,
  };

  useEffect(() => {
    if (selectedOrgCode) getSubscription();
  }, [selectedOrgCode]);

  const getSubscription = async () => {
    const { data } = await getCompanySubscription(selectedOrgCode);
    if (data && data.status === 1 && data.data.length) {
      let localObj = {};
      localObj[selectedOrgCode] = data.data;
      dispatch(updateSubscriptionData(localObj));
    }
  };

  function isEmpty(obj) {
    if (obj === undefined) return true;
    return Object.keys(obj).length === 0;
  }

  const formatOptionLabel = ({
    value,
    label,
    customAbbreviation,
    image,
    location,
  }) => (
    <>
      <Row>
        <Col xs={1}>{image}</Col>
        <Title>
          <Col xs={10}>{label}</Col>
        </Title>
        <Col xs={1}>{customAbbreviation}</Col>
      </Row>
      <Row>
        <Location>
          <Col>📍{location}</Col>{" "}
        </Location>
      </Row>
    </>
  );

  function generateCompanyOptions() {
    let arrOptions = [];
    for (let i = 0; i < Object.keys(company)?.length; i++) {
      let obj = {};
      obj.value = company[i]?.org_code;
      obj.label = company[i]?.org_name;
      obj.image = <ImageDropdown src={company[i]?.org_logo} />;
      obj.location =
        company[i]?.org_state_name + ", " + company[i]?.org_country_name;
      arrOptions.push(obj);
    }
    setOptions(arrOptions);
  }

  const getUserInfo = async () => {
    const { data } = await getUserData();
    if (data && data.status === 1) {
      dispatch(updateUserData({ ...data.data, domain: data.domain }));
      setCurrentUserEmail(data.data["email"]);
      setProfilePicture(data.data.profile_picture);
    }
  };

  const getOrganisationInfo = async () => {
    const { data } = await getCompanies();
    if (data && data.status === 1) {
      if (JSON.stringify(company) != JSON.stringify(data.data)) {
        localStorage.setItem("companyData",JSON.stringify(data))

        let code = getLastViewedCompany(data.data);
        setSelectedOrgCode(code);
        dispatch(updateSelectedGlobalOrgCode(code));
        dispatch(updateCompanyData({ ...data.data }));
        companyProxy.setcompany(
          data.data.filter((idx) => idx.org_code === code)[0]
        );
        dispatch(
          updateCurrentlySelectedOrgData({
            ...data.data.filter((idx) => idx.org_code === code)[0],
          })
        );
      }
    }
  };

  function getLastViewedCompany(data) {
    let arr = Object.keys(data);
    let org = null;
    for (let i = 0; i < arr.length; i++) {
      if (data[arr[i]].org_selected == 1) {
        org = data[arr[i]].org_code;
      }
    }
    return org ? org : arr[0];
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const getCompanyInfo = async (last_login_org_code) => {
    if (last_login_org_code) {
      const { data } = await getCompanyData({ code: last_login_org_code }); //hardcoded to 0 .Need to assign with the last viewed/last created company
      if (data && data.status === 1) {
        dispatch(updateCurrentlySelectedCompanyData({ ...data.data }));
      }
    }
  };
 
  // Confirmation-dialog
  const onUserLogoutConfirmation = () => {
    setLogoutConfirmation(true);
  };
  const navigate = useNavigate();


  const onLogout = async () => {
    const { data } = await authLogout();
    if (data && data.status === 1) {
      localStorage.removeItem("auth_token");
      window.location.assign("/login");
    }
  };
  const changeLanguageHandler = (lang) => {
    const languageValue = lang;
    i18n.changeLanguage(languageValue);
    sessionStorage.setItem("lang", languageValue);
    localStorage.setItem("lang", languageValue);
  };

  function handleChange(e) {
    getCompanyInfo(e.value);
    setSelectedOrgCode(e.value);
    dispatch(updateSelectedGlobalOrgCode(e.value));
    navigate("/dashboard")
    dispatch(setReloadPage(true));
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }
  const onNavigate = (path) => {
    navigate({ pathname: path });
  };
  const creatDisalbeDropItem = (menu,index) =>{
    return menu.name == 'My Profile' ? <DropNavLink
    key={index}
    to={menu.path}
  
    >
    <DropItem>
    {menu.icon}
      <DropdownList>{t(menu.name)}</DropdownList>
    </DropItem>
  
    </DropNavLink>

    : <MenuDisabledList
    key={index} data-html={true} data-place="right"
  
    >

    <DropItem>
    {menu.icon}
      <DropdownList data-html={true} data-tip={t('Company approval is pending<br />with mynext Admin')}>{t(menu.name)}</DropdownList>
    </DropItem>
  
    <ReactTooltip  className="dataToolTip"/>

    </MenuDisabledList>

  }
  const creatDisalbeDropItemRenew = (menu,index) =>{
    return menu.name == 'My Profile' ? <DropNavLink
    key={index}
    to={menu.path}
  
    >
    <DropItem>
    {menu.icon}
      <DropdownList>{t(menu.name)}</DropdownList>
    </DropItem>
  
    </DropNavLink>

    : <MenuDisabledList
    key={index} data-html={true} data-place="right"
  
    >

    <DropItem>
    {menu.icon}
      <DropdownList data-html={true} data-tip={t('Your subscription is expired. Please renew to use this feature')}>{t(menu.name)}</DropdownList>
    </DropItem>
  
    <ReactTooltip  className="dataToolTip"/>

    </MenuDisabledList>

  }
  function checkIsSubscriptionExpired(data) {
    let today = moment(new Date()).format("MM-DD-YYYY")
    today = new Date(today)
    let renew_date = data.renew_date.split('-')
    renew_date = new Date(renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2])
    return (today > renew_date)
  }
  function createMenuItemCond(menuItem, i) {
    if (companyStatus == 'accepted' &&  subscribedData[selected_org_code]  && subscribedData[selected_org_code].paymentstatus == 'success' && userStatus !== undefined) {
      

      //&&  !(menuItem.name.toLowerCase() == 'employee management' && !(isEmpty(subscribedData) || isEmpty(subscribedData[selected_org_code])) ) ){
      // if((cpyOrgData?.user_details?.internship_view != 'Y' && menuItem.name.toLowerCase() == 'internships') || (menuItem.name.toLowerCase() == 'internships' && userStatus != 'accepted')){
      //   return  <></>
      // }
      // else 
      // if (cpyOrgData?.user_details?.company_view != 'Y' && menuItem.name.toLowerCase() == 'my company' && userStatus == 'accepted') {
      //   return <></>
      // }
      // else
       if ((cpyOrgData?.user_details?.user_management_view != 'Y' && menuItem.name.toLowerCase() == 'user management') || (menuItem.name.toLowerCase() == 'user management' && userStatus != 'accepted')) {
        return <></>
      }
      else if ((cpyOrgData?.user_details?.view_job != 'Y' && menuItem.name.toLowerCase() == 'jobs') || (menuItem.name.toLowerCase() == 'jobs' && userStatus != 'accepted')) {
        return <></>
      }
      else if (userStatus != 'accepted' && (!["my company", "my profile", "subscriptions","company branding"].includes(menuItem.name.toLowerCase())))
      {
        return <></>
      }
      // else if(checkIsSubscriptionExpired(subscribedData[selected_org_code])){
      //   console.log("this Menu Disable Nacibar",checkIsSubscriptionExpired(subscribedData[selected_org_code]) );

      //   return creatDisalbeDropItemRenew(menuItem, i)
      // }
      else if(checkIsSubscriptionExpired(subscribedData[selected_org_code])){
        console.log("this Menu Disable Nacibar",checkIsSubscriptionExpired(subscribedData[selected_org_code]) );

        return <></>
      }
      else {
        return creatdropItem(menuItem, i)
      }
    } else if ('accepted' != companyStatus && undefined != companyStatus && ["my company", "my profile", "subscriptions","company branding"].includes(menuItem.name.toLowerCase())) {
      return <></>
    } else if (['accepted'].includes(companyStatus) && ["annual survey"].includes(menuItem.name.toLowerCase())) {
      console.log("this if working Sidebar","companyStatus",companyStatus,"subscribedData",subscribedData[selected_org_code]?.paymentstatus,"userStatus",userStatus)

      return creatdropItem(menuItem, i);
    }
    else {
      return <></>
    }
  }

const creatdropItem = (menu,index) =>{
  return <DropNavLink
  key={index}
  to={menu.path}

  >
    {/* <p>{menu.name}</p> */}
  {/* <Dropdown.Item
    className="dropdown-item"
    // onClick={onNavigate(menu.path)}
  > */}
  <DropItem>
  {menu.icon}
    <DropdownList>{t(menu.name)}</DropdownList>
  </DropItem>

  {/* </Dropdown.Item> */}

  </DropNavLink>
//    <Dropdown.Menu>
//   <Dropdown.Item
//     className="dropdown-item"
//     onClick={onNavigate(menu.path)}
//   >
//     <IoIosLogOut />
//     <DropdownList>{t(menu.name)}</DropdownList>
//   </Dropdown.Item>
// </Dropdown.Menu>
}
  return (
    <>
      {isOpenham && <Menus />}
      <HeaderWrapper id="nav1">
        <CardsWrapper>
          <div className="row">
            <div
              className="col-11"
              style={{ position: "relative", width: "98%" }}
            >
              
              {options.length > 0 ? (
                <SingleSection>
                  <Select

                    ref={selectRef}
                    formatOptionLabel={formatOptionLabel}
                    options={options}
                    components={compo}
                    styles={styles}
                    onChange={handleChange}
                    value={options.find(
                      (idx) => idx.value === selectValue
                    )}
                  
                  />
                </SingleSection>
              ) : null}
            </div>
          </div>
        </CardsWrapper>
        <NavHeader>
          <Navbar>
            {/* <Nav className="margin_right_63"></Nav> */}
            <Nav.Link>
              <Dropdown className="dropdown__toggle">
                <Dropdown.Toggle variant="">
                  <FlagLogo>
                    <img
                      src={i18n.language === "my" ? malayFlag : englishFlag}
                      alt="profile-logo"
                    />
                  </FlagLogo>
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item
                    href="#"
                    onClick={() => changeLanguageHandler("en")}
                  >
                    <img
                      width={32}
                      height={32}
                      src={englishFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"English"}</DropdownList>
                  </Dropdown.Item>
                  <Dropdown.Item
                    href="#"
                    onClick={() => changeLanguageHandler("my")}
                  >
                    <img
                      width={30}
                      height={30}
                      src={malayFlag}
                      alt="profile-logo"
                    />
                    <DropdownList>{"Malay"}</DropdownList>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Nav.Link>
            <Nav
              className=""
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "10px",
              }}
            >
              {process.env.NODE_ENV !== "production" && (
                <NavLink to="#" className="d-none">
                  {t("Version")}: {CONSTANTS.version}
                </NavLink>
              )}

              <Nav.Link href="https://www.mynext.my/faqs" target="_blank">
                <Help />
              </Nav.Link>

              <NavLink to="#">
                <Dropdown className="dropdown__toggle">
                  <Dropdown.Toggle variant="">
                    <ProfileLogo style={{ marginLeft: "-10px" }}>
                      <img
                        src={
                          selectedCompany && selectedCompany.logo_path
                            ? selectedCompany.logo_path
                            : ProfileIcon
                        }
                        alt="profile-logo"
                        style={{ borderRadius: "40px" }}
                      />
                    </ProfileLogo>
                  </Dropdown.Toggle>
                  
                  <Dropdown.Menu>
                  {
                        menuDropDown.map((menu,index)=>{
return createMenuItemCond(menu,index);
                        })
                      }
                    <Dropdown.Item
                      className="dropdown-item"
                      onClick={onUserLogoutConfirmation}
                    >
                      <IoIosLogOut />
                      <DropdownList>{t("Logout")}</DropdownList>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </NavLink>
            </Nav>
            
          </Navbar>
        </NavHeader>
      </HeaderWrapper>

      <div className="row align-items-center" id="nav2">
        <div className="col-7">
          <CardsWrapper1>
            <div className="row">
              <div
                className="col-11"
                style={{ position: "relative", width: "98%" }}
              >
                
                {options.length > 0 ? (
                  <SingleSection>
                    <Select

                      ref={selectRef}
                      formatOptionLabel={formatOptionLabel}
                      options={options}
                      components={compo}
                      styles={styles}
                      onChange={handleChange}
                      defaultValue={options.find(
                        (idx) => idx.value === selectValue
                      )}
                    />
                  </SingleSection>
                ) : null}
              </div>
            </div>
          </CardsWrapper1>
        </div>
        <div className="col-3 menuClass">
          <NavHeader1>
            <Navbar>
              {/* <Nav className="margin_right_63"></Nav> */}
              <Nav.Link>
                <Dropdown
                  className="dropdown__toggle"
                  style={{ marginLeft: "15px" }}
                >
                  <Dropdown.Toggle variant="">
                    <FlagLogo>
                      <img
                        src={i18n.language === "my" ? malayFlag : englishFlag}
                        alt="profile-logo"
                      />
                    </FlagLogo>
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeLanguageHandler("en")}
                    >
                      <img
                        width={32}
                        height={32}
                        src={englishFlag}
                        alt="profile-logo"
                      />
                      <DropdownList>{"English"}</DropdownList>
                    </Dropdown.Item>
                    <Dropdown.Item
                      href="#"
                      onClick={() => changeLanguageHandler("my")}
                    >
                      <img
                        width={30}
                        height={30}
                        src={malayFlag}
                        alt="profile-logo"
                      />
                      <DropdownList>{"Malay"}</DropdownList>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Nav.Link>
              <Nav
                className=""
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "5px",
                  marginLeft: "-10px",
                }}
              >
                {process.env.NODE_ENV !== "production" && (
                  <NavLink to="#" className="d-none">
                    {t("Version")}: {CONSTANTS.version}
                  </NavLink>
                )}

                <Nav.Link href="https://www.mynext.my/faqs" target="_blank">
                  <Help />
                </Nav.Link>

                <NavLink to="#">
                  <Dropdown className="dropdown__toggle">
                    <Dropdown.Toggle variant="">
                      <ProfileLogo style={{ marginLeft: "-18px" }}>
                        <img
                          src={
                            org && org.profile_picture
                              ? org.profile_picture
                              : ProfileIcon
                          }
                          alt="profile-logo"
                        />
                      </ProfileLogo>
                    </Dropdown.Toggle>
                  
                    
                        <Dropdown.Menu>
                        {
                        menuDropDown.map((menu,index)=>{
return createMenuItemCond(menu,index);
                        })
                      }
                      <Dropdown.Item
                        className="dropdown-item"
                        onClick={onUserLogoutConfirmation}
                      >
                        <IoIosLogOut />
                        <DropdownList>{t("Logout")}</DropdownList>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </NavLink>
              </Nav>
              
            </Navbar>
          </NavHeader1>
        </div>
      </div>

      <ConfirmationDialog
        id={0}
        show={logoutConfirmation}
        message={t("Are you sure want to logout?")}
        onAccept={() => onLogout()}
        closeModal={() => setLogoutConfirmation(false)}
      />
    </>
  );
}
