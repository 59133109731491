import { Row, Col } from "react-bootstrap";
import ViewIcon from "../../assets/icons/svg/view.svg";
import JobIcon from "../../assets/icons/svg/businessman.svg";
//import SalaryIcon from "../../assets/icons/salary.png";
import SalaryIcon from "../../assets/icons/svg/salary.svg";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { dateToDateMonthInWordsYear } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

const FlexDiv = styled.div`
  display: flex;
`;

// const CardsWrapper = styled(FlexDiv)`
//   background: ${Colors.light};
//   border-radius: 10px;
//   margin-bottom: 15px;
//   padding: 15px 20px;
//   &:last-child() {
//     margin-bottom: 0px;
//   }
// `;
const ContentSection = styled.div`
  margin-left: 10px;
  width: calc(100% - 26px);
`;
// const IconSection = styled.div`
//   padding: 7px;
// `;
// const Icon = styled.div`
//   width: 12px;
//   height: 12px;
//   border-radius: 50%;
//   background: ${(props) => (props.bg ? props.bg : "#33ff00")};
// `;

const JobTitle = styled.h3`
  display: inline;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: ${Colors.dark};
  margin: 0;
`;

const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const JobPostedDate = styled(DateText)`
  color: ${Colors.primaryText};
`;
const JobExpiredDate = styled(DateText)`
  color: #504f8c;
`;
const DateWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
// const FlexWrapper = styled(FlexDiv)`
//   align-items: center;
//   @media (max-width: 500px) {
//     flex-direction: column;
//   }
// `;
const OtherTextWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const JobDescriptionSection = styled(FlexDiv)`
  align-items: center;
`;
const IconSingleSection = styled(FlexDiv)`
  align-items: center;
  margin-left: ${(props) => (props.margin ? props.margin : "0px")};
  margin-bottom:5px;
`;
const OtherText = styled.p`
  margin: 0;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;

const JobsWrapper = styled.div`
  margin-bottom: 0.75em;
`;

const VacancyText = styled.span`
  display: inline;
  color: #b3b3b3;
  margin-left: 1em;
`;
// const Icons = styled.img`
//   margin: 0px 10px;
//   cursor: pointer;
// `;
// const Message = styled.div`
//   // padding-left: 20px;
//   font-size: 22px;
//   padding-bottom: 5px;
// `;
// const ApplicationText = styled(SmallText)`
//   color: ${Colors.primaryText};
//   margin-left: ${(props) => (props.margin ? props.margin : "")};
// `;

export default function ApplicatantsHeader(props) {
  const {
    title,
    no_positions,
    intern_type,
    min_compensation,
    max_compensation,
    position_start_date,
    position_end_date,
    viewed_count,
    payment_status,
  } = props?.data;
  const { t, } = useTranslation();

  return (
    <ContentSection>
      <JobsWrapper>
        <JobTitle>{title}</JobTitle>
        <VacancyText>
          <img src={ViewIcon} alt="view" />{" "}
          {viewed_count ? viewed_count + t(" views") : t("No views")}
        </VacancyText>
      </JobsWrapper>
      <DateWrapper>
        <JobPostedDate>
          {t('Posted')}: {dateToDateMonthInWordsYear(position_start_date)}
        </JobPostedDate>
        <JobExpiredDate>
          {t('Expires')}: {dateToDateMonthInWordsYear(position_end_date)}
        </JobExpiredDate>
      </DateWrapper>
      <OtherTextWrapper>
        <OtherText>{t('Vacancies')}: {no_positions}</OtherText>
        <JobDescriptionSection className="col-lg-9">
          <Row>
            <Col>
              <IconSingleSection margin="40px">
                <img src={JobIcon} alt="job" />
                <OtherText>
                  {intern_type === "sip" ? "MySIP" : t(intern_type)}
                </OtherText>
              </IconSingleSection>
            </Col>
            &nbsp;
            <Col xs="12" md="6">
              <IconSingleSection margin="40px">
                <img src={SalaryIcon} alt="job" />

                <OtherText>
                  {payment_status === "unpaid"
                    ? "0"
                    : min_compensation + " MYR - " + max_compensation}{" MYR"}
                </OtherText>
              </IconSingleSection>
            </Col>
          </Row>
        </JobDescriptionSection>
      </OtherTextWrapper>
    </ContentSection>
  );
}
