import styled from "styled-components";

import CustomModal from "../modal/modal";
import SuccessLogo from "../../assets/rocket.png";
import { Colors } from "../../utilities/colors";
import Button from "../button/button";
import { useTranslation } from "react-i18next";


const Logo = styled.img`
  object-fit:contain;
  width:60%;
`;
const LogoWrapper = styled.div`
  min-height:304px;
  max-height:304px;
`;
const Wrapper = styled.div`
  text-align: center;
`;
const Header = styled.h3`
  font-weight: 600;
  font-size: 30px;
  line-height: 41px;
  color: ${Colors.primary};
  margin-top: 0;
  margin-bottom:15px;

`;
const Description = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  > span {
    color: #504f8c;
  }
`;
// const LinkText = styled.p`
//   font-weight: 500;
//   font-size: 18px;
//   line-height: 24px;
//   text-decoration: underline;
//   color:#504F8C;
//   margin:0px;
//   cursor:pointer;
// `;
export default function DashboardModal({ open }) {
  // const onLogout = async () => {
  //   const { data, status } = await authLogout();
  //   if (status === 200) {
  //     if (data.status === 1) {
  //       localStorage.removeItem("auth_token");
  //       window.location.assign("/");
  //     }
  //   }
  // };
  const {t} = useTranslation();
  return (
    <CustomModal open={open}>
      <Wrapper>
        <LogoWrapper>
        <Logo src={SuccessLogo} alt="success logo" />        
        </LogoWrapper>  
        <Header>{t('You`re all set to go!')}</Header>
        <Description>
          <span>{t('Work in progress...')}</span>
          <span>{t('We are working to get this site up for you!')}</span>
        </Description>
        <Button name={t('Logout')} onClick={"onLogout"}/> 
      </Wrapper>
    </CustomModal>
  );
}
