import { proxy } from 'valtio'
import { getQuota, companySubscription, getCompanySubscription } from '../../services/apiCalls'
import { SubscriptionData } from '../../assets/data/subscriptionData';
let subsctipationsProxy = proxy({
    plan:"",
    setcount:async (org_code)=>{
        const { data } = await getCompanySubscription(org_code);
        if (data && data.status === 1 && data.data.length>0) {
            if(data.data[0]['paymentstatus']=="success"){
                let d = data.data[0]
                if(d.job_quota == null || d.job_duration_day == null || d.internship_quota == null || d.internship_duration_month == null || d.employe_equota == null){
                    let plan = {}
                    if(SubscriptionData[d.plan.toLowerCase()]){
                        plan = SubscriptionData[d.plan.toLowerCase()]
                        subsctipationsProxy.plan = subsctipationsProxy.plan = d.plan.toLowerCase()
                    }else{
                        plan = SubscriptionData['basic']
                        subsctipationsProxy.plan = 'basic'
                    }
                    d.job_quota = plan.job_quota
                    d.job_duration_day = plan.job_duration_day
                    d.internship_quota = plan.internship_quota
                    d.internship_duration_month = plan.internship_duration_month
                    d.employe_equota = plan.employe_equota
                    d.graduate_talent_search = parseInt(SubscriptionData.talent_acquisition.features[0][d.plan.toLowerCase()]);
                    await companySubscription(org_code,d)
                    subsctipationsProxy.job_quota = plan.job_quota
                    subsctipationsProxy.job_duration_day = plan.job_duration_day
                    subsctipationsProxy.internship_quota = plan.internship_quota
                    subsctipationsProxy.internship_duration_month = plan.internship_duration_month
                    subsctipationsProxy.employe_equota = plan.employe_equota
    
                }else{
                    subsctipationsProxy.job_quota = +d.job_quota
                    subsctipationsProxy.job_duration_day = +d.job_duration_day
                    subsctipationsProxy.internship_quota = +d.internship_quota
                    subsctipationsProxy.internship_duration_month = +d.internship_duration_month
                    subsctipationsProxy.employe_equota = +d.employe_equota
                    subsctipationsProxy.plan = d.plan.toLowerCase()
                   
                }
            }else{
                subsctipationsProxy.setempty()
            }
        }else{
            subsctipationsProxy.setempty()
        }
        subsctipationsProxy.setusedcount(org_code)
    },
    setusedcount:async (org_code)=>{
        const { data } = await getQuota(org_code);
        if (data && data.status === 1) {
            subsctipationsProxy.jobcount=data.job
            subsctipationsProxy.empcount=data.emp
        }else{
            subsctipationsProxy.jobcount=0
            subsctipationsProxy.empcount=0
        }
    },
    setempty:()=>{
        subsctipationsProxy.job_quota=0
        subsctipationsProxy.job_duration_day=0
        subsctipationsProxy.internship_quota=0
        subsctipationsProxy.internship_duration_month=0
        subsctipationsProxy.employe_equota=0
    },
    job_quota:0,
    job_duration_day:0,
    internship_quota:0,
    internship_duration_month:0,
    employe_equota:0,
    jobcount:0,
    empcount:0
})


export {
    subsctipationsProxy
}
