import React from 'react';
import {
    Chart as ChartJS,
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
} from 'chart.js';
import { Chart } from 'react-chartjs-2';
import Male from "../../../assets/svg/male.svg";
import Female from "../../../assets/svg/female.svg";
import LineLegend from "../../../assets/svg/lineLegend.svg";
import { cloneDeep } from 'lodash';
// import { Utils } from '../../utilities/utils';

import zoomPlugin from 'chartjs-plugin-zoom';
import { Utils } from '../utilities';

ChartJS.register(
    LinearScale,
    CategoryScale,
    BarElement,
    PointElement,
    LineElement,
    Legend,
    Tooltip,
    LineController,
    BarController,
    zoomPlugin,
);

const defaultOptions = {
    maxBarThickness: 20,
    layout: {
        padding: {
            left: 0,
            right: 0,
            top: 20,
            bottom: 20,
        },
        margin: {
            left: 0,
            right: 0,
            top: 30,
            bottom: 0,
        }
    },
    elements: {
        point: {
            radius: 8.5,
            backgroundColor: "#ffffff",
            borderWidth: 2,
            borderColor: '#004B84', hoverRadius: 12,
            hoverBorderWidth: 3,
        }
    },
    plugins: {
        legend: {
            display: false,
            position: 'bottom',
            align: 'center',
        },
        datalabels: {
            display: false,
        },
        tooltip: {
            enabled: true,
            yAlign: 'bottom',
            xAlign: 'center',
            displayColors: false,
            padding: 10,
            callbacks: {
                label: (context) => {
                    return context.formattedValue;
                },
                title: (context) => {
                    return '';
                }
            }
        },
        zoom: {
            pan: {
                enabled: true,
                modifierKey: 'ctrl',
            },
            limits: {
                y: { min: 0 },
            },
            zoom: {
                wheel: {
                    enabled: true,
                    modifierKey: 'ctrl',
                },
            }
        }
    },
    scales: {
        y: {
            beginsAtZero: true,
            display: true,
            grid: {
                display: true,
                drawBorder: false,
            },
            ticks: {
                font: {
                    family: 'Inter',
                    size: '16px',
                    style: 'normal',
                    weight: 400,
                    lineHeight: '18px',
                },
                color: '#615E83',
            },
        },
        x: {
            display: true,
            beginsAtZero: true,
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                font: {
                    family: 'Inter',
                    size: '16px',
                    style: 'normal',
                    weight: 400,
                    lineHeight: '18px',
                },
            },
            color: '#615E83',
        },
    },
    responsive: true,
    title: {
        display: false,
    },
};
const female = new Image();
const male = new Image();
const lineLegend = new Image();
female.src = Female;
male.src = Male;
lineLegend.src = LineLegend;

export default function GenderBarLineChart({ data, gradients = [], optionsOverride }) {
    const legendRef = React.useRef(null);
    let finalOptions = defaultOptions;
    const chartRef = React.useRef(null);
    data.datasets[0].dataBackup = cloneDeep(data.datasets[0].data);
    const data2 = cloneDeep(data);
    const [chartData, setChartData] = React.useState(data2);
    const htmlLegendPlugin1 = {
        id: 'htmlLegend',
    };
    let plugins = [htmlLegendPlugin1];

    React.useEffect(() => {
        const chart = chartRef.current;
        const ctx = chart && chart.ctx;

        if (ctx && gradients.length) {
            setChartData({
                ...data,
                datasets: data.datasets.map((dataset, index) => {
                    /*** Gradient ***/
                    const linearGradients = gradients.map((gradient) => {
                        const [gradientStart, gradientEnd] = gradient;
                        const linearGradient = ctx.createLinearGradient(chart.width / 2, 0, chart.width / 2, chart.height);
                        linearGradient.addColorStop(0, gradientStart);
                        linearGradient.addColorStop(1, gradientEnd);
                        return linearGradient;
                    })
                    return ({
                        ...dataset,
                        backgroundColor: linearGradients,
                        hoverBackgroundColor: linearGradients,
                    })
                }),
            });
        }
        htmlLegendPlugin1.afterUpdate = (function (chart, args, options) {
            const ul = Utils.getOrCreateLegendList(chart, this.legendRef, 'space-evenly');

            
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            
            const items = chart.options.plugins.legend.labels.generateLabels(chart);
            const liGroup = document.createElement('div');
            liGroup.style.alignItems = 'center';
            liGroup.style.cursor = 'pointer';
            liGroup.style.display = 'flex';
            liGroup.style.flexDirection = 'row';
            liGroup.style.marginLeft = '10px';
            items.forEach((item, index) => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(item.datasetIndex, !chart.isDatasetVisible(item.datasetIndex));
                    }
                    chart.update();
                };
                const boxSpan = index === 0 ? lineLegend : index === 1 ? male : female;
                
                const textContainer = document.createElement('p');

                textContainer.style.fontFamily = 'Inter';
                textContainer.style.fontStyle = 'normal';
                textContainer.style.margin = '0px';
                textContainer.style.color = '#615E83';
                textContainer.style.fontWeight = '400';
                textContainer.style.lineHeight = '18px';
                textContainer.style.fontSize = '16px';
                textContainer.style.marginLeft = '3px';
                textContainer.style.textDecoration = item.hidden ? 'line-through' : '';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);
                if (index === 0) {
                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    ul.appendChild(li);
                } else if (index === 2) {
                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    liGroup.appendChild(li);
                    ul.appendChild(liGroup);
                } else if (index === 1) {
                    li.style.marginRight = '10px';
                    li.appendChild(boxSpan);
                    li.appendChild(textContainer);
                    liGroup.appendChild(li);
                }
            });
        }).bind({
            legendRef: legendRef.current,
        });
        chartRef.current.update();
    }, []);
    if (optionsOverride) {
        finalOptions = { ...defaultOptions, ...finalOptions };
    }
    
    return <>
        <Chart data={chartData}
            ref={chartRef}
            options={finalOptions} plugins={plugins} />
        <div id="legend-container" ref={legendRef}></div>
    </>
}