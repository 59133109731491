import React from "react";

import "./list.css";
import { t } from "i18next";

const ListZeroViewsComponent = (props) => {
  const options = [
    {
      text: "<strong>Postage date</strong> – It may take some time for talents to reach the postings. Over time, as we continuously recruit new talents onto the platform, your posting should gain more views.",
      id: 1,
    },
    {
      text: "<strong>Off-season for Internship</strong> – According to most public universities’ academic calendars, internship periods usually begin in the month of October. As such, the off-peak internship season may be a reason for the low reachability.",
      id: 2
    },
    {
      text: "<strong>Title of Internship</strong> – Since interns usually will search the title of internship that relates most to their scope of study, therefore if your title did not relate to the scope of study they will not get as much view and tractions.",
      id: 3
    },
  ];

  const listMarkup = options.map((option) => (
    <li key={option.id} className="list-item" dangerouslySetInnerHTML={{ __html: t(option.text) }} />
  ));

  return <div className="list-container"><ul className="no-bullets">{listMarkup}</ul></div>;
};

export default ListZeroViewsComponent;