import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
// import { useDispatch } from "react-redux";

import CloseIcon from "../../assets/close-80.png";

export default function CustomModal(props) {
  // const open = useSelector((state) => state.modal.open);
  // const dispatch = useDispatch();

  // const onCloseModal = () => {
  //   dispatch(updateModal(false));
  // };

  const closeIcon = (
    <div>
      <img
        style={{ marginLeft: "80%" }}
        src={CloseIcon}
        height="3%"
        width="3%"
        alt="close icon modal"
      />
    </div>
  );
  return (
    <Modal
      open={props.open}
      showCloseIcon={props.showCloseIcon === false ? props.showCloseIcon : true}
      closeIcon={closeIcon}
      center
      closeOnOverlayClick={false}
      closeOnEsc={false}
      onClose={props.onCloseModal}
      styles={{width:"400px"}}
    >
      {props.children}
    </Modal>
  );
}
