// initial state for reducers

export const organisationData = {
  name: "",
  step: 1,
  ssm_number: "",
  ssm_rgtr_date: "",
  website: "",
  rgtr_address: "",
  country: "Malaysia",
  state: "",
  city: "",
  country_code: "",
  logo: "",
  logo_path: undefined,
  bsn_address: "",
  bsn_country: "Malaysia",
  bsn_city: "",
  bsn_state: "",
  bsn_country_code: "",
  type_of_org: "",
  sector: "",
  bsn_nature: "",
};

export const newPersonData = {
  email: "",
  job_title: "",
  first_name: "",
  last_name: "",
  password: "",
  confirm_password: "",
  department: "",
  mob_number: "",
};

// initial state for reducer ends

//
export const imgPath = "http://mynextapi.underdev.in";
//
