import React from "react";

import "./list.css";
import { t } from "i18next";

const ListLoginDetailsComponent = (props) => {
  const options = [
    {
      text: "Login to your company's admin account",
      id: 1,
    },
    {
      text: "Click on your profile photo in the top right hand corner and select ‘My Company’",
      id: 2
    },
    {
      text: "Click on ‘View’ and scroll down to the ‘User Management’ section.",
      id: 3
    },
    {
      text: "Click on ‘+ Add New User’ and fill in the new user's details.",
      id: 4
    },
    {
      text: "Select the new user's permissions for how they will use the mynext platform. Please note that selecting ‘Admin’ will allow them full access to all features on the platform. Then click ‘Submit’.",
      id: 5
    },
    {
      text: "The new user will receive an email with instructions for first-time login.",
      id: 6
    },
  ];

  const listMarkup = options.map((option) => (
    <li key={option.id} className="list-item" dangerouslySetInnerHTML={{ __html: t(option.text) }} />
  ));

  return <div className="list-container"><ul className="no-bullets">{listMarkup}</ul></div>;
};

export default ListLoginDetailsComponent;