import styled from "styled-components";

import Businessman from "../../assets/icons/svg/businessman.svg";
import Salary from "../../assets/icons/svg/salary.svg";
import Job from "../../assets/icons/svg/calender-time.svg";
import Vacancies from "../../assets/icons/svg/internships.svg";
import Clock from "../../assets/icons/svg/clock-watch.svg";
import Timer from "../../assets/icons/svg/expire-frame.svg";
import { dateToDateMonthInWordsYear } from "../../utilities/commonFunctions";
import { useTranslation } from "react-i18next";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;
const DetailsWrapper = styled.div`
  background: #f8f8f8;
  border-radius: 54px;
  padding: 12px 16px;
  display: flex;
  flex-direction: row;
  margin-right: 25px;
  margin-bottom: 18px;
  > h6 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    margin: 0px 6px;
    color: #525252;
  }
`;
const Image = styled.img`
  max-height: 30px;
  max-width: 25px;
`;
const DetailsComponent = ({ image, text, specialAlignment }) => {
  if (specialAlignment) {
    return (
      <DetailsWrapper style={{ display: "flex", alignSelf: "center" }}>
        <Image src={image} />
        <h6>{text}</h6>
      </DetailsWrapper>
    );
  } else {
    return (
      <DetailsWrapper>
        <Image src={image} />
        <h6>{text}</h6>
      </DetailsWrapper>
    );
  }
};
export default function InterShipCard({ data }) {
  const getInternTypeName = (intern_type) =>{

    switch(intern_type){
      
      case 'Advertise':
      case 'advertise':
      case 'Advertised':
      case 'sip':
        return 'MySIP Advertised';
  
      case 'Direct Hiring':
        return 'MySIP Direct Hiring';
      
        case 'general':
        case 'General Internship':
          return 'General Internship';
    }
    
  }
  const {t} = useTranslation();

  return (
    <>
      <Wrapper>
        <DetailsComponent
          image={Businessman}
          text={data && data.intern_type === "sip" ? t(getInternTypeName(data.intern_type)) : t("General Internship")}
        />
        {data && data.payment_status === "unpaid" ? (
          <DetailsComponent image={Salary} text={"0 MYR"} />
        ) : (
          <DetailsComponent
            image={Salary}
            text={`${
              data && data.min_compensation + " MYR - " + data.max_compensation
            } MYR`}
          />
        )}

        <DetailsComponent
          specialAlignment
          image={Vacancies}
          text={t("Positions") +`: ${data && data.no_positions}`}
        />
        <DetailsComponent
          image={Job}
          text={t("Joining Date") + `: ${
            data && dateToDateMonthInWordsYear(data.exp_start_date)
          }`}
        />
        <DetailsComponent
          image={Timer}
          text={t("End Date") +`: ${
            data && dateToDateMonthInWordsYear(data.exp_end_date)
          }`}
        />
        {/* </Wrapper> */}
        {/* <Wrapper> */}

        <DetailsComponent
          image={Clock}
          text={t("Working Hours") +`: ${data && data.working_hours}` + t("hrs/ day")}
        />
      </Wrapper>
    </>
  );
}