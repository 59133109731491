import React, { useState } from "react";
import { Col, Modal } from "react-bootstrap";
import Button from "../../button/button";
import { ModalHeader, ModalHeaderText, ModalHeaderTextThree, ModalHeaderTextTwo } from "../../../pages/employeeManagement/indexComponents";
import { t } from "i18next";


const FullProfileModal = (props) => {
//   useSnapshot(trackProxy);
//   const [job, setJob] = useState([]);
//   const [dis, setDis] = useState(true);
//   const [tags, setTags] = useState("");

//   const submit = async () => {
//     var formData = {};
//     // formData['job_id'] = job.value,
//     // formData['user_id'] = id;
//     const { data } = await addShortlistTalentSearch({
//       job_id: job?.value,
//       user_id: id,
//       shortlist_jobposition: job?.value,
//       shortlist_tag: tags

//     });
//     if (data) {
//       toast.success("Application Shortlisted", {
//         position: toast.POSITION.TOP_RIGHT,
//       });
//     }
//     close();
//   };

//   const close = () => {
//     // if (trackProxy.setcheck === 0) {
//     //   trackProxy.setcheck = 1;
//     // } else {
//     //   trackProxy.setcheck = 0;
//     // }
//     onClose();
//   };

  //
  return (
    <div>
    <Modal show={props.show} onHide={props.close} backdrop="static" centered>
      <div style={{ padding: '9px' }}>
        <ModalHeader></ModalHeader>
        <ModalHeaderText>{t(props.title)}</ModalHeaderText>
        <ModalHeaderTextTwo>{t(props.data1[props.modalMode]?.subDesc)}</ModalHeaderTextTwo>
        {/* {props.content} */}
        <ModalHeaderTextThree>{t(props.data1[props.modalMode]?.desc)}</ModalHeaderTextThree>

       { 

props.modalMode == "empty-coin" ?
<Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
               secondary
                width="100px"
                margin="40px 0px 30px 0px"
                name="Ok"
                type="accept"
                onClick={props.close}
              />
            </Col>
:
       
       <Col
              md="12"
              className="d-flex justify-content-center align-items-center"
            >
              <Button
                width="100px"
                margin="40px 0px 30px 0px"
                name={t("Proceed")}
                type="accept"
                onClick={props.deducteToken}
              />
              <Button
                secondary
                color="#FFFFFF"
                name={t("Cancel")}
                style={{
                  margin: "40px 5px 30px 10px",
                }}
                variant="outlined"
                onClick={props.close}
              ></Button>
            </Col>}
      </div>
    </Modal>
  </div>
  );
};

export default FullProfileModal;
