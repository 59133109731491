import { Modal } from "react-bootstrap";
import './modalRegister.css';

export default function CustomModal(props) {
  return (
    <Modal 
      {...props}
      size="sm"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      show={props.open}
      onHide={props.onCloseModal}
      backdrop="static"
      keyboard={false}
      dialogClassName="my-modal"
    >
      <Modal.Body style={{ margin: "1em" }}>{props.children}</Modal.Body>
    </Modal>
  );
}
