import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Form from 'react-bootstrap/Form';
import RadioInput from "../../components/radioInput/radioInput";
import { Controller } from "react-hook-form";
import Input from "../../components/input/input";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import { Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { internshipModule } from "../../pages/MySIPDirectHiring/staticContentInternship";
import ErrorField from "../errorField/errorField";

const Description2 = styled.p`
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 20px
}
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const SmallText = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`;
export const UploadBtn = styled.button`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 30px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;

const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding-top: 14px;
  color: ${Colors.labelText};
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;
const CheckboxGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
`;

// const CheckboxInput = styled.input`
//   margin-right: 10px; 
//   margin-left: 10px;
//   width: 
 
// `;

const Uploader = styled.div`
  display: flex;
  flex-direction: row;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  >label{
    margin-left:30px;
    margin-top: 25px;
  }
  `;
export default function QuestionSection2 ({onSubmit, control, errorsQuestion}) {
    const [selectedCheckboxesStudent, setSelectedCheckboxesStudent] = useState([]);
    const [selectedCheckboxesCompany, setSelectedCheckboxesCompany] = useState([]);
    const [selectedCheckboxesOutcome, setSelectedCheckboxesOutcome] = useState([]);
    const [selectedCheckboxesIntern, setSelectedCheckboxesIntern] = useState([]);
    const [internalTraining, setInternalTraining] = useState('Yes');
    const [acceptStudent, setAcceptStudent] = useState('Yes');
    const [formDataForUpload, setFormDataForUpload] = useState(undefined);
    const [logoLocalPath, setLogoLocalPath] = useState(undefined);
    
    useEffect(() => {
      onSubmit({
        selectedCheckboxesOutcome,
        selectedCheckboxesIntern,
        internalTraining,
        acceptStudent,
      });
    }, []);
    const [error, setError] = useState("");
    const handleCheckboxChangeStudent = (student) => {
      if (selectedCheckboxesStudent.includes(student)) {
        
        setSelectedCheckboxesStudent((prevSelected) =>
          prevSelected.filter((item) => item !== student)
        );
      } else {
        
        if (selectedCheckboxesStudent.length < 3) {
          setSelectedCheckboxesStudent((prevSelected) => [...prevSelected, student]);
        } else{
          setError("Maximum work factors reached.");
        }
      }
        onSubmit({ student: [...selectedCheckboxesStudent, student] });
      };

    const handleCheckBoxChangeComp = (company) => {
      if (selectedCheckboxesCompany.includes(company)) {
        setSelectedCheckboxesCompany((prevSelected) =>
          prevSelected.filter((item) => item !== company)
        );
      } else {
        if (selectedCheckboxesCompany.length < 3) {
          setSelectedCheckboxesCompany((prevSelected) => [...prevSelected, company]);
        }
      }
        onSubmit({company: [...selectedCheckboxesCompany, company]});
      };

      const handleCheckBoxChangeOutcome = (outcome) => {
        if (selectedCheckboxesOutcome.includes(outcome)) {
          setSelectedCheckboxesOutcome((prevSelected) =>
            prevSelected.filter((item) => item !== outcome)
          );
        } else {
          if (selectedCheckboxesOutcome.length < 3) {
            setSelectedCheckboxesOutcome((prevSelected) => [...prevSelected, outcome]);
          }
        }
        onSubmit({selectedCheckboxesOutcome: [...selectedCheckboxesOutcome, outcome]});
      };

      const handleCheckboxChangeIntern = (intern) =>{
        if (selectedCheckboxesIntern.includes(intern)) {
          setSelectedCheckboxesIntern((prevSelected) =>
            prevSelected.filter((item) => item !== intern)
          );
        } else {
          if (selectedCheckboxesIntern.length < 3) {
            setSelectedCheckboxesIntern((prevSelected) => [...prevSelected, intern]);
          }
        }
        onSubmit({selectedCheckboxesIntern: [...selectedCheckboxesIntern, intern]});
      }

      const handleInternalTrainingChange = (event) =>{
        const value = event.target.value;
        setInternalTraining(value);

        onSubmit({internalTraining: value});
      }

      const handleAcceptStudentChange = (event) =>{
        const value = event.target.value;
        setAcceptStudent(value);

        onSubmit({acceptStudent: value});
      }
    
    // const uploadFile = async (event) => {
    //     const formdata = new FormData();
    //     const filesFormats = ["application/pdf"];
    //     let maxFileSize = 1024 * 1024 * 5; // 5MB
      
    //     const isRightFormat = filesFormats.includes(event?.target?.files[0]?.type);
    //     if (!isRightFormat) {
    //       toast.error("Invalid File Format.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     } else if (event?.target?.files[0]?.size > maxFileSize) {
    //       toast.error("File size exceeds maximum limit.", {
    //         position: toast.POSITION.TOP_RIGHT,
    //       });
    //     } else {
    //       formdata.append("pdf_file", event?.target?.files[0]);
    //       setFormDataForUpload(formdata);
    //       let reader = new FileReader();
    //       reader.readAsDataURL(event.target.files[0]);
    //       reader.onload = () => {
    //         setLogoLocalPath(reader.result);
    //       };
    //     }
    //   };
    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
      const file = event.target.files[0];
      setSelectedFile(file);
    };
      
    return(
        <div>
            {/* <Description2>Internship Programme</Description2>
            <BreakSection>
            <hr/>
            </BreakSection> */}
            <GroupWrapper>
                <FormLabel>{internshipModule.question1}</FormLabel>
                {internshipModule.question1List.map((item) => (
                    <CheckboxGroup key={item}>
                    <Row>
                        <Col xs={"auto"} style={{marginTop: "8px"}}>
                        <CheckboxInput 
                        onChange={() => handleCheckboxChangeStudent(item)}
                        checked={selectedCheckboxesStudent.includes(item)}
                        // disabled={selectedCheckboxesStudent.length >= 3 && !selectedCheckboxesStudent.includes(item)}
                        />
                        </Col>
                        <Col>
                        <TableHead>{item}</TableHead>
                        </Col>
                        {item === 'Others (please specify)' ? (
                            <div style={{marginTop: "10px"}}>
                            <Controller
                            name="student_objective_others"
                            control={control}
                            render={({field}) =>(
                              <Input
                              {...field}
                            placeholder={"Others"}/>
                            )}
                            />
                        </div>
                            ): ""}
                    </Row>
                    </CheckboxGroup>
  ))}{error && <span style={{ color: "red" }}>{error}</span>}
            </GroupWrapper>
            <BreakSection >
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>

            <GroupWrapper>
                <FormLabel>{internshipModule.question2}</FormLabel>
                {internshipModule.question2List.map((comp)=>(
                    <CheckboxGroup key={comp}>
                    <Row>
                        <Col xs={"auto"} style={{marginTop: "8px"}}>
                        <CheckboxInput 
                        onChange={() => handleCheckBoxChangeComp(comp)}
                        checked={selectedCheckboxesCompany.includes(comp)}    
                        disabled={selectedCheckboxesCompany.length >= 3 && !selectedCheckboxesCompany.includes(comp)}
                        />
                        </Col>
                        <Col>
                        <TableHead>{comp}</TableHead>
                        </Col>
                        {comp === 'Others (please specify)' ? (
                            <div style={{marginTop: "10px"}}>
                            <Input
                            placeholder={"Others"}/>
                        </div>
                            ): ""}
                    </Row>
                    </CheckboxGroup>
                ))}
            </GroupWrapper>
            <BreakSection>
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>

            <GroupWrapper>
                <FormLabel>{internshipModule.question3}</FormLabel>
                {internshipModule.question3List.map((intern)=>(
                    <CheckboxGroup key={intern}>
                    <Row>
                        <Col xs={"auto"} style={{marginTop: "8px"}}>
                        <CheckboxInput 
                        onChange={() => handleCheckboxChangeIntern(intern)}
                        checked={selectedCheckboxesIntern.includes(intern)}
                        disabled={selectedCheckboxesIntern.length >= 3 && !selectedCheckboxesIntern.includes(intern)}
                        />
                        </Col>
                        <Col>
                        <TableHead>{intern}</TableHead>
                        </Col>
                        {intern === 'Others (please specify)' ? (
                            <div style={{marginTop: "10px"}}>
                            <Input
                            placeholder={"Others"}/>
                        </div>
                            ): ""}
                    </Row>
                    </CheckboxGroup>
                ))}
            </GroupWrapper>
            <BreakSection>
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>

            <GroupWrapper>
                <FormLabel>{internshipModule.question4}</FormLabel>
                {internshipModule.question4List.map((outcome)=>(
                    <CheckboxGroup key={outcome}>
                    <Row>
                        <Col xs={"auto"} style={{marginTop: "8px"}}>
                        <CheckboxInput 
                        onChange={() => handleCheckBoxChangeOutcome(outcome)}
                        checked={selectedCheckboxesOutcome.includes(outcome)}
                        disabled={selectedCheckboxesOutcome.length >= 3 && !selectedCheckboxesOutcome.includes(outcome)}
                        />
                        </Col>
                        <Col>
                        <TableHead>{outcome}</TableHead>
                        </Col>
                        {outcome === 'Others (please specify)' ? (
                            <div style={{marginTop: "10px", marginBottom: "50px"}}>
                            <Input
                            placeholder={"Others"}/>
                        </div>
                            ): ""}
                    </Row>
                    </CheckboxGroup>
                ))}
            </GroupWrapper>
            
            <GroupWrapper>
                <FormLabel>{internshipModule.uploadFile}</FormLabel>
                <Uploader>
                <UploadBtn secondary style={{width: "200px"}}>Choose File</UploadBtn>
                {/* <input type="file" accept=".pdf" onChange={handleFileChange} /> */}
                <SmallText>{selectedFile ? selectedFile.name : 'No file chosen'}</SmallText>
                
                </Uploader>
            </GroupWrapper>

            <GroupWrapper>
                <FormLabel>{internshipModule.question5}</FormLabel>
                <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="Yes"
                          checked={internalTraining === 'Yes'}
                          onChange={
                            handleInternalTrainingChange
                            // setMinDuration("Yes")
                          }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="No"
                          checked={internalTraining === 'No'}
                          onChange={
                            handleInternalTrainingChange
                           }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
            </GroupWrapper>

            <GroupWrapper>
                <FormLabel>{internshipModule.question6}</FormLabel>
                <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="Yes"
                          checked={acceptStudent === 'Yes'}
                          onChange={
                            handleAcceptStudentChange
                            // setMinDuration("Yes")
                          }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          value="No"
                          checked={acceptStudent === 'No'}
                          onChange={
                            handleAcceptStudentChange
                           }/>
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
            </GroupWrapper>
            
        </div>
    )
}