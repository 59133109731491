import { useState, useEffect } from "react";
import styled from "styled-components";
import IntroSection from "../../components/introSectionCreatePassword/introSection";
import Divider from "../../components/divider/divider";
import Button from "../../components/button/button";
import { Colors } from "../../utilities/colors";
import Company from "../../assets/company.png";

import { useNavigate, useLocation } from "react-router-dom";

import { IoIosArrowBack } from "react-icons/io";
import { isEmpty } from "../../utilities/commonFunctions";
import {
    DropdownTitle,
    SelectorWrapper,
} from "../../components/analyticalDashboardPage/commonStyles";
import { Controller, useForm } from "react-hook-form";
import FormErrors from "../../components/formErrors/formErrors";
import { Col, Row } from "react-bootstrap";
import Input from "../../components/input/input";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import Loader from "../../components/loader/loader";
import { useDispatch, useSelector } from "react-redux";
import { forceChangePassword } from "../../actions/auth";
import { toast } from "react-toastify";
import UserCreationLayout from "../../layouts/userCreation/userCreationLayout";

// styling starts
const HeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  position:relative;
  bottom:30px;
  @media (max-width: 650px) {
    display: block;
    position:relative;
    bottom:50px;
  }
`;
const LabelText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
`;
const SignUpBtn = styled(LabelText)`
  color: ${Colors.primary};
  text-decoration: underline;
  margin-left: 10px;
  cursor: pointer;
`;

const FormWrapper = styled.div`
  margin: 10px 0px;
  max-width: 440px;

  @media (max-width: 1024px) {
    max-width: 100%;
  }
`;
// styling ends

export default function CreatePasswordPage() {
    const formSchema = Yup.object().shape({
        email: Yup.string().email("Invalid email").required("Email is required"),
        password: Yup.string()
            .required("Password is required")
            .matches(
                /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
            ),
        repeatPassword: Yup.string()
            .required("Repeat password is required")
            .oneOf([Yup.ref("password")], "Passwords do not match."),
    });

    const {
        register,
        formState: { errors },
        control,
        handleSubmit,
        formState: { touchedFields },
        reset,
    } = useForm({
        criteriaMode: "all",
        resolver: yupResolver(formSchema),
    });
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [uid, setUid] = useState(null);
    const [token, setToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const message = useSelector((state) => state.message.message);
    const onNavigate = (path) => {
        navigate(path);
    };
    const location = useLocation();
    useEffect(() => {
        const email = location && location.state && location.state.email;
        const uid = location && location.state && location.state.uid;
        const token = location && location.state && location.state.token;
        if (email && uid && token) {
            setUid(uid);
            setToken(token);
            reset({
                email: email,
            });
        } else {
            onNavigate("/");
        }
    }, [location]);

    const onSubmit = (data) => {
        if (!isEmpty(errors)) {
            return;
        }
        setLoading(true);
        dispatch(
            forceChangePassword({
                uid: uid,
                token: token,
                newPassword: data.password,
                confirmNewPassword: data.repeatPassword,
                email: data.email
            })
        ).unwrap()
            .then(() => {
                // toast.success("Password changed successfully. Please login again.");
                toast.success("Password changed successfully. Please login again.");

                reset({
                    email: "",
                    password: "",
                    repeatPassword: "",
                });
                navigate("/login", { replace: true });
            })
            .catch((error) => {
                toast.error(message);
                setLoading(false);
            });
    };

    return (
        <>
            <UserCreationLayout coverImg={Company}>
                <HeaderWrapper onClick={() => onNavigate("/")}>
                    <IoIosArrowBack color={Colors.primary} size={20} />
                    <SignUpBtn>Back</SignUpBtn>
                </HeaderWrapper>
                <IntroSection
                    name="Create Password"
                    description="Please provide the following details to create a new password."
                />
                <Divider />
                <form onSubmit={handleSubmit(onSubmit)}>
                    <FormWrapper>
                        <Row>
                            <Col md="12">
                                <DropdownTitle>Email address*</DropdownTitle>
                                <SelectorWrapper>
                                    <Controller
                                        control={control}
                                        name="email"
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                disabled
                                                placeholder="Email address"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                value={value}
                                                className={error ? "invalid" : ""}
                                            />
                                        )}
                                    />
                                    <FormErrors errors={errors} formControlName="email" />
                                </SelectorWrapper>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <DropdownTitle>Create new password*</DropdownTitle>
                                <SelectorWrapper>
                                    <Controller
                                        control={control}
                                        name="password"
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                placeholder="Password"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                type="password"
                                                value={value}
                                                className={error ? "invalid" : ""}
                                            />
                                        )}
                                    />
                                    <FormErrors errors={errors} formControlName="password" />
                                </SelectorWrapper>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <DropdownTitle>Repeat password*</DropdownTitle>
                                <SelectorWrapper>
                                    <Controller
                                        control={control}
                                        name="repeatPassword"
                                        render={({
                                            field: { onChange, onBlur, value, ref },
                                            fieldState: { error },
                                        }) => (
                                            <Input
                                                placeholder="Repeat password"
                                                onChange={onChange}
                                                onBlur={onBlur}
                                                type="password"
                                                value={value}
                                                className={error ? "invalid" : ""}
                                            />
                                        )}
                                    />
                                    <FormErrors errors={errors} formControlName="repeatPassword" />
                                </SelectorWrapper>
                            </Col>
                        </Row>
                        {loading ? <Loader /> : <Button type="Submit" name="Sign in" />}
                    </FormWrapper>
                </form>
            </UserCreationLayout>
        </>
    );
}
