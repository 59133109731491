import { useEffect, useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { Colors } from "../../utilities/colors";
import InternIcon from "../../assets/icons/intern.png";
import InternsIcon from "../../assets/icons/interns.png";
import UserIcon from "../../assets/icons/user.png";
import Remarks from "../../assets/icons/svg/remarks.svg";
import Accepted from "../../assets/icons/svg/acceptedIcon.svg";
import Pending from "../../assets/icons/svg/pendingIcon.svg";
import Reject from "../../assets/icons/reject-48.png";
import AlertModal from "../alertModal/alertModal";
import Button from "../button/button";
import { onDeletingInternship, jobActionReview } from "../../services/apiCalls";
import CompaniesCardTitle from "./companiesCardTitle";
import moment from "moment";
import "./CompaniesTitle.css";
import { useTranslation } from "react-i18next";
const FlexDiv = styled.div`
  display: flex;
`;
const CardsWrapper = styled(FlexDiv)`
  background: ${Colors.light};
  border-radius: 10px;
  margin-bottom: 15px;
  
  // &:last-child() {
  //   margin-bottom: 0px;
  // }
  @media (max-width: 500px) {
    padding: 10px;
    display: block;
    margin-bottom: 0px;
  }
`;
const ContentSection = styled.div`
  margin-left: 10px;
  width: calc(100% - 26px);
  // cursor: pointer;
`;
const IconSection = styled.div`
  padding: 3px;
  @media (max-width: 500px) {
    position: relative;
    top: 30px;
  }
`;



const DateText = styled.p`
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  margin: 0;
  @media (max-width: 500px) {
    font-size: 16px;
  }
`;
const SmallText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
`;

const DateWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  @media (max-width: 500px) {
    flex-direction: column;
  }
`;
const FlexWrapper = styled(FlexDiv)`
  align-items: center;
  @media (max-width: 500px) {
    flex-direction: column;
    margin-left: 60px;
  }
`;

const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
`;
const VacancyText = styled(SmallText)`
  line-height: 35px;
  color: #b3b3b3;
  display: flex;
  align-items: center;
  margin-right: 30px;
  min-width: 50px;
  > img {
    margin-right: 5px;
  }

  @media (max-width: 767px) {
    display: block;
    align-items: left;
    margin-right: -65px;
    min-width: 400px;
    //margin-left:70px;
    > img {
      align-items: left;
      // margin-right: 5px;
    }
    span{
      position:absolute;
      left:100px;
    }
  }
`;

const Message = styled.div`
  margin-bottom: 20px;
  // padding-left: 20px;
  font-size: 22px;
  padding-bottom: 5px;
`;


const ViewWrapper = styled.div`
  color: #d04c8d;
  font-size: 18px;
  font-weight: 500;
  text-decoration-line: underline;
  margin-right: 20px;
  cursor:pointer;
  @media (max-width: 767px) {
    margin-left: 300px;
  }
`;

export const dateDifference = (date) => {
  let date1 = new Date(date);
  let date2 = new Date();
  return parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);
};

export default function CompaniesCard({
  datas,
  onUpdate,
  showToast,
  onCardClick,
  keyIdx,
}) {
  const org = useSelector((state) => state.org.orgData);
  const code = org?.additional_info?.org_code;
  const {
  
    org_interns_count,
    org_internship_count,
    org_logo,
    org_name,
    org_user_count,
    org_status,
    
  } = datas;
  const { is_admin } = datas.user_details; //status to be added here TODO
  const navigate = useNavigate();
  const { t } = useTranslation();
  const onNavigate = () => {
    onCardClick();
  };

  const positionStartDuration = (created_at) => {
    let dateDifferences = dateDifference(created_at);
    if (dateDifferences === 1) {
      return dateDifferences + t(" day ago");
    } else if (dateDifferences > 1) {
      return dateDifferences + t(" days ago");
    } else if (dateDifferences === 0) {
      return t("Today");
    } else {
    }
  };

  useEffect(() => {
    positionStartDuration();
  }, []);

  const getDuration = (d1, d2) => {
    d2 = new Date(d2);
    d1 = new Date(d1);
    const date1 = moment(d1).format("DD/MM/YYYY");
    const date2 = moment(d2).format("DD/MM/YYYY");
    var startDate = new Date(
      date1.split("/")[2],
      date1.split("/")[1] - 1,
      date1.split("/")[0]
    );
    var endDate = new Date(
      date2.split("/")[2],
      date2.split("/")[1] - 1,
      date2.split("/")[0]
    );

    var diff = new Date(endDate - startDate);
    var diffResult = (diff.getFullYear() - 1970) * 12 + diff.getMonth();
    if (diffResult === 0) {
      return `1 Month`;
    } else {
      return `${diffResult} Months`;
    }
  };

  const onEdit = (e) => {
    e.stopPropagation();
    navigate({
      pathname: "/internships/new-intern",
      search: `?id=${datas.id}&fromInternshipPage=true`,
    });
  };

  const [show, setShow] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(true);
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    if (org_status !== undefined) {
      if (org_status === "pending") {
        setShowEditIcon(true);
        setImageSrc(Pending);
      } else if (org_status === "accepted") {
        setImageSrc(Accepted);
        setShowEditIcon(false);
      } else if (org_status === "denied") {
        setShowEditIcon(false);
        setImageSrc(Pending);
      } else if (org_status === "review") {
        setImageSrc(Remarks);
        setShowEditIcon(true);
      }
    }

    if (org_status === "rejected") {
      setImageSrc(Reject);
      setShowEditIcon(false);
    }
  }, []);

  const onConfirmDeletion = async () => {
    const { data } = await onDeletingInternship({
      code: code,
      id: datas.id,
      update_time: datas.update_time,
    });
    if (data && data.status === 1) {
      setShow(false);
      toast.success(t("Internship deleted successfully"), {
        position: toast.POSITION.TOP_RIGHT,
      });
      onUpdate();
    } else {
      toast.error(t("Internship has been deleted "), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const closeModal = () => {
    setShow(false);
  };
  const onDelete = (e) => {
    e.stopPropagation();
    setShow(true);
  };

  const content = () => {
    return (
      <>
        <Message>{t("Are you sure want to delete the Internship?")}</Message>
        <Row>
          <Col md="6" />
          <Col
            md="12"
            className="d-flex justify-content-center align-items-center"
          >
            <Button
              width="100px"
              margin="20px 0px 30px 0px"
              name={t("Yes")}
              onClick={onConfirmDeletion}
            />
            <Button
              secondary
              width="100px"
              name={t("No")}
              color="#FFFFFF"
              margin="20px 0px 30px 20px"
              onClick={closeModal}
            />
          </Col>
        </Row>
      </>
    );
  };

  const makeActivateOrDeactivate = ($cks) => {
    var mainStatus = "";
    if ($cks === false) {
      mainStatus = "deactivate";

    } else {
      mainStatus = "activate";
    }
    const body = {
      id: datas.id,
      status: mainStatus,
      update_time: datas.update_time,
    };
    const result = jobActionReview(body);
    showToast();
    if (result.status === 1) {
      toast.success(result.data.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      if (result.data.status === 1) {
       
      }
    }
  };

  return (
    <CardsWrapper>
      <IconSection>
        {org_status === "pending" || org_status === "rejected" ? (
          <Image
            className=""
            src={imageSrc}
            style={{ width: "25px", height: "25px" }}
          />
        ) : (
          <Image
            className=""
            src={imageSrc}
            style={{ width: "25px", height: "25px" }}
          />
        )}
      </IconSection>

      <ContentSection>
        <CompaniesCardTitle
          title={org_name}
          navigation={onNavigate}
          user_type={is_admin.toLowerCase() === "y" ? "Admin" : ""}
          logo={org_logo}
          invite={false}
          status={org_status}
          keyIdx={keyIdx}
          userCount={org_user_count}
        />

        <>
          <HrLine />

          <DateWrapper >
            <FlexWrapper>
              <VacancyText>
                <img src={UserIcon} alt="view" />
                <span>{org_user_count + t(" Users")}</span>
              </VacancyText>
              <VacancyText>
                <img src={InternIcon} alt="view" />
                <span>{org_internship_count + t(" Internships")}</span>
              </VacancyText>
              <VacancyText>
                <img src={InternsIcon} alt="view" />
                <span>{org_interns_count + t(" Interns")}</span>
              </VacancyText>
            </FlexWrapper>

            <ViewWrapper onClick={onNavigate}>
              <a>{t("View")}</a>
            </ViewWrapper>
          </DateWrapper>
        </>
      </ContentSection>

      <AlertModal show={show} close={closeModal} content={content()} />
    </CardsWrapper>
  );
}
