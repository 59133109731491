import { Modal } from "react-bootstrap";
import styled from "styled-components";
import CloseIcon from "../../assets/closeIcon.png";

const CancelButton = styled.div`
 width:80px;
 height:80px;
 background:white;
 margin-top:-28px;
 border-radius:50%;
 margin-left:90%;
 text-align:center;
 font-size:24px;
 box-shadow: 20px 20px 50px grey ;
 z-index:2000;
 @media (max-width: 500px) {
  display:none;
}
`;
function AlertModal(props) {
  const Wrapper = styled.div`
   height:220px;
   padding:${props.increaseHeight ? "10px 0 0 0" : "40px 0 0 0"};
   @media (max-width: 500px) {
    height:${props.increaseHeight ? "300px" : "220px"};
    padding: 40px 0 0 0;
  }
 `;


  return (
    <div>
      <Modal show={props.show} onHide={props.close} centered>
        {props.increaseHeight ?
          <CancelButton onClick={props.closeDialog}>
            <img src={CloseIcon} style={{ marginTop: "24px" }} />
          </CancelButton>
          : null}
        <Wrapper>
          <div style={{ textAlign: 'center' }}>{props.content}</div>
        </Wrapper>
      </Modal>
    </div>
  );
}

export default AlertModal;
