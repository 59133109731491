import React, { useState } from "react";
import styled from "styled-components";
import "./hiredmodal.css";
import { useSnapshot } from "valtio";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import './ToggleSwitch.css';
import { changApplications } from "../../services/apiCalls";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CardsWrapper = styled.div`
  position: absolute;
  width: 983px;
  height: 329px;
  // left: 27%;
  top: 204px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;

  @media (max-width: 500px) {
    width: 350px;
    height: 630px;
    // position: relative;
    padding-top: 10px;
    // left: 0px;
    margin-left: 2px;
  }
  @media (min-width:600px) and (max-width: 850px) {
    width: 580px;
    height: 630px;
    // position: relative;
    padding-top: 10px;
    // left: 0px;
    margin-left: 2px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 84px;
  margin: 10px;
  display: flex;
`;
const Name = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  color: #202020;
  position: relative;
  top: 20px;
  left: 300px;
  @media (min-width: 375px) and (max-width: 850px) {
    left: 10px;
  }
  
`;
const Icon = styled.div`
  position: absolute;
  top: 25px;
  right: 30px;
  // @media (max-width: 500px) {
  //   right: 120px;
  // }
`;
const Image = styled.img`
  width: 161px;
  height: 160px;
  position: relative;
  left: 50px;
  bottom: 40px;
  @media (min-width: 375px) and (max-width: 500px) {
    width: 161px;
    height: 160px;
    position: relative;
    margin-left: 5px;
    left: 0px;
    bottom: 0px;
  }
`;
const ProfileData = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #d04c8d;
`;
const ProfileValue = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 180%;
  color: #6b6b6b;
`;
const DataWrapper = styled.div`
  display: flex;
  gap: 30px;
  margin: 0 0 10px 0;
`;
const HRLine = styled.div`
  border: 0.4px solid rgba(32, 32, 32, 0.1);
  width: 690px;
  position: relative;
  left: 260px;
  bottom: 30px;
  @media (max-width:850px){
    border: 0.4px solid rgba(32,32,32,0.1);
    width: auto;
    position: relative;
    left: 0px; 
    bottom: 5px;
  }
`;

const DocumentWrapper = styled.div`
  display: flex;
  gap: 10px;
  position: absolute;
  right: 50px;
  //margin:0 0 10px 0;
  @media (min-width: 375px) and (max-width: 850px) {
    gap: 10px;
    margin-left: 68px;
    position: relative;
    top: 23px;
  }
`;
const Footer = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #d04c8d;
`;
const ProfileModal = ({ open, onClose, id }) => {
  useSnapshot(trackProxy)
  const [app,setApp] = useState(null)
  const [contract, setContract] = useState('N')
  const [change,setChange] = useState(0)
  const { t, i18n } = useTranslation();
  if(id!=null){
    for (let i = 0; i < trackProxy.applist.length; i++) {
      if(id === trackProxy.applist[i]['id']){
        if(!app){
          setApp(trackProxy.applist[i])
        }
      }
    }
  }
  const close = ()=>{
    if(trackProxy.setcheck===0){
      trackProxy.setcheck=1
    }else{
      trackProxy.setcheck=0
    }
    onClose()
  }

  const ch = () =>{
    if(change===0){
      setChange(1)
    }else{
      setChange(0)
    }
  }

  const submit=async ()=>{
    if(contract==='N'){
      setContract('Y')
      const {data} = await changApplications("hire",id,{})
    }else{
      setContract('N')
      const {data} = await changApplications("cancelhire",id,{})
    }
    toast.success(t("User Information Updated"), {
      position: toast.POSITION.TOP_RIGHT,
    });
    ch()
  }
  

  return (
    <div className="overlay-popup">
      {(app)?<CardsWrapper>
        <CardWrapper id="headercard">
        <Name>{app.name}</Name>
          <Icon onClick={close} id="headericon" style={{cursor: "pointer"}}>
            X
          </Icon>
        </CardWrapper>

        <div className="hiredstyle">
        <Image src={app.profile_picture} alt="img" />

<div>
  <DataWrapper>
    <ProfileData>{t("Gender")}:</ProfileData>
    <ProfileValue>{app.gender}</ProfileValue>
  </DataWrapper>
  <DataWrapper>
    <ProfileData>{t("E-mail ID")}:</ProfileData>
    <ProfileValue>{app.email}</ProfileValue>
  </DataWrapper>
  <DataWrapper>
    <ProfileData>{t("Location")}:</ProfileData>
    <ProfileValue>{app.city_name}, {app.state_name}, {app.country_name}</ProfileValue>
  </DataWrapper>
</div>

<div>
            <DataWrapper>
              <ProfileData>{t("Nationality")}:</ProfileData>
              <ProfileValue>{app.nationality}</ProfileValue>
            </DataWrapper>
            <DataWrapper>
              <ProfileData>{t("Mobile No")}:</ProfileData>
              <ProfileValue>{app.mob_number}</ProfileValue>
            </DataWrapper>
          
          </div>
        </div>
        <HRLine />
        <DocumentWrapper>
          <Footer>{t("Employee Contract Signed")}</Footer>
          <div className="toggles"  >
            <input  type="checkbox" id="switch" className="checkbox" checked={contract=='N'} onClick={()=>{
              submit()
            }} />
            
            <label for="switch" className="sliders"></label>
        </div>
        </DocumentWrapper>
      </CardsWrapper>:""}
    </div>
  );
};

export default ProfileModal;
