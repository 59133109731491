import React, { useEffect, useState } from "react";
import QuickChart from "quickchart-js/build/quickchart.cjs";
import { Text, Image, View } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const chartData = data;

  const ideaOpts =
    chartData && chartData?.["domains"]?.["Ideas and Opportunities"];
  const resources = chartData && chartData?.["domains"]?.["Resources"];
  const intoAction = chartData && chartData?.["domains"]?.["Into Action"];

  const [ideaOptsChartUrl, setIdeaOptsChartUrl] = useState(null);
  const [resourcesChartUrl, setResourcesChartUrl] = useState(null);
  const [intoActionChartUrl, setIntoActionChartUrl] = useState(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (ideaOpts !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(ideaOpts?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#0B74C3", "#045694", "#053D67"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              plugins: {
                datalabels: {
                  anchor: "end",
                  align: "end",
                },
              },
              cutoutPercentage: 20,
              needle: {
                color: "#8FBBDF",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");

        setIdeaOptsChartUrl(await myChart.getUrl());
      })();
    }

    if (resources !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(resources?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#0B74C3", "#045694", "#053D67"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 20,
              needle: {
                color: "#8FBBDF",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");

        setResourcesChartUrl(await myChart.getUrl());
      })();
    }

    if (intoAction !== undefined) {
      (async () => {
        const myChart = new QuickChart();
        // myChart.setHost("127.0.0.1:3400");
        // myChart.setScheme("http");
        myChart
          .setConfig({
            type: "gauge",
            data: {
              datasets: [
                {
                  value: parseInt(intoAction?.points) / 100,
                  data: [0.28, 0.73, 1.0],
                  backgroundColor: ["#0B74C3", "#045694", "#053D67"],
                  borderWidth: 0,
                },
              ],
            },
            options: {
              valueLabel: {
                fontSize: 22,
                backgroundColor: "transparent",
                color: "#000",
                formatter: (value, context) => value * 100 + "%",
                bottomMarginPercentage: 10,
              },
              cutoutPercentage: 20,
              needle: {
                color: "#8FBBDF",
                radiusPercentage: 2,
                widthPercentage: 3.2,
                lengthPercentage: 80,
              },
            },
          })
          .setWidth(500)
          .setHeight(300)
          .setBackgroundColor("transparent");
    console.log("mychartGuage",myChart.getUrl())
        setIntoActionChartUrl(await myChart.getUrl());
      })();
    }
  }, [ideaOpts, resources, intoAction, t]);

  return (
    <View style={{}}>
      <View
        style={{
          marginRight: 20,
        }}
      >
        <Text
          style={{
            fontFamily: "General Sans",
            fontSize: 24,
            fontWeight: 500,
            color: "#000000",
            textTransform: "uppercase",
            borderBottom: "2px solid black",
            paddingBottom: 5,
          }}
        >
          {"4."} {t("Employability")}
        </Text>

        {ideaOpts && (
          <View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.1"} {t("Ideas and Opportunities")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                textIndent: 18,
              }}
            >
              {t(
                "The Ideas and Opportunities domain relates to the ability to identify the ideas and opportunities that will be most helpful in bringing value to talents’ work and achieving their goals. An important part of this is the ability to develop a clear vision and to capitalise on ideas and opportunities to reach successful outcomes."
              )}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.1"} {t("Spotting Opportunities")} -{" "}
                {t(ideaOpts["values"]["Spotting Opportunities"]["score"])}
              </Text>
              {ideaOpts["values"]["Spotting Opportunities"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Talent would be good at spotting opportunities, which other people may not see, bringing benefits to themselves and others. Talent would have the ability to create opportunities and to problem-solve in order to improve one’s performance in school or work."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Spotting Opportunities"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent will see opportunities, which will benefit oneself and other people. Talent may also be able to engage in problem-solving to improve one’s own performance in work."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Spotting Opportunities"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to identify, create and act on opportunities is important in helping the talent reach one’s goals. Learning to problem- solve can also improve one’s performance in work and bring benefits for others."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.2"} {t("Creativity")} -{" "}
                {t(ideaOpts["values"]["Creativity"]["score"])}
              </Text>
              {ideaOpts["values"]["Creativity"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at brain storming to generate new ideas and finding ways to meet challenges at school or work. The talent has the ability to persuade people to get involved in projects."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Creativity"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent is able to contribute to idea generation, to problem-solving and can persuade others to get involved in projects."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Creativity"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to contribute ideas and to solve problems are important skills when involved in work projects. Development in these skills will help the talent reach one’s goals and improve one’s work performance."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.3"} {t("Valuing ideas")} -{" "}
                {t(ideaOpts["values"]["Valuing Ideas"]["score"])}
              </Text>
              {ideaOpts["values"]["Valuing Ideas"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at estimating the value of ideas and finding solutions to problems in one’s work."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Valuing Ideas"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent will be able to assess the value of ideas. The talent can also support and implement ideas in order to complete a project."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Valuing Ideas"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to evaluate, support and implement ideas are important skills in many work areas. Having the confidence to assess the value of ideas will help the talent to understand the way to use these ideas in projects and improve one’s own work performance."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.4"} {t("Vision")} -{" "}
                {t(ideaOpts["values"]["Vision"]["score"])}
              </Text>
              {ideaOpts["values"]["Vision"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at building a vision of the future that inspires other people and reflects the future in which the talent would like to live. The talent is also able to understand what decisions need to be made to achieve that vision for one’s life."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Vision"]["score"] === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that to some degree the talent is able to build a vision of the future that reflects how the talent would like to live and inspires others. The talent may also be able to make appropriate decisions to ensure the talent achieve one’s goal."
                    )}
                  </Text>
                </>
              ) : ideaOpts["values"]["Vision"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to imagine a future for oneself and others is an important skill. It helps to reach set goals and improve performance at work. Making the appropriate decisions to achieve these outcomes is a valuable skill to develop."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.1.5"} {t("Ethical and Sustainable Thinking")} -{" "}
                {t(
                  ideaOpts["values"]["Ethical and Sustainable Thinking"][
                    "score"
                  ]
                )}
              </Text>
              {
                ideaOpts["values"]["Ethical and Sustainable Thinking"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent knows why integrity and ethical behaviour is important. One’s own behaviour reflects this and the talent takes responsibility to promote and advance ethical practices in work."
                    )}
                  </Text>
                </>
              ) : 
                  ideaOpts["values"]["Ethical and Sustainable Thinking"][
                    "score"
                  ]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that to some degree the talent is guided by standards of integrity and ethical values in one’s behaviour and work. The talent may also promote these values and sustainable practices in the work setting."
                    )}
                  </Text>
                </>
              ) : 
                  ideaOpts["values"]["Ethical and Sustainable Thinking"][
                    "score"
                  ]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Knowing reasons for ethical thinking and personal integrity is important in the workplace. Living by them and promoting them is equally important, as this will help the talent reach one’s own goals. Promoting sustainable practices is an important part of the modern workplace, so developing one’s awareness of this area may help the talent to improve one’s work performance."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}

        {resources && (
          <View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.2"} {t("Resources")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                textIndent: 18,
              }}
            >
              {t(
                "The domain relates to the ability to use both internal resources (awareness, motivation and stress regulation) and external resources(help from others, financial support and knowledge) to achieve goals. This domain includes the ability to recognise one’s personal strengths and apply them effectively to make progress. It also relates to the ability to motivate and gain support from others, bringing people together to work towards an important goal."
              )}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.1"} {t("Self-efficacy")} -{" "}
                {t(resources["values"]["Self Efficacy"]["score"])}
              </Text>
              {resources["values"]["Self Efficacy"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at recognising and understanding when mistakes have been made when trying something new. This means that the talent is able to learn from one’s mistakes, while completing one’s work. The talent is able to push through difficulties to complete the given task"
                    )}
                  </Text>
                </>
              ) : resources["values"]["Self Efficacy"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent may see when a mistake has been made, allowing the talent to learn and continue one’s work."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Self Efficacy"]["score"] === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to believe in one’s ability to successfully complete one’s work will help the talent approach new projects with confidence. It will also help the talent to recognise that mistakes can be learning experiences, not disasters!"
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.2"} {t("Self-awareness")} -{" "}
                {t(resources["values"]["Self Awareness"]["score"])}
              </Text>
              {resources["values"]["Self Awareness"]["score"] === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at knowing one’s own abilities. The talent can deliver presentations well and weigh the value of differentoptions when decision making."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Self Awareness"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that the talent has some understanding of one’s own abilities and have some skill to evaluate different viewpoints when making decisions. The talent also has some ability in making presentations."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Self Awareness"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to understand and know one’s own areas of competence will help the talent plan one’s career path. The ability to think through different options before making a decision will enable the talent to be confident in one’s choices."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.3"} {t("Financial and economic literacy")} -{" "}
                {
                  resources["values"]["Financial and Economic Literacy"][
                    "score"
                  ]
                }
              </Text>
              {
                resources["values"]["Financial and Economic Literacy"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at developing budgets for projects and evaluating the costs and benefits of new ideas. The talent can also develop financial plans for long term projects."
                    )}
                  </Text>
                </>
              ) : 
                  resources["values"]["Financial and Economic Literacy"][
                    "score"
                  ]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent will be able to develop budgets for short and long-term projects. The talent may also be able to evaluate the costs and benefits of new ideas."
                    )}
                  </Text>
                </>
              ) : 
                  resources["values"]["Financial and Economic Literacy"][
                    "score"
                  ]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to understand the financial implications of work projects may be important in helping the talent achieve one’s goals. Learning how to evaluate the costs of new projects willenable the talent to plan one’s own career path more accurately."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.4"} {t("Mobilising others")} -{" "}
                {t(resources["values"]["Mobilising Others"]["score"])}
              </Text>
              {resources["values"]["Mobilising Others"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at inspiring and motivating others to support and contribute to projects in which the talent is involved. The talent is also able to build a balanced team, by recognising the skills and weaknesses of individual team members."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Mobilising Others"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent is able to encourage others to contribute to and support projects in which the talent is involved. The talent may also be able to assemble a well-balanced team, which takes into account individual strengths and weaknesses."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Mobilising Others"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to encourage others to be involved in a team for a project can be a useful skill in many areas of work. Learning to recognise different skill sets in others may also help the talent to bring together a balanced team, when approaching new work."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.2.5"} {t("Mobilising Resources")} -{" "}
                {t(resources["values"]["Mobilising Resources"]["score"])}
              </Text>
              {resources["values"]["Mobilising Resources"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is good at planning to use and gather the resources the talent needs to complete one’s work. The talent can identify extra resources needed to overcome challenges found during the course of a project. The talent is able to find sponsors for a project and then manage the budget successfully."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Mobilising Resources"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent is able to plan projects appropriate to the resources the talent has and is able to gather. The talent may also understand how to budget for a project, after finding sponsors."
                    )}
                  </Text>
                </>
              ) : resources["values"]["Mobilising Resources"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to resource a project can be an important skill to bring to one’s work. Successful management of resources and finance are necessary in most areas of work."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}

        {intoAction && (
          <View>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 14,
                fontWeight: 200,
                color: "#000000",
                marginVertical: 4,
              }}
            >
              {"4.3"} {t("Into Action")}
            </Text>
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 12,
                fontWeight: 200,
                textAlign: "justify",
                color: "#213343",
                lineHeight: 1.5,
                textIndent: 18,
              }}
            >
              {t(
                "The Into Action domain relates to talents’ ability to use ideas and opportunities combined with the appropriate internal and external resources, to develop effective plans and strategies that will help them make progress and achieve their goals. This includes the ability to take the initiative, persevere through periods of uncertainty and learn from their experience to develop their knowledge and skills."
              )}
            </Text>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.1"} {t("Motivation and Perseverance")} -{" "}
                {t(
                  intoAction["values"]["Motivation and Perseverance"]["score"]
                )}
              </Text>
              {
                intoAction["values"]["Motivation and Perseverance"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is motivated and work hard to achieve one’s goals. When facing challenges the talent will work even harder to ensure one’s goals are met. Being aware of one’s strengths enables the talent to make progress at work."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"]["Motivation and Perseverance"]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that at times the talent is able to give maximum effort and remain motivated in one’s work. The talent can also show some ability to overcome challenges when working, showing some confidence in one’s own strengths."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"]["Motivation and Perseverance"]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to work hard and remain motivated is an important skill in the workplace. Success in achieving one’s goals and much of one’s progress will depend on these characteristics. It is important to make the effort to overcome challenges, so that goals can be reached and progress made."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.2"} {t("Working with others")} -{" "}
                {t(intoAction["values"]["Working with Others"]["score"])}
              </Text>
              {intoAction["values"]["Working with Others"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is able to form teams and work with networks of people in order to meet the needs of a project. The talent is a good team player and have confidence in one’s ability to work with diverse individuals and groups in order to achieve a common goal."
                    )}
                  </Text>
                </>
              ) : intoAction["values"]["Working with Others"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that the talent can sometimes adapt to working with others in a team, in order to achieve the desired goal. The talent may also be able to form a team of individuals with diverse skills to ensure the needs of a project are met. The talent do have some confidence in one’s ability in this area."
                    )}
                  </Text>
                </>
              ) : intoAction["values"]["Working with Others"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to work in a team is an important skill in the workplace. Many tasks are completed and goals reached through teamwork and networking with other people. There is often a need to work with diverse people, who have different and complementary skillsets."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.3"} {t("Planning and management")} - {""}
                {t(intoAction["values"]["Planning and Management"]["score"])}
              </Text>
              {intoAction["values"]["Planning and Management"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is able to plan projects, define priorities and give achievable milestones for progress. The talent can set clear goals at the beginning of a project, which the talent aims to achieve and are able to maximise the value of ideas one has encountered."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"]["Planning and Management"]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that the talent can sometimes set goals the talent wants to achieve before beginning a project. The talent may be able to develop plans, which define priorities and give achievable milestones for progress. Within the plan, the talent may also be able to maximise the value of ideas one has encountered."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"]["Planning and Management"]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to plan projects is a useful skill in the workplace. It can help to define achievable goals, incorporate short-term milestones for progress and allow ideas to be evaluated. Planning skills can also be used in many different work situations."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.4"} {t("Learning through Experience")} -{" "}
                {t(
                  intoAction["values"]["Learning Through Experience"]["score"]
                )}
              </Text>
              {
                intoAction["values"]["Learning Through Experience"]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is able to improve, reflect and identify areas of learning when the talent has completed a project. The talent find ways of improving oneself by reflecting on what the talent has learnt from projects. The talent learns from experience by reflecting on mistakes and successes after completing a project. The talent can also identify precise areas of new learning."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"]["Learning Through Experience"]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that the talent has some ability to reflect on the successful aspects of a project and on the mistakes made once the talent has finished the work. The talent also has some ability to identify what the talent has learnt in a project and to use this to improve oneself."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"]["Learning Through Experience"]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being aware of what the talent is learning whilst working on a project is an important skill, as it will help the talent to make improvements in oneself and in one’s skill-set. Once the project is completed, identify what the talent has learnt from the experience. The talent can learn from success and from mistakes, so the talents must make sure one reflects on both of these aspects of one’s work."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.5"} {t("Coping with uncertainty")} -{" "}
                {t(
                  intoAction["values"][
                    "Coping with Uncertainty Ambiguity and Risk"
                  ]["score"]
                )}
              </Text>
              {
                intoAction["values"][
                  "Coping with Uncertainty Ambiguity and Risk"
                ]["score"]
               === "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is able to use one’s best judgment and the available information to make decisions, especially when the situation is ambiguous or uncertain. The talent is also adaptable when circumstances change and can change one’s plans in the course of completing one’s work. Alongside this, the talent has a clear idea of the goals and targets the talent aims to achieve."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"][
                    "Coping with Uncertainty Ambiguity and Risk"
                  ]["score"]
                 === "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that the talent has some ability to use available information and one’s best judgment to make decisions when faced with ambiguous or uncertain situations. The talent may be able to adapt one’s plans when circumstances change in the course of one’s work. One’s goals and targets are sometimes clear."
                    )}
                  </Text>
                </>
              ) : 
                  intoAction["values"][
                    "Coping with Uncertainty Ambiguity and Risk"
                  ]["score"]
                 === "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being aware of and adapting in changing circumstances is an important skill to develop. When the talent has completed a project, look back at times when the talent found it hard to change one’s plans or make a decision when the circumstances were unclear. It is important to have clear goals and targets in work, so the talent must make sure these are defined before one begins a project."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
            <View>
              <Text
                style={{
                  fontFamily: "General Sans",
                  fontSize: 12,
                  fontWeight: 200,
                  color: "#000000",
                  marginVertical: 4,
                }}
              >
                {"4.3.6"} {t("Taking the Initiative")} -{" "}
                {t(intoAction["values"]["Taking the Initiative"]["score"])}
              </Text>
              {intoAction["values"]["Taking the Initiative"]["score"] ===
              "High" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "The talent is able to take responsibility in meeting challenges at work and in the past the talent has initiated action to make improvements in school or work. The talent has also used different types of resources effectively to achieve one’s goals to bring change."
                    )}
                  </Text>
                </>
              ) : intoAction["values"]["Taking the Initiative"]["score"] ===
                "Moderate" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "This means that the talent may, in the past, have initiated actionto make improvements at school or work and that the talent may have accepted some responsibility to meet challenges. The talent may also have achieved one’s goals using different types of resources successfully."
                    )}
                  </Text>
                </>
              ) : intoAction["values"]["Taking the Initiative"]["score"] ===
                "Low" ? (
                <>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      textIndent: 18,
                    }}
                  >
                    {t(
                      "Being able to take responsibility and initiative are important skills in the workplace. Learn to identify areas where the talent knows one could bring improvement and develop plans to do so. Work with others to see one’s plans carried out. When the talent meets challenges at work, must take responsibility to meet them through reflection and planning."
                    )}
                  </Text>
                </>
              ) : (
                <></>
              )}
            </View>
          </View>
        )}
      </View>

      <View
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 14,
        }}
      >
        {ideaOptsChartUrl !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={ideaOptsChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Ideas and Opportunities")}
            </Text>
          </View>
        )}

        {resourcesChartUrl !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={resourcesChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Resources")}
            </Text>
          </View>
        )}

        {intoActionChartUrl !== null && (
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Image src={intoActionChartUrl} style={{ margin: 15 }} />
            <Text
              style={{
                fontFamily: "General Sans",
                fontSize: 10,
                fontWeight: 200,
                textAlign: "center",
                color: "#666666",
              }}
            >
              {t("Fig. Into Action")}
            </Text>
          </View>
        )}
      </View>
    </View>
  );
};
