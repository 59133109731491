import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Form from 'react-bootstrap/Form';
import RadioInput from "../../components/radioInput/radioInput";
import { Controller } from "react-hook-form";
import Input from "../../components/input/input";
import { Row, Col } from "react-bootstrap";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import React, { useEffect, useState } from "react";
import { internshipModule } from "../../pages/MySIPDirectHiring/staticContentInternship";
import { yupResolver } from "@hookform/resolvers/yup";

const Description2 = styled.p`
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 20px
}
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const CheckboxGroup = styled.div`
margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
> input{
    width: 30px,
    height: 30px, 
    border: 2px solid #000, // Specify the border color
    borderRadius: 4px
}
`;

const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding-top: 14px;
  color: ${Colors.labelText};
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;
const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "0px")};
`;
// const CheckboxInput = styled.input`
//   margin-right: 8px; 
//   margin-left: 10px;
//   width: 
 
// `;

const CheckboxLabel = styled.label`
  font-size: 18px;
  
  
`;
export default function QuestionSection3 ({onSubmit, control, watch}) {

    const [selectedCheckboxesOthers, setSelectedCheckboxesOthers] = useState([]);
    const [declarationChecked, setDeclarationChecked] = useState(false);
    const fieldValue = watch('company-checkbox');


    useEffect(()=>{
        onSubmit({
            selectedCheckboxesOthers,
        });
        
    },[]);

    const handleCheckboxChangeOther = (other) =>{
        if (selectedCheckboxesOthers.includes(other)) {
            // If the checkbox is already selected, remove it from the selected list
            setSelectedCheckboxesOthers((prevSelected) =>
              prevSelected.filter((item) => item !== other)
            );
          } else {
            // If the checkbox is not selected, add it to the selected list if the limit is not reached
            if (selectedCheckboxesOthers.length < 3) {
              setSelectedCheckboxesOthers((prevSelected) => [...prevSelected, other]);
            }
          }
        onSubmit({selectedCheckboxesOthers: [...selectedCheckboxesOthers, other]});
      };

      const handleDeclarationChange = (event) => {
        const checked = event.target.checked;
        setDeclarationChecked(checked);
        onSubmit({ declarationChecked: checked });
      };

      return(
        <div>
            <Description2>{internshipModule.subHeading2}</Description2>
            <BreakSection>
            <hr/>
            </BreakSection>
            <GroupWrapper>
                <FormLabel>{internshipModule.question7}</FormLabel>
                {internshipModule.question7List.map((other)=>(
                    <Controller
                    key={other}
                    name="company_checkbox"
                    control={control}
                    render={({field}) =>(
                        <CheckboxGroup key={other}>
                    <Row>
                        <Col xs={"auto"} style={{marginTop: "8px"}}>
                        <CheckboxInput 
                         onChange={() => handleCheckboxChangeOther(other)}
                         checked={selectedCheckboxesOthers.includes(other)}
                         disabled={selectedCheckboxesOthers.length >= 3 && !selectedCheckboxesOthers.includes(other)}
                         />
                        </Col>
                        <Col>
                        <TableHead>{other}</TableHead>
                        </Col>
                        {other === 'Others (please specify)' ? (
                            <div style={{marginTop: "10px", marginBottom: "10px"}}>
                            <Input
                            placeholder={"Others"}/>
                        </div>
                            ): ""}
                    </Row>
                    </CheckboxGroup>
                    )}
                    />
                ))}
            </GroupWrapper>

            <GroupWrapper>
                <FormLabel>Thank you for completing this internship module details.</FormLabel>
                <FormLabel>We shall review and notify the approval via email.</FormLabel>
                <FormLabel>PERSONAL DATA PROTECTION ACT 2010*</FormLabel>
                {/* <div className="checkboxMargin">
                    <CheckboxInput
                    label={declaration}
                    />
                </div> */}
                <CheckboxGroup className='checkbox-group'>
                    {/* <CheckboxInput type="checkbox"/>
                    <CheckboxLabel>{declaration}</CheckboxLabel> */}
                    <Row>
                    <Col xs="auto" style={{marginTop:"10px"}}>
                        <CheckboxInput
                        checked={declarationChecked}
                        onChange={() => setDeclarationChecked(!declarationChecked)}/>
                    </Col>
                    <Col>
                    <TableHead>{internshipModule.declaration}</TableHead>
                    </Col>
                    </Row>
                </CheckboxGroup>

            </GroupWrapper>
        </div>
    )
}