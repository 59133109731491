// import { getCreatingValueDrilldown } from "../../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";

export const getCreatingValueDrilldown = () =>{
    const getCreative = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Aesthetic": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 113,
                        "moderate": 412,
                        "high": 163
                    }
                },
                "Creativity": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 107,
                        "moderate": 380,
                        "high": 201
                    }
                },
                "Leadership": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 113,
                        "moderate": 393,
                        "high": 182
                    }
                },
                "Protecting the Planet": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 111,
                        "moderate": 371,
                        "high": 206
                    }
                }
            }
        }
    }
    return getCreative
}

export default Ad2DrilldownHOC(getCreatingValueDrilldown, [
    {
        title: "Aesthetic",
        dataKey: "Aesthetic",
        url: "aesthetic",
        info:"It is very important for talents with high Aesthetic scores to be involved in work that creates beauty, designs beautiful products, or creates beautiful spaces or landscapes for people to enjoy."
    },
    {
        title: "Creativity",
        dataKey: "Creativity",
        url: "creativity",
        info:"Talents with high Creative value scores work best when they are free to be creative. This may involve solving difficult problems, creating new products, looking at an issue from different angles or creating innovative solutions."
    },
    {
        title: "Leadership",
        dataKey: "Leadership",
        url: "leadership",
        info:"It is very important for talents that score high on Leadership to have opportunities to take on a leadership role."
    },
    {
        title: "Protecting the Planet",
        dataKey: "Protecting the Planet",
        url: "protecting-the-planet",
        info:"It is very important for talents that score high on Protecting the Planet that they work for an organisation which takes its responsibility for the planet seriously."
    },
]);