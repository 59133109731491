import styled from "styled-components";
import Divider from "../../components/divider/divider";
import { useTranslation } from "react-i18next";

// styling starts
const LabelWrapper = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  position: relative;
`;
const SplitWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 25px 0px 70px 50px;
  @media (max-width: 525px){
    margin: 25px 0px 70px 100px;
  }
  @media (max-width: 1024px) {
    /* width: 100%; */
  }
`;
const Label = styled.label`
  margin: 0px 15px;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${(props) => (props.color ? props.color : "#504f8c")};
  display: block;
  text-align: center;
  position: relative;
  margin-top: 5px;
  position: absolute;
  bottom: ${(props) => (props.bottom ? props.bottom : "-35px")};
`;
const Marker = styled.div`
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 2px solid ${(props) => (props.color ? props.color : "#504f8c")};
  background: ${(props) => (props.bg ? props.bg : "#e6e6e6")};
`;
// sttyling ends

export default function PaginationDivider({ step }) {
  const stepOneColor = step === 1 ? "#D04C8D" : "#DFDFDF";
  const stepTwoColor = step === 2 ? "#D04C8D" : "#DFDFDF";
  const stepThreeColor = step === 3 ? "#D04C8D" : "#DFDFDF";
  const stepOneBg = step === 1 ? "#fff" : "#D04C8D";
  const stepTwoBg =  step === 2 || step===3 ? "#D04C8D" : "#fff";
  const stepThreeBg = step === 3 ? "#fff" : "#fff ";
  const dividerOneBg=step===2||step===3?"#D04C8D":'#DFDFDF'
  const dividerTwoBg=step===3?"#D04C8D":'#DFDFDF'
  const {t} = useTranslation();
  return (
    <SplitWrapper>
      <LabelWrapper to="/register">
        <Marker bg={stepOneBg} color={stepOneColor} />
        <Label bottom="-50px" color={stepOneColor}>
          {('Internship Type Details')}
        </Label>
      </LabelWrapper>
      <Divider bg={dividerOneBg}/>
      <LabelWrapper to="/upload">
        <Marker bg={stepTwoBg} color={stepTwoColor} />
        <Label bottom="-50px" color={stepTwoColor}>
         {('Intern Detail Upload')}
        </Label>
      </LabelWrapper>
      <Divider bg={dividerTwoBg}/>
      <LabelWrapper to="/upload">
        <Marker bg={stepThreeBg} color={stepThreeColor} />
        <Label bottom="-50px" color={stepThreeColor}>
         {('Completion Of Internship')}
        </Label>
      </LabelWrapper>
      {/*<Divider bg={dividerTwoBg}/>
      <LabelWrapper to="/new-person">
        <Marker bg={stepThreeBg} color={stepThreeColor} />
        <Label bottom="-50px" color={stepThreeColor}>
          {t('Add Contact Person')}
        </Label>
  </LabelWrapper>*/}
    </SplitWrapper>
  );
}
