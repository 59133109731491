import React, { useEffect, useState } from "react";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import styled from "styled-components";
import Union from "../../assets/Union.png";
import logo from "../../assets/companylogosettings.png";
import InternIcon from "../../assets/icons/intern.png";
import Salery from "../../assets/icons/salary.png";
import { Colors } from "../../utilities/colors";
import AddNewJobs from "../../components/addNewUser/addNewJobs";
import View from "../../assets/icons/view.png";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
// import Toggle from "./Toggle";
import { Pagination } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { getJobs, getlistJobs, updateJobs } from "../../services/apiCalls";
import { useNavigate } from "react-router-dom";
import { setId } from "../../reducers/jobReducer";

import { makeStyles } from "@mui/styles";
import { useSnapshot } from "valtio";
import { jobProxy } from "../../pages/jobPortal/jobproxy";
import { useTranslation } from "react-i18next";
import { subsctipationsProxy } from "../Subscriptions/subscripationsProxy";
import "./swiper.css";
const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin: -10px 0 -10px 0;
  @media (max-width: 500px) {
    margin: 10px 0 -10px 0;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";
`;
const SearchSection = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;
const FilterIcon = styled.div`
  background: rgba(255, 255, 255, 1);
  margin: 15px 20px 0px 0px;
  width: 100px;
  height: 50px;
  border-radius: 93px;
`;
const FilterImg = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 12%;
  text-align: center;
  @media (min-width: 320px) and (max-width: 480px) {
    position: relative;
    // bottom: 50px;
    // left: 82%;
    width: 22px;
    height: 15px;
    padding: 10px 0px 0px 0px;
    margin: 3px 0px 0px 10px;
  }
  img {
    widith: 60%;
  }
`;
const FlexDiv = styled.div`
  display: flex;
`;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 10px 30px 0px 30px;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 1.5rem 1.5rem 1.5rem 1rem;
    margin: 2rem 0;
  }
`;

const JobTitle = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
  @media (max-width: 500px) {
    font-size: 16px;
    line-height: 22px;
    margin: -15px 0 0 0;
  }
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  bottom: 30px;
`;

const PostedDate = styled.div`
  font-family: "General Sans";
  font-style: italic;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  color: #d04c8d;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    text-transform: capitalize;
  }
`;
const SmallText = styled.p`
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  margin: 0;
`;
const VacancyText = styled(SmallText)`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
  letter-spacing: 1px;
  // margin-top:20px;

  > img {
    margin: 0 5px 0 30px;
    align-items: left;
  }
  @media (max-width: 500px) {
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0 0 0;
  }
  @media (max-width: 767px) {
    display: block;
  }
`;
const TimeText = styled(SmallText)`
font-family: 'General Sans';
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
letter-spacing: 1px;
color: #525252;
@media (max-width:500px){
  font-size: 12px;
  line-height: 20px;
   }
@media (max-width: 767px) {
  display: flex;
  justify-content:start;
  }
}
`;

const ContentWrapper = styled.div`
  padding: 5px;
`;
const DataWrapper = styled.div`
  display: flex;
  gap: 30px;
  padding: 5px;
  position: relative;
  bottom: 35px;
  @media (max-width: 500px) {
    bottom: 41px;
    margin: 0 0 -30px 0;
  }
`;
const ViewText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #b3b3b3;
  margin-bottom: -15px;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
  }
  > img {
    margin-right: 5px;
  }
`;
const AppText = styled.div`
  font-family: "General Sans";
  // font-style: italic;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #d04c8d;
  margin-left: 5px;
  margin-bottom: -15px;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 0 -20px;
  }
`;
const P = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  text-align: right;
  color: #212121;
  margin-top: -30px;
  position: relative;
  bottom: 15px;
  text-transform: capitalize;
  @media (max-width: 500px) {
    font-size: 10px;
    line-height: 14px;
    margin: 0 0 -20px 0;
    text-transform: capitalize;
  }
`;

const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;
const ButtonDiv = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    left: 24%;
    margin-top: 60px;
  }
`;
const ActivateWrapper = styled.div`
  display: flex;
  justify-content: end;
  position: relative;
  bottom: 105px;
  @media (max-width: 768px) {
    position: relative;
    bottom: 75px;
    left: 30px;
    font-size: 12px;
    line-height: 16px;
  }
`;
const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));
const FlexWrapper = styled.div`
  display: flex;
`;
const FlexWrappers = styled.div`
  display: flex;
  gap: 10px;
  margin: 15px 0 0 0;
`;
const ImgText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 13px;
  line-height: 18px;
  color: #525252;
  margin: 5px 0 0 0;
`;

const Card1 = styled.div`
  background: #fdfdfd;
  border: 1px solid #d04c8d;
  border-radius: 16px;
  position: relative;
  width: 95%;
`;
const CardTextWrapper = styled.div`
  background: #d04c8d;
  border-radius: 0px 0px 16px 16px;
  padding: 1px;
  height:100px;
`;
const CardText = styled.p`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  // line-height: 4;
  color: #202020;
  text-align: center;
  margin: 20px;
  position: relative;
  word-wrap: break-word;
`;
const JobPage = () => {
  useSnapshot(jobProxy);
  useSnapshot(subsctipationsProxy);
  const [unAuth, setUnAuth] = useState(false);
  const [searchval, setSearchFilter] = useState("");
  const [paginationCount, setPaginationCount] = useState(1);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [loading, setLoading] = useState(true);
  const [paginationCompanies, setPaginationCompanies] = useState([]);
  const companies = useSelector((state) => state.company.companyData);
  const [isSearch, setIsSearch] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [reset, setReset] = useState(0);
  const { t } = useTranslation();
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscriptionPlan = useSelector((state) => state.subscription.subscriptionData);
  const job_duration = subscriptionPlan[org_code]?.job_duration_day;
  const [orgCode, setOrgCode] = useState(undefined);
  const [jobdata, setJobdata] = useState([]);
  const [statusid, setStatusid] = useState("");
  const dispatch = useDispatch();
  const classes = useStyles();
  const navigate = useNavigate();
  function viewapplicationgo(id) {
    jobProxy.setPaginationCount(1);
    jobProxy.setPaginationTabIndex(1);
    jobProxy.setdata([]);
    jobProxy.jobid = id;
    navigate("/jobs/Filterjobs");
  }
  function time_ago(d) {
    let date = new Date();

    var date1 = new Date(d);
    var date2 = new Date(
      date.getFullYear() +
        "-" +
        (parseInt(date.getMonth() + 1) >= 10
          ? parseInt(date.getMonth() + 1)
          : `0${parseInt(date.getMonth() + 1)}`) +
        "-" +
        (date.getDate() >= 10 ? date.getDate() : `0${date.getDate()}`)
    );
    var Difference_In_Time = date2.getTime() - date1.getTime();
    var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    return Difference_In_Days.toFixed(0);
  }

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name == company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }
  const getlist = async (peram) => {
    if (orgCode) {
      let data = await getlistJobs(orgCode, peram);
      if (data.status === 200) {
        let li = []
        for (let i = 0; i < data.data.results.length; i++) {
          let list = data.data.results[i]
          let l = time_ago(list.created_at)
          l = Number(l)
          if (l == 0) {
            list.postago = t("Today")
          } else if (l >= Number(job_duration)) {
            list.postago = t("expired").toUpperCase();
          } else {
            list.postago = l + t(" days ago")
          }
          li.push(list);
          data.data.count = data.data.count - 1
        }
        data.data.results = li;
        let quo = Math.floor(data.data.count / 5);
        let rem = data.data.count % 5;
        setPaginationCount(rem !== 0 ? quo + 1 : quo);
        setJobdata(data.data.results);
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };
  const view = (id) => {
    dispatch(setId(id));
    navigate("/jobs/viewjobs");
  };

  function formatDate(thedate) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var b = thedate.split("-");
    return +b[2] + " " + monthNames[b[1] - 1] + " " + b[0];
  }
  useEffect(() => {
    getlist(`?page=${paginationTabIndex}&page_size=5`);
  }, [orgCode]);
  const fetchData = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
      getlist("", currentPage + 1);
    }
  };
  useEffect(() => {
    getOrgCode();
  }, [company]);

  const getsearch = () => {
    if (searchval && searchval.length > 0) {
      setPaginationTabIndex(1);
      let p = `?search=${searchval}&page=1&page_size=5`;
      getlist(p);
    } else {
      let p = `?page=1&page_size=5`;
      getlist(p);
    }
  };
 
  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    if (e.target.value === "") {
      getsearch();
    }
  };
  const displayPage = (val) => {
    let p = "";
    if (searchval && searchval.length > 0) {
      p = `?search=${searchval}&page=${val}&page_size=5`;
    } else {
      p = `?page=${val}&page_size=5`;
    }
    setPaginationTabIndex(val);
    getlist(p);
  };
  const changestatus = async (l) => {
    if (!statusid || statusid.length == 0) {
      setStatusid(l.id);
      let la = l;
      if (la.activate) {
        if (la.activate == "Y") {
          la.activate = "N";
        } else {
          la.activate = "Y";
        }
      } else {
        la.activate = "Y";
      }
      const { dres } = await updateJobs(la);
    }
  };

  const postedby = (str) => {
    const arr = str.split(" ");
    for (var i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const str2 = arr.join(" ");
    return str2;
  };

  const data = [
    {
      id: "1",
      job_desc: "JD1",
      posted_date: "12 February 2023",
      time: "15 days ago",
      postedBy: "Angie Wong",
    },
    {
      id: "2",
      job_desc: "JD1",
      posted_date: "12 February 2023",
      time: "15 days ago",
      postedBy: "Angie Wong",
    },
    {
      id: "3",
      job_desc: "JD1",
      posted_date: "12 February 2023",
      time: "15 days ago",
      postedBy: "Angie Wong",
    },
  ];
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
      slidesToSlide: 2,
    },
    Largedesktop: {
      breakpoint: { max: 1200, min: 1025 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 800 },
      items: 1,
      slidesToSlide: 1,
    },
    tablet: {
      breakpoint: { max: 800, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <>
      <Carousel
        showDots={true}
        responsive={responsive}
        autoPlay={true}
        arrows={false}
      >
        {jobdata?.map((list, index) => (
          <div style={{ marginBottom: "3rem" }}>
            <Card1>
              <FlexWrapper>
                <img src={company?.logo_path} alt="img" className="imagecarousel" />
                <div>
                  <FlexWrappers>
                    <img
                      src={InternIcon}
                      alt="view"
                      className="swiperIconIntern"
                    ></img>
                    <ImgText>{t(list?.employment_type)}</ImgText>
                  </FlexWrappers>
                  <FlexWrappers>
                    <img
                      src={Salery}
                      alt="view"
                      className="swiperIconIntern"

                    ></img>
                    <ImgText>{`${list?.salary_from} MYR - ${list?.salary_to} MYR`}</ImgText>
                  </FlexWrappers>
                </div>
              </FlexWrapper>

              <CardTextWrapper>
                <CardText>{list?.title}</CardText>
              </CardTextWrapper>
            </Card1>
          </div>
        ))}
      </Carousel>
    </>
  );
};
export default JobPage;
