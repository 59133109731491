import React from "react";
import LoginPage from "./pages/login/loginPage";
import SearchCompany from "./pages/searchcompany/searchcompany";
import RegisterOrganisation from "./pages/registerorganisation/registerOrganisation";
import NewPerson from "./pages/newPerson/newPerson";
import UploadOrganisationLogo from "./pages/uploadOrganisationLogo/uploadOrganisationLogo";
import Dashboard from "./pages/dashboard/dashboard";
import Forget from "./pages/forget/forget";
import Reset from "./pages/resetverify/reset";
import Confirm from "./pages/confirmpass/confirm";
import AuthRoute from "./utilities/authRoute";
import NewIntern from "./pages/newIntern/newIntern";
import AddNewInternship from "./pages/MySIPDirectHiring/addNewInternship";
import Settings from "./pages/settings/settings";
import MyCompany from "./pages/myCompany/myCompany";
import Internships from "./pages/internships/internships";
import ViewDetails from "./pages/viewDetails";
import Application from "./pages/applicants";
import SignUpPage from "./pages/signUp/signUp";
import MyCompanyCard from "./components/myCompanyCard/myCompanyCard";
import SubscriptionsPayment from "./pages/Subscriptions/SubscriptionsPayment";
import SubscriptionsRenew from "./pages/Subscriptions/SubscriptionsRenew";

import UserManagement from "./pages/userManagement/userManagement";
import UserRegistration from "./components/addNewUser/userRegistration";
import EditUser from "./components/addNewUser/EditUser";

import SubscriptionsPageTwo from "./pages/Subscriptions/SubscriptionsPageTwo";
import SubscriptionsPageThree from "./pages/Subscriptions/SubscriptionsPageThree";
import SubscriptionMainPage from "./pages/Subscriptions/SubscriptionMainPage";
import EmployeeManagement from "./pages/employeeManagement/employeeManagement";
import JobPage from "./pages/jobPortal/jobPortalLanPage";
import ViewJobs from "./pages/jobPortal/viewJobs";

import AddNewJob from "./pages/jobPortal/addNewJob";
import EditJob from "./pages/jobPortal/editJob";
import FilterJobs from "./pages/jobPortal/filterJobs";
import CompanyBranding from "./pages/companyBranding/companyBranding";
import TalentSearch from "./pages/TalentSearch/talentSearchFilter";
import ProfileSelection from "./pages/TalentSearch/talentProfileSelection";

import CompanyBrandingEdit from "./pages/companyBranding/companyBrandingEdit";
import ApplicantsComparision from "./pages/applicantsComparision/applicantsComparision";
import TalentProfile from "./pages/talentProfile/talentProfile";
import MyCalendar from "./pages/myCalendar/myCalendar";
import TrackApplications from "./pages/trackApplications/trackApplications";
import CoachingDashboard from "./pages/coachingDashboard/coachingDashboard";
import CreatePasswordPage from "./pages/createPasswordPage/createPasswordPage";

import ViewProfileJob from "./pages/jobPortal/viewProfile";
import ViewStudentJob from "./pages/jobPortal/viewStudentProfile";
import SelectInternshipType from "./pages/MySIPDirectHiring/selectInternshipType";

import TalentShortlist from "./pages/TalentSearch/talentShortlistFilter";
import ViewDocument from "./pages/jobPortal/viewDocument";
import TalentProfileSearch from "./pages/jobPortal/talentProfile";
import AnalyticalMainDashboard from "./pages/applicantAssessments/analyticalMainDashboard";
import InternshipModule from "./pages/MySIPDirectHiring/internshipModuleForm";
import ViewBulkInternDetail from "./pages/MySIPDirectHiring/viewBulkInternDetail";
import CompletionOfInternshipform from "./pages/MySIPDirectHiring/completionOfInternshipform";
import EligibilityCheck from "./pages/MySIPDirectHiring/eligibilityCheck";
import SubscripeRoute from "./utilities/subscriptedRoute";
import ViewInternship from "./pages/LoeApplication/viewInternship";
import { element } from "prop-types";
import LOEApplicationLanding from "./pages/MySIPDirectHiring/loeLandingScreen";
import CompletionOfInternshipOverview from "./pages/LoeApplication/completionOfInternshipOverview";
import UpdateCompletionOfInternshipLoe from "./pages/LoeApplication/updateCompletionOfInternshipLoe";
import EligibilityCheckInternship from "./pages/MySIPDirectHiring/eligibilityCheckInternship";
import RestrictedRoute from "./utilities/restirctedRoute";
import CompanyRegisteredRoute from "./utilities/companyRegisterRoute";
import AnnualSurveyScreen from "./pages/annualSurvey/annualSurvey";
import SkillGapEvaluationForm from "./pages/skillGapEvaluation/skillGapEvaluationForm";
import SkillGapLandingPage from "./pages/skillGapEvaluation/skillGapLandingPage";
import ViewInternshipModule from "./pages/MySIPDirectHiring/viewInternshipModule";
import LOEDataMigrationLanding from "./pages/MySIPDirectHiring/loeDataMigration/loeDataMigrationlandingPage";
import SelectInternsPage from "./pages/MySIPDirectHiring/loeDataMigration/selectInternsPage";
import ViewInternshipModuleDetails from "./pages/MySIPDirectHiring/ViewInternshipModuleDetails";

export const routes = [
  {
    path: "/",
    element:<CompanyRegisteredRoute><LoginPage /></CompanyRegisteredRoute> ,
  },
  {
    path: "/login",
    element: 
    <CompanyRegisteredRoute>
      <LoginPage />
      </CompanyRegisteredRoute> ,
  },
  {
    path: "/signup",
    element: <CompanyRegisteredRoute><SignUpPage /></CompanyRegisteredRoute> ,
  },
  {
    path: "/register",
    element:<CompanyRegisteredRoute><RegisterOrganisation /></CompanyRegisteredRoute> ,
  }, 
  {
    path: "/my-company-register",
    element:<AuthRoute><RegisterOrganisation /></AuthRoute> ,
  }, 
  {
    path: "/new-person",
    element:<NewPerson />,
  },
  {
    path: "/search",
    element: <SearchCompany /> ,
  },
  {
    path: "/upload",
    element:<UploadOrganisationLogo />,
  },
  {
    path: "/my-company/details",
    element: <SubscripeRoute>
      <MyCompanyCard />
    </SubscripeRoute>,
  },
  {
    path: "/dashboard",
    element: (
      <AuthRoute>
        <Dashboard />
      </AuthRoute>
    ),
  },
  {
    path: "/forget-password",
    element: <Forget />,
  },
  {
    path: "/reset-password",
    element: <Reset />,
  },
  {
    path: "/change-password",
    element: <Confirm />,
  },
  {
    path: "/annual-survey",
    element: <AuthRoute>
<AnnualSurveyScreen />
    </AuthRoute> ,
  },
  {
    path: "/skill-gap-evaluation/:id",
    element: <AuthRoute>
 <SkillGapLandingPage />
    </AuthRoute>,
  },
  {
    path: "/internships/new-intern",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <NewIntern />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/internship/create-internship/new-internship",
    element: (
      <AuthRoute>
        <AddNewInternship />
        {/* <ViewInternship/> */}
      </AuthRoute>
    ),
  },
  {
    path: "/loe-application/view-internship",
    element:(
      <AuthRoute>
        <ViewInternship/>
      </AuthRoute>
    )
  },
  {
    path: "/internship/create-internship/completion-of-internship",
    element: (
      <AuthRoute>
        <CompletionOfInternshipform />
      </AuthRoute>
    ),
  },
  {
    path: "/internship/create-internship/new-internship",
    element: (
      <AuthRoute>
        <AddNewInternship />
      </AuthRoute>
    ),
  },
  {
    path: "/internship/create-internship/completion-of-internship",
    element: (
      <AuthRoute>
        <CompletionOfInternshipform />
      </AuthRoute>
    ),
  },
  {
    path: "/internships/details",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <ViewDetails />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/my-profile",
    element: (
      <AuthRoute>
        <Settings />
      </AuthRoute>
    ),
  },
  {
    path: "/my-company",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <MyCompany />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/internships/:dashboardStatus",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <Internships />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/internships",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <Internships />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  // {
  //   path: "/psychometric-analytics",
  //   element: (
  //     <AuthRoute>
  //       <AnalyticalDashboard />
  //     </AuthRoute>
  //   ),
  // },
  {
    path: "/psychometric-analytics/*",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <AnalyticalMainDashboard />

        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/psychometric-analytics",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <AnalyticalMainDashboard />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/internships/applicants",
    element: (
      <AuthRoute>
        <SubscripeRoute>
          <Application />
        </SubscripeRoute>
      </AuthRoute>
    ),
  },
  {
    path: "/user-management",
    element: <SubscripeRoute>
      <UserManagement />,

    </SubscripeRoute>
  },
  {
    path: "/my-company/add-user",
    element:
      <SubscripeRoute>
        <UserRegistration />,

      </SubscripeRoute>
  },
  {
    path: "/my-company/edit-user",
    element: <SubscripeRoute>
      <EditUser />
    </SubscripeRoute>,
  },
  {
    path: "/subscriptions",
    element: <SubscripeRoute>
      <SubscriptionMainPage />
    </SubscripeRoute>,
  },
  {
    path: "/subscriptions/subscriptions2",
    element: <SubscripeRoute>
      <SubscriptionsPageTwo />
    </SubscripeRoute>,
  },
  {
    path: "/subscriptions/subscription-plan",
    element: <SubscripeRoute>
      <SubscriptionsPageThree />
    </SubscripeRoute>,
  },
  {
    path: "/subscriptions/subscriptions-payment",
    element: <SubscripeRoute>
      <SubscriptionsPayment />
    </SubscripeRoute>,
  },
  {
    path: "/subscriptions/subscriptions-renew",
    element: <SubscripeRoute>
      <SubscriptionsRenew />
    </SubscripeRoute>,
  },
  {
    path: "/employeemanagement",
    element: <SubscripeRoute>
      <EmployeeManagement />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/addNewJob",
    element: <SubscripeRoute>
      <AddNewJob />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/editJob",
    element: <SubscripeRoute>
      <EditJob />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/Filterjobs",
    element: <SubscripeRoute>
      <FilterJobs />
    </SubscripeRoute>,
  },
  {
    path: "/jobs",
    element: <SubscripeRoute>
      <JobPage />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/talentProfile",
    element: <SubscripeRoute>
      <TalentProfile />
    </SubscripeRoute>,
  },
  {
    path: "/talentsearch/talentProfile",
    element: <SubscripeRoute>
      <TalentProfileSearch />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/view-profile",
    element: <SubscripeRoute>
      <ViewProfileJob />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/view-document",
    element: <SubscripeRoute>
      <ViewDocument />
    </SubscripeRoute>,
  },
  {
    path: "/jobs/viewjobs",
    element: <SubscripeRoute>
      <ViewJobs />
    </SubscripeRoute>,
  },
  {
    path: "/companybranding",
    element: <SubscripeRoute>
      <CompanyBranding />
    </SubscripeRoute>,
  },
  {
    path: "/companybrandingedit",
    element: <SubscripeRoute>
      <CompanyBrandingEdit />
    </SubscripeRoute>,
  },
  {
    path: "/talentsearch/applicants-comparision",
    element: <SubscripeRoute>
      <ApplicantsComparision />
    </SubscripeRoute>,
  },
  {
    path: "talent/profile",
    element: <SubscripeRoute>
      <TalentProfile />
    </SubscripeRoute>,
  },
  {
    path: "/talentsearch",
    element: <SubscripeRoute>
      <TalentSearch />
    </SubscripeRoute>
  },
  {
    path: "/talentsearch/profileSelection",
    element: <SubscripeRoute><ProfileSelection /></SubscripeRoute>,
  },

  {
    path: "/talentsearch/shortlist",
    element: <SubscripeRoute>
      <TalentShortlist />
    </SubscripeRoute>,
  },

  {
    path: "/my-calendar",
    element:
      <SubscripeRoute>
        <MyCalendar />
      </SubscripeRoute>,
  },
  {
    path: "/track-applications",
    element: <SubscripeRoute>
      <TrackApplications />
    </SubscripeRoute>,
  },
  {
    path: "/coaching-dashboard",
    element: <SubscripeRoute>
      <CoachingDashboard />
    </SubscripeRoute>,
  },

  {
    path: "/create-password",
    element: <CreatePasswordPage />,
  },
  {
    path: "/employeeManagement/student/:id",
    element: <ViewStudentJob />,
  },
  {
    path: "/internships/details/viewAssessment/:id",
    element: <ViewStudentJob />,
  },
  {
    path: "/internships/createinternship/selectinternshiptype",
    element: <SelectInternshipType />,
  },
  {
    path: "/loe/internship-module-eligibility-check",
    element: <EligibilityCheck/>,
  },
  {
    path: "/internships/internship-module-eligibility-check",
    element: <EligibilityCheckInternship/>,
  },
  {
    path: "/internships/internship-module",
    element: <InternshipModule/>
  },
  {
    path: "/internships/upload-intern-details",
    element: <ViewBulkInternDetail />,
  },
  {
    path: "/loe-application/view-internship/completion-of-internship-overview",
    element: <CompletionOfInternshipOverview />,
  },
  {
    path: "/loe-application/view-internship/completion-of-internship-Loe",
    element: <UpdateCompletionOfInternshipLoe />,
  },
  {
    path: "/loe-application",
    element: <LOEApplicationLanding/>
  }, {
    path: "/loe-application/loe-internship-module",
    element: <LOEDataMigrationLanding/>
  },
  {
    path: "/loe-application/select-interns",
    element: <SelectInternsPage/>
  },
  {
    path: "/view-internship-module",
    element: <ViewInternshipModule/>
  },
  {
    path: "/dashboard/view-internship-module-details",
    element: <ViewInternshipModuleDetails/>
  }
];
