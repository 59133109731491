// import { getWorkValuesDrilldown } from "../../../../../services/CommonApi";

import Ad2DrilldownHOC from "../../../pages/applicantAssessments/analyticalShared/analyticalDrilldownHOC";

// import Ad2DrilldownHOC from "../../../ad2-shared/ad2DrilldownHOC/ad2DrilldownHOC";


export const getWorkValuesDrilldown = () => {
    var personlityData = {
        "status": 1,
        "data": {
            "facet": [],
            "request": {
                "insti_name": "141",
                "university": "141"
            },
            "results": {
                "Mastery": {
                    "id": 1,
                    "totalTalents": 695,
                    "results": {
                        "low": 9,
                        "moderate": 642,
                        "high": 37
                    }
                },
                "Quality of Life": {
                    "id": 2,
                    "totalTalents": 695,
                    "results": {
                        "low": 2,
                        "moderate": 661,
                        "high": 25
                    }
                },
                "Fellowship": {
                    "id": 3,
                    "totalTalents": 695,
                    "results": {
                        "low": 2,
                        "moderate": 659,
                        "high": 27
                    }
                },
                "Creating Value": {
                    "id": 4,
                    "totalTalents": 695,
                    "results": {
                        "low": 9,
                        "moderate": 642,
                        "high": 37
                    }
                }
            }
        }
    }
  return personlityData;
  }

export default Ad2DrilldownHOC(getWorkValuesDrilldown, [
    {
        title: "Mastery",
        dataKey: "Mastery",
        url: 'mastery',
        showDrilldown: true,
        info:"The Mastery domain contains the values Intellectual stimulation, Recognition, Achievement, Independence. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values."
    },
    {
        title: "Quality of Life",
        dataKey: "Quality of Life",
        url: 'quality-of-life',
        showDrilldown: true,
        info:"The Quality of Life domain contains the values Variety, Security, Way of Life, Surroundings, and Economic Return. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values."
    },
    {
        title: "Fellowship",
        dataKey: "Fellowship",
        url: 'fellowship',
        showDrilldown: true,
        info:"The Fellowship domain contains the values Altruism, Supervisory Relationship, Associates, Belonging, and Family. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values."
    },
    {
        title: "Creating Value",
        dataKey: "Creating Value",
        url: 'creating-value',
        showDrilldown: true,
        info:"The Creating Value domain contains the values Aesthetic, Creativity, Leadership, and Protecting the Planet. Scoring high, moderate or low for this domain will depend on how the talents have scored for these values."
    },
], '/psychometric-analytics/work-values/');
