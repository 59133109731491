import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { Row, Col } from "react-bootstrap";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../button/button";
import Divider from "../divider/divider";
import PDF from "../../assets/icons/svg/pdf.svg";
import {
  filePathToFileName,
  downloadPdf,
} from "../../utilities/commonFunctions";
import moment from "moment";
import TablePagination from '@mui/material/TablePagination';
import { useTranslation } from "react-i18next";

const approvedStatus = ['accepted', 'expired', 'activate', 'deactivate'];

const AcadamicWrapper = styled.div`
  font-family: General Sans;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  > h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${Colors.labelText};
  }
  p {
    margin: 0;
    margin-left: 5px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;

const AcadamicWrapperTwo = styled.div`
  font-family: General Sans;
  display:flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 5px;
  > h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0;
    color: ${Colors.labelText};
  }
  p {
    margin: 0;
    margin-left: 5px;
    font-weight: normal !important;
    font-size: 16px;
    line-height: 22px;
    color: ${Colors.labelText};
  }
`;
const RowNew = styled(Row)`
  margin-left:0px;
`;
const InternTitle = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 10px;
`;
const InternDescription = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: normal !important;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 3em;
  color: #202020;
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  > h4 {
    font-style: italic;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #b3b3b3;
  }
`;
const ListsWrapper = styled.ul`
  font-family: General Sans;
  margin-bottom: 3em;
  > li {
    padding: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #202020;
    margin-bottom: 10px;
  }
`;

const AttachedDocument = styled.div`
  font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  text-decoration-line: underline;
  color: #d04c8d;
`;

const IconSingleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;

const OtherText = styled.p`
  margin: 0 0 0 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;
const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
const blue = {
  200: "#A5D8FF",
  400: "#3399FF",
};
const CustomTablePagination = styled(TablePagination)(
  ({ theme }) => `
  & .MuiTablePaginationUnstyled-spacer {
    display: none;
  }
  & .MuiTablePaginationUnstyled-toolbar {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;

    @media (min-width: 768px) {
      flex-direction: row;
      align-items: center;
    }
  }
  & .MuiTablePaginationUnstyled-selectLabel {
    margin: 0;
  }
  & .MuiTablePaginationUnstyled-select {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  & .MuiTablePaginationUnstyled-displayedRows {
    margin: 0;

    @media (min-width: 768px) {
      margin-left: auto;
    }
  }
  & .MuiTablePaginationUnstyled-actions {
    padding: 2px;
    border: 1px solid ${grey[200]};
    border-radius: 50px;
    text-align: center;
  }
  & .MuiTablePaginationUnstyled-actions > button {
    margin: 0 8px;
    border: transparent;
    border-radius: 2px;
    background-color: transparent;
    &:hover {
      background-color: ${grey[50]};
    }
    &:focus {
      outline: 1px solid ${blue[200]};
    }
  }
  `
);
const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);
export default function InternShipDetails({
  data,
  setShowApplicants,
  noOfApplicants,
}) {
  const { t } = useTranslation();
  const [page,] = React.useState(0);
  const [rowsPerPage,] = React.useState(5);
  const [theArray,] = React.useState(data.review_comments);
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray?.length) : 0;
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);


  return (
    <>
      <ButtonWrapper>
        {data && data?.status === "accepted" ? (
          <>
            <Button name={t("View Applicants")} onClick={setShowApplicants} />
            <h4>
              {" "}
              {noOfApplicants > 0
                ? noOfApplicants + t(" Applications")
                : t("No Applications")}
            </h4>
          </>
        ) : ("")
        }
        <row>
          {data && data?.intern_type === "sip" && data?.sip_framework_url ? (<a href={data?.sip_framework_url} target="_blank" rel="noreferrer" style={{ marginRight: '1rem' }} ><Button name={t("View MySIP Framework")} /></a>) : ""}
          {data && data?.intern_type === "sip" && approvedStatus.includes(data?.status) && data?.sip_application_url ? (<a href={data?.sip_application_url} target="_blank" rel="noreferrer"><Button name={t("View LOE Application")} /></a>) : ""}
        </row>
        {data && data?.intern_type === "sip" && data?.status !== "rejected" && data?.sip_enrollment_url ? (<a href={process.env.REACT_APP_ENV_TYPE == "r1" ? data?.sip_enrollment_url.split("dev")[0] + "r1" + data?.sip_enrollment_url.split("dev")[1] + "&internship_id=" + data.id + '&token=' + localStorage.getItem("auth_token") + "&org_code=" + org_code : data?.sip_enrollment_url + "&internship_id=" + data.id + '&token=' + localStorage.getItem("auth_token") + "&org_code=" + org_code} target="_blank" rel="noreferrer"><Button name={t("Complete MySIP Enrollment")} /></a>) : ""}
      </ButtonWrapper>
      <Divider bg="#202020" />
      <InternTitle>{t('Internship Description')}</InternTitle>
      <InternDescription
        dangerouslySetInnerHTML={{ __html: data?.description }}
      />
      <InternTitle>{t('Academic Requirements')}</InternTitle>
      <RowNew>
        <Col lg="3" xs="12" className="p-0">
          <AcadamicWrapperTwo>
            <h5> {t('Scope of Study')}: </h5>
          </AcadamicWrapperTwo>
        </Col>
        <Col lg="8" cs="12" className="p-0">
          {data &&
            data?.scopes?.map((x, index) => (
              <AcadamicWrapperTwo>
                <p key={index + x.scope}>
                  {x.scope_abbr}
                  {index === data.scopes.length - 1 ? "" : ","}
                </p>
              </AcadamicWrapperTwo>
            ))}
        </Col>
      </RowNew>
      <RowNew>
        <Col lg="3" xs="12" className="p-0">
          <AcadamicWrapper>
            <h5> {t('Minimum required CGPA')}: </h5>
          </AcadamicWrapper>
        </Col>
        <Col lg="8" cs="12" className="p-0">
          <AcadamicWrapper>
            <p> {data && data.req_gpa} </p>
          </AcadamicWrapper>
        </Col>
      </RowNew>

      <RowNew>
        <Col lg="3" xs="12" className="p-0">
          <AcadamicWrapper>
            <h5> {t('Minimum education level required')}: </h5>
          </AcadamicWrapper>
        </Col>
        <Col lg="8" cs="12" className="p-0">
          <AcadamicWrapper>
            <p> {data && t(data.min_degree)} </p>
          </AcadamicWrapper>

        </Col>
      </RowNew>
      <RowNew>
        <Col lg="3" xs="12" className="p-0">
          <AcadamicWrapper>
            <h5> {t('Minimum year of study')}: </h5>
          </AcadamicWrapper>
        </Col>
        <Col lg="8" cs="12" className="p-0">
          <AcadamicWrapper style={{ marginLeft: "5px" }}>
            {data && t(data.grade)}
          </AcadamicWrapper>

        </Col>
      </RowNew>
      <RowNew>
        <Col lg="3" xs="12" className="p-0">
          <AcadamicWrapper>
            <h5> {t('Skills')} :</h5>
          </AcadamicWrapper>
        </Col>
        <Col lg="8" xs="12" className="p-0">
          <>
            {data &&
              data?.skills?.map((x, index) => (
                <div key={index + x.scope}>
                  <AcadamicWrapper>
                    <p>
                      {x.skill}
                      {index === data?.skills?.length - 1 ? "" : ","}
                    </p>
                  </AcadamicWrapper>
                </div>
              ))}
          </>
        </Col>
      </RowNew>
      <InternTitle>{t('Required Documents')}</InternTitle>
      <ListsWrapper>
        {data &&
          data?.documents?.map((x, index) => x?.doc_type !== 'All' ? (

            <li key={index + x.doc_type}>{t(x.doc_type)}</li>

          ) : '')}
      </ListsWrapper>
      <InternTitle>{t('Optional Documents')}</InternTitle>
      <InternDescription>{data && data.optional_documents ? data.optional_documents : "N/A"}</InternDescription>
      <InternTitle>{t('Location')}</InternTitle>
      {
        data &&
        data?.locations?.map((x, index) => (
          <InternDescription key={x.state + index}>
            {x.city}, {x.state}, {x.country}
          </InternDescription>
        ))
      }
      <InternTitle>{t('Attached Documents')}</InternTitle>
      <InternDescription>
        {data &&
          data?.file_paths?.map((file, index) => (
            <IconSingleSection key={file.id + index}>
              <img src={PDF} alt="PDF" />
              <OtherText>
                <AttachedDocument
                  onClick={() => downloadPdf(file.file_path)}
                  className="pointer-link"
                >
                  {filePathToFileName(file?.file_path)}
                </AttachedDocument>
              </OtherText>
            </IconSingleSection>
          ))}
      </InternDescription>
      <InternTitle style={{ "marginLeft": "-3px" }}>{t('Reviews')}</InternTitle>
      <div
        className="container"
        style={{ "marginLeft": "-16px", "marginBottom": "20px" }}
      >
        <Root sx={{ width: 200, maxWidth: "50%" }}>
          {theArray?.length > 0 ? (
            <table aria-label="custom pagination table">
              <thead>
                <tr>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t('Comments')}
                  </th>
                  <th
                    style={{
                      background: "rgb(250, 74, 161)",
                      color: "#fff",
                    }}
                  >
                    {t('Created')}
                  </th>
                </tr>
              </thead>
              <tbody>
                {theArray?.length > 0
                  ? (rowsPerPage > 0 && theArray?.length
                    ? theArray.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    : theArray
                  ).map((row) => (
                    <tr key={row.comment}>
                      <td>{row.comment}</td>
                      <td style={{ width: 200 }} align="right">
                        {moment(new Date(row.created_at)).format(
                          "DD/MM/YYYY hh:mm:ssA"
                        )}
                      </td>
                    </tr>
                  ))
                  : ""}
                {emptyRows > 0 && (
                  <tr style={{ height: 41 * emptyRows }}>
                    <td colSpan={3} />
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr>
                  <CustomTablePagination
                    rowsPerPageOptions={[
                      5,
                      10,
                      25,
                      { label: t("All"), value: -1 },
                    ]}
                    colSpan={3}
                    count={theArray?.length ?? "0"}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    componentsProps={{
                      select: {
                        "aria-label": "rows per page",
                      },
                      actions: {
                        showFirstButton: true,
                        showLastButton: true,
                      },
                    }}
                  />
                </tr>
              </tfoot>
            </table>
          ) : (
            <div className="container col-lg-12">{t('No comment provided.')}</div>
          )}
        </Root>
      </div>
      {
        data.visibility_status === 0 ? (
          ""
        ) : (
          <>
            <InternTitle>{t('Contact')}</InternTitle>
            <InternDescription>
              {data && data.contact_name} <br />
              {data && data.contact_email} <br />
              {data && data.contact_mob}
            </InternDescription>
          </>
        )
      }
    </>
  );
}
