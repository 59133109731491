import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";



function AlertModal(props) {
  return (
    <div>
      <Modal className="add-student-modal" size="lg" show={props.show} backdrop="static" onHide={props.close} centered>
               
          <button type="button" class="close" data-dismiss="modal" aria-label="Close"  onClick={props.close} style={{

            position: "absolute",
            top: "15%",
            left: "95%",
            border: "none",
            fontSize: "25px",

          }}>
          <span aria-hidden="true" style={{marginRight: "-20px",position: "relative",right: "20px",bottom:"20px"}}>&times;</span>
        </button>
       
        <div style={{ padding: '9px' }}>
        
        <ModalHeader></ModalHeader>
          <ModalHeaderText>{props.title}</ModalHeaderText>
          <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
          {props.content}
        </div>
      </Modal>
    </div>
  );
}

export default AlertModal;
