import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";

export const SubHeading = styled.div`
  font-family: "General sans";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #d04c8d;
  display: flex;
  text-transform: capitalize;
  height: 40px;
  align-items: center;
`;

export const HeadingCard = styled.div`
  //   padding:36px 8px;
  margin-right: 10px;
`;

export const ContentCard = styled.div`
  background: ${(props) => (props.noBg ? "none" : "#FDFDFD")};
  border: ${(props) =>
    props.noBg ? "none" : "1px solid rgba(228, 228, 228, 0.26)"};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  min-width: 335px;
  // overflow-x:scroll;
  padding: 15px 20px 15px 35px;
  // margin-bottom:20px;
  // @media (max-width: 800px) {
  //   min-width:100px;
  // }
`;

export const ContentHeading = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 18.1935px;
  line-height: 115.3%;
  text-align: center;
  letter-spacing: 0.01em;
  min-height: 21px;
  text-transform: capitalize;

  color: #434343;
  min-height: 48px;
  margin-bottom: 6px;
`;

export const Range = styled.div`
  display: flex;
  //justify-content:space-around;
`;

export const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding: 8px 20px 8px 20px;
  margin: auto;
  position: relative;
  // justify-content: space-around;
  width: 100%;
  @media (max-width: 500px) {
    // display:block;
    flex-direction: row;
    position: relative;
    gap: 10px;
  }
`;
export const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;
export const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
  margin-top: 20px;
  margin-bottom: 20px;
`;
export const HrLine = styled.div`
  width: 100%;
  // position: relative;
  margin: 15px 15px 15px 25px;
  height: 10px;
  border-bottom: 0.6px solid #c4c4c4;
`;
export const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
export const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;
export const ColorBar = styled.div`
  //  width:90px;
  height: 8px;
  background: ${(props) => (props.bg ? props.bg : "#f9fcfc")};
  border-radius: 6px;
  margin-right: 8px;
  border: 1px solid #d6d6d6;
`;
export const SectionHeading = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-transform: capitalize;
  color: #202020;
  width: 300px;
  text-align: center;
  z-index: 100;
  background-color: #ffffff;
  position: relative;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 50px;
`;

export const SectionWrapper = styled.div`
  //padding:24px 18px;
  @media (max-width: 1440px) {
    overflow: scroll;
  }
`;

export const NormalText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */
  color: #202020;
  margin-top: 15px;
  text-transform: capitalize;
`;

export const SpanText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */
  color: #adadad;
  text-transform: capitalize;
`;

export const Wrapper = styled.div`
  display: block;
  @media (max-width: 800px) {
    width: 100%;
    //overflow-x:scroll;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 10px;
`;
