import styled from "styled-components";
import DashboardCards from "../dashboardCards/dashboardCards";

const GridWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 15px;
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
    gap: 10px;
  }
`;
const arr = [
  {title: "Applications", label: "applications", datatip: 'applications'},
  { title: "Accepted", label: "accepted", datatip: 'accepted'},
  { title: "Pending Verification", label: "pending", datatip: 'pending verification'},
  { title: "Offered", label: "offered", datatip: 'offered'},
  
  
];

export default function DashboardCardsWrapper({ data }) {
  // arr = data && Object.keys(data);
  return (
    <GridWrapper>
      {arr.map((obj, index) => (
        <DashboardCards name={obj.title} value={data[obj.label]} key={obj.title + index} datatip={obj.datatip}/>
      ))}
    </GridWrapper>
  );
}
