import React from "react";
import { View, Text } from "@react-pdf/renderer";
import { useTranslation } from "react-i18next";

export const Slider = ({ value, color }) => {
  return (
    <View
      style={{
        height: "auto",
        alignItems: "center",
        flexDirection: "row",
        paddingHorizontal: "30px",
      }}
    >
      <Text
        style={{
          fontFamily: "General Sans",
          fontSize: 10,
          fontWeight: 700,
          marginRight: "10px",
        }}
      >
        0
      </Text>
      <View
        style={{
          flexGrow: 1,
          height: "2px",
          backgroundColor: "lightgray",
        }}
      >
        <View
          style={{
            width: `${value - 3}%`,
            height: "100%",
            backgroundColor: `${color}`,
          }}
        />
        <View
          style={{
            left: `${value - 3}%`,
            alignItems: "center",
            justifyContent: "center",
            width: "20px",
            height: "20px",
            borderRadius: "50%",
            borderWidth: 3,
            borderColor: `${color}`,
            position: "absolute",
            backgroundColor: `${color}`,
            fontSize: 8,
            top: -8,
          }}
        >
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 10,
              fontWeight: 700,
              marginTop: 0,
              color: "#ffffff",
            }}
          >
            {value}
          </Text>
        </View>
      </View>
      <Text
        style={{
          fontFamily: "General Sans",
          fontSize: 10,
          fontWeight: 700,
          marginLeft: "10px",
        }}
      >
        100
      </Text>
    </View>
  );
};

export const MasonaryLayout = ({ data }) => {
  const { t } = useTranslation();

  return (
    <View
      style={{
        display: "flex",
        flexDirection: "column",
        flexWrap: "wrap",
        width: "100%",
        flexContent: "center",
      }}
    >
      {data.map((item, i) => (
        <View
          key={i}
          style={{
            width: "100%",
          }}
        >
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 14,
              fontWeight: 200,
              marginTop: 0,
            }}
          >
            {i + 1 + ". " + t(item.title)}
          </Text>
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 12,
              fontWeight: 200,
              textAlign: "justify",
              color: "#213343",
              lineHeight: 1.5,
              marginTop: 4,
            }}
          >
            {t(item.description)}
          </Text>

          {item.value !== undefined && item.color !== undefined ? (
            <View style={{ marginVertical: 20 }}>
              <Slider value={item.value} color={item.color} />
            </View>
          ) : (
            <></>
          )}
        </View>
      ))}
    </View>
  );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ data }) => {
  const { t } = useTranslation();

  const workCompetencyConstants = [
    {
      key: 0,
      title: "Embracing & Managing",
      description:
        "Adjusts well to uncertainties. Skillfully handles pressure and difficulties.",
    },
    {
      key: 1,
      title: "Connecting & Portraying",
      description:
        "Communicates and networks effectively. Successfully persuades and influences others. Relates to others in a confident, relaxed manner.",
    },
    {
      key: 2,
      title: "Supervising & Determining",
      description:
        "Takes charge and assumes authority. Takes the initiative, provides guidance, and accepts accountability.",
    },
    {
      key: 3,
      title: "Ambitious & Result-Oriented",
      description:
        "Committed to setting personal goals and highly motivated to achieving results. Stimulated by accomplishments and the recognition of effort. Demonstrates knowledge of commerce, business and economic affairs. Solicits possibilities for professional and personal growth.",
    },
    {
      key: 4,
      title: "Innovating & Envisioning",
      description:
        "Works well in circumstances where being receptive to diverse perspectives is necessary. Pursues self improvement by seeking new knowledge. Uses imagination and ingenuity to overcome difficulties and issues. Regards situations comprehensively and systematically. Encourages positive organizational transformation.",
    },
    {
      key: 5,
      title: "Evaluating & Resolving",
      description:
        "Demonstrates evidence of analytical clarity. Competent at reaching the core of difficult challenges. Effectively uses own expertise. Adopts new technology quickly. Writes clearly and effectively.",
    },
    {
      key: 6,
      title: "Facilitating & Collaborating",
      description:
        "Supports others and demonstrates respect and favorable regard for them. Focuses on the needs of others and works well with various parties. Possesses reliable characteristics that are compatible with those of the organization.",
    },
    {
      key: 7,
      title: "Coordinating & Implementing",
      description:
        "Prepares for work in advance and organizes work systematically. Executes work by adhering to instructions. Emphasizes the fulfillment of client needs and provides service or product according to established guidelines.",
    },
  ];

  const personalityAspectsConstants = [
    {
      key: 0,
      title: "Withdrawal",
      description:
        "Individuals who score high in withdrawal tend to be more resilient and cope better with stress. They are less likely to feel discouraged, overwhelmed, or excessively self-conscious. They are also less prone to depression and anxiety. On the other hand, individuals who score low in withdrawal may have difficulties dealing with social situations or changes in their environment, and may need extra support in building their confidence and resilience.",
    },
    {
      key: 1,
      title: "Volatility",
      description:
        "Individual's tendency to experience unpredictable and intense emotions. Individuals who score high in Volatility tend to have a stable and consistent emotional state and can regulate their emotions effectively. On the other hand, individuals who score low in Volatility tend to be more emotionally reactive, experiencing frequent mood swings, and having difficulties with impulse control. They are also prone to irritability, anxiety, and anger outbursts. It is important to note that some level of emotional volatility is a normal part of the human experience, and only extreme levels may cause significant distress and interfere with daily functioning.",
    },
    {
      key: 2,
      title: "Enthusiasm",
      description:
        "Enthusiasm refers to an individual's disposition towards expressing positive emotions such as joy, excitement, and happiness. People who score high in enthusiasm are typically outgoing, sociable, and energetic. They are often seen as lively and fun-loving, and they enjoy being around other people. They tend to have a positive outlook on life and are not afraid to show their emotions. Conversely, those who score low in enthusiasm may be more reserved and introverted and may not express their emotions as openly. However, it is important to note that excessive focus on enthusiasm and excitement can lead to impulsivity and a lack of consideration for consequences.",
    },
    {
      key: 3,
      title: "Assertiveness",
      description:
        "Assertiveness is a personality trait that describes individuals who are confident, self-assured, and motivated to achieve social power and status. They are not afraid to take charge of situations and often take a leadership role in group settings. High scorers on assertiveness tend to be provocative and speak their minds, even in challenging situations. However, it's important to note that assertiveness is not the same as aggression. Individuals with high assertiveness can still be respectful of others and work collaboratively towards common goals. Low scorers on assertiveness may be more reserved and avoid confrontation or leadership roles.",
    },
    {
      key: 4,
      title: "Aesthetics Openness",
      description:
        "Aesthetic openness is an aspect of the broader trait of openness to experience. Individuals who score high in this aspect tend to have a strong appreciation for beauty in various forms, such as art, music, nature, and literature. They have a rich inner world and are often drawn to activities that allow them to express themselves creatively, such as writing, painting, or playing an instrument. These individuals may also have a tendency towards daydreaming and fantasizing, and they are often able to immerse themselves deeply in their own thoughts and emotions. Overall, high scorers on this scale value creativity, imagination, and self-expression. On the other hand, those who score low in Aesthetic Openness may have a more limited interest in art and may prefer more familiar or traditional forms of expression.",
    },
    {
      key: 5,
      title: "Intellect",
      description:
        "Individuals who score high in the aspect of Intellect are characterized by their intellectual curiosity and love of exploring complex and challenging ideas. They enjoy engaging in deep and analytical thinking and are often reflective about their experiences. They are open to new perspectives and are curious about the world around them, and often seek out opportunities for learning and growth. On the other hand, individuals who score low in Intellect may be more practical and prefer to focus on concrete, tangible matters rather than abstract ideas and theories.",
    },
    {
      key: 6,
      title: "Compassion",
      description:
        "Compassion refers to the ability and willingness to empathize with others and show concern for their emotional and physical well-being. Those who score high in compassion are typically warm, kind, and nurturing, and are sensitive to the needs and feelings of others. They are often motivated to help others and may pursue careers in fields such as healthcare, social work, or counselling. Conversely, low scores in compassion may indicate a lack of concern for others, or an inability to understand or connect with others on an emotional level. However, it is important to note that excessive focus on compassion and care for others can lead to neglect of one's own needs and well-being.",
    },
    {
      key: 7,
      title: "Politeness",
      description:
        "Individuals who score high in politeness are typically empathetic and cooperative, striving to be considerate and respectful towards others. They are sensitive to social norms and etiquette and prioritize the needs and desires of others. They tend to be patient, calm, and diplomatic, avoiding conflict and focusing on finding peaceful solutions. High scores on politeness can indicate an individual who is courteous, well-mannered, and socially skilled, although excessively high scores can lead to a tendency to be overly compliant or people-pleasing, at the expense of one's own needs and interests. Low scores on politeness can suggest a person who is more self-focused, less aware of social conventions, and may struggle with interpersonal relationships. However, it is important to note that excessive focus on politeness and consideration can lead to a tendency to avoid conflict or to be overly accommodating.",
    },
    {
      key: 8,
      title: "Industriousness",
      description:
        "Industriousness refers to the personality trait of being diligent and hardworking. People who score high in industriousness are typically very goal-oriented, disciplined, and efficient. They have a strong desire to achieve their goals and are willing to work hard to make them happen. This trait is also linked to ‘grit’ which is a combination of passion and perseverance for long-term goals. Industrious individuals tend to be reliable and productive, and they often take pride in their accomplishments. On the other hand, those who score low in industriousness may struggle with procrastination, lack of motivation, and a tendency to avoid hard work. It is also important to note that excessive focus on industriousness and grit can lead to burnout and neglect of other important aspects of life such as self-care and relationships.",
    },
    {
      key: 9,
      title: "Orderliness",
      description:
        "People who score high in orderliness have a strong desire for structure and organization in their daily lives. They thrive on predictability and routine and often have a specific way of doing things that they adhere to strictly. They have a tendency to keep their environment neat and tidy and are often uncomfortable in chaotic or cluttered settings. High scorers on orderliness also tend to be detail-oriented and have a perfectionistic streak, often setting high standards for themselves and others. However, it is important to note that excessive focus on orderliness and perfectionism can lead to rigidity and difficulty adapting to change.",
    },
  ];

  const thirtyFacetsConstants = [
    {
      key: 0,
      title: "E2-Gregariousness",
      description:
        "Individuals who score high on gregariousness enjoy the company of others and thrive in social settings. They find being in a crowd stimulating and derive pleasure from it. On the other hand, those who score low on this scale feel uncomfortable in large groups and prefer to avoid them. They need more personal space and time for themselves. However, it is essential to note that individuals who score low on this scale do not necessarily dislike being with people sometimes, but their need for time for themselves is much more significant than for individuals who score high on this scale.",
    },
    {
      key: 1,
      title: "E6-Cheerfulness",
      description:
        "Cheerfulness is a personality trait that reflects a person's tendency to experience positive emotions, such as happiness, excitement, and enthusiasm. People who score high in cheerfulness are generally optimistic, joyful, and upbeat, even in the face of adversity. They tend to have a positive outlook on life and are able to find pleasure in everyday experiences. On the other hand, those who score low in cheerfulness may be more subdued and less likely to experience high levels of positive emotion. It is important to note that cheerfulness is not the same as Emotional Stability, which measures negative emotions like anxiety and depression.",
    },
    {
      key: 2,
      title: "E1-Friendliness",
      description:
      "Individuals who score high on friendliness are warm, approachable, and enjoy interacting with others. They are quick to form friendships and feel comfortable in social situations. They tend to express their emotions openly and enjoy being around people. On the other hand, low scorers on friendliness are more reserved and find it difficult to connect with others. They may come across as cold or indifferent, and may not make much effort to socialize or build relationships. However, it's important to note that low scores on friendliness do not necessarily mean that a person is unfriendly or hostile.",
    },
    {
      key: 3,
      title: "E5-Excitement Seeking",
      description:
        "Individuals who score high on the Excitement-Seeking facet tend to be easily bored and crave high levels of stimulation in their daily lives. They often enjoy exciting activities and seek out opportunities for new experiences and adventures. These individuals may also be more likely to take risks and seek thrills, such as participating in extreme sports or engaging in impulsive behaviours. Conversely, individuals who score low on this scale may find excitement and novelty overwhelming and prefer quieter, more predictable experiences. They may be more risk-averse and cautious in their decision-making.",
    },
    {
      key: 4,
      title: "E4-Activity Level",
      description:
        "Individuals who score high on the Activity Level are very active and energetic. They have a strong desire to keep themselves engaged in various activities and tend to move about vigorously. They have a lot of energy and enthusiasm that drives them towards accomplishing their goals. In contrast, individuals who score low on this scale tend to prefer a more leisurely pace of life and are not as energetic or active as those who score high. They are content with a slower and more relaxed lifestyle, and do not feel the need to be constantly involved in activities.",
    },
    {
      key: 5,
      title: "E3-Assertiveness",
      description:
        "Assertiveness is a personality trait that describes a person's tendency to be dominant and assert their opinions and ideas. Those who score high on this trait tend to be confident, motivated to attain social status and leadership positions, and tend to be provocative. They have a desire to take charge and often become leaders in groups. On the other hand, low scorers tend to be less talkative and prefer to let others control the activities of their groups. They are less likely to assert their opinions or take charge of situations.",
    },
    {
      key: 6,
      title: "N2-Anger",
      description:
        "Individuals who score low on the anger scale tend to become extremely angry when things do not go their way. They place a high value on fairness and become resentful and bitter when they feel that they are being treated unfairly. The score on this scale reflects a person's tendency to experience anger, but how much they express it depends on their level of agreeableness. High scorers, on the other hand, do not get angry easily and tend to remain calm in frustrating situations.",
    },
    {
      key: 7,
      title: "N5-Immoderation",
      description:
        "Individuals who score low on the immoderation scale experience strong and intense urges and desires that they struggle to control. They are more likely to prioritize immediate satisfaction and gratification over long-term consequences and benefits.Conversely, individuals who score high on this scale are less likely to experience overpowering cravings and are more capable of controlling their impulses. As a result, they are less prone to overindulging and engaging in activities that may have negative long-term consequences.",
    },
    {
      key: 8,
      title: "N1-Anxiety",
      description:
        "Individuals who score low in anxiety tend to worry excessively about the future, often experiencing irrational fears and feeling tense and nervous. Their brains are more easily triggered into the ‘fight-or-flight’ response, which can cause physical symptoms like sweating, trembling, and increased heart rate. On the other hand, those who score high in anxiety are generally calm, relaxed, and fearless. They may not worry as much about potential threats or dangers and may be less likely to experience physical symptoms related to anxiety. However, it's important to note that some degree of anxiety is a normal and healthy response to stressful situations, and extremely low levels of anxiety may indicate a lack of concern or motivation.",
    },
    {
      key: 9,
      title: "N3-Depression",
      description:
        "Individuals who score low on the Depression scale tend to experience feelings of sadness, hopelessness, and low mood. They may lack the motivation to engage in activities and have a general feeling of being ‘down’ or ‘blue’ Conversely, individuals who score high on this scale tend to be more emotionally stable and less prone to these depressive feelings. It's important to note that scoring high on this scale doesn't necessarily indicate clinical depression, but rather a general disposition towards experiencing feelings of sadness and discouragement.",
    },
    {
      key: 10,
      title: "N4-Self - Consciousness",
      description:
        "Self-conscious individuals have a heightened sensitivity to the opinions of others, which can make them feel anxious and uncomfortable in social situations. They often fear rejection and ridicule, leading to shyness and self-doubt. This fear can cause them to become easily embarrassed and feel ashamed, even when there is no reason to be. Unfortunately, their worries about what others think of them can become self-fulfilling, leading to further discomfort in social situations. In contrast, high scorers on this scale are less concerned about others' opinions and are not nervous in social situations.",
    },
    {
      key: 11,
      title: "N6-Vulnerability",
      description:
        "Individuals who score low on the vulnerability facet experience strong negative emotions like anxiety, fear, and worry when faced with challenging or stressful situations. These individuals may feel overwhelmed and helpless and may struggle to cope with stressors. In contrast, those who score low on this facet tend to remain calm and level-headed even under pressure. They are typically more resilient and better able to manage stress and adversity. It is important to note that vulnerability is not the same as weakness, and individuals who score low on this facet are not necessarily less capable or competent than those who score low. Instead, they may simply be more sensitive to stress and may require extra support and coping strategies to manage it effectively.",
    },
    {
      key: 12,
      title: "O5-Intellect",
      description:
        "Two important and central aspects of openness to experience are intellect and artistic interests. Individuals who score high on intellect enjoy playing with ideas, debating intellectual issues, and solving puzzles and brain teasers. They are open-minded and receptive to new and different ideas. In contrast, individuals who obtain low scores on the intellect dimension tend to enjoy interacting with objects or individuals more than with concepts and may consider intellectual activities as unproductive. It should be noted that intellect is not synonymous with intelligence but describes a preference for an intellectual way of thinking. Individuals who obtain high scores on intellect may perform slightly better on intelligence tests compared to those who score low, but this is not a definite rule.",
    },
    {
      key: 13,
      title: "O4-Adventurousness",
      description:
        "Adventurousness describes the inclination towards novel experiences and exploration. People who score high in adventurousness enjoy taking risks, trying new things, and exploring new places. They tend to find routine and predictability dull and are always on the lookout for exciting opportunities.Conversely, those who score low in adventurousness tend to be more cautious and prefer the safety of familiar routines. They may find change and uncertainty to be stressful or uncomfortable.",
    },
    {
      key: 14,
      title: "O2-Aesthetics",
      description:
        "Individuals who score high on this scale have a deep appreciation and attraction towards beauty, both natural and man-made. They enjoy getting involved in creative activities and find them absorbing. However, it doesn't necessarily mean that they possess artistic skills or training. They are driven by a genuine love for aestheticism. On the other hand, individuals who score low in Artistic Interests tend to be less sensitive to aesthetic experiences and may not derive as much pleasure or meaning from art or nature. They may have different interests or priorities, such as practical pursuits or intellectual pursuits.",
    },
    {
      key: 15,
      title: "O1-Imagination",
      description:
        "Individuals with high levels of imagination tend to have a rich and vibrant inner world. They often use their imagination to create an alternate reality that is more exciting and interesting than the mundane real world. They enjoy exploring ideas and concepts that may seem unusual or unrealistic to others.In contrast, individuals with low levels of imagination prefer to focus on facts and practical matters and may find imaginative or abstract ideas to be less interesting or relevant. However, it's important to note that both high and low levels of imagination can be valuable in different contexts and situations.",
    },
    {
      key: 16,
      title: "O3-Emotionality",
      description:
        "Individuals who score high in emotionality are in touch with their feelings and emotions, and they can express themselves in a healthy and constructive manner. They are sensitive to the emotional states of others as well. On the other hand, individuals who score low in emotionality tend to be more reserved and less expressive of their feelings, and they may have difficulty recognizing and identifying their own emotions. They might also be less attuned to the emotions of others, which can lead to communication difficulties in interpersonal relationships.",
    },
    {
      key: 17,
      title: "O6-Liberalism",
      description:
        "Liberalism refers to a tendency to question and challenge traditional values and authority. Individuals who score high on this scale are open to new ideas and may be critical of rules and conventions that they perceive as limiting individual freedoms. However, it's important to note that extreme liberalism can lead to a disregard for rules and order, which may result in chaos and ambiguity. On the other hand, individuals who score low on this scale are inclined towards traditional values and norms, as they find security and stability in them. It's worth noting that psychological conservatism or liberalism doesn't necessarily equate to political affiliation, but it can play a role in shaping one's political views.",
    },
    {
      key: 18,
      title: "A6-Sympathy",
      description:
        "Sympathy is a facet of agreeableness that measures a person's ability to feel empathy and compassion towards others. Those who score high in sympathy are sensitive and caring, and are often moved by the plight of others. They can easily understand and share the feelings of others, and they are quick to provide comfort and support when needed. On the other hand, those who score low in sympathy are not as affected by the suffering of others, and may appear to be more objective and rational in their judgments. They prioritize fairness and truth over mercy, and may have a harder time relating to the emotional needs of others.",
    },
    {
      key: 19,
      title: "A3-Altruism",
      description:
        "Individuals who score high in altruism feel a sense of fulfilment in helping others and are more likely to lend a hand to those in need. They do not view helping others as a burden but rather as a way to enrich their own lives. On the other hand, individuals who score low on this scale do not find helping others as rewarding and may see it as an inconvenience. They are less likely to offer assistance and may even feel resentful when asked for help. It is important to note that low scorers on altruism are not necessarily selfish or uncaring individuals; they simply do not find satisfaction in helping others to the same degree as high scorers do.",
    },
    {
      key: 20,
      title: "A1-Trust",
      description:
        "The Trust facet refers to an individual's inclination to trust others. People who score high in this trait tend to believe that most people are well-intentioned and trustworthy. They are less likely to be suspicious of others' motives and tend to give people the benefit of the doubt. On the other hand, individuals who score low in trust are more sceptical of others' intentions and are more likely to be suspicious of their motives. They may view others as selfish or deceitful and tend to be less willing to take risks or collaborate with others. It is important to note that an individual's level of trust is influenced by both their personality traits and their life experiences.",
    },
    {
      key: 21,
      title: "A4-Cooperation",
      description:
        "Cooperative individuals prioritize harmony and collaboration over conflict and self-interest. They tend to avoid arguments and are willing to make compromises to maintain peaceful relationships. In contrast, individuals who score low on cooperation tend to prioritize their own interests and may use aggressive or intimidating tactics to achieve their goals. It is important to note that being cooperative does not mean being passive or a pushover; rather, it reflects a preference for finding mutually beneficial solutions rather than engaging in power struggles.",
    },
    {
      key: 22,
      title: "A5-Modesty",
      description:
        "Modesty is a personality trait that refers to a person's reluctance to claim superiority or boast about themselves. People who score high on this scale tend to be humble and avoid talking about their accomplishments. However, it's important to note that this trait can also stem from low self-confidence or self-esteem. Conversely, people who are willing to boast and claim superiority are often seen as arrogant by others. It's important to strike a balance between being confident in oneself and being humble in social interactions.",
    },
    {
      key: 23,
      title: "A2-Morality",
      description:
        "The Morality facet measures an individual's tendency to be honest and transparent in their interactions with others. High scorers on this scale are genuine, sincere, and do not feel the need to manipulate or deceive others. They value honesty and authenticity and are straightforward in their communication. On the other hand, low scorers believe that some level of deception is necessary in social interactions and may not be as forthcoming with the truth. This does not mean that low scorers are inherently immoral or deceitful, but rather they may be more guarded in their communication style. High scorers are generally easier to relate to, as their sincerity and openness can foster trust and a sense of comfort in their relationships.",
    },
    {
      key: 24,
      title: "C4-Achievement Striving",
      description:
        "Achievement-Striving is a personality trait that reflects an individual's motivation to excel and succeed. High scorers on this scale are highly driven and have a clear sense of direction in their lives. They set ambitious goals for themselves and work hard to achieve them, often seeking recognition for their accomplishments. However, extremely high scores on this scale may lead to being overly single-minded and obsessed with work, potentially causing neglect in other important areas of life. Low scorers, on the other hand, may lack the motivation to excel and settle for mediocrity, appearing lazy to others.",
    },
    {
      key: 25,
      title: "C1-Self-Efficacy",
      description:
        "Self-efficacy refers to the belief in one's ability to successfully achieve tasks and goals. Individuals who score high in self-efficacy have confidence in their intelligence, motivation, and ability to exercise self-control, which allows them to persist in their pursuits and overcome obstacles. On the other hand, low scorers in self-efficacy may lack belief in their own capabilities and feel that they have little control over their lives. This lack of confidence can hinder their motivation and prevent them from reaching their full potential. It is important to note that self-efficacy is not necessarily related to actual abilities, but rather to one's belief in their abilities.",
    },
    {
      key: 26,
      title: "C5-Self-Discipline",
      description:
        "Self-discipline, also known as willpower, refers to the capacity to persevere through challenging or unpleasant tasks until they are finished. Individuals who have high levels of self-discipline can overcome their initial reluctance to begin tasks and stay focused despite potential distractions.In contrast, those with low self-discipline tend to procrastinate and struggle with following through, even on tasks they genuinely want to complete. Developing self-discipline can be important for achieving personal and professional goals, as it allows individuals to overcome obstacles and setbacks along the way.",
    },
    {
      key: 27,
      title: "C6-Cautiousness",
      description:
        "Cautiousness refers to the tendency of individuals to carefully consider various possibilities before making a decision or taking action. People who score high on the cautiousness facet are thoughtful and deliberate in their decision-making process, and may take longer to reach a conclusion. They carefully weigh the potential consequences of their actions before taking any steps. In contrast, those who score low on the cautiousness facet tend to be more impulsive and may act without fully considering the possible outcomes of their actions. They may jump into situations without fully understanding the risks involved. Cautiousness is an important trait in many professions, including those that require high levels of responsibility or decision-making.",
    },
    {
      key: 28,
      title: "C3-Dutifulness",
      description:
        "Dutifulness is a facet of conscientiousness that pertains to an individual's sense of obligation and responsibility towards moral and ethical principles. Individuals who score high on this scale place a great deal of importance on fulfilling their duties and obligations, whether they are personal or professional in nature. They take pride in being reliable and dependable, and they strive to meet their commitments even when it requires a significant amount of effort. On the other hand, low scorers on this scale tend to be more independent and self-directed, and may have a more flexible attitude towards rules and regulations. They may find the constraints of obligations and rules limiting and may be more likely to take risks or break rules. However, this can also lead to a perception of unreliability and irresponsibility.",
    },
    {
      key: 29,
      title: "C2-Orderliness",
      description:
        "Individuals with high scores in orderliness have a strong preference for structure and organization in their daily lives. They like to plan ahead and stick to routines, keeping things in their place and following schedules. In contrast, low scorers tend to be more spontaneous, unstructured, and flexible in their approach to life, preferring to go with the flow rather than follow strict routines. These individuals may have difficulty keeping track of things and may appear disorganized to others.",
    },
  ];

  const predictiveReliabilityConstants = [
    {
      key: 1,
      title: "Predictive Reliability Factor",
      description:
        "Test or assessment demonstrates reliability in predicting future outcomes or behaviors. It assesses the consistency and stability of the assessment scores in accurately predicting specific criteria or measures of interest.",
    },
  ];

  return (
    <>
      <View>
        <Text
          style={{
            fontFamily: "General Sans",
            fontSize: 24,
            fontWeight: 500,
            textTransform: "uppercase",
            borderBottom: "2px solid black",
            paddingBottom: 5,
          }}
        >
          {"7."} {t("OCEAN")}
        </Text>
      </View>

      {workCompetencyConstants.length > 0 && (
        <>
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 16,
              fontWeight: 500,
              textTransform: "uppercase",
              marginTop: 8,
              marginBottom: 4,
            }}
          >
            {"2.1"} {t("Work Competency Factor")}
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              width: "100%",
              flexContent: "center",
            }}
          >
            {workCompetencyConstants
              .map((x) => {
                if (data.workCompetency.hasOwnProperty(x.title)) {
                  x.value = data.workCompetency[x.title];

                  if (data.workCompetency[x.title] < 25) {
                    x.color = "#FF0000";
                  } else if (data.workCompetency[x.title] < 75) {
                    x.color = "#ff9800";
                  } else if (data.workCompetency[x.title] <= 100) {
                    x.color = "#2e7d32";
                  }
                }
                return x;
              })
              .map((item, i) => (
                <View
                  key={i}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      marginTop: 0,
                    }}
                  >
                    {"2.1." + (i + 1) + " " + t(item.title)}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(item.description)}
                  </Text>

                  {item.value !== undefined && item.color !== undefined ? (
                    <View style={{ marginVertical: 20 }}>
                      <Slider value={item.value} color={item.color} />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ))}
          </View>
        </>
      )}

      {personalityAspectsConstants.length > 0 && (
        <>
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 16,
              fontWeight: 500,
              textTransform: "uppercase",
              marginTop: 8,
              marginBottom: 4,
            }}
          >
            {"2.2"} {t("Personality Aspects")}
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              width: "100%",
              flexContent: "center",
            }}
          >
            {personalityAspectsConstants
              .map((x) => {
                if (data.personalityAspect.hasOwnProperty(x.title)) {
                  x.value = data.personalityAspect[x.title];

                  if (data.personalityAspect[x.title] < 25) {
                    x.color = "#FF0000";
                  } else if (data.personalityAspect[x.title] < 75) {
                    x.color = "#ff9800";
                  } else if (data.personalityAspect[x.title] <= 100) {
                    x.color = "#2e7d32";
                  } else {
                    x.color = "#D04C8D";
                  }
                }
                return x;
              })
              .map((item, i) => (
                <View
                  key={i}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      marginTop: 0,
                    }}
                  >
                    {"2.2." + (i + 1) + " " + t(item.title)}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(item.description)}
                  </Text>

                  {item.value !== undefined && item.color !== undefined ? (
                    <View style={{ marginVertical: 20 }}>
                      <Slider value={item.value} color={item.color} />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ))}
          </View>
        </>
      )}

      {thirtyFacetsConstants.length > 0 && (
        <>
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 16,
              fontWeight: 500,
              textTransform: "uppercase",
              marginTop: 8,
              marginBottom: 4,
            }}
          >
            {"2.3"} {t("30 - Facets")}
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              width: "100%",
              flexContent: "center",
            }}
          >
            {thirtyFacetsConstants
              .map((x) => {
                if (data.thirtyFacets.hasOwnProperty(x.title)) {
                  x.value = data.thirtyFacets[x.title];

                  if (data.thirtyFacets[x.title] < 25) {
                    x.color = "#FF0000";
                  } else if (data.thirtyFacets[x.title] < 75) {
                    x.color = "#ff9800";
                  } else if (data.thirtyFacets[x.title] <= 100) {
                    x.color = "#2e7d32";
                  } else {
                    x.color = "#D04C8D";
                  }
                }
                return x;
              })
              .map((item, i) => (
                <View
                  key={i}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      marginTop: 0,
                    }}
                  >
                    {"2.3." + (i + 1) + " " + t(item.title)}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(item.description)}
                  </Text>

                  {item.value !== undefined && item.color !== undefined ? (
                    <View style={{ marginVertical: 20 }}>
                      <Slider value={item.value} color={item.color} />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ))}
          </View>
        </>
      )}

      {predictiveReliabilityConstants.length > 0 && (
        <>
          <Text
            style={{
              fontFamily: "General Sans",
              fontSize: 16,
              fontWeight: 500,
              textTransform: "uppercase",
              marginTop: 8,
              marginBottom: 4,
            }}
          >
            {"2.4"} {t("Predictive Reliability Factor")}
          </Text>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              flexWrap: "wrap",
              width: "100%",
              flexContent: "center",
            }}
          >
            {predictiveReliabilityConstants
              .map((x) => {
                if (data.predictiveReliability.hasOwnProperty(x.title)) {
                  x.value = data.predictiveReliability[x.title];

                  if (data.predictiveReliability[x.title] < 25) {
                    x.color = "#FF0000";
                  } else if (data.predictiveReliability[x.title] < 75) {
                    x.color = "#ff9800";
                  } else if (data.predictiveReliability[x.title] <= 100) {
                    x.color = "#2e7d32";
                  } else {
                    x.color = "#D04C8D";
                  }
                }
                return x;
              })
              .map((item, i) => (
                <View
                  key={i}
                  style={{
                    width: "100%",
                  }}
                >
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 14,
                      fontWeight: 200,
                      marginTop: 0,
                    }}
                  >
                    {"2.4." + (i + 1) + " " + t(item.title)}
                  </Text>
                  <Text
                    style={{
                      fontFamily: "General Sans",
                      fontSize: 12,
                      fontWeight: 200,
                      textAlign: "justify",
                      color: "#213343",
                      lineHeight: 1.5,
                      marginTop: 4,
                      marginRight: 20,
                    }}
                  >
                    {t(item.description)}
                  </Text>

                  {item.value !== undefined && item.color !== undefined ? (
                    <View style={{ marginVertical: 20 }}>
                      <Slider value={item.value} color={item.color} />
                    </View>
                  ) : (
                    <></>
                  )}
                </View>
              ))}
          </View>
        </>
      )}
    </>
  );
};
