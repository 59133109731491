import moment from "moment";
import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";

function SubscripeRoute({ children }) {

  // const subscribedData = useSelector(
  //     (state) => state.subscription.subscriptionData
  //   );
  //   const selected_org_code = useSelector((state) => state.company.selectedGlobalOrgCode)
  //   const companyData =  useSelector((state) => state?.company?.currentlySelectedcompanyData);
  //   const userStatus = useSelector((state) => state.usermanager.userAcceptedStatus);
  //   const cpyOrgData = useSelector((state) => state.company.currentOrgData)
  //   function checkIsSubscriptionExpired(data) {
  //     let today = moment(new Date()).format("MM-DD-YYYY");
  //     today = new Date(today);
  //     let renew_date = data.renew_date.split("-");
  //     renew_date = new Date(
  //       renew_date[1] + "-" + renew_date[0] + "-" + renew_date[2]
  //     );
  //     return today > renew_date;
  //   }
  const companyStatus = localStorage.getItem("companyCheck");
  const subscriptionSttaus = localStorage.getItem("subscriptionStatus");
  const subscriptionExpired = localStorage.getItem("subscriptionExpired");
  const userStatus = localStorage.getItem("userStatusStore");
  console.log("CompanyStatusUpper", localStorage.getItem("companyCheck"));
  console.log("CompanyStatusUpper222", localStorage.getItem("subscriptionStatus"));
  console.log("CompanyStatusUpper333", localStorage.getItem("subscriptionExpired"));
  console.log("CompanyStatusUpper444", localStorage.getItem("userStatusStore"));
  const location = useLocation();

  const pathName = location && location.pathname.replace('/', '');
  console.log("CompanyStatusUpper555", pathName);
  if (companyStatus !== undefined) {


    console.log("ppppPath", pathName)
    if (companyStatus == 'accepted' && subscriptionSttaus == 'success' && userStatus !== undefined) { //&&  !(menuItem.name.toLowerCase() == 'employee management' && !(isEmpty(subscribedData) || isEmpty(subscribedData[selected_org_code])) ) ){

      if (subscriptionExpired) {
        return <Navigate to="/dashboard" />
      }
      else {
        return children
      }
    } else if ('accepted' != companyStatus) {
      console.log("CompanyStatus", companyStatus);
      return <Navigate to="/dashboard" />
    } else if (['accepted', 'pending', 'denied', 'review'].includes(companyStatus) && ['internships', 'internships/new-intern', 'internships/details', 'my-profile', 'subscriptions', 'subscriptions/subscription-plan', 'my-company', 'my-company/edit-user', 'my-company/add-user'].includes(pathName)) {
      console.log("CompanyStatus22", companyStatus);

      return children
    }
    // else {
    //   return <Navigate to="/dashboard" />
    // }
  }

  //   const currentUser = localStorage.getItem("auth_token");
  return children;
}

export default SubscripeRoute;
