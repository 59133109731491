import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import CheckboxInput from "../../components/checkboxInput/checkboxInput";
import Dropdown from "../../components/dropdown/dropdown";
import Input from "../../components/input/input";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { Colors } from "../../utilities/colors";
import { annualSurveyContent } from "../annualSurvey/staticContentForm";
import { skillGapSchema, skillGapStaticContent } from "./skillGapStaticContent";
// import { annualSurveyContent } from "./staticContentForm";
import "./skillGap.css";
import { getSkillGap, submitSingleInternUpload, submitSkillGapForm } from "../../services/apiCalls";
import Button from "../../components/button/button";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import ErrorField from "../../components/errorField/errorField";
import { useSelector } from "react-redux";



export default function SkillGapEvaluationForm({org_code,userData,intern_id,internship_id,internData,setPassValues,internshipData,handleNext,allInternshipsData,isFinished}){


    const {
        control,
        watch,
        reset: resetForm,
        setError: setErrorCustom,
        setValue: setValue,
        getValues,
        handleSubmit,
        formState: { errors: errorsQuestion },
      } = useForm({
        // defaultValues: { ...iniialEligibleState },
        resolver: yupResolver(skillGapSchema),
        mode: "onSubmit",
      });
    
    const SectionWrapper = styled.section``;

    const HeaderText = styled.h2`
    font-style: normal;
    font-weight: 500;
    font-size: 28px;
    line-height: 38px;
    // display: flex;
    // justify-content: center;
    margin: 0;
    color: ${Colors.dark};
  `;
  const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;

const CardWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 20px;
 
  padding: 2rem;
  margin: 2rem 2rem 0 2rem;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  max-width: 900px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
useEffect(() => {
    document.body.classList.add("bg-body-cover");
  }, []);
  const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const CustomRadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 70px;
flex-wrap: wrap;
`;

  const GroupWrapper = styled.div`
  margin-bottom: 20px;
  margin-top: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioBigButton = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;

const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;
const SkillLabel = styled.label`
  font-style: normal;
  font-weight: 800;
  font-size: 18px;
  line-height: 22px;
  color: ${Colors.pink};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
  width: auto!important;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding-top: 14px;
  color: ${Colors.labelText};
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;
const CustomRadio = styled.input`
width: 30px,
height: 30px,
`
const CheckboxGroup = styled.div`
margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
> input{
    width: 30px,
    height: 30px, 
    border: 2px solid #000, // Specify the border color
    borderRadius: 4px
}
`;
const FlexWrapper = styled.div`
display: flex;
align-items: center;
`;
const ButtonWrapper = styled(FlexWrapper)`
justify-content: end;
margin-top: 40px;
@media (max-width: 600px) {
  flex-direction: column;
}
@media (max-width: 1024px) {
  justify-content: end;
}
`;
console.log("allInternshipslanding",allInternshipsData)
const [question1Eligible, setQuestion1Eligible] = useState(true);
const [selectedIntern, selectIntern] = useState();
const [selectedTechnicalSkill, setTechnicalSkill] = useState([]);
const [selectedSoftSkills, setSoftSkills] = useState([]);
const [internsData, setInternsData] = useState([]);
const [isFinaleStep, setFinaleStep] = useState(false);
const [employFormErrors, setEmployFormErrors] = useState(false);

const navigate = useNavigate();


  const updateValuefield = () => {
    if(internData){
      console.log("internData",internData)
      setValue("intern_name",internData.intern_name);
      setValue("intern_university_name",internData.intern_university_name);
      setValue("intern_academic_qualification",internData.intern_academic_qualification);
      setValue("intern_course_of_study",internData.intern_course_of_study);
    }
  }
  const onErrorSubmit = (error) => {
    
    console.log("ErrorSubmit",error);
    setEmployFormErrors(true);
  };
  const onSubmit = async (data) => {

    
    console.log("completeData",data);
    if(data && data.work_duties){
      let workDuties = data.work_duties;
      // const index = workDuties.findIndex((item) => item.includes("other="));
      const index = workDuties.findIndex((item) => {
        console.log("workingIndex",item)
        // Check if item is a string before using includes
        return typeof item === 'string' && item.includes("other=");
      });
    
      // const index = workDuties.findIndex((item) => item.includes("other="));
      console.log("workDutiesending",workDuties,index);
  
      if(workDuties.includes("Others (please specify)")){
        const indeOther = workDuties.findIndex((item) => item?.includes("Others (please specify)"));
        if(index !== -1){
          workDuties[index] = workDuties[index]?.replace('other=','')

        }
        if(index === -1){
          setErrorCustom('work_duties',{
            type: 'manual',
            message: 'This field is required'
          })

          return;
        }
        workDuties.splice(indeOther,1);

      }else{
        if(index !== -1){
  
  workDuties.splice(index,1);
        }
      }
      const updatedFormat = workDuties.map(item => ({ name: item }));
      console.log("updatedFormat",updatedFormat)
      data['work_duties'] = updatedFormat;
    }
    console.log("internsData",userData)
   if(internData.user_id){
    data['intern'] = internData.user_id;
   }
   if(userData.id){
    data['filled_by'] = userData.id;
   }
   if(org_code.id){
    data['organization'] = org_code.id;
   }
   if(internship_id){
    data['internship'] = internship_id
   }
    console.log("completeRefineData",data)
    console.log("completeRefineData",data)
    console.log("IDData",internship_id)

    const { status } = await submitSingleInternUpload({internship_id:internship_id,formdata:data});
    // const { dataSingleIntern } = response;
    console.log('datasingleI',status,isFinaleStep)
if( status === 200){
  if(isFinaleStep){
    submitAllInternshipData(data)
    
  }else{
    setPassValues(data);
    handleNext();
    scrollToTop();
  }
 
}
   
  }
  useEffect(() => {
    updateValuefield();
   
  },[]);  

  const submitAllInternshipData = async (data) => {
console.log("formData",data);
allInternshipsData.push(data);
const { status } = await submitSkillGapForm({internship_id:internship_id,formdata:allInternshipsData});
if(status === 200){
  toast.success("Skill Gap Submitted", {
    position: toast.POSITION.TOP_RIGHT,
  });
  navigate("/dashboard");
}
console.log("finaleData uplaoded",allInternshipsData)
  }

  useEffect(()=>{
console.log("steps Completed ",internship_id)
if(isFinished === true){
setFinaleStep(true)
}
  },[])
  // const useStyles = makeStyles((theme) => ({
  //   stepper: {
  //     backgroundColor: 'your-color-here', // Change the background color of the stepper
  //   },
  //   stepLabel: {
  //     '&.MuiStepLabel-active': {
  //       color: 'your-active-color-here', // Change the color of the active step label
  //     },
  //     '&.MuiStepLabel-completed': {
  //       color: 'your-completed-color-here', // Change the color of completed step label
  //     },
  //   },
  // }));
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // You can use 'auto' instead of 'smooth' for an instant scroll
    });
  };
    return (<>
    
    {/* <CardWrapper> */}
                {/* <HeaderText>{"Skills Gap Evaluation"}</HeaderText>
                <Description>{"Complete your endorsement for National Structured Internship Programme (MySIP) , by filling in the internship module."}</Description> */}
                <GroupWrapper>
                {/* <Row> */}
                  {/* <Col md="4"> */}
                  <FormLabel>{"1. Intern Name"}</FormLabel>
                  <Controller
                  name="intern_name"
                  control={control}
                  render={({field}) =>(
                    <Input
     placeholder={"Intern Name"}
     {...field}
    //  onChange={(e) => {

    //  }}
     />
//                     <Dropdown
//                     options={skillGapStaticContent.internList.map((e) => {
//                         return { label: e , id: e};
                    
//                       })}
//                       val={selectedIntern}
//                       label={selectedIntern}
//                       change={(val)=>{
// selectIntern(val.label)
//                       }}
//                       {...field}
//                     />
                  )}
                  />
                  {/* </Col> */}
                
                {/* </Row> */}
              </GroupWrapper>


    <GroupWrapper >
      <FormLabel>{"2. Intern’s University Name"}</FormLabel>
      {
    <Controller 
    name="intern_university_name"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"Intern’s University Name"}
     {...field}
    //  onChange={(e) => {

    //  }}
     /></>
        
    )}
    />}
    </GroupWrapper>

    <GroupWrapper >
      <FormLabel>{"3. Academic Qualification"}</FormLabel>
      {
    <Controller 
    name="intern_academic_qualification"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"Academic Qualification"}
     {...field}
    //  onChange={(e) => {

    //  }}
     /></>
        
    )}
    />}
    </GroupWrapper>

    <GroupWrapper >
      <FormLabel>{"4. Course of Study"}</FormLabel>
      {
    <Controller 
    name="intern_course_of_study"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"Course of Study"}
     {...field}
    //  onChange={(e) => {

    //  }}
     /></>
        
    )}
    />}
    </GroupWrapper>

    <GroupWrapper >
      <FormLabel>{"5. Internship Position"}</FormLabel>
      {
    <Controller 
    name="internship_position"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"Internship Position"}
   {...field}
     /></>
        
    )}
    />}
    </GroupWrapper>
    <GroupWrapper >
      <FormLabel>{skillGapStaticContent.question3}</FormLabel>
      <CustomRadioWrapper>
        {
         internshipData && internshipData?.technical_skills?.map((item)=>{
            return         <SkillLabel>{item.skill_name}</SkillLabel>

          })
        }
        {/* <SkillLabel>tznmmzxxm</SkillLabel>
        <SkillLabel>tznmmzxxm</SkillLabel> */}
      </CustomRadioWrapper>
    </GroupWrapper>
    <Controller
                name="does_the_intern_have_technical_skill_as_per_required"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                  <>
                  <GroupWrapper>
                <FormLabel>{skillGapStaticContent.question4}</FormLabel>
             
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 1}
                          onChange={()=> {
                            field.onChange(1)
                          setQuestion1Eligible(true)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 0}
                          onChange={()=>{ 
                            field.onChange(0)
                            setQuestion1Eligible(false)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
               
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{skillGapStaticContent.question5}</FormLabel>
                <Controller
                name="technical_skills_rating"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                  <CustomRadioWrapper>
                  {
                  skillGapStaticContent.option6Option.map((item,index)=>{
                   return <RadioButtonGroup>
                   <input 
                   type="radio" 
                   className="radio"
                   checked={field.value === index}
                   onChange={()=> {
                     field.onChange(index)
                   setQuestion1Eligible(true)
                 }}
                   />
                   <br></br>
                   {/* <div></div> */}
                   <RadioLabel className="radio-label" style={{ margin: 0 }}>{skillGapStaticContent.skillRatingLiat[index]}</RadioLabel>
                   </RadioButtonGroup>
                  })
                  
}
     
             </CustomRadioWrapper>
                )}
                />
            </GroupWrapper>
            {
              field.value === 0 ?
<>
<GroupWrapper>
                <FormLabel>{skillGapStaticContent.question13}</FormLabel>
                <Controller
                name="technical_skill_upskilling"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 1}
                          onChange={()=> {
                            field.onChange(1)
                          setQuestion1Eligible(true)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 0}
                          onChange={()=>{ 
                            field.onChange(0)
                            setQuestion1Eligible(false)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper >
      <FormLabel>{skillGapStaticContent.question12}</FormLabel>
      {
      <Controller 
    name="technical_skill_upskilling_suggestion"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"suggestion"}
     {...field}
     /></>
        
    )}
    />}
    </GroupWrapper>

</>
: <></>

            }
            
                  </>
    
             )}
                />
            
            <GroupWrapper >

<FormLabel>{skillGapStaticContent.question6}</FormLabel>
<CustomRadioWrapper>
  {
   internshipData && internshipData?.soft_skills?.map((item)=>{
      return         <SkillLabel>{item.skill_name}</SkillLabel>

    })
  }
  {/* <SkillLabel>tznmmzxxm</SkillLabel>
  <SkillLabel>tznmmzxxm</SkillLabel> */}
</CustomRadioWrapper>

</GroupWrapper>
            <Controller
                name="does_the_intern_have_soft_skill_as_per_required"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                  <>
                   <GroupWrapper>
                <FormLabel>{skillGapStaticContent.question8}</FormLabel>
                
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 1}
                          onChange={()=> {
                            field.onChange(1)
                          setQuestion1Eligible(true)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 0}
                          onChange={()=>{ 
                            field.onChange(0)
                            setQuestion1Eligible(false)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
               
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{skillGapStaticContent.question7}</FormLabel>
                <Controller
                name="soft_skill_rating"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                    
                  <CustomRadioWrapper>
                         {
                         skillGapStaticContent.option6Option.map((item,index)=>{
                          return <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === index}
                          onChange={()=> {
                            field.onChange(index)
                          setQuestion1Eligible(true)
                        }}
                          />
                   <RadioLabel className="radio-label" style={{ margin: 0 }}>{skillGapStaticContent.skillRatingLiat[index]}</RadioLabel>
                          </RadioButtonGroup>
                         })
                         
}
            
                    </CustomRadioWrapper>

                )}
                />
            </GroupWrapper>
            {
              field.value === 0 ?
<>
<GroupWrapper>
                <FormLabel>{skillGapStaticContent.question13}</FormLabel>
                <Controller
                name="soft_skill_upskilling"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 1}
                          onChange={()=> {
                            field.onChange(1)
                          setQuestion1Eligible(true)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 0}
                          onChange={()=>{ 
                            field.onChange(0)
                            setQuestion1Eligible(false)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper >
      <FormLabel>{skillGapStaticContent.question12}</FormLabel>
      {
      <Controller 
    name="soft_skill_upskilling_suggestion"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"suggestion"}
     {...field}
     /></>
        
    )}
    />}
    </GroupWrapper>
    {/* <GroupWrapper >

      <FormLabel>{skillGapStaticContent.question6}</FormLabel>
      <CustomRadioWrapper>
        {
         internshipData && internshipData?.soft_skills?.map((item)=>{
            return         <SkillLabel>{item.skill_name}</SkillLabel>

          })
        }
 
      </CustomRadioWrapper>
      
    </GroupWrapper> */}
</>
: <></>

            }
                  </>
   
             )}
             /> 
          
            <GroupWrapper >
      <FormLabel>{skillGapStaticContent.question9}</FormLabel>
      {
    <Controller 
    name="work_duties"
    control={control}
    render={({field}) =>(
      <>
      {
         skillGapStaticContent.workDutiesOptions.map((items)=>(
          items === "Others (please specify)" ?
          <>
             <CheckboxGroup key={items.id} style={{paddingBottom:"20px"}}>
          <Row>
            <Col xs={"auto"} style={{ marginTop: "8px" }}>
              <CheckboxInput
                onChange={() => {
               
                    if (selectedTechnicalSkill.includes(items)) {
                        // If already selected, remove it
                        const index = selectedTechnicalSkill.indexOf(items);
                        if (index > -1) {
                          selectedTechnicalSkill.splice(index, 1);
                        }
                      } else {
                        // If not selected, add it
                        selectedTechnicalSkill.push(items);
                      }
                      field.onChange(selectedTechnicalSkill);
                }}
           checked={selectedTechnicalSkill.includes(items)}

              />
            </Col>
            <Col>
              <TableHead>{items}</TableHead>
            </Col>
            </Row>
           
        </CheckboxGroup >
        <Input
   placeholder={"Others"}
   onChange={(e) => {
    let textvalue = e.target.value;
    if(textvalue.trim() !== ''){
      textvalue = "other=" + textvalue;
      let selectedlist = field.value;
      console.log("fetchedList",selectedlist);
      const index = selectedlist.findIndex((item) => item.includes("other="));
      if(index === -1){
        selectedlist.push(textvalue);
      }else{
        selectedlist[index] = textvalue;
      }
      field.onChange(selectedlist);
  console.log("itemsdata",field.value,selectedlist)
    }
  
   }}
  //  required={selectedTechnicalSkill.includes(items)}
   />
   {
    errorsQuestion.work_duties && (
      <ErrorField
            err={errorsQuestion.work_duties.message}
          />
    )
   }
     {/* {selectedTechnicalSkill.includes(items) && (
        <div style={{ color: 'red' }}>
          {field.value && field.value.some((item) => item.includes("other=")) ? null : "This field is required"}
        </div>
      )} */}
        </>
       
         : <CheckboxGroup key={items.id}>
            <Row>
              <Col xs={"auto"} style={{ marginTop: "8px" }}>
                <CheckboxInput
                  onChange={() => {
                    
                      if (selectedTechnicalSkill.includes(items)) {
                          // If already selected, remove it
                          const index = selectedTechnicalSkill.indexOf(items);
                          if (index > -1) {
                            selectedTechnicalSkill.splice(index, 1);
                          }
                        } else {
                          // If not selected, add it
                          selectedTechnicalSkill.push(items);
                        }
                        console.log("checkSelecteTech",selectedTechnicalSkill)
                        field.onChange(selectedTechnicalSkill);
                  }}
             checked={selectedTechnicalSkill.includes(items)}

                />
              </Col>
              <Col>
                <TableHead>{items}</TableHead>
              </Col>
              </Row>
          </CheckboxGroup>
          
        ))
      }
                           {/* <Dropdown
                           isMulti
                    options={skillGapStaticContent.workDutiesOptions.map((e) => {
                        return { label: e , id: e};
                    
                      })}
                      val={selectedIntern}
                      // label={selectedIntern}
                      change={(val)=>{
                        console.log("vallue",val)
selectIntern(val);
                      }}
                      {...field}
                    /> */}
     {/* <Input
     placeholder={"Types of work duties"}
     onChange={(e) => {

     }}
     /> */}
     
     </>
        
    )}
    />}
    </GroupWrapper>
    <GroupWrapper >
      <FormLabel>{skillGapStaticContent.question10}</FormLabel>
      {
    <Controller 
    name="supervisor_comment"
    control={control}
    render={({field}) =>(
      <>
     <Input
     placeholder={"Supervisor Comment"}
     {...field}
     /></>
        
    )}
    />}
    </GroupWrapper>
    <GroupWrapper>
                <FormLabel>{skillGapStaticContent.question11}</FormLabel>
                <Controller
                name="will_you_offer_a_job_placement"
                control={control}
                // defaultValue={1}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 1}
                          onChange={()=> {
                            field.onChange(1)
                          setQuestion1Eligible(true)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 0}
                          onChange={()=>{ 
                            field.onChange(0)
                            setQuestion1Eligible(false)
                        }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
              <ButtonWrapper>
                {
                  isFinaleStep === true ?
                  <Button
                width="198px"
                onClick={handleSubmit(onSubmit,onErrorSubmit)}
                name={"Submit"}
              />: <Button
              width="198px"
              onClick={handleSubmit(onSubmit,onErrorSubmit)}
              name={"Next"}
            />
                }
               
                </ButtonWrapper>
               <div style={{display:"flex",justifyContent:"end"}}>
               {employFormErrors && (
          <ErrorField
            err={"*Please make sure all the question are filled"}
          />
        )}
                </div> 
    {/* </CardWrapper> */}
   
    </>);
}