import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import Logo from "../../assets/addNewIntern.png";
import PlusIcon from "../../assets/plus.png";
import { useTranslation } from "react-i18next";
import ReactTooltip from "react-tooltip";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import './MyComponent.css'; 
import Button from "../../components/button/button";
import InternCard from "../InternshipCard/internCard";
import { Colors } from "../../utilities/colors";
import Image from 'react-bootstrap/Image';
import { Row } from "react-bootstrap"
import UploadPlusIcon from "../../assets/uploader.png";
import SelectInternshipType from "../../pages/MySIPDirectHiring/selectInternshipType";
import EligibilityCheck from "../../pages/MySIPDirectHiring/eligibilityCheck";


const AddNewInternWrapper = styled.div`
  background: transparent;
  border-radius: 10px;
  padding: 15px;
  margin: 50px 0 50px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  > img {
    cursor: pointer;
  }
  @media (max-width: 500px) {
    flex-direction: column;
    margin: 50px 0 50px 0;
    > img {
      display: block;
      margin-left: auto;
    }
  }
  @media (max-width: 1300px) {
    flex-direction: column;
    > img {
      display: block;
      margin-left: auto;
      bottom: -15%;
    }
  }
`;

const BackgroundContainer = styled.div`
  background: linear-gradient(90deg, #f54ba1 0%, #57508d 100%);
  border-radius: 10px;
  padding: 15px;
  margin: 50px 0 50px 0;
  display: flex;
  position: absolute;
  z-index: -1;
  height: 100%;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  > img {
    cursor: pointer;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    flex-direction: column;
    margin: 0px 0 50px 0;
    > img {
      display: block;
      margin-left: auto;
    }
  }
  @media (min-width: 481px) and (max-width: 768px) {
    flex-direction: column;
    margin: 0px;

    > img {
      display: block;
      margin-left: auto;
      bottom: -15%;
    }
  }
  @media (min-width: 769px) and (max-width: 1024px) {
    margin: 0px;
  }
  @media (min-width: 1025px) and (max-width: 1200px) {
    margin: 0px;
  }
`;
const StaticImg = styled.img`
  position: absolute;
  z-index: -1;
  left: -10px;
  /* top: 22%; */
  width: 208px;
  height: 200px;
  top: 5%;
  cursor: default !important;
  @media (max-width: 500px) {
    display: inline-block;
    // bottom:5%;
    top:350px;
    left: -45px;
  }
`;
const ContentWrapper = styled.div`
  margin: 0 20px;
  margin-left: 115px;
  max-width: 543px;
  > h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 27px;
    color: #ffffff;
    margin: 0;
    margin-bottom: 10px;
  }
  > p {
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    margin: 0;
    color: #ffffff;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0;
    margin-left: 0px;
    padding: 5px;
    max-width: 743px;
  }
  //  @media (min-width: 1200px) {
  //   max-width:543;
  // }
  //  @media (min-width: 1300px) {
  //   max-width:653;
  // }
  // @media (min-width: 1600px) {
  //   max-width:1035px;
  //   width:650px;
  // }
  // @media (min-width: 1700px) {
  //   max-width:1035px;
  //   width:700px;
  // }
  @media (min-width: 1800px) {
    max-width: 1035px;
    width: 776px;
  }
  // @media (max-width: 1640px) {
  //     margin-left: 35px;
  //     max-width:321px;
  // }
`;
const CusButton = styled.button`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 10px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
border-style: ${(props) => (props.secondary ? "dotted" : "none")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
`;
const ButtonWrapper2 = styled.div`
  display: flex;
  justify-content: center;
`;
const IconSection = styled.img`
  margin-right: 5px;
  width: 30px;
  height: 30px;
  filter: ${(props) => (props.hovered ? 'invert(1)' : 'none')};
  transition: filter 0.2s ease-in-out;
`;
export default function AddNewIntern({ setUnAuth, coin, userUsedCoins }) {
  const navigate = useNavigate();
  const userRole = useSelector((state) => state.company.currentOrgData);
  const companyData = useSelector((state) => state.company.currentlySelectedcompanyData);
  const companies = useSelector((state)=> state.company.companyData)
  const companyStatus = companyData.status
 const tooltipContent = "Company approval is pending <br/>with mynext Admin"
  const { t, i18n } = useTranslation();

  var parentLeftMargin = i18n.language === "en" ? "-186px" : "-222px";
  var parentMinWidth = i18n.language === "en" ? "265px" : "285px";

  var customStyle =
    i18n.language === "en"
      ? { display: "flex", marginBottom: "19px", marginLeft: "111px" }
      : {
          display: "flex",
          marginBottom: "19px",
          paddingLeft: "206px",
          paddingRight: "42px",
        };
 
  const onNavigate = () => {
    navigate("/internships/internship-module-eligibility-check", { state: { origin: 'internship' } })
    // localStorage.setItem("isGeneral", "general");
  };
  const onNavigateForm = () =>{
    if (userRole?.user_details.internship_modify !== "Y") {
      return setUnAuth(true);
    }
  }

  // To handle create internship
  const onNavigateType = () =>{
    if (userRole?.user_details.internship_modify !== "Y") {
      return setUnAuth(true);
    }
    // navigate("/internships/temporary-view")
    navigate("/internships/createinternship/selectinternshiptype")
  }

  
  const [randomID, setRandomID] = useState(String(Math.random()))
  const data = [
    {
        id: 1,
        sampleName: "Internship Module 1",
        expiredDate: "02/02/2023"
    },
    {
        id: 2,
        sampleName: "Internship Guardian",
        expiredDate: "05/07/2023"
    }
]
  const Content =()=>{
  const [selectedCard, setSelectedCard] = useState([]);

  const handleCardClick = (cardId) => {
    if (selectedCard.includes(cardId)) {
      setSelectedCard(selectedCard.filter((id) => id !== cardId));
    } else {
      setSelectedCard([...selectedCard, cardId]);
    }
  };
    return(
      <div>
         {data.length > 0 ? (
                data.map((list, index) => (
                  <InternCard 
                  key={list.id}
                  data={list}
                  selected={selectedCard}
                  handleCardClick={handleCardClick}/>
                ))
              ) : (
                <p className="noDataFound">{t("No data found")}</p>
              )}
              <ButtonWrapper >
                <CusButton secondary onClick={onNavigateForm}>
                <IconSection src={UploadPlusIcon} /> Add
                </CusButton>
              </ButtonWrapper>
          
         <ButtonWrapper2>
         <Button
          name={"Create Internship"}
          onClick={onNavigateType}
              />
         </ButtonWrapper2>
      </div>
     
      
    )
  }
  return (
    <AddNewInternWrapper>
      {/* <Tooltip
        id="Applicationss"
        content="Total number of internship postings created</br>(All statuses)"
      /> */}
      <BackgroundContainer></BackgroundContainer>
      <StaticImg id="logoImage" src={Logo} alt="new intern" />
      <ContentWrapper>
        <h3>{t("Add new Internship")}</h3>
        <p className="custom-width">
          {t(
            "Create a new internship posting for your organisation. Seek for suitable talents within mynext"
          )}
          <br />
          <b style={{ fontSize: "13px" }}>
            {t(
              "Please note that internship posting via mynext is now a payable feature. As such, we are now offering plan packages to cater to your hiring needs."
            )}
          </b>
          <br />
          <b style={{ fontSize: "13px" }}>
            {t("For more information, kindly email us at")}{" "}
            <a
              style={{ color: "white", textDecoration: "none" }}
              href="mailto:company@mynext.my"
            >
              company@mynext.my
            </a>
            .
          </b>
        </p>
      </ContentWrapper>
      <div style={customStyle}>
        <div
          style={{
            minWidth: parentMinWidth,
            minHeight: "34px",
            background: "#FFFFFF",
            border: "3.01518px solid #EE4E9C",
            marginTop: "14px",
            borderRadius: "12px",
            marginLeft: parentLeftMargin,
          }}
        >
          <p
            style={{
              position: "absolute",
              fontFamily: "Oswald",
              fontStyle: "normal",
              fontWeight: "400",
              fontSize: "21.9611px",
              lineHeight: "27px",
              color: "#57508E",
              marginLeft: "11px",
            }}
          >
            {t("Internship Tokens")}
          </p>
        </div>

        <div
          style={{
            minWidth: "70px",
            minHeight: "69px",
            background:
              "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
            borderRadius: "36px",
            position: "absolute",
            marginLeft: "-26px",
            marginTop: "-4px",
          }}
        >
          <div
            style={{
              minWidth: "64px",
              minHeight: "64px",
              background: "rgb(40, 255, 0)",
              borderRadius: "36px",
              position: "absolute",
              marginLeft: "3px",
              marginTop: "2px",
            }}
          >
            <ReactTooltip />
            <p
              style={{
                height: "38px",
                fontFamily: "Oswald",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "31.5841px",
                lineHeight: "61px",
                background:
                  "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "#57508E",
                textAlign: "center",
              }}
              data-tip="Available"
            >
              {coin ?? ""}
            </p>
          </div>
        </div>

        <div
          style={{
            minWidth: "70px",
            minHeight: "69px",
            background:
              "linear-gradient(90deg, rgba(87,80,142,1) 35%, rgba(238,78,156,1) 100%)",
            borderRadius: "36px",
            position: "absolute",
            marginLeft: "49px",
            textAlign: "center",
            marginTop: "-4px",
          }}
        >
          <div
            style={{
              minWidth: "64px",
              minHeight: "64px",
              background: "#FFFF00",
              borderRadius: "36px",
              position: "absolute",
              marginLeft: "3px",
              marginTop: "2px",
              textAlign: "center",
            }}
          >
            <ReactTooltip />
            <p
              style={{
                height: "38px",
                fontFamily: "Oswald",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "31.5841px",
                lineHeight: "61px",
                background:
                  "linear-gradient(93.23deg, #57508E 3.59%, #EE4E9C 119.46%)",
                WebkitBackgroundClip: "text",
                WebkitTextFillColor: "#57508E",
                textAlign: "center",
              }}
              data-tip="Used"
            >
              {userUsedCoins ?? ""}
            </p>
          </div>
        </div>
        
      </div>
      {/* <img src={PlusIcon} onClick={onNavigate} alt="new intern add" /> */}
   {undefined != companyStatus ?
    'accepted' != companyStatus  ?
      <div >
        {/* <ReactTooltip className="dataToolTip"/> */}
        <img aria-describedby="none" data-for={randomID} data-tip={t(tooltipContent)} data-html={true} src={PlusIcon} alt="new company add" />

        {/* </img> */}
        <ReactTooltip id={randomID}  globalEventOff="true" />

        </div>
    
    : companies && companies[0]?.user_details.status != 'accepted' ?
    <div >
    {/* <ReactTooltip className="dataToolTip"/> */}
    <img aria-describedby="none" data-for={randomID} data-tip={t("Company access is pending.<br/> Please accept company Invite")} data-html={true} src={PlusIcon} alt="new company add" />

    {/* </img> */}
    <ReactTooltip id={randomID}  globalEventOff="true" />

    </div>
    :      <img onClick={()=>{
      onNavigate()
    }} src={PlusIcon} alt="new company add" /> 
    : 
    <img onClick={()=>{

    }} src={PlusIcon} alt="new company add" />
    }
   
    </AddNewInternWrapper>
  );
}
