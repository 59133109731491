import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import styled from "styled-components"
import logo from '../../assets/companylogosettings.png';

export default function CompanyCard({imageUrl,companyName}) {
    const Wrapper = styled.div`
      display:flex;
      max-width:480px;
      padding:12px 0px;
      padding-bottom:36px;
    `;
    const CompanyIcon = styled.img`
      width: 97.25px;
      height: 85px;
    `;
    const CompanyText = styled.p`
     font-family: 'General Sans';
     font-style: normal;
     font-weight: 500;
     font-size: 20px;
     line-height: 27px;
     color: #000000;
     height:40px;
     margin:auto 12px;
    `;

    const [orgimg, setOrgimg] = useState(undefined);
    const [orgname, setOrgname] = useState(undefined);
    const companies = useSelector((state) => state.company.companyData);
    const company = useSelector((state) => state.company.currentlySelectedcompanyData);

    function getOrgCode() {
      if (Object.keys(companies).length > 0) {
        for (let i = 0; i < Object.keys(companies).length; i++) {
          if (companies[i].org_name === company.name) {
            setOrgimg(companies[i].org_logo)
            setOrgname(companies[i].org_name)
            return
          }
        }
      }
    }

    useEffect(() => {
      getOrgCode()
    }, [company]);
  return (
    <Wrapper>
      {
        (orgimg)? <CompanyIcon src={orgimg}/>:<CompanyIcon src={logo}/>
      }

     {
        (orgname)? <CompanyText>{orgname}</CompanyText>:<></>
      }
     
    </Wrapper>
  )
}
