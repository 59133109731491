import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Comparision from "../../components/applicantsComparision/comparision";
import rightarrow from "../../assets/icons/arrow_right.png";
import ShortlistModal from "../../components/trackApplications/shortlistModal";
import {
    getDataWorkEmployability,
    getDataWorkFutureWorks,
    getDataWorkEnglishTest,
    getDataWorkPersonality,
    getDataWorkInterest,
    getMyCyOverall,
    getTalentOceanReport,
    getjobslist,
    changApplications,
} from "../../services/apiCalls";
import { useLocation, useNavigate } from "react-router-dom";
import { useSnapshot } from "valtio";
import { companyProxy } from "../myCompany/companyproxy";
import "./applicant.css"
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import Loader from "../../components/loader/loader";

const MainWrapper = styled.div`
  background: #ffffff;
  border-radius: 10px;
`;
const MainText = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  margin-bottom: 32px;
`;

export default function ApplicantsComparision() {
    useSnapshot(companyProxy);
    const [bulkObj, setBulkObj] = useState([]);
    const [actualData, setActualData] = useState([]);
    const [jobs, setJobs] = useState([]);
    const [profileData, setProfileData] = useState([]);
    const [popup, setPopup] = useState(false);
    const [shortlistedId, setShortListedId] = useState("");
    const [location, setLocation] = useState([]);
    const [education, setEducation] = useState([]);
    const [experience, setExperience] = useState([]);
    //const [status, setstatus] = useState([]);
    const [shortlistedName, setShortListedName] = useState("")
    const [isShortlisted, setAsShortlisted] = useState(false)
    const { t, i18n } = useTranslation();

    const state = useLocation();
    const tabIndex = state?.state?.tabIndex;
    const selectedData = state?.state?.selected;
    const status = state?.state?.selectedStatus;
    const [loading,setLoading] = useState(false);
    console.log("selectedDatastatus",status)
    const [id, setId] = useState(state?.state?.shortlist?.map((e) => e?.user_id));
    const [status1, setStatus1] = useState(state?.state?.selectedStatus?.map((e) => e?.status === "engage" || e?.status === "hire" ? e?.status + "d": e?.status + "ed" ? e?.status === "pending"? e?.status: e?.status +"ed": ""));
    console.log("statussss1",status1);
    const getDataEmployability = async (id) => {
        const { data } = await getDataWorkEmployability(id);
        if (data?.data?.domains) {
            let obj = {};
            obj["id"] = id;
            await Object.keys(data?.data?.domains).map((domain) => {
                obj[domain] = data?.data?.domains[domain];
            });
            setBulkObj((oldArray) => [...oldArray, obj]);
        }
    };

    const delay = ms => new Promise(
        resolve => setTimeout(resolve, ms)
      );
    const getDataFutureWorks = async (id) => {
        const { data } = await getDataWorkFutureWorks(id);
        if (data?.data?.domains) {
            let obj = {};
            obj["id"] = id;
            await Object.keys(data?.data?.domains).map((domain) => {
                obj[domain] = data?.data?.domains[domain];
            });
            setBulkObj((oldArray) => [...oldArray, obj]);
        }
    };

    const getCv = async (id) => {
        const { data } = await getMyCyOverall(id);
        let obj = {};
        obj["id"] = id;
        if (data?.data?.users) {
            obj["user"] = data?.data?.users;
            // await Object.keys(data?.data?.users).map((user) => {
            //     obj[user] = data?.data?.users[user];
            // });
            // setProfileData((oldArray) => [...oldArray, obj]);
        }
        if (data.data?.location?.length>0) {
            obj["location"] = data?.data?.location;
            // data.data?.location?.map((x, i) => {
            //    obj["location"] = 
            //     return setLocation((oldArray) => [...oldArray, x]);
            // });
        }
        if (data?.data?.education?.length>0) {
            obj["education"] = data?.data?.education;
            // data.data?.education?.map((a, i) => {
            //     return setEducation((oldArray) => [...oldArray, a]);
            // });
        }
        if (data?.data?.employement_details?.length>0) {
            obj["employement_details"] = data?.data?.employement_details;
            // data.data?.employement_details?.map((e, i) => {
            //     return setExperience((oldArray) => [...oldArray, e]);
            // });
        }
        setBulkObj((oldArray) => [...oldArray, obj]);
    }
    useEffect(async () => {
        await id?.map((e) => {
            getDataEmployability(e)
            getDataFutureWorks(e)
            getDataEnglishTest(e)
            getDataWorkPersonalityData(e)
            getDataForWorkInt(e)
            getCv(e)
            oceanReport(e)
        })
        await filterModels()

    }, [id])


    const getDataWorkPersonalityData = async (id) => {
        const { data } = await getDataWorkPersonality(id);
        if (data?.data) {
            let obj = {};
            obj["id"] = id;
            await Object.keys(data?.data).map((domain) => {
                obj[domain] = data?.data[domain];
            });
            setBulkObj((oldArray) => [...oldArray, obj]);
        }
    };
    const shortlist = async (item)  => {
        setLoading(true);
        await delay(1);
console.log(item,"itemmmmm",status1);
        let allStatus = status1;
      
        // state?.state?.userShortlis ted();
        const { data } = await changApplications("shortlist", item.id, {
            shortlist_jobposition: item.id,
            shortlist_tag: "shortlist_tag",
          });
          if (data) {
            // usersShortlisted();
           toast.success(t("Application Shortlisted"), {
             position: toast.POSITION.TOP_RIGHT,
           });
           allStatus[item.currentStatus] = "shortlisted";
           setStatus1(allStatus);
           setLoading(false);
         }else{
            setLoading(false);

         }
        // console.log("shortlist item",item)
        // setPopup(true)
        // setShortListedId(item?.id)
        // setShortListedName(item?.user?.display_name);
    
    }

    const getDataForWorkInt = async (id) => {
        const { data } = await getDataWorkInterest(id);
        if (data?.data) {
            let obj = {};
            obj["id"] = id;
            await Object.keys(data?.data).map((domain) => {
                obj[domain] = data?.data[domain];
            });
            setBulkObj((oldArray) => [...oldArray, obj]);
        }
    };
    const getDataEnglishTest = async (id) => {
        const { data } = await getDataWorkEnglishTest(id);
        if (data?.data?.General?.values) {
            let obj = {};
            obj["id"] = id;
          
            await Object.keys(data?.data?.General?.values).map((domain) => {
                obj[domain] = data?.data?.General?.values[domain];
            });
            setBulkObj((oldArray) => [...oldArray, obj]);
        }
    };

    console.log(profileData,"profileData")
    const oceanReport = async (id) => {
        const { data } = await getTalentOceanReport(id);
        if (data && data?.data?.data?.length > 0) {
            const wholeData = data?.data?.data[0];
            if (wholeData) {
                let obj = {};
                obj["id"] = id;
                let mainkeys = Object.keys(wholeData);
                await mainkeys.map(async (e) => {
                    await Object.keys(wholeData[e])?.map((it) => {
                        obj[it] = wholeData[e][it];
                    });
                });
                setBulkObj((oldArray) => [...oldArray, obj]);
            }
        }
    };

    const filterModels = async () => {
        let dt = []
        await id?.map((async(e) => {
            const filter = bulkObj.filter((x) =>x?.id ===e)
            let obj = {}
            obj["id"] = filter[0]?.id
            await filter.map((item) => {
                Object.keys(item).map((key) => {
                    if (key !== id) {
                        obj[key] = item[key]
                    }
                })
            })
            dt.push(obj)
           
        }))
        setActualData(dt)
    }
    console.log(actualData,"actual data")
    useEffect(async () => {
        await id?.map((e) => {
            getDataEmployability(e);
            getDataFutureWorks(e);
            getDataEnglishTest(e);
            getDataWorkPersonalityData(e);
            getDataForWorkInt(e);
            getCv(e);
            oceanReport(e);
        });
    }, [id]);


    useEffect(() => {
        filterModels();
    }, [bulkObj, profileData]);



    const navigate = useNavigate();

    const onNavigate = () => {
        navigate("/talentsearch");
    };
    console.log("idddd",id)
    useEffect(async () => {
        if (companyProxy.selectcompany) {
            const { data } = await getjobslist(companyProxy.selectcompany.org_code);
            if (data.status == 1) {
                setJobs(data.data);
            }
        }
    }, [companyProxy.selectcompany]);

    return (
        <>
            {popup ? (
                <ShortlistModal
                    open={popup}
                    usersShortlisted={()=>{
                        setAsShortlisted(true);
                    }}
                    onClose={() => setPopup(false)}
                    id={shortlistedId}
                    joblist={jobs}
                    name={shortlistedName}
                />
            ) : null}
            <MainLayout>
                {state?.state?.type === "trackapplications" ? (
                    <div className="breadcrumb">
                        <p
                            style={{ cursor: "pointer" }}
                            onClick={() => navigate("/track-applications")}
                        >
                            {t("Track Applications")}
                        </p>
                        <img
                            src={rightarrow}
                            alt="img"
                            className="applicantAroow"
                           
                        />
                        <p className="appName" >{t("Comparison")}</p>
                    </div>
                ) : state?.state?.type === "graduate" ? (
                    <div className="breadcrumb">
                        <p style={{ cursor: "pointer" }} onClick={onNavigate}>
                            {t("Talent Search")}
                        </p>
                        <img
                            src={rightarrow}
                            alt="img"
                            className="applicantAroow"
                        />
                        <p
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate("/talentsearch", { state: { tabIndex: 1 } })
                            }
                        >
                            {t("Graduate Talent Finder")}
                        </p>
                        <img
                            src={rightarrow}
                            alt="img"
                            className="applicantAroow"
                        />
                        <p style={{ color: "#D04C8D" }}>{t("Comparison")}</p>
                    </div>
                ) : (
                    <div className="breadcrumb">
                        <p style={{ cursor: "pointer" }} onClick={onNavigate}>
                            {t("Talent Search")}
                        </p>
                        <img
                            src={rightarrow}
                            alt="img"
                            className="applicantAroow"
                        />
                        <p
                            style={{ cursor: "pointer" }}
                            onClick={() =>
                                navigate("/talentsearch", { state: { tabIndex: 3 } })
                            }
                        >
                            {t("Shortlist")}
                        </p>
                        <img
                            src={rightarrow}
                            alt="img"
                            className="applicantAroow"
                        />
                        <p style={{ color: "#D04C8D" }}>{t("Comparison")}</p>
                    </div>
                )}
                <MainText>{t("Applicants Comparison")}</MainText>
                <MainWrapper>
                    {
                         loading == false ?
                        <Comparision
                         shortlistIds={state?.state?.shortlistId}
                        isUserShortlisted={isShortlisted}
                        data={actualData}
                        shortlist={shortlist}
                        userData={state?.state?.shortlist}
                        tabIndex={tabIndex}
                        profileData={profileData}
                        location={location}
                        education={education}
                        experience={experience}
                        selectedData={selectedData}
                        status={status1}
                    /> : <Loader></Loader>}
                </MainWrapper>
            </MainLayout>
        </>
    );
}
