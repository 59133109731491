import { Modal } from "react-bootstrap";
import { ModalHeader, ModalHeaderText, ModalHeaderTextTwo } from "../../pages/employeeManagement/indexComponents";
import styled from "styled-components";

const CustomModal = styled(Modal)`
  .modal-dialog {
    width: 500px;
    max-width: 100%; 
  }
`;

function UploadBulkIntern(props) {
  return (
    <div> 
      <CustomModal show={props.show} onHide={props.close} backdrop="static">
          <div style={{ padding: '9px' }}>
          <ModalHeader></ModalHeader>
          <ModalHeaderText>{props.title}</ModalHeaderText>
          <ModalHeaderTextTwo>{props.subTitle}</ModalHeaderTextTwo>
          {props.content}
        </div>
      </CustomModal>
    </div>
  );
}

export default UploadBulkIntern;