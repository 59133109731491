import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import Union from '../../assets/Union.png';
import Dropdown from "../dropdown/dropdown";
import RadioInput from '../radioInput/radioInput';
import { Colors } from '../../utilities/colors';
import Info from "../../assets/icons/info.png"
import "./jobStyles.css"
import { useNavigate } from "react-router-dom";
import { useSnapshot } from 'valtio';
import { jobProxy } from '../../pages/jobPortal/jobproxy';
import { getAllCountries, getScopeOfStudyMasterList, getSkillMasterList, getYearOfStudies, viewapplicationList } from '../../services/apiCalls';
import Loader from '../loader/loader';
import { useTranslation } from "react-i18next";

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginLeft:'20px';

  @media (max-width: 767px) {
    // display: none;
  }
`;
const InputBox = styled.div`
 margin-top: 20px;
 margin-right: 20px;
 width:90%;
 height:54px; 
 @media (max-width: 500px) {
  width:100%;
}
`;


const FilterIcon = styled.div`
  background: rgba(255, 255, 255, 1);
  margin:15px 20px 0px 0px;
  width: 72px;
  height: 54px;
  border-radius: 93px;

`
const FilterImg = styled.div`
  width:48px;
  height:28px;
  padding:10px 0px 0px 0px;
  margin:3px 0px 0px 10px;
  @media (max-width: 500px) {
    position:relative;
    bottom:50px;
    left:82%;
    width:22px;
    height:15px;
}
`
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}
`;
const FormWrapper = styled.div`
  display:grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
  
`;
const MainWrapper = styled.div`
  background:#FFFFFF;
  padding:32px 32px 12px 32px;
  border-radius:24px;
  @media (max-width: 500px) {
    padding:24px 24px 24px 32px;
  }
`;
const GroupWrapper = styled.div`
  padding:12px;
`;
const MainText = styled.p`
  padding:12px;
  font-family: 'General Sans';
  font-style: italic;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #858585;
  margin-bottom:0px;
`;
const SpanText = styled.span`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 30px;
  color: #D04C8D;
  margin-right:12px;

`;


export default function JobsFilter() {
  useSnapshot(jobProxy)
  const [searchval, setSearchFilter] = useState("");
  const [viewFilters, setViewFilters] = useState(false)
  const { t, i18n } = useTranslation();
  const [nationality, setNationality] = useState(1)
  const [scopeOfStudies, setScopeOfStudies] = useState([]);
  const [selectedScope, setSelectedScope] = useState([]);
  const [skills, setSkills] = useState([]);
  const [selectedSkills, setSelectedSkils] = useState([]);
  const [year, setYear] = useState([]);
  const [selectedYear, setSelectedYear] = useState([]);
  const [country, setCountry] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState([]);
  const [enval, setEnval] = useState({ label: "Level 3", value: "0.6" });
  const [loading, setLoader] = useState(false);

  const english = [
    { label: t("Level 1"), value: "0.2" },
    { label: t("Level 2"), value: "0.4" },
    { label: t("Level 3"), value: "0.6" },
    { label: t("Level 4"), value: "0.8" },
    { label: t("Level 5"), value: "1" },
  ];
  const filterViewHandler = () => {
    setViewFilters(!viewFilters)
  }
  const navigate = useNavigate();
  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    search("")
  };
  const ch_low_high = (v) => {
    let l = v
    if (v == 'N') {
      l = 'low'
    }
    if (v == 'Y') {
      l = 'high'
    }
    switch (l) {
      case 'low':
        return "0.2"
      case 'medium_low':
        return "0.4"
      case 'medium':
        return "0.6"
      case 'medium_high':
        return "0.8"
      case 'high':
        return "1"
    }
  }

  const gettalenper = async (d, user_id) => {
    let k = {
      "research": ch_low_high(d['research_evaluate']),
      "inception": ch_low_high(d['inception_formation']),
      "communication": ch_low_high(d['communication_presentation']),
      "leadership": ch_low_high(d['leadership']),
      "teamwork": ch_low_high(d['teamwork']),
      "collectedness": ch_low_high(d['collectedness']),
      "plan": ch_low_high(d['plan_execte']),
      "inventive": ch_low_high(d['inventive_accomplishment']),
      "ability": ch_low_high(d['ability_to_influence']),
      "comfort": ch_low_high(d['comfort_with_ambiguity']),
      "achievement": ch_low_high(d['achievement_orientation']),
      "individualism": ch_low_high(d['individualism_vs_collectivism']),
      "receiptiveness": ch_low_high(d['receiptiveness_to_change']),
      "space": ch_low_high(d['space_orientation']),
      "english": enval.value,
      "user_id": `'${user_id}'`
    }
  }
  const search = async () => {
    setLoader(true)
    let req = {}
    req.search = searchval.trim()
    req.filter = '0'
    req.jobid = jobProxy.jobid
    if (viewFilters) {
      let a = []
      let b = []
      let c = []
      let d = []

      req.filter = '1'
      for (let i = 0; i < selectedScope.length; i++) {
        a.push(selectedScope[i].value)
      }
      for (let i = 0; i < selectedSkills.length; i++) {
        b.push(selectedSkills[i].label)
      }
      for (let i = 0; i < selectedCountry.length; i++) {
        c.push(selectedCountry[i].value)
      }
      for (let i = 0; i < selectedYear.length; i++) {
        d.push(selectedYear[i].value)
      }

      req.nationality = nationality
      req.scope = a
      req.skill = b
      req.country = c
      req.year = d

    }
    req.pageindex = jobProxy.paginationTabIndex
    const { data } = await viewapplicationList(req);
    if (data.status === 1) {
      let da = data.data;
      // for (let i = 0; i < data.data.length; i++) {
      //   da[i]["percentage"] = await gettalenper(jobProxy.jobdata, data.data[i]["user_id"])
      // }
      jobProxy.setdata(da)
      let quo = Math.floor(data.count / 5);
      let rem = data.count % 5;
      jobProxy.setPaginationCount(rem !== 0 ? quo + 1 : quo);
    }
    setLoader(false)
  }
  const getscope = async () => {
    if (scopeOfStudies.length === 0) {
      const { data } = await getScopeOfStudyMasterList();
      if (data.status === 1) {
        const scopeOfStudydata = data.data.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setScopeOfStudies(scopeOfStudydata);
      }
    }
  };

  const getskils = async (e) => {
    if (e.trim().length !== 0) {
      const { data } = await getSkillMasterList(e);
      if (data.status === 1) {
        const slicedArray = data.data.slice(0, 100);
        const skillsdata = slicedArray.map((x) => {
          return { ...x, label: x.Example, value: x.Id };
        });
        setSkills(skillsdata);
      }
    }
  };

  const getcountry = async () => {
    if (country.length === 0) {
      const { data, status } = await getAllCountries();
      if (status === 200) {
        if (data.status === 1) {
          const location = data.data;
          const country = location.map((x) => {
            return { ...x, label: x.Name, value: x.Id };
          });
          setCountry(country);
          if (country[131] !== undefined) {
            setSelectedCountry([country[131]]);
          }
        }
      }
    }
  };

  const getYear = async () => {
    if (country.length === 0) {
      const { data, status } = await getYearOfStudies();
      if (status === 200) {
        if (data.status === 1) {
          const yearArray = data.data;
          const yearData = yearArray.map((x) => {
            return { ...x, label: x.Name, value: x.Id };
          });
          setYear(yearData);
        }
      }
    }
  };

  const scopeAddChange = (val) => {

    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setSelectedScope(newVal);
  };

  const skillsAddChange = (val) => {

    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setSelectedSkils(newVal);
  };

  const countryAddChange = (val) => {
    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setSelectedCountry(newVal);
  };

  const yearAddChange = (val) => {
    const newVal = val.map((x) => {
      return { ...x, scope: x.value };
    });
    setSelectedYear(newVal);
  };

  const changeenglish = (val) => {
    setEnval(val)
  };

  useEffect(async () => {
    if (jobProxy?.jobid?.trim().length == 0) {
      navigate('/jobs')
    } else {
      await getscope()
      await getcountry()
      await getYear()
    }


  });



  useEffect(async () => {
    if (jobProxy.jobid.trim().length != 0) {
      await search();
    }

  }, [jobProxy.paginationTabIndex]);



  return (
    <>
      {loading ? <Loader /> : <div>
        <CompanySection >
          <InputBox>
            <Input
              color="#fff"
              placeholder={t("Search Keywords for Applicants Name")}
              value={searchval}
              onChange={onSearch}
            />
          </InputBox>

          <FilterIcon className="hideSearchBars">
            <FilterImg>
              <img src={Union} alt="imgUnion" style={{ cursor: "pointer" }} onClick={filterViewHandler} />
            </FilterImg>
          </FilterIcon>

          <div className="hideSearchBars" style={{ marginLeft: "0px" }}>
            <Button style={{ cursor: 'pointer' }} name={t("Search")} onClick={search} />
          </div>

        </CompanySection>
        {viewFilters
          ?
          <MainWrapper>
            <MainText> <SpanText>{t("Filter")}</SpanText>{t("based on job position posted")}</MainText>
            <FormWrapper>
              <GroupWrapper>
                <FormLabel>{t("Scope of Study")} <img src={Info} alt='' /></FormLabel>
                <Dropdown
                  search={true}
                  options={scopeOfStudies}
                  placeholder={t("Select Scope of Study")}
                  val={selectedScope}
                  change={scopeAddChange}
                  isMulti={true}

                />

              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Skills")} <img src={Info} alt='' /></FormLabel>
                <Dropdown
                  search={true}
                  options={skills}
                  placeholder={t("Select Skills")}
                  val={selectedSkills}
                  change={skillsAddChange}
                  isMulti={true}
                  inputChange={(e) => {
                    getskils(e)
                  }}
                />
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Locations")} <img src={Info} alt='' /></FormLabel>
                <Dropdown
                  search={true}
                  options={country}
                  placeholder={t("Select Locations")}
                  val={selectedCountry}
                  change={countryAddChange}
                  isMulti={true}

                />
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Level of Study")} <img src={Info} alt='' /></FormLabel>
                <Dropdown
                  search={true}
                  options={year}
                  placeholder={t("Select Level of Study")}
                  val={selectedYear}
                  change={yearAddChange}
                  isMulti={true}
                />
              </GroupWrapper>
              <GroupWrapper>
                <FormLabel>{t("Minimum English Proficiency")} <img src={Info} alt='' /></FormLabel>
                <Dropdown
                  name="country"
                  options={english}
                  placeholder={t("Select Minimum English Proficiency")}
                  minWidth="auto"
                  val={enval?.value}
                  label={enval?.label}
                  change={changeenglish}

                />
              </GroupWrapper>
              <GroupWrapper></GroupWrapper>
              <GroupWrapper style={{ display: "flex", flexDirection: "column" }}>
                <FormLabel>{t("Nationality")}*</FormLabel>
                <div className="filterJob">
                  <RadioInput

                    checked={nationality === 1}
                    onChange={() => setNationality(1)}
                    label={"Malaysian"}
                  />
                  <RadioInput

                    checked={nationality === 0}
                    onChange={() => setNationality(0)}
                    label={"Non Malaysian"}
                  />
                </div>
              </GroupWrapper>
              <GroupWrapper></GroupWrapper>
            </FormWrapper>

            <GroupWrapper className="filterButtonWrapper">
              <div></div>
              <Button
                style={{ marginTop: "0px" }}
                onClick={search}
                name={t("Search")}
              />
            </GroupWrapper>
          </MainWrapper>
          : <div />}
      </div>}


    </>

  )
}
