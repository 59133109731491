import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import { Form, Row, Col } from "react-bootstrap";
import Input from "../../components/input/input";
import { Controller, useForm, FormProvider  } from "react-hook-form";
import schema from "../../pages/MySIPDirectHiring/addInternValidation";
import { yupResolver } from "@hookform/resolvers/yup";
import Dropdown from "../dropdown/dropdown";
import { MuiPickersUtilsProvider, DatePicker } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { ThemeProvider } from "@material-ui/styles";
import { ReactComponent as CalenderIcon } from "../../assets/icons/svg/calendarIcon.svg";
import { createTheme } from "@material-ui/core";
import Button from "../button/button";
import ErrorField from "../errorField/errorField";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { formatDate } from "../../utilities/commonFunctions";
import { useSelector } from "react-redux";
import { initialAddIntern } from "../../pages/MySIPDirectHiring/addInternValidation";

const materialTheme = createTheme({
    palette: {
      primary: {
        main: "#D44B9C",
      },
    },
    overrides: {
      MuiPickersCalendarHeader: {
        switchHeader: {
          color: "#D44B9C",
        },
      },
    },
  });

  const CalenderIconWrapper = styled.div`
  position: absolute;
  right: 1rem;
  top: 0.5rem;
  cursor: pointer;
`;
const TitleSection = styled.div`
margin-top: 10px;
padding: 9px;
`;
const TitleDescription = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;

const CardContainer = styled.div`
  margin-top: 2px;
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
export const SelectorWrapper = styled.div`
  width: 100%;
`;

const GroupWrapper = styled.div`
  margin-bottom: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 30px;
`;

export default function AddInternForm ({closeModal2}){
  const {
    control: controlIntern,
    watch: addWatch,
    reset: resetForm,
    setError: setErrorCustom,
    setValue:setValueStudent,
    handleSubmit,
    formState: { errors: errorsAddForm },
  } = useForm({
    defaultValues: { ...initialAddIntern },
    resolver: yupResolver(schema),
    mode: "onSubmit",
  });

      const formVal = addWatch();
      // console.log("DATA>>>>"+formVal.identification);
      console.log("DATA>>>>"+formVal.graduate_date);
      
      const handleSave = () => {
        console.log("DATA>>>>"+formVal.first_name);
        
      };

      const inputChange = (e) => {
        const { name } = e.target;
        let value = e.target.value;
    
        console.log(value, name);
    
        if (value) {
          switch (name) {
            case "id_number":
              if (formVal.id_type == 1) {
                var oldValue = ("" + value).replace(/\D/g, "");
    
                var isNum = true;
                if (oldValue.length > 0) {
                  let lastDigit = oldValue.charAt(oldValue.length - 1);
                  isNum = !isNaN(lastDigit);
                }
    
                let newValue = `${oldValue.substring(0, 6)}${
                  oldValue.length > 6 ? "-" : ""
                }${oldValue.substring(6, 8)}${
                  oldValue.length > 8 ? "-" : ""
                }${oldValue.substring(8, 12)}`;
    
                if (/^[a-zA-Z\d-]+$/.test(oldValue) && isNum) {
                  setValueStudent("id_number", newValue);
                  // setValueStudent("graduation_date", null);
                  // setValueStudent("internship_expected_start_date", null);
                  // setValueStudent("internship_expected_end_date", null);
                }
              } else {
                formVal.id_number = formVal?.id_number?.toUpperCase();
                setValueStudent(
                  "id_number",
                  /^[a-zA-Z\d]+$/.test(value?.toUpperCase())
                    ? value?.toUpperCase()
                    : formVal.id_number
                );
                // setValueStudent("graduation_date", null);
                // setValueStudent("internship_expected_start_date", null);
                // setValueStudent("internship_expected_end_date", null);
              }
              break;
            case "disability_code":
              setValueStudent(
                name,
                /^[0-9]+$/.test(value) ? value : formVal.disability_code
              );
              break;
            default:
              break;
          }
        } else {
          setValueStudent(name, value);
        }
      };

      const dateChange = (name, val) => {
        setValueStudent(name, formatDate(val));
      };
      const onSubmitErrorHandler = () => {
        if (!formVal.first_name || formVal.first_name.trim().length === 0) {
          toast.error("Fill out all  the mandatory fields", {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
        
      };
      
      
    return(
      <div>
       <form onSubmit={handleSubmit(handleSave, onSubmitErrorHandler)}> 
            <TitleSection>
            <TitleDescription>Intern Personal Information</TitleDescription>
            <hr style={{opacity:0.1, marginTop: '-10px'}}/>
            </TitleSection>
            <CardContainer>
                <GroupWrapper>
                <Row>
                    <Col md="4">
                        <FormLabel>First Name*</FormLabel>
                        <Controller
                            name="first_name"
                            control={controlIntern}
                            render={({ field }) => (
                              <>
                              <Input
                                // className="w-100"
                                // disabled=  {fromEdit ? true:false}
                                placeholder={"Enter first name"}
                                {...field}
                            />
                              </>
                            
                    )}
                  />
                  {errorsAddForm.first_name && (
                    <ErrorField err={errorsAddForm.first_name.message}/>
                  )}
                    </Col>
                    <Col md="4">
                        <FormLabel>Email Address*</FormLabel>
                        <Controller
                        name="email"
                        control={controlIntern}
                        render={({field})=>(
                           <>
                            <Input
                            {...field}
                            placeholder="Enter email address"
                            />
                           </>
                            )}/>
                            {errorsAddForm.email && (
                              <ErrorField err={errorsAddForm.email.message}/>
                            )}
                    </Col>
                    <Col md="4">
                        <FormLabel>Gender*</FormLabel>
                        <SelectorWrapper
                    style={{ display: "flex", paddingLeft: "20px" }}
                    className="w-100 mt-4"
                  >
                        <Controller
                        name="gender"
                        control={controlIntern}
                        render={({
                            field: { onChange, onBlur, value, ref },
                            fieldState: { error },}) => (
                                <>
                                <input
                            type="radio"
                            id="gender"
                            name="gender"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="male"
                            checked={value === "male" || value === "Male"}
                          />
                          <label htmlFor="gender">{"Male"}</label>
                          <input
                            type="radio"
                            id="secondarygender"
                            name="gender"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="female"
                            style={{ marginLeft: 20 }}
                            checked={value === "female" || value === "Female"}
                          />
                          <label htmlFor="secondarygender">{"Female"}</label>
                                </>
                                
                        )}
                        />
                        </SelectorWrapper>
                        {errorsAddForm.gender && (
                          <ErrorField err={errorsAddForm.gender.message}/>
                        )}
                    </Col>
                </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                    <Col md="4">
                        <FormLabel>Identification*</FormLabel>
                        <div style={{paddingLeft: "15px"}}>
                        <Controller
                      control={controlIntern}
                      name="id_type"
                      defaultValue="1"
                      render={({
                        field: { onChange, onBlur, value, ref },
                        fieldState: { error },
                      }) => (
                        <>
                            <div style={{display: "flex", flexDirection: "row", marginBottom: 5}}>
                          <input
                            type="radio"
                            id="identification"
                            name="id_type"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="1"
                            checked={value == "1"}
                          />
                          <label htmlFor="identification">{"IC"}</label>
                          <input
                            type="radio"
                            id="secondaryidentification"
                            name="id_type"
                            onChange={onChange}
                            onBlur={onBlur}
                            value="0"
                            style={{ marginLeft: 60 }}
                            checked={value == "0"}
                          />
                          <label style={{fontSize: 14}}>{"Passport"}</label>
                            </div>
                        </>
                      )}
                    />
                        </div>
                          <Row>
                            <Col md="12">
                            <Controller
                            name="id_number"
                            control={controlIntern}
                            render={({ field }) => (
                              <Input
                                
                                placeholder={"Enter Identification Number"}
                                {...field}
                                onChange={inputChange}
                                maxLength={formVal.id_type == 1 ? 14 : 16}
                              />
                            )}
                          />
                            </Col>
                          </Row>
                          {errorsAddForm.identification && (
                            <ErrorField err={errorsAddForm.identification.message}/>
                          )}
                    </Col>
                    <Col md="4">
                        <FormLabel>Mobile No*</FormLabel>
                        <Controller
                        name="mobile"
                        control={controlIntern}
                        render={({field}) =>(
                            <Input
                            {...field}
                            placeholder="Enter mobile no"/>
                        )}/>
                        {errorsAddForm.mobile && (
                          <ErrorField err={errorsAddForm.mobile.message}/>
                        )}
                    </Col>
                    <Col md="4">
                        <FormLabel>Address 1*</FormLabel>
                        <Controller
                        name="address1"
                        control={controlIntern}
                        render={({field}) =>(
                            <Input
                            {...field}
                            placeholder="Enter address 1"/>
                        )}/>
                        {errorsAddForm.address1 && (
                          <ErrorField err={errorsAddForm.address1.message}/>
                        )}
                    </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Address 2*</FormLabel>
                            <Controller
                                name="address2"
                                control={controlIntern}
                                render={({field}) =>(
                                    <Input
                                    placeholder="Enter address 2"/>
                                )}/>
                                {errorsAddForm.address2 && (
                                  <ErrorField err={errorsAddForm.address2.message}/>
                                )}
                        </Col>

                        <Col md="4">
                            <FormLabel>City *</FormLabel>
                            <Controller
                                name="city"
                                control={controlIntern}
                                render={({field}) =>(
                                    <Input
                                    placeholder="Enter city"
                                    {...field}
                                    />
                                )}/>
                                {errorsAddForm.city && (
                                  <ErrorField err={errorsAddForm.city.message}/>
                                )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Postcode*</FormLabel>
                            <Controller
                                name="postcode"
                                control={controlIntern}
                                render={({field}) =>(
                                    <Input
                                    placeholder="Enter postcode"
                                    {...field}
                                    />
                                )}/>
                                {errorsAddForm.postcode && (
                                  <ErrorField err={errorsAddForm.postcode.message}/>
                                )}
                        </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>State *</FormLabel>
                            <Controller
                                name="state"
                                control={controlIntern}
                                render={({field}) =>(
                                    <Input
                                    placeholder="Enter state"
                                    {...field}
                                    />
                                )}/>
                                {errorsAddForm.state && (
                                  <ErrorField err={errorsAddForm.state.message}/>
                                )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Race *</FormLabel>
                            <Controller
                                name="race"
                                control={controlIntern}
                                render={({field}) =>(
                                    <Input
                                    placeholder="Enter race"
                                    {...field}
                                    />
                                )}/>
                                {errorsAddForm.race && (
                                  <ErrorField err={errorsAddForm.race.message}/>
                                )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Graduate Date*</FormLabel>
                                <Controller
                            name="graduate_date"
                            control={controlIntern}
                            render={({ field }) => (
                              <label style={{ width: "max-content" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                    style={{marginTop: "-5px"}}
                                      autoOk
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      placeholder="dd/mm/yyyy"
                                      views={["year", "month", "date"]}
                                      value={
                                        !formVal.graduate_date
                                          ? null
                                          : formVal.graduate_date &&
                                            formVal.graduate_date === "None"
                                          ? null
                                          : formVal.graduate_date === ""
                                          ? null
                                          : new Date(formVal.graduate_date)
                                      }
                                      fullWidth
                                      onChange={(val) => {
                                        dateChange("graduate_date", val);
                                        console.log(formVal.graduate_date);
                                      }}
                                      // minDate={new Date().setDate(
                                      //   new Date().getDate() + 14
                                      // )}
                                      // minDate={id && code ? ""}
                                    //   minDate={
                                    //     id && org_code
                                    //       ? ""
                                    //       : new Date().setDate(
                                    //           new Date().getDate()
                                    //         )
                                    //   }
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                                {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                              </label>
                            )}
                          />
                          {errorsAddForm.graduate_date && (
                            <ErrorField err={errorsAddForm.graduate_date.message}/>
                          )}
                        </Col>
                    </Row>
                </GroupWrapper>
            </CardContainer>

           <TitleSection>
            <TitleDescription>Education Information</TitleDescription>
            <hr style={{opacity:0.1, marginTop: '-10px'}}/>
           </TitleSection>
           <CardContainer>
            <GroupWrapper>
                <Row>
                    <Col md="4">
                        <FormLabel>Campus*</FormLabel>
                        <Controller
                        name="campus"
                        control={controlIntern}
                        render={({field}) =>(
                            <Dropdown
                        name="country"
                        // search={true}
                        // options={scope?.map((e) => {
                        //   return { ...e, label: e.Name, value: e.Id };
                        // })}
                        // val={selectedScope?.value}
                        // label={selectedScope?.label}
                        // change={(val) => dropdownChange("scope", val)}
                        placeholder={"Campus"}
                        minWidth="auto"
                        isClearable={true}
                      />
                        )}
                        />
                        {errorsAddForm.campus && (
                          <ErrorField err={errorsAddForm.campus.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>Faculty*</FormLabel>
                        <Controller
                        name="faculty"
                        control={controlIntern}
                        render={({field}) =>(
                            <Dropdown
                        name="faculty"
                        // search={true}
                        // options={scope?.map((e) => {
                        //   return { ...e, label: e.Name, value: e.Id };
                        // })}
                        // val={selectedScope?.value}
                        // label={selectedScope?.label}
                        // change={(val) => dropdownChange("scope", val)}
                        placeholder={"Faculty"}
                        minWidth="auto"
                        isClearable={true}
                      />
                        )}
                        />
                        {errorsAddForm.faculty && (
                          <ErrorField err={errorsAddForm.faculty.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>Study Program</FormLabel>
                        <Controller
                        name="study_program"
                        control={controlIntern}
                        render={({field}) => (
                            <Input
                            placeholder="Enter study program"/>
                        )}
                        />
                        {errorsAddForm.study_program && (
                          <ErrorField err={errorsAddForm.study_program.message}/>
                        )}
                    </Col>
                </Row>
            </GroupWrapper>

            <GroupWrapper>
                <Row>
                    <Col md="4">
                        <FormLabel>Scope of Study*</FormLabel>
                        <Controller
                        name="scope_of_study"
                        control={controlIntern}
                        render={({field}) =>(
                            <Dropdown
                        name="scope_of_study"
                        // search={true}
                        // options={scope?.map((e) => {
                        //   return { ...e, label: e.Name, value: e.Id };
                        // })}
                        // val={selectedScope?.value}
                        // label={selectedScope?.label}
                        // change={(val) => dropdownChange("scope", val)}
                        placeholder={"Scope of study"}
                        minWidth="auto"
                        isClearable={true}
                      />
                        )}
                        />
                        {errorsAddForm.scope_of_study && (
                          <ErrorField err={errorsAddForm.scope_of_study.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>CGPA*</FormLabel>
                        <Controller
                        name="cgpa"
                        control={controlIntern}
                        render={({field}) => (
                            <Input
                            {...field}
                            placeholder="Enter CGPA"
                            />
                        )}
                        />
                        {errorsAddForm.cgpa && (
                          <ErrorField err={errorsAddForm.cgpa.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>Year of Study</FormLabel>
                        <Controller
                        name="year_of_study"
                        control={controlIntern}
                        render={({field}) =>(
                            <Dropdown
                        name="year_of_study"
                        // search={true}
                        // options={scope?.map((e) => {
                        //   return { ...e, label: e.Name, value: e.Id };
                        // })}
                        // val={selectedScope?.value}
                        // label={selectedScope?.label}
                        // change={(val) => dropdownChange("scope", val)}
                        placeholder={"Year of study"}
                        minWidth="auto"
                        isClearable={true}
                      />
                        )}
                        />
                        {errorsAddForm.year_of_study && (
                          <ErrorField err={errorsAddForm.year_of_study.message}/>
                        )}
                    </Col>
                </Row>
            </GroupWrapper>

            <GroupWrapper>
                <Row>
                    <Col md="4">
                        <FormLabel>Semester*</FormLabel>
                        <Controller
                        name="semester"
                        control={controlIntern}
                        render={({field}) =>(
                            <Dropdown
                        name="semester"
                        // search={true}
                        // options={scope?.map((e) => {
                        //   return { ...e, label: e.Name, value: e.Id };
                        // })}
                        // val={selectedScope?.value}
                        // label={selectedScope?.label}
                        // change={(val) => dropdownChange("scope", val)}
                        placeholder={"Semester"}
                        minWidth="auto"
                        isClearable={true}
                      />
                        )}
                        />
                        {errorsAddForm.semester && (
                          <ErrorField err={errorsAddForm.semester.message}/>
                        )}
                    </Col>

                     <Col md="4">
                        <FormLabel>Status*</FormLabel>
                        <Controller
                        name="status"
                        control={controlIntern}
                        render={({field}) => (
                            <Input
                            {...field}
                            placeholder="Enter status"
                            />
                        )}
                        />
                        {errorsAddForm.status && (
                          <ErrorField err={errorsAddForm.status.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>Academic Qualifications *</FormLabel>
                        <Controller
                        name="academic_qualification"
                        control={controlIntern}
                        render={({field}) => (
                            <Input 
                            {...field}
                            placeholder="Enter academic qualification"
                            />
                        )}
                        />
                        {errorsAddForm.academic_qualification && (
                          <ErrorField err={errorsAddForm.academic_qualification.message}/>
                        )}
                    </Col>
                </Row>
            </GroupWrapper>

            <GroupWrapper>
                <Row>
                    <Col md="4">
                        <FormLabel>Vulnerable Group*</FormLabel>
                        <Controller
                        name="vulnarable_group"
                        control={controlIntern}
                        render={({field}) =>(
                            <Input
                            {...field}
                            placeholder="Enter vulnarable group"
                            />
                        )}
                        />
                        {errorsAddForm.vulnerable_group && (
                          <ErrorField err={errorsAddForm.vulnerable_group.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>Course *</FormLabel>
                        <Controller 
                        name="course"
                        control={controlIntern}
                        render={({field}) => (
                            <Input
                            {...field}
                            placeholder= "Enter course"
                            />
                        )}
                        />
                        {errorsAddForm.course && (
                          <ErrorField err={errorsAddForm.course.message}/>
                        )}
                    </Col>

                    <Col md="4">
                        <FormLabel>Skill Level*</FormLabel>
                        <Controller
                        name="skill_level"
                        control={controlIntern}
                        render={({field}) => (
                            <Input
                            {...field}
                            placeholder= "Skill Level"
                            />
                        )}
                        />
                        {errorsAddForm.skill_level && (
                          <ErrorField err={errorsAddForm.skill_level.message}/>
                        )}
                    </Col>
                </Row>
            </GroupWrapper>
            <GroupWrapper>
                <Row>
                    <Col md="4">
                        <FormLabel>Training Status*</FormLabel>
                        <Controller 
                        name="training_status"
                        control={controlIntern}
                        render={({field}) => (
                            <Input
                            {...field}
                            placeholder="Enter training status"
                            />
                        )}
                        />
                        {errorsAddForm.training_status && (
                          <ErrorField err={errorsAddForm.training_status.message}/>
                        )}
                    </Col>
                </Row>
            </GroupWrapper>
           </CardContainer>

           <TitleSection>
            <TitleDescription>Internship Details</TitleDescription>
            <hr style={{opacity:0.1, marginTop: '-10px'}}/>
           </TitleSection>
            <CardContainer>
                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Expected Internship Start Date*</FormLabel>
                            <Controller
                            name="expected_start_date"
                            control={controlIntern}
                            render={({ field }) => (
                              <label style={{ width: "max-content" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                    style={{marginTop: "-5px", marginTop: "20px"}}
                                      autoOk
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      placeholder="dd/mm/yyyy"
                                      views={["year", "month", "date"]}
                                      value={
                                        !formVal.expected_start_date
                                          ? null
                                          : formVal.expected_start_date &&
                                            formVal.expected_start_date === "None"
                                          ? null
                                          : formVal.expected_start_date === ""
                                          ? null
                                          : new Date(formVal.expected_start_date)
                                      }
                                      fullWidth
                                      onChange={(val) => {
                                        dateChange("expected_start_date", val);
                                        // console.log(formVal.intern_dur_year);
                                      }}
                                      // minDate={new Date().setDate(
                                      //   new Date().getDate() + 14
                                      // )}
                                      // minDate={id && code ? ""}
                                    //   minDate={
                                    //     id && org_code
                                    //       ? ""
                                    //       : new Date().setDate(
                                    //           new Date().getDate()
                                    //         )
                                    //   }
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                                {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                              </label>
                            )}
                          />
                          {errorsAddForm.expected_start_date && (
                          <ErrorField err={errorsAddForm.expected_start_date.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Expected Internship End Date*</FormLabel>
                            <Controller
                            name="expected_end_date"
                            control={controlIntern}
                            render={({ field }) => (
                              <label style={{ width: "max-content" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                    style={{marginTop: "-5px",  marginTop: "20px"}}
                                      autoOk
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      placeholder="dd/mm/yyyy"
                                      views={["year", "month", "date"]}
                                      value={
                                        !formVal.expected_end_date
                                          ? null
                                          : formVal.expected_end_date &&
                                            formVal.expected_end_date === "None"
                                          ? null
                                          : formVal.expected_end_date === ""
                                          ? null
                                          : new Date(formVal.expected_end_date)
                                      }
                                      fullWidth
                                      onChange={(val) => {
                                        dateChange("expected_end_date", val);
                                        // console.log(formVal.intern_dur_year);
                                      }}
                                      // minDate={new Date().setDate(
                                      //   new Date().getDate() + 14
                                      // )}
                                      // minDate={id && code ? ""}
                                    //   minDate={
                                    //     id && org_code
                                    //       ? ""
                                    //       : new Date().setDate(
                                    //           new Date().getDate()
                                    //         )
                                    //   }
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                                {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                              </label>
                            )}
                          />
                          {errorsAddForm.expected_end_date && (
                          <ErrorField err={errorsAddForm.expected_end_date.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Total Expenses during Training (RM)*</FormLabel>
                            <Controller
                            name="total_expenses"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Enter total expenses"
                                />
                            )}
                            />
                            {errorsAddForm.total_expenses && (
                          <ErrorField err={errorsAddForm.total_expenses.message}/>
                        )}
                        </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Old Company Registration Number *</FormLabel>
                            <Controller
                            name="old_company_registration_no"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Old Company Registration Number"
                                />
                            )}
                            />
                            {errorsAddForm.old_company_registration_no && (
                          <ErrorField err={errorsAddForm.old_company_registration_no.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>New Company Registration Number*</FormLabel>
                            <Controller
                            name="new_company_registartion_number"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="New Company Registration Number"
                                />
                            )}
                            />
                            {errorsAddForm.new_company_registration_no && (
                          <ErrorField err={errorsAddForm.new_company_registration_no.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel style={{marginBottom: "35px"}}>Total Meal allowance (RM)*</FormLabel>
                            <Controller
                            name="total_meal_allowance"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Total meal allowance"
                                />
                            )}
                            />
                            {errorsAddForm.total_meal_allowance && (
                          <ErrorField err={errorsAddForm.total_meal_allowance.message}/>
                        )}
                        </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Total Accomodation allowance (RM)*</FormLabel>
                            <Controller
                            name="total_accomodation_allowance"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Total accomodation allowance"
                                />
                            )}
                            />
                            {errorsAddForm.total_accomodation_allowance && (
                          <ErrorField err={errorsAddForm.total_accomodation_allowance.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel style={{marginBottom: "35px"}}>Total Travelling allowance (RM)*</FormLabel>
                            <Controller
                            name="total_travelling_allowance"
                            control={controlIntern}
                            render={({field}) =>(
                                <Input
                                {...field}
                                placeholder="Total travelling allowance"
                                />
                            )}
                            />
                            {errorsAddForm.total__allowance && (
                          <ErrorField err={errorsAddForm.year_of_study.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Total Digital & Communication allowance (RM)*</FormLabel>
                            <Controller
                            name="total_digital_communication_allowance"
                            control={controlIntern}
                            render={({field}) =>(
                                <Input
                                {...field}
                                placeholder="Total Digital & Communication allowance"
                                />
                            )}
                            />
                            {errorsAddForm.total_digital_communication_allowance && (
                          <ErrorField err={errorsAddForm.total_digital_communication_allowance.message}/>
                        )}

                        </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Internship Allowance per month (RM)*</FormLabel>
                            <Controller
                            name="internship_allowance_per_month"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Internship allowance per month"
                                />
                            )}
                            />
                            {errorsAddForm.internship_allowance_per_month && (
                          <ErrorField err={errorsAddForm.internship_allowance_per_month.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Total intership allowance in programme (RM)*</FormLabel>
                            <Controller
                            name="total_internship_allowance_programme"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Total internship allowance in programme"
                                />
                            )}
                            />
                            {errorsAddForm.total_internship_allowance_programme && (
                          <ErrorField err={errorsAddForm.total_internship_allowance_programme.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>Date of Hiring*</FormLabel>
                            <Controller
                            name="date_of_hiring"
                            control={controlIntern}
                            render={({ field }) => (
                              <label style={{ width: "max-content" }}>
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DatePicker
                                    style={{marginTop: "-5px"}}
                                      autoOk
                                      variant="inline"
                                      format="dd/MM/yyyy"
                                      placeholder="dd/mm/yyyy"
                                      views={["year", "month", "date"]}
                                      value={
                                        !formVal.date_of_hiring
                                          ? null
                                          : formVal.date_of_hiring &&
                                            formVal.date_of_hiring === "None"
                                          ? null
                                          : formVal.date_of_hiring === ""
                                          ? null
                                          : new Date(formVal.date_of_hiring)
                                      }
                                      onChange={(val) =>
                                        dateChange("date_of_hiring", val)
                                      }
                                      fullWidth
                                      // minDate={new Date().setDate(
                                      //   new Date().getDate() + 14
                                      // )}
                                      // minDate={id && code ? ""}
                                    //   minDate={
                                    //     id && org_code
                                    //       ? ""
                                    //       : new Date().setDate(
                                    //           new Date().getDate()
                                    //         )
                                    //   }
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                                {/* <CalenderIconWrapper>
                                  <CalenderIcon />
                                </CalenderIconWrapper> */}
                              </label>
                            )}
                          />
                          {errorsAddForm.date_of_hiring && (
                          <ErrorField err={errorsAddForm.date_of_hiring.message}/>
                        )}
                        </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Internship Duration (min 10 weeks)*</FormLabel>
                            <Controller
                            name="internship_duration"
                            control={controlIntern}
                            render={({field}) =>(
                                <Input
                                {...field}
                                placeholder="Internship duration"
                                />
                            )}
                            />
                            {errorsAddForm.internship_duration && (
                          <ErrorField err={errorsAddForm.internship_duration.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel> Completed mynext Talent assessments*</FormLabel>
                        </Col>

                        <Col md="4">
                            <FormLabel>Applied internship in mynext *</FormLabel>
                        </Col>
                    </Row>
                </GroupWrapper>

                <GroupWrapper>
                    <Row>
                        <Col md="4">
                            <FormLabel>Occupation(For MySIP, it would be under Professionals)*</FormLabel>
                            <Controller
                            name="occupation"
                            control={controlIntern}
                            render={({field}) => (
                                <Input
                                {...field}
                                placeholder="Occupation"
                                />
                            )}
                            />
                            {errorsAddForm.occupation && (
                          <ErrorField err={errorsAddForm.occupation.message}/>
                        )}
                        </Col>

                        <Col md="4">
                            <FormLabel>PoHigher Learning/TVET institutions*</FormLabel>
                            <Controller
                            name="pohigher_learning_tvet_institution"
                            control={controlIntern}
                            render={({field}) =>(
                                <Input
                                {...field}
                                placeholder="PoHigher Learning"
                                />
                            )}
                            />
                            {errorsAddForm.pohigher_learning_tvet_institution && (
                          <ErrorField err={errorsAddForm.pohigher_learning_tvet_institution.message}/>
                        )}
                        </Col>
                    </Row>
                </GroupWrapper>
            </CardContainer>
            <ButtonWrapper>
                <Button name="Save" type="submit"/>
                <Button secondary name="Cancel" onClick={closeModal2} />
            </ButtonWrapper>
       </form>
       {/* <div style={{ display: "flex", justifyContent: "center" , marginTop: -80, marginLeft: "200px"}}>
        <Button secondary name="Cancel" onClick={closeModal2} />
      </div> */}
       </div>
    )
}