import React from 'react'
import styled from "styled-components"
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import { useSelector } from "react-redux";

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
}
`;
const ButtonWrapper = styled.div`
  display:flex;
  flex-direction:row;
  justify-content:${(props) => props.alignEnd ? "flex-end" : "space-around"};
  @media (max-width: 500px) {
   flex-direction:column-reverse;
   align-items:center;
  }
`;

const ModalContainer = styled.div`
  min-width: 480px;
  min-height: 270px;
  border-radius: 12px;
  background-color: white;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  z-index: 1000;
  @media (max-width: 500px) {
    min-width: 380px;
    width:396px;
    height: auto;
  }
`;

export default function SubscriptionExpiredPopup({isOpen, setOpen,proceed}) {
    const company = useSelector((state) => state.company.currentlySelectedcompanyData);
    return (
        <div>
            {isOpen === true ? (
                <div className="modalHead">
                    <ModalContainer >
                        <div className="borderBox">
                            <div className="titleCloseBtn" style={{ padding: "0px 10px" }}>
                                <p style={{ color: "#FA4AA1" }}>SUBSCRIBE</p>
                                <p
                                    onClick={() => setOpen(false)}
                                    style={{
                                        cursor: "pointer",
                                    }}
                                >
                                    X
                                </p>
                            </div>
                            <FormLabel style={{ marginTop: "-10px" }}>Your Subscription has expired</FormLabel>

                        </div>

                        <p style={{ color: "#202020",textAlign:"center",marginTop:"24px" }}>
                            Are you sure to proceed ?
                        </p>
                        <ButtonWrapper>
                            <Button
                                onClick={proceed}
                                name="Proceed"
                            />
                        </ButtonWrapper>

                    </ModalContainer>
                </div>
            ) : null
            }
        </div>
    )
}
