import MainLayout from "../../../layouts/mainLayout/mainLayout";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import rightarrow from "../../../assets/icons/arrow_right.png";
import styled from "styled-components";
import { Colors } from "../../../utilities/colors";
// import Form from 'react-bo/otstrap/Form';
// import RadioInput from "../../components/radioInput/radioInput";
import { Controller, useWatch } from "react-hook-form";
import Input from "../../../components/input/input";
// import QuestionSection1 from "../../components/createInternshipModule/internshipModuleQuestion";
// import QuestionSection2 from "../../components/createInternshipModule/internshipModuleQuestion2";
// import QuestionSection3 from "../../components/createInternshipModule/internshipModuleQuestion3";
import Button from "../../../components/button/button";
import { eligibility, internshipModule } from "../staticContentInternship";
import {  useForm, FormProvider  } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "../staticContentInternship";
import { initialStates } from "../staticContentInternship";
import { Row, Col } from "react-bootstrap";
import CheckboxInput from "../../../components/checkboxInput/checkboxInput";
import { toast } from "react-toastify";
import CloseIcon from "../../../assets/close-80.png";
import Dropdown from "../../../components/dropdown/dropdown";
// import dummyApi from "./dummyApiLoe";
// import SkillCard from "../../components/skillCard/skillCard";
import { uploadObject } from "../../../services/apiCalls";
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { getQuestionAnswers , postInternshipModule, getSectors} from "../../../services/apiCalls";
import ErrorField from "../../../components/errorField/errorField";
import dummyApi from "../dummyApiLoe";
import PaginationLOEDivider from "./paginationDividerMigration";

const NewInternPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 50px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;
const SectionWrapper = styled.section``;
const HeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;
const Description = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  margin: 5px 0;
  max-width: 800px;
  color: #858585;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const BreakSection = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 40px
}
`;
const Description2 = styled.p`
    font-style: normal;
  font-weight: 500;
  font-size: 20px;
  color: #D04C8D;
`;
const BreakSection1 = styled.div`
> hr{
    opacity: 0.1;
    max-width: 90%;
    margin-top: 20px
}
`;
const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const InputWrapper = styled.div`
margin-top: 20px;
`
const CheckboxGroup = styled.div`
margin-bottom: ${(props) => (props.margin ? props.margin : '0px')};
> input{
    width: 30px,
    height: 30px, 
    border: 2px solid #000, // Specify the border color
    borderRadius: 4px
}
`;

const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 19px;
  padding-top: 14px;
  color: ${Colors.labelText};
  @media (max-width: 500px) {
    min-width: 150px;
  }
`;

export const UploadBtn = styled.button`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 30px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;

const RadioWrapper = styled.div`
display: flex;
flex-direction: row;
margin-left: 10px;
gap: 200px;
`;

const RadioButtonGroup = styled.div`
  margin-bottom: ${(props) => (props.margin ? props.margin : "10px")};
  cursor: pointer;
  minWidth:300px;
  marginRight:100px;
  margin-left:10px;
  display: flex;
  flex-direction: row;
`;
const RadioLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  text-transform: capitalize;
  // white-space: nowrap;
  text-overflow: ellipsis;
`;

const Uploader = styled.div`
  display: flex;
  flex-direction: row;
  > input {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
  >label{
    margin-left:30px;
    margin-top: 25px;
  }
  `;

  const SmallText = styled.label`
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
`;
const UploadFileBox = styled.div`
  display:flex;
  flex-direction:row;
  justify-content: center;
  height:36px;
  width: 240px;
  border-radius:30px;
  padding:8px 16px;
  background:#F3F3F3;
  margin:auto;
  margin-top:12px;
`;
 
const FileNameWrapper = styled.div`
  width:160px;
  white-space: nowrap !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important
`;
const Icon = styled.img`
  margin: 5px;
  width:12px;
  height:12px;
  z-index:1000;
`;

const UploadFileWrapper = styled.label`
  position: relative;
  cursor: pointer;
  > input {
    position: absolute;
    opacity: 0;
    width: 100%;
  }
`;
const UploadFileText = styled.div`
width: ${(props) => props.width};
background: ${(props) =>
        props.secondary
            ? "transparent"
            : "linear-gradient(90deg, #FA4AA1 0%, #504F8C 100%)"};
border-radius: 30px;
outline: none;
border: ${(props) => (props.secondary ? "1px solid #D04C8D" : "0px")};
font-weight: 600;
font-size: ${(props) => (props.fontSize ? props.fontSize : "18px")};
line-height: ${(props) => (props.lineHeight ? props.lineHeight : "24px")};
color: ${(props) => (props.secondary ? Colors.primaryText : Colors.light)};
padding: 13px 0px;
margin: ${(props) => (props.margin ? props.margin : "15px 0")};
// min-width: ${(props) => (props.minWidth ? props.minWidth : "200px")};
width:  100px;
cursor: pointer;
text-align: center;
&:disabled {
  background:  #D04C8D;
  color: #ccc;
  cursor: no-drop;
}
&:hover:not([disabled]) {
  color: ${Colors.light};
  background: ${(props) =>
        props.secondary
            ? Colors.pink
            : " linear-gradient(90deg, #504F8C 0%, #FA4AA1 100%);"};
}`;
const FlexWrapper = styled.div`
  display: flex;
  align-items: center;
`;
const FileWrapper = styled(FlexWrapper)`
 margin: 10px 0;
 flex-direction: row;
 gap: 5px;
`;

const MainHeaderText = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  margin: 0;
  color: ${Colors.dark};
`;

export default function LOEDataMigrationLanding(){
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [selectedFile, setSelectedFile] = useState("")
    const {
      control,
      watch,
      reset: resetForm,
      setError: setErrorCustom,
      setValue: setInternshipValue,
      handleSubmit,
      formState: { errors: errorsQuestion },
    } = useForm({
      defaultValues: { ...initialStates },
      resolver: yupResolver(schema),
      mode: "onSubmit",
    });

    const formVal = watch();
    console.log('Org Code:', org_code);
    const [compStatus, setCompStatus] = useState([]);
    const [selectedCompanyType, setSelectedCompanyType] = useState([]);
    const [compSector, setSector] = useState(internshipModule.sectors);
    const [comSubSector, setSubSector] = useState([]);
    const [sectors, setSectors] = useState([]);
    const [question1Eligible, setQuestion1Eligible] = useState(true);
    const [question2Eligible, setQuestion2Eligible] = useState(true);
    const [question3Eligible, setQuestion3Eligible] = useState(true);
    const [question4Eligible, setQuestion4Eligible] = useState(true);
    const [monthlyAllowanceBelow500, setMonthlyAllowanceBelow500] = useState(false);
    const [monthlyAllowanceBelow600, setMonthlyAllowanceBelow600] = useState(false);
    // const [selectedSector, setSelectedSector] = useState([]);
    // const [selectedSubSector, setSelectedSubSector] = useState([]);
    
    const location = useLocation();
//   const formval = location.state.data;
  const internship = location.state?.from;
  

useEffect(()=>{
dummyApi.getCompanyStatus()
.then((data)=>{
  const optionLabels = data.status_name.map((item) => ({
    label: item.status_name,
    value: item.id,
  }));
  setCompStatus(optionLabels);
})

const retrieveSectors = async () =>{
  const {data} = await getSectors();
  if(data?.status === 1){
    const options = data.data?.map((x) => {
      return { label: x.Name, value: x.Id };
    });
    setSectors(options)
  }
}
retrieveSectors();
},
[])
console.log(sectors, "FROM API")
const [questionsList, setQuestionsList] = useState([]);
const [answersList, setAnswersList] = useState([]);

useEffect(() => {
  // Define an async function to fetch data
  const fetchData = async () => {
    const { data } = await getQuestionAnswers();
    if (data) {
      setQuestionsList(data?.questions);
      setAnswersList(data?.answers);
    }
  };

  // Call the fetchData function
  fetchData();
}, []);
console.log(answersList, "DATA RETRIEVEDDDD")
const handleDropdown = (val)=>{
  setSelectedCompanyType(val);
  setInternshipValue("comp_type", val);
  console.log('Selected Company Type:', val);
}
// console.log(formval.question_2);
console.log(formVal.file_name,"FILE>>>>")

useEffect(()=>{
setInternshipValue("contact_mob_code1", "+6");
setInternshipValue("contact_mob_code2", "+6");
setInternshipValue("organization", org_code);
setInternshipValue("file_name", selectedFile)

// if(formval.question_1 === "Yes"){
//   setInternshipValue("min_10_weeks", 1)
// }

// if(formval.question_2 === "Yes"){
//   setInternshipValue("min_500_SKM1_Diploma", 1)
// }

// if(formval.question_3 === "Yes"){
// setInternshipValue("min_600_ProfCert_Master", 1)
// }

},[])
console.log(formVal.file_name,"FILE NAME")
const [selectedSector, setSelectedSector] = useState(null);
const [filteredSubsectors, setFilteredSubsectors] = useState([]);
const [selectedSubsector, setSelectedSubsector] = useState([]);

const handleSectorChange = (name,selectedOption) => {
  switch (name){
    case "sectors":
      setSelectedSector(selectedOption);
      const updatedSector = {sector: selectedOption.label}
      setInternshipValue("sector", updatedSector);
  }
  // setSelectedSector(selectedOption.label);
  // console.log(selectedOption,"selected option")
  // // Filter the subsectors based on the selected sector
  // const filtered = internshipModule.sub_sector.filter((subsector) =>
  //   subsector.sector_id === selectedOption.id
  // );
  // setFilteredSubsectors(filtered);
  // setInternshipValue("sector", selectedOption.label);
};

const handleSubsectorChange = (selectedOption) => {
  setSelectedSubsector(selectedOption.label);
  setInternshipValue("sub_sector", selectedOption.label)
};
console.log(formVal.sector,"sectors to send");

  //   const uploadFile = (event) => {
  //     setSelectedFile(event.target.files[0].name)
  // }
  console.log(formVal.upload,"Uploded File")
//   const uploadFile = async (event) => {
//     const formdata = new FormData();

//     let maxFileSize = 1024 * 1024 * 500; //500mb

//     if (event.target.files[0].size > maxFileSize) {
//       toast.error(
        
//           "Only PDF files of size less than or equal to 100 MB can be uploaded!"
//         ,
//         {
//           position: toast.POSITION.TOP_RIGHT,
//         }
//       );
//     } else {
//       formdata.append(
//         "obj_content",
//         event.target.files[0],
//         event.target.files[0].name
//       );
//       setSelectedFile([...selectedFile, event.target.files[0].name])
//       const { data, status } = await uploadObject(formdata);
//       if (status === 200) onUploaded(data);
//     }
//   };
// console.log(selectedFile,"uploaded file")
//   const onUploaded = (data) => {
//     const { status } = data;
//     if (status === 1) {
//       let obj =  data?.data?.obj_content ;
//       console.log(obj,"PATH IN UPLOADED")
//       const arr = [...formVal.upload];
//       console.log(arr, "AFTER THAT")
//       arr.push(obj);
//       setInternshipValue("upload", arr);
//     }
//   };


//   const onDeleteFiles = (index) => {
//     const arr = [...formVal.upload];
//     const newArr = arr.filter((skill, ind) => ind !== index);
//     setInternshipValue("upload", newArr);
//   };
//   var fileName = "";
//   var ext = "";

//   const getfileName = (file_name) => {
//     if (file_name) {
//       var temp_name_2 = file_name?.split(/[\\]/).pop();
//       var temp_name_arr = temp_name_2?.split(".");
//       var temp_name = temp_name_arr[0].slice(0, -8);
//       fileName = temp_name;
//       ext = temp_name_arr[1];
//     } else {
//       // Handle the case when file_name is undefined or null
//       fileName = "";
//       ext = "";
//     }
//   };
//   const clearFile = () => {
//     setSelectedFile(null)
// }
const uploadFile = async (event) => {
  const formdata = new FormData();

  let maxFileSize = 1024 * 1024 * 100;

  if (event.target.files[0].size > maxFileSize) {
    toast.error("File size exceeds maximum limit", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
      formdata.append(
          "obj_content",
          event.target.files[0],
          event.target.files[0].name
      );
      const { data, status } = await uploadObject(formdata);
      if (status === 200) onUploaded(data);
  }
};
const onUploaded = (data) => {
  const { status } = data;
  if (status === 1) {
      setInternshipValue("file_name", data.data.obj_content);
  }
};
const onDeleteFiles = () => {
  setInternshipValue("file_name", "");
};
console.log(formVal.file_name, "FILE NAME LATES")


    const handleStudentCheck = (type, question_code, answer_code, answer_desc) =>{
      let arr = [...formVal[type]];
      const keyWord1 = "question_code";
      const keyWord2 = "answer_code";
     
  // Check if the maximum limit of 3 selections has been reached
  if (arr.length >= 3 && !arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code)) {
    toast.error("Maximum selection of 3 answers reached!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }

  const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

  if (isChecked) {
    arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
  } else {
      const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
      arr.push(obj);
  }

  // Update the state with the selected items
  setInternshipValue(type, arr);
     if(type === "student_objectives"){
      errorsQuestion.student_objectives="";
     }
}

    console.log(formVal.other_allowance, "SELECTED")

    const handleCompanyCheck = (type, question_code, answer_code) =>{
      let arr = [...formVal[type]];
      const keyWord1 = "question_code";
      const keyWord2 = "answer_code";

  // Check if the maximum limit of 3 selections has been reached
  if (arr.length >= 3 && !arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code)) {
    toast.error("Maximum selection of 3 answers reached!", {
      position: toast.POSITION.TOP_RIGHT,
    });
    return;
  }

  const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

  if (isChecked) {
    arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
  } else {
    
      const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
      arr.push(obj);
   
  }

  setInternshipValue(type, arr);
  if(type === "company_objectives"){
    errorsQuestion.company_objectives="";
   }
}

const handleSingleCheck = (type, question_code, answer_code) =>{
  let arr = [...formVal[type]];
      const keyWord1 = "question_code";
      const keyWord2 = "answer_code";

  const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

  if (isChecked) {
    arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
  } else {
      const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
      arr.push(obj);
  }

  // Update the state with the selected items
  setInternshipValue(type, arr);
  if(type === "activities_offered"){
    errorsQuestion.activities_offered="";
  }
}

const handleLearningCheck = (type, question_code, answer_code) =>{
  let arr = [...formVal[type]];
  const keyWord1 = "question_code";
  const keyWord2 = "answer_code";

// Check if the maximum limit of 3 selections has been reached
if (arr.length >= 3 && !arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code)) {
toast.error("Maximum selection of 3 answers reached!", {
  position: toast.POSITION.TOP_RIGHT,
});
return;
}

const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

if (isChecked) {
arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
} else {
  const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
  arr.push(obj);
}

setInternshipValue(type, arr);
if(type === "learning_outcome"){
errorsQuestion.learning_outcome="";
}
}

const handleOtherAllowance = (type, question_code, answer_code) =>{
  let arr = [...formVal[type]];
  const keyWord1 = "question_code";
  const keyWord2 = "answer_code";

const isChecked = arr.some((item) => item[keyWord1] === question_code && item[keyWord2] === answer_code);

if (isChecked) {
arr = arr.filter((item) => !(item[keyWord1] === question_code && item[keyWord2] === answer_code));
} else {
  const obj = { [keyWord1]: question_code, [keyWord2]: answer_code };
  arr.push(obj);
}

// Update the state with the selected items
setInternshipValue(type, arr);
if(type === "other_allowance"){
errorsQuestion.other_allowance="";
}
}

const handleTextOth = (name, question_code, answer_code, answer_desc, val) =>{
  const existingObjects = formVal[name].filter(
    (item) => item.question_code === question_code && item.answer_code !== answer_code
  );

  const obj = { question_code, answer_code, other: val };

  setInternshipValue(name, [...existingObjects, obj]);
}

const handleDeclaration = () =>{
  const isChecked = watch("terms_condition");
  setInternshipValue("terms_condition", !isChecked);
  errorsQuestion.terms_condition="";
};


const updateURL = () =>{
  const url = formVal.file_name

// Split the URL by '/' to get the parts
const parts = url.split('/');

// The file name will be the last part of the array
const fileName = parts[parts.length - 1];

console.log(fileName, "UPDATED");
}
useEffect(()=>{
updateURL();
},[])

const onSubmitForm = async () =>{
 if (formVal.student_objectives.length === 0 || formVal.company_objectives.length === 0 ||
  formVal.activities_offered.length === 0 || formVal.learning_outcome.length === 0 || 
  formVal.other_allowance.length === 0 && formVal.terms_condition === false) {
  toast.error("Please check your data.", {
    position: toast.POSITION.TOP_RIGHT,
  });
}
else{
  await  handleOnSubmit();
}
}
const handleOnSubmit = async  () => {
  const formData = {
   
    organization: formVal.organization,
    min_10_weeks: formVal.min_10_weeks,
    min_500_SKM1_Diploma: formVal.min_500_SKM1_Diploma,
    actual_allowance_SKM1_Diploma: formVal.monthly_allowance_1,
    min_600_ProfCert_Master: formVal.min_600_ProfCert_Master,
    actual_allowance_ProfCert_Master: formVal.monthly_allowance_2,
    contact_name1: formVal.contact_full_name_1,
    contact_email1: formVal.contact_email_1,
    contact_mob_code1: formVal.contact_mob_code1,
    contact_mob1: formVal.contact_mobile_1,
    contact_name2: formVal.contact_full_name_2,
    contact_email2: formVal.contact_email_2,
    contact_mob_code2: formVal.contact_mob_code2,
    contact_mob2: formVal.contact_mobile_2,
    file_path: formVal.file_path,
    // file_name: formVal.file_name,
    // status: ,
    terms_conditions: formVal.terms_condition,
    responses: [
      ...formVal.student_objectives,
      ...formVal.company_objectives,
      ...formVal.learning_outcome,
      ...formVal.activities_offered,
      ...formVal.other_allowance,
    ],
    sector: formVal.sector
  
    
  }
  try {
    const { data } = await postInternshipModule(formData);
    console.log("internshipModule",data)
    console.log("formData",formData)
    if (data?.id) {
      // Assuming 'id' is present in the response data when successful
      toast.success("Internship module submitted!", {
        position: toast.POSITION.TOP_RIGHT,
      });

      // navigate("")
      navigate("/internship/create-internship/new-internship", { state: { typeOrigin: 'on-going' , id: data?.id, fromDataMigration: true} }) 

    //   if(internship === "add-intern"){
    //     navigate("/internships/internship-module-eligibility-check", {state: {createdInternshipModule: "success"}})
    //   } else{
    //     navigate("/loe/internship-module-eligibility-check", {state: {createdInternshipModule: "success"}})
    //   }
      // Optionally, you can redirect to another page or take other actions upon success
    } else {
      toast.error("Failed to create internship module.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  } catch (error) {
    console.error("An error occurred:", error);
    toast.error("An error occurred while submitting the form.", {
      position: toast.POSITION.TOP_RIGHT,
    });
  } finally {
    setLoading(false);
  }
};

return(
        <MainLayout>
            <div className="breadcrumb">
            <p onClick={() => navigate("/loe-application")} style={{ cursor: "pointer" }}>
              {"National Structured Internship Programme (MySIP) Application"}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>{"Internship Module"}</p>
          </div>
          <NewInternPageWrapper>
            <SectionWrapper>
            <MainHeaderText>
            Add a New MySIP Direct Hiring Internship
                  <PaginationLOEDivider step={1}/>
              </MainHeaderText>
            <HeaderText>{internshipModule.header}</HeaderText>
                <Description>{internshipModule.subHeading}</Description>
              <BreakSection>
              <hr/>
              </BreakSection>
              <form> 
              {/* <form onSubmit={handleSubmit(onSubmitForm)}>  */}
              <GroupWrapper>
                <FormLabel>{eligibility.question1}</FormLabel>
                <Controller
                name="provide_internship"
                control={control}
                defaultValue={"Yes"}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes")
                          setQuestion1Eligible(true)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=>{ 
                            field.onChange("No")
                            setQuestion1Eligible(false)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.question2}</FormLabel>
                <Controller
                name="question_2"
                control={control}
                defaultValue={"Yes"}
                render={({field}) =>(
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes")
                            setQuestion2Eligible(true)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=> {
                            field.onChange("No")
                            setQuestion2Eligible(false)}}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.subQuestion}</FormLabel>
                <span className="jonNation">{eligibility.subQuestionDescription}</span>
                <div style={{ marginTop: "10px" }}>
                  <Controller
                    name="monthly_allowance_1"
                    control={control}
                    defaultValue="" 
                    render={({ field }) => (
                      <Input
                        placeholder="5000"
                        {...field} 
                        onChange={(e) => {
                          field.onChange(e);
                          const value = e.target.value;
                          const numericValue = parseFloat(value.replace(/[^\d.]/g, '')); // Extract numeric value
                          setMonthlyAllowanceBelow500(numericValue < 500);
                        }}
                      />
                    )}
                  />
                  {errorsQuestion.learning_outcome && (
    <ErrorField err={errorsQuestion.learning_outcome.message}/>
  )}
    
        </div>
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.question3}</FormLabel>
                <Controller
                name="question_3"
                control={control}
                defaultValue={"Yes"}
                render={({field}) => (
                  <RadioWrapper>
                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'Yes'}
                          onChange={()=> {
                            field.onChange("Yes");
                            setQuestion3Eligible(true);
                          }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>Yes</RadioLabel>
                          </RadioButtonGroup>

                          <RadioButtonGroup>
                          <input 
                          type="radio" 
                          className="radio"
                          checked={field.value === 'No'}
                          onChange={()=> {
                            field.onChange("No")
                            setQuestion3Eligible(false)
                          }}
                          />
                          <RadioLabel className="radio-label" style={{ margin: 0 }}>No</RadioLabel>
                          </RadioButtonGroup>
                    </RadioWrapper>
                )}
                />
            </GroupWrapper>
            <GroupWrapper>
                <FormLabel>{eligibility.subQuestion}</FormLabel>
                <span className="jonNation">{eligibility.subQuestionDescription}</span>
                <div style={{ marginTop: "10px" }}>
                    <Controller
                      name="monthly_allowance_2"
                      control={control}
                      defaultValue="" 
                      render={({ field }) => (
                        <Input
                          placeholder="5000"
                          {...field} 
                          onChange={(e) => {
                            field.onChange(e);
                            const value = e.target.value;
                            const numericValue = parseFloat(value.replace(/[^\d.]/g, '')); // Extract numeric value
                            setMonthlyAllowanceBelow600(numericValue < 600);
                          }}
                        />
                      )}
                    />
                    {errorsQuestion.monthly_allowance_2 && (
                    <ErrorField err={errorsQuestion.monthly_allowance_2.message}/>
                  )}
              </div>
            </GroupWrapper>
            <BreakSection>
              <hr/>
              </BreakSection>
              <GroupWrapper>
                <FormLabel>{internshipModule.quest3}</FormLabel>
                <Row>
                  <Col md="4">
                    <FormLabel>Full Name</FormLabel>
                    <Controller
                    name="contact_full_name_1"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter full name"
                      {...field}
                      />
                    )}
                    />
                  </Col>

                  <Col md="4">
                    <FormLabel>Email</FormLabel>
                    <Controller
                    name="contact_email_1"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter email"
                      {...field}
                      />
                    )}
                    />
                  </Col>

                  {/* <Col md="4">
                    <FormLabel>Phone No</FormLabel>
                    <Controller
                    name="contact_phone_1"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter phone no"
                      {...field}
                      />
                    )}
                    />
                  </Col> */}
                </Row>
                <Row>
                <Col md="4">
                    <InputWrapper>
                    <FormLabel>Mobile No</FormLabel>
                    <Controller
                    name="contact_mobile_1"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter mobile no"
                      {...field}
                      />
                    )}
                    />
                    </InputWrapper>
                  </Col>
                </Row>
              </GroupWrapper>

              <GroupWrapper>
                <FormLabel>{internshipModule.quest4}</FormLabel>
                <Row>
                  <Col md="4">
                    <FormLabel>Full Name</FormLabel>
                    <Controller
                    name="contact_full_name_2"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter full name"
                      {...field}
                      />
                    )}
                    />
                  </Col>

                  <Col md="4">
                    <FormLabel>Email</FormLabel>
                    <Controller
                    name="contact_email_2"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter email "
                      {...field}
                      />
                    )}
                    />
                  </Col>

                  {/* <Col md="4">
                    <FormLabel>Phone No</FormLabel>
                    <Controller
                    name="contact_phone_2"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter phone no"
                      {...field}
                      />
                    )}
                    />
                  </Col> */}
                </Row>
                <Row>
                <Col md="4">
                    <InputWrapper>
                    <FormLabel>Mobile No</FormLabel>
                    <Controller
                    name="contact_mobile_2"
                    control={control}
                    render={({field}) =>(
                      <Input
                      placeholder="Enter mobile no"
                      {...field}
                      />
                    )}
                    />
                    </InputWrapper>
                  </Col>
                </Row>
              </GroupWrapper>

              <GroupWrapper>
                <Row>
                  <Col md="4">
                  <FormLabel>{internshipModule.quest5}</FormLabel>
                  <Controller
                  name="comp_sector"
                  control={control}
                  render={({field}) =>(
                    <Dropdown
                    options={sectors}
                      val={selectedSector?.value}
                      label={selectedSector?.label}
                      change={(val)=> handleSectorChange("sectors", val)}
                      {...field}
                    />
                  )}
                  />
                  </Col>
                  {/* <Col md="4">
                  <FormLabel>{internshipModule.quest6}</FormLabel>
                  <Controller
                  name="comp_sub_sector"
                  control={control}
                  render={({field})=>(
                    <Dropdown
                    options={filteredSubsectors.map((item)=>{
                      return{label: item.sub_sector_name, }
                    })}
                    val= {selectedSubsector}
                    label= {selectedSubsector}
                    change={handleSubsectorChange}
                    {...field}
                    />
                  )}
                  />
                  </Col> */}
                </Row>
              </GroupWrapper>

              <BreakSection>
              <hr/>
              </BreakSection>

              <GroupWrapper>
                
              {questionsList
  .filter((question) => {
    // Filter for the specific question codes you want to display
    return (
      question.question_code === 1 
    );
  })
  .map((question) => (
    <GroupWrapper key={question.id}>
      <FormLabel>{question.question_desc}</FormLabel>
      <Controller 
      name="student_objectives"
      control={control}
      render={({field}) =>(
        answersList.filter((answer) => answer.question_code === question.question_code
          ).map((items)=>(
            <CheckboxGroup key={items.id}>
              <Row>
                <Col xs={"auto"} style={{ marginTop: "8px" }}>
                  <CheckboxInput
                    onChange={() => handleStudentCheck("student_objectives",question.question_code, items.answer_code, items.answer_desc)}
                    checked={formVal.student_objectives.some(
                      (answer) =>
                        answer.question_code === question.question_code &&
                        answer.answer_code === items.answer_code
                    )}
                  />
                </Col>
                <Col>
                  <TableHead>{items.answer_desc}</TableHead>
                </Col>
                {items.answer_desc === "Others (please specify)" ? (
                 <div style={{marginTop: "10px"}}>
                   <Input
                  placeholder="Others"
                  onChange={(e) => handleTextOth("student_objectives", question.question_code, items.answer_code, items.answer_desc, e.target.value)}
                  />
                 </div>
                ): ""}
                </Row>
            </CheckboxGroup>
            
          ))
      )}
      />
    </GroupWrapper>
  ))}
  
  {errorsQuestion.student_objectives && (
    <ErrorField err={errorsQuestion.student_objectives.message}/>
  )}
            </GroupWrapper>
            <BreakSection >
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>

{questionsList.filter((questions)=>{
  return(
    questions.question_code === 2
  )
  }).map((questions)=>(
     <GroupWrapper key={questions.id}>
                <FormLabel>{questions.question_desc}</FormLabel>
                <Controller
                name="company_objectives"
                control={control}
                render={({field}) =>(
                  answersList.filter((ans)=> ans.question_code === questions.question_code )
                  .map((items)=>(
                    <CheckboxGroup key={items.id}>
              <Row>
                <Col xs={"auto"} style={{ marginTop: "8px" }}>
                  <CheckboxInput
                    onChange={() => handleCompanyCheck("company_objectives",questions.question_code, items.answer_code)}
                    checked={formVal.company_objectives.some(
                      (answer) =>
                        answer.question_code === questions.question_code &&
                        answer.answer_code === items.answer_code
                    )}
                  />
                </Col>
                <Col>
                  <TableHead>{items.answer_desc}</TableHead>
                </Col>
                {items.answer_desc === "Others (please specify)" ? (
                 <div style={{marginTop: "10px"}}>
                   <Input
                  placeholder="Others"
                  onChange={(e) => handleTextOth("company_objectives", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                  />
                 </div>
                ): ""}
                </Row>
            </CheckboxGroup>
                  ))
                )}
                />
            </GroupWrapper> 
  )

  )}
           
  {errorsQuestion.company_objectives && (
    <ErrorField err={errorsQuestion.company_objectives.message}/>
  )}
            <BreakSection>
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>


            {questionsList.filter((questions)=>{
  return(
    questions.question_code === 3
  )
  }).map((questions)=>(
     <GroupWrapper key={questions.id}>
                <FormLabel>{questions.question_desc}</FormLabel>
                <Controller
                name="activities_offered"
                control={control}
                render={({field}) =>(
                  answersList.filter((ans)=> ans.question_code === questions.question_code)
                  .map((items)=>(
                    <CheckboxGroup key={items.id}>
              <Row>
                <Col xs={"auto"} style={{ marginTop: "8px" }}>
                  <CheckboxInput
                    onChange={() => handleSingleCheck("activities_offered", questions.question_code, items.answer_code, items.answer_desc)}
                    checked={formVal.activities_offered.some(
                      (answer) =>
                        answer.question_code === questions.question_code &&
                        answer.answer_code === items.answer_code
                    )}
                  />
                </Col>
                <Col>
                  <TableHead>{items.answer_desc}</TableHead>
                </Col>
                {items.answer_desc === "Others (please specify)" ? (
                 <div style={{marginTop: "10px"}}>
                   <Input
                  placeholder="Others"
                  onChange={(e) => handleTextOth("activities_offered", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                  />
                 </div>
                ): ""}
                </Row>
            </CheckboxGroup>
                  ))
                )}
                />
            </GroupWrapper> 
  )

  )}
           
            {errorsQuestion.activities_offered && (
    <ErrorField err={errorsQuestion.activities_offered.message}/>
  )}
            <BreakSection>
            <hr style={{marginTop: "40px"}}/>
            </BreakSection>

            {questionsList.filter((questions)=>{
  return(
    questions.question_code === 4
  )
  }).map((questions)=>(
     <GroupWrapper key={questions.id}>
                <FormLabel>{questions.question_desc}</FormLabel>
                <Controller
                name="learning_outcome"
                control={control}
                render={({field}) =>(
                  answersList.filter((ans)=> ans.question_code === questions.question_code )
                  .map((items)=>(
                    <CheckboxGroup key={items.id}>
              <Row>
                <Col xs={"auto"} style={{ marginTop: "8px" }}>
                  <CheckboxInput
                    onChange={() => handleLearningCheck("learning_outcome", questions.question_code, items.answer_code, items.question_desc)}
                    checked={formVal.learning_outcome.some(
                      (answer) =>
                        answer.question_code === questions.question_code &&
                        answer.answer_code === items.answer_code
                    )}
                  />
                </Col>
                <Col>
                  <TableHead>{items.answer_desc}</TableHead>
                </Col>
                {items.answer_desc === "Others (please specify)" ? (
                  <div style={{marginTop: "10px"}}>
                    <Input
                  placeholder="Others"
                  onChange={(e) => handleTextOth("learning_outcome", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                  />
                  </div>
                ): ""}
                </Row>
            </CheckboxGroup>
                  ))
                )}
                />
            </GroupWrapper> 
  )

  )}
{errorsQuestion.learning_outcome && (
    <ErrorField err={errorsQuestion.learning_outcome.message}/>
  )}
            
            
            <GroupWrapper>
                <FormLabel>{internshipModule.uploadFile}</FormLabel>
                
                
                <Uploader>
                <UploadFileWrapper data-tip data-for="pdf">
                    <input
                      onChange={uploadFile}
                      accept="application/pdf"
                      type="file"
                      multiple
                    />
                    <UploadFileText secondary style={{ width: "200px" }}>
                      {"Choose File"}
                    </UploadFileText>
                    <p className="uploadFileOne">
                      {"The maximum file size limit is 500 MB"}
                    </p>
                  </UploadFileWrapper>
                  <FileWrapper>
                    {/* {formVal?.upload?.length > 0
                      ? formVal.upload?.map((x, index) => (
                          <>
                            {getfileName(x.file_path)}
                            <UploadFileBox style={{ fontSize: "12px", color: "gray" }}>
                        <FileNameWrapper className="selectedFile"> {selectedFile[index]}</FileNameWrapper>
                        <Icon
                          onClick={() => onDeleteFiles(index)}
                          src={CloseIcon}
                        />
                      </UploadFileBox>
                          </>
                        ))
                      : ""} */}
                      {formVal.file_name && (
                        <UploadFileBox style={{ fontSize: "12px", color: "gray" }}>
                        <FileNameWrapper className="selectedFile"> {formVal.file_name}</FileNameWrapper>
                        <Icon
                          onClick={() => onDeleteFiles()}
                          src={CloseIcon}
                        />
                      </UploadFileBox>
                      )}
                  </FileWrapper>
                </Uploader>
                      
            </GroupWrapper>

            
              <Description2>{internshipModule.subHeading2}</Description2>
            <BreakSection>
            <hr/>
            </BreakSection>
            {questionsList.filter((questions)=>{
  return(
    questions.question_code === 5
  )
  }).map((questions)=>(
     <GroupWrapper key={questions.id}>
                <FormLabel>{questions.question_desc}</FormLabel>
                <Controller
                name="other_allowance"
                control={control}
                render={({field}) =>(
                  answersList.filter((ans)=> ans.question_code === questions.question_code)
                  .map((items)=>(
                    <CheckboxGroup key={items.id}>
              <Row>
                <Col xs={"auto"} style={{ marginTop: "8px" }}>
                  <CheckboxInput
                    onChange={() => handleOtherAllowance("other_allowance",questions.question_code, items.answer_code, items.question_desc)}
                    checked={formVal.other_allowance.some(
                      (answer) =>
                        answer.question_code === questions.question_code &&
                        answer.answer_code === items.answer_code
                    )}
                  />
                </Col>
                <Col>
                  <TableHead>{items.answer_desc}</TableHead>
                </Col>
                {items.answer_desc === "Other (please specify)" ? (
                 <div style={{marginTop: "10px"}}>
                   <Input
                  placeholder="Others"
                  onChange={(e) => handleTextOth("other_allowance", questions.question_code, items.answer_code, items.answer_desc, e.target.value)}
                  />
                 </div>
                ): ""}
                </Row>
            </CheckboxGroup>
                  ))
                )}
                />
            </GroupWrapper> 
  )

  )}

 
{errorsQuestion.other_allowance && (
    <ErrorField err={errorsQuestion.other_allowance.message}/>
  )}
            <GroupWrapper>
                <FormLabel>Thank you for completing this internship module details.</FormLabel>
                <FormLabel>We shall review and notify the approval via email.</FormLabel>
                <FormLabel>PERSONAL DATA PROTECTION ACT 2010*</FormLabel>
                <Controller
                name="declaration"
                control={control}
                render={({field}) =>(
                  <CheckboxGroup className='checkbox-group'>
                    
                    <Row>
                    <Col xs="auto" style={{marginTop:"10px"}}>
                        <CheckboxInput
                        onChange={handleDeclaration}
                        checked={watch("terms_condition")}
                        />
                    </Col>
                    <Col>
                    <TableHead>{internshipModule.declaration}</TableHead>
                    </Col>
                    </Row>
                </CheckboxGroup>
                )}
                />

            </GroupWrapper>
            {errorsQuestion.terms_condition && (
              <ErrorField err={errorsQuestion.terms_condition.message}/>
            )}
              <ButtonWrapper>
                <Button name="Submit" type="submit"
                onClick={onSubmitForm}/>
                <Button name="Cancel" secondary/>
              </ButtonWrapper>
              </form>
            </SectionWrapper>
          </NewInternPageWrapper>
        </MainLayout>
    )
}