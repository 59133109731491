import React, { useState } from "react";
import { staticData } from "./staticData";
import {
  SubHeading,
  HeadingCard,
  ContentCard,
  CardWrapper,
  ContentHeading,
  Range,
  ColorBar,
  SectionHeading,
  NormalText,
  SectionWrapper,
  SpanText,
  Wrapper,
} from "./common";
import "./applicantsComparision.css";
import { Slider } from "@mui/material";
import styled from "styled-components";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IconButton } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const UpIcon = styled(ArrowDropUpIcon)`
  position: relative;
  color: #c4c4c4;
`;
const DownIcon = styled(ArrowDropDownIcon)`
  position: relative;
  color: #c4c4c4;
`;
export const OceanSlider = styled(Slider)`
  cursor: default !important;
  .Mui-active {
    pointer-events: none;
  }
  .MuiSlider-thumb {
    color: ${(props) =>
      props.level === "low" ? "#FF0000" 
      : props.level === "moderate" ? "#FFA500"
      : props.level === "high" ? "#32C731"
      : "#D04C8D"};
    size: small;
    box-shadow: 0px 5px 16px rgba(180, 104, 141, 0.9);
  }
  .MuiSlider-thumb.Mui-focusVisible,
  .MuiSlider-thumb:hover {
    box-shadow: 0px 5px 16px rgba(180, 104, 141, 0.9);
    cursor: default !important;
  }
  .MuiSlider-rail {
    background: #fafafa;
    border: 0.2px solid #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15),
      inset 0px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
  }
  .MuiSlider-track {
    background: ${(props) =>
      props.level === "low" ? "#FF0000" 
      : props.level === "moderate" ? "#FFA500"
      : props.level === "high" ? "#32C731"
      : "#D04C8D"};
    border: 0.2px solid #ffffff;
    box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15),
      inset 0px 1px 1px rgba(0, 0, 0, 0.18);
    border-radius: 7px;
  }
  .Mui-disabled {
    color: #d04c8d;
  }
  .MuiSlider-valueLabel {
    background: #d04c8d;
  }
`;
const SeparatorDiv = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`;

const Separator = styled.div`
  padding: 0 10px;
  flex: 1 0 auto;
  text-align: center;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;
const HrLine = styled.hr`
  border: 0.6px solid #c4c4c4;
  position: relative;
  margin: 40px 0 40px 20px;
  width: 40%;
  @media (max-width: 500px) {
    position: relative;
    top: 0px;
  }
`;
export default function OceanReport({ data,cards }) {
  // const [data, setData] = useState([])
  const [openOceanReport, setOpenOceanReport] = useState(true);
  //   const [openOceanReport, setOpenOceanReport] = useState(true);
  const handleOpenOceanReport = () => {
    setOpenOceanReport(!openOceanReport);
  };
  function normalizeBetween0To5 (val){
    var newMin = 0;
    var newMax = 5;
    var oldMin = 0;
    var oldMax = 120;
    var newVal = newMin + (((val - oldMin) * (newMax - newMin)) / (oldMax - oldMin))
    return Math.round(newVal * 100) / 100;
  };
  const { t, i18n } = useTranslation();
  const getLevel = (val) => {
    if (val < 25) {
      return "low";
    } else if (val < 75) {
      return "moderate";
    } else {
      return "high";
    }
  };
  return (
    <div>
      <SectionWrapper>
        <SeparatorDiv onClick={handleOpenOceanReport}>
          <HrLine />
          <Separator>{t("Ocean Report")}</Separator>
          <HrLine />

          {openOceanReport ? (
            <IconButton>
              <UpIcon />
            </IconButton>
          ) : (
            <IconButton>
              <DownIcon />
            </IconButton>
          )}
        </SeparatorDiv>
        {openOceanReport ? (
          <>
            {staticData.oceanReport.content.map((content, idx) => {
               if (content.heading === "Organisational Fit Factor") {
                return null;
              }
              // if (content.heading === "30 - Facets") {
              //   content.heading = "Five Factors";
              //   content.subHeading = [
              //     "Openness to Experience",
              //     "Conscientiousness",
              //     "Extraversion",
              //     "Agreeableness",
              //     "Emotional Stability"
              //   ];
              // }
             return(
              <CardWrapper>
              <ContentCard noBg style={{width:cards}}>
                <ContentHeading>{""}</ContentHeading>
                <HeadingCard>
                  {content.subHeading.map((e) => (
                    <SubHeading>{t(e)}</SubHeading>
                  ))}
                </HeadingCard>
              </ContentCard>
              {data.map((x, i) => (
                <ContentCard style={{width:cards}}>
                  <ContentHeading>{t(content.heading)}</ContentHeading>
                  {content.subHeading.map((e, y) => (
                    <SubHeading>
                      {content.type === "range" ? (
                        // <input style={{ margin: "auto" }} type="range" value={0} />
                        // <OceanSlider level={"low"} value={ 0} aria-label="Slider" valueLabelDisplay="auto" />
                        // content.heading === "Five Factors" ? (
                        //   <OceanSlider
                        //     level={x[e]?.score?.toLowerCase()}
                        //     value={normalizeBetween0To5(x[e]?.points) ? normalizeBetween0To5(x[e]?.points) : 0}
                        //     aria-label="Slider"
                        //     valueLabelDisplay="auto"
                        //     max={5}
                        //   />
                        // ) : 
                        (
                          <OceanSlider
                            level={getLevel(x[e])}
                            value={x[e] ? x[e] : 0}
                            aria-label="Slider"
                            valueLabelDisplay="auto"
                          />
                        )): (
                        <div style={{ display: "flex",width:"100%" }}>
                          <ColorBar bg={x[e] === "Low" ? "#FF4D4D" : false} style={{width:cards}}/>
                          <ColorBar bg={x[e] === "Moderate" ? "#FFB422" : false} style={{width:cards}}/>
                          <ColorBar bg={x[e] === "High"? "#4CD051" : false} style={{width:cards}}/>
                        </div>
                      )}
                    </SubHeading>
                  ))}
                </ContentCard>
              ))}
            </CardWrapper>
             )
              
})}
          </>
        ) : (
          ""
        )}
      </SectionWrapper>
    </div>
  );
}
