import React from "react";
import ChartLoader from "../chartLoader";
import VerticalBarChartDataLabels from "../charts/verticalBarChartModel";
import NoDataChartContainer from "../noDataFoundChart";
import Selector from "../select";
import { Ad2Card } from "../adCard";
import { Ad2SelectorWrapper } from "../adCardStyles";
import { useTranslation } from "react-i18next";



export function Ad2StudentWorkInterest({
    data,
    studentWorkInterestFilter,
    setStudentWorkInterestFilterFilter,
}) {
    const { t, i18n } = useTranslation();
    const chartData = data && data.data;
    const gridData = data && data.gridData;
    const status = data && data.status;

    const columns = [
        {
            name: "",
            selectorKey: "type",
            selector: (row) => row.type,
            sortable: false,
        },
        {
            name: t("Male"),
            selectorKey: "male",
            selector: (row) => row.male,
            sortable: false,
        },
        {
            name: t("Female"),
            selectorKey: "female",
            selector: (row) => row.female,
            sortable: false,
        },
    ];


    function getSelectedOption() {
        const val = studentWorkInterestFilter?.replace("rank", "");
        return val ? { label: `Rank ${val}`, value: `rank${val}` } : null;
    }
    function RightContent() {
        const ranks = [
            { label: "Rank 1", value: "rank1" },
            { label: "Rank 2", value: "rank2" },
            { label: "Rank 3", value: "rank3" },
            // { label: "Rank 4", value: "rank4", },
            // { label: "Rank 5", value: "rank5", },
            // { label: "Rank 6", value: "rank6", },
            // { label: "Rank 7", value: "rank7", },
            // { label: "Rank 8", value: "rank8", },
            // { label: "Rank 9", value: "rank9", },
            // { label: "Rank 10", value: "rank10", },
        ];
        return (
            <Ad2SelectorWrapper>
                <Selector
                    onChange={(value) => {
                        setStudentWorkInterestFilterFilter(value && value.value);
                    }}
                    options={ranks}
                    value={getSelectedOption()}
                />
            </Ad2SelectorWrapper>
        );
    }
    return (
        <>
            <Ad2Card
                title={t("Employee's Work Interest")}
                data={gridData}
                columns={columns}
                exportFileName="employees_work_interest"
                rightContent={<RightContent />}
                status={status}
            >
                <VerticalBarChartDataLabels
                    data={{
                        labels: [
                            t("Realistic"),
                            t("Investigative"),
                            t("Artistic"),
                            t("Social"),
                            t("Enterprising"),
                            t("Conventional"),
                        ],
                        datasets: [
                            {
                                label: t("Female"),
                                data: [
                                    chartData && chartData.Realistic && chartData.Realistic.male + chartData.Realistic.female,
                                    chartData &&
                                      chartData.Investigative &&
                                      chartData.Investigative.male + chartData.Investigative.female ,
                                    chartData && chartData.Artistic && chartData.Artistic.male + chartData.Artistic.female,
                                    chartData && chartData.Social && chartData.Social.male + chartData.Social.female,
                                    chartData &&
                                      chartData.Enterprising &&
                                      chartData.Enterprising.male + chartData.Enterprising.female,
                                    chartData &&
                                      chartData.Conventional &&
                                      chartData.Conventional.male + chartData.Conventional.female,
                                ],
                                backgroundColor: [
                                    '#1694F0',
                                    '#0F87DE',
                                    '#0F83D8',
                                    '#0973C0',
                                    '#0A6FB8',
                                    '#0A6FB8',
                                  ],
                                borderRadius: Number.MAX_VALUE,
                                borderSkipped: false,
                                borderColor: "rgba(0,0,0,0)",
                                barPercentage: 1,
                                categoryPercentage: 0.8,
                            },
                        ],
                    }}
                />
            </Ad2Card>
        </>
    );
}
