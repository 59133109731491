import studentListIcon from "../../../assets/svg/studentList.svg";
import studentListIconDisabled from "../../../assets/svg/studentListIconDisabled.svg";
import React from "react";
import {
  Button,
  Col,
  Row,

} from "react-bootstrap";

import { ToggleTab } from "../styles/analyticalStyles";
import styled from "styled-components";
import { Link , useLocation, useSearchParams} from "react-router-dom";
// import CompnayListTable from "../companyTabel/companyListTabel";
// import { InternshipGenderChart } from "../internshipGenderChart/internshipGenderChart";
// import InternDashboardDataTable from "../InternDasboardDataTable/internDashboardDataTable"

// import { TITLE_MAP } from "../internshipConstants/internshipConstants";
import AnalyticalDataTable from "../analyticalDataTable/analyticalDatatable";
import { TITLE_MAP } from "../../../components/analyticalDashboardPage/utilities";
import chartIcon from "../../../assets/svg/chart.svg";
import chartIconDisabled from "../../../assets/svg/chartIconDisabled.svg";
import genderIcon from "../../../assets/svg/gender.svg";
import genderDisabledIcon from "../../../assets/svg/genderIconDisabled.svg";
import { AnalyticalGenderDrillDown } from "../../../components/analyticalDashboardPage/analyticalGenderDrillDown.js/analyticalGenderDrillDown";
import { DRILLDOWN_API_MAP } from "../../constants/ad2Constants";
import { getDrilldown } from "../../../services/apiCalls";
import { t } from "i18next";

export default function AnalyticalCardVarientFour({children}){
  const location = useLocation();
  const [currentPath, setCurrentPath] = React.useState("");
  const controller = new AbortController();
  const [searchParams, setSearchParams] = useSearchParams();
  const [chartData, setChartData] = React.useState({
    data: null,
    status: "loading",
  });
console.log("childerens",children);
  React.useEffect(() => {
    const pathName = location && location.pathname;
    const paths = pathName && pathName.split("/") && pathName.split("/");
    const currentPathStr = paths && paths.length ? paths[paths.length - 1] : "";

    if (currentPathStr) {
      setCurrentPath((TITLE_MAP[currentPathStr]));
      //setCurrentPath(Utils.getPathName(currentPathStr, searchParams));
    }
    setActive(
      types[
      searchParams.get("label") ? 2 : typeof children === "undefined" ? 1 : 0
      ]
    );

    return () => {
      controller.abort();
    };
  }, [children, location]);
    const types = ["chart","gender","studentList"];
    const [active, setActive] = React.useState(types[0]);
    const ButtonToggle = styled(Button)`
    opacity: 0.7;
    ${({ active }) =>
      active &&
      `
      opacity: 1; 
    `}
  `;
  const getDatasetAtEvent = async (radioValueFromChild, year) => {
    setChartData({ data: null, status: "loading" });
    const pathName = location && location.pathname;
    const paths = pathName && pathName.split("/") && pathName.split("/");
    const currentPathStr = paths && paths.length ? paths[paths.length - 1] : "";
    var deapartmentFilter = searchParams.get("department");
    // setGlobalPathStr(currentPathStr);
    const mandatoryParams = [
      { propertyKey: "selectedCampus", urlKey: "campus" },
      { propertyKey: "selectedFaculty", urlKey: "faculty" },
      { propertyKey: "selectedStudyProgram", urlKey: "study_program" },
      { propertyKey: "selectedYearOfStudy", urlKey: "year_of_study" },
      { propertyKey: "selectedUniversity", urlKey: "insti_name" },
      { propertyKey: "selectedScopeOfStudy", urlKey: "scope_of_study" },
    ];
    const params = {};
    mandatoryParams.forEach((param) => {
      params[param.urlKey] = searchParams.get(param.propertyKey);
    });
    if (radioValueFromChild) {
      params.interval = radioValueFromChild;
    }
    if (year && year.value) {
      params.year = year && year.value;
    }
    const levelMap = {
      low: 1,
      moderate: 2,
      high: 3,
    };
    let url =
      DRILLDOWN_API_MAP &&
      DRILLDOWN_API_MAP[currentPathStr] &&
      DRILLDOWN_API_MAP[currentPathStr].genderRatio;
    console.log("26586", url);

    console.log("Drilllls", url);
    switch (currentPathStr) {
      case "students-onboarded":
        break;
      case "students-un-registered":
        break;
      case "completed-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = currentPathStr.split("-")[0];
        params.type = 'status';
        break;
      case "pending-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = currentPathStr.split("-")[0];
        params.type = 'status';
        break;
      case "secured-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = currentPathStr.split("-")[0];
        params.type = 'status';
        break;
      case "available-internships":
        params.start_date = searchParams.get("internshipStartDate");
        params.end_date = searchParams.get("internshipEndDate");
        params.status = "interested";
        params.type = 'status';
        break;
      case "internship-industry-sector":
        params.sector = searchParams.get("sector");
        params.type = 'sector';
        break;
      case "personality-and-motivation":
      case "emotional-stability":
      case "extraversion":
      case "openness-to-experience":
      case "agreeableness":
      case "conscientiousness":
        const label = searchParams.get("label");
        if (currentPathStr !== "personality-and-motivation" && label) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[label];
        }
        break;
      case "work-interest":
      case "mastery":
      case "quality-of-life":
      case "fellowship":
      case "creating-value":

        break;
      case "cognitive":
      case "cognitive-drilldown":
        const label4 = searchParams.get("label");
        params["cognitive_level"] = levelMap[label4];
        break;
      case "critical-thinking":
      case "planning-and-ways-of-working":
      case "communication":
      case "mental-flexibility":
        const label5 = searchParams.get("label");
        if (label5) {
          params[`${currentPathStr.replace(/-/g, "_")}_level`] =
            levelMap[label5];
        }
        break;
      case "english-proficiency-drilldown":
      case "grammar":
      case "comprehension":
        const label3 = searchParams.get("label");
        const levelParam = label3.includes("Level")
          ? label3.replace("Level", "").trim()
          : label3;
        params["level"] = levelParam;
        console.log("consPams", params)
        break;
      default:
        break;
    }
    params['department'] = deapartmentFilter;
    console.log("parqmsPaams", params,)

    if (url) {
      const { data, status } = await getDrilldown(url, {
        filters: params,
        chartsLoadingController: controller,
      });
      if ([1, 200].includes(status)) {
        setChartData({
          data: data.data.map((x) => {
            x.date = x[radioValueFromChild === "month" ? "month" : "date"];
            return x;
          }),
          status: 200,
        });
      } else {
        setChartData({ data: [], status: "noDataFound" });
      }
    }
  };

  const getToggleIconSrc = (type) => {
    switch (type) {
      case "chart":
        return active === type ? chartIcon : chartIconDisabled;
        case "gender":
          return active === type ? genderIcon : genderDisabledIcon;
      case "studentList":
        return active === type ? studentListIcon : studentListIconDisabled;
      default:
        return null;
    }
  };
  const Heading = styled.div`
  margin-top: -8px;
  //margin-bottom: 1%;
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
  @media (max-width:500px){
    margin:20px 0 20px 5px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;
    return (
        <>
     
        <Row>
        
        {/* <Col>
          <Heading>Internship Dashboard</Heading>
        </Col> */}
        
      </Row>
        <Row>
          <div>
            <ToggleTab className="toggletab">
              {types
                .filter((type) =>
                  type === "gender"
                    ? !searchParams.get("label")
                    : type === "chart"
                    ? !!children
                    : true
                )
                .map((type, i) => (
                  <ButtonToggle
                    key={i}
                    className="togglebutton"
                    active={active === type}
                    onClick={() => {
                      setActive(type);
                    }}
                  >
                    <img
                      className="toggleimg"
                      height={20}
                      alt="toggle-items"
                      src={getToggleIconSrc(type)}
                    />
                  </ButtonToggle>
                ))}
            </ToggleTab>
          </div>
        </Row>
        <Row>
          <Col xs="12">
            {active === "chart" && <div>{children}</div>}
            {active === "gender" && !searchParams.get("label") && (
            <AnalyticalGenderDrillDown
              title={t(currentPath)}
              data={chartData}
              refreshData={getDatasetAtEvent}
            />
          )}
            {active === "studentList" && (
            <AnalyticalDataTable></AnalyticalDataTable>
          )}
           
          </Col>
        </Row>
       
      </>
    );
}