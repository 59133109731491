import React, { useState, useEffect } from "react";
import UserManagementCard from "../../components/userManagement/UserManagement";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import Input from "../../components/input/input";
import AddNewUser from "../../components/addNewUser/addNewUser";

import { toast } from "react-toastify";
import Union from "../../assets/Union.png";
import {
  updateUsersForSelectedCompany,
  updateIsUserAdmin,
} from "./userManagerReducer";
import { useSelector, useDispatch } from "react-redux";
import { getAllUsers, deleteCompanyUser } from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import UserManagementPopup from "./userManagementPopUps";
import UnAuthPopup from "./unAuthPopup";
import { useNavigate } from "react-router-dom";
import XLSX from "sheetjs-style";
import * as FileSaver from "file-saver";
import InfiniteScroll from "react-infinite-scroll-component";

const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin-top: -10px;
  @media (max-width: 500px) {
    margin-top: 0px;
  }
  @media (max-width: 768px) {
    margin-top: 0px;
  }
  @media (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";

  @media (max-width: 767px) {
    display: none;
  }
`;
const SearchSection = styled.div`
  @media (min-width: 768px) {
    display: none;
  }
`;
const Section = styled.div`
  margin-bottom: 50px;
`;

const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem 2rem 1rem 2rem;
  margin: 1rem 0;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 1rem 0;
  }
`;
const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const FilterImg = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 12%;
  text-align: center;
  @media (max-width: 600px) {
    position: relative;
    bottom: 50px;
    left: 82%;
    width: 22px;
    height: 15px;
    padding: 10px 0px 0px 0px;
    margin: 3px 0px 0px 10px;
  }
  img {
    widith: 60%;
  }
`;


const UserManagement = () => {
  const [searchval, setSearchFilter] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchIdx, setSearchIdx] = useState(undefined);
  const dispatch = useDispatch();
  const companies = useSelector((state) => state.company.companyData);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const users = useSelector(
    (state) => state.userManager.usersForSelectedCompany
  );
  const currentUser = useSelector((state) => state.userManager.currentUser);
  const [orgCode, setOrgCode] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [checkDelete, setCheckDelete] = useState(false);
  const [unAuth, setUnAuth] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [deleteOrgCode, setDeleteOrgCode] = useState(null);
  const navigate = useNavigate();
  const [paginationCount, setPaginationCount] = useState(5);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [paginationUsers, setPaginationUsers] = useState(undefined);
  const [exportExcelData, setExportExcelData] = useState([]);
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfRows = 3;

  const userRole = useSelector((state) => state.company);

  let first_name = "";
  let last_name = "";


  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    createFirstLastNames(e.target.value);
    let splitCount = e.target.value.split(" ").length
    let arr = [];
    let userKeys = Object.keys(users);
    for (let i = 0; i < userKeys.length; i++) {
      if ((!(splitCount > 1)) && (users[userKeys[i]].first_name.toLowerCase().includes(first_name) || users[userKeys[i]].last_name.toLowerCase().includes(last_name))){
        arr.push(i);
      }
      if ((splitCount > 1) && (users[userKeys[i]].first_name.toLowerCase().includes(first_name) && users[userKeys[i]].last_name.toLowerCase().includes(last_name))){
        arr.push(i);
      }
      
    }
    setSearchIdx(arr);
  };

  function createFirstLastNames(value) {
    let splittedName = value.split(" ");
    if (splittedName.length > 1) {
      for (let i = 0; i < splittedName.length - 1; i++) {
        first_name = first_name + splittedName[i];
      }
      last_name = splittedName[splittedName.length - 1];
    } else {
      first_name = splittedName[0] && splittedName[0].toLowerCase();
      last_name = splittedName[0] && splittedName[0].toLowerCase();
    }
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  useEffect(() => {
    if (searchval === "" && currentPage === 0) {
      let cmpyCount = Object.keys(users).length;
      if (cmpyCount > 0) {
        let quo = Math.floor(cmpyCount / numberOfRows);
        let rem = cmpyCount % numberOfRows;
        setPaginationCount(rem !== 0 ? quo + 1 : quo);
        setTotal(rem !== 0 ? quo + 1 : quo)
        setCurrentPage(0)
        setPaginationTabIndex(1);
        setPaginationUsers(getPaginationUsers(1));
      }
    }
  }, [users, isSearch]);



  useEffect(() => {
    getOrgCode();
  });

  useEffect(() => {
    checkIsAdminUser();
  });

  useEffect(() => {
    getAllUsersForSelectedCompany();
  }, [orgCode]);

  useEffect(() => {
    if (!isEmpty(users)) setLoading(false);
    if (!isEmpty(users)) {
      setExportExcelData(generateExportExcelData());
    }
  }, [users]);

  useEffect(() => {
    if (searchval === "") {
      setIsSearch(false);
      setSearchError(false);
      setCurrentPage(0)
      setPaginationTabIndex(1);
      displayPage(1);
    }
  }, [searchval]);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  function checkIsAdminUser() {
    if (!isEmpty(currentUser) && !isEmpty(users)) {
      for (let i = 0; i < Object.keys(users).length; i++) {
        if (currentUser.id === users[Object.keys(users)[i]].id) {
          if (users[Object.keys(users)[i]].is_admin === 'Y')
            dispatch(updateIsUserAdmin(true))
          return
        }
      }
    }
  }

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name === company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }

  const getAllUsersForSelectedCompany = async () => {
    if (orgCode) {
      setPaginationUsers({});
      const { data } = await getAllUsers(orgCode);
      if (data && data.status === 1) {
        dispatch(updateUsersForSelectedCompany({ ...data.data }));
        setPaginationUsers({ ...data.data });
      }
    }
  };
  const showToast = () => {
    toast.success("User Created successfully", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const showToastDeleted = (type, msg, status) => {
    if (status === "success") {
      toast.success(`User ${type} successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  function deleteUser(org_code, id) {
    if (userRole?.currentOrgData.user_details.user_management_modify !== "Y") {
      return setUnAuth(true);
    }
    setCheckDelete(true);
    setDeleteOrgCode(org_code);
    setDeleteUserId(id);
  }

  function proceed() {
    confirmDeleteUser(deleteOrgCode, deleteUserId);
    setCheckDelete(false);
  }

  const confirmDeleteUser = async (org_code, deleteUserId) => {
    const { data } = await deleteCompanyUser(org_code, { id: deleteUserId });
    if (data && data.status === 1) {
      showToastDeleted("Deleted", "", "success");
      getAllUsersForSelectedCompany();
    } else {
     
      showToastDeleted("Deleted", data.message, "failed");
    }
  };

  function proceedUnAuth() {
    setUnAuth(false);
    localStorage.removeItem("auth_token");
    navigate("/login");
  }

  const displayPage = (val) => {
    setPaginationTabIndex(val);
    setPaginationUsers(getPaginationUsers(val));
  };

  function getPaginationUsers(tabIdx) {
    let cmpyObj = {};
    let cmpyCount = Object.keys(users).length;
    let toIdx = tabIdx * numberOfRows;
    if (toIdx > cmpyCount) {
      toIdx = cmpyCount;
    }

    for (let i = 0; i < toIdx; i++) {
      cmpyObj[i] = users[i];
    }
    return cmpyObj;
  }

  const fetchData = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
      displayPage(currentPage + 2)
    }
  };

  function handleSearch() {
    if (searchval.length > 0) {
      if (searchIdx === undefined) {
        setSearchError(true);
        setIsSearch(true);
        return;
      }
      let obj = {};
      for (let i = 0; i < searchIdx.length; i++) {
        obj[searchIdx[i]] = users[searchIdx[i]];
      }
      setPaginationUsers(obj);
      setIsSearch(true);
    }

    /*let obj = {}
    obj[searchIdx] = users[searchIdx]
    setPaginationUsers(obj)
    setIsSearch(true)*/
  }

  function getFullString(data) {
    if (data == null || data === undefined) return data
    if (data.toUpperCase() === 'Y') {
      return 'Yes'
    } else if (data.toUpperCase() === 'N') {
      return 'No'
    }
    return data;
  }

  function generateExportExcelData() {
    let data = []
    let userExportDataHeaders = ['first_name', 'last_name', 'email', 'department', 'job_title', 'mob_number', 'is_admin', 'company_view', 'company_modify', 'internship_view', 'internship_modify', 'view_application', 'modify_application', 'view_job', 'modify_job', 'view_talents', 'search_talents', 'user_management_view', 'user_management_modify']
    if (!isEmpty(users)) {
      Object.keys(users).length > 0 && Object.keys(users).map((list, index) => {
        let usrObj = {}
        for (let i = 0; i < userExportDataHeaders.length; i++) {
          usrObj[userExportDataHeaders[i]] = getFullString(users[list][userExportDataHeaders[i]])
        }
        data.push(usrObj)
      })

    }
    return data
  }

  function exportDownload() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = company.name + " - User Management List";
    const ws = XLSX.utils.json_to_sheet(exportExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  function handleExcelExport() {
    exportDownload();
  }

  return (
    <>
      <UserManagementPopup
        isOpen={checkDelete}
        setOpen={setCheckDelete}
        proceed={proceed}
      ></UserManagementPopup>
      <UnAuthPopup
        isOpen={unAuth}
        setOpen={setUnAuth}
        proceed={proceedUnAuth}
      ></UnAuthPopup>
      <MainLayout>
        <HeaderText>{"User Management"}</HeaderText>
        <CompanySection>
          <InputBox>
            <Input
              color="#fff"
              placeholder="Search Keywords for User Name"
              value={searchval}
              onChange={onSearch}
            />
          </InputBox>

       
          <div className="" style={{ marginLeft: "0px" }}>
            <Button
              style={{ cursor: "pointer" }}
              name="Search"
              onClick={handleSearch}
            />
          </div>
        </CompanySection>
        <SearchSection>
          <InputBox>
            <Input
              color="#fff"
              placeholder="Search Keywords for User Name"
              value={searchval}
              onChange={onSearch}
              img={Union}
            ></Input>
            <FilterImg>
              <img src={Union} alt="imgUnion" style={{}} />
            </FilterImg>
          </InputBox>
        </SearchSection>
        <AddNewUser
          setUnAuth={setUnAuth}
          handleExcelExport={handleExcelExport}
        />
        <>
          <Section>
            {loading ? (
              <Loader />
            ) : (
              <>
                <InfiniteScroll
                  dataLength={Object.keys(paginationUsers).length || 0}
                  next={fetchData}
                  hasMore={currentPage < total}
                  scrollThreshold={0.8}
                >
                  {paginationUsers &&
                    Object.keys(paginationUsers).length > 0 &&
                    !searchError ? (
                    Object.keys(paginationUsers).map((list, index) => (
                      <PageWrapper>
                        <UserManagementCard
                          showToast={showToast}
                          showToastDeleted={showToastDeleted}
                          key={users[list].id}
                          datas={users[list]}
                          deleteUser={deleteUser}
                        />
                      </PageWrapper>
                    ))
                  ) : (
                    <p class="noDataFound">No data found</p>
                  )}
                </InfiniteScroll>
              </>
            )}
          </Section>
        </>
        
      
      </MainLayout>
    </>
  );
};

export default UserManagement;
