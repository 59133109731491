import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { Colors } from "../../utilities/colors";
import Button from "../../components/button/button";
import ErrorField from "../../components/errorField/errorField";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "../../components/input/input";
import * as yup from "yup";
import styled from "styled-components";
import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import moment from "moment";
import RadioInput from "../radioInput/radioInput";
import { t } from "i18next";

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.labelText};
  visibility: ${(props) => (props.visible ? "hidden" : "default")};
  margin-bottom: 10px;
  > span {
    font-style: italic;
    font-weight: 300;
    font-size: 14px;
  }
`;
const UploadFileText = styled.label`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  text-decoration-line: underline;
  color: ${Colors.primaryText};
  cursor: pointer;
  //  margin:auto;
`;
const AddressWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-flow: wrap;
  gap: 20px;
  justify-content: ${(props) => (props.alignEnd ? "flex-end" : "space-around")};
  @media (max-width: 500px) {
    justify-content: space-around;
    gap: 0px;
  }
`;
const AddressCard = styled.div`
  width: 300px;
  padding: 12px;
  display: flex;
  flex-dircetion: row;
`;
const AddressLayout = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  position: relative;
  margin: 0 -28px;
  width: auto;
  @media (min-width: 320px) and (max-width: 480px) {
    margin: 0px -3px;
  }
`;
const FormWrapper = styled.div``;
const SpanText = styled.div`
  margin-top: 6px;
`;
const GroupWrapper = styled.div`
  margin-bottom: 20px;
  label {
    position: relative;
    width: -webkit-fill-available;
  }
`;
const MainWrapper = styled.div`
  padding: 24px 64px;
`;

export default function SubscriptionForm({ openPreview }) {
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const schema = yup.object().shape({
    name: yup.string().required("Name is required."),
    position: yup.string().required("Position is required."),
    department: yup.string().required("Department is required."),
  });
  const initialState = {
    name: "",
    position: "",
    department: "",
  };
  const {
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: { ...initialState },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });
  const formVal = watch();
  const [primaryAdd, setPrimaryAdd] = useState(undefined); //"H.no:6-79, Kompally,Medchal Mandal ,Telangana,India"
  const [secondaryAdd, setSecondaryAdd] = useState(undefined);
  const [iAgree, setIAgree] = useState(false);
 
  const [selectedPlan, setSelectedplan] = useState({});
  const [name, setName] = useState("");
  const [department, setDepartment] = useState("");
  const [position, setPosition] = useState("");
  const [address, setAddress] = useState("");

  const [addType,setAddType] = useState("primary_checked")

  const [prevData,setPrevData] = useState(true)
  const [primaryAddCheckbox, setPrimaryAddCheckbox] = useState(true);
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscribedData = useSelector((state) => state.subscription.subscriptionData);

  const onInputChange = (type, val) => {
    if (type === "name") {
      setName(val.target.value);
    }
    if (type === "position") {
      setPosition(val.target.value);
    }
    if (type === "department") {
      setDepartment(val.target.value);
    }
  };

  function handleToggle(e) {
    if (e.target.id === "iAgree") {
      setIAgree(!iAgree);
    }
  }

  const dropdownChange = (e) => {
    setSelectedplan(e);
  };
  const onSubmit = () => {
    localStorage.setItem("name", formVal?.name);
    localStorage.setItem("position", formVal?.position);
    localStorage.setItem("department", formVal?.department);
    localStorage.setItem("address_type", addType);
    localStorage.setItem("address", address);
    localStorage.setItem(
      "country",
      primaryAddCheckbox
        ? company?.locations[0]?.country_abbr
        : company?.locations[1]?.country_abbr
    );
    localStorage.setItem(
      "state",
      primaryAddCheckbox
        ? company?.locations[0]?.state_abbr
        : company?.locations[1]?.state_abbr
    );
    localStorage.setItem(
      "city",
      primaryAddCheckbox
        ? company?.locations[0]?.city_abbr
        : company?.locations[1]?.city_abbr
    );
    localStorage.setItem(
      "country_code",
      primaryAddCheckbox
        ? company?.locations[0]?.country_code
        : company?.locations[1]?.country_code
    );
    let today = moment().add(1, "years").calendar();
    const renewDate =
      today.slice(3, 5) + "-" + today.slice(0, 2) + "-" + today.slice(6);
    if (subscribedData[org_code] && checkDateBeforeMonth()) {
      console.log("subscriptionData",subscribedData,"orgData",org_code)
      const old = subscribedData[org_code]?.renew_date?.slice(0, 9)
      const updatedDate = subscribedData[org_code]?.renew_date?.slice(9)
      const val = +updatedDate + 1
      console.log("rrrRenewDate",old,val);

      localStorage.setItem("renew_date", old + val)
    }
    else {
      localStorage.setItem("renew_date", renewDate);
    }
    localStorage.setItem("paymentstatus", "pending");
    openPreview();
  };

  useEffect(() => {
    if (org_code && subscribedData[org_code] && prevData) {
      setValue('name', subscribedData[org_code]?.name)
      setValue('position', subscribedData[org_code]?.position)
      setValue('department', subscribedData[org_code]?.department)
      setValue("address_type",subscribedData[org_code]?.address_type)
      if(subscribedData[org_code]?.address_type && subscribedData[org_code]?.address_type.length>0) 
      setAddType(subscribedData[org_code]?.address_type)
      setPrevData(false)
    }

  })


  const checkDateBeforeMonth = () => {
    let oneMonthBefore = false
    let today = moment(new Date()).format("DD-MM-YYYY");
    let renew_date = subscribedData[org_code]?.renew_date
    today = today.slice(6) + "-" + today.slice(3, 5) + "-" + today.slice(0, 2)
    renew_date = renew_date.slice(6) + "-" + renew_date.slice(3, 5) + "-" + renew_date.slice(0, 2)

    let todayEpoch = new Date(today).getTime() / 1000
    let renewEpoch = new Date(renew_date).getTime() / 1000
    const monthGap = 30 * 24 * 60 * 60
    if (renewEpoch - todayEpoch > 0) {
      oneMonthBefore = true
    }
    return oneMonthBefore
  }

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }



  function handleRadio(e) {
    if (e === "primary_checked") {
      setAddress(primaryAdd);
      setAddType("primary_checked")
      setPrimaryAddCheckbox(!primaryAddCheckbox);
    }
    if (e === "secondary_checked") {
      setAddress(secondaryAdd);
      setAddType("secondary_checked")
      setPrimaryAddCheckbox(!primaryAddCheckbox);
    }
    return
  }

  useEffect(() => {
    if (!isEmpty(company)) {
      let primary = company.locations[0];
      let secondary = company.locations[1];
      if (primary)
        setPrimaryAdd(
          primary.rgtr_address +
          ", " +
          primary.city_abbr +
          ", " +
          primary.state_abbr +
          ", " +
          primary.country_abbr +
          " - " +
          primary.country_code
        );
      if (secondary)
        setSecondaryAdd(
          secondary.rgtr_address +
          ", " +
          secondary.city_abbr +
          ", " +
          secondary.state_abbr +
          ", " +
          secondary.country_abbr +
          " - " +
          secondary.country_code
        );
      setAddress(
        primary.rgtr_address +
        ", " +
        primary.city_abbr +
        ", " +
        primary.state_abbr +
        ", " +
        primary.country_abbr +
        " - " +
        primary.country_code
      );
    }
  }, [company]);
  return (
    <MainWrapper>
      <FormLabel>{t("Billing Address")}</FormLabel>
      <AddressWrapper>
        <AddressCard>
        

          <RadioInput
           
            checked={addType==="primary_checked"}
            margin="15px"
            dataId="primary_checked"
            onChange={() => handleRadio("primary_checked")}
          />
          {/* </Form.Group> */}
          <AddressLayout>
            {t("Primary Address")}
            <br />
            <SpanText style={{ marginTop: "8px" }}> {primaryAdd}</SpanText>
          </AddressLayout>
        </AddressCard>
        {company?.locations?.length > 1 ? (
          <AddressCard>

            <RadioInput
              checked={addType==="secondary_checked"}
              style={{ background: "#D04C8D" }}
              dataId="secondary_checked"
              onChange={() => handleRadio("secondary_checked")}
            />
            {/* </Form.Group> */}
            <AddressLayout>
              {t("Secondary Address")}
              <br />
              <SpanText style={{ marginTop: "8px" }}> {secondaryAdd}</SpanText>
            </AddressLayout>
          </AddressCard>
        ) : (
          <></>
        )}
      </AddressWrapper>
      <FormWrapper>
        <GroupWrapper>
          <FormLabel>{t("Name")}</FormLabel>

          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("Name")}
                dataId="name"
                name={name}
              />
            )}
          />
          {errors.name && <ErrorField err={t(errors.name.message)} />}
        </GroupWrapper>

        <GroupWrapper>
          <FormLabel>{t("Department")}</FormLabel>

          <Controller
            name="department"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("Enter your Department")}
                dataId="department"
                department={department}
              />
            )}
          />

          {errors.department && <ErrorField err={t(errors.department.message)} />}
        </GroupWrapper>
        <GroupWrapper>
          <FormLabel>{t("Position")}</FormLabel>

          <Controller
            name="position"
            control={control}
            render={({ field }) => (
              <Input
                {...field}
                placeholder={t("Enter your Position")}
                dataId="position"
                position={position}
              />
            )}
          />
          {errors.position && <ErrorField err={t(errors.position.message)} />}
        </GroupWrapper>
      
        <div style={{ display: "flex" }}>
          <Form.Group
            className="mb-3"
            controlId="iAgree"
            onClick={handleToggle}
          >
            <Form.Check type="checkbox" label="" defaultChecked={false} />
          </Form.Group>
          <UploadFileText
            onClick={() =>
              window.open(
                "https://www.talentcorp.com.my/resources/policy/terms-conditions",
                "_blank"
              )
            }
          >
            {t("I agree to the term of subscription.")}
          </UploadFileText>
        </div>
        <div style={{ textAlign: "center", paddingTop: "15px" }}>
          <ButtonWrapper alignEnd>
            <Button
              onClick={handleSubmit(onSubmit)}
              name={t("Subscribe")}
              type="submit"
              disabled={!iAgree}
            />
          </ButtonWrapper>
        </div>
      </FormWrapper>
    </MainWrapper>
  );
}
