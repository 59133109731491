import React, { useState } from "react";
import Dropdown from "../../components/dropdown/dropdown";
import styled from "styled-components";
import Button from "../button/button";
import { useSnapshot } from "valtio";
import { addapplications } from "../../services/apiCalls";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CardsWrapper = styled.div`
  position: absolute;
  width: 645px;
  height: 476px;
  // left: 33%;
  top: 157px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;

  @media (max-width: 500px) {
    width: auto;
    height: 630px;
    position: relative;
    padding-top: 10px;
    // left: auto;
    margin-left: 10px;
    margin-right: 10px;
  }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 136px;
  margin: 10px;
  display: flex;
`;
const Icon = styled.div`
  position: absolute;
  top: 10px;
  right: 30px;
  @media (max-width: 500px) {
    right: 30px;
    top: 25px;
  }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 30px 50px;
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding: 50px 0px;
  margin: 10px 0 0 -120px;
`;

const SubContents = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  position: relative;
  top: 20px;
  span {
    display: block;
  }
`;

const Wrapper2 = styled.div`
  position: relative;
  width: 501px;
  height: 128px;
  left: 72px;
  top: 60px;
  @media (max-width: 500px) {
    position: relative;
    width: auto;
    height: 128px;
    left: 0px;
    top: 60px;
    margin-left: 20px;
    margin-right: 20px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
`;
const ShortlistModal = ({ usersShortlisted ,open, onClose, id, joblist, name }) => {
  console.log("userIdID",id,joblist,name);
  useSnapshot(trackProxy);
  const [job, setJob] = useState([]);
  const [dis, setDis] = useState(true);
  const { t, i18n } = useTranslation();
  const submit = async () => {
    console.log("userIdID",id,job.value);
    const { data } = await addapplications(job.value, id);
    if (data) {
       usersShortlisted();
      toast.success(t("Application Shortlisted"), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    close();
  };
  // "69a5b56d-0255-46e4-a32c-2638d86eccfc"

  const close = () => {
    if (trackProxy.setcheck === 0) {
      trackProxy.setcheck = 1;
    } else {
      trackProxy.setcheck = 0;
    }
    onClose();
  };

  //
  return (
    <div className="overlay-popup">
      <CardsWrapper>
        <CardWrapper id="headercard">
          <Title>{t("Shortlist")}</Title>
          <Content>{t("You are set to shortlist")} {name}.</Content>
          <Icon onClick={close} id="headericon" style={{ cursor: "pointer" }}>
            X
          </Icon>
        </CardWrapper>
        <SubContents>
          {name} {t("is being shortlisted for the following")}
          <span>{t("job position.")}</span>
        </SubContents>
        <Wrapper2>
          <Dropdown
            name="Shortlist"
            search={true}
            options={joblist}
            placeholder="Please Select some options"
            minWidth="auto"
            width="200px"
            isMulti={false}
            val={job?.value}
            label={job?.label}
            change={(e) => {
              setJob(e);
              setDis(false);
            }}
          />
        </Wrapper2>
        <ButtonWrapper>
          <Button
            onClick={submit}
            cursor="pointer"
            name={t("Shortlist")}
            disabled={dis}
          ></Button>
        </ButtonWrapper>
      </CardsWrapper>
    </div>
  );
};

export default ShortlistModal;
