export const staticState = {
  title: "Employee Management",
  description:
    "Edit or add Employee for your organisation changes will be sent to talent corp for approval.",
  downloadTemplate: "Download Template Here",
  uploadInternships: "Add Employee",
  addBulk: "Add Bulk Employee",
  uploadFile: "UPLOAD FILE",
  subTitle: "Upload a file with Employee details",
  subTitleStudent: "Add an Employee to ",
};
