import React from 'react'
import MainLayout from "../../layouts/mainLayout/mainLayout";
import { useNavigate, useLocation } from "react-router-dom";
import rightarrow from "../../assets/icons/arrow_right.png";
import styled from 'styled-components';
import { Colors } from '../../utilities/colors';
import { QuestField } from './staticContent';
import { useEffect, useState } from "react";
import Loader from "../../components/loader/loader";
import { getQuestionAnswers} from '../../services/apiCalls';
import PDF from "../../assets/icons/Company.png"; 
import { Image } from "react-bootstrap";
import EditIcon from "../../assets/icons/svg/pencil.svg";
import moment from "moment";
import { getInternshipModuleByID } from '../../services/apiCalls';

const NewPageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 50px;
  padding: 2rem;
  margin: 2rem 0;
  @media (min-width: 767px) and (max-width: 1023px) {
    margin: 0 -10px 0 -20px;
  }
`;

const HeaderLabel = styled.h3`
  font-weight: 600;
  font-size: 30px;
  line-height: 27px;
  color: ${Colors.pink};
  padding: 10px;

`;

const GroupWrapper= styled.div`
margin-top: 10px;
padding: 10px;
margin-bottom: 10px;
`;

const Questionlabel = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: ${Colors.dark};
  margin: 0;
`;

const SubDetailText = styled.h3`
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: ${Colors.dark};
  margin-top: 20px
`;

const DataLabel = styled.h4`
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  color: ${Colors.greyText};
  margin: 0;
`;

const IconSingleSection = styled.div`
  display: flex;
  align-items: center;
  margin-right: ${(props) => (props.margin ? props.margin : "0px")};
`;

const AttachedDocument = styled.div`
  // font-family: General Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 33px;
  text-decoration-line: underline;
  color: #d04c8d;
`;

const OtherText = styled.p`
  margin: 0 0 0 5px;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #525252;
`;


const HeaderDiv = styled.div`
display: flex;
flex-direction: row;
justify-content: space-between;
margin-right: 30px;
`;

const IconSection2 = styled.div`
    padding: 7px;
    cursor: pointer;
`
const Root = styled("div")(
  ({ theme }) => `
  table {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 0.875rem;
    border-collapse: collapse;
    width: 100%;
  }

  td,
  th {
    border: 1px solid ${grey[200]};
    text-align: left;
    padding: 6px;
  }

  th {
    background-color: ${grey[100]};
  }
  `
);

const grey = {
  50: "#F3F6F9",
  100: "#E7EBF0",
  200: "#E0E3E7",
  300: "#CDD2D7",
  400: "#B2BAC2",
  500: "#A0AAB4",
  600: "#6F7E8C",
  700: "#3E5060",
  800: "#2D3843",
  900: "#1A2027",
};
export default function ViewInternshipModuleDetails() {
    const location = useLocation();
    const navigate = useNavigate();
    const id = location?.state?.id;
    //const internshipData = JSON.parse(localStorage.getItem('internshipData'));  
    const createdYear = localStorage.getItem('createdYear');    
    // console.log(internshipData, "SELECTED DATA FROM DASH");
    console.log(createdYear, "SELECTED DATA FROM DASH");
    const [loading, setLoading] = useState(false);
    const [internshipModuleDetail, setInternshipModuleDetail] = useState({})
    const [internshipModuleResponse, setInternshipModuleResponse]= useState([])
    const [answersList, setAnswersList] = useState([]);
    const [theArray, setTheArray] = useState([]);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [page, setPage] = useState(0);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - theArray.length) : 0;
    const sucessUpdate = location?.state?.state
    const [internshipData, setInternshipData] = useState({});
    const [responses, setResponses] = useState([]);
    console.log(id, "ID IN VIEW PAGE");

   

    const retrieveInternshipModuleData = async () => {
      setLoading(true);
      try {
          const { data } = await getInternshipModuleByID(id);
          if (data) {
              setLoading(false);
              setInternshipData(data);
              setResponses(data?.responses);
              console.log("DATA", data);
          } else {
            setLoading(false);
              console.log("No data received");
          }
      } catch (error) {
        setLoading(false);
          console.error("Error fetching data:", error);
      }
  };
  
  useEffect(() => {
      if (id) {
          retrieveInternshipModuleData();
      }
  }, [id]);
  
    useEffect(() => {
    // Define an async function to fetch data
    const fetchData = async () => {
        const { data } = await getQuestionAnswers();
        if (data) {
        //   setQuestionsList(data?.questions);
        setAnswersList(data?.answers);
        }
    };

    fetchData();
    }, []);
console.log(internshipData, "DATA TO DISPLAY")
    useEffect(()=> {
      if(internshipData){
        setTheArray(internshipData?.comments)
      }
      if(sucessUpdate === 'sucess'){
        retrieveInternshipModuleData()
      }
    },[internshipData, sucessUpdate, id])
    const getFileName = (url) => {
        const pathname = new URL(url).pathname;
        return pathname.split("/").pop();
    };

    function extractYearFromCreatedAt(created_at) {
        const createdAtDate = new Date(created_at);
        const year = createdAtDate.getFullYear();
        console.log(year,"EXTRACTED")
        return year;
        
      }

  return (
    <MainLayout>
         <div className="breadcrumb">
            <p onClick={() => navigate("/dashboard")} style={{ cursor: "pointer" }}>
              {"Dashboard"}
            </p>
            <img
              src={rightarrow}
              alt="img"
              style={{ height: "14px", marginTop: "-10px" }}
            />
            <p style={{ color: "#D04C8D" }}>{`View Internship Module ${extractYearFromCreatedAt(createdYear)}`}</p>
          </div>
          {internshipData.status === "review"? (
              <div className="container mb-5">
              <Root sx={{ width: 200, maxWidth: "50%" }}>
                <table aria-label="custom pagination table" 
                style={{
                  background: "white",
                  borderRadius: "20px"
                }}>
                  <thead>
                    <tr>
                      <th
                        style={{
                          background: "rgb(250, 74, 161)",
                          color: "#fff",
                          borderTopLeftRadius: "20px"
                        }}
                      >
                        {"Admin's Review"}
                      </th>
                      <th
                        style={{
                          background: "rgb(250, 74, 161)",
                          color: "#fff",
                          borderTopRightRadius: "20px"
                        }}
                      >
                        {"Created"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {theArray?.length > 0
                      ? (rowsPerPage > 0 && theArray?.length
                        ? theArray.slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        : theArray
                      ).map((row) => (
                        <tr key={row.comment}>
                          <td>{row.comment}</td>
                          <td style={{ width: 120 }} align="right">
                            {moment(new Date(row.created_at)).format(
                              "DD/MM/YYYY"
                            )}
                          </td>
                        </tr>
                      ))
                      : ""}

                    {emptyRows > 0 && (
                      <tr style={{ height: 41 * emptyRows }}>
                        <td colSpan={3} />
                      </tr>
                    )}
                  </tbody>
                  {theArray?.length > 0 ? (
                    <tfoot>
                      <tr>
                        {/* <CustomTablePagination
                          rowsPerPageOptions={[
                            5,
                            10,
                            25,
                            { label: "All", value: -1 },
                          ]}
                          colSpan={3}
                          count={theArray?.length ?? "0"}
                          rowsPerPage={rowsPerPage}
                          page={page}
                          componentsProps={{
                            select: {
                              "aria-label": "rows per page",
                            },
                            actions: {
                              showFirstButton: true,
                              showLastButton: true,
                            },
                          }}
                          onPageChange={handleChangePage}
                          onRowsPerPageChange={handleChangeRowsPerPage}
                        /> */}
                      </tr>
                    </tfoot>
                  ) : (
                    <div className="container col-lg-12" style={{margin: "10px"}}>{"No Comments"}</div>
                  )}
                </table>
              </Root>
            </div>
            ):null}

          <NewPageWrapper>
            <HeaderDiv>
            <HeaderLabel>Internship Module {extractYearFromCreatedAt(createdYear)}</HeaderLabel>  
            {internshipData?.status === 'review' && (
              <IconSection2 onClick={()=> navigate("/view-internship-module", {state: {origin: "dashboard", id: internshipData.id}})}>
              <Image src={EditIcon}/>
            </IconSection2>
            )}
            </HeaderDiv>
            
            <hr style={{opacity: 0.1}}/>
            <GroupWrapper>
                <Questionlabel>{QuestField.question1}</Questionlabel>
                <DataLabel>{internshipData.min_10_weeks===1?"Yes":"No"}</DataLabel>
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.question2}</Questionlabel>
                <DataLabel>{internshipData.min_500_SKM1_Diploma===1?"Yes":"No"}</DataLabel>
                {internshipData.min_500_SKM1_Diploma===1 && (
                  <>
                    <SubDetailText>{QuestField.subQuestion}</SubDetailText>
                    <DataLabel>RM {internshipData.actual_allowance_SKM1_Diploma}</DataLabel>
                  </>
                )}
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.question4}</Questionlabel>
                <DataLabel>{internshipData.min_600_ProfCert_Master===1?"Yes":"No"}</DataLabel>
                    {internshipData.min_600_ProfCert_Master=== 1 && (
                    <>
                      <SubDetailText>{QuestField.subQuestion}</SubDetailText>
                      <DataLabel>RM {internshipData.actual_allowance_ProfCert_Master}</DataLabel>
                    </>
                  )}
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.remark}</Questionlabel>
                {internshipData.min_600_ProfCert_Master && internshipData.min_500_SKM1_Diploma && internshipData.min_10_weeks===1 ? (
                 <ul>
                  <li>
                    <DataLabel>{QuestField.is_eligible}</DataLabel>
                  </li>
                 </ul> 
                ):(
                  <ul>
                  <li>
                  <DataLabel>{QuestField.not_eligible}</DataLabel>
                  </li>
                 </ul>
                )}
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.obj_student}</Questionlabel>
                <ul style={{ marginTop: "10px" }}>
                {responses
                    .filter((response) => response.question_code === 1)
                    .map((response) => {
                        const answer = answersList.find((answer) => answer.answer_code === response.answer_code);
                        if (answer ) {
                            return (
                                <li key={response.id}>
                                    <DataLabel>{answer.answer_desc === "Others (please specify)"? response.others:
                                     answer.answer_desc}</DataLabel>
                                </li>
                            );
                            }
                    })}
              </ul>
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.obj_company}</Questionlabel>
                <ul style={{ marginTop: "10px" }}>
                {responses
                    .filter((response) => response.question_code === 2)
                    .map((response) => {
                        const answer = answersList.find((answer) => answer.answer_code === response.answer_code);
                        if (answer ) {
                            return (
                                <li key={response.id}>
                                    <DataLabel>{answer.answer_desc === "Others (please specify)"? response.others:
                                     answer.answer_desc}</DataLabel>
                                </li>
                            );
                            }
                    })}
              </ul>
            </GroupWrapper>

            

            <GroupWrapper>
                <Questionlabel>{QuestField.act_offered}</Questionlabel>
                <ul style={{ marginTop: "10px" }}>
                {responses
                    .filter((response) => response.question_code === 3)
                    .map((response) => {
                        const answer = answersList.find((answer) => answer.answer_code === response.answer_code);
                        
                        if (answer && response.answer_desc !== "Others (please specify)") {
                            return (
                                <li key={response.id}>
                                    <DataLabel>{answer.answer_desc === "Others (please specify)"? response.others:
                                     answer.answer_desc}</DataLabel>
                                </li>
                            );
                            }
                    })}
              </ul>
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.expected_outcome}</Questionlabel>
                <ul style={{ marginTop: "10px" }}>
                {responses
                    .filter((response) => response.question_code === 4)
                    .map((response) => {
                        const answer = answersList.find((answer) => answer.answer_code === response.answer_code);
                        
                        if (answer) {
                            return (
                                <li key={response.id}>
                                    <DataLabel>{answer.answer_desc === "Others (please specify)"? response.others:
                                     answer.answer_desc}</DataLabel>
                                </li>
                            );
                            }
                    })}
              </ul>
            </GroupWrapper>

            {internshipData?.file_path? (
                <GroupWrapper>
                    <Questionlabel>{QuestField.additional_doc}</Questionlabel>
                    <IconSingleSection>
                 <img src={PDF} alt="PDF" />
                 <OtherText>
                   <AttachedDocument
                    //  onClick={() => downloadPdf(file.file_path)}
                     className="pointer-link"
                   >
                     <a style={{color:"#D04C8D"}}href={internshipData.file_path} target="_blank" rel="noreferrer">{getFileName(internshipData.file_path)}</a>
                   </AttachedDocument>
                 </OtherText>
               </IconSingleSection>
                </GroupWrapper>
            ): null}

            <GroupWrapper>
                <Questionlabel>{QuestField.other_allowance}</Questionlabel>
                <ul style={{ marginTop: "10px" }}>
                {responses
                    .filter((response) => response.question_code === 5)
                    .map((response) => {
                        const answer = answersList.find((answer) => answer.answer_code === response.answer_code);
                        
                        if (answer && response.answer_desc !== "Others (please specify)") {
                            return (
                                <li key={response.id}>
                                    <DataLabel>{answer.answer_desc === "Others (please specify)"? response.others:
                                     answer.answer_desc}</DataLabel>
                                </li>
                            );
                            }
                    })}
              </ul>
            </GroupWrapper>

            <GroupWrapper>
                <Questionlabel>{QuestField.declare_head}</Questionlabel>
                <ul>
                    <li>
                        <DataLabel>{QuestField.declaration}</DataLabel>
                    </li>
                </ul>
            </GroupWrapper>

          </NewPageWrapper>
    </MainLayout>
  )
}