import React, { useState, useEffect } from "react";

import styled from "styled-components";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector, useDispatch } from "react-redux";
import { Grid } from "@material-ui/core";

import { Colors } from "../../utilities/colors";
import CustomModal from "../modal/modal";
import Input from "../input/input";
import Button from "../button/button";
import { schema } from "./staticContent";
import ErrorField from "../errorField/errorField";
import {
  onUpdateCompanyDatas,
  getCompanyData,
  getCountriesMasterList,
  getSatesMasterList,
  getCitieMasterList,
} from "../../services/apiCalls";
import {  updateCurrentlySelectedCompanyData, updateLoader } from "../../pages/myCompany/reducer";
import Loader from "../loader/loader";
import Description from "../description/description";
import DatePicker from "@mui/lab/DatePicker";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import TextField from "@mui/material/TextField";
import Dropdown from "../dropdown/dropdown";
import RichTextEditorComponent from "../../utilities/rich-text-editor";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const ModalWrapper = styled.div`
  padding: 10px;
  min-width: 420px;
  > h3 {
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 3px;
    color: ${Colors.primaryText};
    margin: 0;
    margin-bottom: 10px;
  }
  @media (max-width: 500px) {
    padding: 0px;
    min-width: 15px;
  }
`;
const FormWrapper = styled.div`
  @media (max-width: 500px) {
    width: auto;
  }
`;
const SingleField = styled.div`
  margin-bottom: 20px;
  @media (max-width: 500px) {
    overflow-x: auto;
  }
`;

const FormLabel = styled.label`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: block;
  color: ${Colors.primaryText};
  margin-bottom: 10px;
`;
// const GridWrapper = styled.div`
//   display: grid;
//   grid-template-columns: repeat(2, 1fr);
//   grid-column-gap: 20px;
//   @media (max-width: 500px) {
//     grid-template-columns: repeat(1, 1fr);
//   }
// `;
const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

// const statuses = ["Primary", "Secondary"];

export default function UpdateCompany(props) {
  const onClose = props.onClose;
  //const company = useSelector((state) => state.company.companyData);
  const org = useSelector((state) => state.org.orgData);
  const companyOrgs = useSelector((state) => state.company.companyData);
  const company= props.companyDetails;
  const company_status = companyOrgs[props.companyIdx].org_status;
  const editLoading = useSelector((state) => state.company.loading);
  
  // const open = useSelector((state) => state.modal.open);
  const {t,} = useTranslation();
  const defaultVal = {
    overview: company && company.overview ? company.overview : "",
    website: company && company.website ? company.website : "",
    ssm_rgtr_date:
      company && company.ssm_rgtr_date ? company.ssm_rgtr_date : "",
    org_size: company && company.org_size ? company.org_size : "",
    bsn_city: company && company.bsn_city ? company.bsn_city : "",
    bsn_address: company && company.bsn_address ? company.bsn_address : "",
    bsn_state: company && company.bsn_state ? company.bsn_state : "",
    bsn_country: company && company.bsn_country ? company.bsn_country : "",
    founded: company && company.founded ? company.founded : "",
    bsn_nature: company && company.bsn_nature ? company.bsn_nature : "",
    country: company && company.country ? company.country : "",
    bsn_country_code:
      company && company.bsn_country_code ? company.bsn_country_code : "",
  };

  const [loading, setLoading] = useState(false);
  const [formErrors, setFormErrors] = useState("");
  const [value, setDateValue] = React.useState(null);
  // const [age, setAge] = React.useState(20);
  // const [options1, setOptions1] = useState({});

  const [countryList, setCountryList] = React.useState([]);
  const [selectedCountry, setSelectedCountry] = React.useState([]);

  const [stateList, setStateList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState([]);

  const [cityList, setCityList] = React.useState([]);
  const [selectedCity, setSelectedCity] = React.useState([]);

  const [stateDisabled, setStateDisabled] = useState(true);
  const [cityDisabled, setCityDisabled] = useState(true);

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    defaultValues: { ...defaultVal },
    resolver: yupResolver(schema),
  });

  const formVal = watch();
  const dispatch = useDispatch();

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await onUpdateCompanyDatas({
      code: companyOrgs[props.companyIdx].org_code,//org?.additional_info?.org_code,
      data: { ...formVal },
    });
    if (data && data.status === 1) {
      toast.success(t(`Details updated successfully.`), {
        position: toast.POSITION.TOP_RIGHT,
      });
      dispatch(updateLoader(!editLoading))
      getData();
      props.onClose();
      setFormErrors("");
    } else {
      setLoading(false);
      setFormErrors(data && data.message);
    }
  };

  const getData = async () => {
    const { data } = await getCompanyData({
      code: companyOrgs[props.companyIdx].org_code,//org?.additional_info?.org_code,
    });
    if (data && data.status === 1) {
      setLoading(false);
      //dispatch(updateCompanyData(data.data));
      //dispatch(updateCurrentlySelectedCompanyData(data.data));
    } else {
      setLoading(false);
    }
    setValue("bsn_country", defaultVal.bsn_country);
    setValue("bsn_state", defaultVal.bsn_state);
    setValue("bsn_city", defaultVal.bsn_city);
  };
  useEffect(() => {
    fetchCounty();
    getData();
    fetchSavedState();
    fetchSavedCity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  // const getOptions = async (name, type) => {
  //   const { data, status } = await onBoardGetData(name);
  //   if (data && data.status === 1) {
  //     let newData = data.data.map((x) => {
  //       return {
  //         label: x,
  //         value: x,
  //       };
  //     });
  //     let obj = { ...options1 };
  //     obj[type] = newData;
  //     setOptions1(obj);
  //   } else {
  //     return null;
  //   }
  // };

  const fetchCounty = async () => {
    const { data, status } = await getCountriesMasterList();
    if (status === 200) {
      if (data.status === 1) {
        const countryList = data.data;
        const country = countryList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCountryList(country);

        if (formVal.bsn_country) {
          let selCountry = country.filter(
            (x) => x.value === formVal.bsn_country
          );

          if (selCountry.length > 0) {
            selCountry = selCountry[0];
            setValue("bsn_country", selCountry.value);
          }
          setSelectedCountry(selCountry);
          setStateDisabled(false);
        }
      }
    }
  };

  const fetchState = async (CountryId) => {
    const { data, status } = await getSatesMasterList(CountryId);
    if (status === 200) {
      if (data.status === 1) {
        const StateList = data.data;
        const state = StateList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setStateList(state);
        if (formVal.bsn_state) {
          let selState = state.filter((x) => x.value === formVal.bsn_state);
          if (selState.length > 0) {
            selState = selState[0];
            setValue("bsn_state", selState.value);
          }
          setSelectedState(selState);
          setStateDisabled(false);
        }
      }
    }
  };
  const fetchSavedState = () => {
    if (formVal.bsn_country) {
      fetchState(formVal.bsn_country);
    }
  };

  const fetchCity = async (CountryId, StateId) => {
    const { data, status } = await getCitieMasterList(CountryId, StateId);
    if (status === 200) {
      if (data.status === 1) {
        const CityList = data.data;
        const city = CityList.map((x) => {
          return { ...x, label: x.Name, value: x.Id };
        });
        setCityList(city);
        if (formVal.bsn_city) {
          let selCity = city.filter((x) => x.value === formVal.bsn_city);
          if (selCity.length > 0) {
            selCity = selCity[0];
            setValue("bsn_city", selCity.value);
            setCityDisabled(false);
          }
          setSelectedCity(selCity);
        }
      }
    }
  };
  const fetchSavedCity = () => {
    if (formVal.bsn_country || formVal.bsn_state) {
      fetchCity(formVal.bsn_country, formVal.bsn_state);
    }
  };

  const dropdownChange = (name, val) => {
    setValue(name, val.value);
    switch (name) {
      case "bsn_country":
        setSelectedCountry(val);

        // dispatch(
        //   updateCompanyData({
        //     ...defaultVal,
        //     ...formVal,
        //     [name]: val.value,
        //   })
        // );
        setValue("bsn_state", "");
        setValue("bsn_city", "");
        fetchState(val.value);
        setSelectedCity([]);
        setCityList([]);
        setCityDisabled(true);
        setStateDisabled(false);
        break;
      case "bsn_state":
        setSelectedState(val);
        // dispatch(
        //   updateCompanyData({
        //     ...defaultVal,
        //     ...formVal,
        //     [name]: val.value,
        //   })
        // );
        setValue("bsn_city", "");
        fetchCity(formVal.bsn_country, val.value);
        setCityDisabled(false);
        break;
      case "bsn_city":
        setSelectedCity(val);
        // dispatch(
        //   updateCompanyData({
        //     ...defaultVal,
        //     ...formVal,
        //     [name]: val.value,
        //   })
        // );
        break;
      default:
        break;
    }
  };

  const formatDate = (date) => {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
  
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
  
    return [year, month, day].join("-");
  };

  const dateChange = (name, val) => {
    setValue(name, formatDate(val));
  };

  // const dropdownChange = (name, val, index) => {
  //   if (name === "bsn_country") {
  //     setValue("bsn_state", "");
  //     setValue("bsn_city", "");
  //   }
  //   if (name === "bsn_state") {
  //     setValue("bsn_city", "");
  //   }
  //   setValue(name, val.value);
  // };

  useEffect(() => {
    if (formVal?.bsn_country) setSelectedCountry(countryList.find((e) => e.value === +formVal?.bsn_country))
    const findState = stateList.find((e) => e.value === +formVal.bsn_state)
    if (formVal.bsn_state) setSelectedState(findState)
    if (formVal.bsn_state) {
      if (findState) {
        setCityDisabled(false)
      }
      setSelectedCity(cityList.find((e) => e.value === +formVal.bsn_city))
    }
  })

  return (
    <CustomModal open={props.open} onCloseModal={props.onClose}>
      <ModalWrapper>
        <Grid container>
          <Grid item lg={12}>
            <h3 style={{ width: "auto" }}>{t('Overview')}</h3>
            <hr />
            <FormWrapper>
              <RichTextEditorComponent
                value={formVal.overview}
                setValue={(val) => setValue("overview", val)}
              />
              {/* <SingleField>
                <FormLabel>About</FormLabel>
                <Controller
                  name="overview"
                  control={control}
                  render={({ field }) => (
      
                    <Description rows="5" {...field} placeholder="Overview" />
                  )}
                />
                {errors.overview && (
                  <ErrorField err={errors.overview.message} />
                )}
              </SingleField> */}

              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Website')}</FormLabel>
                    <Controller
                      name="website"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={t('Website')} />
                      )}
                    />
                    {errors.website && (
                      <ErrorField err={t(errors.website.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginLeft: "2%" }}>
                    <FormLabel>{t('SSM Registration Date')}</FormLabel>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DatePicker
                        value={value ?? defaultVal.ssm_rgtr_date}
                        disabled={company_status == 'pending'? false : true}
                        onChange={(newValue) => {
                          setDateValue(newValue);
                          dateChange("ssm_rgtr_date",newValue)
                        }}
                        renderInput={(params) => (
                          <TextField variant="standard" {...params} />
                        )}
                      />
                    </LocalizationProvider>

                    {errors.ssm_rgtr_date && (
                      <ErrorField err={t(errors.ssm_rgtr_date.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ marginLeft: "2%" }}>
                    <FormLabel>{t('Company Size')}</FormLabel>
                    <StatusWrapper>
                      <Controller
                        name="org_size"
                        control={control}
                        render={({ field }) => (
                          <Input {...field} type="text" placeholder={t('Size')} />
                        )}
                      />
                    </StatusWrapper>
                    {/* {errors.org_size && (
                      <ErrorField err={errors.org_size.message} />
                    )} */}
                  </SingleField>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={8}>
                  <SingleField style={{ marginRight: "2%" }}>
                    <FormLabel>{t('Address*')}</FormLabel>
                    <Controller
                      name="bsn_address"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder={t('Address')} />
                      )}
                    />
                    {errors.bsn_address && (
                      <ErrorField err={t(errors.bsn_address.message)} />
                    )}
                  </SingleField>
                </Grid>
                {/* <Grid item lg={4}>
                  <SingleField style={{ marginLeft: "2%" }}>
                    <FormLabel>City</FormLabel>
                    <FormControl fullWidth>
                      {/* <Controller
                      name="bsn_city"
                      control={control}
                      render={({ field }) => (
                        <Input {...field} placeholder="bsn_city" />
                      )}
                    /> */}
                {/* <Select
                        id="demo-simple-select"
                        value={age}
                        label="City"
                        variant="standard"
                        onChange={handleChange}
                      >
                        <MenuItem value={10}>Ten</MenuItem>
                        <MenuItem value={20}>Twenty</MenuItem>
                        <MenuItem value={30}>Thirty</MenuItem>
                      </Select>
                    </FormControl>
                    {errors.bsn_city && (
                      <ErrorField err={errors.bsn_city.message} />
                    )}
                  </SingleField>
                </Grid> */}

                <Grid item lg={4}>
                  <SingleField style={{ marginLeft: "2%" }}>
                    <FormLabel>{t('Postcode*')}</FormLabel>
                    <Controller
                      name="bsn_country_code"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          onKeyPress={(event) => {
                            if (!/[a-zA-Z0-9]/.test(event.key)) {
                              event.preventDefault();
                            }
                            if (event.target.value.length > 9) {
                              event.preventDefault();
                            }
                          }}
                          placeholder={t('Postcode')}
                          maxLength="10"
                        />
                      )}
                    />
                    {errors.bsn_country_code && (
                      <ErrorField err={t(errors.bsn_country_code.message)} />
                    )}
                  </SingleField>
                </Grid>
              </Grid>

              <Grid container>
                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('Country*')}</FormLabel>
                    <Controller
                      name="bsn_country"
                      control={control}
                      render={({ field }) => (
                  
                        <Dropdown
                          search={true}
                          options={countryList}
                          placeholder={t('Enter country')}
                          val={selectedCountry?.value}
                          label={selectedCountry?.label}
                          change={(val) => dropdownChange("bsn_country", val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.bsn_country && (
                      <ErrorField err={t(errors.bsn_country.message)} />
                    )}
                  </SingleField>
                </Grid>
                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('State*')}</FormLabel>
                    <Controller
                      name="bsn_state"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={stateList}
                          disable={stateDisabled}
                          placeholder={t('Enter Location')}
                          minWidth="auto"
                          val={selectedState?.value}
                          label={selectedState?.label}
                          change={(val) => dropdownChange("bsn_state", val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.bsn_state && (
                      <ErrorField err={t(errors.bsn_state.message)} />
                    )}
                  </SingleField>
                </Grid>

                <Grid item lg={4}>
                  <SingleField style={{ margin: "1%" }}>
                    <FormLabel>{t('City*')}</FormLabel>
                    <Controller
                      name="bsn_city"
                      control={control}
                      render={({ field }) => (
                        <Dropdown
                          search={true}
                          options={cityList}
                          disable={cityDisabled}
                          placeholder={t('Enter Location')}
                          minWidth="auto"
                          val={selectedCity?.value}
                          label={selectedCity?.label}
                          change={(val) => dropdownChange("bsn_city", val)}
                          {...field}
                        />
                      )}
                    />
                    {errors.bsn_city && (
                      <ErrorField err={t(errors.bsn_city.message)} />
                    )}
                  </SingleField>
                </Grid>
              </Grid>

              <SingleField>
                <FormLabel>{t('Nature of Business*')}</FormLabel>
                <Controller
                  name={t("bsn_nature")}
                  control={control}
                  render={({ field }) => (
                    <Description rows="5" {...field} placeholder={t('bsn_nature')} />
                  )}
                />
                {errors.bsn_nature && (
                  <ErrorField err={t(errors.bsn_nature.message)} />
                )}
              </SingleField>
              <Grid
                container
              
                className="button-company"
              >
                
                {formErrors && <ErrorField err={t(formErrors)} />}
                {loading ? (
                  <Loader />
                ) : (
                  <Button name={t('Confirm')} onClick={handleSubmit(onSubmit)} />
                )}
                <Button
                  secondary
                  color="#FFFFFF"
                  name={t('Cancel')}
                  style={{
                    marginLeft: "10px",
                  }}
                  variant="outlined"
                  onClick={onClose}
                ></Button>
              </Grid>
            </FormWrapper>
          </Grid>
        </Grid>
      </ModalWrapper>
    </CustomModal>
  );
}
