import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  subMenuState: {},
};

export const SideBarDataReducer = createSlice({
  name: "sideBar",
  initialState,
  reducers: {
    updateSubMenu: (state, action) => {
      console.log("ppppload",action.payload)
      state.subMenuState = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateSubMenu, } =
  SideBarDataReducer.actions;

export default SideBarDataReducer.reducer;
