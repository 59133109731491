import React from 'react'
import MainLayout from "../../layouts/mainLayout/mainLayout";
import styled from "styled-components";
import { Colors } from "../../utilities/colors";
import TalentSearch from "../../assets/Saly-14.png";
import Employee from "../../assets/3d illustration pack 4.png";
import Button from "../../components/button/button";
import Pagination from "@mui/material/Pagination";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from 'react'
import { getCompanySubscription } from "../../services/apiCalls";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../../components/loader/loader";
import { updateSubscriptionData } from "./reducer";
import { t } from 'i18next';

const HeaderText = styled.h2`
  margin-top:2%;
  margin-bottom:1%;
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: #000000;
`;

const CardsWrapper = styled.div`
  background: ${Colors.light};
  // width: 965px;
  min-height:500px;
  max-height: 638px;
  border-radius:32px;
  padding-bottom:1%;  
  margin-top:28px;
  `;
const Header = styled.div`
  font-family: 'General Sans';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #D04C8D;
  padding:4% 0px 0px 5%;
  margin-bottom:4px;
`
const HrLine = styled.hr`
border: 0.6px solid #C4C4C4;
transform: rotate(90deg);
width:278px;
position:relative;
margin-top:22%;
margin-right:5%;
`;

const SubscribeWrapper = styled.div`
    display:flex;
    flex-direction:row;
    justify-content:space-around;
    margin:auto;
    width:80%;
    // height:360px;
    // padding:24px 0;
    @media (max-width: 500px) {
      width:100%;
    }
`;

const SubscribeCards = styled.div`
    display:flex;
    flex-direction:column;
  
`;

const LargeText = styled.p`
   font-size: 16px;
   line-height: 22px;
   font-weight: 500;
   text-align: center;
`;
const SmallText = styled.p`
    font-size: 14px;
    line-height: 19px;
    font-weight: 400;
    text-align: center;
`;
const ImageWrapper = styled.div`
   display:flex;
   height:280px;
   margin:auto;
`;
const Img = styled.img`
   height:${(props) => props.height};
   width:${(props) => props.width};
`;

const ButtonWrapper = styled.div`
  display:flex;
  width:100%;
  flex-direction:row;
  justify-content:space-around;
`;
const Subscriptions = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);
  const subscribedData = useSelector((state) => state.subscription.subscriptionData);
  const onNavigate = () => {
    navigate("/subscriptions2");
  };

  useEffect(() => {
    if (org_code && !(subscribedData[org_code] && Object.keys(subscribedData[org_code]).length)) { }
  })

  const getSubscription = async () => {
    const { data } = await getCompanySubscription(org_code);
    if (data && data.status === 1 && data.data.length) {
      const filters = data?.data?.filter((e)=>e.paymentstatus === "success")
      if(filters.length>0){
        navigate("/subscriptions/subscriptions-renew");
      }
      
    }
  };

  useEffect(()=>{
    getSubscription()
  },[org_code])

  return (<>

    <CardsWrapper>

      <Header>
        {t("Subscribe to Premium Features")}
      </Header>

      <div style={{ paddingLeft: "5%" }}>{t("This is an annual subscription. This will give you access to these features:")}
      </div>

      <SubscribeWrapper >
        <SubscribeCards>
          <ImageWrapper>
            <Img src={TalentSearch} alt="img" style={{ margin: "0 auto" }} height="271px" width="168px" />
          </ImageWrapper>
          <LargeText style={{ marginTop: "-64px" }}>{t("Talent Search")}</LargeText>
          <SmallText style={{ marginTop: "-12px" }}>{t("Look for graduating talent")}</SmallText>
        </SubscribeCards>
        {/* <HrLine/> */}
        <div style={{ borderLeft: "2px solid #C4C4C4", height: "280px", marginTop: "60px" }}></div>

        <SubscribeCards style={{ marginTop: "36px" }}>
          <ImageWrapper>
            <Img src={Employee} alt="img" style={{ margin: "0px auto 0 auto" }} height="224px" width="173px" />
          </ImageWrapper>
          <LargeText style={{ marginTop: "-64px" }}>{t("Employee")}</LargeText>
          <SmallText style={{ marginTop: "-12px" }} dangerouslySetInnerHTML={{ __html: t("Get your employee to perform <br /> psychometric assessment  <br />and see the results") }}/>

        </SubscribeCards>
      </SubscribeWrapper>
    </CardsWrapper></>
  )
}

export default Subscriptions
