import React, { useState } from "react";
import styled from "styled-components";
import Button from "../button/button";
import { useSnapshot } from "valtio";
import { trackProxy } from "../../pages/trackApplications/trackProxy";
import { changApplications } from "../../services/apiCalls";
import TextArea from "../description/description";
import { useTranslation } from "react-i18next";

const CardsWrapper = styled.div`
  position: absolute;
  width: 645px;
  height: 447px;
  left: 33%;
  top: 157px;
  background: #ffffff;
  border-radius: 10px;
  margin-top: -70px;

  @media (max-width: 500px) {
    width: 335px;
    height: 575px;
    position: relative;
    left: 9%;
    padding-top: 10px;
  }
  @media (min-width:600px) and (max-width: 850px) {
    // width: 335px;
    height: 575px;
    position: relative;
    left: 9%;
    padding-top: 10px;
  }
   @media (min-width:851px) and (max-width: 1200px) {
    left:20%;
   }
`;
const CardWrapper = styled.div`
  background: rgba(83, 80, 141, 0.1);
  border-radius: 24px 24px 0px 0px;
  height: 136px;
  margin: 10px;
  display: flex;
`;
const Icon = styled.div`
  position: absolute;
  top: 10px;
  right: 20px;
  // @media (max-width: 500px) {
  //   right: 120px;
  // }
`;
const Title = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: 1px;
  text-transform: uppercase;
  color: #d04c8d;
  padding: 30px 50px;
  @media (min-width:375px) and (max-width:500px){
    position: relative;
    margin-left: -30px;
  }
`;
const Content = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding: 50px 0px;
  margin: 10px 0 0 -120px;
`;
const SubContent = styled.div`
  font-family: "General Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  color: #000000;
  text-align: center;
  margin: 40px 0 0 0;
`;

const Wrapper2 = styled.div`
  position: relative;
  width: 501px;
  height: 128px;
  left: 72px;
  top: 60px;
  @media (min-width: 375px) and (max-width: 500px) {
    position: relative;
    width: 80%;
    height: 128px;
    left: 36px;
    top: 60px;
  }
`;
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
  Button {
    margin-right: 10px;
  }
  Button:nth-child(2) {
    background: white;
    border: 1px solid #d04c8d;
    color: #d04c8d;
    position: relative;
  }
  @media (min-width: 375px) and (max-width: 500px) {
    Button {
      margin-right: 10px;
      position: relative;
      left:25%;
    }
    Button:nth-child(2) {
      background: white;
      border: 1px solid #d04c8d;
      color: #d04c8d;
      position: relative;
      left: -25%;
      top: 78px;
    }
  }
`;
const RejectModal = ({ open, onClose, id }) => {
  useSnapshot(trackProxy)
  const [reson, setReson] = useState("")
  const [dis,setDis] = useState(true)
  const { t, i18n } = useTranslation();

  const submit = async ()=>{

    let l = {
      rejected_reason:reson
    }
    const {data} = await changApplications("reject",id,l)
    setReson(data?.data?.reson["rejected_reason"])
  close()
  }
  const close = ()=>{
    if(trackProxy.setcheck === 0){
      trackProxy.setcheck=1
    }else{
      trackProxy.setcheck=0
    }
    onClose()
  }

  return (
    <div className="overlay">
      <CardsWrapper>
        <CardWrapper id="headercard">
          <Title>{t("Reject")}</Title>
          <Content>{t("Are you sure about that?")} </Content>
          <Icon onClick={close}  style={{cursor: "pointer"}}>
            X
          </Icon>
        </CardWrapper>
        <SubContent>{t("Why is candidate being rejected?")}</SubContent>

        <Wrapper2>
          <TextArea
            name="rejected_reason"
            placeholder={t("Please Enter Reason for Reject")}
            minWidth="auto"
            width="200px"
            value={reson}
            onChange={(e)=>{
              setReson(e.target.value)
              setDis(false)
              
            }}
          />
          
        </Wrapper2>
        <ButtonWrapper>
          <Button cursor="pointer" onClick={submit} name={t("Yes")} disabled={dis}></Button>
          <Button secondary cursor="pointer" name={t("No")} onClick={close}></Button>
        </ButtonWrapper>
      </CardsWrapper>
    </div>
  );
};

export default RejectModal;
