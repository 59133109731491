import styled from "styled-components";


export const CancelAndExit = styled.button`
  border: 0px;
  background-color: transparent;
  font-family: General Sans;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  text-decoration: none;
  color: #504f8c;
  width: 192px;
  border-radius: 128px;
  outline: none;
  border: 0px;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
  padding: 13px 20px;
  margin: 15px 0;
  cursor: pointer;
  border-radius: 128px;
  border: 1px solid #d04c8d;
  color: #d04c8d;
`;



export const SubHeading = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 0.4px solid #202020;
  .changeText {
    font-family: General Sans;
    font-weight: 500;
    font-size: 14px;
    color: #d04c8d;
    cursor: pointer;
  }
`;

export const SubText = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
  color: #202020;
`;

export const SubHeadingText = styled(SubText)`
  font-size: 18px;
  line-height: 24px;
  color: #202020;
`;


export const Label = styled.div`
  font-family: General Sans;
  font-weight: 500;
  font-size: 16px;
  color: #979797;
  // margin-bottom: 0.625rem;
`;

export const LabelHeading = styled(SubHeadingText)`
  margin-top: 10px;
  font-size: 16px;
  color: #000000;
  margin-bottom: 12px;
`;

export const ContentText = styled(SubText)`
  font-size: 14px;
  line-height: 17px;
  color: #878787;
`;

export const SelectorWrapper = styled.div`
  width: 100%;
`;


export const StyledSimpleCardCustom = styled.p`
align-items: center;
  // background-color: #f1f3f4;
  // border-radius: 100px;
  box-sizing: border-box;
  display: inline-flex;
  max-height: none;
  min-height: 48px;
  // padding-left: 17px;
  // padding-right: 17px;
  position: relative;
  font-family: General Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: #525252;
  color:black;
  margin: 0px 10px 20px 0px;

  svg {
    margin-right: 10px;
  }
`;