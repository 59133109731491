import { Outlet } from "react-router-dom";
import AnalyticalCardVarientFour from "../analyticalCardVarientFour/analyticalCardVarientFour";
import AnalyticalDashboard from "../analyticalDashboard";
import AnalyticalPeronalityDrillDown from "../../../components/analyticalDashboardPage/adPersonality/analyticalPersonalitydrilldown"
import Ad2CreatingValueDrilldown from "../../../components/analyticalDashboardPage/adWorkValues/adCreatingValueDrilldown";
import Ad2FellowshipDrilldown from "../../../components/analyticalDashboardPage/adWorkValues/adFellowshipDrilldown";
import Ad2MasteryDrilldown from "../../../components/analyticalDashboardPage/adWorkValues/adMasteryDrilldown";
import Ad2QualityofLifeDrilldown from "../../../components/analyticalDashboardPage/adWorkValues/adQualityOfLifeDrilldown";
import Ad2WorkValuesDrilldown from "../../../components/analyticalDashboardPage/adWorkValues/adWorkValuesDrillDown";
import Ad2WorkInterestDrilldown from "../../../components/analyticalDashboardPage/adWorkInterest/adWorkInterestDrillDown";
import Ad2EmployabilityDrilldown from "../../../components/analyticalDashboardPage/adEmployability/adEmployability";
import Ad2IdeasAndOpportunities from "../../../components/analyticalDashboardPage/adEmployability/adIdeasAndOpportunities";
import Ad2IntoActionDrilldown from "../../../components/analyticalDashboardPage/adEmployability/adIntoActionDrilldown";
import Ad2ResourcesDrilldown from "../../../components/analyticalDashboardPage/adEmployability/adResourcesDrilldown";
import Ad2CognitiveDrilldown from "../../../components/analyticalDashboardPage/adFutureOfWork/adCognitiveDrilldown";
import Ad2DigitalDrilldown from "../../../components/analyticalDashboardPage/adFutureOfWork/AdDigitalDrilldown";
import Ad2FutureOfWorkDrilldown from "../../../components/analyticalDashboardPage/adFutureOfWork/adFutureOfWorkDrillDown";
import Ad2InterpersonalDrilldown from "../../../components/analyticalDashboardPage/adFutureOfWork/AdInterpersonalDrilldown";
import Ad2SelfLeadershipDrilldown from "../../../components/analyticalDashboardPage/adFutureOfWork/AdSelfLeadershipDrilldown";
import Ad2EnglishProficiencyDrilldown from "../../../components/analyticalDashboardPage/adEnglishProficiency/adEnglishProficiencyDrillDown";
import AnalyticalDashboardEnhanced from "../../analyticalDahsboardEnhanced/analyticalDashboardEnhanced";

const coachingDashboardRoutesGenerator = (element) => ([
  {
    path: 'student',
    element: <Outlet />,
    children: [
    //   {
    //     path: ':id',
    //     element: <CoachingStudentProfile backButtonEnable={false} />,
    //   },
    //   {
    //     path: ':id/counselling',
    //     element: <CoachingStudentCounselling />,
    //   },
    ]
  },
  {
    path: '',
    element: element,
  },
]);

const coachingDashboardRoutes = [
  {
    path: 'student',
    element: <Outlet />,
    // children: [
    //   {
    //     path: ':id',
    //     element: <CoachingStudentProfile backButtonEnable={false}/>,
    //   },
    //   {
    //     path: ':id/counselling',
    //     element: <CoachingStudentCounselling />,
    //   },
    // ]
  },
  {
    path: '',
    element: <AnalyticalCardVarientFour />,
  },
]; 


export const analyticalDashboardPageRoute = [
  {
    path: "/",
    element: <Outlet />,
    children: [
        {
            path: "employee-registered",
            element: <Outlet />, children: coachingDashboardRoutes,
          },
          {
            path: "employee-not-registered",
            element: <Outlet />, children: coachingDashboardRoutes,
          },
          {
            path: "personality-and-motivation",
            element: <Outlet />,
            children: [
              {
                path: "emotional-stability",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "extraversion",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "openness-to-experience",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "agreeableness",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "conscientiousness",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "",
                element: <Outlet />,
                children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                  <AnalyticalPeronalityDrillDown />
                </AnalyticalCardVarientFour>),
              },
             
            ],
          },
          {
            path: "work-interest",
            element: <Outlet />,
            children: coachingDashboardRoutesGenerator(<Ad2WorkInterestDrilldown />),
          },
          {
            path: "work-values",
            element: <Outlet />,
            children: [
              {
                path: "mastery",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "mastery-drilldown",
                element: <Outlet />,
                children: [
                  {
                    path: "intellectual-stimulation",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "recognition",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "achievement",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "independence",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2MasteryDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "quality-of-life",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "quality-of-life-drilldown",
                element: <Outlet />,
                children: [
                  {
                    path: "variety",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "security",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "way-of-life",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "surroundings",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "economic-return",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: '',
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2QualityofLifeDrilldown />
                    </AnalyticalCardVarientFour>),
                  }
                ],
              },
              {
                path: "fellowship",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "fellowship-drilldown",
                element: <Outlet />,
                children: [
                  {
                    path: "altruism",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "supervisory-relationship",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "associates",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "belonging",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "family",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: '',
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2FellowshipDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "creating-value",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: "creating-value-drilldown",
                element: <Outlet />,
                children: [
                  {
                    path: "aesthetic",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "creativity",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "leadership",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "protecting-the-planet",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: '',
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2CreatingValueDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "",
                element: <Outlet />,
                children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                  <Ad2WorkValuesDrilldown />
                </AnalyticalCardVarientFour>),
              },
            ],
          },
          {
            path: "employability",
            element: <Outlet />,
            children: [
              {
                path: "ideas-and-opportunities",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'ideas-and-opportunities-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "spotting-opportunities",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "creativity",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "valuing-ideas",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "vision",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "ethical-and-sustainable-thinking",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2IdeasAndOpportunities />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "resources",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'resources-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "self-awareness",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "self-efficacy",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "financial-and-economic-literacy",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "mobilising-others",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "mobilising-resources",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2ResourcesDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "into-action",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'into-action-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "motivation-and-perseverance",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "working-with-others",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "planning-and-management",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "learning-through-experience",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "coping-with-uncertainty-ambiguity-and-risk",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "taking-the-initiative",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2IntoActionDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: '',
                element: <Outlet />,
                children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                  <Ad2EmployabilityDrilldown />
                </AnalyticalCardVarientFour>),
              },
            ]
          },
          {
            path: "future-of-work",
            element: <Outlet />,
            children: [
              {
                path: "cognitive",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'cognitive-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "critical-thinking",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "planning-and-ways-of-working",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "communication",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "mental-flexibility",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2CognitiveDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "interpersonal",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'interpersonal-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "mobilising-systems",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "developing-relationships",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "effective-teamwork",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "coaching-others",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2InterpersonalDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "self-leadership",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'self-leadership-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "self-awareness",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "self-management",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "entrepreneurship",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "goal-achievement",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2SelfLeadershipDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: "digital",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'digital-drilldown',
                element: <Outlet />,
                children: [
                  {
                    path: "digital-fluency",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "software-use",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "software-development",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "understanding-digital-systems",
                    element: <Outlet />, children: coachingDashboardRoutes,
                  },
                  {
                    path: "",
                    element: <Outlet />,
                    children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                      <Ad2DigitalDrilldown />
                    </AnalyticalCardVarientFour>),
                  },
                ],
              },
              {
                path: '',
                element: <Outlet />,
                children: coachingDashboardRoutesGenerator(<AnalyticalCardVarientFour>
                  <Ad2FutureOfWorkDrilldown />
                </AnalyticalCardVarientFour>),
              },
            ]
          },
          {
            path: "english-proficiency",
            element: <Outlet />,
            children: [
              {
                path: "english-proficiency-drilldown",
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: '',
                element: <Outlet />,
                children: coachingDashboardRoutesGenerator(<Ad2EnglishProficiencyDrilldown />),
              },
              {
                path: 'grammar',
                element: <Outlet />,
                element: <Outlet />, children: coachingDashboardRoutes,
              },
              {
                path: 'comprehension',
                element: <Outlet />,
                element: <Outlet />, children: coachingDashboardRoutes,
              },
            ]
          },
          {
            path: "english-proficiency-drilldown",
            element: <Outlet />, children: coachingDashboardRoutes,
          },
          {
            path: 'grammar',
            element: <Outlet />,
            element: <Outlet />, children: coachingDashboardRoutes,
          },
          {
            path: 'comprehension',
            element: <Outlet />,
            element: <Outlet />, children: coachingDashboardRoutes,
          },
      {
        path: "/",
        element: <AnalyticalDashboardEnhanced />,
      },
    ],
  },
];
