import { createSlice } from "@reduxjs/toolkit";

import { newPersonData } from "../../utilities/constants";

const initialState = {
  value: newPersonData,
};

export const newPersonReducer = createSlice({
  name: "newPerson",
  initialState,
  reducers: {
    updatePersonValues: (state, action) => {
      state.value = action.payload;
    }
  },
});

// Action creators are generated for each case reducer function
export const { updatePersonValues } = newPersonReducer.actions;

export default newPersonReducer.reducer;
