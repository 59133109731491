import * as yup from "yup";


export const skillGapStaticContent = {

  pageDesciption: "Complete your endorsement for National Structured Internship Programme (MySIP) , by filling in the internship module.",
  internList: ["intern1","interns2","intern3","interns4"],
  question3: "Most Important Technical Skills",
  skillRatingLiat: ["Strongly Disagree ","Disagree","Somewhat Disagree","Somewhat Agree","Agree","Strongly Agree"],
  question3Option: [
    "Accounting software",
    "Air, space, or watercraft guidance systems",
    "Application and load testing software",
    "Application server software",
    "Audio/video conferencing software",
    "Automated vehicle guidance",
    "Aviation and marine communication systems",
    "Barcode reading technologies",
    "Bookings and reservations software",
    "Business intelligence and decision support software",
    "Computer aided manufacturing (CAM)",
    "Configuration management software",
    "others"
  ],
  question4: "6. Does this intern have the technical skill as per required ?",
  question5: "7. On a scale of 1 to 6, does the intern meet expectations for the level of proficiency required in the above mentioned technical skills",
  // question5: "If yes, please choose the technical skill refering to this intern",
  question12: "13. Please suggest suitable upskilling training",
  question13: "14. Do you think an upskilling training would be beneficial?",
  option6Option: [
    "scale 1",
    "scale 2",
    "scale 3",
    "scale 4",
    "scale 5",
    "scale 6",
  ],
  question6: "Most Important Soft Skills",
  question6Option: [
    "Embracing & Managing",
    "Connecting & Portraying ",
    "Supervising & Determining",
    "Ambitious &  Result-Oriented",
    "Innovating & Envisioning",
    "Facilitating & Collaborating",
    "Coordinating & Implementing"
  ],
  question7: "8. On a scale of 1 to 6, does the intern meet expectations for the level of proficiency required in the above mentioned soft skills",
  // question7: "If yes, please choose the softskill skill refering to this intern",
  option7Option: [
    "scale 1",
    "scale 2",
    "scale 3",
    "scale 4",
    "scale 5",
    "scale 6",
  ],
  question8: "9. Does this intern have the soft skills as per required",
  question9: "10. Please provide types of work duties or task done during internships",
  question10: "11. Supervisor comment ",
  question11: "12. Will you offer him/her a job placement ?",
  workDutiesOptions: [
    "Analytical/Problem Solving",
    "Project Management",
    "Communication",
    "Logistics",
    "Administration",
    "Presentation",
    "Special Project",
    "Non-essential functions",
    "Others (please specify)"
  ]
} 
export const skillGapSchema = yup.object({
  internship_position: yup.string().required(""),
  supervisor_comment: yup.string().required(""),
  does_the_intern_have_technical_skill_as_per_required: yup.number().required(""),
  technical_skill_upskilling: yup.number().when('does_the_intern_have_technical_skill_as_per_required', {
    is: 0,
    then: yup.number().required(''),
  }),
  technical_skill_upskilling_suggestion: yup.string().when('does_the_intern_have_technical_skill_as_per_required', {
    is: 0,
    then: yup.string().required(''),
  }),
  soft_skill_upskilling: yup.number().when('does_the_intern_have_soft_skill_as_per_required', {
    is: 0,
    then: yup.number().required(''),
  }),
  soft_skill_upskilling_suggestion: yup.string().when('does_the_intern_have_soft_skill_as_per_required', {
    is: 0,
    then: yup.string().required(''),
  }),
  technical_skills_rating: yup.number().required(""),
  does_the_intern_have_soft_skill_as_per_required: yup.number().required(""),
  will_you_offer_a_job_placement: yup.number().required(""),
  soft_skill_rating: yup.number().required(""), 
  work_duties: yup.array().min(1),


})

