import React from 'react'
import { BoxWrapper, NormalText, SpanText, SubHeading, ContentText } from './common'

export default function EducationDetails() {
    return (
        <BoxWrapper>
            <SubHeading style={{ fontSize: "18px" }}>Educational Details</SubHeading>
            <hr />
            <div style={{marginBottom:"36px"}}>
                <NormalText>
                    MBA
                </NormalText>
                <SubHeading style={{ marginBottom: "0" }}>
                    Indian Institute of Technology, Raipur
                </SubHeading>
                <SpanText style={{ fontStyle: "italic", fontSize: "16px", color: "#525252", marginBottom: "12px" }}>Completion year 2016</SpanText>
                <ContentText>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex.
                </ContentText>
            </div>
            <div style={{marginBottom:"36px"}}>
                <NormalText>
                    MBA
                </NormalText>
                <SubHeading style={{ marginBottom: "0" }}>
                    Indian Institute of Technology, Raipur
                </SubHeading>
                <SpanText style={{ fontStyle: "italic", fontSize: "16px", color: "#525252", marginBottom: "12px" }}>Completion year 2016</SpanText>
                <ContentText>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                    nostrud exercitation ullamco laboris nisi ut aliquip ex.
                </ContentText>
            </div>
        </BoxWrapper>
    )
}
