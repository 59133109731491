import styled from "styled-components";
import { useTranslation } from "react-i18next";

import DeleteIcon from "../../../assets/icons/activeDelete.png";
import { Colors } from "../../../utilities/colors";
import EditIcon from "../../../assets/svg/pencil.svg";
const SectionDivisionWrapper = styled.div`
  padding: 1rem 1.5rem 1rem 1.5rem;
  background: #fdfdfd;
  border: 1px solid rgba(228, 228, 228, 0.26);
  box-sizing: border-box;
  border-radius: 5px;
  margin: 30px 0;
  overflow:auto;
  @media (max-width: 767px) {
    padding: 0.5rem;
  }
  
`;
const TableTop = styled.table`
  overflow-x: scroll;
  width:100%;
`;
const TableHead = styled.th`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: ${Colors.labelText};
  padding: 15px 10px;
`;
const TableData = styled.td`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 15px 10px;
  border-top:1px solid #ddd;
  color: #525252;
  text-transform: capitalize;
`;
const Icon = styled.img`
  margin: 0px 5px;
`;
const EditIconW = styled.img`
color:rgb(208, 76, 141);
margin: 0px 5px;
`;

export default function LocationTable(props) {
  const { editLocation, deleteLocation } = props;
  const { t } = useTranslation();

  return (
    <SectionDivisionWrapper>
      <TableTop>
        <thead>
          <tr>
            <TableHead>{t("Country")}</TableHead>
            <TableHead>{t("State")}</TableHead>
            <TableHead>{t("City")}</TableHead>
            <TableHead></TableHead>
            <TableHead></TableHead>
          </tr>
        </thead>
        <tbody>
          {props.data.map((x, index) => (
            <tr key={index}>
              <TableData>{x.country_abbr}</TableData>
              <TableData>{x.state_abbr}</TableData>
              <TableData>{x.city_abbr}</TableData>
              <TableData>
                <EditIconW onClick={() => editLocation(x.id)} src={EditIcon}  color={Colors.greyText} />
              </TableData>
              <TableData>
                <Icon onClick={() => deleteLocation(x.id)} src={DeleteIcon} />
              </TableData>
            </tr>
          ))}
        </tbody>
      </TableTop>
    </SectionDivisionWrapper>
  );
}
