import React from "react";
import { Col, Row } from "react-bootstrap";
import { Ad2CellCard, CardButtonVariantOne, CardHeader, CardHeaderTitle, CardHeaderTitleVariantOne, CardHeaderVariantOne, LeftWrapper, RightWrapper } from "../styles/analyticalStyles";

// import DoughnutChart from "../../../../components/doughnutChart/doughnutChart"; 

import { ReactComponent as Info } from "../../../assets/svg/info.svg";
import { Link, useNavigate } from "react-router-dom";
// import { Utils } from "../../../../utilities/utils";ls
import { useTranslation } from "react-i18next";
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import NoDataChartContainer from "../../NoDataFoundChart/NoDataFoundChart";
import ChartLoader from "../../../components/analyticalDashboardPage/chartLoader";
import { Utils } from "../../../components/analyticalDashboardPage/utilities";
import DoughnutChart from "../../../components/analyticalDashboardPage/charts/doughnutChart";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor:"#0C1A25",
    fontSize: 12,
    padding: 10,
    weight: 500,
    style: "normal",
    family: "Oswald",
  },
}));

export function AnalyticalCardVarientThree(props) {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    const handleNavigate = Utils.handleNavigate.bind(this, navigate);
    const commonProperties = {
        cutout: '40%',
        circumference: -180,
        rotation: -90,
        layout: {
            padding: {
                left: 0,
                right: 0,
                top: 0,
                bottom: 0,
            },
        },
        elements: {
            arc: {
                borderWidth: 1
            }
        },
        plugins: {
            customLabels: {
                display: false,
            },
            datalabels: {
                display: false,
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: true,
                yAlign: 'bottom',
                xAlign: 'center',
                displayColors: false,
                padding: 10,
                callbacks: {
                    label: (context) => {
                        return context.formattedValue;
                    },
                    title: (context) => {
                        return '';
                    }
                }
            },
            legend: {
                display: true,
                labels: {
                    padding: 10,
                    usePointStyle: true,
                    boxWidth: 15
                }
            },
        },
    };
    const onClick = (url) => {
        if (url) {
            handleNavigate(`${url}-drilldown`);
        }
    }
    return <>
        <Ad2CellCard>
            <CardHeaderVariantOne>
                <Row className="pb-2">
                    <Col className="d-flex align-items-center justify-content-between">
                        <LeftWrapper>
                            <CardHeaderTitleVariantOne>{props.title}</CardHeaderTitleVariantOne>
                            <StyledTooltip arrow placement="top" title={<div dangerouslySetInnerHTML={{ __html: props.info}}/>}><Info /></StyledTooltip>
                            {props.leftContent ? props.leftContent : ''}
                        </LeftWrapper>
                        <RightWrapper>
                            {props.rightContent ? props.rightContent : ''}
                            {props.showDrilldown && <span onClick={onClick.bind(this, props.url)}><CardButtonVariantOne className="btn btn-asdf">{t("View Analysis")}</CardButtonVariantOne></span>}
                        </RightWrapper>
                    </Col>
                </Row>
            </CardHeaderVariantOne>
            <Row className="slide-wrapper">
                {props.status !== 'loading' ? props.status === 'noDataFound' ? <NoDataChartContainer /> :
                    <>
                        <DoughnutChart
                            customStyles={{ maxHeight: '270px' }}
                            data={{
                                labels: [t('Low'), t('Moderate'), t('High')],
                                datasets: [{
                                    data: [
                                        props.low,
                                        props.moderate,
                                        props.high,
                                    ],
                                    backgroundColor: [
                                        '#004B84',
                                        '#628FB1',
                                        '#C4D3DE',
                                    ]
                                }],
                            }} optionsOverride={commonProperties}
                            onClick={props.onClick} />
                    </> : <ChartLoader />}
            </Row>
        </Ad2CellCard>
    </>;
}