import { useState, useEffect } from "react";
import "./company.css";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { Colors } from "../../utilities/colors";
import MainLayout from "../../layouts/mainLayout/mainLayout";
import Overview from "../../components/overview/overview";
import CompanyContactPerson from "../../components/companyContactPerson/companyContactPerson";
import Location from "../../components/addLocation/addLocation";
import {
  getCompanyData,
  getOrgData,
  getCompanies,
  getUserData,
  userCompanyInvite,
} from "../../services/apiCalls";
import Loader from "../../components/loader/loader";
import { updateOrgData } from "../../pages/login/reducer";
import { updateUserData } from "../../pages/login/userReducer";
import { updateCompanyData, updateLoader } from "./reducer";
import MyCompanyCard from "../../components/myCompanyCard/myCompanyCard";
import CommentsSection from "../../components/myCompanyCard/commentsSection";
import { useNavigate } from "react-router-dom";
import Menus from "../../components/hamburger/menus";
import HamburgerLay from "../../components/hamburger/hamburger";
import AddNewCompany from "../../components/addNewCompany/addNewCompany";
import Input from "../../components/input/input";
import Button from "../../components/button/button";
import CompaniesCard from "../../components/companiesCard/companiesCard";
import { toast } from "react-toastify";
import Union from "../../assets/Union.png";
import { makeStyles } from "@mui/styles";
import UnAuthPopup from "../userManagement/unAuthPopup";
import InfiniteScroll from "react-infinite-scroll-component";

import { ReactComponent as RightIcon } from "../../assets/svg/check_icon.svg";
import { ReactComponent as CrossIcon } from "../../assets/svg/cross_icon.svg";
import { ReactComponent as PencilIcon } from "../../assets/svg/pencil.svg";
import { Col, Container, Row } from "react-bootstrap";
import DataTable, { createTheme } from "react-data-table-component";
import { onApiCall } from "../../services/CommonApi";
import { getAllUsers, deleteCompanyUser } from "../../services/apiCalls";
import {
  updateUsersForSelectedCompany,
  updateCurrentlySelectedEditUserId,
  updateCurrentlySelectedEditId,
  updateSelectedUser,
  updateIsUserAdmin,
} from "../../pages/userManagement/userManagerReducer";

import Delete from "../../assets/icons/activeDelete.png";
import UserManagementPopup from "../../pages/userManagement/userManagementPopUps";
import zIndex from "@material-ui/core/styles/zIndex";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import AddNewUser from "../../components/addNewUser/addNewUser";
import { useTranslation } from "react-i18next";
import Statusicon from "../../assets/Group 711.svg";

const AddButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  padding-left: 1.5%;
  margin-top: -2%;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;

const ExportButton = styled.p`
  font-weight: 600;
  font-size: 14px;
  padding-left: 96%;
  margin-top: -2%;
  line-height: 19px;
  cursor: pointer;
  color: #d04c8d;
`;

const CompanySection = styled.div`
  display: flex;
  margin-bottom: 20px;
  marginleft: "20px";

  // @media (max-width: 767px) {
  //   display: none;
  // }
`;

const DashboardWrapper = styled.div``;
const PageWrapper = styled.div`
  background: ${Colors.light};
  border-radius: 10px;
  padding: 2rem 2rem 2px 2rem;
  margin: 1rem 0 0rem 0;

  @media (max-width: 500px) {
    padding: 0.5rem;
    margin: 0.5rem 0;
  }
`;

const HeaderText = styled.h2`
  font-weight: 500;
  font-size: 28px;
  line-height: 38px;
  color: ${Colors.dark};
  margin-top: 5px;
  @media (max-width: 500px) {
    margin-top: 0px;
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    margin-top: 0px;
  }
`;

const Section = styled.div`
  margin-bottom: 50px;
`;

const FlexDiv = styled.div`
  display: flex;
`;

const JobsWrapper = styled(FlexDiv)`
  align-items: center;
  justify-content: space-between;
  margin: 10px 0px;
  > h4 {
    margin: 0;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
    color: ${Colors.dark};
  }
  > p {
    margin: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    text-decoration-line: underline;
    color: ${Colors.primaryText};
    cursor: pointer;
  }
`;
const FilterIcon = styled.div`
  background: rgba(255, 255, 255, 1);
  margin: 15px 20px 0px 0px;
  width: 100px;
  height: 50px;
  border-radius: 93px;
`;
const ButtonDiv = styled.div`
  @media (min-width: 320px) and (max-width: 480px) {
    position: absolute;
    left: 24%;
    margin-top: 60px;
  }
`;
const FilterImg = styled.div`
  width: 100%;
  padding: 0;
  margin-top: 12%;
  text-align: center;
  @media (max-width: 500px) {
    position: relative;
    // bottom: 50px;
    // left: 82%;
    width: 22px;
    height: 15px;
    padding: 10px 0px 0px 0px;
    margin: 3px 0px 0px 10px;
  }
  img {
    widith: 60%;
  }
`;
const InputBox = styled.div`
  margin-top: 20px;
  margin-right: 20px;
  width: 90%;
  height: 54px;
  @media (max-width: 500px) {
    width: 100%;
  }
`;

export const DataTableWrapper = styled.div`
  background: #ffffff;
  position: relative;
  border-radius: 34px;
  overflow: hidden;
  margin-bottom: 3rem;
  .custom-selector {
    cursor: pointer !important;
    div[id*="option"] {
      cursor: pointer;
      &:hover {
        background-color: #f3f0f0 !important;
      }
    }
  }
  .rdt_TableHead {
    border-radius: 34px;
    height: 83px;
    box-shadow: 0px 0px 10px #d9d9d9;
    overflow: hidden;
    z-index: 0;
    position: relative;
    margin-bottom: 15px;
    text-align: center;
    .rdt_TableCol_Sortable,
    rdt_TableCol {
      font-family: General Sans;
      white-space: pre-line !important;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 108%;
      color: #d04c8d;
      div {
        white-space: pre-line !important;
      }
    }
  }
  .rdt_TableBody {
    border-radius: 0px 0px 34px 34px;

    .rdt_TableRow {
      cursor: pointer;
      &:hover {
        background-color: #ffe9f4;
      }
    }
  }
`;
const DataTableLoaderWrapper = styled.div`
  z-index: 100;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

export const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  svg {
    height: auto;
    width: 100px;

    @media (min-width: 768px) {
      width: 5vw;
    }
  }
  .arrowOne {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) 0s infinite running
      move_eye;
    position: absolute;
  }
  .arrowTwo {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -0.5s infinite running move_eye;
    position: absolute;
  }
  .arrowThree {
    animation: 1s cubic-bezier(0.72, 0.31, 0.41, 0.89) -1s infinite running move_eye;
    position: absolute;
  }
  .main {
    position: absolute;
  }

  @-webkit-keyframes move_eye {
    from {
      transform: translate(-50%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(0%, 0%);
      opacity: 1;
    }
  }

  @keyframes move_eye {
    from {
      transform: translate(-40%, 0%);
      opacity: 0.4;
    }

    to {
      transform: translate(30%, 0%);
      opacity: 1;
    }
  }
`;

const columnStyling = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "92px",
  color: "#202020",
  // margin: " 1.5rem 1.5rem 1.5rem 1.5rem",
  justifyContent: "center",
  "@media (max-width: 990px)": {
    display: "flex",
    // fontSize: 14,
    justifyContent: "flex-start",
    // padding: "0.5rem 0 0 0.5rem",
  },
};

const columnStylingBigger = {
  fontFamily: "General Sans",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 14,
  lineHeight: "24px",
  minWidth: "200px",
  color: "#202020",
  // margin: " 1.5rem 0",
  textAlign: "left",
  padding: "1.5rem",

  "@media (max-width: 990px)": {
    display: "flex",
    // fontSize: 14,
    justifyContent: "flex-start",
    margin: "1rem 0",
  },
};

const useStyles = makeStyles(() => ({
  ul: {
    "& .MuiPaginationItem-root.Mui-selected ": {
      background: "#D04C8D",
      color: "#fff",
    },
  },
}));

// const ButtonWrapper = styled.div`
//   display: flex;
//   justify-content: space-between;
// `;
export default function MyCompany(props) {
  //handle delete
  const [checkDelete, setCheckDelete] = useState(false);
  const [deleteUserId, setDeleteUserId] = useState(null);
  const [deleteOrgCode, setDeleteOrgCode] = useState(null);

  //handle user management export
  const [exportExcelData, setExportExcelData] = useState([]);
  const users = useSelector(
    (state) => state.userManager.usersForSelectedCompany
  );

  const { t } = useTranslation();
  const [datas, setData] = useState([]);
  const [totalRows, setTotalRows] = useState(0);
  const [keyword, setKeyword] = useState(null);
  const [perPage, setPerPage] = useState(10);
  const company = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const userRole = useSelector((state) => state.company);
  const userRole2 = useSelector((state) => state.company.currentOrgData);
  const [paginationUsers, setPaginationUsers] = useState(undefined);

  const [loading, setLoading] = useState(true);
  const [unAuth, setUnAuth] = useState(false);
  const [isOpenham, setOpenHam] = useState(false);
  const [searchval, setSearchFilter] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [searchError, setSearchError] = useState(false);
  const [searchIdx, setSearchIdx] = useState(undefined);
  const [isEdit, setIsEdit] = useState(false);
  const [companyIdx, setCompanyIdx] = useState(null);
  const [editLoading, setEditLoading] = useState(true);
  const dispatch = useDispatch();
  const org = useSelector((state) => state.org.orgData);
  const user = useSelector((state) => state.user.userData);
  const companies = useSelector((state) => state.company.companyData);
  const editCompany = useSelector(
    (state) => state.company.currentlySelectedcompanyData
  );
  const isDataEdited = useSelector((state) => state.company.loading);
  const [editData, setEditData] = useState({});
  const [paginationCount, setPaginationCount] = useState(5);
  const [paginationTabIndex, setPaginationTabIndex] = useState(1);
  const [paginationCompanies, setPaginationCompanies] = useState(undefined);
  const [orgCode, setOrgCode] = useState(undefined);
  const [primaryColor, setPrimaryColor] = useState("#ffffff");
  const [secondaryColor, setSecondaryColor] = useState("#d04c8d");
  const [total, setTotal] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const numberOfRows = 5;
  const org_code = useSelector((state) => state.company.selectedGlobalOrgCode);

  let navigate = useNavigate();

  const navigateRow = (row) => {
    console.log(row.id, "This is ID");
    // setDeleteUserId(row.id);
    console.log(deleteUserId, "user ID");
    dispatch(updateCurrentlySelectedEditUserId(row.user_id));
    dispatch(updateCurrentlySelectedEditId(row.id));
    navigate(`/my-company/edit-user`);
  };

  useEffect(() => {
    if (deleteUserId !== null) {
      deleteUser(orgCode, deleteUserId);
    }
  }, [deleteUserId]);
  //handle usermanagement export
  function handleExcelExport() {
    exportDownload();
  }

  function exportDownload() {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = company.name + " - User Management List";
    const ws = XLSX.utils.json_to_sheet(exportExcelData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }
  useEffect(() => {
    if (!isEmpty(users)) setLoading(false);
    if (!isEmpty(users)) {
      setExportExcelData(generateExportExcelData());
    }
  }, [users]);
  function getFullString(data) {
    if (data == null || data === undefined) return data;
    if (data.toUpperCase() == "Y") {
      return "Yes";
    } else if (data.toUpperCase() == "N") {
      return "No";
    }
    return data;
  }
  function generateExportExcelData() {
    let data = [];
    let userExportDataHeaders = [
      "first_name",
      "last_name",
      "email",
      "department",
      "job_title",
      "mob_number",
      "is_admin",
      "company_view",
      "company_modify",
      "internship_view",
      "internship_modify",
      "view_application",
      "modify_application",
      "view_job",
      "modify_job",
      "view_talents",
      "search_talents",
      "user_management_view",
      "user_management_modify",
    ];
    if (!isEmpty(users)) {
      Object.keys(users).length > 0 &&
        Object.keys(users).map((list, index) => {
          let usrObj = {};
          for (let i = 0; i < userExportDataHeaders.length; i++) {
            usrObj[userExportDataHeaders[i]] = getFullString(
              users[list][userExportDataHeaders[i]]
            );
          }
          data.push(usrObj);
        });
    }
    return data;
  }

  //handle user management add new user
  const onNavigate = () => {
    if (
      company.status == "accepted" &&
      userRole2?.user_details.user_management_modify === "Y"
    ) {
      navigate("/my-company/add-user");
    } else {
      setUnAuth(true);
    }
  };

  const handlePerRowsChange = async (newPerPage, page) => {
    onApiCall({
      url: `company/api/company-users/${orgCode}`,
      method: "GET",
      params: {
        display_name: keyword,
        page: page,
        per_page: newPerPage,
        display_name: keyword,
      },
    }).then((res) => {
      if (res.status == 200) {
        setData(res?.data?.data?.results.data);
        setPerPage(newPerPage);
      }
    });
  };

  function getOrgCode() {
    if (Object.keys(companies).length > 0) {
      for (let i = 0; i < Object.keys(companies).length; i++) {
        if (companies[i].org_name == company.name) {
          setOrgCode(companies[i].org_code);
          return;
        }
      }
    }
  }
  useEffect(() => {
    getOrgCode();
  });

  const handlePageChange = (page) => {
    onApiCall({
      url: `company/api/company-users/${getOrgCode}`,
      method: "GET",
      params: {
        display_name: keyword,
        page: page,
        per_page: perPage,
        display_name: keyword,
      },
    }).then((res) => {
      if (res.status == 200) {
        setData(res?.data?.data?.results.data);
        setTotalRows(res?.data?.data?.results.total);
      }
    });
  };

  const getAllUsersForSelectedCompany = async () => {
    if (orgCode) {
      setPaginationUsers([]);
      const { data } = await getAllUsers(orgCode);
      if (data && data.status === 1) {
        //setLoading(false)
        dispatch(updateUsersForSelectedCompany({ ...data.data }));
        // setData({ ...data.data })s
        setPaginationUsers([...data.data]);
      }
    }
  };

  //Delete User(UserManagement)
  const showToastDeleted = (type, msg, status) => {
    if (status == "success") {
      toast.success(`User ${type} successfully`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(msg, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const deleteUser = (orgCode, deleteThisUserId) => {
    if (userRole?.currentOrgData.user_details.user_management_modify !== "Y") {
      return setUnAuth(true);
    }
    console.log(deleteUserId, "user ID");
    setDeleteUserId(deleteThisUserId);
    setCheckDelete(true);
    setDeleteOrgCode(orgCode);

    //alert("delete User called")
  };

  function proceed() {
    confirmDeleteUser(deleteOrgCode, deleteUserId);
    setCheckDelete(false);
  }

  const confirmDeleteUser = async (org_code) => {
    const { data } = await deleteCompanyUser(org_code, { id: deleteUserId });
    if (data && data.status === 1) {
      showToastDeleted("Deleted", "", "success");
      getAllUsersForSelectedCompany();
      dispatch(updateCurrentlySelectedEditId(deleteUserId));
      // dispatch(updateUserData({ ...data.data }));
    } else {
      //navigate("/login");
      //setLoading(false);
      showToastDeleted("Deleted", data.message, "failed");
    }
  };

  useEffect(() => {
    getAllUsersForSelectedCompany();
  }, [orgCode]);

  useEffect(() => {
    getOrganisationInfo();
  }, []);

  useEffect(() => {
    if (isEmpty(user)) getUserInfo();

    if (isEmpty(companies)) getOrganisationInfo();
  });

  useEffect(() => {
    if (!isEmpty(companies)) {
      setLoading(false);
    }
  });

  useEffect(() => {
    getCompanyInfo(companyIdx);
  }, [isDataEdited]);

  useEffect(() => {
    if (searchval == "" && currentPage == 0) {
      let cmpyCount = Object.keys(companies).length;
      if (cmpyCount > 0) {
        let quo = Math.floor(cmpyCount / numberOfRows);
        let rem = cmpyCount % numberOfRows;
        setPaginationCount(rem != 0 ? quo + 1 : quo);
        setTotal(rem != 0 ? quo + 1 : quo);
        setCurrentPage(0);
        setPaginationTabIndex(1);
        setPaginationCompanies(getPaginationCompanies(1));
      }
    }
  }, [companies, isSearch]);

  function getPaginationCompanies(tabIdx) {
    let cmpyObj = {};
    let cmpyCount = Object.keys(companies).length;
    let toIdx = tabIdx * numberOfRows;
    let fromIdx = toIdx - numberOfRows;
    if (toIdx > cmpyCount) {
      toIdx = cmpyCount;
    }

    for (let i = 0; i < toIdx; i++) {
      cmpyObj[i] = companies[i];
    }
    return cmpyObj;
  }

  const fetchData = () => {
    if (total > currentPage) {
      setCurrentPage(currentPage + 1);
      displayPage(currentPage + 2);
    }
  };

  useEffect(() => {
    if (isEdit) {
      setEditData({});
    }
  }, [isEdit]);

  useEffect(() => {
    if (searchval === "") {
      setIsSearch(false);
      setSearchError(false);
      setPaginationTabIndex(1);
    }
  }, [searchval]);

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }

  const getCompanyInfo = async (index) => {
    if (index != undefined) {
      const { data } = await getCompanyData({
        code: companies[index].org_code,
      });
      if (data && data.status === 1) {
        setEditData({ ...data.data });
      }
    }
  };

  const getData = async (code) => {
    var result1 = await getOrgData();
    if (result1.status === "401") {
      navigate("/login");
    }
  };

  const getUserInfo = async () => {
    const { data } = await getUserData();
    if (data && data.status === 1) {
      dispatch(updateUserData({ ...data.data }));
    } else {
      setLoading(false);
    }
  };
  const getOrganisationInfo = async () => {
    const { data } = await getCompanies();
    if (data && data.status === 1) {
      if (JSON.stringify(companies) != JSON.stringify(data.data)) {
        dispatch(updateCompanyData({ ...data.data }));
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  const onSearch = (e) => {
    setSearchFilter(e.target.value);
    let arr = [];
    let compKeys = Object.keys(companies);
    for (let i = 0; i < compKeys.length; i++) {
      if (
        companies[compKeys[i]].org_name.toLowerCase().includes(e.target.value)
      ) {
        arr.push(i);
      }
      /*if (companies[compKeys[i]].org_name.toLowerCase() == e.target.value){
        setSearchIdx(i)
      }*/
    }
    setSearchIdx(arr);
  };

  const toCompanyPage = (status) => {};

  const showToast = () => {
    toast.success(t("Company status updated successfully"), {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  function onClickNavToMyCompany(index) {
    setOrgCode(companies[index].org_code);
    setEditLoading(true);
    dispatch(updateLoader(true));
    getCompanyInfo(index);
    setCompanyIdx(index);
    setIsEdit(!isEdit);
  }

  const displayPage = (val) => {
    setPaginationTabIndex(val);
    setPaginationCompanies(getPaginationCompanies(val));
  };

  function handleSearch() {
    if (searchval.length > 0) {
      if (searchIdx == undefined) {
        setSearchError(true);
        setIsSearch(true);
        return;
      }
      let obj = {};
      for (let i = 0; i < searchIdx.length; i++) {
        obj[searchIdx[i]] = companies[searchIdx[i]];
      }
      setPaginationCompanies(obj);
      setIsSearch(true);
    }
  }
  const paginationOptions = {
    rowsPerPageText: t("Rows per page:"),
  };

  // const handleInviteStatus = (id) => {
  //   updateInviteResponse("pending", id);
  // };

  const handleInviteStatus = async (response, id) => {
    const { data } = await userCompanyInvite(org_code, {
      id: id,
      status: response,
    });
    if (data && data.status === 1) {
      showToastDeleted("Resend", "", "success");
      // getOrganisationInfo();
    } else {
      showToastDeleted("Resend", data.message, "Failed");
    }
  };
  //new add
  const columns = [
    {
      name: " ",
      selector: (row) => row.status,
      center: true,
      width: "2%",
      cell: (row, index) => {
        return (
          <div style={{ position: "absolute", left: "1rem" }}>
            {row.status === "pending" ? (
              <img src={Statusicon} height={25} />
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      name: t("Name"),
      selector: (row) => row.first_name + row.last_name + row.email,
      sortable: true,
      style: columnStylingBigger,
      center: true,
      width: "300px",
      cell: (row, index) => {
        return (
          <>
            <Row style={{ position: "absolute", left: "2rem" }}>
              <Col lg="">
                <span
                  style={{ textTransform: "capitalize", marginLeft: "0px" }}
                >
                  {row.first_name + " " + row.last_name}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{row.email}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },

    {
      name: t("Job Title"),
      selector: (row) => row.job_title + row.department,
      sortable: true,
      style: columnStylingBigger,
      center: true,
      // width: "250px",
      cell: (row, index) => {
        return (
          <>
            <Row
              style={{ position: "absolute", left: "2rem" }}
              // onClick={() => onRowClicked(row)}
            >
              <Col lg="">
                <span
                  style={{ textTransform: "capitalize", marginLeft: "0px" }}
                >
                  {row.job_title}
                </span>
              </Col>
              <Col
                lg="12"
                style={{
                  color: "#BCBCBC",
                  fontSize: "14px",
                  fontStyle: "italic",
                }}
              >
                <span>{row.department}</span>
              </Col>
            </Row>

            <br />
          </>
        );
      },
    },

    {
      name: t("Mobile No"),
      sortable: true,
      style: columnStyling,
      selector: (row) => row.mob_number,
      center: true,
      //width: "25%",
      marginLeft: "0px",
    },
    {
      name: "Admin ",
      sortable: true,
      style: columnStyling,
      selector: (row) => row.is_admin,
      center: true,
      width: "20%",
      cell: (row, index) => {
        return (
          <>
            {row.is_admin === "Y" ? (
              <RightIcon height={25} />
            ) : (
              <CrossIcon height={25} />
            )}
          </>
        );
      },
    },
    {
      name: t("Action"),
      sortable: true,
      style: columnStyling,
      // selector: (row) => Delete,
      center: true,
      // width: "20%",
      // marginLeft: "0px",
      cell: (row, index) => {
        const onRowClicked = () => navigateRow(row);
        const onRowClickedDelete = () => deleteUser(orgCode, row.id);
        const updateInviteResponse = () =>
          handleInviteStatus("pending", row.id);
        return (
          <div
            style={{
              display: "grid",
              gridAutoFlow: "column",
              gap: "1rem",
              alignItems: "center",
              justifyItems: "center",
            }}
          >
            <div>
              <PencilIcon onClick={onRowClicked} color={Colors.greyText} />
            </div>
            <div>
              <img src={Delete} onClick={onRowClickedDelete} />
            </div>
            <div>
              {row.status === "rejected" ? (
                <button onClick={updateInviteResponse} className="resendbtn">
                  {t("Resend")}
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <UnAuthPopup isOpen={unAuth} setOpen={setUnAuth}></UnAuthPopup>
      <UserManagementPopup
        isOpen={checkDelete}
        setOpen={setCheckDelete}
        proceed={proceed}
      ></UserManagementPopup>
      <MainLayout>
        <DashboardWrapper>
          {!isEdit ? (
            <>
              <HeaderText>{t("My Company")}</HeaderText>
              <CompanySection>
                <InputBox>
                  <Input
                    color="#fff"
                    placeholder={t("Search Keywords for Company Name")}
                    value={searchval}
                    onChange={onSearch}
                  />
                </InputBox>

                <FilterIcon>
                  <FilterImg>
                    <img src={Union} alt="imgUnion" style={{}} />
                  </FilterImg>
                </FilterIcon>

                <ButtonDiv>
                  <Button
                    style={{ cursor: "pointer" }}
                    name={t("Search")}
                    onClick={handleSearch}
                  />
                </ButtonDiv>
              </CompanySection>

              <AddNewCompany setUnAuth={setUnAuth} />
            </>
          ) : (
            <></>
          )}
          {
            <>
              {loading ? (
                <Loader />
              ) : !isEdit ? (
                <>
                  <Section>
                    <JobsWrapper>
                      <h4>{t("Your Companies")}</h4>
                    </JobsWrapper>
                    <InfiniteScroll
                      dataLength={Object.keys(paginationCompanies).length || 0}
                      next={fetchData}
                      hasMore={currentPage < total}
                      scrollThreshold={0.8}
                    >
                      {Object.keys(paginationCompanies).length > 0 &&
                      !searchError ? (
                        Object.keys(paginationCompanies).map((list, index) => (
                          <PageWrapper>
                            <CompaniesCard
                              showToast={showToast}
                              key={list}
                              keyIdx={list}
                              datas={paginationCompanies[list]}
                              onCardClick={() => onClickNavToMyCompany(list)}
                            />
                          </PageWrapper>
                        ))
                      ) : (
                        <p class="noDataFound">{t("No data found")}</p>
                      )}
                    </InfiniteScroll>
                  </Section>
                </>
              ) : (
                <>
                  <div role="presentation">
                    <div
                      className="d-flex mb-1"
                      style={{
                        fontSize: "1.1rem",
                        fontWeight: "400",
                        marginLeft: "15px",
                        marginBottom: "5px",
                      }}
                    >
                      <span
                        onClick={() => setIsEdit(!isEdit)}
                        style={{
                          textDecoration: "none",
                          color: "#ACACAC",
                          cursor: "pointer",
                          fontWeight: "500",
                        }}
                      >
                        {t("My Company")} &nbsp; &#62;
                      </span>
                      <div className="mx-2" style={{ color: "#ACACAC" }}></div>
                      <span
                        style={{
                          textDecoration: "none",
                          color: "#D04C8D",
                          fontWeight: "500",
                        }}
                      >
                        {t("Edit Company")}
                      </span>
                    </div>
                  </div>
                  {isEmpty(editData) ? (
                    <>
                      <Loader />
                    </>
                  ) : (
                    <>
                      <CommentsSection
                        primaryColor={primaryColor}
                        secondaryColor={secondaryColor}
                        comments={editData.comments}
                      />
                      <PageWrapper>
                        <MyCompanyCard
                          primaryColor={primaryColor}
                          secondaryColor={secondaryColor}
                          companyIdx={companyIdx}
                          companyDetails={editData}
                        />
                        <Overview
                          primaryColor={primaryColor}
                          secondaryColor={secondaryColor}
                          companyIdx={companyIdx}
                          companyDetails={editData}
                        />
                        <>
                          <h4
                            style={{
                              color: "rgb(208, 76, 141)",
                              fontSize: "18px",
                              paddingBottom: "10px",
                              marginRight: "25px",
                              marginLeft: "25px",
                              borderBottom: "0.5px solid black",
                            }}
                          >
                            {t("User Management")}{" "}
                          </h4>

                          <ExportButton onClick={handleExcelExport}>
                            <div>{t("Export")}</div>
                          </ExportButton>

                          <Row>
                            <Col>
                              <DataTableWrapper>
                                <DataTable
                                  onRowClicked={""}
                                  columns={columns}
                                  data={paginationUsers}
                                  pagination
                                  paginationServer
                                  paginationTotalRows={totalRows}
                                  onChangeRowsPerPage={handlePerRowsChange}
                                  s
                                  onChangePage={handlePageChange}
                                  theme="solarized"
                                  progressComponent={
                                    <div
                                      style={{
                                        minHeight: "586px",
                                        backgroundColor: `#FFFFFF !important`,
                                      }}
                                    >
                                      <DataTableLoaderWrapper>
                                        <LoaderWrapper>
                                          <Loader />
                                        </LoaderWrapper>
                                      </DataTableLoaderWrapper>
                                    </div>
                                  }
                                  paginationComponentOptions={paginationOptions}
                                />
                                <>
                                  <AddButton onClick={onNavigate}>
                                    <div>{t("+ Add New User")}</div>
                                  </AddButton>
                                </>
                              </DataTableWrapper>
                            </Col>
                          </Row>
                        </>

                        <Location
                          primaryColor={primaryColor}
                          secondaryColor={secondaryColor}
                          companyIdx={companyIdx}
                          companyDetails={editData}
                        />
                        <Button
                          cursor="pointer"
                          name={t("Back")}
                          onClick={() => setIsEdit(!isEdit)}
                          className="companyButton"
                        ></Button>
                      </PageWrapper>
                    </>
                  )}
                </>
              )}
            </>
          }
        </DashboardWrapper>
      </MainLayout>
    </>
  );
}
